var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/edit-modal";
const editModal = (state = {
  isOpened: false,
  isOrderModal: false
}, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_EDIT_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isOpened: true,
        isOrderModal: false
      });
    case ACTIONS.CLOSE_EDIT_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isOpened: false
      });
    case ACTIONS.OPEN_ORDER_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isOrderModal: true,
        isOpened: false
      });
    case ACTIONS.OPEN_ORDER_MODAL_FOR_MULTI_COLUMN:
      return __spreadProps(__spreadValues({}, state), {
        isOrderModal: true,
        isOpened: false,
        multiColumnData: action.payload.multiColumnData
      });
    case ACTIONS.CLOSE_ORDER_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isOrderModal: false,
        multiColumnData: void 0
      });
    default:
      return state;
  }
};
export default editModal;
