import React, { useCallback, useEffect, useRef, useState } from "react";
import "./dropdown-infinite-list.sass";
import PropTypes from "prop-types";
import { DropdownHeader } from "../../button/header/dropdown-header";
import { DropdownSearchInput } from "../../seach-input/dropdown-search-input";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType,
  PreloaderSizeType
} from "../../../preloaders/preloader/preloader";
import InfiniteScroll from "react-infinite-scroll-component";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import debounce from "lodash.debounce";
import Tooltip from "../../../../../feature/transform-ui/components/tooltip/tooltip";
import TintButtonIcon, { TintButtonIconTypes } from "../../../button-icon/button-icon";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AccountStatus } from "../../../../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/components/account-dropdown/account-dropdown";
import { Typography } from "../../../typography/typography";
const debounceDelay = 300;
const DropdownInfiniteList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems = [],
  dropdownHeaderStyle,
  onLoadData,
  onInputChange,
  isAbleToLoad,
  inputValue,
  setInputListValue,
  isDefaultOption = false,
  defaultOptionTitle = "None",
  placeholder,
  lastChildColor,
  refreshSocialConnection,
  isLoading,
  fixedOption = false
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const updateInputField = useCallback(debounce(onInputChange, debounceDelay), []);
  const defaultValue = { value: "", name: defaultOptionTitle };
  const DropdownTextElement = (title) => {
    return /* @__PURE__ */ React.createElement(
      DropdownHeader,
      {
        iconLeft,
        iconRight,
        iconCenter,
        title,
        style: dropdownHeaderStyle
      }
    );
  };
  useEffect(() => {
    setSearchTerm(inputValue);
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const onInputSearchChange = (event) => {
    updateInputField(event.target.value);
    setInputListValue(event.target.value);
    setSearchTerm(event.target.value);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    InfiniteScroll,
    {
      dataLength: currentItems.length,
      next: onLoadData,
      hasMore: isAbleToLoad,
      scrollableTarget: "dropdown-infinite-list-scroll"
    },
    /* @__PURE__ */ React.createElement(
      "ul",
      {
        className: `dropdown-infinite-list dropdown-infinite-list--${positionHorizontal} dropdown-infinite-list--${positionVertical} dropdown-infinite-list--${fixedOption ? "fixed" : "static"}`,
        ref: elementRef,
        "data-testid": "dropdown-infinite-list",
        id: "dropdown-infinite-list-scroll"
      },
      (currentItems.length === 0 || currentItems.length === 0 && searchTerm.length > 0) && /* @__PURE__ */ React.createElement("li", { className: "dropdown-infinite-list__item dropdown-infinite-list__item--no-results" }, /* @__PURE__ */ React.createElement(Typography, null, "No results")),
      onInputChange && /* @__PURE__ */ React.createElement("li", { className: "dropdown-infinite-list__item dropdown-infinite-list__item--search" }, /* @__PURE__ */ React.createElement(DropdownSearchInput, { onChange: onInputSearchChange, searchTerm, disabled: isLoading }), isLoading && /* @__PURE__ */ React.createElement(
        PreloaderComponent,
        {
          positionType: PreloaderPositionType.ABSOLUTE,
          backdropType: PreloaderBackdropType.TRANSPARENT,
          size: PreloaderSizeType.SMALL
        }
      )),
      isDefaultOption && /* @__PURE__ */ React.createElement(
        "li",
        {
          className: "dropdown-infinite-list__item",
          onClick: () => {
            onChange(defaultValue.value);
            onChangeTextComponent(DropdownTextElement(placeholder));
          }
        },
        /* @__PURE__ */ React.createElement("span", null, defaultValue.name)
      ),
      currentItems.map((item, i) => /* @__PURE__ */ React.createElement(
        "li",
        {
          className: `dropdown-infinite-list__item ${i === currentItems.length - 1 && fixedOption ? "dropdown-infinite-list__item--additional" : ""} ${(item == null ? void 0 : item.isActive) && fixedOption ? "active" : ""}`,
          key: i,
          onClick: () => {
            if ((item == null ? void 0 : item.status) !== AccountStatus.EXPIRED) {
              onChange(item);
              onChangeTextComponent(DropdownTextElement(item.name));
            } else {
              refreshSocialConnection(item);
            }
          }
        },
        (item == null ? void 0 : item.header) && /* @__PURE__ */ React.createElement("header", null, item.header),
        (item == null ? void 0 : item.icon) ? /* @__PURE__ */ React.createElement("span", { className: `icon ${item.icon}` }) : null,
        /* @__PURE__ */ React.createElement(
          "span",
          {
            style: i === currentItems.length - 1 && fixedOption && lastChildColor ? { color: lastChildColor } : null
          },
          item == null ? void 0 : item.name
        ),
        (item == null ? void 0 : item.status) === AccountStatus.EXPIRED && /* @__PURE__ */ React.createElement(
          Tooltip,
          {
            text: "Reconnect",
            component: /* @__PURE__ */ React.createElement(
              TintButtonIcon,
              {
                type: TintButtonIconTypes.NONE,
                icon: faCircleExclamation,
                color: "#FBAA22",
                iconSize: 14,
                onClick: (e) => {
                  e.preventDefault();
                }
              }
            )
          }
        )
      ))
    )
  ));
};
export default DropdownInfiniteList;
DropdownInfiniteList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string
};
