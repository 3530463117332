import React, { memo } from "react";
import PropTypes from "prop-types";
import "./social-container.sass";
import { Config } from "../../../../../../../utils/config";
import ColorParser from "../../../../../../../services/color-parser/color-parser";
const socialTypeToIconClass = {
  facebook: "fa-facebook",
  twitter: "fa-x-twitter",
  instagram: "fa-instagram",
  youtube: "fa-youtube",
  vimeo: "fa-vimeo-v",
  tumblr: "fa-tumblr",
  linkedin: "fa-linkedin-in",
  pinterest: "fa-pinterest",
  tiktok: "fa-tiktok"
};
const SocialContainer = memo(function SocialContainer2({ backgroundColor, iconColor, url, type }) {
  if (url === "" || url === void 0) {
    return null;
  }
  const domainAddressRegExp = Config.regExp.domainPattern.test(url);
  const addressUrl = !domainAddressRegExp ? url : `https://${url}`;
  return /* @__PURE__ */ React.createElement("div", { className: "contest-builder-page__social-container", onClick: () => window.open(addressUrl, "_blank") }, /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `fab ${socialTypeToIconClass[type]}`,
      style: {
        color: ColorParser.defaultColor(iconColor),
        backgroundColor: ColorParser.defaultColor(backgroundColor)
      }
    }
  ));
});
SocialContainer.propTypes = {
  backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  url: PropTypes.string,
  type: PropTypes.string
};
export default SocialContainer;
