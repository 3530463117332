import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { PRODUCTS } from "../../consts";
export const ACTIONS = {
  OPEN_MODAL: createPrefixedActionName(PRODUCTS, "OPEN_MODAL"),
  UPDATE_MODAL: createPrefixedActionName(PRODUCTS, "UPDATE_MODAL"),
  CLOSE_MODAL: createPrefixedActionName(PRODUCTS, "CLOSE_MODAL")
};
export const openModal = (data) => ({
  type: ACTIONS.OPEN_MODAL,
  payload: data
});
export const updateModal = (data) => ({
  type: ACTIONS.UPDATE_MODAL,
  payload: data
});
export const closeModal = () => ({
  type: ACTIONS.CLOSE_MODAL
});
