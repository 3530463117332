import { useState } from "react";
import { FileStack } from "../../../services/filestack/file-stack";
import { Config } from "../../../utils/config";
export const useFileStack = ({
  onUploadSuccess,
  onUploadError,
  maxSize = Config.maxFileSize,
  accept = ["image/*"]
}) => {
  let [uploadedFiles, setUploadedFiles] = useState();
  const onUpload = (res) => {
    if (res.filesUploaded.length > 0) {
      setUploadedFiles(res == null ? void 0 : res.filesUploaded);
      onUploadSuccess == null ? void 0 : onUploadSuccess(res == null ? void 0 : res.filesUploaded);
    }
  };
  const onError = () => {
    onUploadError == null ? void 0 : onUploadError();
  };
  const onOpenFilePicker = () => {
    var _a;
    (_a = new FileStack()) == null ? void 0 : _a.openFilePicker({
      accept,
      maxSize,
      onUploadDone: onUpload,
      onFileUploadFailed: onError
    });
  };
  return {
    onOpenFilePicker,
    uploadedFiles
  };
};
