var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { Config } from "../../utils/config";
import * as filestack from "filestack-js";
export class FileStack {
  constructor(apiKey = Config.fileStackApiKey.general()) {
    __publicField(this, "getClient", () => this.client);
    __publicField(this, "openFilePicker", (options) => this.client.picker(__spreadValues({
      onFileSelected: (file) => {
        const maxFileSize = (options == null ? void 0 : options.maxSize) || Config.maxFileSize;
        const maxVideoFileSize = (options == null ? void 0 : options.maxSize) || Config.maxVideoFileSize;
        if (file.mimetype.includes("image") && file.size > maxFileSize) {
          const fileMbSize = this.convertBytesToMegabytes(maxFileSize);
          throw new Error(`File too big, select an image smaller than ${fileMbSize}MB`);
        }
        if (file.mimetype.includes("video") && file.size > maxVideoFileSize) {
          const videoFileMbSize = this.convertBytesToMegabytes(maxVideoFileSize);
          throw new Error(`File too big, select a video smaller than ${videoFileMbSize}MB`);
        }
      }
    }, options)).open());
    __publicField(this, "metadata", (handle, options) => this.client.metadata(handle, options));
    __publicField(this, "convertBytesToMegabytes", (value) => {
      if (value && typeof value === "number") {
        return value / 1024 / 1024;
      }
    });
    this.client = filestack.init(apiKey);
  }
}
