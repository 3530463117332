export const numberScale = {
  NUMBER_SCALE: ".input-number-scale",
  NUMBER_SCALE_LEGEND: ".input-number-scale legend",
  NUMBER_SCALE_INPUT: '.input-number-scale input[type="radio"]',
  NUMBER_SCALE_INPUT_BEFORE: ".input-number-scale input:before",
  NUMBER_SCALE_INPUT_ACTIVE: ".input-number-scale input:checked, .input-number-scale input:hover",
  NUMBER_SCALE_DIV: ".input-number-scale div",
  NUMBER_SCALE_LABEL: ".input-number-scale label",
  NUMBER_SCALE_LABEL_MIN: ".input-number-scale div:first-of-type",
  NUMBER_SCALE_LABEL_MAX: ".input-number-scale div:last-of-type",
  NUMBER_SCALE_RTL: "*[dir='rtl'] .input-number-scale div:last-of-type"
};
