import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import MasonryLayout from "../../../../../../../../../components/interface/grid/masonry/masonry";
import PostItem from "../../post-item/post-item";
import "./range-col.sass";
import useInfiniteScroll from "../../../../../../../../../components/hooks/use-infinite-scroll/use-infinite-scroll";
const RangeColumn = function({ socialFeedPosts, styles, isPreviewMode }) {
  const fifteenItems = 15;
  let columnRef = useRef();
  let spliceSocialFeedPosts = [...socialFeedPosts];
  let [postsList, setNewPostsList] = useState([]);
  useEffect(() => {
    setNewPostsList(socialFeedPosts);
  }, [socialFeedPosts]);
  const { setIsFetching } = useInfiniteScroll(callback, columnRef);
  function callback() {
    setNewPostsList(socialFeedPosts);
    setIsFetching(true);
  }
  return /* @__PURE__ */ React.createElement("div", { className: "tint-range-column", ref: columnRef }, /* @__PURE__ */ React.createElement(MasonryLayout, { elements: isPreviewMode ? postsList : spliceSocialFeedPosts.slice(0, fifteenItems) }, (element) => /* @__PURE__ */ React.createElement(PostItem, { styles, thumb: element.image_url, title: element.title || element.text })));
};
export default RangeColumn;
RangeColumn.propTypes = {
  width: PropTypes.number,
  socialFeedPosts: PropTypes.array
};
