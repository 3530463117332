var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useCallback, useEffect, useState } from "react";
import "./tint-editor-top-bar-filter-modal.sass";
import TintModal from "../../../../../../components/modal/modal";
import TintEditorFilterTags, { TintEditorFilterTagsAllTagValue } from "../filter-tags/tint-editor-filter-tags";
import ModalHeaderCounter from "../../../../../../components/modal/components/modal-header-counter/modal-header-counter";
import TintEditorFilterSaveState from "../filter-save-state/tint-editor-filter-save-state";
import { DropdownDateRangeHeader } from "../../../../../../components/interface/dropdown/lists/date-list/header/dropdown-date-range-header/dropdown-date-range-header";
import DropdownDateRangeCalendar from "../../../../../../components/interface/dropdown/lists/date-calendar-range/dropdown-date-range-calendar";
import DropdownButton, { ButtonType } from "../../../../../../components/interface/dropdown/button/dropdown-button";
import { Config } from "../../../../../../utils/config";
import { postSavedFilters, removeSavedFilters, updateSavedFilters } from "../../../../actions/posts.fat-actions";
import { useDispatch } from "react-redux";
import { selectFilters } from "../../../../actions/posts.actions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkFilterChanges, getVisualSearchImage } from "../../../../reducers/posts.reducer";
import {
  ActionFilterValues,
  actionRightsList,
  actionsTagList,
  mediaTagList,
  sentimentTagList,
  starRatingTagList
} from "./tint-editor-top-bar-filter-modal-filters.data";
import getIconProperties from "../../../../configs/social-icon";
import { faGlobe, faLanguage, faCrosshairs } from "@fortawesome/pro-solid-svg-icons";
import { GuardNames } from "../../../../../../guards/guards-list";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { getTagList } from "../../../../reducers/tint-editor.reducer";
import { VisualSearchFilter } from "../../../../../../components/interface/filter-tags/visual-search-filter/visual-search-filter";
import { Typography, TypographyVariants } from "../../../../../../components/interface/typography/typography";
import { useQuery } from "../../../../../../components/hooks/use-query/use-query";
import { TintEditorConfig } from "../../../../utils/tint-editor.config";
import { TintEditorViewState } from "../../../../containers/tint-editor/tint-editor.container";
import FeatureGuard from "../../../../../../guards/feature-guard";
import useFeatureAccessibility from "../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import useSocialFeedTranslation from "../../../../../../components/hooks/use-social-feed-names/use-feature-accessibility";
import { getSocialFeedSource } from "../../../../selectors/tint-editor.selector";
import { SocialFeedsSource } from "../../../../../../types/social-feeds";
export const FilterGroupTypes = {
  DATE: "published_at",
  SOCIAL_FEEDS: "social_feed_id",
  ACTIONS: "actions",
  TAGS: "tag",
  LANGUAGE: "language",
  MEDIA_TYPE: "media_type",
  SENTIMENT: "sentiment",
  IMAGE_OBJECT: "image_object",
  QUERY: "q",
  PUBLISHED_AT: "published_at",
  RATING: "rating",
  VISUAL_SEARCH: "visually_similar_to"
};
const TintEditorTopBarFilterModal = ({
  tintId,
  currentFilter,
  selectedFilters,
  isModalOpen,
  onModalClose,
  modalDOMDestination,
  onEditTagsClick,
  isGlobalPageState,
  isSettingsSidebarExpanded
}) => {
  const [currentDate, setCurrentDate] = useState();
  const [mediaTypesTags, setMediaTypeTags] = useState(mediaTagList);
  const [actionsTags, setActionsTags] = useState(actionsTagList);
  const tagListSelector = useSelector(getTagList);
  const { getSocialFeedName } = useSocialFeedTranslation();
  const mapTagList = () => tagListSelector == null ? void 0 : tagListSelector.map((tag) => ({
    name: tag,
    value: tag,
    selected: false
  }));
  const languageSelector = useSelector((state) => state.tintEditorPosts.languages);
  const imageObjectSelector = useSelector((state) => state.tintEditorPosts.imageObjects);
  const socialFeeds = useSelector((state) => {
    var _a, _b;
    return ((_b = (_a = state.socialFeeds) == null ? void 0 : _a.tintEditor) == null ? void 0 : _b.data) || [];
  });
  const hasReviewsRatingsSocialFeed = socialFeeds.find(
    (socialFeed) => getSocialFeedSource(socialFeed) === SocialFeedsSource.REVIEWS_RATINGS
  );
  const { newVisualSimilarity: isSimilarImagesAble } = useFeatureAccessibility();
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });
  const queryParams = useQuery();
  const isTrashState = queryParams.get(TintEditorConfig.urlSearchParamState) === TintEditorViewState.TRASH;
  const mapLanguages = languageSelector && Object.keys(languageSelector).map((lang) => ({
    icon: faLanguage,
    name: languageNames.of(lang),
    value: lang
  })) || [];
  const mapImageObjects = imageObjectSelector && Object.keys(imageObjectSelector).map((lang) => ({
    icon: faCrosshairs,
    name: lang,
    value: lang
  })) || [];
  const generateIcon = (feed) => {
    const feedSource = getSocialFeedSource(feed);
    return JSON.stringify(getIconProperties(feed.attributes.source).icon) !== JSON.stringify(faQuestion) ? getIconProperties(feedSource) : getIconProperties(feed.type);
  };
  const mapSocialFeeds = useSelector((state) => {
    var _a, _b;
    return ((_b = (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.data) == null ? void 0 : _b.socialFeeds) || [];
  }).map((socialFeed) => {
    var _a, _b, _c;
    const iconDetails = generateIcon(socialFeed);
    return {
      icon: iconDetails == null ? void 0 : iconDetails.icon,
      svgIconSrc: iconDetails == null ? void 0 : iconDetails.svgIconSrc,
      name: `${socialFeed.attributes.name} \u2022 ${(_c = getSocialFeedName((_a = socialFeed.attributes) == null ? void 0 : _a.source, (_b = socialFeed.attributes) == null ? void 0 : _b.type)) == null ? void 0 : _c.source}`,
      value: socialFeed.id,
      color: iconDetails.color
    };
  });
  const selectedFiltersCount = selectedFilters && Object.keys(selectedFilters).map((key) => {
    return Array.isArray(selectedFilters[key]) && selectedFilters[key].length || 0;
  }).reduce((a, b) => a + b);
  const [sentimentTags, setSentimentTags] = useState(sentimentTagList);
  const [starRatingTags, setStarRatingTags] = useState(starRatingTagList);
  const [tagList, setTagList] = useState(mapTagList());
  const [socialFeedList, setSocialFeedList] = useState(mapSocialFeeds);
  const [languages, setLanguages] = useState(mapLanguages);
  const [imageObjectTags, setImageObjectTags] = useState(mapImageObjects);
  const visualSearchImage = useSelector(getVisualSearchImage);
  useEffect(() => {
    const mapTagList2 = tagListSelector.map((tag) => {
      const currentElement = tagList.find((currentTags) => currentTags.value === tag);
      return currentElement ? currentElement : {
        value: tag,
        name: tag,
        selected: void 0
      };
    }).filter(Boolean);
    setTagList(mapTagList2);
  }, [tagListSelector]);
  const setAsSelectedFilter = useCallback(
    (list, type) => {
      return list.filter((el) => {
        return type === FilterGroupTypes.ACTIONS && !hasReviewsRatingsSocialFeed ? ![ActionFilterValues.NOT_INCENTIVIZED, ActionFilterValues.INCENTIVIZED].includes(el == null ? void 0 : el.queryParamValue) : el;
      }).map((el) => __spreadProps(__spreadValues({}, el), {
        selected: selectedFilters && selectedFilters[type] && selectedFilters[type].find(
          (selectedElement) => selectedElement === el.value || selectedElement === el.queryParamValue
        ) !== void 0
      }));
    },
    [selectedFilters]
  );
  useEffect(() => {
    setSentimentTags((s) => setAsSelectedFilter(s, FilterGroupTypes.SENTIMENT));
    setStarRatingTags((s) => setAsSelectedFilter(s, FilterGroupTypes.RATING));
    setTagList((s) => setAsSelectedFilter(s, FilterGroupTypes.TAGS));
    setSocialFeedList((s) => setAsSelectedFilter(s, FilterGroupTypes.SOCIAL_FEEDS));
    setLanguages((s) => setAsSelectedFilter(s, FilterGroupTypes.LANGUAGE));
    setMediaTypeTags((s) => setAsSelectedFilter(s, FilterGroupTypes.MEDIA_TYPE));
    setActionsTags((s) => setAsSelectedFilter(s, FilterGroupTypes.ACTIONS));
    setImageObjectTags((s) => setAsSelectedFilter(s, FilterGroupTypes.IMAGE_OBJECT));
    const date = selectedFilters && selectedFilters[FilterGroupTypes.PUBLISHED_AT] && {
      startDate: new Date(selectedFilters[FilterGroupTypes.PUBLISHED_AT].$gte).getTime(),
      endDate: new Date(selectedFilters[FilterGroupTypes.PUBLISHED_AT].$lte).getTime()
    };
    setCurrentDate(date);
  }, [selectedFilters, tintId, setAsSelectedFilter]);
  const dispatch = useDispatch();
  const isAbleToUpdate = useSelector((state) => checkFilterChanges(state.tintEditorPosts));
  const dateDropdownPlaceholder = `All Time - ${Config.monthList[(/* @__PURE__ */ new Date()).getMonth()]} ${(/* @__PURE__ */ new Date()).getDate()}, ${(/* @__PURE__ */ new Date()).getFullYear()}`;
  const groupActionVales = (key) => {
    const rightRequestStatusValues = actionRightsList;
    const rightRequestMatchesValues = selectedFilters[key].filter((el) => rightRequestStatusValues.includes(el));
    const otherValues = selectedFilters[key].filter((el) => {
      return !rightRequestStatusValues.includes(el);
    });
    return {
      right_request_status: rightRequestMatchesValues.length > 0 ? rightRequestMatchesValues : void 0,
      pinned: otherValues.includes(ActionFilterValues.PINNED) ? true : otherValues.includes(ActionFilterValues.NOT_PINNED) ? false : void 0,
      highlighted: otherValues.includes(ActionFilterValues.HIGHLIGHTED) ? true : otherValues.includes(ActionFilterValues.NOT_HIGHLIGHTED) ? false : void 0,
      incentivized: otherValues.includes(ActionFilterValues.INCENTIVIZED) ? true : otherValues.includes(ActionFilterValues.NOT_INCENTIVIZED) ? false : void 0
      // product_tags: otherValues.includes('product_tags'),
    };
  };
  const mapSelectedFilters = () => {
    return Object.keys(selectedFilters).map((key) => {
      return key === FilterGroupTypes.ACTIONS ? groupActionVales(key) : { [key]: selectedFilters[key] };
    }).reduce((a, b) => {
      return __spreadValues(__spreadValues({}, a), b);
    }, {});
  };
  const onSaveFilter = (filterName) => {
    dispatch(postSavedFilters({ tintId, filterName, filters: mapSelectedFilters() })).then(() => {
      toast.success("Filter Added");
      onModalClose();
    }).catch(() => toast.error("Could not save filters"));
  };
  const onUpdateFilter = (filterName, filterId) => {
    dispatch(
      updateSavedFilters({ tintId, savedFilterId: filterId, filterName, filters: mapSelectedFilters() })
    ).then(() => {
      toast.success("Filters Updated");
    }).catch(() => {
      toast.error(() => "Could not update filters");
    });
  };
  const onRemoveFilter = () => {
    dispatch(removeSavedFilters({ tintId, savedFilterId: currentFilter.id })).then(() => {
      onModalClose(true);
      toast.success("Filter Removed");
    }).catch(() => toast.error("Could not remove filters"));
  };
  const onTagChoose = (selectedTags, filterType) => {
    const getValues = selectedTags.map((tag) => tag.value);
    dispatch(selectFilters(getValues, filterType));
  };
  const onActionFilterChoose = (selectedTags, currentTag, filterType) => {
    const getValues = selectedTags.map((tag) => tag.queryParamValue);
    if (currentTag.queryParamValue === ActionFilterValues.PINNED) {
      dispatch(
        selectFilters(
          getValues.filter((el) => el !== ActionFilterValues.NOT_PINNED),
          filterType
        )
      );
      return;
    }
    if (currentTag.queryParamValue === ActionFilterValues.NOT_PINNED) {
      dispatch(
        selectFilters(
          getValues.filter((el) => el !== ActionFilterValues.PINNED),
          filterType
        )
      );
      return;
    }
    if (currentTag.queryParamValue === ActionFilterValues.HIGHLIGHTED) {
      dispatch(
        selectFilters(
          getValues.filter((el) => el !== ActionFilterValues.NOT_HIGHLIGHTED),
          filterType
        )
      );
      return;
    }
    if (currentTag.queryParamValue === ActionFilterValues.NOT_HIGHLIGHTED) {
      dispatch(
        selectFilters(
          getValues.filter((el) => el !== ActionFilterValues.HIGHLIGHTED),
          filterType
        )
      );
      return;
    }
    if (currentTag.queryParamValue === ActionFilterValues.INCENTIVIZED) {
      dispatch(
        selectFilters(
          getValues.filter((el) => el !== ActionFilterValues.NOT_INCENTIVIZED),
          filterType
        )
      );
      return;
    }
    if (currentTag.queryParamValue === ActionFilterValues.NOT_INCENTIVIZED) {
      dispatch(
        selectFilters(
          getValues.filter((el) => el !== ActionFilterValues.INCENTIVIZED),
          filterType
        )
      );
      return;
    }
    dispatch(selectFilters(getValues, filterType));
  };
  return /* @__PURE__ */ React.createElement(
    TintModal,
    {
      isBackDropVisible: false,
      className: `tint-editor-top-bar-filter-modal ${!isSettingsSidebarExpanded ? "tint-editor-top-bar-filter-modal--settings-sidebar-expanded" : ""}`,
      headerTitle: "Filter",
      isOpen: isModalOpen,
      onClose: onModalClose,
      modalHeader: (props) => /* @__PURE__ */ React.createElement(ModalHeaderCounter, __spreadProps(__spreadValues({}, props), { counter: selectedFiltersCount })),
      modalFooter: (props) => /* @__PURE__ */ React.createElement("footer", { className: "tint-modal-footer tint-modal-footer--save-filters", style: { backgroundColor: "#2C2D30" } }, /* @__PURE__ */ React.createElement(
        TintEditorFilterSaveState,
        __spreadValues({
          onSaveClick: onSaveFilter,
          onUpdateClick: onUpdateFilter,
          onRemoveClick: onRemoveFilter,
          filterId: currentFilter && currentFilter.id,
          filterName: currentFilter && currentFilter.attributes.name,
          isAbleToUpdate
        }, props)
      )),
      modalDOMDestination
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__row" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__col tint-editor-filter-group__col--title" }, /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.TITLE, component: "h3" }, "Date")), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__col" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        currentItem: currentDate,
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownDateRangeHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownDateRangeCalendar, __spreadValues({}, props)),
        list: [],
        iconLeft: "fas fa-tag",
        iconRight: "fa fa-caret-down",
        placeholder: dateDropdownPlaceholder,
        buttonType: ButtonType.BUTTON_DATE_RANGE,
        onChangeValue: (dates) => {
          const date = {
            $gte: new Date(dates.value.startDate).toISOString(),
            $lte: new Date(dates.value.endDate).toISOString()
          };
          setCurrentDate(dates);
          dispatch(selectFilters(date, FilterGroupTypes.DATE));
        }
      }
    )))),
    !isTrashState && isSimilarImagesAble && !isGlobalPageState && /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__row" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__col tint-editor-filter-group__col--center tint-editor-filter-group__col--title" }, /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.TITLE }, "Visual Search"), visualSearchImage && /* @__PURE__ */ React.createElement("span", { className: "tint-editor-filter-group__counter" }, "1")), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__col tint-editor-filter-group__col--filters" }, /* @__PURE__ */ React.createElement(VisualSearchFilter, null)))),
    socialFeedList.length > 0 && /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        selectAllTag: {
          icon: faGlobe,
          name: "All Feeds",
          value: TintEditorFilterTagsAllTagValue
        },
        list: socialFeedList,
        headerText: "Social Feeds",
        onTagChoose: (tags) => onTagChoose(tags, FilterGroupTypes.SOCIAL_FEEDS)
      }
    ),
    /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: actionsTags,
        headerText: "Actions",
        onTagChoose: (tags, currentTag) => onActionFilterChoose(tags, currentTag, FilterGroupTypes.ACTIONS)
      }
    ),
    tagList.length > 0 && /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: tagList,
        headerText: "Tags",
        searchEnabled: true,
        onTagChoose: (tags) => onTagChoose(tags, FilterGroupTypes.TAGS),
        onOpenSidebar: onEditTagsClick
      }
    ),
    /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.FILTER_LANGUAGES }, /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: languages,
        headerText: "Languages",
        searchEnabled: true,
        onTagChoose: (lang) => onTagChoose(lang, FilterGroupTypes.LANGUAGE)
      }
    )),
    /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.TAGGING }, /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: imageObjectTags,
        headerText: "Objects",
        searchEnabled: true,
        onTagChoose: (lang) => onTagChoose(lang, FilterGroupTypes.IMAGE_OBJECT)
      }
    )),
    /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: mediaTypesTags,
        headerText: "Media Types",
        onTagChoose: (tags) => onTagChoose(tags, FilterGroupTypes.MEDIA_TYPE)
      }
    ),
    /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.FILTER_RATINGS }, /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: starRatingTags,
        headerText: "Ratings",
        onTagChoose: (tags) => onTagChoose(tags, FilterGroupTypes.RATING)
      }
    )),
    /* @__PURE__ */ React.createElement(
      TintEditorFilterTags,
      {
        list: sentimentTags,
        headerText: "Sentiment",
        onTagChoose: (tags) => onTagChoose(tags, FilterGroupTypes.SENTIMENT)
      }
    )
  );
};
export default React.memo(TintEditorTopBarFilterModal);
