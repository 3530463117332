import React, { useRef } from "react";
import { useSelector } from "react-redux";
import "./dropdown-current-usage-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button } from "../../../button/button";
import ProgressBar from "../../../progress-bar/progress-bar";
import { Config } from "../../../../../utils/config";
import { numberWithCommas } from "../../../../../services/functions/number-format/number-format";
export const isLimitReached = (currentUsageList) => {
  const limits = currentUsageList.filter((e) => e.activeCount + e.activeOnPlan > 0 && e.activeCount >= e.activeOnPlan);
  return limits.length > 0;
};
const DropdownCurrentUsageList = ({
  headerTitle = "Current Usage",
  hasUpgradeButton = true,
  parentRef,
  currentItems
}) => {
  const elementRef = useRef();
  const session = useSelector((state) => state.session);
  const team = session.data.currentTeam;
  const history = useHistory();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const onClick = () => {
    if (team.attributes.self_service) {
      history.push(Config.routePaths.subscribePlan.path);
    } else {
      window.open(Config.url.contactUpgrade, "_blank");
    }
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: elementRef,
      "data-testid": "dropdown-current-usage-list",
      className: `dropdown-current-usage-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--click dropdown-list--wide dropdown-list--with-description`
    },
    /* @__PURE__ */ React.createElement("div", { className: "dropdown-current-usage-list__header" }, /* @__PURE__ */ React.createElement("span", null, headerTitle)),
    /* @__PURE__ */ React.createElement("div", { className: "dropdown-current-usage-list__body" }, currentItems.map((item, i) => {
      return item.activeCount !== void 0 ? /* @__PURE__ */ React.createElement("div", { key: i, className: "dropdown-current-usage-list__element" }, /* @__PURE__ */ React.createElement("span", null, item.customDescription ? item.customDescription : /* @__PURE__ */ React.createElement(React.Fragment, null, "Using ", numberWithCommas(item.activeCount), " ", item.activeOnPlan ? `of ${numberWithCommas(item.activeOnPlan)}` : "", " ", item.name)), item.activeOnPlan && item.activeCount ? /* @__PURE__ */ React.createElement(ProgressBar, { totalProgress: item.activeOnPlan, currentProgress: item.activeCount }) : null) : null;
    })),
    hasUpgradeButton && /* @__PURE__ */ React.createElement("div", { className: "dropdown-current-usage-list__footer" }, /* @__PURE__ */ React.createElement(Button, { text: "Upgrade for More", onClick, size: "full-width", type: "black" }))
  );
};
export default DropdownCurrentUsageList;
DropdownCurrentUsageList.propTypes = {
  currentItems: PropTypes.array,
  parentRef: PropTypes.any
};
