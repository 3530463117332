export const RightRequestsStatusTypes = {
  APPROVED: "approved",
  APPROVAL: "approval",
  DELETED: "deleted",
  ERROR: "error",
  EXPIRED: "expired",
  MISSING: "missing",
  PENDING: "pending",
  REQUESTED: "requested",
  SOFT_APPROVED: "soft_approved",
  TOKEN_ERROR: "token_error"
};
