var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ApiService } from "../../services/api-service/api-service";
import {
  getRequestRightsTemplatesStart,
  getRequestRightsTemplatesSuccess,
  getRequestRightsTemplatesError,
  getRequestRightsAiTemplatesStart,
  getRequestRightsAiTemplatesSuccess,
  getRequestRightsAiTemplatesError,
  patchRequestRightsTemplatesStart,
  patchRequestRightsTemplatesSuccess,
  patchRequestRightsTemplatesError,
  getRequestRightsTermsStart,
  getRequestRightsTermsSuccess,
  getRequestRightsTermsError,
  postRequestRightsError,
  postRequestRightsSuccess,
  postRequestRightsStart,
  deleteRequestRightsTemplateStart,
  deleteRequestRightsTemplateSuccess,
  deleteRequestRightsTemplateError,
  postRequestRightsTemplateError,
  postRequestRightsTemplateSuccess,
  postRequestRightsTemplateStart,
  getRequestRightsTermsAndConditionsStart,
  getRequestRightsTermsAndConditionsSuccess,
  getRequestRightsTermsAndConditionsError,
  updateRequestRightsTermsAndConditionsStart,
  updateRequestRightsTermsAndConditionsSuccess,
  updateRequestRightsTermsAndConditionsError,
  createRequestRightsTermsAndConditionsStart,
  createRequestRightsTermsAndConditionsSuccess,
  createRequestRightsTermsAndConditionsError,
  deleteRequestRightsTermsAndConditionsSuccess,
  deleteRequestRightsTermsAndConditionsStart,
  deleteRequestRightsTermsAndConditionsError
} from "./request-rights.actions";
import { Config } from "../../utils/config";
const getRequestRightsUrl = ({ filterValue, pageSize = Config.defaultPageSize, element }) => {
  return `/right_requests/${element}${pageSize ? `?page[size]=${pageSize}` : `?page[size]=${pageSize}`}${filterValue ? `&filter[q]=${filterValue}` : ""}`;
};
export const postRequestRightsTemplate = ({ template, hashtag, name }) => {
  return (dispatch) => {
    const url = "/right_requests/templates";
    const data = {
      data: {
        type: "right_request_template",
        attributes: {
          name,
          template,
          hashtag
        }
      }
    };
    dispatch(postRequestRightsTemplateStart());
    return ApiService.post(url, data).then((res) => {
      return dispatch(
        postRequestRightsTemplateSuccess({
          data: res.data.data
        })
      );
    }).catch((err) => {
      dispatch(postRequestRightsTemplateError(err));
      throw err;
    });
  };
};
export const postRequestRights = (data) => {
  const _data = {
    data: {
      type: "right_request",
      attributes: __spreadValues({}, data)
    }
  };
  return (dispatch) => {
    const url = "/right_requests";
    dispatch(postRequestRightsStart());
    return ApiService.post(url, _data).then((res) => {
      return dispatch(postRequestRightsSuccess(res.data));
    }).catch((err) => {
      dispatch(postRequestRightsError(err));
      throw err;
    });
  };
};
export const getAiTemplates = ({ post_id }) => {
  const _data = {
    data: {
      type: "right_request_message_suggestion",
      attributes: {
        post_id
      }
    }
  };
  return (dispatch) => {
    dispatch(getRequestRightsAiTemplatesStart());
    return ApiService.post("/right_requests/templates/suggestion", _data).then((res) => {
      return dispatch(
        getRequestRightsAiTemplatesSuccess({
          data: res.data.data,
          links: res.data.links
        })
      );
    }).catch((err) => dispatch(getRequestRightsAiTemplatesError(err)));
  };
};
export const getRequestRightsTemplates = ({ filterValue, pageSize }) => {
  return (dispatch) => {
    dispatch(getRequestRightsTemplatesStart());
    return ApiService.get(
      getRequestRightsUrl({
        filterValue,
        pageSize,
        element: "templates"
      })
    ).then((res) => {
      return dispatch(
        getRequestRightsTemplatesSuccess({
          data: res.data.data,
          links: res.data.links
        })
      );
    }).catch((err) => dispatch(getRequestRightsTemplatesError(err)));
  };
};
export const getRequestRightsTerms = ({ filterValue, pageSize }) => {
  return (dispatch) => {
    dispatch(getRequestRightsTermsStart());
    return ApiService.get(
      getRequestRightsUrl({
        filterValue,
        pageSize,
        element: "terms"
      })
    ).then((res) => {
      return dispatch(
        getRequestRightsTermsSuccess({
          data: res.data.data,
          links: res.data.links
        })
      );
    }).catch((err) => dispatch(getRequestRightsTermsError(err)));
  };
};
export const deleteRequestRightsTemplate = ({ id }) => {
  return (dispatch) => {
    const url = `/right_requests/templates/${id}`;
    dispatch(deleteRequestRightsTemplateStart());
    return ApiService.delete(url).then(() => {
      return dispatch(deleteRequestRightsTemplateSuccess(id));
    }).catch((err) => {
      dispatch(deleteRequestRightsTemplateError(err));
      throw err;
    });
  };
};
export const patchRequestRightsTemplates = ({ name, template, hashtag, templateId }) => {
  const data = {
    data: {
      type: "right_request_template",
      id: templateId,
      attributes: {
        template,
        hashtag,
        name
      }
    }
  };
  return (dispatch) => {
    dispatch(patchRequestRightsTemplatesStart());
    return ApiService.patch(`/right_requests/templates/${templateId}`, data).then((res) => {
      return dispatch(
        patchRequestRightsTemplatesSuccess({
          data: res.data.data
        })
      );
    }).catch((err) => {
      dispatch(patchRequestRightsTemplatesError(err));
      throw err;
    });
  };
};
export const getRequestRightsTermsAndConditions = ({ filters = void 0, paginationUrl = void 0 }) => {
  const _url = filters ? `/right_requests/terms?${filters}&sort=-id` : "/right_requests/terms?sort=-id";
  return (dispatch) => {
    dispatch(getRequestRightsTermsAndConditionsStart());
    return ApiService.get(paginationUrl || _url).then((res) => {
      return dispatch(getRequestRightsTermsAndConditionsSuccess(res.data));
    }).catch((err) => dispatch(getRequestRightsTermsAndConditionsError(err)));
  };
};
export const updateRequestRightsTermsAndConditions = (id, term) => {
  const { text, title, name, document } = term;
  const data = {
    data: {
      type: "right_request_term",
      attributes: {
        text,
        title,
        name,
        document
      }
    }
  };
  return (dispatch) => {
    dispatch(updateRequestRightsTermsAndConditionsStart());
    return ApiService.patch(`/right_requests/terms/${id}`, data).then((res) => {
      return dispatch(updateRequestRightsTermsAndConditionsSuccess(res.data));
    }).catch((err) => {
      dispatch(updateRequestRightsTermsAndConditionsError(err));
    });
  };
};
export const createRequestRightsTermsAndConditions = (terms) => {
  const { text, title, name, document } = terms;
  const data = {
    data: {
      type: "right_request_term",
      attributes: {
        text,
        title,
        name,
        document
      }
    }
  };
  return (dispatch) => {
    dispatch(createRequestRightsTermsAndConditionsStart());
    return ApiService.post("/right_requests/terms", data).then((res) => {
      return dispatch(createRequestRightsTermsAndConditionsSuccess(res.data));
    }).catch((err) => {
      dispatch(createRequestRightsTermsAndConditionsError(err));
    });
  };
};
export const deleteRequestRightsTermsAndCondition = (id) => {
  return (dispatch) => {
    const url = `/right_requests/terms/${id}`;
    dispatch(deleteRequestRightsTermsAndConditionsStart());
    return ApiService.delete(url).then(() => {
      return dispatch(deleteRequestRightsTermsAndConditionsSuccess(id));
    }).catch((err) => dispatch(deleteRequestRightsTermsAndConditionsError(err)));
  };
};
