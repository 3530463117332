import { Config } from "../../../../../../utils/config";
import { capitalize } from "../../../../utils/text-format/text-format";
import { permissions } from "../../../../../../utils/permissions/permissions";
export const SelectAccessType = {
  ALL: "all",
  MANUAL: "manual"
};
export const RolesAccessTypes = {
  TINTS: "tints",
  PERSONALIZATIONS: "personalizations",
  SOCIAL_ACCOUNTS: "accounts",
  COLLECTIONS: "collections",
  EXPERIENCES: "experiences",
  FONTS: "fonts",
  DOMAINS: "domains",
  PRODUCT_ACCOUNTS: "product_accounts",
  PRODUCT_FEEDS: "product_feeds",
  WEBHOOKS: "webhooks",
  OAUTH_APPLICATIONS: "oauth_applications",
  FORMS: "forms",
  MIXES: "mixes"
};
export const getRoleAccesPermissionId = (type) => {
  switch (type) {
    case RolesAccessTypes.TINTS:
      return Config.permissions.newTintAccess;
    case RolesAccessTypes.PERSONALIZATIONS:
      return Config.permissions.newPersonalizations;
    case RolesAccessTypes.COLLECTIONS:
      return Config.permissions.newCollections;
    case RolesAccessTypes.SOCIAL_ACCOUNTS:
      return Config.permissions.newAccounts;
    case RolesAccessTypes.EXPERIENCES:
      return Config.permissions.newExperiences;
    case RolesAccessTypes.FONTS:
      return Config.permissions.newFonts;
    case RolesAccessTypes.DOMAINS:
      return Config.permissions.newDomains;
    case RolesAccessTypes.PRODUCT_ACCOUNTS:
      return Config.permissions.newProductAccounts;
    case RolesAccessTypes.PRODUCT_FEEDS:
      return Config.permissions.newProductFeeds;
    case RolesAccessTypes.WEBHOOKS:
      return Config.permissions.newWebhooks;
    case RolesAccessTypes.OAUTH_APPLICATIONS:
      return Config.permissions.newOauthApplications;
    case RolesAccessTypes.FORMS:
      return Config.permissions.newForms;
    case RolesAccessTypes.MIXES:
      return Config.permissions.newMixes;
    default:
      return "";
  }
};
export const getRoleAccessLabelName = (type) => {
  switch (type) {
    case RolesAccessTypes.TINTS:
      return "TINTs";
    case RolesAccessTypes.PERSONALIZATIONS:
      return "Personalizations";
    case RolesAccessTypes.COLLECTIONS:
      return "Collections";
    case RolesAccessTypes.SOCIAL_ACCOUNTS:
      return "Accounts";
    case RolesAccessTypes.EXPERIENCES:
      return "Experiences";
    case RolesAccessTypes.FONTS:
      return "Fonts";
    case RolesAccessTypes.DOMAINS:
      return "Domains";
    case RolesAccessTypes.PRODUCT_ACCOUNTS:
      return "Product Accounts";
    case RolesAccessTypes.PRODUCT_FEEDS:
      return "Product Feeds";
    case RolesAccessTypes.WEBHOOKS:
      return "Webhooks";
    case RolesAccessTypes.OAUTH_APPLICATIONS:
      return "OAuth Applications";
    case RolesAccessTypes.FORMS:
      return "Forms";
    case RolesAccessTypes.MIXES:
      return "Mixes";
    default:
      return "";
  }
};
export const convertTypeName = (type) => {
  const name = Config.iconsTypes[type] || type.replace(/_/g, " ");
  return capitalize(name);
};
export const mapIdsToNumbers = (ids) => ids && Array.isArray(ids) ? ids.map((id) => Number(id)) : void 0;
export const filterAccessAttributes = ({ selectedAccess, selectedIds, unselectedIds }) => {
  return selectedAccess === SelectAccessType.ALL ? SelectAccessType.ALL : filterObject({ "+": mapIdsToNumbers(selectedIds), "-": mapIdsToNumbers(unselectedIds) });
};
export const filterObject = (object) => {
  Object.keys(object).forEach(
    (key) => (object[key] === void 0 || object[key] !== null && object[key].constructor === Object && Object.keys(object[key]).length === 0) && delete object[key]
  );
  return object;
};
export const filterSelectedCheckboxes = (currentItems, item, isSelected) => {
  let permissionList = [...currentItems, item];
  return !isSelected ? permissionList.filter((permission) => permission !== "all" && permission !== item) : permissionList;
};
export const addNewSelectedCheckboxes = (currentItems, newItems) => {
  return currentItems.concat(newItems.filter((e) => !currentItems.some((r) => r.id === e.id)));
};
export const replaceCharInText = (text, charToReplace = " ") => {
  return text ? text.replace(/_/g, charToReplace) : "";
};
const ALL_PERMISSIONS = Object.values(permissions);
const FUTURE_ACCESS_REGEX = /new/;
export const filterRolesAllPermissions = (permissions2 = ALL_PERMISSIONS) => {
  if (!Array.isArray(permissions2)) return [];
  return permissions2.reduce(
    (obj, e) => {
      obj[FUTURE_ACCESS_REGEX.test(e) ? "access" : "permissions"].push(e);
      return obj;
    },
    { permissions: [], access: [] }
  );
};
