var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { createPrefixedActionName, createRoutine } from "../../services/functions/redux-routines/redux-routines";
import ACCOUNT from "../consts";
import { ApiService } from "../../services/api-service/api-service";
import {
  getPersonalizationError,
  getPersonalizationsError,
  getPersonalizationsStart,
  getPersonalizationsSuccess,
  getPersonalizationStart,
  getPersonalizationSuccess,
  getTintError,
  getTintsError,
  getTintsStart,
  getTintsSuccess,
  getTintStart,
  getTintSuccess
} from "./account.actions";
export const ACTIONS = {
  GET_TINTS: createRoutine(createPrefixedActionName(ACCOUNT, "GET_TINTS")),
  GET_PERSONALIZATION: createRoutine(createPrefixedActionName(ACCOUNT, "GET_PERSONALIZATION"))
};
export const getTints = (tintsUrl) => {
  return (dispatch) => {
    dispatch(getTintsStart());
    return ApiService.get(tintsUrl || "/tints").then((res) => {
      const tints = res.data.data.map((tint) => {
        var _a, _b, _c;
        return __spreadValues(__spreadValues({}, tint), {
          socialFeedsCount: (_c = (_b = (_a = tint.relationships) == null ? void 0 : _a.social_feeds) == null ? void 0 : _b.meta) == null ? void 0 : _c.total
        });
      });
      dispatch(
        getTintsSuccess({
          data: tints,
          links: res.data.links,
          included: res.data.included
        })
      );
    }).catch((err) => dispatch(getTintsError(err)));
  };
};
export const getTint = (tintId, includedParams = "", forceNoTeamPrefix = false) => {
  return (dispatch) => {
    dispatch(getTintStart());
    return ApiService.get(`/tints/${tintId}${includedParams}`, { forceNoTeamPrefix }).then((res) => {
      dispatch(getTintSuccess(res.data));
      return res;
    }).catch((err) => dispatch(getTintError(err)));
  };
};
export const getPersonalizations = ({ teamId, status, filterValue, type, tags, personalizationsUrl }) => {
  return (dispatch) => {
    dispatch(getPersonalizationsStart());
    return ApiService.get(
      personalizationsUrl || `/teams/${teamId}/personalizations?sort=status,-id&${status !== void 0 && status !== "" ? `filter[status]=${status}&` : ""}${filterValue !== void 0 && filterValue !== "" ? `filter[q]=${filterValue}&` : ""}${type !== void 0 && type !== "" ? `filter[type]=${type}&` : ""}${tags !== void 0 && tags !== "" ? `filter[tags]=${tags}&` : ""}`
    ).then((res) => {
      dispatch(getPersonalizationsSuccess(res.data));
    }).catch((err) => {
      dispatch(getPersonalizationsError(err));
    });
  };
};
export const getPersonalization = ({ id }) => {
  return (dispatch) => {
    dispatch(getPersonalizationStart());
    return ApiService.get(`/personalizations/${id}`).then((res) => {
      dispatch(getPersonalizationSuccess(res.data.data));
      return res.data.data;
    }).catch((err) => {
      dispatch(getPersonalizationError(err));
    });
  };
};
