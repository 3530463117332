var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./select-display-options-sidebar.sass";
import SelectTintWrapper from "../../../../../../../../components/wrappers/select-tint-wrapper/select-tint-wrapper";
import SelectPersonalizationWrapper from "../../../../../../../../components/wrappers/select-personalization-wrapper/select-personalization-wrapper";
import { useSelector } from "react-redux";
import { PersonalizationTypesToReadableText } from "../../../../../../../../model/personalizations.model";
import { THEMES } from "../../../../../../schemas/themes";
import { getSlugName } from "../../../../../../../../reducers/account";
const SelectDisplayOptionsSidebar = ({
  onChangeData,
  data,
  tintDetails,
  hideTintDropdown = false,
  hidePersonalizationDropdown = false
}) => {
  var _a;
  const slugName = useSelector(getSlugName);
  const currentPersonalization = useSelector((state) => {
    var _a2;
    return (_a2 = state.account) == null ? void 0 : _a2.personalization;
  });
  const personalizations = useSelector((state) => state.account.personalizations.data);
  const [displaySettings, setDisplaySettings] = useState({
    personalizationId: (data == null ? void 0 : data.personalizationId) || (tintDetails == null ? void 0 : tintDetails.defaultPersonalization),
    tintId: (data == null ? void 0 : data.tintId) || (tintDetails == null ? void 0 : tintDetails.tintId),
    slugName: (data == null ? void 0 : data.slugName) || (tintDetails == null ? void 0 : tintDetails.slugName) || slugName,
    type: data == null ? void 0 : data.type,
    theme: (data == null ? void 0 : data.theme) || ((_a = currentPersonalization == null ? void 0 : currentPersonalization.attributes) == null ? void 0 : _a.theme)
  });
  useEffect(() => {
    setDisplaySettings((s) => {
      var _a2, _b;
      return __spreadProps(__spreadValues({}, s), {
        theme: (_a2 = currentPersonalization == null ? void 0 : currentPersonalization.attributes) == null ? void 0 : _a2.theme,
        type: (_b = currentPersonalization == null ? void 0 : currentPersonalization.attributes) == null ? void 0 : _b.type
      });
    });
  }, [currentPersonalization]);
  useEffect(() => {
    tintDetails && personalizations && (tintDetails == null ? void 0 : tintDetails.defaultPersonalization) && onPersonalizationChange({ value: tintDetails == null ? void 0 : tintDetails.defaultPersonalization });
  }, [personalizations, tintDetails]);
  const onPersonalizationChange = ({ value }) => {
    const currentPersonalization2 = personalizations == null ? void 0 : personalizations.find((personalization) => (personalization == null ? void 0 : personalization.id) === value);
    setDisplaySettings((s) => {
      var _a2, _b;
      return __spreadProps(__spreadValues({}, s), {
        personalizationId: value,
        type: (_a2 = currentPersonalization2 == null ? void 0 : currentPersonalization2.attributes) == null ? void 0 : _a2.type,
        theme: (_b = currentPersonalization2 == null ? void 0 : currentPersonalization2.attributes) == null ? void 0 : _b.theme
      });
    });
  };
  const onTintChange = (data2) => {
    const { slugName: slugName2, value } = data2;
    setDisplaySettings((s) => __spreadProps(__spreadValues({}, s), { tintId: value, slugName: slugName2 }));
  };
  useEffect(() => {
    onChangeData({
      personalizationId: displaySettings.personalizationId || (data == null ? void 0 : data.personalizationId) || (tintDetails == null ? void 0 : tintDetails.defaultPersonalization),
      tintId: displaySettings.tintId || (data == null ? void 0 : data.tintId) || (tintDetails == null ? void 0 : tintDetails.tintId),
      slugName: displaySettings.slugName || (data == null ? void 0 : data.slugName) || (tintDetails == null ? void 0 : tintDetails.slugName),
      isValid: displaySettings.tintId && displaySettings.personalizationId,
      type: displaySettings.type,
      theme: displaySettings.theme
    });
  }, [displaySettings]);
  useEffect(() => {
    return () => {
      setDisplaySettings(void 0);
    };
  }, []);
  const replaceName = (name) => {
    var _a2;
    const flatThemes = THEMES.flat();
    return ((_a2 = flatThemes.find((theme) => theme.key === name)) == null ? void 0 : _a2.name) || "";
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-select-display-options-sidebar" }, /* @__PURE__ */ React.createElement("p", { className: "tint-select-display-options-sidebar__description tint-sidebar-label" }, hideTintDropdown && "Select the Personalization that you wish to display:", hidePersonalizationDropdown && "Select the TINT Board that you wish to display:"), /* @__PURE__ */ React.createElement("div", { style: hideTintDropdown ? { display: "none" } : void 0 }, /* @__PURE__ */ React.createElement(SelectTintWrapper, { onChange: onTintChange, currentItemId: displaySettings == null ? void 0 : displaySettings.tintId })), /* @__PURE__ */ React.createElement("div", { style: hidePersonalizationDropdown ? { display: "none" } : void 0 }, /* @__PURE__ */ React.createElement(
    SelectPersonalizationWrapper,
    {
      onChange: onPersonalizationChange,
      currentItemId: displaySettings.personalizationId
    }
  )), (displaySettings == null ? void 0 : displaySettings.theme) && (displaySettings == null ? void 0 : displaySettings.type) && (displaySettings == null ? void 0 : displaySettings.personalizationId) && (displaySettings == null ? void 0 : displaySettings.tintId) && /* @__PURE__ */ React.createElement("div", { className: "account-data-display" }, /* @__PURE__ */ React.createElement("div", { className: "account-data-display__type" }, /* @__PURE__ */ React.createElement("span", null, "Type"), /* @__PURE__ */ React.createElement("p", null, PersonalizationTypesToReadableText[displaySettings.type])), /* @__PURE__ */ React.createElement("div", { className: "account-data-display__theme" }, /* @__PURE__ */ React.createElement("span", null, "Theme"), /* @__PURE__ */ React.createElement("p", null, replaceName(displaySettings.theme)))));
};
export default React.memo(SelectDisplayOptionsSidebar);
