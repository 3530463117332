var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DropdownInfiniteList from "../../interface/dropdown/lists/infinite-list/dropdown-infinite-list";
import { DropdownHeader } from "../../interface/dropdown/button/header/dropdown-header";
import { InputGroup } from "../../../components/interface/inputs/input-group/input-group";
import DropdownButton, { ButtonType, DropdownButtonType } from "../../interface/dropdown/button/dropdown-button";
import { toast } from "react-toastify";
import { mapDropdownList, mapDropdownListItem } from "../utils/utils";
import { getTintsUrlWithParams } from "../../../feature/tint-editor/utils/filter-helper/filter-helper";
import { getTint, getTints } from "../../../actions/account/account.fat-actions";
const SelectTintWrapper = (_a) => {
  var _b = _a, {
    currentItemId,
    currentItemSlug,
    onChange,
    includedParams,
    onLoadIncludedItems,
    withInputGroup = true,
    isDefaultOption = false,
    defaultOptionTitle = "None"
  } = _b, props = __objRest(_b, [
    "currentItemId",
    "currentItemSlug",
    "onChange",
    "includedParams",
    "onLoadIncludedItems",
    "withInputGroup",
    "isDefaultOption",
    "defaultOptionTitle"
  ]);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState();
  const [contentList, setContentList] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const currentTints = useSelector((state) => {
    var _a2;
    return (_a2 = state.account.tints) == null ? void 0 : _a2.data;
  });
  const currentTintsIncludedItems = useSelector((state) => state.account.tints.included);
  const isAbleToLoad = useSelector((state) => {
    var _a2;
    return (_a2 = state.account.tints.links) == null ? void 0 : _a2.next;
  });
  const currentTint = useSelector((state) => {
    var _a2;
    return (_a2 = state.account) == null ? void 0 : _a2.tint;
  });
  const setIncludedItems = (res) => {
    var _a2, _b2;
    if (includedParams)
      onLoadIncludedItems && onLoadIncludedItems(__spreadValues({
        tintList: [...currentTints, res.data.data]
      }, currentTintsIncludedItems ? {
        includedItems: ((_a2 = res.data) == null ? void 0 : _a2.included) ? [...currentTintsIncludedItems, (_b2 = res.data) == null ? void 0 : _b2.included] : currentTintsIncludedItems
      } : { includedItems: [] }));
  };
  const getTintBySlug = () => {
    getTintById(currentItemSlug, true);
  };
  const getTintById = (slugName, forceNoTeamPrefix) => {
    dispatch(getTint(slugName ? slugName : currentItemId, includedParams = "?include=social_feeds", forceNoTeamPrefix)).then((res) => {
      var _a2, _b2, _c, _d, _e, _f, _g, _h;
      setCurrentItem(mapDropdownListItem(res.data.data));
      setIncludedItems(res);
      onChange == null ? void 0 : onChange({
        value: (_b2 = (_a2 = res == null ? void 0 : res.data) == null ? void 0 : _a2.data) == null ? void 0 : _b2.id,
        slugName: (_e = (_d = (_c = res == null ? void 0 : res.data) == null ? void 0 : _c.data) == null ? void 0 : _d.attributes) == null ? void 0 : _e.slug,
        name: (_h = (_g = (_f = res == null ? void 0 : res.data) == null ? void 0 : _f.data) == null ? void 0 : _g.attributes) == null ? void 0 : _h.name
      });
    }).catch(() => {
      toast("Cannot fetch TINT");
    });
  };
  const findCurrentItem = () => {
    if (currentItemSlug) {
      return currentTints.find((item) => {
        var _a2;
        return ((_a2 = item == null ? void 0 : item.attributes) == null ? void 0 : _a2.slug) === currentItemSlug;
      });
    }
    if (currentItemId) {
      return currentTints.find((item) => (item == null ? void 0 : item.id) === currentItemId);
    }
  };
  const getCurrentItem = () => {
    if (currentTints) {
      const item = findCurrentItem();
      if (item) {
        setCurrentItem(mapDropdownListItem(item));
        onLoadIncludedItems && onLoadIncludedItems({
          tintList: currentTints,
          includedItems: currentTintsIncludedItems
        });
      } else if (currentItemSlug && !currentItemId) {
        getTintBySlug();
      } else if (currentItemId) {
        getTintById();
      }
    }
  };
  useEffect(() => {
    (!currentTint || currentTint.id !== currentItemId) && currentItemId && getTintById(currentItemId);
  }, []);
  useEffect(() => {
    if (filterValue !== void 0) {
      setContentList([]);
      dispatch(
        getTints(
          getTintsUrlWithParams(__spreadValues(__spreadValues(__spreadValues({}, includedParams ? { include: includedParams } : {}), filterValue ? { filterValue } : {}), { isPrivate: false }))
        )
      ).catch(() => toast("Cannot fetch TINTs"));
    }
  }, [filterValue]);
  useEffect(() => {
    dispatch(
      getTints(
        getTintsUrlWithParams(__spreadValues({}, includedParams ? { include: includedParams, isPrivate: false } : { isPrivate: false }))
      )
    );
  }, []);
  useEffect(() => {
    if (currentTints && !(contentList == null ? void 0 : contentList.find((cl) => {
      var _a2;
      return (cl == null ? void 0 : cl.id) === ((_a2 = currentTints[0]) == null ? void 0 : _a2.id);
    })))
      if (!Array.isArray(currentTints)) {
        setContentList([...contentList, currentTints]);
      } else {
        setContentList([...contentList, ...currentTints]);
      }
    getCurrentItem();
  }, [currentTints]);
  const onChangeTint = (data) => {
    onChange == null ? void 0 : onChange(data);
    if (data) {
      getTintById(data.value);
    }
  };
  const onLoadData = () => dispatch(getTints(isAbleToLoad));
  const onInputChange = (query) => setFilterValue(query);
  const renderDropdown = () => {
    return /* @__PURE__ */ React.createElement(
      DropdownButton,
      __spreadValues({
        currentItem,
        dropdownType: DropdownButtonType.SEARCH_INPUT,
        buttonType: ButtonType.BUTTON_SORT,
        dropdownHeader: (props2) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props2)),
        dropdownList: (props2) => /* @__PURE__ */ React.createElement(
          DropdownInfiniteList,
          __spreadProps(__spreadValues({}, props2), {
            onLoadData,
            isAbleToLoad: contentList.length >= 25 && isAbleToLoad,
            onInputChange: (e) => onInputChange(e),
            isDefaultOption,
            defaultOptionTitle,
            placeholder: "Select TINT"
          })
        ),
        list: mapDropdownList(contentList, mapDropdownListItem),
        placeholder: "Select TINT",
        iconRight: "fa fa-caret-down",
        onChangeValue: onChangeTint
      }, props)
    );
  };
  return withInputGroup ? /* @__PURE__ */ React.createElement(InputGroup, { inputGroupIcon: "fas fa-portrait", label: "Select TINT" }, renderDropdown()) : renderDropdown();
};
export default SelectTintWrapper;
