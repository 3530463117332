var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EditSocialFeedCounterContent } from "./edit/edit-social-feed-counter-content";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import SocialFeedCounter from "./component/social-feed-counter";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditSocialFeedCounterStyles } from "./edit/edit-social-feed-counter-styles";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import {
  getMetrics,
  getSocialFeeds,
  subscribeOnWebsocket
} from "../../../../../../services/functions/url-config/url-config";
import PropTypes from "prop-types";
import { SocialAccountTypeToReadableText } from "../../../../../../model/social-account-type.model";
class SocialFeedCounterSection extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "isDataChanged", (prevProps) => {
      return JSON.stringify(this.props.data.content.endDate) !== JSON.stringify(prevProps.data.content.endDate) || JSON.stringify(this.props.data.content.startDate) !== JSON.stringify(prevProps.data.content.startDate) || JSON.stringify(this.props.data.content.slugList) !== JSON.stringify(prevProps.data.content.slugList);
    });
    __publicField(this, "listenForWebSocket", () => {
      if (this.props.data.content.slugList[0] && this.props.data.content.slugList[0].slugId === void 0) return;
      for (const element of this.props.data.content.slugList) {
        if (typeof element.slugId !== "undefined") {
          this.subscribeOnWS(element.slugId, element.socialFeedId);
        }
      }
    });
    __publicField(this, "getSocialFeedsCount", () => {
      const socialFeedsId = this.props.data.content.slugList.map((e) => e.socialFeedId).filter(Boolean);
      if (socialFeedsId.length > 0) {
        getMetrics(socialFeedsId, this.props.data.content.startDate, this.props.data.content.endDate).then((response) => {
          var _a;
          this.setState({
            count: (_a = response.data.data[0]) == null ? void 0 : _a.attributes.count,
            isLoading: false
          });
        });
      }
    });
    __publicField(this, "onStopCounter", () => {
      this.props.onContentUpdate({
        isCountingEnd: true,
        count: this.state.count
      });
    });
    this.state = {
      count: this.props.data.content.count,
      slugList: this.props.data.content.slugList,
      customList: [],
      tintSlugOptions: [],
      socialFeedOptions: [],
      isLoading: true
    };
  }
  componentDidUpdate(prevProps) {
    if (!this.props.data.content.isCountingEnd && this.isDataChanged(prevProps)) {
      this.getSocialFeedsCount();
    }
  }
  componentDidMount() {
    if (!this.props.data.content.isCountingEnd && this.props.isPreviewMode) {
      this.listenForWebSocket();
    } else {
      this.getCurrentDropdownOptions();
    }
    this.getSocialFeedsCount();
  }
  subscribeOnWS(tintId, socialFeedId) {
    subscribeOnWebsocket(tintId, (res) => {
      if (res.social_feed_id && res.social_feed_id.toString() === socialFeedId) {
        this.getSocialFeedsCount();
      }
    });
  }
  getCurrentDropdownOptions() {
    getSocialFeeds().then((res) => {
      const mapSocialFeeds = res.data.included ? res.data.included.map((item) => {
        const {
          attributes: { name, source }
        } = item;
        return {
          value: item.id,
          label: name || source,
          name: `${name || source} (${SocialAccountTypeToReadableText[source] || source})`
        };
      }) : [];
      const mapTints = res.data.data.map((tint) => {
        return {
          value: tint.id,
          name: tint.attributes.slug,
          socialFeeds: tint.relationships.social_feeds.data.map((socialFeed) => __spreadValues({}, mapSocialFeeds.find((item) => item.value === socialFeed.id)))
        };
      });
      this.setState({
        tintSlugOptions: mapTints,
        socialFeedOptions: mapSocialFeeds,
        isLoading: false
      });
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        "data-testid": "socialFeedCounterSection",
        className: `tint-page-section tint-page-section--social-feed-counter ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement("div", { className: "tint-social-feed-counter" }, /* @__PURE__ */ React.createElement(
        SocialFeedCounter,
        {
          isCountingEnd: this.props.data.content.isCountingEnd,
          count: this.state.count,
          style: this.props.data.styles,
          title: this.props.getTranslation(this.props.data.content.counterLabelText),
          baseClass: this.props.baseClass
        }
      )),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit counter" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditSocialFeedCounterContent,
          {
            onTranslationUpdate: this.props.onTranslationUpdate,
            getTranslation: this.props.getTranslation,
            count: this.state.count,
            tintSlugOptions: this.state.tintSlugOptions,
            data: this.props.data,
            dataUpdate: this.props.onContentUpdate,
            onStopCounter: this.onStopCounter
          }
        ), /* @__PURE__ */ React.createElement(
          EditSocialFeedCounterStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate,
            dataUpdate: this.props.onContentUpdate
          }
        )))
      )
    );
  }
}
export default PageSectionHoc(SocialFeedCounterSection);
SocialFeedCounterSection.propTypes = {
  count: PropTypes.number,
  tintSlugOptions: PropTypes.array,
  socialFeedOptions: PropTypes.array,
  data: PropTypes.shape({
    content: PropTypes.shape({
      slugList: PropTypes.array,
      counterLabelText: PropTypes.any,
      startDate: PropTypes.any,
      endDate: PropTypes.any
    }),
    styles: PropTypes.shape({
      backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      sectionPadding: PropTypes.string,
      counterBackgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      counterTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      counterLabelTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  }),
  onChangeData: PropTypes.func,
  dataUpdate: PropTypes.func,
  onStopCounter: PropTypes.func
};
