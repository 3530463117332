import React from "react";
import PropTypes from "prop-types";
import "./status-error.sass";
const StatusError = ({ error }) => {
  return error ? /* @__PURE__ */ React.createElement("div", { className: "status-error" }, error) : null;
};
StatusError.propTypes = {
  error: PropTypes.string
};
export default StatusError;
