var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/product-feeds/add-product-feeds-modal.actions";
export const MODAL_TYPE = {
  FACEBOOK: "facebook",
  MAGENTO: "magento",
  FTP: "ftp",
  SFTP: "sftp",
  SHOPIFY: "shopify",
  WOOCOMMERCE: "woocommerce",
  BIGCOMMERCE: "bigcommerce",
  CSV: "csv",
  CUSTOM: "custom",
  SALESFORCE_COMMERCE_CLOUD: "salesforce_commerce_cloud",
  HTTP: "http",
  AWS: "aws_s3",
  BAZAARVOICE: "bazaarvoice"
};
export const MODAL_STEPS = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third"
};
export const initialState = {
  opened: false,
  step: void 0,
  type: ""
};
export const addProductFeedsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        opened: true,
        step: MODAL_STEPS.FIRST,
        type: ""
      });
    case ACTIONS.UPDATE_MODAL:
      return __spreadValues(__spreadValues({}, state), action.payload);
    case ACTIONS.CLOSE_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        opened: false,
        step: void 0,
        type: ""
      });
    default:
      return state;
  }
};
export const selectCurrentActiveProductFeedType = (state) => state.type;
export const selectCurrentActiveProductFeedStep = (state) => state.step;
