var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./tint-editor-modal.sass";
import { useDispatch, useSelector } from "react-redux";
import { changeModalPost, closeModal } from "../../../actions/tint-editor.actions";
import TintEditorModalHeader from "./header/tint-editor-modal-header";
import TintModal from "../../../../../components/modal/modal";
import TintButtonIcon, { TintButtonIconTypes } from "../../../../../components/interface/button-icon/button-icon";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import FeedCardIcons from "../feed-card-icons/feed-card-icons";
import StatusButton, { POST_STATUS } from "../status-button/status-button";
import {
  getNextPost,
  getPost,
  getPrevPost,
  getProducts,
  getSocialFeed,
  isTagsListModalPostOpen
} from "../../../reducers/posts.reducer";
import ProductsWrapper from "./ecommerce/products-wrapper/products-wrapper";
import TintImageCarousel from "./image-carousel/image-carousel";
import StarRating from "../star-rating/star-rating";
import Tooltip from "../../../../transform-ui/components/tooltip/tooltip";
import { SocialFeedsSource } from "../../../../../types/social-feeds";
import useKeyDown from "../../../../../components/hooks/use-key-down/use-key-down";
import { keyCodes } from "../../../../../components/hooks/use-key-down/keyCodes";
import { openPostTagList } from "../../../actions/posts.actions";
export const TintEditorModalDisplayType = {
  HORIZONTAL: "horizontal",
  INIT: "init",
  VERTICAL: "vertical"
};
const TintEditorModal = () => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const dispatch = useDispatch();
  const postId = useSelector((state) => {
    var _a2, _b2;
    return (_b2 = (_a2 = state.tintEditor) == null ? void 0 : _a2.modal) == null ? void 0 : _b2.postId;
  });
  const post = useSelector((state) => getPost(state.tintEditorPosts, postId));
  const products = useSelector((state) => getProducts(state.tintEditorPosts, postId)) || [];
  const nextPost = useSelector((state) => getNextPost(state.tintEditorPosts, postId));
  const prevPost = useSelector((state) => getPrevPost(state.tintEditorPosts, postId));
  const socialFeed = useSelector((state) => getSocialFeed(state.tintEditorPosts, postId));
  const isPostTagListModalOpen = useSelector(isTagsListModalPostOpen);
  const [nextPostAnimation, setNextPostAnimation] = useState(false);
  const [prevPostAnimation, setPrevPostAnimation] = useState(false);
  const carouselDelay = 600;
  const modalDisplayType = useSelector((state) => state.tintEditor.modalType || TintEditorModalDisplayType.INIT);
  const key = useKeyDown();
  const onKeyDown = (key2) => {
    if (!key2) return;
    switch (key2) {
      case keyCodes.escape:
        isPostTagListModalOpen && dispatch(openPostTagList(void 0));
        break;
      case keyCodes.arrowLeft:
      case keyCodes.arrowDown:
        !isPostTagListModalOpen && !prevPostAnimation && !nextPostAnimation && onPrevPostClick();
        break;
      case keyCodes.arrowRight:
      case keyCodes.arrowUp:
        !isPostTagListModalOpen && !prevPostAnimation && !nextPostAnimation && onNextPostClick();
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    onKeyDown(key == null ? void 0 : key.keyDown);
  }, [key]);
  const onNextPostClick = () => {
    setNextPostAnimation(true);
    dispatch(changeModalPost(nextPost == null ? void 0 : nextPost.id));
    setTimeout(() => {
      setNextPostAnimation(false);
    }, carouselDelay);
  };
  const onPrevPostClick = () => {
    setPrevPostAnimation(true);
    dispatch(changeModalPost(prevPost == null ? void 0 : prevPost.id));
    setTimeout(() => {
      setPrevPostAnimation(false);
    }, carouselDelay);
  };
  return /* @__PURE__ */ React.createElement(
    TintModal,
    {
      blockESCKey: isPostTagListModalOpen,
      className: `tint-modal--tint-editor ${modalDisplayType}`,
      modalHeader: (props) => /* @__PURE__ */ React.createElement(TintEditorModalHeader, __spreadProps(__spreadValues({}, props), { post, displayType: modalDisplayType, socialFeed })),
      isOpen: true,
      onClose: () => {
        !isPostTagListModalOpen && dispatch(closeModal());
      }
    },
    post && /* @__PURE__ */ React.createElement("div", { className: "tint-editor-modal" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-modal__thumb" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-modal__wrapper" }, /* @__PURE__ */ React.createElement(
      TintImageCarousel,
      {
        delay: carouselDelay,
        startNextAnimation: nextPostAnimation,
        startPrevAnimation: prevPostAnimation,
        currentPost: post,
        prevPost,
        nextPost
      }
    ))), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-modal__actions" }, /* @__PURE__ */ React.createElement(FeedCardIcons, { post, tintId: (_c = (_b = (_a = post == null ? void 0 : post.relationships) == null ? void 0 : _a.tint) == null ? void 0 : _b.data) == null ? void 0 : _c.id }), /* @__PURE__ */ React.createElement(
      Tooltip,
      {
        style: { left: "-35px", bottom: "auto", top: "-36px", height: "30px", width: "110px", zIndex: "1" },
        component: /* @__PURE__ */ React.createElement(StatusButton, { status: (_d = post == null ? void 0 : post.attributes) == null ? void 0 : _d.status, postId: post == null ? void 0 : post.id }),
        text: ((_e = post == null ? void 0 : post.attributes) == null ? void 0 : _e.status) !== POST_STATUS.PUBLIC ? "Make visible" : "Make private"
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-modal__content" }, ((_f = post.attributes) == null ? void 0 : _f.rating) && /* @__PURE__ */ React.createElement(StarRating, { rating: post.attributes.rating }), ((_g = post == null ? void 0 : post.attributes) == null ? void 0 : _g.title) && /* @__PURE__ */ React.createElement("p", { className: "tint-editor-modal__title" }, !((_h = post == null ? void 0 : post.attributes) == null ? void 0 : _h.url) ? post.attributes.title : /* @__PURE__ */ React.createElement("a", { href: (_i = post == null ? void 0 : post.attributes) == null ? void 0 : _i.url, target: "_blank", rel: "noopener noreferrer" }, post.attributes.title)), post.attributes.type !== SocialFeedsSource.RSS && post.attributes.type !== SocialFeedsSource.GOOGLE_NEWS && post.attributes.type !== SocialFeedsSource.USER_REVIEW && ((_j = post == null ? void 0 : post.attributes) == null ? void 0 : _j.text) && /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("a", { href: (_k = post == null ? void 0 : post.attributes) == null ? void 0 : _k.url, target: "_blank", rel: "noopener noreferrer" }, post.attributes.text)), products.length > 0 && /* @__PURE__ */ React.createElement(ProductsWrapper, { productList: products })), /* @__PURE__ */ React.createElement("div", { className: "modal-navigation" }, /* @__PURE__ */ React.createElement("div", { className: "modal-navigation--left" }, /* @__PURE__ */ React.createElement(
      TintButtonIcon,
      {
        iconSize: 20,
        isDisabled: nextPostAnimation || prevPostAnimation,
        icon: faAngleLeft,
        type: TintButtonIconTypes.LIGHT,
        onClick: onPrevPostClick
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "modal-navigation--right" }, /* @__PURE__ */ React.createElement(
      TintButtonIcon,
      {
        iconSize: 20,
        isDisabled: nextPostAnimation || prevPostAnimation,
        icon: faAngleRight,
        type: TintButtonIconTypes.LIGHT,
        onClick: onNextPostClick
      }
    ))))
  );
};
export default TintEditorModal;
