var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EXPERIENCE_ANALYTICS_TYPE } from "../public/js/react/model/experience-analytics-type.model";
export const TintPagesScriptsHOC = (PassedComponent) => {
  class TintPagesScripts extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "createAnalyticsScript", (analyticsType, analyticsId) => {
        switch (analyticsType) {
          case EXPERIENCE_ANALYTICS_TYPE.google_analytics:
            this.addGoogleAnalyticsScript(analyticsId);
            break;
          case EXPERIENCE_ANALYTICS_TYPE.google_tag_manager:
            this.addGoogleTagManagerScript(analyticsId);
            break;
          default:
            return;
        }
      });
      __publicField(this, "createFavIconLink", (url) => {
        const favIconLink = document.createElement("link");
        favIconLink.href = url;
        favIconLink.rel = "icon";
        favIconLink.type = "image/png";
        document.head.appendChild(favIconLink);
      });
      __publicField(this, "createNoIndexMeta", () => {
        const favIconLink = document.createElement("meta");
        favIconLink.name = "robots";
        favIconLink.content = "noindex";
        document.head.appendChild(favIconLink);
      });
      __publicField(this, "createCanonicalLink", (url) => {
        const canonicalLink = document.createElement("link");
        canonicalLink.href = url;
        document.head.appendChild(canonicalLink);
      });
    }
    addGoogleTagManagerScript(googleAccountId) {
      const googleTagManagerScript = document.createElement("script");
      googleTagManagerScript.async = true;
      googleTagManagerScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${googleAccountId}')`;
      document.head.prepend(googleTagManagerScript);
      const googleTagManagerNoScript = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleAccountId}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style = "display:none;visibility:hidden";
      googleTagManagerNoScript.appendChild(iframe);
      document.body.prepend(googleTagManagerNoScript);
    }
    addGoogleAnalyticsScript(googleAccountId) {
      const googleTagManagerScript = document.createElement("script");
      googleTagManagerScript.async = true;
      googleTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAccountId}`;
      const googleAnalyticsScript = document.createElement("script");
      googleAnalyticsScript.text = `window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${googleAccountId}');`;
      document.body.appendChild(googleTagManagerScript);
      document.body.appendChild(googleAnalyticsScript);
    }
    render() {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        PassedComponent,
        __spreadProps(__spreadValues({}, this.props), {
          createAnalyticsScript: this.createAnalyticsScript,
          createFavIconLink: this.createFavIconLink,
          createNoIndexMeta: this.createNoIndexMeta,
          createCanonicalLink: this.createCanonicalLink
        })
      ));
    }
  }
  return TintPagesScripts;
};
