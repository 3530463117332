var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState } from "react";
import "./upload-button.sass";
import InputFile from "../interface/inputs/input-file/input-file";
import { useUploader } from "../hooks/use-uploader/use-uploader";
import { Button } from "../interface/button/button";
import usePluralize from "../hooks/use-pluralize/use-pluralize";
import TintIcon from "../interface/icon/icon";
import { faSpinner, faArrowToTop } from "@fortawesome/pro-solid-svg-icons";
import { Typography } from "../interface/typography/typography";
export const UploadButton = (_a) => {
  var _b = _a, {
    onUploadSuccess,
    onUploadError,
    children,
    buttonText = "Upload",
    singleUpload,
    url
  } = _b, props = __objRest(_b, [
    "onUploadSuccess",
    "onUploadError",
    "children",
    "buttonText",
    "singleUpload",
    "url"
  ]);
  const { accept = ["image/*", "video/*"], maxSize } = props;
  const [filesLength, setFilesLength] = useState(0);
  const uploadedAssetsText = usePluralize(filesLength.length, "asset");
  const { onUploadFiles, isPending } = useUploader({
    onUploadSuccess: (data) => onUploadSuccess(singleUpload ? data[0] : data),
    onUploadError,
    url
  });
  const handleFileChange = (assets) => __async(void 0, null, function* () {
    setFilesLength(assets.target.files.length);
    yield onUploadFiles(assets.target.files);
  });
  return /* @__PURE__ */ React.createElement("div", { className: "tint-upload-button" }, /* @__PURE__ */ React.createElement("label", { htmlFor: "file" }, /* @__PURE__ */ React.createElement(InputFile, { isCropped: false, accept, maxSize, handleChange: handleFileChange }, children ? React.Children.map(
    children,
    (child) => React.cloneElement(child, {
      htmlFor: "file",
      onClick: onUploadFiles,
      text: buttonText,
      isPending,
      length: filesLength,
      uploadedAssetsText
    })
  ) : /* @__PURE__ */ React.createElement(Button, { type: "gray", size: "full-width" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faArrowToTop, fontSize: "14px" }), isPending ? /* @__PURE__ */ React.createElement(Typography, null, `${buttonText}ing...`) : /* @__PURE__ */ React.createElement(Typography, null, !filesLength ? buttonText : `Uploaded ${filesLength} ${uploadedAssetsText}`), isPending && /* @__PURE__ */ React.createElement(TintIcon, { icon: faSpinner, fontSize: "14px", className: "spinner" })))));
};
