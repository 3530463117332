var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { Fragment } from "react";
import "./input-group.sass";
import ValidationAlert from "../validation-alert/validation-alert";
export const InputGroupPosition = {
  LEFT: "left",
  RIGHT: "right"
};
export const InputGroup = (props) => {
  const {
    inputGroupLabel,
    inputGroupIcon,
    labelStyleProps = {},
    children,
    label,
    required,
    labelPosition = InputGroupPosition.LEFT,
    error,
    touched
  } = props;
  const isError = error && touched;
  return /* @__PURE__ */ React.createElement("div", { className: `tint-input-group ${isError ? "tint-input-group--error" : ""}` }, label ? /* @__PURE__ */ React.createElement("label", { style: __spreadValues({}, labelStyleProps) }, label, required ? "*" : null) : null, /* @__PURE__ */ React.createElement("div", { className: "tint-input-group__wrapper" }, labelPosition === InputGroupPosition.LEFT && inputGroupIcon && /* @__PURE__ */ React.createElement("span", { className: `icon icon--group ${inputGroupIcon} ${InputGroupPosition.LEFT}` }), labelPosition === InputGroupPosition.LEFT && inputGroupLabel && /* @__PURE__ */ React.createElement("span", { className: `icon icon--group icon--label ${InputGroupPosition.LEFT}` }, inputGroupLabel), React.Children.map(children, (child, i) => /* @__PURE__ */ React.createElement(Fragment, { key: i }, React.cloneElement(child, __spreadProps(__spreadValues({}, child.props), {
    label: void 0,
    error: isError ? void 0 : child.props.error
  })))), labelPosition === InputGroupPosition.RIGHT && inputGroupIcon && /* @__PURE__ */ React.createElement("span", { className: `icon icon--group ${inputGroupIcon} ${InputGroupPosition.RIGHT}` }), labelPosition === InputGroupPosition.RIGHT && inputGroupLabel && /* @__PURE__ */ React.createElement("span", { className: `icon icon--group icon--label ${InputGroupPosition.RIGHT}` }, inputGroupLabel)), /* @__PURE__ */ React.createElement(ValidationAlert, { errorMsg: error, isVisible: isError }));
};
