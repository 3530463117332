import * as ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import "./sidebar.sass";
import PropTypes from "prop-types";
import useKeyDown from "../hooks/use-key-down/use-key-down";
import { keyCodes } from "../hooks/use-key-down/keyCodes";
import { SidebarFooter, SidebarFooterClickButtonAction } from "./components/footer/sidebar-footer";
import { SidebarHeader } from "./components/haeder/sidebar-header";
export const TintSidebarHeightType = {
  FULL_SCREEN: "screen-height",
  BELOW_NAVBAR: "below-navbar-height",
  BELOW_ACTIONBAR: "below-actionbar-height"
  // dark navbar
};
export const TintSidebarSize = {
  BIG: "big",
  NORMAL: "normal"
};
export const TintSidebar = ({
  sidebarClassName,
  children,
  animationTime = 0.3,
  onClose,
  isOpen = false,
  styles = {},
  title,
  isFooterVisible = true,
  alertComponent,
  footerComponent,
  onRightButtonClick,
  onLeftButtonClick,
  rightButtonText,
  leftButtonText,
  isSubmitting,
  isRightButtonDisabled = false,
  defaultFooterStyle = true,
  type = TintSidebarHeightType.BELOW_NAVBAR,
  isBackDropVisible = true,
  id,
  oneButton = false,
  size = TintSidebarSize.NORMAL
}) => {
  const keyDown = useKeyDown();
  const [isSidebarOpen, setSidebarVisibility] = useState(isOpen);
  const [animationState, setAnimationState] = useState(isOpen);
  useEffect(() => {
    if (isOpen) {
      setSidebarVisibility(true);
      setAnimationState(true);
    }
    if (!isOpen && isSidebarOpen) {
      onCloseClick();
    }
  }, [isOpen]);
  useEffect(() => {
    if (onClose && keyDown && keyDown.keyDown === keyCodes.escape) {
      onCloseClick();
    }
  }, [keyDown]);
  const onAnimationEnd = () => {
    if (!isSidebarOpen) {
      setAnimationState(false);
    }
  };
  const onCloseClick = () => {
    setSidebarVisibility(false);
    setTimeout(() => {
      onClose && onClose();
    }, animationTime * 1e3 - 50);
  };
  const renderFooterComponent = () => {
    return footerComponent ? footerComponent({
      onClickButton
    }) : /* @__PURE__ */ React.createElement(
      SidebarFooter,
      {
        onClickButton,
        leftButtonText,
        rightButtonText,
        isSubmitting,
        isRightButtonDisabled,
        oneButton
      }
    );
  };
  const getCloseAnimation = () => {
    return styles.animation && styles.animation.close ? styles.animation.close : size === TintSidebarSize.BIG ? "moveOutBig" : "moveOut";
  };
  const getOpenAnimation = () => {
    return styles.animation && styles.animation.open ? styles.animation.open : size === TintSidebarSize.BIG ? "moveInBig" : "moveIn";
  };
  const onClickButton = (type2) => {
    switch (type2) {
      case SidebarFooterClickButtonAction.LEFT_BUTTON:
        onLeftButtonClick ? onLeftButtonClick() : onCloseClick();
        break;
      case SidebarFooterClickButtonAction.RIGHT_BUTTON:
        onRightButtonClick && onRightButtonClick();
        break;
      default:
        return;
    }
  };
  return animationState && ReactDOM.createPortal(
    /* @__PURE__ */ React.createElement(React.Fragment, null, isBackDropVisible && /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-backdrop" }), /* @__PURE__ */ React.createElement(
      "div",
      {
        "data-testid": id,
        className: `tint-sidebar tint-sidebar--${type} tint-sidebar--size-${size} ${sidebarClassName}`
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "tint-sidebar__content",
          style: {
            animation: `${isSidebarOpen ? getOpenAnimation() : getCloseAnimation()} ${animationTime}s`
          },
          onAnimationEnd
        },
        /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar__container" }, title && /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar__header" }, /* @__PURE__ */ React.createElement(SidebarHeader, { title, onIconCLick: onCloseClick })), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar__children-wrapper", id: "scrollableTarget" }, children), alertComponent, isFooterVisible && /* @__PURE__ */ React.createElement("div", { className: `tint-sidebar__footer ${defaultFooterStyle ? "tint-sidebar__footer--default" : ""}` }, renderFooterComponent()))
      )
    )),
    document.body
  );
};
export default TintSidebar;
TintSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    animation: PropTypes.object
  })
};
