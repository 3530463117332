var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditInput, EditInputDropdown, EditTextArea } from "../../../";
import "./edit-form-content.sass";
import { formUpload } from "../../../../../../../model/form-upload.model";
import { EditInputContainer } from "../../../";
import { createTranslationInputArrayKey } from "../../../../../containers/experience/translation-creator";
import { FIELD_TYPE, transformToSendAble } from "../form-helper";
import { EditFormHelper } from "./edit-form-helper/edit-form-helper";
import { clone } from "../../../../../../../services/functions/clone/clone";
import { useFormik } from "formik";
import EditCheckbox from "../../../edit-checkbox/edit-checkbox";
import { EditTime } from "../../timer/edit/edit-time/edit-time";
import DropdownButton, { ButtonType } from "../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import DragAndDropContainer from "../../../../../containers/experience/components/drag-and-drop/drag-and-drop-container";
import FormFieldItem from "./form-field-item/form-field-item";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropdownClickList from "../../../../../../../components/interface/dropdown/lists/click-list/dropdown-click-list";
import useFeatureAccessibility from "../../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { GuardNames } from "../../../../../../../guards/guards-list";
const uploadSettings = [
  {
    name: "Upload Multiple Files",
    value: formUpload.MANY
  },
  {
    name: "Upload Single File",
    value: formUpload.SINGLE
  }
];
const advancedSettings = [
  {
    name: "OFF",
    value: "off"
  },
  {
    name: "ON",
    value: "on"
  }
];
export const EditFormContent = ({
  data,
  getTranslation,
  onNewTranslationUpdate,
  onTranslationUpdate,
  experienceType,
  dataUpdate,
  selectStep,
  editSuccessMessage,
  saveData,
  setApplyButtonDisabled,
  openEditModal,
  dismissEditModal
}) => {
  const [currentFields, setCurrentFields] = useState(data.fields);
  const removeField = ({ index }) => {
    let _tmp = [...data["fields"]];
    _tmp.splice(index, 1);
    setCurrentFields(_tmp);
    dataUpdate({ ["fields"]: _tmp });
  };
  const keyInputUpdate = (value, i) => {
    const tmpInputFields = currentFields.map((field, index) => {
      return index === i ? __spreadProps(__spreadValues({}, field), {
        key: value
      }) : __spreadValues({}, field);
    });
    setCurrentFields(tmpInputFields);
    dataUpdate({ fields: tmpInputFields });
  };
  const renderUploadSettingsDropdown = () => {
    return data.isPhotoUploadEnabled ? /* @__PURE__ */ React.createElement("div", { className: "margin-bottom-40" }, /* @__PURE__ */ React.createElement(EditInputContainer, { title: "upload settings" }, /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        label: "upload limit",
        onChange: (e) => dataUpdate({ uploadPhotoType: e.target.value }),
        options: uploadSettings,
        defaultValue: data.uploadPhotoType
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "upload button text",
        placeholder: "Enter value",
        defaultValue: getTranslation(data.uploadButtonText),
        onChange: (event) => {
          onTranslationUpdate(data.uploadButtonText, event.target.value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "upload error text",
        placeholder: "Enter value",
        defaultValue: getTranslation(data.uploadErrorText),
        onChange: (event) => {
          onTranslationUpdate(data.uploadErrorText, event.target.value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "Required field text",
        placeholder: "*Required",
        defaultValue: getTranslation(data.requiredFieldText),
        onChange: (event) => {
          onTranslationUpdate(data.requiredFieldText, event.target.value);
        }
      }
    ), data.isPhotoUploadEnabled ? /* @__PURE__ */ React.createElement(
      EditCheckbox,
      {
        value: !!data.isPhotoUploadRequired,
        onChange: (e) => dataUpdate({ isPhotoUploadRequired: e.target.checked }),
        name: "uploadingRequired",
        id: "uploadingRequired",
        label: "Uploading required"
      }
    ) : null)) : null;
  };
  const addElement = ({ type }) => {
    let _index = 0;
    let lastIndex;
    if (data.fields.length > 0) {
      lastIndex = data.fields.slice().sort((a, b) => Number(a.key.split("_").pop()) - Number(b.key.split("_").pop())).pop();
      const keyNumber = Number(lastIndex.key.split("_").pop());
      if (!keyNumber) {
        _index = data.fields.length;
      } else {
        _index = keyNumber;
      }
    }
    _index += 1;
    let translationValue = "";
    switch (type) {
      case FIELD_TYPE.INPUT:
        translationValue = `Sample Single-Line #${_index}`;
        break;
      case FIELD_TYPE.TEXTAREA:
        translationValue = `Sample Multi-Line #${_index}`;
        break;
      case FIELD_TYPE.DROPDOWN:
        translationValue = `Sample Dropdown #${_index}`;
        break;
      case FIELD_TYPE.CHECKBOX:
        translationValue = `Sample Checkbox #${_index}`;
        break;
      case FIELD_TYPE.HIDDEN_INPUT:
        translationValue = `Static Value Passed To Your Form Submission #${_index}`;
        break;
    }
    const translateKey = createTranslationInputArrayKey(experienceType, "form", "placeholder", data.fields.length);
    const tmpFields = [...data["fields"]];
    const newField = {
      type,
      placeholder: translateKey,
      key: transformToSendAble(translationValue),
      isRequired: true
    };
    if (type === FIELD_TYPE.DROPDOWN || type === FIELD_TYPE.CHECKBOX) {
      newField.options = [];
    }
    tmpFields.push(newField);
    dataUpdate({ ["fields"]: tmpFields });
    setCurrentFields(tmpFields);
    onNewTranslationUpdate(translateKey, translationValue);
  };
  const onArrayPropertyChange = ({ property, value, index }) => {
    const _array = clone(data.fields);
    _array[index][property] = value;
    setCurrentFields(_array);
    dataUpdate({ ["fields"]: _array });
  };
  const isExperiencesFieldsHiddenAvailable = useFeatureAccessibility(GuardNames.EXPERIENCES_FIELDS_HIDDEN);
  const renderButtonAdded = () => {
    const dropdownList = [
      { name: "Input", value: FIELD_TYPE.INPUT },
      { name: "Textarea", value: FIELD_TYPE.TEXTAREA },
      { name: "Dropdown", value: FIELD_TYPE.DROPDOWN },
      { name: "Checkbox", value: FIELD_TYPE.CHECKBOX }
    ];
    if (isExperiencesFieldsHiddenAvailable) {
      dropdownList.push({ name: "Hidden input", value: FIELD_TYPE.HIDDEN_INPUT });
    }
    return /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownClickList, __spreadValues({}, props)),
        list: dropdownList,
        placeholder: "Add Element",
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_GRAY,
        styles: { width: "100%", maxWidth: "100%" },
        onChangeValue: (selectedItem) => addElement({ type: selectedItem.value })
      }
    );
  };
  const renderAdvancedSettings = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-input-container" }, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-input-container__label" }, "display advanced settings"), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: advancedSettings,
        iconRight: "fa fa-caret-down",
        placeholder: data.hasAdvancedSettings ? "ON" : "OFF",
        styles: { height: "28px" },
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        onChangeValue: (selectedItem) => {
          const _value = selectedItem.value;
          dataUpdate({
            hasAdvancedSettings: _value === "on"
          });
        }
      }
    ));
  };
  const renderCheckboxOptions = ({
    urlLabel,
    urlProperty,
    textProperty,
    agreeProperty,
    uploadText,
    uploadStep,
    linkTitle
  }) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: urlLabel,
        placeholder: "Enter URL",
        defaultValue: getTranslation(data[urlProperty]),
        onChange: (event) => {
          onTranslationUpdate(data[urlProperty], event.target.value);
        }
      }
    ), getTranslation(data[urlProperty]) || getTranslation(data[textProperty]) ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        placeholder: "I agree to",
        defaultValue: getTranslation(data[agreeProperty]),
        onChange: (event) => {
          onTranslationUpdate(data[agreeProperty], event.target.value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        placeholder: "Link Name",
        defaultValue: getTranslation(data[linkTitle]),
        onChange: (event) => {
          onTranslationUpdate(data[linkTitle], event.target.value);
        }
      }
    )) : null, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-form-content__line-adder", onClick: () => selectStep(uploadStep) }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-form-content__line-adder-element" }, /* @__PURE__ */ React.createElement("span", null, uploadText))));
  };
  const checkBoxValue = (e, property) => {
    if (e[property] === void 0) {
      return true;
    }
    return e[property];
  };
  const onChangeOrder = (fields) => {
    const mapFieldList = fields.map((field) => __spreadProps(__spreadValues({}, field), {
      elementId: void 0
    }));
    if (JSON.stringify(mapFieldList) !== JSON.stringify(data.fields)) {
      setCurrentFields(mapFieldList);
      setFieldValue("fields", mapFieldList);
      dataUpdate({ fields: mapFieldList });
    }
  };
  const renderDnD = (list, handleChange2, errors2) => {
    return /* @__PURE__ */ React.createElement(DndProvider, { backend: HTML5Backend }, /* @__PURE__ */ React.createElement(
      DragAndDropContainer,
      {
        sectionList: currentFields,
        onChangeOrder,
        dndItemComponent: (props) => /* @__PURE__ */ React.createElement(
          FormFieldItem,
          __spreadProps(__spreadValues({}, props), {
            ref: props.componentRef,
            openEditModal,
            dismissEditModal,
            data,
            getTranslation,
            onTranslationUpdate,
            handleChange: handleChange2,
            errors: errors2,
            keyInputUpdate,
            removeField,
            checkBoxValue,
            onArrayPropertyChange
          })
        )
      }
    ));
  };
  const isFormValid = ({ errors: errors2 }) => {
    const a = errors2.fields.find((e) => e.key !== void 0 || e.placeholder !== void 0);
    return a === void 0;
  };
  const validateForm = (values2) => {
    const allValues = [];
    values2.fields.map((e) => {
      allValues.push(e.placeholder);
      allValues.push(e.key);
    });
    let errors2 = {};
    errors2 = __spreadValues(__spreadValues({}, errors2), EditFormHelper.validateInputs({
      values: values2,
      getTranslation
    }));
    EditFormHelper.validateDuplicates({
      values: values2,
      getTranslation,
      allValues,
      errors: errors2,
      properties: ["fields"],
      keys: ["placeholder", "key"]
    });
    setApplyButtonDisabled(!isFormValid({ errors: errors2 }));
    return errors2;
  };
  const { errors, values, handleChange, setFieldValue } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { fields: data.fields },
    onSubmit: () => {
    },
    validate: validateForm
  });
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Edit the fields below to customize your Form." }, /* @__PURE__ */ React.createElement(
    "form",
    {
      className: "tint-edit-form-content",
      noValidate: true,
      onSubmit: (e) => {
        e.preventDefault();
        saveData();
      }
    },
    renderAdvancedSettings(),
    renderDnD(values.fields, handleChange, errors),
    renderButtonAdded(),
    /* @__PURE__ */ React.createElement("div", { className: "margin-bottom-40" }, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "button text",
        placeholder: "Enter value",
        defaultValue: getTranslation(data.buttonText),
        onChange: (event) => {
          onTranslationUpdate(data.buttonText, event.target.value);
        }
      }
    )),
    renderUploadSettingsDropdown(),
    /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit"),
    /* @__PURE__ */ React.createElement(EditInputContainer, { title: "LEGAL LINKS" }, renderCheckboxOptions({
      uploadStep: 2,
      urlLabel: "TERMS & conditions",
      agreeProperty: "termsConditionsAgree",
      textProperty: "termsConditions",
      urlProperty: "termsConditionsUrl",
      uploadText: "Upload Terms",
      linkTitle: "termsConditionsLinkTitle"
    }), renderCheckboxOptions({
      uploadStep: 4,
      urlLabel: "Privacy Policy",
      agreeProperty: "privacyPolicyAgree",
      textProperty: "privacyPolicy",
      urlProperty: "privacyPolicyUrl",
      uploadText: "Upload Privacy Policy",
      linkTitle: "privacyPolicyLinkTitle"
    }), renderCheckboxOptions({
      uploadStep: 1,
      urlLabel: "contest rules",
      agreeProperty: "contestRulesAgree",
      textProperty: "contestRules",
      linkTitle: "contestRulesLinkTitle",
      urlProperty: "contestRulesUrl",
      uploadText: "Upload Contest Rules"
    })),
    /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "OPT IN MESSAGE",
        placeholder: "Yes, I want to receive emails from...",
        defaultValue: getTranslation(data.gdprCheckboxLabelText),
        onChange: (event) => {
          onTranslationUpdate(data.gdprCheckboxLabelText, event.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      EditTextArea,
      {
        label: "OPT IN DESCRIPTION",
        placeholder: "Note: Your data will not be used for reasons other than helping to choose and announce a winner.",
        defaultValue: getTranslation(data.gdprCheckboxDescriptionText),
        onChange: (event) => {
          onTranslationUpdate(data.gdprCheckboxDescriptionText, event.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-edit-form-content__line-adder",
        onClick: () => {
          editSuccessMessage();
          selectStep(3);
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: "tint-edit-form-content__line-adder-element" }, /* @__PURE__ */ React.createElement("span", null, "Edit Success Message"))
    )
  ), /* @__PURE__ */ React.createElement(
    EditCheckbox,
    {
      value: !!data.isEndTimeEnabled,
      onChange: (e) => {
        const updateObject = { isEndTimeEnabled: e.target.checked };
        if (data.endDate === void 0) {
          updateObject.endDate = /* @__PURE__ */ new Date();
        }
        dataUpdate(updateObject);
      },
      label: "SUBMISSION END TIME"
    }
  ), data.isEndTimeEnabled ? /* @__PURE__ */ React.createElement(
    EditTime,
    {
      onDateChange: (date) => {
        dataUpdate({
          endDate: new Date(date)
        });
      },
      data,
      minDate: () => {
        const currentDate = /* @__PURE__ */ new Date();
        return new Date(currentDate.setDate(currentDate.getDate() + 1));
      }
    }
  ) : null);
};
