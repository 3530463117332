var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { EMAIL_TEMPLATE_SETTINGS } from "./email-template-settings";
import { RangeInputUnitType } from "../../../components/interface/inputs/range-input/range-input";
import { EmailTemplateTypes } from "../utils/theme-types/theme-types";
export const EMAIL_TEMPLATE_GRID = {
  name: "Grid",
  key: EmailTemplateTypes.GRID,
  default_settings: __spreadProps(__spreadValues({}, EMAIL_TEMPLATE_SETTINGS), {
    columns: "2",
    rows: "3",
    overlay_opacity: "10",
    overlay_color: "#000",
    postpadding: "20",
    fontcolor_post: "#fff",
    themeName: EmailTemplateTypes.GRID
  }),
  guards: {
    CTA: false,
    waterfall: false,
    popup: false,
    shareIcons: false,
    removeDuplicateImages: false,
    genericAvatarBackgroundColor: false,
    genericAvatarTextColor: false,
    customCSS: false,
    backgroundImage: false,
    banner: false
  },
  themes_settings: [
    {
      key: "columns",
      type: "slider",
      label: "Images Per Row",
      min: "1",
      max: "8",
      unit: RangeInputUnitType.NONE
    },
    {
      key: "rows",
      type: "slider",
      label: "Rows",
      min: "1",
      max: "8",
      unit: RangeInputUnitType.NONE
    },
    {
      key: "postpadding",
      type: "slider",
      label: "Spacing",
      min: "0",
      max: "100"
    },
    {
      key: "overlay_color",
      type: "colorPicker",
      label: "Overlay Color"
    },
    {
      key: "overlay_opacity",
      type: "slider",
      label: "Overlay Opacity",
      min: "0",
      max: "100",
      unit: RangeInputUnitType.PERCENT
    },
    {
      key: "post_scale",
      type: "dropdown",
      label: "Image",
      options: [
        {
          name: "Fill",
          value: "fill"
        },
        {
          name: "Fit",
          value: "fit"
        }
      ]
    },
    {
      key: "post_background_color",
      type: "colorPicker",
      label: "Post Background Color"
    }
  ],
  font_settings: [
    {
      key: "fontcolor_post",
      label: "Font Color",
      type: "colorPicker"
    },
    {
      key: "fontsize_secondary",
      type: "slider",
      label: "Font size",
      min: "8",
      max: "50"
    }
  ],
  extra_settings: [],
  extra_popup_settings: []
};
