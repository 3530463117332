export const multipleMatrix = {
  MULTIPLE_MATRIX: ".input-multiple-matrix",
  MULTIPLE_MATRIX_LEGEND: ".input-multiple-matrix legend",
  MULTIPLE_MATRIX_TABLE: ".input-multiple-matrix table",
  MULTIPLE_MATRIX_TBODY_TD: ".input-multiple-matrix tbody td",
  MULTIPLE_MATRIX_TBODY_TH: ".input-multiple-matrix tbody th",
  MULTIPLE_MATRIX_THEAD_TH: ".input-multiple-matrix thead th",
  MULTIPLE_MATRIX_TH_TD: ".input-multiple-matrix th, .input-multiple-matrix td",
  MULTIPLE_MATRIX_INPUT: ".input-multiple-matrix input",
  MULTIPLE_MATRIX_RTL: "*[dir='rtl'] .input-multiple-matrix thead th"
};
