import { datePickerOptions } from "../../components/interface/dropdown/lists/date-list/dropdown-date-list";
export const getDateDiff = (date1, date2) => {
  let years = date1.diff(date2, "year");
  date2.add(years, "years");
  let months = date1.diff(date2, "months");
  date2.add(months, "months");
  let days = date1.diff(date2, "days");
  date2.add(days, "days");
  let hours = date1.diff(date2, "hours");
  date2.add(hours, "hours");
  let minutes = date1.diff(date2, "minutes");
  date2.add(minutes, "minutes");
  let seconds = date1.diff(date2, "seconds");
  return { years, months, days, hours, minutes, seconds };
};
export const formatTime = (value) => {
  return value ? value.toString().padStart(2, "0") : "";
};
export const formatDateText = (value, type) => {
  return value ? `${value} ${type}${value > 1 ? "s" : ""} and ` : "";
};
export const getDatePickerDataRange = (type) => {
  switch (type) {
    case datePickerOptions.WEEK:
      return getWeekBefore();
    case datePickerOptions.MONTH:
      return getMonthBefore();
    case datePickerOptions.THREE_MONTHS:
      return getThreeMonthsBefore();
    case datePickerOptions.SIX_MONTHS:
      return getSixMonthsBefore();
    case datePickerOptions.YEAR:
      return getYearsBefore();
    default:
      return;
  }
};
const getWeekBefore = () => {
  const currentDate = /* @__PURE__ */ new Date();
  const pastDate = currentDate.getDate() - 7;
  currentDate.setDate(pastDate);
  return new Date(currentDate).getTime();
};
const getMonthBefore = () => {
  const d = /* @__PURE__ */ new Date();
  return d.setMonth(d.getMonth() - 1);
};
const getThreeMonthsBefore = () => {
  const d = /* @__PURE__ */ new Date();
  return d.setMonth(d.getMonth() - 3);
};
const getSixMonthsBefore = () => {
  const d = /* @__PURE__ */ new Date();
  return d.setMonth(d.getMonth() - 6);
};
const getYearsBefore = () => {
  const d = /* @__PURE__ */ new Date();
  return d.setFullYear(d.getFullYear() - 1);
};
