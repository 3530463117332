import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
const PREFIX = "INSIGHTS_CONTENT";
export const ACTIONS = {
  GET_TOTAL_POSTS_INGESTED: createRoutine(createPrefixedActionName(PREFIX, "GET_TOTAL_POSTS_INGESTED")),
  GET_POSTS_INGESTED: createRoutine(createPrefixedActionName(PREFIX, "GET_POSTS_INGESTED")),
  GET_POSTS_INGESTED_PER_DAY: createRoutine(createPrefixedActionName(PREFIX, "GET_POSTS_INGESTED_PER_DAY")),
  GET_TOP_SOCIAL_FEEDS: createRoutine(createPrefixedActionName(PREFIX, "GET_TOP_SOCIAL_FEEDS")),
  GET_TOP_ACCOUNTS: createRoutine(createPrefixedActionName(PREFIX, "GET_TOP_ACCOUNTS")),
  GET_TOP_SOURCES: createRoutine(createPrefixedActionName(PREFIX, "GET_TOP_SOURCES")),
  GET_MODERATION_ACTIVITY: createRoutine(createPrefixedActionName(PREFIX, "GET_MODERATION_ACTIVITY")),
  GET_DAILY_MODERATION: createRoutine(createPrefixedActionName(PREFIX, "GET_DAILY_MODERATION")),
  GET_TOP_MODERATORS: createRoutine(createPrefixedActionName(PREFIX, "GET_TOP_MODERATORS")),
  GET_RIGHTS_REQUESTED: createRoutine(createPrefixedActionName(PREFIX, "GET_RIGHTS_REQUESTED")),
  GET_RIGHTS_APPROVED: createRoutine(createPrefixedActionName(PREFIX, "GET_RIGHTS_APPROVED")),
  GET_TOP_CLICKED_POSTS: createRoutine(createPrefixedActionName(PREFIX, "GET_TOP_CLICKED_POSTS")),
  GET_TOTAL_CONTENT_COLLECTED: createRoutine(createPrefixedActionName(PREFIX, "GET_TOTAL_CONTENT_COLLECTED")),
  GET_TOP_RIGHTS_REQUESTERS: createRoutine(createPrefixedActionName(PREFIX, "GET_TOP_RIGHTS_REQUESTERS")),
  SET_INSIGHT_FILTER: createPrefixedActionName(PREFIX, "SET_INSIGHT_FILTER"),
  CLEAR_ALL_INSIGHTS: createPrefixedActionName(PREFIX, "CLEAR_ALL_INSIGHTS"),
  SET_DOWNLOAD: createPrefixedActionName(PREFIX, "SET_DOWNLOAD")
};
export const clearAllInsights = () => ({
  type: ACTIONS.CLEAR_ALL_INSIGHTS
});
export const setInsightsDownload = (data) => ({
  type: ACTIONS.SET_DOWNLOAD,
  payload: data
});
export const getPostsIngestedPerDayStart = () => ({
  type: ACTIONS.GET_POSTS_INGESTED_PER_DAY.REQUEST
});
export const getPostsIngestedPerDaySuccess = (data) => ({
  type: ACTIONS.GET_POSTS_INGESTED_PER_DAY.SUCCESS,
  payload: data
});
export const getPostsIngestedPerDayError = (err) => ({
  type: ACTIONS.GET_POSTS_INGESTED_PER_DAY.FAILURE,
  payload: err
});
export const getPostsIngestedStart = () => ({
  type: ACTIONS.GET_POSTS_INGESTED.REQUEST
});
export const getPostsIngestedSuccess = (data) => ({
  type: ACTIONS.GET_POSTS_INGESTED.SUCCESS,
  payload: data
});
export const getPostsIngestedError = (err) => ({
  type: ACTIONS.GET_POSTS_INGESTED.FAILURE,
  payload: err
});
export const getTopRightsRequestersStart = () => ({
  type: ACTIONS.GET_TOP_RIGHTS_REQUESTERS.REQUEST
});
export const getTopRightsRequestersSuccess = (data) => ({
  type: ACTIONS.GET_TOP_RIGHTS_REQUESTERS.SUCCESS,
  payload: data
});
export const getTopRightsRequestersError = (err) => ({
  type: ACTIONS.GET_TOP_RIGHTS_REQUESTERS.FAILURE,
  payload: err
});
export const getTotalContentCollectedStart = () => ({
  type: ACTIONS.GET_TOTAL_CONTENT_COLLECTED.REQUEST
});
export const getTotalContentCollectedSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_CONTENT_COLLECTED.SUCCESS,
  payload: data
});
export const getTotalContentCollectedError = (err) => ({
  type: ACTIONS.GET_TOTAL_CONTENT_COLLECTED.FAILURE,
  payload: err
});
export const getTopClickedPostsStart = () => ({
  type: ACTIONS.GET_TOP_CLICKED_POSTS.REQUEST
});
export const getTopClickedPostsSuccess = (data) => ({
  type: ACTIONS.GET_TOP_CLICKED_POSTS.SUCCESS,
  payload: data
});
export const getTopClickedPostsError = (err) => ({
  type: ACTIONS.GET_TOP_CLICKED_POSTS.FAILURE,
  payload: err
});
export const getRightsApprovedStart = () => ({
  type: ACTIONS.GET_RIGHTS_APPROVED.REQUEST
});
export const getRightsApprovedSuccess = (data) => ({
  type: ACTIONS.GET_RIGHTS_APPROVED.SUCCESS,
  payload: data
});
export const getRightsApprovedError = (err) => ({
  type: ACTIONS.GET_RIGHTS_APPROVED.FAILURE,
  payload: err
});
export const getRightsRequestedStart = () => ({
  type: ACTIONS.GET_RIGHTS_REQUESTED.REQUEST
});
export const getRightsRequestedSuccess = (data) => ({
  type: ACTIONS.GET_RIGHTS_REQUESTED.SUCCESS,
  payload: data
});
export const getRightsRequestedError = (err) => ({
  type: ACTIONS.GET_RIGHTS_REQUESTED.FAILURE,
  payload: err
});
export const getTopModeratorsStart = () => ({
  type: ACTIONS.GET_TOP_MODERATORS.REQUEST
});
export const getTopModeratorsSuccess = (data) => ({
  type: ACTIONS.GET_TOP_MODERATORS.SUCCESS,
  payload: data
});
export const getTopModeratorsError = (err) => ({
  type: ACTIONS.GET_TOP_MODERATORS.FAILURE,
  payload: err
});
export const getDailyModerationStart = () => ({
  type: ACTIONS.GET_DAILY_MODERATION.REQUEST
});
export const getDailyModerationSuccess = (data) => ({
  type: ACTIONS.GET_DAILY_MODERATION.SUCCESS,
  payload: data
});
export const getDailyModerationError = (err) => ({
  type: ACTIONS.GET_DAILY_MODERATION.FAILURE,
  payload: err
});
export const getModerationActivityStart = () => ({
  type: ACTIONS.GET_MODERATION_ACTIVITY.REQUEST
});
export const getModerationActivitySuccess = (data) => ({
  type: ACTIONS.GET_MODERATION_ACTIVITY.SUCCESS,
  payload: data
});
export const getModerationActivityError = (err) => ({
  type: ACTIONS.GET_MODERATION_ACTIVITY.FAILURE,
  payload: err
});
export const setInsightsFilter = (data) => ({
  type: ACTIONS.SET_INSIGHT_FILTER,
  payload: data
});
export const getTopSocialFeedsStart = () => ({
  type: ACTIONS.GET_TOP_SOCIAL_FEEDS.REQUEST
});
export const getTopSocialFeedsSuccess = (data) => ({
  type: ACTIONS.GET_TOP_SOCIAL_FEEDS.SUCCESS,
  payload: data
});
export const getTopSocialFeedsError = (err) => ({
  type: ACTIONS.GET_TOP_SOCIAL_FEEDS.FAILURE,
  payload: err
});
export const getTopAccountsStart = () => ({
  type: ACTIONS.GET_TOP_ACCOUNTS.REQUEST
});
export const getTopAccountsSuccess = (data) => ({
  type: ACTIONS.GET_TOP_ACCOUNTS.SUCCESS,
  payload: data
});
export const getTopAccountsError = (err) => ({
  type: ACTIONS.GET_TOP_ACCOUNTS.FAILURE,
  payload: err
});
export const getTopSourcesStart = () => ({
  type: ACTIONS.GET_TOP_SOURCES.REQUEST
});
export const getTopSourcesSuccess = (data) => ({
  type: ACTIONS.GET_TOP_SOURCES.SUCCESS,
  payload: data
});
export const getTopSourcesError = (err) => ({
  type: ACTIONS.GET_TOP_SOURCES.FAILURE,
  payload: err
});
export const getTotalPostsIngestedStart = () => ({
  type: ACTIONS.GET_TOTAL_POSTS_INGESTED.REQUEST
});
export const getTotalPostsIngestedSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_POSTS_INGESTED.SUCCESS,
  payload: data
});
export const getTotalPostsIngestedError = (err) => ({
  type: ACTIONS.GET_TOTAL_POSTS_INGESTED.FAILURE,
  payload: err
});
