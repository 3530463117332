var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/profile/profile.actions";
import produce from "immer";
export const initialState = {
  data: void 0,
  error: void 0,
  isPending: false,
  twoFactorAuth: {
    data: [],
    isPending: false,
    error: void 0
  },
  confirmPasswordModal: {
    isVisible: false,
    data: void 0
  }
};
export const personalSettings = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATCH_USER_PROFILE.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isPending: true
      });
    case ACTIONS.POST_TWO_FACTOR_AUTH.REQUEST:
    case ACTIONS.PATCH_TWO_FACTOR_AUTH.REQUEST:
    case ACTIONS.GET_AUTHENTICATION_FACTORS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        twoFactorAuth: __spreadProps(__spreadValues({}, state.twoFactorAuth), {
          error: void 0,
          isPending: true
        })
      });
    case ACTIONS.POST_TWO_FACTOR_AUTH.FAILURE:
    case ACTIONS.GET_AUTHENTICATION_FACTORS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        twoFactorAuth: __spreadProps(__spreadValues({}, state.twoFactorAuth), {
          error: action.payload,
          isPending: false
        })
      });
    case ACTIONS.PATCH_USER_PROFILE.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isPending: false
      });
    case ACTIONS.PATCH_USER_PROFILE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload,
        error: void 0,
        isPending: false
      });
    case ACTIONS.GET_AUTHENTICATION_FACTORS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        twoFactorAuth: {
          error: void 0,
          isPending: false,
          data: action.payload
        }
      });
    case ACTIONS.PATCH_TWO_FACTOR_AUTH.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        twoFactorAuth: __spreadProps(__spreadValues({}, state.twoFactorAuth), {
          error: action.payload,
          isPending: false
        })
      });
    case ACTIONS.DELETE_TWO_FACTOR_AUTH.SUCCESS:
      return produce(state, (draft) => {
        draft.twoFactorAuth.data = draft.twoFactorAuth.data.filter((factorType) => factorType.id !== action.payload);
        draft.twoFactorAuth.isPending = false;
      });
    case ACTIONS.PATCH_TWO_FACTOR_AUTH.SUCCESS:
      return produce(state, (draft) => {
        const filterFactors = draft.twoFactorAuth.data.filter((factor) => factor.id !== action.payload.id);
        draft.twoFactorAuth.data = [...filterFactors, action.payload];
        draft.twoFactorAuth.isPending = false;
      });
    case ACTIONS.POST_TWO_FACTOR_AUTH.SUCCESS:
      return produce(state, (draft) => {
        draft.twoFactorAuth.isPending = false;
        draft.twoFactorAuth.data.push(action.payload);
      });
    case ACTIONS.CLOSE_CONFIRM_MODAL:
      return produce(state, (draft) => {
        draft.confirmPasswordModal = initialState.confirmPasswordModal;
      });
    case ACTIONS.OPEN_CONFIRM_MODAL:
      return produce(state, (draft) => {
        draft.confirmPasswordModal = action.payload;
      });
    default:
      return state;
  }
};
