import { SIDEBAR } from "./consts.js";
import { createPrefixedActionName } from "../services/functions/redux-routines/redux-routines";
export const SIDEBAR_TYPE = {
  UPGRADE_SIDEBAR: "UPGRADE_SIDEBAR"
};
export const ACTIONS = {
  OPEN_SIDEBAR_MODAL: createPrefixedActionName(SIDEBAR, "OPEN_SIDEBAR"),
  CLOSE_SIDEBAR_MODAL: createPrefixedActionName(SIDEBAR, "CLOSE_SIDEBAR")
};
export const openSidebar = ({ modalType, data, editMode }) => ({
  type: ACTIONS.OPEN_SIDEBAR_MODAL,
  payload: {
    modalType,
    data,
    editMode
  }
});
export const closeSidebar = () => ({
  type: ACTIONS.CLOSE_SIDEBAR_MODAL
});
