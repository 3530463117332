import { createRoutine } from "../../../services/functions/redux-routines/redux-routines";
import store from "../../../store";
import { Config } from "../../../utils/config";
import { ApiService } from "../../../services/api-service/api-service";
import { getTeamId } from "../../experience/utils/experience-helper";
export const ACTIONS = {
  GET_SUBMISSIONS: createRoutine("GET_SUBMISSIONS"),
  GET_SUBMISSIONS_EXPERIENCE: createRoutine("GET_SUBMISSIONS_EXPERIENCE"),
  GET_SUBMISSION_METRICS: createRoutine("GET_SUBMISSION_METRICS"),
  DISPLAY_ANALYTICS: "DISPLAY_ANALYTICS",
  DELETE_SUBMISSIONS: createRoutine("DELETE_SUBMISSIONS"),
  HIDE_ANALYTICS: "HIDE_ANALYTICS",
  CLEAR_SUBMISSIONS_STATE: "CLEAR_SUBMISSIONS_STATE",
  SELECT_SUBMISSION: "SELECT_SUBMISSION",
  SELECT_NEXT_SUBMISSION: "SELECT_NEXT_SUBMISSION",
  SELECT_PREVIOUS_SUBMISSION: "SELECT_PREVIOUS_SUBMISSION"
};
export const getSubmissionsExperienceStart = () => ({
  type: ACTIONS.GET_SUBMISSIONS_EXPERIENCE.REQUEST
});
export const getSubmissionsExperienceSuccess = (experience) => ({
  type: ACTIONS.GET_SUBMISSIONS_EXPERIENCE.SUCCESS,
  payload: experience
});
export const getSubmissionsExperienceError = (err) => ({
  type: ACTIONS.GET_SUBMISSIONS_EXPERIENCE.FAILURE,
  payload: err
});
export const getSubmissionsStart = () => ({
  type: ACTIONS.GET_SUBMISSIONS.REQUEST
});
export const getSubmissionsSuccess = (submissions) => ({
  type: ACTIONS.GET_SUBMISSIONS.SUCCESS,
  payload: submissions
});
export const getSubmissionsError = (err) => ({
  type: ACTIONS.GET_SUBMISSIONS.FAILURE,
  payload: err
});
export const deleteSubmissionsStart = () => ({
  type: ACTIONS.DELETE_SUBMISSIONS.REQUEST
});
export const deleteSubmissionsSuccess = () => ({
  type: ACTIONS.DELETE_SUBMISSIONS.SUCCESS
});
export const deleteSubmissionsError = (err) => ({
  type: ACTIONS.DELETE_SUBMISSIONS.FAILURE,
  payload: err
});
export const getSubmissionMetricsStart = () => ({
  type: ACTIONS.GET_SUBMISSION_METRICS.REQUEST
});
export const getSubmissionMetricsSuccess = (data) => ({
  type: ACTIONS.GET_SUBMISSION_METRICS.SUCCESS,
  payload: data
});
export const getSubmissionMetricsError = (err) => ({
  type: ACTIONS.GET_SUBMISSION_METRICS.FAILURE,
  payload: err
});
export const selectSubmission = (submission, submissionIndex) => ({
  type: ACTIONS.SELECT_SUBMISSION,
  payload: { submission, submissionIndex }
});
export const displayAnalytics = () => ({ type: ACTIONS.DISPLAY_ANALYTICS });
export const hideAnalytics = () => ({ type: ACTIONS.HIDE_ANALYTICS });
export const clearSubmissionsState = () => ({
  type: ACTIONS.CLEAR_SUBMISSIONS_STATE
});
export const selectNextSubmission = () => {
  const state = store.getState();
  const nextSubmissionIndex = getCurrentSelectedSubmissionIndex() + 1;
  return selectSubmission(state.submissions.data.data[nextSubmissionIndex], nextSubmissionIndex);
};
export const selectPreviousSubmission = () => {
  const state = store.getState();
  const previousSubmissionIndex = getCurrentSelectedSubmissionIndex() - 1;
  return selectSubmission(state.submissions.data.data[previousSubmissionIndex], previousSubmissionIndex);
};
export const getSubmissions = (experienceId, url) => {
  return (dispatch) => {
    dispatch(getSubmissionsStart());
    return ApiService.get(
      url || `/experiences/${experienceId}/form_submissions?page[size]=${Config.defaultSubmissionsPageSize}&include=files.asset`
    ).then(
      (res) => {
        dispatch(getSubmissionsSuccess(res.data));
      },
      (err) => {
        dispatch(getSubmissionsError(err));
      }
    );
  };
};
export const getSubmissionsExperience = (experienceId) => {
  return (dispatch) => {
    dispatch(getSubmissionsExperienceStart());
    return ApiService.get(`/experiences/${experienceId}`).then(
      (res) => {
        return dispatch(getSubmissionsExperienceSuccess(res.data.data));
      },
      (err) => {
        dispatch(getSubmissionsExperienceError(err));
      }
    );
  };
};
export const getSubmissionMetricsExperience = (experienceId) => {
  return (dispatch) => {
    dispatch(getSubmissionMetricsStart());
    return ApiService.pureInstance().get(`/v2/teams/${getTeamId()}/experiences/${experienceId}/form_submissions/metrics?include=post&group=post_id`).then(
      (res) => {
        return dispatch(getSubmissionMetricsSuccess(res.data));
      },
      (err) => {
        dispatch(getSubmissionMetricsError(err));
      }
    );
  };
};
export const deleteSubmissions = (experienceId) => {
  return (dispatch) => {
    dispatch(deleteSubmissionsStart());
    return ApiService.delete(`/experiences/${experienceId}/form_submissions`).then(() => {
      return dispatch(deleteSubmissionsSuccess());
    }).catch((err) => {
      dispatch(deleteSubmissionsError(err));
    });
  };
};
const getCurrentSelectedSubmissionIndex = () => {
  const state = store.getState();
  const selectedSubmissionId = state.submissions.selectedSubmission.id;
  return state.submissions.data.data.findIndex((e) => e.id === selectedSubmissionId);
};
