var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useRef } from "react";
import "./typography.sass";
export const TypographyVariants = {
  DEFAULT: "default",
  DESCRIPTION: "description",
  TITLE: "title",
  SUBTITLE: "subtitle",
  LABEL: "label",
  LIST: "list",
  SMALL: "small",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5"
};
export const TypographyColors = {
  PRIMARY: "#1b242d",
  SECONDARY: "#838e94",
  BLUE: "#3B99FC",
  WHITE: "#fff",
  RED: "#FF5055"
};
export const TypographyFontWeights = {
  NORMAL: 400,
  MEDIUM: 500,
  BOLD: 700
};
export const Typography = (_a) => {
  var _b = _a, {
    className,
    children,
    component = "span",
    color,
    fontWeight,
    variant = TypographyVariants.DEFAULT,
    styleProps
  } = _b, props = __objRest(_b, [
    "className",
    "children",
    "component",
    "color",
    "fontWeight",
    "variant",
    "styleProps"
  ]);
  const elementRef = useRef(null);
  const Tag = (props2) => React.createElement(component, __spreadValues({ ref: elementRef }, props2));
  return /* @__PURE__ */ React.createElement(
    Tag,
    __spreadValues({
      className: `tint-typography tint-typography--${variant} ${className}`,
      style: __spreadValues({ color, fontWeight }, styleProps)
    }, props),
    children
  );
};
