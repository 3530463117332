var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { ApiService } from "../api-service/api-service";
import axios from "axios";
import { fileChecksum } from "./fileChecksum";
export const UploadServiceURLs = {
  SCHEDULED_CONTENT: "/scheduled_contents/upload",
  FORMS: "/forms/upload",
  FONTS: "/fonts/upload",
  ASSET_CONTENT: "/assets/upload",
  RIGHTS_REQUEST: "/right_requests/terms/upload",
  POSTS: "/posts/upload"
};
export const PostAsset = (_0) => __async(void 0, [_0], function* ({ data, url }) {
  const checksum = yield fileChecksum(data);
  const payload = {
    data: {
      type: "upload",
      attributes: {
        filename: data == null ? void 0 : data.name,
        byte_size: data.size,
        content_type: data.type,
        checksum
      }
    }
  };
  return ApiService.post(url, payload);
});
export const UploadFile = (_0) => __async(void 0, [_0], function* ({ url, headers, body, onUploadProgress }) {
  return axios({
    method: "put",
    url,
    headers: __spreadValues({}, headers),
    data: body,
    onUploadProgress
  });
});
export const UploadServiceInit = {
  PostAsset,
  UploadFile
};
