import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import "./wysiwyg.sass";
import WysiwygNavigationContainer from "./components/wysiwyg-navigation/wysiwyg-navigation-container";
import useClickOutside from "../hooks/use-click-outside/use-click-outside";
import { commandIdList, wysiwygNavigationItemList } from "./components/wysiwyg-navigation/wysiwyg-navigation-options";
import * as PropTypes from "prop-types";
import WysiwygContentEditor from "./components/wysiwyg-content-editor/wysiwyg-content-editor";
import useAnimation from "../hooks/use-animation/use-animation";
import { Config } from "../../utils/config";
import { getCurrentStyle, getPropertyValueOfElement } from "./wysiwyg-container-DOM-utils";
import PreloaderComponent, { PreloaderPositionType } from "../interface/preloaders/preloader/preloader";
const Wysiwyg = ({
  id,
  onBlur,
  onNavigationOptionChoose,
  style,
  contentValue,
  onContentChange,
  onNavigationRender,
  saveSelection,
  getPrevSelection,
  textDirection,
  isNavStatic,
  onEditorInit,
  isPending,
  onCodeMode
}, ref) => {
  const node = useRef();
  const [isNavVisible, setNavVisibility] = useClickOutside(node);
  const [currentHTML, setCurrentHTML] = useState();
  const [isCodeMode, setCodeMode] = useState(false);
  const [animationState, onAnimationEnd, animationName] = useAnimation(
    isNavVisible,
    "wysiwyg-fade-in",
    "wysiwyg-fade-out"
  );
  const currentSelection = window.getSelection();
  const contentPropertiesList = wysiwygNavigationItemList.map((option) => {
    return {
      commandId: option.commandId,
      value: isNavVisible ? document.queryCommandValue(option.commandId) : "",
      key: option.value
    };
  });
  const getCSSStyle = (cssProperty) => {
    var _a, _b;
    const selectedElement = ((_a = currentSelection == null ? void 0 : currentSelection.focusNode) == null ? void 0 : _a.parentNode) || ((_b = currentSelection == null ? void 0 : currentSelection.anchorNode) == null ? void 0 : _b.parentNode);
    if (cssProperty === "fontFamily" || cssProperty === "fontSize") {
      return getCurrentStyle(selectedElement, cssProperty) ? getCurrentStyle(selectedElement, cssProperty) : getPropertyValueOfElement(selectedElement, "font");
    } else {
      return getCurrentStyle(selectedElement, cssProperty);
    }
  };
  const getContentEditableProperties = () => {
    return contentPropertiesList.reduce((obj, item) => {
      var _a;
      switch (item.commandId) {
        case commandIdList.COLOR:
          obj[item.key] = document.queryCommandValue(item.commandId);
          break;
        case commandIdList.BACKGROUND_COLOR:
          obj[item.key] = getCSSStyle("backgroundColor") || "rgba(255,255,255,0)";
          break;
        case commandIdList.FONT_SIZE:
          obj[item.key] = Number(
            getCSSStyle(commandIdList.FONT_SIZE) && ((_a = getCSSStyle(commandIdList.FONT_SIZE)) == null ? void 0 : _a.replace("px", ""))
          );
          break;
        case commandIdList.LINK:
          obj["link"] = getLinkUrl() ? getLinkUrl() : "";
          break;
        case commandIdList.FONT_FAMILY:
          obj[item.key] = document.queryCommandValue(item.commandId) ? document.queryCommandValue(item.commandId).replace(/["']/g, "") : Config.fonts.secondaryFont;
          break;
        case commandIdList.UNDERLINE:
          obj[item.key] = isQueryCommandState(item.commandId) ? "underline" : "none";
          break;
        case commandIdList.FONT_STYLE:
          obj[item.key] = isQueryCommandState(item.commandId) ? "italic" : "normal";
          break;
        case commandIdList.FONT_WEIGHT:
          obj[item.key] = isQueryCommandState(item.commandId) ? "bold" : "normal";
          break;
        case commandIdList.TEXT_ALIGN_CENTER:
        case commandIdList.TEXT_ALIGN_RIGHT:
        case commandIdList.TEXT_ALIGN_LEFT:
          obj[item.key] = isQueryCommandState(item.commandId) ? item.commandId : void 0;
          break;
        default:
          obj[item.key] = document.queryCommandValue(item.commandId);
      }
      return obj;
    }, {});
  };
  const [wysiwygProperties, setWysiwygProperties] = useState();
  const getLinkUrl = () => {
    const selectedElement = currentSelection.focusNode.parentNode || currentSelection.anchorNode.parentNode;
    return getPropertyValueOfElement(selectedElement, "href");
  };
  const isQueryCommandState = (commandId) => {
    return document.queryCommandState(commandId);
  };
  useEffect(() => {
    onNavigationRender == null ? void 0 : onNavigationRender(isNavVisible);
  }, [isNavVisible]);
  useEffect(() => {
    setCurrentHTML(
      isPending ? /* @__PURE__ */ React.createElement(PreloaderComponent, { positionType: PreloaderPositionType.RELATIVE, style: { height: "240px" } }) : /* @__PURE__ */ React.createElement(
        WysiwygContentEditor,
        {
          isPending,
          onEditorInit,
          id,
          textDirection,
          onBlur,
          onChange: onContentChange,
          onUpdateContentProperties: () => {
            setTimeout(() => {
              setWysiwygProperties(getContentEditableProperties());
            }, 0);
          },
          style,
          value: contentValue,
          ref,
          onSelectAll: onNavigationClick
        }
      )
    );
    if (isNavStatic) {
      setTimeout(() => {
        setWysiwygProperties({});
      });
    }
  }, [isCodeMode, isPending, ref]);
  const onNavigationClick = (commandId, value = "", showUI = false) => {
    onNavigationOptionChoose(commandId, value, showUI);
    setTimeout(() => {
      setWysiwygProperties(getContentEditableProperties());
    });
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      "data-testid": "tint-wysiwyg",
      className: `tint-wysiwyg ${isNavStatic ? "tint-wysiwyg--static" : ""} ${isCodeMode ? "tint-wysiwyg--code-mode" : ""}`,
      ref: node,
      onClick: () => {
        setNavVisibility(true);
      }
    },
    isNavStatic && wysiwygProperties ? /* @__PURE__ */ React.createElement(
      WysiwygNavigationContainer,
      {
        isFontFamilyDropdownVisible: false,
        onNavigationClick,
        valueProperties: wysiwygProperties,
        onSaveSelection: saveSelection,
        getPrevSelection,
        onCodeModeClick: () => {
          setCodeMode((s) => !s);
          onCodeMode == null ? void 0 : onCodeMode();
        }
      }
    ) : animationState && wysiwygProperties && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-wysiwyg__navigation",
        onAnimationEnd,
        style: {
          animation: `${animationName} 0.2s`
        }
      },
      /* @__PURE__ */ React.createElement(
        WysiwygNavigationContainer,
        {
          onNavigationClick,
          valueProperties: wysiwygProperties,
          onSaveSelection: saveSelection,
          getPrevSelection
        }
      )
    ),
    isCodeMode ? /* @__PURE__ */ React.createElement("code", null, /* @__PURE__ */ React.createElement(
      "textarea",
      {
        ref,
        onKeyDown: (e) => {
          onContentChange(e.target.value);
        },
        onChange: (e) => {
          onContentChange(e.target.value);
        },
        defaultValue: contentValue
      }
    )) : currentHTML
  );
};
export default memo(forwardRef(Wysiwyg));
Wysiwyg.propTypes = {
  onBlur: PropTypes.func,
  onNavigationOptionChoose: PropTypes.func,
  styles: PropTypes.any,
  contentValue: PropTypes.string,
  onContentChange: PropTypes.func,
  onNavigationRender: PropTypes.func,
  saveSelection: PropTypes.func,
  getPrevSelection: PropTypes.func
};
