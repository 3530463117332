var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { Button } from "../../../../../../../../components/interface/button/button";
import { FileStack } from "../../../../../../../../services/filestack/file-stack";
import "./image-content.sass";
export class ImageContent extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "onUploadClick", () => {
      return new FileStack().openFilePicker({
        accept: ["image/*"],
        maxSize: this.props.maxFileSize,
        onUploadDone: (res) => {
          if (res.filesUploaded.length > 0) {
            this.props.onContentUpdate({
              mediaUrl: res.filesUploaded[0].url,
              mediaName: res.filesUploaded[0].filename
            });
          }
        }
      });
    });
    __publicField(this, "renderImagePreview", () => {
      return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-header-content__center" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "tint-edit-upload-design__image",
          style: {
            backgroundImage: `url(${this.props.data.content.mediaUrl})`
          }
        }
      ));
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Edit Image", className: "image-content" }, /* @__PURE__ */ React.createElement(
      "form",
      {
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: this.onUploadClick,
          type: "gray",
          size: "full-width",
          text: "Upload media",
          icon: "far fa-arrow-to-top",
          className: "tint-edit-answer__upload-button"
        }
      ),
      this.props.data.content.mediaUrl === void 0 ? null : this.renderImagePreview(),
      this.props.data.content.mediaName !== "" && /* @__PURE__ */ React.createElement("h1", { className: "edit-media__file-name" }, "Current file name: ", this.props.data.content.mediaName),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit")
    ));
  }
}
