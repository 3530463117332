var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import "./feed-card-horizontal.sass";
import { CheckboxSelect } from "../../../../../components/interface/inputs/checkbox-select/checkbox-select";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPosts } from "../../../actions/posts.actions";
import { formatDistanceToNow, parseISO } from "date-fns";
import { GuardNames } from "../../../../../guards/guards-list";
import StatusButton, { POST_STATUS } from "../status-button/status-button";
import FeedCardIcons from "../feed-card-icons/feed-card-icons";
import { Button } from "../../../../../components/interface/button/button";
import StarRating from "../star-rating/star-rating";
import TintIcon from "../../../../../components/interface/icon/icon";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faMessages } from "@fortawesome/pro-solid-svg-icons";
import { faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { getPostCTAs } from "../../../reducers/tint-editor.reducer";
import Tooltip from "../../../../transform-ui/components/tooltip/tooltip";
import { TintEditorConfig } from "../../../utils/tint-editor.config";
import { getTintName } from "../../../reducers/posts.reducer";
import FeatureGuard from "../../../../../guards/feature-guard";
import {
  Typography,
  TypographyFontWeights,
  TypographyVariants
} from "../../../../../components/interface/typography/typography";
import { FeedCardHorizontalComments } from "./comments/feed-card-horizontal.comments";
import { DropdownHeader } from "../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSimpleList from "../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import DropdownButton, { ButtonType } from "../../../../../components/interface/dropdown/button/dropdown-button";
import { updatePosts } from "../../../actions/posts.fat-actions";
import { isAllowed } from "../../../../../services/functions/features-checker/features-checker";
import { Config } from "../../../../../utils/config";
import { MEDIA_TYPE } from "../../../../../feature/insights/components/graphs/performance/engagement-by-type/engagement-by-type";
const rejectForData = [
  { value: "leagal_transgression", name: "Legal Transgression" },
  { value: "inappropriate", name: "Profanity/Inappropriate" },
  { value: "foreign_language", name: "Foreign Language" },
  { value: "contains_private_info", name: "Contains Private Info" },
  { value: "unrelated", name: "Unrelated to Product or Service" },
  { value: "misleading", name: "False or Misleading" },
  { value: "fake", name: "Fake" }
];
const MAX_POST_LENGTH = 150;
const FeedCardHorizontal = ({
  post = {},
  tintId,
  onClickEcommerce,
  onPinFeed,
  onClick,
  maxIconCardLength,
  getTintEditorPosts
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const shouldTruncate = ((_a = post.attributes.text) == null ? void 0 : _a.length) > MAX_POST_LENGTH && !showMore;
  const [commentsVisibility, setCommentsVisibility] = useState(false);
  const CTAs = useSelector((state) => getPostCTAs(state, post.id));
  const tintEditorPosts = useSelector((state) => state.tintEditorPosts);
  const selectedPosts = tintEditorPosts == null ? void 0 : tintEditorPosts.selectedPosts;
  const isChecked = selectedPosts.find((selectedPost) => selectedPost.id === post.id);
  const publishedAt = (_b = post == null ? void 0 : post.attributes) == null ? void 0 : _b.published_at;
  const time = publishedAt ? formatDistanceToNow(parseISO(publishedAt), { addSuffix: true }) : "";
  const session = useSelector((state) => state.session);
  const featureList = session.data.features;
  const hasAccessToComments = isAllowed(featureList, [Config.features.postsReplies]);
  const hasAccessToRejectPost = isAllowed(featureList, [Config.features.postsStatusReasons]);
  const select = () => {
    const updatedSelectPosts = isChecked ? selectedPosts.filter((selectedPost) => selectedPost.id !== post.id) : [...selectedPosts, post];
    dispatch(updateSelectedPosts(updatedSelectPosts));
  };
  const isGlobalPageState = tintId === TintEditorConfig.routePaths.global.tintId;
  const tintName = isGlobalPageState && useSelector((state) => {
    var _a2, _b2, _c2;
    return getTintName(state, (_c2 = (_b2 = (_a2 = post == null ? void 0 : post.relationships) == null ? void 0 : _a2.tint) == null ? void 0 : _b2.data) == null ? void 0 : _c2.id);
  });
  const isTextOnlyPost = ((_c = post == null ? void 0 : post.attributes) == null ? void 0 : _c.media_type) === MEDIA_TYPE.TEXT_ONLY;
  const renderTags = () => {
    const allPosts = post.siblings ? [post, ...post.siblings] : [post];
    const commonTags = allPosts.reduce((common, currentPost) => {
      return common.filter((tag) => currentPost.attributes.tags.includes(tag));
    }, allPosts[0].attributes.tags);
    return commonTags.join(", ");
  };
  const hasTags = ((_d = post.attributes.tags) == null ? void 0 : _d.length) > 0;
  const patchedPosts = (patchedData) => {
    return (post.siblings ? [post, ...post.siblings] : [post]).map((p) => ({
      id: p.id,
      type: p.type,
      attributes: __spreadValues({}, patchedData)
    }));
  };
  const onReject = (e) => {
    dispatch(
      updatePosts({
        updatedPosts: patchedPosts({ status_reason: e.value, status: "deleted" })
      })
    ).then(() => getTintEditorPosts());
  };
  function renderAuthor() {
    var _a2, _b2, _c2, _d2, _e2, _f2;
    const author = (_a2 = post == null ? void 0 : post.attributes) == null ? void 0 : _a2.author;
    const authorUrl = (_b2 = post == null ? void 0 : post.attributes) == null ? void 0 : _b2.author.url;
    const name = (_d2 = (_c2 = post == null ? void 0 : post.attributes) == null ? void 0 : _c2.author) == null ? void 0 : _d2.name;
    const userName = (_f2 = (_e2 = post == null ? void 0 : post.attributes) == null ? void 0 : _e2.author) == null ? void 0 : _f2.username;
    return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-author__author-meta" }, (author == null ? void 0 : author.userName) && /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.H4 }, /* @__PURE__ */ React.createElement("a", { href: authorUrl, target: "_blank", rel: "noopener noreferrer" }, author.userName)), (author == null ? void 0 : author.name) && /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.H4 }, /* @__PURE__ */ React.createElement("a", { href: authorUrl, target: "_blank", rel: "noopener noreferrer" }, author.name)));
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "div",
    {
      style: { borderRadius: commentsVisibility ? "6px 6px 0 0" : "6px" },
      className: "tint-editor-feed-card-horizontal"
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__settings" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__settings-left" }, /* @__PURE__ */ React.createElement(
      CheckboxSelect,
      {
        onClick: select,
        isChecked,
        checkboxId: post == null ? void 0 : post.id,
        style: { margin: "0 4px 0 0", width: "16px", height: "16px" }
      }
    ), /* @__PURE__ */ React.createElement(StarRating, { rating: post.attributes.rating })), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__settings-right" }, /* @__PURE__ */ React.createElement(
      FeedCardIcons,
      {
        withFindSimilar: false,
        withMore: false,
        withTag: false,
        withCTA: false,
        withSiblings: true,
        iconLength: maxIconCardLength,
        isGlobalPageState,
        isSelected: isChecked,
        tintId,
        post,
        onClickEcommerce,
        onPinFeed
      }
    ), /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.MODERATE_TINT }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__moderate" }, /* @__PURE__ */ React.createElement(
      Tooltip,
      {
        component: /* @__PURE__ */ React.createElement(
          StatusButton,
          {
            getTintEditorPosts,
            status: (_e = post == null ? void 0 : post.attributes) == null ? void 0 : _e.status,
            postId: post.siblings ? [post, ...post.siblings].map((e) => e.id) : [post.id]
          }
        ),
        text: ((_f = post == null ? void 0 : post.attributes) == null ? void 0 : _f.status) !== POST_STATUS.PUBLIC ? "Make visible" : "Make private"
      }
    ))))),
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__body" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__author" }, renderAuthor(), /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.DESCRIPTION }, time)), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__container" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-editor-feed-card-horizontal__description ${isTextOnlyPost ? "tint-editor-feed-card-horizontal__description--clickable" : ""}`,
        onClick: () => {
          if (isTextOnlyPost) onClick(post);
        }
      },
      post.attributes.title && /* @__PURE__ */ React.createElement(Typography, { component: "h4", variant: TypographyVariants.H4 }, post.attributes.title),
      ((_g = post.attributes) == null ? void 0 : _g.text) && /* @__PURE__ */ React.createElement("p", null, shouldTruncate ? `${post.attributes.text.slice(0, MAX_POST_LENGTH)}...` : post.attributes.text)
    ), ((_h = post.attributes.text) == null ? void 0 : _h.length) > MAX_POST_LENGTH && /* @__PURE__ */ React.createElement("span", { onClick: () => setShowMore(!showMore), className: "tint-editor-feed-card-horizontal__show-more" }, showMore ? "Show less" : "Show more"), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__siblings-container" }, (post.siblings ? [post, ...post.siblings] : [post]).map((e, i) => {
      var _a2, _b2, _c2;
      return e.attributes.image_url ? /* @__PURE__ */ React.createElement("div", { key: i, className: "sibling-image-container", onClick: () => onClick(e) }, /* @__PURE__ */ React.createElement("div", { className: "sibling-image-container__photo" }, /* @__PURE__ */ React.createElement(
        "img",
        {
          alt: ((_a2 = e == null ? void 0 : e.attributes) == null ? void 0 : _a2.alternative_text) || ((_b2 = e == null ? void 0 : e.attributes) == null ? void 0 : _b2.text) || ((_c2 = e == null ? void 0 : e.attributes) == null ? void 0 : _c2.author.name),
          src: e.attributes.image_url
        }
      ), /* @__PURE__ */ React.createElement("div", { className: "sibling-image-container__circle" }, e.attributes.status === "private" ? /* @__PURE__ */ React.createElement("div", { className: "sibling-image-container__icon-hidden" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faEyeSlash })) : null, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: `sibling-image-container__icon-resize ${e.attributes.status !== "private" ? "sibling-image-container__icon-resize--visible" : ""}`
        },
        /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faUpRightAndDownLeftFromCenter })
      ))), e.attributes.status === "private" ? /* @__PURE__ */ React.createElement("div", { className: "sibling-image-container__cover-private" }) : null) : null;
    })))),
    isGlobalPageState && /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__bar tint-editor-feed-card-horizontal__bar--tint-name" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faGrid2, fontSize: "16px" }), /* @__PURE__ */ React.createElement("span", null, tintName)),
    /* @__PURE__ */ React.createElement("footer", { className: "tint-editor-feed-card-horizontal__footer" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__footer-left" }, !hasAccessToComments ? null : ((_i = post == null ? void 0 : post.attributes) == null ? void 0 : _i.type) !== "public_post" ? null : commentsVisibility ? /* @__PURE__ */ React.createElement(Button, { icon: faMessages, type: "secondary", onClick: () => setCommentsVisibility(false) }, "Close Comments") : /* @__PURE__ */ React.createElement(
      Button,
      {
        icon: faMessages,
        iconColor: "#70757F",
        type: "light-gray",
        onClick: () => setCommentsVisibility(true)
      },
      `Comments (${((_j = post == null ? void 0 : post.replies) == null ? void 0 : _j.length) || 0})`
    )), hasTags && /* @__PURE__ */ React.createElement("div", { className: "tint-editor-feed-card-horizontal__bar" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faTags, fontSize: "16px" }), /* @__PURE__ */ React.createElement("span", null, renderTags()))))
  ), commentsVisibility && /* @__PURE__ */ React.createElement(FeedCardHorizontalComments, { post, getTintEditorPosts }));
};
export default React.memo(FeedCardHorizontal);
