import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Config } from "../../utils/config";
const TintYoutubePlayer = ({ id, playerId, isMuted = true, onPlayerLoad, onPlayerError, autoPlay = true, loop = true }, ref) => {
  const playerNameId = `youtube-player-${id}`;
  const [player, setPlayer] = useState();
  useEffect(() => {
    createYoutubeScript(Config.url.youTubeEmbedScript);
  }, []);
  const createYoutubeScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    window.YT && window.YT.Player ? onYouTubeIframeAPIReady() : appendScript(script);
  };
  const appendScript = (script) => {
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    script.addEventListener("load", () => {
      onYouTubeIframeAPIReady();
    });
    script.addEventListener("error", () => {
      onPlayerError && onPlayerError();
    });
  };
  const onYouTubeIframeAPIReady = () => {
    window.YT.ready(() => {
      new window.YT.Player(playerNameId, {
        videoId: playerId,
        playerVars: {
          origin: window.location.href,
          autoplay: autoPlay,
          loop,
          playlist: playerId
        },
        events: {
          onReady: (event) => {
            setPlayer(event.target);
            event.target.setLoop(true);
            isMuted && event.target.mute();
            onPlayerLoad && onPlayerLoad();
          },
          onError: onPlayerError
        }
      });
    });
  };
  useImperativeHandle(ref, () => ({
    playVideo: () => {
      player && player.playVideo();
    },
    stopVideo: () => {
      player && player.seekTo(0.1, true);
      player && player.pauseVideo();
    }
  }));
  return /* @__PURE__ */ React.createElement("div", { className: "tint-youtube-player", id: playerNameId });
};
export const TintYoutubePlayerRef = forwardRef(TintYoutubePlayer);
