var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/submissions";
const initialState = {
  selectedSubmission: void 0,
  selectedSubmissionIndex: void 0,
  submissionsExperience: void 0,
  data: void 0,
  isFetching: false,
  error: void 0,
  isAnalyticsDisplayed: false,
  metrics: void 0
};
const submissions = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUBMISSION_METRICS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), { metrics: action.payload });
    case ACTIONS.GET_SUBMISSIONS.REQUEST:
    case ACTIONS.GET_SUBMISSIONS_EXPERIENCE.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false
      });
    case ACTIONS.GET_SUBMISSIONS.SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_SUBMISSIONS_EXPERIENCE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), { submissionsExperience: action.payload });
    case ACTIONS.GET_SUBMISSIONS.FAILURE:
    case ACTIONS.GET_SUBMISSIONS_EXPERIENCE.FAILURE:
      return Object.assign({}, state, {
        isError: action.payload,
        isFetching: false
      });
    case ACTIONS.SELECT_SUBMISSION:
      return __spreadProps(__spreadValues({}, state), {
        selectedSubmission: action.payload.submission,
        selectedSubmissionIndex: action.payload.submissionIndex
      });
    case ACTIONS.DELETE_SUBMISSIONS.REQUEST:
      return __spreadProps(__spreadValues({}, state), { isFetching: true, isError: false });
    case ACTIONS.DELETE_SUBMISSIONS.SUCCESS:
    case ACTIONS.DELETE_SUBMISSIONS.FAILURE:
      return __spreadProps(__spreadValues({}, state), { isError: action.payload, isFetching: false });
    case ACTIONS.DISPLAY_ANALYTICS:
      return __spreadProps(__spreadValues({}, state), { isAnalyticsDisplayed: true });
    case ACTIONS.HIDE_ANALYTICS:
      return __spreadProps(__spreadValues({}, state), { isAnalyticsDisplayed: false });
    case ACTIONS.CLEAR_SUBMISSIONS_STATE: {
      return __spreadValues({}, initialState);
    }
    default:
      return state;
  }
};
export default submissions;
