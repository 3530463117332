import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { FORM_BUILDER } from "../../../../actions/consts";
export const ACTIONS = {
  ADD_FORM_ELEMENT: createPrefixedActionName("FORM_BUILDER", "ADD_FORM_ELEMENT"),
  SELECT_ELEMENT: createPrefixedActionName("FORM_BUILDER", "SELECT_ELEMENT"),
  CANCEL_ELEMENT_SELECTION: createPrefixedActionName("FORM_BUILDER", "CANCEL_ELEMENT_SELECTION"),
  REORDER_ELEMENT: createPrefixedActionName("FORM_BUILDER", "REORDER_ELEMENT"),
  DELETE_FORM_ELEMENT: createPrefixedActionName("FORM_BUILDER", "DELETE_ELEMENT"),
  PATCH_FORM: createRoutine(createPrefixedActionName("FORM_BUILDER", "PATCH_FORM")),
  GET_FORM: createRoutine(createPrefixedActionName("FORM_BUILDER", "GET_FORM")),
  ADD_CLASS: createPrefixedActionName("FORM_BUILDER", "ADD_CLASS"),
  UPDATE_NODE_NAME_PROPERTY: createPrefixedActionName("FORM_BUILDER", "UPDATE_NODE_NAME_PROPERTY"),
  CHANGE_POST_SUBMIT_ACTION: createPrefixedActionName("FORM_BUILDER", "CHANGE_POST_SUBMIT_ACTION"),
  UPDATE_WORKFLOWS_PROPERTIES: createPrefixedActionName("FORM_BUILDER", "UPDATE_WORKFLOWS_PROPERTIES"),
  UPDATE_ELEMENT_PROPERTY: createPrefixedActionName("FORM_BUILDER", "UPDATE_ELEMENT_PROPERTY"),
  UPDATE_ELEMENT_PROPERTY_BY_ID: createPrefixedActionName("FORM_BUILDER", "UPDATE_ELEMENT_PROPERTY_BY_ID"),
  UPDATE_ELEMENT_PROPERTY_BY_UNIQUE_PROPERTY: createPrefixedActionName(
    "FORM_BUILDER",
    "UPDATE_ELEMENT_PROPERTY_BY_UNIQUE_PROPERTY"
  ),
  CHANGE_SINGLE_MATRIX_VALUES: createPrefixedActionName("FORM_BUILDER", "CHANGE_SINGLE_MATRIX_VALUES"),
  CHANGE_SINGLE_MATRIX_ELEMENT_ID: createPrefixedActionName("FORM_BUILDER", "CHANGE_SINGLE_MATRIX_ELEMENT_ID"),
  ADD_SINGLE_MATRIX_OPTIONS: createPrefixedActionName("FORM_BUILDER", "ADD_SINGLE_MATRIX_OPTIONS"),
  DELETE_SINGLE_MATRIX_OPTIONS: createPrefixedActionName("FORM_BUILDER", "DELETE_SINGLE_MATRIX_OPTIONS"),
  REMOVE_CLASS: createPrefixedActionName("FORM_BUILDER", "REMOVE_CLASS"),
  UPLOAD_IMAGE: createPrefixedActionName("FORM_BUILDER", "UPLOAD_IMAGE"),
  UPDATE_STYLES: createPrefixedActionName("FORM_BUILDER", "UPDATE_STYLES"),
  UPDATE_STYLE: createPrefixedActionName("FORM_BUILDER", "UPDATE_STYLE"),
  UPDATE_TRANSLATION: createPrefixedActionName("FORM_BUILDER", "UPDATE_TRANSLATION"),
  UPDATE_TRANSLATIONS: createPrefixedActionName("FORM_BUILDER", "UPDATE_TRANSLATIONS"),
  ADD_LOCALES: createPrefixedActionName("FORM_BUILDER", "ADD_LOCALES"),
  REMOVE_LOCALES: createPrefixedActionName("FORM_BUILDER", "REMOVE_LOCALES"),
  ADD_DEFAULT_LOCALES: createPrefixedActionName("FORM_BUILDER", "ADD_DEFAULT_LOCALES"),
  SET_CURRENT_LANGUAGE: createPrefixedActionName("FORM_BUILDER", "SET_CURRENT_LANGUAGE"),
  PUBLISH_FORM: createRoutine(createPrefixedActionName("FORM_BUILDER", "PUBLISH_FORM")),
  ADD_SUCCESS_MESSAGE_LINK: createPrefixedActionName("FORM_BUILDER", "ADD_SUCCESS_MESSAGE_LINK"),
  PATCH_SUCCESS_MESSAGE_LINK: createPrefixedActionName("FORM_BUILDER", "PATCH_SUCCESS_MESSAGE_LINK"),
  CHANGE_RATING_OPTIONS_COUNT: createPrefixedActionName("FORM_BUILDER", "CHANGE_RATING_OPTIONS_COUNT"),
  CHANGE_NUMBER_SCALE_OPTIONS_COUNT: createPrefixedActionName("FORM_BUILDER", "CHANGE_NUMBER_SCALE_OPTIONS_COUNT"),
  ADD_NESTED_FORM_ELEMENT: createPrefixedActionName("FORM_BUILDER", "ADD_NESTED_FORM_ELEMENT"),
  ADD_NESTED_FORM_ELEMENT_NEW: createPrefixedActionName("FORM_BUILDER", "ADD_NESTED_FORM_ELEMENT_NEW"),
  ADD_MISSING_HINTS: createPrefixedActionName("FORM_BUILDER", "ADD_MISSING_HINTS"),
  ADD_FILE_UPLOAD_TRANSLATIONS: createPrefixedActionName("FORM_BUILDER", "ADD_FILE_UPLOAD_TRANSLATIONS"),
  DELETE_NESTED_FORM_ELEMENT: createPrefixedActionName("FORM_BUILDER", "DELETE_NESTED_FORM_ELEMENT"),
  RESET_STATE: createPrefixedActionName(FORM_BUILDER, "RESET_STATE")
};
export const uploadImage = (file) => ({
  type: ACTIONS.UPLOAD_IMAGE,
  payload: file
});
export const setCurrentLanguage = (lang) => ({
  type: ACTIONS.SET_CURRENT_LANGUAGE,
  payload: lang
});
export const addLocales = (lang, locales) => ({
  type: ACTIONS.ADD_LOCALES,
  payload: { lang, locales }
});
export const removeLocales = (lang) => ({
  type: ACTIONS.REMOVE_LOCALES,
  payload: { lang }
});
export const addMissingHints = () => ({
  type: ACTIONS.ADD_MISSING_HINTS
});
export const addFileUploadTranslations = () => ({
  type: ACTIONS.ADD_FILE_UPLOAD_TRANSLATIONS
});
export const addDefaultLocales = () => ({
  type: ACTIONS.ADD_DEFAULT_LOCALES
});
export const addFormBuilderClass = (group, element, className) => ({
  type: ACTIONS.ADD_CLASS,
  payload: { group, element, className }
});
export const removeFormBuilderClass = (group, element, className) => ({
  type: ACTIONS.REMOVE_CLASS,
  payload: { group, element, className }
});
export const updateFormBuilderNodeNameProperty = (group, element, nodeName, property, value) => ({
  type: ACTIONS.UPDATE_NODE_NAME_PROPERTY,
  payload: { group, element, nodeName, property, value }
});
export const updateWorkflowsProperties = ({ accountType, mapping, accountId, emailType, emailTemplateId }) => ({
  type: ACTIONS.UPDATE_WORKFLOWS_PROPERTIES,
  payload: { accountType, mapping, accountId, emailType, emailTemplateId }
});
export const changeFormPostSubmitAction = (payload) => ({
  type: ACTIONS.CHANGE_POST_SUBMIT_ACTION,
  payload
});
export const updateFormBuilderElementProperty = (group, element, selectedSubElementIndex, property, value) => ({
  type: ACTIONS.UPDATE_ELEMENT_PROPERTY,
  payload: { group, element, selectedSubElementIndex, property, value }
});
export const updateFormBuilderElementPropertyByUniqueProperty = ({ propertyNameId, id, property, value }) => ({
  type: ACTIONS.UPDATE_ELEMENT_PROPERTY_BY_UNIQUE_PROPERTY,
  payload: { propertyNameId, property, value, id }
});
export const updateFormBuilderElementPropertyById = ({ id, property, value, addDefault }) => ({
  type: ACTIONS.UPDATE_ELEMENT_PROPERTY_BY_ID,
  payload: { property, value, id, addDefault }
});
export const updateFormBuilderStyle = (id, style) => ({
  type: ACTIONS.UPDATE_STYLE,
  payload: { id, style }
});
export const updateFormBuilderStyles = (style) => ({
  type: ACTIONS.UPDATE_STYLES,
  payload: { style }
});
export const updateTranslation = (lang, key, value) => ({
  type: ACTIONS.UPDATE_TRANSLATION,
  payload: { lang, key, value }
});
export const updateTranslations = (translations) => ({
  type: ACTIONS.UPDATE_TRANSLATIONS,
  payload: { translations }
});
export const deleteFormElement = (elementIndex) => ({
  type: ACTIONS.DELETE_FORM_ELEMENT,
  payload: elementIndex
});
export const reorderElement = (e) => ({
  type: ACTIONS.REORDER_ELEMENT,
  payload: e
});
export const getFormStart = () => ({
  type: ACTIONS.GET_FORM.REQUEST
});
export const getFormSuccess = (form) => ({
  type: ACTIONS.GET_FORM.SUCCESS,
  payload: form
});
export const getFormError = (err) => ({
  type: ACTIONS.GET_FORM.FAILURE,
  payload: err
});
export const patchFormStart = () => ({
  type: ACTIONS.PATCH_FORM.REQUEST
});
export const patchFormSuccess = (form) => ({
  type: ACTIONS.PATCH_FORM.SUCCESS,
  payload: form
});
export const patchFormError = (err) => ({
  type: ACTIONS.PATCH_FORM.FAILURE,
  payload: err
});
export const selectElement = (e, index) => ({
  type: ACTIONS.SELECT_ELEMENT,
  payload: { e, index }
});
export const cancelElementSelection = () => ({
  type: ACTIONS.CANCEL_ELEMENT_SELECTION
});
export const addFormElement = (e) => ({
  type: ACTIONS.ADD_FORM_ELEMENT,
  payload: e
});
export const publishFormStart = () => ({
  type: ACTIONS.PUBLISH_FORM.REQUEST
});
export const publishFormSuccess = () => ({
  type: ACTIONS.PUBLISH_FORM.SUCCESS
});
export const publishFormError = () => ({
  type: ACTIONS.PUBLISH_FORM.FAILURE
});
export const addSuccessMessageLink = (newLink) => ({
  type: ACTIONS.ADD_SUCCESS_MESSAGE_LINK,
  payload: newLink
});
export const patchSuccessMessageLink = (data, key, nodeName) => ({
  type: ACTIONS.PATCH_SUCCESS_MESSAGE_LINK,
  payload: { data, key, nodeName }
});
export const changeRatingOptionsCount = (group, element, value) => ({
  type: ACTIONS.CHANGE_RATING_OPTIONS_COUNT,
  payload: { group, element, value }
});
export const changeSingleMatrixValues = ({ value, elementIndex, selectedElementIndex, changeQuestion }) => ({
  type: ACTIONS.CHANGE_SINGLE_MATRIX_VALUES,
  payload: { value, elementIndex, selectedElementIndex, changeQuestion }
});
export const changeSingleMatrixElementId = ({ value, selectedElementIndex }) => ({
  type: ACTIONS.CHANGE_SINGLE_MATRIX_ELEMENT_ID,
  payload: { value, selectedElementIndex }
});
export const addSingleMatrixOptions = ({ selectedElementIndex, addQuestion, newOption, newTheadElement }) => ({
  type: ACTIONS.ADD_SINGLE_MATRIX_OPTIONS,
  payload: { selectedElementIndex, addQuestion, newOption, newTheadElement }
});
export const deleteSingleMatrixOptions = ({ elementIndex, selectedElementIndex, deleteQuestion }) => ({
  type: ACTIONS.DELETE_SINGLE_MATRIX_OPTIONS,
  payload: { selectedElementIndex, elementIndex, deleteQuestion }
});
export const changeNumberScaleOptionsCount = (group, elementIndex, newElements) => ({
  type: ACTIONS.CHANGE_NUMBER_SCALE_OPTIONS_COUNT,
  payload: { group, elementIndex, newElements }
});
export const addNestedFormElement = (group, elementIndex, element) => ({
  type: ACTIONS.ADD_NESTED_FORM_ELEMENT,
  payload: { group, elementIndex, element }
});
export const deleteNestedFormElement = (group, selectedElementIndex, deleteElementIndex, parentId, property, deleteElementId, deleteAll) => ({
  type: ACTIONS.DELETE_NESTED_FORM_ELEMENT,
  payload: { group, selectedElementIndex, deleteElementIndex, parentId, property, deleteElementId, deleteAll }
});
export const addNestedFormElementNew = ({ parentId, element, replaceOldWithNew }) => ({
  type: ACTIONS.ADD_NESTED_FORM_ELEMENT_NEW,
  payload: { parentId, element, replaceOldWithNew }
});
export const resetState = () => ({
  type: ACTIONS.RESET_STATE
});
