import { createRoutine, createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { EXPERIENCE } from "../../consts";
export const ACTIONS = {
  GET_EXPERIENCE: createRoutine(createPrefixedActionName(EXPERIENCE, "GET_EXPERIENCE")),
  POST_EXPERIENCE: createRoutine(createPrefixedActionName(EXPERIENCE, "POST_EXPERIENCE")),
  PATCH_EXPERIENCE: createRoutine(createPrefixedActionName(EXPERIENCE, "PATCH_EXPERIENCE")),
  PUT_EXPERIENCE: createRoutine(createPrefixedActionName(EXPERIENCE, "PUT_EXPERIENCE")),
  DELETE_EXPERIENCE: createRoutine(createPrefixedActionName(EXPERIENCE, "DELETE_EXPERIENCE")),
  DELETE_MULTI_COLUMN_SECTION: createPrefixedActionName(EXPERIENCE, "DELETE_MULTI_COLUMN_SECTION"),
  ADD_CUSTOM_EXPERIENCE: createPrefixedActionName(EXPERIENCE, "ADD_CUSTOM_EXPERIENCE"),
  PUBLISH_EXPERIENCE: createPrefixedActionName(EXPERIENCE, "PUBLISH_EXPERIENCE"),
  DELETE_SECTION: createPrefixedActionName(EXPERIENCE, "DELETE_SECTION"),
  REORDER_EXPERIENCE: createPrefixedActionName(EXPERIENCE, "REORDER"),
  REORDER_MULTI_COLUMN_EXPERIENCE: createPrefixedActionName(EXPERIENCE, "REORDER_MULTI_COLUMN"),
  SAVE_DATA_ATTRIBUTES_DRAFT: createPrefixedActionName(EXPERIENCE, "SAVE_DATA_ATTRIBUTES_DRAFT"),
  SAVE_DATA_CONTENT_DRAFT: createPrefixedActionName(EXPERIENCE, "SAVE_DATA_CONTENT_DRAFT"),
  SAVE_DATA_STYLES_DRAFT: createPrefixedActionName(EXPERIENCE, "SAVE_DATA_STYLES_DRAFT"),
  SAVE_DATA_MULTI_COLUMN_STYLES_DRAFT: createPrefixedActionName(EXPERIENCE, "SAVE_DATA_MULTI_COLUMN_STYLES_DRAFT"),
  SAVE_DATA_MULTI_COLUMN_CONTENT_DRAFT: createPrefixedActionName(EXPERIENCE, "SAVE_DATA_MULTI_COLUMN_CONTENT_DRAFT"),
  SAVE_CUSTOMIZABLE_TEXT_DRAFT: createPrefixedActionName(EXPERIENCE, "SAVE_CUSTOMIZABLE_TEXT_DRAFT"),
  SAVE_CUSTOMIZABLE_MULTI_COLUMN_TEXT_DRAFT: createPrefixedActionName(
    EXPERIENCE,
    "SAVE_CUSTOMIZABLE_MULTI_COLUMN_TEXT_DRAFT"
  ),
  APPLY_DRAFT_DATA: createPrefixedActionName(EXPERIENCE, "APPLY_DRAFT_DATA"),
  DISCARD_DRAFT_DATA: createPrefixedActionName(EXPERIENCE, "DISCARD_DRAFT_DATA"),
  // probably there will be a lot of questions regarding this clear experience action
  // I was not sure how to handle moving from experiences to -> /experience/:id view where this both views use the same API requests
  // for now I've decide to use clear experience which looks ugly since it's manipulating state and needs to be called before going to /experience/:id
  CLEAR_EXPERIENCE: createPrefixedActionName(EXPERIENCE, "CLEAR_EXPERIENCE"),
  ADD_FONT_FAMILY: createPrefixedActionName(EXPERIENCE, "ADD_FONT_FAMILY"),
  POST_CUSTOM_FONT: createPrefixedActionName(EXPERIENCE, "POST_CUSTOM_FONT"),
  DELETE_FONT_FAMILY: createPrefixedActionName(EXPERIENCE, "DELETE_FONT_FAMILY"),
  ON_DROPDOWN_SELECT: createPrefixedActionName(EXPERIENCE, "ON_DROPDOWN_SELECT"),
  ADD_CUSTOM_LANGUAGE: createPrefixedActionName(EXPERIENCE, "ADD_CUSTOM_LANGUAGE"),
  DELETE_CUSTOM_LANGUAGE: createPrefixedActionName(EXPERIENCE, "DELETE_CUSTOM_LANGUAGE"),
  SET_DEFAULT_LANGUAGE: createPrefixedActionName(EXPERIENCE, "SET_DEFAULT_LANGUAGE")
};
export const getExperienceStart = () => ({
  type: ACTIONS.GET_EXPERIENCE.REQUEST
});
export const getExperienceSuccess = (experience) => ({
  type: ACTIONS.GET_EXPERIENCE.SUCCESS,
  payload: experience
});
export const getExperienceError = (err) => ({
  type: ACTIONS.GET_EXPERIENCE.FAILURE,
  payload: err
});
export const postExperienceStart = () => ({
  type: ACTIONS.POST_EXPERIENCE.REQUEST
});
export const postExperienceSuccess = (experience) => ({
  type: ACTIONS.POST_EXPERIENCE.SUCCESS,
  payload: experience
});
export const postExperienceError = (err) => ({
  type: ACTIONS.POST_EXPERIENCE.FAILURE,
  payload: err
});
export const patchExperienceStart = () => ({
  type: ACTIONS.PATCH_EXPERIENCE.REQUEST
});
export const patchExperienceSuccess = (experience) => ({
  type: ACTIONS.PATCH_EXPERIENCE.SUCCESS,
  payload: experience
});
export const patchExperienceError = (err) => ({
  type: ACTIONS.PATCH_EXPERIENCE.FAILURE,
  payload: err
});
export const putExperienceStart = () => ({
  type: ACTIONS.PUT_EXPERIENCE.REQUEST
});
export const putExperienceSuccess = (experience) => ({
  type: ACTIONS.PUT_EXPERIENCE.SUCCESS,
  payload: experience
});
export const putExperienceError = (err) => ({
  type: ACTIONS.PUT_EXPERIENCE.FAILURE,
  payload: err
});
export const deleteExperienceStart = () => ({
  type: ACTIONS.DELETE_EXPERIENCE.REQUEST
});
export const deleteExperienceSuccess = (experience) => ({
  type: ACTIONS.DELETE_EXPERIENCE.SUCCESS,
  payload: experience
});
export const deleteExperienceError = (err) => ({
  type: ACTIONS.DELETE_EXPERIENCE.FAILURE,
  payload: err
});
export const discardDraftData = () => ({
  type: ACTIONS.DISCARD_DRAFT_DATA
});
export const clearExperience = () => ({
  type: ACTIONS.CLEAR_EXPERIENCE
});
export const addCustomFont = (customFont) => {
  return {
    type: ACTIONS.POST_CUSTOM_FONT,
    payload: customFont
  };
};
export const deleteCustomLanguage = (languageIndex) => {
  return {
    type: ACTIONS.DELETE_CUSTOM_LANGUAGE,
    payload: languageIndex
  };
};
export const addCustomLanguage = (newLanguage) => {
  return {
    type: ACTIONS.ADD_CUSTOM_LANGUAGE,
    payload: newLanguage
  };
};
export const setDefaultLanguage = (lang) => {
  return {
    type: ACTIONS.SET_DEFAULT_LANGUAGE,
    payload: lang
  };
};
