var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./tag-o-war-section.sass";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import PropTypes from "prop-types";
import { TagOWarContent } from "./edit/tag-o-war-content";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import TagOWarStyles from "./edit/tag-o-war-styles";
import TagOWarSection from "./tag-o-war-section";
import {
  getMetrics,
  subscribeOnWebsocket,
  getSocialFeeds,
  getSocialFeedsPosts
} from "../../../../../../services/functions/url-config/url-config";
import TagOWarSectionWinner from "./components/winner/tag-o-war-section-winner";
import {
  tagOWarContentLeftSide,
  tagOWarContentRightSide
} from "../../../../../../data/default-experience-data/experiences/tag-o-war.data";
const socialFeedsSide = {
  LEFT: "socialFeedLeft",
  RIGHT: "socialFeedRight"
};
class TagOWarSectionContainer extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "mapSocialFeeds", (data) => {
      return data.map(
        (socialFeed) => typeof socialFeed.attributes !== "undefined" ? {
          image_url: socialFeed.attributes.image_url,
          title: socialFeed.attributes.title,
          text: socialFeed.attributes.text
        } : socialFeed
      );
    });
    __publicField(this, "getUniquePosts", (posts) => {
      const postsWithoutDuplicates = posts && posts.filter((post) => {
        if (post.attributes.metadata.image_phash) {
          const duplicatedArray = posts.filter(
            (p) => p.attributes.metadata.image_phash === post.attributes.metadata.image_phash && p.attributes.external_id === post.attributes.external_id
          );
          return duplicatedArray.length === 1 || duplicatedArray.length > 1 && duplicatedArray.indexOf(post) === 0;
        }
        return true;
      });
      return postsWithoutDuplicates.filter((post) => {
        return new Date(post.attributes.published_at).getTime() < (/* @__PURE__ */ new Date()).getTime();
      });
    });
    __publicField(this, "onCloseModal", () => {
      this.props.closeModal();
    });
    __publicField(this, "onSetStartDate", (date) => {
      const dateToTimestamp = new Date(date).toJSON();
      this.setState({ startDate: dateToTimestamp }, () => {
        this.props.onContentUpdate({ startDate: dateToTimestamp });
        this.getTagOWarDetails();
      });
    });
    __publicField(this, "onSetEndDate", (date) => {
      const dateToTimestamp = new Date(date).toJSON();
      this.setState({ endDate: dateToTimestamp }, () => {
        this.props.onContentUpdate({ endDate: dateToTimestamp });
        this.getTagOWarDetails();
      });
    });
    __publicField(this, "onEndCompetition", () => {
      this.props.onContentUpdate({
        isCompetitionEnd: true,
        competitionEndDate: (/* @__PURE__ */ new Date()).getTime()
      });
    });
    this.state = {
      socialFeedIdLeft: this.props.data.content.socialFeedLeft.value,
      socialFeedIdRight: this.props.data.content.socialFeedRight.value,
      socialFeedRight: [],
      socialFeedLeft: [],
      socialFeedsDropdownOptions: [],
      tintDropdownOptions: [],
      startDate: this.props.data.content.startDate,
      endDate: this.props.data.content.endDate,
      isFinished: this.props.data.content.isCompetitionEnd ? new Date(this.props.data.content.competitionEndDate).toJSON() : void 0,
      leftSidePosts: this.mapSocialFeeds(this.props.data.content.leftSidePosts),
      rightSidePosts: this.mapSocialFeeds(this.props.data.content.rightSidePosts),
      percentLeft: this.props.data.content.percentLeft,
      percentRight: this.props.data.content.percentRight
    };
  }
  componentDidMount() {
    if (!this.props.isPreviewMode) {
      this.getCurrentDropdownOptions();
    } else {
      this.getTagOWarDetails();
    }
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data.content.leftSidePosts) !== JSON.stringify(prevProps.data.content.leftSidePosts)) {
      this.setState({
        leftSidePosts: this.mapSocialFeeds(this.props.data.content.leftSidePosts)
      });
    }
    if (JSON.stringify(this.props.data.content.rightSidePosts) !== JSON.stringify(prevProps.data.content.rightSidePosts)) {
      this.setState({
        rightSidePosts: this.mapSocialFeeds(this.props.data.content.rightSidePosts)
      });
    }
    if (this.props.data.content.percentRight !== prevProps.data.content.percentRight) {
      this.setState({
        percentRight: this.props.data.content.percentRight
      });
    }
    if (this.props.data.content.percentLeft !== prevProps.data.content.percentLeft) {
      this.setState({
        percentLeft: this.props.data.content.percentLeft
      });
    }
  }
  listenForWebSocket() {
    if (typeof this.props.data.content !== "undefined" && JSON.stringify(this.props.data.content.socialFeedLeft.tint) === JSON.stringify(this.props.data.content.socialFeedRight.tint)) {
      this.subscribeOnWS(this.props.data.content.socialFeedLeft.tint);
    } else {
      this.subscribeOnWS(this.props.data.content.socialFeedLeft.tint);
      this.subscribeOnWS(this.props.data.content.socialFeedRight.tint);
    }
  }
  subscribeOnWS(tintObj) {
    if (typeof tintObj !== "undefined") {
      subscribeOnWebsocket(tintObj.id, () => {
        this.getTagOWarDetails();
      });
    }
  }
  getCurrentDropdownOptions() {
    getSocialFeeds().then((res) => {
      const mapTints = res.data.data.map((tint) => {
        return {
          id: tint.id,
          name: tint.attributes.name,
          slug: tint.attributes.slug,
          socialFeeds: tint.relationships.social_feeds.data.map((socialFeed) => ({
            socialFeedId: socialFeed.id
          }))
        };
      });
      const mapTintConnections = res.data.included ? res.data.included.map((item) => {
        const {
          attributes: { name, source }
        } = item;
        return {
          value: item.id,
          label: name || source,
          name: `${name || source} (${(source.charAt(0).toUpperCase() + source.slice(1)).replace("_", " ")})`
        };
      }) : [];
      const mapsSocialFeedsDropdownOptions = mapTintConnections.map((item) => {
        return __spreadProps(__spreadValues({}, item), {
          tint: mapTints.find((tint) => {
            return tint.socialFeeds.some((a) => {
              return a.socialFeedId === item.value;
            });
          })
        });
      });
      const mapCurrentTintOption = mapTints.map((item) => {
        return {
          value: item.id,
          name: item.slug,
          label: item.slug
        };
      });
      this.setState({
        socialFeedsDropdownOptions: [...new Set(mapsSocialFeedsDropdownOptions)],
        tintDropdownOptions: mapCurrentTintOption
      });
    });
  }
  setDefaultData(side) {
    if (side === socialFeedsSide.LEFT) {
      this.props.onContentUpdate(__spreadValues({}, __spreadValues({}, tagOWarContentLeftSide)));
      this.setState({
        leftSidePosts: [],
        percentLeft: 0
      });
    } else {
      this.props.onContentUpdate(__spreadValues({}, __spreadValues({}, tagOWarContentRightSide)));
      this.setState({
        rightSidePosts: [],
        percentRight: 0
      });
    }
  }
  socialFeedSideChoice(socialFeedSide, socialFeedLeftKey, socialFeedRightKey) {
    return socialFeedSide === socialFeedsSide.LEFT ? socialFeedLeftKey : socialFeedRightKey;
  }
  onDropdownSelect(slugName, socialFeedId, socialFeedSide) {
    const socialFeedIdSide = this.socialFeedSideChoice(socialFeedSide, "socialFeedIdLeft", "socialFeedIdRight");
    if (isNaN(socialFeedId)) {
      this.setDefaultData(socialFeedSide);
      return;
    }
    this.setState({ [socialFeedIdSide]: socialFeedId });
    this.onContentUpdate({
      [socialFeedSide]: this.setSocialFeedObject(socialFeedId)
    });
    this.getTagOWarSideData(slugName, socialFeedId, socialFeedSide);
  }
  getTagOWarDetails() {
    if (typeof this.props.data.content.socialFeedLeft !== "undefined" && this.props.data.content.socialFeedLeft.value !== "" && typeof this.props.data.content.socialFeedRight !== "undefined" && this.props.data.content.socialFeedRight.value !== "") {
      this.getTagOWarData();
    } else if (this.props.data.content.socialFeedLeft.value !== "") {
      this.onDropdownSelect(
        this.props.data.content.slugNameLeft,
        this.props.data.content.socialFeedLeft.value,
        socialFeedsSide.LEFT
      );
    } else if (this.props.data.content.socialFeedRight.value !== "") {
      this.onDropdownSelect(
        this.props.data.content.slugNameRight,
        this.props.data.content.socialFeedRight.value,
        socialFeedsSide.RIGHT
      );
    }
  }
  getTagOWarSideData(slugName, socialFeedId, socialFeedSide) {
    const promiseArray = [
      getSocialFeedsPosts(slugName, socialFeedId, this.state.startDate, this.state.endDate, this.props.isPreviewMode),
      getMetrics([socialFeedId], this.state.startDate, this.state.endDate)
    ];
    Promise.all(promiseArray).then((response) => {
      const sidePostsIndex = 0;
      const percentIndex = 1;
      const postSideType = this.socialFeedSideChoice(socialFeedSide, "leftSidePosts", "rightSidePosts");
      const percentSideType = this.socialFeedSideChoice(socialFeedSide, "percentLeft", "percentRight");
      this.setState({
        [postSideType]: this.mapSocialFeeds(response[sidePostsIndex].data.data)
      });
      const mapSocialFeeds = this.mapSocialFeeds(response[sidePostsIndex].data.data);
      this.onContentUpdate({
        [postSideType]: this.mapSocialFeeds(mapSocialFeeds),
        [percentSideType]: response[percentIndex].data.data[0] && typeof response[percentIndex].data.data[0].attributes !== "undefined" ? response[percentIndex].data.data[0].attributes.count : 0
      });
    });
  }
  getTagOWarData() {
    const promiseArray = [
      getSocialFeedsPosts(
        this.props.data.content.slugNameLeft,
        this.state.socialFeedIdLeft,
        this.state.startDate,
        this.state.endDate,
        this.props.isPreviewMode
      ),
      getSocialFeedsPosts(
        this.props.data.content.slugNameRight,
        this.state.socialFeedIdRight,
        this.state.startDate,
        this.state.endDate,
        this.props.isPreviewMode
      ),
      getMetrics([this.state.socialFeedIdLeft], this.state.startDate, this.state.endDate),
      getMetrics([this.state.socialFeedIdRight], this.state.startDate, this.state.endDate)
    ];
    Promise.all(promiseArray).then((response) => {
      const leftSidePostIndex = 0;
      const rightSidePostsIndex = 1;
      const percentLeftIndex = 2;
      const percentRightIndex = 3;
      const isLeftPostExist = response[leftSidePostIndex] && response[leftSidePostIndex].data;
      const isRightPostExist = response[rightSidePostsIndex] && response[rightSidePostsIndex].data;
      this.setState(
        {
          leftSidePosts: this.mapSocialFeeds(
            this.getUniquePosts(isLeftPostExist && response[leftSidePostIndex].data.data)
          ),
          rightSidePosts: this.mapSocialFeeds(
            this.getUniquePosts(isRightPostExist && response[rightSidePostsIndex].data.data)
          ),
          percentLeft: response[percentLeftIndex].data.data[0] && typeof response[percentLeftIndex].data.data[0].attributes !== "undefined" ? response[percentLeftIndex].data.data[0].attributes.count : void 0,
          [socialFeedsSide.LEFT]: this.setSocialFeedObject(this.state.socialFeedIdLeft),
          percentRight: response[percentRightIndex].data.data[0] && typeof response[percentRightIndex].data.data[0].attributes !== "undefined" ? response[percentRightIndex].data.data[0].attributes.count : void 0,
          [socialFeedsSide.RIGHT]: this.setSocialFeedObject(this.state.socialFeedIdRight)
        },
        () => {
          if (!this.props.data.content.isCompetitionEnd) {
            this.isTimesUp();
            this.listenForWebSocket();
          }
        }
      );
      this.onContentUpdate({
        leftSidePosts: response[leftSidePostIndex].data.data,
        rightSidePosts: response[rightSidePostsIndex].data.data,
        percentLeft: response[percentLeftIndex].data.data[0] && typeof response[percentLeftIndex].data.data[0].attributes !== "undefined" ? response[percentLeftIndex].data.data[0].attributes.count : 0,
        [socialFeedsSide.LEFT]: this.setSocialFeedObject(this.state.socialFeedIdLeft),
        percentRight: response[percentRightIndex].data.data[0] && typeof response[percentRightIndex].data.data[0].attributes !== "undefined" ? response[percentRightIndex].data.data[0].attributes.count : 0,
        [socialFeedsSide.RIGHT]: this.setSocialFeedObject(this.state.socialFeedIdRight)
      });
    });
  }
  onContentUpdate(data) {
    if (!this.props.isPreviewMode) {
      this.props.onContentUpdate(data);
    }
  }
  setSocialFeedObject(id) {
    return this.state.socialFeedsDropdownOptions.find((item) => item.value === id);
  }
  isTimesUp() {
    const oneSecInMilliseconds = 1e3;
    const endDateTimestamp = new Date(this.state.endDate).getTime();
    let currentDate = (/* @__PURE__ */ new Date()).getTime();
    const timeInterval = setInterval(() => {
      if (currentDate >= endDateTimestamp) {
        clearInterval(timeInterval);
        this.setState({ isFinished: true });
      } else {
        currentDate += oneSecInMilliseconds;
      }
    }, oneSecInMilliseconds);
  }
  render() {
    const hasPostsClass = (this.state.leftSidePosts.length > 0 || this.state.rightSidePosts.length > 0) && !this.props.isPreviewMode ? "overflow-blur" : "";
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        dir: "auto",
        className: `tint-page-section tint-page-section--tag-o-war ${hasPostsClass}`,
        "data-testid": "tagOWarSection"
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement(
        TagOWarSection,
        {
          props: this.props,
          percentLeft: this.state.percentLeft,
          percentRight: this.state.percentRight,
          leftPosts: this.state.leftSidePosts,
          rightPosts: this.state.rightSidePosts,
          experience: this.props.experience
        }
      ),
      this.state.isFinished && this.props.isPreviewMode ? /* @__PURE__ */ React.createElement(
        TagOWarSectionWinner,
        {
          socialFeedLeft: this.props.data.content.socialFeedLeft,
          socialFeedRight: this.props.data.content.socialFeedRight,
          percentLeft: this.state.percentLeft,
          percentRight: this.state.percentRight
        }
      ) : null,
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.onCloseModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Tag-o-War" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          TagOWarContent,
          {
            onEndCompetitionClick: this.onEndCompetition,
            onSetStartDate: this.onSetStartDate,
            onSetEndDate: this.onSetEndDate,
            startDate: this.state.startDate,
            data: this.props.data,
            tintDropdownOptions: this.state.tintDropdownOptions,
            socialFeedsDropdownOptions: this.state.socialFeedsDropdownOptions,
            dataUpdate: this.props.onContentUpdate,
            isCompetitionEnd: this.props.data.content.isCompetitionEnd,
            maxFileSize: this.props.getMaxImageSizePlanLimit(),
            onLeftSideSelect: (slugName, socialFeedId) => this.onDropdownSelect(slugName, socialFeedId, socialFeedsSide.LEFT),
            onRightSideSelect: (slugName, socialFeedId) => this.onDropdownSelect(slugName, socialFeedId, socialFeedsSide.RIGHT)
          }
        ), /* @__PURE__ */ React.createElement(TagOWarStyles, { data: this.props.data.styles, dataUpdate: this.props.onStylesUpdate })))
      )
    );
  }
}
export default PageSectionHoc(TagOWarSectionContainer);
TagOWarSectionContainer.propTypes = {
  isVisible: PropTypes.bool,
  dismissModal: PropTypes.func,
  saveData: PropTypes.func,
  onDataSave: PropTypes.func,
  closeModal: PropTypes.func
};
