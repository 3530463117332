var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ExperienceConfig } from "../../feature/experience/utils/experience.config";
export const getDefaultExperienceModel = (data, type, title) => {
  return {
    experience: {
      type: "experience",
      attributes: {
        data: __spreadValues({
          translations: {
            draft: { "en-US": {} },
            published: { "en-US": {} }
          },
          locales: {
            draft: [
              {
                value: ExperienceConfig.languages.defaultLanguage.value,
                name: ExperienceConfig.languages.defaultLanguage.name,
                isDefault: true
              }
            ],
            published: [
              {
                value: ExperienceConfig.languages.defaultLanguage.value,
                name: ExperienceConfig.languages.defaultLanguage.name,
                isDefault: true
              }
            ]
          },
          customFonts: {
            draft: [],
            published: []
          }
        }, data),
        name: title ? `${title}_copy` : "Untitled Project",
        type
      }
    }
  };
};
export const addCustomizableHeader = (section, headerOptions) => {
  const defaultCustomizableHeader = {
    text: "ENTRY TEXT",
    textDecoration: "",
    urlLink: "",
    fontStyle: "",
    textAlign: "center",
    fontFamily: {
      value: "Lato",
      name: "Lato",
      url: "Lato"
    },
    fontWeight: 500,
    topPosition: 50,
    fontSize: 36,
    lineHeight: 38,
    width: 566,
    color: "#ffffff",
    backgroundColor: { r: 255, g: 255, b: 255, a: 0 }
  };
  if (section.data.content.customizableHeaders === void 0) {
    section.data.content.customizableHeaders = [];
  }
  section.data.content.customizableHeaders.push(__spreadValues(__spreadValues({}, defaultCustomizableHeader), headerOptions));
  return section;
};
