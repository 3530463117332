import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_ASSET: createRoutine(createPrefixedActionName("ASSET_MANAGER", "GET_ASSET")),
  CLEAR_ASSET: createPrefixedActionName("ASSET_MANAGER", "CLEAR_ASSET")
};
export const clearAsset = () => ({
  type: ACTIONS.CLEAR_ASSET
});
export const getAssetStart = () => ({
  type: ACTIONS.GET_ASSET.REQUEST
});
export const getAssetSuccess = (data) => {
  return {
    type: ACTIONS.GET_ASSET.SUCCESS,
    payload: data
  };
};
export const getAssetError = (err) => ({
  type: ACTIONS.GET_ASSET.FAILURE,
  payload: err
});
