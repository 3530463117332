import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditForm, EditInputDropdown } from "../../../";
const voteCountOptions = [
  { value: 1, name: "1 Column" },
  { value: 2, name: "2 Column" },
  { value: 3, name: "3 Column" }
];
export class MultiColumnEditContent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, null, /* @__PURE__ */ React.createElement(EditForm, null, /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit"), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        defaultValue: this.props.data.content.amountOfColumns,
        onChange: (e) => {
          this.props.dataUpdate({ amountOfColumns: e.target.value });
        },
        options: voteCountOptions,
        label: "Number of columns for desktop mode"
      }
    )));
  }
}
