import React, { useRef, useEffect, useState } from "react";
import "./validation-alert.sass";
const ValidationAlert = ({ errorMsg, isVisible, color = "#990000" }) => {
  const elementRef = useRef();
  const [currentHeight, setCurrentHeight] = useState();
  useEffect(() => {
    if (isVisible) {
      elementRef.current && setCurrentHeight(elementRef.current.offsetHeight);
    }
  }, [isVisible]);
  const renderValidationAlert = (err) => {
    return /* @__PURE__ */ React.createElement(
      "p",
      {
        style: {
          margin: !isVisible ? "0" : "4px 0",
          height: isVisible ? currentHeight : "0px",
          color
        }
      },
      /* @__PURE__ */ React.createElement("span", { ref: elementRef }, err)
    );
  };
  return Array.isArray(errorMsg) ? errorMsg.map((e, i) => {
    return /* @__PURE__ */ React.createElement("div", { key: i, className: "validation-alert" }, renderValidationAlert(e));
  }) : /* @__PURE__ */ React.createElement("div", { className: "validation-alert" }, renderValidationAlert(errorMsg));
};
export default ValidationAlert;
