var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { SocialPublishingPath } from "../../feature/social-publishing/utils/social-publishing.paths";
import { formBuilderRoutePaths } from "../../feature/form-builder/utils/form-builder.route-paths";
import { missionHubsRoutePaths } from "../../feature/mission-hubs/utils/mission-hubs.route-paths";
export const routePaths = {
  main: {
    title: "Boards",
    newTitle: "Boards",
    react_path: "/tints",
    path: "/",
    settings: {
      path: "/settings"
    }
  },
  socialPublishing: __spreadValues({}, SocialPublishingPath),
  billing: {
    title: "Billing",
    path: "/billing"
  },
  upgrade: {
    title: "No team",
    path: "/upgrade"
  },
  newTeam: {
    title: "Welcome to TINT",
    path: "/teams/new",
    createUrl: ({ plan }) => `/teams/new?plan=${plan}`
  },
  mediaComposer: {
    title: "Media Composer",
    plainPath: "/media-composer",
    path: "/media-composer/:assetId",
    createUrl: ({ assetId }) => `/media-composer/${assetId}`
  },
  personalizations: {
    title: "Personalizations List",
    path: "/personalizations",
    personalization: {
      title: "Personalization builder",
      path: "/personalizations/:id",
      plainPath: "/personalizations/",
      createUrl: (id) => `/personalizations/${id}`
    }
  },
  discover: {
    title: "Launchpad",
    path: "/",
    details: {
      title: "Discover",
      path: "/details/:category/:title",
      createUrl: (category, title) => `/details/${category}/${title}`
    }
  },
  creatorsAndInfluencers: {
    title: "Creators & Influencers",
    path: "/influencer-discovery"
  },
  formBuilder: __spreadValues({}, formBuilderRoutePaths),
  missionHubs: __spreadValues({}, missionHubsRoutePaths),
  subscribePlan: {
    title: "Subscribe",
    path: "/subscribe"
  },
  planAddons: {
    title: "Plan Add-Ons",
    path: "/plan-addons"
  },
  authVerifier: {
    title: "Auth Verifier",
    path: "/auth-verifier"
  },
  assetManager: {
    title: "UGC Studio",
    myAssetsTitle: "My Assets",
    path: "/asset-manager",
    archived: {
      title: "Archived",
      path: "/asset-manager/archived"
    },
    asset: {
      path: "/asset-manager/asset/:assetId",
      creatUrl: (assetId) => `/asset-manager/asset/${assetId}`
    },
    gifBuilder: {
      path: "/asset-manager/gif-builder"
    },
    collections: {
      title: "My Collections",
      path: "/asset-manager/collections",
      specified: {
        path: "/asset-manager/collections/:collectionId",
        creatUrl: (collectionId) => `/asset-manager/collections/${collectionId}`
      }
    }
  },
  transformUI: {
    title: "Transform UI",
    path: "/transform-ui/asset/:assetId",
    createUrl: (assetId) => `/transform-ui/asset/${assetId}`
  },
  tintMix: {
    title: "TINTmix",
    newTitle: "TINTmix",
    path: "/admin/mix"
  },
  newTintMix: {
    title: "TINTmix",
    path: "/tint-mix",
    list: {
      path: "/tint-mix/list"
    },
    scene: {
      path: "/tint-mix/:tintMixId",
      createUrl: (tintMixId) => `/tint-mix/${tintMixId}`
    },
    new: {
      path: "/tint-mix/new"
    },
    mix: {
      path: "/mix",
      preview: {
        path: "/mix/:tintMixId",
        createUrl: (tintMixId) => `/mix/${tintMixId}`
      }
    }
  },
  insights: {
    title: "Insights",
    path: "/insights",
    content: {
      title: "Content",
      path: "/insights/content"
    },
    performance: {
      title: "Performance",
      path: "/insights/performance"
    },
    social: {
      title: "Social",
      path: "/insights/social/:id",
      overview: {
        title: "Overview",
        path: "/insights/social/overview"
      },
      audience: {
        title: "Audience",
        path: "/insights/social/audience"
      },
      posts: {
        title: "Posts",
        path: "/insights/social/posts"
      },
      stories: {
        title: "Stories",
        path: "/insights/social/stories"
      },
      trends: {
        title: "Trends",
        path: "/insights/social/trends"
      }
    },
    commerce: {
      title: "Commerce",
      path: "/insights/commerce"
    }
  },
  experienceBuilder: {
    title: "Experience Builder",
    newTitle: "Experiences",
    path: "/experiences",
    new: {
      path: "/experiences/new"
    },
    experience: {
      path: "/experiences/:id",
      createUrl: (id) => `/experiences/${id}`
    },
    preview: {
      path: "/experiences/:id/preview",
      createUrl: (id) => `/experiences/${id}/preview`
    },
    submission: {
      path: "/experiences/:id/submissions",
      createUrl: (id) => `/experiences/${id}/submissions`
    },
    customDomains: {
      title: "Custom Domains",
      path: "/experiences/custom-domains"
    }
  },
  personalSettings: {
    title: "Personal Settings",
    path: "/profile",
    information: {
      title: "Profile",
      path: "/profile/information"
    },
    accountSecurity: {
      title: "Account Security",
      path: "/profile/security"
    },
    supportAccess: {
      title: "Support Access",
      path: "/profile/support-access"
    },
    api: {
      path: "/profile/api"
    },
    teamWorkspaces: {
      path: "/profile/team-workspaces"
    },
    deleteAccount: {
      path: "/profile/delete-account"
    }
  },
  accountSettings: {
    title: "Team Settings",
    path: "/account-settings/:id"
  },
  teamSettings: {
    title: "Team Settings",
    plainPath: "/team-settings",
    path: "/team-settings/:id",
    socialConnections: {
      title: "Accounts",
      path: "/team-settings/social-connections",
      socialConnectionsEdit: {
        path: "/team-settings/social-connections/:id",
        createUrl: (id) => `/team-settings/social-connections/${id}`
      }
    },
    marketingIntegrations: {
      title: "Integrations",
      path: "/team-settings/integrations"
    },
    emailDomain: {
      title: "Email Domains",
      path: "/team-settings/email-domains/:id",
      createUrl: (id) => `/team-settings/email-domains/${id}`
    },
    emailDomains: {
      title: "Email Domains",
      path: "/team-settings/email-domains"
    },
    emailTemplate: {
      title: "Email Templates",
      path: "/team-settings/email-templates/:id",
      createUrl: (id) => {
        return `/team-settings/email-templates/${id}`;
      }
    },
    emailTemplates: {
      title: "Email Templates",
      path: "/team-settings/email-templates",
      emailTemplateEdit: {
        path: "/team-settings/email-templates/:id",
        createUrl: (id) => {
          return `/team-settings/email-templates/${id}`;
        }
      },
      emailTemplateAdd: {
        path: "/team-settings/email-templates/new"
      }
    },
    customFonts: {
      title: "Fonts",
      path: "/team-settings/fonts"
    },
    roles: {
      title: "Roles",
      path: "/team-settings/roles",
      rolesEdit: {
        path: "/team-settings/roles/:id",
        createUrl: (id) => {
          return `/team-settings/roles/${id}`;
        }
      },
      rolesAdd: {
        path: "/team-settings/roles/new"
      }
    },
    security: {
      title: "Security",
      path: "/team-settings/security"
    },
    collaborators: {
      title: "Collaborators",
      path: "/team-settings/collaborators",
      collaboratorsEdit: {
        path: "/team-settings/collaborators/:id",
        createUrl: (id) => {
          return `/team-settings/collaborators/${id}`;
        }
      }
    },
    api: {
      title: "API",
      path: "/team-settings/api"
    },
    productAccounts: {
      title: "Product Accounts",
      path: "/team-settings/product-accounts"
    },
    productFeeds: {
      title: "Product Feeds",
      path: "/team-settings/product-feeds",
      productFeedsEdit: {
        path: "/team-settings/product-feeds/:id",
        createUrl: (id) => `/team-settings/product-feeds/${id}`
      }
    },
    team: {
      title: "Team Profile",
      path: "/team-settings/team"
    },
    preferences: {
      title: "Preferences",
      path: "/team-settings/preferences"
    },
    rightsManagement: {
      title: "Rights Management",
      path: "/team-settings/rights-management"
    }
  },
  community: {
    title: "Community",
    path: "/community",
    iframePath: "/community-admin/dashboard",
    settings: {
      points: {
        title: "Community",
        path: "/community/settings/points",
        iframePath: "/community-admin/accounts/system_configuration"
      }
    }
  },
  ecommerce: {
    path: "/ecommerce"
  },
  instintUpdates: {
    title: "InsTINT Updates",
    path: "/admin/instint_updates"
  },
  logOut: {
    title: "Log Out",
    path: "/credentials/sign_out"
  },
  globalModeration: {
    title: "Global Feed",
    plainPath: "/tints/global",
    path: "/t/globalfeed/edit/moderate/all"
  },
  zinrelo: {
    title: "Loyalty & Rewards",
    path: null
  },
  helpCenter: {
    title: "Help Center",
    newTitle: "Help",
    path: "https://support.tintup.com"
  },
  audience: {
    title: "Audience Builder",
    path: "/audience-builder",
    overview: {
      title: "Overview",
      path: "/audience-builder/overview"
    },
    settings: {
      title: "Attributes",
      path: "/audience-builder/settings"
    },
    members: {
      title: "People",
      path: "/audience-builder/members",
      memberDetails: {
        path: "/audience-builder/members/:id",
        createUrl: (id) => {
          return `/audience-builder/members/${id}`;
        }
      }
    },
    audiences: {
      title: "Audiences",
      path: "/audience-builder/audiences",
      audiencesDetails: {
        path: "/audience-builder/audiences/:id",
        createUrl: (id) => {
          return `/audience-builder/audiences/${id}`;
        }
      }
    },
    badges: {
      title: "Badges",
      path: "/audience-builder/badges"
    }
  }
};
export const urlToPageTitle = {
  [routePaths.main.path]: routePaths.discover.title,
  [""]: routePaths.discover.title,
  [routePaths.main.react_path]: routePaths.main.title,
  [routePaths.newTintMix.path]: routePaths.newTintMix.title,
  [routePaths.socialPublishing.calendar.path]: routePaths.socialPublishing.calendar.title,
  [routePaths.assetManager.path]: `${routePaths.assetManager.title} - ${routePaths.assetManager.myAssetsTitle}`,
  [routePaths.assetManager.collections.path]: `${routePaths.assetManager.title} - ${routePaths.assetManager.collections.title}`,
  [routePaths.assetManager.archived.path]: `${routePaths.assetManager.title} - ${routePaths.assetManager.archived.title}`,
  [routePaths.experienceBuilder.path]: routePaths.experienceBuilder.title,
  [routePaths.experienceBuilder.customDomains.path]: `${routePaths.experienceBuilder.title} - ${routePaths.experienceBuilder.customDomains.title}`,
  [routePaths.insights.content.path]: `${routePaths.insights.title} - ${routePaths.insights.content.title}`,
  [routePaths.teamSettings.team.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.team.title}`,
  [routePaths.teamSettings.socialConnections.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.socialConnections.title}`,
  [routePaths.teamSettings.marketingIntegrations.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.marketingIntegrations.title}`,
  [routePaths.teamSettings.security.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.security.title}`,
  [routePaths.teamSettings.collaborators.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.collaborators.title}`,
  [routePaths.teamSettings.roles.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.roles.title}`,
  [routePaths.teamSettings.api.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.api.title}`,
  [routePaths.teamSettings.productAccounts.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.productAccounts.title}`,
  [routePaths.teamSettings.productFeeds.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.productFeeds.title}`,
  [routePaths.teamSettings.preferences.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.preferences.title}`,
  [routePaths.personalSettings.information.path]: `${routePaths.personalSettings.title} - ${routePaths.personalSettings.information.title}`,
  [routePaths.personalSettings.accountSecurity.path]: `${routePaths.personalSettings.title} - ${routePaths.personalSettings.accountSecurity.title}`,
  [routePaths.insights.performance.path]: `${routePaths.insights.title} - ${routePaths.insights.performance.title}`,
  [routePaths.insights.commerce.path]: `${routePaths.insights.title} - ${routePaths.insights.commerce.title}`,
  [routePaths.personalizations.path]: routePaths.personalizations.title,
  [routePaths.creatorsAndInfluencers.path]: routePaths.creatorsAndInfluencers.title,
  [routePaths.subscribePlan.path]: routePaths.subscribePlan.title,
  [routePaths.formBuilder.new.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.builder.path]: routePaths.formBuilder.title,
  [routePaths.missionHubs.path]: routePaths.missionHubs.title,
  [routePaths.community.path]: routePaths.community.title,
  [routePaths.community.settings.points.path]: routePaths.community.settings.points.title,
  [routePaths.audience.overview.path]: `${routePaths.audience.title} - ${routePaths.audience.overview.title}`,
  [routePaths.audience.members.path]: `${routePaths.audience.title} - ${routePaths.audience.members.title}`,
  [routePaths.audience.audiences.path]: `${routePaths.audience.title} - ${routePaths.audience.audiences.title}`,
  [routePaths.audience.badges.path]: `${routePaths.audience.title} - ${routePaths.audience.badges.title}`,
  [routePaths.audience.settings.path]: `${routePaths.audience.title} - ${routePaths.audience.settings.title}`
};
export const urlToPageHeader = {
  [routePaths.main.path]: routePaths.discover.title,
  [routePaths.main.react_path]: routePaths.main.title,
  [routePaths.socialPublishing.calendar.path]: routePaths.socialPublishing.calendar.title,
  [routePaths.personalizations.path]: routePaths.personalizations.title,
  [routePaths.personalizations.personalization.plainPath]: routePaths.personalizations.personalization.title,
  [routePaths.newTintMix.path]: routePaths.newTintMix.title,
  [routePaths.assetManager.path]: routePaths.assetManager.title,
  [routePaths.experienceBuilder.path]: routePaths.experienceBuilder.title,
  [routePaths.insights.path]: routePaths.insights.title,
  [routePaths.personalSettings.path]: routePaths.personalSettings.title,
  [routePaths.teamSettings.plainPath]: routePaths.teamSettings.title,
  [routePaths.creatorsAndInfluencers.path]: routePaths.creatorsAndInfluencers.title,
  [routePaths.subscribePlan.path]: routePaths.subscribePlan.title,
  [routePaths.formBuilder.new.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.builder.path]: routePaths.formBuilder.title,
  [routePaths.missionHubs.path]: routePaths.missionHubs.title,
  [routePaths.community.path]: routePaths.community.title,
  [routePaths.community.settings.points.path]: routePaths.community.settings.points.title,
  [routePaths.audience.overview.path]: routePaths.audience.title,
  [routePaths.audience.members.path]: routePaths.audience.title,
  [routePaths.audience.audiences.path]: routePaths.audience.title,
  [routePaths.audience.badges.path]: routePaths.audience.title,
  [routePaths.audience.settings.path]: routePaths.audience.title
};
