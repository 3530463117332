var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./form-section.sass";
import { EditFormContent } from "./edit/edit-form-content";
import { EditFormStyles } from "./edit/edit-form-styles";
import { connect } from "react-redux";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { FormContestRulesModal } from "./contest-rules-modal/form-contest-rules-modal";
import { Form } from "./form/form";
import EditModalButton from "../../../../../../components/edit-modal/edit-modal-footer/edit-modal-button/edit-modal-button";
import { EditInput, EditTextArea } from "../../";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { isUrlAddress } from "../../../../../../services/functions/url-checker/url-address";
import { toast } from "react-toastify";
import { Config } from "../../../../../../utils/config";
import { FormRulesStep } from "./steps/form-rules-step";
import { createTranslationKey } from "../../../../containers/experience/translation-creator";
import { FieldEditModal } from "./field-edit-modal/field-edit-modal";
class FormSection extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "setApplyButtonDisabled", (value) => {
      this.setState({ isApplyButtonDisabled: value });
    });
    __publicField(this, "editSuccessMessage", () => {
      this.props.onDataSave();
      this.setState({ isEditingSuccessMessage: true });
    });
    __publicField(this, "finishEditingSuccessMessage", () => {
      this.setState({ isEditingSuccessMessage: false });
    });
    __publicField(this, "forceErrorVisibility", () => {
      this.setState({ forceErrorVisibility: "sample message" });
    });
    __publicField(this, "disableForceErrorVisibility", () => {
      this.setState({ forceErrorVisibility: "" });
    });
    __publicField(this, "addLink", () => {
      const successMessageLinks = this.props.data.content.successMessageLinks ? JSON.parse(JSON.stringify(this.props.data.content.successMessageLinks)) : [];
      const buttonTextTrKey = createTranslationKey(
        this.props.experienceType,
        "FORM",
        "BUTTON_TEXT",
        successMessageLinks.length
      );
      const buttonUrlTrKey = createTranslationKey(
        this.props.experienceType,
        "FORM",
        "BUTTON_URL",
        successMessageLinks.length
      );
      successMessageLinks.push({
        buttonText: buttonTextTrKey,
        buttonUrl: buttonUrlTrKey,
        id: Config.getUniqueId()
      });
      this.props.onNewTranslationUpdate(buttonTextTrKey, "");
      this.props.onNewTranslationUpdate(buttonUrlTrKey, "");
      this.props.onContentUpdate({ successMessageLinks });
    });
    __publicField(this, "removeSuccessMessageLink", (i) => {
      const successMessageLinks = JSON.parse(JSON.stringify(this.props.data.content.successMessageLinks));
      successMessageLinks.splice(i, 1);
      this.props.onContentUpdate({ successMessageLinks });
    });
    __publicField(this, "renderLinkInputs", () => {
      return this.props.data.content.successMessageLinks ? this.props.data.content.successMessageLinks.map((e, i) => {
        return /* @__PURE__ */ React.createElement("div", { key: e.id }, /* @__PURE__ */ React.createElement(
          EditInput,
          {
            defaultValue: this.props.getTranslation(e.buttonText) || "Button Text",
            onChange: (event) => this.props.onTranslationUpdate(e.buttonText, event.target.value),
            label: `button link ${i + 1}`,
            placeholder: "Button Text",
            rightIcon: "fas fa-trash",
            rightIconAction: () => this.removeSuccessMessageLink(i)
          }
        ), /* @__PURE__ */ React.createElement(
          EditInput,
          {
            defaultValue: this.props.getTranslation(e.buttonUrl),
            onChange: (event) => this.props.onTranslationUpdate(e.buttonUrl, event.target.value),
            placeholder: "Enter URL"
          }
        ));
      }) : null;
    });
    __publicField(this, "areSuccessLinksInvalid", (successMessageLinks) => {
      return successMessageLinks.some((e) => {
        return this.props.getTranslation(e.buttonUrl) !== "" && !isUrlAddress(this.props.getTranslation(e.buttonUrl));
      });
    });
    __publicField(this, "saveSuccessMessage", () => {
      this.finishEditingSuccessMessage();
      this.props.selectStep(0);
    });
    __publicField(this, "onSuccessMessageSave", () => {
      if (this.props.data.content.successMessageLinks && this.props.data.content.successMessageLinks.length > 0) {
        if (this.areSuccessLinksInvalid(this.props.data.content.successMessageLinks)) {
          toast.error("It is not correct address URL, example: https://example.com");
        } else {
          this.saveSuccessMessage();
          this.props.onDataSave();
        }
      } else {
        this.saveSuccessMessage();
        this.props.onDataSave();
      }
    });
    __publicField(this, "renderEditSuccessMessageStep", () => {
      return /* @__PURE__ */ React.createElement(
        EditModalStep,
        {
          footer: /* @__PURE__ */ React.createElement(EditModalButton, { text: "Done", size: "secondary" }),
          customSaveData: this.onSuccessMessageSave,
          title: "Edit Success Message",
          closeModal: () => {
            this.finishEditingSuccessMessage();
          }
        },
        /* @__PURE__ */ React.createElement(
          EditInput,
          {
            onChange: (event) => {
              this.props.onTranslationUpdate(this.props.data.content.successMessageTitle, event.target.value);
            },
            label: "Line 1",
            placeholder: "Thank you!",
            defaultValue: this.props.getTranslation(this.props.data.content.successMessageTitle)
          }
        ),
        /* @__PURE__ */ React.createElement(
          EditTextArea,
          {
            onChange: (event) => {
              this.props.onTranslationUpdate(this.props.data.content.successMessageDescription, event.target.value);
            },
            label: "Line 2",
            placeholder: "The form was submitted successfully",
            defaultValue: this.props.getTranslation(this.props.data.content.successMessageDescription)
          }
        ),
        this.renderLinkInputs(),
        /* @__PURE__ */ React.createElement("div", { onClick: this.addLink, className: "form-section-add-link" }, /* @__PURE__ */ React.createElement("span", null, "Add Link"))
      );
    });
    __publicField(this, "renderStyledText", (text) => {
      return text ? /* @__PURE__ */ React.createElement(
        "span",
        {
          dangerouslySetInnerHTML: {
            __html: text.replace(/(?:\r\n|\r|\n)/g, "<br>")
          }
        }
      ) : null;
    });
    this.state = {
      isContestRulesVisible: false,
      isPrivacyPolicyVisible: false,
      isTermsConditionsVisible: false,
      isEditingSuccessMessage: false,
      isApplyButtonDisabled: false,
      forceErrorVisibility: ""
    };
  }
  openContestRulesModal() {
    this.setState({ isContestRulesVisible: true });
  }
  closeContestRulesModal() {
    this.setState({ isContestRulesVisible: false });
  }
  openPrivacyPolicyModal() {
    this.setState({ isPrivacyPolicyVisible: true });
  }
  closePrivacyPolicyModal() {
    this.setState({ isPrivacyPolicyVisible: false });
  }
  openTermsConditionsModal() {
    this.setState({ isTermsConditionsVisible: true });
  }
  closeTermsConditionsModal() {
    this.setState({ isTermsConditionsVisible: false });
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--form ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement(
        FieldEditModal,
        {
          updateAllTranslation: this.props.onNewTranslationUpdate,
          experienceType: this.props.experienceType,
          getTranslation: this.props.getTranslation,
          dataUpdate: this.props.onContentUpdate,
          openEditModal: this.props.openModal,
          content: this.props.data.content
        }
      ),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          stepIndex: this.props.stepIndex,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Form", isApplyButtonDisabled: this.state.isApplyButtonDisabled }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditFormContent,
          {
            onNewTranslationUpdate: this.props.onNewTranslationUpdate,
            experienceType: this.props.experienceType,
            selectStep: this.props.selectStep,
            openEditModal: this.props.openModal,
            dismissEditModal: this.props.dismissModal,
            nextStep: this.props.nextStep,
            data: this.props.data.content,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            getTranslation: this.props.getTranslation,
            onTranslationUpdate: this.props.onTranslationUpdate,
            editSuccessMessage: this.editSuccessMessage,
            setApplyButtonDisabled: this.setApplyButtonDisabled
          }
        ), /* @__PURE__ */ React.createElement(
          EditFormStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate,
            isUploadEnabled: this.props.data.content.isPhotoUploadEnabled,
            forceErrorVisibility: this.forceErrorVisibility,
            disableForceErrorVisibility: this.disableForceErrorVisibility
          }
        ))),
        /* @__PURE__ */ React.createElement(
          FormRulesStep,
          {
            stepTitle: "Upload Contest Rules",
            selectStep: this.props.selectStep,
            textareaDefaultValue: this.props.getTranslation(this.props.data.content.contestRules),
            onContentUpdate: (e) => this.props.onTranslationUpdate(this.props.data.content.contestRules, e.target.value),
            editTextAreaPlaceholder: "Copy/Paste Contest Rules...",
            closeModal: this.props.closeModal
          }
        ),
        /* @__PURE__ */ React.createElement(
          FormRulesStep,
          {
            stepTitle: "Upload Terms & Conditions",
            selectStep: this.props.selectStep,
            textareaDefaultValue: this.props.getTranslation(this.props.data.content.termsConditions),
            onContentUpdate: (e) => this.props.onTranslationUpdate(this.props.data.content.termsConditions, e.target.value),
            editTextAreaPlaceholder: "Copy/Paste Terms & Conditions...",
            closeModal: this.props.closeModal
          }
        ),
        this.renderEditSuccessMessageStep(),
        /* @__PURE__ */ React.createElement(
          FormRulesStep,
          {
            stepTitle: "Upload Privacy Policy",
            selectStep: this.props.selectStep,
            textareaDefaultValue: this.props.getTranslation(this.props.data.content.privacyPolicy),
            onContentUpdate: (e) => this.props.onTranslationUpdate(this.props.data.content.privacyPolicy, e.target.value),
            editTextAreaPlaceholder: "Copy/Paste Privacy Policy...",
            closeModal: this.props.closeModal
          }
        )
      ),
      /* @__PURE__ */ React.createElement(
        Form,
        __spreadProps(__spreadValues({}, this.props), {
          forceErrorVisibility: this.state.forceErrorVisibility,
          isEditingSuccessMessage: this.state.isEditingSuccessMessage,
          openTermsConditionsModal: this.openTermsConditionsModal.bind(this),
          openContestRulesModal: this.openContestRulesModal.bind(this),
          openPrivacyPolicyModal: this.openPrivacyPolicyModal.bind(this)
        })
      ),
      /* @__PURE__ */ React.createElement(
        FormContestRulesModal,
        {
          body: this.renderStyledText(this.props.getTranslation(this.props.data.content.termsConditions)),
          show: this.state.isTermsConditionsVisible,
          title: this.props.getTranslation(this.props.data.content.termsConditionsLinkTitle),
          onHide: this.closeTermsConditionsModal.bind(this)
        }
      ),
      /* @__PURE__ */ React.createElement(
        FormContestRulesModal,
        {
          body: this.renderStyledText(this.props.getTranslation(this.props.data.content.contestRules)),
          show: this.state.isContestRulesVisible,
          title: this.props.getTranslation(this.props.data.content.contestRulesLinkTitle),
          onHide: this.closeContestRulesModal.bind(this)
        }
      ),
      /* @__PURE__ */ React.createElement(
        FormContestRulesModal,
        {
          body: this.renderStyledText(this.props.getTranslation(this.props.data.content.privacyPolicy)),
          show: this.state.isPrivacyPolicyVisible,
          title: this.props.getTranslation(this.props.data.content.privacyPolicyLinkTitle),
          onHide: this.closePrivacyPolicyModal.bind(this)
        }
      )
    );
  }
}
const mapStateToProps = (state) => ({
  experience: state.experience
});
export default connect(mapStateToProps, null)(PageSectionHoc(FormSection));
