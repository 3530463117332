import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const multipleChoice = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_CHOICE]: {
    display: "grid",
    "grid-template-columns": "min-content 1fr",
    padding: 0,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_CHOICE_LEGEND]: {
    "margin-bottom": "16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_CHOICE_INPUT]: {
    position: "relative",
    cursor: "pointer",
    margin: "2px 8px 16px 0",
    "border-radius": "initial",
    appearance: "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_CHOICE_LABEL]: {
    "clip-path": "initial",
    overflow: "initial",
    position: "static",
    margin: "5px 0 16px 0",
    width: "initial",
    height: "initial",
    "padding-right": "4px",
    "line-height": "14px"
  }
};
