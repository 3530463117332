import React, { Component } from "react";
import "./edit-color-input.sass";
import { EditInput } from "../";
import PropTypes from "prop-types";
import ColorParser from "../../../../../services/color-parser/color-parser";
import { ClickOutsideHOC } from "../../../../../components/HOC/click-outside/click-outside.hoc";
import ColorPicker from "../../../../../components/interface/inputs/color-picker/color-picker";
class EditColorInput extends Component {
  renderColorPicker() {
    return this.props.isMenuElementVisible ? /* @__PURE__ */ React.createElement("div", { className: "tint-color-picker-container" }, /* @__PURE__ */ React.createElement(
      ColorPicker,
      {
        noInputs: true,
        color: ColorParser.defaultColor(this.props.color),
        onColorChange: (color) => this.props.onChange(color)
      }
    )) : null;
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { ref: (node) => this.props.setMenuNode(node), style: this.props.styles }, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: this.props.label,
        value: ColorParser.RGBAToHex(this.props.color),
        onChange: (e) => this.props.onChange(e.target.value),
        onClick: this.props.displayMenu,
        styles: this.props.inputStyles
      },
      this.renderColorPicker()
    ));
  }
}
EditColorInput.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  styles: PropTypes.object,
  inputStyles: PropTypes.object
};
export default ClickOutsideHOC(EditColorInput);
