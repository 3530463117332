import Logger from "../logger/logger";
const EVENT_TYPE = {
  OPEN_UI_SOCIAL_FEEDS: "OPEN_UI_SOCIAL_FEEDS",
  THEME_FAILED: "THEME_FAILED",
  THEME_LOADED: "THEME_LOADED",
  OPEN_UI_ECOMMERCE: "OPEN_UI_ECOMMERCE",
  CLOSE_UI_ECOMMERCE: "CLOSE_UI_ECOMMERCE",
  OPEN_REQUEST_RIGHTS: "OPEN_REQUEST_RIGHTS",
  CLOSE_REQUEST_RIGHTS: "CLOSE_REQUEST_RIGHTS",
  CHANGE_ROUTE: "CHANGE_ROUTE",
  // dont change this please
  NEW_ACCOUNT: "account_new",
  EDIT_ACCOUNT: "account_edit",
  NEW_PRODUCT_ACCOUNT: "product_account_new",
  EDIT_PRODUCT_ACCOUNT: "product_account_edit",
  MARKETING_INTEGRATION_NEW: "marketing_integration_new",
  MARKETING_INTEGRATION_EDIT: "marketing_integration_edit"
};
const ACTIONS = {
  [EVENT_TYPE.OPEN_UI_SOCIAL_FEEDS]: [],
  [EVENT_TYPE.OPEN_UI_ECOMMERCE]: [],
  [EVENT_TYPE.CLOSE_UI_ECOMMERCE]: [],
  [EVENT_TYPE.THEME_LOADED]: [],
  [EVENT_TYPE.THEME_FAILED]: [],
  [EVENT_TYPE.OPEN_REQUEST_RIGHTS]: [],
  [EVENT_TYPE.CLOSE_REQUEST_RIGHTS]: [],
  [EVENT_TYPE.NEW_ACCOUNT]: [],
  [EVENT_TYPE.EDIT_ACCOUNT]: [],
  [EVENT_TYPE.NEW_PRODUCT_ACCOUNT]: [],
  [EVENT_TYPE.EDIT_PRODUCT_ACCOUNT]: [],
  [EVENT_TYPE.MARKETING_INTEGRATION_EDIT]: [],
  [EVENT_TYPE.MARKETING_INTEGRATION_NEW]: [],
  [EVENT_TYPE.CHANGE_ROUTE]: []
};
let eventer;
const init = () => {
  if (!eventer) {
    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, (e) => {
      if (!e) {
        return;
      }
      let data;
      if (typeof e.data === "string") {
        try {
          data = JSON.parse(e.data || e.message);
        } catch (e2) {
          Logger.error(e2);
          data = {};
        }
      } else {
        data = e.data || e.message;
      }
      if (data.type && ACTIONS[data.type]) {
        ACTIONS[data.type].map((e2) => e2(data));
      }
    });
  } else {
    Logger.info("Service has been already initialized, you should not do it second time.");
  }
  return eventer;
};
const on = ({ eventType, fn }) => {
  ACTIONS[eventType].push(fn);
};
const off = ({ eventType, fn }) => {
  ACTIONS[eventType] = ACTIONS[eventType].filter((subFn) => subFn !== fn);
};
export const EventerService = {
  init,
  on,
  off,
  EVENT_TYPE
};
