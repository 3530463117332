import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { EMAIL_DOMAINS } from "../../consts";
export const ACTIONS = {
  GET_EMAIL_DOMAINS: createRoutine(createPrefixedActionName(EMAIL_DOMAINS, "GET_EMAIL_DOMAINS")),
  GET_EMAIL_DOMAIN: createRoutine(createPrefixedActionName(EMAIL_DOMAINS, "GET_EMAIL_DOMAIN")),
  POST_EMAIL_DOMAINS: createRoutine(createPrefixedActionName(EMAIL_DOMAINS, "POST_EMAIL_DOMAINS")),
  DELETE_EMAIL_DOMAIN: createRoutine(createPrefixedActionName(EMAIL_DOMAINS, "DELETE_EMAIL_DOMAIN"))
};
export const getEmailDomainsStart = () => ({
  type: ACTIONS.GET_EMAIL_DOMAINS.REQUEST
});
export const getEmailDomainsSuccess = (data) => ({
  type: ACTIONS.GET_EMAIL_DOMAINS.SUCCESS,
  payload: data
});
export const getEmailDomainsError = (err) => ({
  type: ACTIONS.GET_EMAIL_DOMAINS.FAILURE,
  payload: err
});
export const getEmailDomainStart = () => ({
  type: ACTIONS.GET_EMAIL_DOMAIN.REQUEST
});
export const getEmailDomainSuccess = (data) => ({
  type: ACTIONS.GET_EMAIL_DOMAIN.SUCCESS,
  payload: data
});
export const getEmailDomainError = (err) => ({
  type: ACTIONS.GET_EMAIL_DOMAIN.FAILURE,
  payload: err
});
export const postEmailDomainsStart = () => ({
  type: ACTIONS.POST_EMAIL_DOMAINS.REQUEST
});
export const postEmailDomainsSuccess = (data) => ({
  type: ACTIONS.POST_EMAIL_DOMAINS.SUCCESS,
  payload: data
});
export const postEmailDomainsError = (err) => ({
  type: ACTIONS.POST_EMAIL_DOMAINS.FAILURE,
  payload: err
});
export const deleteEmailDomainStart = () => ({
  type: ACTIONS.DELETE_EMAIL_DOMAIN.REQUEST
});
export const deleteEmailDomainSuccess = (id) => ({
  type: ACTIONS.DELETE_EMAIL_DOMAIN.SUCCESS,
  payload: id
});
export const deleteEmailDomainError = (err) => ({
  type: ACTIONS.DELETE_EMAIL_DOMAIN.FAILURE,
  payload: err
});
