var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrentLanguage } from "../../../feature/experience/actions/languages/languages.actions";
import { getCustomLanguageList } from "../../../feature/experience/reducers/experience/experience";
import { getLanguageDirection } from "../../../services/functions/language/language";
export const URLLanguageParamsHoc = (PassedComponent) => {
  const queryParamName = "lang";
  class URLLanguageParams extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "determineCurrentLanguage", () => {
        const urlParams = new URLSearchParams(window.location.search);
        const langParam = urlParams.get(queryParamName);
        if (langParam && this.isInLanguageList(langParam)) {
          this.setCurrentLanguage(langParam);
          return;
        }
        const defaultLanguage = this.getDefaultLanguage();
        if (defaultLanguage) {
          this.setCurrentLanguage(defaultLanguage.value);
        }
      });
      __publicField(this, "onUpdateUrlParameter", (language) => {
        const params = new URLSearchParams(window.location.search);
        params.set("lang", language);
        let newRelativePathQuery = window.location.pathname + "?" + params.toString();
        this.setCurrentLanguage(language);
        history.pushState(null, "", newRelativePathQuery);
      });
    }
    getDefaultLanguage() {
      return this.props.languages.find((lang) => lang.isDefault);
    }
    setCurrentLanguage(language) {
      setTimeout(() => {
        if (window.Tint && window.Tint.changeLanguage) {
          window.Tint.changeLanguage(language);
        }
      }, 100);
      this.props.setCurrentLanguage({
        value: language,
        direction: getLanguageDirection(language)
      });
    }
    isInLanguageList(language) {
      if (Array.isArray(this.props.languages)) {
        return this.props.languages.some((lang) => lang.value === language);
      }
    }
    componentDidUpdate(prevProps) {
      if (this.props.experience !== prevProps.experience && this.props.languages) {
        this.determineCurrentLanguage();
      }
    }
    render() {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        PassedComponent,
        __spreadProps(__spreadValues({}, this.props), {
          updateLanguageQueryParam: this.onUpdateUrlParameter,
          currentLanguage: this.props.currentLanguage
        })
      ));
    }
  }
  const mapStateToProps = (state) => ({
    currentLanguage: state.languages.currentLanguage.value,
    languages: getCustomLanguageList(
      state.experience.data,
      state.experience.data && state.experience.data.attributes.data.draft ? "draft" : "published"
    )
  });
  const mapDispatchToProps = (dispatch) => ({
    setCurrentLanguage: (lang) => dispatch(setCurrentLanguage(lang))
  });
  return connect(mapStateToProps, mapDispatchToProps)(URLLanguageParams);
};
