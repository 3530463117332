import { SidebarItemTypes } from "../utils/meta/consts";
import { publicPostingDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import * as Yup from "yup";
const validationSchemaPublicPosting = () => {
  return Yup.object({});
};
const initializeEmailCheckedValues = (socialFeed) => {
  var _a, _b;
  return ((_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.email) ? "email" : null;
};
const initializeCustomFieldCheckedValues = (socialFeed) => {
  var _a, _b;
  return ((_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.custom_field) ? "customField" : null;
};
const initializeCustomTermsCheckedValues = (socialFeed) => {
  var _a, _b;
  return ((_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.terms) ? "customTerms" : null;
};
const initializeMultipleFilesValues = (socialFeed) => {
  var _a, _b;
  return socialFeed ? ((_b = (_a = socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.multiple_files) === false ? null : "multipleFiles" : null;
};
const initializeCustomTermsTextareaValues = (socialFeed) => {
  var _a, _b;
  return ((_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.terms) || "";
};
export const publicPosting = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave
    },
    accountSourceType: "public_post",
    selectedOption: { id: "post", title: "Add Public Posting" },
    header: "Add Public Posting",
    description: "Public posting adds a button to the top left of your display, allowing users to post content directly to your board.",
    zendeskUrl: "https://support.tintup.com/hc/en-us/articles/230907848-Public-Posting",
    formSchema: {
      post: {
        validationSchema: validationSchemaPublicPosting(),
        submitMapper: (values) => {
          const checked = Object.keys({
            email: "email",
            customField: "customField",
            customTerms: "customTerms",
            multipleFiles: "multipleFiles"
          }).reduce((acc, curr) => {
            acc[curr] = !!values.checked.find((i) => i === curr);
            return acc;
          }, {});
          return {
            options: {
              terms: checked.customTerms ? values.customTermsTextarea : "",
              email: checked.email,
              custom_field: checked.customField,
              multiple_files: checked.multipleFiles
            }
          };
        },
        inputs: [
          {
            id: "email",
            backendId: "email",
            label: "People who post to your board must provide their email address.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeEmailCheckedValues
          },
          {
            id: "customField",
            backendId: "custom_field",
            label: "People who post to your board must provide a custom field.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeCustomFieldCheckedValues
          },
          {
            id: "multipleFiles",
            backendId: "multiple_files",
            label: "People who post to your board can upload multiple files.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeMultipleFilesValues
          },
          {
            id: "customTerms",
            label: "Upload custom Terms & Conditions.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeCustomTermsCheckedValues
          },
          {
            id: "customTermsTextarea",
            backendId: "terms",
            placeholder: "Paste your custom terms & conditions here...",
            type: SidebarItemTypes.TEXTAREA,
            defaultValue: initializeCustomTermsTextareaValues,
            disabled: publicPostingDisabledButton
          }
        ],
        editInputs: [
          {
            id: "email",
            backendId: "email",
            label: "People who post to your board must provide their email address.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeEmailCheckedValues
          },
          {
            id: "customField",
            backendId: "custom_field",
            label: "People who post to your board must provide a custom field.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeCustomFieldCheckedValues
          },
          {
            id: "multipleFiles",
            backendId: "multiple_files",
            label: "People who post to your board can upload multiple files.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeMultipleFilesValues
          },
          {
            id: "customTerms",
            label: "Upload custom Terms & Conditions.",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeCustomTermsCheckedValues
          },
          {
            id: "customTermsTextarea",
            backendId: "terms",
            placeholder: "Paste your custom terms & conditions here...",
            type: SidebarItemTypes.TEXTAREA,
            defaultValue: initializeCustomTermsTextareaValues,
            disabled: publicPostingDisabledButton
          }
        ]
      }
    }
  }
];
