var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/account/account.actions";
import { produce } from "immer";
const initialState = {
  tints: [],
  personalizations: {
    data: []
  },
  isFetching: false,
  hasError: false
};
export default (state = initialState, action) => {
  var _a, _b;
  switch (action.type) {
    case ACTIONS.GET_TINTS.REQUEST:
    case ACTIONS.GET_TINT.REQUEST:
    case ACTIONS.GET_PERSONALIZATION.REQUEST:
    case ACTIONS.GET_PERSONALIZATIONS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        hasError: false
      });
    case ACTIONS.GET_TINTS.FAILURE:
    case ACTIONS.GET_TINT.FAILURE:
    case ACTIONS.GET_PERSONALIZATIONS.FAILURE:
    case ACTIONS.GET_PERSONALIZATION.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        err: action.payload,
        isFetching: true,
        hasError: true
      });
    case ACTIONS.GET_TINTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        tints: action.payload,
        isFetching: false,
        hasError: false
      });
    case ACTIONS.GET_TINT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        tint: __spreadProps(__spreadValues({}, (_a = action.payload) == null ? void 0 : _a.data), {
          included: (_b = action.payload) == null ? void 0 : _b.included
        }),
        isFetching: false,
        hasError: false
      });
    case ACTIONS.GET_PERSONALIZATIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.personalizations = action.payload, draft.isFetching = false;
        draft.hasError = false;
      });
    case ACTIONS.GET_PERSONALIZATION.SUCCESS:
      return produce(state, (draft) => {
        draft.personalization = action.payload;
        draft.isFetching = false;
        draft.hasError = false;
      });
    default:
      return state;
  }
};
export const getSlugName = (state) => {
  var _a, _b, _c;
  return state && ((_c = (_b = (_a = state == null ? void 0 : state.account) == null ? void 0 : _a.tint) == null ? void 0 : _b.attributes) == null ? void 0 : _c.slug);
};
export const getCurrentTint = (state, id) => {
  var _a, _b, _c;
  return state && ((_c = (_b = (_a = state == null ? void 0 : state.account) == null ? void 0 : _a.tints) == null ? void 0 : _b.data) == null ? void 0 : _c.find((el) => {
    return el.id === id;
  }));
};
