var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import "./omnichannel-solutions-sidebar.sass";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import { faObjectsColumn } from "@fortawesome/pro-solid-svg-icons";
import { AddContentOption } from "../../../../../../../../components/interface/add-content-option/add-content-option";
import {
  AddContentOptionIcon,
  AddContentOptionIconTypes
} from "../../../../../../../../components/interface/add-content-option/add-content-option-icon/add-content-option-icon";
import { DisplaySettingsSwitcherSteps } from "../../display-settings-switcher/display-settings-switcher";
export const OmniChannelSolutionsType = {
  WEBSITE_EMBED: "embed",
  SIGNAGE_DISPLAY: "screen"
};
const OmniChannelSolutionsTypeList = [
  {
    value: OmniChannelSolutionsType.WEBSITE_EMBED,
    title: "Website Embed",
    description: "Create a gallery embed for your website.",
    icon: faObjectsColumn
  },
  {
    value: OmniChannelSolutionsType.SIGNAGE_DISPLAY,
    title: "Signage Display",
    description: "Create realtime dynamic displays for your audience.",
    icon: faTv
  }
];
const OmnichannelSolutionsSidebar = ({ data, switcherData, onChangeData }) => {
  const [omnichannel, setOmnichannel] = useState(data == null ? void 0 : data.type);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-omnichannel-solutions" }, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-omnichannel-solutions__header" }, "Choose a type below:"), OmniChannelSolutionsTypeList.map((type, i) => /* @__PURE__ */ React.createElement(
    AddContentOption,
    {
      key: i,
      id: type.value,
      isSelected: omnichannel === type.value,
      icon: /* @__PURE__ */ React.createElement(
        AddContentOptionIcon,
        {
          type: AddContentOptionIconTypes.FONTAWESOME,
          faIcon: type.icon,
          container: {
            background: "#373740"
          },
          icon: {
            color: "#fff",
            fontSize: 20
          }
        }
      ),
      content: [
        {
          title: type.title,
          description: type.description
        }
      ],
      onChange: (type2) => {
        setOmnichannel(type2);
        onChangeData(__spreadProps(__spreadValues({}, switcherData[DisplaySettingsSwitcherSteps.DISPLAY_OPTIONS]), {
          type: type2,
          isValid: type2 || omnichannel !== void 0
        }));
      }
    }
  )));
};
export default OmnichannelSolutionsSidebar;
