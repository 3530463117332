import { bazaarvoice } from "./bazaarvoice.schema";
import { creatorIq } from "./creator-iq.schema";
import { powerReviews } from "./power-reviews.schema";
import { reCaptcha } from "./re_captcha.schema";
export const socialAccountsSchema = {
  bazaarvoice,
  creator_iq: creatorIq,
  power_reviews: powerReviews,
  re_captcha: reCaptcha
};
