import { Config } from "../../../../utils/config";
export const createParamsForUrl = ({
  baseUrl,
  sort = "-id",
  size = Config.defaultPageSize,
  pageNumber,
  filters: filters2 = [],
  fields: fields2 = {},
  include
} = {}) => {
  if (!baseUrl) {
    throw new Error("baseUrl is mandatory.");
  }
  const filtersParam = filters2.filter(({ value }) => !!value).reduce((acc, curr) => {
    return `${acc}&filter[${curr.by}]=${encodeURIComponent(curr.value)}`;
  }, "");
  const fieldsParam = Object.entries(fields2).map(([key, values]) => `&fields[${key}]=${values.join(",")}`).join("");
  const includeParam = include ? `&include=${include}` : "";
  return `${baseUrl}?sort=${sort}&page[size]=${size}${pageNumber ? `&page[number]=${pageNumber}` : ""}${filtersParam}${fieldsParam}${includeParam}`;
};
const SOURCE = "source";
const QUERY = "q";
const TYPE = "type";
const STATUS = "status";
const PERMISSION = "permission";
const ROLE_ID = "role_id";
const PRODUCT_FEED_ID = "product_feed_ids";
const AVAILABILITY = "availability";
const CATEGORIES = "categories";
const TINT_ID = "tint_id";
const BRAND = "brand";
const SKIP_IDP = "skip_idp";
const PUBLISHED = "published";
export const filters = {
  SOURCE,
  QUERY,
  TYPE,
  STATUS,
  PERMISSION,
  PRODUCT_FEED_ID,
  AVAILABILITY,
  CATEGORIES,
  ROLE_ID,
  TINT_ID,
  BRAND,
  SKIP_IDP,
  PUBLISHED
};
const ROLE = "role";
const TINT = "tint";
const COLLECTION = "collection";
const ACCOUNT = "account";
const EXPERIENCE = "experience";
const FONT = "font";
const DOMAIN = "domain";
const PRODUCT_FEED = "product_feed";
const PRODUCT_ACCOUNT = "product_account";
const PERSONALIZATION = "personalization";
const WEBHOOK = "webhook";
const OAUTH_APPLICATION = "oauth_application";
const FORM = "form";
const MIX = "mix";
export const fields = {
  ROLE,
  TINT,
  PERSONALIZATION,
  ACCOUNT,
  COLLECTION,
  EXPERIENCE,
  FONT,
  DOMAIN,
  PRODUCT_FEED,
  PRODUCT_ACCOUNT,
  WEBHOOK,
  OAUTH_APPLICATION,
  FORM,
  MIX
};
export const querySize = 1e3;
export const queryParamsNames = {
  ROLE_ID: "roleId"
};
