import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_ORDER_COUNT: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_ORDER_COUNT")),
  GET_ORDER_SALES: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_ORDER_SALES")),
  GET_AOV_AFTER_ENGAGING_FROM_EMBED: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_AOV_AFTER_ENGAGING_FROM_EMBED")
  ),
  GET_AOV_NOT_ENGAGING_WITH_TINT: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_AOV_NOT_ENGAGING_WITH_TINT")
  ),
  GET_AOS_AFTER_ENGAGING_FROM_EMBED: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_AOS_AFTER_ENGAGING_FROM_EMBED")
  ),
  GET_AOS_NOT_ENGAGING_WITH_TINT: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_AOS_NOT_ENGAGING_WITH_TINT")
  ),
  GET_PRODUCTION_CONVERSION_RATE: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_PRODUCTION_CONVERSION_RATE")
  ),
  GET_TOTAL_UNIQUE_USERS_CLICKED: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_TOTAL_UNIQUE_USERS_CLICKED")
  ),
  GET_ASSISTED_ORDER_COUNT: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_ASSISTED_ORDER_COUNT")),
  GET_TOTAL_ORDER_COUNT: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_TOTAL_ORDER_COUNT")),
  GET_TOTAL_PRODUCT: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_TOTAL_PRODUCT")),
  GET_TOTAL_REVENUE: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_TOTAL_REVENUE")),
  GET_TOP_EMBED_CLICKED_POSTS: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_TOP_EMBED_CLICKED_POSTS")
  ),
  GET_POST_CONVERSION: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_POST_CONVERSION")),
  GET_POST_CLICKS: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_POST_CLICKS")),
  GET_PAGE_LOADS: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_PAGE_LOADS")),
  GET_EMBED_POST_PRODUCT_CLICKED: createRoutine(
    createPrefixedActionName("INSIGHTS_COMMERCE", "GET_EMBED_POST_PRODUCT_CLICKED")
  ),
  GET_ADD_TO_CART: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_ADD_TO_CART")),
  GET_CONVERSION: createRoutine(createPrefixedActionName("INSIGHTS_COMMERCE", "GET_CONVERSION"))
};
export const getAosAfterEngagingFromEmbedStart = () => ({
  type: ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.REQUEST
});
export const getAosAfterEngagingFromEmbedSuccess = (data) => ({
  type: ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.SUCCESS,
  payload: data
});
export const getAosAfterEngagingFromEmbedError = (err) => ({
  type: ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.FAILURE,
  payload: err
});
export const getAosNotEngagingWithTintStart = () => ({
  type: ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.REQUEST
});
export const getAosNotEngagingWithTintSuccess = (data) => ({
  type: ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.SUCCESS,
  payload: data
});
export const getAosNotEngagingWithTintError = (err) => ({
  type: ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.FAILURE,
  payload: err
});
export const getAovAfterEngagingFromEmbedStart = () => ({
  type: ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.REQUEST
});
export const getAovAfterEngagingFromEmbedSuccess = (data) => ({
  type: ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.SUCCESS,
  payload: data
});
export const getAovAfterEngagingFromEmbedError = (err) => ({
  type: ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.FAILURE,
  payload: err
});
export const getAovNotEngagingWithTintStart = () => ({
  type: ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.REQUEST
});
export const getAovNotEngagingWithTintSuccess = (data) => ({
  type: ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.SUCCESS,
  payload: data
});
export const getAovNotEngagingWithTintError = (err) => ({
  type: ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.FAILURE,
  payload: err
});
export const getPageLoadsStart = () => ({
  type: ACTIONS.GET_PAGE_LOADS.REQUEST
});
export const getPageLoadsSuccess = (data) => ({
  type: ACTIONS.GET_PAGE_LOADS.SUCCESS,
  payload: data
});
export const getPageLoadsError = (err) => ({
  type: ACTIONS.GET_PAGE_LOADS.FAILURE,
  payload: err
});
export const getEmbedPostProductClickedStart = () => ({
  type: ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.REQUEST
});
export const getEmbedPostProductClickedSuccess = (data) => ({
  type: ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.SUCCESS,
  payload: data
});
export const getEmbedPostProductClickedError = (err) => ({
  type: ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.FAILURE,
  payload: err
});
export const getAddToCartStart = () => ({
  type: ACTIONS.GET_ADD_TO_CART.REQUEST
});
export const getAddToCartSuccess = (data) => ({
  type: ACTIONS.GET_ADD_TO_CART.SUCCESS,
  payload: data
});
export const getAddToCartError = (err) => ({
  type: ACTIONS.GET_ADD_TO_CART.FAILURE,
  payload: err
});
export const getConversionStart = () => ({
  type: ACTIONS.GET_CONVERSION.REQUEST
});
export const getConversionSuccess = (data) => ({
  type: ACTIONS.GET_CONVERSION.SUCCESS,
  payload: data
});
export const getConversionError = (err) => ({
  type: ACTIONS.GET_CONVERSION.FAILURE,
  payload: err
});
export const getPostClicksStart = () => ({
  type: ACTIONS.GET_POST_CLICKS.REQUEST
});
export const getPostClicksSuccess = (data) => ({
  type: ACTIONS.GET_POST_CLICKS.SUCCESS,
  payload: data
});
export const getPostClicksError = (err) => ({
  type: ACTIONS.GET_POST_CLICKS.FAILURE,
  payload: err
});
export const getPostConversionStart = () => ({
  type: ACTIONS.GET_POST_CONVERSION.REQUEST
});
export const getPostConversionSuccess = (data) => ({
  type: ACTIONS.GET_POST_CONVERSION.SUCCESS,
  payload: data
});
export const getPostConversionError = (err) => ({
  type: ACTIONS.GET_POST_CONVERSION.FAILURE,
  payload: err
});
export const getTopEmbedClickedPostsStart = () => ({
  type: ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.REQUEST
});
export const getTopEmbedClickedPostsSuccess = (data) => ({
  type: ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.SUCCESS,
  payload: data
});
export const getTopEmbedClickedPostsError = (err) => ({
  type: ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.FAILURE,
  payload: err
});
export const getTotalRevenueStart = () => ({
  type: ACTIONS.GET_TOTAL_REVENUE.REQUEST
});
export const getTotalRevenueSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_REVENUE.SUCCESS,
  payload: data
});
export const getTotalRevenueError = (err) => ({
  type: ACTIONS.GET_TOTAL_REVENUE.FAILURE,
  payload: err
});
export const getTotalProductStart = () => ({
  type: ACTIONS.GET_TOTAL_PRODUCT.REQUEST
});
export const getTotalProductSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_PRODUCT.SUCCESS,
  payload: data
});
export const getTotalProductError = (err) => ({
  type: ACTIONS.GET_TOTAL_PRODUCT.FAILURE,
  payload: err
});
export const getTotalOrderCountStart = () => ({
  type: ACTIONS.GET_TOTAL_ORDER_COUNT.REQUEST
});
export const getTotalOrderCountSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_ORDER_COUNT.SUCCESS,
  payload: data
});
export const getTotalOrderCountError = (err) => ({
  type: ACTIONS.GET_TOTAL_ORDER_COUNT.FAILURE,
  payload: err
});
export const getAssistedOrderCountStart = () => ({
  type: ACTIONS.GET_ASSISTED_ORDER_COUNT.REQUEST
});
export const getAssistedOrderCountSuccess = (data) => ({
  type: ACTIONS.GET_ASSISTED_ORDER_COUNT.SUCCESS,
  payload: data
});
export const getAssistedOrderCountError = (err) => ({
  type: ACTIONS.GET_ASSISTED_ORDER_COUNT.FAILURE,
  payload: err
});
export const getProductionConversionRateStart = () => ({
  type: ACTIONS.GET_PRODUCTION_CONVERSION_RATE.REQUEST
});
export const getProductionConversionRateSuccess = (data) => ({
  type: ACTIONS.GET_PRODUCTION_CONVERSION_RATE.SUCCESS,
  payload: data
});
export const getProductionConversionRateError = (err) => ({
  type: ACTIONS.GET_PRODUCTION_CONVERSION_RATE.FAILURE,
  payload: err
});
export const getTotalUniqueUsersClickedStart = () => ({
  type: ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.REQUEST
});
export const getTotalUniqueUsersClickedSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.SUCCESS,
  payload: data
});
export const getTotalUniqueUsersClickedError = (err) => ({
  type: ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.FAILURE,
  payload: err
});
export const getOrderCountStart = () => ({
  type: ACTIONS.GET_ORDER_COUNT.REQUEST
});
export const getOrderCountSuccess = (data) => ({
  type: ACTIONS.GET_ORDER_COUNT.SUCCESS,
  payload: data
});
export const getOrderCountError = (err) => ({
  type: ACTIONS.GET_ORDER_COUNT.FAILURE,
  payload: err
});
export const getOrderSalesStart = () => ({
  type: ACTIONS.GET_ORDER_SALES.REQUEST
});
export const getOrderSalesSuccess = (data) => ({
  type: ACTIONS.GET_ORDER_SALES.SUCCESS,
  payload: data
});
export const getOrderSalesError = (err) => ({
  type: ACTIONS.GET_ORDER_SALES.FAILURE,
  payload: err
});
