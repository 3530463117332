import React from "react";
import { useSelector } from "react-redux";
import "./request-preview.sass";
import TintModal from "../../../modal/modal";
import { RequestRightsService } from "../../services/request-rights.service";
import TintIcon from "../../../interface/icon/icon";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
export const RequestPreview = () => {
  const requestRights = useSelector((state) => state.requestRights);
  const socialConnections = useSelector((state) => state.socialConnections);
  const data = {};
  const getMentionProperty = () => {
    if (requestRights.modal.subaccount_id) {
      const _subAccount = socialConnections.subAccountsData.data.find((e) => e.id === requestRights.modal.subaccount_id);
      return _subAccount ? _subAccount.attributes.username || _subAccount.attributes.name : "";
    }
    if (requestRights.modal.account_id) {
      const _account = socialConnections.accounts.data.find((e) => e.id === `${requestRights.modal.account_id}`);
      return _account ? _account.attributes.username || _account.attributes.name : "";
    }
    return "";
  };
  const rightRequestTerm = requestRights.termsData ? requestRights.termsData.data.find((e) => e.id === requestRights.modal.right_request_term_id) : "";
  data.username = requestRights.postMessageData.payload.author.username;
  data.hashtag = `#${requestRights.modal.hashtag}`;
  data.terms_url = rightRequestTerm ? rightRequestTerm.attributes.url : "";
  data.mention = getMentionProperty();
  return requestRights ? /* @__PURE__ */ React.createElement(
    TintModal,
    {
      className: "tint-request-rights-preview",
      zIndex: 1,
      isOpen: true,
      animationTime: 0.1,
      isBackDropVisible: false
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-request-rights-preview__container" }, /* @__PURE__ */ React.createElement("div", { className: "tint-request-rights-preview__label" }, /* @__PURE__ */ React.createElement("span", null, "Message Preview")), /* @__PURE__ */ React.createElement("div", { className: "tint-request-rights-preview__body" }, /* @__PURE__ */ React.createElement("div", { className: "tint-request-rights-preview__author" }, /* @__PURE__ */ React.createElement("img", { src: requestRights.postMessageData.payload.author.image_url }), requestRights.postMessageData.payload.author.username), /* @__PURE__ */ React.createElement("div", { className: "tint-request-rights-preview__image-container" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-request-rights-preview__image",
        style: { backgroundImage: `url('${requestRights.postMessageData.payload.image}')` }
      }
    )), requestRights.modal.template && /* @__PURE__ */ React.createElement("div", { className: "tint-request-rights-preview__comment-container" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faComment }), /* @__PURE__ */ React.createElement("span", { className: "tint-request-rights-preview__comment" }, RequestRightsService.templateToString({ template: requestRights.modal.template, params: data })))))
  ) : null;
};
