var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/email-templates/email-templates-builder.actions";
import { produce } from "immer";
import { Config } from "../../../../utils/config";
export const initialState = {
  data: void 0,
  initData: void 0,
  fromSubmission: {
    isFetching: false,
    data: void 0,
    included: {}
  },
  form: {
    isFetching: false,
    data: void 0
  },
  id: void 0,
  isFetching: false,
  error: void 0
};
export const emailTemplatesBuilder = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.POST_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.SUCCESS:
      return produce(state, (draft) => {
        draft.data = action.payload.attributes;
        draft.initData = action.payload.attributes;
        draft.id = action.payload.id;
        draft.isFetching = false;
        draft.error = void 0;
      });
    case ACTIONS.POST_EMAIL_TEMPLATE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data.attributes,
        initData: action.payload.data.attributes,
        id: action.payload.data.id,
        links: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_FORM.REQUEST:
      return produce(state, (draft) => {
        draft.form.isFetching = false;
        draft.form.error = action.payload;
      });
    case ACTIONS.GET_FORM_BUILDER_SUBMISSION.REQUEST:
      return produce(state, (draft) => {
        draft.fromSubmission.isFetching = false;
        draft.fromSubmission.error = action.payload;
      });
    case ACTIONS.GET_FORM.FAILURE:
      return produce(state, (draft) => {
        draft.form.isFetching = false;
        draft.form.error = action.payload;
      });
    case ACTIONS.GET_FORM_BUILDER_SUBMISSION.FAILURE:
      return produce(state, (draft) => {
        draft.fromSubmission.isFetching = false;
        draft.fromSubmission.error = action.payload;
      });
    case ACTIONS.GET_EMAIL_TEMPLATE.FAILURE:
    case ACTIONS.POST_EMAIL_TEMPLATE.FAILURE:
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    case ACTIONS.GET_FORM_BUILDER_SUBMISSION.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b;
        draft.fromSubmission.data = action.payload.data;
        draft.fromSubmission.included = (_b = (_a = action.payload) == null ? void 0 : _a.included) == null ? void 0 : _b.map((el) => ({
          [el.id]: el.attributes.url
        })).reduce((result, item) => {
          return __spreadValues(__spreadValues({}, result), item);
        }, {});
        draft.fromSubmission.isFetching = false;
        draft.fromSubmission.error = void 0;
      });
    case ACTIONS.GET_FORM.SUCCESS:
      return produce(state, (draft) => {
        draft.form.data = action.payload;
        draft.form.isFetching = false;
        draft.form.error = void 0;
      });
    case ACTIONS.GET_EMAIL_TEMPLATE.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b, _c, _d, _e, _f;
        draft.data = (_b = (_a = action.payload) == null ? void 0 : _a.data) == null ? void 0 : _b.attributes;
        draft.initData = (_d = (_c = action.payload) == null ? void 0 : _c.data) == null ? void 0 : _d.attributes;
        draft.id = (_f = (_e = action.payload) == null ? void 0 : _e.data) == null ? void 0 : _f.id;
        draft.isFetching = false;
        draft.error = void 0;
      });
    case ACTIONS.DISCARD_DRAFT_EMAIL_TEMPLATE:
      return produce(state, (draft) => {
        draft.data = void 0;
        draft.initData = void 0;
      });
    case ACTIONS.UPDATE_DRAFT_EMAIL_TEMPLATE:
      return produce(state, (draft) => {
        const { key, value } = action.payload;
        if (draft.data) {
          draft.data[key] = value;
        } else {
          draft.data = { [key]: value };
        }
      });
    case ACTIONS.ADD_LOCALES:
      return produce(state, (draft) => {
        var _a, _b;
        const { lang, locales } = action.payload;
        if ((_a = draft == null ? void 0 : draft.data) == null ? void 0 : _a.locales) {
          draft.data.locales[lang] = locales;
        } else {
          draft.data = __spreadProps(__spreadValues({}, draft.data), {
            locales: __spreadProps(__spreadValues({}, (_b = draft.data) == null ? void 0 : _b.locales), {
              [lang]: locales
            })
          });
        }
      });
    case ACTIONS.UPDATE_TRANSLATION:
      return produce(state, (draft) => {
        var _a, _b, _c, _d, _e, _f;
        const { lang, key, value } = action.payload;
        draft.data = __spreadProps(__spreadValues({}, draft.data || {}), {
          locales: __spreadProps(__spreadValues({}, ((_a = draft.data) == null ? void 0 : _a.locales) || {}), {
            [lang]: __spreadValues(__spreadValues({}, ((_c = (_b = draft.data) == null ? void 0 : _b.locales) == null ? void 0 : _c[lang]) || {}), {
              [key]: typeof value === "object" ? __spreadValues(__spreadValues({}, ((_f = (_e = (_d = draft.data) == null ? void 0 : _d.locales) == null ? void 0 : _e[lang]) == null ? void 0 : _f[key]) || {}), value) : value
            })
          })
        });
      });
    case ACTIONS.REMOVE_LOCALES:
      return produce(state, (draft) => {
        const { lang } = action.payload;
        delete draft.data.locales[lang];
      });
    default: {
      return state;
    }
  }
};
