import React from "react";
import PropTypes from "prop-types";
import "./stripe-checkout-form-footer.sass";
import { Button } from "../../interface/button/button";
import { getPlan } from "../../../reducers/session";
import { useSelector } from "react-redux";
export const StripeCheckoutFormFooter = ({ onClose, onClickPay = 0, numberOfDays = 1, disabled }) => {
  var _a;
  const plan = useSelector(getPlan);
  const instintUpdatesDailyPrice = ((_a = plan == null ? void 0 : plan.attributes) == null ? void 0 : _a.instint_updates_daily_price) * numberOfDays || 0;
  const value = (Math.round(instintUpdatesDailyPrice) / 100).toFixed(2);
  return /* @__PURE__ */ React.createElement("div", { className: "stripe-checkout-form-footer" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", text: "Cancel", onClick: onClose }), /* @__PURE__ */ React.createElement(Button, { type: "primary", text: `Pay $${value}`, onClick: onClickPay, isDisabled: disabled }));
};
StripeCheckoutFormFooter.propTypes = {
  previousStep: PropTypes.func,
  onClickPay: PropTypes.func,
  estimatedQuote: PropTypes.number
};
