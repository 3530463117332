import React from "react";
import "./add-content-option.sass";
import TintButtonIcon, { TintButtonIconShape, TintButtonIconTypes } from "../button-icon/button-icon";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
export const AddContentOption = ({
  id,
  withoutHover = false,
  content,
  icon,
  onChange,
  isSelected = false,
  showSelectedIcon = false,
  link,
  isInactive,
  isComingSoon,
  isRequired
}) => {
  return /* @__PURE__ */ React.createElement(
    "li",
    {
      className: `tint-add-content-option ${isSelected ? "tint-add-content-option--selected" : ""} ${isInactive ? "tint-add-content-option--inactive" : ""} ${withoutHover ? "" : "tint-add-content-option--hoverable"}`,
      onClick: () => {
        if (isInactive) {
          return;
        }
        if (link) {
          window.open(link.href, "_blank");
        }
        onChange && onChange(id);
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-add-content-option__wrapper" }, icon, /* @__PURE__ */ React.createElement("div", { className: "tint-add-content-option__content" }, content.map((meta, index) => /* @__PURE__ */ React.createElement(
      "div",
      {
        key: index,
        className: `tint-add-content-option__meta ${content.length > 1 ? "tint-add-content-option__meta--group" : ""}`
      },
      /* @__PURE__ */ React.createElement("h5", null, meta.title, isComingSoon && /* @__PURE__ */ React.createElement("span", { className: "tint-add-content-option__coming-soon-label" }, "Coming soon"), isRequired && /* @__PURE__ */ React.createElement("span", { className: "tint-add-content-option__coming-soon-label" }, "Upgrade required")),
      /* @__PURE__ */ React.createElement("p", null, meta.description),
      meta.link && /* @__PURE__ */ React.createElement("a", { href: meta.link.url, className: "tint-add-content-option__link", target: "_blank", rel: "noreferrer" }, meta.link.label)
    )), link && /* @__PURE__ */ React.createElement("a", { className: "tint-add-content-option__link", rel: "noopener noreferrer" }, link.description))),
    /* @__PURE__ */ React.createElement("div", { className: "tint-add-content-option__selected-icon" }, showSelectedIcon && isSelected && /* @__PURE__ */ React.createElement(
      TintButtonIcon,
      {
        icon: faCheck,
        shape: TintButtonIconShape.CIRCLE,
        type: TintButtonIconTypes.DARK,
        iconSize: 12,
        width: 20,
        height: 20
      }
    ))
  );
};
