var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/request-rights/request-rights.actions";
import { REQUEST_RIGHTS_SOURCE, REQUEST_RIGHTS_STEP } from "../../components/request-rights/request-rights";
import produce from "immer";
export const initialState = {
  requestRightsData: void 0,
  templateSuggestions: void 0,
  requestRightsPostData: void 0,
  postMessageData: void 0,
  termsData: void 0,
  step: REQUEST_RIGHTS_STEP.PRIMARY,
  isFetching: false,
  data: [],
  termsAndConditions: {},
  modal: {
    hashtag: "",
    template: "",
    templateName: "",
    right_request_term_id: "",
    source: "",
    user_id: "",
    external_id: "",
    account_id: "",
    subaccount_id: ""
  }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
    case ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
    case ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        termsAndConditions: __spreadProps(__spreadValues({}, state.termsAndConditions), {
          isFetching: true
        })
      });
    case ACTIONS.POST_REQUEST_RIGHTS.REQUEST:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true
      });
    case ACTIONS.POST_REQUEST_RIGHTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        requestRightsPostData: action.payload
      });
    case ACTIONS.SET_MODAL_PROGRESS:
      return __spreadProps(__spreadValues({}, state), {
        modal: __spreadValues(__spreadValues({}, state.modal), action.payload)
      });
    case ACTIONS.OPEN_REQUEST_RIGHTS:
      return __spreadProps(__spreadValues({}, state), {
        postMessageData: action.payload
      });
    case ACTIONS.CLOSE_REQUEST_RIGHTS:
      return __spreadProps(__spreadValues({}, initialState), {
        termsData: state.termsData
      });
    case ACTIONS.SET_REQUEST_RIGHT_STEP:
      return __spreadProps(__spreadValues({}, state), {
        step: action.payload
      });
    case ACTIONS.DELETE_REQUEST_RIGHTS_TEMPLATE.SUCCESS:
      return produce(state, (draft) => {
        draft.requestRightsData.data.filter((el) => el.id !== action.payload);
      });
    case ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.termsAndConditions.data = draft.termsAndConditions.data.filter((el) => el.id !== action.payload);
        draft.termsAndConditions.isFetching = false;
      });
    case ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.termsAndConditions.data = [action.payload.data, ...draft.termsAndConditions.data];
        draft.termsAndConditions.isFetching = false;
      });
    case ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.termsAndConditions.data[action.payload.data.id] = action.payload.data;
        draft.termsAndConditions.isFetching = false;
      });
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.termsAndConditions = action.payload;
        draft.termsAndConditions.isFetching = false;
      });
    case ACTIONS.POST_REQUEST_RIGHTS_TEMPLATE.SUCCESS:
      return produce(state, (draft) => {
        var _a;
        (_a = draft.requestRightsData) == null ? void 0 : _a.data.push(action.payload.data);
      });
    case ACTIONS.GET_REQUEST_RIGHTS_TEMPLATES.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        requestRightsData: action.payload
      });
    case ACTIONS.GET_REQUEST_RIGHTS_AI_TEMPLATES.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        isFetchingAiTemplates: true
      });
    case ACTIONS.GET_REQUEST_RIGHTS_AI_TEMPLATES.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        templateSuggestions: action.payload,
        isFetching: false,
        isFetchingAiTemplates: false
      });
    case ACTIONS.GET_REQUEST_RIGHTS_AI_TEMPLATES.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        isFetchingAiTemplates: false,
        error: action.payload
      });
    case ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
    case ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
    case ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        termsAndConditions: __spreadProps(__spreadValues({}, state.termsAndConditions), {
          isFetching: false,
          error: action.payload
        })
      });
    case ACTIONS.POST_REQUEST_RIGHTS.FAILURE:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false
      });
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        termsData: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
};
export const isCommentSectionFilled = (state) => {
  const modal = state.modal;
  if (modal.source === REQUEST_RIGHTS_SOURCE.INSTAGRAM_BUSINESS) {
    return !!(modal.subaccount_id && modal.hashtag && modal.template);
  }
  return !!(modal.source && modal.hashtag && modal.template);
};
export const isTermsSectionFilled = (state) => {
  const modal = state.modal;
  return !!modal.right_request_term_id;
};
