var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import store from "../../../../store";
import update from "immutability-helper/index";
import { prepareExperienceObject } from "../utils/experience-utils";
import {
  ACTIONS,
  deleteExperienceError,
  deleteExperienceStart,
  deleteExperienceSuccess,
  getExperienceError,
  getExperienceStart,
  getExperienceSuccess,
  patchExperienceError,
  patchExperienceStart,
  patchExperienceSuccess,
  postExperienceError,
  postExperienceStart,
  postExperienceSuccess,
  putExperienceError,
  putExperienceStart,
  putExperienceSuccess
} from "./experience.actions";
import { pageSectionType } from "../../../../model/page-section-type.model";
import {
  getCookieBanner,
  getCustomButton,
  getCustomImage,
  getCustomSpacer,
  getCustomVideo,
  getForm,
  getFormBuilder,
  getHeadline,
  getMultiColumn,
  getParagraph,
  getSocial,
  getSocialFeedCounter,
  getTimer,
  getTint
} from "../../../../data/default-experience-data/shared.data";
import { addCustomizableHeader } from "../../../../data/default-experience-data";
import { Config } from "../../../../utils/config";
import {
  saveTranslationDraft,
  standardizeAllTranslations,
  standardizeTranslation
} from "./experience-translations.actions";
import { TranslationSupport } from "../../containers/experience/translation-support";
import { formUpload } from "../../../../model/form-upload.model";
import colors from "../../../../data/default-experience-data/colors";
import { ApiService } from "../../../../services/api-service/api-service";
export const saveDataAttributesDraft = (attributes) => {
  return {
    type: ACTIONS.SAVE_DATA_ATTRIBUTES_DRAFT,
    payload: attributes
  };
};
export const reorderExperience = (data) => {
  return {
    type: ACTIONS.REORDER_EXPERIENCE,
    payload: data
  };
};
export const reorderMultiColumnExperience = (data, index) => {
  return {
    type: ACTIONS.REORDER_MULTI_COLUMN_EXPERIENCE,
    payload: { data, index }
  };
};
export const saveDataContentDraft = (content, pageSectionIndex) => {
  return {
    type: ACTIONS.SAVE_DATA_CONTENT_DRAFT,
    payload: {
      data: content,
      pageSectionIndex
    }
  };
};
export const saveMultiColumnDataContentDraft = (content, pageSectionIndex, parentSectionIndex) => {
  const currentContent = JSON.parse(
    JSON.stringify(
      store.getState().experience.dataDraft.attributes.data.draft[parentSectionIndex].data.content.sections[pageSectionIndex].data.content
    )
  );
  return {
    type: ACTIONS.SAVE_DATA_MULTI_COLUMN_CONTENT_DRAFT,
    payload: {
      data: update(currentContent, { $merge: content }),
      pageSectionIndex,
      parentSectionIndex
    }
  };
};
export const saveCustomizableTextDataDraft = (customizableHeader, customizableHeaderIndex, pageSectionIndex) => {
  return {
    type: ACTIONS.SAVE_CUSTOMIZABLE_TEXT_DRAFT,
    payload: {
      customizableHeader,
      pageSectionIndex,
      customizableHeaderIndex
    }
  };
};
export const saveCustomizableMultiColumnTextDataDraft = (customizableHeader, customizableHeaderIndex, pageSectionIndex, parentSectionIndex) => {
  return {
    type: ACTIONS.SAVE_CUSTOMIZABLE_MULTI_COLUMN_TEXT_DRAFT,
    payload: {
      customizableHeader,
      pageSectionIndex,
      customizableHeaderIndex,
      parentSectionIndex
    }
  };
};
export const saveDataStylesDraft = (content, pageSectionIndex, parentSectionIndex) => {
  if (typeof parentSectionIndex === "number") {
    return {
      type: ACTIONS.SAVE_DATA_MULTI_COLUMN_STYLES_DRAFT,
      payload: {
        data: content,
        pageSectionIndex,
        parentSectionIndex
      }
    };
  }
  return {
    type: ACTIONS.SAVE_DATA_STYLES_DRAFT,
    payload: {
      data: content,
      pageSectionIndex
    }
  };
};
export const applyDraftExperience = () => {
  const state = store.getState();
  const experience = state.experience.dataDraft;
  return (dispatch) => {
    dispatch({ type: ACTIONS.APPLY_DRAFT_DATA });
    if (experience) {
      return putExperience(experience.id, prepareExperienceObject(experience))(dispatch);
    }
  };
};
const headerOptions = {
  color: "#000000"
};
const getCustomSectionData = (sectionType, experienceType) => {
  const id = Config.getUniqueId().toUpperCase();
  const buttonKey = `EXPERIENCE.${experienceType.toUpperCase()}.${sectionType.toUpperCase()}.TEXT_${id}`;
  const buttonLinkKey = `EXPERIENCE.${experienceType.toUpperCase()}.${sectionType.toUpperCase()}.LINK_${id}`;
  switch (sectionType) {
    case pageSectionType.COOKIE_BANNER:
      return {
        customSectionData: getCookieBanner()
      };
    case pageSectionType.CUSTOM_BUTTON:
      return {
        customSectionData: getCustomButton({}, {}, buttonKey, buttonLinkKey)
      };
    case pageSectionType.CUSTOM_PARAGRAPH:
      return {
        customSectionData: getParagraph(
          {},
          {},
          "Get ready to be featured when you share your\n unique sense of style with our brand"
        )
      };
    case pageSectionType.CUSTOM_HEADLINE:
      return {
        customSectionData: getHeadline({}, {}, "HEADLINE")
      };
    case pageSectionType.FORM_BUILDER:
      return {
        customSectionData: getFormBuilder({ backgroundColor: colors.white })
      };
    case pageSectionType.TINT:
      return {
        customSectionData: addCustomizableHeader(getTint({ backgroundColor: colors.white }), __spreadProps(__spreadValues({}, headerOptions), {
          text: "#BRANDHASHTAG"
        }))
      };
    case pageSectionType.TIMER:
      return {
        customSectionData: addCustomizableHeader(getTimer(), __spreadProps(__spreadValues({}, headerOptions), {
          text: "ENTRY TEXT"
        }))
      };
    case pageSectionType.SOCIAL:
      return { customSectionData: getSocial() };
    case pageSectionType.FORM:
      return {
        customSectionData: addCustomizableHeader(
          getForm(false, {
            uploadPhotoType: formUpload.MANY
          }),
          {
            text: "ENTER TEXT",
            textDecoration: "",
            fontStyle: "",
            textAlign: "center",
            fontFamily: {
              value: "Lato",
              name: "Lato",
              url: "Lato"
            },
            fontWeight: 500,
            topPosition: 50,
            fontSize: 36,
            lineHeight: 38,
            width: 566,
            color: colors.white,
            backgroundColor: { r: 0, g: 0, b: 0, a: 1 }
          }
        )
      };
    case pageSectionType.CUSTOM_SPACER:
      return { customSectionData: getCustomSpacer() };
    case pageSectionType.IMAGE:
      return { customSectionData: getCustomImage({}, {}) };
    case pageSectionType.VIDEO:
      return { customSectionData: getCustomVideo({}, {}) };
    case pageSectionType.MULTI_COLUMN:
      return { customSectionData: getMultiColumn() };
    case pageSectionType.SOCIAL_FEED_COUNTER:
      return {
        customSectionData: getSocialFeedCounter({
          counterLabelText: "POSTS & COUNTING"
        })
      };
  }
};
export const addMultiColumnSection = (section, index, newSectionList) => {
  const state = store.getState();
  const experienceType = state.experience.data.attributes.type;
  const translationSupport = new TranslationSupport(
    state.experience.data,
    experienceType,
    {
      standardizeTranslation: (key, value) => store.dispatch(standardizeTranslation(key, value)),
      saveDataContentDraft: (content, pageSectionIndex, parentSectionIndex) => parentSectionIndex === void 0 ? store.dispatch(saveDataContentDraft(content, pageSectionIndex)) : store.dispatch(saveMultiColumnDataContentDraft(content, pageSectionIndex, parentSectionIndex)),
      applyDraftExperience: () => store.dispatch(applyDraftExperience()),
      saveCustomizableTextDataDraft: (customizableHeader, customizableHeaderIndex, pageSectionIndex, parentSectionIndex) => parentSectionIndex !== void 0 ? store.dispatch(
        saveCustomizableMultiColumnTextDataDraft(
          customizableHeader,
          customizableHeaderIndex,
          pageSectionIndex,
          parentSectionIndex
        )
      ) : store.dispatch(
        saveCustomizableTextDataDraft(customizableHeader, customizableHeaderIndex, pageSectionIndex)
      )
    },
    state.experience.data.attributes.data.translations.draft
  );
  store.dispatch(saveDataContentDraft({ sections: newSectionList }, index));
  store.dispatch(
    putExperience(
      store.getState().experience.data.id,
      store.getState().experience.dataDraft,
      () => standardizeAllTranslations(translationSupport)
    )
  );
};
export const addSection = (sectionType, index, attribute) => {
  const state = store.getState();
  const experienceType = state.experience.data.attributes.type;
  const translationSupport = new TranslationSupport(
    state.experience.data,
    experienceType,
    {
      standardizeTranslation: (key, value) => store.dispatch(standardizeTranslation(key, value)),
      saveDataContentDraft: (content, pageSectionIndex) => store.dispatch(saveDataContentDraft(content, pageSectionIndex)),
      applyDraftExperience: () => store.dispatch(applyDraftExperience()),
      saveCustomizableTextDataDraft: (customizableHeader, customizableHeaderIndex, pageSectionIndex, parentSectionIndex) => store.dispatch(
        saveCustomizableTextDataDraft(
          customizableHeader,
          customizableHeaderIndex,
          pageSectionIndex,
          parentSectionIndex
        )
      )
    },
    state.experience.data.attributes.data.translations.draft
  );
  const customSectionData = getCustomSectionData(sectionType, experienceType, translationSupport, index);
  const newState = store.getState();
  const experience = [...newState.experience.data.attributes.data.draft];
  experience.splice(index + 1, 0, customSectionData.customSectionData);
  const draftExperience = [...newState.experience.dataDraft.attributes.data.draft];
  draftExperience.splice(index + 1, 0, customSectionData.customSectionData);
  if (customSectionData.translation !== void 0) {
    if (customSectionData.translation[0]) {
      customSectionData.translation.forEach((translation) => {
        store.dispatch(saveTranslationDraft(translation.key, translation.value));
      });
    } else {
      store.dispatch(saveTranslationDraft(customSectionData.translation.key, customSectionData.translation.value));
    }
  }
  store.dispatch({
    type: ACTIONS.ADD_CUSTOM_EXPERIENCE,
    payload: { experience, draftExperience, attribute }
  });
  return function(dispatch, getState) {
    dispatch(
      putExperience(
        getState().experience.data.id,
        getState().experience.dataDraft,
        () => standardizeAllTranslations(translationSupport)
      )
    );
  };
};
export const deleteMultiColumnSection = (pageSectionIndex, parentSectionIndex) => {
  const state = store.getState();
  const sectionsList = state.experience.data.attributes.data.draft[parentSectionIndex].data.content.sections;
  const elementColumn = sectionsList[pageSectionIndex].column;
  const isLastElementInColumn = sectionsList.filter((sectionElement) => sectionElement.column === elementColumn).length === 1;
  const updatedSectionList = !isLastElementInColumn ? [...sectionsList.slice(0, pageSectionIndex), ...sectionsList.slice(pageSectionIndex + 1)] : sectionsList.map((section, i) => i !== pageSectionIndex ? section : { data: null, column: elementColumn });
  store.dispatch(saveDataContentDraft({ sections: updatedSectionList }, parentSectionIndex));
  return applyDraftExperience();
};
export const deleteSection = (pageSectionIndex, attribute) => {
  const state = store.getState();
  const experience = state.experience.data.attributes.data.draft.filter((value, index) => pageSectionIndex !== index);
  const draftExperience = state.experience.dataDraft.attributes.data.draft.filter(
    (value, index) => pageSectionIndex !== index
  );
  store.dispatch({
    type: ACTIONS.DELETE_SECTION,
    payload: { experience, draftExperience, attribute }
  });
  return (dispatch, getState) => {
    dispatch(putExperience(getState().experience.data.id, getState().experience.data));
  };
};
export const publishExperience = (experience) => {
  store.dispatch({ type: ACTIONS.PUBLISH_EXPERIENCE });
  const id = experience.id;
  const _experience = prepareExperienceObject(experience);
  _experience.attributes.data.published = _experience.attributes.data.draft;
  _experience.attributes.data.translations.published = _experience.attributes.data.translations.draft;
  _experience.attributes.data.locales.published = _experience.attributes.data.locales.draft;
  _experience.attributes.data.customFonts.published = _experience.attributes.data.customFonts.draft;
  return (dispatch) => {
    return dispatch(putExperience(id, _experience));
  };
};
export const getExperience = (id) => {
  return (dispatch) => {
    dispatch(getExperienceStart());
    return ApiService.get(`/experiences/${id}?include=user`).then((res) => {
      dispatch(getExperienceSuccess(res.data.data));
    }).catch((err) => dispatch(getExperienceError(err)));
  };
};
export const postExperience = (data, sideEffectAction) => {
  return (dispatch) => {
    dispatch(postExperienceStart());
    return ApiService.post("/experiences?include=user", { data }).then((res) => {
      dispatch(postExperienceSuccess(res.data.data));
      sideEffectAction && dispatch(sideEffectAction());
    }).catch((err) => {
      dispatch(postExperienceError(err));
    });
  };
};
export const patchExperience = ({ id, sideEffectAction, attributes }) => {
  const data = {
    data: {
      type: "experience",
      id,
      attributes
    }
  };
  return (dispatch) => {
    dispatch(patchExperienceStart());
    return ApiService.patch(`/experiences/${id}?include=user`, data).then((res) => {
      dispatch(patchExperienceSuccess(res.data.data));
      sideEffectAction && dispatch(sideEffectAction());
    }).catch((err) => {
      dispatch(patchExperienceError(err));
      throw err;
    });
  };
};
export const putExperience = (id, data, sideEffectAction) => {
  const experience = prepareExperienceObject(data);
  return (dispatch) => {
    dispatch(putExperienceStart());
    return ApiService.put(`/experiences/${id}?include=user`, {
      data: experience
    }).then((res) => {
      dispatch(putExperienceSuccess(res.data.data));
      sideEffectAction && dispatch(sideEffectAction());
    }).catch((err) => {
      dispatch(putExperienceError(err));
      throw err;
    });
  };
};
export const deleteExperience = (id, sideEffectAction) => {
  return (dispatch) => {
    dispatch(deleteExperienceStart());
    return ApiService.delete(`/experiences/${id}`).then(() => {
      dispatch(deleteExperienceSuccess(void 0));
      sideEffectAction && dispatch(sideEffectAction());
    }).catch((err) => {
      dispatch(deleteExperienceError(err));
    });
  };
};
