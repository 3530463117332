var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./tint-section.sass";
import { ConnectTintBox } from "./connect-tint-box/connect-tint-box";
import { defaultTintPersonalization } from "./edit/edit-tint-content";
import { EditTintContentNew } from "./edit/edit-tint-content-new";
import { EditTintStyles } from "./edit/edit-tint-styles";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { Config } from "../../../../../../utils/config";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { connect } from "react-redux";
class TintSection extends Component {
  constructor(props, context) {
    super(props, context);
    __publicField(this, "renderTint", () => {
      let content = this.props.data.content;
      let displayType;
      try {
        displayType = JSON.parse(content.displayType);
      } catch (e) {
        displayType = {};
      }
      const height = content.heightType === "Fixed" ? Number(content.height) : "";
      const position = content.heightType === "Fixed" ? "relative" : void 0;
      const style = {
        height,
        maxHeight: Number(height) === 0 ? "" : height,
        position
      };
      let additionalAttributes = content.heightType === "Fixed" ? void 0 : JSON.parse('{"data-expand": "true"}');
      let personalizationIdAttribute = content.personalizationId === defaultTintPersonalization || content.personalizationId === "" ? void 0 : JSON.parse(`{"data-personalization-id": "${content.personalizationId}"}`);
      return /* @__PURE__ */ React.createElement(
        "div",
        __spreadValues(__spreadProps(__spreadValues(__spreadValues(__spreadValues({
          className: "tintup",
          ref: (node) => this.tintNode = node,
          "data-baseurl": `${this.state.baseUrl}`,
          "data-id": content.tintName,
          "data-columns": content.ofColumns.toString(),
          style: __spreadValues({}, style)
        }, displayType), additionalAttributes), personalizationIdAttribute), {
          "data-saved-filter-id": content.savedFilterId
        }), !this.props.isPreviewMode ? null : this.props.cookieEnabled && !this.props.isGDPRAllowed ? { ["data-notrack"]: true } : null)
      );
    });
    this.isDevEnv = Config.isDevEnv();
    this.isStagingEnv = Config.isStagingEnv();
    this.state = {
      isDevEnv: /localhost|tintup.dev/.test(window.location.href),
      baseUrl: this.isDevEnv ? `${window.location.protocol}//${window.location.hostname}` : "https://app.tintup.com"
    };
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.reRenderIframe();
    }
    if (this.props.data.content.tintName === "" && document.querySelector(".a5b5e4-inner")) {
      this.clearTintScriptStuff();
    }
  }
  componentDidMount() {
    const themesScript = document.querySelector("script[src*='/dist/embedded.js']");
    if (themesScript) {
      this.reRenderIframe();
    } else {
      const script = document.createElement("script");
      script.src = `${window._env_.BASE_URL}/dist/embedded.js`;
      document.body.appendChild(script);
    }
  }
  clearTintScriptStuff() {
    if (document.querySelector(".a5b5e4-inner")) {
      document.querySelector(".a5b5e4-inner").remove();
      document.querySelector(".rectangle-container").style.height = "";
    }
  }
  isIframeOnSite() {
    return this.tintNode && this.tintNode.children.length > 0;
  }
  reRenderIframe() {
    if (document.querySelector("#tint-page")) {
      document.querySelector("#tint-page").remove();
    }
    if (document.querySelector("#tint-popup")) {
      document.querySelector("#tint-popup").remove();
    }
    if (document.querySelector(".a5b5e4-inner")) {
      document.querySelector(".a5b5e4-inner").remove();
    }
    if (document.querySelector("script[src*='/a5b5e5.js']")) {
      document.querySelector("script[src*='/a5b5e5.js']").remove();
    }
    if (document.querySelector("script[src*='/embed.js']")) {
      document.querySelector("script[src*='/embed.js']").remove();
    }
    if (document.querySelector("script[src*='/dist/embedded.js']")) {
      document.querySelector("script[src*='/dist/embedded.js']").remove();
      const script = document.createElement("script");
      script.src = script.src = `${window._env_.BASE_URL}/dist/embedded.js`;
      document.body.appendChild(script);
    }
    setTimeout(() => {
      if (window.HM && !this.isIframeOnSite()) {
        HM.init();
        HM.render();
        HM.bind();
      }
    });
  }
  getRectangleBox(isSmallFirst) {
    return /* @__PURE__ */ React.createElement("div", { className: "rectangle-container__rectangle-box" }, /* @__PURE__ */ React.createElement("div", { className: `rectangle ${isSmallFirst ? "rectangle--small" : ""}` }), /* @__PURE__ */ React.createElement("div", { className: `rectangle ${!isSmallFirst ? "rectangle--small" : ""}` }));
  }
  renderContent() {
    return /* @__PURE__ */ React.createElement("div", { className: "rectangle-container" }, /* @__PURE__ */ React.createElement(ConnectTintBox, { onClick: this.props.openModal.bind(this), text: "+ Connect TINT" }), this.getRectangleBox(true), this.getRectangleBox(false), this.getRectangleBox(true), this.getRectangleBox(false), this.getRectangleBox(true));
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        dir: "auto",
        className: `tint-page-section tint-page-section--tint ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          display: !this.props.data.content.tintName && this.props.isPreviewMode ? "none" : "",
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
          // If we don't want to show full content tin in preview:
          // ...(!this.props.isPreviewMode  ? {'overflow': 'hidden'} : {}),
          // maxHeight: !this.props.isPreviewMode ? '1000px' : '',
        }
      },
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          closeModal: this.props.closeModal,
          dismissModal: this.props.dismissModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit TINT Board" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditTintContentNew,
          {
            updateForm: this.onDataSave,
            data: this.props.data,
            dataUpdate: this.props.onContentUpdate
          }
        ), /* @__PURE__ */ React.createElement(
          EditTintStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      ),
      this.props.data.content.tintName ? this.renderTint() : this.renderContent()
    );
  }
}
const mapStateToProps = (state) => ({
  session: state.session.data
});
export default connect(mapStateToProps)(PageSectionHoc(TintSection));
