var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/social-connections/social-connections.actions";
import produce from "immer";
const INCLUDE_TYPES = {
  TINT: "tint",
  SOCIAL_FEED: "social_feed"
};
const initialDropdownSubAccountsState = {
  subAccounts: [],
  id: void 0
};
export const initialState = {
  accounts: {
    data: [],
    links: {}
  },
  dropdown: initialDropdownSubAccountsState,
  subAccounts: [],
  isSubAccountsPending: false,
  subAccountsData: {},
  filteredAccounts: [],
  socialsToTransfer: [],
  error: void 0,
  isFetching: false,
  isUpdatingSocialAccount: false,
  isFilteringSocialAccounts: false
};
export const socialConnections = (state = initialState, action) => {
  var _a;
  switch (action.type) {
    case ACTIONS.GET_SUB_ACCOUNTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        subAccountsData: action.payload
      });
    case ACTIONS.PATCH_SOCIAL_FEED.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isUpdatingSocialAccount: true
      });
    case ACTIONS.FILTER_SOCIAL_ACCOUNTS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isFilteringSocialAccounts: true
      });
    case ACTIONS.GET_SOCIAL_CONNECTIONS.REQUEST:
    case ACTIONS.GET_SOCIAL_CONNECTION.REQUEST:
    case ACTIONS.DELETE_SOCIAL_CONNECTION.REQUEST:
    case ACTIONS.POST_SOCIAL_CONNECTION.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isFetching: true
      });
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.REQUEST:
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isSubAccountsPending: true
      });
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.FAILURE:
    case ACTIONS.GET_SOCIAL_CONNECTIONS.FAILURE:
    case ACTIONS.GET_SOCIAL_CONNECTION.FAILURE:
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.FAILURE:
    case ACTIONS.POST_SOCIAL_CONNECTION.FAILURE:
    case ACTIONS.PATCH_SOCIAL_FEED.FAILURE:
    case ACTIONS.FILTER_SOCIAL_ACCOUNTS.FAILURE:
    case ACTIONS.ADD_SOCIAL_CONNECTION.FAILURE:
    case ACTIONS.REFRESH_SOCIAL_CONNETION.FAILURE:
    case ACTIONS.DELETE_SOCIAL_CONNECTION.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false,
        isSubAccountsPending: false,
        isUpdatingSocialAccount: false,
        isFilteringSocialAccounts: false,
        subAccounts: []
      });
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.SUCCESS:
      return produce(state, (draft) => {
        draft.subAccounts = action.payload.data;
        draft.isSubAccountsPending = false;
        draft.error = void 0;
        draft.isFetching = false;
      });
    case ACTIONS.GET_SOCIAL_CONNECTIONS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        accounts: action.payload,
        error: void 0,
        isFetching: false
      });
    case ACTIONS.GET_SOCIAL_CONNECTION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        accounts: {
          data: state.accounts.data.length ? state.accounts.data.map(
            (e) => e.id === action.payload.data.id ? __spreadProps(__spreadValues({}, action.payload.data), {
              included: action.payload.included
            }) : e
          ) : [__spreadProps(__spreadValues({}, action.payload.data), { included: action.payload.included })]
        },
        subAccounts: action.payload.included ? action.payload.included : [],
        error: void 0,
        isFetching: false
      });
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isSubAccountsPending: false,
        dropdown: {
          subAccounts: action.payload.isReFetch ? [...state.dropdown.subAccounts, ...action.payload.data] : action.payload.data,
          links: action.payload.links,
          id: action.payload.id
        }
      });
    case ACTIONS.FILTER_SOCIAL_ACCOUNTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        filteredAccounts: action.payload,
        error: void 0,
        isFilteringSocialAccounts: false
      });
    case ACTIONS.ADD_SOCIAL_CONNECTION.SUCCESS:
    case ACTIONS.POST_SOCIAL_CONNECTION.SUCCESS:
      return produce(state, (draft) => {
        const accounts = draft.accounts.data.filter((account) => account.id !== action.payload.id);
        draft.accounts.data = [action.payload, ...accounts];
        draft.error = void 0;
        draft.isFetching = false;
      });
    case ACTIONS.DELETE_SOCIAL_CONNECTION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        accounts: { data: [...state.accounts.data.filter((acc) => acc.id !== action.payload.id)] },
        dropdown: ((_a = state.dropdown) == null ? void 0 : _a.id) === action.payload.id ? initialDropdownSubAccountsState : state.dropdown,
        error: void 0,
        isFetching: false
      });
    case ACTIONS.REFRESH_SOCIAL_CONNETION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        accounts: { data: state.accounts.data.map((e) => e.id === action.payload.id ? action.payload : e) },
        error: void 0,
        isFetching: false
      });
    case ACTIONS.PATCH_SOCIAL_FEED.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isUpdatingSocialAccount: false
      });
    default:
      return state;
  }
};
export const selectAccount = (state, id) => state.accounts.data.find((e) => e.id === id);
export const getSocialAccounts = (state) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.socialConnections) == null ? void 0 : _a.accounts) == null ? void 0 : _b.data;
};
export const getSubaccounts = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialConnections) == null ? void 0 : _a.subAccounts;
};
export const selectTints = (state, id) => {
  const account = selectAccount(state, id);
  return account && account.included ? account.included.filter((e) => e.type === INCLUDE_TYPES.TINT) : [];
};
export const selectSocialFeeds = (state, id) => {
  const account = selectAccount(state, id);
  return account && account.included ? account.included.filter((e) => e.type === INCLUDE_TYPES.SOCIAL_FEED) : [];
};
export const selectSocialFeedsPerTint = (state, id) => {
  const tints = selectTints(state, id);
  const socialFeeds = selectSocialFeeds(state, id);
  if (tints.length === 0 || socialFeeds.length === 0) {
    return [];
  }
  return socialFeeds.reduce((acc, curr) => {
    return !acc[curr.relationships.tint.data.id] ? __spreadProps(__spreadValues({}, acc), {
      [curr.relationships.tint.data.id]: [{ id: curr.id }]
    }) : __spreadProps(__spreadValues({}, acc), {
      [curr.relationships.tint.data.id]: [...acc[curr.relationships.tint.data.id], { id: curr.id }]
    });
  }, {});
};
export const findSocialFeed = (id, socialFeeds) => socialFeeds.find((e) => e.id === id);
export const findTint = (tints, id) => tints.find((tint) => tint.id === id);
export const filterSocialFeedByType = (type, socialFeeds) => socialFeeds.filter((e) => e.attributes.source === type);
export const mapSocialAccountsForDropdown = (list = []) => list == null ? void 0 : list.map((e) => {
  var _a, _b, _c;
  return {
    name: e.attributes.name || e.attributes.username,
    value: e.id,
    externalId: (_a = e == null ? void 0 : e.attributes) == null ? void 0 : _a.external_id,
    id: e.id,
    status: (_b = e == null ? void 0 : e.attributes) == null ? void 0 : _b.status,
    source: (_c = e == null ? void 0 : e.attributes) == null ? void 0 : _c.type
  };
});
export const selectSubaccounts = (state, accountId) => {
  var _a, _b;
  const account = state.data.find((item) => item.id === accountId);
  return ((_b = (_a = account == null ? void 0 : account.relationships) == null ? void 0 : _a.subaccounts) == null ? void 0 : _b.data) || [];
};
