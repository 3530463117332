var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ApiService } from "../../../../services/api-service/api-service";
import {
  getProductFeedsStart,
  getProductFeedsSuccess,
  getProductFeedsError,
  getProductFeedStart,
  getProductFeedSuccess,
  getProductFeedError,
  deleteProductFeedStart,
  deleteProductFeedSuccess,
  deleteProductFeedError,
  postProductFeedStart,
  postProductFeedSuccess,
  postProductFeedError
} from "./product-feeds.actions";
export const getProductFeed = (productFeedId) => {
  return (dispatch) => {
    dispatch(getProductFeedStart());
    return ApiService.get(`/product_feeds/${productFeedId}`).then((res) => {
      dispatch(getProductFeedSuccess(res.data.data));
    }).catch((err) => dispatch(getProductFeedError(err)));
  };
};
export const getProductFeeds = ({ url = "/product_feeds" } = {}) => {
  return (dispatch) => {
    dispatch(getProductFeedsStart());
    return ApiService.get(url).then((res) => {
      dispatch(getProductFeedsSuccess(res.data));
    }).catch((err) => {
      dispatch(getProductFeedsError(err));
      throw err;
    });
  };
};
export const getProductAccount = ({ productFeedId }) => {
  return (dispatch) => {
    const url = `/product_feeds/${productFeedId}`;
    dispatch(getProductFeedStart());
    return ApiService.get(url).then((res) => {
      dispatch(getProductFeedSuccess(res.data));
    }).catch((err) => {
      dispatch(getProductFeedError(err));
      throw err;
    });
  };
};
export const deleteProductFeed = ({ productFeedId }) => {
  return (dispatch) => {
    const url = `/product_feeds/${productFeedId}`;
    dispatch(deleteProductFeedStart());
    return ApiService.delete(url).then(() => {
      dispatch(deleteProductFeedSuccess(productFeedId));
    }).catch((err) => {
      dispatch(deleteProductFeedError(err));
      throw err;
    });
  };
};
export const addProductFeed = ({
  url = "/product_feeds",
  catalogId,
  csvUrl,
  source,
  productIntegrationId,
  name,
  deleteFilesOnceProcessed
} = {}) => {
  const data = {
    type: "product_feed",
    attributes: __spreadValues({
      source,
      product_account_id: productIntegrationId,
      catalog_id: catalogId,
      url: csvUrl,
      name
    }, deleteFilesOnceProcessed && {
      options: {
        delete_files_once_processed: deleteFilesOnceProcessed
      }
    })
  };
  return (dispatch) => {
    dispatch(postProductFeedStart());
    return ApiService.post(url, { data }).then((res) => {
      dispatch(postProductFeedSuccess(res.data.data));
      return res.data.data;
    }).catch((err) => {
      dispatch(postProductFeedError(err));
      throw err;
    });
  };
};
