var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ACTIONS } from "../actions/tint-editor.actions";
export const initialState = {
  data: [],
  isFetching: false,
  error: void 0
};
export const instintUpdates = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_INSTINT_UPDATES.REQUEST:
    case ACTIONS.POST_INSTINT_UPDATES.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        isError: false
      });
    case ACTIONS.POST_INSTINT_UPDATES.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: [...state.data, action.payload],
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_INSTINT_UPDATES.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_INSTINT_UPDATES.FAILURE:
    case ACTIONS.POST_INSTINT_UPDATES.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isError: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
};
