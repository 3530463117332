import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_PERSONALIZATIONS: createRoutine(createPrefixedActionName("PERSONALIZATION", "GET_PERSONALIZATIONS")),
  GET_PERSONALIZATION: createRoutine(createPrefixedActionName("PERSONALIZATION", "GET_PERSONALIZATION")),
  POST_PERSONALIZATION: createRoutine(createPrefixedActionName("PERSONALIZATION", "POST_PERSONALIZATION")),
  PATCH_PERSONALIZATION: createRoutine(createPrefixedActionName("PERSONALIZATION", "PATCH_PERSONALIZATION")),
  DELETE_PERSONALIZATION: createRoutine(createPrefixedActionName("PERSONALIZATION", "DELETE_PERSONALIZATION")),
  BULK_UPDATE_PERSONALIZATIONS_STATUS: createRoutine(
    createPrefixedActionName("PERSONALIZATION", "BULK_UPDATE_PERSONALIZATIONS_STATUS")
  ),
  BULK_DELETE_PERSONALIZATIONS: createRoutine(
    createPrefixedActionName("PERSONALIZATION", "BULK_DELETE_PERSONALIZATIONS")
  ),
  HIDE_PERSONALIZATION_SIDEBAR: createPrefixedActionName("PERSONALIZATION", "HIDE_PERSONALIZATION_SIDEBAR"),
  SHOW_PERSONALIZATION_SIDEBAR: createPrefixedActionName("PERSONALIZATION", "SHOW_PERSONALIZATION_SIDEBAR"),
  HIDE_CUSTOM_CSS: createPrefixedActionName("PERSONALIZATION", "HIDE_CUSTOM_CSS"),
  SHOW_CUSTOM_CSS: createPrefixedActionName("PERSONALIZATION", "SHOW_CUSTOM_CSS"),
  UPDATE_CONTENT: createPrefixedActionName("PERSONALIZATION", "UPDATE_CONTENT"),
  SELECT_PERSONALIZATION: createPrefixedActionName("PERSONALIZATION", "SELECT_PERSONALIZATION"),
  UNSELECT_PERSONALIZATION: createPrefixedActionName("PERSONALIZATION", "UNSELECT_PERSONALIZATION"),
  DESELECT_ALL_PERSONALIZATIONS: createPrefixedActionName("PERSONALIZATION", "DESELECT_ALL_PERSONALIZATIONS")
};
export const getPersonalizationsStart = () => ({
  type: ACTIONS.GET_PERSONALIZATIONS.REQUEST
});
export const getPersonalizationsSuccess = (data) => ({
  type: ACTIONS.GET_PERSONALIZATIONS.SUCCESS,
  payload: data
});
export const getPersonalizationsError = (err) => ({
  type: ACTIONS.GET_PERSONALIZATIONS.FAILURE,
  payload: err
});
export const getPersonalizationStart = () => ({
  type: ACTIONS.GET_PERSONALIZATION.REQUEST
});
export const getPersonalizationSuccess = (data) => ({
  type: ACTIONS.GET_PERSONALIZATION.SUCCESS,
  payload: data
});
export const getPersonalizationError = (err) => ({
  type: ACTIONS.GET_PERSONALIZATION.FAILURE,
  payload: err
});
export const postPersonalizationStart = () => ({
  type: ACTIONS.POST_PERSONALIZATION.REQUEST
});
export const postPersonalizationSuccess = (data) => ({
  type: ACTIONS.POST_PERSONALIZATION.SUCCESS,
  payload: data
});
export const postPersonalizationError = (err) => ({
  type: ACTIONS.POST_PERSONALIZATION.FAILURE,
  payload: err
});
export const patchPersonalizationStart = () => ({
  type: ACTIONS.PATCH_PERSONALIZATION.REQUEST
});
export const patchPersonalizationSuccess = (data) => ({
  type: ACTIONS.PATCH_PERSONALIZATION.SUCCESS,
  payload: data
});
export const patchPersonalizationError = (err) => ({
  type: ACTIONS.PATCH_PERSONALIZATION.FAILURE,
  payload: err
});
export const deletePersonalizationStart = () => ({
  type: ACTIONS.DELETE_PERSONALIZATION.REQUEST
});
export const deletePersonalizationSuccess = (id) => ({
  type: ACTIONS.DELETE_PERSONALIZATION.SUCCESS,
  payload: id
});
export const deletePersonalizationError = (err) => ({
  type: ACTIONS.DELETE_PERSONALIZATION.FAILURE,
  payload: err
});
export const showPersonalizationSidebar = (data) => ({
  type: ACTIONS.SHOW_PERSONALIZATION_SIDEBAR,
  payload: data
});
export const hidePersonalizationSidebar = () => ({
  type: ACTIONS.HIDE_PERSONALIZATION_SIDEBAR
});
export const showCustomCSS = (type) => ({
  type: ACTIONS.SHOW_CUSTOM_CSS,
  payload: type
});
export const hideCustomCSS = () => ({
  type: ACTIONS.HIDE_CUSTOM_CSS
});
export const updateContent = (data) => ({
  type: ACTIONS.UPDATE_CONTENT,
  payload: data
});
export const selectPersonalization = (personalization) => ({
  type: ACTIONS.SELECT_PERSONALIZATION,
  payload: personalization
});
export const unselectPersonalization = (id) => ({
  type: ACTIONS.UNSELECT_PERSONALIZATION,
  payload: id
});
export const deselectAllPersonalizations = () => ({
  type: ACTIONS.DESELECT_ALL_PERSONALIZATIONS
});
export const bulkUpdatePersonalizationsStatusStart = () => ({
  type: ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.REQUEST
});
export const bulkUpdatePersonalizationsStatusSuccess = () => ({
  type: ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.SUCCESS
});
export const bulkUpdatePersonalizationsStatusError = (err) => ({
  type: ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.FAILURE,
  payload: err
});
export const bulkDeletePersonalizationsStart = () => ({
  type: ACTIONS.BULK_DELETE_PERSONALIZATIONS.REQUEST
});
export const bulkDeletePersonalizationsSuccess = (id) => ({
  type: ACTIONS.BULK_DELETE_PERSONALIZATIONS.SUCCESS,
  payload: id
});
export const bulkDeletePersonalizationsError = (err) => ({
  type: ACTIONS.BULK_DELETE_PERSONALIZATIONS.FAILURE,
  payload: err
});
