var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createPrefixedActionName } from "../../services/functions/redux-routines/redux-routines";
import { assetDisplayType } from "../../feature/asset-manager/model/asset-display-type";
import { clone } from "../../services/functions/clone/clone";
export const defaultConfig = {
  SELECT: createPrefixedActionName("TINT", "SELECT_ELEMENT"),
  UNSELECT: createPrefixedActionName("TINT", "UNSELECT_ELEMENT"),
  SELECT_ALL: createPrefixedActionName("TINT", "SELECT_ALL_ELEMENTS"),
  UNSELECT_ALL: createPrefixedActionName("TINT", "UNSELECT_ALL_ELEMENTS"),
  SET_DISPLAY_TYPE: createPrefixedActionName("TINT", "SET_ELEMENT_DISPLAY_TYPE")
};
export const initialState = {
  data: [],
  meta: void 0,
  links: void 0,
  isFetching: false,
  error: void 0,
  isCreateAssetModalOpened: false,
  displayType: assetDisplayType.GRID_SMALL
};
export function selectable(reducer, config, initialState2) {
  const _config = __spreadValues(__spreadValues({}, defaultConfig), config);
  return (state = initialState2, action) => {
    switch (action.type) {
      case _config.SELECT:
        return __spreadValues(__spreadValues({}, state), {
          data: state.data.map((e) => {
            const _e = clone(e);
            _e.id === action.payload ? _e.isSelected = true : null;
            return _e;
          }),
          selectedAssetId: action.payload
        });
      case _config.UNSELECT:
        return __spreadValues(__spreadValues({}, state), {
          data: state.data.map((e) => {
            const _e = clone(e);
            _e.id === action.payload ? _e.isSelected = false : null;
            return _e;
          }),
          selectedAssetId: action.payload
        });
      case _config.SELECT_ALL:
        return __spreadValues(__spreadValues({}, state), { data: state.data.map((e) => __spreadProps(__spreadValues({}, e), { isSelected: true })) });
      case _config.UNSELECT_ALL:
        return __spreadValues(__spreadValues({}, state), { data: state.data.map((e) => __spreadProps(__spreadValues({}, e), { isSelected: false })) });
      case _config.SET_DISPLAY_TYPE:
        return __spreadValues(__spreadValues({}, state), { displayType: action.payload });
      default:
        return reducer(state, action);
    }
  };
}
