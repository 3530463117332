import * as Yup from "yup";
export const validationSchemaTwitterUsername = () => {
  return Yup.object({
    username: Yup.string().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaTwitterHashtag = () => {
  return Yup.object({
    hashtag: Yup.string().required("Hashtag is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaTwitterLikes = () => {
  return Yup.object({
    username: Yup.string().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaTwitterAdvanced = () => {
  return Yup.object({
    geocode: Yup.string().required("Query is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaTwitterLists = () => {
  return Yup.object({
    username: Yup.string().required("Username is required."),
    list: Yup.string().required("List is required."),
    account: Yup.object().required("Account is required.")
  });
};
