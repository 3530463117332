import { useEffect, useState } from "react";
const useAnimation = (isElementVisible, animationStartName, animationEndName) => {
  const [animationState, setAnimationState] = useState(isElementVisible);
  const onAnimationEnd = () => {
    if (!isElementVisible) {
      setAnimationState(false);
    }
  };
  const animationName = isElementVisible ? animationStartName : animationEndName;
  useEffect(() => {
    if (isElementVisible) setAnimationState(true);
  }, [isElementVisible]);
  return [animationState, onAnimationEnd, animationName];
};
export default useAnimation;
