import React, { Component } from "react";
import "./spacer-section.sass";
import CustomSpacer from "./spacer";
import { PageSectionHoc } from "../../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditModalSwitcher } from "../../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import EditCustonSpacerStyles from "./edit/edit-spacer-styles";
import EditCustomSpacerSection from "./edit/edit-spacer-content";
import PropTypes from "prop-types";
import ColorParser from "../../../../../../../services/color-parser/color-parser";
class CustomSpacerSection extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { backgroundColor } = this.props.data.styles;
    const { sectionHeight } = this.props.data.content;
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--spacer tint-custom-section tint-custom-section--spacer ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(backgroundColor),
          minHeight: `${sectionHeight}px`
        }
      },
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Spacer" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditCustomSpacerSection,
          {
            sectionHeight: this.props.data.content.sectionHeight,
            dataUpdate: this.props.onContentUpdate
          }
        ), /* @__PURE__ */ React.createElement(
          EditCustonSpacerStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      ),
      !this.props.isPreviewMode ? /* @__PURE__ */ React.createElement(CustomSpacer, { sectionHeight }) : null
    );
  }
}
export default PageSectionHoc(CustomSpacerSection);
CustomSpacerSection.propTypes = {
  renderAddAreaBlock: PropTypes.func,
  renderEditArea: PropTypes.func,
  onContentUpdate: PropTypes.func,
  onStylesUpdate: PropTypes.func,
  closeModal: PropTypes.func,
  onDataSave: PropTypes.func,
  dismissModal: PropTypes.func,
  data: PropTypes.shape({
    content: PropTypes.shape({
      sectionHeight: PropTypes.string
    }),
    styles: PropTypes.shape({
      backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  })
};
