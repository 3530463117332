import React, { Component } from "react";
import "./video-section.sass";
import { PageSectionHoc } from "../../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import ColorParser from "../../../../../../../services/color-parser/color-parser";
import { EditModalSwitcher } from "../../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { VideoStyles } from "./edit/video-styles";
import { VideoContent } from "./edit/video-content";
import VideoRenderer from "./video-renderer/video-renderer";
class VideoSection extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--video tint-custom-section tint-custom-section--video ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement(
        VideoRenderer,
        {
          width: this.props.data.styles.videoWidth,
          mediaUrl: this.props.data.content.mediaUrl,
          autoPlay: this.props.data.content.hasAutoplay,
          parentLocation: this.props.parentLocation
        }
      ),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Video" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          VideoContent,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate,
            onContentUpdate: this.props.onContentUpdate,
            maxVideoFileSize: this.props.getMaxVideoSizePlanLimit()
          }
        ), /* @__PURE__ */ React.createElement(
          VideoStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      )
    );
  }
}
export default PageSectionHoc(VideoSection);
