var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./header-section.sass";
import { EditHeaderContent } from "./edit/edit-header-content";
import { EditHeaderStyles } from "./edit/edit-header-styles";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { isUrlAddress } from "../../../../../../services/functions/url-checker/url-address";
import { SectionLogo } from "../../logo/section-logo";
class HeaderSection extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "isUrlValid", (data) => {
      this.setState({ isLinkValid: data });
    });
    __publicField(this, "onModalSwitch", () => {
      if (this.props.data.content.logoRedirectUrl !== "" && !isUrlAddress(this.props.data.content.logoRedirectUrl)) {
        this.props.discardDraftData();
      }
      this.setState({ isLinkValid: true });
    });
    this.state = {
      isLinkValid: true
    };
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--header ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      /* @__PURE__ */ React.createElement(
        SectionLogo,
        {
          logoHeight: this.props.data.styles.logoHeight,
          logoRedirectUrl: this.props.data.content.logoRedirectUrl,
          logoUrl: this.props.data.content.logoUrl
        }
      ),
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          isVisible: this.props.isModalOpen,
          nextStep: this.props.nextStep,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Header", isApplyButtonDisabled: !this.state.isLinkValid }, /* @__PURE__ */ React.createElement(EditModalSwitcher, { modalSwitch: this.onModalSwitch }, /* @__PURE__ */ React.createElement(
          EditHeaderContent,
          {
            closeModal: this.props.closeModal,
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate,
            dataUpdate: this.props.onContentUpdate,
            isUrlValid: this.isUrlValid,
            maxFileSize: this.props.getMaxImageSizePlanLimit()
          }
        ), /* @__PURE__ */ React.createElement(
          EditHeaderStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate,
            dataUpdate: this.props.onContentUpdate
          }
        )))
      )
    );
  }
}
export default PageSectionHoc(HeaderSection);
