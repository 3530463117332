import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const singleMatrix = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX]: {
    padding: 0,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_LEGEND]: {
    "margin-bottom": "16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_TABLE]: {
    width: "100%",
    "table-layout": "fixed"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_TBODY_TH]: {
    "font-weight": "initial",
    "text-align": "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_TBODY_TD]: {
    "text-align": "center"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_THEAD_TH]: {
    "font-weight": "initial",
    display: "table-cell"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_TH_TD]: {
    padding: "8px 8px 8px 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_INPUT]: {
    appearance: "none",
    width: "16px",
    "min-width": "16px",
    height: "16px",
    margin: 0,
    "border-radius": "50%",
    padding: "0 !important",
    cursor: "pointer",
    border: "1px solid #b6b8bb"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_INPUT_CHECKED]: {
    border: "3px solid #3B99FC"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_RTL]: {
    "text-align": "center"
  }
};
