export const colors = {
  starRating: "#f5a623",
  waveGrey6: "#1B242D",
  textPrimary: "#1b242d",
  veryDarkGrey: "#2c2d30",
  shadow: "#00000008",
  buttonSecondary: "#4b5056",
  placeholder: "#70757e",
  semiDarkGrey: "#797E86",
  textSecondary: "#838e94",
  placeholderGrey: "#aab7c4",
  grey: "#b6b8bb",
  lines: "#e9edf3",
  semiLightGray: "#EDEFF1",
  veryLightGrey: "#f6f7f8",
  brand: "#FF564B",
  primary: "#26e0bd",
  primaryLight: "#49e2cc",
  darkGray: "#373740",
  black: "#000000",
  gray: "#797E86",
  lightGray: "#CED0D1",
  bgGray: "#EDEFF1",
  white: "#FFFFFF",
  backdropGray: "#797e8666",
  error: "#D32F2F",
  errorLight: "#FFD8D8",
  warning: "#F5A623",
  warningLight: "#f8dfb5",
  info: "#3b99fc",
  infoLight: "#D7EAFD",
  success: "#00A752",
  successLight: "#CDF8E2"
};
