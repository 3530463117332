import React, { Fragment } from "react";
import "./sidebar-define-list.sass";
import PropTypes from "prop-types";
const TintSidebarDefineList = ({ list }) => {
  return /* @__PURE__ */ React.createElement("dl", { className: "sidebar-define-list" }, list.map((listElement, i) => {
    var _a;
    if ((_a = listElement == null ? void 0 : listElement.dd) == null ? void 0 : _a.length)
      return /* @__PURE__ */ React.createElement(Fragment, { key: i }, /* @__PURE__ */ React.createElement("dt", null, listElement == null ? void 0 : listElement.dt), listElement == null ? void 0 : listElement.dd.map((e, i2) => /* @__PURE__ */ React.createElement("dd", { key: i2 }, e)));
  }));
};
TintSidebarDefineList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.string,
      dd: PropTypes.array
    })
  )
};
export default TintSidebarDefineList;
