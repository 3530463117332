import React, { useEffect, useRef, useState } from "react";
import "./output-emed-script.sass";
import { Config } from "../../../utils/config";
import { Button } from "../button/button";
import {
  DisplayType,
  HeightType
} from "../../../feature/tint-editor/components/tint-editor/sidebars/display-settings/steps/web-embed-settings/web-embed-settings-sidebar";
import { TintEditorConfig } from "../../../feature/tint-editor/utils/tint-editor.config";
const defaultStyles = {
  height: "500px",
  width: "100%"
};
const OutputEmbedScript = ({
  tintId,
  personalizationId,
  product,
  keywords,
  tags,
  savedFilterId,
  columns = "",
  displayType = DisplayType.INFINITE_SCROLL,
  heightType = HeightType.UNLIMITED,
  styles
}) => {
  var _a;
  const [isCodeCopied, setCodeCopied] = useState(false);
  const script = `<script async src='${TintEditorConfig.embedScript}'><\/script>`;
  const divRef = useRef();
  useEffect(() => {
    setCodeCopied(false);
  }, [tintId, personalizationId, product, keywords, tags, savedFilterId, columns, displayType, heightType, styles]);
  const resetDisplayTypeAttributes = () => {
    divRef.current.removeAttribute("data-infinitescroll");
    divRef.current.removeAttribute("data-paginate");
    divRef.current.removeAttribute("data-clickformore");
  };
  if (divRef.current) {
    resetDisplayTypeAttributes();
    divRef.current.classList.add("tintup");
    divRef.current.setAttribute("data-id", tintId);
    divRef.current.setAttribute("data-columns", columns ? columns : "");
    if (heightType === HeightType.UNLIMITED) {
      divRef.current.setAttribute("data-expand", true);
      divRef.current.removeAttribute("data-mobilescroll");
    } else {
      divRef.current.removeAttribute("data-expand");
      divRef.current.setAttribute("data-mobilescroll", true);
    }
    divRef.current.setAttribute(`data-${displayType.toLowerCase()}`, true);
    savedFilterId ? divRef.current.setAttribute("data-saved-filter-id", savedFilterId) : divRef.current.removeAttribute("data-saved-filter-id", savedFilterId);
    product ? divRef.current.setAttribute("data-product-sku", product) : divRef.current.removeAttribute("data-product-sku", product);
    tags ? divRef.current.setAttribute("data-tags", tags) : divRef.current.removeAttribute("data-tags", tags);
    keywords ? divRef.current.setAttribute("data-query", keywords) : divRef.current.removeAttribute("data-query", keywords);
    divRef.current.style.height = styles.height || defaultStyles.height;
    divRef.current.style.width = styles.width || defaultStyles.width;
    divRef.current.setAttribute("data-personalization-id", personalizationId);
  }
  const copyTextToClipboard = (text, onClick) => {
    navigator.clipboard.writeText(text).then(() => onClick());
  };
  const displayTypeUrl = displayType ? `&${displayType}=true` : "";
  const columnsUrl = columns ? `&columns=${columns}` : "";
  const savedFilterIdUrl = savedFilterId ? `&saved_filter_id=${savedFilterId}` : "";
  const productUrl = product ? `&product_sku=${product}` : "";
  const keywordsUrl = keywords ? `&query=${keywords}` : "";
  const tagsUrl = tags ? `&tags=${tags}` : "";
  const queryParams = `?personalization_id=${personalizationId}${columnsUrl}${displayTypeUrl}${savedFilterIdUrl}${productUrl}${keywordsUrl}${tagsUrl}`;
  return /* @__PURE__ */ React.createElement("div", { className: "output-embed-script" }, /* @__PURE__ */ React.createElement("code", null, script, (_a = divRef == null ? void 0 : divRef.current) == null ? void 0 : _a.outerHTML), /* @__PURE__ */ React.createElement("div", { ref: divRef }), /* @__PURE__ */ React.createElement(
    Button,
    {
      className: "output-embed-script__copy-button",
      type: "primary",
      onClick: () => {
        var _a2;
        copyTextToClipboard(`${script}${(_a2 = divRef == null ? void 0 : divRef.current) == null ? void 0 : _a2.outerHTML}`, () => {
          setCodeCopied(true);
        });
      },
      text: `${isCodeCopied ? "Copied!" : "Copy to Clipboard"}`,
      size: "full-width"
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      className: "output-embed-script__open-in-new-tab",
      type: "gray",
      onClick: () => {
        const url = `${Config.baseUrl()}/t/${tintId}${queryParams}`;
        window.open(url, "_blank", "noopener");
      },
      text: "Open In New Tab",
      size: "full-width"
    }
  ));
};
export default OutputEmbedScript;
