var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./select-comment.sass";
import { DropdownHeader } from "../../../interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../interface/dropdown/lists/sort-list/dropdown-sort-list";
import DropdownButton, { ButtonType } from "../../../interface/dropdown/button/dropdown-button";
import { Button } from "../../../interface/button/button";
import TintIcon from "../../../interface/icon/icon";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import {
  setRequestRightsModalProgress,
  setRequestRightStep
} from "../../../../actions/request-rights/request-rights.actions";
import { REQUEST_RIGHTS_SOURCE, REQUEST_RIGHTS_STEP } from "../../request-rights";
import { useSocialAccountsAuthorizationService } from "../../../../feature/account-settings/hooks/use-social-accounts-authorization-service";
import { SocialConnectionsTypes } from "../../../../feature/account-settings/components/social-connections/social-connections";
import { EditCommentBox } from "../edit-comment-box/edit-comment-box";
import {
  getSocialConnections,
  getSubAccounts
} from "../../../../feature/account-settings/actions/social-connections/social-connections.fat-actions";
import PreloaderComponent, {
  PreloaderBackdropColorType,
  PreloaderPositionType
} from "../../../interface/preloaders/preloader/preloader";
import DropdownSearchList from "../../../interface/dropdown/lists/search-list/dropdown-search-list";
import useFeatureAccessibility from "../../../hooks/use-feature-accessibility/use-feature-accessibility";
import { GuardNames } from "../../../../guards/guards-list";
import {
  getRequestRightsTemplates,
  getAiTemplates
} from "../../../../actions/request-rights/request-rights.fat-actions";
import { toast } from "react-toastify";
import DropdownInfiniteScrollList from "../../../interface/dropdown/lists/infinite-scroll-list/dropdown-infinite-scroll-list";
import { Config } from "../../../../utils/config";
export const Source = {
  TWITTER: 2,
  INSTAGRAM_BUSINESS: 21
};
export const SocialFeedType = {
  TWITTER: "twitter",
  INSTAGRAM_BUSINESS: "instagram_business"
};
const ADD_ACCOUNT = "Add account";
const TYPE_CUSTOM_COMMENT = "TYPE_CUSTOM_COMMENT";
const defaultTemplateText = "We'd like to share this image on our social media channels and tag you in our post with your permission. Please review our terms. {{username}} if you agree, reply {{hashtag}} {{terms_url}}\n";
const defaultHashtag = "yes";
export const SelectComment = () => {
  var _a, _b;
  const dispatch = useDispatch();
  const requestRights = useSelector((state) => state.requestRights);
  const socialConnections = useSelector((state) => state.socialConnections);
  const session = useSelector((state) => state.session.data);
  const [isCustomComment, setIsCustomComment] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [socialConnectionAccounts, setSocialConnectionAccounts] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const defaultAccountValue = { name: ADD_ACCOUNT, value: ADD_ACCOUNT };
  const defaultCommentValue = { type: "button", name: "Type Custom Comment", value: TYPE_CUSTOM_COMMENT };
  const isAbleToLoad = useSelector((state) => {
    var _a2, _b2, _c;
    return (_c = (_b2 = (_a2 = state.requestRights) == null ? void 0 : _a2.requestRightsData) == null ? void 0 : _b2.links) == null ? void 0 : _c.next;
  });
  const [selectedComment, setComment] = useState({
    name: "Select Comment",
    value: void 0
  });
  const selectedAccountId = requestRights.modal.account_id;
  const selectedSubAccountId = requestRights.modal.subaccount_id;
  const [socialConnectionSubAccounts, setSocialConnectionSubAccounts] = useState([]);
  const { addSocialConnection } = useSocialAccountsAuthorizationService({
    session,
    onNewAccountSuccess: () => dispatch(getSocialConnections({ url: "/accounts?page[size]=500" }))
  });
  const [contentList, setContentList] = useState([]);
  const [templateSuggestions, setTemplateSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const tintId = requestRights.postMessageData.payload.user_id || requestRights.postMessageData.tintId;
  const isSeparateSettingsAvailable = useFeatureAccessibility(GuardNames.SEPARATE_SETTINGS);
  const userRightMessageAiSuggestionAvailable = useFeatureAccessibility(GuardNames.USER_RIGHT_MESSAGE_AI_SUGGESTION);
  useEffect(() => {
    var _a2;
    if (requestRights.modal.templateName) {
      setIsCustomComment(true);
      setComment({ value: requestRights.modal.template, name: requestRights.modal.templateName });
    } else if (requestRights.modal.template) {
    } else if (!selectedComment && requestRights.requestRightsData !== void 0) {
      const attr = (_a2 = requestRights.requestRightsData.data[0]) == null ? void 0 : _a2.attributes;
      setComment({ value: attr == null ? void 0 : attr.template, name: attr == null ? void 0 : attr.template });
      dispatch(setRequestRightsModalProgress({ template: attr == null ? void 0 : attr.template, hashtag: attr == null ? void 0 : attr.hashtag }));
    }
  }, [requestRights.requestRightsData]);
  useEffect(() => {
    if (requestRights.templateSuggestions) {
      prepareCommentList();
    }
  }, [requestRights.templateSuggestions]);
  useEffect(() => {
    const accountId = requestRights.modal ? requestRights.modal.account_id : null;
    const subAccountId = requestRights.modal ? requestRights.modal.subaccount_id : null;
    switch (requestRights.postMessageData.payload.source) {
      case SocialFeedType.TWITTER:
      case Source.TWITTER:
        setupTwitterDropdown({
          type: REQUEST_RIGHTS_SOURCE.TWITTER,
          data: socialConnections.accounts.data,
          selectedId: accountId
        });
        break;
      case SocialFeedType.INSTAGRAM_BUSINESS:
      case Source.INSTAGRAM_BUSINESS:
        setupInstagramDropdown({
          type: REQUEST_RIGHTS_SOURCE.INSTAGRAM_BUSINESS,
          data: socialConnections.accounts.data,
          selectedId: accountId,
          selectedSubAccountId: subAccountId
        });
        break;
    }
  }, [socialConnections.accounts.data]);
  const setupInstagramDropdown = ({ data, type, selectedSubAccountId: selectedSubAccountId2 }) => {
    const _socialAccounts = data.filter((e) => e.attributes.type === type).map((e) => {
      return __spreadProps(__spreadValues({}, e.attributes), {
        id: e.id,
        title: e.attributes,
        value: e.id,
        name: e.attributes.name || e.attributes.username
      });
    });
    setSocialConnectionAccounts([..._socialAccounts, defaultAccountValue]);
    if (selectedSubAccountId2) {
      const subAccountsData = socialConnections.subAccountsData.data.map((e) => __spreadProps(__spreadValues({
        id: e.id
      }, e.attributes), {
        name: e.attributes.username || e.attributes.name,
        value: e.id
      }));
      setSocialConnectionSubAccounts(subAccountsData);
    }
  };
  const getCurrentSubAccountItem = () => {
    return socialConnectionSubAccounts.find((subAccount) => subAccount.id === selectedSubAccountId.toString());
  };
  const getCurrentAccountItem = () => {
    return socialConnectionAccounts.find((account) => account.id === selectedAccountId.toString());
  };
  const setupTwitterDropdown = ({ data, type }) => {
    const _socialAccounts = data.filter((e) => e.attributes.type === type).map((e) => {
      return __spreadProps(__spreadValues({}, e.attributes), {
        id: e.id,
        title: e.attributes,
        value: e.id,
        name: e.attributes.name || e.attributes.username
      });
    });
    setSocialConnectionAccounts([..._socialAccounts, defaultAccountValue]);
  };
  const getTemplates = ({ currentPageSize, filterValue: filterValue2 }) => {
    dispatch(
      getRequestRightsTemplates({
        pageSize: currentPageSize,
        filterValue: filterValue2
      })
    ).catch(() => toast("Cannot fetch comments list"));
  };
  const prepareCommentList = () => {
    var _a2, _b2;
    const _comments = requestRights.requestRightsData.data.map((e) => {
      var _a3, _b3;
      return __spreadValues({
        name: (_a3 = e == null ? void 0 : e.attributes) == null ? void 0 : _a3.template,
        header: (_b3 = e == null ? void 0 : e.attributes) == null ? void 0 : _b3.name
      }, e);
    });
    let _suggestions;
    if (userRightMessageAiSuggestionAvailable) {
      _suggestions = (_b2 = (_a2 = requestRights.templateSuggestions) == null ? void 0 : _a2.data) == null ? void 0 : _b2.map((e) => {
        var _a3;
        return __spreadValues({
          name: (_a3 = e == null ? void 0 : e.attributes) == null ? void 0 : _a3.template,
          header: /* @__PURE__ */ React.createElement("span", { className: "request-rights-ai-comment-suggestion-header" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faWandMagicSparkles, color: "#e85353" }), "AI Suggestion")
        }, e);
      });
    }
    setContentList([..._suggestions || [], ..._comments, defaultCommentValue]);
  };
  const onSubAccountSelect = (element) => {
    dispatch(setRequestRightsModalProgress({ subaccount_id: element.id }));
  };
  const onAccountSelect = (element) => {
    if (element.value === ADD_ACCOUNT) {
      _addSocialConnection();
    } else {
      dispatch(setRequestRightsModalProgress({ account_id: Number.parseInt(element.id), source: element.type }));
      if (element.type === "instagram_business") {
        setIsPending(true);
        dispatch(getSubAccounts({ accountId: Number.parseInt(element.id) })).then((data) => {
          setSocialConnectionSubAccounts(data.map((e) => __spreadProps(__spreadValues({ id: e.id }, e.attributes), { name: e.attributes.username })));
          setIsPending(false);
        });
      }
    }
  };
  const onSelectComment = (element) => {
    if (element.value !== "TYPE_CUSTOM_COMMENT" && element.type != "right_request_message_suggestion") {
      setIsCustomComment(false);
      dispatch(
        setRequestRightsModalProgress({
          template: element.attributes.template,
          hashtag: element.attributes.hashtag,
          templateName: ""
        })
      );
    } else {
      let message;
      if (element.type == "right_request_message_suggestion") {
        message = {
          template: element.attributes.template,
          templateName: "Ai Suggestion",
          hashtag: "yes"
        };
      } else {
        message = {
          template: defaultTemplateText,
          templateName: "Type Custom Comment",
          hashtag: defaultHashtag
        };
      }
      dispatch(setRequestRightsModalProgress(message));
      setIsCustomComment(true);
    }
  };
  const onCommentSubmit = ({ template, hashtag, setIsEditing }) => {
    hashtag = hashtag.replace("#", "");
    dispatch(setRequestRightsModalProgress({ template, hashtag }));
    setIsEditing(false);
  };
  const _addSocialConnection = () => {
    switch (requestRights.postMessageData.payload.source) {
      case SocialFeedType.TWITTER:
      case Source.TWITTER:
        addSocialConnection(SocialConnectionsTypes.twitter.backendMapping);
        break;
      case SocialFeedType.INSTAGRAM_BUSINESS:
      case Source.INSTAGRAM_BUSINESS:
        addSocialConnection(SocialConnectionsTypes.instagram.backendMapping);
        break;
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-select-comment" }, isPending ? /* @__PURE__ */ React.createElement(
    PreloaderComponent,
    {
      positionType: PreloaderPositionType.ABSOLUTE,
      backdropColor: PreloaderBackdropColorType.DARK
    }
  ) : null, /* @__PURE__ */ React.createElement("div", { className: "tint-select-comment__content-container", key: socialConnectionAccounts.length }, /* @__PURE__ */ React.createElement("h5", null, "STEP 1: POST FROM"), socialConnectionAccounts.length > 0 ? /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: getCurrentAccountItem(),
      dropdownHeaderStyle: { justifyContent: "space-between" },
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSearchList, __spreadValues({ isDefaultOption: false }, props)),
      list: socialConnectionAccounts,
      placeholder: "Select Account...",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: onAccountSelect
    }
  ) : null), socialConnectionSubAccounts.length > 0 ? /* @__PURE__ */ React.createElement("div", { className: "tint-select-comment__content-container" }, /* @__PURE__ */ React.createElement("h5", null, "SELECT SUBACCOUNT"), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: getCurrentSubAccountItem(),
      dropdownHeaderStyle: { justifyContent: "space-between" },
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSearchList, __spreadProps(__spreadValues({}, props), { isDefaultOption: false })),
      list: socialConnectionSubAccounts,
      placeholder: "Select Sub Account...",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: onSubAccountSelect
    }
  )) : null, /* @__PURE__ */ React.createElement("div", { className: "tint-select-comment__content-container" }, /* @__PURE__ */ React.createElement("h5", null, "STEP 2: SELECT A SAVED COMMENT"), ((_b = (_a = requestRights == null ? void 0 : requestRights.requestRightsData) == null ? void 0 : _a.data) == null ? void 0 : _b.length) > 0 || isFetching ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: selectedComment,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(
        DropdownInfiniteScrollList,
        {
          props,
          filterValue,
          setFilterValue,
          setIsFetching,
          getData: getTemplates,
          pageSize: Config.defaultPageSize,
          tintId,
          setList: prepareCommentList,
          isAbleToLoad,
          isSeparateSettingsAvailable,
          data: requestRights == null ? void 0 : requestRights.requestRightsData
        }
      ),
      dropdownHeaderStyle: { justifyContent: "space-between" },
      list: contentList,
      placeholder: "Select Comment",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: onSelectComment,
      isLoading: (requestRights == null ? void 0 : requestRights.isFetching) || (requestRights == null ? void 0 : requestRights.isFetchingAiTemplates)
    }
  )) : null, isCustomComment ? /* @__PURE__ */ React.createElement(
    EditCommentBox,
    {
      onSubmit: onCommentSubmit,
      hashtag: requestRights.modal.hashtag,
      text: requestRights.modal.template,
      isCustom: true
    }
  ) : "", /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => dispatch(setRequestRightStep(REQUEST_RIGHTS_STEP.COMMENTS)),
      styles: { width: "100%", height: "52px" },
      text: "Edit TINT Comments",
      type: "gray"
    }
  )));
};
