var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import PropTypes from "prop-types";
import TintModal from "../../../../../../../components/modal/modal";
import ModalHeader from "../../../../../../../components/modal/components/modal-header/modal-header";
import ModalFooter from "../../../../../../../components/modal/components/footers/modal-footer/modal-footer";
export const FormContestRulesModal = ({ title, body, show, onHide }) => {
  return /* @__PURE__ */ React.createElement(
    TintModal,
    {
      className: "post-section-modal",
      headerTitle: title,
      modalHeader: (props) => /* @__PURE__ */ React.createElement(ModalHeader, __spreadValues({}, props)),
      modalFooter: (props) => /* @__PURE__ */ React.createElement(ModalFooter, __spreadValues({}, props)),
      isOpen: show,
      onClose: onHide
    },
    /* @__PURE__ */ React.createElement("p", null, body)
  );
};
FormContestRulesModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any
};
