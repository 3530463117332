import React from "react";
import "./author-avatar.sass";
import ButtonContentIcon from "../interface/button-content-icon/button-content-icon";
import useImageLoadingState from "../hooks/use-image-state/use-image-loading-state";
import TintIcon from "../interface/icon/icon";
import { faImageSlash } from "@fortawesome/pro-solid-svg-icons";
export const AuthorAvatar = ({ size, author, socialFeedSource, socialFeedConfig = {} }) => {
  const imageState = useImageLoadingState(author == null ? void 0 : author.image_url);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-author-avatar" }, (author == null ? void 0 : author.image_url) ? imageState.isLoaded ? /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "tint-author-avatar__thumb",
      style: {
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${author == null ? void 0 : author.image_url})`
      }
    }
  ) : /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "tint-author-avatar__thumb tint-author-avatar__thumb--error",
      style: {
        width: `${size}px`,
        height: `${size}px`
      }
    },
    /* @__PURE__ */ React.createElement(TintIcon, { icon: faImageSlash })
  ) : ((author == null ? void 0 : author.userName) || (author == null ? void 0 : author.name)) && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-author-avatar__thumb ${!socialFeedSource ? "tint-author-avatar__default-avatar" : "tint-author-avatar__multiple-avatar"}`
    },
    /* @__PURE__ */ React.createElement("a", { href: author == null ? void 0 : author.url, target: "_blank", rel: "noopener noreferrer" }, (author == null ? void 0 : author.userName) ? author.userName.substring(0, 1) : author == null ? void 0 : author.name.substring(0, 1))
  ), socialFeedSource && /* @__PURE__ */ React.createElement(
    ButtonContentIcon,
    {
      source: socialFeedSource,
      circleSize: socialFeedConfig.circle || 16,
      fontSize: socialFeedConfig.fontSize || 8,
      svgIconSize: socialFeedConfig.svgIconSize || 6,
      positionBottom: socialFeedConfig.positionBottom,
      positionRight: socialFeedConfig.positionRight
    }
  ));
};
