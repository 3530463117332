import React, { useEffect, useState } from "react";
import "./tint-editor-filter-save-state.sass";
import { Button } from "../../../../../../components/interface/button/button";
import { faFloppyDisk } from "@fortawesome/pro-solid-svg-icons";
import InputPrimary from "../../../../../../components/interface/inputs/input-primary/input-primary";
import Tooltip from "../../../../../transform-ui/components/tooltip/tooltip";
import TintButtonIcon, { TintButtonIconTypes } from "../../../../../../components/interface/button-icon/button-icon";
import { faRefresh, faTrash, faClone } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import copyToClipboard from "../../../../../../services/functions/copy-to-clipboard/copy-to-clipboard";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { TintEditorConfig } from "../../../../utils/tint-editor.config";
const TintEditorFilterSaveState = ({
  onSaveClick,
  onUpdateClick,
  onRemoveClick,
  filterId = "",
  filterName = "",
  isAbleToUpdate
}) => {
  const { tintId } = useParams();
  const isGlobalPageState = tintId === TintEditorConfig.routePaths.global.tintId;
  const [isEditState, setEditState] = useState(filterId);
  const [isSaveButtonClicked, setSaveButtonClick] = useState(filterId);
  const formik = useFormik({
    initialValues: {
      filterName: !filterName ? "" : filterName
    }
  });
  useEffect(() => {
    formik.setFieldValue("filterName", filterName);
  }, [filterName, formik.setFieldValue]);
  useEffect(() => {
    setEditState(filterId);
  }, [filterId]);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-save-state" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-save-state__action" }, !filterId && !isSaveButtonClicked ? !isGlobalPageState && /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => {
        setSaveButtonClick(true);
      },
      action: "submit",
      type: "secondary",
      text: "Save Filter",
      icon: faFloppyDisk
    }
  ) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      id: "filter-name",
      name: "filterName",
      type: "text",
      autoFocus: true,
      placeholder: "Enter Filter Title",
      readonly: isEditState,
      value: formik.values.filterName,
      error: formik.errors.filterName,
      handleBlur: formik.handleBlur,
      touched: formik.touched.filterName,
      handleChange: formik.handleChange,
      style: { height: "40px" },
      onFocus: () => setEditState(false)
    }
  ), !isEditState ? /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => {
        setEditState(true);
        !filterId && onSaveClick && onSaveClick(formik.values.filterName);
      },
      isDisabled: formik.values.filterName.length === 0,
      action: "submit",
      type: "secondary",
      text: "Done",
      styles: { width: "128px" }
    }
  ) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      style: { left: "-40px", top: "-44px", height: "30px", width: "110px" },
      component: /* @__PURE__ */ React.createElement(
        TintButtonIcon,
        {
          icon: faRefresh,
          isDisabled: filterName === formik.values.filterName && !isAbleToUpdate,
          onClick: () => onUpdateClick(formik.values.filterName, filterId)
        }
      ),
      text: "Update Filter"
    }
  ), /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      style: { left: "-40px", top: "-44px", height: "30px", width: "110px" },
      component: /* @__PURE__ */ React.createElement(TintButtonIcon, { icon: faTrash, onClick: onRemoveClick, type: TintButtonIconTypes.SECONDARY }),
      text: "Remove Filter"
    }
  ), /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      style: { left: "-40px", top: "-44px", height: "30px", width: "110px" },
      component: /* @__PURE__ */ React.createElement(
        TintButtonIcon,
        {
          icon: faClone,
          onClick: () => {
            copyToClipboard(filterId);
            toast.success("Filter ID copied");
          },
          type: TintButtonIconTypes.SECONDARY
        }
      ),
      text: "Copy Filter ID"
    }
  )))));
};
export default TintEditorFilterSaveState;
