import { createParamsForUrl } from "../../feature/account-settings/utils/filter-helper/filter-helper";
export const getUrl = ({ url, query, baseUrl, filters = [], include, size }) => {
  const queryLink = createParamsForUrl({
    baseUrl,
    filters,
    include,
    size
  });
  return query && !url ? `${queryLink}` : url || queryLink;
};
