const white = "#ffffff";
const whiteLight = "#f6f7f8";
const black = "#000000";
const blackLight = "#0e0e0e";
const blackLighter = "#111111";
const blueLight = "#3977ff";
const blueDark = "#174D84";
const cookieBannerBlue = "#006EFD";
const coldDarkBlue = "#415FA2";
const solarBlue = "#70ACDC";
const oceanBlue = "#2F73BD";
const violet = "#7c15d6";
const red = "#b81921";
const redLight = "#E93F47";
const transparent = "transparent";
const colors = {
  white,
  whiteLight,
  red,
  redLight,
  black,
  blackLight,
  blackLighter,
  blueLight,
  blueDark,
  cookieBannerBlue,
  coldDarkBlue,
  violet,
  solarBlue,
  oceanBlue,
  transparent
};
export {
  colors as default,
  white,
  whiteLight,
  red,
  redLight,
  black,
  blackLight,
  blackLighter,
  blueDark,
  blueLight,
  cookieBannerBlue,
  coldDarkBlue,
  violet,
  solarBlue,
  oceanBlue,
  transparent
};
