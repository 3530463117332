var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import * as ReactDOM from "react-dom";
import "./tutorial-tooltip.sass";
import { TutorialCloud } from "../../feature/tint-editor/components/tint-editor/tutorial-cloud/tutorial-cloud";
export const TutorialTooltip = ({ steps = [], onFinish }) => {
  var _a, _b;
  const [stepIndex, setStepIndex] = useState(0);
  const onBack = () => {
    if (stepIndex - 1 < 0) {
      return;
    }
    setStepIndex(stepIndex - 1);
  };
  const onNext = () => {
    if (stepIndex + 1 >= steps.length) {
      onFinish == null ? void 0 : onFinish();
      return;
    }
    setStepIndex(stepIndex + 1);
  };
  const renderBody = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-tutorial-tooltip__body" }, steps[stepIndex].component);
  };
  const getStyles = () => {
    return __spreadValues({}, steps[stepIndex].position);
  };
  const renderFooter = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-tutorial-tooltip__footer" }, stepIndex > 0 ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", { className: "tint-tutorial-tooltip-button tint-tutorial-tooltip-button--back", onClick: onBack }, "Back")) : /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("span", null, `${stepIndex + 1} of ${steps.length}`), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", { className: "tint-tutorial-tooltip-button tint-tutorial-tooltip-button--next", onClick: onNext }, stepIndex + 1 === steps.length ? "Finish" : "Next")));
  };
  if (steps.length < 1) {
    return null;
  }
  if (document.querySelector((_a = steps[stepIndex]) == null ? void 0 : _a.selector) === null) {
    return null;
  }
  const renderBackDrop = () => {
    var _a2, _b2, _c;
    const backdrop = (_a2 = steps[stepIndex]) == null ? void 0 : _a2.backdrop;
    if (!backdrop) {
      return;
    }
    (_c = (_b2 = steps[stepIndex]).onBackDropRender) == null ? void 0 : _c.call(_b2);
    return ReactDOM.createPortal(
      /* @__PURE__ */ React.createElement(
        "div",
        {
          style: { zIndex: backdrop == null ? void 0 : backdrop.zIndex, height: `calc(100% + ${backdrop.additionalHeight}`, top: backdrop.top },
          className: "tint-tutorial-tooltip-backdrop"
        }
      ),
      document.querySelector(backdrop == null ? void 0 : backdrop.selector)
    );
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, ReactDOM.createPortal(
    /* @__PURE__ */ React.createElement("div", { className: "tint-tutorial-tooltip" }, /* @__PURE__ */ React.createElement(TutorialCloud, { position: getStyles(), animation: "none" }, renderBody(), renderFooter())),
    document.querySelector((_b = steps[stepIndex]) == null ? void 0 : _b.selector)
  ), renderBackDrop());
};
