var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./tint-editor-modify-rules.sidebar.sass";
import AccordionTab from "../../../../../../components/interface/accordion/accordion-tab/accordion-tab";
import Accordion from "../../../../../../components/interface/accordion/accordion";
import { useFormik } from "formik";
import TextareaPrimary from "../../../../../../components/interface/inputs/textarea-primary/textarea-primary";
import { Config } from "../../../../../../utils/config";
import DropdownButton, { ButtonType } from "../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSimpleList from "../../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
const backendKeys = {
  users: "author.username",
  usersWhiteList: "author.username",
  keywords: "text",
  keywordsWhiteList: "text",
  ipAddresses: "author.remote_ip",
  imageFilter: "metadata.image_nsfw"
};
const ImageFilterType = {
  ENABLE: "Enable",
  DISABLE: "Disable"
};
const imageFilterDropdownList = [
  {
    value: true,
    name: ImageFilterType.ENABLE
  },
  {
    value: false,
    name: ImageFilterType.DISABLE
  }
];
const TintEditorModifyRulesSidebar = ({ data, onChangeData }) => {
  const newLineSign = "\n";
  const imageFilterData = data == null ? void 0 : data.find((el) => {
    var _a;
    return (_a = el.conditions) == null ? void 0 : _a["metadata.image_nsfw"];
  });
  const [currentImageFilter, setCurrentImageFilter] = useState({
    name: imageFilterData ? imageFilterDropdownList[0].name : imageFilterDropdownList[1].name,
    value: !!imageFilterData
  });
  const reduceDataArray = (list) => list && list.reduce((a, b) => {
    return a !== "" ? a + "\n" + b : a + b;
  }, "");
  const [imageFilterStatus, setImageFilterStatus] = useState(false);
  const whiteLists = data && data.filter((rule) => rule.changes.status === "public");
  const blockedLists = data && data.filter((rule) => rule.changes.status === "private" || rule.changes.status === "moderation_deleted");
  const moderationDeletedList = data && data.filter((rule) => rule.changes.status === "image_moderation_deleted");
  const getValue = (list, key) => {
    const el = list.find((el2) => el2.conditions[key]);
    return el ? el.conditions[key] : void 0;
  };
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      users: reduceDataArray(getValue(blockedLists, backendKeys["users"])) || "",
      usersWhiteList: reduceDataArray(getValue(whiteLists, backendKeys["usersWhiteList"])) || "",
      keywords: reduceDataArray(getValue(blockedLists, backendKeys["keywords"])) || "",
      keywordsWhiteList: reduceDataArray(getValue(whiteLists, backendKeys["keywordsWhiteList"])) || "",
      ipAddresses: reduceDataArray(getValue(blockedLists, backendKeys["ipAddresses"])) || "",
      imageFilter: getValue(moderationDeletedList, backendKeys["imageFilter"])
    },
    validate: (values) => {
      const errorList = Object.keys(values).map(
        (key) => key !== "imageFilter" && {
          [key]: mapTextAreaValue(values[key], key).length === 0 ? void 0 : mapTextAreaValue(values[key], key)
        }
      );
      const reduceErrors = errorList.reduce((a, currentValue) => {
        return __spreadValues(__spreadValues({}, a), currentValue);
      }, {});
      return Object.keys(reduceErrors).filter((key) => reduceErrors[key] !== void 0).reduce((res, key) => {
        res[key] = reduceErrors[key];
        return res;
      }, {});
    }
  });
  const getValidationSchema = (value, type) => {
    switch (type) {
      case "ipAddresses":
        return !Config.regExp.ip.test(value);
      default:
        return false;
    }
  };
  const mapTextAreaValue = (value, key) => {
    const splitValueToArray = value.split(/^/gm);
    const mapValues2 = splitValueToArray.map((value2) => value2.replace(newLineSign, ""));
    const errorList = mapValues2.map((value2) => {
      return getValidationSchema(value2, key) ? `${value2} is not valid` : void 0;
    }).filter(Boolean);
    return errorList;
  };
  useEffect(() => {
    onChangeData && onChangeData({ values: mapValues(formik.values), isValid: formik.isValid && formik.dirty });
  }, [formik.isValid, formik.values, formik.dirty]);
  const mapValues = (values) => {
    return (values && Object.keys(values).map((key) => {
      if (key === "imageFilter") {
        if (imageFilterStatus === ImageFilterType.ENABLE) {
          return [
            {
              conditions: { ["metadata.image_nsfw"]: { $gte: 0.25, $lt: 0.7 } },
              changes: { status: "private" }
            },
            {
              conditions: { ["metadata.image_nsfw"]: { $gte: 0.7 } },
              changes: { status: "image_moderation_deleted" }
            }
          ];
        } else if (imageFilterStatus === ImageFilterType.DISABLE) {
          return values[key] && {
            changes: {
              status: "image_moderation_deleted"
            },
            conditions: {}
          };
        }
      } else {
        return values[key].split("\n")[0].length !== 0 && {
          changes: {
            status: key === "keywordsWhiteList" || key === "usersWhiteList" ? "public" : "private"
          },
          conditions: {
            [backendKeys[key]]: values[key].split("\n")
          }
        };
      }
    })).filter(Boolean).flat();
  };
  const onImageFilterChange = (selectedItem) => {
    setImageFilterStatus(selectedItem.value ? ImageFilterType.ENABLE : ImageFilterType.DISABLE);
    formik.setFieldValue("imageFilter", ImageFilterType.ENABLE);
    setCurrentImageFilter({
      name: selectedItem.name,
      value: selectedItem.value
    });
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-create-custom-post-sidebar" }, /* @__PURE__ */ React.createElement(Accordion, null, /* @__PURE__ */ React.createElement(AccordionTab, { title: "Block Users" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Posts from blocked users will not be imported into your TINT Board."), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      touched: formik.touched.users,
      error: formik.errors.users,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      placeholder: "Enter Usernames",
      name: "users",
      label: "Enter Usernames (1 per line)",
      value: formik.values.users
    }
  )), /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Block Keywords" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Posts containing these keywords will not be imported into your TINT Board."), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      touched: formik.touched.keywords,
      error: formik.errors.keywords,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      placeholder: "Enter Keywords",
      name: "keywords",
      label: "Enter Keywords (1 per line)",
      value: formik.values.keywords
    }
  )), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "tint-btn tint-btn--gray tint-btn--full-width",
      href: Config.url.zenDesk.blockKeywordsRuleExample,
      target: "_blank",
      rel: "noopener noreferrer"
    },
    "View Sample Vulgar Word List"
  ))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Block IP" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Submissions from these IPs will not be imported into your TINT Board."), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      touched: formik.touched.ipAddresses,
      error: formik.errors.ipAddresses,
      handleBlur: formik.handleBlur,
      handleChange: formik.handleChange,
      placeholder: "192.0.0.0",
      name: "ipAddresses",
      label: "Enter IP Addresses (1 per line)",
      value: formik.values.ipAddresses
    }
  )), /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Please Note: We deanonymize IP addresses so providing a specific IP address (/32) will block an entire block of 254 IP addresses (/24)"))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Allow Users" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Posts from these users will be directly added into your TINT Board."), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      touched: formik.touched.usersWhiteList,
      error: formik.errors.usersWhiteList,
      handleBlur: formik.handleBlur,
      handleChange: formik.handleChange,
      placeholder: "Enter Usernames",
      name: "usersWhiteList",
      label: "Enter Usernames (1 per line)",
      keepNewLine: true,
      value: formik.values.usersWhiteList
    }
  )))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Allow Keywords" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Posts containing these keywords will be directly added into your TINT Board."), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      touched: formik.touched.keywordsWhiteList,
      error: formik.errors.keywordsWhiteList,
      handleBlur: formik.handleBlur,
      handleChange: formik.handleChange,
      placeholder: "Enter Keywords",
      name: "keywordsWhiteList",
      label: "Enter Keywords (1 per line)",
      value: formik.values.keywordsWhiteList
    }
  )))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Image Filter" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "The adult content filter automatically removes posts with nude images. Posts that are borderline will be marked as private with an image blur applied."), /* @__PURE__ */ React.createElement("p", { className: "sidebar-title" }, "Image Filter"), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: currentImageFilter,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, props), { isDefaultOption: false })),
      list: imageFilterDropdownList,
      placeholder: "None",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: onImageFilterChange
    }
  )), /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "Please Note: Not 100% of adult content posts will be removed. Removes up to 98% in our testing."), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "tint-btn tint-btn--gray tint-btn--full-width",
      href: Config.url.zenDesk.imageFilterRules,
      target: "_blank",
      rel: "noopener noreferrer"
    },
    "Learn More"
  )))));
};
export default React.memo(TintEditorModifyRulesSidebar);
