import React, { Component } from "react";
import "./edit-input-dropdown.sass";
import PropTypes from "prop-types";
export default class EditInputDropdown extends Component {
  constructor(props) {
    super(props);
    const option = props.options.find((element) => element.value === this.props.defaultValue);
    const description = option ? option.description : "";
    this.state = { description, value: props.defaultValue || "" };
  }
  renderOptions() {
    return this.props.options.map((e, i) => /* @__PURE__ */ React.createElement("option", { value: e.value, key: i }, e.name));
  }
  renderDescription() {
    return /* @__PURE__ */ React.createElement("span", { className: "tint-edit-input-dropdown-container__description" }, this.state.description);
  }
  onChange(e) {
    this.props.onChange(e);
    const option = this.props.options.find((element) => element.value === e.target.value);
    const newState = { value: e.target.value };
    if (option) {
      option.action && option.action();
      newState.description = option.description;
    }
    this.setState(newState);
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-input-dropdown-container", style: this.props.styles }, this.props.label ? /* @__PURE__ */ React.createElement("span", { className: "tint-edit-input-container__label" }, this.props.label) : null, /* @__PURE__ */ React.createElement(
      "select",
      {
        disabled: this.props.isDisabled,
        "data-testid": this.props.id,
        value: this.props.isControlled ? this.props.value || this.props.placeholder : this.state.value,
        onChange: this.onChange.bind(this),
        name: this.props.name ? this.props.name : "TINT"
      },
      this.props.placeholder ? /* @__PURE__ */ React.createElement("option", { value: this.props.placeholder }, this.props.placeholder) : null,
      this.renderOptions()
    ), this.state.description ? this.renderDescription() : null);
  }
}
EditInputDropdown.propTypes = {
  isControlled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  styles: PropTypes.object,
  isDisabled: PropTypes.bool
};
