import React, { forwardRef, useImperativeHandle } from "react";
import "./checkout-form.sass";
import PropTypes from "prop-types";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import "../stripe-checkout-form.sass";
import { toast } from "react-toastify";
const CheckoutForm = ({ handleCheckout, onChange }, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = {
    style: {
      base: {
        fontSize: "14px",
        color: "#1b242d",
        fontWeight: "400",
        borderColor: "red",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#DFDFDF"
        }
      },
      invalid: {
        color: "#990000",
        borderColor: "#990000"
      }
    }
  };
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      stripe.createToken(cardElement).then(function(result) {
        if (result.error) {
          toast.error(result.error.message || "Something went wrong. Please try again.");
        } else {
          handleCheckout(result.token.id);
        }
      });
    }
  }));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "stripe-checkout-form__title" }, "Payment Information"), /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    CardNumberElement,
    {
      id: "cardNumber",
      options,
      onChange: (e) => onChange({ creditCardValid: e.complete })
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "stripe-checkout-form__container" }, /* @__PURE__ */ React.createElement("div", { className: "stripe-checkout-form__card-details stripe-checkout-form__card-details--expiry" }, /* @__PURE__ */ React.createElement("p", { className: "stripe-checkout-form__title" }, "Expire date"), /* @__PURE__ */ React.createElement(
    CardExpiryElement,
    {
      id: "expiry",
      options,
      onChange: (e) => onChange({ expiryDateValid: e.complete })
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "stripe-checkout-form__card-details stripe-checkout-form__card-details--cvc" }, /* @__PURE__ */ React.createElement("p", { className: "stripe-checkout-form__title" }, "CVC"), /* @__PURE__ */ React.createElement(CardCvcElement, { id: "cvc", options, onChange: (e) => onChange({ cvcCodeValid: e.complete }) })))));
};
const CheckoutFormWithForwardedRef = forwardRef(CheckoutForm);
CheckoutFormWithForwardedRef.propTypes = {
  handleCheckout: PropTypes.func,
  onChange: PropTypes.func
};
export default CheckoutFormWithForwardedRef;
