var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useEffect } from "react";
import "./tints-filter-bar.sass";
import { SearchInput, SearchInputType } from "../../../../components/search-input/search-input";
import { useDispatch, useSelector } from "react-redux";
import { getTints } from "../../actions/tint-editor.fat-actions";
import { getTintsUrlWithParams } from "../../utils/filter-helper/filter-helper";
import DropdownSimpleList from "../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import DropdownButton from "../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import useDebounce from "../../../../components/hooks/use-debounce/use-debounce";
import { isAnyTintAdded } from "../../../../reducers/session";
export const TintsFilterBar = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState();
  const [status, setStatus] = useState();
  const debounceDelay = 700;
  const debouncedFilterValue = useDebounce(filterValue, debounceDelay);
  const tintLst = useSelector(isAnyTintAdded);
  useEffect(() => {
    if (filterValue !== void 0 && dispatch) {
      dispatch(getTints(getTintsUrlWithParams({ filterValue, status })));
    }
  }, [debouncedFilterValue]);
  const onInputChange = (value) => {
    setFilterValue(value);
  };
  const onChangeValue = (selectedItem) => {
    const filterStatus = selectedItem === void 0 ? "" : selectedItem.value;
    setStatus(filterStatus);
    dispatch(
      getTints(
        getTintsUrlWithParams({
          filterValue,
          status: filterStatus
        })
      )
    );
  };
  const tintType = [
    {
      value: "active",
      name: "Active",
      isActive: false
    },
    {
      value: "inactive",
      name: "Inactive",
      isActive: false
    }
  ];
  return /* @__PURE__ */ React.createElement("div", { className: "tints-filter-bar" }, tintLst > 0 && /* @__PURE__ */ React.createElement(
    SearchInput,
    {
      onInputChange,
      inputValue: filterValue,
      types: [SearchInputType.ROUND, SearchInputType.SHORT]
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "tints-filter-bar tints-filter-bar__filter-section" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadValues({}, props)),
      list: tintType,
      placeholder: "Status",
      iconLeft: "fas fa-filter",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue
    }
  )));
};
export default TintsFilterBar;
