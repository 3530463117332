import React, { useEffect, useState } from "react";
import "./add-tags.sass";
import InputAddTag from "../inputs/input-add-tag/input-add-tag";
import { useFormik } from "formik";
import { Config } from "../../../utils/config";
import { TagList } from "../tag-list/tag-list";
export const TagsPosition = {
  TOP: "top",
  BOTTOM: "bottom"
};
const AddTags = ({
  label,
  tagList,
  onDeleteTag,
  onAddTag,
  placeholder,
  validationSchema,
  tagsPosition = TagsPosition.TOP,
  focusOnInit = false,
  shouldConfirmDelete = false,
  onFocus,
  onBlur,
  selectOnBlur = true,
  onInputChange,
  shouldResetInput
}) => {
  const [currentList, setCurrentList] = useState(tagList);
  const onRemoveTag = (index) => {
    if (!shouldConfirmDelete) {
      const filteredList = [...currentList.filter((_, i) => i !== index)];
      setCurrentList(filteredList);
      onDeleteTag == null ? void 0 : onDeleteTag(filteredList, currentList[index]);
    } else {
      onDeleteTag == null ? void 0 : onDeleteTag(currentList, index);
    }
  };
  useEffect(() => {
    setCurrentList(tagList);
  }, [tagList]);
  useEffect(() => {
    shouldResetInput && setFieldValue("tags", "");
  }, [shouldResetInput]);
  const checkIfItemExist = (arr, q) => arr.findIndex((item) => q && q.toLowerCase() === item.toLowerCase()) !== -1;
  const onAddElement = (item) => {
    const parsedItem = item.split(/[\s,]+/).filter(Boolean);
    const isItemExist = checkIfItemExist(currentList, ...parsedItem);
    const items = isItemExist ? [...currentList] : [...currentList, ...parsedItem];
    setCurrentList(items);
    item.length > 0 && onAddTag && onAddTag(items, item);
  };
  const {
    handleSubmit,
    errors,
    values,
    dirty,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      tags: ""
    },
    validationSchema,
    onSubmit: (values2, { resetForm }) => {
      onAddElement(values2.tags);
      resetForm();
    }
  });
  const handleSubmitForm = (e) => {
    if (Config.regExp.tagsRegex.test(e.target.value)) return submitForm();
  };
  const renderTagList = () => /* @__PURE__ */ React.createElement(TagList, { list: currentList, onRemove: onRemoveTag });
  return /* @__PURE__ */ React.createElement("div", { className: "tint-assets-add-tags" }, label && /* @__PURE__ */ React.createElement("div", { className: "tint-assets-add-tags__label" }, /* @__PURE__ */ React.createElement("span", null, label)), tagsPosition === TagsPosition.TOP && renderTagList(), /* @__PURE__ */ React.createElement("div", { className: "tint-assets-add-tags__add" }, /* @__PURE__ */ React.createElement("form", { id: "formId", noValidate: true, onSubmit: handleSubmit }, /* @__PURE__ */ React.createElement(
    InputAddTag,
    {
      focusOnInit,
      name: "tags",
      type: "text",
      value: values.tags,
      placeholder: placeholder || "Add tag",
      dirty,
      error: errors.tags,
      touched: touched.tags,
      handleChange: (e) => {
        onInputChange == null ? void 0 : onInputChange(e);
        handleSubmitForm(e);
        return handleChange(e);
      },
      onClick: submitForm,
      handlePaste: () => setTimeout(submitForm),
      handleBlur: (e) => {
        if (selectOnBlur) {
          submitForm(e);
        } else {
          handleBlur(e);
          onBlur == null ? void 0 : onBlur();
        }
      },
      onFocus,
      errorMsg: "Tag already exist"
    }
  ))), tagsPosition === TagsPosition.BOTTOM && renderTagList());
};
export default AddTags;
