import React, { Fragment } from "react";
import "./grid.sass";
export const Grid = ({
  className,
  children,
  spacing,
  direction = "row",
  wrap = "wrap",
  alignItems,
  justifyContent,
  container,
  flex,
  item
}) => {
  const mainStyles = {
    flexDirection: direction,
    flexWrap: wrap,
    marginLeft: wrap === "wrap" ? `-${spacing}px` : 0,
    marginRight: wrap === "wrap" ? `-${spacing}px` : 0,
    alignItems,
    justifyContent,
    flex
  };
  const renderChildren = (child, isChildren) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-grid__cell",
        style: {
          padding: `${wrap === "nowrap" ? "0" : `${spacing}px`} ${spacing}px`,
          flex: isChildren && flex ? flex : "initial"
        }
      },
      child
    );
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-grid ${className ? `tint-grid--${className}` : ""} ${item ? "item" : ""} tint-grid--${wrap} ${container || !item ? "container" : ""}`,
      style: mainStyles
    },
    React.Children.map(children, (child, i) => /* @__PURE__ */ React.createElement(Fragment, { key: i }, container ? child.props.item ? child : renderChildren(child) : renderChildren(child, true)))
  );
};
