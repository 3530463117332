import React from "react";
import { EditModalBody } from "../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import PropTypes from "prop-types";
import { EditUploadDesign } from "../../../../";
const EditCustonSpacerStyles = ({ data, dataUpdate, styleDataUpdate }) => {
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize this section with the color fields below." }, /* @__PURE__ */ React.createElement(
    EditUploadDesign,
    {
      dataUpdate,
      styleDataUpdate,
      data,
      isPaddingDisabled: true
    }
  ));
};
export default EditCustonSpacerStyles;
EditCustonSpacerStyles.propTypes = {
  dataUpdate: PropTypes.func,
  backgroundColor: PropTypes.string
};
