import {
  getMarketingIntegrationsFoldersStart,
  getMarketingIntegrationsFoldersSuccess,
  getMarketingIntegrationsFoldersError
} from "./marketing-integrations-folders.actions";
import { ApiService } from "../../../../services/api-service/api-service";
export const getMarketingIntegrationsFolders = (marketingIntegrationId) => {
  return (dispatch) => {
    dispatch(getMarketingIntegrationsFoldersStart());
    return ApiService.get(`/marketing_integrations/${marketingIntegrationId}/folders`).then((res) => {
      const folders = res.data.data.map((folder) => {
        return {
          id: marketingIntegrationId,
          data: folder
        };
      });
      dispatch(
        getMarketingIntegrationsFoldersSuccess({
          data: folders
        })
      );
    }).catch((err) => {
      dispatch(getMarketingIntegrationsFoldersError(err));
      throw err;
    });
  };
};
