import React, { useState } from "react";
import "./pagination.sass";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";
export const Pagination = ({
  isFetching,
  pageSize,
  customClass,
  nextUrl,
  previousUrl,
  request,
  onPageChange,
  fromPage,
  updateUrl = false
}) => {
  const [fromCount, setFromCount] = useState(() => fromPage ? (fromPage - 1) * pageSize + 1 : 1);
  const location = useLocation();
  const history = useHistory();
  const updateQueryParams = ({ pageNumber, pageSize: pageSize2 }) => {
    if (!updateUrl) {
      return;
    }
    const globalParams = new URLSearchParams(window.location.search);
    const params = new URLSearchParams({ pageSize: pageSize2 || globalParams.get("pageSize"), pageNumber });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };
  const onPrevious = () => {
    if (previousUrl && !isFetching) {
      setFromCount(fromCount - pageSize);
      onPageChange && onPageChange(fromCount - pageSize);
      request && request(previousUrl);
      updateQueryParams({ pageNumber: Number(fromPage) - 1 });
    }
  };
  const onNext = () => {
    if (nextUrl && !isFetching) {
      setFromCount(fromCount + pageSize);
      onPageChange && onPageChange(fromCount + pageSize);
      request && request(nextUrl);
      updateQueryParams({ pageNumber: Number(fromPage) + 1 });
    }
  };
  return /* @__PURE__ */ React.createElement("div", { "data-testid": "tint-pagination", className: `tint-pagination ${customClass && customClass}` }, /* @__PURE__ */ React.createElement("div", { className: "tint-pagination__action tint-pagination__action--left" }, previousUrl && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "span",
    {
      "data-testid": "onPrevious",
      onClick: onPrevious,
      className: `fas fa-chevron-left ${previousUrl ? "" : "fas--disabled"}`
    }
  ), /* @__PURE__ */ React.createElement("span", { onClick: onPrevious, className: "previous-text" }, "Previous"))), fromPage && (nextUrl || previousUrl) && /* @__PURE__ */ React.createElement("div", { className: "tint-pagination__main" }, /* @__PURE__ */ React.createElement("span", null, "Page ", fromPage)), /* @__PURE__ */ React.createElement("div", { className: "tint-pagination__action" }, nextUrl && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { onClick: onNext, className: "next-text" }, "Next"), /* @__PURE__ */ React.createElement(
    "span",
    {
      "data-testid": "onNext",
      onClick: onNext,
      className: `fas fa-chevron-right ${nextUrl ? "" : "fas--disabled"}`
    }
  ))));
};
Pagination.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  isFetching: PropTypes.bool,
  pageSize: PropTypes.number,
  customClass: PropTypes.string,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  request: PropTypes.func,
  onPageChange: PropTypes.func
};
