import React, { forwardRef } from "react";
import { filters } from "../../../feature/account-settings/utils/filter-helper/filter-helper";
import ApiDropdown from "../api-dropdown";
import { getUrl } from "../api-dropdown.helpers";
export const termsAndConditionsDropdownId = "termsAndConditions";
const DropdownTermsAndConditions = ({ currentElement, onChange, styles, placeholder = "Select Terms and Conditions..." }, ref) => {
  const baseURL = "/right_requests/terms";
  return /* @__PURE__ */ React.createElement("div", { className: "tint-terms-conditions-dropdown" }, /* @__PURE__ */ React.createElement(
    ApiDropdown,
    {
      ref,
      label: "Terms and Conditions",
      placeholder,
      fetchUrl: (url, query) => getUrl({
        url,
        query,
        baseUrl: baseURL,
        filters: [
          {
            by: filters.QUERY,
            value: query
          }
        ]
      }),
      baseUrl: baseURL,
      currentElement,
      dropdownId: termsAndConditionsDropdownId,
      onChange,
      styles
    }
  ));
};
DropdownTermsAndConditions.displayName = "DropdownTermsAndConditions";
export default forwardRef(DropdownTermsAndConditions);
