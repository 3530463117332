var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "./vote-post-item.sass";
import VotePostItem from "./vote-post-item";
import { Config } from "../../../../../../../../utils/config";
import ColorParser from "../../../../../../../../services/color-parser/color-parser";
import { getFormSubmissionUrl } from "../../../../../../utils/experience-helper";
import { ApiService } from "../../../../../../../../services/api-service/api-service";
class VotePostItemContainer extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "onVoteClick", () => {
      this.setAsVoted();
      this.sendVote(1);
      this.setState({ isClicked: true });
      this.props.checkIsVoteAllow();
    });
    __publicField(this, "onUnVoteClick", () => {
      this.setState({ isClicked: false });
      this.sendVote(-1);
      this.setAsUnVoted();
      this.props.checkIsVoteAllow();
    });
    __publicField(this, "sendVote", (voteValue) => {
      const postData = {
        post_id: this.props.post.id,
        vote: voteValue
      };
      const bodyFormData = new FormData();
      for (const key of Object.keys(postData)) {
        bodyFormData.set(key, postData[key]);
      }
      const url = getFormSubmissionUrl(this.props.experienceId || this.props.experience.id);
      ApiService.pureInstance().post(url, bodyFormData).then(() => toast.success("Thank you for voting!")).catch(() => toast.error("Something went wrong, try again later"));
    });
    this.state = {
      isClicked: false,
      isVoteAllowed: false,
      isDevEnv: Config.isDevEnv()
    };
    this.props.checkIsVoteAllow();
  }
  componentDidMount() {
    this.markAsVote(this.props.post.id);
  }
  setAsVoted() {
    const postIdList = JSON.parse(localStorage.getItem(this.props.postIdKey)) || [];
    const votedList = [...postIdList, this.props.post];
    localStorage.setItem(this.props.postIdKey, JSON.stringify(votedList));
  }
  setAsUnVoted() {
    if (localStorage.getItem(this.props.postIdKey)) {
      const updatedVotedList = JSON.parse(localStorage.getItem(this.props.postIdKey)).filter(
        (post) => post.id !== this.props.post.id
      );
      localStorage.setItem(this.props.postIdKey, JSON.stringify(updatedVotedList));
    }
  }
  markAsVote(postId) {
    localStorage.getItem(this.props.postIdKey) && localStorage.getItem(this.props.postIdKey).includes(postId) && this.setState({ isClicked: true });
  }
  render() {
    const { post, style, buttonText, buttonVotedText, isPreviewMode, isVoteAllowed, imageType } = this.props;
    return /* @__PURE__ */ React.createElement(
      VotePostItem,
      {
        imageUrl: post.attributes.image_url,
        url: post.attributes.url,
        username: post.attributes.author ? post.attributes.author.username : "",
        type: post.attributes.type,
        isClicked: this.state.isClicked,
        onVoteClick: this.onVoteClick,
        onUnVoteClick: this.onUnVoteClick,
        imageType,
        buttonText,
        buttonVotedText,
        isPreviewMode,
        isVoteAllowed,
        styles: {
          overlayBackgroundColor: ColorParser.defaultColor(style.overlayBackgroundColor),
          overlayTextColor: ColorParser.defaultColor(style.overlayTextColor),
          buttonBackgroundColor: ColorParser.defaultColor(style.buttonBackgroundColor),
          buttonTextColor: ColorParser.defaultColor(style.buttonTextColor)
        }
      }
    );
  }
}
VotePostItemContainer.propTypes = {
  experienceId: PropTypes.string,
  post: PropTypes.any,
  buttonText: PropTypes.string,
  buttonVotedText: PropTypes.string,
  style: PropTypes.any,
  isPreviewMode: PropTypes.bool,
  isVoteAllowed: PropTypes.bool,
  checkIsVoteAllow: PropTypes.func,
  postIdKey: PropTypes.string,
  imageType: PropTypes.string
};
export default VotePostItemContainer;
