var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./button-content-icon.sass";
import TintIcon from "../icon/icon";
import getIconProperties from "../../../feature/tint-editor/configs/social-icon";
import useSvgIconCustomSize from "../../../components/hooks/use-svg-icon-custom-size/use-svg-icon-custom-size";
export const DefaultButtonIconSizes = {
  FONT: "22px",
  CIRCLE: "48px",
  SVG_ICON: "26px"
};
const ButtonContentIcon = ({
  source,
  onClick,
  fontSize = DefaultButtonIconSizes.FONT,
  circleSize = DefaultButtonIconSizes.CIRCLE,
  svgIconSize,
  isDisabled = false,
  styles,
  positionRight = -8,
  positionBottom = -8
}) => {
  var _a, _b;
  const currentIcon = getIconProperties(source);
  const { iconWidth, iconHeight } = useSvgIconCustomSize({
    source,
    currentWidth: (_a = currentIcon == null ? void 0 : currentIcon.svgIconStyles) == null ? void 0 : _a.width,
    currentHeight: (_b = currentIcon == null ? void 0 : currentIcon.svgIconStyles) == null ? void 0 : _b.height,
    resizeValue: svgIconSize
  });
  if (styles) {
    currentIcon.styles = __spreadValues(__spreadValues({}, currentIcon.styles), styles);
  }
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick,
      className: "button-content-icon",
      style: __spreadValues(__spreadValues({
        bottom: positionBottom,
        right: positionRight,
        fontSize,
        height: circleSize,
        width: circleSize
      }, currentIcon == null ? void 0 : currentIcon.styles), isDisabled ? { background: "#838E94" } : {})
    },
    (currentIcon == null ? void 0 : currentIcon.icon) ? /* @__PURE__ */ React.createElement(TintIcon, { icon: currentIcon.icon }) : /* @__PURE__ */ React.createElement(
      TintIcon,
      {
        svgIconSrc: currentIcon.svgIconSrc,
        style: __spreadValues({}, iconWidth && iconHeight ? {
          width: iconWidth,
          height: iconHeight,
          maxWidth: "initial"
        } : {})
      }
    )
  );
};
export default ButtonContentIcon;
