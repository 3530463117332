import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_OVERVIEW: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_OVERVIEW")),
  GET_SUB_ACCOUNTS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_SUB_ACCOUNTS")),
  SET_CURRENT_ACCOUNT: createPrefixedActionName("INSIGHTS_SOCIAL", "SET_CURRENT_ACCOUNT"),
  GET_PROFILE_VIEWS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_PROFILE_VIEWS")),
  GET_WEBSITE_CLICKS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_WEBSITE_CLICKS")),
  GET_IMPRESSIONS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_IMPRESSIONS")),
  GET_REACH: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_REACH")),
  GET_POST_ENGAGEMENT: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_POST_ENGAGEMENT")),
  GET_CLUSTER_POST_ENGAGEMENT: createRoutine(
    createPrefixedActionName("INSIGHTS_SOCIAL", "GET_CLUSTER_POST_ENGAGEMENT")
  ),
  GET_AUDIENCE_GROWTH: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_AUDIENCE_GROWTH")),
  GET_MOST_ENGAGED_FOLLOWERS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_MOST_ENGAGED_FOLLOWERS")),
  GET_MOST_ENGAGED_TYPE: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_MOST_ENGAGED_TYPE")),
  GET_AUDIENCE_GENDER: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_AUDIENCE_GENDER")),
  GET_AUDIENCE_BY_AGE: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_AUDIENCE_BY_AGE")),
  GET_TOP_COUNTRIES: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_TOP_COUNTRIES")),
  GET_TOP_CITIES: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_TOP_CITIES")),
  GET_STORIES_ENGAGEMENT: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_STORIES_ENGAGEMENT")),
  GET_NEW_POST_ENGAGEMENT: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_NEW_POST_ENGAGEMENT")),
  GET_VIDEO_VIEWS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_VIDEO_VIEWS")),
  GET_NEW_IMPRESSIONS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_NEW_IMPRESSIONS")),
  GET_TOTAL_IMPRESSION: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_TOTAL_IMPRESSION")),
  GET_PUBLISHED_POSTS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_PUBLISHED_POSTS")),
  GET_TOTAL_PUBLISHED_POSTS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_TOTAL_PUBLISHED_POSTS")),
  GET_ENGAGEMENT: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_ENGAGEMENT")),
  GET_DAILY_ENGAGEMENTS: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_DAILY_ENGAGEMENTS")),
  GET_TOTAL_ENGAGEMENT: createRoutine(createPrefixedActionName("INSIGHTS_SOCIAL", "GET_TOTAL_ENGAGEMENT"))
};
export const getSocialOverviewDataStart = () => ({
  type: ACTIONS.GET_OVERVIEW.REQUEST
});
export const getSocialOverviewDataSuccess = (data) => ({
  type: ACTIONS.GET_OVERVIEW.SUCCESS,
  payload: data
});
export const getSocialOverviewDataError = (err) => ({
  type: ACTIONS.GET_OVERVIEW.FAILURE,
  payload: err
});
export const getSubAccountsDataStart = () => ({
  type: ACTIONS.GET_SUB_ACCOUNTS.REQUEST
});
export const getSubAccountsDataSuccess = (data) => ({
  type: ACTIONS.GET_SUB_ACCOUNTS.SUCCESS,
  payload: data
});
export const getSubAccountsDataError = (data) => ({
  type: ACTIONS.GET_SUB_ACCOUNTS.SUCCESS,
  payload: data
});
export const setCurrentAccount = (id, isAccount, source) => ({
  type: ACTIONS.SET_CURRENT_ACCOUNT,
  payload: { id, isAccount, source }
});
export const getProfileViewsDataStart = () => ({
  type: ACTIONS.GET_PROFILE_VIEWS.REQUEST
});
export const getProfileViewsDataSuccess = (data) => ({
  type: ACTIONS.GET_PROFILE_VIEWS.SUCCESS,
  payload: data
});
export const getProfileViewsDataError = (err) => ({
  type: ACTIONS.GET_PROFILE_VIEWS.FAILURE,
  payload: err
});
export const getVideoViewsDataStart = () => ({
  type: ACTIONS.GET_VIDEO_VIEWS.REQUEST
});
export const getVideoViewsDataSuccess = (data) => ({
  type: ACTIONS.GET_VIDEO_VIEWS.SUCCESS,
  payload: data
});
export const getVideoViewsDataError = (err) => ({
  type: ACTIONS.GET_VIDEO_VIEWS.FAILURE,
  payload: err
});
export const getNewImpressionsStart = () => ({
  type: ACTIONS.GET_NEW_IMPRESSIONS.REQUEST
});
export const getNewImpressionsSuccess = (data) => ({
  type: ACTIONS.GET_NEW_IMPRESSIONS.SUCCESS,
  payload: data
});
export const getNewImpressionsError = (err) => ({
  type: ACTIONS.GET_NEW_IMPRESSIONS.FAILURE,
  payload: err
});
export const getTotalImpressionsStart = () => ({
  type: ACTIONS.GET_TOTAL_IMPRESSION.REQUEST
});
export const getTotalImpressionsSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_IMPRESSION.SUCCESS,
  payload: data
});
export const getTotalImpressionsError = (err) => ({
  type: ACTIONS.GET_TOTAL_IMPRESSION.FAILURE,
  payload: err
});
export const getPublishedPostsStart = () => ({
  type: ACTIONS.GET_PUBLISHED_POSTS.REQUEST
});
export const getPublishedPostsSuccess = (data) => ({
  type: ACTIONS.GET_PUBLISHED_POSTS.SUCCESS,
  payload: data
});
export const getPublishedPostsError = (err) => ({
  type: ACTIONS.GET_PUBLISHED_POSTS.FAILURE,
  payload: err
});
export const getTotalPublishedPostsStart = () => ({
  type: ACTIONS.GET_TOTAL_PUBLISHED_POSTS.REQUEST
});
export const getTotalPublishedPostsSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_PUBLISHED_POSTS.SUCCESS,
  payload: data
});
export const getTotalPublishedPostsError = (err) => ({
  type: ACTIONS.GET_TOTAL_PUBLISHED_POSTS.FAILURE,
  payload: err
});
export const getEngagementStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT.REQUEST
});
export const getEngagementSuccess = (data) => ({
  type: ACTIONS.GET_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getEngagementError = (err) => ({
  type: ACTIONS.GET_ENGAGEMENT.FAILURE,
  payload: err
});
export const getDailyEngagementStart = () => ({
  type: ACTIONS.GET_DAILY_ENGAGEMENTS.REQUEST
});
export const getDailyEngagementSuccess = (data) => ({
  type: ACTIONS.GET_DAILY_ENGAGEMENTS.SUCCESS,
  payload: data
});
export const getDailyEngagementError = (err) => ({
  type: ACTIONS.GET_DAILY_ENGAGEMENTS.FAILURE,
  payload: err
});
export const getTotalEngagementStart = () => ({
  type: ACTIONS.GET_TOTAL_ENGAGEMENT.REQUEST
});
export const getTotalEngagementSuccess = (data) => ({
  type: ACTIONS.GET_TOTAL_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getTotalEngagementError = (err) => ({
  type: ACTIONS.GET_TOTAL_ENGAGEMENT.FAILURE,
  payload: err
});
export const getPostEngagementStart = () => ({
  type: ACTIONS.GET_POST_ENGAGEMENT.REQUEST
});
export const getPostEngagementSuccess = (data) => ({
  type: ACTIONS.GET_POST_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getPostEngagementError = (err) => ({
  type: ACTIONS.GET_POST_ENGAGEMENT.FAILURE,
  payload: err
});
export const getWebsiteClicksDataStart = () => ({
  type: ACTIONS.GET_WEBSITE_CLICKS.REQUEST
});
export const getWebsiteClicksDataSuccess = (data) => ({
  type: ACTIONS.GET_WEBSITE_CLICKS.SUCCESS,
  payload: data
});
export const getWebsiteClicksDataError = (err) => ({
  type: ACTIONS.GET_WEBSITE_CLICKS.FAILURE,
  payload: err
});
export const getImpressionsDataStart = () => ({
  type: ACTIONS.GET_IMPRESSIONS.REQUEST
});
export const getImpressionsDataSuccess = (data) => ({
  type: ACTIONS.GET_IMPRESSIONS.SUCCESS,
  payload: data
});
export const getImpressionsDataError = (err) => ({
  type: ACTIONS.GET_IMPRESSIONS.FAILURE,
  payload: err
});
export const getReachDataStart = () => ({
  type: ACTIONS.GET_REACH.REQUEST
});
export const getReachDataSuccess = (data) => ({
  type: ACTIONS.GET_REACH.SUCCESS,
  payload: data
});
export const getReachDataError = (err) => ({
  type: ACTIONS.GET_REACH.FAILURE,
  payload: err
});
export const getPostEngagementDataStart = () => ({
  type: ACTIONS.GET_POST_ENGAGEMENT.REQUEST
});
export const getPostEngagementDataSuccess = (data) => ({
  type: ACTIONS.GET_POST_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getPostEngagementDataError = (err) => ({
  type: ACTIONS.GET_POST_ENGAGEMENT.FAILURE,
  payload: err
});
export const getAudienceGrowthDataStart = () => ({
  type: ACTIONS.GET_AUDIENCE_GROWTH.REQUEST
});
export const getAudienceGrowthDataSuccess = (data) => ({
  type: ACTIONS.GET_AUDIENCE_GROWTH.SUCCESS,
  payload: data
});
export const getAudienceGrowthDataError = (err) => ({
  type: ACTIONS.GET_AUDIENCE_GROWTH.FAILURE,
  payload: err
});
export const getMostEngagedFollowersDataStart = () => ({
  type: ACTIONS.GET_MOST_ENGAGED_FOLLOWERS.REQUEST
});
export const getMostEngagedFollowersDataSuccess = (data) => ({
  type: ACTIONS.GET_MOST_ENGAGED_FOLLOWERS.SUCCESS,
  payload: data
});
export const getMostEngagedFollowersDataError = (err) => ({
  type: ACTIONS.GET_MOST_ENGAGED_FOLLOWERS.FAILURE,
  payload: err
});
export const getMostEngagedTypeDataStart = () => ({
  type: ACTIONS.GET_MOST_ENGAGED_TYPE.REQUEST
});
export const getMostEngagedTypeDataSuccess = (data) => ({
  type: ACTIONS.GET_MOST_ENGAGED_TYPE.SUCCESS,
  payload: data
});
export const getMostEngagedTypeDataError = (err) => ({
  type: ACTIONS.GET_MOST_ENGAGED_TYPE.FAILURE,
  payload: err
});
export const getAudienceGenderDataStart = () => ({
  type: ACTIONS.GET_AUDIENCE_GENDER.REQUEST
});
export const getAudienceGenderDataSuccess = (data) => ({
  type: ACTIONS.GET_AUDIENCE_GENDER.SUCCESS,
  payload: data
});
export const getAudienceGenderDataError = (err) => ({
  type: ACTIONS.GET_AUDIENCE_GENDER.FAILURE,
  payload: err
});
export const getAudienceByAgeDataStart = () => ({
  type: ACTIONS.GET_AUDIENCE_BY_AGE.REQUEST
});
export const getAudienceByAgeDataSuccess = (data) => ({
  type: ACTIONS.GET_AUDIENCE_BY_AGE.SUCCESS,
  payload: data
});
export const getAudienceByAgeDataError = (err) => ({
  type: ACTIONS.GET_AUDIENCE_BY_AGE.FAILURE,
  payload: err
});
export const getTopCountriesDataStart = () => ({
  type: ACTIONS.GET_TOP_COUNTRIES.REQUEST
});
export const getTopCountriesDataSuccess = (data) => ({
  type: ACTIONS.GET_TOP_COUNTRIES.SUCCESS,
  payload: data
});
export const getTopCountriesDataError = (err) => ({
  type: ACTIONS.GET_TOP_COUNTRIES.FAILURE,
  payload: err
});
export const getTopCitiesDataStart = () => ({
  type: ACTIONS.GET_TOP_CITIES.REQUEST
});
export const getTopCitiesDataSuccess = (data) => ({
  type: ACTIONS.GET_TOP_CITIES.SUCCESS,
  payload: data
});
export const getTopCitiesDataError = (err) => ({
  type: ACTIONS.GET_TOP_CITIES.FAILURE,
  payload: err
});
export const getStoriesEngagementDataStart = () => ({
  type: ACTIONS.GET_STORIES_ENGAGEMENT.REQUEST
});
export const getStoriesEngagementDataSuccess = (data) => ({
  type: ACTIONS.GET_STORIES_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getStoriesEngagementDataError = (err) => ({
  type: ACTIONS.GET_STORIES_ENGAGEMENT.FAILURE,
  payload: err
});
export const getNewPostEngagementDataStart = () => ({
  type: ACTIONS.GET_NEW_POST_ENGAGEMENT.REQUEST
});
export const getNewPostEngagementDataSuccess = (data) => ({
  type: ACTIONS.GET_NEW_POST_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getNewPostEngagementDataError = (err) => ({
  type: ACTIONS.GET_NEW_POST_ENGAGEMENT.FAILURE,
  payload: err
});
export const getClustersPostEngagementDataStart = () => ({
  type: ACTIONS.GET_CLUSTER_POST_ENGAGEMENT.REQUEST
});
export const getClustersPostEngagementDataSuccess = (data) => ({
  type: ACTIONS.GET_CLUSTER_POST_ENGAGEMENT.SUCCESS,
  payload: data
});
export const getClustersPostEngagementDataError = (err) => ({
  type: ACTIONS.GET_CLUSTER_POST_ENGAGEMENT.FAILURE,
  payload: err
});
