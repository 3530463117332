import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditInputDropdown, EditUploadDesign } from "../../../";
import EditColorInput from "../../../edit-color-input/edit-color-input";
import "./edit-cookie-banner-styles.sass";
const outlineSettings = [
  {
    name: "None",
    value: false
  },
  {
    name: "Yes",
    value: true
  }
];
export class EditCookieBannerStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, null, /* @__PURE__ */ React.createElement(
      "form",
      {
        className: "edit-cookie-banner",
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: "edit-cookie-banner__group" }, /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.buttonAllowStyleTextColor,
          label: "Accept button text color",
          onChange: (value) => {
            this.props.styleDataUpdate({ buttonAllowStyleTextColor: value });
          }
        }
      ), /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.buttonAllowStyleBackground,
          label: "Accept button color",
          onChange: (value) => {
            this.props.styleDataUpdate({ buttonAllowStyleBackground: value });
          }
        }
      ), /* @__PURE__ */ React.createElement(
        EditInputDropdown,
        {
          label: "ACCEPT BUTTON OUTLINE",
          onChange: (e) => this.props.styleDataUpdate({ buttonAllowStyleOutline: e.target.value }),
          options: outlineSettings,
          defaultValue: this.props.data.styles.buttonAllowStyleOutline ? this.props.data.styles.buttonAllowStyleOutline : outlineSettings[0].value
        }
      ), /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.buttonAllowStyleOutlineColor,
          label: "Accept button outline color",
          onChange: (value) => {
            this.props.styleDataUpdate({ buttonAllowStyleOutlineColor: value });
          }
        }
      )),
      /* @__PURE__ */ React.createElement("div", { className: "edit-cookie-banner__group" }, /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.buttonDismissStyleTextColor,
          label: "Dismiss BUTTON TEXT COLOR",
          onChange: (value) => {
            this.props.styleDataUpdate({ buttonDismissStyleTextColor: value });
          }
        }
      ), /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.buttonDismissStyleBackground,
          label: "Dismiss button color",
          onChange: (value) => {
            this.props.styleDataUpdate({ buttonDismissStyleBackground: value });
          }
        }
      ), /* @__PURE__ */ React.createElement(
        EditInputDropdown,
        {
          label: "Dismiss BUTTON OUTLINE",
          onChange: (e) => this.props.styleDataUpdate({ buttonDismissStyleOutline: e.target.value }),
          options: outlineSettings,
          defaultValue: this.props.data.styles.buttonDismissStyleOutline ? this.props.data.styles.buttonDismissStyleOutline : outlineSettings[0].value
        }
      ), /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.buttonDismissStyleOutlineColor,
          label: "Dismiss button outline color",
          onChange: (value) => {
            this.props.styleDataUpdate({ buttonDismissStyleOutlineColor: value });
          }
        }
      )),
      /* @__PURE__ */ React.createElement("div", { className: "edit-cookie-banner__group edit-cookie-banner__group--background" }, /* @__PURE__ */ React.createElement(
        EditColorInput,
        {
          color: this.props.data.styles.bannerTextColor,
          label: "Banner Text Color",
          onChange: (value) => {
            this.props.styleDataUpdate({ bannerTextColor: value });
          }
        }
      ), /* @__PURE__ */ React.createElement(
        EditUploadDesign,
        {
          isPaddingDisabled: true,
          isImageUploadDisabled: true,
          dataUpdate: this.props.dataUpdate,
          styleDataUpdate: this.props.styleDataUpdate,
          data: this.props.data
        }
      )),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit")
    ));
  }
}
