var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./hue-circle.sass";
const HUECircle = ({
  children,
  strokeWidth = 4,
  styles = {
    width: "120px",
    height: "120px"
  }
}) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-hue-circle", style: __spreadValues({}, styles) }, /* @__PURE__ */ React.createElement("div", { className: "tint-hue-circle__content" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "tint-hue-circle__dark-circle",
      style: {
        left: `${strokeWidth}px`,
        top: `${strokeWidth}px`,
        width: `calc(100% - ${strokeWidth * 2}px)`,
        height: `calc(100% - ${strokeWidth * 2}px)`
      }
    },
    /* @__PURE__ */ React.createElement("span", { className: "tint-hue-circle__counter" }, children)
  )));
};
export default HUECircle;
