import { Config } from "../../../utils/config";
export const isUrlAddress = function(url) {
  return Config.regExp.httpPattern.test(url.toLowerCase());
};
export const isDomainAddress = function(url) {
  return Config.regExp.domainPattern.test(url.toLowerCase());
};
export const getUrlAddress = function(link) {
  if (isDomainAddress(link)) {
    return `http://${link}`;
  } else {
    return link;
  }
};
export const getCurrentPage = (link) => {
  return getParameterByName("page[number]", decodeURIComponent(link));
};
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
