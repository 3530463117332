import React from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import EditColorInput from "../../../edit-color-input/edit-color-input";
import PropTypes from "prop-types";
const TagOWarStyles = ({ data, dataUpdate }) => {
  const styleData = {
    leftSide: {
      backgroundColor: data.backgroundColorLeft,
      backgroundRange: data.backgroundRangeLeft,
      backgroundPostColor: data.backgroundColorPostLeft,
      textPostColor: data.textColorPostLeft,
      textColor: data.textColorLeft
    },
    rightSide: {
      backgroundColor: data.backgroundColorRight,
      backgroundRange: data.backgroundRangeRight,
      backgroundPostColor: data.backgroundColorPostRight,
      textColor: data.textColorRight,
      textPostColor: data.textColorRight
    }
  };
  function renderLeftGroupField(data2) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.backgroundColor,
        label: "Primary color",
        onChange: (value) => {
          dataUpdate({
            backgroundColorLeft: value
          });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.backgroundRange,
        label: "Secondary color",
        onChange: (value) => {
          dataUpdate({
            backgroundRangeLeft: value
          });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.textColor,
        label: "Text color",
        onChange: (value) => {
          dataUpdate({ textColorLeft: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.backgroundPostColor,
        label: "Background post color",
        onChange: (value) => {
          dataUpdate({
            backgroundColorPostLeft: value
          });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.textPostColor,
        label: "Post text color",
        onChange: (value) => {
          dataUpdate({ textColorPostLeft: value });
        }
      }
    ));
  }
  function renderRightGroupField(data2) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.backgroundColor,
        label: "Primary color",
        onChange: (value) => {
          dataUpdate({
            backgroundColorRight: value
          });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.backgroundRange,
        label: "Secondary color",
        onChange: (value) => {
          dataUpdate({
            backgroundRangeRight: value
          });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.textColor,
        label: "Text color",
        onChange: (value) => {
          dataUpdate({ textColorRight: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.backgroundPostColor,
        label: "Background post color",
        onChange: (value) => {
          dataUpdate({ backgroundColorPostRight: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data2.textPostColor,
        label: "Post text color",
        onChange: (value) => {
          dataUpdate({ textColorPostRight: value });
        }
      }
    ));
  }
  return /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-styles" }, /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize your Tag-o-War by changing the color fields below." }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group" }, /* @__PURE__ */ React.createElement("p", { className: "tint-edit-modal-body__header" }, "Left side"), renderLeftGroupField(styleData.leftSide)), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group" }, /* @__PURE__ */ React.createElement("p", { className: "tint-edit-modal-body__header" }, "Right side"), renderRightGroupField(styleData.rightSide))));
};
TagOWarStyles.propTypes = {
  dataUpdate: PropTypes.func,
  data: PropTypes.shape({
    backgroundColorLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundColorRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundRangeLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundRangeRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    textColorLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    textColorRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  })
};
export default TagOWarStyles;
