var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./form.sass";
import { SubmissionSuccess } from "../submission-success/submission-success";
import { FileStack } from "../../../../../../../services/filestack/file-stack";
import { formUpload } from "../../../../../../../model/form-upload.model";
import { Formik } from "formik";
import * as Yup from "yup";
import { handleApiError } from "../../../../../../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import ColorParser from "../../../../../../../services/color-parser/color-parser";
import { FIELD_TYPE, transformToSendAble } from "../form-helper";
import { ApiService } from "../../../../../../../services/api-service/api-service";
import { FormFieldRenderer } from "./form-field-renderer";
import { getFormSubmissionUrl } from "../../../../../utils/experience-helper";
import ReCAPTCHA from "react-google-recaptcha";
import HCaptcha from "@hcaptcha/react-hcaptcha";
export class Form extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "updatePlaceholdersStyles", () => {
      const { formFieldTextColor } = this.props.data.styles;
      Object.keys(this.textInputRefs).forEach(
        (key) => this.textInputRefs[key].style.setProperty("--placeholderColor", ColorParser.defaultColor(formFieldTextColor))
      );
    });
    __publicField(this, "formRequest", (formValue) => {
      const bodyFormData = new FormData();
      if (formValue.terms) {
        formValue.terms_accepted = true;
      }
      delete formValue.terms;
      delete formValue.contestRules;
      delete formValue.privacyPolicy;
      delete formValue.uploadFileName;
      Object.keys(formValue).map((key) => {
        if (formValue[key] !== "") {
          const _value = typeof formValue[key] === "string" ? formValue[key].trim() : formValue[key];
          bodyFormData.set(key.replace(" ", "_"), _value);
        }
      });
      if (document.referrer) {
        bodyFormData.set("http_referer", document.referrer);
      }
      if (this.imageFile !== void 0) {
        bodyFormData.set(Object.keys(this.imageFile), this.imageFile.media);
      }
      let paramObj = this.getQueryParams();
      Object.keys(paramObj).map((key) => {
        if (key.includes("utm_")) {
          bodyFormData.set(key, paramObj[key]);
        }
      });
      const url = getFormSubmissionUrl(this.props.experienceId || this.props.experience.id);
      return ApiService.pureInstance().post(url, bodyFormData);
    });
    __publicField(this, "renderCheckbox", ({
      handleChange,
      values,
      errors,
      touched,
      formikPropertyName,
      urlProperty,
      textProperty,
      agreeProperty,
      linkTitleProperty,
      defaultLinkTitleText,
      textColorProperty,
      modalFn,
      requiredText = false
    }) => {
      const _isError = errors[formikPropertyName] && touched[formikPropertyName] || this.props.forceErrorVisibility;
      const errorColor = ColorParser.defaultColor(this.props.data.styles.errorColor);
      const linkTitle = this.props.getTranslation(this.props.data.content[linkTitleProperty]);
      const url = this.props.getTranslation(this.props.data.content[urlProperty]);
      const text = this.props.getTranslation(this.props.data.content[textProperty]);
      return url || text ? /* @__PURE__ */ React.createElement(
        "div",
        {
          className: `${_isError ? "tint-form-terms--invalid" : ""} tint-page-section--form__checkbox-container tint-page-section--form__checkbox-container--flex`
        },
        /* @__PURE__ */ React.createElement("div", { style: { display: "flex" } }, /* @__PURE__ */ React.createElement(
          "input",
          {
            id: formikPropertyName,
            name: formikPropertyName,
            onChange: handleChange,
            value: values[formikPropertyName],
            checked: values[formikPropertyName],
            type: "checkbox",
            required: true,
            style: {
              borderColor: _isError ? errorColor : "",
              backgroundColor: values[formikPropertyName] ? ColorParser.defaultColor(this.props.data.styles[textColorProperty]) : ""
            }
          }
        ), /* @__PURE__ */ React.createElement(
          "label",
          {
            htmlFor: "required",
            style: {
              color: _isError ? errorColor : ColorParser.defaultColor(this.props.data.styles[textColorProperty])
            }
          },
          this.props.getTranslation(this.props.data.content[agreeProperty]),
          " ",
          url ? /* @__PURE__ */ React.createElement(
            "a",
            {
              style: { color: _isError ? errorColor : "" },
              rel: "noopener noreferrer",
              target: "_blank",
              href: url,
              className: "tint-page-section--form__terms__link"
            },
            linkTitle ? linkTitle : defaultLinkTitleText,
            "*"
          ) : /* @__PURE__ */ React.createElement(
            "span",
            {
              onClick: modalFn,
              style: { color: _isError ? errorColor : "" },
              className: "tint-page-section--form__terms__link"
            },
            linkTitle ? linkTitle : defaultLinkTitleText,
            "*"
          )
        )),
        requiredText ? /* @__PURE__ */ React.createElement(
          "span",
          {
            style: {
              color: _isError ? errorColor : ColorParser.defaultColor(this.props.data.styles.requiredColor)
            }
          },
          "*" + (this.props.getTranslation(this.props.data.content.requiredFieldText) || "Required")
        ) : null
      ) : null;
    });
    this.imageFile = void 0;
    this.state = {
      isFormSended: false,
      isPending: false,
      uploadText: ""
    };
    this.textInputRefs = {};
  }
  componentDidMount() {
    this.updatePlaceholdersStyles();
  }
  getQueryParams() {
    const params = {};
    let parser = document.createElement("a");
    parser.href = location.href;
    let query = parser.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }
  submitForm(formValue) {
    if (!this.state.isFormSended && !this.state.isPending) {
      if (this.props.data.content.isEndTimeEnabled && new Date(this.props.data.content.endDate).getTime() < Date.now()) {
        toast.info("You cannot submit entries anymore");
      } else {
        this.formRequest(formValue).then(() => this.setState({ isFormSended: true })).catch((e) => handleApiError(e, toast.error)).then(() => {
          this.setState({ isPending: false });
        });
      }
    }
  }
  onUploadClick(setFieldValue) {
    const oneGB = 1073741824;
    const bypassValidation = this.props.experience.attributes.bypass_mime_type_validation;
    new FileStack().openFilePicker(__spreadProps(__spreadValues({
      maxFiles: parseInt(this.props.data.content.uploadPhotoType) === formUpload.SINGLE ? 1 : 20,
      maxSize: bypassValidation ? oneGB : this.props.getMaxVideoSizePlanLimit()
    }, bypassValidation ? {} : { accept: ["image/*", "video/*"] }), {
      onUploadDone: (res) => {
        if (setFieldValue) {
          setFieldValue("uploadFileName", res.filesUploaded[0].filename);
        }
        const fileAmount = res.filesUploaded.length;
        this.setState({ uploadText: fileAmount > 1 ? `${fileAmount} Files Selected` : "1 File Selected" });
        this.imageFile = {
          media: JSON.stringify(res.filesUploaded.map((e) => e.url))
        };
      }
    }));
  }
  renderUploadButton(setFieldValue, isError) {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          borderColor: ColorParser.defaultColor(this.props.data.styles.uploadButtonBorderColor),
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.uploadButtonBackgroundColor)
        },
        className: `tint-experience-upload-button ${isError ? "tint-experience-upload-button--error" : ""}`,
        onClick: () => this.onUploadClick(setFieldValue)
      },
      /* @__PURE__ */ React.createElement(
        "span",
        {
          style: {
            color: ColorParser.defaultColor(this.props.data.styles.uploadButtonTextColor)
          }
        },
        this.props.getTranslation(this.props.data.content.uploadButtonText)
      )
    );
  }
  renderCaptcha(setFieldValue) {
    switch (this.props.experience.attributes.captcha_type) {
      case "google_recaptcha":
        return /* @__PURE__ */ React.createElement("div", { className: "tint-form-captcha" }, /* @__PURE__ */ React.createElement(
          ReCAPTCHA,
          {
            sitekey: this.props.experience.attributes.captcha_public_key,
            onChange: (token) => setFieldValue("g-recaptcha-response", token)
          }
        ));
      case "h_captcha":
        return /* @__PURE__ */ React.createElement("div", { className: "tint-form-captcha" }, /* @__PURE__ */ React.createElement(
          HCaptcha,
          {
            sitekey: this.props.experience.attributes.captcha_public_key,
            onVerify: (token) => setFieldValue("h-captcha-response", token)
          }
        ));
      default:
        return;
    }
  }
  getInputKey(item) {
    if (this.props.data.content.hasAdvancedSettings && item.key) {
      return item.key;
    }
    const translation = this.props.getTranslation(item.placeholder);
    if (translation) {
      return transformToSendAble(translation);
    }
    return item.placeholder;
  }
  mapFormFields(array) {
    return array.map((item) => {
      const key = this.getInputKey(item);
      return {
        name: key,
        placeholder: this.props.getTranslation(item.placeholder),
        initialValue: item.type === FIELD_TYPE.HIDDEN_INPUT ? this.props.getTranslation(item.placeholder) : "",
        required: item.required,
        validators: item.required || item.required === void 0 ? Yup.string().required(`${this.props.getTranslation(item.placeholder)} is required`) : Yup.string()
      };
    });
  }
  renderGdprCheckbox(handleChange, values) {
    return this.props.getTranslation(this.props.data.content.gdprCheckboxLabelText) ? /* @__PURE__ */ React.createElement("div", { className: "tint-page-section--form__checkbox-container tint-page-section--form__checkbox-container--gdpr" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      "input",
      {
        id: "opt_in",
        name: "opt_in",
        onChange: handleChange,
        value: values.opt_in,
        checked: values.opt_in,
        type: "checkbox",
        style: {
          backgroundColor: values.opt_in ? ColorParser.defaultColor(this.props.data.styles.optTextColor) : ""
        }
      }
    ), /* @__PURE__ */ React.createElement(
      "label",
      {
        htmlFor: "required",
        style: {
          color: ColorParser.defaultColor(this.props.data.styles.optTextColor)
        }
      },
      this.props.getTranslation(this.props.data.content.gdprCheckboxLabelText)
    )), /* @__PURE__ */ React.createElement(
      "span",
      {
        className: "tint-gdpr-description",
        style: {
          color: ColorParser.defaultColor(this.props.data.styles.optTextColor)
        }
      },
      this.props.getTranslation(this.props.data.content.gdprCheckboxDescriptionText)
    )) : null;
  }
  render() {
    const fields = this.mapFormFields(this.props.data.content.fields);
    const mapInitialValues = fields.reduce((map, object) => {
      map[object.name] = object.initialValue;
      return map;
    }, {});
    const mapInitialValidators = fields.reduce((map, object) => {
      map[object.name] = object.validators;
      return map;
    }, {});
    const checkboxValidators = {};
    if (this.props.getTranslation(this.props.data.content.termsConditionsUrl) || this.props.getTranslation(this.props.data.content.termsConditions)) {
      checkboxValidators.terms = Yup.bool().oneOf([true]);
    }
    if (this.props.getTranslation(this.props.data.content.contestRulesUrl) || this.props.getTranslation(this.props.data.content.contestRules)) {
      checkboxValidators.contestRules = Yup.bool().oneOf([true]);
    }
    if (this.props.getTranslation(this.props.data.content.privacyPolicyUrl) || this.props.getTranslation(this.props.data.content.privacyPolicy)) {
      checkboxValidators.privacyPolicy = Yup.bool().oneOf([true]);
    }
    return this.state.isFormSended || this.props.isEditingSuccessMessage ? /* @__PURE__ */ React.createElement(
      SubmissionSuccess,
      {
        title: this.props.getTranslation(this.props.data.content.successMessageTitle),
        description: this.props.getTranslation(this.props.data.content.successMessageDescription),
        successMessageLinks: this.props.data.content.successMessageLinks,
        getTranslation: this.props.getTranslation
      }
    ) : /* @__PURE__ */ React.createElement("div", { className: "tint-form" }, /* @__PURE__ */ React.createElement(
      Formik,
      {
        enableReinitialize: true,
        initialValues: __spreadValues(__spreadValues({}, mapInitialValues), {
          uploadFileName: "",
          terms: false,
          contestRules: false,
          privacyPolicy: false,
          opt_in: false
        }),
        validationSchema: Yup.object().shape(__spreadProps(__spreadValues(__spreadValues({}, mapInitialValidators), checkboxValidators), {
          uploadFileName: this.props.data.content.isPhotoUploadRequired ? Yup.string().required() : Yup.string()
        })),
        onSubmit: (values) => {
          this.submitForm(values);
        },
        render: ({ submitCount, setFieldValue, errors, touched, values, handleChange, handleSubmit, dirty }) => /* @__PURE__ */ React.createElement(
          "form",
          {
            id: "formId",
            className: `form ${!this.props.isPreviewMode ? "form--read-only" : ""}`,
            noValidate: true,
            onSubmit: (e) => {
              e.preventDefault();
              handleSubmit();
            }
          },
          this.props.data.content.fields.map(
            (e, i) => FormFieldRenderer.render({
              e,
              i,
              data: this.props.data,
              forceErrorVisibility: this.props.forceErrorVisibility,
              isPreviewMode: this.props.isPreviewMode,
              getTranslation: this.props.getTranslation,
              dirty,
              errors,
              values,
              touched,
              handleChange,
              setFieldValue,
              key: this.getInputKey(e)
            })
          ),
          values.uploadFileName ? /* @__PURE__ */ React.createElement(
            "p",
            {
              className: "tint-form__upload-file-name",
              style: { color: ColorParser.defaultColor(this.props.data.styles.formFieldTextColor) }
            },
            this.state.uploadText
          ) : null,
          this.props.data.content.isPhotoUploadEnabled ? this.renderUploadButton(
            setFieldValue,
            submitCount > 0 && errors.uploadFileName || this.props.forceErrorVisibility
          ) : null,
          this.props.data.content.isPhotoUploadEnabled && (submitCount > 0 && errors.uploadFileName || this.props.forceErrorVisibility) ? /* @__PURE__ */ React.createElement(
            "p",
            {
              style: { color: ColorParser.defaultColor(this.props.data.styles.errorColor) },
              className: "tint-form__upload-file-name-error"
            },
            this.props.getTranslation(this.props.data.content.uploadErrorText) || "File upload is required in order to submit"
          ) : null,
          /* @__PURE__ */ React.createElement("div", { className: "tint-page-section--form__terms" }, this.renderCheckbox({
            handleChange,
            values,
            errors,
            touched,
            formikPropertyName: "terms",
            urlProperty: "termsConditionsUrl",
            textProperty: "termsConditions",
            agreeProperty: "termsConditionsAgree",
            defaultLinkTitleText: "Terms & Conditions",
            textColorProperty: "termsConditionsTextColor",
            linkTitleProperty: "termsConditionsLinkTitle",
            modalFn: this.props.openTermsConditionsModal,
            requiredText: true
          }), this.renderCheckbox({
            handleChange,
            values,
            errors,
            touched,
            formikPropertyName: "privacyPolicy",
            urlProperty: "privacyPolicyUrl",
            textProperty: "privacyPolicy",
            agreeProperty: "privacyPolicyAgree",
            defaultLinkTitleText: "Privacy Policy",
            textColorProperty: "privacyPolicyTextColor",
            linkTitleProperty: "privacyPolicyLinkTitle",
            modalFn: this.props.openPrivacyPolicyModal
          }), this.renderCheckbox({
            handleChange,
            values,
            errors,
            touched,
            formikPropertyName: "contestRules",
            urlProperty: "contestRulesUrl",
            textProperty: "contestRules",
            agreeProperty: "contestRulesAgree",
            defaultLinkTitleText: "Contest Rules",
            textColorProperty: "contestRulesTextColor",
            linkTitleProperty: "contestRulesLinkTitle",
            modalFn: this.props.openContestRulesModal
          }), this.renderGdprCheckbox(handleChange, values)),
          this.renderCaptcha(setFieldValue),
          /* @__PURE__ */ React.createElement(
            "button",
            {
              style: {
                backgroundColor: ColorParser.defaultColor(this.props.data.styles.buttonBackgroundColor),
                color: ColorParser.defaultColor(this.props.data.styles.buttonTextColor)
              },
              type: "submit"
            },
            this.props.getTranslation(this.props.data.content.buttonText)
          )
        )
      }
    ));
  }
}
