var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/languages/languages.actions";
export const initialState = {
  currentLanguage: {
    value: void 0,
    direction: void 0
  }
};
export const languages = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_LANGUAGE:
      return __spreadProps(__spreadValues({}, state), {
        currentLanguage: {
          value: action.payload.value,
          direction: action.payload.direction
        }
      });
    default:
      return state;
  }
};
export default languages;
