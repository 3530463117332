import React, { useState } from "react";
import { Button } from "../../../../../../../../components/interface/button/button";
import "./twitter-integration.sass";
import { Config } from "../../../../../../../../utils/config";
import { useDispatch } from "react-redux";
import TwitterIntegrationForm from "../../../../../../../../components/twitter-integration-form/twitter-integration-form";
import { useFormik } from "formik";
import { postMarketingIntegration } from "../../../../../../../account-settings/actions/marketing-integrations/marketing-integrations.fat-actions";
import { toast } from "react-toastify";
import { handleFormValidation } from "../../../../../../../../services/functions/error-handler/error-handler";
import { validationSchemaTwitter } from "../../../../../../../account-settings/utils/domain-url-validation/domain-url-validation";
import { SidebarFooter } from "../../../../../../../../components/sidebar/components/footer/sidebar-footer";
import TintSidebar from "../../../../../../../../components/sidebar/sidebar";
export const twitterSidebarType = "twitterSidebar";
const STEPS = {
  info: "INTEGRATION_INFO",
  form: "INTEGRATION_FORM"
};
const TwitterIntegration = ({ isOpen, onClose, onLeftButtonClick, additionalCallback }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(STEPS.info);
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      site: "",
      brandfolder: "",
      access_token: "",
      client_id_v1: "",
      client_id: "",
      secret_id_v1: "",
      secret_id: ""
    },
    validationSchema: validationSchemaTwitter,
    onSubmit: (values, actions) => {
      dispatch(postMarketingIntegration("twitter", values)).then(() => additionalCallback && additionalCallback()).catch((err) => {
        toast.error("Something went wrong, try again later");
        actions.setErrors(handleFormValidation(err));
      });
    }
  });
  const openIntegrationSidebar = () => {
    setCurrentStep(STEPS.form);
  };
  const openInfoSidebar = () => {
    setCurrentStep(STEPS.info);
  };
  const openHelp = () => {
    window.open(Config.url.zenDesk.twitter_integration, "_blank");
  };
  const renderFooter = ({ onClickButton }) => {
    const isFormCurrentStep = currentStep === STEPS.form;
    const leftButtonText = isFormCurrentStep ? "Previous" : "Close";
    const rightButtonText = isFormCurrentStep ? "Connect" : "Next";
    return /* @__PURE__ */ React.createElement(
      SidebarFooter,
      {
        onClickButton,
        leftButtonText,
        rightButtonText,
        isRightButtonDisabled: !(formik.isValid && formik.dirty)
      }
    );
  };
  return /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      title: "Add Integration",
      isOpen,
      onClose,
      onLeftButtonClick: () => {
        if (currentStep === STEPS.form) {
          openInfoSidebar();
        } else {
          onLeftButtonClick();
        }
      },
      onRightButtonClick: () => formik.handleSubmit(),
      defaultFooterStyle: false,
      footerComponent: renderFooter
    },
    /* @__PURE__ */ React.createElement("div", { className: "twitter-integration-sidebar-wrapper" }, " ", currentStep === STEPS.info ? /* @__PURE__ */ React.createElement("div", { className: "twitter-integration-sidebar" }, /* @__PURE__ */ React.createElement("h3", { className: "twitter-integration-sidebar__header" }, "Tweets Incoming!"), /* @__PURE__ */ React.createElement("div", { className: "twitter-integration-sidebar__description-wrapper" }, /* @__PURE__ */ React.createElement("p", { className: "twitter-integration-sidebar__description" }, "We've recently made a change to the way you ingest tweets into our platform. In order to make this process smoother and more secure, we now require users to enter an API key from their X App. By doing this, you'll be able to pull in content easily like you used to.")), /* @__PURE__ */ React.createElement("p", { className: "twitter-integration-sidebar__label" }, "To pull in X Content, you first need to add your X API key"), /* @__PURE__ */ React.createElement(Button, { type: "info", text: "I have API key, I am ready to enter it.", onClick: openIntegrationSidebar }), /* @__PURE__ */ React.createElement("p", { className: "twitter-integration-sidebar__label" }, "If you are unsure, click the link below to read our step-by-step guide."), /* @__PURE__ */ React.createElement(Button, { type: "stroked", text: "I do not have my API key, please show me.", onClick: openHelp })) : /* @__PURE__ */ React.createElement(TwitterIntegrationForm, { formik }))
  );
};
export default TwitterIntegration;
