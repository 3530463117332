export const permissions = {
  all: "all",
  teamSecurity: "team_security",
  teamsUsersRead: "teams_users_r",
  teamsUsersCreate: "teams_users_cu",
  teamsUsersDelete: "teams_users_d",
  rolesCreate: "roles_cu",
  rolesDelete: "roles_d",
  createTint: "create_tint",
  modifyTint: "modify_tint",
  moderateTint: "moderate_tint",
  deleteTint: "delete_tint",
  personalizationsRead: "personalizations_r",
  personalizationsEdit: "personalizations_cu",
  personalizationsDelete: "personalizations_d",
  mixesRead: "mixes_r",
  mixesCreate: "mixes_cu",
  mixesDelete: "mixes_d",
  newMixes: "new_mixes",
  assetsRead: "assets_read",
  assetsEdit: "assets_edit",
  assetsDelete: "assets_delete",
  assetsPublish: "assets_publish",
  accountsRead: "accounts_read",
  accountsDelete: "accounts_delete",
  accountsCreate: "accounts_create",
  newTintAccess: "new_tints_access",
  newAccounts: "accounts_new",
  discover: "discover",
  fontsRead: "fonts_r",
  fontsUpload: "fonts_cu",
  fontsDelete: "fonts_d",
  experiencesRead: "experiences_r",
  experiencesCreate: "experiences_cu",
  experiencesDelete: "experiences_d",
  experiencesPublish: "experiences_publish",
  domainsRead: "domains_r",
  domainsCreate: "domains_cu",
  domainsDelete: "domains_d",
  formSubmissionsRead: "form_submissions_r",
  formSubmissionsDelete: "form_submissions_d",
  newCollections: "new_collections",
  newPersonalizations: "new_personalizations",
  newFonts: "new_fonts",
  newDomains: "new_domains",
  newExperiences: "new_experiences",
  productsRead: "products_r",
  productsDelete: "products_d",
  productsCreate: "products_cu",
  postTagProducts: "posts_tag_product",
  productAccountsRead: "product_accounts_r",
  productAccountsDelete: "product_accounts_d",
  productAccountsCreate: "product_accounts_cu",
  newProductAccounts: "new_product_accounts",
  productFeedsRead: "product_feeds_r",
  productFeedsDelete: "product_feeds_d",
  productFeedsCreate: "product_feeds_cu",
  newProductFeeds: "new_product_feeds",
  newWebhooks: "new_webhooks",
  webhooksRead: "webhooks_r",
  webhooksDelete: "webhooks_d",
  webhooksCreate: "webhooks_cu",
  newOauthApplications: "new_oauth_applications",
  oauthApplicationsRead: "oauth_applications_r",
  oauthApplicationsDelete: "oauth_applications_d",
  oauthApplicationsCreate: "oauth_applications_cu",
  formsPublish: "forms_publish",
  newForms: "new_forms",
  formsRead: "forms_r",
  formsDelete: "forms_d",
  formsCreate: "forms_cu",
  changeBilling: "change_billing",
  security: "security",
  communityUpdate: "community_u"
};
