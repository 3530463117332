import React from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { renderEditColorInput } from "../../page-section-utils";
import { RangeInput } from "../../../../../../../components/interface/inputs/range-input/range-input";
import { EditInputDropdown, EditUploadDesign } from "../../../index";
const colorInputs = [
  {
    property: "firstColumnBackgroundColor",
    label: "Column-1 background color"
  },
  {
    property: "secondColumnBackgroundColor",
    label: "Column-2 background color"
  },
  {
    property: "thirdColumnBackgroundColor",
    label: "Column-3 background color"
  }
];
const justifyContentOptions = [
  { value: "center", name: "center" },
  { value: "flex-start", name: "start" },
  { value: "flex-end", name: "end" }
];
const MultiColumnEditStyles = ({ data, dataUpdate, styleDataUpdate, updateBackgroundColor }) => {
  const getSelectedJustifyContent = () => {
    const justifyContent = justifyContentOptions.find((e) => e.value === data.styles.justifyContent);
    return justifyContent ? justifyContent.name : "";
  };
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize this section with the color fields below." }, /* @__PURE__ */ React.createElement(
    RangeInput,
    {
      value: data.styles.sectionPadding,
      onChange: (e) => {
        styleDataUpdate({ sectionPadding: e });
      },
      label: "Section Padding"
    }
  ), /* @__PURE__ */ React.createElement(
    RangeInput,
    {
      value: data.styles.columnPadding,
      onChange: (e) => {
        styleDataUpdate({ columnPadding: e });
      },
      label: "Column Padding"
    }
  ), /* @__PURE__ */ React.createElement(
    EditInputDropdown,
    {
      placeholder: "Select alignment",
      defaultValue: getSelectedJustifyContent(),
      onChange: (e) => {
        styleDataUpdate({ justifyContent: e.target.value });
      },
      options: justifyContentOptions,
      label: "Alignment"
    }
  ), colorInputs.map(
    (e, i) => renderEditColorInput(
      data.styles[e.property],
      (v) => {
        updateBackgroundColor(v, i);
        styleDataUpdate({ [e.property]: v });
      },
      e.label,
      i
    )
  ), /* @__PURE__ */ React.createElement(
    EditUploadDesign,
    {
      dataUpdate,
      styleDataUpdate,
      data,
      isPaddingDisabled: true
    }
  ));
};
export default MultiColumnEditStyles;
