import { SocialFeedsSource, SocialFeedsTypes } from "../../../types/social-feeds";
export const SocialFeedsSourceTranslate = {
  [SocialFeedsSource.INSTAGRAM]: "Instagram",
  [SocialFeedsSource.INSTAGRAM_BUSINESS]: "Instagram",
  [SocialFeedsSource.TIK_TOK]: "TikTok",
  [SocialFeedsSource.RSS]: "RSS",
  [SocialFeedsSource.YOUTUBE]: "YouTube",
  [SocialFeedsSource.PINTEREST]: "Pinterest",
  [SocialFeedsSource.LINKED_IN]: "LinkedIn",
  [SocialFeedsSource.CUSTOM]: "Custom Posts",
  [SocialFeedsSource.PUBLIC_POST]: "Public Posting",
  [SocialFeedsSource.WEBEX]: "WebEx",
  [SocialFeedsSource.REVIEW_TRACKERS]: "Review Trackers",
  [SocialFeedsSource.FORM_SUBMISSION]: "Form Submission",
  [SocialFeedsTypes.MENTIONED]: "Mentions",
  [SocialFeedsTypes.COMMENTS]: "Comment Mentions",
  [SocialFeedsSource.EXTERNALLY_SOURCED_POSTS]: "Browser Extensions",
  [SocialFeedsSource.CREATOR_IQ]: "Creator IQ"
};
export const SocialFeedsTypesTranslate = {
  [SocialFeedsSource.TWITTER]: {
    [SocialFeedsTypes.TWITTER_GEOCODE]: "Advanced"
  },
  [SocialFeedsSource.FLICKR]: {
    [SocialFeedsTypes.FLICKR_HASHTAG]: "Tag",
    [SocialFeedsTypes.FLICKR_USER]: "Username"
  }
};
