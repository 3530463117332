import { colors } from "../../../utils/variables/colors";
export const preloadersConfig = {
  tagTypes: {
    rect: "rect",
    circle: "circle"
  },
  fillColors: {
    primary: colors.lightGray,
    secondary: colors.semiLightGray,
    white: colors.white
  },
  classes: {
    TABLE_ROW: "content-loader--table-row",
    ASSET_CARD: "content-loader--asset-card",
    CALENDAR_CARD: "content-loader--calendar-card",
    FEED_CARD: "content-loader--feed-card",
    FEED_CARD_HORIZONTAL: "content-loader--feed-card-horizontal",
    FORMS: "content-loader--forms",
    FORMS_SUBMISSION: "content-loader--forms-submission",
    FORMS_SIDEBAR_ELEMENT: "content-loader--forms-sidebar-element"
  }
};
