export const singleMatrix = {
  SINGLE_MATRIX: ".input-single-matrix",
  SINGLE_MATRIX_LEGEND: ".input-single-matrix legend",
  SINGLE_MATRIX_TABLE: ".input-single-matrix table",
  SINGLE_MATRIX_TBODY_TD: ".input-single-matrix tbody td",
  SINGLE_MATRIX_TBODY_TH: ".input-single-matrix tbody th",
  SINGLE_MATRIX_THEAD_TH: ".input-single-matrix thead th",
  SINGLE_MATRIX_TH_TD: ".input-single-matrix th, .input-single-matrix td",
  SINGLE_MATRIX_INPUT: ".input-single-matrix input",
  SINGLE_MATRIX_INPUT_CHECKED: ".input-single-matrix input:checked",
  SINGLE_MATRIX_RTL: "*[dir='rtl'] .input-single-matrix thead th"
};
