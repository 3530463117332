import React, { useRef, useState, useEffect } from "react";
import "./wysiwyg-navigation-container.sass";
import WysiwygNavigation from "./wysiwyg-navigation";
import { commandIdList } from "./wysiwyg-navigation-options";
import CustomFontFamilyDropdown from "../../../interface/inputs/custom-font-family/custom-font-family";
import FontSizeDropdown from "../../../interface/inputs/font-size/font-size";
import * as PropTypes from "prop-types";
import useClickOutside from "../../../hooks/use-click-outside/use-click-outside";
import Color from "../../../../services/color-parser/color-parser";
import ColorPicker from "../../../interface/inputs/color-picker/color-picker";
import AddLink from "../../../interface/inputs/add-link/add-link";
import { faCode } from "@fortawesome/pro-solid-svg-icons";
import TintButtonIcon, { TintButtonIconTypes } from "../../../interface/button-icon/button-icon";
const WysiwygNavigationContainer = ({
  isFontFamilyDropdownVisible = true,
  onNavigationClick,
  valueProperties,
  onSaveSelection,
  getPrevSelection,
  onCodeModeClick
}) => {
  const [isLinkInputVisible, setLinkInputVisibility] = useState();
  const [isCodeMode, setCodeMode] = useState(false);
  const [isColorPickerVisible, setColorPickerVisibility] = useState();
  const [isBackgroundColorPickerVisible, setBackgroundColorPickerVisibility] = useState();
  const isSelectText = () => {
    let ranges = [];
    let sel = window.getSelection();
    for (let i = 0; i < sel.rangeCount; i++) {
      ranges[i] = sel.getRangeAt(i);
    }
    return ranges[0] && ranges[0].toString().length === 0;
  };
  const isNavDisabled = isSelectText();
  const node = useRef();
  const [isActive, setStatus] = useClickOutside(node);
  const onItemClick = (commandId, value) => {
    switch (commandId) {
      case commandIdList.LINK:
        onSaveSelection();
        setLinkInputVisibility(true);
        break;
      case commandIdList.BACKGROUND_COLOR:
        onSaveSelection();
        setBackgroundColorPickerVisibility(true);
        setColorPickerVisibility(false);
        setStatus(true);
        break;
      case commandIdList.COLOR:
        onSaveSelection();
        setColorPickerVisibility(true);
        setBackgroundColorPickerVisibility(false);
        setStatus(true);
        break;
      case commandIdList.FONT_SIZE:
      case commandIdList.FONT_FAMILY:
        if (isNavDisabled) return;
        onNavigationClick(commandId, value);
        break;
      default:
        onNavigationClick(commandId);
        return;
    }
  };
  const onLinkChange = (link) => {
    onNavigationClick(commandIdList.LINK, link.urlLink);
  };
  const onColorChange = (color, commandId) => {
    onNavigationClick(commandId, color);
  };
  const onNavClick = (e) => {
    if (e.target.nodeName !== "BUTTON" && e.target.nodeName !== "SELECT" && e.target.nodeName !== "SPAN" && e.target.nodeName !== "INPUT") {
      getPrevSelection();
    }
  };
  const [currentBackgroundColors, setCurrentBackgroundColor] = useState(valueProperties);
  useEffect(() => {
    setCurrentBackgroundColor(valueProperties.backgroundColor);
  }, [valueProperties.backgroundColor]);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation-container", onClick: onNavClick }, isFontFamilyDropdownVisible && /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation-container__font-family" }, /* @__PURE__ */ React.createElement(
    CustomFontFamilyDropdown,
    {
      fontFamily: valueProperties.fontFamily,
      styleUpdate: (font) => {
        onItemClick("fontName", font.fontFamily.value);
      },
      onChange: () => {
        if (isNavDisabled) return;
      }
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation-container__font-size" }, /* @__PURE__ */ React.createElement(
    FontSizeDropdown,
    {
      fontSize: valueProperties.fontSize,
      styleUpdate: (fontSize) => {
        onItemClick("fontSize", fontSize);
      }
    }
  )), /* @__PURE__ */ React.createElement("div", { ref: node, className: "tint-wysiwyg-navigation-container__color-picker" }, isColorPickerVisible && isActive && /* @__PURE__ */ React.createElement(
    ColorPicker,
    {
      color: valueProperties.color,
      onColorChange: (color) => {
        onColorChange(Color.defaultColor(color), commandIdList.COLOR);
      }
    }
  ), isBackgroundColorPickerVisible && isActive && /* @__PURE__ */ React.createElement(
    ColorPicker,
    {
      color: currentBackgroundColors,
      onColorChange: (color) => {
        setCurrentBackgroundColor(color);
        onColorChange(Color.defaultColor(color), commandIdList.BACKGROUND_COLOR);
      }
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation-container__font-style-general" }, /* @__PURE__ */ React.createElement(WysiwygNavigation, { onNavElementClick: onItemClick, style: valueProperties })), isLinkInputVisible && /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation-container__link" }, /* @__PURE__ */ React.createElement(AddLink, { urlLink: valueProperties.link, onClose: setLinkInputVisibility, dataUpdate: onLinkChange })), onCodeModeClick && /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation-container__code" }, /* @__PURE__ */ React.createElement(
    TintButtonIcon,
    {
      icon: faCode,
      type: TintButtonIconTypes.NONE,
      color: isCodeMode ? "#000" : "#b6b8bb",
      onClick: () => {
        onCodeModeClick();
        setCodeMode((s) => !s);
      }
    }
  )));
};
export default WysiwygNavigationContainer;
WysiwygNavigationContainer.propTypes = {
  onNavigationClick: PropTypes.func,
  valueProperties: PropTypes.object,
  onSaveSelection: PropTypes.func
};
