var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./overview-footer.sass";
import { useParams } from "react-router-dom";
import { Button } from "../../../../components/interface/button/button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import DropdownClickList from "../../../../components/interface/dropdown/lists/click-list/dropdown-click-list";
import { removePublishedAtFormData, setSubmitType, updateFormData } from "../../actions/media-composer.actions";
import { MediaComposerValidatorService } from "../../services/media-composer-validator.service";
import FeatureGuard from "../../../../guards/feature-guard";
import { GuardNames } from "../../../../guards/guards-list";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType
} from "../../../../components/interface/preloaders/preloader/preloader";
import { NETWORK_TYPE } from "../modal/add-network/media-composer-add-network-modal.data";
export const SubmitType = {
  SCHEDULE: "SCHEDULE",
  NOW: "NOW"
};
export const OverviewFooter = ({ onSubmit, onClose, isMediaComposer, isEditMode, isValid }) => {
  var _a, _b, _c, _d;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { assetId } = useParams();
  const dispatch = useDispatch();
  const mediaComposerData = useSelector((state) => state.mediaComposer);
  const isFetching = useSelector((state) => state.socialPublishingCalendar.isPartialFetching);
  const form = mediaComposerData.form;
  const submitType = mediaComposerData.submitType;
  const isThreadsNetwork = ((_b = (_a = mediaComposerData == null ? void 0 : mediaComposerData.selectedAccounts) == null ? void 0 : _a[0]) == null ? void 0 : _b.type) === NETWORK_TYPE.THREADS;
  const isValidNetworkLimit = (mediaComposerData == null ? void 0 : mediaComposerData.selectedAccounts.length) > 0 && (mediaComposerData == null ? void 0 : mediaComposerData.selectedAccounts.length) <= 3;
  const isValidMedia = ((_d = (_c = form == null ? void 0 : form.media) == null ? void 0 : _c.filter(Boolean)) == null ? void 0 : _d.length) > 0;
  const isValidThreadPost = isValidNetworkLimit && ((form == null ? void 0 : form.text) || isValidMedia);
  useEffect(() => {
    setIsButtonDisabled(isButtonDisabledFn());
  }, [mediaComposerData.form]);
  const _onSubmit = () => {
    const timeNow = new Date(Date.now());
    const currentDate = timeNow.setSeconds(timeNow.getSeconds() + 10);
    const data = mediaComposerData.selectedAccounts.map((e) => {
      var _a2, _b2, _c2, _d2, _e, _f;
      const obj = __spreadProps(__spreadValues({
        account_id: e.account_id
      }, form), {
        asset_id: assetId,
        media: ((_b2 = (_a2 = mediaComposerData == null ? void 0 : mediaComposerData.form) == null ? void 0 : _a2.media) == null ? void 0 : _b2.filter(Boolean)) || [],
        campaign_id: mediaComposerData.form.campaignId,
        campaignId: void 0,
        published_at: mediaComposerData.submitType === SubmitType.NOW ? new Date(currentDate) : form.published_at
      });
      if (e.subaccount_id) {
        obj.subaccount_id = e.subaccount_id;
      }
      if ((_c2 = mediaComposerData == null ? void 0 : mediaComposerData.form) == null ? void 0 : _c2.privacy) {
        obj.privacy = (_d2 = mediaComposerData == null ? void 0 : mediaComposerData.form) == null ? void 0 : _d2.privacy;
      }
      if ((_e = mediaComposerData == null ? void 0 : mediaComposerData.form) == null ? void 0 : _e.options) {
        obj.options = (_f = mediaComposerData == null ? void 0 : mediaComposerData.form) == null ? void 0 : _f.options;
      }
      return obj;
    });
    onSubmit(isMediaComposer ? data : data[0], isEditMode, submitType);
  };
  const renderSubmitButton = () => {
    let text = "";
    switch (submitType) {
      case SubmitType.SCHEDULE:
        text = "Schedule For Later";
        break;
      case SubmitType.NOW:
      default:
        text = "Post now";
    }
    return /* @__PURE__ */ React.createElement(Button, { isDisabled: isButtonDisabled, text, type: "info", onClick: _onSubmit });
  };
  const setSubmitButton = ({ value }) => {
    switch (value) {
      case SubmitType.SCHEDULE:
        dispatch(updateFormData({ value: (/* @__PURE__ */ new Date()).toISOString(), field: "publish_at" }));
        break;
      case SubmitType.NOW:
      default:
        dispatch(removePublishedAtFormData());
    }
    dispatch(setSubmitType(value));
  };
  const isButtonDisabledFn = () => {
    return !MediaComposerValidatorService.isScheduleBetweenLimit(form.date);
  };
  const isFormValid = isThreadsNetwork ? isValidThreadPost : (form == null ? void 0 : form.text) && isValid && isValidMedia && isValidNetworkLimit;
  return /* @__PURE__ */ React.createElement("div", { className: "tint-overview-footer" }, /* @__PURE__ */ React.createElement(Button, { text: "Cancel", type: "transparent", onClick: onClose }), /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.ASSET_MARKETING_INTEGRATION }, isMediaComposer ? /* @__PURE__ */ React.createElement("div", { className: "tint-overview-footer__right" }, renderSubmitButton(), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      disabled: isButtonDisabled,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadProps(__spreadValues({}, props), { dropdownHeaderStyle: { justifyContent: "center" } })),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownClickList, __spreadValues({ expandToTop: true }, props)),
      list: [
        { value: SubmitType.SCHEDULE, name: "Schedule For Later" },
        { value: SubmitType.NOW, name: "Post now" }
      ],
      iconCenter: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_ACTIVE,
      onChangeValue: setSubmitButton
    }
  )) : /* @__PURE__ */ React.createElement(Button, { className: "edit-mode", isDisabled: !isFormValid || isFetching, type: "info", onClick: _onSubmit }, isFetching && /* @__PURE__ */ React.createElement(
    PreloaderComponent,
    {
      positionType: PreloaderPositionType.ABSOLUTE,
      backdropType: PreloaderBackdropType.TRANSPARENT
    }
  ), isEditMode ? "Update" : "Post")));
};
