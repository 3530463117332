import React from "react";
import "./edit-modal-body.sass";
import PropTypes from "prop-types";
export const EditModalBody = ({ title, titleClass, children }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body" }, title ? /* @__PURE__ */ React.createElement("span", { className: `tint-edit-modal-body__title tint-edit-modal-body__title--${titleClass}` }, title) : null, children);
};
EditModalBody.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string
};
