import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./avatar-uploader.sass";
import { FileStack } from "../../../services/filestack/file-stack";
import { getMaxImageSizePlanLimit } from "../../../reducers/session";
import { useUploader } from "../../../components/hooks/use-uploader/use-uploader";
import { UploadServiceURLs } from "../../../services/upload/upload.service";
import InputFile from "../../../components/interface/inputs/input-file/input-file";
import { toast } from "react-toastify";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType
} from "../../../components/interface/preloaders/preloader/preloader";
const AvatarUploaderStatus = {
  EMPTY: "empty",
  UPLOADED: "uploaded"
};
const AvatarUploader = ({ imageSrc, onAvatarChange, useFileStack = true, size = 64, children }) => {
  const [imageUrl, setImageUrl] = useState(imageSrc);
  const [uploaderStatus, setUploaderStatus] = useState(AvatarUploaderStatus.EMPTY);
  const [buttonText, setButtonText] = useState("Upload Photo");
  const maxImageSizePlanLimit = useSelector(getMaxImageSizePlanLimit);
  useEffect(() => {
    setImageUrl(imageSrc);
  }, [imageSrc, imageUrl]);
  const onUploadError = () => {
    toast.error("Cannot upload an image");
  };
  const onUploadSuccess = (files) => {
    var _a;
    if (!(files == null ? void 0 : files[0])) {
      return;
    }
    setImageUrl((_a = files[0]) == null ? void 0 : _a.preview);
    onAvatarChange(files[0]);
  };
  const { onUploadFiles, isPending } = useUploader({
    onUploadSuccess,
    onUploadError,
    url: UploadServiceURLs.POSTS
  });
  const openFilePicker = () => {
    const fileStack = new FileStack();
    fileStack.openFilePicker({
      accept: ["image/*"],
      maxSize: maxImageSizePlanLimit,
      transformations: {
        crop: {
          force: true,
          aspectRatio: 1
        },
        circle: false
      },
      onUploadDone: (res) => {
        var _a;
        if (res.filesUploaded.length > 0) {
          setImageSrc((_a = res.filesUploaded[0]) == null ? void 0 : _a.url);
        }
        setUploaderStatus(AvatarUploaderStatus.UPLOADED);
        setButtonText("Remove Photo");
      }
    });
  };
  const onUploadPhoto = (value) => {
    if (value) {
      if (useFileStack) {
        setImageSrc(value);
        setUploaderStatus(AvatarUploaderStatus.UPLOADED);
        setButtonText("Remove Photo");
      } else {
        onUploadFiles(value.target.files);
      }
    }
  };
  const setImageSrc = (url) => {
    setImageUrl(url);
    onAvatarChange(url);
  };
  const handleButton = () => {
    switch (uploaderStatus) {
      case AvatarUploaderStatus.EMPTY:
        openFilePicker();
        break;
      case AvatarUploaderStatus.UPLOADED:
        setImageSrc(null);
        setUploaderStatus(AvatarUploaderStatus.EMPTY);
        setButtonText("Upload Photo");
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-avatar-uploader" }, isPending && /* @__PURE__ */ React.createElement(
    PreloaderComponent,
    {
      positionType: PreloaderPositionType.ABSOLUTE,
      backdropType: PreloaderBackdropType.TRANSPARENT
    }
  ), /* @__PURE__ */ React.createElement(InputFile, { useFileStack, accept: "image/*", label: "Upload Photo", handleChange: onUploadPhoto }, /* @__PURE__ */ React.createElement("div", { className: "tint-avatar-uploader__thumbnail", style: { width: size, height: size } }, imageUrl ? /* @__PURE__ */ React.createElement("img", { src: imageUrl }) : /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-user" }))), /* @__PURE__ */ React.createElement("div", { className: "tint-avatar-uploader__button" }, React.Children.map(
    children,
    (child) => React.cloneElement(child, {
      onClick: handleButton,
      text: buttonText
    })
  )));
};
export default AvatarUploader;
