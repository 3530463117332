var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./form-builder-section.sass";
import { ConnectFormBuilderBox } from "./connect-form-builder-box/connect-form-builder-box";
import { EditFormBuilderContent } from "./edit/edit-form-builder-content";
import { EditFormBuilderStyles } from "./edit/edit-form-builder-styles";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { connect } from "react-redux";
import { Config } from "../../../../../../utils/config";
class FormBuilderSection extends Component {
  constructor(props, context) {
    super(props, context);
    __publicField(this, "renderFormBuilder", () => {
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          id: "tint-form-builder",
          "data-team-id": this.props.data.content.teamId,
          "data-form-id": this.props.data.content.formBuilderId
        }
      );
    });
  }
  initFormBuilderScript() {
    var _a, _b, _c;
    let src = `${Config.getEnvironmentVariable("BASE_URL")}/dist/tint.js`;
    const existingScripts = document.getElementsByTagName("script");
    let scriptExists = false;
    for (let i = 0; i < existingScripts.length; i++) {
      if (existingScripts[i].src === src) {
        scriptExists = true;
        break;
      }
    }
    if (!scriptExists) {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        var _a2, _b2, _c2;
        (_c2 = (_b2 = (_a2 = window == null ? void 0 : window.Tint) == null ? void 0 : _a2.FormBuilder) == null ? void 0 : _b2.renderForms) == null ? void 0 : _c2.call(_b2);
      };
      document.body.appendChild(script);
    } else {
      (_c = (_b = (_a = window == null ? void 0 : window.Tint) == null ? void 0 : _a.FormBuilder) == null ? void 0 : _b.renderForms) == null ? void 0 : _c.call(_b);
    }
  }
  reRenderFormBuilder() {
    if (document.querySelector("script[src*='/dist/tint.js']")) {
      document.querySelector("script[src*='/dist/tint.js']").remove();
      this.initFormBuilderScript();
    }
  }
  componentDidMount() {
    this.initFormBuilderScript();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data.content.formBuilderId !== this.props.data.content.formBuilderId) {
      this.reRenderFormBuilder();
    }
  }
  getRectangleBox(isSmallFirst) {
    return /* @__PURE__ */ React.createElement("div", { className: "rectangle-container__rectangle-box" }, /* @__PURE__ */ React.createElement("div", { className: `rectangle ${isSmallFirst ? "rectangle--small" : ""}` }), /* @__PURE__ */ React.createElement("div", { className: `rectangle ${!isSmallFirst ? "rectangle--small" : ""}` }));
  }
  renderContent() {
    return /* @__PURE__ */ React.createElement("div", { className: "rectangle-container" }, /* @__PURE__ */ React.createElement(ConnectFormBuilderBox, { onClick: this.props.openModal.bind(this), text: "+ Connect Form Builder" }), this.getRectangleBox(true), this.getRectangleBox(false), this.getRectangleBox(true), this.getRectangleBox(false), this.getRectangleBox(true));
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        dir: "auto",
        className: `tint-page-section tint-page-section--form-builder ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
          display: !this.props.data.content.formBuilderId && this.props.isPreviewMode ? "none" : ""
        }
      },
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          closeModal: this.props.closeModal,
          dismissModal: this.props.dismissModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Form" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditFormBuilderContent,
          {
            updateForm: this.onDataSave,
            data: this.props.data,
            dataUpdate: this.props.onContentUpdate
          }
        ), /* @__PURE__ */ React.createElement(
          EditFormBuilderStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      ),
      this.props.data.content.formBuilderId ? this.renderFormBuilder() : this.renderContent()
    );
  }
}
const mapStateToProps = (state) => ({
  session: state.session.data
});
export default connect(mapStateToProps)(PageSectionHoc(FormBuilderSection));
