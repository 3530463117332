var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS as GLOBAL_INSIGHTS_ACTIONS } from "../actions/content/insights-content.actions";
import { ACTIONS } from "../actions/commerce/insights-commerce.actions";
import { produce } from "immer";
import { eachDayOfInterval, format } from "date-fns";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const initialState = {
  ordersCount: {
    data: void 0,
    isFetching: false
  },
  ordersSales: {
    data: void 0,
    isFetching: false
  },
  aovAfterEngagingFromEmbed: {
    data: void 0,
    isFetching: false
  },
  aovNotEngagingWithTint: {
    data: void 0,
    isFetching: false
  },
  aosAfterEngagingFromEmbed: {
    data: void 0,
    isFetching: false
  },
  aosNotEngagingWithTint: {
    data: void 0,
    isFetching: false
  },
  productionConversionRate: {
    data: void 0,
    isFetching: false
  },
  totalUniqueUsersClicked: {
    data: void 0,
    isFetching: false
  },
  assistedOrderCount: {
    data: void 0,
    isFetching: false
  },
  totalOrderCount: {
    data: void 0,
    isFetching: false
  },
  totalProduct: {
    data: void 0,
    isFetching: false
  },
  totalRevenue: {
    data: void 0,
    isFetching: false
  },
  topEmbedClickedPosts: {
    data: void 0,
    isFetching: false
  },
  postConversion: {
    data: void 0,
    isFetching: false
  },
  postClicks: {
    data: void 0,
    isFetching: false
  },
  pageLoads: {
    data: void 0,
    isFetching: false
  },
  embedPostProductClicked: {
    data: void 0,
    isFetching: false
  },
  addToCart: {
    data: void 0,
    isFetching: false
  },
  conversion: {
    data: void 0,
    isFetching: false
  }
};
export const insightsCommerce = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_INSIGHTS_ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;
    case ACTIONS.GET_PAGE_LOADS.FAILURE:
      return produce(state, (draftState) => {
        draftState.pageLoads.isFetching = false;
      });
    case ACTIONS.GET_PAGE_LOADS.REQUEST:
      return produce(state, (draftState) => {
        draftState.pageLoads.isFetching = true;
      });
    case ACTIONS.GET_PAGE_LOADS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.pageLoads.isFetching = false;
        draftState.pageLoads.data = action.payload;
      });
    case ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.FAILURE:
      return produce(state, (draftState) => {
        draftState.embedPostProductClicked.isFetching = false;
      });
    case ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.REQUEST:
      return produce(state, (draftState) => {
        draftState.embedPostProductClicked.isFetching = true;
      });
    case ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.embedPostProductClicked.isFetching = false;
        draftState.embedPostProductClicked.data = action.payload;
      });
    case ACTIONS.GET_ADD_TO_CART.FAILURE:
      return produce(state, (draftState) => {
        draftState.addToCart.isFetching = false;
      });
    case ACTIONS.GET_ADD_TO_CART.REQUEST:
      return produce(state, (draftState) => {
        draftState.addToCart.isFetching = true;
      });
    case ACTIONS.GET_ADD_TO_CART.SUCCESS:
      return produce(state, (draftState) => {
        draftState.addToCart.isFetching = false;
        draftState.addToCart.data = action.payload;
      });
    case ACTIONS.GET_CONVERSION.FAILURE:
      return produce(state, (draftState) => {
        draftState.conversion.isFetching = false;
      });
    case ACTIONS.GET_CONVERSION.REQUEST:
      return produce(state, (draftState) => {
        draftState.conversion.isFetching = true;
      });
    case ACTIONS.GET_CONVERSION.SUCCESS:
      return produce(state, (draftState) => {
        draftState.conversion.isFetching = false;
        draftState.conversion.data = action.payload;
      });
    case ACTIONS.GET_POST_CLICKS.FAILURE:
      return produce(state, (draftState) => {
        draftState.postClicks.isFetching = false;
      });
    case ACTIONS.GET_POST_CLICKS.REQUEST:
      return produce(state, (draftState) => {
        draftState.postClicks.isFetching = true;
      });
    case ACTIONS.GET_POST_CLICKS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.postClicks.isFetching = false;
        if (Array.isArray(draftState.postClicks.data)) {
          draftState.postClicks.data.push(action.payload);
        } else {
          draftState.postClicks.data = [action.payload];
        }
      });
    case ACTIONS.GET_POST_CONVERSION.FAILURE:
      return produce(state, (draftState) => {
        draftState.postConversion.isFetching = false;
      });
    case ACTIONS.GET_POST_CONVERSION.REQUEST:
      return produce(state, (draftState) => {
        draftState.postConversion.isFetching = true;
      });
    case ACTIONS.GET_POST_CONVERSION.SUCCESS:
      return produce(state, (draftState) => {
        draftState.postConversion.isFetching = false;
        if (Array.isArray(draftState.postConversion.data)) {
          draftState.postConversion.data.push(action.payload);
        } else {
          draftState.postConversion.data = [action.payload];
        }
      });
    case ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.FAILURE:
      return produce(state, (draftState) => {
        draftState.topEmbedClickedPosts.isFetching = false;
      });
    case ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.REQUEST:
      return produce(state, (draftState) => {
        draftState.topEmbedClickedPosts.isFetching = true;
      });
    case ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topEmbedClickedPosts.isFetching = false;
        draftState.topEmbedClickedPosts.data = action.payload;
      });
    case ACTIONS.GET_TOTAL_REVENUE.FAILURE:
      return produce(state, (draftState) => {
        draftState.totalRevenue.isFetching = false;
      });
    case ACTIONS.GET_TOTAL_REVENUE.REQUEST:
      return produce(state, (draftState) => {
        draftState.totalRevenue.isFetching = true;
      });
    case ACTIONS.GET_TOTAL_REVENUE.SUCCESS:
      return produce(state, (draftState) => {
        draftState.totalRevenue.isFetching = false;
        draftState.totalRevenue.data = action.payload;
      });
    case ACTIONS.GET_TOTAL_PRODUCT.FAILURE:
      return produce(state, (draftState) => {
        draftState.totalProduct.isFetching = false;
      });
    case ACTIONS.GET_TOTAL_PRODUCT.REQUEST:
      return produce(state, (draftState) => {
        draftState.totalProduct.isFetching = true;
      });
    case ACTIONS.GET_TOTAL_PRODUCT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.totalProduct.isFetching = false;
        draftState.totalProduct.data = action.payload;
      });
    case ACTIONS.GET_ASSISTED_ORDER_COUNT.FAILURE:
      return produce(state, (draftState) => {
        draftState.assistedOrderCount.isFetching = false;
      });
    case ACTIONS.GET_ASSISTED_ORDER_COUNT.REQUEST:
      return produce(state, (draftState) => {
        draftState.assistedOrderCount.isFetching = true;
      });
    case ACTIONS.GET_ASSISTED_ORDER_COUNT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.assistedOrderCount.isFetching = false;
        draftState.assistedOrderCount.data = action.payload;
      });
    case ACTIONS.GET_TOTAL_ORDER_COUNT.FAILURE:
      return produce(state, (draftState) => {
        draftState.totalOrderCount.isFetching = false;
      });
    case ACTIONS.GET_TOTAL_ORDER_COUNT.REQUEST:
      return produce(state, (draftState) => {
        draftState.totalOrderCount.isFetching = true;
      });
    case ACTIONS.GET_TOTAL_ORDER_COUNT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.totalOrderCount.isFetching = false;
        draftState.totalOrderCount.data = action.payload;
      });
    case ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.FAILURE:
      return produce(state, (draftState) => {
        draftState.totalUniqueUsersClicked.isFetching = false;
      });
    case ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.REQUEST:
      return produce(state, (draftState) => {
        draftState.totalUniqueUsersClicked.isFetching = true;
      });
    case ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.totalUniqueUsersClicked.isFetching = false;
        draftState.totalUniqueUsersClicked.data = action.payload;
      });
    case ACTIONS.GET_PRODUCTION_CONVERSION_RATE.FAILURE:
      return produce(state, (draftState) => {
        draftState.productionConversionRate.isFetching = false;
      });
    case ACTIONS.GET_PRODUCTION_CONVERSION_RATE.REQUEST:
      return produce(state, (draftState) => {
        draftState.productionConversionRate.isFetching = true;
      });
    case ACTIONS.GET_PRODUCTION_CONVERSION_RATE.SUCCESS:
      return produce(state, (draftState) => {
        draftState.productionConversionRate.isFetching = false;
        draftState.productionConversionRate.data = action.payload;
      });
    case ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.FAILURE:
      return produce(state, (draftState) => {
        draftState.aosAfterEngagingFromEmbed.isFetching = false;
      });
    case ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.REQUEST:
      return produce(state, (draftState) => {
        draftState.aosAfterEngagingFromEmbed.isFetching = true;
      });
    case ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.aosAfterEngagingFromEmbed.isFetching = false;
        draftState.aosAfterEngagingFromEmbed.data = action.payload;
      });
    case ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.FAILURE:
      return produce(state, (draftState) => {
        draftState.aovAfterEngagingFromEmbed.isFetching = false;
      });
    case ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.REQUEST:
      return produce(state, (draftState) => {
        draftState.aovAfterEngagingFromEmbed.isFetching = true;
      });
    case ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.aovAfterEngagingFromEmbed.isFetching = false;
        draftState.aovAfterEngagingFromEmbed.data = action.payload;
      });
    case ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.FAILURE:
      return produce(state, (draftState) => {
        draftState.aovNotEngagingWithTint.isFetching = false;
      });
    case ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.REQUEST:
      return produce(state, (draftState) => {
        draftState.aovNotEngagingWithTint.isFetching = true;
      });
    case ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.aovNotEngagingWithTint.isFetching = false;
        draftState.aovNotEngagingWithTint.data = action.payload;
      });
    case ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.FAILURE:
      return produce(state, (draftState) => {
        draftState.aosNotEngagingWithTint.isFetching = false;
      });
    case ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.REQUEST:
      return produce(state, (draftState) => {
        draftState.aosNotEngagingWithTint.isFetching = true;
      });
    case ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.aosNotEngagingWithTint.isFetching = false;
        draftState.aosNotEngagingWithTint.data = action.payload;
      });
    case ACTIONS.GET_ORDER_SALES.FAILURE:
      return produce(state, (draftState) => {
        draftState.ordersSales.isFetching = false;
      });
    case ACTIONS.GET_ORDER_SALES.REQUEST:
      return produce(state, (draftState) => {
        draftState.ordersSales.isFetching = true;
      });
    case ACTIONS.GET_ORDER_SALES.SUCCESS:
      return produce(state, (draftState) => {
        draftState.ordersSales.isFetching = false;
        draftState.ordersSales.data = action.payload;
      });
    case ACTIONS.GET_ORDER_COUNT.FAILURE:
      return produce(state, (draftState) => {
        draftState.ordersCount.isFetching = false;
      });
    case ACTIONS.GET_ORDER_COUNT.REQUEST:
      return produce(state, (draftState) => {
        draftState.ordersCount.isFetching = true;
      });
    case ACTIONS.GET_ORDER_COUNT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.ordersCount.isFetching = false;
        draftState.ordersCount.data = action.payload;
      });
    default:
      return state;
  }
};
export const getConversionFunnelData = (state) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  if (!state || !state.pageLoads.data || !state.embedPostProductClicked.data || !state.addToCart.data || !state.conversion.data) {
    return [];
  }
  const pageLoads = ((_b = (_a = state.pageLoads.data.data[0]) == null ? void 0 : _a.attributes) == null ? void 0 : _b.count) || 0;
  const productClicked = ((_d = (_c = state.embedPostProductClicked.data.data[0]) == null ? void 0 : _c.attributes) == null ? void 0 : _d.count) || 0;
  const addToCart = ((_f = (_e = state.addToCart.data.data[0]) == null ? void 0 : _e.attributes) == null ? void 0 : _f.aggregate) || 0;
  const conversion = ((_h = (_g = state.conversion.data.data[0]) == null ? void 0 : _g.attributes) == null ? void 0 : _h.aggregate) || 0;
  return {
    data: [{ amount: pageLoads }, { amount: productClicked }, { amount: addToCart }, { amount: conversion }],
    pageLoads,
    productClicked,
    addToCart,
    conversion
  };
};
export const getConversionDetailsData = (insightsState) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  if (!insightsState || !insightsState.ordersCount.data || !insightsState.ordersSales.data) {
    return { data: [] };
  }
  let assistedOrders = 0;
  insightsState.ordersCount.data.data.map((e) => {
    assistedOrders += e.attributes.aggregate;
  });
  let aovAfterEngagingFromEmbed = 0;
  (_b = (_a = insightsState.aovAfterEngagingFromEmbed) == null ? void 0 : _a.data) == null ? void 0 : _b.data.map((e) => {
    aovAfterEngagingFromEmbed += e.attributes.aggregate;
  });
  let aovNotEngagingWithTint = 0;
  (_d = (_c = insightsState.aovNotEngagingWithTint) == null ? void 0 : _c.data) == null ? void 0 : _d.data.map((e) => {
    aovNotEngagingWithTint += e.attributes.aggregate;
  });
  let aosAfterEngagingFromEmbed = 0;
  (_f = (_e = insightsState.aosAfterEngagingFromEmbed) == null ? void 0 : _e.data) == null ? void 0 : _f.data.map((e) => {
    aosAfterEngagingFromEmbed += e.attributes.aggregate;
  });
  let aosNotEngagingWithTint = 0;
  (_h = (_g = insightsState.aosNotEngagingWithTint) == null ? void 0 : _g.data) == null ? void 0 : _h.data.map((e) => {
    aosNotEngagingWithTint += e.attributes.aggregate;
  });
  const orderSales = (_j = (_i = insightsState.ordersSales) == null ? void 0 : _i.data) == null ? void 0 : _j.data.reduce((acc, e) => acc + e.attributes.aggregate, 0);
  return {
    assistedOrders: assistedOrders || 0,
    orderSales: orderSales || 0,
    avgOrderValueUplift: aovAfterEngagingFromEmbed / aovNotEngagingWithTint || 0,
    avgOrderSizeValueUplift: aosAfterEngagingFromEmbed / aosNotEngagingWithTint || 0,
    avgOrderSize: aosAfterEngagingFromEmbed,
    avgOrderValue: aovAfterEngagingFromEmbed
  };
};
export const getProductConversionRateData = (insightsState, state) => {
  var _a;
  if (!insightsState || !insightsState.productionConversionRate.data || !insightsState.totalUniqueUsersClicked.data) {
    return { data: [] };
  }
  const { data } = (_a = insightsState.productionConversionRate) == null ? void 0 : _a.data;
  const _helperArr = data.map((e, i) => {
    var _a2, _b, _c, _d, _e;
    const totalUniqueUsers = (_d = (_c = (_b = (_a2 = insightsState.totalUniqueUsersClicked) == null ? void 0 : _a2.data) == null ? void 0 : _b.data[i]) == null ? void 0 : _c.attributes) == null ? void 0 : _d.aggregate;
    const time = new Date(e.attributes.dimensions.time);
    return {
      time,
      name: `${(_e = monthNames[time.getMonth()]) == null ? void 0 : _e.substring(0, 3)} ${time.getDate()}`,
      count: totalUniqueUsers ? Number(e.attributes.count / totalUniqueUsers * 100).toFixed(2) : 0
    };
  });
  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);
  return { data: getResultArray(dayArray, _helperArr) };
};
export const getAssistedOrderShareData = (insightsState, state) => {
  var _a;
  if (!insightsState || !insightsState.totalOrderCount.data || !insightsState.assistedOrderCount.data) {
    return { data: [] };
  }
  const { data } = (_a = insightsState.totalOrderCount) == null ? void 0 : _a.data;
  const _helperArr = data.map((e, i) => {
    var _a2, _b, _c, _d, _e;
    const assistedOrderCount = (_d = (_c = (_b = (_a2 = insightsState.assistedOrderCount) == null ? void 0 : _a2.data) == null ? void 0 : _b.data[i]) == null ? void 0 : _c.attributes) == null ? void 0 : _d.aggregate;
    const time = new Date(e.attributes.dimensions.time);
    return {
      time,
      name: `${(_e = monthNames[time.getMonth()]) == null ? void 0 : _e.substring(0, 3)} ${time.getDate()}`,
      count: assistedOrderCount ? Number(e.attributes.aggregate / assistedOrderCount * 100).toFixed(2) : 0
    };
  });
  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);
  return { data: getResultArray(dayArray, _helperArr) };
};
export const getTotalProductData = (insightsState, state) => {
  var _a;
  if (!insightsState || !insightsState.totalProduct.data) {
    return { data: [] };
  }
  const { data } = (_a = insightsState.totalProduct) == null ? void 0 : _a.data;
  const _helperArr = data.map((e) => {
    var _a2;
    const time = new Date(e.attributes.dimensions.time);
    return {
      time,
      name: `${(_a2 = monthNames[time.getMonth()]) == null ? void 0 : _a2.substring(0, 3)} ${time.getDate()}`,
      count: e.attributes.aggregate
    };
  });
  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);
  return { data: getResultArray(dayArray, _helperArr) };
};
export const getTotalRevenueData = (insightsState, state) => {
  var _a;
  if (!insightsState || !insightsState.totalRevenue.data) {
    return { data: [] };
  }
  const { data } = (_a = insightsState.totalRevenue) == null ? void 0 : _a.data;
  const _helperArr = data.map((e) => {
    var _a2;
    const time = new Date(e.attributes.dimensions.time);
    return {
      time,
      name: `${(_a2 = monthNames[time.getMonth()]) == null ? void 0 : _a2.substring(0, 3)} ${time.getDate()}`,
      count: e.attributes.aggregate
    };
  });
  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);
  return { data: getResultArray(dayArray, _helperArr) };
};
const getResultArray = (dayArray, valueArray) => {
  const _arr = dayArray.map((e) => {
    var _a;
    const time = new Date(e);
    const element = valueArray.find((f) => {
      return format(f.time, "yyyy-MM-dd") === e;
    });
    return {
      time,
      name: `${(_a = monthNames[time.getMonth()]) == null ? void 0 : _a.substring(0, 3)} ${time.getDate()}`,
      count: element ? Number(element.count) : 0
    };
  });
  return _arr;
};
const fillArrayWithDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dayArray = eachDayOfInterval({ start, end }).map((date) => format(date, "yyyy-MM-dd"));
  return dayArray;
};
export const getTopConvertingPostsData = (topEmbedClickedPosts, postConversions) => {
  if (!topEmbedClickedPosts.data) {
    return [];
  }
  const _data = topEmbedClickedPosts.data.map((e) => {
    const post = topEmbedClickedPosts.included.find((f) => f.id === e.relationships.post.data.id);
    const postConversion = postConversions.find((f) => f.postId === post.id);
    return __spreadProps(__spreadValues({}, post.attributes), {
      clicks: e.attributes.count,
      conversions: postConversion ? postConversion.data.data[0].attributes.aggregate : 0
    });
  });
  return _data;
};
