var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditInput, EditInputDropdown } from "../../../";
import "./edit-tint-content.sass";
import { ApiService } from "../../../../../../../services/api-service/api-service";
const clickForMore = {
  name: "Click for More Button",
  value: '{"data-clickformore":"true"}',
  description: "More posts will load on your TINT Board when a button is clicked."
};
const pageByPage = {
  name: "Page by Page",
  value: '{"data-paginate":"true"}',
  description: "A fixed number of posts is shown, with buttons to navigate from page to page."
};
const infinityScroll = {
  name: "Infinite Scroll",
  value: '{"data-infinitescroll":"true" }',
  description: "More posts will load automatically on your TINT Board as you scroll down."
};
const filters = {
  name: "Select Filter",
  value: "{}"
};
const TintIncludedType = {
  PERSONALIZATION: "personalization",
  SAVED_FILTER: "saved_filter"
};
export const defaultTintPersonalization = "Select Personalization";
export class EditTintContent extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "getTintDisplayTypes", () => this.state.isHeightInputDisplayed ? [clickForMore, pageByPage, infinityScroll] : [clickForMore, infinityScroll]);
    __publicField(this, "getSavedFiltersForTint", (tintSlug, tintsArg) => {
      const tints = tintsArg || this.state.tints;
      const selectedTint = tints.find((e) => e.attributes.slug === tintSlug);
      const tintPersonalizationsIdArray = selectedTint ? selectedTint.relationships.saved_filters.data.map((e) => e.id) : [];
      return this.savedFilters.filter((e) => tintPersonalizationsIdArray.includes(e.id)).map((e) => ({ value: e.id, name: e.attributes.name }));
    });
    this.personalizations = [];
    this.savedFilters = [];
    this.tintHeightType = [
      {
        name: "Fixed",
        value: "Fixed",
        isHeightInputDisplayed: true
      },
      {
        name: "Unlimited",
        value: "Unlimited",
        isHeightInputDisplayed: false
      }
    ];
    const heightType = this.tintHeightType.find((element) => element.value === this.props.data.content.heightType);
    this.state = {
      tints: [],
      savedFilters: [],
      personalizations: [],
      displayTypeDescription: "",
      isHeightInputDisplayed: heightType ? heightType.isHeightInputDisplayed : false
    };
  }
  componentWillMount() {
    ApiService.get("/tints?include=personalizations,saved_filters&page[size]=500").then((res) => {
      this.personalizations = res.data.included.filter((e) => e.type === TintIncludedType.PERSONALIZATION);
      this.savedFilters = res.data.included.filter((e) => e.type === TintIncludedType.SAVED_FILTER);
      const newState = { tints: res.data.data };
      newState.personalizations = this.props.data.content.tintName ? this.getPersonalizations(this.props.data.content.tintName, newState.tints) : [];
      newState.savedFilters = this.props.data.content.tintName ? this.getSavedFiltersForTint(this.props.data.content.tintName, newState.tints) : [];
      this.setState(newState);
    });
  }
  getTintNames() {
    return this.state.tints.map((e) => ({
      value: e.attributes.slug,
      name: e.attributes.slug
    }));
  }
  onTintSelect(event) {
    this.setState({
      personalizations: this.getPersonalizations(event.target.value),
      savedFilters: this.getSavedFiltersForTint(event.target.value)
    });
    return this.props.dataUpdate({ tintName: event.target.value });
  }
  getPersonalizations(tintSlug, tintsArg) {
    const tints = tintsArg || this.state.tints;
    const selectedTint = tints.find((e) => e.attributes.slug === tintSlug);
    const tintPersonalizationsIdArray = selectedTint ? selectedTint.relationships.personalizations.data.map((e) => e.id) : [];
    return this.personalizations.filter((e) => tintPersonalizationsIdArray.includes(e.id)).map((e) => ({ value: e.id, name: e.attributes.name }));
  }
  getSelectedTintName() {
    const tintName = this.props.data.content.tintName;
    return this.getTintNames().find((e) => e.name === tintName) ? tintName : "";
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Connect your TINT board by selecting a TINT board and a personalization from the lists below." }, /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        isControlled: true,
        placeholder: "Select TINT Board",
        value: this.getSelectedTintName(),
        onChange: (e) => {
          this.onTintSelect(e);
        },
        options: this.getTintNames(),
        label: "tint"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        isControlled: true,
        placeholder: defaultTintPersonalization,
        value: this.props.data.content.personalizationId,
        onChange: (e) => {
          this.props.dataUpdate({ personalizationId: e.target.value });
        },
        options: this.state.personalizations,
        label: "personalization"
      }
    ), /* @__PURE__ */ React.createElement("span", { className: "edit-tint-content__advanced-settings-title" }, "advanced settings"), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        defaultValue: this.props.data.content.heightType,
        onChange: (e) => {
          const val = e.target.value;
          const isHeightInputDisplayed = this.tintHeightType.find((element) => element.value === val).isHeightInputDisplayed;
          this.setState({ isHeightInputDisplayed });
          this.props.dataUpdate({ heightType: val });
        },
        options: this.tintHeightType,
        label: "height type"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        isInvisible: !this.state.isHeightInputDisplayed,
        onChange: (e) => {
          this.props.dataUpdate({ height: e.target.value });
        },
        label: "height",
        placeholder: "500",
        defaultValue: this.props.data.content.height
      }
    ), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        defaultValue: this.props.data.content.displayType ? this.props.data.content.displayType : clickForMore.value,
        onChange: (e) => {
          this.props.dataUpdate({ displayType: e.target.value });
        },
        options: this.getTintDisplayTypes(),
        description: this.props.displayTypeDescription,
        label: "display type"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        onChange: (e) => {
          this.props.dataUpdate({ ofColumns: e.target.value });
        },
        label: "# of columns",
        placeholder: "example: 6",
        defaultValue: this.props.data.content.ofColumns
      }
    ), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        defaultValue: this.props.data.content.savedFilterId ? this.props.data.content.savedFilterId : filters.value,
        onChange: (e) => {
          this.props.dataUpdate({ savedFilterId: e.target.value });
        },
        options: [{ value: "", name: "Select Filter" }, ...this.state.savedFilters],
        label: "Saved filters"
      }
    ));
  }
}
