import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { OAUTH_APPLICATIONS } from "../../consts";
export const ACTIONS = {
  ADD_ROWS_TO_TABLE: createPrefixedActionName(OAUTH_APPLICATIONS, "ADD_ROWS_TO_TABLE"),
  ADD_ROW_TO_TABLE: createPrefixedActionName(OAUTH_APPLICATIONS, "ADD_ROW_TO_TABLE"),
  REMOVE_ROW_FROM_TABLE: createPrefixedActionName(OAUTH_APPLICATIONS, "REMOVE_ROW_FROM_TABLE"),
  CLEAR_TABLE: createPrefixedActionName(OAUTH_APPLICATIONS, "CLEAR_TABLE"),
  EXPAND_TABLE_ROW: createPrefixedActionName(OAUTH_APPLICATIONS, "EXPAND_TABLE_ROW"),
  COLLAPSE_TABLE_ROW: createPrefixedActionName(OAUTH_APPLICATIONS, "COLLAPSE_TABLE_ROW"),
  SHOW_SECRET_ID: createPrefixedActionName(OAUTH_APPLICATIONS, "SHOW_SECRET_ID"),
  HIDE_SECRET_ID: createPrefixedActionName(OAUTH_APPLICATIONS, "HIDE_SECRET_ID")
};
export const addRowsToTable = (data) => ({
  type: ACTIONS.ADD_ROWS_TO_TABLE,
  payload: data
});
export const addRowToTable = (data) => ({
  type: ACTIONS.ADD_ROW_TO_TABLE,
  payload: data
});
export const removeRowFromTable = (id) => ({
  type: ACTIONS.REMOVE_ROW_FROM_TABLE,
  payload: id
});
export const clearTable = () => ({
  type: ACTIONS.CLEAR_TABLE
});
export const expandTableRow = (id) => ({
  type: ACTIONS.EXPAND_TABLE_ROW,
  payload: id
});
export const collapseTableRow = (id) => ({
  type: ACTIONS.COLLAPSE_TABLE_ROW,
  payload: id
});
export const showSecretId = (id) => ({
  type: ACTIONS.SHOW_SECRET_ID,
  payload: id
});
export const hideSecretId = (id) => ({
  type: ACTIONS.HIDE_SECRET_ID,
  payload: id
});
