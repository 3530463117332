var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import "./rights-status-bar.sass";
import { faArrowsRepeat, faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCheck, faHourglassEnd, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import TintIcon from "../../../../../components/interface/icon/icon";
import { Config } from "../../../../../utils/config";
import {
  openRequestRights,
  setRequestRightsModalProgress
} from "../../../../../actions/request-rights/request-rights.actions";
import { toast } from "react-toastify";
import { getPostRightRequests } from "../../../actions/posts.fat-actions";
import { useSelector, useDispatch } from "react-redux";
import { REQUEST_RIGHTS_SOURCE } from "../../../../../components/request-rights/request-rights";
import { getSocialConnections } from "../../../../account-settings/actions/social-connections/social-connections.fat-actions";
import {
  getRequestRightsTemplates,
  getRequestRightsTerms
} from "../../../../../actions/request-rights/request-rights.fat-actions";
import { RightRequestsStatusTypes } from "../../../../../types/right-requests";
const RightsStatusBar = ({ status, expireDate, post, tintId }) => {
  const transformTextDelay = 3e3;
  const [isCopied, setIsCopied] = useState(false);
  const { id: userId } = useSelector((state) => state.session.data);
  const dispatch = useDispatch();
  const isRightsApprovalExpired = expireDate ? new Date(expireDate).getTime() <= (/* @__PURE__ */ new Date()).getTime() : false;
  const onLearnMoreClick = (e) => {
    e.stopPropagation();
    window.open(Config.url.zenDesk.ugcRights);
  };
  const onTryAgainClick = () => {
    dispatch(
      openRequestRights({
        tintId,
        payload: {
          author: __spreadProps(__spreadValues({}, post.attributes.author), {
            picture: post.attributes.image_url
          }),
          url: post.attributes.url,
          source: post.attributes.type,
          image: post.attributes.image_url
        }
      })
    );
    dispatch(getSocialConnections({ url: "/accounts?page[size]=500&filter[status]=ok" }));
    dispatch(getRequestRightsTemplates({}));
    dispatch(getRequestRightsTerms({}));
    dispatch(
      setRequestRightsModalProgress({
        external_id: post.attributes.external_id,
        user_id: userId
      })
    );
  };
  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Text copied!");
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, transformTextDelay);
    });
  };
  const renderMessage = () => {
    switch (status) {
      case RightRequestsStatusTypes.APPROVED:
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: isRightsApprovalExpired ? faHourglassEnd : faCircleCheck }), isRightsApprovalExpired ? "Rights Approval Expired" : "Rights Approved!");
      case RightRequestsStatusTypes.ERROR:
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faHourglassEnd }), "Rights Error...", " ", /* @__PURE__ */ React.createElement("span", { className: "learn-more", onClick: onLearnMoreClick }, "Learn More"));
      case RightRequestsStatusTypes.EXPIRED:
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faHourglassEnd }), "Rights Request Expired...", " ", /* @__PURE__ */ React.createElement(
          "span",
          {
            className: "learn-more",
            onClick: (e) => {
              e.stopPropagation();
              onTryAgainClick == null ? void 0 : onTryAgainClick();
            }
          },
          "Try Again"
        ));
      case RightRequestsStatusTypes.PENDING:
      case RightRequestsStatusTypes.REQUESTED:
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faArrowsRepeat }), "Rights Pending", (post == null ? void 0 : post.attributes.type) !== REQUEST_RIGHTS_SOURCE.TWITTER && (post == null ? void 0 : post.attributes.source) !== REQUEST_RIGHTS_SOURCE.TWITTER && /* @__PURE__ */ React.createElement(
          "span",
          {
            className: "learn-more",
            onClick: (e) => {
              var _a, _b;
              e.stopPropagation();
              if (post.rightRequests) {
                copyTextToClipboard((_b = (_a = post.rightRequests) == null ? void 0 : _a.attributes) == null ? void 0 : _b.text);
              } else {
                dispatch(getPostRightRequests({ tintId, id: post == null ? void 0 : post.id })).then((res) => {
                  var _a2;
                  copyTextToClipboard((_a2 = res == null ? void 0 : res.attributes) == null ? void 0 : _a2.text);
                }).catch(() => toast.error("Something went wrong"));
              }
            }
          },
          !isCopied ? "Copy Message" : "Copied!"
        ));
      case RightRequestsStatusTypes.SOFT_APPROVED:
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faShieldCheck }), "Soft Approval...", " ", /* @__PURE__ */ React.createElement("span", { className: "learn-more", onClick: onLearnMoreClick }, "Learn More"));
      case RightRequestsStatusTypes.DELETED:
      case RightRequestsStatusTypes.MISSING:
      case RightRequestsStatusTypes.APPROVAL:
      case RightRequestsStatusTypes.TOKEN_ERROR:
      default:
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faCircleExclamation }), `${status}...`);
    }
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-rights-status-bar tint-rights-status-bar--${status.replace(" ", "_")}${isRightsApprovalExpired ? "--expired" : ""}`
    },
    renderMessage()
  );
};
export default RightsStatusBar;
