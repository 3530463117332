var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { toast } from "react-toastify";
import { getTintsUrlWithParams } from "../utils/filter-helper/filter-helper";
import {
  getTintsError,
  getTintsStart,
  getTintsSuccess,
  getSearchTintsError,
  getSearchTintsStart,
  getSearchTintsSuccess,
  getTintError,
  getTintStart,
  getTintSuccess,
  getInstintUpdatesError,
  getInstintUpdatesStart,
  getInstintUpdatesSuccess,
  updateTintStatusStart,
  updateTintStatusSuccess,
  updateTintStatusError,
  deleteTintStart,
  deleteTintSuccess,
  deleteTintError,
  duplicateTintStart,
  duplicateTintSuccess,
  duplicateTintError,
  exportPostsStart,
  exportPostsSuccess,
  exportPostsError,
  getExportPostsStatusStart,
  getExportPostsStatusSuccess,
  getExportPostsStatusError,
  createTintStart,
  createTintSuccess,
  createTintError,
  patchTintStart,
  patchTintSuccess,
  patchTintError,
  patchModerationRulesStart,
  patchModerationRulesSuccess,
  patchModerationRulesError,
  getCATSStart,
  getCATSSuccess,
  getCATSError,
  postCTAAssociationsStart,
  postCTAAssociationsSuccess,
  postCTAAssociationsError,
  postCTAStart,
  postCTAError,
  postCTASuccess,
  patchCTAStart,
  patchCTASuccess,
  patchCTAError,
  deleteCTAError,
  deleteCTASuccess,
  deleteCTAStart,
  deleteCTAAssociationsError,
  deleteCTAAssociationsSuccess,
  deleteCTAAssociationsStart,
  applyCTAAssociationsStart,
  applyCTAAssociationsSuccess,
  applyCTAAssociationsError,
  bulkUpdateTintsStatusStart,
  bulkUpdateTintsStatusSuccess,
  bulkUpdateTintsStatusError,
  bulkDeleteTintsStart,
  bulkDeleteTintsSuccess,
  bulkDeleteTintsError,
  postInstintUpdatesStart,
  postInstintUpdatesSuccess,
  postInstintUpdatesError
} from "./tint-editor.actions";
import { handleApiError } from "../../../services/functions/error-handler/error-handler";
import { ApiService } from "../../../services/api-service/api-service";
export const getTints = (tintsUrl) => {
  return (dispatch) => {
    dispatch(getTintsStart());
    return ApiService.get(tintsUrl || "/tints").then((res) => {
      const tints = res.data.data.map((tint) => {
        var _a, _b, _c;
        return __spreadValues(__spreadValues({}, tint), {
          socialFeedsCount: (_c = (_b = (_a = tint.relationships) == null ? void 0 : _a.social_feeds) == null ? void 0 : _b.meta) == null ? void 0 : _c.total
        });
      });
      dispatch(
        getTintsSuccess({
          tints,
          links: res.data.links,
          included: res.data.included
        })
      );
    }).catch((err) => dispatch(getTintsError(err)));
  };
};
export const searchForTints = (tintsUrl) => {
  return (dispatch) => {
    dispatch(getSearchTintsStart());
    return ApiService.get(tintsUrl || "/tints").then((res) => {
      const tints = res.data.data.map((tint) => {
        var _a, _b, _c;
        return __spreadValues(__spreadValues({}, tint), {
          socialFeedsCount: (_c = (_b = (_a = tint.relationships) == null ? void 0 : _a.social_feeds) == null ? void 0 : _b.meta) == null ? void 0 : _c.total
        });
      });
      dispatch(
        getSearchTintsSuccess({
          tints,
          links: res.data.links
        })
      );
    }).catch((err) => dispatch(getSearchTintsError(err)));
  };
};
export const getTint = (tintId) => {
  return (dispatch) => {
    dispatch(getTintStart());
    return ApiService.get(`/tints/${tintId}?include=social_feeds`).then((res) => {
      var _a;
      const tint = __spreadValues(__spreadValues({}, res.data.data), {
        socialFeedsCount: res.data.data.relationships.social_feeds.meta.total,
        socialFeeds: (_a = res.data.included) == null ? void 0 : _a.filter((data) => data.type === "social_feed")
      });
      dispatch(getTintSuccess(tint));
    }).catch((err) => dispatch(getTintError(err)));
  };
};
export const getCTAS = (tintId) => {
  return (dispatch) => {
    dispatch(getCATSStart());
    return ApiService.get(
      `/tints/${tintId}/ctas?include=cta_associations.saved_filter,cta_associations.cta,cta_associations.post`
    ).then((res) => {
      var _a, _b, _c;
      dispatch(
        getCATSSuccess({
          ctas: res.data.data,
          ctaAssociations: ((_a = res.data) == null ? void 0 : _a.included) && res.data.included.filter((include) => include.type === "cta_association"),
          savedFilter: ((_b = res.data) == null ? void 0 : _b.included) && ((_c = res.data) == null ? void 0 : _c.included.filter((include) => include.type === "saved_filter"))
        })
      );
    }).catch((err) => {
      dispatch(getCATSError(err));
    });
  };
};
export const postCTAS = (tintId, data) => {
  const body = {
    data: {
      type: "cta",
      attributes: __spreadValues({}, data)
    }
  };
  return (dispatch) => {
    dispatch(postCTAStart());
    return ApiService.post(`/tints/${tintId}/ctas`, body).then((res) => {
      dispatch(postCTASuccess(res.data.data));
      return res.data.data;
    }).catch((err) => {
      dispatch(postCTAError(err));
      throw err;
    });
  };
};
export const patchCTAS = (tintId, data, id) => {
  const body = {
    data: {
      type: "cta",
      attributes: __spreadProps(__spreadValues({}, data), {
        id: void 0
      })
    }
  };
  return (dispatch) => {
    dispatch(patchCTAStart());
    return ApiService.patch(
      `/tints/${tintId}/ctas/${id}?include=cta_associations.saved_filter,cta_associations.cta`,
      body
    ).then((res) => {
      dispatch(patchCTASuccess(res.data.data, id));
      return res.data.data;
    }).catch((err) => {
      dispatch(patchCTAError(err));
      throw err;
    });
  };
};
export const deleteCTA = (tintId, id) => {
  return (dispatch) => {
    dispatch(deleteCTAStart());
    return ApiService.delete(`/tints/${tintId}/ctas/${id}`).then((res) => {
      dispatch(deleteCTASuccess(id));
      return res.data.data;
    }).catch((err) => {
      dispatch(deleteCTAError(err));
      throw err;
    });
  };
};
export const postCTAAssociations = (tintId, data) => {
  const body = {
    data: {
      type: "cta",
      attributes: {
        saved_filter_id: data.savedFilterId,
        cta_id: data.id
      }
    }
  };
  return (dispatch) => {
    dispatch(postCTAAssociationsStart());
    return ApiService.post(`/tints/${tintId}/cta_associations`, body).then((res) => {
      dispatch(
        postCTAAssociationsSuccess(__spreadProps(__spreadValues({}, res.data.data), {
          savedFilterId: data.savedFilterId,
          ctaId: data.id
        }))
      );
    }).catch((err) => {
      dispatch(postCTAAssociationsError(err));
      throw err;
    });
  };
};
export const applyCTAAssociations = (tintId, data) => {
  const body = {
    data: {
      type: "cta",
      attributes: {
        cta_id: data.id,
        post_id: data == null ? void 0 : data.postId
      }
    }
  };
  return (dispatch) => {
    dispatch(applyCTAAssociationsStart());
    return ApiService.post(`/tints/${tintId}/cta_associations?include=post`, body).then((res) => {
      dispatch(
        applyCTAAssociationsSuccess(__spreadProps(__spreadValues({}, res.data.data), {
          ctaId: data.id,
          postId: data == null ? void 0 : data.postId
        }))
      );
    }).catch((err) => {
      dispatch(applyCTAAssociationsError(err));
      throw err;
    });
  };
};
export const deleteCTAAssociations = (tintId, ctaId, ctaAssociationId) => {
  return (dispatch) => {
    dispatch(deleteCTAAssociationsStart());
    return ApiService.delete(`/tints/${tintId}/cta_associations/${ctaAssociationId}`).then(() => {
      dispatch(deleteCTAAssociationsSuccess(ctaId, ctaAssociationId));
    }).catch((err) => {
      dispatch(deleteCTAAssociationsError(err));
      throw err;
    });
  };
};
export const updateTintStatus = (status, tintId) => {
  const data = {
    data: {
      type: "tint",
      attributes: {
        status
      }
    }
  };
  return (dispatch) => {
    dispatch(updateTintStatusStart());
    return ApiService.put(`/tints/${tintId}`, data).then(dispatch(updateTintStatusSuccess())).catch((err) => {
      dispatch(updateTintStatusError(err));
      handleApiError(err, toast.error);
    });
  };
};
export const bulkUpdateTintsStatus = (status, tintsList) => {
  const data = {
    data: tintsList.map(({ id }) => ({
      id,
      type: "tint",
      attributes: {
        status
      }
    }))
  };
  return (dispatch) => {
    dispatch(bulkUpdateTintsStatusStart());
    return ApiService.patch("/tints", data, { headers: ApiService.getBulkHeaders() }).then(() => {
      dispatch(bulkUpdateTintsStatusSuccess());
    }).catch((err) => {
      dispatch(bulkUpdateTintsStatusError(err));
      handleApiError(err, toast.error);
    });
  };
};
export const patchTintModerationRules = (tintId, rules) => {
  const data = {
    data: {
      type: "tint",
      attributes: {
        auto_moderation_rules: rules
      }
    }
  };
  return (dispatch) => {
    dispatch(patchModerationRulesStart());
    return ApiService.patch(`/tints/${tintId}`, data).then(() => {
      dispatch(patchModerationRulesSuccess(data.data.attributes.auto_moderation_rules));
    }).catch((err) => {
      dispatch(patchModerationRulesError(err));
      throw err;
    });
  };
};
export const deleteTint = (tintId) => {
  return (dispatch) => {
    dispatch(deleteTintStart());
    return ApiService.delete(`/tints/${tintId}`).then(() => {
      dispatch(deleteTintSuccess(tintId));
    }).catch((err) => {
      dispatch(deleteTintError(err));
      handleApiError(err, toast.error);
    });
  };
};
export const bulkDeleteTints = (tintsList) => {
  const data = {
    data: tintsList.map(({ id }) => ({
      id,
      type: "tint"
    }))
  };
  return (dispatch) => {
    dispatch(bulkDeleteTintsStart());
    return ApiService.delete("/tints", { headers: ApiService.getBulkHeaders() }, data).then(() => {
      dispatch(bulkDeleteTintsSuccess(data.data));
    }).catch((err) => {
      dispatch(bulkDeleteTintsError(err));
      handleApiError(err, toast.error);
    });
  };
};
export const duplicateTint = (tintId, name, slug) => {
  const data = {
    data: {
      type: "tint",
      attributes: {
        duplicated_from_id: Number(tintId),
        name,
        slug
      }
    }
  };
  return (dispatch) => {
    dispatch(duplicateTintStart());
    return ApiService.post("/tints?include=social_feeds", data).then(() => {
      dispatch(duplicateTintSuccess());
      dispatch(getTints(getTintsUrlWithParams()));
    }).catch((err) => {
      dispatch(duplicateTintError(err));
      throw err;
    });
  };
};
export const exportPosts = (tintId) => {
  const data = {
    data: {
      type: "post_export_request",
      attributes: {
        tint_id: tintId
      }
    }
  };
  return (dispatch) => {
    dispatch(exportPostsStart());
    return ApiService.post("/posts/export/requests", data).then((res) => {
      return dispatch(
        exportPostsSuccess(__spreadProps(__spreadValues({}, res.data.data), {
          tintId
        }))
      );
    }).catch((err) => {
      dispatch(exportPostsError(err));
    });
  };
};
export const getExportPostsStatus = (requestId) => {
  return (dispatch) => {
    dispatch(getExportPostsStatusStart());
    return ApiService.get(`/posts/export/requests/${requestId}?include=tint`).then((res) => {
      const exportDetails = __spreadValues({
        id: res.data.data.id,
        tintId: res.data.data.relationships.tint.data.id
      }, res.data.data.attributes);
      return dispatch(getExportPostsStatusSuccess(exportDetails));
    }).catch((err) => dispatch(getExportPostsStatusError(err)));
  };
};
export const patchTint = ({
  tintId,
  name,
  slug,
  analytics,
  isPrivate,
  defaultPersonalizationId,
  personalizations,
  tags
}) => {
  const data = {
    data: {
      type: "tint",
      id: tintId,
      attributes: {
        name,
        slug,
        analytics,
        private: isPrivate,
        default_personalization_id: defaultPersonalizationId,
        personalizations,
        tags
      }
    }
  };
  return (dispatch) => {
    dispatch(patchTintStart());
    return ApiService.patch(`/tints/${tintId}`, data).then((res) => {
      dispatch(patchTintSuccess(res.data.data));
    }).catch((err) => {
      dispatch(patchTintError(err));
      throw err;
    });
  };
};
export const createTint = (name, slug, analytics, isPrivate, defaultPersonalizationId) => {
  const data = {
    data: {
      type: "tint",
      attributes: {
        name,
        slug,
        analytics,
        private: isPrivate,
        default_personalization_id: defaultPersonalizationId
      }
    }
  };
  return (dispatch) => {
    dispatch(createTintStart());
    return ApiService.post("/tints", data).then((res) => {
      dispatch(createTintSuccess());
      dispatch(getTints(getTintsUrlWithParams()));
      return res.data.data;
    }).catch((err) => {
      dispatch(createTintError(err));
      throw err;
    });
  };
};
export const getInstintUpdates = (status = null) => {
  return (dispatch) => {
    dispatch(getInstintUpdatesStart());
    return ApiService.get(`/instint_updates?include=tint${status ? `&filter[status]=${status}` : ""}`).then((res) => {
      dispatch(getInstintUpdatesSuccess(res.data.data));
    }).catch((err) => dispatch(getInstintUpdatesError(err)));
  };
};
export const updateInstintUpdates = ({ startsAt, tintId, duration, billingPlatformToken }) => {
  const data = {
    data: {
      type: "instint_updates",
      attributes: {
        starts_at: startsAt,
        tint_id: tintId,
        duration,
        billing_platform_token: billingPlatformToken
      }
    }
  };
  return (dispatch) => {
    dispatch(postInstintUpdatesStart());
    return ApiService.post("/instint_updates", data).then((res) => {
      dispatch(postInstintUpdatesSuccess(res.data.data));
    }).catch((err) => {
      dispatch(postInstintUpdatesError(err));
      throw err;
    });
  };
};
