export const isAllowed = (enabledObj, metaArray) => {
  if (metaArray.length === 0) {
    return true;
  }
  return metaArray !== void 0 && metaArray.length > 0 && (metaArray.find((e) => e === "all") !== void 0 || metaArray.every((e) => enabledObj[e]));
};
export const onEnterprisePlan = (userPlan) => {
  return RegExp("enterprise").test(userPlan);
};
export const onTrialPlan = (userPlan) => {
  return RegExp("trial").test(userPlan);
};
export const onEventPlan = (userPlan) => {
  return RegExp("event_plan").test(userPlan);
};
export const onBasicPlan = (userPlan) => {
  return RegExp("basic_plan").test(userPlan);
};
export const onEventAnnualPlan = (userPlan) => {
  return RegExp("event_plan_annual").test(userPlan);
};
export const isInstintDisabled = (userPlan) => {
  return onTrialPlan(userPlan) || onEnterprisePlan(userPlan) || onEventPlan(userPlan) || onBasicPlan(userPlan) || onEventAnnualPlan(userPlan);
};
