import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import ChromePicker from "react-color/lib/Chrome";
import "./color-picker.sass";
import Color from "../../../../services/color-parser/color-parser";
import { Config } from "../../../../utils/config";
const ColorPicker = ({ color, onColorChange, isAlphaShow = true, noInputs = false }) => {
  const elementRef = useRef();
  const [currentColorHex, setCurrentColorHex] = useState("");
  const [currentAlpha, setCurrentAlpha] = useState("");
  const defaultAlphaValue = 1;
  useEffect(() => {
    setCurrentColorHex(Color.RGBAToHex(color));
    setAlpha();
  }, []);
  const setAlpha = () => {
    if (!color) return;
    if (Config.regExp.hexColorPattern.test(color)) {
      setCurrentAlpha(defaultAlphaValue);
    } else {
      const rgba = color == null ? void 0 : color.replace(Config.regExp.rgbaColorPattern, "").split(",");
      return rgba && rgba[3] ? setCurrentAlpha(rgba[3]) : setCurrentAlpha(defaultAlphaValue);
    }
  };
  const onChange = (color2) => {
    setCurrentColorHex(Color.RGBAToHex(color2));
    if (Config.regExp.hexColorPattern.test(color2) && color2 && isAlphaShow) {
      onColorChange(Color.hexToRGBAString(color2, currentAlpha));
    } else {
      onColorChange(color2);
      setCurrentColorHex(color2);
      setCurrentAlpha(currentAlpha);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { ref: elementRef, className: "tint-color-picker" }, /* @__PURE__ */ React.createElement("div", { onMouseDown: (e) => e.preventDefault() }, /* @__PURE__ */ React.createElement(
    ChromePicker,
    {
      color,
      disableAlpha: !isAlphaShow,
      onChange: (color2) => {
        onColorChange(color2.rgb);
        setCurrentColorHex(color2.hex);
        setCurrentAlpha(color2.rgb.a);
      }
    }
  )), !noInputs ? /* @__PURE__ */ React.createElement("div", { className: "tint-color-picker__input-wrapper" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      className: "input-color-picker input-color-picker--hex",
      type: "text",
      onChange: (e) => onChange(e.target.value),
      id: "inputId",
      value: currentColorHex
    }
  ), isAlphaShow && /* @__PURE__ */ React.createElement(
    "input",
    {
      className: "input-color-picker input-color-picker--alpha",
      type: "text",
      id: "inputAlpha",
      defaultValue: currentAlpha,
      readOnly: true
    }
  )) : null);
};
export default ColorPicker;
ColorPicker.propTypes = {
  onColorChange: PropTypes.func,
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
