var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./post-modal-body.sass";
import { EditInput, EditError, EditTextArea, EditCheckbox, StatusError, FilePicker } from "../../../";
import { FileStack } from "../../../../../../../services/filestack/file-stack";
import { ApiService } from "../../../../../../../services/api-service/api-service";
import { Button } from "../../../../../../../components/interface/button/button";
class PostModalBody extends Component {
  constructor(...args) {
    super(...args);
    __publicField(this, "onFilePickerClick", () => {
      return new Promise((resolve) => {
        new FileStack().openFilePicker({
          maxSize: this.props.maxVideoFileSize,
          onUploadDone: (res) => {
            const imageUrl = res.filesUploaded[0].url;
            resolve(imageUrl);
            this.setState({ imageUrl });
          }
        });
      });
    });
    __publicField(this, "determineFormRequirements", (isCustomFieldAvailable, isEmailRequired) => {
      const yupObject = {};
      if (isEmailRequired) {
        yupObject.emailYup = Yup.string().required("Email is required").email();
      } else {
        yupObject.emailYup = Yup.string().email();
      }
      if (isCustomFieldAvailable) {
        yupObject.customFieldYup = Yup.string().required("Custom field is required");
      }
      return yupObject;
    });
    this.state = {
      imageUrl: ""
    };
    this.apiService = ApiService.pureInstance();
  }
  render() {
    const {
      tintSlug,
      formId,
      onSuccessPost,
      isCustomFieldAvailable,
      isEmailRequired,
      customFieldPlaceholder,
      buttonText
    } = this.props;
    const { emailYup, customFieldYup } = this.determineFormRequirements(isCustomFieldAvailable, isEmailRequired);
    return this.props.isFetching ? null : /* @__PURE__ */ React.createElement("div", { className: "post-modal-form" }, /* @__PURE__ */ React.createElement(
      Formik,
      {
        initialValues: {
          name: "",
          email: "",
          description: "",
          terms: false,
          file: "",
          custom_field: ""
        },
        validationSchema: Yup.object().shape({
          name: Yup.string().required("Name is required"),
          email: emailYup,
          terms: Yup.bool().oneOf([true], "Has to be accepted"),
          file: Yup.string().required("File is required"),
          custom_field: customFieldYup
        }),
        onSubmit: (values, actions) => {
          const bodyFormData = new FormData();
          bodyFormData.set("name", values.name);
          bodyFormData.set("email", values.email);
          bodyFormData.set("description", values.description);
          values.custom_field && bodyFormData.set("custom field", values.custom_field);
          bodyFormData.set("media", JSON.stringify([values.file]));
          this.apiService.post(`/v2/experiences/${this.props.experienceId}/form_submissions`, bodyFormData).then(() => onSuccessPost()).catch(
            () => actions.setStatus({
              msg: "There is a problem with submitting form!"
            })
          );
        },
        render: ({ errors, touched, values, handleChange, handleSubmit, handleBlur, setFieldValue, status }) => /* @__PURE__ */ React.createElement(
          Form,
          {
            id: formId,
            noValidate: true,
            onSubmit: (e) => {
              e.preventDefault();
              handleSubmit();
            }
          },
          /* @__PURE__ */ React.createElement("div", { className: "post-modal-form__form-wrapper" }, /* @__PURE__ */ React.createElement(
            FilePicker,
            {
              value: values.file,
              onChange: handleChange,
              onClick: () => {
                this.onFilePickerClick().then((url) => {
                  setFieldValue("file", url);
                });
              },
              imageUrl: this.state.imageUrl,
              errorMsg: errors.name
            }
          ), /* @__PURE__ */ React.createElement(EditError, { message: errors.file, touched: touched.file }), /* @__PURE__ */ React.createElement(
            EditInput,
            {
              label: "Your name*",
              placeholder: "First & Last Name",
              onChange: handleChange,
              onBlur: handleBlur,
              name: "name",
              value: values.name,
              errorMsg: errors.name,
              touched: touched.name
            }
          ), /* @__PURE__ */ React.createElement(EditError, { message: errors.name, touched: touched.name }), /* @__PURE__ */ React.createElement(
            EditInput,
            {
              label: "email",
              placeholder: isEmailRequired ? "Email Address*" : "Email Address",
              onChange: handleChange,
              onBlur: handleBlur,
              name: "email",
              value: values.email,
              errorMsg: errors.email,
              touched: touched.email
            }
          ), /* @__PURE__ */ React.createElement(EditError, { message: errors.email, touched: touched.email }), isCustomFieldAvailable ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
            EditInput,
            {
              label: "Custom Field",
              placeholder: customFieldPlaceholder,
              onChange: handleChange,
              onBlur: handleBlur,
              name: "custom_field",
              value: values.custom_field,
              errorMsg: errors.custom_field,
              touched: touched.custom_field
            }
          ), /* @__PURE__ */ React.createElement(EditError, { message: errors.custom_field, touched: touched.custom_field })) : null, /* @__PURE__ */ React.createElement(
            EditTextArea,
            {
              label: "Description",
              placeholder: "Write a comment",
              onChange: handleChange,
              onBlur: handleBlur,
              name: "description",
              defaultValue: values.description,
              errorMsg: errors.description,
              touched: touched.description
            }
          ), /* @__PURE__ */ React.createElement(EditError, { errorMsg: errors.description, touched: touched.description }), /* @__PURE__ */ React.createElement(
            EditCheckbox,
            {
              name: "terms",
              id: "terms",
              labelUrl: `https://app.tintup.com/public_post_terms/${tintSlug}`,
              label: "I agree to Terms & Conditions",
              value: values.terms,
              onChange: handleChange,
              onBlur: handleBlur,
              errorMsg: errors.terms,
              touched: touched.terms
            }
          ), /* @__PURE__ */ React.createElement(EditError, { message: errors.terms, touched: touched.terms }), status && status.msg && /* @__PURE__ */ React.createElement(StatusError, { message: status.msg })),
          /* @__PURE__ */ React.createElement("div", { className: "post-modal-form__button" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              type: "gray",
              formId: this.props.formId,
              action: "submit",
              text: buttonText,
              className: "tint-btn--black",
              width: "100%"
            }
          ))
        )
      }
    ));
  }
}
PostModalBody.propTypes = {
  experienceId: PropTypes.string,
  formId: PropTypes.string,
  tintSlug: PropTypes.string,
  onSuccessPost: PropTypes.func,
  customFieldPlaceholder: PropTypes.string
};
export default PostModalBody;
