var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect } from "react";
import "./assets-modal.sass";
import TintModal from "../../../../../components/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { AssetsContainer } from "../../../../asset-manager/components/assets-container/assets-container";
import {
  AssetFilters,
  AssetsFilterBar
} from "../../../../asset-manager/components/assets-filter-bar/assets-filter-bar";
import { isAnyAssetUploaded } from "../../../../../reducers/session";
import { getAssets } from "../../../../asset-manager/actions/asset-manager.fat-actions";
import { getAssetsAggregations } from "../../../../asset-manager/actions/assets-aggregations/assets-aggregations.fat-actions";
import { getAssetUrlWithFilters } from "../../../../asset-manager/utils/filter-helper/filter-helper";
import ModalHeader from "../../../../../components/modal/components/modal-header/modal-header";
import { Button } from "../../../../../components/interface/button/button";
import { Typography, TypographyColors } from "../../../../../components/interface/typography/typography";
import { Grid } from "../../../../../components/interface/grid/grid";
import usePluralize from "../../../../../components/hooks/use-pluralize/use-pluralize";
import { getSelectedAssets } from "../../../../asset-manager/reducers/asset-manager.reducer";
import { getCollections } from "../../../../asset-manager/actions/asset-collections/asset-collections.fat-actions";
import {
  clearFilterOption,
  setFilterOption
} from "../../../../asset-manager/actions/filters/asset-manager-filters.actions";
import { TableEmptyList } from "../../../../../components/interface/lists/table-empty-list/table-empty-list";
export const assetModalId = "asset-modal-id";
export const AssetsModal = ({ isBackDropVisible, isOpen, onClose, onSubmit, modalDOMDestination, filters }) => {
  const dispatch = useDispatch();
  const assetsCount = useSelector((state) => isAnyAssetUploaded(state.session));
  const assetManager = useSelector((state) => state.assetManager);
  const selectedAssets = getSelectedAssets(assetManager);
  useEffect(() => {
    if (filters) {
      dispatch(setFilterOption(__spreadValues({}, filters)));
    }
    dispatch(getCollections("", 500));
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
    dispatch(getAssetsAggregations());
    if (!isOpen || !modalDOMDestination) return null;
    return () => {
      dispatch(clearFilterOption());
    };
  }, [dispatch]);
  const filterList = [AssetFilters.COLLECTION, AssetFilters.OBJECTS, AssetFilters.TAGS, AssetFilters.SORT];
  const assetPlural = usePluralize(selectedAssets.length, "Asset");
  return /* @__PURE__ */ React.createElement(
    TintModal,
    {
      className: "tint-modal--tint-assets-modal tint-assets-modal",
      isOpen,
      modalDOMDestination,
      fullScreen: true,
      isBackDropVisible,
      modalHeader: (props) => /* @__PURE__ */ React.createElement(ModalHeader, __spreadValues({}, props)),
      headerTitle: "Select Asset",
      onClose: () => onClose(assetModalId),
      zIndex: 2
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-assets-modal__wrapper" }, /* @__PURE__ */ React.createElement("div", null, assetsCount ? /* @__PURE__ */ React.createElement("div", { className: "tint-assets-modal__filters" }, /* @__PURE__ */ React.createElement(AssetsFilterBar, { filterList })) : null, assetsCount ? /* @__PURE__ */ React.createElement(AssetsContainer, { hasDisabledAssets: true }) : /* @__PURE__ */ React.createElement(TableEmptyList, { title: "No assets" })), /* @__PURE__ */ React.createElement("footer", null, /* @__PURE__ */ React.createElement("div", null, selectedAssets.length !== 0 && /* @__PURE__ */ React.createElement(Typography, { color: TypographyColors.WHITE }, selectedAssets.length, " ", assetPlural, " Selected")), /* @__PURE__ */ React.createElement(Grid, { spacing: 8 }, /* @__PURE__ */ React.createElement(Button, { onClick: () => onClose(assetModalId), type: "light-border" }, "Cancel"), /* @__PURE__ */ React.createElement(Button, { onClick: () => onSubmit(selectedAssets), type: "info", isDisabled: selectedAssets.length === 0 }, "Confirm"))))
  );
};
