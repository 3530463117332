import { createPrefixedActionName } from "../../services/functions/redux-routines/redux-routines.js";
export const ACTIONS = {
  SET_UPLOAD_DIALOG: createPrefixedActionName("UPLOAD_DIALOG", "SET"),
  CLEAR_UPLOAD_DIALOG: createPrefixedActionName("UPLOAD_DIALOG", "CLEAR")
};
export const setUploadDialog = (payload) => ({
  type: ACTIONS.SET_UPLOAD_DIALOG,
  payload
});
export const clearUploadDialog = () => ({
  type: ACTIONS.CLEAR_UPLOAD_DIALOG
});
