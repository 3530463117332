import axios from "axios";
import { createRoutine, createPrefixedActionName } from "../../../services/functions/redux-routines/redux-routines";
import { POSTS } from "../consts";
import { Config } from "../../../utils/config";
export const ACTIONS = {
  FETCH_POSTS: createRoutine(createPrefixedActionName(POSTS, "FETCH_POSTS"))
};
export const fetchPosts = (pollSize, slug) => (dispatch) => {
  dispatch({ type: ACTIONS.FETCH_POSTS.REQUEST });
  axios.get(`${Config.getApiUrl()}/v2/tints/${slug}/posts?page[size]=${pollSize}`).then(({ data }) => {
    dispatch({ type: ACTIONS.FETCH_POSTS.SUCCESS, payload: data.data });
  }).catch((error) => dispatch({ type: ACTIONS.FETCH_POSTS.FAILURE, payload: error }));
};
