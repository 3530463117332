var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { INPUT_EDIT_MODAL_STEP } from "../../components/experience/page-sections/form/field-edit-modal/field-edit-modal";
import { ACTIONS } from "../../actions/form-section-field-modal/form-section-field-modal.actions";
export const initialState = {
  step: INPUT_EDIT_MODAL_STEP.PRIMARY,
  data: void 0
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_FORM_SECTION_MODAL_STEP:
      return __spreadValues(__spreadValues({}, state), action.payload);
    case ACTIONS.CLOSE_FORM_SECTION_INPUT_MODAL:
      return initialState;
    default:
      return state;
  }
};
