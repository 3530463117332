import React from "react";
import "./wysiwyg-navigation.sass";
import {
  wysiwygNavigationTextStyleItems,
  wysiwygNavigationTextAlignItems,
  wysiwygNavigationColorStyleItems,
  wysiwygNavigationLinkItem,
  commandIdList
} from "./wysiwyg-navigation-options";
import * as PropTypes from "prop-types";
import ColorParser from "../../../../services/color-parser/color-parser";
const WysiwygNavigation = ({ onNavElementClick, style }) => {
  const renderColorBar = (tag) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          backgroundColor: ColorParser.getRgbaColorString(
            tag.commandId === commandIdList.COLOR ? style.color : style.backgroundColor
          )
        },
        className: "tint-menu-color__color-rectangle"
      }
    );
  };
  const renderNavGroup = (items, groupNameClass) => {
    return /* @__PURE__ */ React.createElement("ul", { className: `tint-wysiwyg-navigation__list tint-wysiwyg-navigation__list--${groupNameClass}` }, items.map((tag, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `tint-wysiwyg-navigation__item tint-wysiwyg-navigation__item--${tag.commandId}
              ${tag.commandId === style[tag.value] || tag.commandId === style[tag.commandId] ? "tint-wysiwyg-navigation__item--active" : ""}
            `,
        "data-testid": `wysiwygNavigationItem_${tag.commandId}`,
        key: i,
        onClick: () => onNavElementClick(tag.commandId)
      },
      /* @__PURE__ */ React.createElement("button", { className: tag.iconName }, tag.commandId === commandIdList.COLOR || tag.commandId === commandIdList.BACKGROUND_COLOR ? renderColorBar(tag) : null)
    )));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-navigation" }, renderNavGroup(wysiwygNavigationColorStyleItems, "color-picker"), renderNavGroup(wysiwygNavigationTextStyleItems, "text-style"), /* @__PURE__ */ React.createElement("ul", { className: "tint-wysiwyg-navigation__list tint-wysiwyg-navigation__list--link" }, wysiwygNavigationLinkItem.map((tag, i) => /* @__PURE__ */ React.createElement(
    "li",
    {
      className: `tint-wysiwyg-navigation__item tint-wysiwyg-navigation__item--${tag.commandId}
              ${style[tag.value] && style[tag.value].length > 0 ? "tint-wysiwyg-navigation__item--active" : ""}
            `,
      "data-testid": `wysiwygNavigationItem_${tag.commandId}`,
      key: i,
      onClick: () => onNavElementClick(tag.commandId)
    },
    /* @__PURE__ */ React.createElement("button", { className: tag.iconName })
  ))), renderNavGroup(wysiwygNavigationTextAlignItems, "align"));
};
export default WysiwygNavigation;
WysiwygNavigation.propTypes = {
  onNavElementClick: PropTypes.func,
  style: PropTypes.any
};
