var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./tint-instint-updates-sidebar.sass";
import { StripeCheckoutFormFooter } from "../../../../../components/stripe-checkout-form/stripe-checkout-form-footer/stripe-checkout-form-footer";
import TintSidebar from "../../../../../components/sidebar/sidebar";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import InputPrimary from "../../../../../components/interface/inputs/input-primary/input-primary";
import StripeCheckoutFormWithForwardedRef from "../../../../../components/stripe-checkout-form/stripe-checkout-form";
import { Config } from "../../../../../utils/config";
import { updateInstintUpdates } from "../../../actions/tint-editor.fat-actions";
import { timeZones } from "../../../../../utils/time-zones/time-zones";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DropdownTints } from "../../../../../components/api-dropdown/tint/dropdown-tints";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "../../../../../components/interface/typography/typography";
import { getSidebarData, isSidebarOpen } from "../../../../../reducers/ui/ui.selector";
import { closeSidebar } from "../../../../../actions/ui/ui.actions";
import { toast } from "react-toastify";
import { DateTimePicker } from "../../../../../components/date-time-picker/date-time-picker";
import ValidationAlert from "../../../../../components/interface/inputs/validation-alert/validation-alert";
import { formatISO, isAfter } from "date-fns";
export const tintInsTintUpdatesSidebarId = "tintInsTintUpdatesSidebarId";
const centralEuropeanTimeOffset = "+01:00";
const TintInstintUpdatesSidebar = () => {
  var _a, _b, _c;
  const dispatch = useDispatch();
  const [isPending, setPending] = useState(false);
  const isOpen = useSelector((state) => isSidebarOpen(state, tintInsTintUpdatesSidebarId));
  const data = useSelector((state) => getSidebarData(state, tintInsTintUpdatesSidebarId));
  const currentDate = /* @__PURE__ */ new Date();
  const timeZonesOptions = timeZones.map((e) => ({
    value: e.offset,
    name: e.display
  }));
  const getDefaultTimeZone = timeZonesOptions.find((e) => e.value === centralEuropeanTimeOffset);
  const getValidationSchema = () => {
    return Yup.object().shape({
      tint: Yup.object().shape({
        value: Yup.string().required("Select Tint").min(1),
        name: Yup.string().required("Select Tint Name").min(1)
      }),
      numberOfDays: Yup.string().required("Cannot be empty").matches(Config.regExp.numberOfDays, "Has to be more than 0"),
      cvcCodeValid: Yup.boolean().test("CVC code is required", (value) => value).required(true),
      expiryDateValid: Yup.boolean().test("Expiry date is required", (value) => value).required(true),
      creditCardValid: Yup.boolean().test("Credit card is required", (value) => value).required(true)
    });
  };
  const formik = useFormik({
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      date: currentDate,
      tint: "",
      timeZone: getDefaultTimeZone,
      numberOfDays: "1"
      // Make sure to use a string for number inputs
    },
    validationSchema: getValidationSchema()
  });
  const isCurrentDatePast = (date = formik.values.date) => {
    const newDate = /* @__PURE__ */ new Date();
    return !isAfter(date, newDate);
  };
  const [isDatePast, setIsDatePast] = useState(false);
  const handleCheckout = (token) => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    yield dispatch(
      updateInstintUpdates({
        tintId: (_b2 = (_a2 = formik.values) == null ? void 0 : _a2.tint) == null ? void 0 : _b2.value,
        startsAt: formatISO(formik.values.date),
        // Use ISO format for dates
        duration: `P${parseInt((_c2 = formik.values) == null ? void 0 : _c2.numberOfDays)}D`,
        billingPlatformToken: token
      })
    ).then(() => dispatch(closeSidebar(tintInsTintUpdatesSidebarId))).catch(() => toast.error("Something went wrong")).finally(() => setPending(false));
  });
  const onChangeDate = (date) => {
    formik.setFieldValue("date", date);
    setIsDatePast(isCurrentDatePast(date));
  };
  const onSelectTimeZone = (timeZone) => {
    formik.setFieldValue("timeZone", timeZone);
  };
  const node = useRef();
  const onClickPay = () => __async(void 0, null, function* () {
    if (isCurrentDatePast()) {
      setIsDatePast(true);
      return;
    }
    setPending(true);
    node.current.handleSubmit();
  });
  return /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      sidebarClassName: "tint-instint-updates",
      title: data.title,
      isOpen,
      onClose: () => dispatch(closeSidebar(tintInsTintUpdatesSidebarId)),
      defaultFooterStyle: false,
      footerComponent: () => {
        var _a2;
        return /* @__PURE__ */ React.createElement(
          StripeCheckoutFormFooter,
          {
            onClickPay,
            numberOfDays: (_a2 = formik.values) == null ? void 0 : _a2.numberOfDays,
            disabled: !formik.isValid || isPending || isDatePast,
            onClose: () => dispatch(closeSidebar(tintInsTintUpdatesSidebarId))
          }
        );
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-sidebar", "data-testid": "tint-instint-updates-sidebar" }, /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-sidebar__group" }, /* @__PURE__ */ React.createElement(
      DropdownTints,
      {
        onChange: (selectedItem) => {
          formik.setFieldValue("tint", selectedItem);
        },
        fixedOption: false
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-sidebar__group" }, /* @__PURE__ */ React.createElement(
      Typography,
      {
        color: TypographyColors.PRIMARY,
        variant: TypographyVariants.LABEL,
        component: "label",
        className: "tint-api-dropdown__label"
      },
      "Time Zone"
    ), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: timeZonesOptions,
        iconRight: "fa fa-caret-down",
        placeholder: ((_b = (_a = formik.values) == null ? void 0 : _a.timeZone) == null ? void 0 : _b.value) ? (_c = formik.values) == null ? void 0 : _c.name : "Select a Time Zone",
        onChangeValue: onSelectTimeZone,
        currentItem: formik.values.timeZone,
        dropdownType: DropdownButtonType.SINGLE_SELECT,
        buttonType: ButtonType.BUTTON_GRAY_BORDER
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-sidebar__group" }, /* @__PURE__ */ React.createElement(DateTimePicker, { onChange: onChangeDate }), isDatePast && /* @__PURE__ */ React.createElement(ValidationAlert, { errorMsg: "Cannot be in the past", isVisible: isDatePast })), /* @__PURE__ */ React.createElement("form", { className: "form", id: "tint-instint-updates-sidebar-form", onSubmit: formik.handleSubmit }, /* @__PURE__ */ React.createElement(
      Typography,
      {
        color: TypographyColors.PRIMARY,
        variant: TypographyVariants.LABEL,
        component: "label",
        className: "tint-api-dropdown__label"
      },
      "# of days"
    ), /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        name: "numberOfDays",
        type: "number",
        value: formik.values.numberOfDays,
        placeholder: "DD",
        handleChange: formik.handleChange,
        onBlur: formik.handleBlur,
        error: formik.errors.numberOfDays,
        touched: formik.touched.numberOfDays
      }
    ), /* @__PURE__ */ React.createElement(
      StripeCheckoutFormWithForwardedRef,
      {
        ref: node,
        handleCheckout,
        onChange: (e) => {
          const selectedItemKey = Object.keys(e)[0];
          formik.setFieldValue(selectedItemKey, e[selectedItemKey]);
        }
      }
    )))
  );
};
export default TintInstintUpdatesSidebar;
