var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/experiences";
import { produce } from "immer";
const initialState = {
  data: void 0,
  isFetching: false,
  error: void 0,
  selectedExperiences: []
};
const experiences = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EXPERIENCES.REQUEST:
    case ACTIONS.UPDATE_EXPERIENCES_STATUS.REQUEST:
    case ACTIONS.DELETE_EXPERIENCES.REQUEST:
      return produce(state, (draft) => {
        draft.isFetching = true;
        draft.isError = false;
      });
    case ACTIONS.GET_EXPERIENCES.SUCCESS:
      return produce(state, (draft) => {
        draft.isFetching = false;
        draft.isError = false;
        draft.data = action.payload;
      });
    case ACTIONS.GET_EXPERIENCES.FAILURE:
    case ACTIONS.DELETE_EXPERIENCES.FAILURE:
    case ACTIONS.UPDATE_EXPERIENCES_STATUS.FAILURE:
      return produce(state, (draft) => {
        draft.isFetching = false;
        draft.isError = action.payload;
      });
    case ACTIONS.SELECT_EXPERIENCE:
      return produce(state, (draft) => {
        draft.selectedExperiences = [...draft.selectedExperiences, action.payload];
      });
    case ACTIONS.UNSELECT_EXPERIENCE:
      return produce(state, (draft) => {
        draft.selectedExperiences = draft.selectedExperiences.filter(({ id }) => {
          return action.payload !== id;
        });
      });
    case ACTIONS.UNSELECT_ALL_EXPERIENCES:
      return produce(state, (draft) => {
        draft.selectedExperiences = [];
      });
    case ACTIONS.UPDATE_EXPERIENCES_STATUS.SUCCESS:
      return produce(state, (draft) => {
        draft.selectedExperiences = draft.selectedExperiences.map((exp) => __spreadProps(__spreadValues({}, exp), {
          attributes: __spreadProps(__spreadValues({}, exp.attributes), {
            status: exp.attributes.status === "active" ? "archived" : "active"
          })
        }));
        draft.data.data = draft.data.data.map((exp) => {
          if (draft.selectedExperiences.find((selectedExperiences) => selectedExperiences.id === exp.id)) {
            return __spreadProps(__spreadValues({}, exp), {
              attributes: __spreadProps(__spreadValues({}, exp.attributes), {
                status: exp.attributes.status === "active" ? "archived" : "active"
              })
            });
          }
          return exp;
        });
      });
    case ACTIONS.DELETE_EXPERIENCES.SUCCESS:
      return produce(state, (draft) => {
        draft.selectedExperiences = [];
        draft.isFetching = false;
        draft.isError = false;
        draft.data.data = draft.data.data.filter((data) => !action.payload.some((d) => d.id === data.id));
      });
    default:
      return state;
  }
};
export default experiences;
