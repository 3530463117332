import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const heading = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HEADING_H2]: {
    color: "#000000",
    "font-size": "20px",
    "font-weight": "700"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HEADING_P]: {
    color: "#727272",
    "font-size": "16px"
  }
};
