var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import "./survey-edit-answer.sass";
import * as PropTypes from "prop-types";
import { FileStack } from "../../../../../../../../services/filestack/file-stack";
import { Button } from "../../../../../../../../components/interface/button/button";
import EditInput from "../../../../edit-input/edit-input";
import { EditTextArea } from "../../../../index";
import { Config } from "../../../../../../../../utils/config";
const SurveyEditAnswer = ({
  answer,
  updateAnswerData,
  answers,
  getTranslation,
  onTranslationUpdate,
  hasAdvancedSettings,
  keyInputValidation,
  index,
  setState,
  setApplyButtonDisabled,
  maxVideoFileSize
}) => {
  const onUploadClick = () => {
    new FileStack().openFilePicker({
      maxSize: maxVideoFileSize,
      onUploadDone: (res) => {
        if (res.filesUploaded.length > 0) {
          updateAnswerData({
            answers: updateAnswer("imageUrl", res.filesUploaded[0].url)
          });
        }
      }
    });
  };
  const renderViewWithImage = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-header-content__center" }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-upload-design__image", style: { backgroundImage: `url(${answer.imageUrl})` } }), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          updateAnswerData({
            answers: updateAnswer("imageUrl", void 0)
          });
        },
        type: "gray",
        size: "full-width",
        text: "Remove Image"
      }
    ));
  };
  const updateAnswer = (propertyName, newValue) => {
    return answers.map((e) => {
      if (e.id === answer.id) {
        return __spreadProps(__spreadValues({}, e), {
          [propertyName]: newValue
        });
      }
      return e;
    });
  };
  const removeAnswer = () => {
    updateAnswerData({
      answers: answers = answers.filter((currentAnswer) => {
        return currentAnswer.id !== answer.id;
      })
    });
  };
  const keyInputUpdate = (value, i) => {
    let _keyInputValidation;
    if (Config.regExp.formIds.test(value)) {
      _keyInputValidation = {
        keyInputValidation: __spreadValues(__spreadValues({}, keyInputValidation), { [i]: "" })
      };
      updateAnswerData({
        answers: updateAnswer("key", value)
      });
    } else {
      _keyInputValidation = {
        keyInputValidation: __spreadValues(__spreadValues({}, keyInputValidation), {
          [i]: "Invalid format, input can contain only _, numbers and letters"
        })
      };
    }
    setState(
      { keyInputValidation: _keyInputValidation.keyInputValidation },
      () => updateValidation(_keyInputValidation.keyInputValidation)
    );
  };
  const updateValidation = (_keyInputValidation) => {
    const isFormInvalid = Object.values(_keyInputValidation).every((e) => e !== "");
    setApplyButtonDisabled(isFormInvalid);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-answer" }, /* @__PURE__ */ React.createElement(
    EditInput,
    {
      defaultValue: getTranslation(answer.title) || "Title",
      onChange: (e) => {
        onTranslationUpdate(answer.title, e.target.value);
      },
      rightIcon: "far fa-trash-alt",
      rightIconAction: removeAnswer,
      label: `Answer ${answers.indexOf(answer) + 1}`,
      placeholder: "Title"
    }
  ), /* @__PURE__ */ React.createElement(
    EditTextArea,
    {
      defaultValue: getTranslation(answer.description),
      onChange: (e) => {
        onTranslationUpdate(answer.description, e.target.value);
      },
      placeholder: "Description"
    }
  ), hasAdvancedSettings ? /* @__PURE__ */ React.createElement(
    EditInput,
    {
      touched: true,
      error: keyInputValidation[index] !== "",
      errorMsg: keyInputValidation[index],
      onChange: (event) => {
        keyInputUpdate(event.target.value, index);
      },
      placeholder: "Form Field ID",
      defaultValue: answer.key
    }
  ) : null, answer.imageUrl === void 0 ? /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: onUploadClick,
      type: "gray",
      size: "full-width",
      text: "Upload image",
      icon: "far fa-arrow-to-top",
      className: "tint-edit-answer__upload-button"
    }
  ) : renderViewWithImage(answer));
};
SurveyEditAnswer.propTypes = {
  totalVotes: PropTypes.number,
  updateAnswerData: PropTypes.func,
  answers: PropTypes.array
};
export default SurveyEditAnswer;
