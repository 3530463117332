var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { Component } from "react";
import "./button.sass";
import PropTypes from "prop-types";
import TintIcon from "../icon/icon";
const defaultClasses = {
  type: "primary" | "secondary" | "info" | "stroked" | "white" | "black" | "transparent" | "transparent-gray" | "dark-gray" | "gray" | "light-gray" | "gray-no-hover" | "link" | "stripe" | "stroked-grey" | "navigation-item" | "no-style" | "orange" | "navigation-arrow" | "dark-border" | "light-border" | "new-gray" | "new-primary-icon" | "dashed" | "error",
  size: ""
};
const TargetType = {
  BLANK: "_blank"
};
export class Button extends Component {
  renderIcon() {
    if (this.props.icon) {
      if (typeof this.props.icon === "string") {
        return /* @__PURE__ */ React.createElement("i", { className: `${this.props.icon} button-icon` });
      } else {
        return /* @__PURE__ */ React.createElement("div", { className: "tint-btn__icon" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: this.props.icon, svgIconSrc: this.props.svgIconSrc, color: this.props.iconColor }));
      }
    } else if (this.props.svgIconSrc) {
      return /* @__PURE__ */ React.createElement("div", { className: "tint-btn__icon" }, /* @__PURE__ */ React.createElement(TintIcon, { svgIconSrc: this.props.svgIconSrc }));
    }
  }
  getClassNames() {
    const buttonDisabledClass = this.props.isDisabled ? "tint-btn--disabled" : "";
    return `tint-btn tint-btn--${this.props.type || "primary"} tint-btn--${this.props.size || defaultClasses.size} ${buttonDisabledClass} tint-btn--${this.props.icon ? this.props.iconRight ? "icon-right" : "icon-left" : ""}`;
  }
  renderLink() {
    return /* @__PURE__ */ React.createElement(
      "a",
      {
        className: `${this.getClassNames()} ${this.props.className}`,
        "data-testid": this.props.id,
        href: this.props.openUrl,
        onClick: this.props.onClick,
        rel: "noopener noreferrer",
        style: __spreadValues({
          width: this.props.width,
          paddingLeft: this.props.width ? 0 : null,
          paddingRight: this.props.width ? 0 : null,
          color: this.props.textColor
        }, this.props.styles),
        target: this.props.openUrlTarget !== void 0 ? this.props.openUrlTarget : "_blank"
      },
      !this.props.iconRight && this.renderIcon(),
      this.props.text,
      this.props.children,
      this.props.iconRight && this.renderIcon()
    );
  }
  renderButton() {
    return /* @__PURE__ */ React.createElement(
      "button",
      {
        "data-testid": this.props.id,
        disabled: this.props.isDisabled,
        type: this.props.action,
        form: this.props.formId,
        onClick: this.props.onClick,
        readOnly: this.props.readonly,
        style: __spreadValues({
          height: this.props.height,
          width: this.props.width,
          paddingLeft: this.props.width ? 0 : null,
          paddingRight: this.props.width ? 0 : null,
          color: this.props.textColor
        }, this.props.styles),
        className: `${this.getClassNames()} ${this.props.className}`
      },
      !this.props.iconRight && this.renderIcon(),
      this.props.text,
      this.props.children,
      this.props.iconRight && this.renderIcon()
    );
  }
  render() {
    return this.props.openUrl !== void 0 ? this.renderLink() : this.renderButton();
  }
}
Button.propTypes = {
  action: PropTypes.string,
  formId: PropTypes.string,
  type: PropTypes.string,
  readonly: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  openUrl: PropTypes.string,
  openUrlTarget: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.string,
  svgIconSrc: PropTypes.string
};
Button.defaultProps = {
  action: "button",
  isDisabled: false,
  openUrlTarget: TargetType.BLANK
};
