import React, { useState } from "react";
import "./author-photo.sass";
import TintButtonIcon, { TintButtonIconShape, TintButtonIconTypes } from "../button-icon/button-icon";
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import AvatarUploader from "../avatar-upoloader/avatar-uploader";
import InputFile from "../inputs/input-file/input-file";
import Tooltip from "../../../feature/transform-ui/components/tooltip/tooltip";
import { useUploader } from "../../../components/hooks/use-uploader/use-uploader";
import { UploadServiceURLs } from "../../../services/upload/upload.service";
import { toast } from "react-toastify";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType
} from "../../../components/interface/preloaders/preloader/preloader";
const AuthorPhoto = ({ imageSrc = "", onAvatarChange }) => {
  const [url, setUrl] = useState(imageSrc);
  const onImageChange = (image) => {
    if (image) {
      setUrl(image == null ? void 0 : image.preview);
      onAvatarChange && onAvatarChange(image == null ? void 0 : image.id);
    } else {
      setUrl("");
      onAvatarChange && onAvatarChange("");
    }
  };
  const onUploadError = () => {
    toast.error("Cannot upload an image");
  };
  const onUploadSuccess = (files) => {
    if (!(files == null ? void 0 : files[0])) {
      return;
    }
    onImageChange(files[0]);
  };
  const { onUploadFiles, isPending } = useUploader({
    onUploadSuccess,
    onUploadError,
    url: UploadServiceURLs.POSTS
  });
  return /* @__PURE__ */ React.createElement("div", { className: "tint-author-photo" }, isPending && /* @__PURE__ */ React.createElement(
    PreloaderComponent,
    {
      positionType: PreloaderPositionType.ABSOLUTE,
      backdropType: PreloaderBackdropType.TRANSPARENT
    }
  ), /* @__PURE__ */ React.createElement("div", { className: `tint-author-photo__thumb tint-author-photo__thumb--${url ? "selected" : ""}` }, /* @__PURE__ */ React.createElement(AvatarUploader, { imageSrc: url, size: 40, onAvatarChange: onImageChange, useFileStack: false }, !url ? /* @__PURE__ */ React.createElement(
    InputFile,
    {
      useFileStack: false,
      accept: "image/*",
      label: "Upload Photo",
      handleChange: (e) => onUploadFiles(e.target.files)
    },
    /* @__PURE__ */ React.createElement(
      Tooltip,
      {
        style: { left: "-40px", top: "auto", bottom: "-44px", height: "30px", width: "110px", zIndex: "1" },
        component: /* @__PURE__ */ React.createElement(
          TintButtonIcon,
          {
            icon: faPlus,
            iconSize: 14,
            type: TintButtonIconTypes.BORDER,
            shape: TintButtonIconShape.CIRCLE
          }
        )
      }
    )
  ) : /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      style: { left: "-40px", top: "auto", bottom: "-44px", height: "30px", width: "110px", zIndex: "1" },
      component: /* @__PURE__ */ React.createElement(
        TintButtonIcon,
        {
          icon: faMinus,
          iconSize: 14,
          onClick: () => onImageChange(null),
          type: TintButtonIconTypes.BORDER,
          shape: TintButtonIconShape.CIRCLE
        }
      ),
      text: "Remove Photo"
    }
  ))));
};
export default AuthorPhoto;
