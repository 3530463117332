import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import EditUploadDesign from "../../../edit-upload-design/edit-upload-design";
import { EditColorInput } from "../../../index";
import PropTypes from "prop-types";
export class EditSocialFeedCounterStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EditModalBody, { title: "Edit your header background color" }, /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: this.props.data.styles.counterTextColor,
        label: "Counter text color",
        onChange: (value) => {
          this.props.styleDataUpdate({ counterTextColor: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: this.props.data.styles.counterLabelTextColor,
        label: "Label text color",
        onChange: (value) => {
          this.props.styleDataUpdate({ counterLabelTextColor: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: this.props.data.styles.counterBackgroundColor,
        label: "Counter background color",
        onChange: (value) => {
          this.props.styleDataUpdate({ counterBackgroundColor: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditUploadDesign,
      {
        dataUpdate: this.props.dataUpdate,
        styleDataUpdate: this.props.styleDataUpdate,
        data: this.props.data
      }
    )));
  }
}
EditSocialFeedCounterStyles.propTypes = {
  data: PropTypes.shape({
    styles: PropTypes.shape({
      counterTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      counterLabelTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      counterBackgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  }),
  styleDataUpdate: PropTypes.func,
  dataUpdate: PropTypes.func
};
