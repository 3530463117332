import React, { useEffect, useRef, useState } from "react";
import "./dropdown-multiselect-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import { DropdownMultiSelectListHeader } from "./header/dropdown-multiselect-list-header";
import { DropdownSearchInput } from "../../seach-input/dropdown-search-input";
const DropdownMultiSelectList = function({
  iconLeft,
  iconRight,
  onChange,
  currentItems,
  parentRef,
  onChangeTextComponent,
  title
}) {
  const listRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const { positionHorizontal, positionVertical } = useDropdownListPosition(listRef, parentRef);
  useEffect(() => {
    onChangeTextComponent(DropdownTextElement(currentItems.filter((item) => item.isActive).length));
  }, [currentItems]);
  const DropdownTextElement = (count) => {
    return /* @__PURE__ */ React.createElement(DropdownMultiSelectListHeader, { title, iconLeft, iconRight, count });
  };
  const onInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const results = !searchTerm ? currentItems : currentItems.filter((item) => item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
  return /* @__PURE__ */ React.createElement(
    "ul",
    {
      className: `dropdown-multi-select-list dropdown-multi-select-list--${positionHorizontal} dropdown-multi-select-list--${positionVertical}`,
      ref: listRef,
      "data-testid": "dropdown-multi-select-list"
    },
    /* @__PURE__ */ React.createElement("li", { className: "dropdown-multi-select-list__item dropdown-multi-select-list__item--search" }, /* @__PURE__ */ React.createElement(DropdownSearchInput, { onChange: onInputChange, searchTerm })),
    results.map((item, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-multi-select-list__item ${item.isActive ? "active" : ""}`,
        key: i,
        onClick: () => {
          onChange(item);
        }
      },
      /* @__PURE__ */ React.createElement("span", { className: "checker" }),
      /* @__PURE__ */ React.createElement("span", null, item.name)
    ))
  );
};
export default DropdownMultiSelectList;
DropdownMultiSelectList.propTypes = {
  onChange: PropTypes.func,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  currentItems: PropTypes.array
};
