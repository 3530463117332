var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import "./accounts-table.sass";
import { AccountRow } from "./account-row/account-row";
import { TableListPrimary } from "../../../../components/interface/lists/table-list-primary/table-list-primary";
import { ActionMenu } from "./action-menu/action-menu";
import { ProductAccount } from "../../utils/product-accounts/product-accounts.schema";
import ButtonContentIcon from "../../../../components/interface/button-content-icon/button-content-icon";
import useFeatureAccessibility from "../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { ProductAccountsTranslations } from "../../../../utils/translations/product-accounts";
import { SocialFeedsSourceTranslate } from "../../../../components/hooks/use-social-feed-names/social-feeds";
import { ProductFeedsTranslations } from "../../../../utils/translations/product-feeds";
export const ACCOUNT_TYPE = {
  PRODUCT_ACCOUNT: "product_account",
  PRODUCT_FEED: "product_feed",
  SOCIAL_ACCOUNT: "account"
};
export const AccountsTable = ({ accounts, onRefresh, onDelete, onEdit, onSyncFeed, onExportToCSV }) => {
  const {
    productAccountsCreate: hasPermissionToEdit,
    productAccountsDelete: hasPermissionToDelete,
    productFeedsRead: hasPermissionToRead,
    accountsCreate: hasPermissionToRefresh
  } = useFeatureAccessibility();
  const renderIcon = ({ type, source }) => {
    switch (type) {
      case ACCOUNT_TYPE.PRODUCT_ACCOUNT:
      case ACCOUNT_TYPE.PRODUCT_FEED:
        return /* @__PURE__ */ React.createElement(
          ButtonContentIcon,
          {
            circleSize: 30,
            fontSize: 15,
            svgIconSize: 20,
            source,
            styles: { border: "1px solid #e9edf3" }
          }
        );
      case ACCOUNT_TYPE.SOCIAL_ACCOUNT:
        return /* @__PURE__ */ React.createElement(ButtonContentIcon, { circleSize: 30, fontSize: 15, svgIconSize: 20, source });
      default:
        return null;
    }
  };
  const renderAccountRow = (account) => {
    var _a;
    let result = {
      id: account.id,
      status: account.attributes.status,
      imageUrl: account.attributes.image_url
    };
    if (account.type === ACCOUNT_TYPE.SOCIAL_ACCOUNT) {
      result = __spreadProps(__spreadValues({}, result), {
        name: account.attributes.name || account.attributes.username || SocialFeedsSourceTranslate[account.attributes.type],
        source: account.attributes.type,
        type: ACCOUNT_TYPE.SOCIAL_ACCOUNT,
        count: account.relationships.social_feeds.meta.count,
        label: "social feed"
      });
    } else if (account.type === ACCOUNT_TYPE.PRODUCT_ACCOUNT) {
      result = __spreadProps(__spreadValues({}, result), {
        name: account.attributes.name || account.attributes.username || account.attributes.site || ProductAccountsTranslations[account.attributes.type],
        source: account.attributes.type,
        type: ACCOUNT_TYPE.PRODUCT_ACCOUNT,
        count: account.relationships.product_feeds.meta.count,
        store_url: account.attributes.external_id,
        label: "product feed",
        site: (_a = account.attributes) == null ? void 0 : _a.site
      });
    } else if (account.type === ACCOUNT_TYPE.PRODUCT_FEED) {
      result = __spreadProps(__spreadValues({}, result), {
        name: account.attributes.name || ProductAccount[account.attributes.source].title || ProductFeedsTranslations[account.attributes.source],
        source: account.attributes.source,
        last_ingested_at: account.attributes.last_ingested_at,
        error_message: account.attributes.error_message,
        type: ACCOUNT_TYPE.PRODUCT_FEED,
        count: account.relationships.products.meta.count,
        label: "product"
      });
    } else {
      result = account;
    }
    return /* @__PURE__ */ React.createElement(
      AccountRow,
      {
        key: account.id,
        id: account.id,
        account: result,
        onRefresh,
        hasPermissionToRefresh,
        renderIcon,
        renderActionMenu: /* @__PURE__ */ React.createElement(
          ActionMenu,
          {
            account: result,
            onDelete,
            onEdit,
            onRefresh,
            onSyncFeed,
            onExportToCSV,
            hasPermissionToDelete,
            hasPermissionToEdit,
            hasPermissionToRead
          }
        )
      }
    );
  };
  return /* @__PURE__ */ React.createElement(TableListPrimary, { id: "accounts-table" }, accounts.map((account) => renderAccountRow(account)));
};
