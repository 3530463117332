import React from "react";
import "./table-list-primary-row.sass";
export const TableListPrimaryRow = ({ children, onClick, classNames, id }) => {
  return /* @__PURE__ */ React.createElement("div", { className: `${classNames} tint-table-list-primary-row`, "data-testid": id }, React.Children.map(children, (child, i) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-table-list-primary-row__cell",
        key: i,
        style: {
          flex: `${children[i].props["data-flex"]} 0 0`,
          minWidth: children[i].props["data-width"]
        }
      },
      React.cloneElement(child, {
        onClick
      })
    );
  }));
};
