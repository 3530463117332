import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { MARKETING_INTEGRATIONS } from "../../consts";
export const ACTIONS = {
  GET_MARKETING_INTEGRATIONS: createRoutine(
    createPrefixedActionName(MARKETING_INTEGRATIONS, "GET_MARKETING_INTEGRATIONS")
  ),
  POST_MARKETING_INTEGRATION: createRoutine(
    createPrefixedActionName(MARKETING_INTEGRATIONS, "POST_MARKETING_INTEGRATION")
  ),
  DELETE_MARKETING_INTEGRATION: createRoutine(
    createPrefixedActionName(MARKETING_INTEGRATIONS, "DELETE_MARKETING_INTEGRATION")
  ),
  REFRESH_MARKETING_INTEGRATION: createRoutine(
    createPrefixedActionName(MARKETING_INTEGRATIONS, "REFRESH_MARKETING_INTEGRATION")
  )
};
export const getMarketingIntegrationsStart = () => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS.REQUEST
});
export const getMarketingIntegrationsSuccess = (data) => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS.SUCCESS,
  payload: data
});
export const getMarketingIntegrationsError = (err) => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS.FAILURE,
  payload: err
});
export const postMarketingIntegrationStart = () => ({
  type: ACTIONS.POST_MARKETING_INTEGRATION.REQUEST
});
export const postMarketingIntegrationSuccess = (data) => ({
  type: ACTIONS.POST_MARKETING_INTEGRATION.SUCCESS,
  payload: data
});
export const postMarketingIntegrationError = (err) => ({
  type: ACTIONS.POST_MARKETING_INTEGRATION.FAILURE,
  payload: err
});
export const deleteMarketingIntegrationStart = () => ({
  type: ACTIONS.DELETE_MARKETING_INTEGRATION.REQUEST
});
export const deleteMarketingIntegrationSuccess = (id) => ({
  type: ACTIONS.DELETE_MARKETING_INTEGRATION.SUCCESS,
  payload: id
});
export const deleteMarketingIntegrationError = (err) => ({
  type: ACTIONS.DELETE_MARKETING_INTEGRATION.FAILURE,
  payload: err
});
export const refreshMarketingIntegrationStart = () => ({
  type: ACTIONS.REFRESH_MARKETING_INTEGRATION.REQUEST
});
export const refreshMarketingIntegrationSuccess = (data) => ({
  type: ACTIONS.REFRESH_MARKETING_INTEGRATION.SUCCESS,
  payload: data
});
export const refreshMarketingIntegrationError = (err) => ({
  type: ACTIONS.REFRESH_MARKETING_INTEGRATION.FAILURE,
  payload: err
});
