import React, { useEffect, useState } from "react";
import "./signage-display-settings-sidebar.sass";
import AccordionTab from "../../../../../../../../components/interface/accordion/accordion-tab/accordion-tab";
import Accordion from "../../../../../../../../components/interface/accordion/accordion";
import TintSidebarDefineList from "../../../../../../../../components/sidebar/components/define-list/sidebar-define-list";
import {
  chromeCastDefineList,
  SignageSidebarButtonActions,
  standardConnectionDefineList,
  tintMixDefineList
} from "./signage-display-settings-sidebar-data";
import { useHistory } from "react-router-dom";
import { Config } from "../../../../../../../../utils/config";
import { Chromecast } from "../../../../../../../../services/chromecast/chromecast";
import { toast } from "react-toastify";
import { GuardNames } from "../../../../../../../../guards/guards-list";
import { openSidebar } from "../../../../../../actions/tint-editor.actions";
import { ActionBarMenu } from "../../../../../action-bar/tints-action-bar";
import { useDispatch, useSelector } from "react-redux";
import { getSlugName } from "../../../../../../../../reducers/account";
import useFeatureAccessibility from "../../../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
const SignageDisplaySettingsSidebar = ({ switcherData, onChangeData }) => {
  const history = useHistory();
  const [chromecast, setChromecast] = useState(null);
  const session = useSelector((state) => state.session);
  const canActiveChromecast = useFeatureAccessibility(GuardNames.CHROMECAST);
  const plan = session.data.currentPlan;
  const dispatch = useDispatch();
  const slugName = useSelector(getSlugName);
  useEffect(() => {
    canActiveChromecast && setChromecast(new Chromecast());
    onChangeData({ isValid: true });
  }, []);
  const onDefineListButtonClick = (type) => {
    const selectDisplayStepNumber = 1;
    const { personalizationId } = switcherData[selectDisplayStepNumber];
    const url = `${Config.baseUrl()}/t/${slugName}?personalization_id=${personalizationId}&fullscreen=true`;
    switch (type) {
      case SignageSidebarButtonActions.DOWNLOAD_CHROME_CAST_APP:
        window.open(Config.url.externalLinks.chromecast, "_blank", "noopener");
        break;
      case SignageSidebarButtonActions.CREATE_TINT_MIX:
        history.push(Config.routePaths.newTintMix.path);
        break;
      case SignageSidebarButtonActions.BUT_INS_TINT_UPDATES:
        dispatch(
          openSidebar({
            actionName: ActionBarMenu.INSTINT_UPDATES
          })
        );
        break;
      case SignageSidebarButtonActions.OPEN_IN_NEW_TAB:
        window.open(url, "_blank", "noopener");
        break;
      case SignageSidebarButtonActions.CHROMECAST_OPEN_IN_NEW_TAB:
        if (!navigator.userAgent.match(Config.regExp.chromeCastRegex)) {
          toast.error("Your browser does not support Chromecast");
          return;
        }
        if (!canActiveChromecast) {
          toast.info("Update you plan");
        }
        chromecast.sendMessage(`t/${slugName}?personalization_id=${personalizationId}`);
        break;
      default:
        return;
    }
  };
  const tintMixList = tintMixDefineList(onDefineListButtonClick, plan);
  const chromeCastList = chromeCastDefineList(onDefineListButtonClick, plan);
  const standardConnectionList = standardConnectionDefineList(onDefineListButtonClick, plan);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-signage-display-settings-sidebar" }, /* @__PURE__ */ React.createElement(Accordion, null, /* @__PURE__ */ React.createElement(AccordionTab, { title: "Standard Connection", isActive: true }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TintSidebarDefineList, { list: standardConnectionList }))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Chromecast" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TintSidebarDefineList, { list: chromeCastList }))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "TINTmix" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TintSidebarDefineList, { list: tintMixList })))));
};
export default SignageDisplaySettingsSidebar;
