var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import "./units-input.sass";
import DropdownButton, { ButtonType } from "../dropdown/button/dropdown-button";
import { DropdownHeader } from "../dropdown/button/header/dropdown-header";
import DropdownSimpleList from "../dropdown/lists/simple-list/dropdown-simple-list";
import InputPrimary from "../inputs/input-primary/input-primary";
const Units = {
  PIXEL: "px",
  PERCENT: "%"
};
export const unitList = Object.values(Units).map((unit) => ({
  name: unit,
  value: unit
}));
const UnitsInput = ({ isDisabled, label, formik, id, name, formikValueKey }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-units-input" }, label && /* @__PURE__ */ React.createElement("label", null, label), /* @__PURE__ */ React.createElement("div", { className: "tint-units-input__wrapper" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      type: "number",
      name,
      id,
      value: formik.values[formikValueKey],
      placeholder: "ex. 100",
      handleChange: (e) => {
        formik.handleChange(e);
      },
      disabled: isDisabled,
      error: formik.errors[formikValueKey],
      touched: !!formik.errors[formikValueKey],
      style: {
        border: "none"
      }
    }
  ), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: formik.values.unit,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, props), { isDefaultOption: false })),
      list: unitList,
      disabled: isDisabled,
      placeholder: "px",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_NO_STYL,
      onChangeValue: (selectedItem) => {
        formik.setFieldValue("unit", selectedItem);
      }
    }
  )));
};
export default UnitsInput;
