var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/builder/form-builder-submission.builder.actions";
import produce from "immer";
export const initialState = {
  isFetching: false,
  included: [],
  meta: void 0,
  links: {
    self: void 0,
    prev: void 0,
    next: void 0
  },
  data: []
};
export const formBuilderSubmissionBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUBMISSION.REQUEST:
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    case ACTIONS.GET_SUBMISSION.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b, _c, _d;
        draft.data = (_a = action.payload) == null ? void 0 : _a.data.map((el) => {
          var _a2, _b2, _c2, _d2;
          const relationships = (_b2 = (_a2 = el.attributes) == null ? void 0 : _a2.data) == null ? void 0 : _b2.relationships;
          (_d2 = (_c2 = el.attributes) == null ? void 0 : _c2.data) == null ? true : delete _d2.relationships;
          return __spreadProps(__spreadValues({}, el), {
            attributes: __spreadProps(__spreadValues({}, el.attributes), {
              data: __spreadValues(__spreadValues({}, el.attributes.data), relationships && typeof relationships === "object" ? Object.entries(relationships).reduce((obj, [key, value]) => {
                var _a3, _b3, _c3;
                const files = (_c3 = (_b3 = (_a3 = action.payload) == null ? void 0 : _a3.included) == null ? void 0 : _b3.filter((e) => (e == null ? void 0 : e.type) === "upload")) == null ? void 0 : _c3.filter((e) => {
                  var _a4;
                  return (_a4 = value == null ? void 0 : value.data) == null ? void 0 : _a4.some((file) => (file == null ? void 0 : file.id) === (e == null ? void 0 : e.id) && (file == null ? void 0 : file.type) === "upload");
                });
                return Object.assign(obj, { [key]: files });
              }, {}) : {})
            })
          });
        });
        draft.included = (_b = action.payload) == null ? void 0 : _b.included;
        draft.meta = (_c = action.payload) == null ? void 0 : _c.meta;
        draft.links = (_d = action.payload) == null ? void 0 : _d.links;
        draft.isFetching = false;
      });
    case ACTIONS.GET_SUBMISSION.FAILURE:
      return produce(state, (draft) => {
        draft.isFetching = false;
      });
    case ACTIONS.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
