import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { formsDisabledButton, postMediaAltTextDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { SocialFeedsAccountTypes, BackendKeys, OptionsKeys, FormMediaKeys } from "../consts";
import {
  initializeFormId,
  initializeAuthorNameValue,
  initializeAuthorEmailValue,
  initializeAuthorAvatarValue,
  initializePostTextValue,
  initializeTagsValue,
  initializePostAlternativeTextValue,
  initializeMediaValue,
  validationSchemaPublicPosting
} from "../utils/validation-schema/public-posting-schema";
export const publicPostingUpgrade = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave
    },
    accountSourceType: "public_post",
    selectedOption: { id: "post", title: "Add Public Posting" },
    header: "Add Public Posting",
    description: "Public posting adds a button to the top left of your display, allowing users to post content directly to your board.",
    formSchema: {
      post: {
        validationSchema: validationSchemaPublicPosting(),
        submitMapper: (values) => {
          var _a, _b, _c, _d, _e, _f, _g, _h, _i;
          return {
            form_id: values.form.value,
            options: {
              [OptionsKeys.AUTHOR_NAME_FIELD]: ((_a = values.name) == null ? void 0 : _a.value) || "",
              [OptionsKeys.AUTHOR_EMAIL_FIELD]: ((_b = values.email) == null ? void 0 : _b.value) || "",
              [OptionsKeys.AUTHOR_AVATAR_FIELD]: ((_c = values.avatar) == null ? void 0 : _c.value) || "",
              [OptionsKeys.POST_TEXT_FIELD]: ((_d = values.text) == null ? void 0 : _d.value) || "",
              [OptionsKeys.POST_ALT_TEXT_FIELD]: ((_e = values.media) == null ? void 0 : _e.value) && ((_f = values.alternative_text) == null ? void 0 : _f.value) ? (_g = values.alternative_text) == null ? void 0 : _g.value : "",
              [OptionsKeys.MEDIA]: ((_h = values.media) == null ? void 0 : _h.value) || "",
              [OptionsKeys.TAGS]: ((_i = values.tags) == null ? void 0 : _i.value) || ""
            }
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: "SELECT FORM",
            placeholder: "Select Form...",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "name",
            backendId: OptionsKeys.AUTHOR_NAME_FIELD,
            label: "SELECT AUTHOR NAME",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s author name.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "email",
            backendId: OptionsKeys.AUTHOR_EMAIL_FIELD,
            label: "SELECT AUTHOR EMAIL",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s author email.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: FormMediaKeys.AVATAR,
            backendId: OptionsKeys.AUTHOR_AVATAR_FIELD,
            label: "SELECT AUTHOR AVATAR",
            placeholder: "Select...",
            description: "Choose the author avatar field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: "text",
            backendId: OptionsKeys.POST_TEXT_FIELD,
            label: "POST TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s body content section.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "tags",
            backendId: OptionsKeys.TAGS,
            label: "POST TAGS",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s tags.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTagsValue,
            advanced: true
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: OptionsKeys.MEDIA,
            label: "SELECT MEDIA",
            placeholder: "Select...",
            description: "Choose the media field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: FormMediaKeys.POST_MEDIA_ALT_TEXT,
            backendId: OptionsKeys.POST_ALT_TEXT_FIELD,
            label: "POST MEDIA ALTERNATIVE TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s alternative text.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: postMediaAltTextDisabledButton
          }
        ],
        editInputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: "SELECT FORM",
            placeholder: "Select Form...",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            defaultValue: initializeFormId,
            lineBreak: true
          },
          {
            id: "name",
            backendId: OptionsKeys.AUTHOR_NAME_FIELD,
            label: "SELECT AUTHOR NAME",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s author name.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeAuthorNameValue,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "email",
            backendId: OptionsKeys.AUTHOR_EMAIL_FIELD,
            label: "SELECT AUTHOR EMAIL",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s author email.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeAuthorEmailValue,
            disabled: formsDisabledButton
          },
          {
            id: FormMediaKeys.AVATAR,
            backendId: OptionsKeys.AUTHOR_AVATAR_FIELD,
            label: "SELECT AUTHOR AVATAR",
            placeholder: "Select...",
            description: "Choose the author avatar field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeAuthorAvatarValue,
            disabled: formsDisabledButton
          },
          {
            id: "text",
            backendId: OptionsKeys.POST_TEXT_FIELD,
            label: "POST TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s body content section.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializePostTextValue,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "tags",
            backendId: OptionsKeys.TAGS,
            label: "POST TAGS",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s tags.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTagsValue,
            advanced: true
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: OptionsKeys.MEDIA,
            label: "SELECT MEDIA",
            placeholder: "Select...",
            description: "Choose the media field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeMediaValue,
            disabled: formsDisabledButton
          },
          {
            id: FormMediaKeys.POST_MEDIA_ALT_TEXT,
            backendId: OptionsKeys.POST_ALT_TEXT_FIELD,
            label: "POST MEDIA ALTERNATIVE TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s alternative text.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializePostAlternativeTextValue,
            disabled: postMediaAltTextDisabledButton
          }
        ]
      }
    }
  }
];
