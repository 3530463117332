import { EditInputDropdown } from "../../../../feature/experience/components/experience";
import React from "react";
import "./font-size.sass";
export const FontSizeDropdownEnum = {
  FOURTEEN: 14,
  SIXTEEN: 16,
  EIGHTEEN: 18,
  TWENTY_FOUR: 24,
  THIRTY: 30,
  THIRTY_SIX: 36,
  FORTY_EIGHT: 48,
  SIXTY: 60,
  SEVENTY_TWO: 72,
  EIGHTY_FOUR: 84,
  NINETY_SIX: 96
};
const FontSizeDropdown = ({ fontSize, styleUpdate }) => {
  function getOutlineOptions() {
    return [
      { value: FontSizeDropdownEnum.FOURTEEN, name: "14px" },
      { value: FontSizeDropdownEnum.SIXTEEN, name: "16px" },
      { value: FontSizeDropdownEnum.EIGHTEEN, name: "18px" },
      { value: FontSizeDropdownEnum.TWENTY_FOUR, name: "24px" },
      { value: FontSizeDropdownEnum.THIRTY, name: "30px" },
      { value: FontSizeDropdownEnum.THIRTY_SIX, name: "36px" },
      { value: FontSizeDropdownEnum.FORTY_EIGHT, name: "48px" },
      { value: FontSizeDropdownEnum.SIXTY, name: "60px" },
      { value: FontSizeDropdownEnum.SEVENTY_TWO, name: "72px" },
      { value: FontSizeDropdownEnum.EIGHTY_FOUR, name: "84px" },
      { value: FontSizeDropdownEnum.NINETY_SIX, name: "96px" }
    ];
  }
  return /* @__PURE__ */ React.createElement("div", { className: "font-size-dropdown" }, /* @__PURE__ */ React.createElement(
    EditInputDropdown,
    {
      isControlled: true,
      value: fontSize,
      onChange: (e) => {
        styleUpdate(e.target.value);
      },
      options: getOutlineOptions()
    }
  ));
};
export default FontSizeDropdown;
