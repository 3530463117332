var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useRef, useEffect, forwardRef } from "react";
import "./dropdown-button.sass";
import useClickOutside from "../../../hooks/use-click-outside/use-click-outside";
import PropTypes from "prop-types";
export const DropdownButtonType = {
  MULTI_SELECT: "multiSelect",
  SINGLE_SELECT: "singleSelect",
  SEARCH_INPUT: "searchInput",
  RADIO_BUTTON: "radioButton"
};
export const ButtonType = {
  BUTTON_DEFAULT: "buttonDefault",
  BUTTON_DATE: "buttonDate",
  BUTTON_DATE_RANGE: "buttonDateRange",
  BUTTON_SORT: "buttonSort",
  BUTTON_SORT_ACTIVE: "buttonSortActive",
  BUTTON_PRIMARY: "buttonPrimary",
  BUTTON_GRAY: "buttonGray",
  BUTTON_BORDER: "buttonBorder",
  BUTTON_BORDER_FULL_WIDTH: "buttonBorderFullWidth",
  BUTTON_GRAY_BORDER: "buttonGrayBorder",
  BUTTON_SPREAD: "buttonSpread",
  BUTTON_ACTIVE: "buttonActive",
  BUTTON_WHITE: "buttonWhite",
  BUTTON_ICON: "buttonIcon",
  BUTTON_SOFT_GRAY: "softGray",
  BUTTON_ICON_DARK: "buttonIconDark",
  BUTTON_NO_STYL: "buttonNoStyle",
  NEW_BRAND_BUTTON: "newBrandButton",
  NEW_BUTTON_TRANSPARENT: "newButtonTransparent"
};
const DropdownButton = ({
  dropdownType = DropdownButtonType.SINGLE_SELECT,
  list = [],
  currentItem,
  buttonType,
  placeholder,
  dropdownListStyle,
  dropdownList,
  dropdownHeader,
  dropdownHeaderStyle,
  iconLeft,
  iconRight,
  iconCenter,
  iconOverlap,
  onChangeValue,
  width,
  onAction,
  styles,
  disabled,
  children,
  rerenderHeader = true,
  onToggle,
  onOpen,
  blankOption,
  isLoading
}, ref) => {
  const node = useRef(ref);
  const [isVisible, setVisibility] = useClickOutside(node);
  const [currentList, setCurrentList] = useState([]);
  const [headerStyle, setHeaderStyle] = useState(dropdownHeaderStyle);
  const [inputListValue, setInputListValue] = useState("");
  useEffect(() => {
    onToggle == null ? void 0 : onToggle(isVisible);
  }, [isVisible]);
  const renderDropDownList = () => {
    return dropdownList({
      title: placeholder,
      rerenderHeader,
      onChange: handleDropdownListVisibility,
      onChangeTextComponent: onRerenderHeader,
      onClose: onCloseList,
      parentRef: node,
      currentItems: currentList || list,
      currentItem,
      iconLeft,
      iconRight,
      iconCenter,
      onAction: onActionClick,
      children,
      dropdownHeaderStyle,
      styles: dropdownListStyle,
      setInputListValue,
      inputValue: inputListValue,
      blankOption
    });
  };
  const onCloseList = () => {
    setVisibility(false);
  };
  const handleDropdownListVisibility = (selectedItem) => {
    switch (dropdownType) {
      case DropdownButtonType.MULTI_SELECT:
        onMultiSelectChange(selectedItem);
        break;
      case DropdownButtonType.RADIO_BUTTON:
        onChangeRadioButton(__spreadProps(__spreadValues({}, selectedItem), {
          isActive: true
        }));
        break;
      case DropdownButtonType.SEARCH_INPUT:
      case DropdownButtonType.SINGLE_SELECT:
        onChange(selectedItem);
        break;
      default:
        return;
    }
  };
  const renderDropDownHeader = (textHeader) => {
    return dropdownHeader({
      title: textHeader || placeholder,
      iconLeft,
      iconRight,
      iconCenter,
      iconOverlap,
      children,
      dropdownHeaderStyle: headerStyle,
      currentItem,
      isLoading
    });
  };
  const [dropdownHeaderComponent, setDropdownHeaderComponent] = useState(renderDropDownHeader(placeholder));
  useEffect(() => {
    switch (dropdownType) {
      case DropdownButtonType.MULTI_SELECT:
        handleMultiSelectDropdown();
        break;
      case DropdownButtonType.RADIO_BUTTON:
      case DropdownButtonType.SEARCH_INPUT:
      case DropdownButtonType.SINGLE_SELECT:
        handleSingleSelectDropdown();
        break;
      default:
        return;
    }
  }, [currentItem, isLoading]);
  useEffect(() => {
    setCurrentList(list);
  }, [currentList, list]);
  useEffect(() => {
    if (!dropdownHeaderStyle && !headerStyle) return;
    if (dropdownHeaderStyle && headerStyle && JSON.stringify(headerStyle) !== JSON.stringify(dropdownHeaderStyle)) {
      setDropdownHeaderComponent(renderDropDownHeader(currentItem ? currentItem.name : placeholder));
      setHeaderStyle(dropdownHeaderStyle);
    }
  }, [dropdownHeaderStyle]);
  const handleMultiSelectDropdown = () => {
    if (!currentItem.find((item) => item.isActive)) {
      setDropdownHeaderComponent(renderDropDownHeader(placeholder));
      setCurrentList(currentList.map((item) => __spreadProps(__spreadValues({}, item), { isActive: false })));
    } else {
      setCurrentList(currentItem);
    }
  };
  const handleSingleSelectDropdown = () => {
    setDropdownHeaderComponent(renderDropDownHeader(currentItem ? currentItem.name : placeholder));
    setCurrentList(mapItemList(currentItem));
  };
  const onChange = (selectedItem) => {
    onCloseList();
    setCurrentList(mapItemList(selectedItem));
    onChangeValue == null ? void 0 : onChangeValue(selectedItem);
  };
  const onActionClick = (el) => {
    onAction(el.target.value);
    onCloseList();
  };
  const onChangeRadioButton = (selectedItem) => {
    setVisibility(true);
    setCurrentList(mapItemList(selectedItem));
    onChangeValue == null ? void 0 : onChangeValue(selectedItem);
  };
  const onMultiSelectChange = (selectedItem) => {
    setVisibility(true);
    setCurrentList(mapMultiSelectList(selectedItem));
    onChangeValue == null ? void 0 : onChangeValue(mapMultiSelectList(selectedItem), selectedItem);
  };
  const mapItemList = (selectedItem) => {
    return currentList.map((element) => {
      return __spreadProps(__spreadValues({}, element), {
        isActive: selectedItem && element.value === selectedItem.value
      });
    });
  };
  const mapMultiSelectList = (selectedItem) => {
    return currentList.map((element) => {
      return __spreadProps(__spreadValues({}, element), {
        isActive: element.isActive ? element.value !== selectedItem.value : element.value === selectedItem.value
      });
    });
  };
  const onRerenderHeader = (componentRef) => {
    setDropdownHeaderComponent(componentRef);
  };
  const getButtonClassName = (buttonType2) => {
    switch (buttonType2) {
      default:
      case ButtonType.BUTTON_DEFAULT:
        return "dropdown-button__default";
      case ButtonType.BUTTON_GRAY:
        return "dropdown-button__gray";
      case ButtonType.BUTTON_PRIMARY:
        return "dropdown-button__primary";
      case ButtonType.BUTTON_SORT:
        return "dropdown-button__sort";
      case ButtonType.BUTTON_SORT_ACTIVE:
        return "dropdown-button__sort-active";
      case ButtonType.BUTTON_DATE:
        return "dropdown-button__date";
      case ButtonType.BUTTON_DATE_RANGE:
        return "dropdown-button__date-range";
      case ButtonType.BUTTON_BORDER:
        return "dropdown-button__border";
      case ButtonType.BUTTON_BORDER_FULL_WIDTH:
        return "dropdown-button__border dropdown-button__border--full-width";
      case ButtonType.BUTTON_GRAY_BORDER:
        return "dropdown-button__light-gray-border";
      case ButtonType.BUTTON_SPREAD:
        return "dropdown-button__spread";
      case ButtonType.BUTTON_ACTIVE:
        return "dropdown-button__active";
      case ButtonType.BUTTON_WHITE:
        return "dropdown-button__white";
      case ButtonType.BUTTON_SOFT_GRAY:
        return "dropdown-button__soft-gray";
      case ButtonType.BUTTON_ICON:
        return "dropdown-button__icon";
      case ButtonType.BUTTON_ICON_DARK:
        return "dropdown-button__icon--dark";
      case ButtonType.BUTTON_NO_STYL:
        return "dropdown-button__no-style";
      case ButtonType.NEW_BRAND_BUTTON:
        return "dropdown-button__new-brand-button";
      case ButtonType.NEW_BUTTON_TRANSPARENT:
        return "dropdown-button__new-button-transparent";
    }
  };
  const buttonClass = getButtonClassName(buttonType);
  const getDropdownActivityStatus = () => {
    switch (dropdownType) {
      case DropdownButtonType.MULTI_SELECT:
        return currentItem[0] && currentItem.find((item) => item.isActive);
      case DropdownButtonType.SEARCH_INPUT:
      case DropdownButtonType.SINGLE_SELECT:
        return currentItem;
      default:
        return;
    }
  };
  const statusClass = getDropdownActivityStatus() || isVisible ? "dropdown-button--active" : "";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, disabled ? /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadValues({ width }, styles),
      className: "dropdown-button dropdown-button__inactive",
      ref: node,
      "data-testid": "dropdown-button-inactive"
    },
    /* @__PURE__ */ React.createElement("div", { className: "dropdown-button__title" }, dropdownHeaderComponent)
  ) : /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadValues({ width }, styles),
      className: `dropdown-button ${buttonClass} ${statusClass}`,
      ref: node,
      onClick: (e) => {
        e.stopPropagation();
        if (!isVisible) {
          onOpen && onOpen();
        }
        setVisibility(!isVisible);
      },
      "data-testid": "dropdown-button"
    },
    /* @__PURE__ */ React.createElement("div", { className: "dropdown-button__title", "data-testid": "dropdown-button-title" }, dropdownHeaderComponent),
    isVisible && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "dropdown-button__list",
        style: { display: isVisible ? "block" : "none" },
        onClick: (e) => e.stopPropagation()
      },
      renderDropDownList()
    )
  ));
};
DropdownButton.displayName = "DropdownButton";
export default forwardRef(DropdownButton);
DropdownButton.propTypes = {
  list: PropTypes.array,
  buttonType: PropTypes.string,
  onOpen: PropTypes.func,
  currentItem: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dropdownList: PropTypes.func,
  dropdownHeader: PropTypes.any,
  iconLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconCenter: PropTypes.string,
  width: PropTypes.string,
  onChangeValue: PropTypes.func,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  dropdownType: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};
