var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/discover.actions";
export const initialState = {
  data: {},
  isFetching: false,
  error: void 0,
  // selectedCategory: categoryList[1].value,
  ingestedMetricsData: {},
  rightRequestsMetricData: {},
  rightApprovedMetricData: {},
  communityInsights: {}
};
export const discover = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_CATEGORY:
      return __spreadProps(__spreadValues({}, state), {
        selectedCategory: action.payload
      });
    case ACTIONS.GET_CONTENT_COLLECTED.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        ingestedMetricsData: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_RIGHTS_APPROVED_METRICS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        rightApprovedMetricData: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_RIGHTS_REQUEST_METRICS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        rightRequestsMetricData: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_RECENT_TINT_CONTENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        communityInsights: __spreadProps(__spreadValues({}, state.communityInsights), {
          communityGeneratedContentBreakdown: action.payload.data
        })
      });
    case ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        communityInsights: __spreadProps(__spreadValues({}, state.communityInsights), {
          communityMembersPrevCount: action.payload.data
        })
      });
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        communityInsights: __spreadProps(__spreadValues({}, state.communityInsights), {
          communityMembersCurrentCount: action.payload.data
        })
      });
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        communityInsights: __spreadProps(__spreadValues({}, state.communityInsights), {
          totalCommunityMembersPrevCount: action.payload.data
        })
      });
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        communityInsights: __spreadProps(__spreadValues({}, state.communityInsights), {
          totalCommunityMembersCurrentCount: action.payload.data
        })
      });
    case ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.REQUEST:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.REQUEST:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.REQUEST:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.REQUEST:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.REQUEST:
    case ACTIONS.GET_RECENT_TINT_CONTENT.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        isError: false
      });
    case ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.FAILURE:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.FAILURE:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.FAILURE:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.FAILURE:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.FAILURE:
    case ACTIONS.GET_RECENT_TINT_CONTENT.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        isError: action.payload
      });
    default:
      return state;
  }
};
