var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import "./edit-tint-content-new.sass";
import "../../../../../../../components/sidebar/sidebar.sass";
import { useSelector } from "react-redux";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import PreloaderComponent, {
  PreloaderPositionType
} from "../../../../../../../components/interface/preloaders/preloader/preloader";
import DropdownSortList from "../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import DropdownButton, { ButtonType } from "../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import { InputGroup } from "../../../../../../../components/interface/inputs/input-group/input-group";
import InputPrimary from "../../../../../../../components/interface/inputs/input-primary/input-primary";
import SelectPersonalizationWrapper from "../../../../../../../components/wrappers/select-personalization-wrapper/select-personalization-wrapper";
import SelectTintWrapper from "../../../../../../../components/wrappers/select-tint-wrapper/select-tint-wrapper";
import useSavedFilters from "../../../../../../../components/hooks/use-saved-filters/use-saved-filters";
const clickForMore = {
  name: "Click for More Button",
  value: '{"data-clickformore":"true"}',
  description: "More posts will load on your TINT when a button is clicked."
};
const pageByPage = {
  name: "Page by Page",
  value: '{"data-paginate":"true"}',
  description: "A fixed number of posts is shown, with buttons to navigate from page to page."
};
const infinityScroll = {
  name: "Infinite Scroll",
  value: '{"data-infinitescroll":"true" }',
  description: "More posts will load automatically on your TINT as you scroll down."
};
const filters = {
  name: "Select Filter",
  value: "{}"
};
const tintHeightType = [
  {
    name: "Fixed",
    value: "Fixed",
    isHeightInputDisplayed: true
  },
  {
    name: "Unlimited",
    value: "Unlimited",
    isHeightInputDisplayed: false
  }
];
export const EditTintContentNew = ({ dataUpdate, data }) => {
  var _a;
  const selectedTintSlug = data.content.tintName;
  const selectedPersonalization = data.content.personalizationId;
  const heightType = tintHeightType.find((element) => element.value === data.content.heightType);
  const selectedTintId = useSelector((state) => {
    var _a2, _b;
    return (_b = (_a2 = state.account) == null ? void 0 : _a2.tint) == null ? void 0 : _b.id;
  });
  const savedFilters = useSelector((state) => state.tintEditorPosts.filters);
  const savedFiltersList = useSavedFilters({ tintId: selectedTintId });
  const isFetchingTints = useSelector((state) => state.tintEditor.isFetching);
  const isFetchingPersonalizations = useSelector((state) => state.personalization.isPending);
  const [tints, setTints] = useState([]);
  const [isHeightInputDisplayed, setIsHeightInputDisplayed] = useState(
    heightType ? heightType.isHeightInputDisplayed : false
  );
  const tintDisplayTypes = isHeightInputDisplayed ? [clickForMore, pageByPage, infinityScroll] : [clickForMore, infinityScroll];
  const currentDisplayType = data.content.displayType ? tintDisplayTypes.find((e) => e.value === data.content.displayType) : clickForMore;
  const mapSavedFilters = (data2) => {
    var _a2;
    return (data2 == null ? void 0 : data2.id) ? { name: (_a2 = data2 == null ? void 0 : data2.attributes) == null ? void 0 : _a2.name, value: data2 == null ? void 0 : data2.id } : filters;
  };
  const currentSavedFilter = data.content.savedFilterId && savedFilters ? mapSavedFilters(savedFilters == null ? void 0 : savedFilters.find((e) => e.id === data.content.savedFilterId)) : filters;
  const currentDescription = data.content.displayType ? (_a = tintDisplayTypes.find((e) => e.value === data.content.displayType)) == null ? void 0 : _a.description : "";
  const onTintChange = (e) => {
    return dataUpdate({
      tintName: e.slugName,
      savedFilterId: e.slugName !== data.content.tintName ? null : data.content.savedFilterId
    });
  };
  const onLoadTints = ({ tintList = [] } = {}) => {
    const loadedTints = [...tints, ...tintList];
    setTints(loadedTints);
  };
  const renderDropdownTints = () => {
    return /* @__PURE__ */ React.createElement(
      SelectTintWrapper,
      {
        currentItemSlug: !selectedTintId && selectedTintSlug,
        currentItemId: selectedTintId,
        onLoadIncludedItems: onLoadTints,
        onChange: onTintChange
      }
    );
  };
  const renderDropdownPersonalizations = () => {
    return /* @__PURE__ */ React.createElement(
      SelectPersonalizationWrapper,
      {
        currentItemId: selectedPersonalization,
        onChange: (e) => dataUpdate({ personalizationId: e.value })
      }
    );
  };
  const renderDropdownHeightType = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(InputGroup, { label: "Height Type" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        currentItem: heightType,
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: tintHeightType,
        placeholder: "Select Personalization",
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_SORT,
        onChangeValue: (e) => {
          var _a2;
          const value = e.value;
          const isHeightInputDisplayed2 = (_a2 = tintHeightType.find((type) => type.value === value)) == null ? void 0 : _a2.isHeightInputDisplayed;
          setIsHeightInputDisplayed(isHeightInputDisplayed2);
          dataUpdate({ heightType: value });
        }
      }
    )), isHeightInputDisplayed ? /* @__PURE__ */ React.createElement(InputGroup, { label: "Height" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        name: "height",
        type: "text",
        value: data.content.height,
        placeholder: "500",
        handleChange: (e) => dataUpdate({ height: e.target.value })
      }
    )) : null);
  };
  const renderDropdownDisplayType = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(InputGroup, { label: "Display Type" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        currentItem: currentDisplayType,
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: tintDisplayTypes,
        placeholder: "Select Display Type",
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_SORT,
        onChangeValue: (e) => dataUpdate({ displayType: e.value })
      }
    )), /* @__PURE__ */ React.createElement("span", { className: "tint-edit-input-dropdown-container__description" }, currentDescription));
  };
  const renderDropdownNoColumns = () => {
    return /* @__PURE__ */ React.createElement(InputGroup, { label: "# of columns" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        name: "height",
        type: "text",
        value: data.content.ofColumns,
        placeholder: "example: 6",
        handleChange: (e) => {
          dataUpdate({ ofColumns: e.target.value });
        }
      }
    ));
  };
  const renderDropdownSavedFilters = () => {
    return /* @__PURE__ */ React.createElement(InputGroup, { label: "Saved Filters" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        currentItem: currentSavedFilter,
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: savedFiltersList,
        placeholder: "Select Filter",
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_SORT,
        onChangeValue: (e) => dataUpdate({ savedFilterId: e.id })
      }
    ));
  };
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Connect your TINT board by selecting a TINT board and a personalization from the lists below." }, (isFetchingTints || isFetchingPersonalizations) && /* @__PURE__ */ React.createElement(PreloaderComponent, { positionType: PreloaderPositionType.ABSOLUTE }), renderDropdownTints(), renderDropdownPersonalizations(), /* @__PURE__ */ React.createElement("p", { className: "tint-edit-modal-body__label" }, "Advanced Settings"), renderDropdownHeightType(), renderDropdownDisplayType(), renderDropdownNoColumns(), renderDropdownSavedFilters());
};
