var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/asset-manager.actions";
import { assetDisplayType } from "../model/asset-display-type";
import produce from "immer";
export const initialState = {
  data: [],
  meta: { aggregations: { emotions: [] } },
  links: void 0,
  isFetching: false,
  error: void 0,
  isBulkTagSidebarOpen: false,
  selectedLightBoxAsset: void 0,
  isCreateAssetModalOpened: false,
  displayType: assetDisplayType.GRID_SMALL
};
export const assetManager = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_LIGHT_BOX_ASSET:
      return __spreadProps(__spreadValues({}, state), { selectedLightBoxAsset: action.payload });
    case ACTIONS.CLEAR_ASSETS_STATE:
      return __spreadProps(__spreadValues({}, initialState), { links: state.links, meta: state.meta });
    case ACTIONS.TOGGLE_BULK_TAG_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        isBulkTagSidebarOpen: action.payload
      });
    case ACTIONS.UPDATE_ASSET.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: state.data.map((asset) => {
          return __spreadValues({}, asset);
        })
      });
    case ACTIONS.UPDATE_BULK_TAG.SUCCESS:
      return produce(state, (draft) => {
        draft.data = state.data.map((asset) => {
          const currentAsset = action.payload.find((_asset) => _asset.id === asset.id);
          return currentAsset ? __spreadValues(__spreadValues({}, asset), currentAsset) : asset;
        });
        draft.isFetching = false;
      });
    case ACTIONS.UPDATE_ASSET_TAGS.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: state.data.map((asset) => {
          asset.id === action.payload.id ? asset.attributes.tags = action.payload.attributes.tags : [];
          return asset;
        })
      });
    case ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.REQUEST:
    case ACTIONS.UPDATE_BULK_TAG.REQUEST:
    case ACTIONS.POST_ASSETS.REQUEST:
    case ACTIONS.GET_ASSETS.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        isError: false
      });
    case ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = draftState.data.map((item) => {
          var _a;
          const update = action.payload.data.find((el) => el.id === item.id);
          if (update) {
            const mapUpdatedElement = __spreadProps(__spreadValues({}, update), {
              isSelected: false,
              collections: (_a = action.payload.included) == null ? void 0 : _a.filter(
                (e) => {
                  var _a2, _b, _c;
                  return e.type === "collection" && ((_c = (_b = (_a2 = update == null ? void 0 : update.relationships) == null ? void 0 : _a2.collections) == null ? void 0 : _b.data) == null ? void 0 : _c.some((r) => r.id === e.id));
                }
              )
            });
            return __spreadValues(__spreadValues({}, item), mapUpdatedElement);
          } else {
            return item;
          }
        });
        draftState.isFetching = false;
        draftState.isError = false;
      });
    case ACTIONS.GET_ASSETS.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: action.payload.assets,
        included: action.payload.included,
        links: action.payload.links,
        meta: action.payload.meta,
        isFetching: false,
        isError: false
      });
    case ACTIONS.POST_ASSETS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        isError: false
      });
    case ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.FAILURE:
    case ACTIONS.UPDATE_BULK_TAG.FAILURE:
    case ACTIONS.GET_ASSETS.FAILURE:
    case ACTIONS.POST_ASSETS.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isError: action.payload,
        isFetching: false
      });
    case ACTIONS.OPEN_CREATE_ASSET_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isCreateAssetModalOpened: true
      });
    case ACTIONS.CLOSE_CREATE_ASSET_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isCreateAssetModalOpened: false
      });
    default:
      return state;
  }
};
export const getSelectedAssets = (state) => {
  return state && state.data ? state.data.filter((e) => e.isSelected) : [];
};
export const getBulkTagSidebarVisibility = (state) => {
  return state && (state == null ? void 0 : state.assetManager.isBulkTagSidebarOpen);
};
export const isOneAssetSelected = (state) => getSelectedAssets(state).length === 1;
export const isAnyAssetSelected = (state) => {
  return getSelectedAssets(state).length > 0;
};
export const getAllAssets = (state) => {
  var _a;
  return state && ((_a = state == null ? void 0 : state.assetManager) == null ? void 0 : _a.data);
};
export const getSelectedAsset = (state) => {
  if (!state) return;
  const assets = getAllAssets(state);
  return getSelectedAssets(state.assetManager).length === 1 ? assets.find((el) => el.id === state.assetManager.selectedAssetId) : {};
};
export const getSelectedAssetCollections = (state) => {
  var _a;
  if (!state) return [];
  const selectedAssetsLength = state.assetManager.data.filter((e) => e.isSelected).length;
  const currentAsset = selectedAssetsLength === 1 ? state.assetManager.data.find((el) => el.isSelected) : {};
  return currentAsset ? (_a = currentAsset == null ? void 0 : currentAsset.collections) == null ? void 0 : _a.map((collection) => {
    var _a2;
    return {
      value: collection.id,
      name: (_a2 = collection == null ? void 0 : collection.attributes) == null ? void 0 : _a2.name
    };
  }) : [];
};
export const getCurrentAsset = (state) => {
  var _a;
  return state && ((_a = state == null ? void 0 : state.asset) == null ? void 0 : _a.asset);
};
