import React from "react";
import "./engagement-by-type.sass";
import { useSelector } from "react-redux";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { InsightsSection } from "../../../section/insights-section";
import { useInsightsPerformanceData } from "../../../../hooks/use-insights-performance-data";
import { ACTIONS } from "../../../../actions/performance/insights-performance.actions";
import { getEngagementByTypeData } from "../../../../reducers/insights-performance.reducer";
import { CustomTooltip } from "../../../custom-tooltip/custom-tooltip";
import { numberWithCommas } from "../../../../../../services/functions/number-format/number-format";
import { InsightsNoData } from "../../../no-data/insights-no-data";
export const MEDIA_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
  CAROUSEL: "carousel",
  TEXT_ONLY: "text_only"
};
const COLORS = {
  [MEDIA_TYPE.IMAGE.toUpperCase()]: "#3B99FC",
  [MEDIA_TYPE.VIDEO.toUpperCase()]: "#FBB923",
  [MEDIA_TYPE.CAROUSEL.toUpperCase()]: "#8f23fb",
  [MEDIA_TYPE.TEXT_ONLY.toUpperCase()]: "#1CC666"
};
const mediaTypeToReadableString = {
  [MEDIA_TYPE.IMAGE]: "Image",
  [MEDIA_TYPE.VIDEO]: "Video",
  [MEDIA_TYPE.CAROUSEL]: "Carousel",
  [MEDIA_TYPE.TEXT_ONLY]: "Text Only"
};
export const EngagementByType = () => {
  const insights = useSelector((state) => state.insightsPerformance);
  const [, targetRef] = useInsightsPerformanceData([ACTIONS.GET_ENGAGEMENT_BY_TYPE], false);
  const data = getEngagementByTypeData(insights);
  const getName = (name) => {
    const _name = mediaTypeToReadableString[name];
    return _name ? _name : name;
  };
  const getSumPosts = (_data) => {
    return _data.reduce((a, b) => a + b.value, 0);
  };
  return /* @__PURE__ */ React.createElement(
    InsightsSection,
    {
      isFetching: insights.engagementByType.isFetching,
      childRef: targetRef,
      title: "Engagement by Type",
      description: "What are the most engaging post types?"
    },
    data.length > 0 ? /* @__PURE__ */ React.createElement("div", { className: "tint-engagement-by-type" }, /* @__PURE__ */ React.createElement(ResponsiveContainer, { width: 256, height: "100%" }, /* @__PURE__ */ React.createElement(PieChart, null, /* @__PURE__ */ React.createElement(Tooltip, { content: /* @__PURE__ */ React.createElement(CustomTooltip, { getName, dotColor: COLORS }) }), /* @__PURE__ */ React.createElement(Pie, { data, innerRadius: "75%", outerRadius: "90%", fill: "#8884d8", dataKey: "value" }, data.map((e, index) => /* @__PURE__ */ React.createElement(Cell, { key: `cell-${index}`, fill: COLORS[e.name.toUpperCase()] }))))), /* @__PURE__ */ React.createElement("div", { className: "tint-engagement-by-type__total-container" }, /* @__PURE__ */ React.createElement("span", { className: "total-container__number" }, numberWithCommas(getSumPosts(data))), /* @__PURE__ */ React.createElement("span", { className: "total-container__posts" }, "Clicks")), /* @__PURE__ */ React.createElement("div", { className: "tint-engagement-by-type__description" }, data.map((e, i) => {
      return /* @__PURE__ */ React.createElement("div", { className: "tint-engagement-by-type__description-item", key: i }, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: `tint-engagement-by-type tint-engagement-by-type__circle tint-engagement-by-type--${e.name}`
        }
      ), /* @__PURE__ */ React.createElement("span", { className: "tint-engagement-by-type__name" }, mediaTypeToReadableString[e.name]), /* @__PURE__ */ React.createElement("span", { className: "tint-engagement-by-type__percentage" }, e.percentage > 0 ? e.percentage : "<1", "%"));
    }))) : /* @__PURE__ */ React.createElement(InsightsNoData, null)
  );
};
