import * as Yup from "yup";
export const validationSchemaInstagramBrandContent = () => {
  return Yup.object({
    username: Yup.object().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaInstagramStories = () => {
  return Yup.object({
    username: Yup.object().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaInstagramTagged = () => {
  return Yup.object({
    username: Yup.object().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaInstagramMentions = () => {
  return Yup.object({
    username: Yup.object().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaIntagramHashtags = () => {
  return Yup.object({
    account: Yup.object().required("Account is required."),
    username: Yup.object().required("Username is required."),
    hashtag: Yup.string().matches(/^((?!#).)*$/, "Please remove hash").required("Hashtag is required.")
  });
};
export const validationSchemaIntagramMentions = () => {
  return Yup.object({
    username: Yup.object().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaInstagramSocialListening = () => {
  return Yup.object({
    username: Yup.object().required("Username is required."),
    account: Yup.object().required("Account is required."),
    searchTerm: Yup.string().required("Username is required.")
  });
};
