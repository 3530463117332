import React, { useEffect, useState } from "react";
import "./autocomplete-tags.sass";
import { getTagList } from "../../../feature/tint-editor/reducers/tint-editor.reducer";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteList from "../autocomplete-list/autocomplete-list";
import { updateTintTags } from "../../../feature/tint-editor/actions/tint-editor.actions";
import useSelectListElement from "../autocomplete-list/useSelectListElement";
import { TagList } from "../tag-list/tag-list";
import InputAddTag from "../inputs/input-add-tag/input-add-tag";
import { useFormik } from "formik";
import { keyCodes } from "../../hooks/use-key-down/keyCodes";
const AutocompleteTags = ({ tagList, onChange, onDeleteTag, focusOnInit }) => {
  const tagListSelector = useSelector(getTagList);
  const [inputValue, setInputValue] = useState("");
  const [isFocus, setFocus] = useState(false);
  const [currentTagList, setCurrentTagList] = useState(tagList || []);
  const removeDuplicates = tagListSelector.filter((tag) => !(currentTagList == null ? void 0 : currentTagList.includes(tag)));
  const [autocompleteTagList, setAutocompleteTagList] = useState(removeDuplicates);
  const dispatch = useDispatch();
  const onInputChange = (e) => {
    setInputValue(e.target.value);
    const result = removeDuplicates.filter((tag) => tag.includes(e.target.value));
    if (e.target.value === "") {
      setAutocompleteTagList(removeDuplicates);
    } else {
      setAutocompleteTagList(result);
    }
  };
  useEffect(() => {
    setFocus(inputValue.length > 0);
  }, [inputValue]);
  const { currentElement: currentElementIndex, setCurrentElement } = useSelectListElement({
    listLength: autocompleteTagList == null ? void 0 : autocompleteTagList.length,
    onEnterClick: (i) => {
      (autocompleteTagList == null ? void 0 : autocompleteTagList[i]) && (onTagClick == null ? void 0 : onTagClick(autocompleteTagList[i]));
    }
  });
  const { handleSubmit, errors, values, dirty, touched, handleChange, resetForm } = useFormik({
    validateOnChange: true,
    initialValues: {
      tags: ""
    }
  });
  const onKeyDown = (key) => {
    switch (key.keyCode) {
      case keyCodes.enter:
      case keyCodes.comma:
        currentElementIndex === -1 && onTagClick(inputValue);
        break;
      default:
        return;
    }
  };
  const onTagClick = (tag) => {
    const parsedItems = /* @__PURE__ */ new Set([...tag.split(/[\s,]+/).filter(Boolean)]);
    setFocus(false);
    setAutocompleteTagList((s) => [...s, ...parsedItems]);
    setCurrentTagList((s) => [.../* @__PURE__ */ new Set([...s, ...parsedItems])]);
    dispatch(updateTintTags(parsedItems));
    setCurrentElement(-1);
    setInputValue("");
    resetForm();
  };
  useEffect(() => {
    onChange(currentTagList);
  }, [currentTagList]);
  const onDeleteElement = (_arr, tag) => {
    setCurrentTagList((s) => s.filter((el) => el !== tag));
    tagListSelector.includes(tag) && setAutocompleteTagList((s) => [.../* @__PURE__ */ new Set([...s, tag])]);
    onDeleteTag(_arr);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-autocomplete-tags" }, /* @__PURE__ */ React.createElement(TagList, { list: currentTagList, onRemove: onDeleteElement }), /* @__PURE__ */ React.createElement("form", { id: "formId", noValidate: true, onSubmit: handleSubmit }, /* @__PURE__ */ React.createElement(
    InputAddTag,
    {
      focusOnInit,
      name: "tags",
      type: "text",
      value: values.tags,
      placeholder: "Add tag",
      dirty,
      error: errors.tags,
      touched: touched.tags,
      onKeyDown,
      handleChange: (e) => {
        onInputChange == null ? void 0 : onInputChange(e);
        return handleChange(e);
      },
      errorMsg: "Tag already exist",
      onClick: () => onTagClick(inputValue)
    }
  )), inputValue.length > 0 && isFocus && autocompleteTagList.length !== 0 && /* @__PURE__ */ React.createElement(
    AutocompleteList,
    {
      list: autocompleteTagList,
      searchTerm: inputValue,
      currentElementIndex,
      onChange: onTagClick
    }
  ));
};
export default AutocompleteTags;
