import React, { useEffect, useRef } from "react";
import "./dropdown-search-input.sass";
export const DropdownSearchInput = ({ searchTerm = "", onChange, onFocus, disabled }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!disabled && ref.current) {
      ref.current.focus();
    }
  }, [disabled]);
  return /* @__PURE__ */ React.createElement("div", { className: "dropdown-search-input" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      ref,
      placeholder: "Search...",
      value: searchTerm,
      onChange,
      onFocus,
      disabled,
      "data-testid": "dropdown-search-input"
    }
  ));
};
