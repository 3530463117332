import { createRoutine, createPrefixedActionName } from "../../../services/functions/redux-routines/redux-routines";
import { EXPERIENCES } from "../consts";
import { ApiService } from "../../../services/api-service/api-service";
import { ExperienceConfig } from "../utils/experience.config";
export const ACTIONS = {
  GET_EXPERIENCES: createRoutine(createPrefixedActionName(EXPERIENCES, "GET_EXPERIENCES")),
  SELECT_EXPERIENCE: createPrefixedActionName(EXPERIENCES, "SELECT_EXPERIENCE"),
  UNSELECT_EXPERIENCE: createPrefixedActionName(EXPERIENCES, "UNSELECT_EXPERIENCE"),
  UNSELECT_ALL_EXPERIENCES: createPrefixedActionName(EXPERIENCES, "UNSELECT_ALL_EXPERIENCES"),
  UPDATE_EXPERIENCES_STATUS: createRoutine(createPrefixedActionName(EXPERIENCES, "UPDATE_EXPERIENCES_STATUS")),
  DELETE_EXPERIENCES: createRoutine(createPrefixedActionName(EXPERIENCES, "DELETE_EXPERIENCES"))
};
export const getExperiencesStart = () => ({
  type: ACTIONS.GET_EXPERIENCES.REQUEST
});
export const getExperiencesSuccess = (experiences) => ({
  type: ACTIONS.GET_EXPERIENCES.SUCCESS,
  payload: experiences
});
export const getExperiencesError = (err) => ({
  type: ACTIONS.GET_EXPERIENCES.FAILURE,
  payload: err
});
export const bulkUpdateExperiencesStatusStart = () => ({
  type: ACTIONS.UPDATE_EXPERIENCES_STATUS.REQUEST
});
export const bulkUpdateExperiencesStatusSuccess = () => ({
  type: ACTIONS.UPDATE_EXPERIENCES_STATUS.SUCCESS
});
export const bulkUpdateExperiencesStatusError = (err) => ({
  type: ACTIONS.UPDATE_EXPERIENCES_STATUS.FAILURE,
  payload: err
});
export const bulkDeleteExperiencesStart = () => ({
  type: ACTIONS.DELETE_EXPERIENCES.REQUEST
});
export const bulkDeleteExperiencesSuccess = (id) => ({
  type: ACTIONS.DELETE_EXPERIENCES.SUCCESS,
  payload: id
});
export const bulkDeleteExperiencesError = (err) => ({
  type: ACTIONS.DELETE_EXPERIENCES.FAILURE,
  payload: err
});
export const getExperiences = (url) => {
  return (dispatch) => {
    dispatch(getExperiencesStart());
    return ApiService.get(
      url || `/experiences?sort=status,-updated_at&page[size]=${ExperienceConfig.defaultExperiencePageSize}`
    ).then((res) => {
      return dispatch(getExperiencesSuccess(res.data));
    }).catch((err) => dispatch(getExperiencesError(err)));
  };
};
export const selectExperience = (exp) => ({
  type: ACTIONS.SELECT_EXPERIENCE,
  payload: exp
});
export const unselectExperience = (id) => ({
  type: ACTIONS.UNSELECT_EXPERIENCE,
  payload: id
});
export const deselectAllExperiences = () => ({
  type: ACTIONS.UNSELECT_ALL_EXPERIENCES
});
