import { useEffect, useState } from "react";
const useClickOutside = (ref, callback) => {
  const [isVisible, setVisibility] = useState(false);
  const handleClick = (e) => {
    var _a, _b;
    if (ref && (ref == null ? void 0 : ref.current) && ((_b = (_a = ref == null ? void 0 : ref.current) == null ? void 0 : _a.contains) == null ? void 0 : _b.call(_a, e.target))) {
      return;
    }
    setVisibility(false);
    callback && callback();
  };
  const handleBlur = () => {
    if (document.activeElement.tagName === "IFRAME") {
      setVisibility(false);
      callback && callback();
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleClick);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("mousedown", handleClick);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);
  return [isVisible, setVisibility];
};
export default useClickOutside;
