var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./experience-language-settings.sass";
import { Button } from "../../../../../../components/interface/button/button";
import EditInputDropdown from "../../edit-input-dropdown/edit-input-dropdown";
import EditInput from "../../edit-input/edit-input";
import * as PropTypes from "prop-types";
import { toast } from "react-toastify";
export class ExperienceLanguageSettings extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "addLanguage", () => {
      if (this.state.currentAddedLanguage.value) {
        this.setState({
          selectedList: [...this.state.selectedList, __spreadValues({}, this.state.currentAddedLanguage)],
          currentAddedLanguage: {
            value: void 0,
            name: void 0,
            isMarkAsDeleted: false
          }
        });
        this.props.onAddLanguage({
          value: this.state.currentAddedLanguage.value.includes("_") ? this.state.currentAddedLanguage.value.replace("_", "-") : this.state.currentAddedLanguage.value,
          name: this.state.currentAddedLanguage.name
        });
      }
    });
    __publicField(this, "onLanguageChange", (lang) => {
      if (!this.isLanguageSelected(lang.value)) {
        this.setState({
          currentAddedLanguage: this.getLanguage(lang.value)
        });
      } else {
        toast.info("You have already added this language");
      }
    });
    __publicField(this, "getLanguage", (isoLang) => {
      return this.props.languages.find((language) => language.value === isoLang);
    });
    __publicField(this, "onDeleteLanguageClick", (el, index) => {
      if (el.isMarkAsDeleted) {
        this.deleteLanguage(index);
      } else {
        this.markAsDeleted(el, index);
        toast.info("Click again to approve delete language");
      }
    });
    __publicField(this, "deleteLanguage", (index) => {
      const selectedList = [...this.state.selectedList.slice(0, index), ...this.state.selectedList.slice(index + 1)];
      this.setState({
        selectedList
      });
      this.props.onDeleteLanguage(index);
    });
    __publicField(this, "markAsDeleted", (el, index) => {
      const selectedItem = __spreadProps(__spreadValues({}, this.getLanguage(el.value)), {
        isMarkAsDeleted: true
      });
      const currentList = [
        ...this.state.selectedList.slice(0, index),
        selectedItem,
        ...this.state.selectedList.slice(index + 1)
      ];
      this.setState({
        selectedList: currentList
      });
    });
    __publicField(this, "renderLanguageList", () => {
      return this.state.selectedList.length > 0 && this.state.selectedList.map((el, i) => /* @__PURE__ */ React.createElement(
        "div",
        {
          "data-testid": "selectedLanguageSection",
          className: `language-list__item ${el.isMarkAsDeleted ? "language-list__item--deleted" : ""}`,
          key: `tint-slug-${i}`
        },
        /* @__PURE__ */ React.createElement(
          EditInput,
          {
            value: el.name,
            type: "text",
            rightIcon: "fas fa-trash",
            readOnly: true,
            isRightIconVisible: this.isAbleToDelete(el),
            rightIconAction: () => {
              this.isAbleToDelete(el) ? this.onDeleteLanguageClick(el, i) : void 0;
            }
          }
        )
      ));
    });
    __publicField(this, "isAbleToDelete", (el) => el.value !== this.props.defaultLanguage && el.value !== this.props.currentLanguage);
    this.state = {
      selectedList: this.props.selectedLanguages,
      currentAddedLanguage: {
        value: void 0,
        name: void 0
      }
    };
  }
  isLanguageSelected(lang) {
    return this.state.selectedList.find((language) => language.value === lang);
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-experience-language-settings" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__description" }, "Translate your page into multiple languages. Add and remove languages below."), /* @__PURE__ */ React.createElement("div", { className: "language-list" }, this.renderLanguageList()), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        isControlled: true,
        value: this.state.currentAddedLanguage.value,
        id: "languageDropdown",
        placeholder: "Select Language...",
        onChange: (e) => {
          const getCurrentLang = this.props.languages.find((language) => language.value === e.target.value);
          this.onLanguageChange(getCurrentLang);
        },
        options: this.props.languages
      }
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        id: "addLanguageButton",
        onClick: this.addLanguage,
        type: "upload",
        size: "full-width",
        text: "Add Language"
      }
    )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        id: "defaultLanguageDropdown",
        value: this.props.defaultLanguage,
        isControlled: true,
        placeholder: "Select Language...",
        onChange: (e) => {
          const lang = e.target.value.replace("-", "_");
          const getCurrentLang = this.props.languages.find((language) => language.value === lang);
          this.props.onDefaultLanguageChange(getCurrentLang);
        },
        options: this.props.selectedLanguages,
        label: "Default Language"
      }
    ), /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__description" }, this.props.description)));
  }
}
ExperienceLanguageSettings.propTypes = {
  onDefaultLanguageChange: PropTypes.func,
  onAddLanguage: PropTypes.func,
  onDeleteLanguage: PropTypes.func,
  languages: PropTypes.array,
  selectedLanguages: PropTypes.array,
  defaultLanguage: PropTypes.string,
  currentLanguage: PropTypes.object | PropTypes.string
};
