var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const Poll = (_0) => __async(void 0, [_0], function* ({ request, validate, interval }) {
  const executePoll = (resolve, reject) => __async(void 0, null, function* () {
    const result = yield request();
    if (validate(result)) {
      return resolve(result);
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  });
  return new Promise(executePoll);
});
export const LongPollingService = {
  Poll
};
