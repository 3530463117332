import { threadsDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { validationSchemaThreadsBrandContent, validationSchemaThreadsKeywordContent } from "../utils/validation-schema/threads-schema";
import { GuardNames } from "../../../../../../../guards/guards-list";
export const threads = [
  {
    header: "Add Threads",
    description: "Choose a Social Feed type below:",
    typeList: [
      {
        id: "posted",
        title: "Branded Feed",
        description: "This social feed type allows you to access your authorized Threads posts.",
        featureName: GuardNames.SOCIAL_FEEDS_THREADS
      },
      {
        id: "mentioned",
        title: "Mentions",
        description: "This social feed type allows you to access Threads posts in which your business has been mentioned.",
        featureName: GuardNames.SOCIAL_FEEDS_THREADS_MENTIONED
      },
      {
        id: "keyword",
        title: "Keyword",
        description: "This social feed type allows you to find public Threads posts that contain a chosen keyword.",
        featureName: GuardNames.SOCIAL_FEEDS_THREADS_KEYWORD
      }
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext
    },
    zendeskUrl: void 0,
    isAlert: false,
    formSchema: void 0
  },
  {
    header: void 0,
    description: void 0,
    zendeskUrl: void 0,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "threads",
    formSchema: {
      posted: {
        validationSchema: validationSchemaThreadsBrandContent(),
        footerCopy: "",
        submitMapper: (values) => {
          var _a, _b;
          return {
            account_id: ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.accountId) || values.account.value
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          }
        ]
      },
      mentioned: {
        validationSchema: validationSchemaThreadsBrandContent(),
        footerCopy: "",
        submitMapper: (values) => {
          var _a, _b;
          return {
            account_id: ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.accountId) || values.account.value
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          }
        ]
      },
      keyword: {
        validationSchema: validationSchemaThreadsKeywordContent(),
        footerCopy: "",
        submitMapper: (values) => {
          var _a, _b;
          return {
            account_id: ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.accountId) || values.account.value,
            search_term: values.keyword
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "keyword",
            backendId: "search_term",
            label: "ENTER KEYWORD",
            placeholder: "Enter keyword",
            type: SidebarItemTypes.TEXT,
            disabled: threadsDisabledButton
          }
        ]
      }
    }
  }
];
