import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const communityConsent = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.COMMUNITY_CONSENT]: {
    display: "flex",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.COMMUNITY_CONSENT_LABEL]: {
    cursor: "pointer",
    margin: "4px 0 0 12px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.COMMUNITY_CONSENT_INPUT_REQUIRED_LABEL]: {
    content: '"*"'
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.COMMUNITY_CONSENT_RTL]: {
    margin: "4px 12px 0 0"
  }
};
