import React, { useRef } from "react";
import "./dropdown-click-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
const DropdownClickList = ({ onChange, parentRef, currentItems, children, expandToTop }) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: elementRef,
      "data-testid": "dropdown-click-list",
      className: `dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--click dropdown-list--wide dropdown-list--with-description dropdown-list--${expandToTop ? "expand-top" : ""}`
    },
    currentItems && /* @__PURE__ */ React.createElement("ul", null, currentItems.map((item, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item ${item.isActive ? "active" : ""}`,
        key: i,
        onClick: () => {
          onChange(item);
        }
      },
      item.icon ? /* @__PURE__ */ React.createElement("span", { className: `icon ${item.icon}` }) : null,
      /* @__PURE__ */ React.createElement("span", null, item.name),
      /* @__PURE__ */ React.createElement("span", { className: "dropdown-list__description" }, item.description)
    ))),
    children
  );
};
export default DropdownClickList;
DropdownClickList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  children: PropTypes.object
};
