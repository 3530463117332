var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useEffect } from "react";
import "./experience-captcha-settings.sass";
import DropdownButton, { ButtonType } from "../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import InputPrimary from "../../../../../../components/interface/inputs/input-primary/input-primary";
import FeatureGuard from "../../../../../../guards/feature-guard";
import { GuardNames } from "../../../../../../guards/guards-list";
const CaptchaTypes = {
  H_CAPTCHA: "h_captcha",
  GOOGLE_RECAPTCHA: "google_recaptcha",
  NONE: "none"
};
const captchaOptions = [
  { value: CaptchaTypes.NONE, name: "None" },
  { value: CaptchaTypes.GOOGLE_RECAPTCHA, name: "reCAPTCHA" },
  { value: CaptchaTypes.H_CAPTCHA, name: "hCaptcha" }
];
export const ExperienceCaptchaSettings = ({ dataUpdate, experience, draftExperience }) => {
  const draftCaptcha = draftExperience.attributes.captcha_type;
  const draftCaptchaPublicKey = draftExperience.attributes.captcha_public_key;
  const savedCaptcha = experience.attributes.captcha_type;
  const [selectedCaptcha, setSelectedCaptcha] = useState();
  const [isPrivateKeyVisible, setIsPrivateKeyVisible] = useState(false);
  const isCaptchaSelected = (captchaType) => [CaptchaTypes.H_CAPTCHA, CaptchaTypes.GOOGLE_RECAPTCHA].includes(captchaType);
  const showPrivateKeyInput = () => setIsPrivateKeyVisible(true);
  useEffect(() => {
    const value = draftCaptcha ? draftCaptcha : CaptchaTypes.NONE;
    const captcha = captchaOptions.find((option) => option.value === value);
    setSelectedCaptcha(captcha);
  }, [draftCaptcha]);
  const onCaptchaSelect = ({ value }) => {
    switch (value) {
      case CaptchaTypes.GOOGLE_RECAPTCHA:
      case CaptchaTypes.H_CAPTCHA:
        dataUpdate({ captcha_type: value });
        break;
      case CaptchaTypes.NONE:
      default:
        dataUpdate({
          captcha_type: null,
          captcha_public_key: null,
          captcha_private_key: null
        });
    }
  };
  const renderCaptchaSelectDropdown = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "input-primary" }, /* @__PURE__ */ React.createElement("label", null, "Form Verification"), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        currentItem: selectedCaptcha,
        list: captchaOptions,
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        onChangeValue: onCaptchaSelect
      }
    ));
  };
  const renderCaptchaPublicKeyInput = () => {
    return /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        label: "Public Key",
        id: "captcha-public-key",
        name: "captcha_public_key",
        type: "text",
        placeholder: "Enter Public Key",
        value: draftCaptchaPublicKey || "",
        handleChange: (e) => {
          dataUpdate({ captcha_public_key: e.target.value });
        }
      }
    );
  };
  const renderCaptchaPrivateKeyInput = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        label: "Private Key",
        id: "captcha-private-key",
        name: "captcha_private_key",
        type: "text",
        placeholder: "Enter Private Key",
        handleChange: (e) => {
          dataUpdate({ captcha_private_key: e.target.value });
        }
      }
    ), /* @__PURE__ */ React.createElement("span", { className: "tint-experience-settings__description" }, "Enter the private key ", (selectedCaptcha == null ? void 0 : selectedCaptcha.name) || "", " provided during registration."));
  };
  const renderCaptchaInputs = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, isCaptchaSelected(draftCaptcha) ? /* @__PURE__ */ React.createElement(React.Fragment, null, renderCaptchaPublicKeyInput(), /* @__PURE__ */ React.createElement(React.Fragment, null, savedCaptcha && !isPrivateKeyVisible ? /* @__PURE__ */ React.createElement("div", { className: "tint-experience-settings__private-key" }, /* @__PURE__ */ React.createElement("label", null, "Private Key"), /* @__PURE__ */ React.createElement("span", { className: "tint-experience-settings__description" }, "Already set,", " ", /* @__PURE__ */ React.createElement("span", { className: "tint-experience-settings__link", onClick: showPrivateKeyInput }, "change"))) : renderCaptchaPrivateKeyInput())) : null);
  };
  return /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.CAPTCHA }, /* @__PURE__ */ React.createElement("div", { className: "tint-experience-captcha-settings" }, renderCaptchaSelectDropdown(), renderCaptchaInputs()));
};
