import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AuthorizationService } from "../../../services/authorization/authorization.service";
import { EventerService } from "../../../services/eventer/eventer.service";
import {
  addSocialConnectionStart,
  resolveSocialConnectionStart,
  addSocialConnectionSuccess,
  addSocialConnectionError,
  resolveSocialConnectionSuccess,
  resolveSocialConnectionError
} from "../actions/social-connections/social-connections.actions";
const useSocialAccountsAuthorizationService = ({ onNewAccountSuccess, onNewAccountError, onEditAccountSuccess }) => {
  const dispatch = useDispatch();
  const addSocialConnection = (type) => {
    dispatch(addSocialConnectionStart());
    AuthorizationService.add({ url: `/accounts/new?type=${type}&origin=${window.origin}` });
  };
  const refreshSocialConnection = (account) => {
    const { source, id } = account;
    dispatch(resolveSocialConnectionStart());
    AuthorizationService.edit({
      url: `/accounts/${id}/edit?type=${source}&origin=${window.origin}`
    });
  };
  useEffect(() => {
    const editAccountSuccess = (message) => {
      dispatch(resolveSocialConnectionSuccess(message.data.data));
      onEditAccountSuccess && onEditAccountSuccess(message.data.data);
    };
    const editAccountFailure = (message) => {
      dispatch(resolveSocialConnectionError(message.data.errors[0]));
      handleAccountError(message.data.errors);
    };
    const unsubscribeEditAccount = AuthorizationService.on({
      eventerService: EventerService,
      eventType: EventerService.EVENT_TYPE.EDIT_ACCOUNT,
      success: editAccountSuccess,
      failure: editAccountFailure
    });
    const newAccountSuccess = (message) => {
      dispatch(addSocialConnectionSuccess(message.data.data));
      onNewAccountSuccess && onNewAccountSuccess(message.data.data);
    };
    const newAccountFailure = (message) => {
      dispatch(addSocialConnectionError(message.data.errors[0]));
      handleAccountError(message.data.errors);
      onNewAccountError && onNewAccountError(message.data.errors);
    };
    const unsubscribeNewAccount = AuthorizationService.on({
      eventerService: EventerService,
      eventType: EventerService.EVENT_TYPE.NEW_ACCOUNT,
      success: newAccountSuccess,
      failure: newAccountFailure
    });
    const handleAccountError = (errors) => {
      var _a;
      const err = errors && ((_a = errors[0]) == null ? void 0 : _a.detail) ? `Account ${errors[0].detail}` : "Something went wrong";
      toast.error(err);
    };
    return () => {
      AuthorizationService.off({
        eventerService: EventerService,
        eventType: EventerService.EVENT_TYPE.NEW_ACCOUNT,
        fn: unsubscribeNewAccount
      });
      AuthorizationService.off({
        eventerService: EventerService,
        eventType: EventerService.EVENT_TYPE.EDIT_ACCOUNT,
        fn: unsubscribeEditAccount
      });
    };
  }, []);
  return {
    addSocialConnection,
    refreshSocialConnection,
    windowRef: AuthorizationService.windowRef
  };
};
export { useSocialAccountsAuthorizationService };
