import React from "react";
import "./tag-list.sass";
import { Tag } from "../tag/tag";
export const TagList = ({ list = [], selectedTag, onRemove, onTagClick }) => {
  return /* @__PURE__ */ React.createElement("ul", { className: "tint-tag-list" }, list == null ? void 0 : list.map((tag, i) => /* @__PURE__ */ React.createElement("li", { key: tag }, /* @__PURE__ */ React.createElement(
    Tag,
    {
      tag,
      isSelected: selectedTag,
      onClick: () => onTagClick == null ? void 0 : onTagClick(tag),
      onRemove: () => {
        onRemove == null ? void 0 : onRemove(i, tag);
      }
    }
  ))));
};
