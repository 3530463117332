var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "../../../../../components/interface/button/button";
import "./settings-bar.sass";
import { Config } from "../../../../../utils/config";
import { track } from "../../../../../services/functions/analytics-wrapper/analytics-wrapper";
import { withRouter } from "react-router-dom";
import { ExperienceSettings } from "./experience-settings/experience-settings";
import { toast } from "react-toastify";
import { ModalHoc } from "../../../../../components/HOC/modal/modal.hoc";
import { EditModal } from "../../../../../components/edit-modal/edit-modal/edit-modal";
import { setExperienceBuilderWidth } from "../../../actions/experience-builder";
import {
  addSection,
  deleteSection,
  publishExperience,
  saveDataAttributesDraft
} from "../../../actions/experience/experience.fat-actions";
import {
  addCustomLanguage,
  deleteCustomLanguage,
  discardDraftData,
  setDefaultLanguage
} from "../../../actions/experience/experience.actions";
import { isDataPublished } from "../../../../../model/experience-status.model";
import { EditModalStep } from "../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { handleApiError } from "../../../../../services/functions/error-handler/error-handler";
import { ButtonType } from "../../../../../components/interface/dropdown/button/dropdown-button";
import DropdownButton from "../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../components/interface/dropdown/button/header/dropdown-header";
import LanguageList from "../../../../../components/interface/dropdown/lists/language/language-list";
import { getLanguageDirection, isoLanguageList } from "../../../../../services/functions/language/language";
import { ExperienceLanguageSettings } from "./experience-language-settings/experience-language-settings";
import { setCurrentLanguage } from "../../../actions/languages/languages.actions";
import { getCustomLanguageList } from "../../../reducers/experience/experience";
import { pageSectionType } from "../../../../../model/page-section-type.model";
import { ExperienceConfig } from "../../../utils/experience.config";
import { openSidebar as openGlobalSidebar, SIDEBAR_TYPE } from "../../../../../actions/sidebar";
import { sidebarUpgradeSchema } from "../../../../../components/sidebar-upgrade/sidebar-upgrade.schema";
const displayModeArray = [
  { class: "fas fa-laptop", width: ExperienceConfig.pageSize.desktop },
  { class: "fas fa-tablet-alt", width: ExperienceConfig.pageSize.tablet },
  //{class: 'fas fa-mobile-alt contest-settings-bar__center__rotate '},
  { class: "fas fa-mobile-alt", width: ExperienceConfig.pageSize.mobile }
];
class SettingsBar extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "hasCookieBannerSection", () => {
      return this.props.draftExperience.attributes.data.draft.findIndex(
        (section) => section.sectionType === pageSectionType.COOKIE_BANNER
      ) !== -1;
    });
    __publicField(this, "checkSettingsChanges", () => {
      this.setState({
        cookieConsent: this.props.draftExperience.attributes.cookie_consent && this.hasCookieBannerSection()
      });
      return this.props.saveDraftData().catch((err) => handleApiError(err, toast.error));
    });
    __publicField(this, "goToDashboard", () => {
      this.props.history.push(Config.routePaths.experienceBuilder.path);
    });
    __publicField(this, "onLanguageChange", (currentItem) => {
      if (window.Tint && window.Tint.changeLanguage) {
        window.Tint.changeLanguage(currentItem.value);
      }
      this.props.setCurrentLanguage({
        value: currentItem.value,
        direction: getLanguageDirection(currentItem.value)
      });
    });
    __publicField(this, "onAddLanguage", () => {
      this.setState({
        isLanguageModalOpen: true
      });
    });
    __publicField(this, "getLanguage", () => {
      return this.props.languages.find((language) => language.isDefault);
    });
    __publicField(this, "addCookieBannerSection", (isEnabled) => {
      this.props.saveDataAttributesDraft({ cookie_consent: isEnabled });
      if (isEnabled) {
        if (!this.hasCookieBannerSection()) {
          this.props.addCookieBanner(pageSectionType.COOKIE_BANNER, -1, { cookie_consent: isEnabled });
        }
      } else {
        const cookieBannerIndex = this.props.draftExperience.attributes.data.draft.findIndex(
          (section) => section.sectionType === pageSectionType.COOKIE_BANNER
        );
        this.props.deleteSection(cookieBannerIndex, { cookie_consent: isEnabled });
      }
    });
    this.state = {
      displayModeSelected: 0,
      languageList: Object.entries(isoLanguageList).map((lang) => {
        return { value: lang[0], name: lang[1].name };
      }),
      isLanguageModalOpen: false,
      isLanguageMetaEnabled: this.props.session.features["experiences_advanced_templates"],
      cookieConsent: this.props.draftExperience.attributes.cookie_consent && this.hasCookieBannerSection()
    };
  }
  closeModal() {
    this.setState({ isLanguageModalOpen: false });
    this.props.discardDraftData();
    this.props.closeModal();
  }
  getAddressUrl() {
    var _a, _b;
    if ((_b = (_a = this.props.draftExperience) == null ? void 0 : _a.links) == null ? void 0 : _b.public) {
      return this.props.draftExperience.links.public;
    } else {
      return Config.getTintPageUrl(
        this.props.draftExperience.relationships.user.data.id,
        this.props.draftExperience.attributes.slug
      );
    }
  }
  renderModal() {
    return this.props.isModalOpen && /* @__PURE__ */ React.createElement(
      EditModal,
      {
        isVisible: this.props.isModalOpen,
        saveData: this.checkSettingsChanges,
        closeModal: this.closeModal.bind(this),
        dismissModal: this.closeModal.bind(this)
      },
      /* @__PURE__ */ React.createElement(
        EditModalStep,
        {
          title: "Experience Settings",
          closeModal: this.closeModal.bind(this),
          onCancelClick: () => this.addCookieBannerSection(this.state.cookieConsent)
        },
        /* @__PURE__ */ React.createElement(
          ExperienceSettings,
          {
            experience: this.props.experience,
            draftExperience: this.props.draftExperience,
            experienceUrl: this.getAddressUrl(),
            dataUpdate: this.props.saveDataAttributesDraft,
            cookieConsent: this.state.cookieConsent,
            onAddCookieBanner: this.addCookieBannerSection
          }
        )
      )
    );
  }
  renderLanguageModal() {
    return this.state.isLanguageModalOpen && /* @__PURE__ */ React.createElement(
      EditModal,
      {
        isVisible: this.props.state,
        saveData: this.props.saveDraftData,
        closeModal: this.closeModal.bind(this),
        dismissModal: this.closeModal.bind(this)
      },
      /* @__PURE__ */ React.createElement(EditModalStep, { title: "Language Settings", closeModal: this.closeModal.bind(this) }, /* @__PURE__ */ React.createElement(
        ExperienceLanguageSettings,
        {
          description: "Set the default page for your experience.",
          languages: this.state.languageList,
          selectedLanguages: this.props.languages,
          currentLanguage: this.props.currentLanguage,
          defaultLanguage: this.props.defaultLanguage,
          onDefaultLanguageChange: (lang) => {
            this.props.setDefaultLanguage(lang);
            this.props.setCurrentLanguage({
              value: lang.value.replace("_", "-"),
              direction: getLanguageDirection(lang.value)
            });
          },
          onAddLanguage: this.props.addCustomLanguages,
          onDeleteLanguage: this.props.deleteCustomLanguage,
          dataUpdate: this.props.saveDataAttributesDraft,
          saveData: this.props.saveDraftData
        }
      ))
    );
  }
  componentDidMount() {
    this.props.setCurrentLanguage({
      value: this.props.defaultLanguage,
      direction: getLanguageDirection(this.props.defaultLanguage)
    });
  }
  renderSizeMode() {
    return displayModeArray.map((e, i) => {
      return /* @__PURE__ */ React.createElement(
        "i",
        {
          key: i,
          className: `${e.class} ${i === this.state.displayModeSelected ? "selected" : ""}`,
          onClick: () => {
            this.setState({ displayModeSelected: i });
            this.props.setPageBuilderWidth(e.width);
          }
        }
      );
    });
  }
  publishExperience() {
    var _a, _b;
    if (isDataPublished(this.props.draftExperience, this.props.experience)) {
      return;
    }
    if (!this.props.session.features.experiences) {
      toast.warn("You need to upgrade your plan type to publish experience.");
      return;
    }
    this.props.publishExperience(this.props.draftExperience).then(
      () => {
        toast.success("We've published your page!");
      },
      (err) => {
        handleApiError(err, toast.error);
      }
    );
    track("Click on Publish", {
      type: this.props.draftExperience.attributes.type,
      experience_id: this.props.draftExperience.id,
      link: (_b = (_a = this.props.draftExperience) == null ? void 0 : _a.links) == null ? void 0 : _b.public,
      device_type: navigator.userAgent
    });
  }
  render() {
    return this.props.draftExperience !== void 0 && this.props.languages[0] ? /* @__PURE__ */ React.createElement("div", { className: "tint-experience-settings-bar" }, /* @__PURE__ */ React.createElement("div", { className: "tint-experience-settings-bar__left" }, /* @__PURE__ */ React.createElement("span", { className: "far fa-angle-left tint-experience-settings-bar__back-button", onClick: this.goToDashboard }), /* @__PURE__ */ React.createElement(
      "input",
      {
        onBlur: (e) => {
          this.props.saveDataAttributesDraft({ name: e.target.value });
          this.props.saveDraftData();
        },
        key: this.props.draftExperience.attributes.name,
        placeholder: "Project Name",
        defaultValue: this.props.draftExperience.attributes.name
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-experience-settings-bar__center" }, this.renderSizeMode()), /* @__PURE__ */ React.createElement("div", { className: "tint-experience-settings-bar__right" }, this.state.isLanguageMetaEnabled ? /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(LanguageList, __spreadProps(__spreadValues({}, props), { currentLanguage: this.props.currentLanguage })),
        list: this.props.languages,
        placeholder: this.getLanguage().name,
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_PRIMARY,
        onChangeValue: this.onLanguageChange,
        onAction: this.onAddLanguage
      }
    ) : null, /* @__PURE__ */ React.createElement(
      "a",
      {
        href: Config.routePaths.experienceBuilder.preview.createUrl(this.props.draftExperience.id),
        target: "_blank",
        rel: "noopener noreferrer",
        onClick: () => {
          track("Click on Preview", {
            type: this.props.draftExperience.attributes.type,
            experience_id: this.props.draftExperience.id
          });
        }
      },
      /* @__PURE__ */ React.createElement(Button, { type: "secondary", text: "Preview" })
    ), /* @__PURE__ */ React.createElement("a", { onClick: this.publishExperience.bind(this) }, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: isDataPublished(this.props.draftExperience, this.props.experience) ? "disabled" : "primary",
        text: "Publish"
      }
    )), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-experience-settings-bar__right__settings",
        onClick: (e) => {
          if (this.props.session.features.experiences) {
            this.props.openModal(e);
          } else {
            this.props.openGlobalSidebar();
          }
        }
      },
      /* @__PURE__ */ React.createElement("i", { className: "fas fa-cog" })
    )), this.renderModal(), this.renderLanguageModal()) : null;
  }
}
const mapStateToProps = (state) => ({
  currentLanguage: state.languages.currentLanguage,
  draftExperience: state.experience.dataDraft,
  experience: state.experience.data,
  session: state.session.data,
  languages: getCustomLanguageList(state.experience.data, "draft")
});
const mapDispatchToProps = (dispatch) => ({
  addCookieBanner: (pageSectionType2, pageSectionIndex, cookieConsent) => dispatch(addSection(pageSectionType2, pageSectionIndex, cookieConsent)),
  deleteSection: (pageSectionIndex, cookieConsent) => dispatch(deleteSection(pageSectionIndex, cookieConsent)),
  setCurrentLanguage: (lang) => dispatch(setCurrentLanguage(lang)),
  setDefaultLanguage: (lang) => dispatch(setDefaultLanguage(lang)),
  addCustomLanguages: (lang) => dispatch(addCustomLanguage(lang)),
  deleteCustomLanguage: (lang) => dispatch(deleteCustomLanguage(lang)),
  setPageBuilderWidth: (width) => dispatch(setExperienceBuilderWidth(width)),
  publishExperience: (experience) => dispatch(publishExperience(experience)),
  discardDraftData: () => dispatch(discardDraftData()),
  saveDataAttributesDraft: (attributes) => dispatch(saveDataAttributesDraft(attributes)),
  openGlobalSidebar: () => dispatch(
    openGlobalSidebar({
      modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
      data: {
        sidebarUpgradeData: sidebarUpgradeSchema.default
      }
    })
  )
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalHoc(SettingsBar)));
