var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/oauth-applications/oauth-applications.actions";
export const initialState = {
  data: [],
  links: void 0,
  isFetching: false,
  error: void 0
};
export const oAuthApplications = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_OAUTH_APPLICATIONS.REQUEST:
    case ACTIONS.POST_OAUTH_APPLICATION.REQUEST:
    case ACTIONS.DELETE_OAUTH_APPLICATION.REQUEST:
    case ACTIONS.PATCH_OAUTH_APPLICATION.REQUEST:
    case ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_OAUTH_APPLICATIONS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_OAUTH_APPLICATION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.filter((e) => e.id !== action.payload),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.PATCH_OAUTH_APPLICATION.SUCCESS:
    case ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map(
          (e) => e.id === action.payload.data.id ? __spreadProps(__spreadValues({}, e), { attributes: __spreadValues(__spreadValues({}, e.attributes), action.payload.data.attributes) }) : e
        ),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.POST_OAUTH_APPLICATION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload.data, ...state.data],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_OAUTH_APPLICATIONS.FAILURE:
    case ACTIONS.POST_OAUTH_APPLICATION.FAILURE:
    case ACTIONS.DELETE_OAUTH_APPLICATION.FAILURE:
    case ACTIONS.PATCH_OAUTH_APPLICATION.FAILURE:
    case ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    default:
      return state;
  }
};
