import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { renderEditColorInput } from "../../page-section-utils";
import { EditUploadDesign } from "../../../";
const colorInputs = [
  { property: "buttonTextColor", label: "button text color" },
  { property: "buttonBackgroundColor", label: "button background color" },
  { property: "buttonBorderColor", label: "button border color" }
];
export class EditBackToTopStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Set your styles" }, colorInputs.map(
      (e, i) => renderEditColorInput(
        this.props.data.styles[e.property],
        (v) => this.props.styleDataUpdate({ [e.property]: v }),
        e.label,
        i
      )
    ), /* @__PURE__ */ React.createElement(
      EditUploadDesign,
      {
        dataUpdate: this.props.dataUpdate,
        styleDataUpdate: this.props.styleDataUpdate,
        data: this.props.data
      }
    ));
  }
}
