import React from "react";
import EditModalButton from "../../../../../../../components/edit-modal/edit-modal-footer/edit-modal-button/edit-modal-button";
import { EditInput, EditTextArea } from "../../../index";
import { EditModalStep } from "../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { Config } from "../../../../../../../utils/config";
import { createTranslationKey } from "../../../../../containers/experience/translation-creator";
export const FormSuccessMessageStep = ({
  onSuccessMessageSave,
  finishEditingSuccessMessage,
  onContentUpdate,
  content,
  onTranslationUpdate,
  onNewTranslationUpdate,
  getTranslation,
  experienceType
}) => {
  const addLink = () => {
    const successMessageLinks = content.successMessageLinks ? JSON.parse(JSON.stringify(content.successMessageLinks)) : [];
    const buttonTextTrKey = createTranslationKey(experienceType, "FORM", "BUTTON_TEXT", successMessageLinks.length);
    const buttonUrlTrKey = createTranslationKey(experienceType, "FORM", "BUTTON_URL", successMessageLinks.length);
    successMessageLinks.push({
      buttonText: buttonTextTrKey,
      buttonUrl: buttonUrlTrKey,
      id: Config.getUniqueId()
    });
    onNewTranslationUpdate(buttonTextTrKey, "");
    onNewTranslationUpdate(buttonUrlTrKey, "");
    onContentUpdate({ successMessageLinks });
  };
  const removeSuccessMessageLink = (i) => {
    const successMessageLinks = JSON.parse(JSON.stringify(content.successMessageLinks));
    successMessageLinks.splice(i, 1);
    onContentUpdate({ successMessageLinks });
  };
  const renderLinkInputs = () => {
    return content.successMessageLinks ? content.successMessageLinks.map((e, i) => {
      return /* @__PURE__ */ React.createElement("div", { key: e.id }, /* @__PURE__ */ React.createElement(
        EditInput,
        {
          defaultValue: getTranslation(e.buttonText) || "Button Text",
          onChange: (event) => onTranslationUpdate(e.buttonText, event.target.value),
          label: `button link ${i + 1}`,
          placeholder: "Button Text",
          rightIcon: "fas fa-trash",
          rightIconAction: () => removeSuccessMessageLink(i)
        }
      ), /* @__PURE__ */ React.createElement(
        EditInput,
        {
          defaultValue: getTranslation(e.buttonUrl),
          onChange: (event) => onTranslationUpdate(e.buttonUrl, event.target.value),
          placeholder: "Enter URL"
        }
      ));
    }) : null;
  };
  return /* @__PURE__ */ React.createElement(
    EditModalStep,
    {
      footer: /* @__PURE__ */ React.createElement(EditModalButton, { onClick: onSuccessMessageSave, text: "Done", size: "secondary" }),
      title: "Edit Success Message",
      closeModal: () => {
        finishEditingSuccessMessage();
      }
    },
    /* @__PURE__ */ React.createElement(
      EditInput,
      {
        onChange: (event) => {
          onTranslationUpdate(content.successMessageTitle, event.target.value);
        },
        label: "Line 1",
        placeholder: "Thank you!",
        defaultValue: getTranslation(content.successMessageTitle)
      }
    ),
    /* @__PURE__ */ React.createElement(
      EditTextArea,
      {
        onChange: (event) => {
          onTranslationUpdate(content.successMessageDescription, event.target.value);
        },
        label: "Line 2",
        placeholder: "The form was submitted successfully",
        defaultValue: getTranslation(content.successMessageDescription)
      }
    ),
    renderLinkInputs(),
    /* @__PURE__ */ React.createElement("div", { onClick: addLink, className: "form-section-add-link" }, /* @__PURE__ */ React.createElement("span", null, "Add Link"))
  );
};
export default FormSuccessMessageStep;
