import React from "react";
import "./alternative-text.sass";
import InputPrimary from "../../../../../components/interface/inputs/input-primary/input-primary";
import { Button as TintButton } from "../../../../../components/interface/button/button";
import { useFormik } from "formik";
const AlternativeText = ({ text, onUpdate }) => {
  const formik = useFormik({
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: {
      alternativeText: text
    }
  });
  const onUpdatePost = () => {
    onUpdate && onUpdate({
      alternative_text: formik.values.alternativeText
    });
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-alternative-text" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      name: "alternativeText",
      type: "text",
      value: formik.values.alternativeText,
      placeholder: "Alternative text",
      handleBlur: formik.handleBlur,
      handleChange: formik.handleChange
    }
  ), /* @__PURE__ */ React.createElement(TintButton, { text: text ? "Update" : "Done", type: "primary", onClick: onUpdatePost }));
};
export default React.memo(AlternativeText);
