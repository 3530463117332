import { preloadersConfig } from "../../../../../../components/interface/preloaders/preloaders.config";
export const feedCardLoaderRects = {
  desktop: [
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "24",
        y: "18",
        width: "14",
        height: "14",
        fill: preloadersConfig.fillColors.white,
        strokeWidth: "1",
        stroke: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "77%",
        y: "16",
        width: "18",
        height: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "82%",
        y: "16",
        width: "18",
        height: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "87%",
        y: "16",
        width: "18",
        height: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.circle,
      props: {
        cx: "95%",
        cy: "25",
        r: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "50",
        width: "100%",
        height: "1",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "24",
        y: "80",
        width: "10%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "80",
        width: "68%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "100",
        width: "66%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "120",
        width: "30%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "160",
        width: "120",
        height: "120",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "440",
        width: "100%",
        height: "1",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "24",
        y: "468",
        width: "154",
        height: "38",
        rx: "6",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "82%",
        y: "478",
        width: "14%",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "78%",
        y: "478",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    }
  ],
  desktopXXL: [
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "24",
        y: "18",
        width: "14",
        height: "14",
        fill: preloadersConfig.fillColors.white,
        strokeWidth: "1",
        stroke: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "82%",
        y: "16",
        width: "18",
        height: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "86%",
        y: "16",
        width: "18",
        height: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "90%",
        y: "16",
        width: "18",
        height: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.circle,
      props: {
        cx: "95%",
        cy: "25",
        r: "18",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "50",
        width: "100%",
        height: "1",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "24",
        y: "80",
        width: "10%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "80",
        width: "68%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "100",
        width: "66%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "120",
        width: "30%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "28%",
        y: "160",
        width: "120",
        height: "120",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "440",
        width: "100%",
        height: "1",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "24",
        y: "468",
        width: "154",
        height: "38",
        rx: "6",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "82%",
        y: "478",
        width: "14%",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "78%",
        y: "478",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    }
  ]
};
