import { useState } from "react";
const useSelectContentEditable = () => {
  const [currentSelection, secCurrentSelection] = useState();
  let sel = window.getSelection();
  function saveSelection() {
    if (window.getSelection) {
      if (sel.getRangeAt && sel.rangeCount) {
        secCurrentSelection(sel.getRangeAt(0));
        return sel.getRangeAt(0);
      }
    } else if (document.selection && document.selection.createRange) {
      secCurrentSelection(document.selection.createRange());
      return document.selection.createRange();
    }
    return null;
  }
  function getPrevSelection() {
    if (currentSelection) {
      if (window.getSelection) {
        sel.removeAllRanges();
        sel.addRange(currentSelection);
      } else if (document.selection && currentSelection.select) {
        currentSelection.select();
      }
    }
  }
  return [saveSelection, getPrevSelection];
};
export default useSelectContentEditable;
