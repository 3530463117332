import { ApiService } from "../../../../../../../services/api-service/api-service";
import {
  postSocialFeedStart,
  postSocialFeedSuccess,
  postSocialFeedError,
  patchSocialFeedStart,
  patchSocialFeedSuccess,
  patchSocialFeedError,
  getExperiencesStart,
  getExperiencesSuccess,
  getExperiencesError,
  getExperienceStart,
  getExperienceSuccess,
  getExperienceError,
  getSocialFeedsStart,
  getSocialFeedsSuccess,
  getSocialFeedsError,
  deleteSocialFeedStart,
  deleteSocialFeedSuccess,
  deleteSocialFeedError,
  getMissionsStart,
  getMissionsSuccess,
  getMissionsError,
  getFormsStart,
  getFormsSuccess,
  getFormsError,
  getFormStart,
  getFormSuccess,
  getFormError
} from "./social-feeds.actions";
export const postSocialFeed = ({ payload }) => {
  const data = {
    type: "social_feed",
    attributes: payload
  };
  return (dispatch) => {
    const url = "/social_feeds";
    dispatch(postSocialFeedStart());
    return ApiService.post(url, { data }).then((res) => dispatch(postSocialFeedSuccess(res.data.data))).catch((err) => {
      dispatch(postSocialFeedError(err));
      throw err;
    });
  };
};
export const patchSocialFeed = ({ payload, socialFeedId }) => {
  const data = {
    type: "social_feed",
    id: socialFeedId,
    attributes: payload
  };
  return (dispatch) => {
    const url = `/social_feeds/${socialFeedId}?include=tint`;
    dispatch(patchSocialFeedStart());
    return ApiService.patch(url, { data }).then((res) => dispatch(patchSocialFeedSuccess(res.data.data))).catch((err) => {
      dispatch(patchSocialFeedError(err));
      throw err;
    });
  };
};
export const deleteSocialFeed = (socialFeedId) => {
  return (dispatch) => {
    dispatch(deleteSocialFeedStart());
    return ApiService.delete(`/social_feeds/${socialFeedId}`).then(() => dispatch(deleteSocialFeedSuccess(socialFeedId))).catch((err) => {
      dispatch(deleteSocialFeedError(err));
      throw err;
    });
  };
};
export const getSocialConnections = ({ url = "/social_feeds" }) => {
  return (dispatch) => {
    dispatch(getSocialFeedsStart());
    return ApiService.get(url).then((resp) => {
      dispatch(getSocialFeedsSuccess(resp.data));
      return resp.data.data;
    }).catch((err) => {
      dispatch(getSocialFeedsError(err));
      throw err;
    });
  };
};
export const getForms = (url = "/forms") => {
  return (dispatch) => {
    dispatch(getFormsStart());
    return ApiService.get(url).then((resp) => {
      dispatch(getFormsSuccess(resp.data));
      return resp.data;
    }).catch((err) => {
      dispatch(getFormsError(err));
      throw err;
    });
  };
};
export const getExperiences = (url = "/experiences?include=form_submission_keys") => {
  return (dispatch) => {
    dispatch(getExperiencesStart());
    return ApiService.get(url).then((resp) => {
      dispatch(getExperiencesSuccess(resp.data));
      return resp.data;
    }).catch((err) => {
      dispatch(getExperiencesError(err));
      throw err;
    });
  };
};
export const getMissions = (url = "/missions") => {
  return (dispatch) => {
    dispatch(getMissionsStart());
    return ApiService.get(url).then((res) => {
      dispatch(getMissionsSuccess(res.data));
      return res.data;
    }).catch((err) => {
      dispatch(getMissionsError(err));
      throw err;
    });
  };
};
export const getExperience = ({ experienceId }) => {
  return (dispatch) => {
    const url = `/experiences/${experienceId}?include=form_submission_keys`;
    dispatch(getExperienceStart());
    return ApiService.get(url).then((res) => dispatch(getExperienceSuccess(res.data))).catch((err) => {
      dispatch(getExperienceError(err));
      throw err;
    });
  };
};
export const getForm = ({ formId }) => {
  return (dispatch) => {
    const url = `/forms/${formId}?include=form_submission_keys`;
    dispatch(getFormStart());
    return ApiService.get(url).then((res) => dispatch(getFormSuccess(res.data))).catch((err) => {
      dispatch(getFormError(err));
      throw err;
    });
  };
};
