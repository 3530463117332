export const formBuilderRoutePaths = {
  title: "Form Builder",
  newTitle: "Forms",
  path: "/form-builder",
  new: {
    title: "New Form Template",
    path: "/form-builder/new"
  },
  builder: {
    title: "Form Builder",
    path: "/form-builder/builder/:id",
    createUrl: (formId) => `/form-builder/builder/${formId}`,
    submission: {
      title: "Form Builder Report",
      path: "/form-builder/builder/:id/submission",
      createUrl: (formId) => `/form-builder/builder/${formId}/submission`
    }
  }
};
