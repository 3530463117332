import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_PLANS: createRoutine(createPrefixedActionName("SUBSCRIBE_PLAN", "GET_PLANS")),
  POST_PAYMENT: createRoutine(createPrefixedActionName("SUBSCRIBE_PLAN", "POST_PAYMENT"))
};
export const postPaymentStart = () => ({
  type: ACTIONS.POST_PAYMENT.REQUEST
});
export const postPaymentSuccess = (data) => ({
  type: ACTIONS.POST_PAYMENT.SUCCESS,
  payload: data
});
export const postPaymentError = (err) => ({
  type: ACTIONS.POST_PAYMENT.FAILURE,
  payload: err
});
export const getPlansStart = () => ({
  type: ACTIONS.GET_PLANS.REQUEST
});
export const getPlansSuccess = (data) => ({
  type: ACTIONS.GET_PLANS.SUCCESS,
  payload: data
});
export const getPlansError = (err) => ({
  type: ACTIONS.GET_PLANS.FAILURE,
  payload: err
});
