import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { FORM_BUILDER } from "../../../../actions/consts";
export const ACTIONS = {
  POST_FORM: createRoutine(createPrefixedActionName(FORM_BUILDER, "POST_FORMS")),
  GET_FORMS: createRoutine(createPrefixedActionName(FORM_BUILDER, "GET_FORMS")),
  UPDATE_FORM: createRoutine(createPrefixedActionName(FORM_BUILDER, "UPDATE_FORM")),
  DUPLICATE_FORM: createRoutine(createPrefixedActionName(FORM_BUILDER, "DUPLICATE_FORM")),
  DELETE_FORM: createRoutine(createPrefixedActionName(FORM_BUILDER, "DELETE_FORM")),
  BULK_UPDATE_FORMS_STATUS: createRoutine(createPrefixedActionName(FORM_BUILDER, "BULK_UPDATE_FORMS_STATUS")),
  BULK_DELETE_FORMS: createRoutine(createPrefixedActionName(FORM_BUILDER, "BULK_DELETE_FORMS")),
  SELECT_FORM: createPrefixedActionName(FORM_BUILDER, "SELECT_FORM"),
  UNSELECT_FORM: createPrefixedActionName(FORM_BUILDER, "UNSELECT_FORM"),
  DESELECT_ALL_FORMS: createPrefixedActionName(FORM_BUILDER, "DESELECT_ALL_FORMS"),
  RESET_STATE: createPrefixedActionName(FORM_BUILDER, "RESET_STATE")
};
export const getFormsStart = () => ({
  type: ACTIONS.GET_FORMS.REQUEST
});
export const getFormsSuccess = (data) => ({
  type: ACTIONS.GET_FORMS.SUCCESS,
  payload: data
});
export const getFormsError = (err) => ({
  type: ACTIONS.GET_FORMS.FAILURE,
  payload: err
});
export const postFormStart = () => ({
  type: ACTIONS.POST_FORM.REQUEST
});
export const postFormSuccess = (data) => ({
  type: ACTIONS.POST_FORM.SUCCESS,
  payload: data
});
export const postFormError = (err) => ({
  type: ACTIONS.POST_FORM.FAILURE,
  payload: err
});
export const updateFormStart = (fetchingFromId) => ({
  type: ACTIONS.UPDATE_FORM.REQUEST,
  payload: fetchingFromId
});
export const updateFormSuccess = (id, data) => ({
  type: ACTIONS.UPDATE_FORM.SUCCESS,
  payload: { id, data }
});
export const updateFormError = (err) => ({
  type: ACTIONS.UPDATE_FORM.FAILURE,
  payload: err
});
export const duplicateFormStart = (duplicatedId) => ({
  type: ACTIONS.DUPLICATE_FORM.REQUEST,
  payload: duplicatedId
});
export const duplicateFormSuccess = (data) => ({
  type: ACTIONS.DUPLICATE_FORM.SUCCESS,
  payload: data
});
export const duplicateFormError = (err) => ({
  type: ACTIONS.DUPLICATE_FORM.FAILURE,
  payload: err
});
export const deleteFormStart = (id) => ({
  type: ACTIONS.DELETE_FORM.REQUEST,
  payload: id
});
export const deleteFormSuccess = (formId) => ({
  type: ACTIONS.DELETE_FORM.SUCCESS,
  payload: formId
});
export const deleteFormError = (err) => ({
  type: ACTIONS.DELETE_FORM.FAILURE,
  payload: err
});
export const selectForm = (tint) => ({
  type: ACTIONS.SELECT_FORM,
  payload: tint
});
export const unselectForm = (id) => ({
  type: ACTIONS.UNSELECT_FORM,
  payload: id
});
export const deselectAllForms = () => ({
  type: ACTIONS.DESELECT_ALL_FORMS
});
export const bulkUpdateFormsStatusStart = () => ({
  type: ACTIONS.BULK_UPDATE_FORMS_STATUS.REQUEST
});
export const bulkUpdateFormsStatusSuccess = () => ({
  type: ACTIONS.BULK_UPDATE_FORMS_STATUS.SUCCESS
});
export const bulkUpdateFormsStatusError = (err) => ({
  type: ACTIONS.BULK_UPDATE_FORMS_STATUS.FAILURE,
  payload: err
});
export const bulkDeleteFormsStart = () => ({
  type: ACTIONS.BULK_DELETE_FORMS.REQUEST
});
export const bulkDeleteFormsSuccess = (id) => ({
  type: ACTIONS.BULK_DELETE_FORMS.SUCCESS,
  payload: id
});
export const bulkDeleteFormsError = (err) => ({
  type: ACTIONS.BULK_DELETE_FORMS.FAILURE,
  payload: err
});
