var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/gif-builder/asset-manager-gif-builder.actions";
import { Config } from "../../../../utils/config";
export const initialState = {
  assets: [],
  options: {
    delay: 1e3,
    loop: 0,
    width: 470,
    height: 470,
    fit: "clip",
    align: "center",
    background: "transparent"
  },
  zoomLevel: 100
};
export const assetManagerGifBuilder = (state = initialState, action) => {
  let draftAssets;
  switch (action.type) {
    case ACTIONS.SELECT_ASSET:
      return __spreadProps(__spreadValues({}, state), {
        assets: state.assets.map((e) => {
          e.isSelected = e.id === action.payload;
          return e;
        })
      });
    case ACTIONS.ADD_ASSETS:
      action.payload[0].isSelected = true;
      return __spreadProps(__spreadValues({}, state), {
        assets: action.payload
      });
    case ACTIONS.REMOVE_ASSETS:
      draftAssets = state.assets.filter((e) => e.id !== action.payload);
      if (!draftAssets.every((e) => e.isSelected) && draftAssets.length > 0) {
        draftAssets[0].isSelected = true;
      }
      return __spreadProps(__spreadValues({}, state), {
        assets: draftAssets
      });
    case ACTIONS.SET_OPTION:
      return __spreadProps(__spreadValues({}, state), {
        options: __spreadProps(__spreadValues({}, state.options), {
          [action.payload.key]: action.payload.value
        })
      });
    default:
      return state;
  }
};
export const getSelectedGifAsset = (state) => {
  return state.assets.find((e) => e.isSelected);
};
const _getTransformedFSUrl = (state, id) => {
  const _id = id.includes("preset=name") ? `https://process.filestackapi.com/${id}` : id;
  return `"https://process.filestackapi.com/${Config.fileStackApiKey.general()}/resize=w:${state.options.width},h:${state.options.height},fit:crop/${_id}",`;
};
export const getFilestackGifUrl = (state) => {
  let url = "animate=";
  for (let [key, value] of Object.entries(state.options)) {
    if (value && key !== "background") {
      url += `${key}:${value},`;
    }
  }
  url = url.slice(0, -1);
  url += "/[";
  state.assets.map((e) => {
    let id = e.attributes.url.replace("https://cdn.filestackcontent.com/", "");
    url += _getTransformedFSUrl(state, id);
  });
  url = url.slice(0, -1);
  url += "]";
  return url;
};
