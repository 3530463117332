import React, { forwardRef } from "react";
import ValidationAlert from "../validation-alert/validation-alert";
import "./input-add-tag.sass";
import PropTypes from "prop-types";
const InputAddTag = forwardRef(
  ({
    name,
    type,
    value,
    label,
    placeholder,
    error,
    touched,
    handleBlur,
    handleChange,
    readonly,
    disabled,
    onClick,
    handlePaste,
    focusOnInit,
    onFocus,
    onKeyDown
  }, ref) => {
    const isError = error && touched;
    const errorClassName = isError ? "input-primary--error" : "";
    return /* @__PURE__ */ React.createElement("div", { className: `input-add-tag ${errorClassName}` }, /* @__PURE__ */ React.createElement("div", { className: "input-add-tag__wrapper" }, label ? /* @__PURE__ */ React.createElement("label", null, label) : null, /* @__PURE__ */ React.createElement(
      "input",
      {
        autoFocus: focusOnInit,
        ref,
        placeholder,
        onChange: handleChange,
        onPaste: handlePaste,
        onBlur: handleBlur,
        onFocus,
        name,
        type,
        onKeyDown,
        value,
        readOnly: readonly,
        disabled,
        autoComplete: "off"
      }
    ), /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-plus", onClick })), /* @__PURE__ */ React.createElement(ValidationAlert, { errorMsg: error, isVisible: isError }));
  }
);
InputAddTag.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  dirty: PropTypes.bool,
  handleChange: PropTypes.func,
  handlePaste: PropTypes.func,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
InputAddTag.displayName = "InputAddTag";
export default InputAddTag;
