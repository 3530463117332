var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/social-feeds-sidebar.actions";
export const initialState = {
  opened: false,
  type: void 0,
  step: void 0,
  tintId: void 0
};
export const socialFeedsSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SOCIAL_FEEDS_SIDEBAR:
      return __spreadValues(__spreadProps(__spreadValues({}, state), {
        opened: true
      }), action.payload);
    case ACTIONS.UPDATE_SOCIAL_FEEDS_SIDEBAR:
      return __spreadValues(__spreadValues({}, state), action.payload);
    case ACTIONS.CLOSE_SOCIAL_FEEDS_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        opened: false,
        type: void 0,
        step: void 0,
        tintId: void 0
      });
    default:
      return state;
  }
};
export const selectCurrentActiveSocialFeedType = (state) => state.type;
export const selectCurrentActiveSocialFeedStep = (state) => state.step;
