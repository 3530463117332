var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import "./input-file.sass";
import ValidationAlert from "../validation-alert/validation-alert";
import PropTypes from "prop-types";
import { FileStack } from "../../../../services/filestack/file-stack";
import { getMaxImageSizePlanLimit } from "../../../../reducers/session";
const InputFile = forwardRef(
  ({
    name = "file",
    id = "file",
    accept,
    value,
    label,
    placeholder,
    error,
    touched,
    handleBlur,
    dirty,
    handleChange,
    readonly,
    disabled,
    style,
    useFileStack = false,
    isCropped = true,
    maxSize,
    defaultCropImageDimensions = {
      width: 64,
      height: 64
    },
    children
  }, ref) => {
    const isError = error && touched;
    const errorClassName = isError ? "tint-input-file--error" : "";
    const validClassName = !error && touched !== void 0 && dirty ? "tint-input-file--valid" : "";
    const [buttonLabel, setButtonLAbel] = useState(label);
    const [file, setFile] = useState();
    const maxImageSizePlanLimit = useSelector(getMaxImageSizePlanLimit);
    const clearCurrentFile = () => {
      setFile(void 0);
      setButtonLAbel(label);
      handleChange(void 0);
    };
    const openFilePicker = () => {
      if (file) {
        clearCurrentFile();
        return;
      }
      const fileStack = new FileStack();
      fileStack.openFilePicker({
        accept: accept || "image/*",
        maxSize: maxSize || maxImageSizePlanLimit,
        transformations: __spreadValues({
          circle: false
        }, isCropped && {
          crop: {
            force: true,
            aspectRatio: defaultCropImageDimensions.width / defaultCropImageDimensions.height
          }
        }),
        onFileUploadStarted: () => {
        },
        onUploadDone: (res) => {
          var _a, _b, _c;
          setButtonLAbel(`Clean ${(_a = res.filesUploaded[0]) == null ? void 0 : _a.filename}`);
          setFile((_b = res.filesUploaded[0]) == null ? void 0 : _b.url);
          handleChange((_c = res.filesUploaded[0]) == null ? void 0 : _c.url);
        }
      });
    };
    return useFileStack ? /* @__PURE__ */ React.createElement("div", { className: `tint-input-file ${errorClassName} ${validClassName}`, onClick: openFilePicker }, React.Children.map(
      children,
      (child) => React.cloneElement(child, {
        text: buttonLabel
      })
    )) : /* @__PURE__ */ React.createElement("div", { className: `tint-input-file ${errorClassName} ${validClassName}`, htmlFor: id }, /* @__PURE__ */ React.createElement(
      "input",
      {
        accept,
        ref,
        placeholder,
        onChange: handleChange,
        onClick: (e) => e.target.value = null,
        onBlur: handleBlur,
        name,
        type: "file",
        id,
        value,
        readOnly: readonly,
        multiple: true,
        disabled,
        style: __spreadValues({}, style)
      }
    ), !useFileStack && children ? React.Children.map(
      children,
      (child) => React.cloneElement(child, {
        text: buttonLabel
      })
    ) : /* @__PURE__ */ React.createElement("label", { htmlFor: id }, label), /* @__PURE__ */ React.createElement(ValidationAlert, { errorMsg: error, isVisible: isError }));
  }
);
InputFile.displayName = "InputFile";
export default InputFile;
InputFile.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ref: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.any
};
