var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { assetDisplayType } from "../../model/asset-display-type";
import { ACTIONS } from "../../actions/asset-collections/asset-collections.actions";
import { produce } from "immer";
export const initialState = {
  data: [],
  meta: void 0,
  links: void 0,
  isFetching: false,
  error: void 0,
  displayType: assetDisplayType.GRID_SMALL,
  isModalOpened: false,
  isSettingsModalOpened: false
};
export const assetCollections = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATCH_COLLECTION.SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.findIndex((e) => e.id === action.payload.id);
        draftState.data[index].attributes = __spreadValues(__spreadValues({}, draftState.data[index].attributes), action.payload.attributes);
      });
    case ACTIONS.PATCH_COLLECTION.REQUEST:
    case ACTIONS.GET_COLLECTIONS.REQUEST:
    case ACTIONS.DELETE_COLLECTIONS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true
      });
    case ACTIONS.GET_COLLECTIONS.ERROR:
    case ACTIONS.DELETE_COLLECTIONS.ERROR:
    case ACTIONS.GET_COLLECTIONS_PAGE.ERROR:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false
      });
    case ACTIONS.GET_COLLECTIONS_PAGE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.collections,
        links: action.payload.links,
        meta: action.payload.meta,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_COLLECTIONS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.collections,
        links: action.payload.links,
        meta: action.payload.links,
        isFetching: false,
        isError: false
      });
    case ACTIONS.OPEN_COLLECTION_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isModalOpened: true
      });
    case ACTIONS.DELETE_COLLECTIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((draftCollection) => {
          return !action.payload.find((collection) => {
            return draftCollection.id === collection.id;
          });
        });
      });
    case ACTIONS.CLEAR_COLLECTIONS:
      return __spreadProps(__spreadValues({}, initialState), { links: state.links, meta: state.meta });
    case ACTIONS.CLOSE_COLLECTION_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isModalOpened: false
      });
    case ACTIONS.CLOSE_COLLECTION_SETTINGS_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isSettingsModalOpened: false
      });
    case ACTIONS.OPEN_COLLECTION_SETTINGS_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        isSettingsModalOpened: true
      });
    default:
      return state;
  }
};
export const getSelectedCollections = (state) => state ? state.data.filter((e) => e.isSelected) : [];
export const isAnyCollectionSelected = (state) => getSelectedCollections(state).length > 0;
export default assetCollections;
