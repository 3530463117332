import React, { useCallback, useEffect, useMemo } from "react";
import { FORM_BUILDER_INPUT_CLASSES } from "../../../../utils/form-builder.types";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceMatrix = ({ item, values, formId, analyticForm, t }) => {
  var _a;
  if (!item) return null;
  const { legend, table, tbody, input, thead } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const isMultipleMatrix = (_a = item == null ? void 0 : item.class) == null ? void 0 : _a.includes(FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX);
  const theadTr = thead.elements.find((el) => el.nodeName === "tr");
  const inputs = useMemo(() => {
    return {};
  }, []);
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  const validateInputs = useCallback(() => {
    var _a2;
    (_a2 = Object.values(inputs)) == null ? void 0 : _a2.some((el) => {
      const isAnyInputChecked = el == null ? void 0 : el.some((input2) => input2 == null ? void 0 : input2.checked);
      if (isAnyInputChecked) {
        el == null ? void 0 : el.forEach((input2) => {
          input2 == null ? void 0 : input2.setCustomValidity("");
        });
      } else {
        const firstInvalidInput = el == null ? void 0 : el[0];
        firstInvalidInput == null ? void 0 : firstInvalidInput.setCustomValidity("Please tick any box if you want to proceed.");
      }
    });
  }, [inputs]);
  useEffect(() => {
    if (isMultipleMatrix && (input == null ? void 0 : input.required)) {
      const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
      tbody.elements.forEach((tr) => {
        const tdElements = tr.elements.filter((el) => (el == null ? void 0 : el.nodeName) === "td");
        tdElements.forEach((td) => {
          var _a2;
          const input2 = td.elements[0];
          const inputHTML = (_a2 = formDiv == null ? void 0 : formDiv.shadowRoot) == null ? void 0 : _a2.getElementById(input2.id);
          if (inputs == null ? void 0 : inputs[input2 == null ? void 0 : input2.name]) {
            inputs[input2.name].push(inputHTML);
          } else {
            inputs[input2.name] = [inputHTML];
          }
        });
      });
      validateInputs();
    }
  }, [input == null ? void 0 : input.id, input == null ? void 0 : input.required, inputs, formId, isMultipleMatrix, tbody.elements, validateInputs]);
  const onChange = () => {
    if (isMultipleMatrix && (input == null ? void 0 : input.required)) {
      validateInputs();
    }
  };
  return /* @__PURE__ */ React.createElement("fieldset", { className: `${item.class}` }, /* @__PURE__ */ React.createElement("legend", { id: legend.id }, t(legend == null ? void 0 : legend.nodeValue)), /* @__PURE__ */ React.createElement("table", { "aria-describedby": table["aria-describedby"] }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, theadTr.elements.map((th, i) => /* @__PURE__ */ React.createElement("th", { id: th.id, key: `${t(th.nodeValue)}-${i}` }, t(th.nodeValue))))), /* @__PURE__ */ React.createElement("tbody", null, tbody.elements.map((tr, i) => {
    return /* @__PURE__ */ React.createElement("tr", { key: i }, tr.elements.map((el, i2) => {
      const Tag = el.nodeName;
      return /* @__PURE__ */ React.createElement(Tag, { id: el == null ? void 0 : el.id, key: i2, "data-label": Tag === "td" ? t(theadTr.elements[i2].nodeValue) : "" }, (el == null ? void 0 : el.elements) ? el.elements.map((el2) => {
        const defaultValue = values == null ? void 0 : values.getAll(el2.name);
        return /* @__PURE__ */ React.createElement(
          "input",
          {
            onFocus,
            onBlur,
            key: i2,
            type: el2.type,
            id: el2.id,
            name: el2.name,
            value: el2.value,
            defaultChecked: (defaultValue == null ? void 0 : defaultValue.indexOf(el2 == null ? void 0 : el2.value)) >= 0,
            "aria-labelledby": el2["aria-labelledby"],
            required: !isMultipleMatrix ? el2 == null ? void 0 : el2.required : false,
            onChange
          }
        );
      }) : t(el.nodeValue));
    }));
  }))));
};
FormBuilderFormInterfaceMatrix.displayName = "FormBuilderFormInterfaceMatrix";
export default React.memo(FormBuilderFormInterfaceMatrix);
