import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import "./stripe-checkout-form.sass";
import { Config } from "../../utils/config";
import CheckoutFormWithForwardedRef from "./checkout-form/checkout-form";
const StripeCheckoutForm = ({ handleCheckout, onChange }, ref) => {
  const stripePromise = loadStripe(Config.stripePublishableKey());
  return /* @__PURE__ */ React.createElement("div", { className: "stripe-checkout-form" }, /* @__PURE__ */ React.createElement("div", { className: "stripe-checkout-form__elements" }, /* @__PURE__ */ React.createElement(Elements, { stripe: stripePromise }, /* @__PURE__ */ React.createElement(CheckoutFormWithForwardedRef, { ref, handleCheckout, onChange }))));
};
const StripeCheckoutFormWithForwardedRef = forwardRef(StripeCheckoutForm);
StripeCheckoutFormWithForwardedRef.propTypes = {
  onChange: PropTypes.func,
  handleCheckout: PropTypes.func
};
export default StripeCheckoutFormWithForwardedRef;
