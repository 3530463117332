var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { elementToComponent } from "../public/js/react/feature/experience/containers/experience/element-to-component";
import { setPreviewMode } from "../public/js/react/feature/experience/actions/experience-builder";
import { getExperienceSuccess } from "../public/js/react/feature/experience/actions/experience/experience.actions";
import { TintPagesScriptsHOC } from "./tint-pages-scripts.hoc";
import { ButtonType } from "../public/js/react/components/interface/dropdown/button/dropdown-button";
import { URLLanguageParamsHoc } from "../public/js/react/components/HOC/url-language-params/url-language-params.hoc";
import DropdownButton from "../public/js/react/components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../public/js/react/components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../public/js/react/components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import { Config } from "../public/js/react/utils/config";
import { AddFontLinkHoc } from "../public/js/react/components/HOC/add-font-link/add-font-link.hoc";
import { removeMethodIEPolyfill } from "./tint-pages-polyfill";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { pageSectionType } from "../public/js/react/model/page-section-type.model";
class TingPages extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "getIsGDPRSeen", () => {
      try {
        const GDPRComplianceLocalStorageValue = JSON.parse(
          window.localStorage.getItem(
            `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`
          )
        );
        return GDPRComplianceLocalStorageValue !== null && GDPRComplianceLocalStorageValue.seen;
      } catch (e) {
        return null;
      }
    });
    __publicField(this, "getIsGDPRAllowed", () => {
      try {
        const GDPRComplianceLocalStorageValue = JSON.parse(
          window.localStorage.getItem(
            `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`
          )
        );
        return GDPRComplianceLocalStorageValue !== null && (GDPRComplianceLocalStorageValue == null ? void 0 : GDPRComplianceLocalStorageValue.analytics) && (GDPRComplianceLocalStorageValue == null ? void 0 : GDPRComplianceLocalStorageValue.marketing);
      } catch (e) {
        return null;
      }
    });
    __publicField(this, "onHandleAction", (pageSection) => {
      switch (pageSection) {
        case pageSectionType.COOKIE_BANNER:
          this.setState({ cookieEnabled: true });
          if (!this.isAnalyticsCreated) {
            this.setState({ isAnalyticsCreated: true });
            this.addAnalyticsScripts(this.props.experience);
          }
          return;
        default:
          return;
      }
    });
    __publicField(this, "onLanguageChange", (currentItem) => {
      if (window.Tint && window.Tint.changeLanguage) {
        window.Tint.changeLanguage(currentItem.value);
      }
      this.props.updateLanguageQueryParam(currentItem.value);
    });
    __publicField(this, "getCurrentValue", () => {
      return this.props.languages.find((lang) => lang.value === this.props.currentLanguage);
    });
    this.state = {
      isFetchFail: false,
      cookieEnabled: false,
      pageWidth: 1100,
      isClient: false,
      isAnalyticsCreated: false
    };
  }
  getSlug() {
    return window.location.pathname.replace(/\//g, "");
  }
  getRequestAddress() {
    return this.getEmbeddedUrl() || `${Config.getApiUrl()}/v2/teams/${Config.getDomain()}/experiences/${this.getSlug()}`;
  }
  getEmbeddedUrl() {
    const tintAppNode = document.getElementById("tint-app");
    if (tintAppNode === null) {
      return void 0;
    }
    const domain = tintAppNode.getAttribute("data-domain");
    const slug = tintAppNode.getAttribute("data-slug");
    const locale = tintAppNode.getAttribute("data-locale");
    const localeUrl = locale ? `?lang=${locale}` : "";
    if (domain && slug) {
      return `${Config.getApiUrl()}/v2/teams/${domain}/experiences/${slug}${localeUrl}`;
    }
    return void 0;
  }
  getTeamId() {
    return this.getRequestAddress().replace("https://api.tintup.com/v2/teams/", "").split("/")[0];
  }
  componentDidMount() {
    removeMethodIEPolyfill();
    this.props.setPreviewMode(true);
    this.setState({ isClient: true });
    this.getExperienceData();
  }
  getExperienceData() {
    axios.get(this.getRequestAddress()).then(
      (res) => {
        this.setState({ cookieEnabled: res.data.data.attributes.cookie_consent });
        this.props.getExperienceSuccess(res.data.data);
        res.data.data.attributes.favicon_url && this.props.createFavIconLink(res.data.data.attributes.favicon_url);
        res.data.data.attributes.noindex && this.props.createNoIndexMeta();
        res.data.data.links.public && this.props.createCanonicalLink(res.data.data.links.public);
        this.props.loadFonts(
          res.data.data.attributes.data.translations.published[this.props.currentLanguage],
          this.getTeamId()
        );
        const title = this.props.experience.attributes.title;
        document.title = title ? title : document.title;
        this.addDescriptionTag(res.data.data.attributes.description);
        if (res.data.data.attributes.analytics_id && (res.data.data.attributes.cookie_consent && this.getIsGDPRAllowed() || !res.data.data.attributes.cookie_consent)) {
          this.addAnalyticsScripts(res.data.data);
        }
      },
      () => {
        this.setState({ isFetchFail: true });
      }
    );
  }
  addDescriptionTag(description) {
    if (!description) {
      return;
    }
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = description;
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
  addAnalyticsScripts(experience) {
    this.props.createAnalyticsScript(experience.attributes.analytics_type, experience.attributes.analytics_id);
  }
  renderSections(sectionArray, pageWidth) {
    return sectionArray.map((e, i) => {
      return /* @__PURE__ */ React.createElement(Fragment, { key: `${e.sectionType}_${i}` }, this.renderElementToComponent(e, i, pageWidth));
    });
  }
  renderElementToComponent(e, i, pageWidth) {
    return elementToComponent({
      element: e,
      pageWidth,
      _id: `${e.sectionType}_${i}`,
      index: i,
      translations: this.props.experience.attributes.data.translations.published,
      parentLocation: window.parent.location.hostname,
      cookieEnabled: this.state.cookieEnabled,
      handleAction: this.onHandleAction,
      isGDPRAllowed: this.getIsGDPRAllowed(),
      isGDPRSeen: this.getIsGDPRSeen(),
      teamId: this.getTeamId(),
      language: this.props.currentLanguage
    });
  }
  render() {
    if (this.state.isFetchFail) {
      return /* @__PURE__ */ React.createElement("h1", null, "We could not fetch this experience");
    }
    const placeholder = this.props.languages.find((lang) => lang.value === this.props.currentLanguage) ? this.props.languages.find((lang) => lang.value === this.props.currentLanguage).name : "";
    return this.state.isClient && this.props.experience ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      ToastContainer,
      {
        hideProgressBar: true,
        autoClose: 3e3,
        draggable: false,
        position: toast.POSITION.BOTTOM_LEFT
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "dashboard dashboard--padding-0" }, /* @__PURE__ */ React.createElement("div", { className: "experience-preview experience-preview--public" }, this.renderSections(this.props.experience.attributes.data.published, 1100), this.props.languages !== void 0 && this.props.languages.length > 1 ? /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "experience-preview__languages",
        style: {
          position: "absolute",
          right: 0,
          top: 0
        }
      },
      /* @__PURE__ */ React.createElement(
        DropdownButton,
        {
          currentItem: this.getCurrentValue(),
          dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
          dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
          list: this.props.languages,
          placeholder,
          iconRight: "fa fa-caret-down",
          buttonType: ButtonType.BUTTON_SORT,
          onChangeValue: this.onLanguageChange,
          onAction: this.onAddLanguage
        }
      )
    ) : null))) : null;
  }
}
const mapStateToProps = (state) => ({
  experience: state.experience.data
});
const mapDispatchToProps = (dispatch) => ({
  setPreviewMode: (isPreviewMode) => dispatch(setPreviewMode(isPreviewMode)),
  getExperienceSuccess: (data) => dispatch(getExperienceSuccess(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(URLLanguageParamsHoc(AddFontLinkHoc(TintPagesScriptsHOC(TingPages))));
