import { SocialAccountsFooterLabels } from "../helpers/helpers";
import { SidebarItemTypes } from "../../../../tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/meta/consts";
import { SocialFeedsSource } from "../../../../../types/social-feeds";
import * as Yup from "yup";
export const validateSchema = () => {
  return Yup.object({
    access_token: Yup.string().required("API Key is required.")
  });
};
export const creatorIq = [
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.firstStepNext
    }
  },
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.finalStepNext
    },
    accountSourceType: SocialFeedsSource.CREATOR_IQ,
    header: "Add CreatorIQ",
    selectedOption: { id: "account", title: "Add CreatorIQ" },
    formSchema: {
      account: {
        submitMapper: (values) => ({
          access_token: values.access_token
        }),
        validationSchema: validateSchema(),
        inputs: [
          {
            id: "access_token",
            backendId: "access_token",
            label: "API KEY",
            placeholder: "Enter API Key",
            type: SidebarItemTypes.TEXT
          }
        ]
      }
    }
  }
];
