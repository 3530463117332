var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState } from "react";
import { UploadServiceInit } from "../../../services/upload/upload.service";
export const imageTypes = ["image/jpeg", "image/png", "image/gif"];
export const videoTypes = ["video/mp4"];
export const useUploader = ({ onUploadSuccess, onPending, onUploadError, url }) => {
  const [isPending, setPending] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const getVideoDetails = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const videoBlob = new Blob([reader.result], { type: file.type });
      const video = document.createElement("video");
      video.addEventListener("loadedmetadata", function() {
        const width = video.videoWidth;
        const height = video.videoHeight;
        const duration = video.duration;
        resolve({ resolution: [width, height], duration });
      });
      video.onerror = () => {
        reject("There was some problem with your video.");
      };
      video.src = URL.createObjectURL(videoBlob);
    };
    reader.readAsArrayBuffer(file);
  });
  const getImageDimensions = (file) => new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve([width, height]);
    };
    img.onerror = () => {
      reject("There was some problem with your image.");
    };
    img.src = URL.createObjectURL(file);
  });
  const postFile = (file) => __async(void 0, null, function* () {
    return UploadServiceInit.PostAsset({ data: file, url }).then((res) => {
      return UploadServiceInit.UploadFile({
        url: res.data.data.attributes.url,
        body: file,
        headers: __spreadValues({}, res.data.data.attributes.headers),
        onUploadProgress: (progressEvent) => setUploadProgress(progressEvent.loaded / progressEvent.total * 100)
      }).then(() => __async(void 0, null, function* () {
        const oneSecInMs = 1e3;
        const resolution = file.type.includes("image") && (yield getImageDimensions(file));
        const videoDetails = file.type.includes("video") && (yield getVideoDetails(file));
        return {
          id: res.data.data.id,
          type: file.type,
          preview: URL.createObjectURL(file),
          poster: null,
          size: file.size,
          resolution: file.type.includes("image") ? resolution : videoDetails.resolution,
          duration: (videoDetails == null ? void 0 : videoDetails.duration) * oneSecInMs
        };
      }));
    }).catch((err) => {
      onUploadError == null ? void 0 : onUploadError(err);
      throw err;
    });
  });
  const onUpload = (files) => __async(void 0, null, function* () {
    if (!files.length) {
      return;
    }
    const promises = [...files].map((file) => postFile(file));
    setPending(files.length > 0);
    onPending == null ? void 0 : onPending(files.length > 0);
    yield Promise.all(promises).then((results) => {
      onUploadSuccess(results);
    }).catch((err) => {
      onUploadError == null ? void 0 : onUploadError(err);
    }).finally(() => {
      setPending(false);
      onPending == null ? void 0 : onPending(false);
    });
  });
  return {
    onUploadFiles: onUpload,
    isPending,
    uploadProgress
  };
};
