var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { COMMON_SETTINGS } from "./common-settings";
import { languageList } from "../configs/personalization-languages";
export const SLIDER = {
  name: "Horizontal Slider",
  key: "new_slider",
  premiumDesign: false,
  default_settings: __spreadProps(__spreadValues({}, COMMON_SETTINGS), {
    autoscroll: false,
    theme_layout: "text_on_hover",
    slider_is_carousel: "slider",
    post_background_color: "#ffffff",
    post_duration: "4000",
    sliderType: "one-row",
    themeName: "new_slider"
  }),
  guards: {
    CTA: true,
    waterfall: false,
    popup: true,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true
  },
  font_settings: [
    {
      key: "fontcolor_post",
      label: "Font Color",
      type: "colorPicker"
    },
    {
      key: "fontsize_secondary",
      type: "slider",
      label: "Font size",
      min: "8",
      max: "50"
    },
    {
      key: "color_namebar_buttons",
      label: "Accent Color",
      type: "colorPicker"
    }
  ],
  themes_settings: [
    {
      key: "autoscroll",
      type: "checkbox",
      label: "Autoscrolling Horizontal Slider"
    }
  ],
  extra_settings: [
    {
      key: "time_language",
      type: "dropdown",
      label: "Language for post timestamp",
      options: languageList
    },
    {
      key: "postwidth",
      type: "slider",
      label: "Minimum Post Width",
      min: "150",
      max: "1000"
    },
    {
      key: "postpadding",
      type: "slider",
      label: "Post Spacing",
      min: "0",
      max: "50"
    },
    {
      key: "theme_layout",
      label: "Theme Layout",
      type: "switcher",
      values: [
        { value: "text", label: "Show Text" },
        {
          value: "text_on_hover",
          label: "Text On Hover"
        },
        { value: "image", label: "Only Image" }
      ]
    },
    {
      key: "slider_is_carousel",
      label: "Slider's Variant",
      type: "switcher",
      sideObjectToUpdate: (value) => value === "carousel" ? { sliderType: "one-row" } : {},
      values: [
        { value: "carousel", label: "Carousel" },
        { value: "slider", label: "Slider" }
      ]
    },
    {
      key: "post_background_color",
      label: "Post Background Color",
      type: "colorPicker",
      defaultValue: "#ffffff"
    },
    {
      key: "sliderType",
      type: "dropdown",
      placeholder: "Select slider type",
      label: "Slider type",
      options: [
        {
          value: "one-row",
          name: "One row"
        },
        {
          value: "double-row",
          name: "Two rows"
        },
        {
          value: "highlight",
          name: "Two rows and highlight",
          isAvailable: (draft) => draft["slider_is_carousel"] === "slider"
        }
      ]
    },
    {
      key: "post_duration",
      type: "dropdown",
      label: "Post Duration",
      options: [
        {
          value: "4000",
          name: "4 seconds"
        },
        {
          value: "6000",
          name: "6 seconds"
        },
        {
          value: "8000",
          name: "8 seconds"
        },
        {
          value: "10000",
          name: "10 seconds"
        },
        {
          value: "15000",
          name: "15 seconds"
        },
        {
          value: "30000",
          name: "30 seconds"
        },
        {
          value: "60000",
          name: "60 seconds"
        }
      ]
    }
  ],
  extra_popup_settings: [
    // {
    //   key: 'popup_products_visibility',
    //   label: 'Hide Product Hotspots',
    //   control_type: 'Checkbox',
    //   target: '#personalize-popup-controls',
    //   add_class: 'control-divide-above',
    //   required_flags: ['ecommerce'],
    // },
  ]
};
