import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const fullName = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FULL_NAME]: {
    display: "flex"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FULL_NAME_INPUT_CLASS]: {
    display: "inline-block",
    margin: "0 10px 0 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FULL_NAME_INPUT_CLASS_LAST_CHILD]: {
    margin: "0 0 0 10px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FULL_NAME_INPUT_CLASS_RTL_SECOND_CHILD]: {
    margin: "0 0 0 10px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FULL_NAME_INPUT_CLASS_RTL_LAST_CHILD]: {
    margin: "0 10px 0 0"
  }
};
