export const missionHubsRoutePaths = {
  title: "Mission Hubs",
  newTitle: "Missions",
  path: "/missions",
  mission: {
    path: "/missions/:id",
    createMissionUrl: (id) => `/missions/${id}`,
    createVestaUrl: (id) => `/community-admin/mission_hubs/${id}/structure`
  }
};
