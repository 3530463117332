import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_EMAIL_TEMPLATE: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "GET_EMAIL_TEMPLATE")),
  POST_EMAIL_TEMPLATE: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "POST_EMAIL_TEMPLATE")),
  UPDATE_EMAIL_TEMPLATE: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "UPDATE_EMAIL_TEMPLATE")),
  ADD_LOCALES: createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "ADD_LOCALES"),
  REMOVE_LOCALES: createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "REMOVE_LOCALES"),
  UPDATE_TRANSLATION: createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "UPDATE_TRANSLATION"),
  DISCARD_DRAFT_EMAIL_TEMPLATE: createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "DISCARD_DRAFT_EMAIL_TEMPLATE"),
  UPDATE_DRAFT_EMAIL_TEMPLATE: createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "UPDATE_DRAFT_EMAIL_TEMPLATE"),
  GET_FORM_BUILDER_SUBMISSION: createRoutine(
    createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "GET_FORM_BUILDER_SUBMISSION")
  ),
  GET_FORM: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES_BUILDER", "GET_FORM"))
};
export const getEmailTemplateStart = () => ({
  type: ACTIONS.GET_EMAIL_TEMPLATE.REQUEST
});
export const getEmailTemplateSuccess = (data) => ({
  type: ACTIONS.GET_EMAIL_TEMPLATE.SUCCESS,
  payload: data
});
export const getEmailTemplateError = (err) => ({
  type: ACTIONS.GET_EMAIL_TEMPLATE.FAILURE,
  payload: err
});
export const getFormSubmissionStart = () => ({
  type: ACTIONS.GET_FORM_BUILDER_SUBMISSION.REQUEST
});
export const getFormSubmissionSuccess = (data) => ({
  type: ACTIONS.GET_FORM_BUILDER_SUBMISSION.SUCCESS,
  payload: data
});
export const getFormSubmissionError = (err) => ({
  type: ACTIONS.GET_FORM_BUILDER_SUBMISSION.FAILURE,
  payload: err
});
export const getFormStart = () => ({
  type: ACTIONS.GET_FORM.REQUEST
});
export const getFormSuccess = (data) => ({
  type: ACTIONS.GET_FORM.SUCCESS,
  payload: data
});
export const getFormError = (err) => ({
  type: ACTIONS.GET_FORM.FAILURE,
  payload: err
});
export const postEmailTemplateStart = () => ({
  type: ACTIONS.POST_EMAIL_TEMPLATE.REQUEST
});
export const postEmailTemplateSuccess = (data) => ({
  type: ACTIONS.POST_EMAIL_TEMPLATE.SUCCESS,
  payload: data
});
export const postEmailTemplateError = (err) => ({
  type: ACTIONS.POST_EMAIL_TEMPLATE.FAILURE,
  payload: err
});
export const updateEmailTemplateStart = () => ({
  type: ACTIONS.UPDATE_EMAIL_TEMPLATE.REQUEST
});
export const updateEmailTemplateSuccess = (data) => ({
  type: ACTIONS.UPDATE_EMAIL_TEMPLATE.SUCCESS,
  payload: data
});
export const updateEmailTemplateError = (err) => ({
  type: ACTIONS.UPDATE_EMAIL_TEMPLATE.FAILURE,
  payload: err
});
export const addLocales = (lang, locales) => ({
  type: ACTIONS.ADD_LOCALES,
  payload: { lang, locales }
});
export const updateTranslation = (lang, key, value) => ({
  type: ACTIONS.UPDATE_TRANSLATION,
  payload: { lang, key, value }
});
export const removeLocales = (lang) => ({
  type: ACTIONS.REMOVE_LOCALES,
  payload: { lang }
});
export const discardDraftEmailTemplate = () => ({
  type: ACTIONS.DISCARD_DRAFT_EMAIL_TEMPLATE
});
export const updateDraftEmailTemplate = (key, value) => ({
  type: ACTIONS.UPDATE_DRAFT_EMAIL_TEMPLATE,
  payload: { key, value }
});
