import { ACTIONS } from "../actions/subscribe-plan.actions";
import { produce } from "immer";
const initialState = {
  data: [],
  isFetching: false
};
export const subscribePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_PLANS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload;
        draftState.isFetching = false;
        return draftState;
      });
    case ACTIONS.GET_PLANS.FAILURE:
    case ACTIONS.GET_PLANS.REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        return draftState;
      });
    default:
      return state;
  }
};
