import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { creatorIqDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { validationSchemaCreatorIq } from "../utils/validation-schema/creator-iq-schema";
const dropdownCampaignDefaultValue = {
  value: "",
  name: "Select campaign"
};
const clearSubAccountsDropdown = (formik) => formik.setFieldValue("campaign", dropdownCampaignDefaultValue);
const initializeApprovedOnlyheckedValue = (socialFeed, option) => {
  var _a, _b;
  return ((_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.pre_processing_rules) == null ? void 0 : _b.some((v) => v.conditions["right_request.status"])) ? option : null;
};
export const creatorIq = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "creator_iq",
    selectedOption: { id: "campaign", title: "Add CreatorIQ" },
    header: "Add CreatorIQ",
    description: "To ingest content from CreatorIQ, select your CreatorIQ account and a campaign:",
    formSchema: {
      campaign: {
        validationSchema: validationSchemaCreatorIq(),
        submitMapper: (values) => {
          var _a;
          return {
            account_id: values.account.value,
            external_id: values.campaign.value,
            pre_processing_rules: [
              ...((_a = values.checked) == null ? void 0 : _a.includes("approved_only")) ? [{
                conditions: {
                  "right_request.status": "approved"
                }
              }] : []
            ]
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            clearSubAccountsDropdown
          },
          {
            id: "campaign",
            backendId: "external_id",
            label: "SELECT CAMPAIGN",
            placeholder: "Select Campaign...",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.SELECT,
            disabled: creatorIqDisabledButton,
            lineBreak: true
          },
          {
            id: "approved_only",
            type: SidebarItemTypes.CHECKBOX,
            label: "Only ingest content with usage rights",
            defaultValue: initializeApprovedOnlyheckedValue,
            disabled: creatorIqDisabledButton
          }
        ]
      }
    }
  }
];
