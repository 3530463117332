var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import TintModal from "../modal/modal";
import ModalHeader from "../modal/components/modal-header/modal-header";
import ModalFooter from "../modal/components/footers/modal-footer/modal-footer";
export const ConfirmModalResult = {
  CONFIRM: "confirm",
  REJECT: "reject"
};
export const confirmModalId = "confirm-modal-id";
const ConfirmModal = ({
  onChange,
  fn,
  leftButtonText,
  rightButtonText,
  confirmButtonType = "primary",
  description
}) => {
  return /* @__PURE__ */ React.createElement(
    TintModal,
    {
      headerTitle: "Are you sure?",
      modalHeader: (props) => /* @__PURE__ */ React.createElement(ModalHeader, __spreadValues({}, props)),
      modalFooter: (props) => /* @__PURE__ */ React.createElement(
        ModalFooter,
        __spreadProps(__spreadValues({}, props), {
          closeButtonText: leftButtonText,
          confirmButtonType,
          confirmButtonText: rightButtonText
        })
      ),
      isOpen: true,
      onConfirm: () => onChange(ConfirmModalResult.CONFIRM, fn),
      onClose: () => onChange(ConfirmModalResult.REJECT, fn)
    },
    /* @__PURE__ */ React.createElement("p", { className: "secondary-text" }, description)
  );
};
export default ConfirmModal;
