var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState } from "react";
const handleResponse = (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
};
export const useFormBuilderCountries = ({ item, formId, locale, onPending } = {}) => {
  const elements = item == null ? void 0 : item.elements;
  const divs = elements == null ? void 0 : elements.filter((el) => (el == null ? void 0 : el.nodeName) === "div");
  const addressElements = divs == null ? void 0 : divs.map((div) => div == null ? void 0 : div.elements);
  const inputs = addressElements == null ? void 0 : addressElements.map((arr) => arr == null ? void 0 : arr.find((el) => (el == null ? void 0 : el.nodeName) === "input" || (el == null ? void 0 : el.nodeName) === "select"));
  const countrySelect = inputs == null ? void 0 : inputs.find((el) => {
    var _a;
    return (_a = el == null ? void 0 : el.id) == null ? void 0 : _a.includes("country");
  });
  const [countryDetails, setCountryDetails] = useState({});
  const [countriesList, setCountriesList] = useState(countrySelect == null ? void 0 : countrySelect.elements);
  const getCountryDetails = (_0) => __async(void 0, [_0], function* ({ countryId }) {
    onPending(true, countrySelect.name);
    try {
      const response = yield fetch(
        `https://api.tintup.com/v2/countries/${countryId}?include=subdivisions${locale ? `&locale=${locale}` : ""}`
      );
      handleResponse(response);
      const data = yield response.json();
      return data;
    } catch (err) {
      throw new Error(`Cannot fetch country details: ${err.message}`);
    } finally {
      onPending(false, countrySelect.name);
    }
  });
  const getCountriesLocales = (_0) => __async(void 0, [_0], function* ({ countriesIds }) {
    onPending(true, countrySelect.name);
    try {
      const response = yield fetch(`https://api.tintup.com/v2/countries?filter[id]=${countriesIds}&locale=${locale}`);
      handleResponse(response);
      const data = yield response.json();
      return data.data;
    } catch (err) {
      throw new Error(`Cannot fetch countries locales: ${err.message}`);
    } finally {
      onPending(false, countrySelect.name);
    }
  });
  const getCountriesList = () => __async(void 0, null, function* () {
    try {
      const response = yield fetch("https://api.tintup.com/v2/countries");
      handleResponse(response);
      const data = yield response.json();
      return data.data;
    } catch (err) {
      throw new Error(`Cannot fetch countries list: ${err.message}`);
    }
  });
  const updateCountryDetails = (countryId = "en_US") => __async(void 0, null, function* () {
    var _a, _b;
    const details = yield getCountryDetails({ countryId });
    const subdivisions = ((_b = (_a = details == null ? void 0 : details.included) == null ? void 0 : _a.filter((el) => (el == null ? void 0 : el.type) === "subdivision")) == null ? void 0 : _b.map((subdivision) => {
      var _a2;
      return {
        value: subdivision == null ? void 0 : subdivision.id,
        name: (_a2 = subdivision == null ? void 0 : subdivision.attributes) == null ? void 0 : _a2.name
      };
    })) || [];
    setCountryDetails(__spreadProps(__spreadValues({}, details), { subdivisionsList: [...subdivisions] }));
  });
  const updateCountriesNames = (_0) => __async(void 0, [_0], function* ({ countriesIds }) {
    const localizedCountries = yield getCountriesLocales({ countriesIds });
    setCountriesList(
      (cl) => cl.map((country) => {
        var _a, _b;
        const translatedName = (_b = (_a = localizedCountries == null ? void 0 : localizedCountries.find((localizedCountry) => (localizedCountry == null ? void 0 : localizedCountry.id) === country.value)) == null ? void 0 : _a.attributes) == null ? void 0 : _b.name;
        return __spreadProps(__spreadValues({}, country), { name: translatedName });
      })
    );
  });
  const onCountryChange = ({ e, localizeCountryNames }) => {
    var _a, _b, _c;
    const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
    if (formDiv) {
      const selectedCountry = (_b = (_a = e == null ? void 0 : e.target) == null ? void 0 : _a.selectedOptions) == null ? void 0 : _b[0];
      const countryInputHTML = (_c = formDiv == null ? void 0 : formDiv.shadowRoot) == null ? void 0 : _c.getElementById(countrySelect.id);
      if (localizeCountryNames) {
        const countriesIds = [...countrySelect.elements].map((country) => country.value);
        updateCountriesNames({ countriesIds });
      }
      updateCountryDetails(selectedCountry ? selectedCountry.value : countryInputHTML.selectedOptions[0].value);
    }
  };
  return {
    localizedCountriesList: countriesList,
    countryDetails,
    onCountryChange,
    getCountryDetails,
    getCountriesList,
    updateCountryDetails
  };
};
