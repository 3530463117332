var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import "./visual-search-filter.sass";
import { Button } from "../../button/button";
import { faArrowUpFromLine } from "@fortawesome/pro-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFileStack } from "../../../hooks/use-filestack/use-filestack";
import { useVisualSearch } from "../../../hooks/use-visual-search/use-visual-search";
import { Typography, TypographyColors } from "../../typography/typography";
import { getVisualSearchImage } from "../../../../feature/tint-editor/reducers/posts.reducer";
import { selectFilters } from "../../../../feature/tint-editor/actions/posts.actions";
export const VisualSearchFilter = () => {
  var _a;
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const { tintId } = useParams();
  const teamId = (_a = session == null ? void 0 : session.data) == null ? void 0 : _a.team_id;
  const pageSize = useSelector((state) => state.tintEditorPosts.pageSize || 20);
  const selectedFilters = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.selectedFilters);
  const sortByFilters = useSelector((state) => {
    var _a2;
    return (_a2 = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a2.sortPostsBy;
  });
  const visualSearchImage = useSelector(getVisualSearchImage);
  const onUploadSuccess = (res) => {
    var _a2;
    onVisualSearchUpload({
      tintId,
      teamId,
      src: (_a2 = res == null ? void 0 : res[0]) == null ? void 0 : _a2.url,
      pageSize,
      filters: selectedFilters,
      sortBy: __spreadProps(__spreadValues({}, sortByFilters), { sortBy: "score" })
    });
  };
  const onRemoveVisualSearch = () => {
    dispatch(selectFilters([], "visually_similar_to"));
  };
  const { onOpenFilePicker } = useFileStack({ onUploadSuccess });
  const { onVisualSearchUpload } = useVisualSearch();
  return /* @__PURE__ */ React.createElement("div", { className: "tint-visual-search-filter" }, visualSearchImage ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-visual-search-filter__uploaded-file" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", { src: visualSearchImage, alt: "" }), /* @__PURE__ */ React.createElement(Typography, { color: TypographyColors.WHITE }, "Selected Post")), /* @__PURE__ */ React.createElement(Button, { type: "link", onClick: onRemoveVisualSearch }, "Remove"))) : /* @__PURE__ */ React.createElement(Button, { onClick: onOpenFilePicker, size: "full-width", icon: faArrowUpFromLine, type: "dashed" }, "Click to Upload Image"));
};
