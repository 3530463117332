import React from "react";
import { FormBuilderFormHint } from "./common/hint/form-builder-form.hint";
import { FormBuilderElementsMappingService } from "../../../services/builder/form-builder.elements-mapping.service";
export const FormBuilderFormInterfaceInput = ({ item, values, analyticForm, t }) => {
  if (!item) return null;
  const { input, label, p: hint } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const handleFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const handleBlur = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  };
  return /* @__PURE__ */ React.createElement("div", { className: item.class }, /* @__PURE__ */ React.createElement("label", { htmlFor: label.for }, t(label.nodeValue)), /* @__PURE__ */ React.createElement(
    "input",
    {
      onFocus: handleFocus,
      onBlur: handleBlur,
      id: input.id,
      name: input.name,
      min: input.min,
      max: input.max,
      minLength: input.minlength,
      maxLength: input.maxlength,
      type: input.type,
      defaultValue: values == null ? void 0 : values.getAll(input.name),
      autoComplete: input.autocomplete,
      pattern: input.pattern,
      placeholder: t(input.placeholder),
      required: input.required,
      "aria-describedby": input["aria-describedby"]
    }
  ), hint && /* @__PURE__ */ React.createElement(FormBuilderFormHint, { item: t(hint == null ? void 0 : hint.nodeValue), id: hint == null ? void 0 : hint.id }));
};
FormBuilderFormInterfaceInput.displayName = "FormBuilderFormInterfaceInput";
export default React.memo(FormBuilderFormInterfaceInput);
