var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { forwardRef } from "react";
import "./input-primary.sass";
import ValidationAlert from "../validation-alert/validation-alert";
const InputPrimary = forwardRef(
  ({
    name,
    type,
    value,
    label,
    labelStyleProps = {},
    placeholder,
    error,
    touched,
    handleBlur,
    dirty,
    handleChange,
    onKeyDown,
    defaultValue,
    readonly,
    disabled,
    errorColor,
    style,
    onFocus,
    isCustomPlaceholderColor = false,
    rightIcon,
    rightIconAction,
    autoComplete = "on",
    min,
    max,
    pattern,
    classes,
    autoFocus = false
  }, ref) => {
    const isError = error && touched;
    const errorClassName = isError && !errorColor ? "input-primary--error" : "";
    const validClassName = !error && touched !== void 0 && dirty ? "input-primary--valid" : "";
    const customPlaceholderColorClass = isCustomPlaceholderColor ? "input-primary--custom-placeholder-color" : "";
    const getErrorStyles = () => {
      return isError ? { border: `1px solid ${errorColor}`, color: `${errorColor}` } : {};
    };
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `input-primary ${errorClassName} ${validClassName} ${customPlaceholderColorClass} ${classes}`,
        "data-testid": `input_${name}`
      },
      label ? /* @__PURE__ */ React.createElement("label", { style: __spreadValues({}, labelStyleProps) }, label) : null,
      /* @__PURE__ */ React.createElement("div", { className: "input-primary__wrapper" }, /* @__PURE__ */ React.createElement(
        "input",
        {
          "data-testid": "inputPrimary",
          ref,
          min,
          max,
          placeholder,
          onChange: handleChange,
          onBlur: handleBlur,
          name,
          type,
          autoFocus,
          onKeyDown,
          value,
          defaultValue,
          readOnly: readonly,
          disabled,
          style: __spreadValues(__spreadValues({}, style), getErrorStyles()),
          onFocus,
          autoComplete,
          pattern
        }
      ), rightIcon && /* @__PURE__ */ React.createElement("span", { className: `icon icon--right ${rightIcon}`, onClick: rightIconAction })),
      /* @__PURE__ */ React.createElement(ValidationAlert, { errorMsg: error, isVisible: isError, color: errorColor })
    );
  }
);
InputPrimary.displayName = "InputPrimary";
export default InputPrimary;
