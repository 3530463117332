import React from "react";
import "./edit-input-container.sass";
import PropTypes from "prop-types";
const EditInputContainer = ({ title, children }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "unique-tint-edit-input-container" }, /* @__PURE__ */ React.createElement("p", { className: "unique-tint-edit-input-container__title" }, title), children);
};
EditInputContainer.propTypes = {
  title: PropTypes.string
};
export default EditInputContainer;
