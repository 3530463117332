import React from "react";
import EditModalButton from "../../../../../../../components/edit-modal/edit-modal-footer/edit-modal-button/edit-modal-button";
import { EditModalStep } from "../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditTextArea } from "../../../index";
export const FormRulesStep = ({
  stepTitle,
  onContentUpdate,
  editTextAreaPlaceholder,
  textareaDefaultValue,
  closeModal,
  selectStep
}) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    EditModalStep,
    {
      closeModalMain: closeModal,
      footer: /* @__PURE__ */ React.createElement(EditModalButton, { onClick: () => selectStep(0), text: "Done", size: "secondary" }),
      title: stepTitle
    },
    /* @__PURE__ */ React.createElement(
      EditTextArea,
      {
        onChange: onContentUpdate,
        placeholder: editTextAreaPlaceholder,
        defaultValue: textareaDefaultValue
      }
    )
  ));
};
