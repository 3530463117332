var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState } from "react";
import * as OAuth from "oauth4webapi";
import { Config } from "../../../utils/config";
import { ApiService } from "../../../services/api-service/api-service";
export const oauthConfig = {
  clientId: Config.getEnvironmentVariable("CLIENT_ID"),
  authorizationEndpoint: Config.getApiUrl() + "/oauth/authorize",
  tokenEndpoint: Config.getApiUrl() + "/oauth/token",
  redirectUri: `${Config.getEnvironmentVariable("BASE_URL")}auth-verifier`,
  scopes: ["all"]
};
export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};
export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};
export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};
export const setRefreshToken = (token) => {
  sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
};
export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY);
};
export const useOAuth = () => {
  const [authUrl, setAuthUrl] = useState("");
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const redirectToLogin = () => {
    generatePKCE().then((authUrl2) => {
      window.location.href = authUrl2;
    });
  };
  const clearStorage = () => {
    removeAccessToken();
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem("code_verifier");
    ApiService.setAuth({ token: null });
  };
  const onAppLoadingInit = () => {
    const token2 = getAccessToken();
    if (!token2) {
      generatePKCE().then((authUrl2) => {
        window.location.href = authUrl2;
      });
      return;
    }
    ApiService.setAuth({ token: token2, requestRefreshToken, redirectToLogin });
  };
  const generatePKCE = () => __async(void 0, null, function* () {
    const code_verifier = OAuth.generateRandomCodeVerifier();
    const code_challenge = yield OAuth.calculatePKCECodeChallenge(code_verifier);
    sessionStorage.setItem("code_verifier", code_verifier);
    sessionStorage.setItem("redirect_url", window.location.href);
    const url = new URL(oauthConfig.authorizationEndpoint);
    url.searchParams.append("client_id", oauthConfig.clientId);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", oauthConfig.redirectUri);
    url.searchParams.append("scope", oauthConfig.scopes.join(" "));
    url.searchParams.append("code_challenge", code_challenge);
    url.searchParams.append("code_challenge_method", "S256");
    setAuthUrl(url.toString());
    return url.toString();
  });
  const requestRefreshToken = () => __async(void 0, null, function* () {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      throw new Error("No refresh token found");
    }
    const params = new URLSearchParams();
    params.append("client_id", oauthConfig.clientId);
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refreshToken);
    const response = yield fetch(oauthConfig.tokenEndpoint, {
      method: "POST",
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    const data = yield response.json();
    if (response.ok) {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      sessionStorage.removeItem("code_verifier");
      ApiService.setAuth({ token: data.access_token });
      setToken(data);
    } else {
      clearStorage();
      redirectToLogin();
      return "error";
    }
  });
  const handleCallback = (code) => __async(void 0, null, function* () {
    try {
      const code_verifier = sessionStorage.getItem("code_verifier");
      if (!code_verifier) {
        throw new Error("Code verifier not found in session storage");
      }
      const params = new URLSearchParams();
      params.append("grant_type", "authorization_code");
      params.append("code", code);
      params.append("redirect_uri", oauthConfig.redirectUri);
      params.append("client_id", oauthConfig.clientId);
      params.append("code_verifier", code_verifier);
      const response = yield fetch(oauthConfig.tokenEndpoint, {
        method: "POST",
        body: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      const data = yield response.json();
      if (response.ok) {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
        sessionStorage.removeItem("code_verifier");
        ApiService.setAuth({ token: data.access_token });
        setToken(data);
      } else {
        setError((data == null ? void 0 : data.error_description) || "Token request failed");
      }
    } catch (err) {
      setError(err);
    }
  });
  const logout = () => __async(void 0, null, function* () {
    var _a;
    try {
      const accessToken = getAccessToken();
      const params = new URLSearchParams();
      params.append("client_id", oauthConfig.clientId);
      params.append("token", accessToken);
      const response = yield ApiService.pureInstance().post(Config.getApiUrl() + "/oauth/revoke", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      if (response.status === 200) {
        clearStorage();
        window.location.href = `${Config.getApiUrl()}/credentials/sign_out`;
      } else {
        setError(((_a = response.data) == null ? void 0 : _a.error_description) || "Token revocation failed");
      }
    } catch (err) {
      setError(err.message || "An error occurred");
    }
  });
  return {
    authUrl,
    token,
    error,
    handleCallback,
    generatePKCE,
    logout,
    requestRefreshToken,
    onAppLoadingInit,
    redirectToLogin
  };
};
