var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import TimeAgo from "react-timeago";
import { useDispatch } from "react-redux";
import "./feed-card-horizontal.comments.sass";
import { Typography } from "../../../../../../components/interface/typography/typography";
import { DropdownHeader } from "../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import DropdownButton, { ButtonType } from "../../../../../../components/interface/dropdown/button/dropdown-button";
import { Button } from "../../../../../../components/interface/button/button";
import TextareaPrimary from "../../../../../../components/interface/inputs/textarea-primary/textarea-primary";
import {
  patchComment,
  deleteComment as deleteCommentAction,
  postComment as postCommentAction
} from "../../../../actions/comments.fat-actions";
import { deleteReply } from "../../../../actions/posts.actions";
import Preloader, {
  PreloaderBackdropType,
  PreloaderPositionType
} from "../../../../../../components/interface/preloaders/preloader/preloader";
export const FeedCardHorizontalComments = ({ post, getTintEditorPosts }) => {
  var _a, _b;
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [fetchingReplyId, setFetchingReplyId] = useState(null);
  const [addingComment, setAddingComment] = useState(false);
  const [commentVisibility, setCommentVisibility] = useState("active");
  const dispatch = useDispatch();
  const onVisibilityChange = (value, reply) => {
    setFetchingReplyId(reply.id);
    switch (value.value) {
      case "active":
        dispatch(patchComment({ visibility: "active" }, reply.id)).then(() => setFetchingReplyId(null));
        break;
      case "inactive":
        dispatch(patchComment({ visibility: "inactive" }, reply.id)).then(() => setFetchingReplyId(null));
        break;
    }
  };
  const deleteComment = (reply) => {
    setFetchingReplyId(reply.id);
    dispatch(deleteCommentAction(reply.id)).then(() => {
      dispatch(deleteReply({ replyId: reply.id, postId: post.id }));
      setFetchingReplyId(null);
    });
  };
  const postComment = (text) => {
    if (!text) return;
    if (text.length > 500) {
      setError("Comment is too long, 500 is max");
      return;
    }
    setAddingComment(true);
    dispatch(
      postCommentAction({
        external_id: post.attributes.external_id,
        text,
        source: post.attributes.type,
        visibility: commentVisibility
      })
    ).then(() => {
      setAddingComment(false);
      getTintEditorPosts();
    });
  };
  const onChange = (e) => {
    setComment(e.target.value);
    if (e.target.value.length > 500) {
      setError("Comment is too long, 500 is max");
      return;
    }
    setError("");
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments" }, addingComment && /* @__PURE__ */ React.createElement(Preloader, { backdropType: PreloaderBackdropType.COVER, positionType: PreloaderPositionType.ABSOLUTE }), (_b = (_a = post.replies) == null ? void 0 : _a.map) == null ? void 0 : _b.call(_a, (reply) => {
    var _a2, _b2, _c, _d;
    return /* @__PURE__ */ React.createElement("div", { key: reply.id, className: "tint-feed-card-horizontal-comments__comment" }, fetchingReplyId === reply.id && /* @__PURE__ */ React.createElement(Preloader, { backdropType: PreloaderBackdropType.COVER, positionType: PreloaderPositionType.ABSOLUTE }), /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__left-column" }, /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__comment-content" }, /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__comment-author" }, /* @__PURE__ */ React.createElement(Typography, { color: "#373740" }, `${(_b2 = (_a2 = reply == null ? void 0 : reply.user) == null ? void 0 : _a2.attributes) == null ? void 0 : _b2.first_name} ${(_d = (_c = reply == null ? void 0 : reply.user) == null ? void 0 : _c.attributes) == null ? void 0 : _d.last_name}`)), /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__comment-date" }, /* @__PURE__ */ React.createElement(TimeAgo, { styles: { color: "#797E86" }, date: new Date(reply.attributes.created_at) }))), /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__text" }, /* @__PURE__ */ React.createElement(Typography, null, reply.attributes.text))), /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__right-column" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: [
          { name: "Public", value: "active" },
          { name: "Private", value: "inactive" }
        ],
        placeholder: reply.attributes.visibility === "active" ? "Public" : "Private",
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        iconRight: "fa fa-caret-down",
        onChangeValue: (e) => onVisibilityChange(e, reply)
      }
    ), /* @__PURE__ */ React.createElement(Button, { type: "light-gray", onClick: () => deleteComment(reply) }, "Delete")));
  }), /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__textarea-container" }, /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      error,
      touched: true,
      placeholder: "Enter a comment",
      value: comment,
      handleChange: onChange
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "tint-feed-card-horizontal-comments__actions" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      list: [
        { name: "Public", value: "active" },
        { name: "Private", value: "inactive" }
      ],
      placeholder: "Public",
      buttonType: ButtonType.BUTTON_GRAY_BORDER,
      iconRight: "fa fa-caret-down",
      onChangeValue: (e) => setCommentVisibility(e.value)
    }
  ), /* @__PURE__ */ React.createElement(Button, { type: "info", text: "Post", onClick: () => postComment(comment) }))));
};
