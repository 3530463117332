export const FORM_BUILDER_TEMPLATE_TYPE = {
  BLANK: "custom",
  PRODUCT_REVIEW: "product_review",
  PUBLIC_POST: "public_post",
  EVENT_FEEDBACK: "event_feedback",
  NET_PROMOTER_SCORE: "net_promoter_score",
  CUSTOMER_QUESTIONNAIRE: "customer_questionnaire",
  EMPLOYEE_FEEDBACK: "employee_feedback",
  SURVEY: "survey",
  CONTACT_US: "contact_us",
  WEBSITE_FEEDBACK: "website_feedback",
  TESTIMONIAL: "testimonial",
  COMMUNITY: "community_sign_up",
  COMMUNITY_PRODUCT_REVIEW: "community_product_review"
};
export const FORM_BUILDER_TEMPLATE_LABEL = {
  [FORM_BUILDER_TEMPLATE_TYPE.BLANK]: "Blank",
  [FORM_BUILDER_TEMPLATE_TYPE.PRODUCT_REVIEW]: "Product Review",
  [FORM_BUILDER_TEMPLATE_TYPE.PUBLIC_POST]: "Public Post",
  [FORM_BUILDER_TEMPLATE_TYPE.EVENT_FEEDBACK]: "Event Feedback",
  [FORM_BUILDER_TEMPLATE_TYPE.NET_PROMOTER_SCORE]: "Net Promoter Score",
  [FORM_BUILDER_TEMPLATE_TYPE.CUSTOMER_QUESTIONNAIRE]: "Customer Questionnaire",
  [FORM_BUILDER_TEMPLATE_TYPE.EMPLOYEE_FEEDBACK]: "Employee Feedback",
  [FORM_BUILDER_TEMPLATE_TYPE.SURVEY]: "Survey",
  [FORM_BUILDER_TEMPLATE_TYPE.CONTACT_US]: "Contact Us",
  [FORM_BUILDER_TEMPLATE_TYPE.WEBSITE_FEEDBACK]: "Website Feedback",
  [FORM_BUILDER_TEMPLATE_TYPE.TESTIMONIAL]: "Testimonial",
  [FORM_BUILDER_TEMPLATE_TYPE.COMMUNITY]: "Community Sign Up",
  [FORM_BUILDER_TEMPLATE_TYPE.COMMUNITY_PRODUCT_REVIEW]: "Community Product Review"
};
