var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/custom-fonts/custom-fonts.actions";
export const initialState = {
  fontList: [],
  isPending: false,
  error: void 0,
  links: void 0
};
export const fontList = (state = initialState, action) => {
  var _a;
  switch (action.type) {
    case ACTIONS.GET_CUSTOM_FONTS.REQUEST:
    case ACTIONS.DELETE_CUSTOM_FONT.REQUEST:
    case ACTIONS.POST_CUSTOM_FONT.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true,
        error: void 0
      });
    case ACTIONS.DELETE_CUSTOM_FONT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isPending: false,
        fontList: [...(_a = state.fontList) == null ? void 0 : _a.filter((font) => font.id !== action.payload)],
        error: void 0
      });
    case ACTIONS.POST_CUSTOM_FONT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        fontList: [action.payload.data, ...state.fontList],
        isPending: false,
        error: void 0
      });
    case ACTIONS.GET_CUSTOM_FONTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        fontList: action.payload.data,
        isPending: false,
        error: void 0,
        links: action.payload.links
      });
    case ACTIONS.GET_CUSTOM_FONTS.FAILURE:
    case ACTIONS.DELETE_CUSTOM_FONT.FAILURE:
    case ACTIONS.POST_CUSTOM_FONT.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isPending: false
      });
    default:
      return state;
  }
};
export default fontList;
export const getCustomFontList = (state) => {
  return state.fontList ? state.fontList : [];
};
export const isCustomFontsPending = (state) => state.isPending;
