var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./tint-editor-filter-tags.sass";
import TintFilterTags from "../../../../../../components/interface/filter-tags/filter-tags";
import { SearchInput, SearchInputType } from "../../../../../../components/search-input/search-input";
import TintIcon from "../../../../../../components/interface/icon/icon";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Typography, TypographyVariants } from "../../../../../../components/interface/typography/typography";
export const TintEditorFilterTagsAllTagValue = "all";
const TintEditorFilterTags = ({
  list,
  headerText,
  searchEnabled = false,
  onOpenSidebar,
  onTagChoose,
  selectAllTag
}) => {
  const [isSelectAllClicked, setSelectAllClicked] = useState(false);
  const [tagList, setTagList] = useState(selectAllTag ? [selectAllTag, ...list] : list);
  const [filteredList, setFilteredList] = useState(selectAllTag ? [selectAllTag, ...list] : list);
  const [counter, setCount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const markAsSelectedFilteredList = (isSelectedAll, arr) => {
    const selectedElements = list.filter((el) => el.selected);
    const filteredList2 = selectAllTag ? [selectAllTag, ...arr] : arr;
    const selectedList = filteredList2.map((el) => {
      return __spreadProps(__spreadValues({}, el), {
        selected: isSelectedAll ? true : el.value === TintEditorFilterTagsAllTagValue ? isSelectAllClicked : selectedElements.find((item) => item.value === el.value && item.name === el.name) !== void 0
      });
    });
    setFilteredList(selectedList);
  };
  useEffect(() => {
    setCount(filteredList.filter((tag) => tag.value !== TintEditorFilterTagsAllTagValue && tag.selected).length);
  }, [filteredList]);
  useEffect(() => {
    const isAllSelected = selectAllTag && list.filter((tag) => tag.selected).length === list.length;
    markAsSelectedFilteredList(isAllSelected, inputValue ? filterByInputValue() : list);
    setTagList(selectAllTag ? [selectAllTag, ...list] : list);
  }, [list]);
  const filterByInputValue = () => list.filter((tag) => {
    return tag.name.toLowerCase().includes(inputValue.toLowerCase());
  });
  const onTagSelect = (selectedElement) => {
    let markAsSelected = [];
    if (selectedElement.value === TintEditorFilterTagsAllTagValue) {
      setSelectAllClicked(!selectedElement.selected);
      markAsSelected = list.map((tag) => __spreadProps(__spreadValues({}, tag), {
        selected: !selectedElement.selected
      }));
    } else {
      setSelectAllClicked(false);
      markAsSelected = list.map((tag) => __spreadProps(__spreadValues({}, tag), {
        selected: tag.value === TintEditorFilterTagsAllTagValue ? false : tag.value === selectedElement.value && tag.name === selectedElement.name ? !tag.selected : tag.selected
      }));
    }
    const selectedTags = markAsSelected.filter((tag) => tag.value !== TintEditorFilterTagsAllTagValue && tag.selected);
    const selectedElementsLength = selectedTags.length;
    onTagChoose && onTagChoose(selectedTags, selectedElement);
    setCount(selectedElementsLength);
  };
  const onInputChange = (inputValue2) => {
    setInputValue(inputValue2);
    if (inputValue2) {
      setFilteredList([
        ...tagList.filter((tag) => {
          return tag.name.toLowerCase().includes(inputValue2.toLowerCase());
        })
      ]);
    } else {
      setFilteredList(tagList);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__row" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__col tint-editor-filter-group__col--title" }, /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.TITLE, component: "h3" }, headerText, counter !== 0 ? /* @__PURE__ */ React.createElement("span", { className: "tint-editor-filter-group__counter" }, counter) : null, onOpenSidebar && /* @__PURE__ */ React.createElement("span", { className: "tint-editor-filter-group__edit", onClick: onOpenSidebar }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faPen }))), searchEnabled && /* @__PURE__ */ React.createElement(
    SearchInput,
    {
      inputValue,
      onInputChange,
      styles: { height: "40px", borderRadius: "40px" },
      types: [SearchInputType.ROUND]
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-filter-group__col tint-editor-filter-group__col--filters" }, /* @__PURE__ */ React.createElement(TintFilterTags, { list: filteredList, onTagSelect }))));
};
export default React.memo(TintEditorFilterTags);
