var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  SET_RENDER_TYPE: createPrefixedActionName("TINT_EDITOR", "SET_RENDER_TYPE"),
  CLEAR_STATE: createPrefixedActionName("POSTS", "CLEAR_STATE"),
  GET_POSTS: createRoutine(createPrefixedActionName("POSTS", "GET_POSTS")),
  GET_GLOBAL_POSTS: createRoutine(createPrefixedActionName("POSTS", "GET_GLOBAL_POSTS")),
  UPDATE_SELECTED_POSTS: createPrefixedActionName("POSTS", "UPDATE_SELECTED_POSTS"),
  UPDATE_PAGE_SIZE: createPrefixedActionName("POSTS", "UPDATE_PAGE_SIZE"),
  GET_SAVED_FILTERS: createRoutine(createPrefixedActionName("POSTS", "GET_SAVED_FILTERS")),
  CLEAR_SAVED_FILTERS: createPrefixedActionName("POSTS", "CLEAR_SAVED_FILTERS"),
  POST_SAVED_FILTERS: createRoutine(createPrefixedActionName("POSTS", "POST_SAVED_FILTERS")),
  UPDATE_SAVED_FILTERS: createRoutine(createPrefixedActionName("POSTS", "UPDATE_SAVED_FILTERS")),
  REMOVE_SAVED_FILTERS: createRoutine(createPrefixedActionName("POSTS", "REMOVE_SAVED_FILTERS")),
  SELECT_FILTER: createPrefixedActionName("POSTS", "SELECT_FILTER"),
  SORT_POSTS_BY: createPrefixedActionName("POSTS", "SORT_POSTS_BY"),
  SORT_POSTS_BY_VISUAL_SEARCH: createPrefixedActionName("POSTS", "SORT_POSTS_BY_VISUAL_SEARCH"),
  CHOOSE_SAVED_FILTER: createPrefixedActionName("POSTS", "CHOOSE_SAVED_FILTER"),
  RESET_FILTERS: createPrefixedActionName("POSTS", "RESET_FILTERS"),
  ADD_CUSTOM_POST: createRoutine(createPrefixedActionName("POSTS", "ADD_CUSTOM_POST")),
  UPDATE_CUSTOM_POST: createRoutine(createPrefixedActionName("POSTS", "UPDATE_CUSTOM_POST")),
  SEARCH_QUERY: createRoutine(createPrefixedActionName("TINT_EDITOR", "SEARCH_QUERY")),
  OPEN_POST_TAG_LIST: createPrefixedActionName("TINT_EDITOR", "OPEN_POST_TAG_LIST"),
  UPDATE_POST: createRoutine(createPrefixedActionName("TINT_EDITOR", "UPDATE_POST")),
  UPDATE_POSTS: createRoutine(createPrefixedActionName("TINT_EDITOR", "UPDATE_POSTS")),
  DELETE_POST: createRoutine(createPrefixedActionName("TINT_EDITOR", "DELETE_POST")),
  DELETE_POSTS: createRoutine(createPrefixedActionName("TINT_EDITOR", "DELETE_POSTS")),
  RECOVER_POST: createRoutine(createPrefixedActionName("TINT_EDITOR", "RECOVER_POST")),
  PIN_TO_TOP: createPrefixedActionName("TINT_EDITOR", "PIN_TO_TOP"),
  UNPIN_TO_TOP: createPrefixedActionName("TINT_EDITOR", "UNPIN_TO_TOP"),
  SET_IS_LOADING: createPrefixedActionName("TINT_EDITOR", "SET_IS_LOADING"),
  GET_POST_AGGREGATIONS: createRoutine(createPrefixedActionName("TINT_EDITOR", "GET_POST_AGGREGATIONS")),
  GET_POST_REQUEST_RIGHTS: createRoutine(createPrefixedActionName("TINT_EDITOR", "GET_POST_REQUEST_RIGHTS")),
  POST_SIMILAR_IMAGE: createRoutine(createPrefixedActionName("TINT_EDITOR", "POST_SIMILAR_IMAGE")),
  ADD_POST_PRODUCT_TAG: createPrefixedActionName("TINT_EDITOR", "ADD_POST_PRODUCT_TAG"),
  DELETE_POST_PRODUCT_TAG: createPrefixedActionName("TINT_EDITOR", "DELETE_POST_PRODUCT_TAG"),
  GET_LAST_PAGE: createRoutine(createPrefixedActionName("TINT_EDITOR", "GET_LAST_PAGE")),
  DELETE_REPLY: createPrefixedActionName("TINT_EDITOR", "DELETE_REPLY")
};
export const deleteReply = ({ replyId, postId }) => ({
  type: ACTIONS.DELETE_REPLY,
  payload: { replyId, postId }
});
export const setRenderType = (renderType) => ({
  type: ACTIONS.SET_RENDER_TYPE,
  payload: renderType
});
export const openPostTagList = (postId) => ({
  type: ACTIONS.OPEN_POST_TAG_LIST,
  payload: postId
});
export const getLastPageStart = () => ({
  type: ACTIONS.GET_LAST_PAGE.REQUEST
});
export const getLastPageSuccess = (isLastPage) => ({
  type: ACTIONS.GET_LAST_PAGE.SUCCESS,
  payload: isLastPage
});
export const getLastPageError = (err) => ({
  type: ACTIONS.GET_LAST_PAGE.FAILURE,
  payload: err
});
export const clearPostState = () => ({
  type: ACTIONS.CLEAR_STATE
});
export const getPostAggregationsStart = () => ({
  type: ACTIONS.GET_POST_AGGREGATIONS.REQUEST
});
export const getPostAggregationsSuccess = (payload) => ({
  type: ACTIONS.GET_POST_AGGREGATIONS.SUCCESS,
  payload
});
export const getPostAggregationsError = (err) => ({
  type: ACTIONS.GET_POST_AGGREGATIONS.FAILURE,
  payload: err
});
export const addPostProductTag = (externalId, data) => ({
  type: ACTIONS.ADD_POST_PRODUCT_TAG,
  payload: {
    externalId,
    data
  }
});
export const deletePostProductTag = (externalId, rectId, attachmentId) => ({
  type: ACTIONS.DELETE_POST_PRODUCT_TAG,
  payload: {
    externalId,
    rectId,
    attachmentId
  }
});
export const unpinToTop = (postFeed) => ({
  type: ACTIONS.UNPIN_TO_TOP,
  payload: postFeed
});
export const pinToTop = (postFeed) => ({
  type: ACTIONS.PIN_TO_TOP,
  payload: postFeed
});
export const updatePostStart = (id) => ({
  type: ACTIONS.UPDATE_POST.REQUEST,
  payload: id
});
export const updatePostSuccess = (data) => ({
  type: ACTIONS.UPDATE_POST.SUCCESS,
  payload: data
});
export const updatePostError = (err) => ({
  type: ACTIONS.UPDATE_POST.FAILURE,
  payload: err
});
export const updatePostsStart = (posts) => ({
  type: ACTIONS.UPDATE_POSTS.REQUEST,
  payload: posts
});
export const updatePostsSuccess = (data) => ({
  type: ACTIONS.UPDATE_POSTS.SUCCESS,
  payload: data
});
export const updatePostsError = (err) => ({
  type: ACTIONS.UPDATE_POSTS.FAILURE,
  payload: err
});
export const recoverPostStart = (id) => ({
  type: ACTIONS.RECOVER_POST.REQUEST,
  payload: id
});
export const recoverPostSuccess = (id) => ({
  type: ACTIONS.RECOVER_POST.SUCCESS,
  payload: id
});
export const recoverPostError = (err) => ({
  type: ACTIONS.RECOVER_POST.FAILURE,
  payload: err
});
export const getPostRightsRequestStart = () => ({
  type: ACTIONS.GET_POST_REQUEST_RIGHTS.REQUEST
});
export const getPostRightsRequestSuccess = (id) => ({
  type: ACTIONS.GET_POST_REQUEST_RIGHTS.SUCCESS,
  payload: id
});
export const getPostRightsRequestError = (err) => ({
  type: ACTIONS.GET_POST_REQUEST_RIGHTS.FAILURE,
  payload: err
});
export const deletePostStart = (id) => ({
  type: ACTIONS.DELETE_POST.REQUEST,
  payload: id
});
export const deletePostSuccess = (postId) => ({
  type: ACTIONS.DELETE_POST.SUCCESS,
  payload: postId
});
export const deletePostError = (err) => ({
  type: ACTIONS.DELETE_POST.FAILURE,
  payload: err
});
export const deletePostsStart = () => ({
  type: ACTIONS.DELETE_POSTS.REQUEST
});
export const deletePostsSuccess = (data) => ({
  type: ACTIONS.DELETE_POSTS.SUCCESS,
  payload: data
});
export const deletePostsError = (err) => ({
  type: ACTIONS.DELETE_POSTS.FAILURE,
  payload: err
});
export const getPostsStart = () => ({
  type: ACTIONS.GET_POSTS.REQUEST
});
export const getPostsSuccess = (data, socialFeeds) => ({
  type: ACTIONS.GET_POSTS.SUCCESS,
  payload: __spreadProps(__spreadValues({}, data), {
    socialFeeds
  })
});
export const getPostsError = (err) => ({
  type: ACTIONS.GET_POSTS.FAILURE,
  payload: err
});
export const getGlobalPostsStart = () => ({
  type: ACTIONS.GET_GLOBAL_POSTS.REQUEST
});
export const getGlobalPostsSuccess = (data, socialFeeds) => ({
  type: ACTIONS.GET_GLOBAL_POSTS.SUCCESS,
  payload: __spreadProps(__spreadValues({}, data), {
    socialFeeds
  })
});
export const getGlobalPostsError = (err) => ({
  type: ACTIONS.GET_GLOBAL_POSTS.FAILURE,
  payload: err
});
export const updateSelectedPosts = (posts) => ({
  type: ACTIONS.UPDATE_SELECTED_POSTS,
  payload: posts
});
export const updatePageSize = (size) => ({
  type: ACTIONS.UPDATE_PAGE_SIZE,
  payload: size
});
export const clearSavedFilters = () => ({
  type: ACTIONS.CLEAR_SAVED_FILTERS
});
export const getSavedFiltersStart = () => ({
  type: ACTIONS.GET_SAVED_FILTERS.REQUEST
});
export const getSavedFiltersSuccess = (data) => ({
  type: ACTIONS.GET_SAVED_FILTERS.SUCCESS,
  payload: data
});
export const getSavedFiltersError = (err) => ({
  type: ACTIONS.GET_SAVED_FILTERS.FAILURE,
  payload: err
});
export const postSavedFiltersStart = () => ({
  type: ACTIONS.POST_SAVED_FILTERS.REQUEST
});
export const postSavedFiltersSuccess = (data) => ({
  type: ACTIONS.POST_SAVED_FILTERS.SUCCESS,
  payload: data
});
export const postSavedFiltersError = (err) => ({
  type: ACTIONS.POST_SAVED_FILTERS.FAILURE,
  payload: err
});
export const removeSavedFiltersStart = () => ({
  type: ACTIONS.REMOVE_SAVED_FILTERS.REQUEST
});
export const removeSavedFiltersSuccess = (savedFilterId) => ({
  type: ACTIONS.REMOVE_SAVED_FILTERS.SUCCESS,
  payload: savedFilterId
});
export const removeSavedFiltersError = (err) => ({
  type: ACTIONS.REMOVE_SAVED_FILTERS.FAILURE,
  payload: err
});
export const updateSavedFiltersStart = () => ({
  type: ACTIONS.UPDATE_SAVED_FILTERS.REQUEST
});
export const updateSavedFiltersSuccess = (data) => ({
  type: ACTIONS.UPDATE_SAVED_FILTERS.SUCCESS,
  payload: data
});
export const updateSavedFiltersError = (err) => ({
  type: ACTIONS.UPDATE_SAVED_FILTERS.FAILURE,
  payload: err
});
export const selectFilters = (data, type) => ({
  type: ACTIONS.SELECT_FILTER,
  payload: {
    data,
    type
  }
});
export const sortPostsBy = (data, type) => ({
  type: ACTIONS.SORT_POSTS_BY,
  payload: {
    data,
    type
  }
});
export const sortByVisualSearch = (isActive) => ({
  type: ACTIONS.SORT_POSTS_BY_VISUAL_SEARCH,
  payload: isActive
});
export const setIsLoading = (isLoading) => ({
  type: ACTIONS.SET_IS_LOADING,
  payload: isLoading
});
export const chooseFilter = (filters, id) => ({
  type: ACTIONS.CHOOSE_SAVED_FILTER,
  payload: { filters, id }
});
export const resetFilters = () => ({
  type: ACTIONS.RESET_FILTERS
});
export const updateCustomPostStart = () => ({
  type: ACTIONS.UPDATE_CUSTOM_POST.REQUEST
});
export const updateCustomPostSuccess = (data) => ({
  type: ACTIONS.UPDATE_CUSTOM_POST.SUCCESS,
  payload: data
});
export const updateCustomPostError = (err) => ({
  type: ACTIONS.UPDATE_CUSTOM_POST.FAILURE,
  payload: err
});
export const addCustomPostStart = () => ({
  type: ACTIONS.ADD_CUSTOM_POST.REQUEST
});
export const addCustomPostSuccess = (data) => ({
  type: ACTIONS.ADD_CUSTOM_POST.SUCCESS,
  payload: data
});
export const addCustomPostError = (err) => ({
  type: ACTIONS.ADD_CUSTOM_POST.FAILURE,
  payload: err
});
export const postSimilarImageStart = () => ({
  type: ACTIONS.POST_SIMILAR_IMAGE.REQUEST
});
export const postSimilarImageSuccess = (data) => ({
  type: ACTIONS.POST_SIMILAR_IMAGE.SUCCESS,
  payload: data
});
export const postSimilarImageError = (err) => ({
  type: ACTIONS.POST_SIMILAR_IMAGE.FAILURE,
  payload: err
});
