import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./field-edit-modal.sass";
import TintSidebar from "../../../../../../../components/sidebar/sidebar";
import { closeFormSectionFieldModal } from "../../../../../actions/form-section-field-modal/form-section-field-modal.actions";
import { Button } from "../../../../../../../components/interface/button/button";
import { createTranslationKey } from "../../../../../containers/experience/translation-creator";
import { clone } from "../../../../../../../services/functions/clone/clone";
import InputPrimary from "../../../../../../../components/interface/inputs/input-primary/input-primary";
import { saveTranslationDraft } from "../../../../../actions/experience/experience-translations.actions";
import { discardDraftData } from "../../../../../actions/experience/experience.actions";
export const INPUT_EDIT_MODAL_STEP = {
  PRIMARY: "PRIMARY_STEP",
  PLACEHOLDER: "PLACEHOLDER",
  DROPDOWN: "DROPDOWN_STEP",
  CHECKBOX: "CHECKBOX_STEP"
};
export const FieldEditModal = ({
  getTranslation,
  content,
  dataUpdate,
  experienceType,
  updateAllTranslation,
  openEditModal
}) => {
  const dispatch = useDispatch();
  const formSectionFieldModal = useSelector((state) => state.formSectionFieldModal);
  const getIndex = () => {
    return formSectionFieldModal.data.i;
  };
  const onAddOption = () => {
    const tmpFields = clone(content["fields"]);
    const tmpOptions = tmpFields[getIndex()].options;
    const translationKey = createTranslationKey(
      experienceType,
      "form",
      "placeholder_dropdown_option",
      tmpOptions.length + 1
    );
    tmpOptions.push({ placeholder: translationKey });
    tmpFields[getIndex()].options = tmpOptions;
    updateAllTranslation(translationKey, `Option number ${tmpOptions.length}`);
    dataUpdate({ ["fields"]: tmpFields });
  };
  const onRemoveOption = (index) => {
    const tmpFields = clone(content["fields"]);
    const tmpOptions = tmpFields[getIndex()].options;
    tmpOptions.splice(index, 1);
    tmpFields[getIndex()].options = tmpOptions;
    dataUpdate({ ["fields"]: tmpFields });
  };
  const onOptionChange = (e, key) => {
    const value = e.target.value;
    dispatch(saveTranslationDraft(key, value));
  };
  const renderOptions = () => {
    let options = content.fields[getIndex()].options;
    return options.map((e, i) => {
      return /* @__PURE__ */ React.createElement(
        InputPrimary,
        {
          key: e.placeholder,
          rightIcon: "fas fa-trash",
          rightIconAction: () => onRemoveOption(i),
          label: `Option ${i + 1}`,
          handleChange: (event) => onOptionChange(event, e.placeholder),
          defaultValue: getTranslation(e.placeholder)
        }
      );
    });
  };
  const renderModal = () => {
    switch (formSectionFieldModal.step) {
      case INPUT_EDIT_MODAL_STEP.CHECKBOX:
      case INPUT_EDIT_MODAL_STEP.DROPDOWN:
        return /* @__PURE__ */ React.createElement(
          TintSidebar,
          {
            sidebarClassName: "tint-field-edit-modal",
            title: "Editing form field",
            footerComponent: () => /* @__PURE__ */ React.createElement(
              Button,
              {
                type: "secondary",
                text: "Done",
                onClick: () => {
                  dispatch(closeFormSectionFieldModal());
                  openEditModal();
                }
              }
            ),
            onClose: () => {
              dispatch(closeFormSectionFieldModal());
              dispatch(discardDraftData());
            },
            styles: { top: "60px" },
            isOpen: true
          },
          /* @__PURE__ */ React.createElement("div", { className: "tint-field-edit-modal__container" }, /* @__PURE__ */ React.createElement("div", { className: "tint-field-edit-modal__header" }, /* @__PURE__ */ React.createElement("p", null, "Field name: ", getTranslation(content.fields[formSectionFieldModal.data.i].placeholder))), /* @__PURE__ */ React.createElement("div", null, renderOptions(), /* @__PURE__ */ React.createElement(Button, { width: "100%", type: "secondary", onClick: onAddOption, text: "Add Option" })))
        );
    }
    return null;
  };
  return renderModal();
};
