var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/tint-mix.actions";
import produce from "immer";
import { defaultTintMixName } from "../components/tint-mix-scene-navigation-bar/tint-mix-scene-navigation-bar";
import { TintMixGridTemplates } from "../components/tint-mix-grid-template/tint-mix-grid-template";
import mapGridTemplateToElement from "../components/tint-mix-grid-template/tint-mix-grid-template.mapper";
const initialState = {
  data: void 0,
  links: void 0,
  currentTintMix: void 0,
  currentTintMixUI: void 0,
  isPending: false,
  scenes: void 0,
  selectedMixes: []
};
export const tintMix = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_MIX.REQUEST:
    case ACTIONS.GET_MIX.REQUEST:
    case ACTIONS.GET_MIXES.REQUEST:
    case ACTIONS.BULK_DELETE_MIXES.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true
      });
    case ACTIONS.PATCH_MIX.FAILURE:
    case ACTIONS.DELETE_MIX.FAILURE:
    case ACTIONS.POST_MIX.FAILURE:
    case ACTIONS.GET_MIX.FAILURE:
    case ACTIONS.GET_MIXES.FAILURE:
    case ACTIONS.BULK_DELETE_MIXES.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isPending: false
      });
    case ACTIONS.SET_NEW_MIX:
      return produce(state, (draft) => {
        draft.currentTintMixUI = {
          attributes: {
            name: defaultTintMixName,
            scenes: [
              {
                areas: {
                  a: []
                },
                template: TintMixGridTemplates.FULL_SCREEN
              }
            ]
          }
        };
      });
    case ACTIONS.SET_CURRENT_MIX_UI:
      return produce(state, (draft) => {
        draft.currentTintMixUI = __spreadProps(__spreadValues({}, action.payload), {
          attributes: __spreadProps(__spreadValues({}, action.payload.attributes), {
            scenes: action.payload.attributes.scenes.map((scene) => {
              return __spreadProps(__spreadValues({}, scene), {
                areas: Object.keys(scene.areas).map((key) => {
                  return {
                    [key]: [
                      ...scene.areas[key].map((area) => {
                        return __spreadProps(__spreadValues({}, area), {
                          defaultAreaKey: key
                        });
                      })
                    ]
                  };
                }).reduce((a, i) => {
                  return __spreadValues(__spreadValues({}, a), i);
                }, {})
              });
            })
          })
        });
      });
    case ACTIONS.CLEAR_CURRENT_MIX_UI:
      return produce(state, (draft) => {
        draft.currentTintMixUI = void 0;
        draft.currentTintMix = void 0;
      });
    case ACTIONS.GET_MIXES.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data,
        links: action.payload.links,
        isPending: false
      });
    case ACTIONS.PATCH_MIX.SUCCESS:
      return produce(state, (draft) => {
        const updatedMixId = action.payload.id;
        draft.data = draft.data ? draft.data.map((mix) => mix.id === updatedMixId ? action.payload : mix) : draft.data = [action.payload];
        draft.isPending = false;
      });
    case ACTIONS.RENAME_TINT_MIX:
      return produce(state, (draft) => {
        let currentTintMix = draft.data && draft.data.find((tintMix2) => tintMix2.id === action.payload.id);
        currentTintMix && currentTintMix.attributes.name === action.payload.name;
        draft.currentTintMixUI.attributes.name = action.payload.name;
      });
    case ACTIONS.POST_MIX.SUCCESS:
      return produce(state, (draft) => {
        draft.data = [...draft.data, action.payload];
        draft.currentTintMix = action.payload;
        draft.currentTintMixUI = action.payload;
        draft.isPending = false;
      });
    case ACTIONS.DELETE_MIX.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((mix) => mix.id !== action.payload);
        draft.isPending = false;
      });
    case ACTIONS.GET_MIX.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isPending: false,
        currentTintMixUI: __spreadValues({}, action.payload),
        currentTintMix: __spreadValues({}, action.payload)
      });
    case ACTIONS.CHANGE_SCENE_LAYOUT:
      return produce(state, (draft) => {
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].layout = void 0;
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].template = action.payload.template;
        const itemList = Object.values(
          draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas
        ).flat();
        const sections = mapGridTemplateToElement(action.payload.template);
        const currentAreas = Object.keys(draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas);
        const inactiveAreas = currentAreas.filter((item) => !sections.includes(item));
        const unAssignItems = inactiveAreas.map((key) => {
          return itemList.filter((areaItem) => areaItem.defaultAreaKey === key);
        }).flat();
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas = sections.map((key, i) => {
          if (i === 0) {
            return {
              [key]: [...itemList.filter((areaItem) => areaItem.defaultAreaKey === key), ...unAssignItems]
            };
          } else {
            return {
              [key]: itemList.filter((areaItem) => areaItem.defaultAreaKey === key)
            };
          }
        }).reduce((a, b) => __spreadValues(__spreadValues({}, a), b));
      });
    case ACTIONS.ADD_NEW_SCENE:
      return produce(state, (draft) => {
        draft.currentTintMixUI.attributes.scenes.splice(action.payload + 1, 0, {
          template: TintMixGridTemplates.FULL_SCREEN,
          areas: { a: [] }
        });
      });
    case ACTIONS.DELETE_SCENE:
      return produce(state, (draft) => {
        draft.currentTintMixUI.attributes.scenes = draft.currentTintMixUI.attributes.scenes.filter(
          (scene, i) => i !== action.payload
        );
      });
    case ACTIONS.ADD_NEW_AREA_ITEM:
      return produce(state, (draft) => {
        const currentArea = draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area];
        const areaContent = __spreadProps(__spreadValues({
          type: action.payload.contentType
        }, action.payload.data), {
          tint_slug: action.payload.data.tintSlug,
          tint_id: action.payload.data.tintId,
          personalization_id: action.payload.data.personalizationId,
          defaultAreaKey: action.payload.area
        });
        const area = {
          [action.payload.area]: [areaContent]
        };
        currentArea ? currentArea.push(areaContent) : draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas = __spreadValues(__spreadValues({}, draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas), area);
      });
    case ACTIONS.UPDATE_AREA_ITEM:
      return produce(state, (draft) => {
        var _a, _b, _c;
        const currentItem = draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area][action.payload.areaIndex];
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area][action.payload.areaIndex] = __spreadProps(__spreadValues(__spreadValues({}, currentItem), action.payload.data), {
          tintId: void 0,
          personalizationId: void 0,
          tintSlug: void 0,
          tint_slug: ((_a = action.payload.data) == null ? void 0 : _a.tintSlug) || currentItem.tint_slug,
          tint_id: ((_b = action.payload.data) == null ? void 0 : _b.tintId) || currentItem.tint_id,
          personalization_id: ((_c = action.payload.data) == null ? void 0 : _c.personalizationId) || currentItem.personalization_id
        });
      });
    case ACTIONS.REMOVE_AREA_ITEM:
      return produce(state, (draft) => {
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area] = draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area].filter(
          (item, index) => index !== action.payload.index
        );
      });
    case ACTIONS.REORDER_SCENE:
      return produce(state, (draft) => {
        draft.currentTintMixUI.attributes.scenes = action.payload;
      });
    case ACTIONS.REORDER_AREA_ITEM:
      return produce(state, (draft) => {
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas = action.payload.areas;
      });
    case ACTIONS.UPDATE_TINT_MIX_UI:
      return produce(state, (draft) => {
        draft.currentTintMix = __spreadProps(__spreadValues({}, draft.currentTintMixUI), {
          attributes: __spreadProps(__spreadValues({}, draft.currentTintMixUI.attributes), {
            scenes: draft.currentTintMixUI.attributes.scenes.map((scene) => __spreadProps(__spreadValues({}, scene), {
              areas: Object.keys(scene.areas).map((key) => {
                return {
                  [key]: [
                    ...scene.areas[key].map((area) => {
                      return __spreadProps(__spreadValues({}, area), {
                        index: void 0,
                        defaultAreaKey: void 0,
                        sceneIndex: void 0,
                        column: void 0,
                        elementId: void 0,
                        isValid: void 0
                      });
                    })
                  ]
                };
              }).reduce((a, i) => {
                return __spreadValues(__spreadValues({}, a), i);
              }, {})
            }))
          })
        });
      });
    case ACTIONS.BULK_DELETE_MIXES.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = state.data.filter((mix) => {
          return !action.payload.find((m) => {
            return m.id === mix.id;
          });
        });
        draftState.isPending = false;
        draftState.isError = false;
        draftState.selectedMixes = [];
      });
    case ACTIONS.SELECT_MIX:
      return produce(state, (draft) => {
        draft.selectedMixes = [...draft.selectedMixes, action.payload];
      });
    case ACTIONS.UNSELECT_MIX:
      return produce(state, (draft) => {
        draft.selectedMixes = draft.selectedMixes.filter(({ id }) => {
          return action.payload !== id;
        });
      });
    case ACTIONS.DESELECT_ALL_MIXES:
      return produce(state, (draft) => {
        draft.selectedMixes = [];
      });
    default:
      return state;
  }
};
export const getCurrentTintMix = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.tintMix) == null ? void 0 : _a.currentTintMix;
};
export const getAllTintMixes = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.tintMix) == null ? void 0 : _a.data;
};
export const getAreaDuration = (areas) => {
  if (!areas) return {};
  return areas.reduce(function(prev, current) {
    return prev + +current.duration;
  }, 0);
};
export const getSumAreasDuration = (areas) => {
  if (!areas) return {};
  const getAreaItemsDurationTime = Object.keys(areas).map((key) => {
    return areas[key].reduce((prev, current) => {
      return prev + +current.duration;
    }, 0);
  });
  const getTheBiggestValue = Math.max(...getAreaItemsDurationTime);
  return getTheBiggestValue;
};
export const getScenes = (state) => {
  return state.tintMix.currentTintMixUI && state.tintMix.currentTintMixUI.attributes.scenes || [
    {
      template: "'a'",
      areas: {
        a: [],
        b: [
          {
            fit: "contain",
            url: "https://cdn.filestackcontent.com/xb32BsyRVKwaNtiln1gg",
            name: "file",
            type: "video",
            duration: 15
          }
        ]
      }
    }
  ];
};
export const getUniqueTemplatesSelector = (state) => {
  const usedTemplates = (state.tintMix.currentTintMix && state.tintMix.currentTintMix.attributes.scenes).map((scene) => scene.template).filter(Boolean);
  return [.../* @__PURE__ */ new Set([...Object.values(TintMixGridTemplates), ...usedTemplates])];
};
