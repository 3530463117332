var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import produce from "immer";
import { format, startOfDay, endOfDay } from "date-fns";
import { ACTIONS } from "../actions/social-publishing.filters.actions";
import { SocialPublishingConstants } from "../utils/constants";
export const periodType = {
  WEEK: "WEEK",
  MONTH: "MONTH"
};
const currentDate = /* @__PURE__ */ new Date();
const startOfWeek = (date) => {
  let _date = new Date(date);
  return startOfDay(_date.setDate(_date.getDate() - _date.getDay() + SocialPublishingConstants.startDateDay));
};
const endOfWeek = (date) => {
  let _date = new Date(date);
  return endOfDay(_date.setDate(_date.getDate() - _date.getDay() + SocialPublishingConstants.endDateDay));
};
export const initialState = {
  period: periodType.WEEK,
  date: {
    $gte: startOfWeek(currentDate),
    $lte: endOfWeek(currentDate)
  },
  content: {},
  mimeType: {},
  selectedFilters: {}
};
export const socialPublishingFilters = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_PERIOD_TYPE:
      return __spreadProps(__spreadValues({}, state), {
        period: action.payload
      });
    case ACTIONS.SET_DATE:
      return __spreadProps(__spreadValues({}, state), {
        date: action.payload
      });
    case ACTIONS.SET_CONTENT_STATUS:
      return produce(state, (draftState) => {
        draftState.content[action.payload.key] = action.payload.value;
        return draftState;
      });
    case ACTIONS.SET_MIME_TYPE:
      return produce(state, (draftState) => {
        draftState.mimeType[action.payload.key] = action.payload.value;
        return draftState;
      });
    default:
      return state;
  }
};
export const getFilters = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingFilters) == null ? void 0 : _a.selectedFilters;
};
export const getFiltersDate = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingFilters) == null ? void 0 : _a.date;
};
export const getFiltersAmount = (state) => {
  var _a, _b;
  if (!state || !state.socialPublishingFilters) {
    return;
  }
  return (_b = (_a = Object.values(state.socialPublishingFilters.selectedFilters)) == null ? void 0 : _a.flat()) == null ? void 0 : _b.length;
};
