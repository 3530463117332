import { preloadersConfig } from "../../../../../components/interface/preloaders/preloaders.config";
export const assetCardLoaderRects = {
  desktop: [
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "20",
        width: "100%",
        height: "210",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "2",
        y: "2",
        rx: "2",
        ry: "2",
        width: "14",
        height: "14",
        fill: preloadersConfig.fillColors.white,
        strokeWidth: "2",
        stroke: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.circle,
      props: {
        cx: "85%",
        cy: "22",
        r: "23",
        fill: preloadersConfig.fillColors.white
      }
    },
    {
      tagType: preloadersConfig.tagTypes.circle,
      props: {
        cx: "85%",
        cy: "22",
        r: "20",
        fill: preloadersConfig.fillColors.secondary
      }
    }
  ]
};
