var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/teams/teams.actions";
export const initialState = {
  data: void 0,
  error: void 0,
  isPending: false,
  selectedTeamIndex: 0
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_TEAMS.FAILURE:
    case ACTIONS.PATCH_TEAM.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isPending: false
      });
    case ACTIONS.GET_TEAMS.REQUEST:
    case ACTIONS.PATCH_TEAM.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true
      });
    case ACTIONS.GET_TEAMS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload,
        isPending: false,
        error: void 0
      });
    case ACTIONS.PATCH_TEAM.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: {
          data: state.data.data.map((e) => e.id === action.payload.data.id ? action.payload.data : e)
        },
        isPending: false,
        error: void 0
      });
    default:
      return state;
  }
};
export const getSelectedTeam = (state) => {
  return state.data ? state.data.data[state.selectedTeamIndex] : void 0;
};
