const tintKey = "tint-log-level";
export const logLevels = {
  EMPTY: 0,
  INFO: 1,
  WARNING: 2,
  ERROR: 3
};
export default class Logger {
  static isAllowed(logLevel) {
    return this.getLogLevel() >= logLevel;
  }
  static error(message) {
    if (this.isAllowed(logLevels.ERROR)) {
      console.error(message);
    }
  }
  static warning(message) {
    if (this.isAllowed(logLevels.WARNING)) {
      console.warn(message);
    }
  }
  static info(message) {
    if (this.isAllowed(logLevels.INFO)) {
      console.info(message);
    }
  }
  static setLogLevel(logLevel) {
    if (localStorage !== void 0) {
      localStorage.setItem(tintKey, logLevel);
    }
  }
  static getLogLevel() {
    if (localStorage !== void 0) {
      return localStorage.getItem(tintKey) || 0;
    }
    return 0;
  }
}
