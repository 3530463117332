var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/social-feeds.actions";
import produce from "immer";
const formInitialState = {
  data: {},
  included: []
};
export const initialState = {
  experiences: {
    data: [],
    links: {},
    meta: {}
  },
  missions: {
    data: [],
    links: {},
    meta: {}
  },
  forms: {
    data: [],
    links: {},
    meta: {}
  },
  experience: {
    data: {},
    included: []
  },
  form: formInitialState,
  tintEditor: {
    data: [],
    links: {}
  },
  isFetching: false,
  isFetchingForm: false,
  error: void 0
};
export const socialFeeds = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_STATE:
      return initialState;
    case ACTIONS.CLEAR_SOCIAL_FEED_FORM_STATE:
      return __spreadProps(__spreadValues({}, state), {
        form: formInitialState
      });
    case ACTIONS.REMOVE_SOCIAL_FEED.REQUEST:
    case ACTIONS.POST_SOCIAL_FEED.REQUEST:
    case ACTIONS.PATCH_SOCIAL_FEED.REQUEST:
    case ACTIONS.GET_EXPERIENCES.REQUEST:
    case ACTIONS.GET_FORMS.REQUEST:
    case ACTIONS.GET_MISSIONS.REQUEST:
    case ACTIONS.GET_EXPERIENCE.REQUEST:
    case ACTIONS.GET_SOCIAL_FEEDS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true
      });
    case ACTIONS.GET_FORM.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetchingForm: true
      });
    case ACTIONS.GET_SOCIAL_FEEDS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        tintEditor: __spreadValues({}, action.payload)
      });
    case ACTIONS.GET_FORMS.SUCCESS:
      return produce(state, (draft) => {
        draft.forms.data = [...state.forms.data, ...action.payload.data];
        draft.forms.links = action.payload.links;
        draft.isFetching = false;
      });
    case ACTIONS.GET_EXPERIENCES.SUCCESS:
      return produce(state, (draft) => {
        draft.experiences.data = [...state.experiences.data, ...action.payload.data];
        draft.experiences.links = action.payload.links;
        draft.isFetching = false;
      });
    case ACTIONS.GET_MISSIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.missions.data = [...state.missions.data, ...action.payload.data];
        draft.missions.links = action.payload.links;
        draft.isFetching = false;
      });
    case ACTIONS.GET_EXPERIENCE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        experience: {
          data: action.payload.data,
          included: action.payload.included || initialState.experience.included
        }
      });
    case ACTIONS.GET_FORM.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetchingForm: false,
        form: {
          data: action.payload.data,
          included: action.payload.included || initialState.form.included
        }
      });
    case ACTIONS.POST_SOCIAL_FEED.SUCCESS:
      return produce(state, (draft) => {
        draft.tintEditor.data = [action.payload, ...draft.tintEditor.data];
        draft.isFetching = false;
      });
    case ACTIONS.REMOVE_SOCIAL_FEED.SUCCESS:
      return produce(state, (draft) => {
        draft.tintEditor.data = draft.tintEditor.data.filter((feed) => feed.id !== action.payload);
        draft.isFetching = false;
      });
    case ACTIONS.PATCH_SOCIAL_FEED.SUCCESS:
      return produce(state, (draft) => {
        const currentIndexElement = draft.tintEditor.data.findIndex((feed) => feed.id === action.payload.id);
        draft.tintEditor.data[currentIndexElement] = action.payload;
        draft.isFetching = false;
      });
    case ACTIONS.GET_FORM.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetchingForm: false,
        error: action.payload
      });
    case ACTIONS.REMOVE_SOCIAL_FEED.FAILURE:
    case ACTIONS.GET_MISSIONS.FAILURE:
    case ACTIONS.GET_EXPERIENCES.FAILURE:
    case ACTIONS.GET_FORMS.FAILURE:
    case ACTIONS.POST_SOCIAL_FEED.FAILURE:
    case ACTIONS.GET_EXPERIENCE.FAILURE:
    case ACTIONS.GET_SOCIAL_FEEDS.FAILURE:
    case ACTIONS.PATCH_SOCIAL_FEED.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    default:
      return state;
  }
};
