var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ACTIONS } from "../actions/experience-builder";
const experienceBuilder = (state = {
  width: void 0,
  isPreviewMode: false,
  selectedTextarea: void 0
}, action) => {
  switch (action.type) {
    case ACTIONS.SET_PAGE_BUILDER_WIDTH:
      return __spreadValues(__spreadValues({}, state), { width: action.payload });
    case ACTIONS.SET_PREVIEW_MODE:
      return __spreadValues(__spreadValues({}, state), { isPreviewMode: action.payload });
    case ACTIONS.SET_SELECTED_TEXTAREA:
      return __spreadValues(__spreadValues({}, state), { selectedTextarea: action.payload });
    default:
      return state;
  }
};
export default experienceBuilder;
