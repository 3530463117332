var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import PropTypes from "prop-types";
import "./search-input.sass";
export const SearchInputType = {
  SHORT: "short",
  DEFAULT: "default",
  ROUND: "round",
  SQUARE: "square"
};
export const SearchInput = ({
  onInputChange,
  inputValue = "",
  placeholder = "Search...",
  styles = {},
  types = [SearchInputType.DEFAULT, SearchInputType.ROUND]
}) => {
  const isInputValue = inputValue && inputValue.length > 0;
  const handleValueChange = (e) => {
    onInputChange(e);
  };
  const getTypesClassNames = () => {
    return types.map((type) => {
      return `search-input__${type}`;
    }).join(" ");
  };
  return /* @__PURE__ */ React.createElement("div", { className: `search-input ${getTypesClassNames()}`, style: __spreadValues({}, styles) }, !isInputValue && /* @__PURE__ */ React.createElement("span", { className: "icon far fa-search" }), /* @__PURE__ */ React.createElement(
    "input",
    {
      value: inputValue,
      placeholder,
      onChange: (e) => handleValueChange(e.target.value),
      style: __spreadValues({}, isInputValue && { padding: "0 42px 0 20px" })
    }
  ), isInputValue && /* @__PURE__ */ React.createElement("span", { className: "icon icon-clear fal fa-times", onClick: () => handleValueChange("") }));
};
export default SearchInput;
SearchInput.propTypes = {
  onInputChange: PropTypes.func,
  styles: PropTypes.object,
  placeholder: PropTypes.string,
  types: PropTypes.array
};
