import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { CUSTOM_FONTS } from "../../consts";
export const ACTIONS = {
  POST_CUSTOM_FONT: createRoutine(createPrefixedActionName(CUSTOM_FONTS, "POST_CUSTOM_FONT")),
  DELETE_CUSTOM_FONT: createRoutine(createPrefixedActionName(CUSTOM_FONTS, "DELETE_CUSTOM_FONT")),
  GET_CUSTOM_FONTS: createRoutine(createPrefixedActionName(CUSTOM_FONTS, "GET_CUSTOM_FONTS"))
};
export const getCustomFontsStart = () => ({
  type: ACTIONS.GET_CUSTOM_FONTS.REQUEST
});
export const getCustomFontsSuccess = (data) => ({
  type: ACTIONS.GET_CUSTOM_FONTS.SUCCESS,
  payload: data
});
export const getCustomFontsError = (err) => ({
  type: ACTIONS.GET_CUSTOM_FONTS.FAILURE,
  payload: err
});
export const postCustomFontStart = () => ({
  type: ACTIONS.POST_CUSTOM_FONT.REQUEST
});
export const postCustomFontSuccess = (data) => ({
  type: ACTIONS.POST_CUSTOM_FONT.SUCCESS,
  payload: data
});
export const postCustomFontError = (err) => ({
  type: ACTIONS.POST_CUSTOM_FONT.FAILURE,
  payload: err
});
export const deleteCustomFontStart = () => ({
  type: ACTIONS.DELETE_CUSTOM_FONT.REQUEST
});
export const deleteCustomFontSuccess = (id) => ({
  type: ACTIONS.DELETE_CUSTOM_FONT.SUCCESS,
  payload: id
});
export const deleteCustomFontError = (err) => ({
  type: ACTIONS.DELETE_CUSTOM_FONT.FAILURE,
  payload: err
});
