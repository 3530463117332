import { createPrefixedActionName } from "../../../services/functions/redux-routines/redux-routines";
const prefix = "SOCIAL_PUBLISHING_FILTERS";
export const ACTIONS = {
  SET_FILTERS: createPrefixedActionName(prefix, "SET_FILTERS"),
  CLEAR_FILTERS: createPrefixedActionName(prefix, "CLEAR_FILTERS"),
  SET_PERIOD_TYPE: createPrefixedActionName(prefix, "SET_PERIOD_TYPE"),
  SET_DATE: createPrefixedActionName(prefix, "SET_DATE"),
  SET_CONTENT_STATUS: createPrefixedActionName(prefix, "SET_CONTENT_STATUS"),
  SET_MIME_TYPE: createPrefixedActionName(prefix, "SET_MIME_TYPE")
};
export const setDate = (data) => ({
  type: ACTIONS.SET_DATE,
  payload: data
});
export const setPeriodType = (data) => ({
  type: ACTIONS.SET_PERIOD_TYPE,
  payload: data
});
export const setContentStatus = (data) => ({
  type: ACTIONS.SET_CONTENT_STATUS,
  payload: data
});
export const setMimeType = (data) => ({
  type: ACTIONS.SET_MIME_TYPE,
  payload: data
});
export const selectFilters = (data, type) => ({
  type: ACTIONS.SET_FILTERS,
  payload: {
    data,
    type
  }
});
export const clearFilters = () => ({
  type: ACTIONS.CLEAR_FILTERS
});
