var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import i18n from "../../components/i18next/i18n";
import { Config } from "../../../../utils/config";
import { FORM_BUILDER_INPUT_CLASSES, itemClassToType } from "../../utils/form-builder.types";
import { formBuilderCustomTranslations } from "./form-builder.translations";
import { createI18nInstance } from "../../components/i18next/i18n.instance";
export const _generateTranslation = (parentElement, element) => {
  let parentClass = parentElement.class;
  const cleanParentClass = itemClassToType(parentClass.class);
  if (cleanParentClass) {
    parentClass = cleanParentClass;
  }
  return _createTranslationKey(`FORM.${parentClass.toUpperCase()}.${element.nodeName.toUpperCase()}`);
};
const addTranslationsToNestedElements = (element, elements, translations) => {
  elements.forEach((e) => {
    if ("nodeValue" in e || "placeholder" in e || "href" in e || "content" in e || "src" in e || "alt" in e) {
      const key = _generateTranslation(element, e);
      if ("nodeValue" in e) {
        translations[key] = e.nodeValue;
        e.nodeValue = key;
        if ("href" in e) {
          const _key = _generateTranslation(element, { nodeName: `${e.nodeName}.HREF` });
          translations[_key] = e.href;
          e.href = _key;
        }
      } else if ("placeholder" in e) {
        translations[key] = e.placeholder;
        e.placeholder = key;
      } else if ("content" in e) {
        translations[key] = e.content;
        e.content = key;
      } else if ("src" in e) {
        translations[key] = e.src;
        e.src = key;
        if ("alt" in e) {
          const _key = _generateTranslation(element, { nodeName: `${e.nodeName}.ALT` });
          translations[_key] = e.alt;
          e.alt = _key;
        }
      }
    }
    if (e.elements) {
      addTranslationsToNestedElements(element, e.elements, translations);
    }
  });
};
const addInternalizationToElement = (element) => {
  if (element.nodeName === "p") {
    const key = _createTranslationKey(`FORM.${element.nodeName.toUpperCase()}`);
    let _translations = { [key]: element.nodeValue };
    element.nodeValue = key;
    return { element, translations: _translations };
  }
  if (!(element == null ? void 0 : element.elements)) {
    console.error("Elements array is not defined");
    return { element };
  }
  const translations = {};
  addTranslationsToNestedElements(element, element.elements, translations);
  if (element.class === FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD) {
    const input = element.elements.find((el) => el.nodeName === "input");
    const dataLocale = JSON.parse(input["data-locale"]);
    const dataLocaleTranslations = Object.entries(dataLocale).map(([key, tr]) => {
      const value = formBuilderCustomTranslations["data-locale"][key];
      return { [tr]: value };
    }).reduce((acc, obj) => {
      const key = Object.keys(obj)[0];
      acc[key] = obj[key];
      return acc;
    }, {});
    return { element, translations: __spreadValues(__spreadValues({}, dataLocaleTranslations), translations) };
  }
  return { element, translations };
};
function _createTranslationKey(prefix = "") {
  return `${prefix}.${Math.random().toString(36).substring(2).toUpperCase()}`;
}
function loadInternalization(lang, translations) {
  i18n.addResourceBundle(lang, "translation", translations);
}
function updateSingleTranslation(lang, key, value) {
  i18n.addResource(lang, "translation", key, value);
}
function changeLanguage(lang) {
  i18n.changeLanguage(lang);
}
function init(translations, lang, isPreview) {
  const translationKeys = Object == null ? void 0 : Object.keys(translations);
  const splitLang = lang.includes("_") ? lang.split("_") : lang.split("-");
  const similarLanguages = translationKeys == null ? void 0 : translationKeys.filter(
    (lang2) => {
      var _a, _b;
      return (_b = lang2 == null ? void 0 : lang2.toLowerCase()) == null ? void 0 : _b.includes((_a = splitLang == null ? void 0 : splitLang[0]) == null ? void 0 : _a.toLowerCase());
    }
  );
  const mainLanguage = similarLanguages.find(
    (lang2) => {
      var _a;
      return ((_a = lang2 == null ? void 0 : lang2.toLowerCase()) == null ? void 0 : _a.includes(Config.language.defaultLanguage.toLowerCase())) ? lang2 : (lang2 == null ? void 0 : lang2.length) === 2;
    }
  );
  const languageWithTranslation = similarLanguages.find((simlang) => (simlang == null ? void 0 : simlang.toLowerCase()) === (lang == null ? void 0 : lang.toLowerCase()));
  let language;
  if (languageWithTranslation) language = languageWithTranslation;
  if (!language && mainLanguage) language = mainLanguage;
  if (!language && similarLanguages[0]) language = similarLanguages[0];
  if (!language) language = translationKeys[0];
  for (let lang2 in translations) {
    const currentContent = translations[lang2];
    translations[lang2] = { translation: currentContent };
  }
  const initValues = {
    lng: language,
    fallbackLng: Config.language.defaultLanguage,
    resources: translations,
    keySeparator: "*",
    saveMissing: true,
    returnEmptyString: true,
    fallbackValue: false,
    returnNull: false,
    interpolation: {
      escapeValue: false
    },
    missingKeyHandler: () => {
      return "";
    },
    parseMissingKeyHandler: () => {
      return "";
    }
  };
  if (isPreview) {
    i18n.init(initValues);
    return { language };
  } else {
    const i18Instance = createI18nInstance(initValues);
    return { language, i18Instance };
  }
}
export const FormBuilderTranslatorService = {
  addInternalizationToElement,
  updateSingleTranslation,
  loadInternalization,
  changeLanguage,
  init
};
