import { pageSectionType } from "../../../model/page-section-type.model";
import { ExperienceType } from "../../../model/experience.type";
import { Config } from "../../../utils/config";
import { ApiService } from "../../../services/api-service/api-service";
export const isSectionDeletable = (pageSection, experienceType, experience, index) => {
  switch (pageSection) {
    case pageSectionType.HEADER:
    case pageSectionType.FOOTER:
    case pageSectionType.SURVEY:
      return false;
    case pageSectionType.TINT:
      return experienceType === ExperienceType.TAG_O_WAR || experienceType === ExperienceType.POLL || experience && experience.attributes.data.draft.filter((e) => e.sectionType === pageSectionType.TINT).length > 1;
    case pageSectionType.FORM:
      if (experienceType === ExperienceType.CONTEST) {
        return !(experience && experience.attributes.data.draft[index].data.content.isPhotoUploadEnabled);
      }
      return true;
    case pageSectionType.MEDIA:
    case pageSectionType.SOCIAL_FEED_COUNTER:
    case pageSectionType.BANNER:
    case pageSectionType.SOCIAL:
    case pageSectionType.CUSTOM_BUTTON:
    case pageSectionType.CUSTOM_HEADLINE:
    case pageSectionType.TIMER:
    case pageSectionType.CUSTOM_PARAGRAPH:
    case pageSectionType.CUSTOM_SPACER:
    case pageSectionType.MULTI_COLUMN:
    case pageSectionType.IMAGE:
    case pageSectionType.FORM_BUILDER:
      return true;
    case pageSectionType.VIDEO:
      if (experienceType === ExperienceType.EVENT) {
        return experience && experience.attributes.data.draft.filter((e) => e.sectionType === pageSectionType.VIDEO).length > 1;
      }
      return true;
    case pageSectionType.POST:
    case pageSectionType.POLL:
      return false;
  }
  return false;
};
export const isReorderAble = (pageSection) => {
  switch (pageSection) {
    case pageSectionType.COOKIE_BANNER:
      return false;
    default:
      return true;
  }
};
export const getFormSubmissionUrl = (experienceId) => {
  return `/v2/teams/${getTeamId()}/experiences/${experienceId}/form_submissions`;
};
export const getTeamId = () => {
  var _a, _b;
  const dataDomain = (_b = (_a = document.querySelector("#tint-app")) == null ? void 0 : _a.attributes["data-domain"]) == null ? void 0 : _b.value;
  const teamId = ApiService.getTeamId() ? ApiService.getTeamId() : dataDomain;
  return teamId;
};
export const getIsGDPRSeen = () => {
  try {
    const GDPRComplianceLocalStorageValue = JSON.parse(
      window.localStorage.getItem(
        `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`
      )
    );
    return GDPRComplianceLocalStorageValue !== null && GDPRComplianceLocalStorageValue.seen;
  } catch (e) {
    return null;
  }
};
export const getIsGDPRAllowed = () => {
  try {
    const GDPRComplianceLocalStorageValue = JSON.parse(
      window.localStorage.getItem(
        `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`
      )
    );
    return GDPRComplianceLocalStorageValue !== null && (GDPRComplianceLocalStorageValue == null ? void 0 : GDPRComplianceLocalStorageValue.analytics) && (GDPRComplianceLocalStorageValue == null ? void 0 : GDPRComplianceLocalStorageValue.marketing);
  } catch (e) {
    return null;
  }
};
