import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_EMAIL_TEMPLATES: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES", "GET_EMAIL_TEMPLATES")),
  POST_EMAIL_TEMPLATE: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES", "POST_EMAIL_TEMPLATE")),
  UPDATE_EMAIL_TEMPLATE: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES", "UPDATE_EMAIL_TEMPLATE")),
  DELETE_EMAIL_TEMPLATE: createRoutine(createPrefixedActionName("EMAIL_TEMPLATES", "DELETE_EMAIL_TEMPLATE")),
  SELECT_EMAIL_TEMPLATE: createPrefixedActionName("EMAIL_TEMPLATES", "SELECT_EMAIL_TEMPLATE"),
  UNSELECT_EMAIL_TEMPLATE: createPrefixedActionName("EMAIL_TEMPLATES", "UNSELECT_EMAIL_TEMPLATE"),
  UNSELECT_ALL_EMAIL_TEMPLATES: createPrefixedActionName("EMAIL_TEMPLATES", "UNSELECT_ALL_EMAIL_TEMPLATES"),
  BULK_DELETE_EMAIL_TEMPLATES: createRoutine(
    createPrefixedActionName("EMAIL_TEMPLATES", "BULK_DELETE_EMAIL_TEMPLATES")
  )
};
export const getEmailTemplatesStart = () => ({
  type: ACTIONS.GET_EMAIL_TEMPLATES.REQUEST
});
export const getEmailTemplatesSuccess = (data) => ({
  type: ACTIONS.GET_EMAIL_TEMPLATES.SUCCESS,
  payload: data
});
export const getEmailTemplatesError = (err) => ({
  type: ACTIONS.GET_EMAIL_TEMPLATES.FAILURE,
  payload: err
});
export const postEmailTemplateStart = () => ({
  type: ACTIONS.POST_EMAIL_TEMPLATE.REQUEST
});
export const postEmailTemplateSuccess = (data) => ({
  type: ACTIONS.POST_EMAIL_TEMPLATE.SUCCESS,
  payload: data
});
export const postEmailTemplateError = (err) => ({
  type: ACTIONS.POST_EMAIL_TEMPLATE.FAILURE,
  payload: err
});
export const deleteEmailTemplateStart = () => ({
  type: ACTIONS.DELETE_EMAIL_TEMPLATE.REQUEST
});
export const deleteEmailTemplateSuccess = (id) => ({
  type: ACTIONS.DELETE_EMAIL_TEMPLATE.SUCCESS,
  payload: id
});
export const deleteEmailTemplateError = (err) => ({
  type: ACTIONS.DELETE_EMAIL_TEMPLATE.FAILURE,
  payload: err
});
export const selectEmailTemplate = (data) => ({
  type: ACTIONS.SELECT_EMAIL_TEMPLATE,
  payload: data
});
export const unselectEmailTemplate = (id) => ({
  type: ACTIONS.UNSELECT_EMAIL_TEMPLATE,
  payload: id
});
export const deselectAllEmailTemplates = () => ({
  type: ACTIONS.UNSELECT_ALL_EMAIL_TEMPLATES
});
export const bulkDeleteEmailTemplatesStart = () => ({
  type: ACTIONS.BULK_DELETE_EMAIL_TEMPLATES.REQUEST
});
export const bulkDeleteEmailTemplatesSuccess = (id) => ({
  type: ACTIONS.BULK_DELETE_EMAIL_TEMPLATES.SUCCESS,
  payload: id
});
export const bulkDeleteEmailTemplatesError = (err) => ({
  type: ACTIONS.BULK_DELETE_EMAIL_TEMPLATES.FAILURE,
  payload: err
});
export const updateEmailTemplateStart = () => ({
  type: ACTIONS.UPDATE_EMAIL_TEMPLATE.REQUEST
});
export const updateEmailTemplateSuccess = (data) => ({
  type: ACTIONS.UPDATE_EMAIL_TEMPLATE.SUCCESS,
  payload: data
});
export const updateEmailTemplateError = (err) => ({
  type: ACTIONS.UPDATE_EMAIL_TEMPLATE.FAILURE,
  payload: err
});
