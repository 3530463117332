var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import "./sidebar-switcher.sass";
import TintSidebar from "../sidebar/sidebar";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
export const TintSidebarSwitcherStateType = {
  MAIN: "main",
  EDIT: "edit",
  CREATE: "create"
};
const TintSidebarSwitcher = ({
  modalDetails,
  onSubmit,
  onChangeContent,
  onRemove,
  onClose,
  closeSidebarAfterSubmit = true
}) => {
  const debounceDelayMs = 100;
  const [sidebarDetails, setSidebarDetails] = useState();
  const [isModalVisible, setModalVisibility] = useState(modalDetails.isVisible);
  const [isEditMode] = useState(modalDetails.isEditMode);
  const onChangeData = debounce((data) => {
    setSidebarDetails(data);
  }, debounceDelayMs);
  const renderCurrentComponent = () => {
    return modalDetails.component && modalDetails.component({
      onChangeContent,
      onChangeData,
      onRemove,
      isEditMode,
      data: modalDetails.data,
      state: modalDetails.state
    });
  };
  const onFooterLeftButtonClick = () => {
    switch (modalDetails.state) {
      case TintSidebarSwitcherStateType.MAIN:
        onSidebarClose();
        break;
      case TintSidebarSwitcherStateType.CREATE:
        onChangeContent(TintSidebarSwitcherStateType.MAIN);
        break;
      case TintSidebarSwitcherStateType.EDIT:
        onChangeContent(TintSidebarSwitcherStateType.MAIN);
        break;
      default:
        return;
    }
  };
  const onRightButtonClick = () => {
    var _a;
    onSubmit == null ? void 0 : onSubmit(
      __spreadProps(__spreadValues({}, sidebarDetails), {
        isValid: void 0
      }),
      modalDetails.state,
      (_a = modalDetails.data) == null ? void 0 : _a.id
    );
    closeSidebarAfterSubmit && onSidebarClose();
  };
  const onSidebarClose = () => {
    setModalVisibility(false);
    onClose && onClose();
  };
  return isModalVisible && /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      sidebarClassName: "tint-sidebar-switcher",
      title: modalDetails.headerTitle,
      isOpen: isModalVisible,
      onClose: onSidebarClose,
      defaultFooterStyle: false,
      onLeftButtonClick: onFooterLeftButtonClick,
      oneButton: modalDetails.state === TintSidebarSwitcherStateType.MAIN,
      leftButtonText: modalDetails.state === TintSidebarSwitcherStateType.MAIN ? "Confirm" : "Previous",
      rightButtonText: modalDetails.state !== TintSidebarSwitcherStateType.MAIN ? "Save" : "Edit",
      isRightButtonDisabled: !sidebarDetails || sidebarDetails && !sidebarDetails.isValid,
      onRightButtonClick
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-switcher__content" }, renderCurrentComponent())
  );
};
TintSidebarSwitcher.propTypes = {
  mainComponent: PropTypes.any,
  onChangeContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default TintSidebarSwitcher;
