import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  SET_FETCH_PREVIOUS: createPrefixedActionName("ASSET_MANAGER", "SET_FETCH_PREVIOUS"),
  CLEAR_ASSETS_STATE: createPrefixedActionName("ASSET_MANAGER", "CLEAR_ASSETS_STATE"),
  GET_ASSETS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "GET_ASSETS")),
  DELETE_ASSETS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "DELETE_ASSETS")),
  POST_ASSETS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "POST_ASSETS")),
  UPDATE_ASSET_TAGS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "UPDATE_ASSET_TAGS")),
  UPDATE_ASSET_STATUS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "UPDATE_ASSET_STATUS")),
  UPDATE_ASSET: createRoutine(createPrefixedActionName("ASSET_MANAGER", "UPDATE_ASSET")),
  SELECT_ASSET: createPrefixedActionName("ASSET_MANAGER", "SELECT_ASSET"),
  UNSELECT_ASSET: createPrefixedActionName("ASSET_MANAGER", "UNSELECT_ASSET"),
  SELECT_ALL_ASSETS: createPrefixedActionName("ASSET_MANAGER", "SELECT_ALL_ASSETS"),
  UNSELECT_ALL_ASSETS: createPrefixedActionName("ASSET_MANAGER", "UNSELECT_ALL_ASSETS"),
  SET_ASSET_DISPLAY_TYPE: createPrefixedActionName("ASSET_MANAGER", "SET_ASSET_DISPLAY_TYPE"),
  POST_MARKETING_INTEGRATIONS_ASSET_REQUEST: createRoutine(
    createPrefixedActionName("ASSET_MANAGER", "POST_MARKETING_INTEGRATIONS_ASSET_REQUEST")
  ),
  GET_MARKETING_INTEGRATIONS_ASSET_REQUEST: createRoutine(
    createPrefixedActionName("ASSET_MANAGER", "GET_MARKETING_INTEGRATIONS_ASSET_REQUEST")
  ),
  OPEN_CREATE_ASSET_MODAL: createPrefixedActionName("ASSET_MANAGER", "OPEN_CREATE_ASSET_MODAL"),
  CLOSE_CREATE_ASSET_MODAL: createPrefixedActionName("ASSET_MANAGER", "CLOSE_CREATE_ASSET_MODAL"),
  SELECT_LIGHT_BOX_ASSET: createPrefixedActionName("ASSET_MANAGER", "SELECT_LIGHT_BOX_ASSET"),
  DOWNLOAD_ASSETS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "DOWNLOAD_ASSETS")),
  GET_DOWNLOAD_STATUS: createRoutine(createPrefixedActionName("ASSET_MANAGER", "GET_DOWNLOAD_STATUS")),
  UPDATE_BULK_TAG: createRoutine(createPrefixedActionName("ASSET_MANAGER", "UPDATE_BULK_TAG")),
  TOGGLE_BULK_TAG_SIDEBAR: createPrefixedActionName("ASSET_MANAGER", "TOGGLE_BULK_TAG_SIDEBAR"),
  UPDATE_ASSETS_TO_COLLECTIONS: createRoutine(
    createPrefixedActionName("ASSET_MANAGER", "UPDATE_ASSETS_TO_COLLECTIONS")
  )
};
export const toggleBulkTagStart = (isVisible) => ({
  type: ACTIONS.TOGGLE_BULK_TAG_SIDEBAR,
  payload: isVisible
});
export const updateBulkTagStart = () => ({
  type: ACTIONS.UPDATE_BULK_TAG.REQUEST
});
export const updateBulkTagSuccess = (data) => ({
  type: ACTIONS.UPDATE_BULK_TAG.SUCCESS,
  payload: data
});
export const updateBulkTagError = (err) => ({
  type: ACTIONS.UPDATE_BULK_TAG.FAILURE,
  payload: err
});
export const getAssetDownloadStatusStart = () => ({
  type: ACTIONS.GET_DOWNLOAD_STATUS.REQUEST
});
export const getAssetDownloadStatusSuccess = (data) => ({
  type: ACTIONS.GET_DOWNLOAD_STATUS.SUCCESS,
  payload: data
});
export const getAssetDownloadStatusError = (err) => ({
  type: ACTIONS.GET_DOWNLOAD_STATUS.FAILURE,
  payload: err
});
export const downloadAssetsStart = () => ({
  type: ACTIONS.DOWNLOAD_ASSETS.REQUEST
});
export const downloadAssetsSuccess = (data) => ({
  type: ACTIONS.DOWNLOAD_ASSETS.SUCCESS,
  payload: data
});
export const downloadAssetsError = (err) => ({
  type: ACTIONS.DOWNLOAD_ASSETS.FAILURE,
  payload: err
});
export const selectLightBoxAsset = (asset) => ({
  type: ACTIONS.SELECT_LIGHT_BOX_ASSET,
  payload: asset
});
export const clearAssetsState = () => ({
  type: ACTIONS.CLEAR_ASSETS_STATE
});
export const openCreateAssetModal = () => ({
  type: ACTIONS.OPEN_CREATE_ASSET_MODAL
});
export const closeCreateAssetModal = () => ({
  type: ACTIONS.CLOSE_CREATE_ASSET_MODAL
});
export const postMarketingIntegrationsAssetRequestStart = () => ({
  type: ACTIONS.POST_MARKETING_INTEGRATIONS_ASSET_REQUEST.REQUEST
});
export const postMarketingIntegrationsAssetRequestSuccess = (data) => ({
  type: ACTIONS.POST_MARKETING_INTEGRATIONS_ASSET_REQUEST.SUCCESS,
  payload: data
});
export const postMarketingIntegrationsAssetRequestError = (err) => ({
  type: ACTIONS.POST_MARKETING_INTEGRATIONS_ASSET_REQUEST.FAILURE,
  payload: err
});
export const getMarketingIntegrationsAssetRequestStart = () => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS_ASSET_REQUEST.REQUEST
});
export const getMarketingIntegrationsAssetRequestSuccess = (data) => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS_ASSET_REQUEST.SUCCESS,
  payload: data
});
export const getMarketingIntegrationsAssetRequestError = (err) => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS_ASSET_REQUEST.FAILURE,
  payload: err
});
export const postAssetsStart = () => ({
  type: ACTIONS.POST_ASSETS.REQUEST
});
export const postAssetsSuccess = (data) => ({
  type: ACTIONS.POST_ASSETS.SUCCESS,
  payload: data
});
export const postAssetsError = (err) => ({
  type: ACTIONS.POST_ASSETS.FAILURE,
  payload: err
});
export const getAssetsStart = () => ({
  type: ACTIONS.GET_ASSETS.REQUEST
});
export const getAssetsSuccess = (data) => ({
  type: ACTIONS.GET_ASSETS.SUCCESS,
  payload: data
});
export const getAssetsError = (err) => ({
  type: ACTIONS.GET_ASSETS.FAILURE,
  payload: err
});
export const deleteAssetsStart = () => ({
  type: ACTIONS.DELETE_ASSETS.REQUEST
});
export const deleteAssetsSuccess = (data) => ({
  type: ACTIONS.DELETE_ASSETS.SUCCESS,
  payload: data
});
export const deleteAssetsError = (err) => ({
  type: ACTIONS.DELETE_ASSETS.FAILURE,
  payload: err
});
export const updateAssetTagsStart = () => ({
  type: ACTIONS.UPDATE_ASSET_TAGS.REQUEST
});
export const updateAssetTagsSuccess = (data) => ({
  type: ACTIONS.UPDATE_ASSET_TAGS.SUCCESS,
  payload: data
});
export const updateAssetTagsError = (err) => ({
  type: ACTIONS.UPDATE_ASSET_TAGS.FAILURE,
  payload: err
});
export const updateAssetStatusStart = () => ({
  type: ACTIONS.UPDATE_ASSET_STATUS.REQUEST
});
export const updateAssetStatusSuccess = (data) => ({
  type: ACTIONS.UPDATE_ASSET_STATUS.SUCCESS,
  payload: data
});
export const updateAssetStatusError = (err) => ({
  type: ACTIONS.UPDATE_ASSET_STATUS.FAILURE,
  payload: err
});
export const updateAssetStart = () => ({
  type: ACTIONS.UPDATE_ASSET.REQUEST
});
export const updateAssetSuccess = (data) => ({
  type: ACTIONS.UPDATE_ASSET.SUCCESS,
  payload: data
});
export const updateAssetError = (err) => ({
  type: ACTIONS.UPDATE_ASSET.FAILURE,
  payload: err
});
export const setAssetDisplayType = (displayType) => {
  return {
    type: ACTIONS.SET_ASSET_DISPLAY_TYPE,
    payload: displayType
  };
};
export const selectAllAssets = () => {
  return {
    type: ACTIONS.SELECT_ALL_ASSETS
  };
};
export const unselectAllAssets = () => {
  return {
    type: ACTIONS.UNSELECT_ALL_ASSETS
  };
};
export const selectAsset = (id) => {
  return {
    type: ACTIONS.SELECT_ASSET,
    payload: id
  };
};
export const unSelectAsset = (id) => {
  return {
    type: ACTIONS.UNSELECT_ASSET,
    payload: id
  };
};
export const updateAssetsToCollectionsStart = () => ({
  type: ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.REQUEST
});
export const updateAssetsToCollectionsSuccess = (data) => ({
  type: ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.SUCCESS,
  payload: data
});
export const updateAssetsToCollectionsError = (err) => ({
  type: ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.FAILURE,
  payload: err
});
