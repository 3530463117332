import { preloadersConfig } from "../../../../../../components/interface/preloaders/preloaders.config";
export const feedCardLoaderRects = {
  desktop: [
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "9",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.white,
        strokeWidth: "1",
        stroke: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "34",
        y: "9",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "60",
        y: "9",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "86",
        y: "9",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "112",
        y: "9",
        width: "16",
        height: "16",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "40",
        width: "100%",
        height: "300",
        rx: "6",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.circle,
      props: {
        cx: "15",
        cy: "369",
        r: "15",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "42",
        y: "354",
        width: "25%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "42",
        y: "370",
        width: "15%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "400",
        width: "100%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "426",
        width: "90%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "0",
        y: "452",
        width: "40%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    },
    {
      tagType: preloadersConfig.tagTypes.rect,
      props: {
        x: "75%",
        y: "500",
        width: "25%",
        height: "12",
        fill: preloadersConfig.fillColors.secondary
      }
    }
  ]
};
