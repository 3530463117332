export const getPercentValue = (value, naturalImageWidth) => {
  const hundredPercent = 100;
  return value * hundredPercent / naturalImageWidth;
};
export const removePixelsFromValue = (value) => {
  var _a;
  return (_a = value == null ? void 0 : value.toString()) == null ? void 0 : _a.replace("px", "");
};
export const addPixelsToValue = (value) => {
  return `${value}px`;
};
export const findElementByValue = (array, value) => {
  return array == null ? void 0 : array.find((el) => el.value === value);
};
