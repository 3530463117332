import store from "../../store";
import { setPageHeader, setPageTitle } from "../../actions/app-route-actions";
const configure = ({ pageTitle, pageHeader }) => {
  document.title = pageTitle ? `TINT - ${pageTitle}` : "TINT";
  store.dispatch(setPageTitle(pageTitle));
  store.dispatch(setPageHeader(pageHeader));
};
export const PageInitService = {
  configure
};
