import React from "react";
import PropTypes from "prop-types";
import ColorParser from "../../../../../../../../services/color-parser/color-parser";
import usePercent from "../../../../../../../../components/hooks/use-percent/use-percent";
import "./tag-o-war-section-ring.sass";
const TagOWarSectionRing = function({ content, styles, percentLeft, percentRight }) {
  const data = {
    leftSide: {
      hashTag: content.socialFeedLeft.label,
      percentResult: percentLeft,
      backgroundRange: styles.backgroundRangeLeft,
      background: styles.backgroundColorLeft,
      textColor: styles.textColorLeft,
      thumb: content.leftSideImage
    },
    rightSide: {
      hashTag: content.socialFeedRight.label,
      percentResult: percentRight,
      backgroundRange: styles.backgroundRangeRight,
      background: styles.backgroundColorRight,
      textColor: styles.textColorRight,
      thumb: content.rightSideImage
    }
  };
  const { percentValue } = usePercent(data.leftSide.percentResult, data.rightSide.percentResult);
  function renderRingSide({ hashTag, background, textColor, thumb }) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-ring__thumb", style: { backgroundImage: `url(${thumb})` } }, !thumb ? /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-image" }) : null, /* @__PURE__ */ React.createElement(
      "span",
      {
        className: "triangle",
        ref: (input) => {
          input && input.style.setProperty("border-top-color", ColorParser.defaultColor(background));
        }
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-ring__content", style: { backgroundColor: ColorParser.defaultColor(background) } }, /* @__PURE__ */ React.createElement("h2", { style: { color: ColorParser.defaultColor(textColor) } }, hashTag), /* @__PURE__ */ React.createElement("span", { className: "tint-tag-o-war-ring__counter" })));
  }
  function getPercentValue(percentValue2) {
    const fiftyPercentCount = 50;
    return isNaN(percentValue2) ? fiftyPercentCount : percentValue2;
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-ring", "data-testid": "tagOWarRing" }, /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-ring__left-side" }, renderRingSide(data.leftSide)), /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-ring__right-side" }, renderRingSide(data.rightSide)), /* @__PURE__ */ React.createElement("span", { className: "versus" }, "vs")), /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-ring-result" }, /* @__PURE__ */ React.createElement(
    "span",
    {
      "data-testid": "leftCountBar",
      className: "tint-tag-o-war-ring-result__left range-bar",
      style: {
        backgroundColor: ColorParser.defaultColor(data.leftSide.backgroundRange),
        width: `${getPercentValue(percentValue.firstValue)}%`
      }
    },
    `${getPercentValue(percentValue.firstValue)}%`
  ), /* @__PURE__ */ React.createElement(
    "span",
    {
      "data-testid": "rightCountBar",
      className: "tint-tag-o-war-ring-result__right range-bar",
      style: {
        backgroundColor: ColorParser.defaultColor(data.rightSide.backgroundRange),
        width: `${getPercentValue(percentValue.secondValue)}%`
      }
    },
    `${getPercentValue(percentValue.secondValue)}%`
  )));
};
export default TagOWarSectionRing;
TagOWarSectionRing.propTypes = {
  content: PropTypes.shape({
    socialFeedLeft: PropTypes.object,
    leftSideImage: PropTypes.string,
    percentLeft: PropTypes.number,
    socialFeedRight: PropTypes.object,
    rightSideImage: PropTypes.string,
    percentRight: PropTypes.number
  }),
  styles: PropTypes.any
};
