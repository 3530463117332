import React, { useRef, useState, useEffect } from "react";
import "./accordion-tab.sass";
const AccordionTabAnimationState = {
  COLLAPSE_END: "tint-accordion-tab--collapsing-end",
  COLLAPSE_PENDING: "tint-accordion-tab--collapsing",
  EXPAND_END: "tint-accordion-tab--expanding-end",
  EXPAND_PENDING: "tint-accordion-tab--expanding"
};
const AccordionTab = ({
  children,
  animationTimeDuration,
  isActive,
  isDisabled,
  title,
  selectedItemsCount,
  onTabClick,
  onClick,
  index,
  icon,
  maxHeight
}) => {
  const collapseActiveClass = isActive ? "tint-accordion-tab--expanded" : "tint-accordion-tab--collapsed";
  const elementRef = useRef();
  const [animationState, setAnimationState] = useState();
  const [timeoutFunc, setTimeoutFunc] = useState();
  const [clickTime, setClickTime] = useState(animationTimeDuration * 1e3);
  const [isAnimationFinished, setAnimationFinished] = useState(true);
  const [currentHeight, setCurrentHeight] = useState(true);
  const onAccordionTabClick = () => {
    if (!isDisabled) onTabClick(index);
    onClick && onClick(isActive);
  };
  useEffect(() => {
    manageAnimation();
  }, [isActive]);
  const manageAnimation = () => {
    setAnimationState(
      isActive ? AccordionTabAnimationState.EXPAND_PENDING : AccordionTabAnimationState.COLLAPSE_PENDING
    );
    setAnimationFinished(false);
    if (isAnimationFinished) {
      startAnimation(animationTimeDuration * 1e3);
      setClickTime((/* @__PURE__ */ new Date()).getTime());
    } else {
      const differentTime = (/* @__PURE__ */ new Date()).getTime() - clickTime;
      clearTimeout(timeoutFunc);
      startAnimation(differentTime);
    }
  };
  const onAnimationFinish = () => {
    setAnimationFinished(true);
    setAnimationState(isActive ? AccordionTabAnimationState.EXPAND_END : AccordionTabAnimationState.COLLAPSE_END);
  };
  const startAnimation = (delay) => {
    setTimeoutFunc(setTimeout(onAnimationFinish, delay));
  };
  useEffect(() => {
    if (elementRef.current && currentHeight !== elementRef.current.clientHeight && elementRef.current.clientHeight !== 0 && typeof maxHeight === "undefined") {
      setCurrentHeight(elementRef.current.clientHeight);
    }
  });
  return /* @__PURE__ */ React.createElement("div", { className: `tint-accordion-tab ${collapseActiveClass} ${animationState}`, "data-testid": "tint-accordion-tab" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "tint-accordion-tab__header",
      onClick: (e) => {
        e.stopPropagation();
        onAccordionTabClick(index);
      }
    },
    /* @__PURE__ */ React.createElement("span", { className: "tint-accordion-tab__title" }, title),
    /* @__PURE__ */ React.createElement("span", { className: "tint-accordion-tab__actions" }, selectedItemsCount && selectedItemsCount !== 0 ? /* @__PURE__ */ React.createElement("span", { className: "tint-accordion-tab__counter" }, selectedItemsCount) : null, icon ? icon : null, !icon ? isActive ? /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-chevron-up" }) : /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-chevron-down" }) : null)
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "tint-accordion-tab__content",
      style: {
        minHeight: isActive ? currentHeight : 0,
        transition: `min-height ${animationTimeDuration}s, max-height ${!maxHeight ? animationTimeDuration : animationTimeDuration * 1.5}s`,
        maxHeight: isActive ? maxHeight ? maxHeight : currentHeight : 0
      }
    },
    React.Children.map(
      children,
      (child) => React.cloneElement(child, {
        ref: elementRef
      })
    )
  ));
};
export default React.memo(AccordionTab);
