import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./custom-date-picker.sass";
const CustomDatePicker = ({ disabled, onClear, onClick, value, calendarIconLeft = false, clearButton = false }, ref) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-custom-data-picker${disabled ? " tint-custom-data-picker--disabled" : ""} ${calendarIconLeft ? " tint-custom-data-picker--icon-left" : ""}`,
      onClick,
      ref
    },
    /* @__PURE__ */ React.createElement("span", { className: "fas fa-calendar-alt tint-custom-data-picker__calendar-icon" }),
    value,
    value && clearButton && /* @__PURE__ */ React.createElement("span", { className: "fal fa-times tint-custom-data-picker__clear-icon", onClick: onClear })
  );
};
const CustomDatePickerWithForwardedRef = forwardRef(CustomDatePicker);
CustomDatePickerWithForwardedRef.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
export default CustomDatePickerWithForwardedRef;
