import InputPrimary from "../interface/inputs/input-primary/input-primary";
import React from "react";
import { Config } from "../../utils/config";
import TintIcon from "../interface/icon/icon";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import "./twitter-integration-form.sass";
const TwitterIntegrationForm = ({ formik }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "twitter-integration-form" }, /* @__PURE__ */ React.createElement("form", { id: "integration-url", onSubmit: formik.handleSubmit }, /* @__PURE__ */ React.createElement("h4", { className: "twitter-integration-form__twitter-header" }, "API v2"), /* @__PURE__ */ React.createElement("p", { className: "twitter-integration-form__twitter-description" }, "Please use the following fields to enter your app credentials for X API v2"), /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "CLIENT ID",
      placeholder: "Enter Client ID",
      id: "client-id",
      name: "client_id",
      type: "text",
      value: formik.values.client_id,
      error: formik.errors.client_id,
      touched: formik.touched.client_id,
      errorMsg: formik.errors.client_id,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur
    }
  ), /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "CLIENT SECRET",
      placeholder: "Enter Client Secret",
      id: "secret-id",
      name: "secret_id",
      type: "text",
      value: formik.values.secret_id,
      error: formik.errors.secret_id,
      touched: formik.touched.secret_id,
      errorMsg: formik.errors.secret_id,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur
    }
  ), /* @__PURE__ */ React.createElement("hr", { className: "twitter-integration-form__twitter-separator" }), /* @__PURE__ */ React.createElement("h4", { className: "twitter-integration-form__twitter-header" }, "API v1.1"), /* @__PURE__ */ React.createElement("p", { className: "twitter-integration-form__twitter-description" }, "Please use the following fields to enter your app credentials for X API v1.1"), /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "CLIENT ID",
      placeholder: "Enter Client ID",
      id: "client_id_v1",
      name: "client_id_v1",
      type: "text",
      value: formik.values.client_id_v1,
      error: formik.errors.client_id_v1,
      touched: formik.touched.client_id_v1,
      errorMsg: formik.errors.client_id_v1,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur
    }
  ), /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "CLIENT SECRET",
      placeholder: "Enter Client Secret",
      id: "secret-id",
      name: "secret_id_v1",
      type: "text",
      value: formik.values.secret_id_v1,
      error: formik.errors.secret_id_v1,
      touched: formik.touched.secret_id_v1,
      errorMsg: formik.errors.secret_id_v1,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur
    }
  )), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "twitter-integration-form__info-card",
      href: Config.url.zenDesk.twitter_integration,
      target: "_blank",
      rel: "noreferrer"
    },
    /* @__PURE__ */ React.createElement("p", null, "Click here to learn how to obtain an API key."),
    /* @__PURE__ */ React.createElement(TintIcon, { icon: faArrowUpRight, fontSize: "16px" })
  ));
};
export default TwitterIntegrationForm;
