var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect, useMemo } from "react";
import "./ecommerce.container.sass";
import TintModal from "../../../../components/modal/modal";
import ImageDrawArea from "../../components/image-draw-area/image-draw-area";
import { Button } from "../../../../components/interface/button/button";
import AssignProductsSidebar from "../../components/assign-products-sidebar/assign-products-sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  addNoDrawableProduct,
  cancelDrawRectUI,
  dismissDrawRectUI,
  hoverProduct,
  restoreDefaultTaggedProducts,
  unHoverProduct
} from "../../actions/ui-ecommerce.actions";
import { EventerService } from "../../../../services/eventer/eventer.service";
import {
  getInfiniteLoadedPosts,
  getFilteredProducts,
  getProducts
} from "../../../account-settings/actions/products/products.fat-actions";
import { getProductFeeds } from "../../../account-settings/actions/product-feeds/product-feeds.fat-actions";
import { getPostTaggedProducts } from "../../actions/ui-ecommerce.fat-actions";
import useDebounce from "../../../../components/hooks/use-debounce/use-debounce";
import { toast } from "react-toastify";
import { setDefaultFilteredProducts } from "../../../account-settings/actions/products/products.actions";
import { getCategories, getBrand } from "../../../account-settings/reducers/products/products.reducer";
const mapItemsForDropdown = (items) => items ? Object.keys(items).map((item, i) => ({
  key: i,
  name: item
})) : [];
import { createParamsForUrl, filters } from "../../../account-settings/utils/filter-helper/filter-helper";
import { handleApiError } from "../../../../services/functions/error-handler/error-handler";
import { GuardNames } from "../../../../guards/guards-list";
import FeatureGuard from "../../../../guards/feature-guard";
import useSocialFeedTranslation from "../../../../components/hooks/use-social-feed-names/use-feature-accessibility";
import TintIcon from "../../../../components/interface/icon/icon";
import { faBagShopping } from "@fortawesome/pro-solid-svg-icons";
import { ImageDraggableRectStatus } from "../../components/image-draggable-rect/image-draggable-rect";
const ECommerceContainer = ({ tintId, newEditorPost, onClose }) => {
  const dispatch = useDispatch();
  const taggedProducts = useSelector((state) => state.UIEcommerce.taggedProducts);
  const isPending = useSelector((state) => state.UIEcommerce.isPending);
  const isNoDrawableProduct = useSelector((state) => state.UIEcommerce.isNoDrawable);
  const categories = useSelector((state) => getCategories(state.products));
  const brand = useSelector((state) => getBrand(state.products));
  const productFeeds = useSelector((state) => state.productFeeds.data);
  const isAbleToLoadProductFeeds = useSelector((state) => {
    var _a, _b;
    return (_b = (_a = state.productFeeds) == null ? void 0 : _a.links) == null ? void 0 : _b.next;
  });
  const productsError = useSelector((state) => state.products.error);
  const filteredAndInfiniteLoadedProductList = useSelector((state) => state.products.infiniteLoadedData);
  const filteredAndInfiniteLoadedProductListLinks = useSelector((state) => state.products.infiniteLoadedLinks);
  const isUnassignedElement = taggedProducts.find((product) => !product.productId);
  const mappedAndMemoizedCategories = useMemo(() => mapItemsForDropdown(categories), [categories]);
  const mappedAndMemoizedBrand = useMemo(() => mapItemsForDropdown(brand), [brand]);
  const { getSocialFeedName } = useSocialFeedTranslation();
  const mapProductFeeds = (list = []) => {
    return list.map((feed) => {
      var _a;
      return {
        key: feed.id,
        name: feed.attributes.name || ((_a = getSocialFeedName(feed.attributes.source)) == null ? void 0 : _a.source)
      };
    });
  };
  const [isAbleToDraw, setAbleToDraw] = useState(false);
  const [post, setPost] = useState();
  const [currentTintId, setCurrentTintId] = useState(tintId);
  const [imageSrc, setImageSrc] = useState();
  const [isSidebarVisible, setSidebarVisibility] = useState(true);
  const [productFilters, setProductFilters] = useState();
  const [productFeedQuery, setProductFeedQuery] = useState();
  const [dropdownProductFeeds, setDropdownProductFeeds] = useState([]);
  const debounceDelay = 300;
  const mapProductList = (arr) => {
    return arr.map((product) => ({
      id: product.id,
      name: product.attributes.name,
      sku: product.attributes.sku,
      imageUrl: product.attributes.image_url
    }));
  };
  const fetchNextProductFeeds = () => dispatch(getProductFeeds({ url: isAbleToLoadProductFeeds }));
  const onSearchProductFeeds = (query) => setProductFeedQuery(query);
  useEffect(() => {
    if ((productFeeds == null ? void 0 : productFeeds.length) && !dropdownProductFeeds.find((e) => e.id === productFeeds[0].id))
      setDropdownProductFeeds([...dropdownProductFeeds, ...productFeeds]);
  }, [productFeeds]);
  useEffect(() => {
    if (productFeedQuery !== void 0) {
      dispatch(
        getProductFeeds({
          url: createParamsForUrl({
            baseUrl: "/product_feeds",
            size: 25,
            filters: [
              {
                by: filters.QUERY,
                value: productFeedQuery
              }
            ]
          })
        })
      );
      setDropdownProductFeeds([]);
    }
  }, [productFeedQuery, dispatch]);
  useEffect(() => {
    if (!newEditorPost) {
      EventerService.on({
        eventType: EventerService.EVENT_TYPE.OPEN_UI_ECOMMERCE,
        fn: onInit
      });
    }
    return () => {
      EventerService.off({
        eventType: EventerService.EVENT_TYPE.CLOSE_UI_ECOMMERCE,
        fn: onInit
      });
    };
  }, []);
  const onInit = (data, isNewEditor = false) => {
    setSidebarVisibility(true);
    const post2 = data.payload;
    const _tintId = data.payload.user_id;
    const postId = post2.post_id ? post2.post_id.replace("user_", "") : data.payload.id;
    setPost(post2);
    setCurrentTintId(_tintId || tintId);
    if (isNewEditor) {
      setImageSrc(data.payload.attributes.image_url);
    } else {
      setImageSrc(data.payload.original_image);
    }
    Promise.all([
      dispatch(getPostTaggedProducts(postId)),
      dispatch(getProducts({ url: "/products?page[size]=25&filter[status]=active,archived" })),
      dispatch(getProductFeeds())
    ]).catch(() => {
      toast.error("Something went wrong, try again later");
    });
  };
  useEffect(() => {
    if (newEditorPost) {
      onInit({ payload: newEditorPost }, true);
    }
  }, [newEditorPost]);
  const onCloseSidebar = () => {
    setProductFilters(void 0);
    dispatch(restoreDefaultTaggedProducts());
    dispatch(cancelDrawRectUI());
    dispatch(dismissDrawRectUI());
    dispatch(setDefaultFilteredProducts());
    setAbleToDraw(false);
    setPost(void 0);
    setCurrentTintId(void 0);
    setImageSrc(void 0);
    window.parent.postMessage({ type: "CLOSE_UI_ECOMMERCE" }, "*");
    setSidebarVisibility(false);
    onClose && onClose();
  };
  const onSuccessfullyChangedTaggedProduct = () => window.iframeRef && window.iframeRef.contentWindow && window.iframeRef.contentWindow.postMessage({ type: "ON_TAGGED_PRODUCTS_CHANGE", taggedProducts, post }, "*");
  useEffect(() => {
    onSuccessfullyChangedTaggedProduct();
  }, [taggedProducts]);
  const onAddProductTagButtonClick = (isContentDrawable) => {
    if (isContentDrawable) {
      setAbleToDraw(true);
      dispatch(dismissDrawRectUI());
    } else {
      setSidebarVisibility(true);
      setAbleToDraw(false);
      dispatch(
        addNoDrawableProduct({
          rectId: taggedProducts.length.toString(),
          status: ImageDraggableRectStatus.UNASSIGNED
        })
      );
    }
  };
  const addProductWithoutDraw = () => {
    setSidebarVisibility(true);
    setAbleToDraw(false);
    dispatch(
      addNoDrawableProduct({
        rectId: taggedProducts.length.toString(),
        status: ImageDraggableRectStatus.UNASSIGNED
      })
    );
  };
  const onRectClick = () => {
    setSidebarVisibility(true);
  };
  const onModalClose = () => onCloseSidebar();
  const productFiltersDebounce = useDebounce(productFilters, debounceDelay);
  useEffect(() => {
    productFilters && dispatch(
      getFilteredProducts({
        url: createParamsForUrl({
          baseUrl: "/products",
          size: 25,
          filters: [
            ...Object.entries(productFilters).filter(([, value]) => value).map(([key, value]) => ({ by: key, value })),
            { by: "status", value: "active,archived" }
          ]
        })
      })
    );
  }, [productFiltersDebounce]);
  const fetchNextProducts = () => {
    if (filteredAndInfiniteLoadedProductListLinks) {
      dispatch(
        getInfiniteLoadedPosts({
          url: filteredAndInfiniteLoadedProductListLinks.next
        })
      ).catch((err) => handleApiError(err, toast.error));
    }
  };
  const onInputChange = (value) => {
    setProductFilters(__spreadProps(__spreadValues({}, productFilters), {
      q: value
    }));
  };
  const onProductFeedChange = (currentItem) => {
    setProductFilters(__spreadProps(__spreadValues({}, productFilters), {
      product_feed_ids: currentItem ? currentItem.key : ""
    }));
  };
  const onProductCategoryChange = (currentItem) => {
    setProductFilters(__spreadProps(__spreadValues({}, productFilters), {
      categories: currentItem ? currentItem.name : ""
    }));
  };
  const onProductBrandChange = (currentItem) => {
    setProductFilters(__spreadProps(__spreadValues({}, productFilters), {
      brand: currentItem ? currentItem.name : ""
    }));
  };
  const renderProductIcon = (product, index, isActive) => /* @__PURE__ */ React.createElement(
    "div",
    {
      key: product ? product.productId + index : index,
      className: `gallery__product-icon ${isActive ? "gallery__product-icon--active" : ""}`
    },
    /* @__PURE__ */ React.createElement(TintIcon, { icon: faBagShopping }),
    /* @__PURE__ */ React.createElement("span", null, index + 1)
  );
  const checkIsCarouselImagePost = (type, post2) => {
    var _a, _b;
    return type === "carousel" && ((_a = post2 == null ? void 0 : post2.attributes) == null ? void 0 : _a.image_url) && !((_b = post2 == null ? void 0 : post2.attributes) == null ? void 0 : _b.video_url);
  };
  const renderModal = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    const type = post.attributes.media_type;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      TintModal,
      {
        isOpen: isSidebarVisible,
        onClose: onCloseSidebar,
        isBackDropVisible: true,
        className: "ecommerce-gallery gallery"
      },
      /* @__PURE__ */ React.createElement("span", { className: "gallery__close-button fas fa-times", onClick: onModalClose }),
      /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.MODERATE_TINT }, /* @__PURE__ */ React.createElement(React.Fragment, null, !isAbleToDraw && !isUnassignedElement && !isNoDrawableProduct ? /* @__PURE__ */ React.createElement("div", { className: "gallery__action" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "info",
          text: "+Add Product Tag",
          onClick: () => onAddProductTagButtonClick(type === "image" || checkIsCarouselImagePost(type, post))
        }
      ), /* @__PURE__ */ React.createElement("span", null, `Tag products that appear in this ${type === "video" ? "video" : type === "image" ? "image" : "post"}.`)) : /* @__PURE__ */ React.createElement("div", { className: "gallery__action" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "info",
          text: "Cancel",
          onClick: () => {
            dispatch(cancelDrawRectUI());
            setAbleToDraw(false);
          }
        }
      ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, type === "image" || checkIsCarouselImagePost(type, post) ? "Drag a bounding box around the product you wish to tag." : "Select product from the right sidebar."), type === "image" || checkIsCarouselImagePost(type, post) ? /* @__PURE__ */ React.createElement("p", { className: "gallery__no-draw-button" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          onClick: () => isAbleToDraw ? addProductWithoutDraw() : null,
          style: { cursor: isAbleToDraw ? "pointer" : "not-allowed" }
        },
        "OR click here to skip"
      ), ", and tag product without bounding box coordinates.") : null)))),
      !isPending && /* @__PURE__ */ React.createElement("div", { className: "gallery__icons-container" }, taggedProducts.map((product, i) => {
        return renderProductIcon(product, i, product.status !== ImageDraggableRectStatus.COMPLETE);
      })),
      type === "image" || checkIsCarouselImagePost(type, post) ? /* @__PURE__ */ React.createElement(
        ImageDrawArea,
        {
          isPending,
          taggedProducts,
          isAbleToDraw,
          imageSrc,
          onDrawEnd: () => {
            setSidebarVisibility(true);
            setAbleToDraw(false);
          },
          onRectClick,
          tintId: currentTintId,
          post
        }
      ) : type === "video" || type === "carousel" && ((_a = post == null ? void 0 : post.attributes) == null ? void 0 : _a.video_url) ? /* @__PURE__ */ React.createElement("div", { className: "gallery__wrapper" }, /* @__PURE__ */ React.createElement(
        "img",
        {
          className: "tint-image-preview__image",
          src: (_b = post == null ? void 0 : post.attributes) == null ? void 0 : _b.image_url,
          alt: ((_c = post == null ? void 0 : post.attributes) == null ? void 0 : _c.alternative_text) || ((_d = post == null ? void 0 : post.attributes) == null ? void 0 : _d.text) || ((_e = post == null ? void 0 : post.attributes) == null ? void 0 : _e.author.name)
        }
      ), type === "video" && /* @__PURE__ */ React.createElement(
        TintIcon,
        {
          style: { width: "80px", height: "80px" },
          className: "video-icon",
          svgIconSrc: "/public/js/react/assets/tint-editor/play-icon.svg",
          alt: "Play Video"
        }
      )) : type === "text_only" ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "gallery__wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__no-thumb-description" }, post.attributes.type === "rss" || post.attributes.type === "google_news" || post.attributes.type === "review_trackers" || post.attributes.rating ? /* @__PURE__ */ React.createElement("p", null, ((_f = post == null ? void 0 : post.attributes) == null ? void 0 : _f.title) || ((_g = post == null ? void 0 : post.attributes) == null ? void 0 : _g.text)) : /* @__PURE__ */ React.createElement("p", null, ((_h = post == null ? void 0 : post.attributes) == null ? void 0 : _h.text) || ((_i = post == null ? void 0 : post.attributes) == null ? void 0 : _i.title))))) : null,
      /* @__PURE__ */ React.createElement(
        AssignProductsSidebar,
        {
          isPending,
          onInputChange,
          onProductFeedChange,
          onProductCategoryChange,
          onProductBrandChange,
          post,
          tintId: currentTintId,
          onAssignProduct: () => setAbleToDraw(false),
          isSidebarVisible,
          onSidebarClose: onCloseSidebar,
          filteredProductList: mapProductList(filteredAndInfiniteLoadedProductList),
          filteredProductListLinks: filteredAndInfiniteLoadedProductListLinks,
          productFeeds: mapProductFeeds(dropdownProductFeeds),
          isAbleToLoadProductFeeds,
          onSearchProductFeeds,
          fetchNextProductFeeds,
          productCategories: mappedAndMemoizedCategories,
          productBrand: mappedAndMemoizedBrand,
          onProductOver: (productId, rectId) => dispatch(hoverProduct(productId, rectId)),
          onProductLeave: () => dispatch(unHoverProduct()),
          fetchNextProducts,
          productsError,
          productFilters
        }
      )
    ));
  };
  return post ? /* @__PURE__ */ React.createElement("div", { className: "tint-ecommerce-container" }, renderModal()) : null;
};
export default ECommerceContainer;
