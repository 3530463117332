export const hasNullValue = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      return true;
    }
  }
  return false;
};
export const isJSONParsable = (str) => {
  if (typeof str !== "string") {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};
