import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditInput } from "../../../";
import { Button } from "../../../../../../../components/interface/button/button";
import { FileStack } from "../../../../../../../services/filestack/file-stack";
import "./edit-footer-content.sass";
import { createTranslationKey } from "../../../../../containers/experience/translation-creator";
export class EditFooterContent extends Component {
  componentWillMount() {
    this.footerLinksTmp = JSON.parse(JSON.stringify(this.props.data.footerLinks));
  }
  onLogoButtonClick() {
    const fileStack = new FileStack();
    fileStack.openFilePicker({
      accept: ["image/*"],
      maxSize: this.props.maxFileSize,
      onUploadDone: (res) => {
        if (res.filesUploaded.length > 0) {
          this.props.dataUpdate({ logoUrl: res.filesUploaded[0].url });
        }
      }
    });
  }
  renderViewWithLogo() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-header-content tint-edit-header-content__center" }, /* @__PURE__ */ React.createElement("img", { src: this.props.data.logoUrl }), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.props.dataUpdate({ logoUrl: "" });
        },
        type: "gray",
        size: "full-width",
        text: "Remove Logo"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "logo link",
        placeholder: "Enter Url",
        defaultValue: this.props.data.logoRedirectUrl,
        onChange: (e) => {
          this.props.dataUpdate({ logoRedirectUrl: e.target.value });
        }
      }
    ), this.props.cookieEnabled ? /* @__PURE__ */ React.createElement(
      EditInput,
      {
        label: "Manage Cookies Label",
        placeholder: "Cookies label",
        defaultValue: this.props.data.manageCookiesLabel,
        onChange: (e) => {
          this.props.dataUpdate({ manageCookiesLabel: e.target.value });
        }
      }
    ) : null);
  }
  renderViewWithoutLogo() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-header-content" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: this.onLogoButtonClick.bind(this),
        type: "gray",
        size: "full-width",
        text: "Upload Logo",
        icon: "far fa-arrow-to-top"
      }
    ));
  }
  removeFormField(footerLinkIndex) {
    this.footerLinksTmp = this.footerLinksTmp.filter(
      (footerLink, _index, array) => array.indexOf(footerLink) !== footerLinkIndex
    );
    this.props.dataUpdate({ footerLinks: this.footerLinksTmp });
  }
  renderEditInput() {
    return this.props.data.footerLinks.map((e, i) => {
      return /* @__PURE__ */ React.createElement("div", { key: i }, /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (event) => {
            this.props.onTranslationUpdate(e.text, event.target.value);
          },
          label: `Footer LINK ${i + 1}`,
          placeholder: "Enter Text",
          rightIcon: "fas fa-trash",
          rightIconAction: () => this.removeFormField(i),
          defaultValue: this.props.getTranslation(e.text)
        }
      ), /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (event) => {
            this.props.onTranslationUpdate(e.url, event.target.value);
          },
          placeholder: "Enter URL",
          defaultValue: this.props.getTranslation(e.url)
        }
      ));
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Upload an image below to add it to this block on your page." }, /* @__PURE__ */ React.createElement(
      "form",
      {
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
          this.props.saveData();
        }
      },
      this.props.data.logoUrl ? this.renderViewWithLogo() : this.renderViewWithoutLogo(),
      /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.footerCopyright, e.target.value);
          },
          placeholder: "Enter Text",
          label: "footer copyright",
          defaultValue: this.props.getTranslation(this.props.data.footerCopyright)
        }
      ),
      this.renderEditInput(),
      /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: (e) => {
            e.preventDefault();
            const footerLinkKey = createTranslationKey(
              this.props.experienceType,
              "FOOTER",
              "LINK",
              this.props.data.footerLinks.length
            );
            const footerTextKey = createTranslationKey(
              this.props.experienceType,
              "FOOTER",
              "TEXT",
              this.props.data.footerLinks.length
            );
            this.footerLinksTmp.push({
              url: footerLinkKey,
              text: footerTextKey
            });
            this.props.onNewTranslationUpdate(footerLinkKey, "");
            this.props.onNewTranslationUpdate(footerTextKey, "Footer link text");
            this.props.dataUpdate({ footerLinks: this.footerLinksTmp });
          },
          type: "gray",
          size: "full-width",
          text: "Add Footer Link"
        }
      ),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit")
    ));
  }
}
