import React from "react";
import { useSelector } from "react-redux";
import "./edit-upload-design.sass";
import PropTypes from "prop-types";
import { FileStack } from "../../../../../services/filestack/file-stack";
import { Button } from "../../../../../components/interface/button/button";
import { RangeInput } from "../../../../../components/interface/inputs/range-input/range-input";
import { EditColorInput } from "../";
import { getMaxImageSizePlanLimit } from "../../../../../reducers/session";
const EditUploadDesign = ({ dataUpdate, styleDataUpdate, data, isPaddingDisabled, isImageUploadDisabled = false }) => {
  const maxImageSizePlanLimit = useSelector(getMaxImageSizePlanLimit);
  const onUploadClick = (propertyName) => {
    new FileStack().openFilePicker({
      accept: ["image/*"],
      maxSize: maxImageSizePlanLimit,
      onUploadDone: (res) => {
        if (res.filesUploaded.length > 0) {
          dataUpdate({
            [propertyName]: res.filesUploaded[0].url
          });
        }
      }
    });
  };
  const renderUploadButton = (title, propertyName, imageLimit) => {
    return data.content[propertyName] ? renderViewWithLogo(propertyName, title) : /* @__PURE__ */ React.createElement("div", { className: "tint-edit-upload-design__upload-container" }, /* @__PURE__ */ React.createElement("p", { className: "tint-edit-upload-design__button-description" }, title), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => onUploadClick(propertyName, imageLimit),
        type: "gray",
        size: "full-width",
        text: "Upload image",
        icon: "far fa-arrow-to-top"
      }
    ));
  };
  const renderViewWithLogo = (propertyName, title) => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-header-content__center" }, /* @__PURE__ */ React.createElement("p", { className: "tint-edit-upload-design__button-description" }, title), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-edit-upload-design__image",
        style: { backgroundImage: `url(${data.content[propertyName]})` }
      }
    ), /* @__PURE__ */ React.createElement(Button, { onClick: () => dataUpdate({ [propertyName]: "" }), type: "gray", size: "full-width", text: "Remove Image" }));
  };
  const renderViewWithoutLogo = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-upload-design" }, isPaddingDisabled ? null : /* @__PURE__ */ React.createElement(
      RangeInput,
      {
        value: data.styles.sectionPadding,
        onChange: (e) => {
          styleDataUpdate({ sectionPadding: e });
        },
        label: "Section Padding"
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-upload-design__header" }, /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: data.styles.backgroundColor,
        label: "section background color",
        onChange: (value) => {
          styleDataUpdate({ backgroundColor: value });
        }
      }
    )), !isImageUploadDisabled && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "tint-edit-upload-design__title" }, "BACKGROUND IMAGE"), /* @__PURE__ */ React.createElement("p", { className: "tint-edit-upload-design__description" }, "Add a background image by clicking the upload buttons below. You can add up to three images that are optimized for various sizes."), renderUploadButton("DESKTOP - 1170px", "backgroundImageUrl"), renderUploadButton("TABLET - 768px", "backgroundImageUrlMedium"), renderUploadButton("MOBILE - 480px", "backgroundImageUrlSmall")));
  };
  return renderViewWithoutLogo();
};
EditUploadDesign.propTypes = {
  backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dataUpdate: PropTypes.func,
  styleDataUpdate: PropTypes.func,
  data: PropTypes.object,
  isPaddingDisabled: PropTypes.bool
};
export default EditUploadDesign;
