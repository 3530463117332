import React from "react";
import { useSelector } from "react-redux";
import "./image-preview.sass";
import TintIcon from "../../../../../components/interface/icon/icon";
import getIconProperties from "../../../configs/social-icon";
import { faClone, faBagShopping } from "@fortawesome/free-solid-svg-icons";
import TintButtonIcon, {
  TintButtonIconShape,
  TintButtonIconTypes
} from "../../../../../components/interface/button-icon/button-icon";
import { getSocialFeed } from "../../../reducers/posts.reducer";
import { getSocialFeedSource } from "../../../selectors/tint-editor.selector";
const TintImagePreview = ({ post }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
  const socialFeed = useSelector((state) => getSocialFeed(state.tintEditorPosts, post == null ? void 0 : post.id));
  const feedSource = getSocialFeedSource(socialFeed);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview" }, ((_a = post == null ? void 0 : post.attributes) == null ? void 0 : _a.image_url) && /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "tint-image-preview__image",
      src: (_b = post == null ? void 0 : post.attributes) == null ? void 0 : _b.image_url,
      alt: ((_c = post == null ? void 0 : post.attributes) == null ? void 0 : _c.alternative_text) || ((_d = post == null ? void 0 : post.attributes) == null ? void 0 : _d.text) || ((_e = post == null ? void 0 : post.attributes) == null ? void 0 : _e.author.name)
    }
  ), !post.attributes.image_url && /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__no-thumb-description" }, post.attributes.type === "rss" || post.attributes.type === "google_news" || post.attributes.type === "review_trackers" || post.attributes.rating ? /* @__PURE__ */ React.createElement("p", null, ((_f = post == null ? void 0 : post.attributes) == null ? void 0 : _f.title) || ((_g = post == null ? void 0 : post.attributes) == null ? void 0 : _g.text)) : /* @__PURE__ */ React.createElement("p", null, ((_h = post == null ? void 0 : post.attributes) == null ? void 0 : _h.text) || ((_i = post == null ? void 0 : post.attributes) == null ? void 0 : _i.title))), /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__icon-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__icons-left" }, (post == null ? void 0 : post.attributes.media_type) === "carousel" && /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__media-type" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faClone, fontSize: "16px" })), ((_l = (_k = (_j = post == null ? void 0 : post.relationships) == null ? void 0 : _j.product_tags) == null ? void 0 : _k.data) == null ? void 0 : _l[0]) && /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__shop-icon" }, /* @__PURE__ */ React.createElement(
    TintButtonIcon,
    {
      iconSize: 16,
      icon: faBagShopping,
      type: TintButtonIconTypes.WHITE,
      shape: TintButtonIconShape.CIRCLE
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__type-icon" }, /* @__PURE__ */ React.createElement(
    TintIcon,
    {
      icon: (_m = getIconProperties(feedSource)) == null ? void 0 : _m.icon,
      svgIconSrc: getIconProperties(feedSource).svgPostPreviewIconSrc ? getIconProperties(feedSource).svgPostPreviewIconSrc : (_n = getIconProperties(feedSource)) == null ? void 0 : _n.svgIconSrc,
      fontSize: "16px"
    }
  ))), ((_o = post == null ? void 0 : post.attributes) == null ? void 0 : _o.video_url) && /* @__PURE__ */ React.createElement(
    TintIcon,
    {
      style: { width: "80px", height: "80px" },
      className: "video-icon",
      svgIconSrc: "/public/js/react/assets/tint-editor/play-icon.svg",
      alt: "Play Video"
    }
  ));
};
export default TintImagePreview;
