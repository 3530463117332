export const isoLanguageList = {
  af: { name: "Afrikaans", direction: "ltr" },
  af_NA: { name: "Afrikaans (Namibia)", direction: "ltr" },
  af_ZA: { name: "Afrikaans (South Africa)", direction: "ltr" },
  ak: { name: "Akan", direction: "ltr" },
  ak_GH: { name: "Akan (Ghana)", direction: "ltr" },
  sq: { name: "Albanian", direction: "ltr" },
  sq_AL: { name: "Albanian (Albania)", direction: "ltr" },
  sq_XK: { name: "Albanian (Kosovo)", direction: "ltr" },
  sq_MK: { name: "Albanian (Macedonia)", direction: "ltr" },
  am: { name: "Amharic", direction: "ltr" },
  am_ET: { name: "Amharic (Ethiopia)", direction: "ltr" },
  ar: { name: "Arabic", direction: "rtl" },
  ar_DZ: { name: "Arabic (Algeria)", direction: "rtl" },
  ar_BH: { name: "Arabic (Bahrain)", direction: "rtl" },
  ar_TD: { name: "Arabic (Chad)", direction: "rtl" },
  ar_KM: { name: "Arabic (Comoros)", direction: "rtl" },
  ar_DJ: { name: "Arabic (Djibouti)", direction: "rtl" },
  ar_EG: { name: "Arabic (Egypt)", direction: "rtl" },
  ar_ER: { name: "Arabic (Eritrea)", direction: "rtl" },
  ar_IQ: { name: "Arabic (Iraq)", direction: "rtl" },
  ar_IL: { name: "Arabic (Israel)", direction: "rtl" },
  ar_JO: { name: "Arabic (Jordan)", direction: "rtl" },
  ar_KW: { name: "Arabic (Kuwait)", direction: "rtl" },
  ar_LB: { name: "Arabic (Lebanon)", direction: "rtl" },
  ar_LY: { name: "Arabic (Libya)", direction: "rtl" },
  ar_MR: { name: "Arabic (Mauritania)", direction: "rtl" },
  ar_MA: { name: "Arabic (Morocco)", direction: "rtl" },
  ar_OM: { name: "Arabic (Oman)", direction: "rtl" },
  ar_PS: { name: "Arabic (Palestinian Territories)", direction: "rtl" },
  ar_QA: { name: "Arabic (Qatar)", direction: "rtl" },
  ar_SA: { name: "Arabic (Saudi Arabia)", direction: "rtl" },
  ar_SO: { name: "Arabic (Somalia)", direction: "rtl" },
  ar_SS: { name: "Arabic (South Sudan)", direction: "rtl" },
  ar_SD: { name: "Arabic (Sudan)", direction: "rtl" },
  ar_SY: { name: "Arabic (Syria)", direction: "rtl" },
  ar_TN: { name: "Arabic (Tunisia)", direction: "rtl" },
  ar_AE: { name: "Arabic (United Arab Emirates)", direction: "rtl" },
  ar_EH: { name: "Arabic (Western Sahara)", direction: "rtl" },
  ar_YE: { name: "Arabic (Yemen)", direction: "rtl" },
  hy: { name: "Armenian", direction: "ltr" },
  hy_AM: { name: "Armenian (Armenia)", direction: "ltr" },
  as: { name: "Assamese", direction: "ltr" },
  as_IN: { name: "Assamese (India)", direction: "ltr" },
  az: { name: "Azerbaijani", direction: "rtl" },
  az_AZ: { name: "Azerbaijani (Azerbaijan)", direction: "rtl" },
  az_Cyrl_AZ: { name: "Azerbaijani (Cyrillic, Azerbaijan)", direction: "rtl" },
  az_Cyrl: { name: "Azerbaijani (Cyrillic)", direction: "rtl" },
  az_Latn_AZ: { name: "Azerbaijani (Latin, Azerbaijan)", direction: "rtl" },
  az_Latn: { name: "Azerbaijani (Latin)", direction: "rtl" },
  bm: { name: "Bambara", direction: "ltr" },
  bm_Latn_ML: { name: "Bambara (Latin, Mali)", direction: "ltr" },
  bm_Latn: { name: "Bambara (Latin)", direction: "ltr" },
  eu: { name: "Basque", direction: "ltr" },
  eu_ES: { name: "Basque (Spain)", direction: "ltr" },
  be: { name: "Belarusian", direction: "ltr" },
  be_BY: { name: "Belarusian (Belarus)", direction: "ltr" },
  bn: { name: "Bengali", direction: "ltr" },
  bn_BD: { name: "Bengali (Bangladesh)", direction: "ltr" },
  bn_IN: { name: "Bengali (India)", direction: "ltr" },
  bs: { name: "Bosnian", direction: "ltr" },
  bs_BA: { name: "Bosnian (Bosnia & Herzegovina)", direction: "ltr" },
  bs_Cyrl_BA: {
    name: "Bosnian (Cyrillic, Bosnia & Herzegovina)",
    direction: "ltr"
  },
  bs_Cyrl: { name: "Bosnian (Cyrillic)", direction: "ltr" },
  bs_Latn_BA: {
    name: "Bosnian (Latin, Bosnia & Herzegovina)",
    direction: "ltr"
  },
  bs_Latn: { name: "Bosnian (Latin)", direction: "ltr" },
  br: { name: "Breton", direction: "ltr" },
  br_FR: { name: "Breton (France)", direction: "ltr" },
  bg: { name: "Bulgarian", direction: "ltr" },
  bg_BG: { name: "Bulgarian (Bulgaria)", direction: "ltr" },
  my: { name: "Burmese", direction: "ltr" },
  my_MM: { name: "Burmese (Myanmar (Burma))", direction: "ltr" },
  ca: { name: "Catalan", direction: "ltr" },
  ca_AD: { name: "Catalan (Andorra)", direction: "ltr" },
  ca_FR: { name: "Catalan (France)", direction: "ltr" },
  ca_IT: { name: "Catalan (Italy)", direction: "ltr" },
  ca_ES: { name: "Catalan (Spain)", direction: "ltr" },
  zh: { name: "Chinese", direction: "ltr" },
  zh_CN: { name: "Chinese (China)", direction: "ltr" },
  zh_HK: { name: "Chinese (Hong Kong SAR China)", direction: "ltr" },
  zh_MO: { name: "Chinese (Macau SAR China)", direction: "ltr" },
  zh_Hans_CN: { name: "Chinese (Simplified, China)", direction: "ltr" },
  zh_Hans_HK: {
    name: "Chinese (Simplified, Hong Kong SAR China)",
    direction: "ltr"
  },
  zh_Hans_MO: {
    name: "Chinese (Simplified, Macau SAR China)",
    direction: "ltr"
  },
  zh_Hans_SG: { name: "Chinese (Simplified, Singapore)", direction: "ltr" },
  zh_Hans: { name: "Chinese (Simplified)", direction: "ltr" },
  zh_SG: { name: "Chinese (Singapore)", direction: "ltr" },
  zh_TW: { name: "Chinese (Taiwan)", direction: "ltr" },
  zh_Hant_HK: {
    name: "Chinese (Traditional, Hong Kong SAR China)",
    direction: "ltr"
  },
  zh_Hant_MO: {
    name: "Chinese (Traditional, Macau SAR China)",
    direction: "ltr"
  },
  zh_Hant_TW: { name: "Chinese (Traditional, Taiwan)", direction: "ltr" },
  zh_Hant: { name: "Chinese (Traditional)", direction: "ltr" },
  kw: { name: "Cornish", direction: "ltr" },
  kw_GB: { name: "Cornish (United Kingdom)", direction: "ltr" },
  hr: { name: "Croatian", direction: "ltr" },
  hr_BA: { name: "Croatian (Bosnia & Herzegovina)", direction: "ltr" },
  hr_HR: { name: "Croatian (Croatia)", direction: "ltr" },
  cs: { name: "Czech", direction: "ltr" },
  cs_CZ: { name: "Czech (Czech Republic)", direction: "ltr" },
  da: { name: "Danish", direction: "ltr" },
  da_DK: { name: "Danish (Denmark)", direction: "ltr" },
  da_GL: { name: "Danish (Greenland)", direction: "ltr" },
  nl: { name: "Dutch", direction: "ltr" },
  nl_AW: { name: "Dutch (Aruba)", direction: "ltr" },
  nl_BE: { name: "Dutch (Belgium)", direction: "ltr" },
  nl_BQ: { name: "Dutch (Caribbean Netherlands)", direction: "ltr" },
  nl_CW: { name: "Dutch (Cura\xE7ao)", direction: "ltr" },
  nl_NL: { name: "Dutch (Netherlands)", direction: "ltr" },
  nl_SX: { name: "Dutch (Sint Maarten)", direction: "ltr" },
  nl_SR: { name: "Dutch (Suriname)", direction: "ltr" },
  dz: { name: "Dzongkha", direction: "ltr" },
  dz_BT: { name: "Dzongkha (Bhutan)", direction: "ltr" },
  "en-US": { name: "English", direction: "ltr" },
  en_AS: { name: "English (American Samoa)", direction: "ltr" },
  en_AI: { name: "English (Anguilla)", direction: "ltr" },
  en_AG: { name: "English (Antigua & Barbuda)", direction: "ltr" },
  en_AU: { name: "English (Australia)", direction: "ltr" },
  en_BS: { name: "English (Bahamas)", direction: "ltr" },
  en_BB: { name: "English (Barbados)", direction: "ltr" },
  en_BE: { name: "English (Belgium)", direction: "ltr" },
  en_BZ: { name: "English (Belize)", direction: "ltr" },
  en_BM: { name: "English (Bermuda)", direction: "ltr" },
  en_BW: { name: "English (Botswana)", direction: "ltr" },
  en_IO: { name: "English (British Indian Ocean Territory)", direction: "ltr" },
  en_VG: { name: "English (British Virgin Islands)", direction: "ltr" },
  en_CM: { name: "English (Cameroon)", direction: "ltr" },
  en_CA: { name: "English (Canada)", direction: "ltr" },
  en_KY: { name: "English (Cayman Islands)", direction: "ltr" },
  en_CX: { name: "English (Christmas Island)", direction: "ltr" },
  en_CC: { name: "English (Cocos (Keeling) Islands)", direction: "ltr" },
  en_CK: { name: "English (Cook Islands)", direction: "ltr" },
  en_DG: { name: "English (Diego Garcia)", direction: "ltr" },
  en_DM: { name: "English (Dominica)", direction: "ltr" },
  en_ER: { name: "English (Eritrea)", direction: "ltr" },
  en_FK: { name: "English (Falkland Islands)", direction: "ltr" },
  en_FJ: { name: "English (Fiji)", direction: "ltr" },
  en_GM: { name: "English (Gambia)", direction: "ltr" },
  en_GH: { name: "English (Ghana)", direction: "ltr" },
  en_GI: { name: "English (Gibraltar)", direction: "ltr" },
  en_GD: { name: "English (Grenada)", direction: "ltr" },
  en_GU: { name: "English (Guam)", direction: "ltr" },
  en_GG: { name: "English (Guernsey)", direction: "ltr" },
  en_GY: { name: "English (Guyana)", direction: "ltr" },
  en_HK: { name: "English (Hong Kong SAR China)", direction: "ltr" },
  en_IN: { name: "English (India)", direction: "ltr" },
  en_IE: { name: "English (Ireland)", direction: "ltr" },
  en_IM: { name: "English (Isle of Man)", direction: "ltr" },
  en_JM: { name: "English (Jamaica)", direction: "ltr" },
  en_JE: { name: "English (Jersey)", direction: "ltr" },
  en_KE: { name: "English (Kenya)", direction: "ltr" },
  en_KI: { name: "English (Kiribati)", direction: "ltr" },
  en_LS: { name: "English (Lesotho)", direction: "ltr" },
  en_LR: { name: "English (Liberia)", direction: "ltr" },
  en_MO: { name: "English (Macau SAR China)", direction: "ltr" },
  en_MG: { name: "English (Madagascar)", direction: "ltr" },
  en_MW: { name: "English (Malawi)", direction: "ltr" },
  en_MY: { name: "English (Malaysia)", direction: "ltr" },
  en_MT: { name: "English (Malta)", direction: "ltr" },
  en_MH: { name: "English (Marshall Islands)", direction: "ltr" },
  en_MU: { name: "English (Mauritius)", direction: "ltr" },
  en_FM: { name: "English (Micronesia)", direction: "ltr" },
  en_MS: { name: "English (Montserrat)", direction: "ltr" },
  en_NA: { name: "English (Namibia)", direction: "ltr" },
  en_NR: { name: "English (Nauru)", direction: "ltr" },
  en_NZ: { name: "English (New Zealand)", direction: "ltr" },
  en_NG: { name: "English (Nigeria)", direction: "ltr" },
  en_NU: { name: "English (Niue)", direction: "ltr" },
  en_NF: { name: "English (Norfolk Island)", direction: "ltr" },
  en_MP: { name: "English (Northern Mariana Islands)", direction: "ltr" },
  en_PK: { name: "English (Pakistan)", direction: "ltr" },
  en_PW: { name: "English (Palau)", direction: "ltr" },
  en_PG: { name: "English (Papua New Guinea)", direction: "ltr" },
  en_PH: { name: "English (Philippines)", direction: "ltr" },
  en_PN: { name: "English (Pitcairn Islands)", direction: "ltr" },
  en_PR: { name: "English (Puerto Rico)", direction: "ltr" },
  en_RW: { name: "English (Rwanda)", direction: "ltr" },
  en_WS: { name: "English (Samoa)", direction: "ltr" },
  en_SC: { name: "English (Seychelles)", direction: "ltr" },
  en_SL: { name: "English (Sierra Leone)", direction: "ltr" },
  en_SG: { name: "English (Singapore)", direction: "ltr" },
  en_SX: { name: "English (Sint Maarten)", direction: "ltr" },
  en_SB: { name: "English (Solomon Islands)", direction: "ltr" },
  en_ZA: { name: "English (South Africa)", direction: "ltr" },
  en_SS: { name: "English (South Sudan)", direction: "ltr" },
  en_SH: { name: "English (St. Helena)", direction: "ltr" },
  en_KN: { name: "English (St. Kitts & Nevis)", direction: "ltr" },
  en_LC: { name: "English (St. Lucia)", direction: "ltr" },
  en_VC: { name: "English (St. Vincent & Grenadines)", direction: "ltr" },
  en_SD: { name: "English (Sudan)", direction: "ltr" },
  en_SZ: { name: "English (Swaziland)", direction: "ltr" },
  en_TZ: { name: "English (Tanzania)", direction: "ltr" },
  en_TK: { name: "English (Tokelau)", direction: "ltr" },
  en_TO: { name: "English (Tonga)", direction: "ltr" },
  en_TT: { name: "English (Trinidad & Tobago)", direction: "ltr" },
  en_TC: { name: "English (Turks & Caicos Islands)", direction: "ltr" },
  en_TV: { name: "English (Tuvalu)", direction: "ltr" },
  en_UM: { name: "English (U.S. Outlying Islands)", direction: "ltr" },
  en_VI: { name: "English (U.S. Virgin Islands)", direction: "ltr" },
  en_UG: { name: "English (Uganda)", direction: "ltr" },
  en_GB: { name: "English (United Kingdom)", direction: "ltr" },
  en_US: { name: "English (United States)", direction: "ltr" },
  en_VU: { name: "English (Vanuatu)", direction: "ltr" },
  en_ZM: { name: "English (Zambia)", direction: "ltr" },
  en_ZW: { name: "English (Zimbabwe)", direction: "ltr" },
  eo: { name: "Esperanto", direction: "ltr" },
  et: { name: "Estonian", direction: "ltr" },
  et_EE: { name: "Estonian (Estonia)", direction: "ltr" },
  ee: { name: "Ewe", direction: "ltr" },
  ee_GH: { name: "Ewe (Ghana)", direction: "ltr" },
  ee_TG: { name: "Ewe (Togo)", direction: "ltr" },
  fo: { name: "Faroese", direction: "ltr" },
  fo_FO: { name: "Faroese (Faroe Islands)", direction: "ltr" },
  fi: { name: "Finnish", direction: "ltr" },
  fi_FI: { name: "Finnish (Finland)", direction: "ltr" },
  fr: { name: "French", direction: "ltr" },
  fr_DZ: { name: "French (Algeria)", direction: "ltr" },
  fr_BE: { name: "French (Belgium)", direction: "ltr" },
  fr_BJ: { name: "French (Benin)", direction: "ltr" },
  fr_BF: { name: "French (Burkina Faso)", direction: "ltr" },
  fr_BI: { name: "French (Burundi)", direction: "ltr" },
  fr_CM: { name: "French (Cameroon)", direction: "ltr" },
  fr_CA: { name: "French (Canada)", direction: "ltr" },
  fr_CF: { name: "French (Central African Republic)", direction: "ltr" },
  fr_TD: { name: "French (Chad)", direction: "ltr" },
  fr_KM: { name: "French (Comoros)", direction: "ltr" },
  fr_CG: { name: "French (Congo - Brazzaville)", direction: "ltr" },
  fr_CD: { name: "French (Congo - Kinshasa)", direction: "ltr" },
  fr_CI: { name: "French (C\xF4te d\u2019Ivoire)", direction: "ltr" },
  fr_DJ: { name: "French (Djibouti)", direction: "ltr" },
  fr_GQ: { name: "French (Equatorial Guinea)", direction: "ltr" },
  fr_FR: { name: "French (France)", direction: "ltr" },
  fr_GF: { name: "French (French Guiana)", direction: "ltr" },
  fr_PF: { name: "French (French Polynesia)", direction: "ltr" },
  fr_GA: { name: "French (Gabon)", direction: "ltr" },
  fr_GP: { name: "French (Guadeloupe)", direction: "ltr" },
  fr_GN: { name: "French (Guinea)", direction: "ltr" },
  fr_HT: { name: "French (Haiti)", direction: "ltr" },
  fr_LU: { name: "French (Luxembourg)", direction: "ltr" },
  fr_MG: { name: "French (Madagascar)", direction: "ltr" },
  fr_ML: { name: "French (Mali)", direction: "ltr" },
  fr_MQ: { name: "French (Martinique)", direction: "ltr" },
  fr_MR: { name: "French (Mauritania)", direction: "ltr" },
  fr_MU: { name: "French (Mauritius)", direction: "ltr" },
  fr_YT: { name: "French (Mayotte)", direction: "ltr" },
  fr_MC: { name: "French (Monaco)", direction: "ltr" },
  fr_MA: { name: "French (Morocco)", direction: "ltr" },
  fr_NC: { name: "French (New Caledonia)", direction: "ltr" },
  fr_NE: { name: "French (Niger)", direction: "ltr" },
  fr_RE: { name: "French (R\xE9union)", direction: "ltr" },
  fr_RW: { name: "French (Rwanda)", direction: "ltr" },
  fr_SN: { name: "French (Senegal)", direction: "ltr" },
  fr_SC: { name: "French (Seychelles)", direction: "ltr" },
  fr_BL: { name: "French (St. Barth\xE9lemy)", direction: "ltr" },
  fr_MF: { name: "French (St. Martin)", direction: "ltr" },
  fr_PM: { name: "French (St. Pierre & Miquelon)", direction: "ltr" },
  fr_CH: { name: "French (Switzerland)", direction: "ltr" },
  fr_SY: { name: "French (Syria)", direction: "ltr" },
  fr_TG: { name: "French (Togo)", direction: "ltr" },
  fr_TN: { name: "French (Tunisia)", direction: "ltr" },
  fr_VU: { name: "French (Vanuatu)", direction: "ltr" },
  fr_WF: { name: "French (Wallis & Futuna)", direction: "ltr" },
  ff: { name: "Fulah", direction: "ltr" },
  ff_CM: { name: "Fulah (Cameroon)", direction: "ltr" },
  ff_GN: { name: "Fulah (Guinea)", direction: "ltr" },
  ff_MR: { name: "Fulah (Mauritania)", direction: "ltr" },
  ff_SN: { name: "Fulah (Senegal)", direction: "ltr" },
  gl: { name: "Galician", direction: "ltr" },
  gl_ES: { name: "Galician (Spain)", direction: "ltr" },
  lg: { name: "Ganda", direction: "ltr" },
  lg_UG: { name: "Ganda (Uganda)", direction: "ltr" },
  ka: { name: "Georgian", direction: "ltr" },
  ka_GE: { name: "Georgian (Georgia)", direction: "ltr" },
  de: { name: "German", direction: "ltr" },
  de_AT: { name: "German (Austria)", direction: "ltr" },
  de_BE: { name: "German (Belgium)", direction: "ltr" },
  de_DE: { name: "German (Germany)", direction: "ltr" },
  de_LI: { name: "German (Liechtenstein)", direction: "ltr" },
  de_LU: { name: "German (Luxembourg)", direction: "ltr" },
  de_CH: { name: "German (Switzerland)", direction: "ltr" },
  el: { name: "Greek", direction: "ltr" },
  el_CY: { name: "Greek (Cyprus)", direction: "ltr" },
  el_GR: { name: "Greek (Greece)", direction: "ltr" },
  gu: { name: "Gujarati", direction: "ltr" },
  gu_IN: { name: "Gujarati (India)", direction: "ltr" },
  ha: { name: "Hausa", direction: "ltr" },
  ha_GH: { name: "Hausa (Ghana)", direction: "ltr" },
  ha_Latn_GH: { name: "Hausa (Latin, Ghana)", direction: "ltr" },
  ha_Latn_NE: { name: "Hausa (Latin, Niger)", direction: "ltr" },
  ha_Latn_NG: { name: "Hausa (Latin, Nigeria)", direction: "ltr" },
  ha_Latn: { name: "Hausa (Latin)", direction: "ltr" },
  ha_NE: { name: "Hausa (Niger)", direction: "ltr" },
  ha_NG: { name: "Hausa (Nigeria)", direction: "ltr" },
  he: { name: "Hebrew", direction: "rtl" },
  he_IL: { name: "Hebrew (Israel)", direction: "rtl" },
  hi: { name: "Hindi", direction: "ltr" },
  hi_IN: { name: "Hindi (India)", direction: "ltr" },
  hu: { name: "Hungarian", direction: "ltr" },
  hu_HU: { name: "Hungarian (Hungary)", direction: "ltr" },
  is: { name: "Icelandic", direction: "ltr" },
  is_IS: { name: "Icelandic (Iceland)", direction: "ltr" },
  ig: { name: "Igbo", direction: "ltr" },
  ig_NG: { name: "Igbo (Nigeria)", direction: "ltr" },
  id: { name: "Indonesian", direction: "ltr" },
  id_ID: { name: "Indonesian (Indonesia)", direction: "ltr" },
  ga: { name: "Irish", direction: "ltr" },
  ga_IE: { name: "Irish (Ireland)", direction: "ltr" },
  it: { name: "Italian", direction: "ltr" },
  it_IT: { name: "Italian (Italy)", direction: "ltr" },
  it_SM: { name: "Italian (San Marino)", direction: "ltr" },
  it_CH: { name: "Italian (Switzerland)", direction: "ltr" },
  ja: { name: "Japanese", direction: "ltr" },
  ja_JP: { name: "Japanese (Japan)", direction: "ltr" },
  kl: { name: "Kalaallisut", direction: "ltr" },
  kl_GL: { name: "Kalaallisut (Greenland)", direction: "ltr" },
  kn: { name: "Kannada", direction: "ltr" },
  kn_IN: { name: "Kannada (India)", direction: "ltr" },
  ks: { name: "Kashmiri", direction: "ltr" },
  ks_Arab_IN: { name: "Kashmiri (Arabic, India)", direction: "rtl" },
  ks_Arab: { name: "Kashmiri (Arabic)", direction: "rtl" },
  ks_IN: { name: "Kashmiri (India)", direction: "ltr" },
  kk: { name: "Kazakh", direction: "ltr" },
  kk_Cyrl_KZ: { name: "Kazakh (Cyrillic, Kazakhstan)", direction: "ltr" },
  kk_Cyrl: { name: "Kazakh (Cyrillic)", direction: "ltr" },
  kk_KZ: { name: "Kazakh (Kazakhstan)", direction: "ltr" },
  km: { name: "Khmer", direction: "ltr" },
  km_KH: { name: "Khmer (Cambodia)", direction: "ltr" },
  ki: { name: "Kikuyu", direction: "ltr" },
  ki_KE: { name: "Kikuyu (Kenya)", direction: "ltr" },
  rw: { name: "Kinyarwanda", direction: "ltr" },
  rw_RW: { name: "Kinyarwanda (Rwanda)", direction: "ltr" },
  ko: { name: "Korean", direction: "ltr" },
  ko_KP: { name: "Korean (North Korea)", direction: "ltr" },
  ko_KR: { name: "Korean (South Korea)", direction: "ltr" },
  ky: { name: "Kyrgyz", direction: "ltr" },
  ky_Cyrl_KG: { name: "Kyrgyz (Cyrillic, Kyrgyzstan)", direction: "ltr" },
  ky_Cyrl: { name: "Kyrgyz (Cyrillic)", direction: "ltr" },
  ky_KG: { name: "Kyrgyz (Kyrgyzstan)", direction: "ltr" },
  lo: { name: "Lao", direction: "ltr" },
  lo_LA: { name: "Lao (Laos)", direction: "ltr" },
  lv: { name: "Latvian", direction: "ltr" },
  lv_LV: { name: "Latvian (Latvia)", direction: "ltr" },
  ln: { name: "Lingala", direction: "ltr" },
  ln_AO: { name: "Lingala (Angola)", direction: "ltr" },
  ln_CF: { name: "Lingala (Central African Republic)", direction: "ltr" },
  ln_CG: { name: "Lingala (Congo - Brazzaville)", direction: "ltr" },
  ln_CD: { name: "Lingala (Congo - Kinshasa)", direction: "ltr" },
  lt: { name: "Lithuanian", direction: "ltr" },
  lt_LT: { name: "Lithuanian (Lithuania)", direction: "ltr" },
  lu: { name: "Luba-Katanga", direction: "ltr" },
  lu_CD: { name: "Luba-Katanga (Congo - Kinshasa)", direction: "ltr" },
  lb: { name: "Luxembourgish", direction: "ltr" },
  lb_LU: { name: "Luxembourgish (Luxembourg)", direction: "ltr" },
  mk: { name: "Macedonian", direction: "ltr" },
  mk_MK: { name: "Macedonian (Macedonia)", direction: "ltr" },
  mg: { name: "Malagasy", direction: "ltr" },
  mg_MG: { name: "Malagasy (Madagascar)", direction: "ltr" },
  ms: { name: "Malay", direction: "ltr" },
  ms_BN: { name: "Malay (Brunei)", direction: "ltr" },
  ms_Latn_BN: { name: "Malay (Latin, Brunei)", direction: "ltr" },
  ms_Latn_MY: { name: "Malay (Latin, Malaysia)", direction: "ltr" },
  ms_Latn_SG: { name: "Malay (Latin, Singapore)", direction: "ltr" },
  ms_Latn: { name: "Malay (Latin)", direction: "ltr" },
  ms_MY: { name: "Malay (Malaysia)", direction: "ltr" },
  ms_SG: { name: "Malay (Singapore)", direction: "ltr" },
  ml: { name: "Malayalam", direction: "ltr" },
  ml_IN: { name: "Malayalam (India)", direction: "ltr" },
  mt: { name: "Maltese", direction: "ltr" },
  mt_MT: { name: "Maltese (Malta)", direction: "ltr" },
  gv: { name: "Manx", direction: "ltr" },
  gv_IM: { name: "Manx (Isle of Man)", direction: "ltr" },
  mr: { name: "Marathi", direction: "ltr" },
  mr_IN: { name: "Marathi (India)", direction: "ltr" },
  mn: { name: "Mongolian", direction: "ltr" },
  mn_Cyrl_MN: { name: "Mongolian (Cyrillic, Mongolia)", direction: "ltr" },
  mn_Cyrl: { name: "Mongolian (Cyrillic)", direction: "ltr" },
  mn_MN: { name: "Mongolian (Mongolia)", direction: "ltr" },
  ne: { name: "Nepali", direction: "ltr" },
  ne_IN: { name: "Nepali (India)", direction: "ltr" },
  ne_NP: { name: "Nepali (Nepal)", direction: "ltr" },
  nd: { name: "North Ndebele", direction: "ltr" },
  nd_ZW: { name: "North Ndebele (Zimbabwe)", direction: "ltr" },
  se: { name: "Northern Sami", direction: "ltr" },
  se_FI: { name: "Northern Sami (Finland)", direction: "ltr" },
  se_NO: { name: "Northern Sami (Norway)", direction: "ltr" },
  se_SE: { name: "Northern Sami (Sweden)", direction: "ltr" },
  no: { name: "Norwegian", direction: "ltr" },
  no_NO: { name: "Norwegian (Norway)", direction: "ltr" },
  nb: { name: "Norwegian Bokm\xE5l", direction: "ltr" },
  nb_NO: { name: "Norwegian Bokm\xE5l (Norway)", direction: "ltr" },
  nb_SJ: { name: "Norwegian Bokm\xE5l (Svalbard & Jan Mayen)", direction: "ltr" },
  nn: { name: "Norwegian Nynorsk", direction: "ltr" },
  nn_NO: { name: "Norwegian Nynorsk (Norway)", direction: "ltr" },
  or: { name: "Oriya", direction: "ltr" },
  or_IN: { name: "Oriya (India)", direction: "ltr" },
  om: { name: "Oromo", direction: "ltr" },
  om_ET: { name: "Oromo (Ethiopia)", direction: "ltr" },
  om_KE: { name: "Oromo (Kenya)", direction: "ltr" },
  os: { name: "Ossetic", direction: "ltr" },
  os_GE: { name: "Ossetic (Georgia)", direction: "ltr" },
  os_RU: { name: "Ossetic (Russia)", direction: "ltr" },
  ps: { name: "Pashto", direction: "ltr" },
  ps_AF: { name: "Pashto (Afghanistan)", direction: "ltr" },
  fa: { name: "Persian", direction: "rtl" },
  fa_AF: { name: "Persian (Afghanistan)", direction: "rtl" },
  fa_IR: { name: "Persian (Iran)", direction: "rtl" },
  pl: { name: "Polish", direction: "ltr" },
  pl_PL: { name: "Polish (Poland)", direction: "ltr" },
  pt: { name: "Portuguese", direction: "ltr" },
  pt_AO: { name: "Portuguese (Angola)", direction: "ltr" },
  pt_BR: { name: "Portuguese (Brazil)", direction: "ltr" },
  pt_CV: { name: "Portuguese (Cape Verde)", direction: "ltr" },
  pt_GW: { name: "Portuguese (Guinea-Bissau)", direction: "ltr" },
  pt_MO: { name: "Portuguese (Macau SAR China)", direction: "ltr" },
  pt_MZ: { name: "Portuguese (Mozambique)", direction: "ltr" },
  pt_PT: { name: "Portuguese (Portugal)", direction: "ltr" },
  pt_ST: { name: "Portuguese (S\xE3o Tom\xE9 & Pr\xEDncipe)", direction: "ltr" },
  pt_TL: { name: "Portuguese (Timor-Leste)", direction: "ltr" },
  pa: { name: "Punjabi", direction: "ltr" },
  pa_Arab_PK: { name: "Punjabi (Arabic, Pakistan)", direction: "rtl" },
  pa_Arab: { name: "Punjabi (Arabic)", direction: "rtl" },
  pa_Guru_IN: { name: "Punjabi (Gurmukhi, India)", direction: "ltr" },
  pa_Guru: { name: "Punjabi (Gurmukhi)", direction: "ltr" },
  pa_IN: { name: "Punjabi (India)", direction: "ltr" },
  pa_PK: { name: "Punjabi (Pakistan)", direction: "ltr" },
  qu: { name: "Quechua", direction: "ltr" },
  qu_BO: { name: "Quechua (Bolivia)", direction: "ltr" },
  qu_EC: { name: "Quechua (Ecuador)", direction: "ltr" },
  qu_PE: { name: "Quechua (Peru)", direction: "ltr" },
  ro: { name: "Romanian", direction: "ltr" },
  ro_MD: { name: "Romanian (Moldova)", direction: "ltr" },
  ro_RO: { name: "Romanian (Romania)", direction: "ltr" },
  rm: { name: "Romansh", direction: "ltr" },
  rm_CH: { name: "Romansh (Switzerland)", direction: "ltr" },
  rn: { name: "Rundi", direction: "ltr" },
  rn_BI: { name: "Rundi (Burundi)", direction: "ltr" },
  ru: { name: "Russian", direction: "ltr" },
  ru_BY: { name: "Russian (Belarus)", direction: "ltr" },
  ru_KZ: { name: "Russian (Kazakhstan)", direction: "ltr" },
  ru_KG: { name: "Russian (Kyrgyzstan)", direction: "ltr" },
  ru_MD: { name: "Russian (Moldova)", direction: "ltr" },
  ru_RU: { name: "Russian (Russia)", direction: "ltr" },
  ru_UA: { name: "Russian (Ukraine)", direction: "ltr" },
  sg: { name: "Sango", direction: "ltr" },
  sg_CF: { name: "Sango (Central African Republic)", direction: "ltr" },
  gd: { name: "Scottish Gaelic", direction: "ltr" },
  gd_GB: { name: "Scottish Gaelic (United Kingdom)", direction: "ltr" },
  sr: { name: "Serbian", direction: "ltr" },
  sr_BA: { name: "Serbian (Bosnia & Herzegovina)", direction: "ltr" },
  sr_Cyrl_BA: {
    name: "Serbian (Cyrillic, Bosnia & Herzegovina)",
    direction: "ltr"
  },
  sr_Cyrl_XK: { name: "Serbian (Cyrillic, Kosovo)", direction: "ltr" },
  sr_Cyrl_ME: { name: "Serbian (Cyrillic, Montenegro)", direction: "ltr" },
  sr_Cyrl_RS: { name: "Serbian (Cyrillic, Serbia)", direction: "ltr" },
  sr_Cyrl: { name: "Serbian (Cyrillic)", direction: "ltr" },
  sr_XK: { name: "Serbian (Kosovo)", direction: "ltr" },
  sr_Latn_BA: {
    name: "Serbian (Latin, Bosnia & Herzegovina)",
    direction: "ltr"
  },
  sr_Latn_XK: { name: "Serbian (Latin, Kosovo)", direction: "ltr" },
  sr_Latn_ME: { name: "Serbian (Latin, Montenegro)", direction: "ltr" },
  sr_Latn_RS: { name: "Serbian (Latin, Serbia)", direction: "ltr" },
  sr_Latn: { name: "Serbian (Latin)", direction: "ltr" },
  sr_ME: { name: "Serbian (Montenegro)", direction: "ltr" },
  sr_RS: { name: "Serbian (Serbia)", direction: "ltr" },
  sh: { name: "Serbo-Croatian", direction: "ltr" },
  sh_BA: { name: "Serbo-Croatian (Bosnia & Herzegovina)", direction: "ltr" },
  sn: { name: "Shona", direction: "ltr" },
  sn_ZW: { name: "Shona (Zimbabwe)", direction: "ltr" },
  ii: { name: "Sichuan Yi", direction: "ltr" },
  ii_CN: { name: "Sichuan Yi (China)", direction: "ltr" },
  si: { name: "Sinhala", direction: "ltr" },
  si_LK: { name: "Sinhala (Sri Lanka)", direction: "ltr" },
  sk: { name: "Slovak", direction: "ltr" },
  sk_SK: { name: "Slovak (Slovakia)", direction: "ltr" },
  sl: { name: "Slovenian", direction: "ltr" },
  sl_SI: { name: "Slovenian (Slovenia)", direction: "ltr" },
  so: { name: "Somali", direction: "ltr" },
  so_DJ: { name: "Somali (Djibouti)", direction: "ltr" },
  so_ET: { name: "Somali (Ethiopia)", direction: "ltr" },
  so_KE: { name: "Somali (Kenya)", direction: "ltr" },
  so_SO: { name: "Somali (Somalia)", direction: "ltr" },
  es: { name: "Spanish", direction: "ltr" },
  es_AR: { name: "Spanish (Argentina)", direction: "ltr" },
  es_BO: { name: "Spanish (Bolivia)", direction: "ltr" },
  es_IC: { name: "Spanish (Canary Islands)", direction: "ltr" },
  es_EA: { name: "Spanish (Ceuta & Melilla)", direction: "ltr" },
  es_CL: { name: "Spanish (Chile)", direction: "ltr" },
  es_CO: { name: "Spanish (Colombia)", direction: "ltr" },
  es_CR: { name: "Spanish (Costa Rica)", direction: "ltr" },
  es_CU: { name: "Spanish (Cuba)", direction: "ltr" },
  es_DO: { name: "Spanish (Dominican Republic)", direction: "ltr" },
  es_EC: { name: "Spanish (Ecuador)", direction: "ltr" },
  es_SV: { name: "Spanish (El Salvador)", direction: "ltr" },
  es_GQ: { name: "Spanish (Equatorial Guinea)", direction: "ltr" },
  es_GT: { name: "Spanish (Guatemala)", direction: "ltr" },
  es_HN: { name: "Spanish (Honduras)", direction: "ltr" },
  es_MX: { name: "Spanish (Mexico)", direction: "ltr" },
  es_NI: { name: "Spanish (Nicaragua)", direction: "ltr" },
  es_PA: { name: "Spanish (Panama)", direction: "ltr" },
  es_PY: { name: "Spanish (Paraguay)", direction: "ltr" },
  es_PE: { name: "Spanish (Peru)", direction: "ltr" },
  es_PH: { name: "Spanish (Philippines)", direction: "ltr" },
  es_PR: { name: "Spanish (Puerto Rico)", direction: "ltr" },
  es_ES: { name: "Spanish (Spain)", direction: "ltr" },
  es_US: { name: "Spanish (United States)", direction: "ltr" },
  es_UY: { name: "Spanish (Uruguay)", direction: "ltr" },
  es_VE: { name: "Spanish (Venezuela)", direction: "ltr" },
  sw: { name: "Swahili", direction: "ltr" },
  sw_KE: { name: "Swahili (Kenya)", direction: "ltr" },
  sw_TZ: { name: "Swahili (Tanzania)", direction: "ltr" },
  sw_UG: { name: "Swahili (Uganda)", direction: "ltr" },
  sv: { name: "Swedish", direction: "ltr" },
  sv_AX: { name: "Swedish (\xC5land Islands)", direction: "ltr" },
  sv_FI: { name: "Swedish (Finland)", direction: "ltr" },
  sv_SE: { name: "Swedish (Sweden)", direction: "ltr" },
  tl: { name: "Tagalog", direction: "ltr" },
  tl_PH: { name: "Tagalog (Philippines)", direction: "ltr" },
  ta: { name: "Tamil", direction: "ltr" },
  ta_IN: { name: "Tamil (India)", direction: "ltr" },
  ta_MY: { name: "Tamil (Malaysia)", direction: "ltr" },
  ta_SG: { name: "Tamil (Singapore)", direction: "ltr" },
  ta_LK: { name: "Tamil (Sri Lanka)", direction: "ltr" },
  te: { name: "Telugu", direction: "ltr" },
  te_IN: { name: "Telugu (India)", direction: "ltr" },
  th: { name: "Thai", direction: "ltr" },
  th_TH: { name: "Thai (Thailand)", direction: "ltr" },
  bo: { name: "Tibetan", direction: "ltr" },
  bo_CN: { name: "Tibetan (China)", direction: "ltr" },
  bo_IN: { name: "Tibetan (India)", direction: "ltr" },
  ti: { name: "Tigrinya", direction: "ltr" },
  ti_ER: { name: "Tigrinya (Eritrea)", direction: "ltr" },
  ti_ET: { name: "Tigrinya (Ethiopia)", direction: "ltr" },
  to: { name: "Tongan", direction: "ltr" },
  to_TO: { name: "Tongan (Tonga)", direction: "ltr" },
  tr: { name: "Turkish", direction: "ltr" },
  tr_CY: { name: "Turkish (Cyprus)", direction: "ltr" },
  tr_TR: { name: "Turkish (Turkey)", direction: "ltr" },
  uk: { name: "Ukrainian", direction: "ltr" },
  uk_UA: { name: "Ukrainian (Ukraine)", direction: "ltr" },
  ur: { name: "Urdu", direction: "rtl" },
  ur_IN: { name: "Urdu (India)", direction: "rtl" },
  ur_PK: { name: "Urdu (Pakistan)", direction: "rtl" },
  ug: { name: "Uyghur", direction: "ltr" },
  ug_Arab_CN: { name: "Uyghur (Arabic, China)", direction: "rtl" },
  ug_Arab: { name: "Uyghur (Arabic)", direction: "rtl" },
  ug_CN: { name: "Uyghur (China)", direction: "ltr" },
  uz: { name: "Uzbek", direction: "ltr" },
  uz_AF: { name: "Uzbek (Afghanistan)", direction: "ltr" },
  uz_Arab_AF: { name: "Uzbek (Arabic, Afghanistan)", direction: "rtl" },
  uz_Arab: { name: "Uzbek (Arabic)", direction: "rtl" },
  uz_Cyrl_UZ: { name: "Uzbek (Cyrillic, Uzbekistan)", direction: "ltr" },
  uz_Cyrl: { name: "Uzbek (Cyrillic)", direction: "ltr" },
  uz_Latn_UZ: { name: "Uzbek (Latin, Uzbekistan)", direction: "ltr" },
  uz_Latn: { name: "Uzbek (Latin)", direction: "ltr" },
  uz_UZ: { name: "Uzbek (Uzbekistan)", direction: "ltr" },
  vi: { name: "Vietname: se},", direction: "ltr" },
  vi_VN: { name: "Vietname: se (Vietnam)", direction: "ltr" },
  cy: { name: "Welsh", direction: "ltr" },
  cy_GB: { name: "Welsh (United Kingdom)", direction: "ltr" },
  fy: { name: "Western Frisian", direction: "ltr" },
  fy_NL: { name: "Western Frisian (Netherlands)", direction: "ltr" },
  yi: { name: "Yiddish", direction: "ltr" },
  yo: { name: "Yoruba", direction: "ltr" },
  yo_BJ: { name: "Yoruba (Benin)", direction: "ltr" },
  yo_NG: { name: "Yoruba (Nigeria)", direction: "ltr" },
  zu: { name: "Zulu", direction: "ltr" },
  zu_ZA: { name: "Zulu (South Africa)", direction: "ltr" }
};
export const getLanguageDirection = (lang) => {
  const currentLang = lang.includes("-") ? lang.replace("-", "_") : lang;
  return isoLanguageList[currentLang] && isoLanguageList[currentLang].direction;
};
