var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/posts.actions";
import { produce } from "immer";
import { FilterGroupTypes } from "../components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal";
import {
  ActionFilterKeyProps,
  actionFilterProps,
  ActionFilterValues
} from "../components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal-filters.data";
import { MAX_STAR_AMOUNT } from "../components/tint-editor/star-rating/star-rating";
import { SocialAccountType } from "../../../model/social-account-type.model";
import { SortBy } from "../components/tint-editor/top-bar/tint-editor-top-bar";
export const PostRenderType = {
  GRID: "grid",
  LIST: "list"
};
export const initialState = {
  data: [],
  filters: [],
  selectedFilters: void 0,
  sortPostsBy: { sortBy: SortBy.TIME_POSTED },
  languages: {},
  links: null,
  selectedPosts: [],
  pageSize: 20,
  currentPageStart: 1,
  currentPageEnd: 20,
  isFetching: false,
  isLastPage: false,
  isLoading: false,
  fetchingFeedId: void 0,
  error: void 0,
  pinnedId: void 0,
  renderType: PostRenderType.GRID
};
export const tintEditorPosts = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_RENDER_TYPE:
      return __spreadProps(__spreadValues({}, state), {
        renderType: action.payload
      });
    case ACTIONS.CLEAR_STATE:
      return initialState;
    case ACTIONS.RECOVER_POST.REQUEST:
    case ACTIONS.DELETE_POST.REQUEST:
    case ACTIONS.UPDATE_POST.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        isError: false,
        fetchingFeedId: action.payload
      });
    case ACTIONS.GET_POST_REQUEST_RIGHTS.REQUEST:
    case ACTIONS.GET_LAST_PAGE.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isError: false
      });
    case ACTIONS.DELETE_POSTS.REQUEST:
    case ACTIONS.POST_SIMILAR_IMAGE.REQUEST:
    case ACTIONS.REMOVE_SAVED_FILTERS.REQUEST:
    case ACTIONS.UPDATE_SAVED_FILTERS.REQUEST:
    case ACTIONS.POST_SAVED_FILTERS.REQUEST:
    case ACTIONS.GET_SAVED_FILTERS.REQUEST:
    case ACTIONS.GET_GLOBAL_POSTS.REQUEST:
    case ACTIONS.GET_POSTS.REQUEST:
    case ACTIONS.UPDATE_POSTS.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        isError: false
      });
    case ACTIONS.GET_POST_AGGREGATIONS.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b;
        const languageObj = action.payload.data.find((e) => e.id === "language");
        const imageObjectObj = action.payload.data.find((e) => e.id === "image_objects");
        draft.languages = ((_a = languageObj == null ? void 0 : languageObj.attributes) == null ? void 0 : _a.buckets) || {};
        draft.imageObjects = ((_b = imageObjectObj == null ? void 0 : imageObjectObj.attributes) == null ? void 0 : _b.buckets) || {};
      });
    case ACTIONS.GET_POST_REQUEST_RIGHTS.SUCCESS:
      return produce(state, (draft) => {
        const el = draft.data.find((post) => post.id === action.payload.id);
        el.rightRequests = action.payload.data;
      });
    case ACTIONS.GET_LAST_PAGE.SUCCESS:
      return produce(state, (draft) => {
        draft.isLastPage = action.payload;
      });
    case ACTIONS.OPEN_POST_TAG_LIST:
      return produce(state, (draftState) => {
        draftState.tagListModalPostId = action.payload;
      });
    case ACTIONS.GET_GLOBAL_POSTS.SUCCESS:
    case ACTIONS.POST_SIMILAR_IMAGE.SUCCESS:
    case ACTIONS.GET_POSTS.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
        const includedCTA = (_b = (_a = action.payload) == null ? void 0 : _a.included) == null ? void 0 : _b.filter((el) => el.type === "cta_association");
        const socialFeeds = (_d = (_c = action.payload) == null ? void 0 : _c.included) == null ? void 0 : _d.filter((data) => data.type === "social_feed");
        const uniquePosts = removePostDuplicates(action.payload.data, socialFeeds);
        draft.data = uniquePosts.map((post) => {
          var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2;
          return __spreadProps(__spreadValues({}, post), {
            cta: __spreadValues({}, (_c2 = (_b2 = (_a2 = post == null ? void 0 : post.relationships) == null ? void 0 : _a2.cta_associations) == null ? void 0 : _b2.data) == null ? void 0 : _c2.map(
              (ctaAssociations) => {
                var _a3, _b3, _c3;
                return (_c3 = (_b3 = (_a3 = includedCTA == null ? void 0 : includedCTA.find((includedCTA2) => includedCTA2.id === ctaAssociations.id)) == null ? void 0 : _a3.relationships) == null ? void 0 : _b3.cta) == null ? void 0 : _c3.data;
              }
            )),
            siblings: (_f2 = (_e2 = (_d2 = post == null ? void 0 : post.relationships) == null ? void 0 : _d2.siblings) == null ? void 0 : _e2.data) == null ? void 0 : _f2.map(
              (sibling) => {
                var _a3, _b3;
                return (_b3 = (_a3 = action.payload) == null ? void 0 : _a3.included) == null ? void 0 : _b3.find((e) => e.id === sibling.id);
              }
            ),
            replies: (_i2 = (_h2 = (_g2 = post == null ? void 0 : post.relationships) == null ? void 0 : _g2.replies) == null ? void 0 : _h2.data) == null ? void 0 : _i2.map((reply) => {
              var _a3, _b3, _c3, _d3;
              const foundReply = (_b3 = (_a3 = action.payload) == null ? void 0 : _a3.included) == null ? void 0 : _b3.filter((e) => e.type === "reply").find((e) => e.id === reply.id);
              const user = (_d3 = (_c3 = action.payload) == null ? void 0 : _c3.included) == null ? void 0 : _d3.find(
                (e) => {
                  var _a4, _b4, _c4;
                  return e.type === "user" && e.id === ((_c4 = (_b4 = (_a4 = foundReply == null ? void 0 : foundReply.relationships) == null ? void 0 : _a4.user) == null ? void 0 : _b4.data) == null ? void 0 : _c4.id);
                }
              );
              return __spreadProps(__spreadValues({}, foundReply), {
                user
              });
            })
          });
        });
        draft.tints = (_g = (_f = (_e = action.payload) == null ? void 0 : _e.included) == null ? void 0 : _f.filter((el) => el.type === "tint")) == null ? void 0 : _g.map((tint) => {
          var _a2;
          return { id: tint == null ? void 0 : tint.id, slugName: (_a2 = tint == null ? void 0 : tint.attributes) == null ? void 0 : _a2.name };
        });
        draft.links = action.payload.links;
        draft.currentPageStart = action.payload.pageStart;
        draft.currentPageEnd = action.payload.pageEnd;
        draft.selectedPosts = [];
        draft.isFetching = false;
        draft.isError = false;
        draft.products = (_i = (_h = action.payload) == null ? void 0 : _h.included) == null ? void 0 : _i.filter((data) => data.type === "product");
        draft.productTags = (_k = (_j = action.payload) == null ? void 0 : _j.included) == null ? void 0 : _k.filter((data) => data.type === "product_tag");
        draft.socialFeeds = socialFeeds;
      });
    case ACTIONS.DELETE_REPLY:
      return produce(state, (draft) => {
        const post = draft.data.find((post2) => post2.id === action.payload.postId);
        post.replies = post.replies.filter((reply) => reply.id !== action.payload.replyId);
        return draft;
      });
    case ACTIONS.ADD_POST_PRODUCT_TAG:
      return produce(state, (draft) => {
        var _a, _b;
        const currentPost = draft.data.find(
          (post) => post.attributes.external_id === action.payload.externalId && post.attributes.attachment_id === action.payload.data.attachmentId
        );
        (_b = (_a = currentPost == null ? void 0 : currentPost.relationships) == null ? void 0 : _a.product_tags) == null ? void 0 : _b.data.push(action.payload.data);
      });
    case ACTIONS.DELETE_POST_PRODUCT_TAG:
      return produce(state, (draft) => {
        var _a, _b;
        const currentPost = draft.data.find(
          (post) => post.attributes.external_id === action.payload.externalId && post.attributes.attachment_id === action.payload.attachmentId
        );
        currentPost.relationships.product_tags.data = (_b = (_a = currentPost == null ? void 0 : currentPost.relationships) == null ? void 0 : _a.product_tags) == null ? void 0 : _b.data.filter(
          (productTag) => productTag.id !== action.payload.rectId
        );
      });
    case ACTIONS.RECOVER_POST.FAILURE:
    case ACTIONS.DELETE_POSTS.FAILURE:
    case ACTIONS.DELETE_POST.FAILURE:
    case ACTIONS.UPDATE_POST.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isError: action.payload,
        isFetching: false,
        fetchingFeedId: void 0
      });
    case ACTIONS.GET_POST_REQUEST_RIGHTS.FAILURE:
    case ACTIONS.POST_SIMILAR_IMAGE.FAILURE:
    case ACTIONS.POST_SAVED_FILTERS.FAILURE:
    case ACTIONS.GET_SAVED_FILTERS.FAILURE:
    case ACTIONS.REMOVE_SAVED_FILTERS.FAILURE:
    case ACTIONS.GET_GLOBAL_POSTS.FAILURE:
    case ACTIONS.GET_POSTS.FAILURE:
    case ACTIONS.UPDATE_POSTS.FAILURE:
    case ACTIONS.GET_LAST_PAGE.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isError: action.payload,
        isFetching: false
      });
    case ACTIONS.UNPIN_TO_TOP:
      return produce(state, (draft) => {
        draft.fetchingFeedId = void 0;
        draft.pinnedId = void 0;
      });
    case ACTIONS.PIN_TO_TOP:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((post) => post.id !== action.payload.id);
        draft.data = [action.payload, ...draft.data];
        draft.fetchingFeedId = void 0;
        draft.pinnedId = action.payload.id;
      });
    case ACTIONS.RECOVER_POST.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((post) => post.id !== action.payload);
        draft.isFetching = false;
        draft.fetchingFeedId = void 0;
      });
    case ACTIONS.DELETE_POST.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((post) => post.id !== action.payload);
        draft.isFetching = false;
        draft.fetchingFeedId = void 0;
      });
    case ACTIONS.DELETE_POSTS.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((post) => action.payload.every((el) => el.id !== post.id));
        draft.isFetching = false;
        draft.fetchingFeedId = void 0;
      });
    case ACTIONS.UPDATE_POST.SUCCESS:
      return produce(state, (draft) => {
        const index = draft.data.findIndex((post) => post.id === action.payload.id);
        draft.data[index] = action.payload;
        draft.data.forEach((post) => {
          if (post.siblings) {
            post.siblings = post.siblings.map((sibling) => {
              if (sibling.id === action.payload.id) {
                return action.payload;
              }
              return sibling;
            });
          }
        });
        draft.isFetching = false;
        draft.fetchingFeedId = void 0;
      });
    case ACTIONS.UPDATE_POSTS.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.map((post) => {
          const updatedPost = action.payload.find((updatePost) => updatePost.id === post.id);
          return updatedPost ? updatedPost : post;
        });
        draft.data = draft.data.filter((e) => e.attributes.status !== "deleted");
        draft.selectedPosts = action.payload;
        draft.isFetching = false;
        draft.fetchingFeedId = void 0;
      });
    case ACTIONS.UPDATE_SELECTED_POSTS:
      return __spreadProps(__spreadValues({}, state), {
        selectedPosts: action.payload
      });
    case ACTIONS.UPDATE_PAGE_SIZE:
      return __spreadProps(__spreadValues({}, state), {
        pageSize: action.payload
      });
    case ACTIONS.SET_IS_LOADING:
      return __spreadProps(__spreadValues({}, state), {
        isLoading: action.payload
      });
    case ACTIONS.SELECT_FILTER:
      return produce(state, (draft) => {
        draft.selectedFilters = __spreadProps(__spreadValues({}, draft.selectedFilters), {
          [action.payload.type]: action.payload.type !== FilterGroupTypes.DATE ? action.payload.data.sort((a, b) => a > b ? 1 : -1) : action.payload.data
        });
      });
    case ACTIONS.CLEAR_SAVED_FILTERS:
      return produce(state, (draft) => {
        draft.filters = void 0;
      });
    case ACTIONS.SORT_POSTS_BY:
      return produce(state, (draft) => {
        draft.sortPostsBy = __spreadProps(__spreadValues({}, draft == null ? void 0 : draft.sortPostsBy), {
          [action.payload.type]: action.payload.data
        });
        draft.sortPostsByVisualSearch = void 0;
      });
    case ACTIONS.SORT_POSTS_BY_VISUAL_SEARCH:
      return produce(state, (draft) => {
        draft.sortPostsByVisualSearch = action.payload;
      });
    case ACTIONS.CHOOSE_SAVED_FILTER:
      return produce(state, (draft) => {
        const selectedFilters = mapSelectedFilters(action.payload.filters);
        draft.selectedFilters = selectedFilters;
        draft.filterId = action.payload.id;
      });
    case ACTIONS.RESET_FILTERS:
      return produce(state, (draft) => {
        draft.selectedFilters = void 0;
        draft.sortPostsBy = __spreadValues({}, initialState == null ? void 0 : initialState.sortPostsBy);
        draft.filterId = void 0;
      });
    case ACTIONS.GET_SAVED_FILTERS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        filters: action.payload,
        isFetching: false
      });
    case ACTIONS.REMOVE_SAVED_FILTERS.SUCCESS:
      return produce(state, (draft) => {
        draft.filters = draft.filters.filter((filter) => filter.id !== action.payload);
        draft.isFetching = false;
      });
    case ACTIONS.UPDATE_SAVED_FILTERS.SUCCESS:
      return produce(state, (draft) => {
        draft.filters = draft.filters.map((filter) => filter.id === action.payload.id ? action.payload : filter);
        draft.isFetching = false;
      });
    case ACTIONS.POST_SAVED_FILTERS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        filters: [...state.filters, action.payload],
        isFetching: false
      });
    default:
      return state;
  }
};
const removePostDuplicates = (data, socialFeeds = []) => {
  const existObject = {};
  const socialFeedsObj = {};
  socialFeeds == null ? void 0 : socialFeeds.map((e) => {
    socialFeedsObj[e.id] = e;
  });
  return data.filter((e) => {
    const post = e.attributes;
    const socialFeedId = e.relationships.social_feed.data.id;
    const source = socialFeedsObj[socialFeedId].attributes.source;
    let key = (post == null ? void 0 : post.external_id) + (post == null ? void 0 : post.attachment_id) + (post == null ? void 0 : post.source);
    switch (source) {
      case SocialAccountType.FORM_SUBMISSION:
      case SocialAccountType.TUMBLR:
      case SocialAccountType.LINKED_IN:
        key += post == null ? void 0 : post.image_url;
        break;
      case SocialAccountType.INSTAGRAM:
      case SocialAccountType.INSTAGRAM_BUSINESS:
        key += post == null ? void 0 : post.url;
        break;
    }
    if (existObject[key]) {
      return false;
    }
    existObject[key] = true;
    return true;
  });
};
const mapSelectedFilters = (selectedFilters) => {
  const mapActionsFilters = Object.keys(selectedFilters).map((key) => {
    if (actionFilterProps.includes(key)) {
      if (key === ActionFilterKeyProps.RIGHT_REQUEST_STATUS) {
        return selectedFilters[key] !== void 0 ? selectedFilters[key] : void 0;
      } else if (key === ActionFilterValues.PINNED || key === ActionFilterValues.NOT_PINNED || key === ActionFilterValues.HIGHLIGHTED || key === ActionFilterValues.NOT_HIGHLIGHTED || key === ActionFilterValues.INCENTIVIZED || key === ActionFilterValues.NOT_INCENTIVIZED) {
        return selectedFilters[key] !== void 0 ? selectedFilters[key] ? key : `not-${key}` : void 0;
      }
    } else if (key === ActionFilterKeyProps.RATING) {
      return selectedFilters[key] !== void 0 ? selectedFilters[key]["$range"] ? selectedFilters[key]["$range"].split(",") : void 0 : void 0;
    }
  }).filter(Boolean).flat().sort((a, b) => a > b ? 1 : -1);
  return [
    ...Object.keys(selectedFilters).map((key) => {
      if (!actionFilterProps.includes(key)) {
        return {
          [key]: Object.keys(selectedFilters[key]).length > 0 || selectedFilters[key].length > 0 ? selectedFilters[key] : void 0
        };
      }
    }),
    { [FilterGroupTypes.ACTIONS]: mapActionsFilters }
  ].filter(Boolean).reduce((a, b) => __spreadValues(__spreadValues({}, a), b));
};
export const checkFilterChanges = (state) => {
  var _a;
  const currentFilter = (_a = state == null ? void 0 : state.filters) == null ? void 0 : _a.find((filter) => filter.id === state.filterId);
  return currentFilter && JSON.stringify(mapSelectedFilters(currentFilter.attributes.filter)) !== JSON.stringify(state.selectedFilters);
};
export const getCurrentSelectedFilter = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.filters) == null ? void 0 : _a.find((filter) => filter.id === state.filterId);
};
export const getMappedSavedFilters = (state) => {
  var _a, _b;
  return ((_b = (_a = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a.filters) == null ? void 0 : _b.map((filter) => ({
    id: filter.id,
    name: filter.attributes.name,
    filters: filter.attributes.filter
  }))) || [];
};
export const isSocialFeedPostFiltered = (state) => {
  var _a;
  return state && ((_a = state.sortPostsBy) == null ? void 0 : _a.social_feed_id);
};
export const isVisualSearchUsed = (state) => {
  var _a;
  return state && ((_a = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a.sortPostsByVisualSearch);
};
export const getPost = (state, id) => {
  if (!state || !id) return;
  let currentPost = state.data.find((post) => post.id === id);
  if (!currentPost) {
    const sibling = state.data.reduce((foundSibling, parent) => {
      const sibling2 = parent.siblings.find((s) => s.id === id);
      if (sibling2) {
        return sibling2;
      }
      return foundSibling;
    }, void 0);
    currentPost = sibling;
  }
  return currentPost;
};
export const getProducts = (state, id) => {
  var _a, _b, _c;
  if (!state || !id) return;
  let currentPost = state == null ? void 0 : state.data.find((post) => post.id === id);
  if (!currentPost) {
    const sibling = state.data.reduce((foundSibling, parent) => {
      const sibling2 = parent.siblings.find((s) => s.id === id);
      if (sibling2) {
        return sibling2;
      }
      return foundSibling;
    }, void 0);
    currentPost = sibling;
  }
  const mappedProductsTags = state.productTags && state.productTags.map((productTag) => ({
    id: productTag.id,
    product: state.products.find((product) => product.id === productTag.relationships.product.data.id)
  })) || [];
  const currentPostProducts = (_c = (_b = (_a = currentPost == null ? void 0 : currentPost.relationships) == null ? void 0 : _a.product_tags) == null ? void 0 : _b.data) == null ? void 0 : _c.map((productTag) => {
    return mappedProductsTags.find((product) => product.id === productTag.id);
  }).filter((el) => el == null ? void 0 : el.product.id).filter(Boolean);
  const products = currentPostProducts && currentPostProducts.map((el) => __spreadValues({}, el.product));
  return products;
};
export const getSocialFeed = (state, id) => {
  var _a;
  if (!state || !id) return;
  let currentPost = state == null ? void 0 : state.data.find((post) => post.id === id);
  if (!currentPost) {
    currentPost = state.data.reduce((foundSibling, parent) => {
      const sibling = parent.siblings.find((s) => s.id === id);
      if (sibling) {
        return sibling;
      }
      return foundSibling;
    }, void 0);
  }
  const currentPostSocialFeed = (_a = state == null ? void 0 : state.socialFeeds) == null ? void 0 : _a.find(
    (socialFeed) => {
      var _a2, _b, _c;
      return socialFeed.id === ((_c = (_b = (_a2 = currentPost == null ? void 0 : currentPost.relationships) == null ? void 0 : _a2.social_feed) == null ? void 0 : _b.data) == null ? void 0 : _c.id);
    }
  );
  return currentPostSocialFeed;
};
export const getNextPost = (state, id) => {
  if (!state && !state.data) return;
  const indexOfElement = state.data.findIndex((post) => post.id === id);
  if (state.data[indexOfElement] && state.data[indexOfElement].siblings && state.data[indexOfElement].siblings.length > 0) {
    return state.data[indexOfElement].siblings[0];
  }
  if (indexOfElement === -1) {
    for (let i = 0; i < state.data.length; i++) {
      if (state.data[i].siblings && !(state.data[i].siblings.length > 0)) {
        continue;
      }
      const indexOfSibling = state.data[i].siblings.findIndex((sibling) => sibling.id === id);
      if (indexOfSibling !== -1) {
        if (state.data[i].siblings.length > indexOfSibling + 1) {
          return state.data[i].siblings[indexOfSibling + 1];
        }
        if (state.data.length > i + 1) {
          return state.data[i + 1];
        }
        return state.data[0];
      }
    }
  }
  return state.data.length > indexOfElement + 1 ? state.data[indexOfElement + 1] : state.data[0];
};
export const getPrevPost = (state, id) => {
  if (!state && !state.data) return;
  const indexOfElement = state.data.findIndex((post) => post.id === id);
  if (state.data[indexOfElement] && state.data[indexOfElement].siblings && state.data[indexOfElement].siblings.length > 0) {
    return state.data[indexOfElement].siblings[state.data[indexOfElement].siblings.length - 1];
  }
  if (indexOfElement === -1) {
    for (let i = 0; i < state.data.length; i++) {
      if (state.data[i].siblings && !(state.data[i].siblings.length > 0)) {
        continue;
      }
      const indexOfSibling = state.data[i].siblings.findIndex((sibling) => sibling.id === id);
      if (indexOfSibling !== -1) {
        if (indexOfSibling > 0) {
          return state.data[i].siblings[indexOfSibling - 1];
        }
        if (i > 0) {
          return state.data[i - 1];
        }
        return state.data[state.data.length - 1];
      }
    }
  }
  return indexOfElement > 0 ? state.data[indexOfElement - 1] : state.data[state.data.length - 1];
};
export const getActiveStars = (value) => {
  if (!value) return 0;
  return Math.ceil(parseInt(value) * MAX_STAR_AMOUNT / 100);
};
export const getTintName = (state, tintId) => {
  var _a, _b, _c;
  return state && ((_c = (_b = (_a = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a.tints) == null ? void 0 : _b.find((tint) => tint.id === tintId)) == null ? void 0 : _c.slugName);
};
export const getVisualSearchImage = (state) => {
  var _a, _b, _c;
  return state && ((_c = (_b = (_a = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a.selectedFilters) == null ? void 0 : _b.visually_similar_to) == null ? void 0 : _c[0]);
};
export const isTagsListModalPostOpen = (state) => {
  var _a;
  return state && ((_a = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a.tagListModalPostId) !== void 0;
};
