var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./edit-modal-switcher.sass";
export class EditModalSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContentVisible: true
    };
  }
  setContentVisibility(isContentVisible) {
    if (this.state.isContentVisible === isContentVisible) {
      return void 0;
    }
    this.setState({ isContentVisible });
    this.props.modalSwitch && this.props.modalSwitch();
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-switcher" }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-switcher__button-container" }, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: `${this.state.isContentVisible ? "active" : ""}`,
        onClick: () => {
          this.setContentVisibility(true);
        }
      },
      /* @__PURE__ */ React.createElement("span", null, this.props.leftButtonText || "Content")
    ), /* @__PURE__ */ React.createElement(
      "button",
      {
        className: `${!this.state.isContentVisible ? "active" : ""}`,
        onClick: () => {
          this.setContentVisibility(false);
        }
      },
      /* @__PURE__ */ React.createElement("span", null, this.props.rightButtonText || "Design")
    )), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `${this.state.isContentVisible ? "" : "tint-edit-switcher__invisible"} tint-edit-switcher__content`
      },
      React.cloneElement(this.props.children[0], __spreadValues({}, this.props))
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `${!this.state.isContentVisible ? "" : "tint-edit-switcher__invisible"} tint-edit-switcher__design`
      },
      React.cloneElement(this.props.children[1], __spreadValues({}, this.props))
    ));
  }
}
EditModalSwitcher.propTypes = {
  modalSwitch: PropTypes.func,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string
};
