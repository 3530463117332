import { Config } from "../../../../utils/config";
const filtersParam = (filterValue) => filterValue !== void 0 && filterValue !== "" ? `&filter[q]=${filterValue}` : "";
const statusParam = (status) => status !== void 0 && status !== "" ? `&filter[status]=${status}` : "";
const privateParam = (isPrivate) => isPrivate !== void 0 && isPrivate !== "" ? `&filter[private]=${isPrivate}` : "";
const includeParam = (value) => value ? `&include=${value}` : "";
export const getTintsUrlWithParams = ({
  url = "/tints",
  include = void 0,
  sort = "status,-id",
  pageSize = Config.defaultTintsPageSize,
  filterValue = void 0,
  status = void 0,
  isPrivate = void 0
} = {}) => {
  return `${url}?${includeParam(include)}&sort=${sort}&page[size]=${pageSize}${filtersParam(filterValue)}${statusParam(
    status
  )}${privateParam(isPrivate)}`;
};
export const sortTintsByStatus = (a, b) => {
  var _a, _b;
  const statusA = ((_a = a.attributes.status) == null ? void 0 : _a.toUpperCase()) || "INACTIVE";
  const statusB = ((_b = b.attributes.status) == null ? void 0 : _b.toUpperCase()) || "INACTIVE";
  if (statusA > statusB) {
    return 1;
  } else if (statusA < statusB) {
    return -1;
  }
  return 0;
};
