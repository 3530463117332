import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { pinterestDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { validationSchemaPinterest } from "../utils/validation-schema/pinterest-schema";
const dropdownProfileDefaultValue = {
  value: "board",
  name: "Select board"
};
const clearSubAccountsDropdown = (formik) => formik.setFieldValue("profile", dropdownProfileDefaultValue);
export const pinterest = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "pinterest",
    selectedOption: { id: "board", title: "Add Pinterest" },
    header: "Add Pinterest",
    description: "To ingest pins from Pinterest, select your Pinterest account and a board:",
    formSchema: {
      board: {
        validationSchema: validationSchemaPinterest(),
        submitMapper: (values) => {
          return {
            account_id: values.account.value,
            external_id: values.board.value
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            clearSubAccountsDropdown
          },
          {
            id: "board",
            backendId: "external_id",
            label: "SELECT BOARD",
            placeholder: "Select Board...",
            icon: SidebarIcons.GRID,
            type: SidebarItemTypes.SELECT,
            disabled: pinterestDisabledButton
          }
        ]
      }
    }
  }
];
