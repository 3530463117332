var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { Button } from "../../../../../../../components/interface/button/button";
import { FileStack } from "../../../../../../../services/filestack/file-stack";
import "./edit-media-content.sass";
import { pageSectionType } from "../../../../../../../model/page-section-type.model";
import { EditInput } from "../../../index";
export class EditMediaContent extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "onUploadClick", () => {
      const mediaType = this.props.data.content.mediaType;
      new FileStack().openFilePicker({
        maxSize: this.props.maxVideoFileSize,
        accept: [this.isImageType(mediaType) ? "image/*" : "video/*"],
        onUploadDone: (res) => {
          if (res.filesUploaded.length > 0) {
            this.props.onContentUpdate({
              mediaUrl: res.filesUploaded[0].url,
              mediaName: res.filesUploaded[0].filename
            });
          }
        }
      });
    });
    __publicField(this, "renderViewWithImage", () => {
      return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-header-content__center" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "tint-edit-upload-design__image",
          style: {
            backgroundImage: `url(${this.props.data.content.mediaUrl})`
          }
        }
      ));
    });
    __publicField(this, "renderVideoEdit", () => {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (event) => {
            this.props.onContentUpdate({
              mediaUrl: event.target.value,
              mediaName: ""
            });
          },
          label: "or paste Video url",
          placeholder: "Video url",
          value: this.props.data.content.mediaUrl
        }
      ), /* @__PURE__ */ React.createElement("span", { className: "tint-edit-media__input-description" }, "Supported: Facebook, YouTube, Vimeo, Twitch, TikTok"));
    });
    __publicField(this, "determineMediaType", () => {
      const mediaUrl = this.props.data.content.mediaUrl;
      const mediaType = this.props.data.content.mediaType;
      return mediaUrl === void 0 ? null : this.isImageType(mediaType) ? this.renderViewWithImage() : this.renderVideoEdit();
    });
    __publicField(this, "isImageType", (mediaType) => mediaType === pageSectionType.IMAGE || mediaType === pageSectionType.IMAGE.toUpperCase());
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Edit Media", className: "edit-media" }, /* @__PURE__ */ React.createElement(
      "form",
      {
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: this.onUploadClick,
          type: "gray",
          size: "full-width",
          text: "Upload media",
          icon: "far fa-arrow-to-top",
          className: "tint-edit-answer__upload-button"
        }
      ),
      this.determineMediaType(),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit")
    ));
  }
}
