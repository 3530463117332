import React, { useState } from "react";
import "./image.sass";
import TintIcon from "../../../../../../components/interface/icon/icon";
import { faImageSlash } from "@fortawesome/pro-solid-svg-icons";
export const ImageOrientationType = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  SQUARE: "square"
};
const ImageStatus = {
  LOADED: "loaded",
  LOADING: "loading",
  FAILED: "failed"
};
const TintModalImage = ({ src, alt, onLoad }) => {
  const [imageStatus, setImageStatus] = useState(src ? ImageStatus.LOADING : ImageStatus.FAILED);
  const [imageOrientation, setImageOrientation] = useState(ImageOrientationType.HORIZONTAL);
  const getImageOrientation = (e) => {
    return e.target.naturalWidth > e.target.naturalHeight ? ImageOrientationType.HORIZONTAL : e.target.naturalWidth < e.target.naturalHeight ? ImageOrientationType.VERTICAL : ImageOrientationType.SQUARE;
  };
  const handleImageStatus = (e) => {
    setImageStatus(ImageStatus.LOADED);
    setImageOrientation(getImageOrientation(e));
    onLoad == null ? void 0 : onLoad();
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-modal-image tint-modal-image--${imageOrientation}
        ${imageStatus === ImageStatus.LOADED || imageStatus === ImageStatus.FAILED ? `tint-modal-image--${imageStatus}` : `tint-modal-image--${ImageStatus.LOADING}`}
    `
    },
    !src || src === "" ? /* @__PURE__ */ React.createElement(TintIcon, { icon: faImageSlash, color: "#fff", fontSize: "48px" }) : /* @__PURE__ */ React.createElement(
      "img",
      {
        src,
        alt,
        onLoad: handleImageStatus,
        onError: () => {
          setImageStatus(ImageStatus.FAILED);
        }
      }
    )
  );
};
export default React.memo(TintModalImage);
