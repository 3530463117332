var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component, Fragment } from "react";
export const ClickOutsideHOC = (PassedComponent) => {
  class ClickOutside extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "hideMenu", () => {
        this.props.onMenuClose && this.props.onMenuClose();
        this.setState({ isMenuElementVisible: false });
      });
      this.state = {
        isMenuElementVisible: false
      };
      this.mouseDownFunction = this.handleClick.bind(this);
      this.menuContainerNode = {};
    }
    componentWillMount() {
      document.addEventListener("mousedown", this.mouseDownFunction);
    }
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.mouseDownFunction);
    }
    handleClick(e) {
      if (this.menuContainerNode.contains(e.target)) {
        return;
      }
      this.props.onMenuClose && this.props.onMenuClose();
      this.setState({ isMenuElementVisible: false });
    }
    displayMenu() {
      this.props.onMenuOpen && this.props.onMenuOpen();
      this.setState({ isMenuElementVisible: true });
    }
    onSetMenuNode(menuNode) {
      if (menuNode) {
        this.menuContainerNode = menuNode;
      }
    }
    render() {
      return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
        PassedComponent,
        __spreadProps(__spreadValues({}, this.props), {
          isMenuElementVisible: this.state.isMenuElementVisible,
          setMenuNode: this.onSetMenuNode.bind(this),
          displayMenu: this.displayMenu.bind(this),
          hideMenu: this.hideMenu
        })
      ));
    }
  }
  return ClickOutside;
};
