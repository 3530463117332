var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useRef, useEffect, Fragment } from "react";
import "./social-feed-card.sass";
import Button from "../../../../components/button";
import Tooltip from "../../../transform-ui/components/tooltip/tooltip";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import DropdownLinkList from "../../../../components/interface/dropdown/lists/link-list/dropdown-link-list";
import ButtonContentIcon from "../../../../components/interface/button-content-icon/button-content-icon";
import InputPrimary from "../../../../components/interface/inputs/input-primary/input-primary";
import TintIcon from "../../../../components/interface/icon/icon";
import {
  faPlay,
  faCheck,
  faTimes,
  faPause,
  faLock,
  faUnlock,
  faGear,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { SocialFeedsSource } from "../../../../types/social-feeds";
import ConfirmModal, { ConfirmModalResult } from "../../../../components/confirm-modal/confirm-modal";
import {
  deleteSocialFeed,
  patchSocialFeed
} from "../tint-editor/sidebars/social-feed-sidebar/actions/social-feeds.fat-actions";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../../../services/functions/error-handler/error-handler";
import { getSession } from "../../../../actions/session";
import { toast } from "react-toastify";
import useSocialFeedTranslation from "../../../../components/hooks/use-social-feed-names/use-feature-accessibility";
import { Typography } from "../../../../components/interface/typography/typography";
import { getSocialFeedSource } from "../../selectors/tint-editor.selector";
import { openSidebar } from "../../../../actions/ui/ui.actions";
export const socialFeedSidebar = "socialFeedSidebar";
const settingType = {
  play: "PLAY",
  pause: "PAUSE",
  warning: "WARNING",
  moderationLock: "MODERATION-LOCK",
  moderationUnlock: "MODERATION-UNLOCK",
  more: "MORE"
};
const StatusType = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "token_requested",
  STREAMED: "streamed"
};
const SocialFeedCard = ({ feed, onSocialFeedClick }) => {
  var _a, _b, _c, _d, _e;
  const [isEditSocialFeedName, setIsEditSocialFeedName] = useState(false);
  const [feedName, setFeedName] = useState(feed.attributes.name);
  const inputRef = useRef(null);
  const [cbFn, setCbFn] = useState();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState();
  const [confirmModalData, setConfirmModalData] = useState();
  const isPendingFeed = feed.attributes.status === StatusType.PENDING;
  const currentSocialFeedSource = getSocialFeedSource(feed);
  const dispatch = useDispatch();
  const editableTwitterTypes = {
    hashtag: "hashtag",
    geocode: "geocode"
  };
  const isPublicPostSocialFeed = feed.attributes.source === SocialFeedsSource.PUBLIC_POST;
  const isChangeSettingAvailable = isPublicPostSocialFeed || feed.attributes.source === SocialFeedsSource.VESTA || feed.attributes.source === SocialFeedsSource.TWITTER && (feed.attributes.type === editableTwitterTypes.hashtag || feed.attributes.type === editableTwitterTypes.geocode);
  const isEditSocialFeedNameAvailable = !(feed.attributes.source === SocialFeedsSource.EMAIL || feed.attributes.source === SocialFeedsSource.CUSTOM);
  const { getSocialFeedName } = useSocialFeedTranslation();
  const cardSettings = [
    {
      name: settingType.play,
      icon: faPlay,
      isActive: feed.attributes.status === StatusType.INACTIVE,
      action: () => {
        onUpdate(feed.id, {
          status: feed.attributes.status === StatusType.ACTIVE ? StatusType.INACTIVE : StatusType.ACTIVE
        });
      },
      tooltipMessage: "Unpause Feed"
    },
    {
      name: settingType.warning,
      color: "#FBAA22",
      icon: faExclamationCircle,
      isActive: feed.attributes.status === "error" || feed.attributes.status === "deprecated" || feed.attributes.status === "unknown",
      action: null,
      tooltipMessage: "There is a problem with this social feed, please contact us for details."
    },
    {
      name: settingType.pause,
      icon: faPause,
      isActive: feed.attributes.status === StatusType.ACTIVE || feed.attributes.status === StatusType.STREAMED,
      action: () => {
        onUpdate(feed.id, {
          status: feed.attributes.status === StatusType.ACTIVE ? StatusType.INACTIVE : StatusType.ACTIVE
        });
      },
      tooltipMessage: "Pause Feed",
      shouldOpenModal: feed.attributes.status === StatusType.ACTIVE,
      modalDescription: "All content on this social feed will be paused and no new content will be ingested until its is un-paused",
      leftButtonText: "No, Keep Feed Active",
      rightButtonText: "Yes, Pause Feed"
    },
    {
      name: settingType.moderationLock,
      icon: faLock,
      color: isPendingFeed ? "#838e94" : "#ff564b",
      isActive: feed.attributes.moderated,
      action: () => {
        onUpdate(feed.id, { moderated: !feed.attributes.moderated });
      },
      tooltipMessage: "Remove Moderation",
      shouldOpenModal: true,
      modalDescription: "By unlocking this feed, the content will now be unmoderated, and will be marked as visible as to the public automatically.",
      leftButtonText: "No, Keep Feed Locked",
      rightButtonText: "Yes, Unlock Feed"
    },
    {
      name: settingType.moderationUnlock,
      icon: faUnlock,
      color: "#838E94",
      isActive: !feed.attributes.moderated,
      action: () => {
        onUpdate(feed.id, { moderated: !feed.attributes.moderated });
      },
      tooltipMessage: "Add Moderation",
      shouldOpenModal: false
    },
    {
      name: settingType.more,
      icon: faGear,
      isActive: true,
      tooltipMessage: null,
      shouldOpenModal: false
    }
  ];
  useEffect(() => {
    if (isEditSocialFeedName) {
      inputRef && inputRef.current.focus();
    }
  }, [isEditSocialFeedName]);
  const moreSettingsList = [
    ...isEditSocialFeedNameAvailable ? [
      {
        name: "Edit name",
        value: "",
        action: () => {
          setIsEditSocialFeedName(true);
        },
        shouldOpenModal: false
      }
    ] : [],
    ...isChangeSettingAvailable ? [
      {
        name: "Change Settings",
        value: "",
        action: () => dispatch(
          openSidebar(socialFeedSidebar, { editFeedData: feed, socialFeedSidebar: currentSocialFeedSource })
        ),
        shouldOpenModal: false
      }
    ] : [],
    {
      name: feed.attributes.status === StatusType.ACTIVE ? "Pause Feed" : "Unpause Feed",
      value: "",
      action: () => {
        onUpdate(feed.id, {
          status: feed.attributes.status === StatusType.ACTIVE ? StatusType.INACTIVE : StatusType.ACTIVE
        });
      },
      shouldOpenModal: feed.attributes.status === StatusType.ACTIVE,
      modalDescription: "All content on this social feed will be paused and no new content will be ingested until its is un-paused",
      leftButtonText: "No, Keep Feed Active",
      rightButtonText: "Yes, Pause Feed"
    },
    {
      name: "Remove Feed",
      value: "",
      action: () => onRemoveSocialFeed(feed.id),
      shouldOpenModal: true,
      modalDescription: "All content on this social feed will be deleted and cannot be recovered. Re-adding the exact feed will not recover these posts",
      leftButtonText: "No, Keep Feed",
      rightButtonText: "Yes, Delete Feed"
    },
    ...isPublicPostSocialFeed && !((_b = (_a = feed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.form_id) ? [
      {
        name: "Upgrade",
        value: "",
        action: () => dispatch(
          openSidebar(socialFeedSidebar, {
            isUpgradeMode: true,
            editFeedData: feed,
            socialFeedSidebar: currentSocialFeedSource
          })
        ),
        shouldOpenModal: false
      }
    ] : []
  ];
  const onSelect = (selectedValue) => {
    var _a2;
    if (selectedValue.shouldOpenModal) {
      setIsConfirmModalVisible(true);
      setConfirmModalData(selectedValue);
      setCbFn(() => selectedValue.action);
    } else {
      (_a2 = selectedValue == null ? void 0 : selectedValue.action) == null ? void 0 : _a2.call(selectedValue);
    }
  };
  const renderIconButton = (setting) => {
    const iconComponent = /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `social-feed-card__icon social-feed-card__icon--${setting.icon.iconName}`,
        onClick: (e) => {
          if (setting.name !== settingType.more) {
            e.stopPropagation();
          }
          onSelect(setting);
        }
      },
      /* @__PURE__ */ React.createElement(TintIcon, { icon: setting.icon, color: setting.color, fontSize: "16px" })
    );
    if (setting.tooltipMessage !== null) {
      return /* @__PURE__ */ React.createElement(
        Tooltip,
        {
          style: { top: setting.name === settingType.warning ? "-84px" : "-3.5em", left: "-5em", fontSize: "12px" },
          text: setting.tooltipMessage,
          component: iconComponent
        }
      );
    } else if (setting.name === settingType.more) {
      return /* @__PURE__ */ React.createElement(
        DropdownButton,
        {
          dropdownHeader: () => iconComponent,
          dropdownList: (p) => /* @__PURE__ */ React.createElement(DropdownLinkList, __spreadValues({}, p)),
          list: moreSettingsList,
          buttonType: ButtonType.BUTTON_NO_STYL,
          onChangeValue: (selectedItem) => {
            onSelect(selectedItem);
          }
        }
      );
    }
    return /* @__PURE__ */ React.createElement("span", { className: `${setting.icon} social-feed-card__icon`, onClick: setting.action });
  };
  const onUpdate = (socialFeedId, data) => {
    dispatch(
      patchSocialFeed({
        socialFeedId,
        payload: data
      })
    ).catch((err) => handleApiError(err, toast.error));
  };
  const onRemoveSocialFeed = (id) => {
    dispatch(deleteSocialFeed(id)).then(() => {
      dispatch(getSession({}));
    }).catch((err) => handleApiError(err, toast.error));
  };
  const onModalChange = (type, fn) => {
    switch (type) {
      case ConfirmModalResult.CONFIRM:
        fn();
        setIsConfirmModalVisible(true);
        break;
      case ConfirmModalResult.REJECT:
        setIsConfirmModalVisible(false);
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `social-feed-card ${isPendingFeed ? "social-feed-card__pending" : ""}`,
      onClick: () => onSocialFeedClick({ id: feed.id, isModerated: feed.attributes.moderated })
    },
    /* @__PURE__ */ React.createElement("div", { className: "social-feed-card__header" }, /* @__PURE__ */ React.createElement("div", { className: "social-feed-card__source" }, /* @__PURE__ */ React.createElement(
      ButtonContentIcon,
      {
        source: currentSocialFeedSource,
        fontSize: 12,
        circleSize: 24,
        svgIconSize: 16,
        isDisabled: isPendingFeed
      }
    ), isPendingFeed && /* @__PURE__ */ React.createElement("span", { className: "social-feed-card__status" }, "PENDING")), /* @__PURE__ */ React.createElement("div", { className: "social-feed-card__settings" }, cardSettings.filter((setting) => setting.isActive).map((setting, index) => /* @__PURE__ */ React.createElement(Fragment, { key: index }, " ", renderIconButton(setting), " ")))),
    /* @__PURE__ */ React.createElement("div", { className: "social-feed-card__content" }, !isEditSocialFeedName ? /* @__PURE__ */ React.createElement("p", { className: "social-feed-card__name" }, feed.attributes.name) : /* @__PURE__ */ React.createElement("div", { className: "social-feed-card__edit-name" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        ref: inputRef,
        handleChange: (e) => {
          setFeedName(e.target.value);
        },
        defaultValue: feed.attributes.name
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "social-feed-card__input-edit-buttons" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        className: "social-feed-card__input-edit-buttons--approve",
        onClick: () => {
          setIsEditSocialFeedName(false);
          onUpdate(feed.id, { name: feedName });
        }
      },
      /* @__PURE__ */ React.createElement(TintIcon, { icon: faCheck })
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        className: "social-feed-card__input-edit-buttons--cancel",
        onClick: () => setIsEditSocialFeedName(false)
      },
      /* @__PURE__ */ React.createElement(TintIcon, { icon: faTimes })
    ))), /* @__PURE__ */ React.createElement(Typography, { component: "p" }, (_e = getSocialFeedName((_c = feed.attributes) == null ? void 0 : _c.source, (_d = feed.attributes) == null ? void 0 : _d.type)) == null ? void 0 : _e.type))
  ), isConfirmModalVisible && /* @__PURE__ */ React.createElement(
    ConfirmModal,
    {
      confirmButtonType: "error",
      fn: cbFn,
      description: confirmModalData == null ? void 0 : confirmModalData.modalDescription,
      rightButtonText: confirmModalData == null ? void 0 : confirmModalData.rightButtonText,
      leftButtonText: confirmModalData == null ? void 0 : confirmModalData.leftButtonText,
      onChange: onModalChange
    }
  ));
};
export default React.memo(SocialFeedCard);
