import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
const ADD_PRODUCT = "ADD_PRODUCT";
export const ACTIONS = {
  OPEN_MODAL: createPrefixedActionName(ADD_PRODUCT, "OPEN_MODAL"),
  CLOSE_MODAL: createPrefixedActionName(ADD_PRODUCT, "CLOSE_MODAL")
};
export const openAddProductModal = (data) => ({
  type: ACTIONS.OPEN_MODAL,
  payload: data
});
export const closeAddProductModal = () => ({
  type: ACTIONS.CLOSE_MODAL
});
