import React from "react";
import "./modal-footer.sass";
import { Button } from "../../../../interface/button/button";
const ModalFooter = ({
  closeButtonText = "Cancel",
  confirmButtonText = "Ok",
  onCloseClick,
  onConfirm,
  isDisabled,
  confirmButtonType
}) => {
  return /* @__PURE__ */ React.createElement("footer", { className: "tint-modal-footer" }, /* @__PURE__ */ React.createElement(Button, { onClick: onCloseClick, action: "submit", type: "gray", text: closeButtonText }), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: onConfirm,
      isDisabled,
      action: "submit",
      type: confirmButtonType,
      text: confirmButtonText
    }
  ));
};
export default ModalFooter;
