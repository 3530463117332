var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { COMMON_SETTINGS } from "./common-settings";
import { GuardNames } from "../../../guards/guards-list";
const popupProps = {
  popup_font_color_verified_buyer_disabled: true,
  popup_font_color_verified_buyer: "#000",
  popup_font_color_review_title_disabled: true,
  popup_font_color_review_title: "#000",
  popup_font_size_title: "24",
  popup_font_color_author: "#000",
  popup_font_color_share_controls: "#70757E",
  popup_font_color_timestamp: "#000",
  popup_font_color_timestamp_disabled: true,
  popup_font_color_incentivized: "#000",
  popup_font_color_incentivized_disabled: true
};
export const TABLE = {
  name: "Table",
  key: "table",
  default_settings: __spreadProps(__spreadValues(__spreadValues({}, COMMON_SETTINGS), popupProps), {
    themeName: "table",
    verified_buyer_label: "Verified Buyer",
    incentivized_label: "Incentivized Review",
    rating_star_color: "#fb9501",
    progress_bar_background_color: "#e6e8eb",
    progress_bar_value_background_color: "#3b99fc",
    rating_button_clear_filter_text: "Clear Filter",
    rating_button_clear_filter_text_color: "#3b99fc",
    rating_button_clear_filter_background_color: "transparent",
    rating_button_clear_filter_border_color: "#3b99fc",
    rating_button_review_text: "Write a Review",
    rating_button_review_text_color: "#fff",
    rating_button_review_background_color: "#3b99fc",
    rating_button_review_border_color: "#3b99fc",
    rating_font_color_title: "#000000",
    rating_font_size_title: "24",
    font_color_incentivized: "#000",
    font_color_verified_label: "#000",
    font_color_author: "#000",
    font_color_timestamp: "#000",
    font_color_share_controls: "#70757E",
    font_color_comment: "#000"
  }),
  guards: {
    CTA: true,
    waterfall: false,
    popup: true,
    shareIcons: true,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    backgroundImage: true,
    customCSS: true,
    banner: false,
    ratingReviewPopup: true
  },
  themes_settings: [
    {
      key: "verified_buyer_label",
      label: "Verified buyer label",
      type: "input",
      placeholder: "Verified Buyer"
    },
    {
      key: "incentivized_label",
      label: "Incentivized review label",
      type: "input",
      placeholder: "Incentivized Review"
    }
  ],
  font_settings: [
    {
      key: "fontcolor_post",
      label: "Review Font Color",
      type: "colorPicker"
    },
    {
      key: "rating_font_color_title",
      label: "Title Font Color",
      type: "colorPicker"
    },
    {
      key: "color_namebar_buttons",
      label: "Accent Color",
      type: "colorPicker"
    },
    {
      key: "font_color_author",
      label: "Author Color",
      type: "colorPicker"
    },
    {
      key: "font_color_timestamp",
      label: "Timestamp Color",
      type: "colorPicker"
    },
    {
      key: "font_color_verified_label",
      label: "Verified Label Color",
      type: "colorPicker"
    },
    {
      key: "font_color_incentivized",
      label: "Incentivized Label Color",
      type: "colorPicker"
    },
    {
      key: "font_color_share_controls",
      label: "Share Controls Color",
      type: "colorPicker"
    },
    {
      key: "fontsize_secondary",
      type: "slider",
      label: "Font Size",
      min: "8",
      max: "50"
    },
    {
      key: "rating_font_size_title",
      type: "slider",
      label: "Title Font Size",
      min: "8",
      max: "50"
    },
    {
      key: "font_color_comment",
      label: "Comment Color",
      type: "colorPicker",
      featureName: GuardNames.POSTS_REPLIES
    }
  ],
  extra_settings: [
    { key: "rating_star_color" },
    { key: "progress_bar_background_color" },
    { key: "progress_bar_value_background_color" },
    { key: "rating_button_clear_filter_text" },
    { key: "rating_button_clear_filter_text_color" },
    { key: "rating_button_clear_filter_background_color" },
    { key: "rating_button_clear_filter_border_color" },
    { key: "rating_button_review_text" },
    { key: "rating_button_review_text_color" },
    { key: "rating_button_review_background_color" },
    { key: "rating_button_review_border_color" },
    { key: "rating_font_color_title" }
  ],
  extra_popup_settings: []
};
