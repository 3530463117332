import { Config } from "../../utils/config";
import Pusher from "pusher-js";
const connect = ({ session, type, onMessage }) => {
  let pusher, channel;
  pusher = new Pusher(Config.getPusherKey(), {
    authEndpoint: `${Config.getApiUrl()}/integrations/pusher/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${session.data.access_token}`
      }
    }
  });
  channel = pusher.subscribe(`private-team-${session.data.team_id}`);
  channel.bind(type, onMessage);
  return pusher;
};
const unsubscribe = ({ pusher, session }) => {
  return pusher.unsubscribe(`private-team-${session.data.team_id}`);
};
export const WebSocketService = {
  connect,
  unsubscribe
};
