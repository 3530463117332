var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { Component } from "react";
import "./custom-section-component-modal.sass";
import { CustomComponent } from "../custom-component/custom-component";
import PropTypes from "prop-types";
import { pageSectionType } from "../../../../../../model/page-section-type.model";
import { isAllowed } from "../../../../../../services/functions/features-checker/features-checker";
export class CustomSectionComponentModal extends Component {
  constructor(props) {
    super(props);
    this.customBlocks = [
      {
        title: "Headline",
        icon: "fa-font",
        action: () => this.props.addSection(pageSectionType.CUSTOM_HEADLINE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Paragraph",
        icon: "fa-align-left",
        action: () => this.props.addSection(pageSectionType.CUSTOM_PARAGRAPH),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "TINT",
        icon: "fa-th-large",
        action: () => this.props.addSection(pageSectionType.TINT),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Button",
        icon: "fa-rectangle-wide",
        action: () => this.props.addSection(pageSectionType.CUSTOM_BUTTON),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Counter",
        icon: "fa-poll",
        action: () => this.props.addSection(pageSectionType.SOCIAL_FEED_COUNTER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["experiences_advanced_templates"]
      },
      {
        title: "Timer",
        icon: "fa-clock",
        action: () => this.props.addSection(pageSectionType.TIMER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Spacer",
        icon: "fa-arrows-alt-v",
        action: () => this.props.addSection(pageSectionType.CUSTOM_SPACER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Social",
        icon: "fa-share-alt",
        action: () => this.props.addSection(pageSectionType.SOCIAL),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      //{
      //  title: 'Form',
      //  icon: 'fa-list-alt',
      //  action: () => this.props.addSection(pageSectionType.FORM),
      //  onClick: () => this.props.hideMenu(),
      //  enabledFeature: ['all'],
      //  isAddable: experience => {
      //    const pageSections = experience.attributes.data.draft;
      //    return (
      //      pageSections.filter(e => e.sectionType === pageSectionType.FORM).length === 0 &&
      //      !pageSections.some(e => e.sectionType === pageSectionType.POLL)
      //    );
      //  },
      //},
      {
        title: "Form Builder",
        icon: "fa-list-alt",
        action: () => this.props.addSection(pageSectionType.FORM_BUILDER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"],
        isAddable: (experience) => {
          const pageSections = experience.attributes.data.draft;
          return !pageSections.filter((e) => e.sectionType === pageSectionType.FORM_BUILDER).length;
        }
      },
      {
        title: "Multi-Column",
        icon: "fa-columns",
        action: () => this.props.addSection(pageSectionType.MULTI_COLUMN),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["experiences_advanced_templates"]
      },
      {
        title: "Image",
        icon: "fa-columns",
        action: () => this.props.addSection(pageSectionType.IMAGE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["experiences_advanced_templates"]
      },
      {
        title: "Video",
        icon: "fa-columns",
        action: () => this.props.addSection(pageSectionType.VIDEO),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["experiences_advanced_templates"]
      }
    ];
    this.multiColumnBlocks = [
      {
        title: "Headline",
        icon: "fa-font",
        action: () => this.props.addSection(pageSectionType.CUSTOM_HEADLINE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Paragraph",
        icon: "fa-align-left",
        action: () => this.props.addSection(pageSectionType.CUSTOM_PARAGRAPH),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "TINT",
        icon: "fa-th-large",
        action: () => this.props.addSection(pageSectionType.TINT),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Image",
        icon: "fas fa-image",
        action: () => this.props.addSection(pageSectionType.IMAGE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Video",
        icon: "fas fa-video",
        action: () => this.props.addSection(pageSectionType.VIDEO),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      },
      {
        title: "Button",
        icon: "fa-rectangle-wide",
        action: () => this.props.addSection(pageSectionType.CUSTOM_BUTTON),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ["all"]
      }
    ];
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-custom-section-component-modal", style: this.props.styles }, /* @__PURE__ */ React.createElement("span", { className: "tint-custom-section-component-modal__title" }, "Add Block"), /* @__PURE__ */ React.createElement("div", { className: "tint-custom-section-component-modal__blocks-container" }, (!this.props.isForMultiColumn ? this.customBlocks : this.multiColumnBlocks).map((e, i) => /* @__PURE__ */ React.createElement(
      CustomComponent,
      __spreadProps(__spreadValues({
        key: i
      }, e), {
        isBlocked: !isAllowed(this.props.enabled, e.enabledFeature) || (e.isAddable !== void 0 ? !e.isAddable(this.props.experience) : false)
      })
    )), this.props.isBlocked && !this.props.isForMultiColumn ? /* @__PURE__ */ React.createElement("div", { className: "upgrade-information" }, /* @__PURE__ */ React.createElement("span", null, "Upgrade Plan to Add")) : null));
  }
}
CustomSectionComponentModal.propTypes = {
  pageSectionIndex: PropTypes.number,
  addSection: PropTypes.func,
  hideMenu: PropTypes.func,
  isForMultiColumn: PropTypes.bool,
  styles: PropTypes.object,
  enabled: PropTypes.object,
  experience: PropTypes.object
};
