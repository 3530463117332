var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect, useRef } from "react";
import Logger from "../../../../../../services/logger/logger";
import { errorTypes, useFormBuilderUpload } from "../../../../hooks/use-form-builder-upload";
import { useDataTranslationsValue } from "../../../../hooks/use-data-translations-value";
import { FormBuilderFormHint } from "../common/hint/form-builder-form.hint";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceFileUpload = ({ item, teamId, formId, apiUrl, onPending, analyticForm, t }) => {
  if (!item) return null;
  const [, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const { input, label, p: hint } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const {
    uploading: uploadingLabel,
    remove: removeLabel,
    invalid: invalidLabel,
    failed: failedLabel,
    tooShortVideo: tooShortVideoLabel,
    tooLongVideo: tooLongVideoLabel
  } = useDataTranslationsValue({
    element: input,
    key: "data-locale"
  });
  const { onUploadFiles, files, isAnyComponentsPending, onRemoveFile } = useFormBuilderUpload({
    onUploadError: (err) => Logger.error(err),
    multiple: input == null ? void 0 : input.multiple,
    onPending: (isPending) => onPending(isPending, input.name),
    url: `${apiUrl}/v2/teams/${teamId}/forms/${formId}/form_submissions/upload/${input.name.replace("[]", "")}`
  });
  useEffect(() => {
    onPending(isAnyComponentsPending, input.name);
  }, [isAnyComponentsPending]);
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => __async(void 0, null, function* () {
    var _a;
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    setIsDragging(false);
    const droppedFiles = Array.from(((_a = e == null ? void 0 : e.dataTransfer) == null ? void 0 : _a.files) || []);
    const fileInputElement = fileInputRef.current;
    const validationErrors = [];
    for (const file of droppedFiles) {
      const customMessage = yield handleVideoValidation(file);
      if (customMessage) {
        validationErrors.push(customMessage);
      }
    }
    if (validationErrors.length > 0) {
      fileInputElement.setCustomValidity(validationErrors.join("\n"));
      fileInputElement.reportValidity();
      return;
    }
    fileInputElement.setCustomValidity("");
    fileInputElement.reportValidity();
    fileInputElement.files = droppedFiles;
    onUploadFiles(droppedFiles);
  });
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  const dataTranslationsAttribute = JSON.stringify({
    uploading: t(uploadingLabel),
    remove: t(removeLabel),
    invalid: t(invalidLabel),
    failed: t(failedLabel)
  });
  const handleVideoValidation = (file) => {
    return new Promise((resolve) => {
      if (!file.type.startsWith("video/")) {
        resolve(null);
        return;
      }
      const video = document.createElement("video");
      const objectURL = URL.createObjectURL(file);
      video.src = objectURL;
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        var _a, _b;
        URL.revokeObjectURL(objectURL);
        const duration = video.duration;
        const minLength = ((_a = input["data-validation"]) == null ? void 0 : _a.min_duration) || 0;
        const maxLength = ((_b = input["data-validation"]) == null ? void 0 : _b.max_duration) || 1e7;
        if (duration < minLength) {
          resolve(
            tooShortVideoLabel ? t(tooShortVideoLabel) : `Video ${file.name} is too short. Minimum length is ${minLength} seconds.`
          );
        } else if (duration > maxLength) {
          resolve(
            tooLongVideoLabel ? t(tooLongVideoLabel) : `Video ${file.name} is too long. Maximum length is ${maxLength} seconds.`
          );
        } else {
          resolve(null);
        }
      };
      video.onerror = () => {
        URL.revokeObjectURL(objectURL);
        resolve(`Unable to load video file ${file.name}.`);
      };
    });
  };
  const onFileChange = (files2) => __async(void 0, null, function* () {
    const fileInputElement = fileInputRef.current;
    const validationErrors = [];
    for (const file of files2) {
      const customMessage = yield handleVideoValidation(file);
      if (customMessage) {
        validationErrors.push(customMessage);
      }
    }
    if (validationErrors.length > 0) {
      fileInputElement.setCustomValidity(validationErrors.join("\n"));
    } else {
      fileInputElement.setCustomValidity("");
      onUploadFiles(files2);
    }
    fileInputElement.reportValidity();
  });
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: item.class,
      onDrop: handleDrop,
      onDragEnter: handleDragIn,
      onDragLeave: handleDragOut,
      onDragOver: handleDragOver
    },
    /* @__PURE__ */ React.createElement("label", { htmlFor: input.name }, t(label.nodeValue)),
    /* @__PURE__ */ React.createElement(
      "input",
      {
        ref: fileInputRef,
        id: input.id,
        name: input.name,
        "data-content-text": t(input.nodeValue),
        required: input.required,
        onFocus,
        onBlur,
        accept: input.accept,
        multiple: input.multiple,
        type: "file",
        onChange: (e) => onFileChange(e.target.files),
        "aria-describedby": input["aria-describedby"]
      }
    ),
    files.length > 0 && /* @__PURE__ */ React.createElement("output", null, /* @__PURE__ */ React.createElement("ul", null, files.map((file, index) => {
      return /* @__PURE__ */ React.createElement("li", { className: "input-file__file", "aria-invalid": file.error ? true : false, key: `${file.name}-${index}` }, !file.error && file.id && /* @__PURE__ */ React.createElement(
        "input",
        {
          key: `${file.id}`,
          type: "hidden",
          id: `${input.id}_${index}`,
          name: input.name,
          value: file.id
        }
      ), /* @__PURE__ */ React.createElement("div", { className: "input-file__file-name" }, file.name), file.error && /* @__PURE__ */ React.createElement("div", { className: "input-file__file-error" }, t(file.error === errorTypes.failed ? failedLabel : invalidLabel, { name: file.name })), !file.error && file.progress !== 100 && /* @__PURE__ */ React.createElement(
        "progress",
        {
          id: `${input.id}_${index}`,
          max: "100",
          value: file.progress,
          "aria-label": t(uploadingLabel, { name: file.name }) || ""
        }
      ), /* @__PURE__ */ React.createElement(
        "button",
        {
          className: "input-file__file-button",
          onClick: () => {
            onRemoveFile(file.id);
            if (input.multiple) {
              if (files.length === 1) {
                fileInputRef.current.value = "";
              }
            } else {
              fileInputRef.current.value = "";
            }
          }
        },
        t(removeLabel, { name: file.name }) || ""
      ));
    }))),
    /* @__PURE__ */ React.createElement(FormBuilderFormHint, { item: t(hint == null ? void 0 : hint.nodeValue), id: hint == null ? void 0 : hint.id })
  );
};
FormBuilderFormInterfaceFileUpload.displayName = "FormBuilderFormInterfaceFileUpload";
export default React.memo(FormBuilderFormInterfaceFileUpload);
