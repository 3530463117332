import React, { useRef } from "react";
import "./dropdown-simple-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import { DropdownHeader } from "../../button/header/dropdown-header";
import TintButtonIcon, { TintButtonIconTypes } from "../../../button-icon/button-icon";
import useSelectListElement from "../../../autocomplete-list/useSelectListElement";
const DropdownSimpleList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems,
  title,
  isDefaultOption = true,
  defaultOptionTitle = "All",
  dropdownHeaderStyle,
  rerenderHeader
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const { currentElement, isSelected } = useSelectListElement({
    listLength: currentItems == null ? void 0 : currentItems.length,
    onEnterClick: (i) => {
      (currentItems == null ? void 0 : currentItems[i]) ? onSelect == null ? void 0 : onSelect(currentItems[i]) : i === -1 && onDefaultOptionSelect();
    },
    isDefaultOption
  });
  const DropdownTextElement = (title2) => {
    return /* @__PURE__ */ React.createElement(
      DropdownHeader,
      {
        iconLeft,
        iconRight,
        iconCenter,
        title: title2,
        style: dropdownHeaderStyle
      }
    );
  };
  const onSelect = (item) => {
    onChange(item);
    rerenderHeader && onChangeTextComponent(DropdownTextElement(item.name));
  };
  const onDefaultOptionSelect = () => {
    onChange("");
    rerenderHeader && onChangeTextComponent(DropdownTextElement(title));
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "ul",
    {
      className: `dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical}`,
      ref: elementRef,
      "data-testid": "dropdown-simple-list"
    },
    isDefaultOption && /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item ${currentElement === 0 ? "dropdown-list__item--selected" : ""}`,
        onClick: onDefaultOptionSelect
      },
      /* @__PURE__ */ React.createElement("span", null, defaultOptionTitle)
    ),
    currentItems && currentItems.map((item, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item ${item.isActive ? "active" : ""} ${isSelected(i) ? "dropdown-list__item--selected" : ""} ${(item == null ? void 0 : item.customClassName) ? item.customClassName : ""} 
            ${i}`,
        key: i,
        onClick: () => onSelect(item)
      },
      item.icon && typeof item.icon === "string" ? /* @__PURE__ */ React.createElement("span", { className: `icon ${item.icon}` }) : item.icon && /* @__PURE__ */ React.createElement(TintButtonIcon, { type: TintButtonIconTypes.NONE, icon: item.icon }),
      /* @__PURE__ */ React.createElement("span", null, item.name)
    ))
  ));
};
export default DropdownSimpleList;
DropdownSimpleList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string
};
