import React from "react";
import "./autocomplete-list.sass";
const AutocompleteList = ({ list = [], searchTerm, onChange, currentElementIndex }) => {
  const maxCountItem = 9;
  const markTextAsSelect = (value) => {
    if (!value) {
      return "";
    }
    const indexOfValue = value.toLowerCase().indexOf(searchTerm.toLowerCase());
    return /* @__PURE__ */ React.createElement(React.Fragment, null, value.substr(0, indexOfValue), /* @__PURE__ */ React.createElement("span", { className: "light" }, value.substr(indexOfValue, searchTerm.length)), value.substr(indexOfValue + searchTerm.length, value.length));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-autocomplete-list", "data-testid": "tint-autocomplete-list" }, (list == null ? void 0 : list.length) !== 0 ? /* @__PURE__ */ React.createElement("div", { className: "tint-autocomplete-list__group" }, /* @__PURE__ */ React.createElement("ul", { className: "search-list" }, list.sort((a, b) => a - b).map(
    (object, i) => i <= maxCountItem ? /* @__PURE__ */ React.createElement(
      "li",
      {
        "data-testid": `searchListItem_${i}`,
        className: `search-list__item ${currentElementIndex === i ? "search-list__item--selected" : ""}`,
        key: i,
        onClick: () => onChange == null ? void 0 : onChange(list[i])
      },
      /* @__PURE__ */ React.createElement("span", null, markTextAsSelect(object))
    ) : null
  ))) : searchTerm.length === 0 && /* @__PURE__ */ React.createElement("span", { className: "tint-autocomplete-list__no-found" }, "No items found"));
};
export default AutocompleteList;
