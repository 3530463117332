var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/session";
import Logger from "../services/logger/logger";
import produce from "immer";
import { hasOneOfThePermission } from "../services/functions/permissions-checker/permissions-checker";
import { isAllowed } from "../services/functions/features-checker/features-checker";
import { GuardsRules } from "../guards/guards-rules";
import { Config } from "../utils/config";
export const initialState = {
  data: void 0,
  guards: {},
  isPending: false,
  error: void 0
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SESSION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload,
        isPending: false,
        error: void 0
      });
    case ACTIONS.UPDATE_SESSION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: __spreadValues(__spreadValues({}, state.data), action.payload.data.attributes),
        isPending: false,
        error: void 0
      });
    case ACTIONS.UPDATE_TEAMS.FAILURE:
    case ACTIONS.POST_SESSION_TOKEN.FAILURE:
    case ACTIONS.UPDATE_SESSION.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isPending: false,
        error: action.payload
      });
    case ACTIONS.GET_SESSION.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        data: void 0,
        isPending: false,
        error: action.payload
      });
    case ACTIONS.SET_FEATURES_ACCESSIBILITY:
      return __spreadProps(__spreadValues({}, state), {
        guards: Object.keys(GuardsRules).sort().map((key) => {
          return {
            [key]: mapUserGuardsAvailability({
              session: state,
              optionalFeatures: GuardsRules[key].optionalFeatures,
              features: GuardsRules[key].features,
              permissions: GuardsRules[key].permissions
            })
          };
        }).reduce((acc, value) => {
          return __spreadValues(__spreadValues({}, acc), value);
        }, {})
      });
    case ACTIONS.UPDATE_TEAMS.REQUEST:
    case ACTIONS.POST_SESSION_TOKEN.REQUEST:
    case ACTIONS.GET_SESSION.REQUEST:
    case ACTIONS.UPDATE_SESSION.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true
      });
    case ACTIONS.UPDATE_SESSION_PROFILE_DATA:
      return __spreadProps(__spreadValues({}, state), {
        data: __spreadValues(__spreadValues({}, state.data), {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          image_url: action.payload.image_url,
          email: action.payload.email
        })
      });
    case ACTIONS.UPDATE_SESSION_CURRENT_TEAM:
      return produce(state, (draft) => {
        draft.data.currentTeam.attributes.name = action.payload.name;
        draft.data.currentTeam.attributes.image_url = action.payload.image_url;
      });
    case ACTIONS.UPDATE_TEAMS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: __spreadProps(__spreadValues({}, state.data), {
          currentTeam: action.payload
        })
      });
    case ACTIONS.POST_SESSION_TOKEN.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: __spreadValues(__spreadValues({}, state.data), action.payload)
      });
    case ACTIONS.PATCH_PRIMARY_FACTOR_AUTH.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: __spreadProps(__spreadValues({}, state.data), {
          default_authentication_factor_id: action.payload
        })
      });
    default:
      return state;
  }
};
export const getGuardPermission = (state, permissionType) => {
  return state && (state == null ? void 0 : state.guards) && state.guards[permissionType];
};
const mapUserGuardsAvailability = ({ session, features, permissions, optionalFeatures, forbiddenFeatures }) => {
  const featureList = session.data.features;
  const permissionsList = session.data.permissions;
  const isFeatureAble = (features == null ? void 0 : features.length) > 0 ? isAllowed(featureList, features) : true;
  const isOptionalFeatureAble = optionalFeatures && (optionalFeatures == null ? void 0 : optionalFeatures.length) > 0 ? optionalFeatures == null ? void 0 : optionalFeatures.some((feature) => featureList[feature]) : true;
  const hasForbiddenFeatures = forbiddenFeatures && (forbiddenFeatures == null ? void 0 : forbiddenFeatures.length) > 0 ? !(forbiddenFeatures == null ? void 0 : forbiddenFeatures.some((feature) => featureList[feature])) : true;
  const isPermissionsAble = (permissions == null ? void 0 : permissions.length) > 0 ? hasOneOfThePermission(permissionsList, permissions) : true;
  return isFeatureAble && isPermissionsAble && isOptionalFeatureAble && hasForbiddenFeatures;
};
export const isAnyAssetUploaded = (state) => {
  if (state && state.data) {
    try {
      const team = state.data.currentTeam;
      return team && team.relationships.assets.meta.total;
    } catch (e) {
      Logger.error(e);
      return void 0;
    }
  }
  return void 0;
};
export const isAnyTintAdded = (state) => {
  var _a;
  if (state && ((_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data)) {
    try {
      const team = state.session.data.currentTeam;
      return team && team.relationships.tints.meta.count;
    } catch (e) {
      Logger.error(e);
      return void 0;
    }
  }
  return void 0;
};
export const isUploadAllowed = (session) => {
  const plan = session.data.currentPlan;
  const team = session.data.currentTeam;
  if (plan && team) {
    return plan.attributes.uploaded_assets - team.relationships.assets.meta.uploaded_total > 0;
  }
  return false;
};
export const getPermissions = (session) => {
  const _session = session.data;
  return _session ? _session.permissions : {};
};
export const getFeatures = (session) => {
  const _session = session.data;
  return _session ? _session.features : {};
};
export const hasTeam = (session) => {
  return !!session.data.currentTeam;
};
export const getUserId = (session) => {
  var _a;
  return (_a = session == null ? void 0 : session.data) == null ? void 0 : _a.attributes.id;
};
export const findTeam = (session, teamId) => {
  if (!session || !session.data || !session.data.included) {
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  const queryParamTeamId = params.get("teamId");
  if (queryParamTeamId) {
    params.delete("teamId");
    const _params = params.toString() ? "?" + params.toString() : "";
    window.history.pushState({}, "", window.location.pathname + _params);
  }
  const _teamId = queryParamTeamId || teamId;
  const teams = session.data.included.filter((e) => e.type === "team");
  const isTeamIdWithinTeamsRange = teams.some((e) => e.id === _teamId);
  if (!isTeamIdWithinTeamsRange) {
    return teams[0];
  }
  return teams.find((e) => _teamId ? e.id === _teamId : true);
};
export const getMaxImageSizePlanLimit = (state) => {
  var _a;
  return state && state.session.data && state.session.data.included ? (_a = state.session.data.currentPlan) == null ? void 0 : _a.attributes.maximum_image_size : Config.maxFileSize;
};
export const getMaxVideoSizePlanLimit = (state) => {
  var _a;
  return state && state.session.data && state.session.data.included ? (_a = state.session.data.currentPlan) == null ? void 0 : _a.attributes.maximum_video_size : Config.maxVideoFileSize;
};
export const getSocialFeedsLimits = (state) => {
  var _a, _b, _c, _d;
  const plan = (_b = (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data) == null ? void 0 : _b.currentPlan;
  const team = (_d = (_c = state == null ? void 0 : state.session) == null ? void 0 : _c.data) == null ? void 0 : _d.currentTeam;
  return {
    activeSocialFeedsLimit: plan ? plan.attributes.active_social_feeds : 0,
    activeSocialFeedsCount: team && team.relationships.social_feeds ? team.relationships.social_feeds.meta.active_social_feeds_count : 0
  };
};
export const getFormsLimits = (state) => {
  var _a, _b, _c, _d;
  const plan = (_b = (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data) == null ? void 0 : _b.currentPlan;
  const team = (_d = (_c = state == null ? void 0 : state.session) == null ? void 0 : _c.data) == null ? void 0 : _d.currentTeam;
  return {
    activeFormsLimit: plan ? plan.attributes.forms : 0,
    activeFormsCount: team && team.relationships.forms ? team.relationships.forms.meta.published_count : 0
  };
};
export const getPlan = (state) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data) == null ? void 0 : _b.currentPlan;
};
export const getTeam = (state) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data) == null ? void 0 : _b.currentTeam;
};
export const getTeamId = (state) => {
  var _a, _b, _c;
  return (_c = (_b = (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data) == null ? void 0 : _b.currentTeam) == null ? void 0 : _c.id;
};
export const getOnBoardingState = (state) => {
  var _a, _b, _c;
  const team = (_b = (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data) == null ? void 0 : _b.currentTeam;
  if (!team) return;
  return (_c = team == null ? void 0 : team.attributes) == null ? void 0 : _c.onboarding;
};
