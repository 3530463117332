import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { ACCOUNT_SETTINGS } from "../../consts";
export const ACTIONS = {
  OPEN_MODAL: createPrefixedActionName(ACCOUNT_SETTINGS, "OPEN_MODAL"),
  UPDATE_MODAL: createPrefixedActionName(ACCOUNT_SETTINGS, "UPDATE_MODAL"),
  CLOSE_MODAL: createPrefixedActionName(ACCOUNT_SETTINGS, "CLOSE_MODAL")
};
export const openModal = (data) => ({
  type: ACTIONS.OPEN_MODAL,
  payload: data
});
export const updateModal = (data) => ({
  type: ACTIONS.UPDATE_MODAL,
  payload: data
});
export const closeModal = (data) => ({
  type: ACTIONS.CLOSE_MODAL,
  payload: data
});
