import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { PRODUCTS } from "../../consts";
export const ACTIONS = {
  GET_PRODUCTS: createRoutine(createPrefixedActionName(PRODUCTS, "GET_PRODUCTS")),
  GET_ASSIGNED_PRODUCTS: createRoutine(createPrefixedActionName(PRODUCTS, "GET_ASSIGNED_PRODUCTS")),
  GET_INFINITE_LOADED_PRODUCTS: createRoutine(createPrefixedActionName(PRODUCTS, "GET_INFINITE_LOADED_PRODUCTS")),
  GET_FILTERED_PRODUCTS: createRoutine(createPrefixedActionName(PRODUCTS, "GET_FILTERED_PRODUCTS")),
  SET_DEFAULT_FILTERED_PRODUCTS: createPrefixedActionName(PRODUCTS, "SET_DEFAULT_FILTERED_PRODUCTS"),
  DELETE_PRODUCT: createRoutine(createPrefixedActionName(PRODUCTS, "DELETE_PRODUCT")),
  POST_PRODUCT: createRoutine(createPrefixedActionName(PRODUCTS, "POST_PRODUCT")),
  PATCH_PRODUCT: createRoutine(createPrefixedActionName(PRODUCTS, "PATCH_PRODUCT")),
  EXPORT_TO_CSV: createRoutine(createPrefixedActionName(PRODUCTS, "EXPORT_TO_CSV")),
  SYNC_FEED: createRoutine(createPrefixedActionName(PRODUCTS, "SYNC_FEED"))
};
export const getProductsStart = () => ({
  type: ACTIONS.GET_PRODUCTS.REQUEST
});
export const getProductsSuccess = (data) => ({
  type: ACTIONS.GET_PRODUCTS.SUCCESS,
  payload: data
});
export const getProductsError = (err) => ({
  type: ACTIONS.GET_PRODUCTS.FAILURE,
  payload: err
});
export const getAssignedProductsStart = () => ({
  type: ACTIONS.GET_ASSIGNED_PRODUCTS.REQUEST
});
export const getAssignedProductsSuccess = (data) => ({
  type: ACTIONS.GET_ASSIGNED_PRODUCTS.SUCCESS,
  payload: data
});
export const getAssignedProductsError = (err) => ({
  type: ACTIONS.GET_ASSIGNED_PRODUCTS.FAILURE,
  payload: err
});
export const getInfiniteLoadedProductsStart = () => ({
  type: ACTIONS.GET_INFINITE_LOADED_PRODUCTS.REQUEST
});
export const getInfiniteLoadedProductsSuccess = (data) => ({
  type: ACTIONS.GET_INFINITE_LOADED_PRODUCTS.SUCCESS,
  payload: data
});
export const getInfiniteLoadedProductsError = (err) => ({
  type: ACTIONS.GET_INFINITE_LOADED_PRODUCTS.FAILURE,
  payload: err
});
export const getFilteredProductsStart = () => ({
  type: ACTIONS.GET_FILTERED_PRODUCTS.REQUEST
});
export const getFilteredProductsSuccess = (data) => ({
  type: ACTIONS.GET_FILTERED_PRODUCTS.SUCCESS,
  payload: data
});
export const getFilteredProductsError = (err) => ({
  type: ACTIONS.GET_FILTERED_PRODUCTS.FAILURE,
  payload: err
});
export const setDefaultFilteredProducts = () => ({
  type: ACTIONS.SET_DEFAULT_FILTERED_PRODUCTS
});
export const deleteProductStart = () => ({
  type: ACTIONS.DELETE_PRODUCT.REQUEST
});
export const deleteProductSuccess = (data) => ({
  type: ACTIONS.DELETE_PRODUCT.SUCCESS,
  payload: data
});
export const deleteProductError = (err) => ({
  type: ACTIONS.DELETE_PRODUCT.FAILURE,
  payload: err
});
export const postProductStart = () => ({
  type: ACTIONS.POST_PRODUCT.REQUEST
});
export const postProductSuccess = (data) => ({
  type: ACTIONS.POST_PRODUCT.SUCCESS,
  payload: data
});
export const postProductError = (err) => ({
  type: ACTIONS.POST_PRODUCT.FAILURE,
  payload: err
});
export const patchProductStart = () => ({
  type: ACTIONS.PATCH_PRODUCT.REQUEST
});
export const patchProductSuccess = (data) => ({
  type: ACTIONS.PATCH_PRODUCT.SUCCESS,
  payload: data
});
export const patchProductError = (err) => ({
  type: ACTIONS.PATCH_PRODUCT.FAILURE,
  payload: err
});
export const syncFeedStart = () => ({
  type: ACTIONS.SYNC_FEED.REQUEST
});
export const syncFeedSuccess = (data) => ({
  type: ACTIONS.SYNC_FEED.SUCCESS,
  payload: data
});
export const syncFeedError = (err) => ({
  type: ACTIONS.SYNC_FEED.FAILURE,
  payload: err
});
export const exportToCSVStart = () => ({
  type: ACTIONS.EXPORT_TO_CSV.REQUEST
});
export const exportToCSVSuccess = (data) => ({
  type: ACTIONS.EXPORT_TO_CSV.SUCCESS,
  payload: data
});
export const exportToCSVError = (err) => ({
  type: ACTIONS.EXPORT_TO_CSV.FAILURE,
  payload: err
});
