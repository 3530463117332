import * as React from "react";
import Masonry from "react-masonry-component";
import "./masonry.sass";
import PropTypes from "prop-types";
class MasonryLayout extends React.Component {
  renderChildElements() {
    return this.props.elements.map((item, i) => {
      return /* @__PURE__ */ React.createElement("div", { className: "masonry-list__item", key: i }, this.props.children(item));
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      Masonry,
      {
        enableResizableChildren: true,
        options: { percentPosition: true },
        className: "masonry-list",
        updateOnEachImageLoad: true
      },
      this.renderChildElements()
    );
  }
}
export default MasonryLayout;
MasonryLayout.propTypes = {
  elements: PropTypes.array,
  children: PropTypes.func
};
