import React, { useState } from "react";
import "./hue.sass";
import { Button } from "../../../../../components/interface/button/button";
import { faClose, faTags } from "@fortawesome/free-solid-svg-icons";
import TintIcon from "../../../../../components/interface/icon/icon";
import TintButtonIcon, { TintButtonIconTypes } from "../../../../../components/interface/button-icon/button-icon";
import HUECircle from "../hue-circle/hue-circle";
import usePercents from "../../../../../components/hooks/use-percents/use-percents";
const HUETabs = {
  SCORE: "score",
  TAGS: "tags"
};
const HUE = ({ tagList = [], probability, onClose }) => {
  const [tab, setTab] = useState(HUETabs.SCORE);
  const percentValue = usePercents(1, probability);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-hue", onClick: (e) => e.stopPropagation() }, /* @__PURE__ */ React.createElement("div", { className: "tint-hue__scroll-wrapper", onClick: (e) => e.stopPropagation() }, /* @__PURE__ */ React.createElement(TintButtonIcon, { className: "tint-hue__close", type: TintButtonIconTypes.DARK, onClick: onClose, icon: faClose }), /* @__PURE__ */ React.createElement("div", { className: `tint-hue__tab tint-hue__tab--recommendation ${tab === HUETabs.SCORE ? "active" : ""}` }, /* @__PURE__ */ React.createElement(HUECircle, null, percentValue, "%"), /* @__PURE__ */ React.createElement("p", null, "Recommended")), /* @__PURE__ */ React.createElement("div", { className: `tint-hue__tab tint-hue__tab--tags ${tab === HUETabs.TAGS ? "active" : ""}` }, /* @__PURE__ */ React.createElement("div", { className: "tint-hue__wrapper" }, /* @__PURE__ */ React.createElement("ul", { className: "tint-hue-tags" }, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faTags }), " ", tagList.length), tagList.map((tag) => /* @__PURE__ */ React.createElement("li", { className: "tint-hue-tags__tag", key: tag.name }, tag.name)))))), /* @__PURE__ */ React.createElement("ul", { className: "tint-hue__navigation" }, /* @__PURE__ */ React.createElement("li", { className: `hue-tab-button ${tab === HUETabs.SCORE ? "active" : ""}` }, /* @__PURE__ */ React.createElement(Button, { type: "no-style", onClick: () => setTab(HUETabs.SCORE) }, "Score")), /* @__PURE__ */ React.createElement("li", { className: `hue-tab-button ${tab === HUETabs.TAGS ? "active" : ""}` }, /* @__PURE__ */ React.createElement(Button, { type: "no-style", onClick: () => setTab(HUETabs.TAGS) }, "Tags"))));
};
export default HUE;
