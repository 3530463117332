var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/social-connections/social-connections-modal.actions";
export const ACTION_TYPES = {
  ADD_SOCIAL_CONNECTION: "add_social_connection",
  TRANSFER_SOCIAL_FEEDS: "transfer_social_feeds"
};
const modals = {
  [ACTION_TYPES.ADD_SOCIAL_CONNECTION]: {
    opened: false,
    type: ""
  },
  [ACTION_TYPES.TRANSFER_SOCIAL_FEEDS]: {
    opened: false,
    socialFeed: "",
    ownerId: ""
  }
};
export const initialState = {
  modals
};
export const socialConnectionsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        modals: __spreadProps(__spreadValues({}, state.modals), {
          [action.payload.modal]: __spreadProps(__spreadValues(__spreadValues({}, state.modals[action.payload.modal]), action.payload.fields), {
            opened: true
          })
        })
      });
    case ACTIONS.UPDATE_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        modals: __spreadProps(__spreadValues({}, state.modals), {
          [action.payload.modal]: __spreadValues(__spreadValues({}, state.modals[action.payload.modal]), action.payload.fields)
        })
      });
    case ACTIONS.CLOSE_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        modals: __spreadProps(__spreadValues({}, state.modals), {
          [action.payload.modal]: __spreadProps(__spreadValues({}, action.payload.fields), {
            opened: false
          })
        })
      });
    default:
      return state;
  }
};
