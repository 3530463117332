var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/ui/ui.actions";
import { produce } from "immer";
export const initialState = {
  modals: {},
  sidebars: {},
  dropdowns: {},
  infiniteLists: {}
};
export const ui = (state = initialState, action) => {
  var _a, _b;
  switch (action.type) {
    case ACTIONS.SET_MEDIA_DATA:
      return __spreadProps(__spreadValues({}, state), {
        sidebars: __spreadProps(__spreadValues({}, state == null ? void 0 : state.sidebars), {
          [(_a = action.payload) == null ? void 0 : _a.id]: {
            media: (_b = action.payload) == null ? void 0 : _b.data
          }
        })
      });
    case ACTIONS.OPEN_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        modals: __spreadProps(__spreadValues({}, state == null ? void 0 : state.modals), {
          [action.payload.id]: action.payload.data ? action.payload.data : {}
        })
      });
    case ACTIONS.CLOSE_MODAL: {
      const modals = state == null ? void 0 : state.modals;
      delete modals[action.payload];
      return __spreadProps(__spreadValues({}, state), {
        modals
      });
    }
    case ACTIONS.CLOSE_ALL_MODALS: {
      return __spreadProps(__spreadValues({}, state), {
        modals: {}
      });
    }
    case ACTIONS.OPEN_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        sidebars: __spreadProps(__spreadValues({}, state == null ? void 0 : state.sidebars), {
          [action.payload.id]: action.payload.data ? action.payload.data : {}
        })
      });
    case ACTIONS.CLOSE_SIDEBAR: {
      const sidebars = __spreadValues({}, state == null ? void 0 : state.sidebars);
      sidebars == null ? true : delete sidebars[action.payload];
      return __spreadProps(__spreadValues({}, state), {
        sidebars
      });
    }
    case ACTIONS.RESET_UI: {
      return initialState;
    }
    case ACTIONS.SET_DROPDOWN_DATA:
      return produce(state, (draft) => {
        var _a2, _b2, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
        if ((_a2 = action.payload) == null ? void 0 : _a2.query) {
          draft.dropdowns[action.payload.id].query = (_b2 = action.payload) == null ? void 0 : _b2.query;
          draft.dropdowns[action.payload.id].links = (_c = action.payload) == null ? void 0 : _c.links;
          const hasOverride = ((_d = action.payload) == null ? void 0 : _d.query) === state.dropdowns[action.payload.id].query;
          draft.dropdowns[action.payload.id].data = removeDuplicates(
            hasOverride ? [...(_g = (_f = (_e = state.dropdowns) == null ? void 0 : _e[action.payload.id]) == null ? void 0 : _f.data) != null ? _g : [], ...((_h = action.payload) == null ? void 0 : _h.data) || []] : (_i = action.payload) == null ? void 0 : _i.data
          );
        } else {
          const hasOverride = !!(state.dropdowns[action.payload.id] && ((_j = action.payload) == null ? void 0 : _j.query) === ((_k = state.dropdowns[action.payload.id]) == null ? void 0 : _k.query));
          draft.dropdowns[action.payload.id] = {
            data: removeDuplicates(
              hasOverride ? [...(_n = (_m = (_l = state.dropdowns) == null ? void 0 : _l[action.payload.id]) == null ? void 0 : _m.data) != null ? _n : [], ...((_o = action.payload) == null ? void 0 : _o.data) || []] : ((_p = action.payload) == null ? void 0 : _p.data) || []
            ),
            query: void 0,
            links: (_q = action.payload) == null ? void 0 : _q.links
          };
        }
      });
    case ACTIONS.SET_INFINITE_LIST_DATA:
      return produce(state, (draft) => {
        var _a2, _b2, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
        if ((_a2 = action.payload) == null ? void 0 : _a2.query) {
          draft.infiniteLists[action.payload.id].query = (_b2 = action.payload) == null ? void 0 : _b2.query;
          draft.infiniteLists[action.payload.id].links = (_c = action.payload) == null ? void 0 : _c.links;
          const hasOverride = ((_d = action.payload) == null ? void 0 : _d.query) === state.infiniteLists[action.payload.id].query;
          draft.infiniteLists[action.payload.id].data = removeDuplicates(
            hasOverride ? [...(_g = (_f = (_e = state.infiniteLists) == null ? void 0 : _e[action.payload.id]) == null ? void 0 : _f.data) != null ? _g : [], ...((_h = action.payload) == null ? void 0 : _h.data) || []] : (_i = action.payload) == null ? void 0 : _i.data
          );
        } else {
          const hasOverride = !!(state.infiniteLists[action.payload.id] && ((_j = action.payload) == null ? void 0 : _j.query) === ((_k = state.infiniteLists[action.payload.id]) == null ? void 0 : _k.query));
          draft.infiniteLists[action.payload.id] = {
            data: removeDuplicates(
              hasOverride ? [...(_n = (_m = (_l = state.infiniteLists) == null ? void 0 : _l[action.payload.id]) == null ? void 0 : _m.data) != null ? _n : [], ...((_o = action.payload) == null ? void 0 : _o.data) || []] : ((_p = action.payload) == null ? void 0 : _p.data) || []
            ),
            query: void 0,
            links: (_q = action.payload) == null ? void 0 : _q.links
          };
        }
      });
    case ACTIONS.UPDATE_DROPDOWN_DATA:
      return produce(state, (draft) => {
        var _a2, _b2;
        const currentElementIndex = (_b2 = (_a2 = draft.dropdowns[action.payload.id]) == null ? void 0 : _a2.data) == null ? void 0 : _b2.findIndex(
          (el) => {
            var _a3;
            return el.id === ((_a3 = action == null ? void 0 : action.payload) == null ? void 0 : _a3.id);
          }
        );
        if (!currentElementIndex) {
          return;
        }
        draft.dropdowns[action.payload.id].data[currentElementIndex] = action.payload.data;
      });
    case ACTIONS.CLEAR_DROPDOWN_DATA:
      return produce(state, (draft) => {
        draft.dropdowns[action.payload.id] = {
          data: [],
          query: void 0,
          links: void 0
        };
      });
    case ACTIONS.CLEAR_INFINITE_LIST_DATA:
      return produce(state, (draft) => {
        draft.infiniteLists[action.payload.id] = {
          data: [],
          query: void 0,
          links: void 0
        };
      });
    default:
      return state;
  }
};
const removeDuplicates = (data) => Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
