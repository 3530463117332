import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const dropdown = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.DROPDOWN_SELECT]: {
    "background-position": "calc(100% - 8px) 50%",
    padding: "8px 36px 8px 8px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.DROPDOWN_RTL]: {
    padding: "8px 8px 8px 36px",
    "background-position": "8px 50%",
    "background-image": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='14' height='14' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E")`
  }
};
