export const fontFamilies = [
  "Alegreya",
  "Alegreya Sans",
  "Anonymous Pro",
  "Archivo Narrow",
  "Arvo",
  "BioRhyme",
  "Cabin",
  "Cardo",
  "Chivo",
  "Cormorant",
  "Crimson Text",
  "Domine",
  "DM Sans",
  "Muli",
  "Fira Sans",
  "IBM Plex Sans",
  "Inconsolata",
  "Inknut Antiqua",
  "Karla",
  "Lato",
  "Libre Baskerville",
  "Libre Franklin",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Neuton",
  "Open Sans",
  "Oswald",
  "Poppins",
  "Proza Libre",
  "PT Sans",
  "PT Serif",
  "Raleway",
  "Roboto",
  "Roboto Condensed",
  "Rubik",
  "Rubik Mono One",
  "Source Sans Pro",
  "Source Serif Pro",
  "Space Mono",
  "Spectral",
  "Work Sans"
];
