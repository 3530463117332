import React from "react";
const FormBuilderFormInterfaceFullName = ({ item, values, analyticForm, t }) => {
  const divs = item.elements.filter((element) => element.nodeName === "div");
  const label = item.elements.find((element) => element.nodeName === "legend");
  const firstName = divs[0];
  const firstNameLabel = firstName.elements.find((element) => element.nodeName === "label");
  const firstNameInput = firstName.elements.find((element) => element.nodeName === "input");
  const lastName = divs[1];
  const lastNameLabel = lastName.elements.find((element) => element.nodeName === "label");
  const lastNameInput = lastName.elements.find((element) => element.nodeName === "input");
  const onFocus = (e, input) => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = (input) => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  return /* @__PURE__ */ React.createElement("fieldset", { className: item.class }, /* @__PURE__ */ React.createElement("legend", null, t(label.content)), /* @__PURE__ */ React.createElement("div", { className: firstName.class }, /* @__PURE__ */ React.createElement("label", { htmlFor: firstNameLabel.for }, t(firstNameLabel.nodeValue)), /* @__PURE__ */ React.createElement(
    "input",
    {
      name: firstNameInput.name,
      defaultValue: values == null ? void 0 : values.getAll(firstNameInput.name),
      type: firstNameInput.type,
      autoComplete: firstNameInput.autocomplete,
      onFocus: (e) => onFocus(e, firstNameInput),
      onBlur: () => onBlur(firstNameInput),
      placeholder: t(firstNameInput.placeholder),
      required: firstNameInput.required,
      id: firstNameInput.id
    }
  )), /* @__PURE__ */ React.createElement("div", { className: lastName.class }, /* @__PURE__ */ React.createElement("label", { htmlFor: lastNameLabel.for }, t(lastNameLabel.nodeValue)), /* @__PURE__ */ React.createElement(
    "input",
    {
      name: lastNameInput.name,
      defaultValue: values == null ? void 0 : values.getAll(lastNameInput.name),
      type: lastNameInput.type,
      autoComplete: lastNameInput.autocomplete,
      onFocus: (e) => onFocus(e, lastNameInput),
      onBlur: () => onBlur(lastNameInput),
      placeholder: t(lastNameInput.placeholder),
      required: lastNameInput.required,
      id: lastNameInput.id
    }
  )));
};
FormBuilderFormInterfaceFullName.displayName = "FormBuilderFormInterfaceFullName";
export default React.memo(FormBuilderFormInterfaceFullName);
