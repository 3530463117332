var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./social-feed-form-input.sass";
import { InputGroup } from "../../../../../../../../components/interface/inputs/input-group/input-group";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import InputPrimary from "../../../../../../../../components/interface/inputs/input-primary/input-primary";
import Checkbox, {
  CheckboxButtonType
} from "../../../../../../../../components/interface/inputs/checkbox-button/checkbox-button";
import EditTextArea from "../../../../../../../experience/components/experience/edit-textarea/edit-textarea";
import AccountDropdown from "../account-dropdown/account-dropdown";
import { useDispatch, useSelector } from "react-redux";
import ExperienceDropdown from "../experience-dropdown/experience-dropdown";
import FormDropdown from "../form-dropdown/form-dropdown";
import { DropdownEmailTemplates } from "../../../../../../../../components/api-dropdown/email-templates/dropdown-email-templates";
import { TermsConditions } from "../../../../../../../../components/request-rights/components/terms-conditions/terms-conditions";
import { SidebarItemTypes } from "../../utils/meta/consts";
import { CheckboxColors, dropdownStyles } from "../../utils/common/common";
import { getSocialConnectionSubaccountDrodpown } from "../../../../../../../account-settings/actions/social-connections/social-connections.fat-actions";
import { handleApiError } from "../../../../../../../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import DropdownInfiniteList from "../../../../../../../../components/interface/dropdown/lists/infinite-list/dropdown-infinite-list";
import { setRequestRightStep } from "../../../../../../../../actions/request-rights/request-rights.actions";
import { REQUEST_RIGHTS_STEP } from "../../../../../../../../components/request-rights/request-rights";
import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "../../../../../../../../components/interface/typography/typography";
import { EmailTemplates } from "../../../../../../../../types/email-templates";
const SocialFeedFormInput = ({
  inputValue,
  formik,
  currentActiveSocialFeedSource,
  accountSourceType,
  defaultList,
  setHashTagsCount,
  selectedType
}) => {
  const dispatch = useDispatch();
  const {
    type,
    advanced,
    advancedLabel,
    label,
    required,
    icon,
    id,
    placeholder,
    disabled,
    styles,
    clearSubAccountsDropdown
  } = inputValue;
  const socialSubAccounts = useSelector((state) => {
    var _a, _b;
    return (_b = (_a = state.socialConnections) == null ? void 0 : _a.dropdown) == null ? void 0 : _b.subAccounts;
  });
  const currentAccountId = useSelector((state) => {
    var _a, _b;
    return (_b = (_a = state.socialConnections) == null ? void 0 : _a.dropdown) == null ? void 0 : _b.id;
  });
  const isPending = useSelector((state) => {
    var _a;
    return (_a = state.socialConnections) == null ? void 0 : _a.isSubAccountsPending;
  });
  const [filterValue, setFilterValue] = useState("");
  const isAbleToLoad = useSelector((state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.socialConnections) == null ? void 0 : _a.dropdown) == null ? void 0 : _b.links) == null ? void 0 : _c.next;
  });
  const onLoadData = () => {
    dispatch(
      getSocialConnectionSubaccountDrodpown({
        socialConnectionId: currentAccountId,
        socialConnectionUrl: isAbleToLoad,
        isReFetch: true
      })
    ).catch((err) => handleApiError(err, toast.error));
  };
  const fetchFilteredSocialConnectionSubaccounts = () => {
    dispatch(
      getSocialConnectionSubaccountDrodpown({ socialConnectionId: currentAccountId, query: filterValue })
    ).catch(() => toast("Cannot fetch subaccounts list"));
  };
  const onInputChange = (query) => setFilterValue(query);
  useEffect(() => {
    if (type !== SidebarItemTypes.SELECT) return;
    if (filterValue === void 0 || !currentAccountId) return;
    fetchFilteredSocialConnectionSubaccounts();
  }, [filterValue]);
  const renderInput = (type2) => {
    var _a;
    switch (type2) {
      case SidebarItemTypes.ACCOUNT:
        return /* @__PURE__ */ React.createElement(
          AccountDropdown,
          {
            activeSocialFeedSource: currentActiveSocialFeedSource,
            accountSourceType,
            label,
            icon,
            id,
            placeholder,
            disabled,
            formik,
            clearSubAccountsDropdown,
            selectedType
          }
        );
      case SidebarItemTypes.EXPERIENCE_SELECT:
        return /* @__PURE__ */ React.createElement(
          ExperienceDropdown,
          {
            label,
            icon,
            id,
            placeholder,
            disabled,
            formik
          }
        );
      case SidebarItemTypes.EMAIL_TEMPLATE_SELECT:
        return /* @__PURE__ */ React.createElement(InputGroup, { label, inputGroupIcon: icon, error: formik.errors[id], touched: formik.touched[id] }, /* @__PURE__ */ React.createElement(
          DropdownEmailTemplates,
          {
            type: EmailTemplates.REVIEW_REPLY,
            onChange: (e) => {
              formik.setFieldValue([id], { value: e.value, name: e.name });
            },
            currentElement: formik.values[id]
          }
        ));
      case SidebarItemTypes.FORM_SELECT:
        return /* @__PURE__ */ React.createElement(
          FormDropdown,
          {
            advanced,
            advancedLabel,
            label,
            icon,
            id,
            placeholder,
            disabled,
            formik
          }
        );
      case SidebarItemTypes.SELECT:
        return /* @__PURE__ */ React.createElement(InputGroup, { label, inputGroupIcon: icon, error: formik.errors[id], touched: formik.touched[id] }, /* @__PURE__ */ React.createElement(
          DropdownButton,
          {
            styles: dropdownStyles,
            dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
            dropdownType: DropdownButtonType.SEARCH_INPUT,
            dropdownList: (props) => /* @__PURE__ */ React.createElement(
              DropdownInfiniteList,
              __spreadProps(__spreadValues({}, props), {
                isDefaultOption: false,
                onLoadData,
                isAbleToLoad,
                onInputChange: (e) => onInputChange(e)
              })
            ),
            currentItem: formik.values[id],
            list: defaultList ? defaultList : socialSubAccounts.map((account) => {
              var _a2, _b, _c, _d, _e;
              return {
                name: ((_a2 = account.attributes) == null ? void 0 : _a2.name) ? (_b = account.attributes) == null ? void 0 : _b.name : (_c = account.attributes) == null ? void 0 : _c.username,
                value: {
                  id: account.id,
                  recently_searched_hashtag_count: (_e = (_d = account.attributes) == null ? void 0 : _d.meta) == null ? void 0 : _e.recently_searched_hashtag_count
                }
              };
            }),
            placeholder,
            iconRight: "fa fa-caret-down",
            buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
            name: id,
            onChangeValue: (selectedItem) => {
              var _a2;
              setHashTagsCount((_a2 = selectedItem.value) == null ? void 0 : _a2.recently_searched_hashtag_count);
              formik.setFieldValue([id], { value: selectedItem.value.id, name: selectedItem.name });
            },
            disabled: disabled && disabled(
              formik.values,
              socialSubAccounts || isPending || filterValue ? (socialSubAccounts == null ? void 0 : socialSubAccounts.length) === 0 && !isPending && !filterValue : true
            )
          }
        ));
      case SidebarItemTypes.TEXT:
        return /* @__PURE__ */ React.createElement(
          InputGroup,
          {
            required,
            label,
            inputGroupIcon: icon,
            error: formik.errors[id],
            touched: formik.touched[id]
          },
          /* @__PURE__ */ React.createElement(
            InputPrimary,
            {
              label,
              placeholder,
              id,
              name: id,
              type: "text",
              value: formik.values[id],
              handleChange: formik.handleChange,
              handleBlur: formik.handleBlur,
              disabled: disabled && disabled(formik.values, socialSubAccounts ? (socialSubAccounts == null ? void 0 : socialSubAccounts.length) === 0 : true)
            }
          )
        );
      case SidebarItemTypes.CHECKBOX:
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            color: CheckboxColors.BLUE,
            borderColor: CheckboxColors.GRAY,
            markColor: CheckboxColors.WHITE,
            backgroundColor: CheckboxColors.WHITE,
            id,
            defaultChecked: (_a = formik.values.checked) == null ? void 0 : _a.includes(id),
            onStatusChange: (_, event) => {
              formik.handleChange(event);
            },
            type: CheckboxButtonType.PRIMARY,
            name: "checked",
            value: id
          }
        ), /* @__PURE__ */ React.createElement("p", null, label));
      case SidebarItemTypes.TEXTAREA:
        return /* @__PURE__ */ React.createElement(
          EditTextArea,
          {
            placeholder,
            disabled: disabled && disabled(formik.values),
            name: id,
            required,
            defaultValue: formik.values[id],
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            label,
            styles,
            errorMsg: formik.errors[id],
            touched: formik.touched[id]
          }
        );
      case SidebarItemTypes.TERMS_CONDITIONS:
        return /* @__PURE__ */ React.createElement(
          TermsConditions,
          {
            onSelect: (termId) => formik.setFieldValue(id, termId),
            onEditClick: () => dispatch(setRequestRightStep(REQUEST_RIGHTS_STEP.TERMS_CONDITIONS))
          }
        );
      case SidebarItemTypes.LABEL:
        return /* @__PURE__ */ React.createElement(Typography, { color: TypographyColors.PRIMARY, variant: TypographyVariants.LABEL }, label);
      case SidebarItemTypes.DATE_TEXT:
        return /* @__PURE__ */ React.createElement("div", { className: "social-feed-form__date-inputs" }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Start Date", error: formik.errors["start_at"], touched: formik.touched["start_at"] }, /* @__PURE__ */ React.createElement(
          InputPrimary,
          {
            label: "Start Date",
            name: "start_at",
            id: "start_at",
            type: "text",
            value: formik.values["start_at"] || "",
            placeholder,
            handleChange: formik.handleChange,
            handleBlur: formik.handleBlur
          }
        )), /* @__PURE__ */ React.createElement(InputGroup, { label: "End Date", error: formik.errors["end_at"], touched: formik.touched["end_at"] }, /* @__PURE__ */ React.createElement(
          InputPrimary,
          {
            label: "End Date",
            name: "end_at",
            id: "end_at",
            type: "text",
            value: formik.values["end_at"] || "",
            placeholder,
            handleChange: formik.handleChange,
            handleBlur: formik.handleBlur
          }
        )));
      default:
        return null;
    }
  };
  return renderInput(type);
};
export default React.memo(SocialFeedFormInput);
