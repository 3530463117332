var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/collaborators/collaborators.actions";
import { produce } from "immer";
export const initialState = {
  data: [],
  included: [],
  links: void 0,
  meta: void 0,
  isFetching: false,
  error: void 0,
  selectedCollaborators: []
};
export const collaborators = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_COLLABORATORS.REQUEST:
    case ACTIONS.BULK_DELETE_COLLABORATORS.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.DELETE_COLLABORATOR.REQUEST:
    case ACTIONS.PATCH_COLLABORATOR.REQUEST:
    case ACTIONS.BULK_PATCH_COLLABORATORS.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        error: void 0
      });
    case ACTIONS.POST_COLLABORATOR.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        error: void 0
      });
    case ACTIONS.GET_COLLABORATORS.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
        included: action.payload.included,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_COLLABORATOR.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        data: state.data.filter((e) => e.id !== action.payload),
        error: void 0
      });
    case ACTIONS.PATCH_COLLABORATOR.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload.data.id ? action.payload.data : e),
        included: state.included.concat(
          action.payload.included ? action.payload.included.filter((e) => !state.included.some((r) => r.id === e.id)) : []
        ),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.BULK_PATCH_COLLABORATORS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => {
          return action.payload.data.find((d) => d.id === e.id) ? action.payload.data.find((d) => d.id === e.id) : e;
        }),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.POST_COLLABORATOR.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [...action.payload.data, ...state.data],
        included: state.included ? state.included.concat(
          action.payload.included ? action.payload.included.filter((e) => !state.included.some((r) => r.id === e.id)) : []
        ) : [],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_COLLABORATORS.FAILURE:
    case ACTIONS.POST_COLLABORATOR.FAILURE:
    case ACTIONS.DELETE_COLLABORATOR.FAILURE:
    case ACTIONS.PATCH_COLLABORATOR.FAILURE:
    case ACTIONS.BULK_PATCH_COLLABORATORS.FAILURE:
    case ACTIONS.BULK_DELETE_COLLABORATORS.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false
      });
    case ACTIONS.SELECT_COLLABORATOR:
      return __spreadProps(__spreadValues({}, state), {
        selectedCollaborators: [...state.selectedCollaborators, action.payload]
      });
    case ACTIONS.UNSELECT_COLLABORATOR:
      return __spreadProps(__spreadValues({}, state), {
        selectedCollaborators: state.selectedCollaborators.filter(({ id }) => {
          return action.payload !== id;
        })
      });
    case ACTIONS.DESELECT_ALL_COLLABORATORS:
      return __spreadProps(__spreadValues({}, state), {
        selectedCollaborators: []
      });
    case ACTIONS.BULK_DELETE_COLLABORATORS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = draftState.data.filter((collaborator) => {
          return !action.payload.some((c) => c.id === collaborator.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedCollaborators = [];
      });
    default:
      return state;
  }
};
export const getAlreadyInvitedEmails = (state) => state ? state.map((e) => e.attributes.email) : [];
export const filterCollaboratorsRoles = (collaborators2) => {
  const _state = collaborators2.data;
  return _state ? _state.map((collaborator) => {
    return __spreadProps(__spreadValues({}, collaborator), {
      role: collaborators2.included && !!collaborator.relationships.role.data ? collaborators2.included.find((e) => e.id === collaborator.relationships.role.data.id) : null
    });
  }) : [];
};
