import React, { Component, Fragment } from "react";
import "./edit-modal-step.sass";
import PropTypes from "prop-types";
import { Button } from "../../../components/interface/button/button";
export class EditModalStep extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal__header" }, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal__header__title" }, this.props.title), /* @__PURE__ */ React.createElement(
      "i",
      {
        className: "fal fa-times",
        onClick: () => {
          this.props.closeModalMain && this.props.closeModalMain();
          this.props.closeModal && this.props.closeModal();
        }
      }
    )), /* @__PURE__ */ React.createElement("div", { className: `tint-edit-modal__body ${this.props.type ? `tint-edit-modal__body--${this.props.type}` : ""}` }, this.props.children), /* @__PURE__ */ React.createElement("div", { className: `tint-edit-modal__footer ${this.props.footer ? "" : "tint-edit-modal__footer--default"}` }, this.props.footer ? React.cloneElement(this.props.footer, {
      saveData: this.props.customSaveData || this.props.saveData,
      onModalClose: this.props.closeModalMain,
      goToNextStep: this.props.nextStep,
      goToPreviousStep: this.props.previousStep
    }) : /* @__PURE__ */ React.createElement(Fragment, null, this.props.previousStep ? /* @__PURE__ */ React.createElement(Button, { onClick: this.props.previousStep, type: "secondary", size: "long", text: "Previous" }) : /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.props.closeModalMain && this.props.closeModalMain();
          this.props.onCancelClick && this.props.onCancelClick();
        },
        type: "secondary",
        size: "long",
        text: "Cancel"
      }
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        isDisabled: this.props.isApplyButtonDisabled,
        onClick: this.props.saveData,
        type: "primary",
        size: "long",
        text: "Apply"
      }
    ))));
  }
}
EditModalStep.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  isApplyButtonDisabled: PropTypes.bool,
  dismissModal: PropTypes.func,
  customSaveData: PropTypes.func,
  saveData: PropTypes.func,
  closeModal: PropTypes.func,
  closeModalMain: PropTypes.func,
  onCancelClick: PropTypes.func,
  footer: PropTypes.object,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  type: PropTypes.string
};
