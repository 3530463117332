import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import "./edit-cookie-banner-content.sass";
import { EditInput, EditTextArea } from "../../../";
export class EditCookieBannerContent extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, null, /* @__PURE__ */ React.createElement(
      "form",
      {
        className: "edit-cookie-banner-content",
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: "edit-cookie-banner__group" }, /* @__PURE__ */ React.createElement(
        EditTextArea,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.cookieText, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.cookieText),
          label: "Banner Message",
          placeholder: "Sample text"
        }
      )),
      /* @__PURE__ */ React.createElement("div", { className: "edit-cookie-banner__group" }, /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.cookieAllowButton, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.cookieAllowButton),
          label: "Accept Button Text",
          placeholder: "Allow"
        }
      ), /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.cookieDismissButton, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.cookieDismissButton),
          label: "Dismiss  Button Text",
          placeholder: "Decline Optional Cookies"
        }
      )),
      /* @__PURE__ */ React.createElement("div", { className: "edit-cookie-banner__group" }, /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.cookiePolicyText, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.cookiePolicyText),
          label: "Cookie Policy",
          placeholder: "Cookie Policy"
        }
      ), /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.cookiePolicyHref, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.cookiePolicyHref),
          placeholder: "Enter URL"
        }
      ), /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.privacyPolicyText, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.privacyPolicyText),
          label: "Privacy Policy",
          placeholder: "Privacy Policy"
        }
      ), /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (e) => {
            this.props.onTranslationUpdate(this.props.data.content.privacyPolicyHref, e.target.value);
          },
          defaultValue: this.props.getTranslation(this.props.data.content.privacyPolicyHref),
          placeholder: "Enter URL"
        }
      )),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit")
    ));
  }
}
