var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./tint-editor-manage-cta-sidebar-wrapper.sass";
import TintSidebarSwitcher, {
  TintSidebarSwitcherStateType
} from "../../../../../../components/sidebar-switcher/sidebar-switcher";
import TintEditorManageCtaSidebar from "../mange-cta-sidebar/tint-editor-manage-cta-sidebar";
import { getMappedSavedFilters } from "../../../../reducers/posts.reducer";
import {
  applyCTAAssociations,
  deleteCTA,
  deleteCTAAssociations,
  patchCTAS,
  postCTAAssociations,
  postCTAS
} from "../../../../actions/tint-editor.fat-actions";
import TintEditorCtaListSidebar from "../cta-list-sidebar/tint-editor-cta-list-sidebar";
import { toast } from "react-toastify";
const TintEditorManageCtaSidebarWrapper = ({ tintId, isVisible, onClose, CTAModalDetails }) => {
  const dispatch = useDispatch();
  const [modalDetails, setModalDetails] = useState({
    isVisible: false
  });
  useEffect(() => {
    isVisible && setCTAMainStateSidebar();
  }, [isVisible]);
  const tintEditorSavedFilters = useSelector((state) => getMappedSavedFilters(state));
  const onCloseSidebar = () => {
    setModalDetails((s) => __spreadProps(__spreadValues({}, s), {
      isVisible: false
    }));
    onClose();
  };
  const postCtaAssociations = (data, cta) => {
    dispatch(postCTAAssociations(tintId, __spreadValues(__spreadProps(__spreadValues({}, cta), { savedFilterId: data.savedFilterId }), data))).then(setCTAMainStateSidebar).catch(() => toast.error("Something went wrong, try again later"));
  };
  const addCTAAssociations = (data, cta) => {
    dispatch(applyCTAAssociations(tintId, __spreadValues(__spreadProps(__spreadValues({}, cta), { savedFilterId: data.savedFilterId }), data))).then(setCTAMainStateSidebar).catch(() => {
      toast.error("Something went wrong, try again later");
    });
  };
  const removeCTAAssociations = (data, ctaId, ctaAssociationId) => {
    dispatch(deleteCTAAssociations(tintId, ctaId, ctaAssociationId)).then(setCTAMainStateSidebar).catch(() => {
      toast.error("Something went wrong, try again later");
    });
  };
  const onRemoveCTA = (id) => {
    dispatch(deleteCTA(tintId, id)).then(setCTAMainStateSidebar).catch(() => toast.error("Something went wrong, try again later"));
  };
  const updateCTA = (data, id) => {
    dispatch(patchCTAS(tintId, __spreadProps(__spreadValues({}, data), { savedFilterId: void 0 }), id)).then((cta) => {
      if (data.savedFilterId) {
        postCtaAssociations(data, cta);
      }
    }).catch(() => toast.error("Something went wrong, try again later")).finally(setCTAMainStateSidebar);
  };
  const postCTA = (data) => {
    dispatch(postCTAS(tintId, __spreadProps(__spreadValues({}, data), { savedFilter: void 0 }))).then((cta) => {
      if (data.savedFilterId) {
        postCtaAssociations(data, cta);
      }
    }).catch(() => toast.error("Something went wrong, try again later")).finally(setCTAMainStateSidebar);
  };
  const onCTASSubmit = (data, state, id) => {
    switch (state) {
      case TintSidebarSwitcherStateType.EDIT:
        updateCTA(data, id);
        return;
      case TintSidebarSwitcherStateType.CREATE:
      default:
        postCTA(data);
        break;
    }
  };
  const onAssignCta = (cta) => {
    var _a;
    addCTAAssociations({ postId: ((_a = CTAModalDetails == null ? void 0 : CTAModalDetails.postId) == null ? void 0 : _a[0]) || (CTAModalDetails == null ? void 0 : CTAModalDetails.postId) }, cta);
  };
  const onUnAssignCTA = (ctaId, ctaAssociationId) => {
    var _a;
    removeCTAAssociations({ postId: ((_a = CTAModalDetails == null ? void 0 : CTAModalDetails.postId) == null ? void 0 : _a[0]) || (CTAModalDetails == null ? void 0 : CTAModalDetails.postId) }, ctaId, ctaAssociationId);
  };
  const setCTAMainStateSidebar = () => {
    setModalDetails({
      isVisible: true,
      state: TintSidebarSwitcherStateType.MAIN,
      headerTitle: "Manage CTAs",
      component: (props) => {
        var _a;
        return /* @__PURE__ */ React.createElement(
          TintEditorCtaListSidebar,
          __spreadProps(__spreadValues({}, props), {
            postId: ((_a = CTAModalDetails == null ? void 0 : CTAModalDetails.postId) == null ? void 0 : _a[0]) || (CTAModalDetails == null ? void 0 : CTAModalDetails.postId),
            isAssignMode: CTAModalDetails == null ? void 0 : CTAModalDetails.isAssignMode,
            onAssignCTA: onAssignCta,
            onUnAssignCTA
          })
        );
      }
    });
  };
  const setCTASidebarState = (state, data) => {
    switch (state) {
      case TintSidebarSwitcherStateType.CREATE:
        setModalDetails({
          isVisible: true,
          headerTitle: "Create CTA",
          state: TintSidebarSwitcherStateType.CREATE,
          data: {
            filters: tintEditorSavedFilters
          },
          component: (props) => /* @__PURE__ */ React.createElement(TintEditorManageCtaSidebar, __spreadValues({}, props))
        });
        break;
      case TintSidebarSwitcherStateType.EDIT:
        setModalDetails({
          isVisible: true,
          headerTitle: "Edit CTA",
          state: TintSidebarSwitcherStateType.EDIT,
          data: __spreadProps(__spreadValues({}, data), {
            filters: tintEditorSavedFilters
          }),
          component: (props) => /* @__PURE__ */ React.createElement(TintEditorManageCtaSidebar, __spreadProps(__spreadValues({}, props), { onRemove: onRemoveCTA }))
        });
        break;
      case TintSidebarSwitcherStateType.MAIN:
        setCTAMainStateSidebar();
        break;
      default:
        return;
    }
  };
  return modalDetails.isVisible && /* @__PURE__ */ React.createElement(
    TintSidebarSwitcher,
    {
      closeSidebarAfterSubmit: false,
      onClose: onCloseSidebar,
      modalDetails,
      onChangeContent: setCTASidebarState,
      onSubmit: onCTASSubmit
    }
  );
};
export default TintEditorManageCtaSidebarWrapper;
