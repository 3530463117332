import { SocialFeedsFooterLabels, twitterDisabledButton } from "./helpers";
import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import {
  validationSchemaTwitterAdvanced,
  validationSchemaTwitterHashtag,
  validationSchemaTwitterLikes,
  validationSchemaTwitterLists,
  validationSchemaTwitterUsername
} from "../utils/validation-schema/twitter-schema";
const defaultPreProcessingRuleValue = (socialFeed) => {
  var _a, _b;
  return typeof ((_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.pre_processing_rules) === "object" && ((_b = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _b.pre_processing_rules.length) === 0 ? null : "pre_processing_rules";
};
export const twitter = [
  {
    header: "Add X",
    description: "Choose a Social Feed type below:",
    typeList: [
      {
        id: "hashtag",
        title: "Hashtag",
        description: "Import Tweets containing an X Hashtag"
      },
      {
        id: "username",
        title: "Username",
        description: "Import Tweets from an X Username"
      },
      {
        id: "favorites",
        title: "Likes",
        description: "Import \u201CLikes\u201D from an X Username."
      },
      {
        id: "list",
        title: "Lists",
        description: "Pull in Tweets from an X List"
      },
      {
        id: "geocode",
        title: "Advanced",
        description: "Enter your own query to aggregate content"
      }
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext
    },
    zendeskUrl: void 0,
    isAlert: false,
    formSchema: void 0
  },
  {
    header: void 0,
    description: void 0,
    zendeskUrl: void 0,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave
    },
    accountSourceType: "twitter",
    formSchema: {
      hashtag: {
        validationSchema: validationSchemaTwitterHashtag(),
        submitMapper: (values) => ({
          search_term: `#${values.hashtag}`,
          account_id: values.account.value,
          pre_processing_rules: values.checked.find((i) => i === "pre_processing_rules") ? [
            {
              conditions: {
                media_type: ["image", "video"]
              }
            }
          ] : null
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "hashtag",
            backendId: "search_term",
            label: "ENTER HASHTAG",
            placeholder: "Hashtag",
            description: "Enter an X Hashtag that has been active in the last seven days.",
            link: "https://support.tintup.com/hc/en-us/articles/231579407",
            icon: SidebarIcons.HASHTAG,
            type: SidebarItemTypes.TEXT,
            disabled: twitterDisabledButton
          },
          {
            id: "pre_processing_rules",
            backendId: "pre_processing_rules",
            label: "Only ingest posts that contain media",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: defaultPreProcessingRuleValue,
            disabled: twitterDisabledButton
          }
        ],
        editInputs: [
          {
            id: "pre_processing_rules",
            backendId: "pre_processing_rules",
            label: "Only ingest posts that contain media",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: defaultPreProcessingRuleValue
          }
        ]
      },
      username: {
        validationSchema: validationSchemaTwitterUsername(),
        submitMapper: (values) => ({
          search_term: values.username,
          account_id: values.account.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "username",
            backendId: "search_term",
            label: "ENTER USERNAME",
            placeholder: "Username",
            description: 'Enter the username that you want to import "Tweets" from.',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
            disabled: twitterDisabledButton
          }
        ]
      },
      favorites: {
        validationSchema: validationSchemaTwitterLikes(),
        submitMapper: (values) => ({
          search_term: values.username,
          account_id: values.account.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "username",
            backendId: "search_term",
            label: "ENTER USERNAME",
            placeholder: "Username",
            description: 'Enter the username that you want to import "Likes" from.',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
            disabled: twitterDisabledButton
          }
        ]
      },
      list: {
        validationSchema: validationSchemaTwitterLists(),
        submitMapper: (values) => ({
          search_term: `${values.username}/${values.list}`,
          account_id: values.account.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "username",
            backendId: "search_term",
            label: "ENTER USERNAME",
            placeholder: "Username",
            description: "Find a list on X and enter the URL as shown below:",
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
            disabled: twitterDisabledButton
          },
          {
            id: "list",
            label: "ENTER LIST NAME",
            placeholder: "List name",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.TEXT,
            disabled: twitterDisabledButton
          }
        ]
      },
      geocode: {
        validationSchema: validationSchemaTwitterAdvanced(),
        submitMapper: (values) => ({
          search_term: values.geocode,
          account_id: values.account.value,
          pre_processing_rules: values.checked.find((i) => i === "pre_processing_rules") ? [
            {
              conditions: {
                media_type: ["image", "video"]
              }
            }
          ] : []
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "geocode",
            backendId: "search_term",
            label: "ENTER QUERY",
            placeholder: "Advanced query",
            description: "Type an advanced query to pull the exact content that you want to display.",
            link: "https://support.tintup.com/hc/en-us/articles/115002463473",
            icon: SidebarIcons.SEARCH,
            type: SidebarItemTypes.TEXT,
            disabled: twitterDisabledButton
          },
          {
            id: "pre_processing_rules",
            backendId: "pre_processing_rules",
            label: "Only ingest posts that contain media",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: defaultPreProcessingRuleValue,
            disabled: twitterDisabledButton
          }
        ],
        editInputs: [
          {
            id: "pre_processing_rules",
            backendId: "pre_processing_rules",
            label: "Only ingest posts that contain media",
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: defaultPreProcessingRuleValue
          }
        ]
      }
    }
  }
];
