import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      "Welcome to React": "PIPIPI"
    }
  },
  fr: {
    translation: {
      "Welcome to React": "WOWOWOWOW"
    }
  }
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en_US",
  // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option
  interpolation: {
    escapeValue: false
    // react already safes from xss
  }
}).catch((e) => {
  console.log("i18n error", e);
});
export default i18n;
