var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./timer-section.sass";
import { CountDown } from "./count-down/count-down";
import { EditTimerContent } from "./edit/edit-timer-content";
import { EditTimerStyles } from "./edit/edit-timer-styles";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { dropDownType } from "../../../../../../model/poll-image-type.model";
const currentDate = /* @__PURE__ */ new Date();
const today = new Date(currentDate.setDate(currentDate.getDate()));
class TimerSection extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "isCountDownFinished", () => {
      return new Date(this.props.data.content.endDate).getTime() - Date.now() <= 0;
    });
  }
  getClassBasedOnWidth() {
    if (this.props.isPreviewMode) {
      return "";
    }
    return `tint-page-section--timer--${this.props.baseClass}`;
  }
  getDateFormat(date) {
    const dateObject = new Date(date);
    return dateObject.toLocaleString(
      this.props.data.content.dateType === void 0 || this.props.data.content.dateType === dropDownType.US ? "en-US" : "en-GB",
      {
        hour: "numeric",
        minute: "numeric",
        month: "numeric",
        day: "numeric",
        year: "numeric"
      }
    );
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--timer ${this.props.getSectionClass()} ${this.getClassBasedOnWidth()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      this.props.data.content.fixedTitle ? /* @__PURE__ */ React.createElement(
        "span",
        {
          className: "tint-page-section--timer__time-left",
          style: {
            color: ColorParser.defaultColor(this.props.data.styles.headingTextColor)
          }
        },
        this.props.data.content.fixedTitle
      ) : null,
      /* @__PURE__ */ React.createElement("div", { className: "timer-count-down-container" }, /* @__PURE__ */ React.createElement(
        CountDown,
        {
          textDirection: this.props.textDirection,
          endDate: this.props.data.content.endDate,
          styles: this.props.data.styles,
          content: this.props.data.content,
          isCountDownFinished: this.isCountDownFinished,
          getTranslation: this.props.getTranslation
        }
      ), this.isCountDownFinished() || !this.props.getTranslation(this.props.data.content.endTitle) ? null : /* @__PURE__ */ React.createElement(
        "span",
        {
          className: "timer-count-down-container__end-title",
          style: {
            color: ColorParser.defaultColor(this.props.data.styles.labelTextColor)
          }
        },
        this.props.getTranslation(this.props.data.content.endTitle),
        " ",
        this.getDateFormat(this.props.data.content.endDate)
      )),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Timer" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditTimerContent,
          {
            data: this.props.data.content,
            dataUpdate: this.props.onContentUpdate,
            minDate: today,
            getTranslation: this.props.getTranslation,
            onTranslationUpdate: this.props.onTranslationUpdate
          }
        ), /* @__PURE__ */ React.createElement(
          EditTimerStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      )
    );
  }
}
export default PageSectionHoc(TimerSection);
