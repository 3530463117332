var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./footer-section.sass";
import { EditFooterContent } from "./edit/edit-footer-content";
import { EditFooterStyles } from "./edit/edit-footer-styles";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { Config } from "../../../../../../utils/config";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { SectionLogo } from "../../logo/section-logo";
import { pageSectionType } from "../../../../../../model/page-section-type.model";
import Logger from "../../../../../../services/logger/logger";
class FooterSection extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "openCookieBanner", () => {
      try {
        const GDPRComplianceLocalStorageKey = `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`;
        const GDPRComplianceLocalStorageValue = JSON.parse(window.localStorage.getItem(GDPRComplianceLocalStorageKey));
        localStorage.setItem(
          GDPRComplianceLocalStorageKey,
          JSON.stringify(__spreadProps(__spreadValues({}, GDPRComplianceLocalStorageValue), {
            seen: null
          }))
        );
        window.scrollTo(0, 0);
        this.props.onOpenCookieBanner(pageSectionType.COOKIE_BANNER);
      } catch (e) {
        Logger.info(e);
      }
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--footer ${this.props.getSectionClass()}`,
        style: {
          color: ColorParser.defaultColor(this.props.data.styles.textColor),
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { closeModal: this.props.dismissModal, title: "Edit Footer" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditFooterContent,
          {
            experienceType: this.props.experience.attributes.type,
            maxFileSize: this.props.getMaxImageSizePlanLimit(),
            data: this.props.data.content,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            getTranslation: this.props.getTranslation,
            onTranslationUpdate: this.props.onTranslationUpdate,
            onNewTranslationUpdate: this.props.onNewTranslationUpdate,
            cookieEnabled: this.props.cookieEnabled
          }
        ), /* @__PURE__ */ React.createElement(
          EditFooterStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      ),
      /* @__PURE__ */ React.createElement(
        SectionLogo,
        {
          logoHeight: this.props.data.styles.logoHeight,
          logoRedirectUrl: this.props.data.content.logoRedirectUrl,
          logoUrl: this.props.data.content.logoUrl
        }
      ),
      /* @__PURE__ */ React.createElement("div", { className: "tint-page-section__footer-information" }, this.props.getTranslation(this.props.data.content.footerCopyright) ? /* @__PURE__ */ React.createElement("p", { className: "tint-page-section__footer-information--copyrights" }, this.props.getTranslation(this.props.data.content.footerCopyright)) : null, /* @__PURE__ */ React.createElement("ul", { className: "footer-section-nav" }, this.props.data.content.footerLinks.map(
        (e, i) => e.text ? /* @__PURE__ */ React.createElement("li", { key: i }, /* @__PURE__ */ React.createElement(
          "a",
          {
            style: {
              color: ColorParser.defaultColor(this.props.data.styles.textColor)
            },
            rel: "noopener noreferrer",
            href: Config.getValidRedirectUrl(this.props.getTranslation(e.url)),
            target: "_blank"
          },
          this.props.getTranslation(e.text)
        )) : null
      ), this.props.cookieEnabled ? /* @__PURE__ */ React.createElement("li", { key: "menage-key" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          style: {
            color: ColorParser.defaultColor(this.props.data.styles.textColor)
          },
          onClick: this.openCookieBanner,
          rel: "noopener noreferrer"
        },
        this.props.getTranslation(this.props.data.content.manageCookiesLabel) ? this.props.getTranslation(this.props.data.content.manageCookiesLabel) : "Manage Cookies"
      )) : null))
    );
  }
}
export default PageSectionHoc(FooterSection);
