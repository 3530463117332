export const thumbScale = {
  THUMB_SCALE: ".input-thumb-scale",
  THUMB_SCALE_LABEL: ".input-thumb-scale label",
  THUMB_SCALE_INPUT: '.input-thumb-scale input[type="radio"]',
  THUMB_SCALE_INPUT_BEFORE: '.input-thumb-scale input[type="radio"]:before',
  THUMB_SCALE_LABEL_ACTIVE: ".input-thumb-scale label:has(input:checked), .input-thumb-scale label:hover",
  THUMB_SCALE_LABEL_THUMB_UP: ".input-thumb-scale label:first-of-type input:before",
  THUMB_SCALE_LABEL_THUMB_UP_ACTIVE: ".input-thumb-scale label:first-of-type:has(input:checked) input:before, .input-thumb-scale label:first-of-type:hover input:before",
  THUMB_SCALE_LABEL_THUMB_DOWN: ".input-thumb-scale label:nth-of-type(2) input:before",
  THUMB_SCALE_LABEL_THUMB_DOWN_ACTIVE: ".input-thumb-scale label:nth-of-type(2):has(input:checked) input:before, .input-thumb-scale label:nth-of-type(2):hover input:before",
  THUMB_SCALE_RTL: "*[dir='rtl'] .input-thumb-scale label:first-of-type"
};
