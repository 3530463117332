import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  POST_ASSET: createRoutine(createPrefixedActionName("ASSET_MANAGER_TRANSFORM_UI", "POST_ASSET"))
};
export const postAssetStart = () => ({
  type: ACTIONS.POST_ASSET.REQUEST
});
export const postAssetSuccess = (data) => ({
  type: ACTIONS.POST_ASSET.SUCCESS,
  payload: data
});
export const postAssetError = (err) => ({
  type: ACTIONS.POST_ASSET.FAILURE,
  payload: err
});
