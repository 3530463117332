import { Config } from "../../../utils/config";
import { RolesAccessTypes } from "../../../feature/account-settings/components/roles/utils/filter-helper/filter-helper";
export const PermissionTypesTitles = {
  TEAM: "Team",
  COLLABORATORS: "Collaborators & Roles",
  COMMUNITY: "Community & Mission Hubs",
  TINTS: "Tint Boards",
  PERSONALIZATIONS: "Personalizations",
  MIXES: "TINTmix",
  UGC_STUDIO: "UGC Studio",
  SOCIAL_ACCOUNTS: "Accounts",
  FONTS: "Fonts",
  EXPERIENCE_BUILDER: "Experience Builder",
  DOMAINS: "Domains",
  FORM_SUBMISSIONS: "Form Submissions",
  PRODUCTS: "Products",
  PRODUCT_ACCOUNTS: "Product Accounts",
  PRODUCT_FEEDS: "Product Feeds",
  WEBHOOKS: "Webhooks",
  OAUTH_APPLICATIONS: "OAuth Applications",
  FORMS: "Form Builder"
};
export const permissionTypes = [
  {
    title: PermissionTypesTitles.TEAM,
    data: [
      {
        name: Config.permissions.changeBilling,
        title: "Can Make Purchases and View Billing",
        description: "Can update credit card on file, change plan type, make purchases, and view Payment panel"
      },
      {
        name: Config.permissions.teamSecurity,
        title: "Can Manage Security Settings",
        description: "Can manage security settings"
      }
    ]
  },
  {
    title: PermissionTypesTitles.COLLABORATORS,
    data: [
      {
        name: Config.permissions.teamsUsersRead,
        title: "Can View Collaborators & Roles",
        description: "Can view Collaborators and Roles"
      },
      {
        name: Config.permissions.teamsUsersCreate,
        title: "Can Invite/Modify Collaborators",
        description: "Can invite and modify Collaborators"
      },
      {
        name: Config.permissions.teamsUsersDelete,
        title: "Can Delete Collaborators",
        description: "Can delete Collaborators"
      },
      {
        name: Config.permissions.rolesCreate,
        title: "Can Create/Modify Roles",
        description: "Can create or modify Roles"
      },
      {
        name: Config.permissions.rolesDelete,
        title: "Can Delete Roles",
        description: "Can delete Roles"
      }
    ]
  },
  {
    title: PermissionTypesTitles.COMMUNITY,
    data: [
      {
        name: Config.permissions.communityUpdate,
        title: "Can Manage Community and Mission Hubs",
        description: "Can manage the community including creating, deleting and modifying mission hubs"
      }
    ]
  },
  {
    title: PermissionTypesTitles.TINTS,
    accessType: RolesAccessTypes.TINTS,
    data: [
      {
        name: Config.permissions.createTint,
        title: "Can Create Boards",
        description: "Can create a new TINT Board"
      },
      {
        name: Config.permissions.modifyTint,
        title: "Can Modify Boards",
        description: "Can change a TINT Board's Content and Personalization options"
      },
      {
        name: Config.permissions.moderateTint,
        title: "Can Moderate Boards",
        description: "Can approve or reject posts within a Board"
      },
      {
        name: Config.permissions.deleteTint,
        title: "Can Delete Boards",
        description: "Can delete a Board"
      }
    ]
  },
  {
    title: PermissionTypesTitles.PERSONALIZATIONS,
    accessType: RolesAccessTypes.PERSONALIZATIONS,
    data: [
      {
        name: Config.permissions.personalizationsRead,
        title: "Can View Personalizations",
        description: "Can view personalizations"
      },
      {
        name: Config.permissions.personalizationsEdit,
        title: "Can Create/Modify Personalizations",
        description: "Can create or modify personalizations"
      },
      {
        name: Config.permissions.personalizationsDelete,
        title: "Can Delete Personalizations",
        description: "Can delete personalizations"
      }
    ]
  },
  {
    title: PermissionTypesTitles.MIXES,
    accessType: RolesAccessTypes.MIXES,
    data: [
      {
        name: Config.permissions.mixesRead,
        title: "Can View Mixes",
        description: "Can view a Mix",
        require: "displayMix"
      },
      {
        name: Config.permissions.mixesCreate,
        title: "Can Create/Modify Mixes",
        description: "Can create or modify a Mix",
        require: "displayMix"
      },
      {
        name: Config.permissions.mixesDelete,
        title: "Can Delete Mixes",
        description: "Can delete a Mix",
        require: "displayMix"
      }
    ]
  },
  {
    title: PermissionTypesTitles.UGC_STUDIO,
    accessType: RolesAccessTypes.COLLECTIONS,
    data: [
      {
        name: Config.permissions.assetsRead,
        title: "Can View Assets",
        description: "Can view an Asset"
      },
      {
        name: Config.permissions.assetsEdit,
        title: "Can Create/Modify Assets",
        description: "Can create or modify an Asset"
      },
      {
        name: Config.permissions.assetsDelete,
        title: "Can Delete Assets",
        description: "Can delete an Asset"
      },
      {
        name: Config.permissions.assetsPublish,
        title: "Can Publish Assets",
        description: "Can Publish Assets",
        require: "marketing_integrations"
      }
    ]
  },
  {
    title: PermissionTypesTitles.SOCIAL_ACCOUNTS,
    accessType: RolesAccessTypes.SOCIAL_ACCOUNTS,
    data: [
      {
        name: Config.permissions.accountsRead,
        title: "Can Display Accounts",
        description: "Can display Accounts"
      },
      {
        name: Config.permissions.accountsCreate,
        title: "Can Create/Modify Accounts",
        description: "Can create or modify Accounts"
      },
      {
        name: Config.permissions.accountsDelete,
        title: "Can Delete Accounts",
        description: "Can delete Accounts"
      }
    ]
  },
  {
    title: PermissionTypesTitles.EXPERIENCE_BUILDER,
    accessType: RolesAccessTypes.EXPERIENCES,
    data: [
      {
        name: Config.permissions.experiencesRead,
        title: "Can View Experiences",
        description: "Can see Experiences"
      },
      {
        name: Config.permissions.experiencesCreate,
        title: "Can Create/Modify Experiences",
        description: "Can create and update Fonts"
      },
      {
        name: Config.permissions.experiencesDelete,
        title: "Can Delete Experiences",
        description: "Can delete Experiences"
      },
      {
        name: Config.permissions.experiencesPublish,
        title: "Can Publish changes to Experiences",
        description: "Can publish changes to Experiences"
      },
      {
        name: Config.permissions.formSubmissionsRead,
        title: "Can View Form Submissions",
        description: "Can view Form Submissions"
      },
      {
        name: Config.permissions.formSubmissionsDelete,
        title: "Can Delete Form Submissions",
        description: "Can delete Form Submissions"
      }
    ]
  },
  {
    title: PermissionTypesTitles.FONTS,
    accessType: RolesAccessTypes.FONTS,
    data: [
      {
        name: Config.permissions.fontsRead,
        title: "Can View Fonts",
        description: "Can view Fonts"
      },
      {
        name: Config.permissions.fontsUpload,
        title: "Can Upload Fonts",
        description: "Can upload Fonts"
      },
      {
        name: Config.permissions.fontsDelete,
        title: "Can Delete Fonts",
        description: "Can delete Fonts"
      }
    ]
  },
  {
    title: PermissionTypesTitles.DOMAINS,
    accessType: RolesAccessTypes.DOMAINS,
    data: [
      {
        name: Config.permissions.domainsRead,
        title: "Can View Domains",
        description: "Can view Domains",
        require: "domains"
      },
      {
        name: Config.permissions.domainsCreate,
        title: "Can Create/Modify Domains",
        description: "Can create and update Domains",
        require: "domains"
      },
      {
        name: Config.permissions.domainsDelete,
        title: "Can Delete Domains",
        description: "Can delete Domains",
        require: "domains"
      }
    ]
  },
  {
    title: PermissionTypesTitles.PRODUCTS,
    data: [
      {
        name: Config.permissions.postTagProducts,
        title: "Can Tag Products in Post",
        description: "Can tag Products in Post",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productsRead,
        title: "Can View Products",
        description: "Can view Products",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productsCreate,
        title: "Can Create/Modify Products",
        description: "Can create and update Products",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productsDelete,
        title: "Can Delete Products",
        description: "Can delete Products",
        require: "ecommerce"
      }
    ]
  },
  {
    title: PermissionTypesTitles.PRODUCT_ACCOUNTS,
    accessType: RolesAccessTypes.PRODUCT_ACCOUNTS,
    data: [
      {
        name: Config.permissions.productAccountsRead,
        title: "Can View Product Accounts",
        description: "Can view Product Accounts",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productAccountsCreate,
        title: "Can Create/Modify Product Accounts",
        description: "Can create and update Product Accounts",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productAccountsDelete,
        title: "Can Delete Product Accounts",
        description: "Can delete Product Accounts",
        require: "ecommerce"
      }
    ]
  },
  {
    title: PermissionTypesTitles.PRODUCT_FEEDS,
    accessType: RolesAccessTypes.PRODUCT_FEEDS,
    data: [
      {
        name: Config.permissions.productFeedsRead,
        title: "Can View Product Feeds",
        description: "Can view Product Feeds",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productFeedsCreate,
        title: "Can Create/Modify Product Feeds",
        description: "Can create and update Product Feeds",
        require: "ecommerce"
      },
      {
        name: Config.permissions.productFeedsDelete,
        title: "Can Delete Product Feeds",
        description: "Can delete Product Feeds",
        require: "ecommerce"
      }
    ]
  },
  {
    title: PermissionTypesTitles.WEBHOOKS,
    accessType: RolesAccessTypes.WEBHOOKS,
    data: [
      {
        name: Config.permissions.webhooksRead,
        title: "Can View Webhooks",
        description: "Can view Webhooks",
        require: "api"
      },
      {
        name: Config.permissions.webhooksCreate,
        title: "Can Create/Modify Webhooks",
        description: "Can create and update Webhooks",
        require: "api"
      },
      {
        name: Config.permissions.webhooksDelete,
        title: "Can Delete Webhooks",
        description: "Can delete Webhooks",
        require: "api"
      }
    ]
  },
  {
    title: PermissionTypesTitles.OAUTH_APPLICATIONS,
    accessType: RolesAccessTypes.OAUTH_APPLICATIONS,
    data: [
      {
        name: Config.permissions.oauthApplicationsRead,
        title: "Can View OAuth Applications",
        description: "Can view OAuth Applications",
        require: "api"
      },
      {
        name: Config.permissions.oauthApplicationsCreate,
        title: "Can Create/Modify OAuth Applications",
        description: "Can create and update OAuth Applications",
        require: "api"
      },
      {
        name: Config.permissions.oauthApplicationsDelete,
        title: "Can Delete OAuth Applications",
        description: "Can delete OAuth Applications",
        require: "api"
      }
    ]
  },
  {
    title: PermissionTypesTitles.FORMS,
    accessType: RolesAccessTypes.FORMS,
    data: [
      {
        name: Config.permissions.formsRead,
        title: "Can View Forms",
        description: "Can view Forms"
      },
      {
        name: Config.permissions.formsCreate,
        title: "Can Create/Modify Forms",
        description: "Can create and update Forms"
      },
      {
        name: Config.permissions.formsDelete,
        title: "Can Delete Forms",
        description: "Can delete Forms"
      },
      {
        name: Config.permissions.formsPublish,
        title: "Can Publish changes to Forms",
        description: "Can publish changes to Forms"
      }
    ]
  }
];
export const getAllPermissionsNames = () => permissionTypes.map((group) => group.data.map((permission) => permission.name)).flat();
export const hasOneOfThePermission = (permissions = {}, listToCheck = []) => {
  return permissions.all || listToCheck.length && listToCheck.some((e) => permissions[e]);
};
export const findPermissionByName = (name) => {
  return permissionTypes.reduce((acc, item) => {
    const data = item.data.filter((e) => e.name === name);
    return !data.length ? acc : Object.assign({}, item, { data });
  }, {});
};
export const getSelectedPermissions = (permissions) => {
  const getPermissions = permissions.map((item) => findPermissionByName(item));
  return getPermissions.reduce((acc, item) => {
    const exist = acc.find((a) => a.title === item.title);
    !exist ? acc.push({ title: item.title, data: item.data }) : exist.data.push(item.data[0]);
    return acc;
  }, []);
};
export const filteredPermissionsList = permissionTypes.map((group) => {
  return group.data.map((permission) => {
    return { value: permission.name, name: permission.title };
  });
}).flat();
