import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import "./reload-posts-button.sass";
import TintIcon from "../../../../components/interface/icon/icon";
import { useWebsocket } from "../../../../components/hooks/use-websocket/use-websocket";
import { getSocialFeedLength } from "../tint-editor/sidebars/social-feed-sidebar/reducers/social-feeds.selector";
export const ReloadPostsButton = ({ onClick, tintId }) => {
  const [isVisible, setVisibility] = useState(false);
  const posts = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.data);
  const socialFeedLength = useSelector(getSocialFeedLength);
  const [currentSocialFeedLength, setCurrentSocialFeedLength] = useState(0);
  useEffect(() => {
    manageVisibilityByAddingSocialFeed();
  }, [socialFeedLength]);
  const manageVisibilityByAddingSocialFeed = () => {
    if (currentSocialFeedLength === 0) {
      setCurrentSocialFeedLength(socialFeedLength);
    } else if (socialFeedLength !== currentSocialFeedLength && currentSocialFeedLength < socialFeedLength) {
      setVisibility(true);
    }
    setCurrentSocialFeedLength(socialFeedLength);
  };
  const onMessage = (data) => {
    let _data = data;
    if (typeof data === "string") {
      _data = JSON.parse(data);
    }
    if (Number(_data.tint_id) === Number(tintId)) {
      if (posts.length === 0) {
        setVisibility(true);
      } else {
        setVisibility(true);
      }
    }
  };
  useWebsocket({ onMessage, type: "posts_ingested" });
  const _onClick = () => {
    onClick == null ? void 0 : onClick();
    setVisibility(false);
  };
  return isVisible ? /* @__PURE__ */ React.createElement("div", { className: "tint-reload-posts-button", onClick: _onClick }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faRotate }), /* @__PURE__ */ React.createElement("span", { className: "tint-reload-posts-button__text" }, "New Posts")) : null;
};
