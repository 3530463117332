var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {
  postCustomFontError,
  postCustomFontStart,
  postCustomFontSuccess,
  deleteCustomFontError,
  deleteCustomFontStart,
  deleteCustomFontSuccess,
  getCustomFontsError,
  getCustomFontsStart,
  getCustomFontsSuccess
} from "./custom-fonts.actions";
import { toast } from "react-toastify";
import { ApiService } from "../../../../services/api-service/api-service";
export const getCustomFonts = ({ pageSize = void 0, paginationUrl = void 0 } = {}) => {
  const _url = `/fonts?${pageSize ? `page[size]=${pageSize}&` : ""}sort=-id`;
  return (dispatch) => {
    dispatch(getCustomFontsStart());
    return ApiService.get(paginationUrl || _url).then(function(res) {
      dispatch(getCustomFontsSuccess(res.data));
    }).catch((err) => {
      dispatch(getCustomFontsError(err));
      toast.error("Something went wrong");
    });
  };
};
export const deleteCustomFont = (id) => {
  return (dispatch) => {
    dispatch(deleteCustomFontStart());
    return ApiService.delete(`/fonts/${id}`).then(function() {
      dispatch(deleteCustomFontSuccess(id));
    }).catch((err) => {
      dispatch(deleteCustomFontError(err));
      throw err;
    });
  };
};
export const postCustomFont = (file) => {
  const body = {
    data: {
      type: "font",
      attributes: __spreadValues({
        upload_id: file.uploadId
      }, file.name ? { name: file.name } : {})
    }
  };
  return (dispatch) => {
    dispatch(postCustomFontStart());
    return ApiService.post("/fonts", body).then(function(res) {
      dispatch(postCustomFontSuccess(res.data));
    }).catch((err) => {
      dispatch(postCustomFontError(err));
      throw err;
    });
  };
};
