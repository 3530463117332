export const SocialAccountType = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  INSTAGRAM_BUSINESS: "instagram_business",
  HOOTSUITE: "hootsuite",
  LINKED_IN: "linkedin",
  TUMBLR: "tumblr",
  DELETED: "deleted",
  FORM_SUBMISSION: "form_submission",
  CUSTOM: "custom",
  FLICKR: "flickr"
};
export const SocialAccountTypeToReadableText = {
  custom: "Custom",
  email: "Email",
  externally_sourced_posts: "Browser Extensions",
  facebook: "Facebook",
  flickr: "Flickr",
  form_submission: "Form Submissions",
  google_news: "Google News",
  hootsuite: "Hootsuite",
  instagram_business: "Instagram Business",
  linkedin: "LinkedIn",
  pinterest: "Pinterest",
  public_post: "Public Post",
  review_trackers: "Review Trackers",
  rss: "RSS",
  slack: "Slack",
  sms: "SMS",
  spark: "Spark",
  tiktok: "TikTok",
  tumblr: "Tumblr",
  twitter: "X",
  youtube: "YouTube",
  scheduled_posts: "Scheduled Posts",
  reviews_ratings: "Reviews & Ratings",
  threads: "Threads",
  creator_iq: "CreatorIQ"
};
export const SocialAccountDropdownSubaccountLabel = {
  linkedin: "Select Company",
  facebook: "Select Page",
  instagram_business: "Select Business Account"
};
export const SocialAccountSubaccountTypeTranslation = {
  facebook: "Page",
  linkedin: "Company",
  instagram_business: "Account"
};
