const CheckboxColors = {
  WHITE: "#FFFFFF",
  GRAY: "#DFDFDF",
  BLUE: "#3B99FC"
};
const dropdownStyles = { maxWidth: "100%", width: "100%", height: "32px" };
const dropdownAdditionalSelectField = {
  name: "+ Add Account",
  value: "ADD_ACCOUNT"
};
const dropdownAdditionalFormSelectField = {
  name: "+ Add Form",
  value: "ADD_FORM"
};
const dropdownMissionHubsSelectField = {
  name: "+ Add Mission",
  value: "ADD_MISSION"
};
const dropdownAdditionalExperienceSelectField = {
  name: "+ Add Experience",
  value: "ADD_EXPERIENCE"
};
const dropdownDefaultSelectField = {
  name: "None",
  value: "none"
};
const postMessages = {
  SUCCESSFULLY_ADDED_SOCIAL_FEED: "SUCCESSFULLY_ADDED_SOCIAL_FEED"
};
export const languagesDropdown = {
  english: {
    name: "English",
    value: "en"
  },
  spanish: {
    name: "Spanish",
    value: "es"
  },
  french: {
    name: "French",
    value: "fr"
  },
  german: {
    name: "German",
    value: "de"
  },
  itialian: {
    name: "Itialian",
    value: "it"
  },
  chinese: {
    name: "Chinese",
    value: "ch"
  }
};
export const topicsDropdown = {
  world: {
    name: "World",
    value: "world"
  },
  us: {
    name: "U.S",
    value: "us"
  },
  business: {
    name: "Business",
    value: "business"
  },
  technology: {
    name: "Technology",
    value: "technology"
  },
  entertainment: {
    name: "Entertainment",
    value: "entertainment"
  },
  sports: {
    name: "Sports",
    value: "sports"
  },
  science: {
    name: "Science",
    value: "science"
  },
  health: {
    name: "Health",
    value: "health"
  },
  spotlight: {
    name: "Spotlight",
    value: "spotlight"
  }
};
export {
  CheckboxColors,
  dropdownStyles,
  dropdownAdditionalSelectField,
  dropdownAdditionalFormSelectField,
  dropdownAdditionalExperienceSelectField,
  dropdownMissionHubsSelectField,
  dropdownDefaultSelectField,
  postMessages
};
