import React from "react";
import "./svg-icon.sass";
import SVG from "react-inlinesvg";
import { Config } from "../../../utils/config";
export const SvgIconSize = {
  SMALL: "small",
  AVERAGE: "average",
  MEDIUM: "medium",
  XMEDIUM: "xmedium",
  BIG: "big"
};
export const SvgIcon = ({ size = SvgIconSize.MEDIUM, type, withBorder, colored, color }) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-svg-icon tint-svg-icon--${size} ${withBorder ? "tint-svg-icon--with-border" : ""}  ${Config.iconsTypes[type]} ${colored ? "tint-svg-icon--colored" : ""}`,
      style: {
        color
      }
    },
    /* @__PURE__ */ React.createElement(SVG, { src: `/icons/rebranding/${Config.iconsTypes[type]}.svg`, className: `icon ${Config.iconsTypes[type]}` })
  );
};
