import { ACTIONS } from "../../actions/list/mission-hubs.list.actions";
import { produce } from "immer";
export const initialState = {
  isFetching: false,
  isError: false,
  fetchingMissionId: false,
  links: void 0,
  missions: [],
  tags: []
};
export const missionHubsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MISSIONS.REQUEST:
    case ACTIONS.POST_MISSION.REQUEST:
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    case ACTIONS.DUPLICATE_MISSION.REQUEST:
    case ACTIONS.DELETE_MISSION.REQUEST:
      return produce(state, (draft) => {
        draft.fetchingMissionId = draft.missions[0].id;
      });
    case ACTIONS.GET_MISSIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.links = action.payload.links;
        draft.missions = action.payload.data;
        draft.tags = action.payload.meta.aggregations.tags;
        draft.isFetching = false;
      });
    case ACTIONS.DUPLICATE_MISSION.SUCCESS:
      return produce(state, (draft) => {
        draft.missions = [action.payload, ...draft.missions];
        draft.fetchingMissionId = false;
      });
    case ACTIONS.POST_MISSION.SUCCESS:
      return produce(state, (draft) => {
        draft.missions = [action.payload, ...draft.missions];
        draft.isFetching = false;
      });
    case ACTIONS.DELETE_MISSION.SUCCESS:
      return produce(state, (draft) => {
        draft.missions = [...state.missions.filter((mission) => mission.id !== action.payload)];
        draft.fetchingMissionId = false;
      });
    case ACTIONS.DUPLICATE_MISSION.FAILURE:
    case ACTIONS.DELETE_MISSION.FAILURE:
      return produce(state, (draft) => {
        draft.fetchingMissionId = false;
        draft.isError = action.payload;
      });
    case ACTIONS.GET_MISSIONS.FAILURE:
    case ACTIONS.POST_MISSION.FAILURE:
      return produce(state, (draft) => {
        draft.isError = action.payload;
        draft.isFetching = false;
      });
    default:
      return state;
  }
};
