var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { SESSION } from "./consts.js";
import { createPrefixedActionName } from "../services/functions/redux-routines/redux-routines.js";
import { Config } from "../utils/config";
import { createRoutine } from "../services/functions/redux-routines/redux-routines";
import { ApiService } from "../services/api-service/api-service";
import { analytics, identify } from "../services/functions/analytics-wrapper/analytics-wrapper";
import { findTeam } from "../reducers/session";
import { getAccessToken } from "../components/hooks/use-oauth/use-oauth";
export const ACTIONS = {
  GET_SESSION: createRoutine(createPrefixedActionName("SESSION", "GET_SESSION")),
  UPDATE_SESSION: createRoutine(createPrefixedActionName("SESSION", "UPDATE_SESSION")),
  UPDATE_TEAMS: createRoutine(createPrefixedActionName("SESSION", "UPDATE_TEAMS")),
  UPDATE_SESSION_PROFILE_DATA: createPrefixedActionName("SESSION", "UPDATE_SESSION_PROFILE_DATA"),
  UPDATE_SESSION_CURRENT_TEAM: createPrefixedActionName("SESSION", "UPDATE_SESSION_CURRENT_TEAM"),
  POST_SESSION_TOKEN: createRoutine(createPrefixedActionName("SESSION", "POST_SESSION_TOKEN")),
  PATCH_PRIMARY_FACTOR_AUTH: createRoutine(createPrefixedActionName("SESSION", "PATCH_PRIMARY_FACTOR_AUTH")),
  SET_FEATURES_ACCESSIBILITY: createPrefixedActionName("SESSION", "SET_FEATURES_ACCESSIBILITY")
};
export const updateTeamStart = () => ({
  type: ACTIONS.UPDATE_TEAMS.REQUEST
});
export const updateTeamSuccess = (data) => ({
  type: ACTIONS.UPDATE_TEAMS.SUCCESS,
  payload: data
});
export const updateTeamError = (err) => ({
  type: ACTIONS.UPDATE_TEAMS.FAILURE,
  payload: err
});
export const getSessionStart = () => ({
  type: ACTIONS.GET_SESSION.REQUEST
});
export const getSessionSuccess = (data) => ({
  type: ACTIONS.GET_SESSION.SUCCESS,
  payload: data
});
export const getSessionError = (err) => ({
  type: ACTIONS.GET_SESSION.FAILURE,
  payload: err
});
export const setFeaturesAccessibility = (features, permissions) => ({
  type: ACTIONS.SET_FEATURES_ACCESSIBILITY,
  payload: {
    features,
    permissions
  }
});
export const updateSessionStart = () => ({
  type: ACTIONS.UPDATE_SESSION.REQUEST
});
export const updateSessionSuccess = (data) => ({
  type: ACTIONS.UPDATE_SESSION.SUCCESS,
  payload: data
});
export const updateSessionError = (err) => ({
  type: ACTIONS.UPDATE_SESSION.FAILURE,
  payload: err
});
export const updateSessionProfileData = (data) => ({
  type: ACTIONS.UPDATE_SESSION_PROFILE_DATA,
  payload: data
});
export const updateSessionCurrentTeam = (data) => ({
  type: ACTIONS.UPDATE_SESSION_CURRENT_TEAM,
  payload: data
});
export const postSessionTokenStart = () => ({
  type: ACTIONS.POST_SESSION_TOKEN.REQUEST
});
export const postSessionTokenSuccess = (sessionTokenDetails, currentDate) => ({
  type: ACTIONS.POST_SESSION_TOKEN.SUCCESS,
  payload: __spreadProps(__spreadValues({}, sessionTokenDetails), {
    expireDate: currentDate
  })
});
export const postSessionTokenError = (err) => ({
  type: ACTIONS.POST_SESSION_TOKEN.FAILURE,
  payload: err
});
export const patchPrimaryFactorAuthStart = () => ({
  type: ACTIONS.PATCH_PRIMARY_FACTOR_AUTH.REQUEST
});
export const patchPrimaryFactorAuthSuccess = (id) => ({
  type: ACTIONS.PATCH_PRIMARY_FACTOR_AUTH.SUCCESS,
  payload: id
});
export const patchPrimaryFactorAuthError = (err) => ({
  type: ACTIONS.PATCH_PRIMARY_FACTOR_AUTH.FAILURE,
  payload: err
});
export const getSession = ({ onError, token }) => (dispatch) => {
  dispatch(getSessionStart());
  const access_token = token || getAccessToken();
  if (access_token) {
    ApiService.setAuth({ token: access_token });
    return ApiService.get("/me?include=teams.plan,teams.agent").then((data) => {
      var _a, _b, _c, _d, _e;
      const team = findTeam(data, ApiService.getTeamId());
      const plan = (_a = data.data.included) == null ? void 0 : _a.find((e) => e.type === "plan" && e.id === team.relationships.plan.data.id);
      const agent = ((_d = (_c = (_b = team == null ? void 0 : team.relationships) == null ? void 0 : _b.agent) == null ? void 0 : _c.data) == null ? void 0 : _d.id) ? (_e = data.data.included) == null ? void 0 : _e.find((e) => e.type === "agent" && e.id === team.relationships.agent.data.id) : null;
      analytics.init({ userId: data.data.data.id });
      identify(data.data.data.id, { email: data.data.data.attributes.email, subscriptionPlan: plan == null ? void 0 : plan.id });
      if (team) {
        ApiService.setTeamId(team.id, true);
      }
      dispatch(
        getSessionSuccess(__spreadProps(__spreadValues({}, data.data.data.attributes), {
          id: data.data.data.id,
          team_id: team == null ? void 0 : team.id,
          plan_id: plan == null ? void 0 : plan.id,
          access_token,
          features: convertArrayToHash(plan == null ? void 0 : plan.attributes.features),
          permissions: convertArrayToHash(team == null ? void 0 : team.attributes.permissions),
          relationships: team == null ? void 0 : team.relationships,
          included: data == null ? void 0 : data.data.included,
          currentTeam: team,
          currentPlan: plan,
          agent
        }))
      );
      dispatch(
        setFeaturesAccessibility(
          convertArrayToHash(plan == null ? void 0 : plan.attributes.features),
          convertArrayToHash(team == null ? void 0 : team.attributes.permissions)
        )
      );
    }).catch((err) => {
      getSessionError(err);
      onError && onError(err);
    });
  } else {
    getSessionError({});
    onError && onError({});
  }
};
const isRedirectUrl = (response) => response.request && response.request.responseURL && response.request.responseURL.includes(Config.passwordExpiredUrl);
export const convertArrayToHash = (arr = []) => {
  return arr ? arr.reduce(function(acc, obj) {
    acc[obj] = true;
    return acc;
  }, {}) : {};
};
export const getSessionData = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.session) == null ? void 0 : _a.data;
};
