import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { OAUTH_APPLICATIONS } from "../../consts";
export const ACTIONS = {
  GET_OAUTH_APPLICATIONS: createRoutine(createPrefixedActionName(OAUTH_APPLICATIONS, "GET_OAUTH_APPLICATIONS")),
  POST_OAUTH_APPLICATION: createRoutine(createPrefixedActionName(OAUTH_APPLICATIONS, "POST_OAUTH_APPLICATION")),
  PATCH_OAUTH_APPLICATION: createRoutine(createPrefixedActionName(OAUTH_APPLICATIONS, "PATCH_OAUTH_APPLICATION")),
  DELETE_OAUTH_APPLICATION: createRoutine(createPrefixedActionName(OAUTH_APPLICATIONS, "DELETE_OAUTH_APPLICATION")),
  GET_OAUTH_APPLICATION_SECRET_ID: createRoutine(
    createPrefixedActionName(OAUTH_APPLICATIONS, "GET_OAUTH_APPLICATION_SECRET_ID")
  )
};
export const getOAuthApplicationsStart = () => ({
  type: ACTIONS.GET_OAUTH_APPLICATIONS.REQUEST
});
export const getOAuthApplicationsSuccess = (data) => ({
  type: ACTIONS.GET_OAUTH_APPLICATIONS.SUCCESS,
  payload: data
});
export const getOAuthApplicationsError = (err) => ({
  type: ACTIONS.GET_OAUTH_APPLICATIONS.FAILURE,
  payload: err
});
export const postOAuthApplicationStart = () => ({
  type: ACTIONS.POST_OAUTH_APPLICATION.REQUEST
});
export const postOAuthApplicationSuccess = (data) => ({
  type: ACTIONS.POST_OAUTH_APPLICATION.SUCCESS,
  payload: data
});
export const postOAuthApplicationError = (err) => ({
  type: ACTIONS.POST_OAUTH_APPLICATION.FAILURE,
  payload: err
});
export const patchOAuthApplicationStart = () => ({
  type: ACTIONS.PATCH_OAUTH_APPLICATION.REQUEST
});
export const patchOAuthApplicationSuccess = (data) => ({
  type: ACTIONS.PATCH_OAUTH_APPLICATION.SUCCESS,
  payload: data
});
export const patchOAuthApplicationError = (err) => ({
  type: ACTIONS.PATCH_OAUTH_APPLICATION.FAILURE,
  payload: err
});
export const deleteOAuthApplicationStart = () => ({
  type: ACTIONS.DELETE_OAUTH_APPLICATION.REQUEST
});
export const deleteOAuthApplicationSuccess = (id) => ({
  type: ACTIONS.DELETE_OAUTH_APPLICATION.SUCCESS,
  payload: id
});
export const deleteOAuthApplicationError = (err) => ({
  type: ACTIONS.DELETE_OAUTH_APPLICATION.FAILURE,
  payload: err
});
export const getOAuthApplicationSecretIdStart = () => ({
  type: ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.REQUEST
});
export const getOAuthApplicationSecretIdSuccess = (data) => ({
  type: ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.SUCCESS,
  payload: data
});
export const getOAuthApplicationSecretIdError = (err) => ({
  type: ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.FAILURE,
  payload: err
});
