import React, { useEffect } from "react";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
export const termsAndConditionsLabelKey = "FORM.DIV DIV-COMMUNITY-CONSENT.TERMS-AND-CONDITIONS_LABEL";
export const privacyPolicyLabelKey = "FORM.DIV DIV-COMMUNITY-CONSENT.PRIVACY-POLICY-LABEL";
export const communityUrlKey = "FORM.DIV DIV-COMMUNITY-CONSENT.COMMUNITY-URL";
export const communityNameKey = "FORM.DIV DIV-COMMUNITY-CONSENT.COMMUNITY-NAME";
export const communityAgeKey = "FORM.DIV DIV-COMMUNITY-CONSENT.COMMUNITY-AGE";
const FormBuilderFormInterfaceCommunityConsent = ({ item, formId, analyticForm, t }) => {
  if (!item) return null;
  const { input: checkbox, label } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(checkbox.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(checkbox.name);
  const communityName = t(communityNameKey);
  const communityUrl = t(communityUrlKey);
  const communityAge = t(communityAgeKey);
  const communityTermsAndConditionsLink = `<a href="${communityUrl}/policies/terms_conditions" target="_blank">${t(
    termsAndConditionsLabelKey
  )}</a>`;
  const communityPrivacyPolicyLink = `<a href="${communityUrl}/policies/privacy" target="_blank">${t(
    privacyPolicyLabelKey
  )}</a>`;
  const textHtml = t(label.nodeValue, {
    minimum_age: communityAge,
    name: communityName,
    terms_link: communityTermsAndConditionsLink,
    privacy_policy_link: communityPrivacyPolicyLink
  });
  useEffect(() => {
    const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
    if (formDiv == null ? void 0 : formDiv.shadowRoot) {
      const consentInputElement = formDiv.shadowRoot.getElementById(checkbox.id);
      const dataMapping = checkbox == null ? void 0 : checkbox["data-mapping"];
      const dataMappingObj = dataMapping ? JSON.parse(dataMapping) : {};
      const relatedEmailInput = formDiv.shadowRoot.querySelector(`[name="${dataMappingObj == null ? void 0 : dataMappingObj.email}"]`);
      consentInputElement.disabled = true;
      if (relatedEmailInput) {
        relatedEmailInput.onchange = () => {
          const isEmailEmpty = relatedEmailInput.value === "" || relatedEmailInput.value === void 0;
          consentInputElement.disabled = isEmailEmpty;
          if (consentInputElement.checked && isEmailEmpty) {
            consentInputElement.checked = false;
          }
        };
      }
    }
  }, [formId, checkbox]);
  return /* @__PURE__ */ React.createElement("div", { className: `${item.class}` }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: checkbox == null ? void 0 : checkbox.type,
      name: checkbox == null ? void 0 : checkbox.name,
      onFocus,
      onBlur,
      id: checkbox == null ? void 0 : checkbox.id,
      required: checkbox == null ? void 0 : checkbox.required,
      value: checkbox.value ? checkbox.value : ""
    }
  ), /* @__PURE__ */ React.createElement("label", { htmlFor: label.for, dangerouslySetInnerHTML: { __html: textHtml } }));
};
FormBuilderFormInterfaceCommunityConsent.displayName = "FormBuilderFormInterfaceCommunityConsent";
export default FormBuilderFormInterfaceCommunityConsent;
