import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const submitButton = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON_BUTTON]: {
    cursor: "pointer",
    "border-style": "none",
    "background-color": "#0472EF",
    width: "100%",
    "justify-self": "left",
    "font-size": "16px",
    "padding-top": "12px",
    "padding-right": "12px",
    "padding-bottom": "12px",
    "padding-left": "12px",
    color: "#ffffff"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON_BUTTON_DISABLED]: {
    filter: "grayscale(100%)",
    cursor: "no-drop"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON_BUTTON_RTL]: {
    "text-align": "center"
  }
};
