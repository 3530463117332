export const identify = (id, traits) => {
  var _a;
  (_a = window._hsq) == null ? void 0 : _a.push(["identify", traits]);
};
export const track = (name, properties) => {
  var _a;
  (_a = window._hsq) == null ? void 0 : _a.push([
    "trackCustomBehavioralEvent",
    {
      name,
      properties
    }
  ]);
};
const init = ({ userId }) => {
};
export const analytics = {
  track,
  identify,
  init
};
