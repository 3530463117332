export const transformToSendAble = (value) => {
  return value.toLowerCase().replace(/[^A-Za-z0-9]+/g, "_");
};
export const FIELD_TYPE = {
  INPUT: "INPUT",
  HIDDEN_INPUT: "HIDDEN_INPUT",
  TEXTAREA: "TEXTAREA",
  DROPDOWN: "DROPDOWN",
  CHECKBOX: "CHECKBOX"
};
