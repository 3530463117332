import { useState, useEffect } from "react";
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState();
  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedValue !== value) {
        setDebouncedValue(value);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
};
export default useDebounce;
