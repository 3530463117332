import { ProductAccounts } from "../../types/product-accounts";
export const ProductAccountsTranslations = {
  [ProductAccounts.FTP]: "FTP",
  [ProductAccounts.SALESFORCE_COMMERCE_CLOUD]: "Salesforce Commerce Cloud",
  [ProductAccounts.BIGCOMMERCE]: "BigCommerce",
  [ProductAccounts.WOOCOMMERCE]: "WooCommerce",
  [ProductAccounts.SHOPIFY]: "Shopify",
  [ProductAccounts.MAGENTO]: "Magento",
  [ProductAccounts.FACEBOOK]: "Facebook",
  [ProductAccounts.S3]: "AWS S3",
  [ProductAccounts.HTTP]: "HTTP",
  [ProductAccounts.BAZAARVOICE]: "Bazaarvoice"
};
