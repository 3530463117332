const TINTS = "TINTS";
const EXPERIENCE_TRANSLATIONS = "EXPERIENCE_TRANSLATIONS";
const EXPERIENCE = "EXPERIENCE";
const POSTS = "POSTS";
const EXPERIENCES = "EXPERIENCES";
const EXPERIENCE_BUILDER = "EXPERIENCE_BUILDER";
const TINT_PAGES = "TINT_PAGES";
const CUSTOM_DOMAINS = "CUSTOM_DOMAINS";
const names = {
  TINTS,
  EXPERIENCE,
  POSTS,
  EXPERIENCE_TRANSLATIONS,
  EXPERIENCES,
  EXPERIENCE_BUILDER,
  TINT_PAGES,
  CUSTOM_DOMAINS
};
export {
  names as default,
  TINTS,
  EXPERIENCE,
  POSTS,
  EXPERIENCE_TRANSLATIONS,
  EXPERIENCES,
  EXPERIENCE_BUILDER,
  TINT_PAGES,
  CUSTOM_DOMAINS
};
