import { produce } from "immer";
import { ACTIONS } from "../../actions/mission-hub/mission-hub.actions";
export const initialState = {
  isFetching: false,
  isError: false,
  data: {}
};
export const missionHubReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MISSION.REQUEST:
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    case ACTIONS.GET_MISSION.SUCCESS:
      return produce(state, (draft) => {
        draft.data = action.payload;
        draft.isFetching = false;
      });
    case ACTIONS.GET_MISSION.FAILURE:
      return produce(state, (draft) => {
        draft.isError = action.payload;
        draft.isFetching = false;
      });
    default:
      return state;
  }
};
