import React from "react";
import { EditModalBody } from "../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { renderEditColorInput } from "../../../page-section-utils";
import EditUploadDesign from "../../../../edit-upload-design/edit-upload-design";
import { RangeInput } from "../../../../../../../../components/interface/inputs/range-input/range-input";
import CustomFontFamilyDropdown from "../../../../../../../../components/interface/inputs/custom-font-family/custom-font-family";
import { EditInput } from "../../../../index";
import { onSelectModal } from "../../../../../../../../actions/dropdown";
import { useDispatch } from "react-redux";
import "./survey-counter-edit-style.sass";
const colorInputs = [
  { property: "counterTextColor", label: "Counter text color" },
  { property: "counterBackgroundColor", label: "Counter background color" }
];
const SurveyCounterEditStyles = ({ data, dataUpdate, styleDataUpdate }) => {
  const dispatch = useDispatch();
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize this section with the color fields below." }, /* @__PURE__ */ React.createElement(
    RangeInput,
    {
      value: data.styles.sectionPadding,
      onChange: (e) => {
        styleDataUpdate({ sectionPadding: e });
      },
      label: "Section Padding"
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "survey-counter-section-font-family-wrapper" }, /* @__PURE__ */ React.createElement("p", null, "Font family"), /* @__PURE__ */ React.createElement(
    CustomFontFamilyDropdown,
    {
      fontFamily: data.styles.fontFamily.value,
      styleUpdate: styleDataUpdate,
      onChange: (item) => dispatch(onSelectModal(item))
    }
  )), /* @__PURE__ */ React.createElement(
    EditInput,
    {
      label: "Font Size",
      placeholder: "Enter value",
      onChange: (e) => {
        styleDataUpdate({ fontSize: e.target.value });
      },
      defaultValue: data.styles.fontSize
    }
  ), colorInputs.map(
    (e, i) => renderEditColorInput(data.styles[e.property], (color) => styleDataUpdate({ [e.property]: color }), e.label, i)
  ), /* @__PURE__ */ React.createElement(
    EditUploadDesign,
    {
      dataUpdate,
      styleDataUpdate,
      data,
      isPaddingDisabled: true
    }
  ));
};
export default SurveyCounterEditStyles;
