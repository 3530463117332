import { GuardNames } from "../../../../../guards/guards-list";
const MODAL_STEP = {
  STEP_ONE: "STEP_ONE",
  STEP_TWO: "STEP_TWO"
};
export const NETWORK_TYPE = {
  HOOTSUITE: "hootsuite",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram_business",
  LINKED_IN: "linkedin",
  TIKTOK: "tiktok",
  YOUTUBE: "youtube",
  THREADS: "threads"
};
const networks = [
  {
    type: NETWORK_TYPE.INSTAGRAM,
    icon: "instagram",
    title: "Instagram",
    description: "Select your Instagram Business Account to compose scheduled posts. Personal and Creator accounts are not supported."
  },
  {
    type: NETWORK_TYPE.TIKTOK,
    icon: "tiktok",
    title: "TikTok",
    description: "Select your TikTok Account to compose and schedule posts."
  },
  {
    type: NETWORK_TYPE.TWITTER,
    icon: "twitter",
    title: "X",
    description: "Select your X Account to compose and schedule posts."
  },
  {
    type: NETWORK_TYPE.FACEBOOK,
    icon: "facebook",
    title: "Facebook",
    description: "Select your Facebook Account to compose and schedule posts."
  },
  {
    type: NETWORK_TYPE.LINKED_IN,
    icon: "linkedin",
    title: "LinkedIn",
    description: "Select your LinkedIn Account to compose and schedule posts."
  },
  {
    type: NETWORK_TYPE.HOOTSUITE,
    icon: "hootsuite",
    title: "Hootsuite Amplify",
    description: "Select your Hootsuite Account to compose and schedule posts."
  },
  {
    type: NETWORK_TYPE.YOUTUBE,
    icon: "youtube",
    title: "YouTube",
    description: "Select your YouTube Account to compose and schedule posts.",
    guardName: GuardNames.PUBLISHING_YOUTUBE
  },
  {
    type: NETWORK_TYPE.THREADS,
    icon: "threads",
    title: "Threads",
    description: "Select your Threads Account to compose and schedule posts.",
    guardName: GuardNames.PUBLISHING_THREADS
  }
];
export const MediaComposerAddNetworkModalData = {
  networks,
  NETWORK_TYPE,
  MODAL_STEP
};
