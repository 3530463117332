var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./preloader.sass";
export const PreloaderPositionType = {
  ABSOLUTE: "absolute",
  RELATIVE: "relative"
};
export const PreloaderBackdropType = {
  TRANSPARENT: "transparent",
  SEMI_COVER: "semi-cover",
  COVER: "cover"
};
export const PreloaderBackdropColorType = {
  LIGHT: "#fff",
  DARK: "#1b242d",
  NONE: "none",
  TRANSPARENT: "transparent"
};
export const PreloaderColorType = {
  DEFAULT: "#1b242d",
  LIGHT: "#e9edf3",
  WHITE: "#fff"
};
export const PreloaderSizeType = {
  BIG: "64px",
  NORMAL: "32px",
  SMALL: "16px"
};
const PreloaderComponent = ({
  backdropType = PreloaderBackdropType.TRANSPARENT,
  backdropColor = PreloaderBackdropColorType.LIGHT,
  positionType = PreloaderPositionType.RELATIVE,
  color = PreloaderColorType.DEFAULT,
  size = PreloaderSizeType.NORMAL,
  style
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      "data-testid": "preloaderId",
      className: `preloader-component preloader-component--${positionType}`,
      style: __spreadValues({
        fontSize: size,
        color
      }, style)
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `preloader-component__backdrop preloader-component__backdrop--${backdropType}`,
        style: {
          backgroundColor: backdropColor
        }
      }
    ),
    /* @__PURE__ */ React.createElement("span", { className: "icon tint-preloader-icon" })
  );
};
export default PreloaderComponent;
