import React from "react";
import "./tint-editor-social-feeds.sass";
import ButtonContentIcon from "../../../../../components/interface/button-content-icon/button-content-icon";
import { useDispatch, useSelector } from "react-redux";
import { openSidebar } from "../../../../../actions/ui/ui.actions";
import { socialFeedSidebar } from "../../social-feed-card/social-feed-card";
import { SocialFeedsSource, SocialFeedsTypes } from "../../../../../types/social-feeds";
import { getSocialFeedsLimits } from "../../../../../reducers/session";
import Tooltip from "../../../../transform-ui/components/tooltip/tooltip";
import useFeatureAccessibility from "../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { openSidebar as openGlobalSidebar, SIDEBAR_TYPE } from "../../../../../actions/sidebar";
import { sidebarUpgradeSchema } from "../../../../../components/sidebar-upgrade/sidebar-upgrade.schema";
import FeatureGuard from "../../../../../guards/feature-guard";
import { GuardNames } from "../../../../../guards/guards-list";
import { findSocialFeed } from "../../../components/tint-editor/sidebars/social-feed-sidebar/reducers/social-feeds.selector";
export const SocialFeedsTooltipText = {
  [SocialFeedsSource.TWITTER]: "X",
  [SocialFeedsSource.INSTAGRAM_BUSINESS]: "Instagram",
  [SocialFeedsSource.FACEBOOK]: "Facebook",
  [SocialFeedsSource.RSS]: "RSS",
  [SocialFeedsSource.PUBLIC_POST]: "Public Post",
  [SocialFeedsSource.SMS]: "SMS",
  [SocialFeedsSource.EMAIL]: "Email",
  [SocialFeedsSource.USER_REVIEW]: "ReviewTrackers",
  [SocialFeedsSource.TUMBLR]: "Tumblr",
  [SocialFeedsSource.LINKED_IN]: "LinkedIn",
  [SocialFeedsSource.FLICKR]: "Flickr",
  [SocialFeedsSource.GOOGLE_NEWS]: "Google News",
  [SocialFeedsSource.SLACK]: "Slack",
  [SocialFeedsSource.HOOT_SUITE]: "Hootsuite",
  [SocialFeedsSource.TIK_TOK]: "TikTok",
  [SocialFeedsSource.WEBEX]: "Cisco WebEx",
  [SocialFeedsSource.EXPERIENCE_BUILDER]: "Experience Builder",
  [SocialFeedsSource.FORMS]: "Forms",
  [SocialFeedsSource.FORM_SUBMISSION]: "Experience Builder",
  [SocialFeedsSource.INFLUENCER]: "influencer",
  [SocialFeedsSource.CUSTOM]: "Custom Posts",
  [SocialFeedsSource.DELETED]: "deleted",
  [SocialFeedsSource.PINTEREST]: "Pinterest",
  [SocialFeedsSource.EXTERNALLY_SOURCED_POSTS]: "Browser Extensions",
  [SocialFeedsSource.EXTERNALLY_SOURCED_POSTS_ACCOUNT]: "Browser Extensions",
  [SocialFeedsSource.SCHEDULED_POSTS]: "Scheduled Posts",
  [SocialFeedsSource.REVIEWS_RATINGS]: "Ratings & Reviews",
  [SocialFeedsSource.YOUTUBE]: "YouTube",
  [SocialFeedsSource.VESTA]: "Community Content",
  [SocialFeedsSource.THREADS]: "Threads",
  [SocialFeedsSource.CREATOR_IQ]: "CreatorIQ",
  [SocialFeedsSource.BAZAARVOICE]: "Bazaarvoice",
  [SocialFeedsSource.POWER_REVIEWS]: "PowerReviews",
  [SocialFeedsSource.RE_CAPTCHA]: "reCAPTCHA"
};
const TintEditorSocialFeeds = ({ onModalOpen }) => {
  var _a, _b, _c, _d;
  const dispatch = useDispatch();
  const socialFeedLimits = useSelector(getSocialFeedsLimits);
  const session = useSelector((state) => state.session);
  const publicPostSocialFeed = useSelector(
    (state) => findSocialFeed(state, SocialFeedsSource.PUBLIC_POST, SocialFeedsTypes.POST)
  );
  const ratingReviewsSocialFeed = useSelector(
    (state) => findSocialFeed(state, SocialFeedsSource.PUBLIC_POST, SocialFeedsTypes.REVIEWS)
  );
  const isVestaCommunity = (_d = (_c = (_b = (_a = session == null ? void 0 : session.data) == null ? void 0 : _a.relationships) == null ? void 0 : _b.community) == null ? void 0 : _c.data) == null ? void 0 : _d.id;
  const {
    socialFeedsTwitter: isTwitterAllowed,
    influencer: isInfluencerSocialFeed,
    experiences: isExperienceAllowed,
    forms: isFormAllowed,
    socialFeedsHootsuite: isHootsuiteAllowed,
    socialFeedsReviewTrackers: isReviewsTrackersAllowed,
    filterRatings: isReviewsRatingsAllowed,
    socialFeedsPinterest: isPinterestAllowed,
    socialFeedsEmail: isEmailAllowed,
    socialFeedsRSS: isRSSAllowed,
    socialFeedsTumblr: isTumblrAllowed,
    socialFeedFlickr: isFlickrAllowed,
    socialFeedLinkedIn: isLinkedInAllowed,
    socialFeedGoogleNews: isGoogleNewsAllowed,
    socialFeedSlack: isSlackAllowed,
    socialFeedsWebex: isWebexAllowed,
    socialFeedPublicPost: isPublicPostAllowed,
    socialFeedsSMS: isSMSAllowed,
    socialFeedsTikTok: isTikTokAllowed,
    socialFeedsInstagramBusiness: isSocialFeedsInstagramBusinessAllowed,
    socialFeedsThreads: isSocialFeedsThreadsAllowed,
    socialFeedsCreatorIq: isSocialFeedsCreatorIqAllowed,
    socialFeedsBazaarvoice: isSocialFeedsBazaarvoiceAllowed,
    socialFeedsPowerReviews: isSocialFeedsPowerReviewsAllowed,
    socialFeedsYouTube: isYouTubeAllowed,
    socialFeedsFacebook: isFacebookAllowed
  } = useFeatureAccessibility();
  const openSocialFeed = (val) => {
    if (disableSocialFeeds.includes(val.toLowerCase())) {
      dispatch(
        openGlobalSidebar({
          modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
          data: {
            sidebarUpgradeData: sidebarUpgradeSchema.default
          }
        })
      );
      return;
    }
    if (socialFeedLimits.activeSocialFeedsLimit <= socialFeedLimits.activeSocialFeedsCount) {
      onModalOpen();
    } else {
      if (val === SocialFeedsSource.REVIEWS_RATINGS && ratingReviewsSocialFeed) {
        dispatch(
          openSidebar(socialFeedSidebar, {
            editFeedData: ratingReviewsSocialFeed,
            socialFeedSidebar: val
          })
        );
      } else if (val === SocialFeedsSource.PUBLIC_POST && publicPostSocialFeed) {
        dispatch(
          openSidebar(socialFeedSidebar, {
            editFeedData: publicPostSocialFeed,
            socialFeedSidebar: val
          })
        );
      } else {
        dispatch(
          openSidebar(socialFeedSidebar, {
            editFeedData: null,
            socialFeedSidebar: val
          })
        );
      }
    }
  };
  const disableSocialFeeds = [
    ...isSocialFeedsInstagramBusinessAllowed ? [] : [SocialFeedsSource.INSTAGRAM_BUSINESS],
    ...isFacebookAllowed ? [] : [SocialFeedsSource.FACEBOOK],
    ...isTwitterAllowed ? [] : [SocialFeedsSource.TWITTER],
    ...isRSSAllowed ? [] : [SocialFeedsSource.RSS],
    ...isPublicPostAllowed ? [] : [SocialFeedsSource.PUBLIC_POST],
    ...isSMSAllowed ? [] : [SocialFeedsSource.SMS],
    ...isEmailAllowed ? [] : [SocialFeedsSource.EMAIL],
    ...isPinterestAllowed ? [] : [SocialFeedsSource.PINTEREST],
    ...isLinkedInAllowed ? [] : [SocialFeedsSource.LINKED_IN],
    ...isReviewsTrackersAllowed ? [] : [SocialFeedsSource.USER_REVIEW],
    ...isTumblrAllowed ? [] : [SocialFeedsSource.TUMBLR],
    ...isFlickrAllowed ? [] : [SocialFeedsSource.FLICKR],
    ...isGoogleNewsAllowed ? [] : [SocialFeedsSource.GOOGLE_NEWS],
    ...isSlackAllowed ? [] : [SocialFeedsSource.SLACK],
    ...isHootsuiteAllowed ? [] : [SocialFeedsSource.HOOT_SUITE],
    ...isTikTokAllowed ? [] : [SocialFeedsSource.TIK_TOK],
    ...isWebexAllowed ? [] : [SocialFeedsSource.WEBEX],
    ...isExperienceAllowed ? [] : [SocialFeedsSource.EXPERIENCE_BUILDER],
    ...isFormAllowed ? [] : [SocialFeedsSource.FORMS],
    ...isInfluencerSocialFeed ? [] : [SocialFeedsSource.INFLUENCER],
    ...isReviewsRatingsAllowed ? [] : [SocialFeedsSource.REVIEWS_RATINGS],
    ...isYouTubeAllowed ? [] : [SocialFeedsSource.YOUTUBE],
    ...isVestaCommunity ? [] : [SocialFeedsSource.VESTA]
  ];
  const hiddenSocialFeeds = [
    SocialFeedsSource.EXTERNALLY_SOURCED_POSTS_ACCOUNT,
    SocialFeedsSource.CUSTOM,
    SocialFeedsSource.INSTAGRAM,
    SocialFeedsSource.FORM_SUBMISSION,
    SocialFeedsSource.DELETED,
    SocialFeedsSource.EXTERNALLY_SOURCED_POSTS,
    SocialFeedsSource.SCHEDULED_POSTS,
    SocialFeedsSource.LINKED_IN,
    SocialFeedsSource.GOOGLE_NEWS,
    SocialFeedsSource.BAZAARVOICE,
    SocialFeedsSource.POWER_REVIEWS,
    SocialFeedsSource.RE_CAPTCHA,
    ...isSocialFeedsThreadsAllowed ? [] : [SocialFeedsSource.THREADS],
    ...isSocialFeedsCreatorIqAllowed ? [] : [SocialFeedsSource.CREATOR_IQ],
    ...isYouTubeAllowed ? [] : [SocialFeedsSource.YOUTUBE]
  ];
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-social-feeds" }, /* @__PURE__ */ React.createElement("ul", null, Object.values(SocialFeedsSource).filter((val) => !hiddenSocialFeeds.includes(val.toLowerCase())).map((val, i) => {
    const renderFeed = () => /* @__PURE__ */ React.createElement("li", { key: val }, /* @__PURE__ */ React.createElement(
      Tooltip,
      {
        style: {
          top: "auto",
          // Every 5 icons are close to the edge, so we have to change the tooltip position to fit the screen.
          // After we write new tooltips this should be fixed.
          left: (i + 1) % 5 !== 0 ? "0" : "-100%",
          bottom: "100%",
          minHeight: "12px",
          padding: "6px",
          textAlign: "center",
          textTransform: "capitalize"
        },
        text: SocialFeedsTooltipText[val],
        component: /* @__PURE__ */ React.createElement(ButtonContentIcon, { source: val, onClick: () => openSocialFeed(val) })
      }
    ));
    return val === SocialFeedsSource.REVIEWS_RATINGS ? /* @__PURE__ */ React.createElement(FeatureGuard, { key: val, featureName: GuardNames.REVIEWS }, renderFeed()) : renderFeed();
  })));
};
export default TintEditorSocialFeeds;
