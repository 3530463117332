import React from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { renderEditColorInput } from "../../page-section-utils";
import { EditUploadDesign } from "../../../";
const colorInputs = [
  { property: "overlayBackgroundColor", label: "Overlay background color" },
  { property: "overlayTextColor", label: "Overlay text color" },
  { property: "buttonBackgroundColor", label: "Button color" },
  { property: "buttonTextColor", label: "button text color" }
];
const PollEditStyles = ({ data, dataUpdate, styleDataUpdate }) => {
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize this section with the color fields below." }, colorInputs.map(
    (e, i) => renderEditColorInput(data.styles[e.property], (v) => styleDataUpdate({ [e.property]: v }), e.label, i)
  ), /* @__PURE__ */ React.createElement(EditUploadDesign, { dataUpdate, styleDataUpdate, data }));
};
export default PollEditStyles;
