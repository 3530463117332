var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./display-settings-switcher.sass";
import SelectDisplayOptionsSidebar from "../steps/select-display-options/select-display-options-sidebar";
import TintSidebarStepper from "../../../../../../../components/sidebar-stepper/sidebar-stepper";
import SignageDisplaySettingsSidebar from "../steps/signage-display-settings/signage-display-settings-sidebar";
import EmailDisplaySettingsSidebar from "../steps/email-display-settings-sidebar/email-display-settings-sidebar";
import OmnichannelSolutionsSidebar from "../steps/omnichannel-solutions/omnichannel-solutions-sidebar";
import { PersonalizationTypes } from "../../../../../../../model/personalizations.model";
import WebEmbedSettingsSidebar from "../steps/web-embed-settings/web-embed-settings-sidebar";
export const DisplaySettingsSwitcherSteps = {
  DISPLAY_OPTIONS: 1,
  SETTINGS_OPTIONS: 2,
  OMNICHANNEL: 2,
  EMBED: 2,
  OMNICHANNEL_NOT_AVAILABLE: 3
};
const DisplaySettingsSwitcherSidebar = ({
  tintDetails,
  isVisible,
  onClose,
  hideTintDropdown,
  hidePersonalizationDropdown
}) => {
  const [modalDetails, setModalDetails] = useState({
    isVisible: false,
    steps: 2
  });
  useEffect(() => {
    isVisible && onChangeStep({
      step: 1
    });
  }, [isVisible]);
  const onCloseSidebar = () => {
    setModalDetails({
      isVisible: false
    });
    onClose == null ? void 0 : onClose();
  };
  const getSettingsOptions = (data, step) => {
    const type = data == null ? void 0 : data.type;
    switch (type) {
      case PersonalizationTypes.SCREEN:
      case PersonalizationTypes.VIRTUAL_EVENT:
        setModalDetails({
          isVisible: true,
          headerTitle: "Signage Display Settings",
          step: step || DisplaySettingsSwitcherSteps.SETTINGS_OPTIONS,
          isValid: true,
          component: (props) => /* @__PURE__ */ React.createElement(SignageDisplaySettingsSidebar, __spreadValues({}, props))
        });
        break;
      case PersonalizationTypes.EMAIL:
        setModalDetails({
          isVisible: true,
          headerTitle: "Email Display Settings",
          step: DisplaySettingsSwitcherSteps.SETTINGS_OPTIONS,
          isValid: true,
          component: (props) => /* @__PURE__ */ React.createElement(EmailDisplaySettingsSidebar, __spreadValues({}, props))
        });
        break;
      case PersonalizationTypes.REVIEWS:
      case PersonalizationTypes.SHOPPABLE:
      case PersonalizationTypes.MOBILE:
      case PersonalizationTypes.EVENT:
      case PersonalizationTypes.EMBED:
        setModalDetails({
          isVisible: true,
          headerTitle: "Web Embed Settings",
          step: step || DisplaySettingsSwitcherSteps.EMBED,
          isValid: true,
          component: (props) => /* @__PURE__ */ React.createElement(WebEmbedSettingsSidebar, __spreadValues({}, props))
        });
        break;
    }
  };
  const onChangeStep = ({ step, data }) => {
    if (step === 2 && ((data == null ? void 0 : data.type) === "not_available" || modalDetails.step === 3)) {
      setModalDetails({
        isVisible: true,
        headerTitle: "Omnichannel Solutions",
        step: DisplaySettingsSwitcherSteps.OMNICHANNEL,
        isValid: true,
        component: (props) => /* @__PURE__ */ React.createElement(OmnichannelSolutionsSidebar, __spreadValues({}, props))
      });
      setModalDetails((s) => __spreadProps(__spreadValues({}, s), { steps: 3 }));
      return;
    }
    switch (step) {
      case DisplaySettingsSwitcherSteps.DISPLAY_OPTIONS:
        setModalDetails({
          isVisible: true,
          headerTitle: "Select Display Options",
          step: DisplaySettingsSwitcherSteps.DISPLAY_OPTIONS,
          component: (props) => /* @__PURE__ */ React.createElement(
            SelectDisplayOptionsSidebar,
            __spreadProps(__spreadValues({}, props), {
              tintDetails: !data && tintDetails,
              hideTintDropdown,
              hidePersonalizationDropdown
            })
          )
        });
        break;
      case DisplaySettingsSwitcherSteps.SETTINGS_OPTIONS:
        getSettingsOptions(data);
        break;
      case DisplaySettingsSwitcherSteps.OMNICHANNEL_NOT_AVAILABLE:
        getSettingsOptions(data, 3);
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, modalDetails.isVisible && /* @__PURE__ */ React.createElement(
    TintSidebarStepper,
    {
      onClose: onCloseSidebar,
      modalDetails,
      onChangeStep,
      onSubmit: () => {
      },
      stepsLength: modalDetails.steps
    }
  ));
};
export default React.memo(DisplaySettingsSwitcherSidebar);
