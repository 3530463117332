var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/email-domains/email-domains.actions";
import { produce } from "immer";
export const initialState = {
  data: [],
  isFetching: false,
  currentDomain: void 0,
  error: void 0
};
export const emailDomains = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EMAIL_DOMAINS.REQUEST:
    case ACTIONS.GET_EMAIL_DOMAIN.REQUEST:
    case ACTIONS.POST_EMAIL_DOMAINS.REQUEST:
    case ACTIONS.DELETE_EMAIL_DOMAIN.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_EMAIL_DOMAINS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_EMAIL_DOMAINS.FAILURE:
    case ACTIONS.GET_EMAIL_DOMAIN.FAILURE:
    case ACTIONS.POST_EMAIL_DOMAINS.FAILURE:
    case ACTIONS.DELETE_EMAIL_DOMAIN.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    case ACTIONS.GET_EMAIL_DOMAIN.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        currentDomain: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.POST_EMAIL_DOMAINS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload.data, ...state.data],
        links: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_EMAIL_DOMAIN.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((domain) => domain.id !== action.payload);
        draft.isFetching = false;
      });
    default: {
      return state;
    }
  }
};
