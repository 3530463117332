import React from "react";
import PropTypes from "prop-types";
import "./marketing-integrations-table-row.sass";
import { TableListPrimaryRow } from "../../../../../../components/interface/lists/table-list-primary/components/table-list-row/table-list-primary-row";
import { MarketingIntegrationsSettingsList } from "../settings-list/marketing-integrations-settings-list";
import { Button } from "../../../../../../components/interface/button/button";
import ButtonContentIcon from "../../../../../../components/interface/button-content-icon/button-content-icon";
export const ExpirationStatuses = {
  READY: "ready",
  ERROR: "error"
};
export const MarketingIntegrationsTableRow = ({ marketingIntegration, onRemove, onResolve }) => /* @__PURE__ */ React.createElement(TableListPrimaryRow, null, /* @__PURE__ */ React.createElement("div", { "data-flex": 0, "data-width": "56px" }, /* @__PURE__ */ React.createElement(
  ButtonContentIcon,
  {
    source: marketingIntegration.attributes.source,
    circleSize: 30,
    fontSize: 15,
    svgIconSize: 20
  }
)), /* @__PURE__ */ React.createElement("div", { "data-flex": 3, className: "tint-social-connection-row__name" }, displayName(marketingIntegration)), /* @__PURE__ */ React.createElement("div", { "data-flex": 2 }, /* @__PURE__ */ React.createElement("div", { className: "tint-marketing-integrations-table-row__settings-button" }, marketingIntegration.attributes.status === ExpirationStatuses.ERROR && /* @__PURE__ */ React.createElement(
  Button,
  {
    type: "orange",
    size: "medium",
    onClick: () => onResolve(marketingIntegration.attributes.source, marketingIntegration.id),
    icon: "fas fa-exclamation-circle"
  },
  "Resolve"
), /* @__PURE__ */ React.createElement(MarketingIntegrationsSettingsList, { marketingIntegration, onRemove }))));
MarketingIntegrationsTableRow.propTypes = {
  marketingIntegration: PropTypes.object
};
const displayName = (marketingIntegration) => {
  if (marketingIntegration.attributes.source === "twitter") {
    return (marketingIntegration.attributes.external_id || "").split("|").join(" ");
  }
  return marketingIntegration.attributes.name || marketingIntegration.attributes.username;
};
