import React, { Component } from "react";
import { EditUploadDesign } from "../../../";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
export class EditTintStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Set styles for your TINT section" }, /* @__PURE__ */ React.createElement(
      EditUploadDesign,
      {
        dataUpdate: this.props.dataUpdate,
        styleDataUpdate: this.props.styleDataUpdate,
        data: this.props.data
      }
    ));
  }
}
