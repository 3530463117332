var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { FORM_BUILDER_INPUT_CLASSES } from "../../form-builder.types";
import { header } from "./elements/header";
import { heading } from "./elements/heading";
import { submitButton } from "./elements/submit-button";
import { successMessage } from "./elements/success-message";
import { image } from "./elements/image";
import { singleChoice } from "./elements/single-choice";
import { multipleChoice } from "./elements/multiple-choice";
import { common } from "./common/common";
import { spacer } from "./elements/spacer";
import { smileyScale } from "./elements/smiley-scale";
import { fileUpload } from "./elements/file-upload";
import { consent } from "./elements/consent";
import { communityConsent } from "./elements/community-consent";
import { thumbScale } from "./elements/thumb-scale";
import { acceptance } from "./elements/acceptance";
import { date } from "./elements/date";
import { birthdate } from "./elements/birthdate";
import { numberScale } from "./elements/number-scale";
import { paragraph } from "./elements/paragraph";
import { singleMatrix } from "./elements/single-matrix";
import { multipleMatrix } from "./elements/multiple-matrix";
import { address } from "./elements/address";
import { dropdown } from "./elements/dropdown";
import { vimeo } from "./elements/vimeo";
import { youtube } from "./elements/youtube";
import { multiLineText } from "./elements/multi-line-text";
import { starRating } from "./elements/star-rating";
import { line } from "./elements/line";
import { fullName } from "./elements/full-name";
export const FORM_BUILDER_STYLE_ELEMENT_CLASSES = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, common), header), heading), submitButton), successMessage), image), singleChoice), multipleChoice), spacer), smileyScale), fileUpload), consent), communityConsent), thumbScale), acceptance), date), birthdate), numberScale), paragraph), singleMatrix), multipleMatrix), address), dropdown), vimeo), youtube), multiLineText), starRating), line), fullName);
export const FORM_BUILDER_INPUT_TYPE_TO_CLASSES = {
  // common is an exception to store styles which are shared by many elements or meant for specific style cases
  common: [...Object.values(common)],
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN]: [...Object.values(dropdown)],
  [FORM_BUILDER_INPUT_CLASSES.HEADER]: [...Object.values(header)],
  [FORM_BUILDER_INPUT_CLASSES.HEADING]: [...Object.values(heading)],
  [FORM_BUILDER_INPUT_CLASSES.SPACER]: [...Object.values(spacer)],
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE]: [...Object.values(singleChoice)],
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE]: [...Object.values(multipleChoice)],
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT]: [...Object.values(multiLineText)],
  [FORM_BUILDER_INPUT_CLASSES.FULL_NAME]: [...Object.values(fullName)],
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD]: [...Object.values(fileUpload)],
  [FORM_BUILDER_INPUT_CLASSES.CONSENT]: [...Object.values(consent)],
  [FORM_BUILDER_INPUT_CLASSES.STAR_RATING]: [...Object.values(starRating)],
  [FORM_BUILDER_INPUT_CLASSES.IMAGE]: [...Object.values(image)],
  [FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE]: [...Object.values(smileyScale)],
  [FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE]: [...Object.values(thumbScale)],
  [FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT]: [...Object.values(communityConsent)],
  [FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE]: [...Object.values(acceptance)],
  [FORM_BUILDER_INPUT_CLASSES.DATE]: [...Object.values(date)],
  [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: [...Object.values(birthdate)],
  [FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE]: [...Object.values(numberScale)],
  [FORM_BUILDER_INPUT_CLASSES.PARAGRAPH]: [...Object.values(paragraph)],
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX]: [...Object.values(singleMatrix)],
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX]: [...Object.values(multipleMatrix)],
  [FORM_BUILDER_INPUT_CLASSES.ADDRESS]: [...Object.values(address)],
  [FORM_BUILDER_INPUT_CLASSES.YOUTUBE]: [...Object.values(youtube)],
  [FORM_BUILDER_INPUT_CLASSES.VIMEO]: [...Object.values(vimeo)]
};
