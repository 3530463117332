import React, { Fragment } from "react";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceThumbScale = ({ item, values, t }) => {
  if (!item) return null;
  const elements = item.elements;
  const { input, legend } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const defaultValues = values == null ? void 0 : values.getAll(input.name);
  return /* @__PURE__ */ React.createElement("fieldset", { className: item.class }, /* @__PURE__ */ React.createElement("legend", null, t(legend == null ? void 0 : legend.nodeValue)), elements.filter((el) => (el == null ? void 0 : el.type) === "radio").map((input2, index) => {
    var _a;
    const labelNodeValue = (_a = elements.find((label) => (label == null ? void 0 : label.for) === (input2 == null ? void 0 : input2.id))) == null ? void 0 : _a.nodeValue;
    return /* @__PURE__ */ React.createElement(Fragment, { key: index }, /* @__PURE__ */ React.createElement("label", { htmlFor: input2 == null ? void 0 : input2.id }, t(labelNodeValue), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: input2 == null ? void 0 : input2.type,
        name: input2 == null ? void 0 : input2.name,
        id: input2 == null ? void 0 : input2.id,
        defaultChecked: (defaultValues == null ? void 0 : defaultValues.indexOf(input2 == null ? void 0 : input2.value)) >= 0,
        value: input2.value,
        required: input2 == null ? void 0 : input2.required
      }
    )));
  }));
};
FormBuilderFormInterfaceThumbScale.displayName = "FormBuilderFormInterfaceThumbScale";
export default React.memo(FormBuilderFormInterfaceThumbScale);
