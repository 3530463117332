var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { forwardRef } from "react";
import "./textarea-primary.sass";
import ValidationAlert from "../validation-alert/validation-alert";
const TextareaPrimary = forwardRef(
  ({
    name,
    type,
    value,
    label,
    placeholder,
    error,
    touched,
    onClick,
    handleBlur,
    dirty,
    handleChange,
    disabled,
    errorColor,
    style,
    defaultValue,
    readonly,
    isCustomPlaceholderColor = false,
    rows,
    onFocus
  }, ref) => {
    const isError = error && touched;
    const errorClassName = isError && !errorColor ? "textarea-primary--error" : "";
    const validClassName = !error && touched !== void 0 && dirty ? "textarea-primary--valid" : "";
    const customPlaceholderColorClass = isCustomPlaceholderColor ? "textarea-primary--custom-placeholder-color" : "";
    const getErrorStyles = () => {
      return isError ? { border: `1px solid ${errorColor}`, color: `${errorColor}` } : {};
    };
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        onClick: () => onClick && onClick(),
        className: `textarea-primary ${errorClassName} ${validClassName} ${customPlaceholderColorClass}`
      },
      label ? /* @__PURE__ */ React.createElement("label", null, label) : null,
      /* @__PURE__ */ React.createElement(
        "textarea",
        {
          "data-testid": "textareaPrimary",
          ref,
          defaultValue,
          placeholder,
          onChange: handleChange,
          onBlur: handleBlur,
          onFocus,
          name,
          type,
          disabled,
          style: __spreadValues(__spreadValues({}, style), getErrorStyles()),
          value,
          rows,
          readOnly: readonly
        },
        value
      ),
      /* @__PURE__ */ React.createElement(ValidationAlert, { errorMsg: error, isVisible: isError, color: errorColor })
    );
  }
);
TextareaPrimary.displayName = "TextareaPrimary";
export default TextareaPrimary;
