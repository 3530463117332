import React, { Fragment } from "react";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceNumberScale = ({ item, values, analyticForm, t }) => {
  if (!item) return null;
  const tags = FormBuilderElementsMappingService.getAllSingleElementHTMLTags(item);
  const legend = tags.legend[0];
  const input = tags.input[0];
  const scaleElements = tags.input;
  const labelElements = tags.div;
  const minLabel = labelElements.find((el) => el.id.includes("min"));
  const maxLabel = labelElements.find((el) => el.id.includes("max"));
  const defaultValues = values == null ? void 0 : values.getAll(input == null ? void 0 : input.name);
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  return /* @__PURE__ */ React.createElement("fieldset", { className: `${item.class}` }, /* @__PURE__ */ React.createElement("legend", null, t(legend == null ? void 0 : legend.nodeValue)), scaleElements.filter((el) => {
    var _a;
    return ((_a = el == null ? void 0 : el.nodeName) == null ? void 0 : _a.includes("input")) && (el == null ? void 0 : el.type) === "radio" || (el == null ? void 0 : el.type) === "checkbox";
  }).map((el, index) => {
    var _a, _b;
    const labelNodeValue = (_a = scaleElements.find((label) => (label == null ? void 0 : label.for) === (el == null ? void 0 : el.id))) == null ? void 0 : _a.nodeValue;
    return /* @__PURE__ */ React.createElement(Fragment, { key: index }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: input == null ? void 0 : input.type,
        name: el == null ? void 0 : el.name,
        id: el == null ? void 0 : el.id,
        onFocus,
        onBlur,
        defaultChecked: (defaultValues == null ? void 0 : defaultValues.indexOf((_b = el == null ? void 0 : el.value) == null ? void 0 : _b.toString())) >= 0,
        value: el == null ? void 0 : el.value,
        required: input == null ? void 0 : input.required,
        "aria-describedby": el == null ? void 0 : el["aria-describedby"]
      }
    ), /* @__PURE__ */ React.createElement("label", { htmlFor: el == null ? void 0 : el.id }, t(labelNodeValue)), index === 0 ? /* @__PURE__ */ React.createElement("div", { id: minLabel == null ? void 0 : minLabel.id, "aria-hidden": "true" }, t(minLabel == null ? void 0 : minLabel.nodeValue)) : null, index === scaleElements.length - 1 ? /* @__PURE__ */ React.createElement("div", { id: maxLabel == null ? void 0 : maxLabel.id, "aria-hidden": "true" }, t(maxLabel == null ? void 0 : maxLabel.nodeValue)) : null);
  }));
};
FormBuilderFormInterfaceNumberScale.displayName = "FormBuilderFormInterfaceNumberScale";
export default React.memo(FormBuilderFormInterfaceNumberScale);
