var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/product-accounts/add-product-accounts-modal.actions";
export const MODAL_TYPES = {
  FACEBOOK: "facebook",
  MAGENTO: "magento",
  SHOPIFY: "shopify",
  WOOCOMMERCE: "woocommerce",
  BIGCOMMERCE: "bigcommerce",
  FTP: "ftp",
  SFTP: "sftp",
  SALESFORCE_COMMERCE_CLOUD: "salesforce_commerce_cloud",
  BAZAARVOICE: "bazaarvoice"
};
export const MODAL_STEPS = {
  FIRST: "first",
  SECOND: "second"
};
export const MODAL_TYPE = {
  EDIT: "edit",
  ADD: "add"
};
export const initialState = {
  modalType: MODAL_TYPE.ADD,
  opened: false,
  data: {},
  step: void 0,
  type: "",
  title: "Add Product Account",
  saveButtonTitle: ""
};
export const addProductAccountsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_EDIT_MODAL:
      return {
        title: "Edit Product Account",
        modalType: MODAL_TYPE.EDIT,
        data: action.payload.data,
        type: action.payload.type,
        opened: true,
        saveButtonTitle: "Edit",
        step: MODAL_STEPS.SECOND
      };
    case ACTIONS.OPEN_REAUTHENTICATE_MODAL:
      return {
        title: "Reauthenticate Product Account",
        modalType: MODAL_TYPE.EDIT,
        data: action.payload.data,
        type: action.payload.type,
        opened: true,
        saveButtonTitle: "Reauthenticate",
        step: MODAL_STEPS.SECOND
      };
    case ACTIONS.OPEN_MODAL:
      return __spreadProps(__spreadValues({}, state), {
        opened: true,
        step: MODAL_STEPS.FIRST
      });
    case ACTIONS.UPDATE_MODAL:
      return __spreadValues(__spreadValues({}, state), action.payload);
    case ACTIONS.CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
export const selectCurrentActiveProductAccountType = (state) => state.type;
export const selectCurrentActiveProductAccountStep = (state) => state.step;
