var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Config } from "../../utils/config";
export const isModalOpen = (state, id) => {
  var _a;
  if (!state) return false;
  return !!((_a = state == null ? void 0 : state.ui) == null ? void 0 : _a.modals[id]);
};
export const getModalData = (state, id) => {
  var _a;
  if (!state) return false;
  return (_a = state == null ? void 0 : state.ui) == null ? void 0 : _a.modals[id];
};
export const isSidebarOpen = (state, id) => {
  var _a;
  if (!state) return false;
  return !!((_a = state == null ? void 0 : state.ui) == null ? void 0 : _a.sidebars[id]);
};
export const getSidebarData = (state, id) => {
  var _a;
  if (!state) return false;
  return __spreadProps(__spreadValues({}, (_a = state == null ? void 0 : state.ui) == null ? void 0 : _a.sidebars[id]), {
    isOpen: isSidebarOpen(state, id)
  });
};
export const UISelectorKeys = {
  DROPDOWNS: "dropdowns",
  INFINITE_LIST: "infiniteLists",
  SIDEBARS: "sidebars",
  MODALS: "modals"
};
export const getInfiniteData = (key, state, id, fetchFnMapper, filterDataFn = Boolean) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  if (!state) return false;
  const _data = (_b = (_a = state == null ? void 0 : state.ui) == null ? void 0 : _a[key]) == null ? void 0 : _b[id];
  return __spreadProps(__spreadValues({}, _data), {
    data: ((_c = _data == null ? void 0 : _data.query) == null ? void 0 : _c.length) > 0 ? (_e = (_d = _data == null ? void 0 : _data.data) == null ? void 0 : _d.filter(filterDataFn)) == null ? void 0 : _e.map((el) => ({ value: el.id, name: el.attributes.name })) : mapDropdownList(_data == null ? void 0 : _data.data, fetchFnMapper, filterDataFn),
    isAbleToLoad: ((_f = _data == null ? void 0 : _data.links) == null ? void 0 : _f.next) && ((_g = _data == null ? void 0 : _data.data) == null ? void 0 : _g.length) !== 0,
    page: Math.round(((_h = _data == null ? void 0 : _data.data) == null ? void 0 : _h.length) / Config.defaultInfiniteListPageSize)
  });
};
const mapDropdownList = (data, fetchFnMapper, filterDataFn) => {
  var _a;
  return ((_a = data == null ? void 0 : data.filter(filterDataFn)) == null ? void 0 : _a.map(fetchFnMapper)) || [];
};
export const getDropdownData = (state, id) => {
  var _a;
  if (!state) return false;
  return (_a = state == null ? void 0 : state.ui) == null ? void 0 : _a.dropdowns[id];
};
