import { hasNullValue } from "../../../utils/helpers/helpers";
export const useDataTranslationsValue = ({ key, element }) => {
  const keyValue = element == null ? void 0 : element[key];
  if (!keyValue || hasNullValue(keyValue)) return {};
  const parse = keyValue && JSON.parse(keyValue);
  const value = Object.entries(parse).map(([key2, value2]) => {
    return {
      [key2]: value2
    };
  }).reduce((acc, obj) => {
    const key2 = Object.keys(obj)[0];
    acc[key2] = obj[key2];
    return acc;
  }, {});
  return value || {};
};
