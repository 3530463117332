var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import autosize from "autosize";
import "./customizable-text.sass";
import { ClickOutsideHOC } from "../../../../../components/HOC/click-outside/click-outside.hoc";
import PropTypes from "prop-types";
import WysiwygContainer from "../../../../../components/wysiwyg/wysiwyg-container";
import debounce from "lodash.debounce";
const resizeDirection = {
  LEFT: "left",
  RIGHT: "right"
};
const widthPageAnimationDuration = 300;
const defaultZIndex = 6;
class CustomizableText extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "onFocus", () => {
      this.setState({ zIndex: defaultZIndex + 1 });
    });
    __publicField(this, "onNavigationRender", (isVisible) => {
      this.setState({ zIndex: isVisible ? defaultZIndex + 1 : defaultZIndex });
    });
    this.resizeDirection = "";
    this.saveData = debounce(this.props.saveData.bind(this), 200);
    this.textUpdate = debounce((value) => this.props.onTextUpdate(value), 100);
    this.textAreaRef = React.createRef();
    this.state = {
      zIndex: defaultZIndex,
      isNavVisible: false,
      currentValue: this.props.defaultValue
    };
  }
  componentDidMount() {
    autosize(this.textAreaRef);
  }
  componentDidUpdate(prevProps) {
    if (this.props.fontSize !== prevProps.fontSize && this.props.pageWidth === prevProps.pageWidth) {
      autosize.update(this.textAreaRef);
    }
    if (this.props.pageWidth !== prevProps.pageWidth) {
      setTimeout(() => {
        autosize.update(this.textAreaRef);
      }, widthPageAnimationDuration);
    }
  }
  onMouseUp() {
    document.onmouseup = null;
    document.onmousemove = null;
    this.saveData();
  }
  onMouseDown(e) {
    document.onmouseup = this.onMouseUp.bind(this);
    document.onmousemove = this.onMouseMove.bind(this);
    this.original_mouse_x = e.pageX;
    this.original_width = parseFloat(
      getComputedStyle(this.draggableElement, null).getPropertyValue("width").replace("px", "")
    );
  }
  onMouseMove(e) {
    this.moveElement.bind(this)(e);
  }
  moveElement(e) {
    switch (this.resizeDirection) {
      case resizeDirection.LEFT:
        this.draggableElement.style.width = this.original_width - (e.pageX - this.original_mouse_x) + "px";
        autosize.update(this.textAreaRef);
        break;
      case resizeDirection.RIGHT:
        this.draggableElement.style.width = e.clientX - this.draggableElement.getBoundingClientRect().left + "px";
        autosize.update(this.textAreaRef);
        break;
    }
  }
  onDraggableRef(node) {
    this.draggableElement = node;
    this.props.setMenuNode(node);
  }
  render() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        ref: this.onDraggableRef.bind(this),
        className: `tint-customizable-text tint-customizable-text--movable tint-customizable-text--${this.props.baseClass}
          tint-customizable-text--${this.state.zIndex > defaultZIndex ? "active" : ""}
          `,
        style: {
          zIndex: this.state.zIndex,
          top: this.props.topPosition,
          width: this.props.width
        }
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          onMouseDown: (e) => {
            this.resizeDirection = resizeDirection.LEFT;
            this.onMouseDown(e);
          },
          className: "circle circle--left"
        }
      ),
      /* @__PURE__ */ React.createElement(
        WysiwygContainer,
        {
          ref: this.textAreaRef,
          textDirection: this.props.textDirection,
          onBlur: () => {
            if (this.state.isAbleToUpdate) this.saveData();
            this.setState({ isAbleToUpdate: false });
          },
          onNavigationRender: this.onNavigationRender,
          fontColor: this.props.color,
          backgroundColor: this.props.backgroundColor,
          onAddFontFamily: this.props.onAddFontFamily,
          style: this.props,
          contentValue: this.props.defaultValue,
          onChange: (data) => {
            this.textUpdate(data);
            this.setState({ isAbleToUpdate: true });
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          onMouseDown: (e) => {
            this.resizeDirection = resizeDirection.RIGHT;
            this.onMouseDown(e);
          },
          className: "circle circle--right"
        }
      )
    ));
  }
}
export default ClickOutsideHOC(CustomizableText);
CustomizableText.propTypes = {
  text: PropTypes.string,
  defaultValue: PropTypes.string,
  baseClass: PropTypes.string,
  textDecoration: PropTypes.string,
  fontStyle: PropTypes.string,
  fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textAlign: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  topPosition: PropTypes.number,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onTextUpdate: PropTypes.func,
  textDirection: PropTypes.string,
  onAddFontFamily: PropTypes.func
};
