import React from "react";
import { FormBuilderFormHint } from "../common/hint/form-builder-form.hint";
const FormBuilderFormInterfaceTextarea = ({ item, values, analyticForm, t }) => {
  if (!item) return null;
  const elements = item.elements;
  const input = elements.find((e) => e.nodeName === "textarea") || {};
  const label = elements.find((e) => e.nodeName === "label") || {};
  const hint = elements.find((e) => {
    var _a;
    return (_a = e.id) == null ? void 0 : _a.includes("hint");
  }) || {};
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  };
  return /* @__PURE__ */ React.createElement("div", { className: item.class }, /* @__PURE__ */ React.createElement("label", { htmlFor: label.for }, t(label.nodeValue)), /* @__PURE__ */ React.createElement(
    "textarea",
    {
      onFocus,
      onBlur,
      name: input.name,
      defaultValue: values == null ? void 0 : values.getAll(input.name),
      id: input.id,
      placeholder: t(input.placeholder),
      required: input.required,
      "aria-describedby": input["aria-describedby"]
    }
  ), /* @__PURE__ */ React.createElement(FormBuilderFormHint, { item: t(hint == null ? void 0 : hint.nodeValue), id: hint == null ? void 0 : hint.id }));
};
FormBuilderFormInterfaceTextarea.displayName = "FormBuilderFormInterfaceTextarea";
export default React.memo(FormBuilderFormInterfaceTextarea);
