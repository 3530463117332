var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/audience-builder.action";
import produce from "immer";
export const initialState = {
  audiences: [],
  members: [],
  selectedMembers: [],
  selectedAudiences: [],
  isMemberListFetching: false,
  isAudienceFetching: false,
  isError: false,
  person: void 0,
  currentMembersFilter: {},
  sidebar: {
    name: void 0,
    data: void 0
  },
  currentAudience: void 0,
  audiencesLinks: void 0,
  membersLinks: void 0,
  modal: {
    name: void 0,
    onSubmit: void 0
  }
};
export const audienceBuilder = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BULK_DELETE_MEMBERS.REQUEST:
    case ACTIONS.DELETE_MEMBER.REQUEST:
    case ACTIONS.DELETE_AUDIENCE.REQUEST:
    case ACTIONS.BULK_DELETE_AUDIENCES.REQUEST:
    case ACTIONS.GET_PERSON.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isError: false
      });
    case ACTIONS.GET_MEMBERS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isMemberListFetching: action.payload,
        isError: false
      });
    case ACTIONS.GET_AUDIENCES.REQUEST:
    case ACTIONS.UPDATE_AUDIENCE.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isAudienceFetching: action.payload,
        isError: false
      });
    case ACTIONS.GET_AUDIENCES.SUCCESS:
      return produce(state, (draft) => {
        draft.isAudienceFetching = false;
        draft.audiences = action.payload.isPagination ? [...state.audiences, ...action.payload.data] : action.payload.data;
        draft.audiencesLinks = action.payload.links;
      });
    case ACTIONS.GET_MEMBERS.SUCCESS:
      return produce(state, (draft) => {
        draft.isMemberListFetching = false;
        draft.members = action.payload.isPagination ? [...state.members, ...action.payload.data] : action.payload.data;
        draft.membersLinks = action.payload.links;
      });
    case ACTIONS.DESELECT_ALL_MEMBERS:
      return produce(state, (draft) => {
        draft.selectedMembers = [];
      });
    case ACTIONS.SELECT_MEMBERS:
      return produce(state, (draft) => {
        draft.selectedMembers = action.payload;
      });
    case ACTIONS.DESELECT_ALL_AUDIENCES:
      return produce(state, (draft) => {
        draft.selectedAudiences = [];
      });
    case ACTIONS.SELECT_AUDIENCES:
      return produce(state, (draft) => {
        draft.selectedAudiences = action.payload;
      });
    case ACTIONS.SET_CURRENT_AUDIENCE:
      return produce(state, (draft) => {
        draft.currentAudience = action.payload;
      });
    case ACTIONS.BULK_DELETE_MEMBERS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.members = draftState.members.filter((member) => {
          return !action.payload.some((p) => p.id === member.id);
        });
        draftState.isMemberListFetching = false;
        draftState.isError = false;
        draftState.selectedMembers = [];
      });
    case ACTIONS.DELETE_MEMBER.SUCCESS:
      return produce(state, (draft) => {
        draft.members = draft.members.filter((member) => member.id !== action.payload);
        draft.isMemberListFetching = false;
      });
    case ACTIONS.BULK_DELETE_AUDIENCES.SUCCESS:
      return produce(state, (draftState) => {
        draftState.audiences = draftState.audiences.filter((a) => {
          return !action.payload.some((p) => p.id === a.id);
        });
        draftState.isAudienceFetching = false;
        draftState.isError = false;
        draftState.selectedAudiences = [];
      });
    case ACTIONS.DELETE_AUDIENCE.SUCCESS:
      return produce(state, (draft) => {
        draft.audiences = draft.audiences.filter((a) => a.id !== action.payload);
        draft.isAudienceFetching = false;
      });
    case ACTIONS.ADD_AUDIENCE.SUCCESS:
      return produce(state, (draft) => {
        draft.audiences = [...state.audiences, action.payload.data];
      });
    case ACTIONS.UPDATE_AUDIENCE.SUCCESS:
      return produce(state, (draft) => {
        draft.audiences = state.audiences.map((a) => {
          if (a.id === action.payload.id) {
            return action.payload;
          }
          return a;
        });
      });
    case ACTIONS.GET_PERSON.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        person: action.payload.data
      });
    case ACTIONS.ADD_PERSON.SUCCESS:
      return produce(state, (draft) => {
        draft.members = [...draft.members, action.payload];
      });
    case ACTIONS.OPEN_MEMBERS_FILTER:
      return __spreadProps(__spreadValues({}, state), {
        isMemberFilterOpen: true
      });
    case ACTIONS.CLOSE_MEMBERS_FILTER:
      return __spreadProps(__spreadValues({}, state), {
        isMemberFilterOpen: false
      });
    case ACTIONS.UPDATE_MEMBERS_FILTER:
      return produce(state, (draft) => {
        draft.currentMembersFilter = __spreadValues(__spreadValues({}, draft.currentMembersFilter), action.payload);
      });
    case ACTIONS.CLEAR_MEMBERS_FILTER:
      return produce(state, (draft) => {
        draft.currentMembersFilter = {};
      });
    case ACTIONS.OPEN_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        sidebar: action.payload
      });
    case ACTIONS.CLOSE_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        sidebar: {
          name: void 0,
          data: void 0
        }
      });
    case ACTIONS.GET_AUDIENCES.FAILURE:
    case ACTIONS.GET_MEMBERS.FAILURE:
    case ACTIONS.BULK_DELETE_MEMBERS.FAILURE:
    case ACTIONS.DELETE_MEMBER.FAILURE:
    case ACTIONS.DELETE_AUDIENCE.FAILURE:
    case ACTIONS.BULK_DELETE_AUDIENCES.FAILURE:
    case ACTIONS.ADD_AUDIENCE.FAILURE:
    case ACTIONS.UPDATE_AUDIENCE.FAILURE:
    case ACTIONS.GET_PERSON.FAILURE:
    case ACTIONS.GET_EXPORT_MEMBERS.FAILURE:
    case ACTIONS.GET_EXPORT_MEMBERS_STATUS.FAILURE:
    case ACTIONS.GET_IMPORT_MEMBERS.FAILURE:
    case ACTIONS.GET_IMPORT_MEMBERS_STATUS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isError: action.payload,
        isAudienceFetching: false,
        isMemberListFetching: false
      });
    default:
      return state;
  }
};
