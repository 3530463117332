import * as Yup from "yup";
import { Config } from "../../../../utils/config";
export const validationSchemaBynder = () => {
  return Yup.object({
    site: Yup.string().required("URL is required.").matches(Config.regExp.httpPattern, "Incorrect URL."),
    client_id: Yup.string().required("Client ID is required."),
    secret_id: Yup.string().required("Secret ID is required.")
  });
};
export const validationSchemaWiden = () => {
  return Yup.object({
    access_token: Yup.string().required("Access token is required.")
  });
};
export const validationSchemaSalesfoce = () => {
  return Yup.object({
    site: Yup.string().required("URL is required.").matches(Config.regExp.httpPattern, "Incorrect URL."),
    client_id: Yup.string().required("Client ID is required."),
    secret_id: Yup.string().required("Secret ID is required.")
  });
};
export const validationSchemaAdobe = () => {
  return Yup.object({
    site: Yup.string().required("URL is required.").matches(Config.regExp.httpPattern, "Incorrect URL."),
    client_id: Yup.string().required("Username is required."),
    secret_id: Yup.string().required("Passowrd is required.")
  });
};
export const validationSchemaTwitter = () => {
  return Yup.object({
    client_id_v1: Yup.string().required("Client ID (OAuth 1.0) required"),
    client_id: Yup.string().required("Client ID (OAuth 2.0) required"),
    secret_id_v1: Yup.string().required("Client Secret (OAuth 1.0) is required."),
    secret_id: Yup.string().required("Client Secret (OAuth 2.0) is required.")
  });
};
export const validationSchemaBrandfolder = () => {
  return Yup.object({
    brandfolder: Yup.string().required("API Key is required.")
  });
};
