import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_ASSETS_AGGREGATIONS: createRoutine(createPrefixedActionName("ASSETS_AGGREGATIONS", "GET_ASSETS_AGGREGATIONS")),
  UPDATE_ASSETS_AGGREGATIONS_TAGS: createPrefixedActionName("ASSETS_AGGREGATIONS", "UPDATE_ASSETS_AGGREGATIONS_TAGS")
};
export const getAssetsAggregationsStart = () => ({
  type: ACTIONS.GET_ASSETS_AGGREGATIONS.REQUEST
});
export const getAssetsAggregationsSuccess = (data) => ({
  type: ACTIONS.GET_ASSETS_AGGREGATIONS.SUCCESS,
  payload: data
});
export const getAssetsAggregationsError = (err) => ({
  type: ACTIONS.GET_ASSETS_AGGREGATIONS.FAILURE,
  payload: err
});
export const updateAssetsAggregationsTags = (data) => ({
  type: ACTIONS.UPDATE_ASSETS_AGGREGATIONS_TAGS,
  payload: data
});
