var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./tint-editor-manage-cta-sidebar.sass";
import { useFormik } from "formik";
import InputPrimary from "../../../../../../components/interface/inputs/input-primary/input-primary";
import DropdownButton, { ButtonType } from "../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSimpleList from "../../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import { Button } from "../../../../../../components/interface/button/button";
import TintSidebarSwitcherRemoveModalConfirm from "../../../../../../components/sidebar-switcher/components/remove-modal-confirm/sidebar-switcher-remove-modal-confirm";
import * as Yup from "yup";
import { Config } from "../../../../../../utils/config";
import { TintSidebarSwitcherStateType } from "../../../../../../components/sidebar-switcher/sidebar-switcher";
const TintEditorManageCtaSidebar = ({ data, onChangeData, onRemove, state, assignMode }) => {
  var _a;
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const formik = useFormik({
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: {
      name: (data == null ? void 0 : data.name) || "",
      text: (data == null ? void 0 : data.text) || "",
      url: (data == null ? void 0 : data.url) || "",
      savedFilterId: (data == null ? void 0 : data.savedFilterId) || ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(1, "is too short (minimum is 1 character)").required("Can	 be blank"),
      text: Yup.string().min(1, "is too short (minimum is 1 character)").required("Can't be blank"),
      url: Yup.string().required("URL is required").matches(Config.regExp.strictHttp, "Incorrect URL address")
    })
  });
  useEffect(() => {
    onChangeData(__spreadProps(__spreadValues({}, formik.values), { isValid: formik.isValid && formik.dirty }));
  }, [formik.isValid, formik.values, formik.dirty]);
  const onRemoveClick = () => {
    setDisplayConfirmModal(true);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-manage-cta-sidebar" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-manage-cta-sidebar__content" }, /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      touched: formik.touched.name,
      error: formik.errors.name,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      placeholder: "Enter Title",
      name: "name",
      label: "CTA Title",
      value: formik.values.name
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      touched: formik.touched.text,
      error: formik.errors.text,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      placeholder: "Enter Text",
      name: "text",
      label: "Button Text",
      value: formik.values.text
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      touched: formik.touched.url,
      error: formik.errors.url,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      placeholder: "Enter URL",
      name: "url",
      label: "Link URL",
      value: formik.values.url
    }
  )), !assignMode && /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement("p", { className: "sidebar-title" }, "Apply Saved Filter"), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: data == null ? void 0 : data.filters.find((savedFilter) => savedFilter.id === formik.values.savedFilterId),
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, props), { isDefaultOption: true, defaultOptionTitle: "None" })),
      list: (data == null ? void 0 : data.filters) || [],
      placeholder: "None",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: (selectedItem) => {
        formik.setFieldValue("savedFilterId", selectedItem ? selectedItem == null ? void 0 : selectedItem.id : void 0);
      }
    }
  ))), state !== TintSidebarSwitcherStateType.CREATE && /* @__PURE__ */ React.createElement(Button, { type: "gray", text: "Delete CTA", size: "full-width", onClick: onRemoveClick }), displayConfirmModal && /* @__PURE__ */ React.createElement(
    TintSidebarSwitcherRemoveModalConfirm,
    {
      onConfirm: () => onRemove(data.id),
      onCancel: () => setDisplayConfirmModal(false),
      description: ((_a = data == null ? void 0 : data.posts) == null ? void 0 : _a.length) ? `This CTA is currently applied to ${data.posts.length} posts. Are you sure you want to permanently delete this CTA and remove it from all posts?` : "This CTA is currently no applied to any posts. Are you sure you want to permanently delete this CTA?",
      title: "Are you sure?",
      cancelButtonText: "No, Keep CTA",
      confirmButtonText: "Yes, Delete CTA"
    }
  ));
};
export default React.memo(TintEditorManageCtaSidebar);
