var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import "./assign-products-sidebar.sass";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import ProductItem from "../products/product-item/product-item";
import TintSidebar, { TintSidebarHeightType } from "../../../../components/sidebar/sidebar";
import SearchInput from "../../../../components/search-input/search-input";
import {
  assignProduct,
  dismissDrawRectUI,
  selectProduct,
  editRectUIStatus,
  unselectProduct,
  cancelDrawRectUI
} from "../../actions/ui-ecommerce.actions";
import {
  SidebarFooter,
  SidebarFooterClickButtonAction
} from "../../../../components/sidebar/components/footer/sidebar-footer";
import { postProductTag, deleteProductTag } from "../../actions/ui-ecommerce.fat-actions";
import { getTaggedProducts } from "../../reducer/ui-ecommerce.reducer";
import { ImageDraggableRectStatus } from "../image-draggable-rect/image-draggable-rect";
import { toast } from "react-toastify";
import DropdownSimpleList from "../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import DropdownInfiniteList from "../../../../components/interface/dropdown/lists/infinite-list/dropdown-infinite-list";
import PreloaderComponent from "../../../../components/interface/preloaders/preloader/preloader";
import { setDefaultFilteredProducts } from "../../../account-settings/actions/products/products.actions";
import { handleApiError } from "../../../../services/functions/error-handler/error-handler";
import { addPostProductTag, deletePostProductTag } from "../../../tint-editor/actions/posts.actions";
import { getSocialFeed } from "../../../tint-editor/reducers/posts.reducer";
import { TintEditorConfig } from "../../../tint-editor/utils/tint-editor.config";
export const AssignProductsSidebarStepType = {
  STEP_ONE: "step-one",
  STEP_TWO: "step-two",
  STEP_THREE: "step-three"
};
const AssignProductsSidebar = ({
  isSidebarVisible,
  onInputChange,
  onProductFeedChange,
  onProductCategoryChange,
  onProductBrandChange,
  onAssignProduct,
  onSidebarClose,
  filteredProductList,
  filteredProductListLinks,
  fetchNextProducts,
  fetchNextProductFeeds,
  onSearchProductFeeds,
  productsError,
  productFeeds,
  productCategories,
  productBrand,
  onAddProduct,
  onProductOver,
  onProductLeave,
  post,
  tintId,
  isPending,
  productFilters,
  isAbleToLoadProductFeeds
}) => {
  var _a, _b;
  const dispatch = useDispatch();
  const uiEcommerce = useSelector((state) => state.UIEcommerce);
  const filteredAndInfiniteLoadedProductList = useSelector((state) => state.products.infiniteLoadedData);
  const taggedProducts = uiEcommerce.taggedProducts;
  const hasTaggedProducts = ((_b = (_a = uiEcommerce == null ? void 0 : uiEcommerce.data) == null ? void 0 : _a.included) == null ? void 0 : _b.length) === 0;
  const currentRect = uiEcommerce.currentRect;
  const currentProduct = uiEcommerce.selectedProduct;
  const [searchInputValue, setSearchInputValue] = useState("");
  const currentTaggedProduct = getTaggedProducts(uiEcommerce);
  const sidebarStatus = uiEcommerce.sidebarStatus;
  const socialFeed = useSelector((state) => getSocialFeed(state.tintEditorPosts, post == null ? void 0 : post.id));
  const onProductRemove = (productId, rectId) => {
    dispatch(deleteProductTag({ id: rectId, tintId })).then(() => {
      dispatch(deletePostProductTag(post.attributes.external_id, rectId, post.attributes.attachment_id));
      toast.success("Product tag has been removed successfully");
    }).catch((err) => handleApiError(err, toast.error));
  };
  const onSelectProduct = (product) => {
    dispatch(selectProduct(product));
    (currentTaggedProduct == null ? void 0 : currentTaggedProduct.rectId) && dispatch(editRectUIStatus(ImageDraggableRectStatus.ASSIGN, currentTaggedProduct.rectId));
  };
  const mapProductTag = (productTag) => {
    return {
      id: productTag.productId,
      name: productTag.name,
      sku: productTag.sku,
      imageUrl: productTag.image_url ? productTag.image_url : productTag.imageUrl
    };
  };
  const selectedProductFeed = productFilters && productFilters.product_feed_ids ? productFeeds.find((e) => e.key === productFilters.product_feed_ids) : void 0;
  const selectedCategory = productFilters && productFilters.categories ? productCategories.find((e) => e.key === productFilters.categories) : void 0;
  const selectedBrand = productFilters && productFilters.brand ? productBrand.find((e) => e.key === productFilters.brand) : void 0;
  const renderProductListView = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-assign-products-sidebar__filters" }, /* @__PURE__ */ React.createElement("div", { className: "tint-assign-products-sidebar__search" }, /* @__PURE__ */ React.createElement(
      SearchInput,
      {
        onInputChange: (e) => {
          onInputChange(e);
          setSearchInputValue(e);
        },
        inputValue: searchInputValue
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-assign-products-sidebar__search" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(
          DropdownInfiniteList,
          __spreadProps(__spreadValues({}, props), {
            onLoadData: fetchNextProductFeeds,
            isAbleToLoad: productFeeds.length >= 25 && isAbleToLoadProductFeeds,
            onInputChange: onSearchProductFeeds
          })
        ),
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        list: productFeeds,
        placeholder: "Product Feed",
        iconRight: "fa fa-caret-down",
        currentItem: selectedProductFeed,
        onChangeValue: onProductFeedChange
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-assign-products-sidebar__wrapper" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadValues({}, props)),
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        list: productBrand,
        placeholder: "Brand",
        iconRight: "fa fa-caret-down",
        currentItem: selectedBrand,
        onChangeValue: onProductBrandChange
      }
    ), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadValues({}, props)),
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        list: productCategories,
        placeholder: "Category",
        iconRight: "fa fa-caret-down",
        currentItem: selectedCategory,
        onChangeValue: onProductCategoryChange
      }
    ))), /* @__PURE__ */ React.createElement("ul", { className: "tint-assign-products-sidebar__product-list" }, filteredProductList.length > 0 ? /* @__PURE__ */ React.createElement(
      InfiniteScroll,
      {
        dataLength: productsError ? 0 : filteredProductList.length,
        next: fetchNextProducts,
        hasMore: filteredProductListLinks && filteredProductListLinks.next && !productsError,
        loader: /* @__PURE__ */ React.createElement(PreloaderComponent, null),
        scrollableTarget: "scrollableTarget"
      },
      filteredProductList.map((product) => /* @__PURE__ */ React.createElement("li", { key: product.id }, /* @__PURE__ */ React.createElement(
        ProductItem,
        {
          isDisabled: !currentRect && post.attributes.type === "image",
          isActive: currentProduct && currentProduct.id === product.id,
          productDetails: product,
          onProductClick: onSelectProduct
        }
      )))
    ) : /* @__PURE__ */ React.createElement("div", { className: "tint-assign-products-sidebar__no-products-msg" }, /* @__PURE__ */ React.createElement("h4", null, "No Products Found"))));
  };
  const renderSelectedProductsList = () => {
    return /* @__PURE__ */ React.createElement("ul", { className: "tint-assign-products-sidebar__product-list" }, taggedProducts.length > 0 ? taggedProducts.map(
      (product) => product.productId && /* @__PURE__ */ React.createElement("li", { key: product.rectId }, /* @__PURE__ */ React.createElement(
        ProductItem,
        {
          isActive: currentTaggedProduct && currentTaggedProduct.productId === product.productId && currentTaggedProduct.rectId === product.rectId,
          key: product.productId,
          productDetails: mapProductTag(product),
          onProductClick: onAddProduct,
          onProductOver,
          onProductLeave,
          onProductRemove: () => onProductRemove(product.productId, product.rectId)
        }
      ))
    ) : /* @__PURE__ */ React.createElement("div", { className: "tint-assign-products-sidebar__no-products-msg" }, /* @__PURE__ */ React.createElement("h4", null, "No Products Assigned"), /* @__PURE__ */ React.createElement("p", null, "To assign a product, you first need to add a product tag to the post.")));
  };
  const sidebarViewSwitcher = (type) => {
    switch (type) {
      case AssignProductsSidebarStepType.STEP_TWO:
        return renderProductListView();
      case AssignProductsSidebarStepType.STEP_ONE:
      case AssignProductsSidebarStepType.STEP_THREE:
        return renderSelectedProductsList();
      default:
        return;
    }
  };
  const getCurrentProduct = (productId) => {
    return filteredAndInfiniteLoadedProductList.find((product) => product.id === productId);
  };
  const onProductListClose = () => {
    dispatch(cancelDrawRectUI());
    dispatch(setDefaultFilteredProducts());
  };
  const createNewProductTag = (productId, rectId) => {
    var _a2, _b2, _c, _d, _e;
    const taggedProduct = taggedProducts.find((e) => e.status === ImageDraggableRectStatus.ASSIGN);
    const productAttributes = taggedProduct.x && taggedProduct.y ? __spreadValues({
      x: taggedProduct.x.toString(),
      y: taggedProduct.y.toString(),
      height: taggedProduct.height.toString(),
      width: taggedProduct.width.toString(),
      product_id: productId,
      external_id: post.social_post_id || post.attributes.external_id,
      source: (_a2 = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a2.source
    }, post.attributes.attachment_id || post.attributes.attachment_id === 0 ? { attachment_id: String(post.attributes.attachment_id) } : {}) : __spreadValues({
      product_id: productId,
      external_id: post.social_post_id || post.attributes.external_id,
      source: (_b2 = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _b2.source
    }, post.attributes.attachment_id || post.attributes.attachment_id === 0 ? { attachment_id: String(post.attributes.attachment_id) } : {});
    return dispatch(
      postProductTag({
        tintId: tintId !== TintEditorConfig.routePaths.global.tintId ? tintId : (_e = (_d = (_c = post == null ? void 0 : post.relationships) == null ? void 0 : _c.tint) == null ? void 0 : _d.data) == null ? void 0 : _e.id,
        rectId,
        productId,
        attributes: productAttributes
      })
    );
  };
  const handleProductsListFooterButtonAction = (buttonType) => {
    switch (buttonType) {
      case SidebarFooterClickButtonAction.LEFT_BUTTON:
        onProductListClose();
        break;
      case SidebarFooterClickButtonAction.RIGHT_BUTTON:
        createNewProductTag(currentProduct.id, currentRect.rectId).then((res) => {
          toast.success("Product tag has been created successfully");
          dispatch(assignProduct(getCurrentProduct(currentProduct.id), res.id));
          dispatch(dismissDrawRectUI());
          dispatch(unselectProduct());
          onAssignProduct && onAssignProduct();
          dispatch(
            addPostProductTag(post.attributes.external_id, {
              type: "product_tag",
              id: res.id,
              attachmentId: post.attributes.attachment_id
            })
          );
        }).catch(() => toast.error("Cannot create a product tag, please try again"));
        break;
      default:
        return;
    }
  };
  const renderSidebarFooter = (props) => {
    switch (sidebarStatus) {
      case AssignProductsSidebarStepType.STEP_TWO:
        return /* @__PURE__ */ React.createElement(
          SidebarFooter,
          __spreadProps(__spreadValues({}, props), {
            oneButton: filteredProductList.length === 0,
            onClickButton: handleProductsListFooterButtonAction,
            isSubmitting: false,
            isRightButtonDisabled: !currentProduct || !getCurrentProduct(currentProduct.id),
            rightButtonText: "Assign Product"
          })
        );
      case AssignProductsSidebarStepType.STEP_ONE:
      case AssignProductsSidebarStepType.STEP_THREE:
        return /* @__PURE__ */ React.createElement(
          SidebarFooter,
          __spreadProps(__spreadValues({}, props), {
            oneButton: true,
            onClickButton: onSidebarClose,
            isSubmitting: false,
            leftButtonText: taggedProducts.length === 0 && hasTaggedProducts ? "Cancel" : "Done"
          })
        );
      default:
        return /* @__PURE__ */ React.createElement(SidebarFooter, __spreadProps(__spreadValues({}, props), { onClickButton: handleProductsListFooterButtonAction, oneButton: true }));
    }
  };
  return /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      isOpen: isSidebarVisible,
      onClose: onSidebarClose,
      type: TintSidebarHeightType.BELOW_NAVBAR,
      title: sidebarStatus === AssignProductsSidebarStepType.STEP_TWO ? "Assign Products" : "Assigned Products",
      defaultFooterStyle: false,
      footerComponent: (props) => renderSidebarFooter(props),
      sidebarClassName: "tint-assign-products-sidebar"
    },
    isPending ? /* @__PURE__ */ React.createElement(PreloaderComponent, null) : sidebarViewSwitcher(sidebarStatus)
  );
};
export default AssignProductsSidebar;
