var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/personalization.action";
import produce from "immer";
const defaultSidebarState = {
  isOpen: false,
  actionName: void 0,
  personalization: []
};
const defaultCustomCSSState = {
  isOpen: false,
  type: void 0
};
const initialState = {
  data: [],
  sidebar: defaultSidebarState,
  customCSS: defaultCustomCSSState,
  isPending: false,
  filters: {
    value: void 0,
    type: void 0,
    tags: void 0
  },
  current: void 0,
  content: void 0,
  tagsList: [],
  selectedPersonalizations: []
};
export const personalization = (state = initialState, action) => {
  var _a, _b, _c;
  switch (action.type) {
    case ACTIONS.DELETE_PERSONALIZATION.REQUEST:
    case ACTIONS.POST_PERSONALIZATION.REQUEST:
    case ACTIONS.GET_PERSONALIZATION.REQUEST:
    case ACTIONS.GET_PERSONALIZATIONS.REQUEST:
    case ACTIONS.BULK_DELETE_PERSONALIZATIONS.REQUEST:
    case ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true
      });
    case ACTIONS.GET_PERSONALIZATIONS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        tagsList: (_c = (_b = (_a = action.payload) == null ? void 0 : _a.meta) == null ? void 0 : _b.aggregations) == null ? void 0 : _c.tags,
        data: action.payload.data,
        links: action.payload.links,
        isPending: false
      });
    case ACTIONS.GET_PERSONALIZATION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        current: action.payload.data,
        isPending: false
      });
    case ACTIONS.PATCH_PERSONALIZATION.SUCCESS:
      return produce(state, (draft) => {
        draft.current = action.payload.data;
        draft.data = state.data ? state.data.map(
          (personalization2) => personalization2.id !== action.payload.data.id ? personalization2 : action.payload.data
        ) : void 0;
      });
    case ACTIONS.POST_PERSONALIZATION.SUCCESS:
      return produce(state, (draft) => {
        draft.data = [action.payload.data, ...draft.data];
        draft.isPending = false;
      });
    case ACTIONS.DELETE_PERSONALIZATION.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((personalization2) => personalization2.id !== action.payload);
        draft.isPending = false;
      });
    case ACTIONS.SHOW_PERSONALIZATION_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        sidebar: {
          actionName: action.payload.actionName,
          isOpen: true,
          personalization: action.payload.personalization
        }
      });
    case ACTIONS.HIDE_PERSONALIZATION_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        sidebar: {
          isOpen: false,
          actionName: void 0,
          personalization: void 0
        }
      });
    case ACTIONS.SHOW_CUSTOM_CSS:
      return __spreadProps(__spreadValues({}, state), {
        customCSS: {
          type: action.payload,
          isOpen: true
        }
      });
    case ACTIONS.HIDE_CUSTOM_CSS:
      return __spreadProps(__spreadValues({}, state), {
        customCSS: {
          isOpen: false,
          type: void 0
        }
      });
    case ACTIONS.UPDATE_CONTENT:
      return __spreadProps(__spreadValues({}, state), {
        content: action.payload
      });
    case ACTIONS.SELECT_PERSONALIZATION:
      return produce(state, (draft) => {
        draft.selectedPersonalizations = [...draft.selectedPersonalizations, action.payload];
      });
    case ACTIONS.UNSELECT_PERSONALIZATION:
      return produce(state, (draft) => {
        draft.selectedPersonalizations = draft.selectedPersonalizations.filter(({ id }) => {
          return action.payload !== id;
        });
      });
    case ACTIONS.DESELECT_ALL_PERSONALIZATIONS:
      return produce(state, (draft) => {
        draft.selectedPersonalizations = [];
      });
    case ACTIONS.BULK_DELETE_PERSONALIZATIONS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = draftState.data.filter((personalization2) => {
          return !action.payload.some((p) => p.id === personalization2.id);
        });
        draftState.isPending = false;
        draftState.isError = false;
        draftState.selectedPersonalizations = [];
      });
    case ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.selectedPersonalizations = draftState.selectedPersonalizations.map((personalization2) => __spreadProps(__spreadValues({}, personalization2), {
          attributes: __spreadProps(__spreadValues({}, personalization2.attributes), {
            status: personalization2.attributes.status === "active" ? "archived" : "active"
          })
        }));
        draftState.isPending = false;
        draftState.data = draftState.data.map((personalization2) => {
          if (draftState.selectedPersonalizations.find(
            (selectedPersonalization) => selectedPersonalization.id === personalization2.id
          )) {
            return __spreadProps(__spreadValues({}, personalization2), {
              attributes: __spreadProps(__spreadValues({}, personalization2.attributes), {
                status: personalization2.attributes.status === "active" ? "archived" : "active"
              })
            });
          }
          return personalization2;
        });
      });
    case ACTIONS.BULK_DELETE_PERSONALIZATIONS.FAILURE:
    case ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isPending: false,
        errors: action.payload
      });
    default:
      return state;
  }
};
