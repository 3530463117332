var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./assets-filter-bar.sass";
import { SearchInput } from "../interface/search-input/search-input";
import { ButtonType, DropdownButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import DropdownMultiSelectList from "../../../../components/interface/dropdown/lists/multiselect-list/dropdown-multiselect-list";
import DropdownSimpleList from "../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import { useSelector, useDispatch } from "react-redux";
import DropdownSearchList from "../../../../components/interface/dropdown/lists/search-list/dropdown-search-list";
import DropdownButton from "../../../../components/interface/dropdown/button/dropdown-button";
import { getAssets } from "../../actions/asset-manager.fat-actions";
import {
  clearFilterOption,
  setFilterOption,
  setSearchPhraseOption
} from "../../actions/filters/asset-manager-filters.actions";
import { getAssetUrlWithFilters } from "../../utils/filter-helper/filter-helper";
import { SearchListType } from "../interface/search-input/search-list/search-list";
import useDebounce from "../../../../components/hooks/use-debounce/use-debounce";
import { DropdownMultiSelectListHeader } from "../../../../components/interface/dropdown/lists/multiselect-list/header/dropdown-multiselect-list-header";
import { Button } from "../../../../components/interface/button/button";
import { Config } from "../../../../utils/config";
import DropdownDateRangeCalendar from "../../../../components/interface/dropdown/lists/date-calendar-range/dropdown-date-range-calendar";
import { DropdownDateRangeHeader } from "../../../../components/interface/dropdown/lists/date-list/header/dropdown-date-range-header/dropdown-date-range-header";
import FeatureGuard from "../../../../guards/feature-guard";
import { GuardNames } from "../../../../guards/guards-list";
import { AssetsSortBy } from "../assets-sort-by/assets-sort-by";
import { assetTypeList } from "./asset-filter-bar.init";
export const sourceList = [
  {
    value: "instagram,instagram_business",
    name: "Instagram (UGC)"
  },
  {
    value: "twitter",
    name: "X (UGC)"
  },
  {
    value: "facebook",
    name: "Facebook (UGC)"
  },
  {
    value: "public_post",
    name: "Public Post"
  },
  {
    value: "branded",
    name: "Owned Content"
  },
  {
    value: "form_submission",
    name: "Form Submission"
  },
  {
    value: "externally_sourced_posts",
    name: "Browser Extensions"
  },
  {
    value: "threads",
    name: "Threads"
  },
  {
    value: "creator_iq",
    name: "CreatorIQ"
  }
];
export const AssetFilters = {
  ASSET_TYPE: "asset-type",
  SOURCE: "source",
  SENTIMENT: "sentiment",
  OBJECTS: "objects",
  TAGS: "tags",
  DATE: "date",
  SORT: "sort",
  COLLECTION: "collection"
};
const defaultFilterList = [
  AssetFilters.ASSET_TYPE,
  AssetFilters.SOURCE,
  AssetFilters.SENTIMENT,
  AssetFilters.OBJECTS,
  AssetFilters.TAGS,
  AssetFilters.DATE
];
export const AssetsFilterBar = ({ filterList = defaultFilterList }) => {
  var _a;
  const debounceDelay = 700;
  const assetManager = useSelector((state) => state.assetManager);
  const assetsAggregations = useSelector((state) => state.assetsAggregations);
  const assetManagerFilters = useSelector((state) => state.assetManagerFilters);
  const assetCollections = useSelector((state) => state.assetCollections);
  const history = useHistory();
  const location = useLocation();
  const [inputValue, setInputValue] = useState();
  const dispatch = useDispatch();
  const debouncedPhrase = useDebounce(inputValue, debounceDelay);
  useEffect(() => {
    if (inputValue !== void 0) {
      dispatch(setSearchPhraseOption(inputValue));
      dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
    }
  }, [debouncedPhrase, dispatch]);
  const assetTags = assetsAggregations.tags.map((item) => {
    const objectFilterElement = assetManagerFilters.filter.tags.find((e) => e.name === item);
    return {
      name: item,
      value: item,
      isActive: objectFilterElement && objectFilterElement.isActive
    };
  });
  const assetObjects = assetsAggregations.objects.map((item) => {
    const objectFilterElement = assetManagerFilters.filter.objects.find((e) => e.name === item);
    return {
      name: item,
      value: item,
      isActive: objectFilterElement && objectFilterElement.isActive
    };
  });
  const assetSentiment = assetsAggregations.emotions.map((item) => {
    const el = assetManagerFilters.filter.sentiment.find((e) => e.name === item);
    return {
      name: item,
      value: item,
      isActive: el && el.isActive
    };
  });
  const collections = (_a = assetCollections == null ? void 0 : assetCollections.data) == null ? void 0 : _a.map((item) => {
    const objectFilterElement = assetManagerFilters.filter.collections.id === item.id;
    return {
      name: item.attributes.name,
      value: item.id,
      isActive: objectFilterElement && objectFilterElement.isActive
    };
  });
  const onPropertyTypeChange = (selectedItem, propertyName) => {
    const searchParams = new URLSearchParams(location.search);
    if ((selectedItem == null ? void 0 : selectedItem.value) !== void 0) {
      searchParams.set(propertyName, selectedItem.value);
    } else {
      searchParams.delete(propertyName);
    }
    history.push({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : ""
    });
    dispatch(
      setFilterOption({
        [propertyName]: selectedItem === void 0 ? "" : selectedItem
      })
    );
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
  };
  const onAssetDropdownChange = (selectedItem) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedItem) {
      searchParams.set("tag", selectedItem.value);
    } else {
      searchParams.delete("tag");
    }
    history.push({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : ""
    });
    dispatch(
      setFilterOption({
        tags: selectedItem === void 0 ? [] : [__spreadProps(__spreadValues({}, selectedItem), { isActive: true })]
      })
    );
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
  };
  const onDateDropdownChange = (selectedItem) => {
    if (!selectedItem) return;
    dispatch(
      setFilterOption({
        date: __spreadProps(__spreadValues({}, selectedItem.value), {
          name: selectedItem.name
        })
      })
    );
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
  };
  const onMultiSelectDropdownChange = (key, arr) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(key);
    arr.filter((e) => e.isActive).forEach((e) => {
      searchParams.append(key, e.value);
    });
    history.push({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : ""
    });
    dispatch(setFilterOption({ [key]: arr }));
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
  };
  const onSearchInputChange = (value) => {
    onSearchPhraseChange(value.name);
  };
  const clearFilters = () => {
    dispatch(clearFilterOption());
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
  };
  const onSearchPhraseChange = (phrase) => {
    setInputValue(phrase);
  };
  const imagesList = assetManager == null ? void 0 : assetManager.data.filter((item) => {
    var _a2;
    return item.attributes.name !== null && ((_a2 = item.attributes.mime_type) == null ? void 0 : _a2.includes(SearchListType.IMAGES));
  }).flatMap((item) => item.attributes);
  const videosList = assetManager == null ? void 0 : assetManager.data.filter((item) => {
    var _a2;
    return item.attributes.title !== null && ((_a2 = item.attributes.mime_type) == null ? void 0 : _a2.includes(SearchListType.VIDEOS));
  }).flatMap((item) => item.attributes);
  const dateDropdownPlaceholder = `All Time - ${Config.monthList[(/* @__PURE__ */ new Date()).getMonth()]} ${(/* @__PURE__ */ new Date()).getDate()}, ${(/* @__PURE__ */ new Date()).getFullYear()}`;
  const isAnyFilterSelected = () => {
    return assetManagerFilters.filter.objects.find((e) => e.isActive) !== void 0 || assetManagerFilters.filter.tags.find((e) => e.isActive) !== void 0 || assetManagerFilters.filter.assetType !== "" || assetManagerFilters.filter.date || assetManagerFilters.filter.source !== "";
  };
  const getSourcesList = () => {
    return sourceList.filter((e) => assetsAggregations.sources.some((i) => e.value.includes(i)));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-assets-filter-bar" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    SearchInput,
    {
      images: imagesList,
      videos: videosList,
      onChange: onSearchInputChange,
      onClear: onSearchPhraseChange,
      onEnter: onSearchPhraseChange,
      onValueChange: onSearchPhraseChange,
      searchPhrase: assetManagerFilters.searchPhrase || ""
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-assets-filter-bar__filter-section" }, filterList.includes(AssetFilters.COLLECTION) && /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.collectionId,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadValues({}, props)),
      list: collections,
      placeholder: "Collection",
      iconLeft: "fas fa-camera",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue: (selectedItem) => onPropertyTypeChange(selectedItem.value, "collectionId")
    }
  ), filterList.includes(AssetFilters.ASSET_TYPE) && /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.assetType,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadValues({}, props)),
      list: assetTypeList,
      placeholder: "Asset Type",
      iconLeft: "fas fa-camera",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue: (selectedItem) => onPropertyTypeChange(selectedItem, "assetType")
    }
  ), filterList.includes(AssetFilters.SOURCE) && /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.source,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadValues({}, props)),
      list: getSourcesList(),
      placeholder: "Source",
      iconLeft: "fas fa-globe",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue: (selectedItem) => onPropertyTypeChange(selectedItem, "source")
    }
  ), filterList.includes(AssetFilters.SENTIMENT) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.ASSETS_METADATA_IMAGE_SENTIMENT }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.sentiment,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownMultiSelectListHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownMultiSelectList, __spreadValues({}, props)),
      list: assetSentiment,
      dropdownType: DropdownButtonType.MULTI_SELECT,
      placeholder: "Sentiment",
      iconLeft: "fas fa-shapes",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue: (arr) => onMultiSelectDropdownChange("sentiment", arr)
    }
  ))), filterList.includes(AssetFilters.OBJECTS) && /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.objects,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownMultiSelectListHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownMultiSelectList, __spreadValues({}, props)),
      list: assetObjects,
      dropdownType: DropdownButtonType.MULTI_SELECT,
      placeholder: "Objects",
      iconLeft: "fas fa-shapes",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue: (arr) => onMultiSelectDropdownChange("objects", arr)
    }
  ), filterList.includes(AssetFilters.TAGS) && /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.tags[0],
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSearchList, __spreadValues({}, props)),
      list: assetTags,
      placeholder: "Tags",
      iconLeft: "fas fa-tag",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DEFAULT,
      onChangeValue: onAssetDropdownChange
    }
  )), filterList.includes(AssetFilters.DATE) && /* @__PURE__ */ React.createElement("div", { className: "tint-assets-filter-bar__right" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: assetManagerFilters.filter.date,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownDateRangeHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownDateRangeCalendar, __spreadValues({}, props)),
      list: [],
      placeholder: dateDropdownPlaceholder,
      iconLeft: "fas fa-tag",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_DATE_RANGE,
      onChangeValue: onDateDropdownChange
    }
  ), isAnyFilterSelected() ? /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: clearFilters,
      type: "stroked",
      size: "medium",
      text: "Clear filters",
      className: "margin-left-10"
    }
  ) : null), filterList.includes(AssetFilters.SORT) && /* @__PURE__ */ React.createElement(AssetsSortBy, null));
};
