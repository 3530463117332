var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { getFooter, getSocial, getHeader, getTagOWar } from "../shared.data";
import colors from "../colors";
const currentDate = /* @__PURE__ */ new Date();
const yesterday = new Date(currentDate.setDate(currentDate.getDate() - 1));
const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 2));
export const tagOWarContentLeftSide = {
  socialFeedLeft: {
    label: "#hashtag1",
    value: "",
    name: ""
  },
  leftSidePosts: [],
  percentLeft: 0
};
export const tagOWarContentRightSide = {
  socialFeedRight: {
    label: "#hashtag2",
    value: "",
    name: ""
  },
  rightSidePosts: [],
  percentRight: 0
};
export const tagOWarContentData = __spreadProps(__spreadValues(__spreadValues({}, __spreadValues({}, tagOWarContentLeftSide)), __spreadValues({}, tagOWarContentRightSide)), {
  slugNameLeft: "",
  slugIdLeft: "",
  slugNameRight: "",
  slugIdRight: "",
  leftSideImage: "",
  rightSideImage: "",
  isCompetitionEnd: false,
  competitionEndDate: "",
  startDate: yesterday,
  endDate: tomorrow,
  timeZone: "",
  fixedTitle: ""
});
export const tagOWarStylesData = {
  textColorLeft: colors.white,
  textColorPostLeft: colors.white,
  backgroundRangeLeft: colors.blueDark,
  backgroundColorLeft: colors.blueLight,
  backgroundColorPostLeft: colors.blueLight,
  textColorRight: colors.white,
  textColorPostRight: colors.white,
  backgroundRangeRight: colors.red,
  backgroundColorRight: colors.redLight,
  backgroundColorPostRight: colors.redLight
};
export const tagOWarData = {
  published: [],
  draft: [getHeader(colors.white), getTagOWar(tagOWarContentData, tagOWarStylesData), getSocial(), getFooter()]
};
