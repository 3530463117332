import React, { Component } from "react";
import "./media-section.sass";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { EditMediaContent } from "./edit/edit-media-content";
import { pageSectionType } from "../../../../../../model/page-section-type.model";
import VideoRenderer from "../custom/video/video-renderer/video-renderer";
class MediaSection extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-page-section tint-page-section-media",
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor)
        }
      },
      this.props.renderGenericComponents(),
      this.props.data.content.mediaType === pageSectionType.IMAGE || this.props.data.content.mediaType === pageSectionType.IMAGE.toUpperCase() ? /* @__PURE__ */ React.createElement(
        "img",
        {
          className: "tint-page-section-media__thumb",
          src: this.props.data.content.mediaUrl,
          alt: this.props.data.content.mediaUrl
        }
      ) : /* @__PURE__ */ React.createElement(
        VideoRenderer,
        {
          width: 100,
          mediaUrl: this.props.data.content.mediaUrl,
          parentLocation: this.props.parentLocation
        }
      ),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Media" }, /* @__PURE__ */ React.createElement(
          EditMediaContent,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate,
            onContentUpdate: this.props.onContentUpdate,
            maxVideoFileSize: this.props.getMaxVideoSizePlanLimit()
          }
        ))
      )
    );
  }
}
export default PageSectionHoc(MediaSection);
