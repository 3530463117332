import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { SocialFeedsFooterLabels } from "./helpers";
import { validationSchemaTikTok } from "../utils/validation-schema/tiktok-schema";
export const tiktok = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "tiktok",
    selectedOption: { id: "account", title: "Add TikTok" },
    header: "Add TikTok",
    description: "To ingest branded videos from TikTok, select your TikTok account:",
    formSchema: {
      account: {
        validationSchema: validationSchemaTikTok(),
        submitMapper: (values) => {
          var _a, _b, _c, _d;
          return {
            account_id: ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.accountId) || values.account.value,
            external_id: ((_d = (_c = values.account) == null ? void 0 : _c.value) == null ? void 0 : _d.externalId) || values.account.externalId
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          }
        ]
      }
    }
  }
];
