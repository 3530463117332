export const MarketingIntegrations = {
  TWITTER: "twitter",
  FACEBOOK: "facebook",
  SALESFORCE: "salesforce_marketing_cloud",
  ADOBE: "adobe_experience_manager",
  BYNDER: "bynder",
  DROPBOX: "dropbox",
  MAILCHIMP: "mailchimp",
  HUBSPOT: "hubspot",
  WIDEN: "widen_collective",
  HOOTSUITE: "hootsuite",
  BRANDFOLDER: "brandfolder",
  ZAPIER: "zapier",
  GOOGLE_DRIVE: "google_drive"
};
