var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import DragAndDropItem from "./drag-and-drop-item";
import "./drag-and-drop-item.sass";
import update from "immutability-helper";
import PropTypes from "prop-types";
export const DragAndDropType = {
  PARENT: "parent",
  CHILD: "children"
};
class DragAndDropContainer extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "onMoveElement", (dragIndex, hoverIndex) => {
      const dragCard = this.state.sections[dragIndex];
      this.setState((state) => {
        return {
          sections: update(state.sections, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
          })
        };
      });
    });
    __publicField(this, "onDroppedElement", () => {
      const unMapSections = this.state.sections.map((item) => {
        const majorItem = __spreadProps(__spreadValues({}, item), {
          elementId: item.elementId
        });
        return item.column === void 0 ? majorItem : __spreadProps(__spreadValues({}, majorItem), { column: item.column });
      });
      this.props.onChangeOrder(unMapSections);
      this.setState({ isDragging: false });
      this.setState({ dragItemClass: "inactive" });
    });
    __publicField(this, "onElementDrag", () => {
      this.setState({ isDragging: true });
    });
    __publicField(this, "onMouseDown", (e) => {
      e.stopPropagation();
      this.setState({ dragItemClass: "active" });
    });
    __publicField(this, "onMouseUp", () => {
      this.setState({ dragItemClass: "inactive" });
    });
    this.state = {
      sections: this.mapSectionList(),
      isDragging: false,
      dragItemClass: "inactive"
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.sectionList !== this.props.sectionList) {
      this.setState({ sections: this.mapSectionList() });
    }
  }
  mapSectionList() {
    return this.props.sectionList.map((item, index) => __spreadProps(__spreadValues({}, item), {
      elementId: index
    }));
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `drag-and-drop-container ${this.state.isDragging ? "drag-and-drop-container--dragging" : ""} ${this.state.dragItemClass}`,
        onMouseDown: this.onMouseDown,
        onMouseEnter: this.onMouseDown,
        onMouseLeave: this.onMouseUp
      },
      this.state.sections.map((card) => /* @__PURE__ */ React.createElement(
        DragAndDropItem,
        {
          isAnyElementDragging: this.state.isDragging,
          currentDragClass: this.state.dragItemClass,
          itemType: this.props.itemType,
          key: card.elementId,
          index: card.elementId,
          onMoveElement: this.onMoveElement,
          onElementDropped: this.onDroppedElement,
          onElementDrag: this.onElementDrag,
          dndItemComponent: this.props.dndItemComponent,
          item: card,
          isVerticalDisplay: this.props.isVerticalDisplay
        }
      ))
    );
  }
}
export default DragAndDropContainer;
DragAndDropContainer.propTypes = {
  sectionList: PropTypes.array,
  onChangeOrder: PropTypes.func,
  dndItemComponent: PropTypes.func,
  isVerticalDisplay: PropTypes.bool
};
