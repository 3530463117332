import React from "react";
import "./search-list.sass";
import * as PropTypes from "prop-types";
export const SearchListType = {
  IMAGES: "image",
  VIDEOS: "video",
  NONE: "none"
};
const SearchList = ({ images, videos, searchTerm, onChange }) => {
  const isNoValue = images.length > 0 || videos.length > 0;
  const maxCountItem = 9;
  const renderListItems = (list, title, icon, type) => {
    return list && list.length > 0 && /* @__PURE__ */ React.createElement("div", { className: "tint-search-input-list__group" }, /* @__PURE__ */ React.createElement("span", { className: "tint-search-input-list__label" }, /* @__PURE__ */ React.createElement("span", { className: `icon ${icon}` }), title), /* @__PURE__ */ React.createElement("ul", { className: "search-list" }, list.map(
      (object, i) => i <= maxCountItem ? /* @__PURE__ */ React.createElement(
        "li",
        {
          "data-testid": `searchListItem_${i}`,
          className: "search-list__item",
          key: i,
          onClick: () => onChange(object, type)
        },
        /* @__PURE__ */ React.createElement("span", null, markTextAsSelect(object.name))
      ) : null
    )));
  };
  const markTextAsSelect = (value) => {
    if (!value) {
      return "";
    }
    const indexOfValue = value.toLowerCase().indexOf(searchTerm.toLowerCase());
    return /* @__PURE__ */ React.createElement(React.Fragment, null, value.substr(0, indexOfValue), /* @__PURE__ */ React.createElement("span", { className: "light" }, value.substr(indexOfValue, searchTerm.length)), value.substr(indexOfValue + searchTerm.length, value.length));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-search-input-list", "data-testid": "dropdownSearchInputList" }, isNoValue ? /* @__PURE__ */ React.createElement(React.Fragment, null, renderListItems(images, "Images", "fas fa-image", SearchListType.IMAGES), renderListItems(videos, "Videos", "fas fa-video", SearchListType.VIDEOS)) : /* @__PURE__ */ React.createElement("span", { className: "tint-search-input-list__no-found" }, "No items found"));
};
export default SearchList;
SearchList.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  searchTerm: PropTypes.string,
  onChange: PropTypes.func
};
