var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import "./social-account-form.sass";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PreloaderComponent, {
  PreloaderPositionType
} from "../../../../../components/interface/preloaders/preloader/preloader";
import { useSelector, useDispatch } from "react-redux";
import SocialFeedFormInput from "../../../../tint-editor/components/tint-editor/sidebars/social-feed-sidebar/components/social-feed-form-input/social-feed-form-input";
import { postSocialConnection } from "../../../actions/social-connections/social-connections.fat-actions";
import { handleFormValidation } from "../../../../../services/functions/error-handler/error-handler";
import { TintLink } from "../../../../../components/interface/link/link";
export const socialAccountFormType = "socialAccountForm";
const SocialAccountForm = ({ schema, type, callbackFn, sidebarId = socialAccountFormType, setIsFormValid }, ref) => {
  var _a;
  const dispatch = useDispatch();
  const formSchema = (_a = schema.formSchema) == null ? void 0 : _a.account;
  const inputs = formSchema == null ? void 0 : formSchema.inputs;
  const getValidationSchema = () => formSchema ? formSchema == null ? void 0 : formSchema.validationSchema : Yup.object({});
  const isPending = useSelector((state) => state.socialConnections.isPending);
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: getValidationSchema,
    initialValues: inputs.reduce((previousValue, currentValue) => {
      const value = { [currentValue.id]: "" };
      return __spreadValues(__spreadValues({}, previousValue), value);
    }, {}),
    onSubmit: (values, actions) => {
      dispatch(
        postSocialConnection(__spreadProps(__spreadValues({}, formSchema.submitMapper(values)), {
          type
        }))
      ).then((data) => {
        toast.success("Account created successfully");
        callbackFn && callbackFn(data);
      }).catch((err) => actions == null ? void 0 : actions.setErrors(handleFormValidation(err)));
    }
  });
  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      formik.handleSubmit();
    }
  }));
  useEffect(() => {
    if (type) {
      setIsFormValid == null ? void 0 : setIsFormValid(formik.isValid);
    }
  }, [dispatch, type, sidebarId, formik.isValid, formik.dirty]);
  const renderInput = (value, formik2) => {
    return /* @__PURE__ */ React.createElement(
      SocialFeedFormInput,
      {
        inputValue: value,
        formik: formik2,
        accountSourceType: schema.accountSourceType,
        currentActiveSocialFeedSource: type
      }
    );
  };
  const renderPreloader = () => {
    return /* @__PURE__ */ React.createElement(PreloaderComponent, { positionType: PreloaderPositionType.ABSOLUTE });
  };
  const renderForm = () => {
    return /* @__PURE__ */ React.createElement("form", { id: `${type}-form`, ref, onSubmit: formik.handleSubmit }, /* @__PURE__ */ React.createElement("ul", null, inputs.map((value) => /* @__PURE__ */ React.createElement("li", { key: value.id, className: value.id }, renderInput(value, formik)))), isPending && renderPreloader());
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-social-account-form" }, renderForm());
};
export const SocialAccountFormRef = forwardRef(SocialAccountForm);
