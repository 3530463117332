export const COMMON_SETTINGS = {
  header: "#HASHTAG",
  header_position: "left",
  strip_position: "bottom",
  connected_hashtag_highlight_color: "#cc44ee",
  cta_button_show: "both",
  cta_text_color: "#ffffff",
  cta_button_color: "#000000",
  postwidth: 300,
  postpadding: 10,
  bg_page_image: "#ffffff",
  fontcolor_post: "#000000",
  font_secondary: "Lato",
  fontsize_secondary: 18,
  color_namebar_buttons: "#4598ba",
  popup_product_header_text: "SHOP NOW",
  popup_font: "Lato",
  popup_fontsize: 16,
  popup_hide_description: false,
  popup_products_visibility: false,
  popup_product_font_size: 16,
  popup_product_font_color: "#000000",
  popup_product_price_font_size: 16,
  popup_product_price_font_color: "#70757e",
  popup_product_image_outline: "#e9edf3",
  popup_product_background: "#000000",
  popup_product_header_font_size: 16,
  popup_product_header_font_color: "#70757e",
  popup_font_color_body_disabled: true,
  popup_font_color_author_disabled: true,
  popup_font_color_author_username_disabled: true,
  popup_font_color_link_disabled: true,
  popup_background_color_disabled: true,
  popup_lightbox_color_disabled: true,
  popup_image_background_color_disabled: true,
  popup_controls_enable: false,
  popup_font_color_body: "#000000",
  popup_font_color_author: "#000000",
  popup_font_color_author_username: "#cccccc",
  popup_font_color_link: "#4598ba",
  popup_background_color: "#ffffff",
  popup_image_background_color: "#000000",
  popup_lightbox_color: "#ffffff",
  publicpost_button_color: "#4598ba",
  publicpost_button_text_color: "#000000",
  waterfallToggle: false,
  popup_show_author_name: true,
  popup_show_author_username: true,
  popup_show_timestamp: true,
  popup_size: "normal",
  sharebuttons: 1,
  sharebuttonoptions: ["fb", "li", "email", "pin", "tw"],
  show_author: 1,
  remove_duplicate_images: true,
  time_language: "en",
  highlightVideoPlayback: "play-to-transition",
  generic_avatar_text_color: "#ffffff",
  generic_avatar_background_color: "#517fa4"
};
