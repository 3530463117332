import React from "react";
import "./add-content-option-icon.sass";
import { SvgIcon, SvgIconSize } from "../../svg-icon/svg-icon";
import TintIcon from "../../icon/icon";
const IconDefaultProps = {
  color: "#000000",
  fontSize: "14px",
  className: "far fa-circle"
};
const IconContainerDefaultProps = {
  background: "transparent",
  inactiveBackground: "#656873",
  isInactive: false
};
export const AddContentOptionIconTypes = {
  SVG: "svg",
  ICON: "icon",
  IMAGE: "image",
  FONTAWESOME: "fontawesome"
};
export const AddContentOptionIconSizes = {
  SMALL: "25px",
  NORMAL: "50px"
};
export const AddContentOptionIcon = ({
  type = AddContentOptionIconTypes.ICON,
  name = "icon",
  faIcon,
  container: {
    background = IconContainerDefaultProps.background,
    inactiveBackground = IconContainerDefaultProps.inactiveBackground,
    isInactive = IconContainerDefaultProps.isInactive,
    containerSize = AddContentOptionIconSizes.NORMAL
  } = {
    background: IconContainerDefaultProps.background,
    inactiveBackground: IconContainerDefaultProps.inactiveBackground,
    isInactive: IconContainerDefaultProps.isInactive
  },
  icon: {
    color = IconDefaultProps.color,
    fontSize = IconDefaultProps.fontSize,
    className = IconDefaultProps.className
  } = {
    color: IconDefaultProps.color,
    fontSize: IconDefaultProps.fontSize,
    className: IconDefaultProps.className
  },
  image: { src = "" } = {
    src: ""
  },
  svg: { svgType = "", size = SvgIconSize.MEDIUM, withBorder = false, colored = false } = {
    svgType: "",
    size: SvgIconSize.MEDIUM,
    withBorder: false,
    colored: false,
    color: void 0
  }
} = {}) => {
  const renderBody = () => {
    switch (type) {
      case AddContentOptionIconTypes.SVG:
        return /* @__PURE__ */ React.createElement(SvgIcon, { size, type: svgType, withBorder, colored, color });
      case AddContentOptionIconTypes.ICON:
        return /* @__PURE__ */ React.createElement(
          "span",
          {
            className: `icon ${className}`,
            style: {
              color,
              fontSize: `${fontSize}px`
            }
          }
        );
      case AddContentOptionIconTypes.FONTAWESOME:
        return /* @__PURE__ */ React.createElement(TintIcon, { icon: faIcon, color, fontSize });
      case AddContentOptionIconTypes.IMAGE:
        return /* @__PURE__ */ React.createElement("img", { src, alt: name });
      default:
        return null;
    }
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-add-content-option-icon  ${isInactive ? "tint-add-content-option-icon--inactive" : ""}`,
      style: { background: isInactive ? inactiveBackground : background, width: containerSize, height: containerSize }
    },
    renderBody()
  );
};
