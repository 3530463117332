import React from "react";
import PropTypes from "prop-types";
const EditForm = ({ onSubmit, children, novalidate, id }) => {
  return /* @__PURE__ */ React.createElement("form", { onSubmit, noValidate: novalidate, id }, !onSubmit ? /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit") : null, children);
};
EditForm.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  novalidate: PropTypes.bool,
  id: PropTypes.string
};
EditForm.defaultProps = {
  onSubmit: (e) => e.preventDefault(),
  novalidate: false
};
export default EditForm;
