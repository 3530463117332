import EditColorInput from "../edit-color-input/edit-color-input";
import React from "react";
import { EditInput } from "../";
export const renderEditColorInput = (color, onChange, label, key, styles = {}, onMenuOpen, onMenuClose) => {
  return /* @__PURE__ */ React.createElement(
    EditColorInput,
    {
      color,
      label,
      onChange,
      key,
      styles,
      onMenuOpen,
      onMenuClose
    }
  );
};
export const renderEditInput = (defaultValue, onChange, label, key, placeholder) => {
  return /* @__PURE__ */ React.createElement(EditInput, { defaultValue, label, onChange, key, placeholder });
};
