var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { COMMON_SETTINGS } from "./common-settings";
import { languageList } from "../configs/personalization-languages";
export const TESTIMONIALS = {
  name: "Testimonials",
  key: "testimonials",
  premiumDesign: false,
  default_settings: __spreadProps(__spreadValues({}, COMMON_SETTINGS), {
    themeName: "testimonials"
  }),
  guards: {
    CTA: false,
    waterfall: false,
    popup: true,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true
  },
  themes_settings: [],
  font_settings: [
    {
      key: "fontcolor_post",
      label: "Font Color",
      type: "colorPicker"
    },
    {
      key: "fontsize_secondary",
      type: "slider",
      label: "Font size",
      min: "8",
      max: "50"
    },
    {
      key: "color_namebar_buttons",
      label: "Accent Color",
      type: "colorPicker"
    }
  ],
  extra_settings: [
    {
      key: "time_language",
      type: "dropdown",
      label: "Language for post timestamp",
      options: languageList
    },
    {
      key: "postwidth",
      type: "slider",
      label: "Minimum Post Width",
      min: "150",
      max: "500"
    }
  ],
  extra_popup_settings: [
    // {
    //   key: 'popup_products_visibility',
    //   label: 'Hide Product Hotspots',
    //   control_type: 'Checkbox',
    //   target: '#personalize-popup-controls',
    //   add_class: 'control-divide-above',
    //   required_flags: ['ecommerce'],
    // },
  ]
};
