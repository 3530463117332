import {
  getMarketingIntegrationsStart,
  getMarketingIntegrationsSuccess,
  getMarketingIntegrationsError,
  postMarketingIntegrationStart,
  postMarketingIntegrationSuccess,
  postMarketingIntegrationError,
  deleteMarketingIntegrationStart,
  deleteMarketingIntegrationSuccess,
  deleteMarketingIntegrationError
} from "./marketing-integrations.actions";
import { ApiService } from "../../../../services/api-service/api-service";
export const getMarketingIntegrations = (url = "/marketing_integrations") => {
  return (dispatch) => {
    dispatch(getMarketingIntegrationsStart());
    return ApiService.get(url).then((res) => {
      dispatch(
        getMarketingIntegrationsSuccess({
          data: res.data.data,
          links: res.data.links
        })
      );
    }).catch((err) => {
      dispatch(getMarketingIntegrationsError(err));
      throw err;
    });
  };
};
export const postMarketingIntegration = (source, { access_token, client_id, client_id_v1, secret_id_v1, secret_id, brandfolder, site }) => {
  const external_id = source === "twitter" ? `${client_id}|${client_id_v1}` : void 0;
  const access_token_value = source !== "twitter" ? access_token || client_id && secret_id && `${client_id}|${secret_id}` || brandfolder : `${secret_id}|${secret_id_v1}`;
  const data = {
    data: {
      type: "marketing_integration",
      attributes: {
        access_token: access_token_value,
        external_id,
        source,
        site: site || void 0
      }
    }
  };
  return (dispatch) => {
    dispatch(postMarketingIntegrationStart());
    return ApiService.post("/marketing_integrations", data).then((res) => {
      dispatch(
        postMarketingIntegrationSuccess({
          data: res.data.data
        })
      );
      return res;
    }).catch((err) => {
      dispatch(postMarketingIntegrationError(err));
      throw err;
    });
  };
};
export const deleteMarketingIntegration = ({ marketingIntegrationId }) => {
  return (dispatch) => {
    const url = `/marketing_integrations/${marketingIntegrationId}`;
    dispatch(deleteMarketingIntegrationStart());
    return ApiService.delete(url).then(() => {
      dispatch(deleteMarketingIntegrationSuccess({ id: marketingIntegrationId }));
    }).catch((err) => {
      dispatch(deleteMarketingIntegrationError(err));
      throw err;
    });
  };
};
