import React, { Fragment } from "react";
import { FormBuilderFormHint } from "../common/hint/form-builder-form.hint";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceDropdown = ({ item, values, t }) => {
  var _a;
  if (!item) return null;
  const { label, select, p: hint } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  return /* @__PURE__ */ React.createElement("div", { className: item.class }, /* @__PURE__ */ React.createElement("label", { htmlFor: label.for }, t(label.nodeValue)), /* @__PURE__ */ React.createElement(
    "select",
    {
      id: select.id,
      name: select == null ? void 0 : select.name,
      defaultValue: ((_a = values == null ? void 0 : values.getAll(select.name)) == null ? void 0 : _a.length) ? values.getAll(select.name) : "",
      required: select == null ? void 0 : select.required,
      "aria-describedby": select["aria-describedby"]
    },
    select.elements.map((el, index) => {
      if (el.nodeName === "option") {
        return /* @__PURE__ */ React.createElement(Fragment, { key: index }, /* @__PURE__ */ React.createElement("option", { value: el.value }, t(el.nodeValue)));
      }
    })
  ), /* @__PURE__ */ React.createElement(FormBuilderFormHint, { item: t(hint == null ? void 0 : hint.nodeValue), id: hint == null ? void 0 : hint.id }));
};
FormBuilderFormInterfaceDropdown.displayName = "FormBuilderFormInterfaceDropdown";
export default React.memo(FormBuilderFormInterfaceDropdown);
