import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTumblr,
  faXTwitter,
  faYelp,
  faYoutube,
  faPinterest,
  faThreads
} from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/pro-regular-svg-icons";
import { faBullhorn, faMegaphone, faPen, faQuestion, faStar, faSquareList } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope, faX, faPuzzlePiece, faUpload } from "@fortawesome/free-solid-svg-icons";
import Logger from "../../../services/logger/logger";
import { faCommentAltPlus } from "@fortawesome/pro-solid-svg-icons";
import { SocialFeedsSource } from "../../../types/social-feeds";
import { MarketingIntegrations } from "../../../types/marketing-integrations";
import { ProductAccounts } from "../../../types/product-accounts";
import { ProductFeeds } from "../../../types/product-feeds";
import { DefaultButtonIconSizes } from "../../../components/interface/button-content-icon/button-content-icon";
const getIconProperties = (iconType) => {
  switch (iconType) {
    case SocialFeedsSource.VESTA:
      return {
        svgIconSrc: "/public/js/react/assets/icons/vesta-logo/vesta-logo.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff"
        }
      };
    case SocialFeedsSource.TWITTER:
      return {
        icon: faXTwitter,
        styles: {
          background: "#000000",
          color: "#fff"
        }
      };
    case SocialFeedsSource.THREADS:
      return {
        icon: faThreads,
        styles: {
          background: "#000000",
          color: "#fff"
        }
      };
    case SocialFeedsSource.INSTAGRAM_BUSINESS:
      return {
        icon: faInstagram,
        styles: {
          background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
          color: "#fff"
        }
      };
    case SocialFeedsSource.FACEBOOK:
      return {
        icon: faFacebook,
        styles: {
          background: "#0476FB",
          color: "#fff"
        }
      };
    case SocialFeedsSource.RSS:
      return {
        icon: faRss,
        styles: {
          background: "#F56613",
          color: "#fff"
        }
      };
    case SocialFeedsSource.PUBLIC_POST:
      return {
        icon: faPen,
        styles: {
          background: "#18C167",
          color: "#fff"
        }
      };
    case SocialFeedsSource.SMS:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/sms.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#FCB829",
          color: "#000"
        }
      };
    case SocialFeedsSource.EMAIL:
      return {
        icon: faEnvelope,
        styles: {
          background: "#FCB829",
          color: "#fff"
        }
      };
    case SocialFeedsSource.LINKED_IN:
      return {
        icon: faLinkedinIn,
        styles: {
          background: "#2867B2",
          color: "#fff"
        }
      };
    case SocialFeedsSource.USER_REVIEW:
      return {
        icon: faYelp,
        styles: {
          background: "#D32323",
          color: "#fff"
        }
      };
    case SocialFeedsSource.TUMBLR:
      return {
        icon: faTumblr,
        styles: {
          background: "#011A36",
          color: "#fff"
        }
      };
    case SocialFeedsSource.FLICKR:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/flickr.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        }
      };
    case SocialFeedsSource.GOOGLE_NEWS:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/google-news.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.SLACK:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/slack.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.SCHEDULED_POSTS:
    case SocialFeedsSource.HOOT_SUITE:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/hootsuite.svg",
        svgPostPreviewIconSrc: "/public/js/react/assets/icons/social-media-icons/hootsuite-post.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.TIK_TOK:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/tiktok.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#000",
          color: "#fff"
        }
      };
    case SocialFeedsSource.CREATOR_IQ:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/creatoriq.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.WEBEX:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/cisco.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#000",
          color: "#fff"
        }
      };
    case SocialFeedsSource.EXPERIENCE_BUILDER:
      return {
        icon: faMegaphone,
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.FORMS:
      return {
        icon: faSquareList,
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.CUSTOM:
      return {
        icon: faCommentAltPlus,
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.FORM_SUBMISSION:
      return {
        icon: faBullhorn,
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case SocialFeedsSource.INFLUENCER:
      return {
        svgIconSrc: "/public/js/react/assets/icons/social-media-icons/influencer.svg",
        svgIconStyles: {
          width: "42px",
          height: "42px"
        },
        styles: {
          background: "linear-gradient(45deg, rgba(82, 187, 240, 1) 0%, rgba(108, 99, 236, 1) 100%)",
          color: "#000"
        }
      };
    case SocialFeedsSource.DELETED:
      return {
        icon: faX,
        styles: {
          background: "#E9EDF3",
          color: "#000"
        }
      };
    case SocialFeedsSource.YOUTUBE:
      return {
        icon: faYoutube,
        styles: {
          background: "#FF0000",
          color: "#FFF"
        }
      };
    case SocialFeedsSource.PINTEREST:
      return {
        icon: faPinterest,
        styles: {
          background: "#E60023",
          color: "#FFF"
        }
      };
    case SocialFeedsSource.EXTERNALLY_SOURCED_POSTS_ACCOUNT:
    case SocialFeedsSource.EXTERNALLY_SOURCED_POSTS:
      return {
        icon: faPuzzlePiece,
        styles: {
          background: "#000",
          color: "#FFF"
        }
      };
    case SocialFeedsSource.REVIEWS_RATINGS:
      return {
        icon: faStar,
        styles: {
          background: "linear-gradient(122.97deg, #FFBA00 0%, #FB9501 100%)",
          color: "#fff"
        }
      };
    case SocialFeedsSource.POWER_REVIEWS:
      return {
        svgIconSrc: "/icons/rebranding/power_reviews.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#FFF",
          color: "#000"
        }
      };
    case SocialFeedsSource.RE_CAPTCHA:
      return {
        svgIconSrc: "/icons/rebranding/re_captcha.svg"
      };
    case MarketingIntegrations.SALESFORCE:
    case ProductAccounts.SALESFORCE_COMMERCE_CLOUD:
    case ProductFeeds.SALESFORCE_COMMERCE_CLOUD:
      return {
        svgIconSrc: "/icons/rebranding/salesforce.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#fff"
        }
      };
    case MarketingIntegrations.ADOBE:
      return {
        svgIconSrc: "/icons/rebranding/adobe.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#FA0F01",
          color: "#FFF"
        }
      };
    case MarketingIntegrations.BYNDER:
      return {
        svgIconSrc: "/icons/rebranding/bynder.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#00ABFE",
          color: "#FFF"
        }
      };
    case MarketingIntegrations.DROPBOX:
      return {
        svgIconSrc: "/icons/rebranding/dropbox.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#0062FE",
          color: "#FFF"
        }
      };
    case MarketingIntegrations.MAILCHIMP:
      return {
        svgIconSrc: "/icons/rebranding/mailchimp.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#FFE300",
          color: "#000"
        }
      };
    case MarketingIntegrations.HUBSPOT:
      return {
        svgIconSrc: "/icons/rebranding/hubspot.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#FB7500",
          color: "#FFF"
        }
      };
    case MarketingIntegrations.WIDEN:
      return {
        svgIconSrc: "/icons/rebranding/widen.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#E12500",
          color: "#FFF"
        }
      };
    case MarketingIntegrations.HOOTSUITE:
      return {
        svgIconSrc: "/icons/rebranding/hootsuite.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case MarketingIntegrations.BRANDFOLDER:
      return {
        svgIconSrc: "/icons/rebranding/brandfolder.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#587ed2",
          color: "#FFF"
        }
      };
    case MarketingIntegrations.ZAPIER:
      return {
        svgIconSrc: "/icons/rebranding/zapier.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case MarketingIntegrations.GOOGLE_DRIVE:
      return {
        svgIconSrc: "/icons/rebranding/google_drive.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.SFTP:
    case ProductAccounts.FTP:
      return {
        svgIconSrc: "/icons/rebranding/cloud-check.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.MAGENTO:
    case ProductFeeds.MAGENTO:
      return {
        svgIconSrc: "/icons/rebranding/magento.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#f26322",
          color: "#000"
        }
      };
    case ProductAccounts.SHOPIFY:
    case ProductFeeds.SHOPIFY:
      return {
        svgIconSrc: "/icons/rebranding/shopify.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.WOOCOMMERCE:
    case ProductFeeds.WOOCOMMERCE:
      return {
        svgIconSrc: "/icons/rebranding/woocommerce.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.BIGCOMMERCE:
    case ProductFeeds.BIGCOMMERCE:
      return {
        svgIconSrc: "/icons/rebranding/bigcommerce.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#000",
          color: "#fff"
        }
      };
    case ProductAccounts.CSV:
    case ProductFeeds.CSV:
      return {
        icon: faUpload,
        styles: {
          background: "#000",
          color: "#fff"
        }
      };
    case ProductAccounts.S3:
      return {
        svgIconSrc: "/icons/rebranding/s3.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.HTTP:
      return {
        svgIconSrc: "/icons/rebranding/cloud-check.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.CUSTOM:
    case ProductFeeds.CUSTOM:
    case ProductFeeds.MANUAL:
      return {
        svgIconSrc: "/icons/rebranding/manual.svg",
        svgIconStyles: {
          width: "26px",
          height: "26px"
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    case ProductAccounts.BAZAARVOICE:
    case ProductFeeds.BAZAARVOICE:
      return {
        svgIconSrc: "/icons/rebranding/bazaarvoice.svg",
        svgIconStyles: {
          width: DefaultButtonIconSizes.SVG_ICON,
          height: DefaultButtonIconSizes.SVG_ICON
        },
        styles: {
          background: "#fff",
          color: "#000"
        }
      };
    default:
      Logger.warning("Can not get social feed icon properties");
      return {
        icon: faQuestion
      };
  }
};
export default getIconProperties;
