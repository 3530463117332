import { createPrefixedActionName, createRoutine } from "../../services/functions/redux-routines/redux-routines";
import { ACCOUNT } from "../consts";
export const ACTIONS = {
  GET_TINTS: createRoutine(createPrefixedActionName(ACCOUNT, "GET_TINTS")),
  GET_TINT: createRoutine(createPrefixedActionName(ACCOUNT, "GET_TINT")),
  GET_PERSONALIZATIONS: createRoutine(createPrefixedActionName(ACCOUNT, "GET_PERSONALIZATIONS")),
  GET_PERSONALIZATION: createRoutine(createPrefixedActionName(ACCOUNT, "GET_PERSONALIZATION"))
};
export const getTintsStart = () => ({
  type: ACTIONS.GET_TINTS.REQUEST
});
export const getTintsSuccess = (data) => ({
  type: ACTIONS.GET_TINTS.SUCCESS,
  payload: data
});
export const getTintsError = (err) => ({
  type: ACTIONS.GET_TINTS.FAILURE,
  payload: err
});
export const getTintStart = () => ({
  type: ACTIONS.GET_TINT.REQUEST
});
export const getTintSuccess = (data) => ({
  type: ACTIONS.GET_TINT.SUCCESS,
  payload: data
});
export const getTintError = (err) => ({
  type: ACTIONS.GET_TINT.FAILURE,
  payload: err
});
export const getPersonalizationStart = () => ({
  type: ACTIONS.GET_PERSONALIZATION.REQUEST
});
export const getPersonalizationSuccess = (data) => ({
  type: ACTIONS.GET_PERSONALIZATION.SUCCESS,
  payload: data
});
export const getPersonalizationError = (err) => ({
  type: ACTIONS.GET_PERSONALIZATION.FAILURE,
  payload: err
});
export const getPersonalizationsStart = () => ({
  type: ACTIONS.GET_PERSONALIZATIONS.REQUEST
});
export const getPersonalizationsSuccess = (data) => ({
  type: ACTIONS.GET_PERSONALIZATIONS.SUCCESS,
  payload: data
});
export const getPersonalizationsError = (err) => ({
  type: ACTIONS.GET_PERSONALIZATIONS.FAILURE,
  payload: err
});
