var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { Button } from "../../../../../../../../components/interface/button/button";
import { FileStack } from "../../../../../../../../services/filestack/file-stack";
import "./video-content.sass";
import { EditInput } from "../../../../index";
import EditCheckbox from "../../../../edit-checkbox/edit-checkbox";
export class VideoContent extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "onUploadClick", () => {
      new FileStack().openFilePicker({
        accept: ["video/*"],
        maxSize: this.props.maxVideoFileSize,
        onUploadDone: (res) => {
          if (res.filesUploaded.length > 0) {
            this.props.onContentUpdate({
              mediaUrl: res.filesUploaded[0].url,
              mediaName: res.filesUploaded[0].filename
            });
          }
        }
      });
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { titleClass: "bold", title: "UPLOAD VIDEO", className: "edit-content" }, /* @__PURE__ */ React.createElement(
      "form",
      {
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: "video-content-upload-container" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: this.onUploadClick,
          type: "gray",
          size: "full-width",
          text: "Upload media",
          icon: "far fa-arrow-to-top"
        }
      ), /* @__PURE__ */ React.createElement("span", { className: "video-content-upload-container__text" }, "Limited to 1GB")),
      /* @__PURE__ */ React.createElement(
        EditInput,
        {
          onChange: (event) => {
            this.props.onContentUpdate({
              mediaUrl: event.target.value,
              mediaName: ""
            });
          },
          label: "or paste Video url",
          placeholder: "Video url",
          value: this.props.data.content.mediaUrl
        }
      ),
      /* @__PURE__ */ React.createElement("span", { className: "tint-edit-media__input-description" }, "Supported: Facebook, YouTube, Vimeo, Twitch, TikTok"),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit"),
      /* @__PURE__ */ React.createElement(
        EditCheckbox,
        {
          value: !!this.props.data.content.hasAutoplay,
          onChange: (e) => this.props.onContentUpdate({ hasAutoplay: e.target.checked }),
          name: "autoPlay",
          id: "autoPlay",
          label: "Turn ON autoplay"
        }
      )
    ));
  }
}
