import React, { Component } from "react";
import PropTypes from "prop-types";
import "./edit-input.sass";
export default class EditInput extends Component {
  render() {
    const errorClasses = this.props.errorMsg && this.props.touched ? "tint-edit-input-container--error" : "";
    const iconClass = this.props.isRightIconVisible ? "tint-edit-input-container--icon-visible" : "";
    const readonlyClass = this.props.readOnly ? "tint-edit-input-container--readonly" : "";
    const disabledClass = this.props.disabled ? "tint-edit-input-container--disabled" : "";
    return this.props.isInvisible ? null : /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-edit-input-container ${errorClasses} ${iconClass} ${readonlyClass} ${disabledClass}`,
        style: { marginBottom: this.props.margin + "px" }
      },
      /* @__PURE__ */ React.createElement("span", { className: "tint-edit-input-container__label" }, this.props.label, this.props.labelIcon ? /* @__PURE__ */ React.createElement("span", { onClick: this.props.labelIconAction, className: this.props.labelIcon }) : null),
      /* @__PURE__ */ React.createElement("div", { ref: this.props.inputChildrenRef }, /* @__PURE__ */ React.createElement(
        "input",
        {
          style: this.props.styles,
          onClick: this.props.onClick,
          onChange: this.props.onChange,
          onBlur: this.props.onBlur,
          onFocus: this.props.onFocus,
          spellCheck: this.props.spellCheck,
          defaultValue: this.props.defaultValue,
          value: this.props.value,
          readOnly: this.props.readOnly,
          disabled: this.props.disabled,
          placeholder: this.props.placeholder,
          name: this.props.name,
          type: this.props.type ? this.props.type : "text",
          required: true,
          autoFocus: this.props.autoFocus
        }
      ), this.props.rightIcon ? /* @__PURE__ */ React.createElement(
        "span",
        {
          onClick: this.props.rightIconAction,
          className: `tint-edit-input-container__icon ${this.props.rightIcon}`
        }
      ) : null, this.props.children),
      this.props.errorMsg && this.props.error && this.props.touched ? /* @__PURE__ */ React.createElement("span", { className: "tint-edit-input-container__error-msg" }, this.props.errorMsg) : null
    );
  }
}
EditInput.propTypes = {
  isInvisible: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  spellCheck: PropTypes.bool,
  touched: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.object,
  labelIcon: PropTypes.string,
  labelIconAction: PropTypes.func,
  rightIcon: PropTypes.string,
  rightIconAction: PropTypes.func,
  isRightIconVisible: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  type: PropTypes.string,
  styles: PropTypes.object,
  inputChildrenRef: PropTypes.object
};
