import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  POST_TEAM: createRoutine(createPrefixedActionName("NEW_TEAM", "POST_TEAM")),
  PLAN_DETAILS: createRoutine(createPrefixedActionName("NEW_TEAM", "PLAN_DETAILS"))
};
export const postTeamStart = () => ({
  type: ACTIONS.POST_TEAM.REQUEST
});
export const postTeamSuccess = (data) => ({
  type: ACTIONS.POST_TEAM.SUCCESS,
  payload: data
});
export const postTeamError = (err) => ({
  type: ACTIONS.POST_TEAM.FAILURE,
  payload: err
});
export const getPlanDetailsStart = () => ({
  type: ACTIONS.PLAN_DETAILS.REQUEST
});
export const getPlanDetailsSuccess = (data) => ({
  type: ACTIONS.PLAN_DETAILS.SUCCESS,
  payload: data
});
export const getPlanDetailsError = (err) => ({
  type: ACTIONS.PLAN_DETAILS.FAILURE,
  payload: err
});
