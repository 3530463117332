var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect, useState } from "react";
import "./email-display-settings-sidebar.sass";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import { InputGroup } from "../../../../../../../../components/interface/inputs/input-group/input-group";
import InputPrimary from "../../../../../../../../components/interface/inputs/input-primary/input-primary";
import { Button } from "../../../../../../../../components/interface/button/button";
import TextareaPrimary from "../../../../../../../../components/interface/inputs/textarea-primary/textarea-primary";
import { Config } from "../../../../../../../../utils/config";
import useSavedFilters from "../../../../../../../../components/hooks/use-saved-filters/use-saved-filters";
import { Link } from "react-router-dom";
const EmailDisplaySettingsSidebar = ({ switcherData, onChangeData }) => {
  var _a;
  const selectDisplayStepNumber = 1;
  const { personalizationId, slugName } = switcherData[selectDisplayStepNumber];
  const baseUrl = `${Config.baseUrl()}/t/${slugName}.jpg?personalization_id=${personalizationId}`;
  const [savedFilter, setSavedFilter] = useState();
  const [products, setProducts] = useState();
  const [keywords, setKeywords] = useState();
  const [tags, setTags] = useState();
  const [url, setUrl] = useState(baseUrl);
  const [isCopyCodeClicked, setIsCopyCodeClicked] = useState(false);
  const [isCopyUrlClicked, setIsCopyUrlClicked] = useState(false);
  useEffect(() => {
    onChangeData({ isValid: true });
  }, []);
  const embedCode = `<img src='${url}' alt='social feed'>`;
  const getParams = () => {
    return __spreadValues(__spreadValues(__spreadValues(__spreadValues({}, savedFilter ? { saved_filter_id: savedFilter.id } : {}), products ? { product_sku: products } : {}), keywords ? { query: keywords } : {}), tags ? { tags } : {});
  };
  const buildQueryString = (params) => {
    return Object.entries(params).map(([key, val]) => `${key}=${val}`).join("&");
  };
  useEffect(() => {
    const params = getParams();
    const queryString = buildQueryString(params);
    setUrl(`${baseUrl}${queryString ? `&${queryString}` : ""}`);
  }, [savedFilter, products, keywords, tags]);
  const savedFilters = useSavedFilters({ tintId: (_a = switcherData["1"]) == null ? void 0 : _a.tintId });
  const renderSavedFilterDropdown = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar__item" }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Saved Filter" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: savedFilters,
        rerenderHeader: false,
        iconRight: "fa fa-caret-down",
        placeholder: "Select Saved Filter",
        currentItem: savedFilter,
        dropdownType: DropdownButtonType.SEARCH_INPUT,
        buttonType: ButtonType.BUTTON_SORT,
        onChangeValue: (e) => {
          setSavedFilter(e);
          setUrl(`${baseUrl}&filter[saved_filter_id]=${e.id}`);
        }
      }
    )));
  };
  const renderFilterByProductInput = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar__item" }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Filter By Product" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        name: "products",
        type: "text",
        placeholder: "Enter SKU",
        handleChange: (e) => setProducts(e.currentTarget.value)
      }
    )));
  };
  const renderFilterByKeywordInput = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar__item" }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Filter By Keyword" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        name: "keywords",
        type: "text",
        placeholder: "Enter Keywords",
        handleChange: (e) => setKeywords(e.currentTarget.value)
      }
    )));
  };
  const renderFilterByTagsInput = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar__item" }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Filter By Tags" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        name: "tags",
        type: "text",
        placeholder: "Enter Tags",
        handleChange: (e) => setTags(e.currentTarget.value)
      }
    )));
  };
  const renderOptionCode = () => {
    const onClick = () => {
      setIsCopyCodeClicked(true);
      setIsCopyUrlClicked(false);
    };
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar__item" }, /* @__PURE__ */ React.createElement(
      TextareaPrimary,
      {
        value: embedCode,
        label: "Option 1 - Code",
        style: {
          backgroundColor: "#373740",
          borderColor: "#373740",
          color: "#FFFFFF",
          borderRadius: "4px"
        },
        readonly: true
      }
    )), renderCopyToClipboardButton(isCopyCodeClicked, embedCode, onClick));
  };
  const renderOptionImageUrl = () => {
    const onClick = () => {
      setIsCopyCodeClicked(false);
      setIsCopyUrlClicked(true);
    };
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar__item" }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Option 2 - Image URL" }, /* @__PURE__ */ React.createElement(InputPrimary, { name: "image_url", type: "text", value: url, readonly: true }))), renderCopyToClipboardButton(isCopyUrlClicked, url, onClick));
  };
  const copyTextToClipboard = (text, onClick) => {
    navigator.clipboard.writeText(text).then(() => onClick());
  };
  const renderCopyToClipboardButton = (isTextCopied, text, onClick) => {
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        className: "tint-email-display-settings-sidebar__copy-button",
        type: "primary",
        onClick: () => copyTextToClipboard(text, onClick),
        text: `${isTextCopied ? "Copied!" : "Copy to Clipboard"}`,
        size: "full-width"
      }
    );
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-email-display-settings-sidebar tint-sidebar-label" }, /* @__PURE__ */ React.createElement("p", null, "You can further customize your gallery\u2019s content by applying a filter below.", " ", /* @__PURE__ */ React.createElement(
    Link,
    {
      className: "tint-email-display-settings-sidebar__link",
      to: { pathname: Config.url.customizeEmailTemplateArticle },
      target: "_blank",
      rel: "noopener noreferrer"
    },
    "Learn More"
  )), renderSavedFilterDropdown(), renderFilterByProductInput(), renderFilterByKeywordInput(), renderFilterByTagsInput(), /* @__PURE__ */ React.createElement("hr", null), /* @__PURE__ */ React.createElement("p", null, "Below are two ways to add your email gallery to an email template.", " ", /* @__PURE__ */ React.createElement(
    Link,
    {
      className: "tint-email-display-settings-sidebar__link",
      to: { pathname: Config.url.customizeEmailTemplateArticle },
      target: "_blank",
      rel: "noopener noreferrer"
    },
    "Learn More"
  )), renderOptionCode(), /* @__PURE__ */ React.createElement("hr", null), renderOptionImageUrl());
};
export default EmailDisplaySettingsSidebar;
