var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useRef } from "react";
import "./tint-editor-manage-custom-post.sidebar.sass";
import AccordionTab from "../../../../../../components/interface/accordion/accordion-tab/accordion-tab";
import Accordion from "../../../../../../components/interface/accordion/accordion";
import InputFile from "../../../../../../components/interface/inputs/input-file/input-file";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputPrimary from "../../../../../../components/interface/inputs/input-primary/input-primary";
import TextareaPrimary from "../../../../../../components/interface/inputs/textarea-primary/textarea-primary";
import AddTags, { TagsPosition } from "../../../../../../components/interface/add-tags/add-tags";
import AuthorPhoto from "../../../../../../components/interface/author-photo/author-photo";
import { Button } from "../../../../../../components/interface/button/button";
import { Config } from "../../../../../../utils/config";
import TintSidebar from "../../../../../../components/sidebar/sidebar";
import { addCustomPost, updatePost } from "../../../../actions/posts.fat-actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getMaxVideoSizePlanLimit } from "../../../../../../reducers/session";
import { getPost } from "../../../../reducers/posts.reducer";
import { getSocialConnections } from "../social-feed-sidebar/actions/social-feeds.fat-actions";
import { findSocialFeed } from "../social-feed-sidebar/reducers/social-feeds.selector";
import { SocialFeedsSource } from "../../../../../../types/social-feeds";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "../../../../../../components/interface/typography/typography";
import { Uploader } from "../../../../../../components/uploader/uploader";
import { assetModalId } from "../../../../../social-publishing/components/assets/assets-modal/assets-modal";
import { UploadServiceURLs } from "../../../../../../services/upload/upload.service";
import { openModal, setMediaData } from "../../../../../../actions/ui/ui.actions";
import { getSidebarData } from "../../../../../../reducers/ui/ui.selector";
export const customPostSidebar = "customPostSidebar";
const TintEditorManageCustomPostSidebar = ({ isVisible, onClose }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const dispatch = useDispatch();
  const { tintId } = useParams();
  const sidebarData = useSelector((state) => getSidebarData(state, customPostSidebar));
  const editMode = sidebarData == null ? void 0 : sidebarData.editMode;
  const postId = sidebarData == null ? void 0 : sidebarData.id;
  const media = (sidebarData == null ? void 0 : sidebarData.media) || [];
  const maxVideoSizePlanLimit = useSelector(getMaxVideoSizePlanLimit);
  const post = useSelector((state) => getPost(state.tintEditorPosts, postId));
  const isCustomPostFeedExist = useSelector((state) => findSocialFeed(state, SocialFeedsSource.CUSTOM, null));
  const [isPending, setUploadPending] = useState(false);
  const formik = useFormik({
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: {
      mediaUrl: ((_b = (_a = post == null ? void 0 : post.attributes) == null ? void 0 : _a.author) == null ? void 0 : _b.url) || "",
      description: ((_c = post == null ? void 0 : post.attributes) == null ? void 0 : _c.text) || "",
      authorName: ((_e = (_d = post == null ? void 0 : post.attributes) == null ? void 0 : _d.author) == null ? void 0 : _e.name) || "",
      tags: ((_f = post == null ? void 0 : post.attributes) == null ? void 0 : _f.tags) || [],
      url: ((_g = post == null ? void 0 : post.attributes) == null ? void 0 : _g.url) || "",
      media: []
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().min(1, "is too short (minimum is 1 character)").required("Can't be blank"),
      authorName: Yup.string().min(1, "is too short (minimum is 1 character)").required("Can't be blank"),
      url: Yup.string().matches(Config.regExp.httpPattern, "Incorrect URL address")
    })
  });
  const mapPostData = (data) => {
    return {
      text: data == null ? void 0 : data.description,
      url: (data == null ? void 0 : data.url) || null,
      tags: data == null ? void 0 : data.tags,
      author: {
        name: data == null ? void 0 : data.authorName,
        image_id: (data == null ? void 0 : data.authorPhoto) || ""
      }
    };
  };
  const onSubmit = () => {
    if (editMode) {
      dispatch(updatePost({ postId, postData: mapPostData(formik.values) })).then(() => toast.success("Post has been updated")).catch(() => toast.error("Cannot update a Custom Post"));
    } else {
      dispatch(
        addCustomPost({
          tintId,
          postData: __spreadValues(__spreadValues({}, formik.values), { media })
        })
      ).then(() => {
        toast.success("Custom Post added");
        if (!isCustomPostFeedExist) {
          dispatch(
            getSocialConnections({ url: `/social_feeds?include=tint&filter[tint_id]=${tintId}&page[size]=500` })
          ).catch(() => toast.error("Cannot get Custom Post social feed, try again later"));
        }
      }).catch(() => toast.error("Cannot add a Custom Post"));
    }
    onClose && onClose();
  };
  const onUpload = (urls) => {
    dispatch(setMediaData({ id: customPostSidebar, data: urls }));
  };
  const onRemoveAsset = (index) => {
    const urls = media.filter((_file, i) => i !== index);
    dispatch(setMediaData({ id: customPostSidebar, data: urls }));
  };
  const openUGCModal = () => {
    dispatch(openModal(assetModalId));
  };
  const renderUploader = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group tint-sidebar-group__uploader" }, /* @__PURE__ */ React.createElement(Typography, { component: "span", variant: TypographyVariants.LABEL, color: TypographyColors.SECONDARY }, "Upload or drag and drop images or videos."), /* @__PURE__ */ React.createElement(
      Uploader,
      {
        singleUpload: false,
        onUpload,
        isPreview: true,
        onPending: (isPending2) => setUploadPending(isPending2),
        onRemoveAsset,
        openUGCModal,
        assets: media,
        errors: [],
        url: UploadServiceURLs.POSTS,
        acceptOnlyImages: false
      }
    ));
  };
  return /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      title: editMode ? "Edit Custom Post" : "Create Custom Post",
      isOpen: isVisible,
      onClose,
      defaultFooterStyle: false,
      onRightButtonClick: onSubmit,
      isRightButtonDisabled: !formik.isValid || !formik.dirty,
      rightButtonText: editMode ? "Edit" : "Add"
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-create-custom-post-sidebar" }, /* @__PURE__ */ React.createElement(Accordion, { openedTabIndex: 0 }, /* @__PURE__ */ React.createElement(AccordionTab, { title: "Content" }, /* @__PURE__ */ React.createElement("div", null, !editMode && renderUploader(), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
      TextareaPrimary,
      {
        touched: formik.touched.description,
        error: formik.errors.description,
        handleChange: formik.handleChange,
        handleBlur: formik.handleBlur,
        placeholder: "Add description",
        name: "description",
        label: "Post description *",
        value: formik.values.description
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        touched: formik.touched.authorName,
        error: formik.errors.authorName,
        handleChange: formik.handleChange,
        handleBlur: formik.handleBlur,
        placeholder: "Add Author Name",
        name: "authorName",
        label: "Author Name *",
        value: formik.values.authorName
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement("div", { className: "sidebar-title" }, "Author Photo"), /* @__PURE__ */ React.createElement(
      AuthorPhoto,
      {
        imageSrc: (formik == null ? void 0 : formik.values["authorPhoto"]) || ((_i = (_h = post == null ? void 0 : post.attributes) == null ? void 0 : _h.author) == null ? void 0 : _i.image_url),
        onAvatarChange: (avatarId) => formik.setFieldValue("authorPhoto", avatarId)
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        touched: formik.touched.url,
        error: formik.errors.url,
        handleChange: formik.handleChange,
        handleBlur: formik.handleBlur,
        placeholder: "Enter URL",
        name: "url",
        label: "Link Post To",
        value: formik.values.url
      }
    )))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Advanced Options" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-group__separator" }, /* @__PURE__ */ React.createElement("div", { className: "sidebar-title" }, "Tags (Optional)"), /* @__PURE__ */ React.createElement(
      AddTags,
      {
        tagsPosition: TagsPosition.BOTTOM,
        tagList: formik.values.tags,
        onAddTag: (tagList) => {
          formik.setFieldValue("tags", tagList);
        },
        onDeleteTag: (tagList) => {
          formik.setFieldValue("tags", tagList);
        }
      }
    ))))))
  );
};
export default React.memo(TintEditorManageCustomPostSidebar);
