var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/marketing-integrations/marketing-integrations.actions";
export const initialState = {
  data: [],
  links: void 0,
  isFetching: false,
  error: void 0
};
export const marketingIntegrations = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MARKETING_INTEGRATIONS.SUCCESS:
      return {
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: void 0
      };
    case ACTIONS.POST_MARKETING_INTEGRATION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload.data, ...state.data],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_MARKETING_INTEGRATION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.filter((e) => e.id !== action.payload.id),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.REFRESH_MARKETING_INTEGRATION.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload.id ? action.payload : e),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_MARKETING_INTEGRATIONS.REQUEST:
    case ACTIONS.DELETE_MARKETING_INTEGRATION.REQUEST:
    case ACTIONS.REFRESH_MARKETING_INTEGRATION.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_MARKETING_INTEGRATIONS.FAILURE:
    case ACTIONS.POST_MARKETING_INTEGRATION.FAILURE:
    case ACTIONS.DELETE_MARKETING_INTEGRATION.FAILURE:
    case ACTIONS.REFRESH_MARKETING_INTEGRATION.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    default:
      return state;
  }
};
