var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ACTIONS } from "../actions/transform-ui.actions";
export const initialState = {
  data: {},
  isFetching: false,
  error: void 0
};
export const assetManagerTransformUI = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_ASSET.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        error: false
      });
    case ACTIONS.POST_ASSET.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
};
