var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ACTIONS } from "../../actions/upload-dialog/upload-dialog";
export const initialState = {
  isFinished: false,
  isPending: false,
  title: "",
  files: [],
  type: "",
  onCloseClick: void 0
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_UPLOAD_DIALOG:
      return __spreadValues(__spreadValues({}, state), action.payload);
    case ACTIONS.CLEAR_UPLOAD_DIALOG:
      return initialState;
    default:
      return state;
  }
};
