import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  ADD_ASSETS: createPrefixedActionName("ASSET_MANAGER_GIF_BUILDER", "ADD_ASSETS"),
  REMOVE_ASSETS: createPrefixedActionName("ASSET_MANAGER_GIF_BUILDER", "REMOVE_ASSETS"),
  SELECT_ASSET: createPrefixedActionName("ASSET_MANAGER_GIF_BUILDER", "SELECT_ASSET"),
  REORDER_ASSETS: createPrefixedActionName("ASSET_MANAGER_GIF_BUILDER", "REORDER_ASSETS"),
  SET_OPTION: createPrefixedActionName("ASSET_MANAGER_GIF_BUILDER", "SET_OPTION")
};
export const addGifBuilderAssets = (payload) => ({
  type: ACTIONS.ADD_ASSETS,
  payload
});
export const removeGifBuilderAsset = (id) => ({
  type: ACTIONS.REMOVE_ASSETS,
  payload: id
});
export const selectGifBuilderAsset = (id) => ({
  type: ACTIONS.SELECT_ASSET,
  payload: id
});
export const reorderGifBuilderAssets = () => ({
  type: ACTIONS.REORDER_ASSETS
});
export const setGifBuilderOption = (payload) => ({
  type: ACTIONS.SET_OPTION,
  payload
});
