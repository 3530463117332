var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useLocation } from "react-router-dom";
import { setFilterOption } from "../../actions/filters/asset-manager-filters.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { sourceList } from "./assets-filter-bar";
export const assetTypeList = [
  {
    value: "image",
    name: "Image"
  },
  {
    value: "video",
    name: "Video"
  }
];
export const AssetFilterBarInit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assetTypeParam = searchParams.get("assetType");
  const sources = searchParams.get("source");
  const tagParam = searchParams.get("tag");
  const objects = searchParams.getAll("objects");
  const sentiments = searchParams.getAll("sentiment");
  const assetsAggregations = useSelector((state) => state.assetsAggregations);
  const assetManagerFilters = useSelector((state) => state.assetManagerFilters);
  const [isInitialized, setIsInitialized] = useState(false);
  const assetTags = assetsAggregations.tags.map((item) => {
    const objectFilterElement = assetManagerFilters.filter.tags.find((e) => e.name === item);
    return {
      name: item,
      value: item,
      isActive: objectFilterElement && objectFilterElement.isActive
    };
  });
  const assetObjects = assetsAggregations.objects.map((item) => {
    const objectFilterElement = assetManagerFilters.filter.objects.find((e) => e.name === item);
    return {
      name: item,
      value: item,
      isActive: objectFilterElement && objectFilterElement.isActive
    };
  });
  const assetSentiment = assetsAggregations.emotions.map((item) => {
    const el = assetManagerFilters.filter.sentiment.find((e) => e.name === item);
    return {
      name: item,
      value: item,
      isActive: el && el.isActive
    };
  });
  useEffect(() => {
    if ((assetTags == null ? void 0 : assetTags.length) > 0 && tagParam) {
      const assetTag = assetTags.find((tag) => tag.value === tagParam);
      if (assetTag) {
        dispatch(setFilterOption({ tags: [__spreadProps(__spreadValues({}, assetTag), { isActive: true })] }));
      }
    }
    if ((assetObjects == null ? void 0 : assetObjects.length) > 0 && objects.length > 0) {
      const assetObjectsList = assetObjects.filter((object) => objects.includes(object.value)).map((object) => __spreadProps(__spreadValues({}, object), { isActive: true }));
      dispatch(setFilterOption({ objects: assetObjectsList }));
    }
    if ((assetSentiment == null ? void 0 : assetSentiment.length) > 0 && sentiments.length > 0) {
      const assetSentimentList = assetSentiment.filter((sentiment) => sentiments.includes(sentiment.value)).map((sentiment) => __spreadProps(__spreadValues({}, sentiment), { isActive: true }));
      dispatch(setFilterOption({ sentiment: assetSentimentList }));
    }
    setTimeout(() => {
      setIsInitialized(true);
    }, 700);
  }, [assetsAggregations]);
  useEffect(() => {
    if (assetTypeParam) {
      const assetType = assetTypeList.find((type) => type.value === assetTypeParam);
      dispatch(setFilterOption({ assetType }));
    }
    if (sources) {
      const source = sourceList.find((source2) => source2.value === sources);
      if (source) {
        dispatch(setFilterOption({ source }));
      }
    }
  }, []);
  return { isReady: isInitialized };
};
