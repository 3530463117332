export const ImageRationOptions = [
  {
    value: "original",
    name: "Original"
  },
  {
    value: "1:1",
    name: "1:1 Square"
  },
  {
    value: "1:2",
    name: "1:2 (Vertical)"
  },
  {
    value: "2:1",
    name: "2:1 (Horizontal)"
  },
  {
    value: "2:3",
    name: "2:3 Standard (Vertical)"
  },
  {
    value: "3:2",
    name: "3:2 Standard (Horizontal)"
  },
  {
    value: "3:4",
    name: "3:4"
  },
  {
    value: "4:3",
    name: "4:3"
  },
  {
    value: "9:16",
    name: "9:16 Widescreen"
  },
  {
    value: "16:9",
    name: "16:9 Widescreen"
  }
];
export const roundCornerRadiusSize = "6px";
export const CornerRadiusStyleTypes = {
  SQUARE: "square",
  ROUNDED: "rounded"
};
export const CornerRadiusStyleOptions = [
  {
    value: "square",
    name: "Square"
  },
  {
    value: "rounded",
    name: "Rounded"
  }
];
export const FullWidthValues = {
  YES: "100%",
  NO: "max-content"
};
export const FullWidthDropdownOptions = [
  {
    value: FullWidthValues.YES,
    name: "YES"
  },
  {
    value: FullWidthValues.NO,
    name: "NO"
  }
];
export const AlignmentValues = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right"
};
export const AlignmentOptions = [
  {
    value: AlignmentValues.LEFT,
    name: "Left"
  },
  {
    value: AlignmentValues.CENTER,
    name: "Center"
  },
  {
    value: AlignmentValues.RIGHT,
    name: "Right"
  }
];
export const AspectRatioValues = {
  ORIGINAL: void 0,
  SQUARE: "1",
  VERTICAL: "1/2",
  HORIZONTAL: "2/1",
  STANDARD_VERTICAL: "2/3",
  STANDARD_HORIZONTAL: "3/2",
  THREE_FOURTH: "3/4",
  FOUR_THIRD: "4/3",
  WILD_SCREEN: "9/16",
  WILD_SCREEN_2: "16/9"
};
export const AspectRatioOptions = [
  {
    value: AspectRatioValues.ORIGINAL,
    name: "Original"
  },
  {
    value: AspectRatioValues.SQUARE,
    name: "1:1 (Square)"
  },
  {
    value: AspectRatioValues.VERTICAL,
    name: "1:2 (Vertical)"
  },
  {
    value: AspectRatioValues.HORIZONTAL,
    name: "2:1 (Horizontal)"
  },
  {
    value: AspectRatioValues.STANDARD_VERTICAL,
    name: "2:3 Standard (Vertical)"
  },
  {
    value: AspectRatioValues.STANDARD_HORIZONTAL,
    name: "3:2 Standard (Horizontal)"
  },
  {
    value: AspectRatioValues.THREE_FOURTH,
    name: "3:4"
  },
  {
    value: AspectRatioValues.FOUR_THIRD,
    name: "4:3"
  },
  {
    value: AspectRatioValues.WILD_SCREEN,
    name: "9:16 Widescreen"
  },
  {
    value: AspectRatioValues.WILD_SCREEN_2,
    name: "16:9 Widescreen"
  }
];
export const ObjectFitValues = {
  COVER: "cover",
  NONE: "initial"
};
export const ObjectFitOptions = [
  {
    value: ObjectFitValues.COVER,
    name: "Cover"
  },
  {
    value: ObjectFitValues.NONE,
    name: "None"
  }
];
export const BorderTypeOptions = [
  {
    value: "outline",
    name: "Outline"
  },
  {
    value: "underline",
    name: "Underline"
  },
  {
    value: "none",
    name: "None"
  }
];
export const TextSizeOptions = [
  {
    value: "10px",
    name: "10 px"
  },
  {
    value: "11px",
    name: "11 px"
  },
  {
    value: "12px",
    name: "12 px"
  },
  {
    value: "13px",
    name: "13 px"
  },
  {
    value: "14px",
    name: "14 px"
  },
  {
    value: "15px",
    name: "15 px"
  },
  {
    value: "16px",
    name: "16 px"
  },
  {
    value: "18px",
    name: "18 px"
  },
  {
    value: "20px",
    name: "20 px"
  },
  {
    value: "22px",
    name: "22 px"
  },
  {
    value: "24px",
    name: "24 px"
  },
  {
    value: "26px",
    name: "26 px"
  },
  {
    value: "28px",
    name: "28 px"
  },
  {
    value: "32px",
    name: "32 px"
  },
  {
    value: "36px",
    name: "36 px"
  },
  {
    value: "40px",
    name: "40 px"
  },
  {
    value: "44px",
    name: "44 px"
  },
  {
    value: "48px",
    name: "48 px"
  },
  {
    value: "56px",
    name: "56 px"
  },
  {
    value: "64px",
    name: "64 px"
  },
  {
    value: "72px",
    name: "72 px"
  }
];
export const colorSettingsTypes = {
  LABEL_COLOR: "label_color",
  FIELD_TEXT_COLOR: "field_text_color",
  HINT_COLOR: "hint_color",
  FILL_COLOR: "fill_color",
  ACTIVE_COLOR: "active_color",
  BORDER_COLOR: "border_color",
  TITLE_COLOR: "title_color",
  TEXT_COLOR: "text_color",
  BUTTON_COLOR: "button_color"
};
export const borderTypes = {
  OUTLINE: "outline",
  UNDERLINE: "underline",
  NONE: "none"
};
export const SliderMarks = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }];
