var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect, useState } from "react";
import "./status-button.sass";
import { Button } from "../../../../../components/interface/button/button";
import TintIcon from "../../../../../components/interface/icon/icon";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { updatePost, updatePosts } from "../../../actions/posts.fat-actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
export const POST_STATUS = {
  PRIVATE: "private",
  HIDDEN: "hidden",
  ALL: "all",
  PUBLIC: "public",
  MODERATION_DELETED: "moderation_deleted",
  DELETED: "deleted"
};
const POST_STATUS_RENDER = [
  {
    status: POST_STATUS.PRIVATE,
    icon: faPlus,
    iconHover: faPlus
  },
  {
    status: POST_STATUS.PUBLIC,
    icon: faCheck,
    iconHover: faMinus
  },
  {
    status: POST_STATUS.HIDDEN,
    icon: faCheck
  },
  {
    status: POST_STATUS.ALL,
    icon: faCheck
  },
  {
    status: POST_STATUS.MODERATION_DELETED,
    icon: faCheck
  }
];
const StatusButton = ({ status, postId, getTintEditorPosts }) => {
  const [currentIcon, setCurrentIcon] = useState(POST_STATUS_RENDER[0]);
  const [isHover, setIsHover] = useState(false);
  const dispatch = useDispatch();
  const patchedPosts = (patchedData) => {
    return postId.map((id) => ({
      id,
      type: "post",
      attributes: __spreadValues({}, patchedData)
    }));
  };
  const onUpdateFeedCard = (data) => {
    if (Array.isArray(postId)) {
      dispatch(
        updatePosts({
          updatedPosts: patchedPosts(__spreadValues({}, data))
        })
      ).then(() => getTintEditorPosts == null ? void 0 : getTintEditorPosts());
    } else {
      dispatch(
        updatePost({
          postId,
          postData: __spreadValues({}, data)
        })
      ).catch(() => toast.error("Can not update alternative text"));
    }
  };
  const changePostStatus = () => {
    onUpdateFeedCard({
      status: status === POST_STATUS.PUBLIC ? "private" : "public"
    });
  };
  useEffect(() => {
    const icon = POST_STATUS_RENDER.find((el) => el.status === status);
    setCurrentIcon(icon);
  }, [status]);
  return (status === POST_STATUS.PUBLIC || status === POST_STATUS.PRIVATE) && /* @__PURE__ */ React.createElement("div", { className: "tint-button-status", onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: changePostStatus,
      className: `tint-button-status__button ${status === POST_STATUS.PUBLIC ? "tint-button-status__button--public" : "tint-button-status__button--private"}`
    },
    /* @__PURE__ */ React.createElement(TintIcon, { icon: (currentIcon == null ? void 0 : currentIcon.icon) && isHover ? currentIcon.iconHover : currentIcon == null ? void 0 : currentIcon.icon, fontSize: "22px" })
  ));
};
export default StatusButton;
