var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { pageSectionToTranslation, pageSectionType } from "../../../../model/page-section-type.model";
import Logger from "../../../../services/logger/logger";
import produce from "immer";
import { Config } from "../../../../utils/config";
import { getTranslation } from "../../reducers/experience/experience";
import { createTranslationInputArrayKey } from "./translation-creator";
export class TranslationSupport {
  constructor(experience, experienceType, { standardizeTranslation, saveDataContentDraft, applyDraftExperience, saveCustomizableTextDataDraft }, translations) {
    __publicField(this, "run", () => {
      this.experience.attributes.data.draft.map((e, i) => this.addLanguageSupportToSection(e, i));
      this.saveDataQue.map(
        (e) => this.actions.saveDataContentDraft({ [e.data.key]: e.data.value }, e.index, e.parentSection)
      );
      this.saveCustomizableTextDataQue.map(
        (e) => this.actions.saveCustomizableTextDataDraft({ text: e.text }, e.i, e.sectionIndex, e.parentIndex)
      );
      this.actions.applyDraftExperience();
    });
    __publicField(this, "setExperience", (experience) => {
      this.experience = experience;
    });
    __publicField(this, "addLanguageSupportToSection", (section, i, parentIndex) => {
      this.addLanguageSupportToTextarea(section, i, parentIndex);
      switch (section.sectionType) {
        case pageSectionType.COOKIE_BANNER:
          Logger.info("Add Language Support To Cookie Banner");
          this.addLanguageSupportToCookieBannerSection(section, i, parentIndex);
          break;
        case pageSectionType.CUSTOM_BUTTON:
          Logger.info("Add Language Support To Button Section");
          this.addLanguageSupportToCustomButtonSection(section, i, parentIndex);
          break;
        case pageSectionType.SOCIAL:
          Logger.info("Add Language Support To Social Section");
          this.addLanguageSupportToSocialSection(section, i);
          break;
        case pageSectionType.FOOTER:
          Logger.info("Add Language Support To Footer Section");
          this.addLanguageSupportToFooterSection(section, i);
          break;
        case pageSectionType.TIMER:
          Logger.info("Add Language Support To Timer Section");
          this.addLanguageSupportToTimerSection(section, i);
          break;
        case pageSectionType.FORM:
          Logger.info("Add Language Support To Form Section");
          this.addLanguageSupportToFormSection(section, i);
          break;
        case pageSectionType.SURVEY:
          Logger.info("Add Language Support To Survey Section");
          this.addLanguageSupportToSurveySection(section, i);
          break;
        case pageSectionType.SURVEY_COUNTER:
          Logger.info("Add Language Support To Survey Counter Section");
          this.addLanguageSupportToSurveyCounterSection(section, i);
          break;
        case pageSectionType.POST:
          Logger.info("Add Language Support To Post Section");
          this.addLanguageSupportToPostSection(section, i);
          break;
        case pageSectionType.SOCIAL_FEED_COUNTER:
          Logger.info("Add Language Support To Social Feed Counter Section");
          this.addLanguageSupportToSocialFeedCounterSection(section, i);
          break;
        case pageSectionType.MULTI_COLUMN:
          Logger.info("Add Language Support To Multi Column Section");
          this.addLanguageSupportToMultiColumnSection(section, i);
          break;
        case pageSectionType.TINT:
        case pageSectionType.TAG_O_WAR:
        case pageSectionType.BACK_TO_TOP:
        case pageSectionType.BANNER:
        case pageSectionType.HEADER:
          Logger.info("No need to add translation here!");
          break;
        default:
          Logger.info("Unhandled Section Type");
          break;
      }
    });
    __publicField(this, "addLanguageSupportToMultiColumnSection", (parentSection, parentIndex) => {
      parentSection.data.content.sections.map((section, i) => {
        if (section) {
          this.addLanguageSupportToSection(section, i, parentIndex);
        }
      });
    });
    __publicField(this, "addLanguageSupportToFooterSection", (section, i) => {
      this.addLanguageSupportToProperties(
        section,
        "footerCopyright",
        createTranslationInputArrayKey(this.experienceType, section.sectionType, "FOOTER_COPYRIGHT"),
        i
      );
      this.addLanguageSupportToArrays(section, "footerLinks", ["text", "url"], ["TEXT", "LINK"], i);
    });
    __publicField(this, "addLanguageSupportToSocialSection", (section, i) => {
      this.addLanguageSupportToArrays(section, "socialLinks", ["url"], ["LINK"], i);
    });
    __publicField(this, "addLanguageSupportToSocialFeedCounterSection", (section, i) => {
      this.addLanguageSupportToProperties(
        section,
        "counterLabelText",
        `EXPERIENCE.${this.experienceType.toUpperCase()}.${section.sectionType.toUpperCase()}.${Config.getUniqueId().toUpperCase()}.COUNTER_LABEL`,
        i
      );
    });
    __publicField(this, "addLanguageSupportToCustomButtonSection", (section, i, parentIndex) => {
      const propertyArray = [
        { property: "buttonText", newKey: "BUTTON_TEXT" },
        { property: "buttonUrl", newKey: "BUTTON_URL" }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i,
          parentIndex
        );
      });
    });
    __publicField(this, "addLanguageSupportToCookieBannerSection", (section, i, parentIndex) => {
      const propertyArray = [
        { property: "cookieText", newKey: "COOKIE_TEXT" },
        { property: "cookieAllowButton", newKey: "BUTTON_COOKIE_ALLOW" },
        { property: "cookieDismissButton", newKey: "BUTTON_COOKIE_DISMISS" },
        { property: "cookiePolicyText", newKey: "BUTTON_COOKIE_POLICY_TEXT" },
        { property: "cookiePrivacyPolicyText", newKey: "BUTTON_COOKIE_PRIVACY_POLICY" },
        { property: "cookiePolicyHref", newKey: "BUTTON_COOKIE_POLICY_HREF" },
        { property: "cookiePrivacyPolicyHref", newKey: "BUTTON_COOKIE_PRIVACY_POLICY_HREF" }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i,
          parentIndex
        );
      });
    });
    __publicField(this, "addLanguageSupportToPostSection", (section, i) => {
      const propertyArray = [
        { property: "buttonText", newKey: "BUTTON_TEXT" },
        { property: "popupTitle", newKey: "POPUP_TITLE" },
        { property: "popupButtonText", newKey: "POPUP_BUTTON_TEXT" },
        {
          property: "customFieldPlaceholder",
          newKey: "CUSTOM_FIELD_PLACEHOLDER"
        }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i
        );
      });
    });
    __publicField(this, "addLanguageSupportToPollSection", (section, i) => {
      const propertyArray = [
        { property: "buttonText", newKey: "BUTTON_TEXT" },
        { property: "buttonVotedText", newKey: "BUTTON_VOTED_TEXT" }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i
        );
      });
    });
    __publicField(this, "addLanguageSupportToSurveyCounterSection", (section, i) => {
      const propertyArray = [{ property: "countLabelText", newKey: "COUNT_LABEL_TEXT" }];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i
        );
      });
    });
    __publicField(this, "addLanguageSupportToSurveySection", (section, i) => {
      const propertyArray = [
        { property: "successMessageTitle", newKey: "SUCCESS_MESSAGE_TITLE" },
        {
          property: "successMessageDescription",
          newKey: "SUCCESS_MESSAGE_DESCRIPTION"
        },
        {
          property: "buttonText",
          newKey: "BUTTON_TEXT"
        }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i
        );
      });
      this.addLanguageSupportToArrays(section, "answers", ["title", "description"], ["TITLE", "DESCRIPTION"], i);
      this.addLanguageSupportToArrays(
        section,
        "successMessageLinks",
        ["buttonText", "buttonUrl"],
        ["BUTTON_TEXT", "BUTTON_URL"],
        i
      );
    });
    __publicField(this, "addLanguageSupportToFormSection", (section, i) => {
      const propertyArray = [
        { property: "buttonText", newKey: "BUTTON_TEXT" },
        { property: "uploadButtonText", newKey: "UPLOAD_BUTTON_TEXT" },
        { property: "successMessageTitle", newKey: "SUCCESS_MESSAGE_TITLE" },
        { property: "successMessageDescription", newKey: "SUCCESS_MESSAGE_DESCRIPTION" },
        { property: "gdprCheckboxLabelText", newKey: "GDPR_TEXT" },
        { property: "gdprCheckboxDescriptionText", newKey: "GDPR_DESCRIPTION" },
        { property: "requiredFieldText", newKey: "REQUIRED_FIELD_TEXT" },
        { property: "termsConditionsUrl", newKey: "TERMS_CONDITIONS_URL" },
        { property: "termsConditions", newKey: "TERMS_CONDITIONS" },
        { property: "termsConditionsAgree", newKey: "TERMS_CONDITIONS_AGREE" },
        { property: "termsConditionsLinkTitle", newKey: "TERMS_CONDITIONS_LINK_TITLE" },
        { property: "privacyPolicyUrl", newKey: "PRIVACY_POLICY_URL" },
        { property: "privacyPolicy", newKey: "PRIVACY_POLICY" },
        { property: "privacyPolicyAgree", newKey: "PRIVACY_POLICY_AGREE" },
        { property: "privacyPolicyLinkTitle", newKey: "PRIVACY_POLICY_LINK_TITLE" },
        { property: "contestRulesUrl", newKey: "CONTEST_URL" },
        { property: "contestRules", newKey: "CONTEST" },
        { property: "contestRulesAgree", newKey: "CONTEST_RULES_AGREE" },
        { property: "contestRulesLinkTitle", newKey: "CONTEST_RULES_LINK_TITLE" }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i
        );
      });
      this.addLanguageSupportToArrays(section, "fields", ["placeholder"], ["PLACEHOLDER"], i);
      this.addLanguageSupportToArrays(
        section,
        "successMessageLinks",
        ["buttonText", "buttonUrl"],
        ["BUTTON_TEXT", "BUTTON_URL"],
        i
      );
    });
    __publicField(this, "addLanguageSupportToTimerSection", (section, i) => {
      const propertyArray = [
        { property: "labelDays", newKey: "LABEL_DAYS" },
        { property: "labelHours", newKey: "LABEL_HOURS" },
        { property: "labelMinutes", newKey: "LABEL_MINUTES" },
        { property: "labelSeconds", newKey: "LABEL_SECONDS" },
        { property: "endTitle", newKey: "END_TITLE" }
      ];
      propertyArray.map((e) => {
        this.addLanguageSupportToProperties(
          section,
          e.property,
          createTranslationInputArrayKey(this.experienceType, section.sectionType, e.newKey),
          i
        );
      });
    });
    __publicField(this, "addLanguageSupportToTextarea", (section, sectionIndex, parentIndex) => {
      const customizableHeaders = section.data !== null ? section.data.content.customizableHeaders : void 0;
      if (customizableHeaders === void 0) {
        return;
      }
      return customizableHeaders.map((e, i) => {
        const id = Config.getUniqueId();
        const translation = getTranslation(this.experience, e.text, this.translations);
        if (translation === void 0) {
          const key = `EXPERIENCE.${this.experienceType && this.experienceType.toUpperCase()}.${pageSectionToTranslation[section.sectionType]}.TEXTAREA_${id}`;
          this.actions.standardizeTranslation(key, e.text);
          this.saveCustomizableTextDataQue.push({
            text: key,
            i,
            sectionIndex,
            parentIndex
          });
        }
      });
    });
    __publicField(this, "addLanguageSupportToArrays", (section, parentPropertyName, arrayMutablePropertyName, arrayTranslationKey, i) => {
      const newArray = produce(section.data.content[parentPropertyName], (draftState) => {
        arrayMutablePropertyName.map((mutablePropertyName, mutablePropertyIndex) => {
          section.data.content[parentPropertyName].map((e, i2) => {
            const key = `EXPERIENCE.${this.experienceType.toUpperCase()}.${pageSectionToTranslation[section.sectionType]}.${Config.getUniqueId().toUpperCase()}.${arrayTranslationKey[mutablePropertyIndex]}_${i2}`;
            const socialLinkTranslation = getTranslation(this.experience, e[mutablePropertyName], this.translations);
            if (socialLinkTranslation === void 0) {
              this.actions.standardizeTranslation(key, e[mutablePropertyName]);
              draftState[i2][mutablePropertyName] = key;
            }
          });
        });
      });
      if (JSON.stringify(section.data.content[parentPropertyName]) !== JSON.stringify(newArray)) {
        this.saveDataQue.push({
          data: { key: parentPropertyName, value: newArray },
          index: i
        });
      }
    });
    __publicField(this, "addLanguageSupportToProperties", (section, propertyName, newTranslationKey, i, parentSection) => {
      const translation = getTranslation(this.experience, section.data.content[propertyName], this.translations);
      if (translation === void 0) {
        this.actions.standardizeTranslation(newTranslationKey, section.data.content[propertyName]);
        this.saveDataQue.push({
          data: { key: propertyName, value: newTranslationKey },
          index: i,
          parentSection
        });
      }
    });
    this.experience = experience;
    this.experienceType = experienceType;
    this.translations = translations;
    this.actions = {
      standardizeTranslation,
      saveDataContentDraft,
      applyDraftExperience,
      saveCustomizableTextDataDraft
    };
    this.saveDataQue = [];
    this.saveCustomizableTextDataQue = [];
  }
}
