var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { FIELD_TYPE, transformToSendAble } from "../form-helper";
import InputPrimary from "../../../../../../../components/interface/inputs/input-primary/input-primary";
import ColorParser from "../../../../../../../services/color-parser/color-parser";
import TextareaPrimary from "../../../../../../../components/interface/inputs/textarea-primary/textarea-primary";
import DropdownButton, { ButtonType } from "../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSimpleList from "../../../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import CheckboxButton, {
  CheckboxButtonType
} from "../../../../../../../components/interface/inputs/checkbox-button/checkbox-button";
const _getName = ({ e, data, getTranslation }) => {
  return data.content.hasAdvancedSettings && e.key ? e.key : getTranslation(e.placeholder) ? transformToSendAble(getTranslation(e.placeholder)) : e.placeholder;
};
const _getPlaceholder = ({ e, getTranslation }) => {
  return getTranslation(e.placeholder) ? `${getTranslation(e.placeholder)}${e.required === void 0 || e.required ? "*" : ""}` : "";
};
const renderHiddenInput = ({
  i,
  e,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation
}) => {
  return /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      className: "input",
      classes: "no-margin",
      id: i,
      key: i,
      placeholder: _getPlaceholder({ e, getTranslation }),
      name: _getName({ e, data, getTranslation }),
      dirty,
      errorColor: ColorParser.defaultColor(data.styles.errorColor),
      error: errors[Object.keys(values)[i]] || forceErrorVisibility,
      touched: touched[Object.keys(values)[i]] || !!forceErrorVisibility,
      handleChange,
      disabled: !isPreviewMode,
      isCustomPlaceholderColor: true,
      value: _getPlaceholder({ e, getTranslation }),
      style: {
        display: "none",
        border: `1px solid ${data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : "#fff"}`,
        color: ColorParser.defaultColor(data.styles.formFieldTextColor)
      }
    }
  );
};
const renderInput = ({
  i,
  e,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation
}) => {
  return /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      className: "input",
      id: i,
      key: i,
      placeholder: _getPlaceholder({ e, getTranslation }),
      name: _getName({ e, data, getTranslation }),
      dirty,
      errorColor: ColorParser.defaultColor(data.styles.errorColor),
      error: errors[Object.keys(values)[i]] || forceErrorVisibility,
      touched: touched[Object.keys(values)[i]] || !!forceErrorVisibility,
      handleChange,
      disabled: !isPreviewMode,
      isCustomPlaceholderColor: true,
      style: {
        border: `1px solid ${data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : "#fff"}`,
        color: ColorParser.defaultColor(data.styles.formFieldTextColor)
      }
    }
  );
};
const renderTextarea = ({
  i,
  e,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation
}) => {
  return /* @__PURE__ */ React.createElement(
    TextareaPrimary,
    {
      className: "textarea",
      id: i,
      key: i,
      placeholder: _getPlaceholder({ e, getTranslation }),
      name: _getName({ getTranslation, e, data }),
      dirty,
      errorColor: ColorParser.defaultColor(data.styles.errorColor),
      error: errors[Object.keys(values)[i]] || forceErrorVisibility,
      touched: touched[Object.keys(values)[i]] || !!forceErrorVisibility,
      handleChange,
      disabled: !isPreviewMode,
      isCustomPlaceholderColor: true,
      style: {
        border: `1px solid ${data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : "#fff"}`,
        color: ColorParser.defaultColor(data.styles.formFieldTextColor)
      }
    }
  );
};
const renderDropdown = ({
  i,
  e,
  data,
  forceErrorVisibility,
  errors,
  values,
  getTranslation,
  setFieldValue,
  touched,
  key
}) => {
  const list = e.options.map((el) => {
    return {
      value: getTranslation(el.placeholder),
      name: getTranslation(el.placeholder)
    };
  });
  const error = touched[data.content.fields[i].key || getTranslation(e.placeholder)] && errors[data.content.fields[i].key || getTranslation(e.placeholder)] || forceErrorVisibility;
  const borderColor = error ? ColorParser.defaultColor(data.styles.errorColor) : data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : "#fff";
  const fontColor = error ? ColorParser.defaultColor(data.styles.errorColor) : ColorParser.defaultColor(data.styles.formFieldTextColor);
  const currentValue = values[key];
  const currentItem = {
    value: currentValue ? currentValue : getTranslation(e.placeholder),
    name: currentValue ? currentValue : `${getTranslation(e.placeholder)}${e.required ? "*" : ""}`
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-container", key: i }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownHeaderStyle: { color: fontColor, borderColor },
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, props), { isDefaultOption: false })),
      list,
      placeholder: _getPlaceholder({ e, getTranslation }),
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_GRAY,
      styles: {
        border: `1px solid ${borderColor}`,
        color: fontColor
      },
      onChangeValue: (_value) => {
        setFieldValue(_getName({ e, data, getTranslation }), _value.value);
      }
    }
  ), /* @__PURE__ */ React.createElement(
    "span",
    {
      style: { color: ColorParser.defaultColor(data.styles.errorColor) },
      className: "tint-dropdown-container__error"
    },
    error
  ));
};
const renderCheckbox = ({
  i,
  e,
  data,
  forceErrorVisibility,
  errors,
  values,
  getTranslation,
  setFieldValue,
  touched
}) => {
  const list = e.options.map((el) => {
    return {
      value: getTranslation(el.placeholder),
      name: getTranslation(el.placeholder)
    };
  });
  const fieldName = _getName({ e, data, getTranslation });
  const error = touched[Object.keys(values)[i]] && errors[Object.keys(values)[i]] || forceErrorVisibility;
  const borderColor = error ? ColorParser.defaultColor(data.styles.errorColor) : data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : "#fff";
  const fontColor = error ? ColorParser.defaultColor(data.styles.errorColor) : ColorParser.defaultColor(data.styles.formFieldTextColor);
  const _setFormValue = (item) => {
    const value = list[item].value;
    let _tmpValues;
    if (values[fieldName]) {
      _tmpValues = JSON.parse(values[fieldName]);
    } else {
      _tmpValues = [];
    }
    if (_tmpValues.find((e2) => e2 === value)) {
      _tmpValues = _tmpValues.filter((e2) => e2 !== value);
    } else {
      _tmpValues.push(value);
    }
    if (_tmpValues.length === 0) {
      _tmpValues = "";
    } else {
      _tmpValues = JSON.stringify(_tmpValues);
    }
    setFieldValue(fieldName, _tmpValues);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-checkbox-container", key: i }, /* @__PURE__ */ React.createElement("span", { className: "tint-checkbox-container__title", style: { color: fontColor } }, _getPlaceholder({
    e,
    getTranslation
  })), list.map((option, optionI) => {
    const checkboxUID = `${optionI}-${Math.floor(Math.random() * Date.now())}`;
    return /* @__PURE__ */ React.createElement("div", { className: "tint-checkbox-container__box", key: optionI }, /* @__PURE__ */ React.createElement(
      CheckboxButton,
      {
        id: `${checkboxUID}`,
        type: CheckboxButtonType.PRIMARY,
        markColor: borderColor,
        onStatusChange: () => _setFormValue(optionI),
        borderColor,
        color: borderColor
      }
    ), /* @__PURE__ */ React.createElement("span", { style: { color: fontColor } }, option.name));
  }), /* @__PURE__ */ React.createElement(
    "span",
    {
      style: { color: ColorParser.defaultColor(data.styles.errorColor) },
      className: "tint-dropdown-container__error"
    },
    error
  ));
};
const render = ({
  e,
  i,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation,
  setFieldValue,
  key
}) => {
  const renderData = {
    e,
    i,
    data,
    forceErrorVisibility,
    dirty,
    errors,
    values,
    touched,
    handleChange,
    isPreviewMode,
    getTranslation,
    setFieldValue,
    key
  };
  switch (e.type) {
    case FIELD_TYPE.INPUT:
      return renderInput(renderData);
    case FIELD_TYPE.HIDDEN_INPUT:
      return renderHiddenInput(__spreadProps(__spreadValues({}, renderData), { isHidden: true }));
    case FIELD_TYPE.TEXTAREA:
      return renderTextarea(renderData);
    case FIELD_TYPE.DROPDOWN:
      return renderDropdown(renderData);
    case FIELD_TYPE.CHECKBOX:
      return renderCheckbox(renderData);
  }
};
export const FormFieldRenderer = {
  render
};
