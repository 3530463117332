var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { InputGroup } from "../../../../../../../../components/interface/inputs/input-group/input-group";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import { useSelector } from "react-redux";
import {
  selectFormSubmissionKeys,
  selectDefaultLocale,
  selectExperienceSection,
  getTranslation
} from "../../reducers/social-feeds.selector";
import { dropdownStyles } from "../../utils/common/common";
import { transformToSendAble } from "../../../../../../../../feature/experience/components/experience/page-sections/form/form-helper";
import { pageSectionType } from "../../../../../../../../model/page-section-type.model";
import { BackendKeys } from "../../../../../../../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/consts";
const ExperienceDropdown = ({ label, icon, id, placeholder, disabled, formik }) => {
  var _a, _b, _c, _d, _e;
  const experience = useSelector((state) => state.socialFeeds.experience);
  const formSubmissionSection = selectExperienceSection(experience, [pageSectionType.FORM, pageSectionType.POST]);
  const defaultLocale = selectDefaultLocale(experience);
  const formSubmissionKeys = selectFormSubmissionKeys(experience);
  const hasAdvancedSettingsOn = ((_b = (_a = formSubmissionSection == null ? void 0 : formSubmissionSection.data) == null ? void 0 : _a.content) == null ? void 0 : _b.hasAdvancedSettings) === true;
  const hasFormBuilderForm = (_d = (_c = formSubmissionSection == null ? void 0 : formSubmissionSection.data) == null ? void 0 : _c.content) == null ? void 0 : _d.formBuilderId;
  const getDefaultTranslationKey = (item) => {
    var _a2, _b2, _c2;
    const formInput = (_c2 = (_b2 = (_a2 = formSubmissionSection == null ? void 0 : formSubmissionSection.data) == null ? void 0 : _a2.content) == null ? void 0 : _b2.fields) == null ? void 0 : _c2.find((e) => (e == null ? void 0 : e.key) === (item == null ? void 0 : item.id));
    if (formInput) {
      const translation = getTranslation(experience, formInput == null ? void 0 : formInput.placeholder, defaultLocale);
      if (translation) {
        return transformToSendAble(translation);
      } else {
        return formInput == null ? void 0 : formInput.placeholder;
      }
    }
    return item == null ? void 0 : item.id;
  };
  const filterFormSubmissionKeys = ((_e = formSubmissionKeys == null ? void 0 : formSubmissionKeys.filter((e) => (e == null ? void 0 : e.id) !== BackendKeys.MEDIA)) == null ? void 0 : _e.map((item) => {
    var _a2, _b2;
    if (((_b2 = (_a2 = formSubmissionSection == null ? void 0 : formSubmissionSection.data) == null ? void 0 : _a2.content) == null ? void 0 : _b2.hasAdvancedSettings) === true) {
      return item == null ? void 0 : item.id;
    } else {
      return getDefaultTranslationKey(item);
    }
  })) || [];
  const list = formSubmissionSection ? [...new Set(filterFormSubmissionKeys)].map((key) => {
    return { name: key, value: key };
  }) : [];
  return /* @__PURE__ */ React.createElement(InputGroup, { label, inputGroupIcon: icon, error: formik.errors[id], touched: formik.touched[id] }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      styles: dropdownStyles,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownType: DropdownButtonType.SEARCH_INPUT,
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      currentItem: formik.values[id],
      list,
      placeholder,
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      name: id,
      onChangeValue: (selectedItem) => formik.setFieldValue([id], selectedItem),
      disabled: !formSubmissionSection || hasFormBuilderForm || disabled && disabled(formik.values)
    }
  ));
};
export default React.memo(ExperienceDropdown);
