var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/product-accounts/product-accounts.actions";
import { ProductAccountsTranslations } from "../../../../utils/translations/product-accounts";
import produce from "immer";
export const initialState = {
  data: [],
  links: {},
  filteredProductAccounts: [],
  isFetching: false,
  isFilteringProductAccounts: false,
  error: void 0
};
export const productAccounts = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_PRODUCT_ACCOUNTS.REQUEST:
    case ACTIONS.GET_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.DELETE_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.REFRESH_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.PATCH_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.POST_PRODUCT_ACCOUNT.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.FILTER_PRODUCT_ACCOUNTS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        error: void 0,
        isFilteringProductAccounts: true
      });
    case ACTIONS.GET_PRODUCT_ACCOUNTS.SUCCESS:
      return __spreadProps(__spreadValues(__spreadValues({}, state), action.payload), {
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_PRODUCT_ACCOUNT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.length ? state.data.map(
          (e) => e.id === action.payload.data.id ? __spreadProps(__spreadValues({}, action.payload.data), {
            included: action.payload.included
          }) : e
        ) : [__spreadProps(__spreadValues({}, action.payload.data), { included: action.payload.included })],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_PRODUCT_ACCOUNT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: void 0,
        data: state.data.filter((e) => e.id !== action.payload)
      });
    case ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload, ...state.data],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.PATCH_PRODUCT_ACCOUNT.SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.findIndex((e) => e.id === action.payload.id);
        draftState.data[index] = action.payload;
        draftState.isFetching = false;
        draftState.error = void 0;
      });
    case ACTIONS.REFRESH_PRODUCT_ACCOUNT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload.id ? action.payload : e),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.POST_PRODUCT_ACCOUNT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload, ...state.data],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.FILTER_PRODUCT_ACCOUNTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        filteredProductAccounts: action.payload,
        isFilteringProductAccounts: false,
        error: void 0
      });
    case ACTIONS.GET_PRODUCT_ACCOUNTS.FAILURE:
    case ACTIONS.GET_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.DELETE_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.FAILURE:
    case ACTIONS.REFRESH_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.PATCH_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.POST_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.FILTER_PRODUCT_ACCOUNTS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false,
        isFilteringProductAccounts: false
      });
    default:
      return state;
  }
};
export const mapProductAccountsForDropdown = (list) => list.map((e) => ({
  name: e.attributes.name || e.attributes.username || e.attributes.site || ProductAccountsTranslations[e.attributes.type],
  value: e.id
}));
export const mapCatalogsForDropdown = (list, productAccount) => {
  const _productAccount = productAccount ? list.find((e) => e.id === productAccount.value) : [];
  return _productAccount && _productAccount.included ? _productAccount.included.map((e) => ({ name: e.attributes.name, value: e.id })) : [];
};
