import React, { useEffect, useRef, useState } from "react";
import "./dropdown-date-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import { Config } from "../../../../../utils/config";
import { getDatePickerDataRange } from "../../../../../utils/date-modifiers/date-modifiers";
import PropTypes from "prop-types";
import { DropdownDateRangeHeader } from "./header/dropdown-date-range-header/dropdown-date-range-header";
export const datePickerOptions = {
  WEEK: "Last 7 Days",
  MONTH: "Last Month",
  THREE_MONTHS: "Last 3 Months",
  SIX_MONTHS: "Last 6 Months",
  YEAR: "Last Year"
};
const DropdownDateList = ({ onChange, currentItems, parentRef, onChangeTextComponent, title }) => {
  const listRef = useRef();
  const [currentList, setCurrentList] = useState(currentItems);
  const { positionHorizontal, positionVertical } = useDropdownListPosition(listRef, parentRef);
  useEffect(() => {
    setCurrentList(
      currentItems.map((item) => ({
        value: item.value,
        name: item.name,
        currentDate: getCurrentDate(),
        timeDifference: getDateFrom(getDatePickerDataRange(item.value)),
        isActive: item.isActive || false
      }))
    );
  }, [currentItems]);
  const renderItemNameFormat = (name, value) => {
    return /* @__PURE__ */ React.createElement("span", { className: "dropdown-date-format" }, /* @__PURE__ */ React.createElement("span", { className: "dropdown-date-format__type" }, name, "\xA0"), /* @__PURE__ */ React.createElement("span", { className: "dropdown-date-format__range" }, getDateFrom(getDatePickerDataRange(value)), " - ", getCurrentDate()));
  };
  const getCurrentDate = () => {
    return `${Config.monthList[(/* @__PURE__ */ new Date()).getMonth()]} ${(/* @__PURE__ */ new Date()).getDate()}, ${(/* @__PURE__ */ new Date()).getFullYear()}`;
  };
  const getDateFrom = (dateTimestamp) => {
    return `${Config.monthList[new Date(dateTimestamp).getMonth()]} ${new Date(dateTimestamp).getDate()}, ${new Date(
      dateTimestamp
    ).getFullYear()}`;
  };
  const DropdownTextElement = (title2) => {
    return /* @__PURE__ */ React.createElement(DropdownDateRangeHeader, { title: title2 });
  };
  return /* @__PURE__ */ React.createElement(
    "ul",
    {
      className: `dropdown-date-list dropdown-date-list--${positionHorizontal} dropdown-list--${positionVertical}`,
      ref: listRef,
      "data-testid": "dropdown-date-list"
    },
    /* @__PURE__ */ React.createElement(
      "li",
      {
        className: "dropdown-list__item",
        onClick: () => {
          onChangeTextComponent(DropdownTextElement(title));
          onChange(void 0);
        }
      },
      /* @__PURE__ */ React.createElement("span", null, "All Time")
    ),
    currentList.map((item, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-date-list__item ${item.isActive ? "active" : ""}`,
        key: i,
        onClick: () => {
          onChange(item);
          onChangeTextComponent(
            DropdownTextElement(`${getDateFrom(getDatePickerDataRange(item.value))} - ${getCurrentDate()}`)
          );
        }
      },
      renderItemNameFormat(item.name, item.value)
    ))
  );
};
export default DropdownDateList;
DropdownDateList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  title: PropTypes.string
};
