var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/api/api.actions";
import produce from "immer";
import { Config } from "../../../../utils/config";
export const initialState = {
  data: [],
  isFetching: false,
  error: void 0
};
export const webhooks = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_WEBHOOK.REQUEST:
    case ACTIONS.GET_WEBHOOKS.REQUEST:
    case ACTIONS.UPDATE_WEBHOOK.REQUEST:
    case ACTIONS.DELETE_WEBHOOK.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.DELETE_WEBHOOK.FAILURE:
    case ACTIONS.POST_WEBHOOK.FAILURE:
    case ACTIONS.UPDATE_WEBHOOK.FAILURE:
    case ACTIONS.GET_WEBHOOKS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_WEBHOOKS.SUCCESS:
      return __spreadProps(__spreadValues(__spreadValues({}, state), {
        data: action.payload.data,
        links: action.payload.links,
        error: void 0
      }), {
        isFetching: false
      });
    case ACTIONS.UPDATE_WEBHOOK.SUCCESS:
      return produce(state, (draft) => {
        const index = draft.data.findIndex((el) => el.id === action.payload.id);
        draft.data[index] = action.payload;
        draft.isFetching = false;
      });
    case ACTIONS.POST_WEBHOOK.SUCCESS:
      return produce(state, (draft) => {
        const draftData = [...draft.data];
        if (draftData.length > Config.limits.webhooksListMaxElements - 1)
          draftData.length = Config.limits.webhooksListMaxElements - 1;
        draft.data = [action.payload, ...draftData];
        draft.isFetching = false;
      });
    case ACTIONS.DELETE_WEBHOOK.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((webhook) => webhook.id !== action.payload);
        draft.isFetching = false;
      });
    default:
      return state;
  }
};
