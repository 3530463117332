var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect, useState } from "react";
import "./edit-form-builder-content.sass";
import { useHistory } from "react-router-dom";
import "../../../../../../../components/sidebar/sidebar.sass";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { ApiService } from "../../../../../../../services/api-service/api-service";
import { DropdownHeader } from "../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownButton, { ButtonType } from "../../../../../../../components/interface/dropdown/button/dropdown-button";
import { InputGroup } from "../../../../../../../components/interface/inputs/input-group/input-group";
import { formBuilderRoutePaths } from "../../../../../../form-builder/utils/form-builder.route-paths";
import DropdownSearchList from "../../../../../../../components/interface/dropdown/lists/search-list/dropdown-search-list";
const addFormBuilderElement = { name: "+ Add Form Builder", value: "ADD_FORM_BUILDER" };
export const EditFormBuilderContent = ({ dataUpdate, data }) => {
  const history = useHistory();
  const [forms, setForms] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  useEffect(() => {
    fetchFormBuilderData();
  }, []);
  const fetchFormBuilderData = () => {
    ApiService.get("/forms?page[size]=500&filter[published]=true").then((e) => {
      setForms(e.data.data.map((form) => ({ name: form.attributes.name, value: form.id })));
      const id = data.content.formBuilderId;
      if (id) {
        let form = e.data.data.find((form2) => form2.id === id);
        form = { name: form.attributes.name, value: form.id };
        setCurrentItem(form);
      }
    }).finally(() => {
    });
  };
  const onFormSelect = (form) => {
    if (form.value === addFormBuilderElement.value) {
      history.push(formBuilderRoutePaths.new.path);
      return;
    }
    const teamId = ApiService.getTeamId();
    dataUpdate({
      teamId,
      formBuilderId: form.value
    });
  };
  return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Connect your Form Builder by selecting a specific form from the list below." }, /* @__PURE__ */ React.createElement(InputGroup, { label: "Form" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      placeholder: "Select Form",
      currentItem,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSearchList, __spreadValues({ isDefaultOption: false }, props)),
      list: [...forms, addFormBuilderElement],
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_SORT,
      onChangeValue: onFormSelect
    }
  )));
};
