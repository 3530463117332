import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import store from "../public/js/react/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "../app/css/reset.sass";
import "../app/css/main.sass";
import "../public/js/react/containers/experience.sass";
import TingPages from "./tint-pages";
require("formdata-polyfill");
hydrate(
  /* @__PURE__ */ React.createElement(Provider, { store }, /* @__PURE__ */ React.createElement(TingPages, null)),
  document.getElementById("tint-app")
);
