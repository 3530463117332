import React from "react";
import PropTypes from "prop-types";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditInput, EditForm } from "../../../";
const PostEditPopup = ({ data, isCustomFieldAvailable, onTranslationUpdate, getTranslation }) => {
  return /* @__PURE__ */ React.createElement(EditModalBody, null, /* @__PURE__ */ React.createElement(EditForm, null, /* @__PURE__ */ React.createElement(
    EditInput,
    {
      defaultValue: getTranslation(data.popupTitle),
      label: "popup title",
      onChange: (e) => {
        onTranslationUpdate(data.popupTitle, e.target.value);
      }
    }
  ), /* @__PURE__ */ React.createElement(
    EditInput,
    {
      defaultValue: getTranslation(data.popupButtonText),
      label: "button text",
      onChange: (e) => {
        onTranslationUpdate(data.popupButtonText, e.target.value);
      }
    }
  ), isCustomFieldAvailable ? /* @__PURE__ */ React.createElement(
    EditInput,
    {
      defaultValue: getTranslation(data.customFieldPlaceholder),
      label: "custom field",
      onChange: (e) => {
        onTranslationUpdate(data.customFieldPlaceholder, e.target.value);
      }
    }
  ) : null));
};
PostEditPopup.propTypes = {
  dataUpdate: PropTypes.func,
  data: PropTypes.object
};
export default PostEditPopup;
