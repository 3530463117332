var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/product-feeds/product-feeds.actions";
export const initialState = {
  data: [],
  links: void 0,
  isFetching: false,
  error: void 0
};
export const productFeeds = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_PRODUCT_FEEDS.REQUEST:
    case ACTIONS.GET_PRODUCT_FEED.REQUEST:
    case ACTIONS.DELETE_PRODUCT_FEED.REQUEST:
    case ACTIONS.POST_PRODUCT_FEED.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_PRODUCT_FEEDS.SUCCESS:
      return __spreadProps(__spreadValues(__spreadValues({}, state), action.payload), {
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_PRODUCT_FEED.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.length ? state.data.map(
          (e) => e.id === action.payload.id ? __spreadValues({}, action.payload) : e
        ) : [__spreadValues({}, action.payload)],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_PRODUCT_FEED.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: void 0,
        data: state.data.filter((e) => e.id !== action.payload)
      });
    case ACTIONS.POST_PRODUCT_FEED.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload, ...state.data],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_PRODUCT_FEEDS.FAILURE:
    case ACTIONS.GET_PRODUCT_FEED.FAILURE:
    case ACTIONS.DELETE_PRODUCT_FEED.FAILURE:
    case ACTIONS.POST_PRODUCT_FEED.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
};
export const filterProductFeeds = (products) => {
  var _a;
  return ((_a = products == null ? void 0 : products.included) == null ? void 0 : _a.length) > 0 ? products.included.filter((e) => e.type === "product_feed") : [];
};
