import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const smileyScale = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE]: {
    display: "flex",
    padding: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT]: {
    width: "42px",
    height: "42px",
    padding: 0,
    cursor: "pointer",
    margin: "0 16px 0 0",
    "border-radius": "50%",
    appearance: "none",
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_LABEL]: {
    border: "0",
    "clip-path": "inset(50%)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    width: "1px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_ONE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cline%20x1%3D%2210%22%20y1%3D%2214%22%20x2%3D%2218%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cline%20x1%3D%2232%22%20y1%3D%2214%22%20x2%3D%2224%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cpath%20d%3D%22M14%2032%20Q%2021%2025%2C%2029%2032%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_ONE_ACTIVE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%23F04723%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cline%20x1%3D%2210%22%20y1%3D%2214%22%20x2%3D%2218%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cline%20x1%3D%2232%22%20y1%3D%2214%22%20x2%3D%2224%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cpath%20d%3D%22M14%2032%20Q%2021%2025%2C%2029%2032%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_TWO]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2031%20Q%2021%2026%2C%2030%2031%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_TWO_ACTIVE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%23F68E20%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2031%20Q%2021%2026%2C%2030%2031%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_THREE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%20%3Crect%20x%3D%2212%22%20y%3D%2228%22%20width%3D%2218%22%20height%3D%222%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_THREE_ACTIVE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%23FECC0A%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%20%3Crect%20x%3D%2212%22%20y%3D%2228%22%20width%3D%2218%22%20height%3D%222%22%20fill%3D%22%23ffffff%22%2F%3E%20%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_FOUR]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2028%20Q%2021%2034%2C%2030%2028%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_FOUR_ACTIVE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%2392E53F%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2028%20Q%2021%2034%2C%2030%2028%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_FIVE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M8%2C26%20H34%20A14%2C14%200%200%201%208%2026%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_INPUT_FIVE_ACTIVE]: {
    content: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%230FC12F%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M8%2C26%20H34%20A14%2C14%200%200%201%208%2026%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E')"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SMILEY_SCALE_RTL]: {
    "margin-right": 0
  }
};
