import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { MARKETING_INTEGRATIONS } from "../../consts";
export const ACTIONS = {
  GET_MARKETING_INTEGRATIONS_FOLDERS: createRoutine(
    createPrefixedActionName(MARKETING_INTEGRATIONS, "GET_MARKETING_INTEGRATIONS_FOLDERS")
  )
};
export const getMarketingIntegrationsFoldersStart = () => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.REQUEST
});
export const getMarketingIntegrationsFoldersSuccess = (data) => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.SUCCESS,
  payload: data
});
export const getMarketingIntegrationsFoldersError = (err) => ({
  type: ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.FAILURE,
  payload: err
});
