var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSocialAccountsAuthorizationService } from "../../../../../account-settings/hooks/use-social-accounts-authorization-service";
import { getSocialConnection } from "../../../../../account-settings/actions/social-connections/social-connections.fat-actions";
import TintSidebar from "../../../../../../components/sidebar/sidebar";
import { SidebarFooter } from "../../../../../../components/sidebar/components/footer/sidebar-footer";
import SocialList from "./components/social-list/social-list";
import { handleApiError } from "../../../../../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import SocialFeedForm from "./components/social-feed-form/social-feed-form";
import { socialFeedSchema } from "./schemas/social-feed-schema";
import "./social-feed-sidebar.sass";
import { SocialFeedsSource } from "../../../../../../types/social-feeds";
import { getSession } from "../../../../../../actions/session";
import { HeaderSocialFeedSidebar } from "./components/common/header-social-feed-sidebar/header-social-feed-sidebar";
import { patchSocialFeed, postSocialFeed } from "./actions/social-feeds.fat-actions";
import { AlertSocialFeedSidebar } from "./components/common/alert-social-feed-sidebar/alert-social-feed-sidebar";
import { getMarketingIntegrations } from "../../../../../account-settings/actions/marketing-integrations/marketing-integrations.fat-actions";
import TwitterIntegration from "./components/twitter-integration/twitter-integration";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType,
  PreloaderSizeType
} from "../../../../../../components/interface/preloaders/preloader/preloader";
import { getForm } from "./actions/social-feeds.fat-actions";
import { clearSocialFeedFormState } from "./actions/social-feeds.actions";
import { getSidebarData, isSidebarOpen } from "../../../../../../reducers/ui/ui.selector";
import { closeSidebar } from "../../../../../../actions/ui/ui.actions";
import { socialFeedSidebar } from "../../../social-feed-card/social-feed-card";
import {
  socialAccountFormType,
  SocialAccountFormRef
} from "../../../../../account-settings/components/social-connections-modal/social-account-form/social-account-form";
import { socialAccountsSchema } from "../../../../../account-settings/components/social-connections-modal/schemas/social-accounts-schema";
const SocialFeedsSidebar = ({ tintId }) => {
  var _a, _b, _c;
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const isOpen = useSelector((state) => isSidebarOpen(state, socialFeedSidebar));
  const isSocialAccountForm = useSelector((state) => isSidebarOpen(state, socialAccountFormType));
  const sidebarData = useSelector((state) => getSidebarData(state, socialFeedSidebar));
  const isSocialFeedUpgradeSidebarOpen = sidebarData == null ? void 0 : sidebarData.isUpgradeMode;
  const editFeedData = sidebarData == null ? void 0 : sidebarData.editFeedData;
  const sidebarType = sidebarData == null ? void 0 : sidebarData.socialFeedSidebar;
  const isCommunitySocialFeed = sidebarType === SocialFeedsSource.VESTA;
  const isPublicPostingSocialFeed = sidebarType === SocialFeedsSource.PUBLIC_POST || sidebarType === SocialFeedsSource.REVIEWS_RATINGS;
  const moderation = editFeedData && typeof ((_a = editFeedData.attributes) == null ? void 0 : _a.moderated) === "boolean" ? editFeedData.attributes.moderated : true;
  const isPublicPostingUpgradeMode = isPublicPostingSocialFeed && (!editFeedData || ((_c = (_b = editFeedData == null ? void 0 : editFeedData.attributes) == null ? void 0 : _b.options) == null ? void 0 : _c.form_id) || isSocialFeedUpgradeSidebarOpen);
  const schema = isPublicPostingUpgradeMode ? socialFeedSchema[sidebarType + "_upgrade"] : socialFeedSchema[sidebarType];
  const [currentStep, setCurrentStep] = useState(
    !editFeedData || isPublicPostingSocialFeed || isCommunitySocialFeed ? 0 : 1
  );
  const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(true);
  const [isModeratedChecked, setIsModeratedChecked] = useState(moderation);
  const [newAddedAccountId, setNewAddedAccountId] = useState();
  const [selectedListElement, setSelectedListElement] = useState(
    !editFeedData ? schema[currentStep].selectedOption : {
      id: editFeedData.attributes.type || sidebarType,
      title: editFeedData.attributes.type || sidebarType
    }
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef();
  const accountFormRef = useRef();
  const [backendErrors, setBackendErrors] = useState({});
  const currentSchema = schema[currentStep];
  const { addSocialConnection } = useSocialAccountsAuthorizationService({
    session: session.data,
    onNewAccountSuccess: (data) => {
      dispatch(getSocialConnection({ socialConnectionId: data.id })).then(() => setNewAddedAccountId(data.id));
    }
  });
  const marketingIntegrations = useSelector((state) => state.marketingIntegrations);
  const socialFeeds = useSelector((state) => state.socialFeeds);
  const onCloseSocialAccountForm = () => {
    dispatch(closeSidebar(socialAccountFormType));
  };
  const onCloseSidebar = () => {
    dispatch(closeSidebar(socialFeedSidebar));
    if (isSocialAccountForm) onCloseSocialAccountForm();
  };
  const onSocialListElementChange = (element) => {
    setSelectedListElement(element);
  };
  useEffect(() => {
    if (sidebarType === SocialFeedsSource.TWITTER) {
      dispatch(getMarketingIntegrations());
    }
  }, [sidebarType, dispatch]);
  useEffect(() => {
    var _a2, _b2;
    if (isPublicPostingSocialFeed && ((_b2 = (_a2 = editFeedData == null ? void 0 : editFeedData.attributes) == null ? void 0 : _a2.options) == null ? void 0 : _b2.form_id)) {
      dispatch(getForm({ formId: editFeedData.attributes.options.form_id })).catch(
        (err) => handleApiError(err, toast.error)
      );
    }
    return () => {
      dispatch(clearSocialFeedFormState());
      if (isSocialAccountForm) onCloseSocialAccountForm();
    };
  }, []);
  useEffect(() => {
    const errors = {};
    setBackendErrors(errors);
  }, [currentSchema.accountSourceType]);
  const twitterIntegrationGuard = sidebarType === SocialFeedsSource.TWITTER && !marketingIntegrations.data.find((integration) => integration.attributes.source === SocialFeedsSource.TWITTER);
  const renderBody = () => {
    if (isSocialAccountForm) {
      return /* @__PURE__ */ React.createElement(
        SocialAccountFormRef,
        {
          ref: accountFormRef,
          schema: socialAccountsSchema[sidebarType][1],
          setIsFormValid,
          type: sidebarType,
          callbackFn: onCloseSocialAccountForm
        }
      );
    }
    if (currentSchema.typeList) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, currentSchema.description && /* @__PURE__ */ React.createElement(HeaderSocialFeedSidebar, { copy: currentSchema.description }), /* @__PURE__ */ React.createElement(
        SocialList,
        {
          list: currentSchema.typeList,
          onChange: onSocialListElementChange,
          selectedOption: selectedListElement,
          socialFeedType: sidebarType
        }
      ));
    } else {
      return /* @__PURE__ */ React.createElement(
        SocialFeedForm,
        {
          addSocialConnection,
          newAddedAccountId,
          setNewAddedAccountId,
          onSubmit,
          onPatch,
          formRef,
          schema: currentSchema,
          selectedOption: selectedListElement == null ? void 0 : selectedListElement.id,
          setIsFormValid: (isValid) => setIsFormValid(isValid),
          currentActiveSocialFeedSource: sidebarType,
          isSocialFeedUpgradeSidebarOpen: isPublicPostingUpgradeMode,
          tintId,
          backendErrors,
          editFeedData
        }
      );
    }
  };
  const checkIsSocialFeedWithoutType = (currentType) => {
    return [
      SocialFeedsSource.PINTEREST,
      SocialFeedsSource.RSS,
      SocialFeedsSource.SMS,
      SocialFeedsSource.SLACK,
      SocialFeedsSource.EMAIL,
      SocialFeedsSource.WEBEX,
      SocialFeedsSource.USER_REVIEW
    ].includes(currentType);
  };
  useEffect(() => {
    if (currentSchema.typeList && (selectedListElement == null ? void 0 : selectedListElement.id) || currentSchema.formSchema && isFormValid) {
      setIsRightButtonDisabled(false);
    } else {
      setIsRightButtonDisabled(true);
    }
  }, [currentSchema, selectedListElement, isFormValid]);
  const onSubmit = (value) => {
    dispatch(
      postSocialFeed({
        payload: __spreadValues({
          type: checkIsSocialFeedWithoutType(sidebarType) ? void 0 : selectedListElement == null ? void 0 : selectedListElement.id,
          tint_id: tintId,
          source: currentSchema.accountSourceType,
          moderated: isModeratedChecked
        }, value)
      })
    ).then(() => {
      toast.success("Social feed created successfully");
      onCloseSidebar();
      dispatch(getSession({}));
    }).catch((err) => {
      handleBackendError(err);
    });
  };
  const onPatch = (value, id) => {
    dispatch(
      patchSocialFeed({
        payload: __spreadProps(__spreadValues({}, value), {
          moderated: isModeratedChecked
        }),
        socialFeedId: id
      })
    ).then(() => onCloseSidebar()).catch((err) => {
      handleBackendError(err);
    });
  };
  const handleBackendError = (err) => {
    var _a2, _b2;
    setBackendErrors({});
    if ((_b2 = (_a2 = err == null ? void 0 : err.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.errors) {
      const filterErrors = err.response.data.errors.filter((e) => {
        const isInlineError = e.source && e.source.pointer && e.detail && e.title;
        if (isInlineError) {
          setBackendErrors(__spreadProps(__spreadValues({}, backendErrors), { [e.source.pointer.split("/").splice(-1)[0]]: `${e.title} ${e.detail}` }));
        }
        return !isInlineError;
      });
      handleApiError(filterErrors, toast.error);
    }
  };
  const scrollToTopSidebar = () => {
    document.querySelector("#scrollableTarget").scrollTo(0, 0);
  };
  const onRightButtonClick = () => {
    scrollToTopSidebar();
    if (isSocialAccountForm && (accountFormRef == null ? void 0 : accountFormRef.current)) {
      return accountFormRef.current.handleSubmit();
    }
    if (schema.length === currentStep + 1) {
      if (formRef.current) {
        formRef == null ? void 0 : formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  const onLeftButtonClick = () => {
    scrollToTopSidebar();
    if (isSocialAccountForm) return onCloseSocialAccountForm();
    if (currentStep === 0 || sidebarType === SocialFeedsSource.TWITTER && editFeedData) {
      onCloseSidebar();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };
  const renderFooter = ({ onClickButton }) => {
    const rightFooter = isSocialAccountForm ? socialAccountsSchema[sidebarType][1].footer : editFeedData && (currentSchema == null ? void 0 : currentSchema.editFooter) ? currentSchema == null ? void 0 : currentSchema.editFooter : currentSchema == null ? void 0 : currentSchema.footer;
    return /* @__PURE__ */ React.createElement(
      SidebarFooter,
      {
        onClickButton,
        isRightButtonDisabled,
        leftButtonText: rightFooter.leftButtonLabel,
        rightButtonText: rightFooter.rightButtonLabel
      }
    );
  };
  const renderSocialFeedSidebar = () => {
    if (twitterIntegrationGuard) {
      return /* @__PURE__ */ React.createElement(
        TwitterIntegration,
        {
          isOpen: twitterIntegrationGuard,
          onClose: onLeftButtonClick,
          onLeftButtonClick
        }
      );
    }
    return /* @__PURE__ */ React.createElement(
      TintSidebar,
      {
        sidebarClassName: "tint-sidebar-social-feeds",
        isOpen,
        onClose: onCloseSidebar,
        onRightButtonClick,
        onLeftButtonClick,
        title: currentSchema.header ? currentSchema.header : selectedListElement.title,
        defaultFooterStyle: false,
        footerComponent: renderFooter,
        alertComponent: currentSchema.isAlert && /* @__PURE__ */ React.createElement(
          AlertSocialFeedSidebar,
          {
            name: "moderated",
            id: "moderated",
            onStatusChange: () => setIsModeratedChecked((checked) => !checked),
            defaultChecked: isModeratedChecked
          }
        )
      },
      marketingIntegrations.isFetching || socialFeeds.isFetchingForm ? /* @__PURE__ */ React.createElement(
        PreloaderComponent,
        {
          positionType: PreloaderPositionType.RELATIVE,
          backdropType: PreloaderBackdropType.TRANSPARENT,
          size: PreloaderSizeType.NORMAL
        }
      ) : /* @__PURE__ */ React.createElement("div", { className: "tint-social-feed-sidebar__feed" }, renderBody())
    );
  };
  return renderSocialFeedSidebar();
};
export default React.memo(SocialFeedsSidebar);
