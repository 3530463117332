import { useEffect, useState } from "react";
import useKeyDown from "../../hooks/use-key-down/use-key-down";
import { keyCodes } from "../../hooks/use-key-down/keyCodes";
const useSelectListElement = ({ listLength, onEnterClick, isDefaultOption }) => {
  const defaultState = -1;
  const [currentElement, setCurrentElement] = useState(defaultState);
  const _listLength = isDefaultOption ? listLength + 1 : listLength;
  const key = useKeyDown();
  useEffect(() => {
    const index = isDefaultOption ? currentElement - 1 : currentElement;
    switch (key == null ? void 0 : key.keyDown) {
      case keyCodes.enter:
        onEnterClick == null ? void 0 : onEnterClick(index);
        break;
      case keyCodes.arrowBottom:
        setCurrentElement((i) => i < _listLength - 1 ? i + 1 : 0);
        break;
      case keyCodes.arrowTop:
        setCurrentElement((i) => i === 0 ? _listLength - 1 : i > 0 ? i - 1 : 0);
        break;
      default:
        return;
    }
  }, [key]);
  const isSelected = (i) => {
    const index = isDefaultOption ? i + 1 : i;
    if (isDefaultOption && currentElement !== -1) {
      if (currentElement === 0) {
        return false;
      }
      return currentElement === index;
    }
    return currentElement === i;
  };
  return { setCurrentElement, currentElement, isSelected };
};
export default useSelectListElement;
