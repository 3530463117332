import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditUploadDesign } from "../../../../index";
import { RangeInput } from "../../../../../../../../components/interface/inputs/range-input/range-input";
export class ImageStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize this section." }, /* @__PURE__ */ React.createElement(
      RangeInput,
      {
        value: this.props.data.styles.imageWidth,
        onChange: (e) => {
          this.props.styleDataUpdate({ imageWidth: e });
        },
        step: "1",
        minRange: "1",
        maxRange: "100",
        label: "Image width",
        unit: "%"
      }
    ), /* @__PURE__ */ React.createElement(
      EditUploadDesign,
      {
        dataUpdate: this.props.dataUpdate,
        styleDataUpdate: this.props.styleDataUpdate,
        data: this.props.data
      }
    ));
  }
}
