var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useIsInViewport from "use-is-in-viewport";
import Logger from "../../../services/logger/logger";
import { ACTIONS } from "../actions/performance/insights-performance.actions";
import {
  getActiveVisitorsCount,
  getDailyEmbedClicks,
  getDailyEmbedViews,
  getEngagementByType,
  getEngagementCount,
  getEngagementCTACount,
  getEngagementImpressions,
  getEngagementPerDevice,
  getEngagementPostCount,
  getTopUsedOmnichannelTypes,
  getTopUsedThemes,
  getVisitorDemographic
} from "../actions/performance/insights-performance.fat-actions";
export const useInsightsPerformanceData = (requestArray = [], force = false) => {
  const dispatch = useDispatch();
  const insights = useSelector((state) => state.insights);
  const insightsPerformance = useSelector((state) => state.insightsPerformance);
  const [lastFilterRequest, setLastFilterRequest] = useState();
  const [isInViewport, targetRef] = useIsInViewport();
  const download = insights.download;
  useEffect(() => {
    var _a, _b, _c, _d, _e;
    let _filterRequest = ((_b = (_a = insights == null ? void 0 : insights.filter) == null ? void 0 : _a.date) == null ? void 0 : _b.name) + ((_c = insights == null ? void 0 : insights.filter) == null ? void 0 : _c.tintId) + ((_d = insights == null ? void 0 : insights.filter) == null ? void 0 : _d.personalizationId) + ((_e = insights == null ? void 0 : insights.filter) == null ? void 0 : _e.locationId);
    if ((isInViewport || download) && lastFilterRequest !== _filterRequest) {
      setLastFilterRequest(_filterRequest);
      run();
    }
  }, [insights.filter, insights.filter.tintId, isInViewport, download]);
  const run = () => {
    var _a;
    const filters = (_a = insights == null ? void 0 : insights.filter) != null ? _a : {};
    requestArray.map((e) => {
      switch (e) {
        case ACTIONS.GET_ENGAGEMENT_CTA_COUNT:
          if (force || !insightsPerformance.engagementCTACount.isFetching) {
            dispatch(getEngagementCTACount({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_ENGAGEMENT_POST_COUNT:
          if (force || !insightsPerformance.engagementPostCount.isFetching) {
            dispatch(getEngagementPostCount({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_ACTIVE_VISITORS_COUNT:
          if (force || !insightsPerformance.activeVisitorsCount.isFetching) {
            dispatch(getActiveVisitorsCount({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_ENGAGEMENT_IMPRESSIONS:
          if (force || !insightsPerformance.engagementImpressions.isFetching) {
            dispatch(getEngagementImpressions({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_ENGAGEMENT_COUNT:
          if (force || !insightsPerformance.engagementCount.isFetching) {
            dispatch(getEngagementCount({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_TOP_USED_THEMES:
          if (force || !insightsPerformance.topUsedThemes.isFetching) {
            dispatch(getTopUsedThemes({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_ENGAGEMENT_PER_DEVICE:
          if (force || !insightsPerformance.engagementPerDevice.isFetching) {
            dispatch(getEngagementPerDevice({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_ENGAGEMENT_BY_TYPE:
          if (force || !insightsPerformance.engagementByType.isFetching) {
            dispatch(getEngagementByType({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_VISITOR_DEMOGRAPHIC:
          if (force || !insightsPerformance.visitorDemographic.isFetching) {
            dispatch(getVisitorDemographic({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_DAILY_EMBED_VIEWS:
          if (force || !insightsPerformance.dailyEmbedViews.isFetching) {
            dispatch(getDailyEmbedViews({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_DAILY_EMBED_CLICKS:
          if (force || !insightsPerformance.dailyEmbedClicks.isFetching) {
            dispatch(getDailyEmbedClicks({ filter: __spreadValues({}, filters) }));
          }
          break;
        case ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES:
          if (force || !insightsPerformance.topUsedOmnichannelTypes.isFetching) {
            dispatch(getTopUsedOmnichannelTypes({ filter: __spreadValues({}, filters) }));
          }
          break;
        default:
          Logger.error("Request type not handled!");
      }
    });
  };
  return [run, targetRef];
};
