var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useMemo, useEffect, useState } from "react";
import "./feed-card-icons.sass";
import { useHistory } from "react-router-dom";
import Tooltip from "../../../../transform-ui/components/tooltip/tooltip";
import TintButtonIcon, { TintButtonIconTypes } from "../../../../../components/interface/button-icon/button-icon";
import { POST_STATUS } from "../status-button/status-button";
import {
  openRequestRights,
  setRequestRightsModalProgress
} from "../../../../../actions/request-rights/request-rights.actions";
import { getSocialConnections } from "../../../../account-settings/actions/social-connections/social-connections.fat-actions";
import {
  getRequestRightsTemplates,
  getAiTemplates,
  getRequestRightsTerms
} from "../../../../../actions/request-rights/request-rights.fat-actions";
import { REQUEST_RIGHTS_SOURCE } from "../../../../../components/request-rights/request-rights";
import { deletePost, recoverPost, updatePost, updatePosts } from "../../../actions/posts.fat-actions";
import { faBullhorn, faShieldCheck, faEllipsisH, faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import {
  faStar,
  faThumbTack,
  faBan,
  faTrash,
  faUniversalAccess,
  faTags,
  faCirclePlus,
  faCircleMinus,
  faSearch,
  faPenToSquare,
  faPhotoFilm,
  faCheck,
  faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import { faArrowDownToLine } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { openPostTagList, pinToTop, unpinToTop } from "../../../actions/posts.actions";
import DropdownButton, { ButtonType } from "../../../../../components/interface/dropdown/button/dropdown-button";
import DropdownSimpleList from "../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import { patchTintModerationRules } from "../../../actions/tint-editor.fat-actions";
import { handleApiError } from "../../../../../services/functions/error-handler/error-handler";
import { openEcommerceModal, toggleCTASidebar } from "../../../actions/tint-editor.actions";
import { Button } from "../../../../../components/interface/button/button";
import AlternativeText from "../alternative-text/alternative-text";
import { TintEditorConfig } from "../../../utils/tint-editor.config";
import AutocompleteTags from "../../../../../components/interface/autocomplete-tags/autocomplete-tags";
import { useQuery } from "../../../../../components/hooks/use-query/use-query";
import { TintEditorViewState } from "../../../containers/tint-editor/tint-editor.container";
import { SocialFeedsSource } from "../../../../../types/social-feeds";
import { useVisualSearch } from "../../../../../components/hooks/use-visual-search/use-visual-search";
import useFeatureAccessibility from "../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { GuardNames } from "../../../../../guards/guards-list";
import { openSidebar as openGlobalSidebar, SIDEBAR_TYPE } from "../../../../../actions/sidebar";
import { sidebarUpgradeSchema } from "../../../../../components/sidebar-upgrade/sidebar-upgrade.schema";
import { routePaths } from "../../../../../utils/route-paths/route-paths";
import { customPostSidebar } from "../sidebars/manage-custom-post/tint-editor-manage-custom-post.sidebar";
import { openSidebar } from "../../../../../actions/ui/ui.actions";
import { getSocialFeed } from "../../../reducers/posts.reducer";
import { RightRequestsStatusTypes } from "../../../../../types/right-requests";
import { getSocialFeedSource } from "../../../selectors/tint-editor.selector";
const MORE_SETTINGS_VALUES = {
  DELETE: "delete",
  BLOCK_USER: "blockUser",
  PIN_TO_TOP: "pinToTop",
  ALTERNATIVE_TEXT: "alternativeText",
  HIGHLIGHT: "highlight",
  EDIT_CUSTOM_POST: "editCustomPost",
  VIEW_IN_UGC: "viewInUGC",
  DOWNLOAD_IMAGE: "downloadImage",
  MARK_AS_INCENTIVIZED: "markAsIncentivized"
};
const FeedCardIcons = ({
  post,
  tintId,
  isSelected,
  onPinFeed,
  isGlobalPageState,
  iconLength = 6,
  withTag = true,
  withMore = true,
  withSiblings = false,
  withCTA = true,
  withFindSimilar = true
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
  const history = useHistory();
  const dispatch = useDispatch();
  const tintEditor = useSelector((state) => state.tintEditor);
  const tintEditorPosts = useSelector((state) => state.tintEditorPosts);
  const iconWidth = 34;
  const { id: userId } = useSelector((state) => state.session.data);
  const socialFeed = useSelector((state) => getSocialFeed(tintEditorPosts, post == null ? void 0 : post.id));
  const postSource = getSocialFeedSource(socialFeed);
  const isTagsModalVisible = (tintEditorPosts == null ? void 0 : tintEditorPosts.tagListModalPostId) === (post == null ? void 0 : post.id);
  const [isAlternativeTextVisible, setAlternativeTextVisibility] = useState();
  const [tags, setTags] = useState((_a = post == null ? void 0 : post.attributes) == null ? void 0 : _a.tags);
  useEffect(() => {
    var _a2;
    setTags((_a2 = post == null ? void 0 : post.attributes) == null ? void 0 : _a2.tags);
  }, [post]);
  const {
    cta: isCTAAvailable,
    ecommerce: isEcommerceAvailable,
    userRights: isUserRightsAvailable,
    moderateTint: isModerateTintAvailable,
    blockUser: isBlockUserAvailable,
    newVisualSimilarity: newVisualSimilarityAvailable,
    tagging: isTaggingAvailable
  } = useFeatureAccessibility();
  const userRightMessageAiSuggestionAvailable = useFeatureAccessibility(GuardNames.USER_RIGHT_MESSAGE_AI_SUGGESTION);
  const autoModerationRules = (_c = (_b = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _b.attributes) == null ? void 0 : _c.auto_moderation_rules;
  const queryParams = useQuery();
  const isTrashState = queryParams.get(TintEditorConfig.urlSearchParamState) === TintEditorViewState.TRASH;
  const pageSize = useSelector((state) => state.tintEditorPosts.pageSize || 20);
  const selectedFilters = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.selectedFilters);
  const isPostDeleted = post.attributes.status === POST_STATUS.DELETED || post.attributes.status === POST_STATUS.MODERATION_DELETED;
  const getAuthorRules = () => autoModerationRules.find((rule) => rule.conditions["author.username"] && rule.changes.status === POST_STATUS.PRIVATE);
  const isUserBlocked = (name) => {
    if (!autoModerationRules) return false;
    const authorRules = getAuthorRules();
    return name && authorRules ? authorRules.conditions["author.username"].includes(name) : false;
  };
  const sortByFilters = useSelector((state) => {
    var _a2;
    return (_a2 = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a2.sortPostsBy;
  });
  useEffect(() => {
    setAlternativeTextVisibility(false);
  }, [(_d = tintEditor == null ? void 0 : tintEditor.modal) == null ? void 0 : _d.postId]);
  const dropdownItems = [
    postSource === SocialFeedsSource.REVIEWS_RATINGS && {
      name: post.attributes.incentivized ? "Unmark as Incentivized" : "Mark as Incentivized",
      value: MORE_SETTINGS_VALUES.MARK_AS_INCENTIVIZED,
      icon: ((_e = post.attributes) == null ? void 0 : _e.incentivized) ? faCircleXmark : faCheck,
      action: () => {
        var _a2;
        return onUpdateFeedCard({ incentivized: !((_a2 = post.attributes) == null ? void 0 : _a2.incentivized) });
      }
    },
    ((_f = post == null ? void 0 : post.attributes) == null ? void 0 : _f.type) === SocialFeedsSource.CUSTOM && {
      name: "Edit",
      value: MORE_SETTINGS_VALUES.EDIT_CUSTOM_POST,
      icon: faPenToSquare,
      action: () => dispatch(openSidebar(customPostSidebar, { id: post.id, editMode: true }))
    },
    isModerateTintAvailable && ((_g = post == null ? void 0 : post.attributes) == null ? void 0 : _g.status) !== POST_STATUS.DELETED && ((_h = post == null ? void 0 : post.attributes) == null ? void 0 : _h.status) !== POST_STATUS.MODERATION_DELETED && {
      name: "Delete",
      value: MORE_SETTINGS_VALUES.DELETE,
      icon: faTrash,
      action: () => dispatch(deletePost(post.id)).catch(() => toast.error("Can not delete post"))
    },
    isBlockUserAvailable && ((_j = (_i = post == null ? void 0 : post.attributes) == null ? void 0 : _i.author) == null ? void 0 : _j.username) && {
      name: isUserBlocked((_l = (_k = post == null ? void 0 : post.attributes) == null ? void 0 : _k.author) == null ? void 0 : _l.username) ? "Unblock user" : "Block user",
      value: MORE_SETTINGS_VALUES.BLOCK_USER,
      icon: faBan,
      action: () => onBlockUserClick()
    },
    isModerateTintAvailable && {
      name: post.attributes.pinned ? "Unpin" : "Pin to top",
      value: MORE_SETTINGS_VALUES.PIN_TO_TOP,
      icon: faThumbTack,
      action: () => onUpdateFeedCard({ pinned: !post.attributes.pinned }).then((postFeed) => {
        if (!postFeed.attributes.pinned) {
          dispatch(unpinToTop(postFeed));
        } else {
          dispatch(pinToTop(postFeed));
          onPinFeed();
        }
      })
    },
    isModerateTintAvailable && {
      name: ((_m = post == null ? void 0 : post.attributes) == null ? void 0 : _m.alternative_text) ? "Edit alternative text" : "Add alternative text",
      value: MORE_SETTINGS_VALUES.ALTERNATIVE_TEXT,
      icon: faUniversalAccess,
      action: () => setAlternativeTextVisibility(true)
    },
    isModerateTintAvailable && {
      name: post.attributes.highlighted ? "Un-Highlight" : "Highlight",
      value: MORE_SETTINGS_VALUES.HIGHLIGHT,
      icon: faStar,
      action: () => onUpdateFeedCard({ highlighted: !post.attributes.highlighted })
    },
    ((_n = post == null ? void 0 : post.relationships) == null ? void 0 : _n.asset.data) && {
      name: "View in UGC Studio",
      value: MORE_SETTINGS_VALUES.VIEW_IN_UGC,
      icon: faPhotoFilm,
      action: () => history.push(routePaths.assetManager.asset.creatUrl(post.relationships.asset.data.id)),
      customClassName: "hidden-mobile-flex"
    },
    (post == null ? void 0 : post.attributes.status) === RightRequestsStatusTypes.APPROVED && {
      name: "Download Image",
      value: MORE_SETTINGS_VALUES.DOWNLOAD_IMAGE,
      icon: faArrowDownToLine,
      action: () => {
        const a = document.createElement("a");
        a.href = post.attributes.image_url;
        a.download = "image.jpg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  ].filter(Boolean);
  const onBlockUserClick = () => {
    var _a2, _b2, _c2, _d2;
    const autoModerationRules2 = (_b2 = (_a2 = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _a2.attributes) == null ? void 0 : _b2.auto_moderation_rules;
    const authorRules = getAuthorRules();
    const authorUsernameIndex = autoModerationRules2.findIndex((rule) => {
      return rule.conditions["author.username"] && rule.changes.status === "private";
    });
    const filteredModerationRules = (_d2 = (_c2 = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _c2.attributes) == null ? void 0 : _d2.auto_moderation_rules.filter(
      (_rule, i) => i !== authorUsernameIndex
    );
    let authorUsernameList = authorRules && authorRules.conditions["author.username"] || [];
    if (isUserBlocked(post.attributes.author.username)) {
      authorUsernameList = authorUsernameList.filter((a) => a !== post.attributes.author.username);
    } else {
      authorUsernameList = [...authorUsernameList, post.attributes.author.username];
    }
    const authorUserName = {
      changes: {
        status: "private"
      },
      conditions: {
        ["author.username"]: authorUsernameList
      }
    };
    updateBlockUserList([...filteredModerationRules, authorUserName]);
  };
  const updateBlockUserList = (data) => {
    dispatch(patchTintModerationRules(tintEditor.data.id, data)).catch((err) => handleApiError(err, toast.error));
  };
  const { onVisualSearchUpload } = useVisualSearch();
  const isSeparateSettingsAllowed = useFeatureAccessibility(GuardNames.SEPARATE_SETTINGS);
  const settings = [
    {
      icon: faSearch,
      action: () => {
        if (!newVisualSimilarityAvailable) {
          dispatch(
            openGlobalSidebar({
              modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
              data: {
                sidebarUpgradeData: sidebarUpgradeSchema.visualSearch
              }
            })
          );
        } else {
          onVisualSearchUpload({
            tintId,
            src: post.attributes.image_url,
            postId: post.id,
            pageSize,
            filters: selectedFilters,
            sortBy: __spreadProps(__spreadValues({}, sortByFilters), { sortBy: "score" })
          });
        }
      },
      isVisible: !isTrashState && post.attributes.image_url && !isGlobalPageState && withFindSimilar,
      tooltipText: newVisualSimilarityAvailable ? "Find Similar" : "Find Similar",
      name: "Find Similar",
      className: "feed-card-icons__find-similar"
    },
    {
      icon: faStar,
      color: "#3B99FC",
      action: () => onUpdateFeedCard({ highlighted: !post.attributes.highlighted }),
      isVisible: !isTrashState && isModerateTintAvailable && post.attributes.highlighted,
      tooltipText: post.attributes.highlighted ? "Un-Highlight" : "Highlight",
      className: "feed-card-icons__highlight"
    },
    {
      icon: faThumbTack,
      color: "#3B99FC",
      action: () => onUpdateFeedCard({ pinned: !post.attributes.pinned }),
      isVisible: !isTrashState && isModerateTintAvailable && post.attributes.pinned,
      tooltipText: post.attributes.pinned ? "Unpin" : "Pin to top",
      className: "feed-card-icons__pinned"
    },
    {
      icon: faTags,
      action: () => {
        if (!isTaggingAvailable) {
          dispatch(
            openGlobalSidebar({
              modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
              data: {
                sidebarUpgradeData: sidebarUpgradeSchema.default
              }
            })
          );
        } else {
          dispatch(openPostTagList(isTagsModalVisible ? void 0 : post.id));
        }
      },
      isVisible: isModerateTintAvailable && post.attributes.status !== POST_STATUS.DELETED && withTag,
      tooltipText: "Tags",
      name: "Tags",
      className: "feed-card-icons__tags"
    },
    {
      icon: faShieldCheck,
      action: () => {
        if (isUserRightsAvailable) {
          dispatch(
            openRequestRights({
              tintId,
              payload: {
                author: __spreadProps(__spreadValues({}, post.attributes.author), {
                  picture: post.attributes.image_url
                }),
                url: post.attributes.url,
                source: post.attributes.type,
                image: post.attributes.image_url
              }
            })
          );
          dispatch(getSocialConnections({ url: "/accounts?page[size]=500&filter[status]=ok" }));
          dispatch(getRequestRightsTemplates({}));
          if (userRightMessageAiSuggestionAvailable) {
            dispatch(getAiTemplates({ post_id: post.id }));
          }
          dispatch(getRequestRightsTerms({}));
          dispatch(
            setRequestRightsModalProgress({
              external_id: post.attributes.external_id,
              user_id: userId
            })
          );
        } else {
          dispatch(
            openGlobalSidebar({
              modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
              data: {
                sidebarUpgradeData: sidebarUpgradeSchema.rightsManagement
              }
            })
          );
        }
      },
      isVisible: !isTrashState && (post.attributes.type === REQUEST_RIGHTS_SOURCE.TWITTER || post.attributes.type === REQUEST_RIGHTS_SOURCE.INSTAGRAM_BUSINESS) && !isPostDeleted && !((_q = (_p = (_o = post == null ? void 0 : post.relationships) == null ? void 0 : _o.right_requests) == null ? void 0 : _p.meta) == null ? void 0 : _q.status),
      tooltipText: "Get Rights",
      name: "Get Rights",
      className: "feed-card-icons__get-rights"
    },
    {
      icon: faShoppingCart,
      action: () => {
        if (!isEcommerceAvailable) {
          dispatch(
            openGlobalSidebar({
              modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
              data: {
                sidebarUpgradeData: sidebarUpgradeSchema.tagProducts
              }
            })
          );
        } else {
          dispatch(openEcommerceModal(post));
        }
      },
      isVisible: !isTrashState && !isPostDeleted && withTag,
      tooltipText: "Tag Products",
      name: "Tag Products",
      className: "feed-card-icons__tag-products"
    },
    {
      icon: faBullhorn,
      action: () => {
        var _a2;
        const postIds = post.siblings ? [post.id, ...post.siblings.map((s) => s.id)] : [post.id];
        dispatch(toggleCTASidebar(!((_a2 = tintEditor == null ? void 0 : tintEditor.CTAModal) == null ? void 0 : _a2.isCTASidebarOpen), true, postIds));
      },
      isVisible: !isTrashState && isCTAAvailable && !isPostDeleted && !isGlobalPageState && withCTA,
      tooltipText: "Call To Action",
      name: "Call To Action",
      className: "feed-card-icons__call-to-action"
    },
    {
      icon: faCirclePlus,
      action: () => {
        dispatch(
          recoverPost({
            postId: post.id,
            status: POST_STATUS.PUBLIC
          })
        );
      },
      isVisible: isTrashState && isPostDeleted,
      tooltipText: "Recover post and make Public",
      name: "Recover post and make Public",
      className: "feed-card-icons__recover-public"
    },
    {
      icon: faCircleMinus,
      action: () => {
        dispatch(
          recoverPost({
            postId: post.id,
            status: POST_STATUS.PRIVATE
          })
        );
      },
      isVisible: isTrashState && isPostDeleted,
      tooltipText: "Recover post and make Private",
      name: "Recover post and make Private",
      className: "feed-card-icons__recover-private"
    }
  ].filter((el) => el.isVisible);
  const patchedPosts = (patchedData) => {
    return (post.siblings ? [post, ...post.siblings] : [post]).map((p) => __spreadProps(__spreadValues({}, p), {
      attributes: __spreadValues({}, patchedData)
    }));
  };
  const onUpdateFeedCard = (data) => {
    if (withSiblings) {
      return dispatch(
        updatePosts({
          updatedPosts: patchedPosts(data)
        })
      );
    } else {
      return dispatch(
        updatePost({
          postId: post.id,
          postData: __spreadValues({}, data)
        })
      ).catch(() => toast.error("Can not update alternative text"));
    }
  };
  const hiddenIcons = useMemo(() => {
    const filterVisibleIcons = settings.filter((setting) => setting.isVisible);
    const hiddenElementsLength = filterVisibleIcons.length - iconLength > 0 ? filterVisibleIcons.length - iconLength : 0;
    const hiddenIcons2 = hiddenElementsLength > 0 ? filterVisibleIcons.slice(-hiddenElementsLength) : [];
    return hiddenIcons2;
  }, [settings.length, iconLength]);
  const style = { gridTemplateColumns: `repeat(${iconLength}, ${iconWidth}px)` };
  return /* @__PURE__ */ React.createElement("div", { className: "feed-card-icons" }, /* @__PURE__ */ React.createElement("div", { className: "feed-card-icons__wrapper", style: __spreadValues({}, style) }, settings.map(
    (setting, index) => setting.isVisible && index <= iconLength - 1 && /* @__PURE__ */ React.createElement("div", { className: setting == null ? void 0 : setting.className, key: index }, /* @__PURE__ */ React.createElement(
      Tooltip,
      {
        style: { top: "auto", left: "-100%", bottom: "100%" },
        text: setting.tooltipText,
        component: /* @__PURE__ */ React.createElement(
          TintButtonIcon,
          {
            color: setting == null ? void 0 : setting.color,
            key: index,
            type: TintButtonIconTypes.NONE,
            icon: setting.icon,
            onClick: setting.action,
            isDisabled: isSelected
          }
        )
      }
    ))
  )), !isPostDeleted && isModerateTintAvailable && withMore && /* @__PURE__ */ React.createElement("div", { className: "feed-card-icons__dropdown-button" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: () => /* @__PURE__ */ React.createElement(TintButtonIcon, { type: TintButtonIconTypes.NONE, icon: faEllipsisH }),
      dropdownList: (p) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, p), { isDefaultOption: false, onChangeTextComponent: () => {
      } })),
      list: [...hiddenIcons, ...dropdownItems],
      buttonType: ButtonType.BUTTON_NO_STYL,
      onChangeValue: (el) => el == null ? void 0 : el.action()
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "feed-card-icons__content" }, isTagsModalVisible && /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__tag" }, /* @__PURE__ */ React.createElement(AutocompleteTags, { tagList: tags, onChange: setTags, onDeleteTag: setTags, focusOnInit: true }), /* @__PURE__ */ React.createElement(
    Button,
    {
      text: "Done",
      type: "primary",
      onClick: () => {
        onUpdateFeedCard({ tags });
        dispatch(openPostTagList(void 0));
      }
    }
  )), isAlternativeTextVisible && /* @__PURE__ */ React.createElement("div", { className: "tint-image-preview__alternative-text" }, /* @__PURE__ */ React.createElement(
    AlternativeText,
    {
      text: (_r = post == null ? void 0 : post.attributes) == null ? void 0 : _r.alternative_text,
      onUpdate: (data) => {
        onUpdateFeedCard(data);
        setAlternativeTextVisibility(false);
      }
    }
  ))));
};
export default React.memo(FeedCardIcons);
