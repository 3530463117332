import { SocialFeedsSource } from "../../types/social-feeds";
export const urls = {
  contact: "https://www.tintup.com/contact",
  pricing: "https://www.tintup.com/pricing/",
  experiences: "https://www.tintup.com/experiences",
  contactUpgrade: "https://www.tintup.com/contact?upgrade",
  apiDocumentation: "https://developers.tintup.com/v2/",
  embedLocationsHelp: "https://support.tintup.com/hc/en-us/articles/231452208-Embedding-your-TINT",
  collaboratorsHelp: "https://support.tintup.com/hc/en-us/articles/230870987-Collaborators",
  shopify: "https://apps.shopify.com/tint",
  productAccountsHelp: "https://support.tintup.com",
  productFeedsHelp: "https://support.tintup.com",
  productsCSVHelp: "https://support.tintup.com/hc/en-us/articles/360063684453-Custom-Product-Feed",
  productCSVSample: "https://support.tintup.com/hc/en-us/article_attachments/1500010230222/Sample.csv",
  marketingIntegrationsHelp: "https://support.tintup.com/hc/en-us/articles/1500008985061-Integrations-Overview",
  marketingIntegrationsHootsuiteApp: "https://apps.hootsuite.com/apps/tint",
  marketingIntegrationsZapierApp: "https://zapier.com/developer/public-invite/163089/7396e2287724fcec95dbbf005cd40bc9/",
  customDomainHelp: "https://support.tintup.com/hc/en-us/articles/360034278673-Custom-Domain-for-Experience-Builder-ExB-",
  tintMixLearn: "https://support.tintup.com/hc/en-us/articles/231888608",
  teamPreferencesLearn: "https://support.tintup.com/hc/en-us/articles/231453608-Keyword-and-Tag-Filtering ",
  zenDesk: {
    base: "https://support.tintup.com",
    saml: "https://support.tintup.com/hc/en-us/articles/360058983074",
    createTintAndAddContent: "https://support.tintup.com/hc/en-us/articles/231580527-Creating-a-TINT-and-Adding-Content",
    imageFilterRules: "https://support.tintup.com/hc/en-us/articles/115002081807",
    blockKeywordsRuleExample: "https://api.tintup.com/assets/naughty_words.csv",
    ugcRights: "https://support.tintup.com/hc/en-us/articles/230883267#h_01G023BZ0GJC2G4JWT7RQGJ359",
    widget: "https://static.zdassets.com/ekr/snippet.js?key=2618fffa-e1b7-4ac3-9941-f3aef9633517",
    twitter_integration: "https://support.tintup.com/hc/en-us/articles/16130285332371",
    webEmbedSettingsSavedLinks: "https://support.tintup.com/hc/en-us/articles/231453608",
    [SocialFeedsSource.TWITTER]: "https://support.tintup.com/hc/en-us/articles/10293427908627",
    [SocialFeedsSource.INSTAGRAM]: "https://support.tintup.com/hc/en-us/articles/360013162314",
    [SocialFeedsSource.INSTAGRAM_BUSINESS]: "https://support.tintup.com/hc/en-us/articles/360013162314",
    [SocialFeedsSource.FACEBOOK]: "https://support.tintup.com/hc/en-us/articles/13082694178835",
    [SocialFeedsSource.TUMBLR]: "https://support.tintup.com/hc/en-us/articles/13082709863059",
    [SocialFeedsSource.FLICKR]: "https://support.tintup.com/hc/en-us/articles/9768808346003",
    [SocialFeedsSource.SLACK]: "https://support.tintup.com/hc/en-us/articles/231752128",
    [SocialFeedsSource.HOOT_SUITE]: "https://support.tintup.com/hc/en-us/articles/231936008",
    [SocialFeedsSource.TIK_TOK]: "https://support.tintup.com/hc/en-us/articles/13082695779859",
    [SocialFeedsSource.WEBEX]: "https://support.tintup.com/hc/en-us/articles/231584027",
    [SocialFeedsSource.PINTEREST]: "https://support.tintup.com/hc/en-us/articles/7154676672147",
    [SocialFeedsSource.THREADS]: "https://support.tintup.com/hc/en-us/articles/31916517285395-Threads"
  },
  externalLinks: {
    chromecast: "https://play.google.com/store/apps/details?id=com.google.android.apps.chromecast.app&hl=en&gl=US",
    markdown: "https://daringfireball.net/projects/markdown/syntax?"
  },
  dropkiqCSS: "https://unpkg.com/dropkiq-ui@1.0.66/dist/dropkiq.css",
  dropkiqJS: "https://unpkg.com/dropkiq-ui@1.0.66/dist/dropkiq.min.js",
  tikTokEmbedScript: "https://www.tiktok.com/embed.js",
  youTubeEmbedScript: "https://www.youtube.com/iframe_api",
  customizeEmailTemplateArticle: "https://support.tintup.com/hc/en-us/articles/9006578907027",
  influencerDiscoveryContact: "https://www.tintup.com/contact/?influencer-discovery",
  discovery: {
    howCreateCommunity: "https://www.tintup.com/blog/how-to-create-a-community-powered-marketing-strategy/",
    howKnowWhenReady: "https://www.tintup.com/blog/how-do-i-know-when-im-ready-for-an-online-brand-community/",
    guideToZeroParty: "https://www.tintup.com/blog/how-to-use-zero-party-data/",
    howToBuildBrandAdvocacy: "https://www.tintup.com/blog/how-to-build-brand-advocacy/",
    howUseOnlineCommunity: "https://www.tintup.com/blog/how-to-use-an-online-community-to-get-more-product-reviews-right-now/",
    creatingACommunity: "https://www.tintup.com/blog/creating-a-community-how-to-tap-shared-identity-for-growth/"
  },
  rightsRequest: "//cdn.hypemarks.com/assets/terms/Sample_Content_Rights_ToS.pdf"
};
