import { ApiService } from "../api-service/api-service";
import { toast } from "react-toastify";
import { handleApiError } from "../functions/error-handler/error-handler";
let windowRef;
const _openWindow = ({ url }) => {
  windowRef && windowRef.close();
  windowRef = window.open(url, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
  return windowRef;
};
const add = ({ url }) => {
  ApiService.get(url).then((res) => {
    const windowRef2 = _openWindow({});
    windowRef2.location = res.data.data.attributes.url;
  }).catch((err) => handleApiError(err, toast.error));
};
const addWithBody = ({ url, target }) => {
  if (windowRef && !windowRef.closed) {
    windowRef.close();
  }
  windowRef = window.open("", target ? target : "_blank");
  ApiService.get(url).then((res) => {
    if (windowRef) {
      windowRef.document.open();
      windowRef.document.write(res.data);
      windowRef.document.close();
    } else {
      toast.error("Popup blocked. Please allow popups for this site.");
    }
  }).catch((err) => handleApiError(err, toast.error));
};
const edit = ({ url }) => {
  ApiService.get(url).then((res) => {
    const windowRef2 = _openWindow({});
    windowRef2.location = res.data.data.attributes.url;
  }).catch((err) => handleApiError(err, toast.error));
};
const _callback = (success, failure) => {
  return (message) => {
    if (message.status === 200) {
      success(message);
      windowRef.close();
    } else {
      failure(message);
      windowRef.close();
    }
  };
};
const on = ({ eventerService, eventType, success, failure }) => {
  const fn = _callback(success, failure);
  eventerService.on({
    eventType,
    fn
  });
  return fn;
};
const off = ({ eventerService, eventType, fn }) => {
  eventerService.off({ eventType, fn });
};
export const AuthorizationService = {
  add,
  edit,
  on,
  off,
  addWithBody,
  windowRef
};
