import { useEffect } from "react";
import debounce from "lodash.debounce";
export const useResize = ({ refCurrent, setWidth, delay = 500 }) => {
  useEffect(() => {
    if (refCurrent) {
      setWidth(refCurrent.offsetWidth);
    }
    const debouncedSetWidth = debounce(setWidth, delay);
    window.addEventListener("resize", debouncedSetWidth);
    return () => window.removeEventListener("resize", debouncedSetWidth);
  }, [refCurrent, setWidth]);
};
