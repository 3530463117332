import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import CustomDatePicker from "../../../../../../../../components/interface/inputs/custom-date-picker/custom-date-picker";
import { EditError, EditInput, EditInputDropdown } from "../../../../index";
export const EditTime = ({ data, minDate, onDateChange }) => {
  const getInitialValues = () => {
    const date = new Date(data.endDate);
    const hours = date.getHours();
    return {
      timeType: date.getHours() > 11 ? "PM" : "AM",
      hour: hours > 11 ? hours - 12 : hours,
      minute: date.getMinutes().toString()
    };
  };
  const onHourUpdate = ({ timeType, value }) => {
    if (value !== "" && value >= 0 && value <= 11) {
      let additionalHours = timeType === "PM" ? 12 : 0;
      const add = additionalHours + parseInt(value, 10);
      const endDate = new Date(data.endDate);
      endDate.setHours(add);
      onDateChange(new Date(endDate));
    }
  };
  const onMinuteUpdate = ({ value }) => {
    if (value !== "" && value >= 0 && value <= 59) {
      onDateChange(new Date(data.endDate).setMinutes(value));
    }
  };
  return /* @__PURE__ */ React.createElement(
    Formik,
    {
      initialValues: getInitialValues(),
      validationSchema: Yup.object().shape({
        hour: Yup.number().required("Cannot be empty").min(0, "Has to be between 0-11").max(11, "Has to be between 0-11"),
        minute: Yup.number().required("Cannot be empty").min(0, "Has to be between 0-59").max(59, "Has to be between 0-59")
      })
    },
    ({ errors, values, handleChange }) => /* @__PURE__ */ React.createElement("form", null, /* @__PURE__ */ React.createElement(
      DatePicker,
      {
        customInput: /* @__PURE__ */ React.createElement(CustomDatePicker, null),
        onChange: onDateChange,
        selected: new Date(data.endDate),
        minDate: new Date(minDate)
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-timer-content__time-input-container" }, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        onChange: (e) => {
          onHourUpdate({ timeType: values.timeType, value: e.target.value });
          handleChange(e);
        },
        name: "hour",
        label: "Hours",
        type: "number",
        placeholder: "HH",
        errorMsg: errors.hour,
        defaultValue: values.hour
      }
    ), /* @__PURE__ */ React.createElement(EditError, { message: errors.hour, touched: true }), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        onChange: (e) => {
          onMinuteUpdate({ value: e.target.value });
          handleChange(e);
        },
        label: "Minutes",
        name: "minute",
        type: "number",
        placeholder: "MM",
        errorMsg: errors.minute,
        defaultValue: values.minute
      }
    ), /* @__PURE__ */ React.createElement(EditError, { message: errors.minute, touched: true }), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        name: "timeType",
        defaultValue: values.timeType,
        onChange: (e) => {
          handleChange(e);
          const additionalHours = e.target.value === "PM" ? 12 : -12;
          const date = new Date(data.endDate);
          onDateChange(date.setHours(date.getHours() + additionalHours));
        },
        label: "Time",
        options: [{ name: "AM", value: "AM" }, { name: "PM", value: "PM" }]
      }
    )))
  );
};
