var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./filter-tags.sass";
import TintIcon from "../icon/icon";
import PropTypes from "prop-types";
import { faArrowsFromDottedLine, faArrowsToDottedLine } from "@fortawesome/pro-solid-svg-icons";
const TintFilterTags = ({ list = [], onTagSelect }) => {
  const tagListLimit = 10;
  const [selectedList, setSelectedList] = useState(list);
  const [isExpended, setExpended] = useState(false);
  const isLimitReached = selectedList.length > tagListLimit;
  useEffect(() => {
    setSelectedList(list);
  }, [list]);
  const onTagClick = (selectedTag) => {
    const mapSelectedList = selectedList.map((tag) => __spreadProps(__spreadValues({}, tag), {
      selected: tag.value === selectedTag.value && tag.name === selectedTag.name ? !tag.selected : tag.selected
    }));
    setSelectedList(mapSelectedList);
    onTagSelect && onTagSelect(selectedTag);
  };
  const renderToggleButtons = () => {
    return isExpended ? /* @__PURE__ */ React.createElement("li", { className: "tint-filter-tags__tag tint-filter-tags__tag--show-more", onClick: () => setExpended(!isExpended) }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faArrowsToDottedLine }), /* @__PURE__ */ React.createElement("span", null, "Show Less")) : /* @__PURE__ */ React.createElement("li", { className: "tint-filter-tags__tag tint-filter-tags__tag--show-more", onClick: () => setExpended(!isExpended) }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faArrowsFromDottedLine }), /* @__PURE__ */ React.createElement("span", null, "Show More"));
  };
  const renderElement = (tag, i) => {
    var _a;
    return /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `tint-filter-tags__tag ${tag.selected ? "tint-filter-tags__tag--selected" : ""}`,
        key: i,
        onClick: () => onTagClick(tag)
      },
      tag.icon ? /* @__PURE__ */ React.createElement(TintIcon, { color: (_a = tag == null ? void 0 : tag.style) == null ? void 0 : _a.color, icon: tag.icon }) : tag.svgIconSrc ? /* @__PURE__ */ React.createElement(
        TintIcon,
        {
          style: { filter: tag.selected ? "grayscale(1)" : "grayscale(1) invert(1)" },
          svgIconSrc: tag.svgIconSrc
        }
      ) : null,
      /* @__PURE__ */ React.createElement("span", null, tag.name)
    );
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-filter-tags" }, /* @__PURE__ */ React.createElement("ul", { className: "tint-filter-tags__list" }, selectedList.map(
    (tag, i) => isLimitReached && !isExpended ? i < tagListLimit ? renderElement(tag, i) : null : renderElement(tag, i)
  ), isLimitReached && renderToggleButtons()));
};
export default TintFilterTags;
TintFilterTags.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onTagSelect: PropTypes.func
};
