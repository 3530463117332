import React from "react";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceAcceptance = ({ item, analyticForm, t }) => {
  if (!item) return null;
  const { input: checkbox, a, label, ["#text"]: text } = FormBuilderElementsMappingService.getSingleElementHTMLTags(
    item
  );
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(checkbox.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(checkbox.name);
  const link = `<a href=${t(a.href)} target="_blank">${t(a == null ? void 0 : a.nodeValue)}</a>`;
  const textHtml = t(text.nodeValue, {
    link
  });
  return /* @__PURE__ */ React.createElement("div", { className: `${item.class}` }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: checkbox == null ? void 0 : checkbox.type,
      name: checkbox == null ? void 0 : checkbox.name,
      onFocus,
      onBlur,
      id: checkbox == null ? void 0 : checkbox.id,
      required: checkbox == null ? void 0 : checkbox.required,
      value: checkbox.value
    }
  ), /* @__PURE__ */ React.createElement("label", { htmlFor: label.for, dangerouslySetInnerHTML: { __html: textHtml } }));
};
FormBuilderFormInterfaceAcceptance.displayName = "FormBuilderFormInterfaceAcceptance";
export default FormBuilderFormInterfaceAcceptance;
