import React from "react";
import PropTypes from "prop-types";
import "./post-list.sass";
const PostList = ({ posts, children }) => {
  return /* @__PURE__ */ React.createElement("ul", { className: "post-list", "data-testid": "postList" }, posts.map((post) => /* @__PURE__ */ React.createElement("li", { key: post.id }, children(post))));
};
PostList.propTypes = {
  posts: PropTypes.array,
  children: PropTypes.func
};
export default PostList;
