var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./tint-editor-top-bar.sass";
import { faTableCells, faTableList } from "@fortawesome/free-solid-svg-icons";
import TintEditorTopNavigation from "../top-bar-navigation/tint-editor-top-navigation";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../components/interface/dropdown/button/dropdown-button";
import { faSortSizeDown } from "@fortawesome/pro-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { SearchInput, SearchInputType } from "../../../../../components/search-input/search-input";
import DropdownSortListRadioList, {
  getSortKey
} from "../../../../../components/interface/dropdown/lists/sort-list-radio/dropdown-sort-list-radio-list";
import TintEditorTopBarFilter from "../top-bar-filter/tint-editor-top-bar-filter";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../../components/hooks/use-debounce/use-debounce";
import { setRenderType, sortPostsBy } from "../../../actions/posts.actions";
import { useHistory } from "react-router-dom";
import { SortDropdownHeader } from "./sort-dropdown-header";
import TintIcon from "../../../../../components/interface/icon/icon";
import { PostRenderType } from "../../../reducers/posts.reducer";
import { isAllowed } from "../../../../../services/functions/features-checker/features-checker";
import { Config } from "../../../../../utils/config";
export const SortBy = {
  TIME_POSTED: "time-posted",
  LAST_MODERATED: "last_moderated_at",
  PINNED: "pinned",
  RELEVANCE: "score"
};
const sortOptions = [
  {
    name: "Time Posted",
    value: SortBy.TIME_POSTED,
    isActive: false
  },
  {
    name: "Last Moderated",
    value: SortBy.LAST_MODERATED,
    isActive: false
  },
  {
    name: "Pinned",
    value: SortBy.PINNED,
    isActive: false
  },
  {
    name: "Relevancy",
    value: SortBy.RELEVANCE,
    isActive: false
  }
];
const TintEditorTopBar = ({
  onToggleClick,
  isGlobalPageState,
  modalDOMDestination,
  onFilterModalOpen,
  isScrollingDown,
  isAnyFilterSelected,
  isSettingsSidebarExpanded
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [queryValue, setQueryValue] = useState();
  const [isFilterModalOpen, setFilterModalVisibility] = useState(false);
  const initialUpdate = useRef();
  const debouncedPhrase = useDebounce(queryValue, 500);
  const renderType = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.renderType);
  const session = useSelector((state) => state.session);
  const [sortByValue, setSortBy] = useState(
    sortOptions.find((e) => {
      var _a;
      return e.value === localStorage.getItem(getSortKey((_a = session == null ? void 0 : session.data) == null ? void 0 : _a.currentTeam));
    }) || sortOptions[0]
  );
  const [initialSortOption, setInitialSortOption] = useState(null);
  const featureList = session.data.features;
  const hasAccessToPostsReplies = isAllowed(featureList, [Config.features.boardsHorizontal]);
  useEffect(() => {
    if (debouncedPhrase !== void 0) {
      dispatch(sortPostsBy(debouncedPhrase, "q"));
    }
  }, [dispatch, debouncedPhrase]);
  const setPageState = (name) => {
    history.push({
      search: `?page=${name.toLowerCase()}`
    });
  };
  const onSearchInputChange = (value) => {
    var _a, _b, _c;
    setQueryValue(value);
    if (value !== "") {
      if (!initialSortOption) {
        const savedSortOptionValue = localStorage.getItem(getSortKey((_a = session == null ? void 0 : session.data) == null ? void 0 : _a.currentTeam));
        setInitialSortOption(sortOptions.find((e) => e.value === savedSortOptionValue) || sortOptions[0]);
      }
      const sortOption = sortOptions.find((e) => e.value === SortBy.RELEVANCE);
      setSortBy(sortOption);
      localStorage.setItem(getSortKey((_b = session == null ? void 0 : session.data) == null ? void 0 : _b.currentTeam), sortOption.value);
    } else {
      if (initialSortOption) {
        setSortBy(initialSortOption);
        localStorage.setItem(getSortKey((_c = session == null ? void 0 : session.data) == null ? void 0 : _c.currentTeam), initialSortOption.value);
      }
      setInitialSortOption(null);
    }
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: initialUpdate,
      className: `tint-editor-top-bar ${isFilterModalOpen ? "tint-editor-top-bar--modal-filter-open" : ""}`
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-top-bar__navigation" }, /* @__PURE__ */ React.createElement(
      TintEditorTopNavigation,
      {
        isGlobalPageState,
        onToggleClick,
        isSettingsSidebarExpanded,
        onFeedTabClick: (feed, name) => {
          dispatch(sortPostsBy(feed, "status"));
          setPageState(name);
        }
      }
    )),
    /* @__PURE__ */ React.createElement("div", { className: `tint-editor-top-bar__search ${isScrollingDown ? "tint-editor-top-bar__search--collapsed" : ""}` }, /* @__PURE__ */ React.createElement(SearchInput, { inputValue: queryValue, onInputChange: onSearchInputChange, types: [SearchInputType.ROUND] })),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-editor-top-bar__sort ${isScrollingDown ? isAnyFilterSelected && isScrollingDown ? "tint-editor-top-bar__sort--filter-selected" : "tint-editor-top-bar__sort--collapsed" : ""}`
      },
      /* @__PURE__ */ React.createElement(
        DropdownButton,
        {
          dropdownHeader: (props) => /* @__PURE__ */ React.createElement(SortDropdownHeader, __spreadProps(__spreadValues({}, props), { iconCenter: faSortSizeDown })),
          dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortListRadioList, __spreadValues({}, props)),
          list: sortOptions,
          currentItem: sortByValue,
          dropdownType: DropdownButtonType.RADIO_BUTTON,
          iconLeft: faSortSizeDown,
          iconRight: faCaretDown,
          placeholder: "Time Posted",
          buttonType: ButtonType.BUTTON_SORT_ACTIVE,
          onChangeValue: (sortBy) => {
            setSortBy(sortBy);
            dispatch(sortPostsBy(sortBy.value, "sortBy"));
          }
        }
      ),
      hasAccessToPostsReplies ? /* @__PURE__ */ React.createElement("div", { className: "tint-editor-top-bar__view-type" }, /* @__PURE__ */ React.createElement("div", { onClick: () => dispatch(setRenderType(PostRenderType.GRID)) }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faTableCells, color: renderType === PostRenderType.GRID ? "#212121" : "#838E94" })), /* @__PURE__ */ React.createElement("div", { onClick: () => dispatch(setRenderType(PostRenderType.LIST)) }, /* @__PURE__ */ React.createElement(TintIcon, { icon: faTableList, color: renderType === PostRenderType.LIST ? "#212121" : "#838E94" }))) : null,
      /* @__PURE__ */ React.createElement("div", { className: "tint-editor-top-bar__filter" }, /* @__PURE__ */ React.createElement(
        TintEditorTopBarFilter,
        {
          modalDOMDestination,
          onModalStateChange: (isOpen) => {
            setFilterModalVisibility(isOpen);
            onFilterModalOpen(isOpen);
          },
          isSettingsSidebarExpanded
        }
      ))
    )
  );
};
export default React.memo(TintEditorTopBar);
