var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./tint-editor-top-bar-filter.sass";
import { Button } from "../../../../../components/interface/button/button";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { DropdownIconHeader } from "../../../../../components/interface/dropdown/button/icon-header/dropdown-icon-header";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../components/interface/dropdown/button/dropdown-button";
import { faBarsFilter } from "@fortawesome/pro-regular-svg-icons";
import TintEditorTopBarFilterModal from "./filter-modal/tint-editor-top-bar-filter-modal";
import TintEditorEditTagsSidebar from "../sidebars/edit-tags/tint-editor-edit-tags.sidebar";
import TintSidebar from "../../../../../components/sidebar/sidebar";
import {
  getCurrentSelectedFilter,
  getMappedSavedFilters,
  isSocialFeedPostFiltered,
  isVisualSearchUsed
} from "../../../reducers/posts.reducer";
import { useSelector, useDispatch } from "react-redux";
import { chooseFilter, resetFilters, sortByVisualSearch } from "../../../actions/posts.actions";
import { useParams } from "react-router-dom";
import DropdownSearchList from "../../../../../components/interface/dropdown/lists/search-list/dropdown-search-list";
import { handleApiError } from "../../../../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import { patchTint } from "../../../actions/tint-editor.fat-actions";
import { TintEditorConfig } from "../../../utils/tint-editor.config";
const TintEditorTopBarFilter = ({ modalDOMDestination, onModalStateChange, isSettingsSidebarExpanded }) => {
  const dispatch = useDispatch();
  const { tintId } = useParams();
  const isGlobalPageState = tintId === TintEditorConfig.routePaths.global.tintId;
  const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
  const [isEditTagSidebarVisible, setEditTagSidebarVisibility] = useState(false);
  const [isEditTagSidebarDisabled, setEditTagSidebarDisabled] = useState(true);
  const savedFilters = useSelector(getMappedSavedFilters);
  const selectedFilters = useSelector((state) => state.tintEditorPosts.selectedFilters);
  const isSocialFeedPost = useSelector((state) => isSocialFeedPostFiltered(state.tintEditorPosts));
  const isVisualSearchFiltered = useSelector(isVisualSearchUsed);
  const currentSelectedFilter = useSelector((state) => getCurrentSelectedFilter(state.tintEditorPosts));
  const tagList = useSelector((state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.tintEditor) == null ? void 0 : _a.data) == null ? void 0 : _b.attributes) == null ? void 0 : _c.tags;
  });
  const [newTagList, setNewTagList] = useState(tagList);
  const isAnyFilterSelected = selectedFilters && Object.values(selectedFilters).some((el) => (el == null ? void 0 : el.length) > 0) || (selectedFilters == null ? void 0 : selectedFilters.published_at);
  const onModalFilterToggle = (shouldResetFilter) => {
    if (shouldResetFilter) onClearFilters();
    setFilterModalVisibility(!isFilterModalVisible);
    onModalStateChange && onModalStateChange(!isFilterModalVisible);
  };
  const onClearFilters = () => {
    dispatch(resetFilters());
    isVisualSearchFiltered && dispatch(sortByVisualSearch(false));
  };
  useEffect(() => {
    return () => {
      onClearFilters();
    };
  }, []);
  const onSubmit = () => {
    dispatch(patchTint({ tintId, tags: newTagList })).then(() => {
      setEditTagSidebarVisibility(false);
      toast.success("Your Tags has been updated.");
    }).catch((err) => handleApiError(err, toast.error));
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-editor-top-bar-filter ${isAnyFilterSelected ? "tint-editor-top-bar-filter--selected" : ""}
    ${isFilterModalVisible ? "tint-editor-top-bar-filter--modal-open" : ""}`
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-top-bar-filter__wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-top-bar-filter__filter-button" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "transparent",
        onClick: () => onModalFilterToggle(),
        text: isFilterModalVisible ? "Close" : "Filters",
        icon: faBarsFilter
      }
    )), !isGlobalPageState && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-editor-top-bar-filter__saved-filters ${isFilterModalVisible ? "tint-editor-top-bar-filter__saved-filters--active" : ""}`
      },
      /* @__PURE__ */ React.createElement(
        DropdownButton,
        {
          dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownIconHeader, __spreadProps(__spreadValues({}, props), { iconCenter: faBookmark })),
          dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSearchList, __spreadProps(__spreadValues({}, props), { isDefaultOption: false, shouldRerenderHeader: false })),
          list: savedFilters,
          rerenderHeader: false,
          dropdownType: DropdownButtonType.SEARCH_INPUT,
          buttonType: ButtonType.NEW_BUTTON_TRANSPARENT,
          onChangeValue: (selectedItem) => {
            dispatch(chooseFilter(selectedItem.filters, selectedItem.id));
          }
        }
      )
    )),
    (isAnyFilterSelected || isSocialFeedPost || isVisualSearchFiltered) && /* @__PURE__ */ React.createElement(Button, { type: "stroked-grey", onClick: onClearFilters, text: "Clear Filters", className: "button-clear-filters" }),
    isFilterModalVisible && /* @__PURE__ */ React.createElement(
      TintEditorTopBarFilterModal,
      {
        tintId,
        isGlobalPageState,
        currentFilter: currentSelectedFilter,
        selectedFilters,
        isModalOpen: isFilterModalVisible,
        onModalClose: onModalFilterToggle,
        modalDOMDestination,
        isSettingsSidebarExpanded,
        onEditTagsClick: () => {
          setEditTagSidebarVisibility(true);
        }
      }
    ),
    isEditTagSidebarVisible && /* @__PURE__ */ React.createElement(
      TintSidebar,
      {
        title: "Manage Tags",
        isOpen: isEditTagSidebarVisible,
        onClose: () => setEditTagSidebarVisibility(false),
        defaultFooterStyle: false,
        rightButtonText: "Save",
        onRightButtonClick: onSubmit,
        isRightButtonDisabled: isEditTagSidebarDisabled
      },
      /* @__PURE__ */ React.createElement("div", { className: "tint-editor-edit-tags-sidebar__content" }, /* @__PURE__ */ React.createElement(
        TintEditorEditTagsSidebar,
        {
          list: tagList,
          onChange: (tagList2) => {
            setNewTagList(tagList2);
            setEditTagSidebarDisabled(false);
          }
        }
      ))
    )
  );
};
export default TintEditorTopBarFilter;
