var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import DropdownInfiniteList from "../infinite-list/dropdown-infinite-list";
import { colors } from "../../../../../utils/variables/colors";
const DropdownInfiniteScrollList = ({
  props,
  filterValue,
  setIsFetching,
  tintId,
  getData,
  data,
  setList,
  setFilterValue,
  isAbleToLoad,
  isSeparateSettingsAvailable,
  pageSize
}) => {
  var _a;
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const onLoadData = () => {
    const newPageSize = currentPageSize + pageSize;
    setCurrentPageSize(newPageSize);
    getData({ currentPageSize: newPageSize, tintId, isSeparateSettingsAvailable, filterValue });
  };
  useEffect(() => {
    if (data == null ? void 0 : data.data) {
      setList();
    }
  }, [data == null ? void 0 : data.data]);
  useEffect(() => {
    if (filterValue !== void 0) {
      setIsFetching(true);
      setCurrentPageSize(pageSize);
      getData({ currentPageSize: pageSize, tintId, isSeparateSettingsAvailable, filterValue });
    }
  }, [filterValue, tintId, isSeparateSettingsAvailable, pageSize]);
  return /* @__PURE__ */ React.createElement(
    DropdownInfiniteList,
    __spreadProps(__spreadValues({}, props), {
      onLoadData,
      isAbleToLoad: ((_a = data == null ? void 0 : data.data) == null ? void 0 : _a.length) >= pageSize && isAbleToLoad,
      onInputChange: (e) => setFilterValue(e),
      fixedOption: true,
      lastChildColor: colors.info
    })
  );
};
export default DropdownInfiniteScrollList;
