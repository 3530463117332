var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { SocialFeedsSource, SocialFeedsTypes } from "../../../types/social-feeds";
const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "$"
};
const currencyFormatter = (value, options) => {
  if (typeof value !== "number") value = 0;
  options = __spreadValues(__spreadValues({}, defaultOptions), options);
  value = value.toFixed(options.significantDigits);
  const [currency, decimal] = value.split(".");
  return `${options.symbol}${currency.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator)}${options.decimalSeparator}${decimal}`;
};
const numberWithCommas = (x) => {
  return x == null ? void 0 : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const socialFeedSource = (socialFeed) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const isReviewsRatingsSocialFeed = ((_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.source) === SocialFeedsSource.PUBLIC_POST && ((_b = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _b.type) === SocialFeedsTypes.REVIEWS;
  const isFormSocialFeed = ((_c = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _c.source) === SocialFeedsSource.FORM_SUBMISSION && ((_d = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _d.type) === SocialFeedsTypes.FORM;
  const isInfluencerSocialFeed = ((_e = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _e.source) === SocialFeedsSource.INSTAGRAM_BUSINESS && ((_f = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _f.type) === SocialFeedsSource.INFLUENCER;
  return isReviewsRatingsSocialFeed ? SocialFeedsSource.REVIEWS_RATINGS : isFormSocialFeed ? SocialFeedsSource.FORMS : isInfluencerSocialFeed ? SocialFeedsSource.INFLUENCER : (_g = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _g.source;
};
const socialFeedToReadableString = (socialFeed) => {
  var _a;
  let title = "";
  if (!socialFeed) {
    title = "Deleted \u2022 This information is no longer available.";
  } else if (socialFeed.attributes.source === "custom") {
    title = "Custom Post";
  } else {
    if (socialFeed.attributes.status === "deleted") {
      title = "Deleted \u2022 This information is no longer available.";
    } else {
      let name = ((_a = socialFeed.attributes) == null ? void 0 : _a.name) ? `${socialFeed.attributes.name} \u2022` : "";
      title = `${name} ${isNaN(socialFeed.attributes.type) ? socialFeed.attributes.type.charAt(0).toUpperCase() + socialFeed.attributes.type.slice(1) : socialFeed.attributes.type ? socialFeed.attributes.type : socialFeed.attributes.source}`;
      title = title.replace("review_trackers", "Review Trackers");
      title = title.replace("spark", "Cisco Webex");
      title = title.replace("form_submission", "Form Submission");
      title = title.replace("externally_sourced_posts", "Browser Extensions");
    }
  }
  return title;
};
const calculateGrowth = (first, last) => {
  if (first === 0 && last !== 0) {
    return last;
  }
  if (last === 0 && first === 0) {
    return 0;
  }
  if (last === 0) {
    return -100;
  }
  if (last - first < 0) {
    return (100 - last * 100 / first) * -1;
  } else {
    return last * 100 / first - 100;
  }
};
const numberWithK = (count) => {
  if (count < 1e3) {
    return count;
  }
  if (count < 1e6) {
    return `${Math.round((count / 1e3 + Number.EPSILON) * 100) / 100}k`;
  }
  return `${Math.round((count / 1e6 + Number.EPSILON) * 100) / 100}m`;
};
const formatNumberToLocaleString = (rating) => {
  return rating ? rating.toLocaleString("en-US") : 0;
};
export const InsightsUtil = {
  numberWithK,
  numberWithCommas,
  calculateGrowth,
  socialFeedSource,
  socialFeedToReadableString,
  currencyFormatter,
  formatNumberToLocaleString
};
