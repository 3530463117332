import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_FEATURES: createRoutine(createPrefixedActionName("PLAN_ADDONS", "GET_FEATURES")),
  SELECT_FEATURE: createPrefixedActionName("PLAN_ADDONS", "SELECT_FEATURE"),
  OPEN_MODAL: createPrefixedActionName("PLAN_ADDONS", "OPEN_MODAL"),
  CLOSE_MODAL: createPrefixedActionName("PLAN_ADDONS", "CLOSE_MODAL")
};
export const closePlanAddonModal = () => ({
  type: ACTIONS.CLOSE_MODAL
});
export const openPlanAddonModal = (url) => ({
  type: ACTIONS.OPEN_MODAL,
  payload: { video_url: url, isOpened: true }
});
export const selectFeature = (type) => ({
  type: ACTIONS.SELECT_FEATURE,
  payload: type
});
export const getFeaturesStart = () => ({
  type: ACTIONS.GET_FEATURES.REQUEST
});
export const getFeaturesSuccess = (data) => ({
  type: ACTIONS.GET_FEATURES.SUCCESS,
  payload: data
});
export const getFeaturesError = (err) => ({
  type: ACTIONS.GET_FEATURES.FAILURE,
  payload: err
});
