export const getCleanFormElementId = (formElementId) => {
  const regex = /^([a-zA-Z0-9_]+)(?:\[[a-zA-Z0-9_]+\])?(?:\[\])?$/;
  const match = formElementId.match(regex);
  const cleanSlugIndex = 1;
  return (match == null ? void 0 : match[cleanSlugIndex]) || "";
};
export const getSlugFromId = (id) => {
  const parts = id.split("_");
  parts.pop();
  return parts.join("_");
};
export const formatCSSForPreview = (styles) => {
  const newStyles = {};
  for (const className in styles) {
    if (className.includes("dir='rtl'") || className.includes(".div-form")) {
      newStyles[className] = styles[className];
    } else if (className.includes("[id=")) {
      newStyles[`.div-form div ${className}`] = styles[className];
    } else if (className.includes(",")) {
      const formattedClassName = className.split(", ").map((className2) => {
        return `.div-form ${className2}`;
      }).join(", ");
      newStyles[formattedClassName] = styles[className];
    } else {
      newStyles[`.div-form ${className}`] = styles[className];
    }
  }
  return newStyles;
};
