export const features = {
  all: "all",
  topClickedPosts: "insights_top_clicked_posts",
  mediaComposer: "assets_composer",
  assetsTransformations: "assets_transformations",
  assetsWorkflows: "assets_workflows",
  assetsMetadataSentiment: "assets_metadata_sentiment",
  assetsMetadataInsights: "assets_metadata_insights",
  teamsSaml: "teams_saml",
  marketingIntegrations: "marketing_integrations",
  globalModeration: "globalModeration",
  ecommerce: "ecommerce",
  ecommerceWIP: "ecommerce_wip",
  socialFeeds: "social_feeds",
  displayMix: "displayMix",
  mixPaid: "mixPaid",
  chromecast: "chromecast",
  newTintMix: "new_tint_mix",
  gdprCompliance: "experiences_cookie_consent",
  captcha: "experiences_captcha",
  experiences: "experiences",
  experiencesSeo: "experiences_seo",
  experiencesAdvancedOptions: "experiences_advanced_options",
  experiencesFieldsHidden: "experiences_fields_hidden",
  disableLaunchpad: "disable_launchpad",
  billingHideUpgradePrompts: "billing_hide_upgrade_prompts",
  selfServiceOnboarding: "self_service_onboarding",
  selfServiceInfluencers: "self_service_influencers",
  launchpadExplore: "launchpad_explore",
  insights: "insights",
  insightsPerformance: "insights_performance",
  insightsCommerce: "insights_commerce",
  insightsSocial: "insights_social",
  zapier: "zapier",
  google_drive: "google_drive",
  insightsHashtags: "insights_hashtags",
  cta: "cta",
  autoModerate: "automoderate",
  userRights: "user_rights",
  analytics: "analytics",
  api: "api",
  tintsSocialFeedsInstagramSocialListening: "tints_social_feeds_instagram_social_listening",
  postsMetadataLanguage: "posts_metadata_language",
  socialFeedsInstagramBusiness: "tints_social_feeds_instagram_business",
  socialFeedsThreads: "tints_social_feeds_threads",
  socialFeedsThreadsMentioned: "tints_social_feeds_threads_mentioned",
  socialFeedsThreadsKeyword: "tints_social_feeds_threads_keyword",
  socialFeedsCreatorIq: "tints_social_feeds_creator_iq",
  socialFeedsBazaarvoice: "integrations_bazaarvoice",
  socialFeedsPowerReviews: "integrations_power_reviews",
  socialFeedsFacebook: "tints_social_feeds_facebook",
  socialFeedsRSS: "tints_social_feeds_rss",
  socialFeedsPublicPost: "tints_social_feeds_public_post",
  socialFeedsTwitter: "tints_social_feeds_twitter",
  socialFeedsSMS: "tints_social_feeds_sms",
  socialFeedsEmail: "tints_social_feeds_email",
  socialFeedLinkedIn: "tints_social_feeds_linkedin",
  socialFeedsPinterest: "tints_social_feeds_pinterest",
  socialFeedsReviewTrackers: "tints_social_feeds_review_trackers",
  socialFeedsTumblr: "tints_social_feeds_tumblr",
  socialFeedFlickr: "tints_social_feeds_flickr",
  socialFeedGoogleNews: "tints_social_feeds_google_news",
  socialFeedSlack: "tints_social_feeds_slack",
  socialFeedsHootsuite: "tints_social_feeds_hootsuite",
  socialFeedsWebex: "tints_social_feeds_webex",
  socialFeedsReviews: "tints_social_feeds_reviews",
  socialFeedsCustom: "tints_social_feeds_custom",
  socialFeedsYouTube: "tints_social_feeds_youtube",
  socialFeedsTikTok: "tints_social_feeds_tiktok",
  socialFeedsInfluencer: "tints_social_feeds_influencer",
  newVisualSimilarity: "new_visual_similarity",
  hue: "hue",
  emailTemplatesPersonalization: "email_templates",
  postsMetaDataTags: "posts_metadata_tags",
  selfServiceFeatureDiscovery: "self_service_feature_discovery",
  zenDeskWidget: "zendesk_widget",
  stripeBilling: "stripe_billing",
  onboardingTints: "onboarding_tints",
  onboardingSampleBoard: "onboarding_sample_board",
  customCSS: "customCSS",
  tintProjection: "tintProjection",
  salesforce: "salesforce",
  tagging: "tagging",
  scheduler: "publishing",
  engineeringClientDevelopment: "engineering_client_development",
  publishingYoutube: "publishing_youtube",
  publishingThreads: "publishing_threads",
  forms: "forms",
  formsMapping: "forms_mapping",
  formsAdvanced: "forms_advanced",
  formsInsights: "forms_insights",
  formsLocales: "forms_locales",
  formsTracking: "forms_tracking",
  formsEmbed: "forms_embed",
  formsAllFileTypes: "forms_all_file_types",
  formsCaptcha: "forms_captcha",
  engineeringForms: "engineering_forms",
  personalizationsArchived: "personalizations_archived",
  reviews: "reviews",
  audienceBuilder: "audience_builder",
  audienceBadges: "audience_badges",
  domainsEmail: "domains_email",
  emailTemplatesBuilder: "email_templates_builder",
  boardsHorizontal: "boards_horizontal",
  postsReplies: "posts_replies",
  postsStatusReasons: "posts_status_reasons",
  insightsSocialTiktok: "insights_social_tiktok",
  integrationsPowerReviews: "integrations_power_reviews",
  integrationsBazaarvoice: "integrations_bazaarvoice",
  userRightsMessageAiSuggestion: "user_right_message_ai_suggestions",
  zinreloNavigation: "zinrelo_navigation"
};
