var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import "./link.sass";
import { Link } from "react-router-dom";
export const TintLinkColors = {
  BLUE: "blue",
  GREY: "grey"
};
export const TintLinkSize = {
  FULL_WIDTH: "full-width",
  DEFAULT: "default"
};
export const TintLink = (props) => {
  return /* @__PURE__ */ React.createElement(
    Link,
    __spreadProps(__spreadValues({
      className: `tint-link tint-link--${props.type || "none"} tint-link--${props.size || "default"}`,
      target: (props == null ? void 0 : props.to) && "_blank",
      rel: "noopener noreferrer"
    }, props), {
      to: { pathname: (props == null ? void 0 : props.to) || void 0 }
    })
  );
};
