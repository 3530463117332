import React, { Component } from "react";
import PropTypes from "prop-types";
import ColorParser from "../../../../../services/color-parser/color-parser";
import "./customizable-text-preview.sass";
import { Config } from "../../../../../utils/config";
export default class CustomizableTextPreview extends Component {
  renderTextElement() {
    return Config.regExp.htmlTag.test(this.props.text) ? /* @__PURE__ */ React.createElement(
      "div",
      {
        dangerouslySetInnerHTML: {
          __html: this.props.text
        }
      }
    ) : this.renderOldContent();
  }
  renderOldContent() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "customizable-text-preview",
        style: {
          fontFamily: typeof this.props.fontFamily === "object" ? JSON.stringify(this.props.fontFamily.value) : this.props.fontFamily,
          fontStyle: this.props.fontStyle,
          textDecoration: this.props.textDecoration,
          color: ColorParser.getRgbaColorString(this.props.color),
          fontSize: this.props.fontSize,
          fontWeight: this.props.fontWeight,
          backgroundColor: ColorParser.getRgbaColorString(this.props.backgroundColor)
        },
        dangerouslySetInnerHTML: {
          __html: this.props.text && this.props.text.replace(/(?:\r\n|\r|\n)/g, "<br>")
        }
      }
    );
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-customizable-text tint-customizable-text--preview",
        style: {
          direction: this.props.textDirection,
          textAlign: this.props.textAlign,
          top: this.props.topPosition,
          width: this.props.width
        }
      },
      this.props.urlLink ? /* @__PURE__ */ React.createElement("a", { href: this.props.urlLink, rel: "noopener noreferrer", target: "_blank" }, this.renderTextElement()) : this.renderTextElement()
    );
  }
}
CustomizableTextPreview.propTypes = {
  text: PropTypes.string,
  textDecoration: PropTypes.string,
  urlLink: PropTypes.string,
  fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fontStyle: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  topPosition: PropTypes.number,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  textDirection: PropTypes.string
};
