var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../../../hooks/use-window-dimensions";
import { Config } from "../../../../../utils/config";
import TintIcon from "../../../../../components/interface/icon/icon";
import { DropdownHeader } from "../../../../../components/interface/dropdown/button/header/dropdown-header";
export const SortDropdownHeader = (_a) => {
  var _b = _a, { iconCenter } = _b, props = __objRest(_b, ["iconCenter"]);
  const { width } = useWindowDimensions();
  const [isSmallScreen, setIsSmallScreen] = useState(width < Config.screenResolution.xl);
  useEffect(() => {
    setIsSmallScreen(width < Config.screenResolution.xxl);
  }, [width]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, isSmallScreen ? /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-icon-header" }, iconCenter && /* @__PURE__ */ React.createElement(TintIcon, { icon: iconCenter, "data-testid": "tint-dropdown-icon-header" })) : /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)));
};
