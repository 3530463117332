var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomizableText, CustomizableTextPreview } from "../../../feature/experience/components/experience/";
import {
  addSection,
  applyDraftExperience,
  deleteSection,
  saveCustomizableTextDataDraft,
  saveDataContentDraft,
  saveDataStylesDraft,
  saveDataAttributesDraft,
  deleteMultiColumnSection,
  saveMultiColumnDataContentDraft,
  addMultiColumnSection,
  saveCustomizableMultiColumnTextDataDraft
} from "../../../feature/experience/actions/experience/experience.fat-actions";
import { discardDraftData } from "../../../feature/experience/actions/experience/experience.actions";
import { ModalHoc } from "../modal/modal.hoc";
import { getDefaultLanguage, selectExperienceType } from "../../../feature/experience/reducers/experience/experience";
import AddBlock from "../../../feature/experience/components/experience/add-block/add-block";
import { isSectionDeletable, isReorderAble } from "../../../feature/experience/utils/experience-helper";
import EditArea from "../../../feature/experience/components/experience/edit-area/edit-area";
import {
  saveNewTranslationDraft,
  saveTranslationDraft,
  standardizeTranslation
} from "../../../feature/experience/actions/experience/experience-translations.actions";
import { getCustomFonts } from "../../../feature/account-settings/actions/custom-fonts/custom-fonts.fat-actions";
import {
  getCustomFontList,
  isCustomFontsPending
} from "../../../feature/account-settings/reducers/custom-fonts/custom-fonts.reducer";
import { BackgroundStyleService } from "./background-styles.service";
import { AddFontLinkHoc } from "../add-font-link/add-font-link.hoc";
import { ExperienceConfig } from "../../../feature/experience/utils/experience.config";
import { Config } from "../../../utils/config";
export const PageSectionHoc = (PassedComponent) => {
  class PageSection extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "deleteSection", () => {
        typeof this.props.parentSectionIndex !== "number" ? this.props.deleteSection(this.props.index) : this.props.deleteMultiColumnSection(this.props.index, this.props.parentSectionIndex);
      });
      __publicField(this, "getTranslation", (key) => {
        if (this.props.translations && this.props.translations[this.props.currentLanguage][key] !== void 0) {
          return this.props.translations[this.props.currentLanguage][key];
        }
        return key;
      });
      __publicField(this, "addSection", (pageSectionType, data) => {
        this.props.addSection(pageSectionType, this.props.index, void 0, data);
      });
      __publicField(this, "pageSectionToTitle", (pageSection) => {
        const _pageSection = pageSection;
        switch (_pageSection.toLowerCase()) {
          case "tint":
            return "TINT Board";
          default:
            return pageSection;
        }
      });
      __publicField(this, "renderEditArea", () => {
        return !this.props.isPreviewMode ? /* @__PURE__ */ React.createElement(
          EditArea,
          {
            title: this.pageSectionToTitle(this.props.pageSection),
            openModal: this.props.openModal,
            openOrderModal: typeof this.props.parentSectionIndex !== "number" ? this.props.openOrderModal : () => this.props.openOrderModalForMultiColumn({
              parentIndex: this.props.parentSectionIndex,
              column: this.props.experience.attributes.data.draft[this.props.parentSectionIndex].data.content.sections[this.props.index].column
            }),
            deleteSection: this.deleteSection,
            isReorderAble: typeof this.props.parentSectionIndex === "number" || isReorderAble(this.props.pageSection, this.props.experienceType, this.props.experience, this.props.index),
            isDeletable: typeof this.props.parentSectionIndex === "number" || isSectionDeletable(
              this.props.pageSection,
              this.props.experienceType,
              this.props.experience,
              this.props.index
            )
          }
        ) : null;
      });
      __publicField(this, "renderHoverLine", () => {
        return /* @__PURE__ */ React.createElement("div", { className: "tint-page-section__blue-border" });
      });
      __publicField(this, "renderAddAreaBlock", () => {
        return this.props.isPreviewMode ? null : /* @__PURE__ */ React.createElement(
          AddBlock,
          {
            addSection: this.props.parentSectionIndex === void 0 ? this.addSection : this.props.addSectionForMultiColumn,
            pageSectionIndex: this.props.index,
            isForMultiColumn: this.props.parentSectionIndex !== void 0
          }
        );
      });
      __publicField(this, "renderBackgroundImageStyles", () => {
        const styles = BackgroundStyleService.getBackgroundStyles({
          content: this.props.data.content,
          pageWidth: this.props.pageWidth,
          index: this.props.index,
          parentSectionIndex: this.props.parentSectionIndex
        });
        return /* @__PURE__ */ React.createElement("style", { dangerouslySetInnerHTML: { __html: styles } });
      });
      __publicField(this, "renderGenericComponents", () => {
        return /* @__PURE__ */ React.createElement("div", { className: "generic-components", dir: "auto" }, this.renderHoverLine(), this.renderAddAreaBlock(), !this.props.isFontFamilyPending && this.renderCustomizableText(), this.renderEditArea(), this.renderBackgroundImageStyles());
      });
      this.state = {
        customFonts: this.props.customFonts || []
      };
    }
    onDataSave() {
      return this.props.applyDraftExperience();
    }
    onContentUpdate(data) {
      typeof this.props.parentSectionIndex !== "number" ? this.props.saveDataContentDraft(data, this.props.index) : this.props.saveMultiColumnDataContentDraft(data, this.props.index, this.props.parentSectionIndex);
    }
    onStylesUpdate(data) {
      typeof this.props.parentSectionIndex !== "number" ? this.props.saveDataStylesDraft(data, this.props.index) : this.props.saveDataStylesDraft(data, this.props.index, this.props.parentSectionIndex);
    }
    onNewTranslationUpdate(key, value) {
      this.props.saveNewTranslationDraft(key, value);
    }
    onTranslationUpdate(key, value) {
      this.props.saveTranslationDraft(key, value);
    }
    addMultiColumnSection(section, index, newSectionList) {
      this.props.addMultiColumnSection(section, index, newSectionList);
    }
    onCustomizableTextUpdate(customizableHeader, customizableHeaderIndex) {
      if (typeof this.props.parentSectionIndex === "number") {
        this.props.saveCustomizableMultiColumnTextDataDraft(
          customizableHeader,
          customizableHeaderIndex,
          this.props.index,
          this.props.parentSectionIndex
        );
      } else {
        this.props.saveCustomizableTextDataDraft(customizableHeader, customizableHeaderIndex, this.props.index);
      }
    }
    renderCustomizableText() {
      const lineHeight = 4;
      if (!this.props.data.content.customizableHeaders) {
        return null;
      }
      const customizableHeaders = this.props.data.content.customizableHeaders;
      return customizableHeaders.map((e, i) => {
        return this.props.isPreviewMode ? /* @__PURE__ */ React.createElement(
          CustomizableTextPreview,
          {
            textDirection: this.props.textDirection,
            key: i,
            textDecoration: e.textDecoration,
            fontStyle: e.fontStyle,
            fontWeight: e.fontWeight,
            fontSize: e.fontSize,
            lineHeight: e.fontSize + lineHeight,
            fontFamily: e.fontFamily,
            width: e.width,
            topPosition: e.topPosition,
            textAlign: e.textAlign,
            color: e.color,
            backgroundColor: e.backgroundColor,
            text: this.getTranslation(e.text),
            urlLink: e.urlLink,
            baseClass: this.getClassBasedOnWidth()
          }
        ) : /* @__PURE__ */ React.createElement(
          CustomizableText,
          {
            textDirection: this.props.textDirection,
            key: i + this.props.currentLanguage,
            textDecoration: e.textDecoration,
            fontStyle: e.fontStyle,
            fontWeight: e.fontWeight,
            fontSize: e.fontSize,
            lineHeight: e.fontSize + lineHeight,
            fontFamily: e.fontFamily,
            width: e.width,
            textAlign: e.textAlign,
            topPosition: e.topPosition,
            color: e.color,
            backgroundColor: e.backgroundColor,
            saveData: this.onDataSave.bind(this),
            pageWidth: this.props.pageWidth,
            onDataUpdate: (customizableHeader) => {
              this.onCustomizableTextUpdate(customizableHeader, i);
            },
            onTextUpdate: (text) => {
              this.props.saveTranslationDraft(e.text, text);
            },
            defaultValue: this.getTranslation(e.text),
            urlLink: e.urlLink,
            baseClass: this.getClassBasedOnWidth()
          }
        );
      });
    }
    onCloseModal() {
      this.props.discardDraftData();
      this.props.closeModal();
    }
    getSectionClass() {
      return typeof this.props.parentSectionIndex !== "number" ? `tint-page-section-index-${this.props.index}` : `tint-page-section-index-${this.props.parentSectionIndex}-${this.props.index}`;
    }
    getMaxImageSizePlanLimit() {
      var _a, _b;
      return ((_b = (_a = this.props.experience) == null ? void 0 : _a.attributes) == null ? void 0 : _b.maximum_image_size) || Config.maxFileSize;
    }
    getMaxVideoSizePlanLimit() {
      var _a, _b;
      return ((_b = (_a = this.props.experience) == null ? void 0 : _a.attributes) == null ? void 0 : _b.maximum_video_size) || Config.maxVideoFileSize;
    }
    getClassBasedOnWidth() {
      switch (this.props.pageWidth) {
        case ExperienceConfig.pageSize.desktop:
          return "xl";
        case ExperienceConfig.pageSize.tablet:
          return "lg";
        case ExperienceConfig.pageSize.mobile:
          return "xs";
        default:
          return "";
      }
    }
    render() {
      return /* @__PURE__ */ React.createElement("div", { dir: this.props.textDirection }, /* @__PURE__ */ React.createElement(
        PassedComponent,
        __spreadProps(__spreadValues({}, this.props), {
          closeModal: this.onCloseModal.bind(this),
          renderGenericComponents: this.renderGenericComponents,
          onContentUpdate: this.onContentUpdate.bind(this),
          onStylesUpdate: this.onStylesUpdate.bind(this),
          onDataSave: this.onDataSave.bind(this),
          getTranslation: this.getTranslation,
          onTranslationUpdate: this.onTranslationUpdate.bind(this),
          onNewTranslationUpdate: this.onNewTranslationUpdate.bind(this),
          getSectionClass: this.getSectionClass.bind(this),
          getMaxImageSizePlanLimit: this.getMaxImageSizePlanLimit.bind(this),
          getMaxVideoSizePlanLimit: this.getMaxVideoSizePlanLimit.bind(this),
          addSection: this.addSection.bind(this),
          baseClass: this.getClassBasedOnWidth()
        })
      ));
    }
  }
  const mapStateToProps = (state) => ({
    textDirection: state.languages.currentLanguage && state.languages.currentLanguage.direction,
    fontFamily: getCustomFontList(state.customFonts),
    isFontFamilyPending: isCustomFontsPending(state.customFonts),
    isPreviewMode: state.experienceBuilder.isPreviewMode,
    pageWidth: state.experienceBuilder.width,
    experience: state.experience.data,
    draftExperience: state.experience.dataDraft,
    currentLanguage: state.languages.currentLanguage && state.languages.currentLanguage.value || getDefaultLanguage(state.experience.data, "draft"),
    experienceType: selectExperienceType(state.experience)
  });
  const mapDispatchToProps = (dispatch) => ({
    standardizeTranslation: (key, value) => dispatch(standardizeTranslation(key, value)),
    applyDraftExperience: () => dispatch(applyDraftExperience()),
    saveTranslationDraft: (key, value) => dispatch(saveTranslationDraft(key, value)),
    saveNewTranslationDraft: (key, value) => dispatch(saveNewTranslationDraft(key, value)),
    saveDataContentDraft: (content, pageSectionIndex) => dispatch(saveDataContentDraft(content, pageSectionIndex)),
    saveMultiColumnDataContentDraft: (content, pageSectionIndex, parentSectionIndex) => dispatch(saveMultiColumnDataContentDraft(content, pageSectionIndex, parentSectionIndex)),
    saveDataStylesDraft: (content, pageSectionIndex, parentSectionIndex) => dispatch(saveDataStylesDraft(content, pageSectionIndex, parentSectionIndex)),
    saveCustomizableTextDataDraft: (customizableHeader, customizableHeaderIndex, pageSectionIndex) => dispatch(saveCustomizableTextDataDraft(customizableHeader, customizableHeaderIndex, pageSectionIndex)),
    saveCustomizableMultiColumnTextDataDraft: (customizableHeader, customizableHeaderIndex, pageSectionIndex, parentSectionIndex) => dispatch(
      saveCustomizableMultiColumnTextDataDraft(
        customizableHeader,
        customizableHeaderIndex,
        pageSectionIndex,
        parentSectionIndex
      )
    ),
    saveDataAttributesDraft: (attributes) => dispatch(saveDataAttributesDraft(attributes)),
    discardDraftData: () => dispatch(discardDraftData()),
    deleteSection: (pageSectionIndex) => dispatch(deleteSection(pageSectionIndex)),
    deleteMultiColumnSection: (pageSectionIndex, parentSectionIndex) => dispatch(deleteMultiColumnSection(pageSectionIndex, parentSectionIndex)),
    addSection: (pageSectionType, pageSectionIndex, attributes, data) => dispatch(addSection(pageSectionType, pageSectionIndex, attributes, data)),
    addMultiColumnSection: (section, index, newSectionList) => addMultiColumnSection(section, index, newSectionList),
    getCustomFonts: (fontFamily) => dispatch(getCustomFonts(fontFamily))
  });
  return connect(mapStateToProps, mapDispatchToProps)(ModalHoc(AddFontLinkHoc(PageSection)));
};
