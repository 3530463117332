import React from "react";
import "./tag.sass";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import TintButtonIcon, { TintButtonIconTypes } from "../button-icon/button-icon";
export const Tag = ({ tag, isSelected, onClick, onRemove }) => {
  return /* @__PURE__ */ React.createElement("span", { className: `tint-tag ${isSelected ? "tint-tag--selected" : ""}`, onClick }, tag, /* @__PURE__ */ React.createElement(
    TintButtonIcon,
    {
      type: TintButtonIconTypes.NONE,
      icon: faTimes,
      fontSize: "16px",
      onClick: (e) => {
        e.stopPropagation();
        onRemove();
      }
    }
  ));
};
