var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { useEffect, useRef, useState } from "react";
const formEvents = {
  FORM_LOADED: "form_loaded",
  FORM_VIEWED: "form_viewed",
  FORM_SUBMITTED: "form_submitted",
  FORM_INPUT_FOCUSED: "form_input_focused",
  FORM_INPUT_BLURRED: "form_input_blurred"
};
const useFormAnalytics = ({ formName, formId, teamId, tintAnonymousUid, tracker, noTrack, featureFlag }) => {
  const trackingEnabled = !noTrack && featureFlag;
  if (!tracker || !trackingEnabled) return;
  const [events, setEvents] = useState({});
  const formLoadTime = useRef(Date.now());
  const [userMetadata, setUserMetadata] = useState({});
  useEffect(() => {
    const anonymousId = tintAnonymousUid || getAnonymousId();
    const locale = navigator.language;
    const browserInfo = `${navigator.userAgent} - ${navigator.userAgentData}`;
    setUserMetadata({ anonymousId, locale, browserInfo });
    const devise_category = /Mobile/i.test(navigator.userAgent) ? "mobile" : "desktop";
    trackEvent({ eventName: formEvents.FORM_LOADED });
    tracker == null ? void 0 : tracker.recordTraits({
      embed_url: window.location.href,
      team_id: teamId,
      form_id: formId,
      locale,
      browserInfo,
      devise_category
    });
    tracker == null ? void 0 : tracker.track(formEvents.FORM_LOADED, { form_id: formId });
  }, []);
  const trackEvent = ({ eventName, inputName, additionalData }) => {
    const eventTime = Date.now();
    const event = __spreadValues({
      eventTime
    }, additionalData);
    setEvents((prevEvents) => {
      const updatedEvents = __spreadValues({}, prevEvents);
      if (!updatedEvents[inputName || formName]) {
        updatedEvents[inputName || formName] = {};
      }
      if (!updatedEvents[inputName || formName][eventName]) {
        updatedEvents[inputName || formName][eventName] = [];
      }
      updatedEvents[inputName || formName][eventName].push(event);
      return updatedEvents;
    });
  };
  const trackFormViewed = () => {
    trackEvent({ eventName: formEvents.FORM_VIEWED });
    return tracker == null ? void 0 : tracker.track(formEvents.FORM_VIEWED);
  };
  const trackFormSubmitted = () => {
    const submissionTime = Date.now();
    const formFillDuration = submissionTime - formLoadTime.current;
    trackEvent({ eventName: formEvents.FORM_SUBMITTED, form_fill_duration: formFillDuration });
    return tracker == null ? void 0 : tracker.track(formEvents.FORM_SUBMITTED, { form_fill_duration: formFillDuration });
  };
  const trackInputFocused = (inputName) => {
    trackEvent({ eventName: formEvents.FORM_INPUT_FOCUSED, inputName });
    return tracker == null ? void 0 : tracker.track(formEvents.FORM_INPUT_FOCUSED, { inputName });
  };
  const trackInputBlurred = (inputName) => {
    trackEvent({ eventName: formEvents.FORM_INPUT_BLURRED, inputName });
    return tracker == null ? void 0 : tracker.track(formEvents.FORM_INPUT_BLURRED, { inputName });
  };
  return {
    trackFormViewed,
    trackInputFocused,
    trackInputBlurred,
    trackFormSubmitted,
    data: {
      form: {
        name: formName
      },
      client: userMetadata,
      events
    }
  };
};
export default useFormAnalytics;
const getAnonymousId = () => {
  const anonymousIdKey = "anonymousId";
  let anonymousId = localStorage.getItem(anonymousIdKey);
  if (!anonymousId) {
    anonymousId = `anon_${Math.random().toString(36).substr(2, 9)}`;
    localStorage.setItem(anonymousIdKey, anonymousId);
  }
  return anonymousId;
};
