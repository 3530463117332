import React from "react";
import { Button } from "../../../../../../../../components/interface/button/button";
import "./vote-post-item.sass";
import PropTypes from "prop-types";
import useImageValidation from "../../../../../../../../components/hooks/use-image-validation/use-image-validation";
import { Config } from "../../../../../../../../utils/config";
import { pollImageType } from "../../../../../../../../model/poll-image-type.model";
const VotePostItem = ({
  styles,
  imageUrl,
  url,
  username,
  type,
  isClicked,
  onVoteClick,
  onUnVoteClick,
  buttonText,
  buttonVotedText,
  isPreviewMode,
  isVoteAllowed,
  imageType
}) => {
  const { newImageUrl, isImageValid } = useImageValidation(Config.getFileStackCdnUrl(`b64://${window.btoa(imageUrl)}`));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: !isPreviewMode || isVoteAllowed || isClicked ? "vote-post-item" : "vote-post-item vote-post-item--vote-blocked"
    },
    /* @__PURE__ */ React.createElement("div", { className: "vote-post-item__overlay", style: { background: styles.overlayBackgroundColor } }),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `vote-post-item__thumb ${!isImageValid ? "vote-post-item__thumb--fail" : ""} ${imageType !== void 0 && imageType === pollImageType.FIT ? "vote-post-item__thumb--fit" : ""}`,
        style: { backgroundImage: `url("${newImageUrl}")` }
      }
    ),
    /* @__PURE__ */ React.createElement("div", { className: "vote-post-item__details" }, /* @__PURE__ */ React.createElement("div", { className: "social-media" }, /* @__PURE__ */ React.createElement("a", { rel: "noopener noreferrer", href: url, target: "_blank", style: { color: styles.overlayTextColor } }, /* @__PURE__ */ React.createElement("span", { className: `icon fab fa-${type}`, style: { color: styles.overlayTextColor } }), username ? /* @__PURE__ */ React.createElement("span", { style: { color: styles.overlayTextColor } }, "@", username) : null)), isPreviewMode ? /* @__PURE__ */ React.createElement(React.Fragment, null, !isClicked ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          onVoteClick();
        },
        type: "white",
        text: buttonText,
        size: "long",
        styles: {
          background: styles.buttonBackgroundColor,
          borderColor: styles.buttonBackgroundColor,
          color: styles.buttonTextColor
        }
      }
    )) : /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "transparent",
        text: buttonVotedText || "Voted",
        size: "long",
        icon: "fas fa-check",
        onClick: () => {
          onUnVoteClick();
        },
        styles: {
          background: styles.buttonBackgroundColor,
          borderColor: styles.buttonBackgroundColor,
          color: styles.buttonTextColor
        }
      }
    )) : null)
  ));
};
VotePostItem.propTypes = {
  styles: PropTypes.any,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  username: PropTypes.string,
  type: PropTypes.string,
  isClicked: PropTypes.bool,
  onVoteClick: PropTypes.func,
  onUnVoteClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonVotedText: PropTypes.string,
  imageType: PropTypes.string
};
export default VotePostItem;
