import { Config } from "../utils/config";
import { GuardNames } from "./guards-list";
export const GuardsRules = {
  [GuardNames.CTA]: {
    features: [Config.features.cta]
  },
  [GuardNames.ALL]: {
    features: [Config.features.all]
  },
  [GuardNames.SOCIAL_FEEDS_YOUTUBE]: {
    features: [Config.features.socialFeedsYouTube]
  },
  [GuardNames.SOCIAL_FEEDS_TIKTOK]: {
    features: [Config.features.socialFeedsTikTok]
  },
  [GuardNames.NEW_INFLUENCER_PAGE]: {
    features: [Config.features.selfServiceInfluencers]
  },
  [GuardNames.TINT_EDITOR_SOCIAL_FEED_INSTAGRAM]: {
    features: [Config.features.tintsSocialFeedsInstagramSocialListening]
  },
  [GuardNames.PERSONALIZATION_EMAIL_TEMPLATE]: {
    features: [Config.features.emailTemplatesPersonalization]
  },
  [GuardNames.PERSONALIZATION_TINT_PROJECTION]: {
    features: [Config.features.tintProjection]
  },
  [GuardNames.SOCIAL_CONNECTIONS_HOOTSUITE]: {
    features: [Config.features.socialFeedsHootsuite]
  },
  [GuardNames.SETTINGS_SOCIAL_ACCOUNTS]: {
    permissions: [
      Config.permissions.all,
      Config.permissions.accountsRead,
      Config.permissions.accountsCreate,
      Config.permissions.accountsDelete
    ]
  },
  [GuardNames.SETTINGS_PRODUCT_ACCOUNTS]: {
    features: [Config.features.ecommerce],
    permissions: [
      Config.permissions.all,
      Config.permissions.productAccountsRead,
      Config.permissions.productAccountsCreate,
      Config.permissions.productAccountsDelete
    ]
  },
  [GuardNames.SETTINGS_PRODUCT_FEEDS]: {
    permissions: [
      Config.permissions.all,
      Config.permissions.productFeedsRead,
      Config.permissions.productFeedsCreate,
      Config.permissions.productFeedsDelete
    ],
    features: [Config.features.ecommerce]
  },
  [GuardNames.API]: {
    permissions: [
      Config.permissions.all,
      Config.permissions.webhooksRead,
      Config.permissions.webhooksCreate,
      Config.permissions.webhooksDelete,
      Config.permissions.oauthApplicationsRead,
      Config.permissions.oauthApplicationsCreate,
      Config.permissions.oauthApplicationsDelete
    ],
    features: [Config.features.api]
  },
  [GuardNames.WEBHOOKS]: {
    permissions: [
      Config.permissions.all,
      Config.permissions.webhooksRead,
      Config.permissions.webhooksCreate,
      Config.permissions.webhooksDelete
    ],
    features: [Config.features.api]
  },
  [GuardNames.WEBHOOKS_READ]: {
    permissions: [Config.permissions.webhooksRead]
  },
  [GuardNames.WEBHOOKS_CREATE]: {
    permissions: [Config.permissions.webhooksCreate]
  },
  [GuardNames.WEBHOOKS_DELETE]: {
    permissions: [Config.permissions.webhooksDelete]
  },
  [GuardNames.OAUTH_APPLICATIONS]: {
    permissions: [
      Config.permissions.all,
      Config.permissions.oauthApplicationsRead,
      Config.permissions.oauthApplicationsCreate,
      Config.permissions.oauthApplicationsDelete
    ],
    features: [Config.features.api]
  },
  [GuardNames.OAUTH_APPLICATIONS_READ]: {
    permissions: [Config.permissions.oauthApplicationsRead]
  },
  [GuardNames.OAUTH_APPLICATIONS_CREATE]: {
    permissions: [Config.permissions.oauthApplicationsCreate]
  },
  [GuardNames.OAUTH_APPLICATIONS_DELETE]: {
    permissions: [Config.permissions.oauthApplicationsDelete]
  },
  [GuardNames.FORMS]: {
    features: [Config.features.forms]
  },
  [GuardNames.FORMS_PERMISSIONS]: {
    permissions: [
      Config.permissions.all,
      Config.permissions.formsRead,
      Config.permissions.formsCreate,
      Config.permissions.formsDelete,
      Config.permissions.formsPublish
    ]
  },
  [GuardNames.FORMS_READ]: {
    permissions: [Config.permissions.formsRead]
  },
  [GuardNames.FORMS_CREATE]: {
    permissions: [Config.permissions.formsCreate]
  },
  [GuardNames.FORMS_DELETE]: {
    permissions: [Config.permissions.formsDelete]
  },
  [GuardNames.FORMS_PUBLISH]: {
    permissions: [Config.permissions.formsPublish],
    features: [Config.features.forms]
  },
  [GuardNames.SETTINGS_SECURITY]: {
    permissions: [Config.permissions.all, Config.permissions.teamSecurity]
  },
  [GuardNames.SETTINGS_COLLABORATORS]: {
    permissions: [Config.permissions.all, Config.permissions.teamsUsersRead, Config.permissions.teamsUsersCreate]
  },
  [GuardNames.SETTINGS_ROLES]: {
    permissions: [Config.permissions.all, Config.permissions.teamsUsersRead, Config.permissions.rolesCreate]
  },
  [GuardNames.ONBOARDING_TINTS]: {
    features: [Config.features.onboardingTints]
  },
  [GuardNames.EXPERIENCE_SEO]: {
    features: [Config.features.experiencesSeo]
  },
  [GuardNames.SELF_SERVICE_ONBOARDING]: {
    features: [Config.features.selfServiceOnboarding]
  },
  [GuardNames.TINT_MIX_LIST]: {
    features: [Config.features.displayMix, Config.features.mixPaid]
  },
  [GuardNames.STRIPE_BILLING]: {
    features: [Config.features.stripeBilling],
    permissions: [Config.permissions.changeBilling]
  },
  [GuardNames.MIXES_READ]: {
    features: [Config.features.displayMix],
    permissions: [Config.permissions.mixesRead]
  },
  [GuardNames.MIXES_CREATE]: {
    features: [Config.features.displayMix],
    permissions: [Config.permissions.mixesCreate]
  },
  [GuardNames.MIXES_DELETE]: {
    features: [Config.features.displayMix],
    permissions: [Config.permissions.mixesDelete]
  },
  [GuardNames.FONTS_READ]: {
    permissions: [Config.permissions.fontsRead]
  },
  [GuardNames.FONTS_UPLOAD]: {
    permissions: [Config.permissions.fontsUpload]
  },
  [GuardNames.FONTS_DELETE]: {
    permissions: [Config.permissions.fontsDelete]
  },
  [GuardNames.PRODUCT_DELETE]: {
    permissions: [Config.permissions.productsDelete]
  },
  [GuardNames.PRODUCT_READ]: {
    permissions: [Config.permissions.productsRead]
  },
  [GuardNames.TEAM_SECURITY]: {
    features: [Config.features.teamsSaml],
    permissions: [Config.permissions.teamSecurity]
  },
  [GuardNames.MARKETING_INTEGRATIONS]: {
    features: [Config.features.marketingIntegrations]
  },
  [GuardNames.PERMISSIONS_ALL]: {
    permissions: [Config.permissions.all]
  },
  [GuardNames.SOCIAL_CONNECTIONS]: {
    permissions: [
      Config.permissions.accountsRead,
      Config.permissions.accountsCreate,
      Config.permissions.accountsDelete
    ]
  },
  [GuardNames.DISCOVER_LAUNCHPAD]: {
    forbiddenFeatures: [Config.features.disableLaunchpad]
  },
  [GuardNames.DISCOVER_LAUNCHPAD_EXPLORE]: {
    features: [Config.features.launchpadExplore]
  },
  [GuardNames.CHANGE_BILLING]: {
    permissions: [Config.permissions.changeBilling]
  },
  [GuardNames.ASSET_MARKETING_INTEGRATION]: {
    features: [Config.features.marketingIntegrations],
    permissions: [Config.permissions.assetsPublish]
  },
  [GuardNames.INSIGHTS]: {
    features: [Config.features.insights],
    forbiddenFeatures: [Config.features.billingHideUpgradePrompts]
  },
  [GuardNames.INSIGHTS_HASH_TAGS]: {
    features: [Config.features.insightsHashtags]
  },
  [GuardNames.INSIGHTS_PERFORMANCE]: {
    features: [Config.features.insightsPerformance]
  },
  [GuardNames.INSIGHTS_COMMERCE]: {
    features: [Config.features.insightsCommerce]
  },
  [GuardNames.INSIGHTS_SOCIAL]: {
    features: [Config.features.insightsSocial],
    permissions: [Config.permissions.accountsRead]
  },
  [GuardNames.TOP_CLICKED_POSTS]: {
    features: [Config.features.topClickedPosts]
  },
  [GuardNames.GDPR_COMPLIANCE]: {
    features: [Config.features.gdprCompliance]
  },
  [GuardNames.EXPERIENCES_ADVANCED_OPTIONS]: {
    features: [Config.features.experiencesAdvancedOptions]
  },
  [GuardNames.CAPTCHA]: {
    features: [Config.features.captcha]
  },
  [GuardNames.EXPERIENCES_FIELDS_HIDDEN]: {
    features: [Config.features.experiencesFieldsHidden]
  },
  [GuardNames.ASSETS_METADATA_INSIGHTS]: {
    features: [Config.features.assetsMetadataInsights]
  },
  [GuardNames.GLOBAL_MODERATION]: {
    features: [Config.features.globalModeration]
  },
  [GuardNames.MODIFY_TINT]: {
    permissions: [Config.permissions.modifyTint]
  },
  [GuardNames.PRODUCT_FEED_CREATE]: {
    permissions: [Config.permissions.productFeedsCreate]
  },
  [GuardNames.CREATE_TINT]: {
    permissions: [Config.permissions.createTint]
  },
  [GuardNames.DELETE_TINT]: {
    permissions: [Config.permissions.deleteTint]
  },
  [GuardNames.MODIFY_RULES]: {
    features: [Config.features.autoModerate],
    permissions: [Config.permissions.modifyTint]
  },
  [GuardNames.BLOCK_USER]: {
    features: [Config.features.autoModerate],
    permissions: [Config.permissions.modifyTint]
  },
  [GuardNames.COLLABORATORS]: {
    permissions: [Config.permissions.teamsUsersRead, Config.permissions.teamsUsersCreate]
  },
  [GuardNames.MODERATE_TINT]: {
    permissions: [Config.permissions.moderateTint]
  },
  [GuardNames.ECOMMERCE]: {
    features: [Config.features.ecommerce]
  },
  [GuardNames.ECOMMERCE_WIP]: {
    features: [Config.features.ecommerceWIP]
  },
  [GuardNames.TAG_PRODUCTS]: {
    features: [Config.features.ecommerce],
    permissions: [Config.permissions.postTagProducts]
  },
  [GuardNames.USER_RIGHTS]: {
    features: [Config.features.userRights]
  },
  [GuardNames.FILTER_RATINGS]: {
    features: [Config.features.socialFeedsReviews]
  },
  [GuardNames.FILTER_LANGUAGES]: {
    features: [Config.features.postsMetadataLanguage]
  },
  [GuardNames.CHROMECAST]: {
    features: [Config.features.chromecast]
  },
  [GuardNames.INFLUENCER]: {
    features: [Config.features.socialFeedsInfluencer]
  },
  [GuardNames.HUE]: {
    features: [Config.features.hue]
  },
  [GuardNames.POST_METADATA_TAGS]: {
    features: [Config.features.postsMetaDataTags]
  },
  [GuardNames.NEW_SIMILAR_IMAGES]: {
    features: [Config.features.newVisualSimilarity]
  },
  [GuardNames.ZENDESK_WIDGET]: {
    features: [Config.features.zenDeskWidget]
  },
  [GuardNames.CUSTOM_POSTS]: {
    features: [Config.features.socialFeedsCustom]
  },
  [GuardNames.CUSTOM_CSS]: {
    features: [Config.features.customCSS]
  },
  [GuardNames.PERSONALIZATIONS_READ]: {
    permissions: [Config.permissions.personalizationsRead]
  },
  [GuardNames.PERSONALIZATIONS_EDIT]: {
    permissions: [Config.permissions.personalizationsEdit]
  },
  [GuardNames.PERSONALIZATIONS_DELETE]: {
    permissions: [Config.permissions.personalizationsDelete]
  },
  [GuardNames.EXPERIENCES]: {
    features: [Config.features.experiences]
  },
  [GuardNames.EXPERIENCES_PERMISSIONS]: {
    permissions: [
      Config.permissions.experiencesRead,
      Config.permissions.experiencesCreate,
      Config.permissions.experiencesDelete,
      Config.permissions.experiencesPublish
    ]
  },
  [GuardNames.EXPERIENCES_CREATE]: {
    permissions: [Config.permissions.experiencesCreate]
  },
  [GuardNames.EXPERIENCES_DELETE]: {
    permissions: [Config.permissions.experiencesDelete]
  },
  [GuardNames.EXPERIENCES_READ]: {
    permissions: [Config.permissions.experiencesRead]
  },
  [GuardNames.FORM_SUBMISSIONS]: {
    permissions: [Config.permissions.formSubmissionsRead]
  },
  [GuardNames.DOMAINS]: {
    permissions: [Config.permissions.domainsRead, Config.permissions.domainsCreate, Config.permissions.domainsDelete]
  },
  [GuardNames.DOMAINS_READ]: {
    permissions: [Config.permissions.domainsRead]
  },
  [GuardNames.DOMAINS_CREATE]: {
    permissions: [Config.permissions.domainsCreate]
  },
  [GuardNames.DOMAINS_DELETE]: {
    permissions: [Config.permissions.domainsDelete]
  },
  [GuardNames.ZAPIER]: {
    features: [Config.features.zapier]
  },
  [GuardNames.SOCIAL_FEEDS_HOOT_SUITE]: {
    features: [Config.features.socialFeedsHootsuite]
  },
  [GuardNames.SOCIAL_FEEDS_REVIEW_TRACKERS]: {
    features: [Config.features.socialFeedsReviewTrackers]
  },
  [GuardNames.SOCIAL_FEEDS_EMAIL]: {
    features: [Config.features.socialFeedsEmail]
  },
  [GuardNames.SOCIAL_FEEDS_PINTEREST]: {
    features: [Config.features.socialFeedsPinterest]
  },
  [GuardNames.ASSETS_TRANSFORMATIONS]: {
    permissions: [Config.permissions.assetsTransformations]
  },
  [GuardNames.ASSETS_METADATA_IMAGE_SENTIMENT]: {
    features: [Config.features.assetsMetadataSentiment]
  },
  [GuardNames.BILLING_HIDE_UPGRADE_PROMPTS]: {
    features: [Config.features.billingHideUpgradePrompts]
  },
  [GuardNames.SELF_SERVICE_FEATURE_DISCOVERY]: {
    features: [Config.features.selfServiceFeatureDiscovery]
  },
  [GuardNames.ANALYTICS]: {
    features: [Config.features.analytics]
  },
  [GuardNames.TEAMS_USERS_READ]: {
    permissions: [Config.permissions.teamsUsersRead]
  },
  [GuardNames.TEAMS_USERS_CREATE]: {
    permissions: [Config.permissions.teamsUsersCreate]
  },
  [GuardNames.TEAMS_USERS_DELETE]: {
    permissions: [Config.permissions.teamsUsersDelete]
  },
  [GuardNames.ROLES_CREATE]: {
    permissions: [Config.permissions.rolesCreate]
  },
  [GuardNames.ROLES_DELETE]: {
    permissions: [Config.permissions.rolesDelete]
  },
  [GuardNames.PRODUCT_ACCOUNT]: {
    features: [Config.features.ecommerce],
    permissions: [Config.permissions.productAccountsRead]
  },
  [GuardNames.PRODUCT_ACCOUNT_READ]: {
    permissions: [Config.permissions.productAccountsRead]
  },
  [GuardNames.PRODUCT_ACCOUNT_CREATE]: {
    permissions: [Config.permissions.productAccountsCreate]
  },
  [GuardNames.PRODUCT_ACCOUNT_DELETE]: {
    permissions: [Config.permissions.productAccountsDelete]
  },
  [GuardNames.PRODUCTS_CREATE]: {
    permissions: [Config.permissions.productsCreate]
  },
  [GuardNames.PRODUCTS_DELETE]: {
    permissions: [Config.permissions.productsDelete]
  },
  [GuardNames.PRODUCT_FEEDS]: {
    features: [Config.features.ecommerce],
    permissions: [Config.permissions.productFeedsRead]
  },
  [GuardNames.PRODUCT_FEED_READ]: {
    permissions: [Config.permissions.productFeedsRead]
  },
  [GuardNames.PRODUCT_ACCOUNT_SALESFORCE]: {
    features: [Config.features.salesforce]
  },
  [GuardNames.ACCOUNTS_CREATE]: {
    permissions: [Config.permissions.accountsCreate]
  },
  [GuardNames.ACCOUNTS_DELETE]: {
    permissions: [Config.permissions.accountsDelete]
  },
  [GuardNames.SOCIAL_FEEDS_RSS]: {
    features: [Config.features.socialFeedsRSS]
  },
  [GuardNames.SOCIAL_FEEDS_FACEBOOK]: {
    features: [Config.features.socialFeedsFacebook]
  },
  [GuardNames.SOCIAL_FEEDS_INSTAGRAM_BUSINESS]: {
    features: [Config.features.socialFeedsInstagramBusiness]
  },
  [GuardNames.TEAMS_SOCIAL_ACCOUNTS_THREADS]: {
    optionalFeatures: [Config.features.socialFeedsThreads, Config.features.publishingThreads]
  },
  [GuardNames.SOCIAL_FEEDS_THREADS]: {
    features: [Config.features.socialFeedsThreads]
  },
  [GuardNames.SOCIAL_FEEDS_THREADS_MENTIONED]: {
    features: [Config.features.socialFeedsThreadsMentioned]
  },
  [GuardNames.SOCIAL_FEEDS_THREADS_KEYWORD]: {
    features: [Config.features.socialFeedsThreadsKeyword]
  },
  [GuardNames.PUBLISHING_THREADS]: {
    features: [Config.features.publishingThreads]
  },
  [GuardNames.SOCIAL_FEEDS_CREATOR_IQ]: {
    features: [Config.features.socialFeedsCreatorIq]
  },
  [GuardNames.SOCIAL_FEEDS_POWER_REVIEWS]: {
    features: [Config.features.socialFeedsPowerReviews]
  },
  [GuardNames.SOCIAL_FEEDS_BAZAARVOICE]: {
    features: [Config.features.socialFeedsBazaarvoice]
  },
  [GuardNames.SOCIAL_FEEDS_TUMBLR]: {
    features: [Config.features.socialFeedsTumblr]
  },
  [GuardNames.SOCIAL_FEEDS_FLICKR]: {
    features: [Config.features.socialFeedFlickr]
  },
  [GuardNames.SOCIAL_FEEDS_LINKEDIN]: {
    features: [Config.features.socialFeedLinkedIn]
  },
  [GuardNames.SOCIAL_FEEDS_PUBLIC_POST]: {
    features: [Config.features.socialFeedsPublicPost]
  },
  [GuardNames.SOCIAL_FEEDS_SMS]: {
    features: [Config.features.socialFeedsSMS]
  },
  [GuardNames.SOCIAL_FEEDS_GOOGLE_NEWS]: {
    features: [Config.features.socialFeedGoogleNews]
  },
  [GuardNames.SOCIAL_FEEDS_SLACK]: {
    features: [Config.features.socialFeedSlack]
  },
  [GuardNames.SOCIAL_FEEDS_WEBEX]: {
    features: [Config.features.socialFeedsWebex]
  },
  [GuardNames.SOCIAL_FEEDS_TWITTER]: {
    features: [Config.features.socialFeedsTwitter]
  },
  [GuardNames.ASSETS_EDIT]: {
    permissions: [Config.permissions.assetsEdit]
  },
  [GuardNames.EDIT_ASSETS_LIGHTOBOX]: {
    forbiddenFeatures: [Config.features.billingHideUpgradePrompts],
    permissions: [Config.permissions.assetsEdit]
  },
  [GuardNames.ASSETS_READ]: {
    permissions: [Config.permissions.assetsRead]
  },
  [GuardNames.MULTIPLE_ASSETS]: {
    features: [Config.features.mediaComposer, Config.features.marketingIntegrations],
    permissions: [Config.permissions.assetsPublish]
  },
  [GuardNames.ONBOARDING_SAMPLE_BOARD]: {
    features: [Config.features.onboardingSampleBoard]
  },
  [GuardNames.TAGGING]: {
    features: [Config.features.tagging]
  },
  [GuardNames.SCHEDULER]: {
    features: [Config.features.scheduler]
  },
  [GuardNames.COMMUNITY_UPDATE]: {
    permissions: [Config.permissions.communityUpdate]
  },
  [GuardNames.PUBLISHING_YOUTUBE]: {
    features: [Config.features.publishingYoutube]
  },
  [GuardNames.COLLABORATORS_OPTIONS]: {
    features: [Config.features.teamsSaml],
    permissions: [Config.permissions.security]
  },
  [GuardNames.TEAMS_SAML]: {
    features: [Config.features.teamsSaml]
  },
  [GuardNames.PERSONALIZATIONS_ARCHIVED]: {
    features: [Config.features.personalizationsArchived]
  },
  [GuardNames.REVIEWS]: {
    features: [Config.features.reviews]
  },
  [GuardNames.REVIEWS_PERSONALIZATION]: {
    features: [Config.features.reviews, Config.features.socialFeedsReviews]
  },
  [GuardNames.AUDIENCE_BUILDER]: {
    features: [Config.features.audienceBuilder]
  },
  [GuardNames.AUDIENCE_BADGES]: {
    features: [Config.features.audienceBadges]
  },
  [GuardNames.DOMAINS_EMAIL]: {
    features: [Config.features.domainsEmail]
  },
  [GuardNames.EMAIL_TEMPLATES]: {
    features: [Config.features.emailTemplatesBuilder, Config.features.domainsEmail]
  },
  [GuardNames.FORMS_MAPPING]: {
    features: [Config.features.formsMapping]
  },
  [GuardNames.FORMS_ADVANCED]: {
    features: [Config.features.formsAdvanced]
  },
  [GuardNames.FORMS_LOCALES]: {
    features: [Config.features.formsLocales]
  },
  [GuardNames.FORMS_EMBED]: {
    features: [Config.features.formsEmbed]
  },
  [GuardNames.FORMS_ALL_FILE_TYPES]: {
    features: [Config.features.formsAllFileTypes]
  },
  [GuardNames.FORMS_INSIGHTS]: {
    features: [Config.features.formsInsights]
  },
  [GuardNames.FORMS_CAPTCHA]: {
    features: [Config.features.formsCaptcha]
  },
  [GuardNames.INSIGHTS_SOCIAL_TIKTOK]: {
    features: [Config.features.insightsSocialTiktok]
  },
  [GuardNames.POSTS_REPLIES]: {
    features: [Config.features.postsReplies]
  },
  [GuardNames.FORMS_TRACKING]: {
    features: [Config.features.formsTracking]
  },
  [GuardNames.INTEGRATIONS_BAZZARVOICE]: {
    features: [Config.features.integrationsBazaarvoice]
  },
  [GuardNames.INTEGRATIONS_POWER_REVIEWS]: {
    features: [Config.features.integrationsPowerReviews]
  },
  [GuardNames.USER_RIGHT_MESSAGE_AI_SUGGESTION]: {
    features: [Config.features.userRightsMessageAiSuggestion]
  },
  [GuardNames.ZINRELO_NAVIGATION]: {
    features: [Config.features.zinreloNavigation]
  }
};
