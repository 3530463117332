var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import "./sidebar-stepper.sass";
import TintSidebar from "../sidebar/sidebar";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
export const TintSidebarStepperStateType = {
  FIRST: "main",
  NEXT: "edit",
  FINALLY: "create"
};
const TintSidebarStepper = ({ modalDetails, onChangeStep, onClose, stepsLength = 2 }) => {
  var _a;
  const debounceDelayMs = 100;
  const [currentSidebarDetails, setCurrentSidebarDetails] = useState();
  const [sidebarDataList, setSidebarDataList] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(modalDetails.isVisible);
  const onChangeData = debounce((data) => {
    setCurrentSidebarDetails(data);
    setSidebarDataList((s) => __spreadProps(__spreadValues({}, s), {
      [modalDetails.step]: __spreadValues({}, data)
    }));
  }, debounceDelayMs);
  const renderCurrentComponent = () => {
    return modalDetails.component && modalDetails.component({
      onClose: onSidebarClose,
      onChangeStep,
      onChangeData,
      data: sidebarDataList[modalDetails.step],
      switcherData: sidebarDataList,
      step: modalDetails.step
    });
  };
  const onFooterLeftButtonClick = () => {
    var _a2;
    if (modalDetails.step === 1) {
      onClose();
    }
    onChangeStep({
      step: modalDetails.step - 1,
      data: __spreadValues({}, sidebarDataList[modalDetails.step - 1])
    });
    setCurrentSidebarDetails({
      step: modalDetails.step - 1,
      data: __spreadValues({}, sidebarDataList[modalDetails.step - 1]),
      isValid: (_a2 = sidebarDataList[modalDetails.step - 1]) == null ? void 0 : _a2.isValid
    });
  };
  const onRightButtonClick = () => {
    onChangeStep({
      step: modalDetails.step + 1,
      data: __spreadProps(__spreadValues({}, currentSidebarDetails), {
        isValid: void 0
      })
    });
    setCurrentSidebarDetails(void 0);
    if (stepsLength === modalDetails.step) {
      onClose == null ? void 0 : onClose();
    }
  };
  const onSidebarClose = () => {
    setModalVisibility(false);
    onClose && onClose();
  };
  return isModalVisible && /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      sidebarClassName: "tint-sidebar-stepper",
      title: modalDetails.headerTitle,
      isOpen: isModalVisible,
      onClose: onSidebarClose,
      defaultFooterStyle: false,
      onLeftButtonClick: onFooterLeftButtonClick,
      leftButtonText: modalDetails.step !== 1 ? "Previous" : "Cancel",
      rightButtonText: modalDetails.step !== stepsLength ? "Next" : "Done",
      isRightButtonDisabled: !sidebarDataList[modalDetails.step] || sidebarDataList[modalDetails.step] && !((_a = sidebarDataList[modalDetails.step]) == null ? void 0 : _a.isValid),
      onRightButtonClick
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-switcher__content" }, renderCurrentComponent())
  );
};
TintSidebarStepper.propTypes = {
  modalDetails: PropTypes.any,
  onChangeStep: PropTypes.func,
  onClose: PropTypes.func,
  stepsLength: PropTypes.number
};
export default TintSidebarStepper;
