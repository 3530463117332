import React, { Fragment } from "react";
import "./blank-coulmn-block.sass";
import * as PropTypes from "prop-types";
import AddBlock from "../../../../add-block/add-block";
import { pageSectionType } from "../../../../../../../../model/page-section-type.model";
import {
  getCustomButton,
  getHeadline,
  getMedia,
  getParagraph,
  getTint
} from "../../../../../../../../data/default-experience-data/shared.data";
import { addCustomizableHeader } from "../../../../../../../../data/default-experience-data";
import { elementToComponent } from "../../../../../../containers/experience/element-to-component";
import colors from "../../../../../../../../data/default-experience-data/colors";
const BlankColumnBlock = ({
  element,
  index,
  column,
  isPreview,
  parentSectionIndex,
  translations,
  addSection,
  paddingTop,
  paddingBottom
}) => {
  const AddBlockStyles = {
    minWidth: "512px"
  };
  const getCustomSection = (sectionName) => {
    switch (sectionName) {
      case pageSectionType.CUSTOM_BUTTON: {
        return getCustomButton({}, {});
      }
      case pageSectionType.CUSTOM_PARAGRAPH: {
        return getParagraph(
          {},
          { sectionPadding: "64" },
          "Get ready to be featured when you share your unique sense of style with our brand"
        );
      }
      case pageSectionType.CUSTOM_HEADLINE: {
        return getHeadline({}, { sectionPadding: "64" });
      }
      case pageSectionType.TINT: {
        return addCustomizableHeader(getTint({ backgroundColor: colors.white, sectionPadding: "64" }), {
          color: colors.blackLighter,
          text: "#BRANDHASHTAG"
        });
      }
      case pageSectionType.IMAGE:
        return getMedia(pageSectionType.IMAGE, colors.white);
      case pageSectionType.VIDEO:
        return getMedia(pageSectionType.VIDEO, colors.white);
    }
  };
  const renderSection = (data, pageWidth) => {
    return /* @__PURE__ */ React.createElement(Fragment, { key: index }, renderElementToComponent(data, index, pageWidth));
  };
  const renderElementToComponent = (e, i, pageWidth) => {
    return elementToComponent({
      element: e,
      pageWidth,
      _id: e.data._id ? e.data._id : i,
      index: i,
      translations,
      parentSectionIndex,
      addSectionForMultiColumn: addNewSection
    });
  };
  const addNewSection = (sectionName) => {
    addSection(getCustomSection(sectionName), index, column);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "blank-column-block" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "blank-column-block__container",
      style: {
        paddingTop: `${paddingTop}px`,
        paddingBottom: `${paddingBottom}px`
      }
    },
    element.data === null ? !isPreview ? /* @__PURE__ */ React.createElement("div", { className: "blank-column-block__button-wrapper" }, /* @__PURE__ */ React.createElement(AddBlock, { isForMultiColumn: true, styles: AddBlockStyles, addSection: addNewSection })) : null : renderSection(element)
  ));
};
BlankColumnBlock.propTypes = {
  backgroundColor: PropTypes.string,
  element: PropTypes.object,
  index: PropTypes.number,
  column: PropTypes.number,
  isPreview: PropTypes.bool,
  parentSectionIndex: PropTypes.number,
  translations: PropTypes.object,
  addSection: PropTypes.func
};
export default BlankColumnBlock;
