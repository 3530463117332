import React from "react";
import "./submission-success.sass";
export const SubmissionSuccess = ({ title, description, successMessageLinks, getTranslation }) => {
  const renderSuccessMessageLinks = () => {
    return successMessageLinks.map((e, i) => {
      return /* @__PURE__ */ React.createElement(
        "a",
        {
          rel: "noopener noreferrer",
          key: i,
          href: getTranslation(e.buttonUrl),
          target: "_blank",
          className: "tint-submission-success"
        },
        /* @__PURE__ */ React.createElement("span", null, getTranslation(e.buttonText) || "Button text")
      );
    });
  };
  const renderStyledText = (text) => {
    if (text) {
      return /* @__PURE__ */ React.createElement(
        "span",
        {
          dangerouslySetInnerHTML: {
            __html: text.replace(/(?:\r\n|\r|\n)/g, "<br>")
          }
        }
      );
    }
    return void 0;
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-page-section--form__submission-container" }, /* @__PURE__ */ React.createElement("i", { className: "fas fa-check-circle" }), /* @__PURE__ */ React.createElement("p", { className: "tint-page-section--form__submission-container__header" }, title || "Thank you!"), /* @__PURE__ */ React.createElement("p", { className: "tint-page-section--form__submission-container__description" }, renderStyledText(description) || "The form was submitted successfully"), successMessageLinks && successMessageLinks.length > 0 ? /* @__PURE__ */ React.createElement("div", { className: "tint-submission-success-container" }, " ", renderSuccessMessageLinks()) : null);
};
