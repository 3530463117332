var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { produce } from "immer";
import { ACTIONS } from "../actions/tint-editor.actions";
import { tintStatusType } from "../../../model/tint-status-type.model";
export const initialState = {
  data: void 0,
  ctas: [],
  searched: [],
  links: void 0,
  included: void 0,
  isFetching: false,
  error: void 0,
  modal: void 0,
  modalType: void 0,
  modalEcommerce: void 0,
  selectedTints: []
};
export const tintEditor = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_STATE:
      return initialState;
    case ACTIONS.DELETE_CTA_ASSOCIATIONS.REQUEST:
    case ACTIONS.POST_CTA_ASSOCIATIONS.REQUEST:
    case ACTIONS.APPLY_CTA_ASSOCIATIONS.REQUEST:
    case ACTIONS.DELETE_CTA.REQUEST:
    case ACTIONS.POST_CTA.REQUEST:
    case ACTIONS.GET_CTAS.REQUEST:
    case ACTIONS.PATCH_CTA.REQUEST:
    case ACTIONS.GET_TINTS.REQUEST:
    case ACTIONS.GET_SEARCH_TINTS.REQUEST:
    case ACTIONS.GET_TINT.REQUEST:
    case ACTIONS.UPDATE_TINT_STATUS.REQUEST:
    case ACTIONS.BULK_UPDATE_TINTS_STATUS.REQUEST:
    case ACTIONS.UPDATE_TINT_PRIVACY.REQUEST:
    case ACTIONS.DELETE_TINT.REQUEST:
    case ACTIONS.BULK_DELETE_TINTS.REQUEST:
    case ACTIONS.PATCH_TINT.REQUEST:
    case ACTIONS.DUPLICATE_TINT.REQUEST:
    case ACTIONS.CREATE_TINT.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        isError: false
      });
    case ACTIONS.DELETE_TINT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.tints = draftState.tints.filter((tint) => tint.id !== action.payload);
        draftState.isFetching = false;
        draftState.isError = false;
      });
    case ACTIONS.BULK_DELETE_TINTS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.tints = draftState.tints.filter((tint) => {
          return !action.payload.some((t) => t.id === tint.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedTints = [];
      });
    case ACTIONS.BULK_UPDATE_TINTS_STATUS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.selectedTints = draftState.selectedTints.map((tint) => __spreadProps(__spreadValues({}, tint), {
          attributes: __spreadProps(__spreadValues({}, tint.attributes), {
            status: tint.attributes.status === tintStatusType.ACTIVE ? tintStatusType.INACTIVE : tintStatusType.ACTIVE
          })
        }));
      });
    case ACTIONS.PATCH_TINT.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload;
      });
    case ACTIONS.TOGGLE_CTA_SIDEBAR:
      return produce(state, (draftState) => {
        draftState.CTAModal = {
          postId: action.payload.postId,
          isCTASidebarOpen: action.payload.isVisible,
          isAssignMode: action.payload.isAssignMode
        };
      });
    case ACTIONS.TOGGLE_SIDEBAR:
      return produce(state, (draftState) => {
        draftState.modals = {
          [action.payload.sidebarName]: {
            isOpen: action.payload.isVisible
          }
        };
      });
    case ACTIONS.GET_TINTS.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        tints: action.payload.tints,
        included: action.payload.included,
        links: action.payload.links,
        isFetching: false,
        isError: false
      });
    case ACTIONS.OPEN_MODAL:
      return produce(state, (draftState) => {
        draftState.modal = {
          postId: action.payload
        };
        draftState.modalType = void 0;
      });
    case ACTIONS.OPEN_VISUAL_SEARCH_MODAL:
      return produce(state, (draftState) => {
        draftState.modalVisualSearch = true;
      });
    case ACTIONS.CLOSE_VISUAL_SEARCH_MODAL:
      return produce(state, (draftState) => {
        draftState.modalVisualSearch = void 0;
      });
    case ACTIONS.OPEN_ECOMMERCE_MODAL:
      return produce(state, (draftState) => {
        draftState.modalEcommerce = action.payload;
      });
    case ACTIONS.CLOSE_ECOMMERCE_MODAL:
      return produce(state, (draftState) => {
        draftState.modalEcommerce = void 0;
      });
    case ACTIONS.CHANGE_MODAL_POST:
      return produce(state, (draftState) => {
        draftState.modal = {
          postId: action.payload
        };
      });
    case ACTIONS.CLOSE_MODAL:
      return produce(state, (draftState) => {
        draftState.modal = void 0;
        draftState.modalType = void 0;
      });
    case ACTIONS.INCREASE_VISUAL_SEARCH_COUNT:
      return produce(state, (draftState) => {
        draftState.data.meta.visual_searches_count = draftState.data.meta.visual_searches_count + 1;
      });
    case ACTIONS.CHANGE_MODAL_ORIENTATION:
      return produce(state, (draftState) => {
        draftState.modalType = action.payload;
      });
    case ACTIONS.POST_CTA_ASSOCIATIONS.SUCCESS:
      return produce(state, (draftState) => {
        const currentCTA = draftState.ctas.find((cta) => cta.id === action.payload.ctaId);
        draftState.ctas = draftState.ctas.filter((cta) => cta.id !== action.payload.ctaId);
        draftState.ctas = [
          __spreadProps(__spreadValues({}, currentCTA), {
            savedFilterId: action.payload.savedFilterId,
            ctaAssociationId: action.payload.id
          }),
          ...draftState.ctas
        ];
      });
    case ACTIONS.APPLY_CTA_ASSOCIATIONS.SUCCESS:
      return produce(state, (draftState) => {
        var _a;
        const cta = draftState.ctas.find((cta2) => cta2.id === action.payload.ctaId);
        const posts = ((_a = cta.posts) == null ? void 0 : _a.length) > 0 ? cta.posts : [];
        cta.posts = [action.payload, ...posts];
        cta.ctaAssociationId = action.payload.id;
      });
    case ACTIONS.DELETE_CTA_ASSOCIATIONS.SUCCESS:
      return produce(state, (draftState) => {
        var _a;
        const cta = draftState.ctas.find((cta2) => cta2.id === action.payload.ctaId);
        cta.posts = (_a = cta == null ? void 0 : cta.posts) == null ? void 0 : _a.filter((post) => (post == null ? void 0 : post.id) !== action.payload.ctaAssociationId);
      });
    case ACTIONS.GET_CTAS.SUCCESS:
      return produce(state, (draftState) => {
        var _a, _b;
        draftState.ctas = (_b = (_a = action.payload) == null ? void 0 : _a.ctas) == null ? void 0 : _b.map((cta) => {
          var _a2, _b2, _c, _d, _e, _f, _g, _h, _i;
          return __spreadProps(__spreadValues({}, cta.attributes), {
            id: cta.id,
            posts: (_b2 = (_a2 = action.payload) == null ? void 0 : _a2.ctaAssociations) == null ? void 0 : _b2.filter(
              (el) => el.relationships.cta.data.id === cta.id && el.relationships.post.data
            ),
            ctaAssociationId: ((_c = action.payload) == null ? void 0 : _c.ctaAssociations) ? (_e = (_d = action.payload.ctaAssociations) == null ? void 0 : _d.find((ctaAssociations) => {
              var _a3, _b3;
              return ((_b3 = (_a3 = ctaAssociations.relationships.cta) == null ? void 0 : _a3.data) == null ? void 0 : _b3.id) === cta.id;
            })) == null ? void 0 : _e.id : void 0,
            savedFilterId: ((_f = action.payload) == null ? void 0 : _f.savedFilter) ? (_i = (_h = (_g = action.payload) == null ? void 0 : _g.savedFilter) == null ? void 0 : _h.find((ctaAssociations) => {
              var _a3, _b3;
              return ((_b3 = (_a3 = ctaAssociations.relationships.saved_filter) == null ? void 0 : _a3.data) == null ? void 0 : _b3.id) === draftState.ctaAssociationId;
            })) == null ? void 0 : _i.id : void 0
          });
        });
        draftState.isFetching = false;
        draftState.isError = false;
      });
    case ACTIONS.POST_CTA.SUCCESS:
      return produce(state, (draftState) => {
        const currentCTA = __spreadProps(__spreadValues({}, action.payload.attributes), {
          id: action.payload.id
        });
        draftState.ctas = [currentCTA, ...draftState.ctas];
        draftState.isFetching = false;
        draftState.isError = false;
      });
    case ACTIONS.GET_SEARCH_TINTS.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        searched: action.payload.tints,
        isFetching: false,
        isError: false
      });
    case ACTIONS.GET_TINT.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        data: action.payload,
        isFetching: false,
        isError: false
      });
    case ACTIONS.PATCH_MODERATION_RULES.SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.attributes.auto_moderation_rules = action.payload;
      });
    case ACTIONS.PATCH_CTA.SUCCESS:
      return produce(state, (draftState) => {
        const currentCTAIndex = draftState.ctas.findIndex((cta) => cta.id === action.payload.id);
        draftState.ctas[currentCTAIndex] = __spreadValues(__spreadValues({}, draftState.ctas[currentCTAIndex]), action.payload.data.attributes);
      });
    case ACTIONS.DELETE_CTA.SUCCESS:
      return produce(state, (draftState) => {
        draftState.ctas = draftState.ctas.filter((cta) => cta.id !== action.payload);
      });
    case ACTIONS.MARK_PREVIEW_MODE:
      return produce(state, (draft) => {
        draft.isPreviewMode = action.payload;
      });
    case ACTIONS.UPDATE_TINT_TAGS:
      return produce(state, (draft) => {
        draft.data.attributes.tags = [.../* @__PURE__ */ new Set([...draft.data.attributes.tags, ...action.payload])];
      });
    case ACTIONS.SELECT_TINT:
      return produce(state, (draft) => {
        draft.selectedTints = [...draft.selectedTints, action.payload];
      });
    case ACTIONS.UNSELECT_TINT:
      return produce(state, (draft) => {
        draft.selectedTints = draft.selectedTints.filter(({ id }) => {
          return action.payload !== id;
        });
      });
    case ACTIONS.DESELECT_ALL_TINTS:
      return produce(state, (draft) => {
        draft.selectedTints = [];
      });
    case ACTIONS.DELETE_CTA_ASSOCIATIONS.FAILURE:
    case ACTIONS.APPLY_CTA_ASSOCIATIONS.FAILURE:
    case ACTIONS.POST_CTA_ASSOCIATIONS.FAILURE:
    case ACTIONS.DELETE_CTA.FAILURE:
    case ACTIONS.PATCH_CTA.FAILURE:
    case ACTIONS.POST_CTA.FAILURE:
    case ACTIONS.GET_CTAS.FAILURE:
    case ACTIONS.GET_TINTS.FAILURE:
    case ACTIONS.GET_SEARCH_TINTS.FAILURE:
    case ACTIONS.GET_TINT.FAILURE:
    case ACTIONS.UPDATE_TINT_STATUS.FAILURE:
    case ACTIONS.BULK_UPDATE_TINTS_STATUS.FAILURE:
    case ACTIONS.UPDATE_TINT_PRIVACY.FAILURE:
    case ACTIONS.DELETE_TINT.FAILURE:
    case ACTIONS.BULK_DELETE_TINTS.FAILURE:
    case ACTIONS.PATCH_TINT.FAILURE:
    case ACTIONS.DUPLICATE_TINT.FAILURE:
    case ACTIONS.CREATE_TINT.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isError: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
};
export const getPostCTAs = (state, postId) => {
  var _a, _b;
  return state && ((_b = (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.ctas) == null ? void 0 : _b.filter((cta) => {
    var _a2;
    return (_a2 = cta == null ? void 0 : cta.posts) == null ? void 0 : _a2.some((post) => {
      var _a3, _b2, _c;
      return ((_c = (_b2 = (_a3 = post.relationships) == null ? void 0 : _a3.post) == null ? void 0 : _b2.data) == null ? void 0 : _c.id) === postId;
    });
  }));
};
export const getTagList = (state) => {
  var _a, _b, _c, _d, _e, _f;
  if (!Array.isArray((_c = (_b = (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.data) == null ? void 0 : _b.attributes) == null ? void 0 : _c.tags)) {
    return [];
  }
  return (_f = (_e = (_d = state == null ? void 0 : state.tintEditor) == null ? void 0 : _d.data) == null ? void 0 : _e.attributes) == null ? void 0 : _f.tags;
};
export const getTintList = (state) => {
  var _a;
  return state && ((_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.tints) || [];
};
export const getSlugName = (state) => {
  var _a, _b, _c;
  return state && ((_c = (_b = (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.data) == null ? void 0 : _b.attributes) == null ? void 0 : _c.slug);
};
export const getSelectedTints = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.selectedTints;
};
