import React from "react";
const FormBuilderFormInterfaceVideo = ({ item, isPreview, language }) => {
  if (!item) return null;
  let url = item.src;
  if (isPreview) {
    const defaultAutoplayValue = 0;
    const parsedUrl = new URL(item.src);
    const urlParams = new URLSearchParams(parsedUrl.search);
    urlParams.set("autoplay", defaultAutoplayValue);
    parsedUrl.search = urlParams.toString();
    url = parsedUrl.href;
  }
  return /* @__PURE__ */ React.createElement(
    "iframe",
    {
      className: item.class,
      src: `${url}${language ? `&cc_lang_pref=${language}` : ""}`,
      id: item.id,
      allow: item.allow,
      allowFullScreen: true
    }
  );
};
FormBuilderFormInterfaceVideo.displayName = "FormBuilderFormInterfaceVideo";
export default React.memo(FormBuilderFormInterfaceVideo);
