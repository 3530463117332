import { CLASSIC } from "./classic.theme";
import { SLIDER } from "./slider.theme";
import { SQUARE_PHOTO } from "./square-photo.theme";
import { TALL_PHOTO } from "./tall-photo.theme";
import { GEM } from "./gem.theme";
import { ATMOSPHERE } from "./atmosphere.theme";
import { ARCHIVED_CLASSIC } from "./archived-classic.theme";
import { ARCHIVED_SLIDER } from "./archived-slider.theme";
import { ARCHIVED_SQUARE_PHOTO } from "./archived-square-photo";
import { ARCHIVED_TALL_PHOTO } from "./archived-tall-photo.theme";
import { ARCHIVED_GEM } from "./archived-gem.theme";
import { ARCHIVED_ATMOSPHERE } from "./archived-atmosphere.theme";
import { TESTIMONIALS } from "./testimonials.theme";
import { ARTSY } from "./artsy.theme";
import { NEWS } from "./news.theme";
import { GRID } from "./grid.theme";
import { EMAIL_TEMPLATE_GRID } from "./email-template-grid.theme";
import { EMAIL_TEMPLATE_COLLAGE } from "./email-template-collage.theme";
import { SLIDESHOW } from "./slideshow.theme";
import { PHOTO_SLIDESHOW } from "./photo-slideshow.theme";
import { TICKER } from "./ticker.theme";
import { MOSAIC } from "./mosaic.theme";
import { LEGACY_CLASSIC } from "./legacy-classic.theme";
import { ARCHIVED_NEWS } from "./archived-news.theme";
import { TABLE } from "./table.theme";
import { LEGACY_WATERFALL } from "./legacy-waterfall.theme";
import { LEGACY_HORIZONTAL } from "./legacy-horizontal.theme";
import { VIDEO_SLIDER } from "./video-slider.theme";
export const REVIEWS_THEMES = [TABLE];
export const EMAIL_THEMES = [EMAIL_TEMPLATE_GRID, EMAIL_TEMPLATE_COLLAGE];
export const LEGACY_THEMES = [LEGACY_CLASSIC, LEGACY_WATERFALL, LEGACY_HORIZONTAL];
export const ARCHIVED_THEMES = [
  ARCHIVED_CLASSIC,
  ARCHIVED_SLIDER,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO,
  ARCHIVED_NEWS,
  ARCHIVED_GEM,
  ARCHIVED_ATMOSPHERE
];
export const OLD_THEMES = [...LEGACY_THEMES, ...ARCHIVED_THEMES];
export const NEW_THEMES = [
  CLASSIC,
  SLIDER,
  SQUARE_PHOTO,
  TALL_PHOTO,
  NEWS,
  GEM,
  ATMOSPHERE,
  ...EMAIL_THEMES,
  ...REVIEWS_THEMES,
  VIDEO_SLIDER
];
export const THEMES = [
  // New Themes
  ...NEW_THEMES,
  // OLD Themes:
  TESTIMONIALS,
  ARTSY,
  GRID,
  SLIDESHOW,
  PHOTO_SLIDESHOW,
  TICKER,
  MOSAIC,
  // Archived Themes:
  ARCHIVED_CLASSIC,
  ARCHIVED_SLIDER,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO,
  ARCHIVED_NEWS,
  ARCHIVED_GEM,
  ARCHIVED_ATMOSPHERE,
  // Legacy Themes(Not support anymore):
  ...LEGACY_THEMES
];
export const EMBED_THEMES = [
  // New themes:
  CLASSIC,
  SLIDER,
  SQUARE_PHOTO,
  TALL_PHOTO,
  VIDEO_SLIDER,
  // OLD Themes:
  TESTIMONIALS,
  ARTSY,
  NEWS,
  GRID,
  // Archived Themes:
  ARCHIVED_CLASSIC,
  ARCHIVED_SLIDER,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO,
  ARCHIVED_NEWS,
  ...LEGACY_THEMES
];
export const SIGNAGE_THEMES = [
  CLASSIC,
  SQUARE_PHOTO,
  TALL_PHOTO,
  TESTIMONIALS,
  ARTSY,
  NEWS,
  GRID,
  SLIDESHOW,
  PHOTO_SLIDESHOW,
  TICKER,
  GEM,
  MOSAIC,
  ATMOSPHERE,
  ARCHIVED_CLASSIC,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO,
  ARCHIVED_NEWS,
  ARCHIVED_GEM,
  ARCHIVED_ATMOSPHERE,
  ...LEGACY_THEMES
];
export const SHOPPABLE_THEMES = [
  SLIDER,
  SQUARE_PHOTO,
  TALL_PHOTO,
  ARCHIVED_SLIDER,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO
];
export const EVENT_THEMES = [...SIGNAGE_THEMES];
export const VIRTUAL_EVENT_THEMES = [
  CLASSIC,
  SLIDER,
  SQUARE_PHOTO,
  TALL_PHOTO,
  TESTIMONIALS,
  ARTSY,
  NEWS,
  GRID,
  SLIDESHOW,
  TICKER,
  GEM,
  MOSAIC,
  ATMOSPHERE,
  ARCHIVED_CLASSIC,
  ARCHIVED_SLIDER,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO,
  ARCHIVED_NEWS,
  ARCHIVED_GEM,
  //mosaic
  ARCHIVED_ATMOSPHERE,
  ...LEGACY_THEMES
];
export const MOBILE_THEMES = [
  SLIDER,
  SQUARE_PHOTO,
  TALL_PHOTO,
  ARCHIVED_SLIDER,
  ARCHIVED_SQUARE_PHOTO,
  ARCHIVED_TALL_PHOTO
];
export const OTHER_THEMES = [
  // New themes:
  CLASSIC
];
