import React from "react";
import "./uploading-dialog.sass";
import PreloaderComponent from "../interface/preloaders/preloader/preloader";
import { useDispatch } from "react-redux";
import { clearUploadDialog } from "../../actions/upload-dialog/upload-dialog";
export const UPLOAD_DIALOG_TYPE = {
  DOWNLOAD: "DOWNLOAD",
  UPLOAD: "UPLOAD",
  REFRESH: "REFRESH"
};
export const UploadingDialog = ({
  onCloseClick,
  files,
  title,
  type,
  isFinished = false,
  isPending = true,
  refreshFn,
  successMsg
}) => {
  const dispatch = useDispatch();
  const onLinkClick = ({ url }) => {
    const target = window.open("");
    if (refreshFn) {
      refreshFn().then((data) => {
        const _url = data.payload ? data.payload.url : data.data.data.attributes.url;
        target.location.href = _url;
      });
    } else {
      target.location.href = url;
    }
  };
  const renderBody = () => {
    switch (type) {
      case UPLOAD_DIALOG_TYPE.DOWNLOAD:
        return files.map((e, i) => /* @__PURE__ */ React.createElement(
          "a",
          {
            className: `${isFinished ? "upload-finished" : ""}`,
            onClick: () => isFinished && onLinkClick({ url: e.url }),
            key: i
          },
          e.filename
        ));
      case UPLOAD_DIALOG_TYPE.UPLOAD:
        return files.map((e, i) => /* @__PURE__ */ React.createElement("span", { key: i }, e.filename));
      case UPLOAD_DIALOG_TYPE.REFRESH:
        return /* @__PURE__ */ React.createElement("span", null);
    }
  };
  return isPending ? /* @__PURE__ */ React.createElement("div", { className: "tint-uploading-dialog" }, /* @__PURE__ */ React.createElement("div", { className: "tint-uploading-dialog__title" }, /* @__PURE__ */ React.createElement("span", null, title), /* @__PURE__ */ React.createElement(
    "span",
    {
      onClick: () => {
        onCloseClick && onCloseClick();
        dispatch(clearUploadDialog());
      },
      className: "icon far fa-times"
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-uploading-dialog__description" }, /* @__PURE__ */ React.createElement("div", { className: "tint-uploading-dialog__file-container" }, renderBody()), /* @__PURE__ */ React.createElement("div", { className: "tint-uploading-dialog__loader-container" }, isFinished ? /* @__PURE__ */ React.createElement("span", null, successMsg || "Click to download") : /* @__PURE__ */ React.createElement(PreloaderComponent, null)))) : null;
};
