import { DROPDOWN } from "./consts.js";
import { createPrefixedActionName } from "../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  ON_SELECT_MODAL: createPrefixedActionName(DROPDOWN, "ON_SELECT_MODAL")
};
export const onSelectModal = (value) => {
  return {
    type: ACTIONS.ON_SELECT_MODAL,
    payload: {
      value
    }
  };
};
