import React from "react";
import PropTypes from "prop-types";
import "./checkbox-select.sass";
import TintIcon from "../../icon/icon";
import { faMinus } from "@fortawesome/pro-solid-svg-icons";
export const CheckboxSelect = ({ onClick, isChecked, checkboxId = "select-checkbox", icon = faMinus, style }) => {
  const change = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };
  return /* @__PURE__ */ React.createElement("label", { className: "checkbox-select", htmlFor: checkboxId, style }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "checkbox",
      value: isChecked,
      id: checkboxId,
      onChange: change,
      className: `checkbox-select__input ${isChecked ? "checkbox-select__input--selected" : ""}`
    }
  ), isChecked ? /* @__PURE__ */ React.createElement(TintIcon, { icon }) : null);
};
CheckboxSelect.propTypes = {
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  icon: PropTypes.string
};
