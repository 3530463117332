var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./icon.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const TintIcon = ({ className, icon, color, svgIconSrc, size, fontSize, style, onClick }) => {
  return /* @__PURE__ */ React.createElement("span", { className: `tint-icon icon ${className}`, style: { fontSize }, onClick: () => onClick && onClick() }, icon ? /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon, size, style: __spreadValues({ color }, style) }) : /* @__PURE__ */ React.createElement("img", { src: svgIconSrc, style: __spreadValues({}, style) }));
};
export default TintIcon;
