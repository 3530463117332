var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/ui-ecommerce.actions";
import produce from "immer";
import { ImageDraggableRectStatus } from "../components/image-draggable-rect/image-draggable-rect";
import { AssignProductsSidebarStepType } from "../components/assign-products-sidebar/assign-products-sidebar";
export const initialState = {
  isPending: false,
  error: void 0,
  data: void 0,
  selectedProduct: void 0,
  hoveredProduct: void 0,
  currentRect: void 0,
  taggedProducts: [],
  sidebarStatus: AssignProductsSidebarStepType.STEP_ONE,
  isNoDrawable: false
};
export const uiEcommerce = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_POST_TAGGED_PRODUCTS.REQUEST:
    case ACTIONS.PATCH_PRODUCT_TAG.REQUEST:
    case ACTIONS.POST_PRODUCT_TAG.REQUEST:
    case ACTIONS.DELETE_PRODUCT_TAG.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true,
        error: void 0
      });
    case ACTIONS.GET_POST_TAGGED_PRODUCTS.FAILURE:
    case ACTIONS.PATCH_PRODUCT_TAG.FAILURE:
    case ACTIONS.POST_PRODUCT_TAG.FAILURE:
    case ACTIONS.DELETE_PRODUCT_TAG.FAILURE:
      return produce(state, (draft) => {
        draft.isPending = false;
        draft.error = action.payload;
      });
    case ACTIONS.PATCH_PRODUCT_TAG.SUCCESS:
    case ACTIONS.POST_PRODUCT_TAG.SUCCESS:
      return produce(state, (draft) => {
        draft.isPending = false;
        draft.error = void 0;
        draft.taggedProducts = draft.taggedProducts.map((product) => {
          if (product.rectId === action.payload.rectId) {
            return __spreadProps(__spreadValues(__spreadValues(__spreadValues({}, product), draft.selectedProduct), action.payload.data.attributes), {
              rectId: action.payload.data.id,
              productId: action.payload.productId
            });
          } else {
            return product;
          }
        });
        draft.currentRect = __spreadProps(__spreadValues({}, action.payload.data.attributes), {
          rectId: action.payload.data.id,
          productId: action.payload.productId
        });
      });
    case ACTIONS.GET_POST_TAGGED_PRODUCTS.SUCCESS:
      return produce(state, (draft) => {
        draft.isPending = false;
        draft.data = action.payload;
        draft.data.included = action.payload.included ? mergeProductTagsWithProducts(action.payload.included) : [];
        draft.taggedProducts = action.payload.included && action.payload.included ? action.payload.included.map((taggedProduct) => __spreadProps(__spreadValues({}, taggedProduct.attributes), {
          product_id: void 0,
          productId: taggedProduct.attributes.product_id,
          rectId: taggedProduct.id,
          status: ImageDraggableRectStatus.COMPLETE
        })) : [];
      });
    case ACTIONS.DRAW_RECT:
      return produce(state, (draft) => {
        draft.currentRect = action.payload;
        draft.taggedProducts.push(action.payload);
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_TWO;
      });
    case ACTIONS.ADD_NO_DRAWABLE_PRODUCT:
      return produce(state, (draft) => {
        draft.currentRect = action.payload;
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_TWO;
        draft.isNoDrawable = true;
        draft.taggedProducts.push(action.payload);
      });
    case ACTIONS.CANCEL_DRAW_RECT:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts.filter(
          (product) => product.status === ImageDraggableRectStatus.COMPLETE || product.status === ImageDraggableRectStatus.EDITABLE && product.productId
        );
        draft.currentRect = void 0;
        draft.selectedProduct = void 0;
        draft.hoveredProduct = void 0;
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_ONE;
        draft.isNoDrawable = false;
      });
    case ACTIONS.DISMISS_DRAW_RECT:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts.map((product) => __spreadProps(__spreadValues({}, product), {
          status: ImageDraggableRectStatus.COMPLETE
        }));
        draft.currentRect = void 0;
        draft.isNoDrawable = false;
      });
    case ACTIONS.EDIT_RECT_UI_STATUS:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts.map((taggedProduct) => {
          if (taggedProduct.rectId === action.payload.rectId) {
            return __spreadProps(__spreadValues({}, taggedProduct), {
              status: action.payload.status
            });
          } else {
            return __spreadProps(__spreadValues({}, taggedProduct), {
              status: ImageDraggableRectStatus.COMPLETE
            });
          }
        });
        const isEditable = draft.taggedProducts.find(
          (taggedProduct) => taggedProduct.status === ImageDraggableRectStatus.EDITABLE
        );
        draft.sidebarStatus = isEditable ? AssignProductsSidebarStepType.STEP_ONE : AssignProductsSidebarStepType.STEP_TWO;
      });
    case ACTIONS.ASSIGN_PRODUCT:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts.map((product) => {
          if (product.status === ImageDraggableRectStatus.ASSIGN) {
            return __spreadProps(__spreadValues({}, product), {
              productId: action.payload.productDetails.id,
              rectId: action.payload.rectId
            });
          } else {
            return product;
          }
        });
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_THREE;
      });
    case ACTIONS.DELETE_PRODUCT_TAG.SUCCESS:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts.filter((product) => product.rectId !== action.payload.rectId);
        draft.isPending = false;
      });
    case ACTIONS.UPDATE_RECT_UI:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts.map((product) => {
          if (product.rectId === action.payload.rectId) {
            return __spreadValues(__spreadValues({}, product), action.payload);
          } else {
            return product;
          }
        });
        draft.currentRect = action.payload;
      });
    case ACTIONS.SELECT_PRODUCT:
      return produce(state, (draft) => {
        draft.selectedProduct = __spreadValues({}, action.payload);
      });
    case ACTIONS.HOVER_PRODUCT:
      return produce(state, (draft) => {
        draft.hoveredProduct = action.payload;
      });
    case ACTIONS.UN_HOVER_PRODUCT:
      return produce(state, (draft) => {
        draft.hoveredProduct = void 0;
      });
    case ACTIONS.UNSELECT_PRODUCT:
      return produce(state, (draft) => {
        draft.selectedProduct = void 0;
      });
    case ACTIONS.RESTORE_DEFAULT_TAGGED_PRODUCTS:
      return produce(state, (draft) => {
        draft.taggedProducts = draft.taggedProducts ? draft.taggedProducts.map((taggedProduct) => __spreadProps(__spreadValues({}, taggedProduct.attributes), {
          product_id: void 0,
          productId: taggedProduct.id,
          rectId: taggedProduct.id,
          status: ImageDraggableRectStatus.COMPLETE
        })) : [];
      });
    default:
      return state;
  }
};
export const getTaggedProducts = (state) => state ? state.taggedProducts.find(
  (e) => e.status === ImageDraggableRectStatus.EDITABLE || e.status === ImageDraggableRectStatus.UNASSIGNED || e.status === ImageDraggableRectStatus.ASSIGN
) : void 0;
const mergeProductTagsWithProducts = (data) => {
  const products = data.filter((e) => e.type === "product");
  const productTags = data.filter((e) => e.type === "product_tag");
  return productTags.map((productTag) => {
    const product = products.find(
      (e) => productTag.relationships && productTag.relationships.product && productTag.relationships.product.data && productTag.relationships.product.data.id === e.id
    );
    const productDetails = product ? product.attributes : {};
    return __spreadProps(__spreadValues({}, productTag), {
      attributes: __spreadProps(__spreadValues(__spreadValues({}, productTag.attributes), productDetails), {
        product_id: product.id
      })
    });
  });
};
