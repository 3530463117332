import React from "react";
import PropTypes from "prop-types";
import "./tag-o-war-section-winner.sass";
export const drawTranslate = "Draw!";
const TagOWarSectionWinner = ({ socialFeedLeft, socialFeedRight, percentLeft, percentRight }) => {
  let winner = "";
  if (percentLeft > percentRight) {
    winner = socialFeedLeft.label;
  }
  if (percentLeft < percentRight) {
    winner = socialFeedRight.label;
  }
  if (percentRight === percentLeft) {
    winner = drawTranslate;
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-tag-o-war-winner", "data-testid": "tagOWarWinner" }, winner === drawTranslate ? /* @__PURE__ */ React.createElement("span", { "data-testid": "winner" }, /* @__PURE__ */ React.createElement("strong", null, winner)) : /* @__PURE__ */ React.createElement("span", { "data-testid": "winner" }, /* @__PURE__ */ React.createElement("strong", null, winner, " "), "won!")));
};
export default TagOWarSectionWinner;
TagOWarSectionWinner.propTypes = {
  winner: PropTypes.string
};
