import { createPrefixedActionName } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  SAVE_CANVAS_STATE: createPrefixedActionName("ASSET_MANAGER_TRANSFORM_UI", "SAVE_CANVAS_STATE"),
  SAVE_DEFAULT_CANVAS_STATE: createPrefixedActionName("ASSET_MANAGER_TRANSFORM_UI", "SAVE_DEFAULT_CANVAS_STATE"),
  UNDO_CANVAS: createPrefixedActionName("ASSET_MANAGER_TRANSFORM_UI", "UNDO_CANVAS"),
  REDO_CANVAS: createPrefixedActionName("ASSET_MANAGER_TRANSFORM_UI", "REDO_CANVAS"),
  CLEAR_HISTORY: createPrefixedActionName("ASSET_MANAGER_TRANSFORM_UI", "CLEAR_HISTORY")
};
export const clearAssetManagerTransformUIHistory = () => ({
  type: ACTIONS.CLEAR_HISTORY
});
export const saveCanvasState = (payload) => ({
  type: ACTIONS.SAVE_CANVAS_STATE,
  payload
});
export const saveDefaultCanvasState = (payload) => ({
  type: ACTIONS.SAVE_DEFAULT_CANVAS_STATE,
  payload
});
export const undoCanvas = () => ({
  type: ACTIONS.UNDO_CANVAS
});
export const redoCanvas = () => ({
  type: ACTIONS.REDO_CANVAS
});
