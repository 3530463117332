var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Config } from "../../utils/config";
import store from "../../store";
import axios from "axios";
const _bulkHeaders = {
  "Content-Type": "application/vnd.api+json; ext=bulk",
  Accept: "application/vnd.api+json; ext=bulk"
};
let _headers = { "Content-Type": "application/vnd.api+json" };
let teamId;
let _authInterceptor;
const excludeTeamArray = ["/me?", "authentication_factors", "/teams", "notifications", "/plans"];
const teamIdKey = "TINT_TEAM_ID";
const axiosInstance = axios.create({
  baseURL: Config.getApiUrl() + Config.apiVersion,
  headers: _headers
});
let isRefreshing = false;
let requestRefreshTokenFn = null;
let redirectToLoginFn = null;
const addErrorInterceptor = ({ errorCode, fn }) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (errorCode === error.response.status) {
        return fn(error);
      }
      return Promise.reject(error);
    }
  );
};
const getTeamPrefix = ({ path = "", config }) => {
  if (config && config.forceNoTeamPrefix) return "";
  if (teamId) {
    return excludeTeamArray.some((e) => path.includes(e)) ? "" : `teams/${teamId}`;
  }
  return "";
};
const setTeamId = (id, force = false) => {
  const localStorageTeamId = window.localStorage.getItem(teamIdKey);
  if (!localStorageTeamId) {
    teamId = id;
  } else if (localStorageTeamId && force) {
    teamId = id;
  } else {
    teamId = localStorageTeamId;
  }
  window.localStorage.setItem(teamIdKey, teamId);
};
const getTeamId = () => {
  const localStorageTeamId = window.localStorage.getItem(teamIdKey);
  if (localStorageTeamId) {
    teamId = localStorageTeamId;
  }
  return teamId;
};
const get = (path, config) => {
  return axiosInstance.get(getTeamPrefix({ path, config }) + path, config);
};
const post = (path, data, config) => {
  return axiosInstance.post(getTeamPrefix({ path, config }) + path, data, config);
};
const put = (path, data, config) => {
  return axiosInstance.put(getTeamPrefix({ path }) + path, data, config);
};
const patch = (path, data, config) => {
  let _path;
  if (path === "/me") {
    _path = "/me";
  } else {
    _path = getTeamPrefix({ path }) + path;
  }
  return axiosInstance.patch(_path, data, config);
};
const deleteRequest = (path, config, data = {}) => {
  return axiosInstance.delete(getTeamPrefix({ path, config }) + path, __spreadProps(__spreadValues({}, config), { data }));
};
const setAuth = ({ token, requestRefreshToken, redirectToLogin }) => {
  const session = store.getState().session.data;
  const auth = token || store.getState().session.data && session.access_token;
  if (requestRefreshToken) {
    requestRefreshTokenFn = requestRefreshToken;
  }
  if (redirectToLogin) {
    redirectToLoginFn = redirectToLogin;
  }
  removeAuth();
  _authInterceptor = axiosInstance.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${auth}`;
    return request;
  });
};
const handle401Error = (error) => __async(void 0, null, function* () {
  if (isRefreshing) {
    return axiosInstance(error.config);
  }
  isRefreshing = true;
  try {
    const data = yield requestRefreshTokenFn();
    if (data !== "error") {
      isRefreshing = false;
    }
  } catch (refreshError) {
    redirectToLoginFn();
    return Promise.reject(refreshError);
  }
});
const removeAuth = () => {
  axiosInstance.interceptors.request.eject(_authInterceptor);
};
const getBulkHeaders = () => _bulkHeaders;
const pureInstance = () => axios.create({
  baseURL: Config.getApiUrl(),
  headers: _headers
});
export const ApiService = {
  get,
  post,
  put,
  patch,
  delete: deleteRequest,
  getBulkHeaders,
  setAuth,
  removeAuth,
  pureInstance,
  setTeamId,
  getTeamId,
  clearLocalStorageTeamId: () => window.localStorage.removeItem(teamIdKey),
  getTeamPrefix,
  addErrorInterceptor,
  handle401Error
};
