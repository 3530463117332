var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import PreloaderComponent from "../preloaders/preloader/preloader";
import "./thumbnail.sass";
import useImageValidation from "../../hooks/use-image-validation/use-image-validation";
import PropTypes from "prop-types";
const TintThumbnail = ({ imageUrl, style, type, noAlt = false }) => {
  const { newImageUrl, isPending } = useImageValidation(imageUrl);
  const defaultStyle = {
    width: "48px",
    height: "48px"
  };
  const typeClassName = type === "rigid" ? "tint-thumbnail--rigid" : "";
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-thumbnail ${typeClassName} ${noAlt && "tint-thumbnail--no-image"}`,
      style: __spreadValues(__spreadValues({}, defaultStyle), style)
    },
    isPending ? /* @__PURE__ */ React.createElement(PreloaderComponent, null) : noAlt ? /* @__PURE__ */ React.createElement("img", { src: imageUrl, "data-testid": "imageUrl" }) : /* @__PURE__ */ React.createElement("img", { src: newImageUrl, "data-testid": "imageUrl" })
  );
};
export default TintThumbnail;
TintThumbnail.propTypes = {
  imageUrl: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.any
};
