import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { slackDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { validationSchemaSlack } from "../utils/validation-schema/slack-validation";
export const slack = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "slack",
    selectedOption: { id: "slack", title: "Add Slack" },
    header: "Add Slack",
    description: "To ingest Slack content, please select an account and channel below:",
    formSchema: {
      slack: {
        validationSchema: validationSchemaSlack(),
        submitMapper: (values) => {
          return {
            account_id: values.account.value,
            external_id: values.channel.value,
            search_term: values.term
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "channel",
            backendId: "external_id",
            label: "SELECT CHANNEL",
            placeholder: "Select Channel...",
            icon: SidebarIcons.HASHTAG,
            type: SidebarItemTypes.SELECT,
            disabled: slackDisabledButton
          },
          {
            id: "term",
            backendId: "search_term",
            label: "ENTER KEYWORD",
            placeholder: "Keyword",
            icon: SidebarIcons.SEARCH,
            type: SidebarItemTypes.TEXT,
            disabled: slackDisabledButton
          }
        ]
      }
    }
  }
];
