import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  POST_COMMENT: createRoutine(createPrefixedActionName("EDITOR_COMMENTS", "POST_COMMENT"))
};
export const postCommentStart = () => ({
  type: ACTIONS.POST_COMMENT.REQUEST
});
export const postCommentSuccess = (data) => ({
  type: ACTIONS.POST_COMMENT.SUCCESS,
  payload: data
});
export const postCommentError = (err) => ({
  type: ACTIONS.POST_COMMENT.FAILURE,
  payload: err
});
