var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useCallback, useEffect, useState } from "react";
import "./uploader.sass";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { UploaderPreview } from "./preview/uploader-preview";
import { useUploader } from "../hooks/use-uploader/use-uploader";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType
} from "../interface/preloaders/preloader/preloader";
import { UploaderDropArea } from "./drop-area/uploader-drop-area";
export const Uploader = ({
  singleUpload,
  onUpload,
  onPending,
  onRemoveAsset,
  assets = [],
  openUGCModal,
  isPreview = true,
  errors,
  url,
  acceptOnlyImages
}) => {
  const [previewList, setPreviews] = useState([]);
  useEffect(() => {
    setPreviews((s) => removeDuplicates([...s, ...assets]));
  }, [assets]);
  const removeDuplicates = (data) => Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
  const onUploadSuccess = (files) => {
    if (!singleUpload) {
      const uniqueFiles = removeDuplicates([...previewList, ...files]).filter(Boolean);
      setPreviews(uniqueFiles);
      onUpload == null ? void 0 : onUpload(uniqueFiles);
    } else {
      if (!(files == null ? void 0 : files[0])) {
        return;
      }
      setPreviews(files[0].preview);
      onUpload(files[0]);
    }
  };
  const { onUploadFiles, isPending } = useUploader({ onUploadSuccess, onPending, url });
  const onUploadAssets = (files) => __async(void 0, null, function* () {
    yield onUploadFiles(files);
  });
  const handleFileDrop = useCallback(
    (files) => __async(void 0, null, function* () {
      if (files) {
        yield onUploadAssets(files);
      }
    }),
    [onUploadAssets]
  );
  const onRemoveFile = (file, index) => {
    const filteredFiles = previewList.filter((el, i) => i !== index);
    setPreviews(filteredFiles);
    onRemoveAsset == null ? void 0 : onRemoveAsset(index);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-uploader" }, /* @__PURE__ */ React.createElement(DndProvider, { backend: HTML5Backend }, isPending && /* @__PURE__ */ React.createElement(
    PreloaderComponent,
    {
      positionType: PreloaderPositionType.ABSOLUTE,
      backdropType: PreloaderBackdropType.TRANSPARENT
    }
  ), isPreview && /* @__PURE__ */ React.createElement(UploaderPreview, { fileList: previewList, onRemoveFile, errors }), /* @__PURE__ */ React.createElement(
    UploaderDropArea,
    {
      onDrop: handleFileDrop,
      onUGCClick: openUGCModal,
      onUpload: onUploadAssets,
      acceptOnlyImages
    }
  )));
};
