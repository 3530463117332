var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./edit-timer-content.sass";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { renderEditInput } from "../../page-section-utils";
import { dropDownType } from "../../../../../../../model/poll-image-type.model";
import { EditInputDropdown } from "../../../";
import { EditTime } from "./edit-time/edit-time";
const editInputs = [
  { property: "labelDays", label: "default label - days", placeholder: "Days" },
  {
    property: "labelHours",
    label: "default label - hours",
    placeholder: "Hours"
  },
  {
    property: "labelMinutes",
    label: "default label - minutes",
    placeholder: "Minutes"
  },
  {
    property: "labelSeconds",
    label: "default label - seconds",
    placeholder: "Seconds"
  },
  {
    property: "endTitle",
    label: "default label - end",
    placeholder: "End title"
  }
];
export class EditTimerContent extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "onDateChange", (date) => {
      this.props.dataUpdate({
        endDate: new Date(date)
      });
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Customize your countdown timer by inputting a date and time below." }, /* @__PURE__ */ React.createElement(
      "form",
      {
        className: "tint-edit-timer-content",
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement(EditTime, { data: this.props.data, minDate: this.props.minDate, onDateChange: this.onDateChange }),
      /* @__PURE__ */ React.createElement(
        EditInputDropdown,
        {
          label: "End Date Format",
          defaultValue: this.props.data.dateType,
          options: [
            { value: dropDownType.US, name: "MM/DD/YYYY (US)" },
            {
              value: dropDownType.INTERNATIONAL,
              name: "DD/MM/YYYY (international)"
            }
          ],
          onChange: (e) => this.props.dataUpdate({ dateType: e.target.value })
        }
      ),
      /* @__PURE__ */ React.createElement("p", { className: "tint-edit-timer-content__property-title" }, "Customize default countdown timer labels below for your preferred language."),
      editInputs.map(
        (e, i) => renderEditInput(
          this.props.getTranslation(this.props.data[e.property]),
          (v) => this.props.onTranslationUpdate(this.props.data[e.property], v.target.value),
          e.label,
          i,
          e.placeholder
        )
      )
    ));
  }
}
