import React from "react";
import PropTypes from "prop-types";
import "./assets-select-checkbox.sass";
export const AssetsSelectCheckbox = ({ onClick, isChecked, icon = "fas fa-check" }) => {
  const click = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };
  return /* @__PURE__ */ React.createElement("div", { onClick: click, className: `asset-select-checkbox ${isChecked ? "asset-select-checkbox--selected" : ""}` }, isChecked ? /* @__PURE__ */ React.createElement("span", { className: icon }) : null);
};
AssetsSelectCheckbox.propTypes = {
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  icon: PropTypes.string
};
