import React, { useState, useRef } from "react";
import "./slider.sass";
import TintButtonIcon, { TintButtonIconTypes } from "../button-icon/button-icon";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useResize } from "../../hooks/use-resize/use-resize";
const CarouselArrowNav = {
  LEFT: "left",
  RIGHT: "right"
};
const Slider = ({ children }) => {
  const [currentState, setCurrentState] = useState(1);
  const [sliderWidth, setCarouselWidth] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const sliderRef = useRef();
  const slidesLength = children.length;
  const shouldDisplayNav = slidesLength > 1;
  const debounceDelay = 300;
  const setCurrentWidth = (currentWidth) => {
    setCarouselWidth(currentWidth);
    setTranslateX(0 - (currentState - 1) * sliderWidth);
  };
  useResize({
    refCurrent: sliderRef.current,
    setWidth: setCurrentWidth,
    delay: debounceDelay
  });
  const onRightArrowClick = () => {
    if (currentState === slidesLength) {
      setTranslateX(0);
      setCurrentState(1);
    } else {
      setTranslateX(translateX - sliderWidth);
      setCurrentState((count) => count + 1);
    }
  };
  const onPrevArrowClick = () => {
    if (currentState === 1) {
      setTranslateX(0 - sliderWidth * (slidesLength - 1));
      setCurrentState(slidesLength);
    } else {
      setTranslateX(translateX + sliderWidth);
      setCurrentState((count) => count - 1);
    }
  };
  const onSliderNavClick = (stateCount) => {
    setTranslateX(0 - (stateCount - 1) * sliderWidth);
    setCurrentState(stateCount);
  };
  const onArrowNavClick = (type) => {
    switch (type) {
      case CarouselArrowNav.RIGHT:
        onRightArrowClick();
        break;
      case CarouselArrowNav.LEFT:
        onPrevArrowClick();
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-slider", ref: sliderRef }, /* @__PURE__ */ React.createElement("div", { className: "tint-slider__wrapper" }, shouldDisplayNav && /* @__PURE__ */ React.createElement(
    TintButtonIcon,
    {
      color: "#fff",
      type: TintButtonIconTypes.GREY,
      icon: faAngleLeft,
      className: "tint-slider-arrow-nav tint-slider-arrow-nav--prev",
      onClick: () => onArrowNavClick(CarouselArrowNav.LEFT)
    }
  ), /* @__PURE__ */ React.createElement("ul", { className: "tint-slider-list", style: { transform: `translateX(${translateX}px)` } }, React.Children.map(
    children,
    (child, i) => child && /* @__PURE__ */ React.createElement("li", { key: i, className: "tint-slider-list__item" }, React.cloneElement(child))
  )), shouldDisplayNav && /* @__PURE__ */ React.createElement(
    TintButtonIcon,
    {
      color: "#fff",
      type: TintButtonIconTypes.GREY,
      icon: faAngleRight,
      className: "tint-slider-arrow-nav tint-slider-arrow-nav--next",
      onClick: () => onArrowNavClick(CarouselArrowNav.RIGHT)
    }
  )), shouldDisplayNav && /* @__PURE__ */ React.createElement("nav", { className: "tint-slider-nav" }, React.Children.map(
    children,
    (child, i) => child && /* @__PURE__ */ React.createElement(
      "li",
      {
        key: i,
        onClick: () => onSliderNavClick(i + 1),
        className: `tint-slider-nav__item ${currentState === i + 1 ? "tint-slider-nav__item--active" : ""}`
      }
    )
  )));
};
export default Slider;
