var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { COMMON_SETTINGS } from "./common-settings";
import { languageList } from "../configs/personalization-languages";
export const TICKER = {
  name: "Ticker",
  key: "ticker",
  premiumDesign: true,
  default_settings: __spreadProps(__spreadValues({}, COMMON_SETTINGS), {
    relative_font_size: "80",
    max_height: "200",
    shape: "Rounded",
    scroll_speed: "10",
    hide_urls: false,
    hide_timeago: false,
    themeName: "ticker"
  }),
  guards: {
    CTA: false,
    waterfall: false,
    popup: true,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true
  },
  themes_settings: [
    {
      key: "max_height",
      type: "slider",
      label: "Maximum height",
      min: "50",
      max: "400"
    },
    {
      key: "shape",
      label: "Author Image Shape",
      type: "switcher",
      values: [
        {
          value: "circle",
          label: "Circle"
        },
        {
          value: "rounded",
          label: "Rounded"
        },
        {
          value: "square",
          label: "Square"
        }
      ]
    },
    {
      key: "scroll_speed",
      type: "slider",
      label: "Scroll speed",
      min: "0",
      max: "100"
    },
    {
      key: "hide_urls",
      type: "checkbox",
      label: "Hide URLs in posts"
    },
    {
      key: "hide_timeago",
      type: "checkbox",
      label: "Hide post timestamp"
    }
  ],
  font_settings: [
    {
      key: "fontcolor_post",
      label: "Font Color",
      type: "colorPicker"
    },
    {
      key: "relative_font_size",
      type: "slider",
      label: "Relative font size",
      min: "50",
      max: "120"
    },
    {
      key: "color_namebar_buttons",
      label: "Accent Color",
      type: "colorPicker"
    }
  ],
  extra_settings: [
    {
      key: "time_language",
      type: "dropdown",
      label: "Language for post timestamp",
      options: languageList
    }
  ],
  extra_popup_settings: [
    // {
    //   key: 'popup_products_visibility',
    //   label: 'Hide Product Hotspots',
    //   control_type: 'Checkbox',
    //   target: '#personalize-popup-controls',
    //   add_class: 'control-divide-above',
    //   required_flags: ['ecommerce'],
    // },
  ]
};
