import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const acceptance = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ACCEPTANCE]: {
    display: "flex",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ACCEPTANCE_LABEL]: {
    cursor: "pointer",
    margin: "4px 0 0 12px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ACCEPTANCE_RTL]: {
    margin: "4px 12px 0 0"
  }
};
