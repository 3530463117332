var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import "./tint-mix-scene-navigation-bar.sass";
import InputPrimary from "../../../../components/interface/inputs/input-primary/input-primary";
import { Config } from "../../../../utils/config";
import { useHistory } from "react-router";
import { Button } from "../../../../components/interface/button/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import useDebounce from "../../../../components/hooks/use-debounce/use-debounce";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownCurrentUsageList, {
  isLimitReached
} from "../../../../components/interface/dropdown/lists/current-usage-list/dropdown-current-usage-list";
import useUsagePlanLimits from "../../../../components/hooks/use-plan/use-usage-plan-limits";
import { useSelector } from "react-redux";
import { getCurrentTintMix } from "../../reducers/tint-mix.reducer";
export const defaultTintMixName = "Untitled Mix";
export const TintMixSceneNavigationBar = ({ title = defaultTintMixName, onChangeName, onAddScene }) => {
  var _a, _b, _c, _d, _e, _f;
  const history = useHistory();
  const [inputValue, setInputValue] = useState(title);
  const tintMix = useSelector(getCurrentTintMix);
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      tintMixSceneName: inputValue
    },
    validationSchema: Yup.object().shape({
      tintMixSceneName: Yup.string()
    })
  });
  const debounceInputValue = useDebounce(inputValue, 1e3);
  useEffect(() => {
    inputValue !== title && onChangeName(inputValue);
  }, [debounceInputValue]);
  const planList = ["tint_limit", "image_limit", "media_limit"];
  const plans = useUsagePlanLimits(planList, "tint_mix_config");
  const currentUsageList = [
    {
      name: "Images",
      activeCount: (_a = plans == null ? void 0 : plans.image_limit) == null ? void 0 : _a.count,
      activeOnPlan: (_b = plans == null ? void 0 : plans.image_limit) == null ? void 0 : _b.plan
    },
    {
      name: "Media",
      activeCount: (_c = plans == null ? void 0 : plans.media_limit) == null ? void 0 : _c.count,
      activeOnPlan: (_d = plans == null ? void 0 : plans.media_limit) == null ? void 0 : _d.plan
    },
    {
      name: "Tints",
      activeCount: (_e = plans == null ? void 0 : plans.tint_limit) == null ? void 0 : _e.count,
      activeOnPlan: (_f = plans == null ? void 0 : plans.tint_limit) == null ? void 0 : _f.plan
    }
  ].filter((plan) => plan.activeOnPlan);
  const onPreview = () => {
    window.open(Config.routePaths.newTintMix.mix.preview.createUrl(tintMix.id), "_blank", "noopener,noreferrer");
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-mix-scene-navigation-bar" }, /* @__PURE__ */ React.createElement("div", { className: "tint-mix-scene-navigation-bar__wrapper" }, /* @__PURE__ */ React.createElement(
    "span",
    {
      className: "tint-mix-scene-navigation-bar__back-button far fa-angle-left ",
      onClick: () => history.push(Config.routePaths.newTintMix.path)
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "tint-mix-scene-navigation-bar__title" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      name: "tintMixSceneName",
      type: "text",
      value: formik.values.tintMixSceneName,
      placeholder: formik.values.tintMixSceneName,
      error: formik.errors.tintMixSceneName,
      handleBlur: formik.handleBlur,
      touched: formik.touched.tintMixSceneName,
      handleChange: (e) => {
        setInputValue(e.target.value);
        return formik.handleChange(e);
      }
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "tint-mix-scene-navigation-bar__actions" }, currentUsageList.length !== 0 && /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownCurrentUsageList, __spreadProps(__spreadValues({}, props), { headerTitle: "Media Limits", hasUpgradeButton: false })),
      list: currentUsageList,
      iconCenter: "fas fa-chart-pie-alt",
      iconOverlap: isLimitReached(currentUsageList) ? "fas fa-exclamation-circle" : void 0,
      buttonType: ButtonType.BUTTON_ICON_DARK
    }
  ), /* @__PURE__ */ React.createElement(Button, { type: "primary", text: "+Add Scene", onClick: onAddScene }), /* @__PURE__ */ React.createElement(Button, { type: "secondary", text: "View Mix", onClick: onPreview })));
};
