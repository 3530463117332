import React, { Component } from "react";
import "./back-to-top.sass";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditBackToTopStyles } from "./edit/edit-back-to-top.styles";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { animateScroll as scroll } from "react-scroll";
import ColorParser from "../../../../../../services/color-parser/color-parser";
class BackToTop extends Component {
  scrollToTop() {
    scroll.scrollToTop();
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--back-to-top ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
          height: this.props.data.styles.sectionPadding ? "auto" : "200px"
        }
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "tint-page-section--back-to-top__back-button",
          onClick: this.scrollToTop.bind(this),
          style: {
            borderColor: ColorParser.defaultColor(this.props.data.styles.buttonBorderColor),
            backgroundColor: ColorParser.defaultColor(this.props.data.styles.buttonBackgroundColor)
          }
        },
        /* @__PURE__ */ React.createElement(
          "span",
          {
            style: {
              color: ColorParser.defaultColor(this.props.data.styles.buttonTextColor)
            }
          },
          "BACK TO TOP"
        )
      ),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Back To Top" }, /* @__PURE__ */ React.createElement(
          EditBackToTopStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        ))
      )
    );
  }
}
export default PageSectionHoc(BackToTop);
