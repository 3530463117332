var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useUsagePlanLimits = (planList, planKey) => {
  const session = useSelector((state) => state.session);
  const plan = session.data.currentPlan;
  const team = session.data.currentTeam;
  const [plans, setPlans] = useState();
  const getPlanValue = (key) => {
    var _a, _b;
    return {
      [key]: {
        plan: plan.attributes[key] || 0,
        count: ((_b = (_a = team.relationships[key]) == null ? void 0 : _a.meta) == null ? void 0 : _b.count) || 0
      }
    };
  };
  const getPlanKeyValue = (key) => {
    return {
      [key]: {
        plan: plan.attributes[planKey] && plan.attributes[planKey][key] ? plan.attributes[planKey][key] : 0,
        count: team.relationships[planKey] && team.relationships[planKey][key] ? team.relationships[planKey][key].meta.count : 0
      }
    };
  };
  const preparePlans = () => {
    const mapPlans = planKey ? planList.map((plan2) => getPlanKeyValue(plan2)) : planList.map((plan2) => getPlanValue(plan2));
    const reducePlans = mapPlans.reduce((obj, item) => {
      return __spreadValues(__spreadValues({}, obj), item);
    }, {});
    setPlans(reducePlans);
  };
  useEffect(() => {
    if (!team || !plan) return;
    preparePlans();
  }, []);
  return plans;
};
export default useUsagePlanLimits;
