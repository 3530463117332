import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditUploadDesign } from "../../../";
import { EditColorInput } from "../../../";
export class EditSocialStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Display icons for your accounts. Insert URLs to add social icons to the layout." }, /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: this.props.data.styles.iconBackgroundColor,
        label: "background icon color",
        onChange: (value) => {
          this.props.styleDataUpdate({ iconBackgroundColor: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditColorInput,
      {
        color: this.props.data.styles.iconColor,
        label: "Icon color",
        onChange: (value) => {
          this.props.styleDataUpdate({ iconColor: value });
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditUploadDesign,
      {
        dataUpdate: this.props.dataUpdate,
        styleDataUpdate: this.props.styleDataUpdate,
        data: this.props.data
      }
    ));
  }
}
