var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/social-connections/social-connections.actions";
export const initialState = {
  toggled: []
};
export const socialFeedsToTransfer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_TO_TRANSFER:
      return __spreadProps(__spreadValues({}, state), {
        toggled: state.toggled.includes(action.payload) ? [...state.toggled.filter((id) => id != action.payload)] : [...state.toggled, action.payload]
      });
    case ACTIONS.SELECT_ALL_TO_TRANSFER:
      return __spreadProps(__spreadValues({}, state), {
        toggled: [...action.payload]
      });
    case ACTIONS.CLEAR_TO_TRANSFER:
      return __spreadProps(__spreadValues({}, state), {
        toggled: []
      });
    default:
      return state;
  }
};
