import React from "react";
import { Button } from "../../../interface/button/button";
import "./sidebar-footer.sass";
import PreloaderComponent, {
  PreloaderSizeType,
  PreloaderPositionType,
  PreloaderBackdropColorType,
  PreloaderBackdropType
} from "../../../interface/preloaders/preloader/preloader";
export const SidebarFooterClickButtonAction = {
  LEFT_BUTTON: "left",
  RIGHT_BUTTON: "right"
};
export const SidebarFooter = ({
  onClickButton,
  leftButtonText = "Cancel",
  rightButtonText = "Continue",
  isRightButtonDisabled = false,
  isLeftButtonDisabled = false,
  oneButton = false,
  isSubmitting
}) => {
  return oneButton ? /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-footer" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      id: "tint-sidebar-footer__button--one",
      action: "submit",
      type: "secondary",
      text: leftButtonText,
      onClick: () => onClickButton(SidebarFooterClickButtonAction.LEFT_BUTTON),
      isDisabled: isLeftButtonDisabled
    }
  )) : /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-footer" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      id: "tint-sidebar-footer__button--left",
      action: "submit",
      type: "secondary",
      text: leftButtonText,
      onClick: () => onClickButton(SidebarFooterClickButtonAction.LEFT_BUTTON),
      isDisabled: isLeftButtonDisabled
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      id: "tint-sidebar-footer__button--right",
      action: "submit",
      type: "primary",
      className: "tint-sidebar-footer__button",
      isDisabled: isRightButtonDisabled,
      onClick: isSubmitting ? () => {
      } : () => onClickButton(SidebarFooterClickButtonAction.RIGHT_BUTTON)
    },
    isSubmitting ? /* @__PURE__ */ React.createElement(
      PreloaderComponent,
      {
        size: PreloaderSizeType.SMALL,
        positionType: PreloaderPositionType.ABSOLUTE,
        backdropColor: PreloaderBackdropColorType.TRANSPARENT,
        backdropType: PreloaderBackdropType.TRANSPARENT
      }
    ) : null,
    rightButtonText
  ));
};
