var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { useCurrentAssetSettingList } from "../../hooks/use-current-asset-setting-list";
export const AssetStatus = {
  ARCHIVED: "archived",
  ACTIVE: "active",
  DELETED: "deleted"
};
export const AssetSettingsDropdown = ({ selectedAssetLength, selectedAssets, selectedVariantIds, variantUrl }) => {
  const { currentSettingsList, onSettingChange: onSettingDropdownChange } = useCurrentAssetSettingList({
    selectedAssets,
    selectedAssetLength,
    selectedVariantIds,
    variantUrl
  });
  return /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      list: currentSettingsList,
      iconCenter: "fas fa-ellipsis-h",
      buttonType: ButtonType.BUTTON_PRIMARY,
      onChangeValue: onSettingDropdownChange
    }
  );
};
export default AssetSettingsDropdown;
