import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const header = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HEADER_H1]: {
    margin: 0,
    color: "#000000",
    "font-size": "32px",
    "font-weight": "700"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HEADER_P]: {
    margin: 0,
    color: "#727272",
    "font-size": "16px"
  }
};
