import React, { useRef } from "react";
import "./dropdown-sort-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import { DropdownHeader } from "../../button/header/dropdown-header";
import { Button } from "../../../button/button";
import TintIcon from "../../../icon/icon";
const DropdownSortList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems,
  expandToTop,
  styles,
  dropdownHeaderStyle,
  blankOption
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const DropdownTextElement = (title) => {
    return /* @__PURE__ */ React.createElement(
      DropdownHeader,
      {
        iconLeft,
        iconRight,
        iconCenter,
        title,
        dropdownHeaderStyle
      }
    );
  };
  let _currentOptions = blankOption ? [
    {
      value: null,
      name: " ",
      blankOption: true
    },
    ...currentItems
  ] : currentItems;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "ul",
    {
      className: `dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--${expandToTop ? "expand-top" : ""}`,
      style: styles,
      ref: elementRef,
      "data-testid": "dropdown-simple-list"
    },
    _currentOptions.map((item, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item${(item == null ? void 0 : item.type) === "button" ? " dropdown-list__item--button" : ""}${(item == null ? void 0 : item.isDisabled) ? " dropdown-list__item--disabled" : ""}`,
        key: i,
        onClick: () => {
          if (!item.isDisabled) {
            onChange(item);
            onChangeTextComponent(DropdownTextElement(item.name));
          }
        }
      },
      (item == null ? void 0 : item.icon) && typeof item.icon === "string" && /* @__PURE__ */ React.createElement("span", { className: `icon ${item.icon}` }),
      (item == null ? void 0 : item.icon) && typeof item.icon !== "string" && /* @__PURE__ */ React.createElement("span", { className: "icon icon__left" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: item.icon })),
      (item == null ? void 0 : item.type) === "button" ? /* @__PURE__ */ React.createElement(Button, { type: "gray", text: item.name }) : /* @__PURE__ */ React.createElement("span", null, !(item == null ? void 0 : item.blankOption) ? item.name : item == null ? void 0 : item.blankOption)
    ))
  ));
};
export default DropdownSortList;
DropdownSortList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconCenter: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
