import React, { useState, useCallback, useRef } from "react";
import "./dropdown-search-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import { DropdownHeader } from "../../button/header/dropdown-header";
import { DropdownSearchInput } from "../../seach-input/dropdown-search-input";
import useSelectListElement from "../../../autocomplete-list/useSelectListElement";
import debounce from "lodash.debounce";
const debounceDelay = 300;
const DropdownSearchList = ({
  onChange,
  currentItems,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  title,
  isDefaultOption = true,
  shouldRerenderHeader = true,
  onLoadData,
  setFilterValue,
  filterValue,
  currentAccountId,
  isPending
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const [searchTerm, setSearchTerm] = useState(filterValue ? filterValue : "");
  const { currentElement, isSelected } = useSelectListElement({
    listLength: currentItems == null ? void 0 : currentItems.length,
    onEnterClick: (i) => {
      (currentItems == null ? void 0 : currentItems[i]) ? onSelect == null ? void 0 : onSelect(currentItems[i]) : i === -1 && onDefaultOptionSelect();
    },
    isDefaultOption
  });
  const onInputChange = (event) => {
    onLoadData && onLoadData(currentAccountId, event);
  };
  const updateInputField = useCallback(debounce(onInputChange, debounceDelay), []);
  const onSelect = (item) => {
    shouldRerenderHeader && onChangeTextComponent(DropdownTextElement(item.name));
    onChange(item);
  };
  const onDefaultOptionSelect = () => {
    shouldRerenderHeader && onChangeTextComponent(DropdownTextElement(title));
    onChange(void 0);
  };
  const DropdownTextElement = (title2) => {
    return /* @__PURE__ */ React.createElement(DropdownHeader, { iconLeft, iconRight, iconCenter, title: title2 });
  };
  const handleChange = (event) => {
    onLoadData && updateInputField(event.target.value);
    setSearchTerm(event.target.value);
    setFilterValue && setFilterValue(event.target.value);
  };
  const results = !searchTerm || onLoadData ? currentItems : currentItems.filter((item) => item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "ul",
    {
      className: `dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical}`,
      ref: elementRef,
      "data-testid": "dropdown-search-list"
    },
    (currentItems.length > 1 || searchTerm || isPending) && /* @__PURE__ */ React.createElement("li", { className: "dropdown-list__item dropdown-list__item--search", onClick: (e) => e.stopPropagation() }, /* @__PURE__ */ React.createElement(DropdownSearchInput, { onChange: handleChange, searchTerm })),
    isDefaultOption && /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item ${currentElement === 0 ? "dropdown-list__item--selected" : ""}`,
        onClick: () => {
          shouldRerenderHeader && onChangeTextComponent(DropdownTextElement(title));
          onChange(void 0);
        }
      },
      /* @__PURE__ */ React.createElement("span", null, "All")
    ),
    results.map((item, i) => /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item ${item.isActive ? "active" : ""} ${isSelected(i) ? "dropdown-list__item--selected" : ""}`,
        key: i,
        onClick: () => {
          shouldRerenderHeader && onChangeTextComponent(DropdownTextElement(item.name));
          onChange(item);
        }
      },
      item.icon ? /* @__PURE__ */ React.createElement("span", { className: `icon ${item.icon}` }) : null,
      /* @__PURE__ */ React.createElement("span", null, item.name)
    ))
  ));
};
export default DropdownSearchList;
DropdownSearchList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string
};
