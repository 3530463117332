import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./request-rights.sass";
import { toast } from "react-toastify";
import TintSidebar from "../sidebar/sidebar";
import { closeRequestRights, setRequestRightStep } from "../../actions/request-rights/request-rights.actions";
import AccordionTab from "../interface/accordion/accordion-tab/accordion-tab";
import Accordion from "../interface/accordion/accordion";
import { SelectComment } from "./components/select-comment/select-comment";
import { CommentsModal } from "./components/comments-modal/comments-modal";
import { postRequestRights } from "../../actions/request-rights/request-rights.fat-actions";
import { TermsConditions } from "./components/terms-conditions/terms-conditions";
import { isCommentSectionFilled, isTermsSectionFilled } from "../../reducers/request-rights/request-rights.reducer";
import { handleApiError } from "../../services/functions/error-handler/error-handler";
import copyToClipboard from "../../services/functions/copy-to-clipboard/copy-to-clipboard";
import { InstagramInstructionsModal } from "./components/instagram-instructions-modal/instagram-instructions-modal";
import { RequestPreview } from "./components/request-preview/request-preview";
import { isRequestRightsLoading } from "../../reducers/selectors/request-rights.selector";
const Icon = ({ status }) => {
  return /* @__PURE__ */ React.createElement("span", { className: `fas fa-check status-icon status-icon--${status}` });
};
export const REQUEST_RIGHTS_STEP = {
  PRIMARY: "PRIMARY",
  COMMENTS: "COMMENTS",
  TERMS_CONDITIONS: "TERMS_CONDITIONS",
  INSTAGRAM_INSTRUCTIONS: "INSTAGRAM_INSTRUCTIONS"
};
export const REQUEST_RIGHTS_SOURCE = {
  TWITTER: "twitter",
  INSTAGRAM_BUSINESS: "instagram_business"
};
const RequestRights = () => {
  const dispatch = useDispatch();
  const requestRights = useSelector((state) => state.requestRights);
  const isRequestRightsFetching = useSelector(isRequestRightsLoading);
  const onSubmit = () => {
    if (isCommentSectionFilled(requestRights) && isTermsSectionFilled(requestRights)) {
      dispatch(postRequestRights(requestRights.modal)).then((data) => {
        if (window.iframeRef) {
          window.iframeRef.contentWindow.postMessage({ type: "REQUEST_RIGHTS_SUCCESS" }, "*");
        }
        if (requestRights.modal.source === REQUEST_RIGHTS_SOURCE.INSTAGRAM_BUSINESS) {
          openInstagramPost(data);
        } else {
          dispatch(closeRequestRights());
          toast.success("We've sent your right request!");
        }
      }).catch((err) => {
        handleApiError(err, toast.error);
      });
    } else {
      toast.info("Fill the comment and terms section first");
    }
  };
  const openInstagramPost = (data) => {
    copyToClipboard(data.payload.data.attributes.text);
    dispatch(setRequestRightStep(REQUEST_RIGHTS_STEP.INSTAGRAM_INSTRUCTIONS));
  };
  const renderModal = (step) => {
    switch (step) {
      case REQUEST_RIGHTS_STEP.PRIMARY:
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
          TintSidebar,
          {
            isBackDropVisible: true,
            sidebarClassName: "tint-request-rights",
            title: "Request Rights",
            rightButtonText: "Apply",
            onClose: () => dispatch(closeRequestRights()),
            styles: { top: "60px" },
            onRightButtonClick: onSubmit,
            defaultFooterStyle: false,
            isRightButtonDisabled: isRequestRightsFetching,
            isOpen: requestRights.postMessageData !== void 0
          },
          /* @__PURE__ */ React.createElement(Accordion, { openedTabIndex: 0 }, /* @__PURE__ */ React.createElement(
            AccordionTab,
            {
              title: "Select Comment",
              icon: /* @__PURE__ */ React.createElement(Icon, { status: isCommentSectionFilled(requestRights) ? "active" : "" })
            },
            /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SelectComment, null))
          ), /* @__PURE__ */ React.createElement(
            AccordionTab,
            {
              title: "Terms & Conditions",
              icon: /* @__PURE__ */ React.createElement(Icon, { status: isTermsSectionFilled(requestRights) ? "active" : "" })
            },
            /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
              TermsConditions,
              {
                onEditClick: () => dispatch(setRequestRightStep(REQUEST_RIGHTS_STEP.TERMS_CONDITIONS))
              }
            ))
          ))
        ));
      case REQUEST_RIGHTS_STEP.COMMENTS:
        return /* @__PURE__ */ React.createElement(CommentsModal, null);
      case REQUEST_RIGHTS_STEP.INSTAGRAM_INSTRUCTIONS:
        return /* @__PURE__ */ React.createElement(InstagramInstructionsModal, null);
    }
  };
  return requestRights.postMessageData !== void 0 ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(RequestPreview, null), renderModal(requestRights.step)) : null;
};
export default RequestRights;
