import React, { Component } from "react";
import "./image-section.sass";
import { PageSectionHoc } from "../../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import ColorParser from "../../../../../../../services/color-parser/color-parser";
import { ImageContent } from "./edit/image-content";
import { EditModalSwitcher } from "../../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { ImageStyles } from "./edit/image-styles";
class ImageSection extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--image tint-custom-section tint-custom-section--image ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement(
        "img",
        {
          className: "tint-page-section-media__thumb",
          src: this.props.data.content.mediaUrl,
          alt: this.props.data.content.mediaUrl,
          style: {
            width: `${this.props.data.styles.imageWidth}%`
          }
        }
      ),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Image" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          ImageContent,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate,
            onContentUpdate: this.props.onContentUpdate,
            maxFileSize: this.props.getMaxImageSizePlanLimit()
          }
        ), /* @__PURE__ */ React.createElement(
          ImageStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      )
    );
  }
}
export default PageSectionHoc(ImageSection);
