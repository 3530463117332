var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { ACCOUNT_SETTINGS } from "../../consts";
export const ACTIONS = {
  GET_SUB_ACCOUNTS: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "GET_SUB_ACCOUNTS")),
  GET_SOCIAL_CONNECTIONS: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "GET_SOCIAL_CONNECTIONS")),
  GET_SOCIAL_CONNECTION: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "GET_SOCIAL_CONNECTION")),
  POST_SOCIAL_CONNECTION: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "POST_SOCIAL_CONNECTION")),
  GET_SOCIAL_CONNECTION_SUB_ACCOUNTS: createRoutine(
    createPrefixedActionName(ACCOUNT_SETTINGS, "GET_SOCIAL_CONNECTION_SUB_ACCOUNTS")
  ),
  GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN: createRoutine(
    createPrefixedActionName(ACCOUNT_SETTINGS, "GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN")
  ),
  FILTER_SOCIAL_ACCOUNTS: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "FILTER_SOCIAL_ACCOUNTS")),
  ADD_SOCIAL_CONNECTION: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "ADD_SOCIAL_CONNECTION")),
  DELETE_SOCIAL_CONNECTION: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "DELETE_SOCIAL_CONNECTION")),
  REFRESH_SOCIAL_CONNETION: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "REFRESH_SOCIAL_CONNETION")),
  PATCH_SOCIAL_FEED: createRoutine(createPrefixedActionName(ACCOUNT_SETTINGS, "PATCH_SOCIAL_FEED")),
  TOGGLE_TO_TRANSFER: createPrefixedActionName(ACCOUNT_SETTINGS, "TOGGLE_TO_TRANSFER"),
  CLEAR_TO_TRANSFER: createPrefixedActionName(ACCOUNT_SETTINGS, "CLEAR_TO_TRANSFER"),
  SELECT_ALL_TO_TRANSFER: createPrefixedActionName(ACCOUNT_SETTINGS, "SELECT_ALL_TO_TRANSFER")
};
export const getSocialConnectionsStart = () => ({
  type: ACTIONS.GET_SOCIAL_CONNECTIONS.REQUEST
});
export const getSocialConnectionsSuccess = (data) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTIONS.SUCCESS,
  payload: data
});
export const getSocialConnectionsError = (err) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTIONS.FAILURE,
  payload: err
});
export const getSocialConnectionSubAccountsStart = () => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.REQUEST
});
export const getSocialConnectionSubAccountsSuccess = (data) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.SUCCESS,
  payload: data
});
export const getSocialConnectionSubAccountsError = (err) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.FAILURE,
  payload: err
});
export const getSocialConnectionDropdownSubaccountsStart = () => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.REQUEST
});
export const getSocialConnectionDropdownSubaccountsSuccess = (data, id, isReFetch) => {
  return {
    type: ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.SUCCESS,
    payload: __spreadProps(__spreadValues({}, data), {
      id,
      isReFetch
    })
  };
};
export const getSocialConnectionDropdownSubaccountsFailure = (err) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.FAILURE,
  payload: err
});
export const getSocialConnectionStart = () => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION.REQUEST
});
export const getSocialConnectionSuccess = (data) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION.SUCCESS,
  payload: data
});
export const getSocialConnectionError = (err) => ({
  type: ACTIONS.GET_SOCIAL_CONNECTION.FAILURE,
  payload: err
});
export const getSubAccountsStart = () => ({
  type: ACTIONS.GET_SUB_ACCOUNTS.REQUEST
});
export const getSubAccountsSuccess = (data) => ({
  type: ACTIONS.GET_SUB_ACCOUNTS.SUCCESS,
  payload: data
});
export const getSubAccountsError = (err) => ({
  type: ACTIONS.GET_SUB_ACCOUNTS.FAILURE,
  payload: err
});
export const filterSocialAccountsStart = () => ({
  type: ACTIONS.FILTER_SOCIAL_ACCOUNTS.REQUEST
});
export const filterSocialAccountsSuccess = (data) => ({
  type: ACTIONS.FILTER_SOCIAL_ACCOUNTS.SUCCESS,
  payload: data
});
export const filterSocialAccountsError = (err) => ({
  type: ACTIONS.FILTER_SOCIAL_ACCOUNTS.FAILURE,
  payload: err
});
export const addSocialConnectionStart = () => ({
  type: ACTIONS.ADD_SOCIAL_CONNECTION.REQUEST
});
export const addSocialConnectionSuccess = (data) => ({
  type: ACTIONS.ADD_SOCIAL_CONNECTION.SUCCESS,
  payload: data
});
export const addSocialConnectionError = (err) => ({
  type: ACTIONS.ADD_SOCIAL_CONNECTION.FAILURE,
  payload: err
});
export const deleteSocialConnectionStart = () => ({
  type: ACTIONS.DELETE_SOCIAL_CONNECTION.REQUEST
});
export const deleteSocialConnectionSuccess = (data) => ({
  type: ACTIONS.DELETE_SOCIAL_CONNECTION.SUCCESS,
  payload: data
});
export const deleteSocialConnectionError = (err) => ({
  type: ACTIONS.DELETE_SOCIAL_CONNECTION.FAILURE,
  payload: err
});
export const resolveSocialConnectionStart = () => ({
  type: ACTIONS.REFRESH_SOCIAL_CONNETION.REQUEST
});
export const resolveSocialConnectionSuccess = (data) => ({
  type: ACTIONS.REFRESH_SOCIAL_CONNETION.SUCCESS,
  payload: data
});
export const resolveSocialConnectionError = (err) => ({
  type: ACTIONS.REFRESH_SOCIAL_CONNETION.FAILURE,
  payload: err
});
export const toggleToTransfer = (id) => ({
  type: ACTIONS.TOGGLE_TO_TRANSFER,
  payload: id
});
export const clearToTransfer = () => ({
  type: ACTIONS.CLEAR_TO_TRANSFER
});
export const selectAllToTransfer = (ids) => ({
  type: ACTIONS.SELECT_ALL_TO_TRANSFER,
  payload: ids
});
export const patchSocialFeedStart = () => ({
  type: ACTIONS.PATCH_SOCIAL_FEED.REQUEST
});
export const patchSocialFeedSuccess = () => ({
  type: ACTIONS.PATCH_SOCIAL_FEED.SUCCESS
});
export const patchSocialFeedError = (err) => ({
  type: ACTIONS.PATCH_SOCIAL_FEED.FAILURE,
  payload: err
});
export const postSocialConnectionStart = () => ({
  type: ACTIONS.POST_SOCIAL_CONNECTION.REQUEST
});
export const postSocialConnectionSuccess = (data) => ({
  type: ACTIONS.POST_SOCIAL_CONNECTION.SUCCESS,
  payload: data
});
export const postSocialConnectionError = (err) => ({
  type: ACTIONS.POST_SOCIAL_CONNECTION.FAILURE,
  payload: err
});
