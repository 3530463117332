import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { facebookDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import {
  validationSchemaFacebookPage,
  validationSchemaFacebookRatings,
  validationSchemaFacebookTaggedPosts
} from "../utils/validation-schema/facebook-schema";
import { GuardNames } from "../../../../../../../guards/guards-list";
const dropdownPageDefaultValue = {
  name: "Select Page...",
  value: "select-page"
};
const clearSubAccountsDropdown = (formik) => formik.setFieldValue("page", dropdownPageDefaultValue);
export const facebook = [
  {
    header: "Add Facebook",
    description: "Choose a Social Feed type below:",
    typeList: [
      {
        id: "page",
        title: "Page",
        description: "This social feed type allows you to access your authorized Facebook page content."
      },
      {
        id: "tagged",
        title: "Tagged Posts",
        description: "This social feed type allows you to access public posts in which your page has been tagged.",
        meta: GuardNames.TINTS_SOCIAL_FEEDS_FACEBOOK_TAGGED
      },
      {
        id: "ratings",
        title: "Ratings and Reviews",
        description: "This social feed type allows you to access ratings and reviews for your page.",
        meta: GuardNames.TINTS_SOCIAL_FEEDS_FACEBOOK_RATINGS
      }
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext
    },
    zendeskUrl: void 0,
    isAlert: false,
    formSchema: void 0
  },
  {
    header: void 0,
    description: void 0,
    zendeskUrl: void 0,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "facebook",
    formSchema: {
      account: {
        id: "account",
        title: "Profile",
        footerCopy: "Note: This social feed type allows you to ingest content from your Facebook Profile.",
        submitMapper: (values) => ({
          account_id: values.account.value,
          external_id: values.page.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          }
        ]
      },
      page: {
        id: "page",
        title: "Page",
        validationSchema: validationSchemaFacebookPage(),
        submitMapper: (values) => ({
          account_id: values.account.value,
          external_id: values.page.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown
          },
          {
            id: "page",
            backendId: "external_id",
            label: "Select PAGE",
            placeholder: "Select Page...",
            description: "To ingest content, please select a Facebook page below:",
            icon: SidebarIcons.FLAG,
            type: SidebarItemTypes.SELECT,
            disabled: facebookDisabledButton
          }
        ]
      },
      tagged: {
        id: "tagged",
        title: "Tagged Posts",
        validationSchema: validationSchemaFacebookTaggedPosts(),
        submitMapper: (values) => ({
          account_id: values.account.value,
          external_id: values.page.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown
          },
          {
            id: "page",
            backendId: "external_id",
            label: "Select PAGE",
            placeholder: "Select Page...",
            description: "To ingest content, select the page that you want to import tagged posts from.",
            icon: SidebarIcons.FLAG,
            type: SidebarItemTypes.SELECT,
            disabled: facebookDisabledButton
          }
        ]
      },
      ratings: {
        id: "ratings",
        title: "Ratings and Reviews",
        validationSchema: validationSchemaFacebookRatings(),
        submitMapper: (values) => ({
          account_id: values.account.value,
          external_id: values.page.value
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown
          },
          {
            id: "page",
            backendId: "external_id",
            label: "Select PAGE",
            placeholder: "Select Page...",
            description: "To ingest content, select the page that you want to reviews and ratings for.",
            icon: SidebarIcons.FLAG,
            type: SidebarItemTypes.SELECT,
            disabled: facebookDisabledButton
          }
        ]
      }
    }
  }
];
