import React, { Fragment } from "react";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceScale = ({ item, values, analyticForm, t }) => {
  if (!item) return null;
  const elements = item.elements;
  const { legend, input } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const defaultValues = values == null ? void 0 : values.getAll(input.name);
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  return /* @__PURE__ */ React.createElement("fieldset", { className: item.class }, /* @__PURE__ */ React.createElement("legend", null, t(legend == null ? void 0 : legend.nodeValue)), elements.filter((el) => {
    var _a;
    return ((_a = el == null ? void 0 : el.nodeName) == null ? void 0 : _a.includes("input")) && (el == null ? void 0 : el.type) === "radio" || (el == null ? void 0 : el.type) === "checkbox";
  }).map((el, index) => {
    var _a, _b;
    const labelNodeValue = (_a = elements.find((label) => (label == null ? void 0 : label.for) === (el == null ? void 0 : el.id))) == null ? void 0 : _a.nodeValue;
    return /* @__PURE__ */ React.createElement(Fragment, { key: index }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: input == null ? void 0 : input.type,
        name: el == null ? void 0 : el.name,
        id: el == null ? void 0 : el.id,
        defaultChecked: (defaultValues == null ? void 0 : defaultValues.indexOf((_b = el == null ? void 0 : el.value) == null ? void 0 : _b.toString())) >= 0,
        onFocus,
        onBlur,
        value: el == null ? void 0 : el.value,
        required: input == null ? void 0 : input.required
      }
    ), /* @__PURE__ */ React.createElement("label", { htmlFor: el == null ? void 0 : el.id }, t(labelNodeValue)));
  }));
};
FormBuilderFormInterfaceScale.displayName = "FormBuilderFormInterfaceScale";
export default React.memo(FormBuilderFormInterfaceScale);
