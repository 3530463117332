var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/roles/roles-sidebar.actions";
import { SelectAccessType } from "../../components/roles/utils/filter-helper/filter-helper";
import { getRoleAccessIds } from "./roles.reducer";
const initialValues = {
  selectedAccess: SelectAccessType.MANUAL,
  totalCount: 0,
  ids: {
    valuesToAdd: void 0,
    valuesToRemove: void 0
  },
  data: [],
  isInitial: true
};
export const initialState = {
  type: void 0,
  tints: initialValues,
  personalizations: initialValues,
  collections: initialValues,
  accounts: initialValues,
  experiences: initialValues,
  fonts: initialValues,
  domains: initialValues,
  product_accounts: initialValues,
  product_feeds: initialValues,
  webhooks: initialValues,
  oauth_applications: initialValues,
  forms: initialValues,
  mixes: initialValues,
  isOpen: false,
  isFetching: false,
  error: void 0
};
export const rolesSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        type: action.payload,
        isOpen: true
      });
    case ACTIONS.SET_PENDING_TINTS:
    case ACTIONS.SET_PENDING_PERSONALIZATIONS:
    case ACTIONS.SET_PENDING_COLLECTIONS:
    case ACTIONS.SET_PENDING_SOCIAL_ACCOUNTS:
    case ACTIONS.SET_PENDING_EXPERIENCES:
    case ACTIONS.SET_PENDING_DOMAINS:
    case ACTIONS.SET_PENDING_FONTS:
    case ACTIONS.SET_PENDING_PRODUCT_ACCOUNTS:
    case ACTIONS.SET_PENDING_PRODUCT_FEEDS:
    case ACTIONS.SET_PENDING_WEBHOOKS:
    case ACTIONS.SET_PENDING_OAUTH_APPLICATIONS:
    case ACTIONS.SET_PENDING_FORMS:
    case ACTIONS.SET_PENDING_MIXES:
      return __spreadValues(__spreadValues({}, state), {
        [action.payload.type]: __spreadValues(__spreadValues({}, state[action.payload.type]), __spreadValues({
          ids: {
            valuesToAdd: action.payload.valuesToAdd,
            valuesToRemove: action.payload.valuesToRemove
          }
        }, action.payload))
      });
    case ACTIONS.CLEAR_PENDING_DATA:
      return __spreadValues(__spreadValues({}, state), {
        type: void 0,
        tints: initialValues,
        personalizations: initialValues,
        collections: initialValues,
        accounts: initialValues,
        experiences: initialValues,
        fonts: initialValues,
        domains: initialValues,
        product_accounts: initialValues,
        product_feeds: initialValues,
        webhooks: initialValues,
        oauth_applications: initialValues,
        forms: initialValues,
        mixes: initialValues
      });
    case ACTIONS.CLOSE_SIDEBAR:
      return __spreadProps(__spreadValues({}, state), {
        isOpen: false
      });
    case ACTIONS.GET_ROLE_TINTS.SUCCESS:
    case ACTIONS.GET_ROLE_PERSONALIZATIONS.SUCCESS:
    case ACTIONS.GET_ROLE_ACCOUNTS.SUCCESS:
    case ACTIONS.GET_ROLE_COLLECTIONS.SUCCESS:
    case ACTIONS.GET_ROLE_EXPERIENCES.SUCCESS:
    case ACTIONS.GET_ROLE_FONTS.SUCCESS:
    case ACTIONS.GET_ROLE_DOMAINS.SUCCESS:
    case ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.SUCCESS:
    case ACTIONS.GET_ROLE_PRODUCT_FEEDS.SUCCESS:
    case ACTIONS.GET_ROLE_WEBHOOKS.SUCCESS:
    case ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.SUCCESS:
    case ACTIONS.GET_ROLE_FORMS.SUCCESS:
    case ACTIONS.GET_ROLE_MIXES.SUCCESS:
      return __spreadValues(__spreadValues({}, state), {
        [action.payload.type]: __spreadProps(__spreadValues({}, state[action.payload.type]), {
          ids: {
            valuesToAdd: getRoleAccessIds(action.payload.data)
          },
          data: action.payload.data,
          isInitial: false
        }),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_ROLE_ACCOUNTS.REQUEST:
    case ACTIONS.GET_ROLE_TINTS.REQUEST:
    case ACTIONS.GET_ROLE_PERSONALIZATIONS.REQUEST:
    case ACTIONS.GET_ROLE_COLLECTIONS.REQUEST:
    case ACTIONS.GET_ROLE_EXPERIENCES.REQUEST:
    case ACTIONS.GET_ROLE_FONTS.REQUEST:
    case ACTIONS.GET_ROLE_DOMAINS.REQUEST:
    case ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.REQUEST:
    case ACTIONS.GET_ROLE_PRODUCT_FEEDS.REQUEST:
    case ACTIONS.GET_ROLE_WEBHOOKS.REQUEST:
    case ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.REQUEST:
    case ACTIONS.GET_ROLE_FORMS.REQUEST:
    case ACTIONS.GET_ROLE_MIXES.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_ROLE_ACCOUNTS.FAILURE:
    case ACTIONS.GET_ROLE_TINTS.FAILURE:
    case ACTIONS.GET_ROLE_PERSONALIZATIONS.FAILURE:
    case ACTIONS.GET_ROLE_COLLECTIONS.FAILURE:
    case ACTIONS.GET_ROLE_EXPERIENCES.FAILURE:
    case ACTIONS.GET_ROLE_FONTS.FAILURE:
    case ACTIONS.GET_ROLE_DOMAINS.FAILURE:
    case ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.FAILURE:
    case ACTIONS.GET_ROLE_PRODUCT_FEEDS.FAILURE:
    case ACTIONS.GET_ROLE_WEBHOOKS.FAILURE:
    case ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.FAILURE:
    case ACTIONS.GET_ROLE_FORMS.FAILURE:
    case ACTIONS.GET_ROLE_MIXES.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    default:
      return state;
  }
};
