var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/media-composer.actions";
import { produce } from "immer";
import { MediaComposerAddNetworkModalData } from "../components/modal/add-network/media-composer-add-network-modal.data";
import { SubmitType } from "../components/footer/overview-footer";
const defaultAddNetworkModalData = {
  step: MediaComposerAddNetworkModalData.MODAL_STEP.STEP_ONE,
  title: "Add Network",
  type: "",
  isOpened: false
};
const initialState = {
  accounts: [],
  form: {},
  addNetworkModal: __spreadValues({}, defaultAddNetworkModalData),
  submitType: SubmitType.NOW,
  selectedAccounts: []
};
const mediaComposer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      return produce(state, (draftState) => {
        draftState.form = action.payload.form;
        draftState.isDuplicate = action.payload.isDuplicate;
        draftState.selectedAccounts = action.payload.selectedAccounts;
      });
    case ACTIONS.REMOVE_PUBLISHED_AT_FORM_DATA:
      return produce(state, (draftState) => {
        delete draftState.form.published_at;
        return draftState;
      });
    case ACTIONS.SET_SUBMIT_TYPE:
      return __spreadProps(__spreadValues({}, state), { submitType: action.payload });
    case ACTIONS.GET_ACCOUNTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), { accounts: action.payload });
    case ACTIONS.GET_ASSET.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        asset: action.payload.data.data
      });
    case ACTIONS.CLEAR_STATE:
      return initialState;
    case ACTIONS.UPDATE_FORM:
      return produce(state, (draftState) => {
        draftState.form[action.payload.field] = action.payload.value;
        return draftState;
      });
    case ACTIONS.OPEN_ADD_NETWORK_MODAL:
      return __spreadProps(__spreadValues({}, state), { addNetworkModal: __spreadProps(__spreadValues({}, state.addNetworkModal), { isOpened: true }) });
    case ACTIONS.CLOSE_ADD_NETWORK_MODAL:
      return __spreadProps(__spreadValues({}, state), { addNetworkModal: __spreadValues({}, defaultAddNetworkModalData) });
    case ACTIONS.SET_ADD_NETWORK_MODAL_DATA:
      return __spreadProps(__spreadValues({}, state), { addNetworkModal: __spreadValues(__spreadValues({}, state.addNetworkModal), action.payload) });
    case ACTIONS.SET_ADD_NETWORK_MODAL_DATA_OPTIONS:
      return produce(state, (draftState) => {
        draftState.addNetworkModal.options = __spreadValues(__spreadValues({}, draftState.addNetworkModal.options), action.payload);
        return draftState;
      });
    case ACTIONS.REMOVE_SELECTED_ACCOUNT:
      return produce(state, (draftState) => {
        draftState.selectedAccounts.splice(action.payload, 1);
        return draftState;
      });
    case ACTIONS.ADD_SELECTED_ACCOUNT:
      return produce(state, (draftState) => {
        draftState.selectedAccounts.push(action.payload);
        return draftState;
      });
    default:
      return state;
  }
};
export default mediaComposer;
