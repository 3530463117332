var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { common } from "../../utils/styles/elements-classes/common/common";
import { formatCSSForPreview } from "../../helpers/helpers";
import cssbeautify from "cssbeautify";
import { objectToCss } from "../../components/form/form-builder.form";
const getFormattedStyles = (styles) => {
  const stylesCopy = __spreadValues({}, styles);
  const commonStyles = {};
  Object.values(common).forEach((selector) => {
    commonStyles[selector] = styles[selector];
    delete stylesCopy[selector];
  });
  const commonCSString = objectToCss(formatCSSForPreview(commonStyles));
  const cssString = objectToCss(formatCSSForPreview(stylesCopy));
  const formattedCommonCss = cssbeautify(commonCSString, {
    indent: "  ",
    openbrace: "end-of-line",
    autosemicolon: true
  });
  const formattedRestOfCss = cssbeautify(cssString, {
    indent: "  ",
    openbrace: "end-of-line",
    autosemicolon: true
  });
  return { formattedCommonCss, formattedRestOfCss };
};
export const FormBuilderStylesService = {
  getFormattedStyles
};
