import { SOCIAL_FEEDS } from "../consts";
import { createPrefixedActionName } from "../../../../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  OPEN_SOCIAL_FEEDS_SIDEBAR: createPrefixedActionName(SOCIAL_FEEDS, "OPEN_SOCIAL_FEEDS_SIDEBAR"),
  UPDATE_SOCIAL_FEEDS_SIDEBAR: createPrefixedActionName(SOCIAL_FEEDS, "UPDATE_SOCIAL_FEEDS_SIDEBAR"),
  CLOSE_SOCIAL_FEEDS_SIDEBAR: createPrefixedActionName(SOCIAL_FEEDS, "CLOSE_SOCIAL_FEEDS_SIDEBAR")
};
export const openSidebar = (data) => ({
  type: ACTIONS.OPEN_SOCIAL_FEEDS_SIDEBAR,
  payload: data
});
export const updateSidebar = (data) => ({
  type: ACTIONS.UPDATE_SOCIAL_FEEDS_SIDEBAR,
  payload: data
});
export const closeSidebar = (data) => ({
  type: ACTIONS.CLOSE_SOCIAL_FEEDS_SIDEBAR,
  payload: data
});
