import { useState, useEffect } from "react";
const IMAGE_STATE = {
  NULL: "null",
  ERROR: "error",
  LOADED: "loaded",
  LOADING: "loading"
};
const useImageLoadingState = (initialImageUrl) => {
  const [state, setState] = useState(IMAGE_STATE.LOADING);
  const onLoad = () => {
    setState(IMAGE_STATE.LOADED);
  };
  const onError = () => {
    setState(IMAGE_STATE.ERROR);
  };
  const loadImage = (url) => {
    setState(IMAGE_STATE.LOADING);
    const img = new Image();
    img.src = url;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
  };
  useEffect(() => {
    if (initialImageUrl === "" || !initialImageUrl) {
      setState(IMAGE_STATE.NULL);
    }
    if (initialImageUrl) {
      loadImage(initialImageUrl);
    } else {
      onError();
    }
    return () => {
      const img = new Image();
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [initialImageUrl]);
  return {
    isLoading: state === IMAGE_STATE.LOADING,
    isLoaded: state === IMAGE_STATE.LOADED || state === IMAGE_STATE.NULL,
    isError: state === IMAGE_STATE.ERROR
  };
};
export default useImageLoadingState;
