var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useRef, useMemo } from "react";
import { fileChecksum } from "../../../services/upload/fileChecksum";
export const errorTypes = {
  invalid: "invalid",
  failed: "failed"
};
export const useFormBuilderUpload = ({ onUploadSuccess, onPending, onUploadError, url, multiple }) => {
  const [isPending, setPending] = useState(false);
  const [files, setFiles] = useState([]);
  const xhrRequests = useRef({});
  const saveXhrRequest = (id, xhrRequest) => {
    xhrRequests.current[id] = xhrRequest;
  };
  const cancelXhrRequest = (id) => {
    if (xhrRequests.current[id]) {
      xhrRequests.current[id].abort();
      delete xhrRequests.current[id];
    }
  };
  const postFile = (file) => __async(void 0, null, function* () {
    const checksum = yield fileChecksum(file, (e) => {
      setFiles(
        (s) => s.map((f) => {
          return f.id === file.id ? __spreadProps(__spreadValues({}, f), {
            progress: e.progress * 0.2
          }) : f;
        })
      );
    });
    const payload = {
      data: {
        type: "upload",
        attributes: {
          filename: file == null ? void 0 : file.name,
          byte_size: file.size,
          content_type: file.type,
          checksum
        }
      }
    };
    let xhrRequest;
    try {
      xhrRequest = new XMLHttpRequest();
      saveXhrRequest(file.name, xhrRequest);
      const response = yield fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json"
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = yield response.json();
      multiple ? setFiles((s) => s.map((item) => item.name === file.name ? __spreadProps(__spreadValues({}, item), { id: data.data.id }) : item)) : setFiles((s) => [__spreadProps(__spreadValues({}, s[0]), { id: data.data.id })]);
      yield putFile({
        url: data.data.attributes.url,
        id: data.data.id,
        body: file,
        headers: __spreadValues({}, data.data.attributes.headers),
        onError: (id) => {
          setFiles(
            (s) => s.map((file2) => {
              return file2.id === id ? __spreadProps(__spreadValues({}, file2), {
                error: errorTypes.failed,
                progress: 100
              }) : file2;
            })
          );
          throw new Error("The upload failed");
        },
        onUploadProgress: (progressEvent) => {
          setFiles(
            (s) => s.map((file2) => {
              return file2.id === progressEvent.id ? __spreadProps(__spreadValues({}, file2), {
                progress: progressEvent.loaded / progressEvent.total * 100 * 0.8 + 20
              }) : file2;
            })
          );
        }
      });
    } catch (err) {
      setFiles(
        (s) => s.map(
          (el) => el.name === file.name ? __spreadProps(__spreadValues({}, el), {
            error: errorTypes.invalid,
            progress: 100
          }) : el
        )
      );
      onUploadError == null ? void 0 : onUploadError(err);
      throw err;
    } finally {
      delete xhrRequests.current[file.name];
    }
  });
  const putFile = (_0) => __async(void 0, [_0], function* ({ url: url2, headers, body, id, onError, onUploadProgress }) {
    return new Promise((resolve, reject) => {
      let xhrRequest = new XMLHttpRequest();
      xhrRequest.open("PUT", url2);
      if (headers) {
        Object.entries(headers).forEach(([key, value]) => {
          xhrRequest.setRequestHeader(key, value);
        });
      }
      xhrRequest.upload.addEventListener("progress", ({ loaded, total }) => {
        onUploadProgress({ id, loaded, total });
      });
      xhrRequest.onload = () => {
        if (xhrRequest.status === 200) {
          resolve(xhrRequest.response);
        } else {
          reject(xhrRequest.statusText);
        }
      };
      xhrRequest.onerror = () => {
        onError(id);
        reject("Network error occurred while uploading file");
      };
      xhrRequest.send(body);
      saveXhrRequest(id, xhrRequest);
    });
  });
  const onUpload = (fileList) => __async(void 0, null, function* () {
    var _a;
    if ((fileList == null ? void 0 : fileList.length) == 0) return;
    setPending(fileList.length > 0);
    onPending == null ? void 0 : onPending(fileList.length > 0);
    const filteredFiles = [...fileList].filter((file) => !files.find((_file) => _file.name === file.name));
    const promises = [...filteredFiles].map((file) => postFile(file));
    multiple ? setFiles(
      (s) => removeDuplicates([
        ...s,
        ...[...filteredFiles].map((file) => ({
          name: file.name,
          progress: 0
        }))
      ])
    ) : setFiles([{ name: (_a = filteredFiles == null ? void 0 : filteredFiles[0]) == null ? void 0 : _a.name, progress: 0 }]);
    yield Promise.all(promises).then((results) => {
      onUploadSuccess(results);
    }).catch((err) => {
      onUploadError == null ? void 0 : onUploadError(err);
    }).finally(() => {
      setPending(false);
      onPending == null ? void 0 : onPending(false);
    });
  });
  const onRemoveFile = (id) => {
    cancelXhrRequest(id);
    setFiles((s) => s.filter((file) => file.id !== id));
  };
  const removeDuplicates = (data) => {
    return data.filter((obj, index, self) => index === self.findIndex((t) => t.name === obj.name));
  };
  const isAnyComponentsPending = useMemo(() => {
    return files.filter((file) => file.progress !== 100).length !== 0;
  }, [files]);
  return {
    onUploadFiles: onUpload,
    isPending,
    isAnyComponentsPending,
    files,
    onRemoveFile
  };
};
