import { EDIT_MODAL } from "./consts.js";
import { createPrefixedActionName } from "../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  OPEN_ORDER_MODAL: createPrefixedActionName(EDIT_MODAL, "OPEN_ORDER_MODAL"),
  OPEN_ORDER_MODAL_FOR_MULTI_COLUMN: createPrefixedActionName(EDIT_MODAL, "OPEN_ORDER_MODAL_FOR_MULTI_COLUMN"),
  CLOSE_ORDER_MODAL: createPrefixedActionName(EDIT_MODAL, "CLOSE_ORDER_MODAL"),
  OPEN_EDIT_MODAL: createPrefixedActionName(EDIT_MODAL, "OPEN_EDIT_MODAL"),
  CLOSE_EDIT_MODAL: createPrefixedActionName(EDIT_MODAL, "CLOSE_EDIT_MODAL")
};
export const openEditModal = () => ({ type: ACTIONS.OPEN_EDIT_MODAL });
export const openOrderModal = () => ({ type: ACTIONS.OPEN_ORDER_MODAL });
export const openOrderModalForMultiColumn = (multiColumnData) => ({
  type: ACTIONS.OPEN_ORDER_MODAL_FOR_MULTI_COLUMN,
  payload: { multiColumnData }
});
export const closeEditModal = () => ({ type: ACTIONS.CLOSE_EDIT_MODAL });
export const closeOrderModal = () => ({ type: ACTIONS.CLOSE_ORDER_MODAL });
