var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import "./date-picker.sass";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Button } from "../button/button";
import InputPrimary from "../inputs/input-primary/input-primary";
import { TintDatePickerDefineRangeList } from "./components/date-picker-define-range-list";
import { DateRange } from "react-date-range";
import { useFormik } from "formik";
import { format, differenceInDays, isAfter } from "date-fns";
import { toast } from "react-toastify";
import * as Yup from "yup";
const timeFormat = "MM/dd/yyyy";
const calculateDateDifference = ({ startDate, endDate }) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  endDateObj.setHours(23, 59, 59, 999);
  if (startDate === endDate) {
    return 0;
  }
  if (!isAfter(endDateObj, startDateObj)) {
    toast.error("End date must be after start date");
    return 0;
  }
  const timeDifference = Math.abs(differenceInDays(endDateObj, startDateObj));
  return timeDifference;
};
export const TintDatePicker = ({
  currentDate,
  onApply,
  onCancel,
  minDate,
  maxDate,
  noAllTime = false,
  numberOfDaysAllow
}) => {
  const regexp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
  const dateValidationSchema = Yup.string().matches(regexp, "Date must be in MM/DD/YYYY format").required("Date is required");
  const validationSchema = Yup.object().shape({
    startDate: dateValidationSchema,
    endDate: dateValidationSchema
  });
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      startDate: (currentDate == null ? void 0 : currentDate.startDate) ? format(currentDate == null ? void 0 : currentDate.startDate, timeFormat) : /* @__PURE__ */ new Date(),
      endDate: (currentDate == null ? void 0 : currentDate.endDate) ? format(currentDate == null ? void 0 : currentDate.endDate, timeFormat) : new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() + 7)),
      key: "selection"
    },
    validationSchema
  });
  const currentTime = (/* @__PURE__ */ new Date()).getTime();
  const datePlaceholder = new Date(currentTime);
  const onChange = (date) => {
    if (numberOfDaysAllow && calculateDateDifference(date) > numberOfDaysAllow) {
      toast.error(`Date range should be lower than ${numberOfDaysAllow} days`);
      return;
    }
    formik.setFieldValue("startDate", format(date.startDate, timeFormat));
    formik.setFieldValue("endDate", format(date.endDate, timeFormat));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-date-range-picker" }, /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-date-range-picker__row" }, !numberOfDaysAllow ? /* @__PURE__ */ React.createElement(TintDatePickerDefineRangeList, { onSelectDate: onChange, noAllTime }) : null, /* @__PURE__ */ React.createElement(
    DateRange,
    {
      onChange: (data) => onChange(data.selection),
      ranges: [
        __spreadValues({}, __spreadProps(__spreadValues({}, formik.values), {
          startDate: regexp.test(formik.values.startDate) && new Date(formik.values.startDate) || /* @__PURE__ */ new Date(),
          endDate: regexp.test(formik.values.endDate) && new Date(formik.values.endDate) || /* @__PURE__ */ new Date()
        }))
      ],
      months: 2,
      minDate,
      maxDate,
      direction: "horizontal",
      showDateDisplay: false
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-date-range-picker__row tint-dropdown-date-range-picker__row--footer" }, /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-date-range-picker-custom-fields" }, /* @__PURE__ */ React.createElement("span", { className: "tint-dropdown-date-range-picker-custom-fields__label" }, "Custom"), /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-date-range-picker-custom-fields__inputs" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      name: "startDate",
      type: "string",
      value: formik.values.startDate,
      placeholder: datePlaceholder,
      error: formik.errors.startDate,
      touched: formik.touched.startDate,
      onBlur: formik.handleBlur,
      handleBlur: formik.handleBlur,
      handleChange: formik.handleChange
    }
  ), /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-arrow-right" }), /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      name: "endDate",
      type: "string",
      value: formik.values.endDate,
      placeholder: datePlaceholder,
      error: formik.errors.endDate,
      touched: formik.touched.endDate,
      handleBlur: formik.handleBlur,
      handleChange: formik.handleChange
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-date-range-picker-custom-fields__actions" }, /* @__PURE__ */ React.createElement(Button, { type: "stroked", text: "Cancel", size: "36", onClick: onCancel }), /* @__PURE__ */ React.createElement(
    Button,
    {
      type: "info",
      text: "Apply",
      size: "36",
      isDisabled: !formik.isValid,
      onClick: () => {
        if (formik.values.startDate && formik.values.endDate) {
          const startDateStr = format(new Date(formik.values.startDate), "yyyy-MM-dd");
          const endDateStr = format(new Date(formik.values.endDate), "yyyy-MM-dd");
          const startDate = /* @__PURE__ */ new Date(startDateStr + "T00:00:00");
          const endDate = /* @__PURE__ */ new Date(endDateStr + "T23:59:59");
          onApply({
            startDate,
            endDate,
            areCustomValues: true
          });
        } else {
          onApply(__spreadProps(__spreadValues({}, formik.values), { areCustomValues: false }));
        }
      }
    }
  ))));
};
