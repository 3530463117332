import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./terms-conditions.sass";
import { setRequestRightsModalProgress } from "../../../../actions/request-rights/request-rights.actions";
import { Button } from "../../../interface/button/button";
import { openSidebar } from "../../../../actions/ui/ui.actions";
import {
  RightsManagementDropdownState,
  RightsManagementSidebar,
  rightsManagementSidebarId
} from "../../../sidebars/rights-managment/rights-management.sidebar";
import { isSidebarOpen } from "../../../../reducers/ui/ui.selector";
import DropdownTermsAndConditions, {
  termsAndConditionsDropdownId
} from "../../../api-dropdown/terms-and-conditions/dropdown-terms-and-conditions";
export const TermsConditions = ({ onSelect, currentId, onFormBuilderSelect }) => {
  var _a, _b;
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isSidebarOpen(state, rightsManagementSidebarId));
  const dropdownRef = useRef();
  const onTermSelect = (element) => {
    var _a2;
    dispatch(setRequestRightsModalProgress({ right_request_term_id: element.value }));
    onSelect && onSelect(element.value, (_a2 = ui == null ? void 0 : ui.dropdowns) == null ? void 0 : _a2[termsAndConditionsDropdownId]);
  };
  const onTermsAndConditionsUpdate = () => {
    if (dropdownRef.current) {
      dropdownRef.current.refetch();
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-terms-conditions" }, /* @__PURE__ */ React.createElement(DropdownTermsAndConditions, { currentElement: currentId, onChange: onTermSelect, ref: dropdownRef }), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => dispatch(openSidebar(rightsManagementSidebarId, { type: RightsManagementDropdownState.CREATE })),
      styles: { width: "100%", height: "52px" },
      text: `${((_b = (_a = ui == null ? void 0 : ui[termsAndConditionsDropdownId]) == null ? void 0 : _a.data) == null ? void 0 : _b.length) > 0 ? "Edit" : "+ Add"} Terms & Conditions`,
      type: "gray"
    }
  ), isOpen && /* @__PURE__ */ React.createElement(RightsManagementSidebar, { isBackDropVisible: false, onUpdate: onTermsAndConditionsUpdate }));
};
