import React, { useEffect, useState } from "react";
const Accordion = ({ children, animationTimeDuration = 0.6, openedTabIndex }) => {
  const [expandedTab, setExpandedTab] = useState(openedTabIndex);
  const onItemClick = (index) => {
    setExpandedTab(expandedTab === index ? void 0 : index);
  };
  useEffect(() => {
    if (typeof openedTabIndex === "undefined" || typeof openedTabIndex === "number") {
      setExpandedTab(openedTabIndex);
    }
  }, [openedTabIndex]);
  return /* @__PURE__ */ React.createElement("ul", { className: "tint-accordion", "data-testid": "tint-accordion" }, React.Children.map(
    children,
    (child, i) => child && /* @__PURE__ */ React.createElement("li", { key: i }, React.cloneElement(child, {
      isActive: expandedTab === i,
      onTabClick: onItemClick,
      index: i,
      animationTimeDuration
    }))
  ));
};
export default Accordion;
