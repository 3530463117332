var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ACTIONS } from "../actions/app-route-actions";
export default (state = { path: "", pageTitle: "", pageHeader: "" }, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_MAIN_APP_ROUTE:
      return __spreadValues(__spreadValues({}, state), { path: action.payload.route, id: action.payload.id });
    case ACTIONS.SET_PAGE_TITLE:
      return __spreadValues(__spreadValues({}, state), { pageTitle: action.payload });
    case ACTIONS.SET_PAGE_HEADER:
      return __spreadValues(__spreadValues({}, state), { pageHeader: action.payload });
    default:
      return state;
  }
};
