var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect, useState } from "react";
import { InputGroup } from "../../../../../../../../components/interface/inputs/input-group/input-group";
import {
  dropdownAdditionalSelectField,
  dropdownAdditionalFormSelectField,
  dropdownMissionHubsSelectField,
  dropdownAdditionalExperienceSelectField
} from "../../utils/common/common";
import { filters } from "../../../../../../../account-settings/utils/filter-helper/filter-helper";
import { getSocialConnectionSubaccountDrodpown } from "../../../../../../../account-settings/actions/social-connections/social-connections.fat-actions";
import { handleApiError } from "../../../../../../../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SocialFeedsSource } from "../../../../../../../../types/social-feeds";
import { getExperience, getForm } from "../../actions/social-feeds.fat-actions";
import TintIcon from "../../../../../../../../components/interface/icon/icon";
import { faArrowUpRight, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import "./account-dropdown.sass";
import { Config } from "../../../../../../../../utils/config";
import { SocialFeedsAccountTypes } from "../../consts";
import { FORM_BUILDER_PUBLISHED } from "../../../../../../../../types/form-builder";
import { FORM_BUILDER_TEMPLATE_TYPE } from "../../../../../../../form-builder/utils/templates/helpers/config";
import { DropdownAccounts } from "../../../../../../../../components/api-dropdown/accounts/dropdown-accounts";
import { openSidebar } from "../../../../../../../../actions/ui/ui.actions";
import { socialAccountFormType } from "../../../../../../../account-settings/components/social-connections-modal/social-account-form/social-account-form";
const dropdownAccountDefaultValue = {
  name: "Select Account...",
  value: "select-account"
};
const dropdownNameDefaultValue = {
  name: "Select...",
  value: "select-name"
};
export const AccountStatus = {
  EXPIRED: "expired",
  OK: "ok"
};
const AccountDropdown = ({
  activeSocialFeedSource,
  accountSourceType,
  label,
  icon,
  id,
  formik,
  clearSubAccountsDropdown
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [newAddedAccountId, setNewAddedAccountId] = useState();
  const socialAccounts = useSelector((state) => state.socialConnections);
  const dispatch = useDispatch();
  const accountSocialFeed = activeSocialFeedSource !== SocialFeedsSource.EXPERIENCE_BUILDER && activeSocialFeedSource !== SocialFeedsSource.VESTA && activeSocialFeedSource !== SocialFeedsSource.FORMS && activeSocialFeedSource !== SocialFeedsSource.PUBLIC_POST && activeSocialFeedSource !== SocialFeedsSource.REVIEWS_RATINGS;
  const subAccountsSocialFeedList = [
    SocialFeedsSource.FACEBOOK,
    SocialFeedsSource.LINKED_IN,
    SocialFeedsSource.SLACK,
    SocialFeedsSource.HOOT_SUITE,
    SocialFeedsSource.WEBEX,
    SocialFeedsSource.INSTAGRAM_BUSINESS,
    SocialFeedsSource.PINTEREST,
    SocialFeedsSource.YOUTUBE,
    SocialFeedsSource.CREATOR_IQ
  ];
  const hasAddAccountForm = [
    SocialFeedsSource.CREATOR_IQ,
    SocialFeedsSource.BAZAARVOICE,
    SocialFeedsSource.POWER_REVIEWS,
    SocialFeedsSource.RE_CAPTCHA
  ].includes(accountSourceType);
  useEffect(() => {
    if (newAddedAccountId !== void 0) {
      const externalIdSocialFeedList = [
        SocialFeedsSource.FACEBOOK,
        SocialFeedsSource.LINKED_IN,
        SocialFeedsSource.TIK_TOK
      ];
      const account = socialAccounts.accounts.data.find((social) => social.id === newAddedAccountId);
      const currentItem = !externalIdSocialFeedList.includes(activeSocialFeedSource) ? { name: account.attributes.name || account.attributes.username, value: account.id } : {
        name: account.attributes.name || account.attributes.username,
        value: account.id,
        externalId: account.attributes.external_id
      };
      formik.setFieldValue(["account"], currentItem, false);
      setNewAddedAccountId(void 0);
    }
  }, [activeSocialFeedSource, formik, newAddedAccountId, setNewAddedAccountId, socialAccounts]);
  const queryParams = {
    [SocialFeedsAccountTypes.MISSION]: {
      baseUrl: "/missions",
      placeholder: "Select Mission...",
      additionalOption: {
        name: dropdownMissionHubsSelectField.name,
        value: dropdownMissionHubsSelectField.value,
        link: Config.routePaths.missionHubs.path
      }
    },
    [SocialFeedsAccountTypes.FORM]: {
      baseUrl: "/forms",
      filters: [
        {
          by: filters.PUBLISHED,
          value: FORM_BUILDER_PUBLISHED.YES
        },
        ...activeSocialFeedSource === SocialFeedsSource.PUBLIC_POST ? [
          {
            by: filters.TYPE,
            value: `${FORM_BUILDER_TEMPLATE_TYPE.PUBLIC_POST},${FORM_BUILDER_TEMPLATE_TYPE.BLANK}`
          }
        ] : [],
        ...activeSocialFeedSource === SocialFeedsSource.REVIEWS_RATINGS ? [
          {
            by: filters.TYPE,
            value: `${FORM_BUILDER_TEMPLATE_TYPE.PRODUCT_REVIEW},${FORM_BUILDER_TEMPLATE_TYPE.BLANK}`
          }
        ] : []
      ],
      placeholder: "Select Form...",
      additionalOption: {
        name: dropdownAdditionalFormSelectField.name,
        value: dropdownAdditionalFormSelectField.value,
        link: Config.routePaths.formBuilder.new.path
      }
    },
    [SocialFeedsAccountTypes.ACCOUNT]: __spreadValues({
      baseUrl: "/accounts",
      filters: [
        {
          by: filters.TYPE,
          value: accountSourceType
        }
      ],
      placeholder: "Select Account..."
    }, hasAddAccountForm ? {
      additionalOption: {
        action: () => dispatch(openSidebar(socialAccountFormType)),
        name: dropdownAdditionalSelectField.name,
        value: dropdownAdditionalSelectField.value
      }
    } : { additionalOption: void 0 }),
    [SocialFeedsAccountTypes.EXPERIENCE]: {
      baseUrl: "/experiences",
      fetchFnMapper: (e) => {
        var _a2;
        return { name: e.attributes.name, value: e.id, externalId: (_a2 = e.attributes) == null ? void 0 : _a2.external_id };
      },
      filterDataFn: (e) => {
        var _a2, _b2, _c2;
        return ((_c2 = (_b2 = (_a2 = e == null ? void 0 : e.relationships) == null ? void 0 : _a2.form_submission_keys) == null ? void 0 : _b2.data) == null ? void 0 : _c2.length) > 0;
      },
      placeholder: "Select Experience...",
      additionalOption: {
        name: dropdownAdditionalExperienceSelectField.name,
        value: dropdownAdditionalExperienceSelectField.value,
        link: Config.routePaths.experienceBuilder.new.path
      },
      include: "form_submission_keys"
    }
  };
  const getSubAccounts = (value) => {
    dispatch(
      getSocialConnectionSubaccountDrodpown({
        socialConnectionId: value
      })
    ).catch((err) => handleApiError(err, toast.error));
  };
  const onChangeValue = (selectedItem, id2) => {
    switch (activeSocialFeedSource) {
      case SocialFeedsSource.FORMS:
      case SocialFeedsSource.PUBLIC_POST:
      case SocialFeedsSource.REVIEWS_RATINGS:
        if (id2 === SocialFeedsAccountTypes.FORM) {
          dispatch(getForm({ formId: selectedItem.value })).then(() => formik.resetForm()).catch((err) => handleApiError(err, toast.error));
        }
        formik.setFieldValue([id2], selectedItem);
        break;
      case SocialFeedsSource.EXPERIENCE_BUILDER:
        if (id2 === SocialFeedsAccountTypes.EXPERIENCE) {
          formik.setFieldValue("name", dropdownNameDefaultValue);
          formik.setFieldValue("body", dropdownNameDefaultValue);
          dispatch(getExperience({ experienceId: selectedItem.value })).catch((err) => handleApiError(err, toast.error));
        }
        formik.setFieldValue([id2], selectedItem);
        break;
      default:
        clearSubAccountsDropdown && clearSubAccountsDropdown(formik);
        if (selectedItem.value === dropdownAdditionalSelectField.value) {
          formik.setFieldValue("account", dropdownAccountDefaultValue);
        } else {
          if (subAccountsSocialFeedList.includes(activeSocialFeedSource)) {
            if (selectedItem.value && selectedItem.value !== "") getSubAccounts(selectedItem.value);
          }
          formik.setFieldValue([id2], selectedItem);
        }
        break;
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(InputGroup, { label, inputGroupIcon: icon, error: formik.errors[id], touched: formik.touched[id] }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    DropdownAccounts,
    {
      currentElement: (_a = formik.values) == null ? void 0 : _a[id],
      queryParams: (queryParams == null ? void 0 : queryParams[id]) || "",
      placeholder: (_b = queryParams == null ? void 0 : queryParams[id]) == null ? void 0 : _b.placeholder,
      onChange: (e) => onChangeValue(e, id),
      accountType: accountSourceType,
      include: (_c = queryParams == null ? void 0 : queryParams[id]) == null ? void 0 : _c.include,
      onAccountCreate: (data) => {
        setNewAddedAccountId(data.id);
        getSubAccounts(data.id);
      },
      additionalOption: (_d = queryParams == null ? void 0 : queryParams[id]) == null ? void 0 : _d.additionalOption,
      fetchFnMapper: (_e = queryParams == null ? void 0 : queryParams[id]) == null ? void 0 : _e.fetchFnMapper,
      filterDataFn: (_f = queryParams == null ? void 0 : queryParams[id]) == null ? void 0 : _f.filterDataFn
    }
  ))), accountSocialFeed && !formik.values[id] && /* @__PURE__ */ React.createElement("div", { className: "account-dropdown__info-card" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faQuestionCircle, fontSize: "16px" }), /* @__PURE__ */ React.createElement("p", null, "Can't find the account you're", /* @__PURE__ */ React.createElement("br", null), "looking for?")), /* @__PURE__ */ React.createElement("a", { href: (_g = Config.url.zenDesk) == null ? void 0 : _g[activeSocialFeedSource], target: "_blank", rel: "noreferrer" }, /* @__PURE__ */ React.createElement("span", null, "Learn More"), /* @__PURE__ */ React.createElement(TintIcon, { icon: faArrowUpRight, fontSize: "16px" }))));
};
export default React.memo(AccountDropdown);
