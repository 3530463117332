import { Config } from "../../../../utils/config";
import { GuardNames } from "../../../../guards/guards-list";
export const marketingIntegrationsSources = [
  {
    title: "X",
    source: "twitter",
    description: "Connect your X API Key to ingest content, schedule posts and more."
  },
  {
    title: "Facebook Ads",
    source: "facebook",
    description: "Access rights approved UGC for use in Facebook Ad creative.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Salesforce Marketing Cloud",
    source: "salesforce_marketing_cloud",
    description: "Send rights approved UGC to Salesforce Marketing Cloud Products",
    meta: "salesforce",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Adobe Experience Manager",
    source: "adobe_experience_manager",
    description: "Access rights approved UGC for use inside Adobe Experience Manager.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Bynder",
    source: "bynder",
    description: "Access rights approved UGC from Bynder Media Library.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Dropbox",
    source: "dropbox",
    description: "Access rights approved UGC for use inside Dropbox library.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Mailchimp",
    source: "mailchimp",
    description: "Access rights approved UGC from Mailchimp Media Library.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Hubspot",
    source: "hubspot",
    description: "Access rights approved UGC from Hubspot Content Library.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Widen",
    source: "widen_collective",
    description: "Access rights approved UGC for use inside Widen Collective.",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Hootsuite",
    source: "hootsuite",
    description: "Access rights approved content from the Hootsuite Media Library.",
    link: Config.url.marketingIntegrationsHootsuiteApp,
    linkDescription: "Install via Hootsuite",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Brandfolder",
    source: "brandfolder",
    description: "Access rights approved UGC for use inside Brandfolder",
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Zapier",
    source: "zapier",
    description: "Looking for something else? Create your own workflow using Zapier.",
    link: Config.url.marketingIntegrationsZapierApp,
    linkDescription: "Install via Zapier",
    guardName: GuardNames.ZAPIER,
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  },
  {
    title: "Google Drive",
    source: "google_drive",
    description: "Access rights approved UGC from Google Drive.",
    guardName: Config.features.google_drive,
    mainGuard: GuardNames.MARKETING_INTEGRATIONS
  }
];
