var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/custom-domains/custom-domains.actions";
import { produce } from "immer";
export const initialState = {
  domain: void 0,
  error: void 0,
  isPending: false,
  customDomainExperiences: []
};
export const customDomains = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.REQUEST:
    case ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.REQUEST:
    case ACTIONS.POST_CUSTOM_DOMAIN.REQUEST:
    case ACTIONS.DELETE_CUSTOM_DOMAIN.REQUEST:
    case ACTIONS.GET_CUSTOM_DOMAINS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPending: true
      });
    case ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.SUCCESS:
      return produce(state, (draft) => {
        var _a;
        const experiences = draft.data.filter((domain) => domain.id !== action.payload.data.id);
        draft.data = [
          __spreadProps(__spreadValues({}, action.payload.data.attributes), {
            id: action.payload.data.id,
            defaultExperienceId: (_a = action.payload.data.relationships.default_experience.data) == null ? void 0 : _a.id
          }),
          ...experiences
        ];
        draft.isPending = false;
      });
    case ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        customDomainExperiences: action.payload,
        isPending: false,
        error: void 0
      });
    case ACTIONS.GET_CUSTOM_DOMAINS.SUCCESS:
      return {
        data: action.payload,
        isPending: false,
        error: void 0
      };
    case ACTIONS.POST_CUSTOM_DOMAIN.SUCCESS:
      return {
        data: [...state.data, action.payload],
        isPending: false,
        error: void 0
      };
    case ACTIONS.DELETE_CUSTOM_DOMAIN.SUCCESS:
      return {
        data: state.data.filter((domain) => domain.id !== action.payload),
        isPending: false,
        error: void 0
      };
    case ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.FAILURE:
    case ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.FAILURE:
    case ACTIONS.POST_CUSTOM_DOMAIN.FAILURE:
    case ACTIONS.DELETE_CUSTOM_DOMAIN.FAILURE:
    case ACTIONS.GET_CUSTOM_DOMAINS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isPending: false,
        error: action.payload
      });
    default:
      return state;
  }
};
export default customDomains;
export const getCustomDomainDefaultExperience = (state, domainId) => {
  var _a;
  return (_a = state.customDomains.data.find((experience) => experience.id === domainId)) == null ? void 0 : _a.defaultExperienceId;
};
