var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
export const POST_SUBMIT_ACTION = {
  SHOW_SUCCESS_MESSAGE: "show_success_message",
  REDIRECT_TO_URL: "redirect_to_url",
  HIDE_THE_FORM: "hide_the_form"
};
export class FormBuilder {
  constructor() {
    __publicField(this, "scriptUrl", `${window._env_.BASE_URL}dist/formBuilder-bundle.js`);
    __publicField(this, "stylesUrl", `${window._env_.BASE_URL}dist/formBuilder.css`);
    __publicField(this, "apiUrl", window._env_.API_URL);
    __publicField(this, "formSelector", "[data-team-id][data-form-id]");
    __publicField(this, "forms", null);
    __publicField(this, "initialized", false);
    __publicField(this, "scriptFetched", false);
    __publicField(this, "isFingerPrintScriptIncluded", false);
    __publicField(this, "_getCookie", (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2)
        return parts.pop().split(";").shift();
    });
  }
  init() {
    if (!this.initialized) {
      this.initialized = true;
      this.forms = document.querySelectorAll(this.formSelector);
      if (this.forms.length > 0) {
        this._includeFingerprint();
        this._fetchReactFormScript();
      }
    }
  }
  _includeFingerprint() {
    window.io_bb_callback = (bb, complete) => {
      window.bbDeviceFingerprint = bb;
    };
    const script = document.createElement("script");
    script.src = "https://mpsnare.iesnare.com/snare.js";
    script.async = true;
    this.isFingerPrintScriptIncluded = true;
    document.body.appendChild(script);
  }
  afterSubmitAction(e, data, formId) {
    var _a, _b, _c, _d;
    const url = (_b = (_a = data == null ? void 0 : data.layout) == null ? void 0 : _a.postSubmit) == null ? void 0 : _b.url;
    const action = (_d = (_c = data == null ? void 0 : data.layout) == null ? void 0 : _c.postSubmit) == null ? void 0 : _d.action;
    if (!action) {
      return;
    }
    switch (action) {
      case POST_SUBMIT_ACTION.REDIRECT_TO_URL:
        if (url) {
          location.href = url;
        } else {
          console.warn("Missing url in post submit action");
        }
        break;
      case POST_SUBMIT_ACTION.HIDE_THE_FORM: {
        const div = document.querySelector(`[data-form-id="${formId}"]`);
        if ((div == null ? void 0 : div.tagName) === "DIALOG") {
          div.close();
        } else {
          div.style.display = "none";
        }
        break;
      }
    }
  }
  _fetchReactFormScript() {
    const script = document.createElement("script");
    script.src = this.scriptUrl;
    script.async = true;
    script.onload = () => {
      this.scriptFetched = true;
      this.renderForms();
    };
    document.body.appendChild(script);
  }
  findConfigByElementId(elementId) {
    return window.tintForms && window.tintForms[elementId] || {};
  }
  renderForms() {
    document.querySelectorAll(this.formSelector).forEach((div) => {
      var _a, _b;
      let {
        teamId: configTeamId = void 0,
        formId: configFormId = void 0,
        locale: configLocale = void 0,
        noTrack: configNoTrack = void 0,
        values: configFormValues = void 0,
        events: configEvents = void 0
      } = this.findConfigByElementId(div.id);
      let teamId = div.getAttribute("data-team-id") || configTeamId;
      let formId = div.getAttribute("data-form-id") || configFormId;
      let locale = div.getAttribute("data-form-locale") || configLocale || document.documentElement.lang;
      let noTrack = div.getAttribute("data-notrack") != void 0 || configNoTrack;
      let formValues = div.getAttribute("data-form-values") || configFormValues;
      let onAfterLoad = (configEvents == null ? void 0 : configEvents.onAfterLoad) ? configEvents.onAfterLoad : null;
      let onSubmitUser = (configEvents == null ? void 0 : configEvents.onAfterSubmit) ? configEvents.onAfterSubmit : null;
      let onBeforeSubmit = (configEvents == null ? void 0 : configEvents.onBeforeSubmit) ? configEvents.onBeforeSubmit : null;
      let onSubmitFinal = function(customEvent, formData) {
        var _a2;
        if (onSubmitUser) {
          if (!(onSubmitUser == null ? void 0 : onSubmitUser(customEvent))) return;
        }
        (_a2 = this.afterSubmitAction) == null ? void 0 : _a2.call(this, customEvent, formData, formId);
      };
      (_b = (_a = window.Tint) == null ? void 0 : _a.tintFormBuilderRender) == null ? void 0 : _b.call(_a, {
        element: div,
        stylesUrl: this.stylesUrl,
        teamId,
        formId,
        locale,
        noTrack,
        formValues,
        apiUrl: this.apiUrl,
        tintAnonymousUid: this._getCookie("tint-anonymous-uid"),
        onAfterSubmit: onSubmitFinal.bind(this),
        onAfterLoad: onAfterLoad == null ? void 0 : onAfterLoad.bind(this),
        onBeforeSubmit: onBeforeSubmit == null ? void 0 : onBeforeSubmit.bind(this)
      });
    });
  }
}
