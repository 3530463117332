import React from "react";
import "./image-wrapper.sass";
import TintIcon from "../interface/icon/icon";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faImageSlash } from "@fortawesome/pro-solid-svg-icons";
export const TintThumbWrapper = ({
  src,
  videoSrc,
  videoPosterSrc,
  alt,
  width = 90,
  height = 90,
  borderRadius,
  videoPlayerIcon = faPlay,
  error
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-thumb-wrapper ${error ? "tint-thumb-wrapper--error" : ""}`,
      style: {
        width,
        height,
        borderRadius
      }
    },
    src ? /* @__PURE__ */ React.createElement("img", { className: "tint-thumb-wrapper__image", src, alt }) : videoSrc ? /* @__PURE__ */ React.createElement(React.Fragment, null, videoPosterSrc ? /* @__PURE__ */ React.createElement("video", { poster: videoPosterSrc }, /* @__PURE__ */ React.createElement("source", { src: videoSrc })) : /* @__PURE__ */ React.createElement("video", { src: videoSrc })) : !src && !videoSrc ? /* @__PURE__ */ React.createElement(TintIcon, { icon: faImageSlash, className: "tint-thumb-wrapper__no-source", alt: "Play Video" }) : /* @__PURE__ */ React.createElement(TintIcon, { icon: videoPlayerIcon, className: "tint-thumb-wrapper__no-source", alt: "Play Video" })
  );
};
