import { SocialFeedsFooterLabels } from "./helpers";
import * as Yup from "yup";
export const sms = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "sms",
    header: "Add Text Message",
    selectedOption: { id: "sms", title: "Add Text Message" },
    description: 'Click "Add" to get a phone number assigned to your TINT. Your audience can then send texts to this number to submit posts.',
    subDescription: "We currently assign US numbers only.",
    formSchema: {
      sms: {
        submitMapper: () => ({
          source: "sms"
        }),
        validationSchema: Yup.object({}),
        inputs: []
      }
    }
  }
];
