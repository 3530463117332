var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import "./attention-score-description.sass";
import { AttentionScoreCircleInfo } from "../attention-score-circle-info/attention-score-circle-info";
import { AttentionScoreProgressBar } from "../attention-score-progress-bar/attention-score-progress-bar";
export const OverlayType = {
  NONE: "NONE",
  HEAT: "HEAT",
  FOG: "FOG",
  EYE_TRACKER: "EYE_TRACKER",
  SALIENCY: "SALIENCY"
};
const overlayList = [{ key: OverlayType.NONE, name: "None" }, { key: OverlayType.HEAT, name: "Heat" }];
const scoreValues = {
  HIGH: "High",
  MEDIUM_HIGH: "Medium-High",
  MEDIUM_LOW: "Medium-Low",
  LOW: "Low"
};
const Colors = {
  RED: "#E54D42",
  YELLOW: "#F0C430",
  LIGHT_GREEN: "#36CA74",
  DARK_GREEN: "#2EAE63"
};
const CognitiveDemandList = [
  { valueText: "High", rangeFrom: 76, rangeTo: 100, details: "Too much cognitive demand", circleColor: Colors.RED },
  { valueText: "Medium-High", rangeFrom: 51, rangeTo: 75, details: "Most effective range", circleColor: Colors.YELLOW },
  {
    valueText: "Medium-Low",
    rangeFrom: 26,
    rangeTo: 50,
    details: "Most effective range",
    circleColor: Colors.LIGHT_GREEN
  },
  {
    valueText: "Low",
    rangeFrom: 0,
    rangeTo: 25,
    details: "The image may be too clean and minimal, potentially not exciting enough.",
    circleColor: Colors.DARK_GREEN
  }
];
export const ClarityList = [
  {
    valueText: "High",
    rangeFrom: 76,
    rangeTo: 100,
    details: "85-90% may be too clear, not interesting enough.",
    circleColor: Colors.DARK_GREEN
  },
  {
    valueText: "Medium-High",
    rangeFrom: 51,
    rangeTo: 75,
    details: "Most effective range (anything from 54-100)",
    circleColor: Colors.LIGHT_GREEN
  },
  {
    valueText: "Medium-Low",
    rangeFrom: 26,
    rangeTo: 50,
    details: "Clearer, but not enough",
    circleColor: Colors.YELLOW
  },
  {
    valueText: "Low",
    rangeFrom: 0,
    rangeTo: 25,
    details: "Not clear enough, audience won\u2019t know where to look.",
    circleColor: Colors.RED
  }
];
const scoreMessages = {
  1: [
    "This asset will be easy for your audience to process since Cognitive Demand scores in the middle tier.",
    "Due to the excellent Clarity score, viewers of this asset will tend to notice the same visual elements.",
    "This asset sends a clear message and is able to be processed in seconds.",
    "Should you use this asset, there is a high likelihood that viewers will successfully observe the elements you are trying to show them."
  ],
  2: [
    "Pro Tip: Remember the higher the Clarity, the clearer the message.",
    "The current Cognitive Demand of this asset means that your audience will process this visual information within a few seconds.",
    "For further optimization, consider adding visual enhancements like a brand logo, headlines or a call to action."
  ],
  3: [
    "Consider optimizing this asset prior to use.",
    "This asset contains too many visual features that require attention at the same time. This could cause your audience\u2019s attention to be too spread out.",
    "You may want to consider pairing down the amount of information contained in the asset. By pairing down the visual information, you will create more distinct areas of focus for your audience.",
    "This asset may require a bit more time to process the full information. Use the eye tracker overlay to observe what areas of your asset get the most attention, this is where your audience will pay the most attention.",
    "Since Cognitive Demand is low, explore adding visual elements to this asset to increase visual interest. This will ensure that viewers don't skip past it due to not being interesting enough."
  ],
  4: [
    "Based on your current scores, we suggest optimizing this asset prior to use.",
    "Make sure that Cognitive Demand is not too high for this asset. When an asset contains too much information, your audience is likely to be overloaded. Your audience may be confused by what you are trying to tell them.",
    "Consider optimizing this asset to increase Clarity. This will ensure you don't lose viewers before they\u2019ve processed the whole asset.",
    "To improve your score, try to simplify this asset's visual information. This will help guide viewers to the same important areas of focus."
  ]
};
export const scoreColors = {
  1: Colors.DARK_GREEN,
  2: Colors.LIGHT_GREEN,
  3: Colors.YELLOW,
  4: Colors.RED
};
export const cogDemandClarityToMessage = {
  [scoreValues.LOW]: {
    [scoreValues.HIGH]: 4,
    [scoreValues.MEDIUM_HIGH]: 4,
    [scoreValues.MEDIUM_LOW]: 4,
    [scoreValues.LOW]: 4
  },
  [scoreValues.MEDIUM_LOW]: {
    [scoreValues.HIGH]: 4,
    [scoreValues.MEDIUM_HIGH]: 3,
    [scoreValues.MEDIUM_LOW]: 3,
    [scoreValues.LOW]: 3
  },
  [scoreValues.MEDIUM_HIGH]: {
    [scoreValues.HIGH]: 4,
    [scoreValues.MEDIUM_HIGH]: 1,
    [scoreValues.MEDIUM_LOW]: 1,
    [scoreValues.LOW]: 3
  },
  [scoreValues.HIGH]: {
    [scoreValues.HIGH]: 4,
    [scoreValues.MEDIUM_HIGH]: 2,
    [scoreValues.MEDIUM_LOW]: 2,
    [scoreValues.LOW]: 3
  }
};
export const findScoreObject = (arr, value) => {
  return __spreadProps(__spreadValues({}, arr.find((e) => value >= e.rangeFrom && value <= e.rangeTo)), { value });
};
export const determineAttentionScoreColor = (clarity, cognitive_demand) => {
  const clarityText = findScoreObject(ClarityList, Math.round(clarity)).valueText;
  const cognitiveDemandText = findScoreObject(CognitiveDemandList, Math.round(cognitive_demand)).valueText;
  return scoreColors[cogDemandClarityToMessage[clarityText][cognitiveDemandText]];
};
const determineScoreMessage = (clarityValue, cogDemandValue) => {
  return scoreMessages[cogDemandClarityToMessage[clarityValue][cogDemandValue]];
};
export const AttentionScoreDescription = ({ onOverlayChange, insight }) => {
  if (!insight) {
    return null;
  }
  const [overlay, setOverlay] = useState(OverlayType.NONE);
  const [scoreMessage, setScoreMessage] = useState(
    determineScoreMessage(
      findScoreObject(ClarityList, Math.round(insight.metrics.clarity)).valueText,
      findScoreObject(CognitiveDemandList, Math.round(insight.metrics.cognitive_demand)).valueText
    )
  );
  useEffect(() => {
    setScoreMessage(
      determineScoreMessage(
        findScoreObject(ClarityList, Math.round(insight.metrics.clarity)).valueText,
        findScoreObject(CognitiveDemandList, Math.round(insight.metrics.cognitive_demand)).valueText
      )
    );
  }, [insight]);
  const overlayChange = (e) => {
    setOverlay(e.key);
    onOverlayChange && onOverlayChange(e.key);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description" }, /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__header" }, /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__header-left" }, /* @__PURE__ */ React.createElement("span", { className: "icon fab fa-buffer" }), /* @__PURE__ */ React.createElement("span", null, "Overlays")), /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__header-right" }, overlayList.map((e, i) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        key: i,
        className: `${overlay === e.key ? "tint-attention-score-description-overlay--selected" : ""} tint-attention-score-description-overlay`,
        onClick: () => overlayChange(e)
      },
      /* @__PURE__ */ React.createElement("span", null, e.name)
    );
  }))), /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__body" }, /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__circle" }, /* @__PURE__ */ React.createElement(
    AttentionScoreCircleInfo,
    __spreadValues({
      tooltip: "Cognitive Demand describes how much information your customer has to process in this asset.",
      title: "Cognitive Demand"
    }, findScoreObject(CognitiveDemandList, Math.round(insight.metrics.cognitive_demand)))
  ), /* @__PURE__ */ React.createElement(
    AttentionScoreProgressBar,
    {
      colors: [
        { value: 25, color: Colors.DARK_GREEN },
        { value: 25, color: Colors.LIGHT_GREEN },
        { value: 25, color: Colors.YELLOW },
        { value: 25, color: Colors.RED }
      ],
      value: Math.round(insight.metrics.cognitive_demand)
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__circle" }, /* @__PURE__ */ React.createElement(
    AttentionScoreCircleInfo,
    __spreadValues({
      tooltip: "Clarity describes how much of your asset requires attention at the same time.",
      title: "Clarity"
    }, findScoreObject(ClarityList, Math.round(insight.metrics.clarity)))
  ), /* @__PURE__ */ React.createElement(
    AttentionScoreProgressBar,
    {
      colors: [
        { value: 25, color: Colors.RED },
        { value: 25, color: Colors.YELLOW },
        { value: 25, color: Colors.LIGHT_GREEN },
        { value: 25, color: Colors.DARK_GREEN }
      ],
      value: Math.round(insight.metrics.clarity)
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "tint-attention-score-description__footer" }, /* @__PURE__ */ React.createElement("span", { className: "tint-attention-score-description__footer-title" }, "Your Score Explained..."), /* @__PURE__ */ React.createElement("ul", null, scoreMessage.map((e, i) => /* @__PURE__ */ React.createElement("li", { key: i }, e)))));
};
