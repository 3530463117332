var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { EditInput, EditInputDropdown, EditForm } from "../../../";
import { pollImageType } from "../../../../../../../model/poll-image-type.model";
import { TintSelector } from "../../form/edit/tint-selector/tint-selector";
import { ExperienceConfig } from "../../../../../utils/experience.config";
import { ExperienceType } from "../../../../../../../model/experience.type";
export class EditPollContent extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "getAmountOfVotes", (amount) => new Array(amount).fill(void 0).map((e, i) => ({ value: i + 1, name: `${i + 1} Votes` })));
  }
  getTintPollSizes() {
    return [
      { value: 6, name: "6 Posts" },
      { value: 9, name: "9 Posts" },
      { value: 12, name: "12 Posts" },
      { value: 15, name: "15 Posts" },
      { value: 18, name: "18 Posts" },
      { value: 21, name: "21 Posts" },
      { value: 24, name: "24 Posts" },
      { value: 27, name: "27 Posts" },
      { value: 30, name: "30 Posts" },
      { value: 33, name: "33 Posts" },
      { value: 36, name: "36 Posts" }
    ];
  }
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "To display posts for your poll, select a TINT Board from the list below." }, /* @__PURE__ */ React.createElement(EditForm, null, /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit"), /* @__PURE__ */ React.createElement(
      TintSelector,
      {
        defaultTint: this.props.data.content.tintSlug,
        isPublicPosting: false,
        dataUpdate: ({ tintSlug }) => this.props.dataUpdate({ tintSlug })
      }
    ), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        placeholder: "Poll size",
        defaultValue: this.props.data.content.pollSize,
        onChange: (e) => {
          this.props.dataUpdate({ pollSize: e.target.value });
        },
        options: this.getTintPollSizes(),
        label: "poll size"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        placeholder: "Default: 2",
        defaultValue: this.props.data.content.amountOfVotes,
        onChange: (e) => {
          this.props.dataUpdate({ amountOfVotes: e.target.value });
        },
        options: this.getAmountOfVotes(ExperienceConfig[ExperienceType.POLL].maximumVoteAmount),
        label: "Amount of votes"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInputDropdown,
      {
        defaultValue: this.props.data.content.imageType,
        onChange: (e) => {
          this.props.dataUpdate({ imageType: e.target.value });
        },
        options: [
          { value: pollImageType.FILL, name: pollImageType.FILL },
          { value: pollImageType.FIT, name: pollImageType.FIT }
        ],
        label: "Image type"
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        defaultValue: this.props.getTranslation(this.props.data.content.buttonText),
        label: "Button text",
        onChange: (e) => {
          this.props.onTranslationUpdate(this.props.data.content.buttonText, e.target.value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      EditInput,
      {
        defaultValue: this.props.getTranslation(this.props.data.content.buttonVotedText),
        label: "Button voted text",
        onChange: (e) => {
          this.props.onTranslationUpdate(this.props.data.content.buttonVotedText, e.target.value);
        }
      }
    )));
  }
}
