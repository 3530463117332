import React, { useEffect, memo, useMemo, forwardRef, useState } from "react";
import * as PropTypes from "prop-types";
import { Config } from "../../../../utils/config";
import ColorParser from "../../../../services/color-parser/color-parser";
import "./wysiwyg-content-editor.sass";
import { keyCodes } from "../../../hooks/use-key-down/keyCodes";
import { commandIdList } from "../wysiwyg-navigation/wysiwyg-navigation-options";
import DOMPurify from "dompurify";
import useDebounce from "../../../hooks/use-debounce/use-debounce";
import { replaceQuoteSigns } from "../../utils/utils";
const WysiwygContentEditor = ({ id, style, value, onBlur, onChange, onUpdateContentProperties, onSelectAll, textDirection, onEditorInit }, ref) => {
  const countClickToSelectWholeText = 3;
  useEffect(() => {
    if (ref == null ? void 0 : ref.current) {
      onEditorInit == null ? void 0 : onEditorInit(ref);
    }
  }, [ref]);
  const camelCase = (string) => {
    return string.replace(/-([a-z])/gi, (all, letter) => letter.toUpperCase());
  };
  const backgroundColor = (style == null ? void 0 : style.backgroundColor) || { a: 0, b: 255, g: 255, r: 255 };
  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    const allowTags = ["SPAN", "DIV", "P", "A", "BR", "FONT", "UL", "LI", "OL", "H1", "H2", "H3", "H4", "H5", "H6"];
    if (allowTags.includes(node.tagName)) {
      for (let i = 0; i < Object.keys(node.style).length; i++) {
        if (!isNaN(Number(Object.keys(node.style)[i]))) {
          if (Config.regExp.strictHttp.test(node.style[node.style[[i]]])) {
            const styleCamelCase = camelCase(Object.values(node.style)[i]);
            node.style[styleCamelCase] = "";
          }
        } else {
          break;
        }
      }
    } else {
      node.remove();
    }
  });
  const onInputChange = () => {
    setTimeout(() => {
      setEditorValue(getContentValue());
    }, 0);
  };
  function newContent() {
    const cleanHTMLScripts = useMemo(() => {
      return value ? DOMPurify.sanitize(value) : "";
    }, [value]);
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        key: id,
        id,
        dir: textDirection,
        onBlur: () => {
          setTimeout(() => {
            onBlur == null ? void 0 : onBlur();
          });
          onInputChange();
        },
        onKeyDown: (e) => {
          onSelectAllByKeyboard(e);
        },
        onKeyUp: () => {
          onInputChange();
        },
        onInput: () => {
          onInputChange();
        },
        ref,
        contentEditable: true,
        onMouseDown: (e) => {
          onUpdateContentProperties();
          if ((e == null ? void 0 : e.detail) === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        },
        suppressContentEditableWarning: true,
        className: "tint-wysiwyg-editor",
        onFocus: (e) => {
          onUpdateContentProperties();
          if ((e == null ? void 0 : e.detail) === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        },
        onClick: (e) => {
          onUpdateContentProperties();
          if ((e == null ? void 0 : e.detail) === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        },
        dangerouslySetInnerHTML: {
          __html: cleanHTMLScripts
        }
      }
    );
  }
  function getContentValue() {
    const contentValues = ref.current;
    return contentValues && contentValues.innerHTML;
  }
  function onSelectAllByKeyboard(e) {
    if ((e.keyCode === keyCodes.command || (e == null ? void 0 : e.keyCode) === keyCodes.ctrl) && e.key === "a") {
      onSelectAll(commandIdList.SELECT_ALL);
    }
  }
  const debounceDelay = 100;
  const [editorValue, setEditorValue] = useState();
  const debounceValue = useDebounce(editorValue, debounceDelay);
  useEffect(() => {
    debounceValue !== void 0 && onChange(debounceValue);
  }, [debounceValue]);
  function oldContent() {
    var _a, _b;
    const cleanHTMLScripts = useMemo(() => {
      return value ? DOMPurify.sanitize(value) : "";
    }, [value]);
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        key: id,
        id,
        onBlur: () => {
          setTimeout(() => {
            onBlur == null ? void 0 : onBlur();
            onInputChange();
          });
        },
        onFocus: (e) => {
          onUpdateContentProperties();
          if ((e == null ? void 0 : e.detail) === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        },
        onMouseDown: (e) => {
          onUpdateContentProperties();
          if ((e == null ? void 0 : e.detail) === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        },
        onKeyDown: (e) => {
          onSelectAllByKeyboard(e);
        },
        onInput: () => {
          onInputChange();
        },
        onKeyUp: () => {
          onInputChange();
        },
        ref,
        contentEditable: true,
        suppressContentEditableWarning: true,
        className: "tint-wysiwyg-editor",
        onClick: (e) => {
          onUpdateContentProperties();
          if ((e == null ? void 0 : e.detail) === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        },
        style: {
          textAlign: style == null ? void 0 : style.textAlign,
          fontFamily: `${replaceQuoteSigns((_a = style == null ? void 0 : style.fontFamily) == null ? void 0 : _a.name)}` || Config.fonts.secondaryFont
        }
      },
      (style == null ? void 0 : style.urlLink) ? /* @__PURE__ */ React.createElement("a", { href: style == null ? void 0 : style.urlLink, target: "_blank", rel: "noopener noreferrer" }, /* @__PURE__ */ React.createElement(
        "span",
        {
          style: {
            fontFamily: `${replaceQuoteSigns((_b = style == null ? void 0 : style.fontFamily) == null ? void 0 : _b.name)}`,
            textAlign: style == null ? void 0 : style.textAlign,
            fontStyle: style == null ? void 0 : style.fontStyle,
            textDecoration: style == null ? void 0 : style.textDecoration,
            backgroundColor: ColorParser.defaultColor(backgroundColor),
            fontWeight: (style == null ? void 0 : style.fontWeight) === 500 ? 400 : style == null ? void 0 : style.fontWeight,
            color: ColorParser.defaultColor(style == null ? void 0 : style.color),
            fontSize: style == null ? void 0 : style.fontSize
          }
        },
        cleanHTMLScripts
      )) : cleanHTMLScripts
    );
  }
  function renderWysiwygElement() {
    return Config.regExp.htmlTag.test(value) ? newContent() : oldContent();
  }
  return renderWysiwygElement();
};
export default memo(forwardRef(WysiwygContentEditor));
WysiwygContentEditor.propTypes = {
  style: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onUpdateContentProperties: PropTypes.func,
  ref: PropTypes.object,
  onSelectAll: PropTypes.func
};
