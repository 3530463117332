import { useState, useEffect } from "react";
const usePercents = (maxValue = 1, currentValue = 0) => {
  const [percentValue, setPercentValue] = useState(null);
  const countPercent = () => {
    const hundredPercents = 100;
    setPercentValue(currentValue * hundredPercents / maxValue);
  };
  useEffect(() => {
    countPercent();
  }, [maxValue, currentValue]);
  return percentValue == null ? void 0 : percentValue.toFixed(0);
};
export default usePercents;
