import { dropdownAdditionalSelectField } from "../utils/common/common";
export const SocialFeedsFooterLabels = {
  firstStepPrev: "Cancel",
  firstStepNext: "Next",
  firstStepSave: "Save",
  finalStepPrev: "Previous",
  finalStepNext: "Add Social Feed",
  finalStepUpgrade: "Upgrade"
};
export const flickrDisabledButton = (values) => {
  var _a, _b;
  return values.account === void 0 || values.account === "" || ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.value) === dropdownAdditionalSelectField.value;
};
export const twitterDisabledButton = (values) => {
  var _a, _b;
  return values.account === void 0 || values.account === "" || ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.value) === dropdownAdditionalSelectField.value;
};
export const tumblrDisabledButton = (values) => {
  var _a, _b;
  return values.account === void 0 || values.account === "" || ((_b = (_a = values.account) == null ? void 0 : _a.value) == null ? void 0 : _b.value) === dropdownAdditionalSelectField.value;
};
export const instagramDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const facebookDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const publicPostingDisabledButton = (values) => {
  var _a;
  return !((_a = values.checked) == null ? void 0 : _a.includes("customTerms"));
};
export const linkedinDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const slackDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const hootsuiteDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const webexDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const experienceBuilderDisabledButton = (values) => {
  var _a, _b;
  return !((_a = values == null ? void 0 : values.experience) == null ? void 0 : _a.value) || ((_b = values == null ? void 0 : values.experience) == null ? void 0 : _b.value) === "";
};
export const formsDisabledButton = (values) => {
  var _a, _b;
  return !((_a = values == null ? void 0 : values.form) == null ? void 0 : _a.value) || ((_b = values == null ? void 0 : values.form) == null ? void 0 : _b.value) === "";
};
export const postMediaAltTextDisabledButton = (values) => {
  var _a, _b;
  return !((_a = values == null ? void 0 : values.media) == null ? void 0 : _a.value) || ((_b = values == null ? void 0 : values.media) == null ? void 0 : _b.value) === "";
};
export const pinterestDisabledButton = (values, additionalCondition) => {
  return (values == null ? void 0 : values.account) === "" || additionalCondition;
};
export const creatorIqDisabledButton = (values, additionalCondition) => {
  var _a, _b;
  return values.account === void 0 || values.account === "" || ((_a = values.account) == null ? void 0 : _a.value) === "" || ((_b = values.account) == null ? void 0 : _b.value) === dropdownAdditionalSelectField.value || additionalCondition;
};
export const threadsDisabledButton = (values) => {
  return (values == null ? void 0 : values.account) === "";
};
