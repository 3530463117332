var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import "./date-time-picker.sass";
import CustomDatePicker from "../interface/inputs/custom-date-picker/custom-date-picker";
import HourPicker from "../hour-picker/hour-picker";
import { Typography, TypographyColors, TypographyVariants } from "../interface/typography/typography";
export const DateTimePicker = ({ data, onChange, label = "Start Date & Time", isDisabled }) => {
  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      date: (data == null ? void 0 : data.data) || /* @__PURE__ */ new Date(),
      mins: (data == null ? void 0 : data.mins) || (/* @__PURE__ */ new Date()).getMinutes(),
      hour: (data == null ? void 0 : data.hour) || (/* @__PURE__ */ new Date()).getHours()
    }
  });
  useEffect(() => {
    const currentDate = formik.values.date;
    currentDate.setHours(formik.values.hour);
    currentDate.setMinutes(formik.values.mins);
    currentDate.setSeconds(59);
    onChange == null ? void 0 : onChange(currentDate);
  }, [formik.values]);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-date-time-picker" }, /* @__PURE__ */ React.createElement(
    Typography,
    {
      color: TypographyColors.PRIMARY,
      variant: TypographyVariants.LABEL,
      component: "label",
      className: "tint-api-dropdown__label"
    },
    label
  ), /* @__PURE__ */ React.createElement(
    DatePicker,
    {
      customInput: /* @__PURE__ */ React.createElement(CustomDatePicker, { disabled: isDisabled }),
      onChange: (date) => formik.setFieldValue("date", date),
      selected: formik.values.date,
      disabled: isDisabled
    }
  ), /* @__PURE__ */ React.createElement(
    HourPicker,
    {
      data: __spreadValues({}, formik.values),
      formikProps: formik,
      onChange: (option) => {
        const selectedItemKey = Object.entries(option);
        formik.setFieldValue(selectedItemKey[0][0], selectedItemKey[0][1]);
      }
    }
  ));
};
