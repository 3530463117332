import React from "react";
import PropTypes from "prop-types";
const CustomButton = ({ styles, buttonUrl, buttonTitle }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-custom-button" }, /* @__PURE__ */ React.createElement("a", { href: buttonUrl, target: "_blank", rel: "noopener noreferrer", style: styles }, /* @__PURE__ */ React.createElement("span", null, buttonTitle)));
};
export default CustomButton;
CustomButton.propTypes = {
  styles: PropTypes.shape({
    buttonTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonOutlineType: PropTypes.number,
    buttonOutline: PropTypes.string,
    buttonOutlineBorderColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  buttonUrl: PropTypes.string,
  buttonTitle: PropTypes.string
};
