var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { COMMON_SETTINGS } from "./common-settings";
import { languageList } from "../configs/personalization-languages";
export const ARCHIVED_ATMOSPHERE = {
  name: "Archived Atmosphere",
  key: "hypeTemplate_atmosphere",
  premiumDesign: true,
  default_settings: __spreadProps(__spreadValues({}, COMMON_SETTINGS), {
    post_duration: "10000",
    themeName: "hypeTemplate_atmosphere",
    atmosphere_blip_colors: ["#ff564b", "#ff6c54", "#2fc2c7", "#50d3d8", "#d74743"]
  }),
  guards: {
    CTA: false,
    waterfall: false,
    popup: false,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true
  },
  font_settings: [
    {
      key: "fontcolor_post",
      label: "Font Color",
      type: "colorPicker"
    }
  ],
  themes_settings: [
    {
      key: "atmosphere_blip_colors",
      type: "dropdownColorPalette",
      label: "Atmosphere colors",
      options: [
        ["#ff564b", "#ff6c54", "#2fc2c7", "#50d3d8", "#d74743"],
        ["#d74743", "#fdb35c", "#5bc36f", "#4598ba", "#a36ec9"],
        ["#000000", "#3a3a3a", "#6e6e6e", "#a0a0a0", "#bbbbbb"],
        ["#244c21", "#397f31", "#78aa5d", "#c9de8e", "#b2ca69"],
        ["#345b62", "#55797f", "#a9daaa", "#7bbc9b", "#3e8685"],
        ["#c52733", "#e13441", "#f3644f", "#fd9c61", "#facc84"],
        ["#19999e", "#54cdc3", "#b5ea61", "#fd6c6e", "#c24e5a"],
        ["#34132f", "#424253", "#65908a", "#e7caa6", "#ca2c44"],
        ["#6dd2e6", "#a9dbd8", "#c8e2d3", "#f1863c", "#f86920"]
      ]
    },
    {
      key: "post_duration",
      label: "Post Duration",
      type: "dropdown",
      options: [
        {
          value: "4000",
          name: "4 seconds"
        },
        {
          value: "6000",
          name: "6 seconds"
        },
        {
          value: "8000",
          name: "8 seconds"
        },
        {
          value: "10000",
          name: "10 seconds"
        },
        {
          value: "15000",
          name: "15 seconds"
        },
        {
          value: "30000",
          name: "30 seconds"
        },
        {
          value: "60000",
          name: "60 seconds"
        }
      ]
    }
  ],
  extra_settings: [
    {
      key: "time_language",
      type: "dropdown",
      label: "Language for post timestamp",
      options: languageList
    },
    {
      key: "shareIcons"
    }
  ],
  extra_popup_settings: []
};
