import React from "react";
import "./author.sass";
import { AuthorAvatar } from "../author-avatar/author-avatar";
const AuthorAvatarDefaultSize = 30;
const Author = ({
  avatarSize = AuthorAvatarDefaultSize,
  author,
  authorUrl,
  socialFeedSource,
  socialFeedConfig,
  withAvatar = true
}) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-author" }, withAvatar && /* @__PURE__ */ React.createElement(
    AuthorAvatar,
    {
      author,
      size: avatarSize,
      socialFeedSource,
      socialFeedConfig
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-author__author-meta" }, (author == null ? void 0 : author.userName) && /* @__PURE__ */ React.createElement("p", { className: "tint-editor-author__author-meta--name" }, /* @__PURE__ */ React.createElement("a", { href: authorUrl, target: "_blank", rel: "noopener noreferrer" }, "@", author.userName)), (author == null ? void 0 : author.name) && /* @__PURE__ */ React.createElement("p", { className: "tint-editor-author__author-meta--feed-id" }, /* @__PURE__ */ React.createElement("a", { href: authorUrl, target: "_blank", rel: "noopener noreferrer" }, author.name))));
};
export default Author;
