var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Worker from "./hashWorker.worker.js";
export const fileChecksum = (file, onprogress) => __async(void 0, null, function* () {
  return yield md5FromFile(file, onprogress);
});
const md5FromFile = (file, onprogress) => {
  return new Promise((resolve, reject) => {
    const worker = new Worker();
    worker.postMessage(file);
    worker.onmessage = (event) => {
      if (event.data.type === "progress") {
        onprogress == null ? void 0 : onprogress(event.data);
      }
      if (event.data.type === "result") {
        resolve(event.data.hashBase64);
      }
    };
    worker.onerror = reject;
  });
};
