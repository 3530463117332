import * as Yup from "yup";
export const validationSchemaThreadsBrandContent = () => {
  return Yup.object({
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaThreadsKeywordContent = () => {
  return Yup.object({
    account: Yup.object().required("Account is required."),
    keyword: Yup.string().matches(/^\w+$/, "Keyword should only be 1 word").required("Keyword is required.")
  });
};
