var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/assets-aggregations/assets-aggregations.actions";
import { AggsTypes } from "../../../../types/assets-aggregations";
import produce from "immer";
export const initialState = {
  [AggsTypes.EMOTIONS]: [],
  [AggsTypes.MIME_TYPES]: [],
  [AggsTypes.OBJECTS]: [],
  [AggsTypes.SOURCES]: [],
  [AggsTypes.TAGS]: [],
  isFetching: false,
  error: void 0
};
export const assetsAggregations = (state = initialState, action) => {
  var _a;
  switch (action.type) {
    case ACTIONS.GET_ASSETS_AGGREGATIONS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true
      });
    case ACTIONS.GET_ASSETS_AGGREGATIONS.SUCCESS:
      return __spreadProps(__spreadValues(__spreadValues({}, state), (_a = action.payload) == null ? void 0 : _a.reduce((result, item) => {
        var _a2;
        return Object.assign(result, { [item.id]: Object.keys((_a2 = item == null ? void 0 : item.attributes) == null ? void 0 : _a2.buckets) });
      }, {})), {
        error: void 0,
        isFetching: false
      });
    case ACTIONS.GET_ASSETS_AGGREGATIONS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false
      });
    case ACTIONS.UPDATE_ASSETS_AGGREGATIONS_TAGS:
      return produce(state, (draft) => {
        draft.tags = [.../* @__PURE__ */ new Set([...draft.tags, ...action.payload])];
      });
    default:
      return state;
  }
};
