var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./add-block.sass";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ClickOutsideHOC } from "../../../../../components/HOC/click-outside/click-outside.hoc";
import { CustomSectionComponentModal } from "./custom-section-component-modal/custom-section-component-modal";
class AddBlock extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "onIconClick", () => this.props.isMenuElementVisible ? this.props.hideMenu() : this.props.displayMenu());
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-add-block ${!this.props.isFetching && this.props.isMenuElementVisible ? "tint-add-block--active" : ""}`,
        ref: (node) => this.props.setMenuNode(node)
      },
      /* @__PURE__ */ React.createElement("div", { className: "plus-container", onClick: this.onIconClick }, /* @__PURE__ */ React.createElement("span", { className: "fas fa-plus" })),
      /* @__PURE__ */ React.createElement("div", { className: "tint-add-block__title-container" }, /* @__PURE__ */ React.createElement("span", null, "Add Block")),
      !this.props.isFetching && this.props.isMenuElementVisible ? this.props.isForMultiColumn ? /* @__PURE__ */ React.createElement("div", { className: "tint-add-block__custom-modal-container" }, /* @__PURE__ */ React.createElement("div", { className: "tint-add-block__custom-modal-wrapper" }, /* @__PURE__ */ React.createElement(
        CustomSectionComponentModal,
        {
          isForMultiColumn: this.props.isForMultiColumn,
          hideMenu: this.props.hideMenu,
          addSection: this.props.addSection,
          pageSectionIndex: this.props.pageSectionIndex,
          styles: this.props.styles,
          enabled: this.props.session.features,
          experience: this.props.experience
        }
      ))) : /* @__PURE__ */ React.createElement(
        CustomSectionComponentModal,
        {
          isForMultiColumn: this.props.isForMultiColumn,
          hideMenu: this.props.hideMenu,
          addSection: this.props.addSection,
          pageSectionIndex: this.props.pageSectionIndex,
          styles: this.props.styles,
          enabled: this.props.session.features,
          experience: this.props.experience
        }
      ) : null
    );
  }
}
const mapStateToProps = (state) => ({
  session: state.session.data,
  isFetching: state.experience.isFetching,
  experience: state.experience.dataDraft
});
export default connect(mapStateToProps)(ClickOutsideHOC(AddBlock));
AddBlock.propTypes = {
  pageSectionIndex: PropTypes.number,
  displayMenu: PropTypes.func,
  hideMenu: PropTypes.func,
  setMenuNode: PropTypes.func,
  isMenuElementVisible: PropTypes.bool,
  styles: PropTypes.object,
  isForMultiColumn: PropTypes.bool
};
