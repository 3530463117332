import * as Yup from "yup";
export const validationSchemaTumblrHashtag = () => {
  return Yup.object({
    account: Yup.object().required("Account is required."),
    hashtag: Yup.string().required("Hashtag is required.")
  });
};
export const validationSchemaTumblrUsername = () => {
  return Yup.object({
    account: Yup.object().required("Account is required."),
    username: Yup.string().required("Username is required.")
  });
};
