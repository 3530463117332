var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/products/products.actions";
import { ACTIONS as UI_ECOMMERCE_ACTIONS } from "../../../ecommerce/actions/ui-ecommerce.actions";
export const initialState = {
  data: [],
  links: void 0,
  infiniteLoadedData: [],
  infiniteLoadedLinks: void 0,
  assignedProducts: [],
  source: "",
  meta: void 0,
  isFetching: false,
  error: void 0
};
export const products = (state = initialState, action) => {
  var _a;
  switch (action.type) {
    case ACTIONS.GET_PRODUCTS.REQUEST:
    case ACTIONS.GET_INFINITE_LOADED_PRODUCTS.REQUEST:
    case ACTIONS.GET_FILTERED_PRODUCTS.REQUEST:
    case ACTIONS.DELETE_PRODUCT.REQUEST:
    case ACTIONS.POST_PRODUCT.REQUEST:
    case ACTIONS.PATCH_PRODUCT.REQUEST:
    case ACTIONS.GET_ASSIGNED_PRODUCTS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case UI_ECOMMERCE_ACTIONS.ASSIGN_PRODUCT:
      return __spreadProps(__spreadValues({}, state), {
        assignedProducts: [...state.assignedProducts, action.payload.productDetails]
      });
    case ACTIONS.PATCH_PRODUCT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), { isFetching: false });
    case ACTIONS.GET_ASSIGNED_PRODUCTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        assignedProducts: action.payload.data,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_PRODUCTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data,
        included: (_a = action.payload) == null ? void 0 : _a.included,
        links: action.payload.links,
        infiniteLoadedData: action.payload.data,
        infiniteLoadedLinks: action.payload.links,
        meta: action.payload.meta,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.SET_DEFAULT_FILTERED_PRODUCTS:
      return __spreadProps(__spreadValues({}, state), {
        infiniteLoadedData: state.data,
        infiniteLoadedLinks: state.links
      });
    case ACTIONS.GET_INFINITE_LOADED_PRODUCTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        infiniteLoadedData: [...state.infiniteLoadedData, ...action.payload.data],
        infiniteLoadedLinks: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_FILTERED_PRODUCTS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        infiniteLoadedData: action.payload.data,
        infiniteLoadedLinks: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_PRODUCT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: void 0,
        data: state.data.filter((e) => e.id !== action.payload)
      });
    case ACTIONS.POST_PRODUCT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [action.payload, ...state.data],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_PRODUCTS.FAILURE:
    case ACTIONS.GET_INFINITE_LOADED_PRODUCTS.FAILURE:
    case ACTIONS.GET_FILTERED_PRODUCTS.FAILURE:
    case ACTIONS.DELETE_PRODUCT.FAILURE:
    case ACTIONS.POST_PRODUCT.FAILURE:
    case ACTIONS.PATCH_PRODUCT.FAILURE:
    case ACTIONS.GET_ASSIGNED_PRODUCTS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
};
export const getCategories = (state) => {
  var _a, _b;
  return (_b = (_a = state.meta) == null ? void 0 : _a.aggregations) == null ? void 0 : _b.categories;
};
export const getBrand = (state) => {
  var _a, _b;
  return (_b = (_a = state.meta) == null ? void 0 : _a.aggregations) == null ? void 0 : _b.brand;
};
export const getAlreadyAddedSkuValues = (state) => state ? state.map((e) => e.attributes.sku) : [];
