var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState } from "react";
import "./pagination-bars.sass";
import { faAngleDoubleLeft, faMinusCircle, faPlusCircle, faTag, faTrash } from "@fortawesome/pro-solid-svg-icons";
import TintIcon from "../../../../components/interface/icon/icon";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import { Button as TintButton } from "../../../../components/interface/button/button";
import { CheckboxSelect } from "../../../../components/interface/inputs/checkbox-select/checkbox-select";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, updatePageSize, updateSelectedPosts } from "../../actions/posts.actions";
import { deletePosts, getLastPage, getPostsFromURL, updatePosts } from "../../actions/posts.fat-actions";
import { toast } from "react-toastify";
import AddTags from "../../../../components/interface/add-tags/add-tags";
import Button from "../../../../components/button";
import { GuardNames } from "../../../../guards/guards-list";
import ConfirmModal, { ConfirmModalResult } from "../../../../components/confirm-modal/confirm-modal";
import { useQuery } from "../../../../components/hooks/use-query/use-query";
import { TintEditorConfig } from "../../utils/tint-editor.config";
import { TintEditorViewState } from "../../containers/tint-editor/tint-editor.container";
import Tooltip from "../../../transform-ui/components/tooltip/tooltip";
import FeatureGuard from "../../../../guards/feature-guard";
import { ReloadPostsButton } from "../reload-posts-button/reload-posts-button";
import { colors } from "../../../../utils/variables/colors";
import { Config } from "../../../../utils/config";
const PaginationBar = ({
  scrollRef,
  getPosts,
  isScrollingDown,
  isAnyFilterSelected,
  isSelectedMenuVisible,
  setIsSelectedMenuVisible
}) => {
  const { tintId } = useParams();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const selectedPosts = useSelector((state) => state.tintEditorPosts.selectedPosts);
  const pageSize = useSelector((state) => state.tintEditorPosts.pageSize);
  const currentPageStart = useSelector((state) => state.tintEditorPosts.currentPageStart);
  const currentPageEnd = useSelector((state) => state.tintEditorPosts.currentPageEnd);
  const posts = useSelector((state) => state.tintEditorPosts.data);
  const links = useSelector((state) => state.tintEditorPosts.links);
  const isLastPage = useSelector((state) => state.tintEditorPosts.isLastPage);
  const isLoading = useSelector((state) => state.tintEditorPosts.isLoading);
  const [tags, setTags] = useState([]);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState();
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const onSelectCheckboxStatusChanged = () => {
    isSelectedMenuVisible ? dispatch(updateSelectedPosts([])) : dispatch(updateSelectedPosts(posts));
    setIsSelectedMenuVisible(!isSelectedMenuVisible);
  };
  const queryParams = useQuery();
  const isTrashState = queryParams.get(TintEditorConfig.urlSearchParamState) === TintEditorViewState.TRASH;
  const paginationButtonsConfig = {
    firstPage: 1,
    previousPage: currentPageStart - pageSize,
    nextPage: currentPageStart + pageSize,
    currentPageNumber: currentPageEnd / pageSize
  };
  const hasPosts = (posts == null ? void 0 : posts.length) > 0;
  useEffect(() => {
    setIsSelectedMenuVisible((selectedPosts == null ? void 0 : selectedPosts.length) > 0);
    setTags([]);
    setIsTagMenuOpen(false);
  }, [selectedPosts]);
  useEffect(() => {
    if (posts.length <= pageSize) {
      dispatch(getLastPage(links == null ? void 0 : links.next));
    }
  }, [links, dispatch]);
  const definePaginationBarClass = () => {
    return isScrollingDown ? "tint-editor-pagination-bar--collapsed" : isAnyFilterSelected ? "tint-editor-pagination-bar--filter-selected" : "";
  };
  const pageSizes = [
    { name: "20", value: 20 },
    { name: "40", value: 40 },
    { name: "60", value: 60 },
    { name: "80", value: 80 },
    { name: "100", value: 100 },
    { name: "120", value: 120 }
  ];
  const patchedPosts = (patchedData) => {
    return selectedPosts.map((post) => __spreadProps(__spreadValues({}, post), {
      attributes: __spreadValues(__spreadValues({}, post.attributes), patchedData)
    }));
  };
  const onUpdateFeedCard = ({ newTags }) => {
    dispatch(
      updatePosts({
        updatedPosts: patchedPosts({ tags: newTags })
      })
    ).then(() => {
      setTags(newTags);
    }).catch(() => {
      toast.error("Something went wrong");
    });
  };
  const menuItems = [
    {
      icon: faPlusCircle,
      action: () => dispatch(
        updatePosts({
          updatedPosts: patchedPosts({ status: "public" })
        })
      ).catch(() => {
        toast.error("Something went wrong");
      }),
      tooltipDescription: "Make Selected Posts Public"
    },
    {
      icon: faMinusCircle,
      action: () => dispatch(
        updatePosts({
          updatedPosts: patchedPosts({ status: "private" })
        })
      ).catch(() => {
        toast.error("Something went wrong");
      }),
      tooltipDescription: "Make Selected Posts Private"
    },
    {
      icon: faTag,
      action: () => setIsTagMenuOpen(!isTagMenuOpen),
      tooltipDescription: "Tag Selected Posts",
      guard: GuardNames.TAGGING
    },
    {
      icon: faTrash,
      action: () => {
        isTrashState ? setIsConfirmModalVisible(true) : setDeletedPostsStatus();
      },
      tooltipDescription: "Delete Selected Posts"
    }
  ];
  const navigationItems = [
    {
      icon: faAngleDoubleLeft,
      action: () => {
        dispatch(setIsLoading(true));
        dispatch(getPostsFromURL(links == null ? void 0 : links.first, paginationButtonsConfig.firstPage));
      },
      isDisabled: currentPageStart === 1
    },
    {
      icon: faAngleLeft,
      action: () => {
        scrollRef.current.scrollIntoView();
        dispatch(setIsLoading(true));
        dispatch(getPostsFromURL(links == null ? void 0 : links.prev, paginationButtonsConfig.previousPage));
      },
      isDisabled: !hasPosts || currentPageStart === 1
    },
    {
      icon: faAngleRight,
      action: () => {
        scrollRef.current.scrollIntoView();
        dispatch(setIsLoading(true));
        dispatch(getPostsFromURL(links == null ? void 0 : links.next, paginationButtonsConfig.nextPage));
      },
      isDisabled: !hasPosts || currentPageEnd < pageSize || isLastPage || isLoading
    }
  ];
  const onChangePageSize = (selectedSize) => {
    dispatch(updatePageSize(selectedSize.value));
  };
  const setDeletedPostsStatus = () => dispatch(
    updatePosts({
      updatedPosts: patchedPosts({ status: "deleted" })
    })
  ).catch(() => {
    toast.error("Something went wrong");
  });
  const removePosts = () => {
    var _a;
    const data = selectedPosts.map((post) => ({
      id: post.id,
      type: post.type
    }));
    dispatch(
      deletePosts({
        data,
        teamId: (_a = session == null ? void 0 : session.data) == null ? void 0 : _a.team_id
      })
    ).then(() => dispatch(updateSelectedPosts([]))).catch(() => {
      toast.error("Something went wrong");
    });
  };
  const onModalChange = (type) => {
    switch (type) {
      case ConfirmModalResult.CONFIRM:
        removePosts();
        setIsConfirmModalVisible(false);
        break;
      case ConfirmModalResult.REJECT:
        setIsConfirmModalVisible(false);
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: `tint-editor-pagination-bar ${definePaginationBarClass()}` }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-pagination-bar__inner" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-editor-pagination-bar__content ${isSelectedMenuVisible ? "tint-editor-pagination-bar__content--post-selected" : ""}`
    },
    /* @__PURE__ */ React.createElement(CheckboxSelect, { onClick: onSelectCheckboxStatusChanged, isChecked: isSelectedMenuVisible }),
    /* @__PURE__ */ React.createElement("label", { className: "tint-editor-pagination-bar__select-label", onClick: onSelectCheckboxStatusChanged }, selectedPosts.length ? /* @__PURE__ */ React.createElement("span", null, selectedPosts.length, " ", selectedPosts.length === 1 ? "Post" : "Posts", " Selected") : "Select All"),
    isSelectedMenuVisible && /* @__PURE__ */ React.createElement(
      TintButton,
      {
        type: "no-style",
        onClick: onSelectCheckboxStatusChanged,
        className: "tint-editor-pagination-bar__deselect"
      },
      "Deselect All"
    ),
    isConfirmModalVisible && /* @__PURE__ */ React.createElement(
      ConfirmModal,
      {
        confirmButtonType: "error",
        isOpen: isConfirmModalVisible,
        description: "Selected content will be permanently deleted and cannot be recovered.",
        rightButtonText: "Yes, Delete Content",
        leftButtonText: "No, Keep Content",
        onChange: onModalChange
      }
    ),
    /* @__PURE__ */ React.createElement(ReloadPostsButton, { onClick: getPosts, tintId })
  ), /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.MODERATE_TINT }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-editor-pagination-bar__moderate-wrapper ${isSelectedMenuVisible ? "tint-editor-pagination-bar__moderate-wrapper--collapsed" : ""}`
    },
    menuItems.map((item, index) => /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: item.guard ? item.guard : GuardNames.ALL, key: item.tooltipDescription }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Tooltip,
      {
        component: /* @__PURE__ */ React.createElement(
          TintButton,
          {
            type: "no-style",
            key: index,
            onClick: item.action,
            className: "tint-editor-pagination-bar__icon"
          },
          /* @__PURE__ */ React.createElement(TintIcon, { icon: item.icon })
        ),
        text: item.tooltipDescription
      }
    ))))
  )), isTagMenuOpen && /* @__PURE__ */ React.createElement("div", { className: "tint-editor-pagination-bar__tag" }, /* @__PURE__ */ React.createElement(AddTags, { tagList: tags, onAddTag: setTags, onDeleteTag: setTags, focusOnInit: true }), /* @__PURE__ */ React.createElement(
    TintButton,
    {
      text: "Done",
      type: "primary",
      onClick: () => {
        onUpdateFeedCard({ newTags: tags });
        setIsTagMenuOpen(false);
      }
    }
  )), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-editor-pagination-bar__navigation ${isSelectedMenuVisible ? "tint-editor-pagination-bar__navigation--post-selected" : ""}`
    },
    /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        currentItem: pageSizes.find((size) => size.value === pageSize),
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownHeaderStyle: {
          color: colors.textSecondary,
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "40px",
          padding: "0 8px"
        },
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: pageSizes,
        onChangeValue: onChangePageSize,
        buttonType: ButtonType.BUTTON_NO_STYL,
        iconRight: "fa fa-caret-down"
      }
    ),
    /* @__PURE__ */ React.createElement("span", { className: "tint-editor-pagination-bar__page-count" }, "per page"),
    /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `tint-editor-pagination-bar__current-page ${isSelectedMenuVisible ? "tint-editor-pagination-bar__current-page--post-selected" : ""}`
      },
      hasPosts ? `${currentPageStart}-${currentPageEnd}` : ""
    ),
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-pagination-bar__navigation-container" }, navigationItems.map((item, index) => /* @__PURE__ */ React.createElement(
      Button,
      {
        key: index,
        disabled: item.isDisabled,
        onClick: item.action,
        className: "tint-editor-pagination-bar__navigation-icon"
      },
      /* @__PURE__ */ React.createElement(TintIcon, { icon: item.icon, fontSize: "16px" })
    )))
  )));
};
PaginationBar.propTypes = {};
export default PaginationBar;
