import React from "react";
import "./star-rating.sass";
import TintIcon from "../../../../../components/interface/icon/icon";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { getActiveStars } from "../../../reducers/posts.reducer";
export const MAX_STAR_AMOUNT = 5;
const StarRating = ({ rating }) => {
  const activeStars = rating ? getActiveStars(rating) : 0;
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-star-rating" }, [...Array(activeStars)].map((_, index) => {
    return /* @__PURE__ */ React.createElement(TintIcon, { key: index, icon: faStar, color: "#FB9501", fontSize: "18px" });
  }));
};
export default StarRating;
