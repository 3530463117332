export const PersonalizationTypesToReadableText = {
  embed: "Website Embed",
  screen: "Signage Display",
  email: "Email Display",
  tile: "SmartTile",
  ad: "Ad Unit",
  shoppable: "Shoppable Instagram",
  event: "Live Event",
  mobile: "Mobile App",
  not_available: "n/a",
  reviews: "Ratings & Reviews",
  virtual_event: "Virtual Event"
};
export const PersonalizationTypes = {
  EMBED: "embed",
  SCREEN: "screen",
  EMAIL: "email",
  TILE: "tile",
  AD: "ad",
  SHOPPABLE: "shoppable",
  EVENT: "event",
  VIRTUAL_EVENT: "virtual_event",
  MOBILE: "mobile",
  NOT_AVAILABLE: "not_available",
  REVIEWS: "reviews"
};
