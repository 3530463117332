import React from "react";
import { handleApiError } from "../../../services/functions/error-handler/error-handler";
import { useSocialAccountsAuthorizationService } from "../../../feature/account-settings/hooks/use-social-accounts-authorization-service";
import { getSocialConnection } from "../../../feature/account-settings/actions/social-connections/social-connections.fat-actions";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setDropdownData, updateDropdownData } from "../../../actions/ui/ui.actions";
import ApiDropdown from "../api-dropdown";
import { getUrl } from "../api-dropdown.helpers";
import { filters } from "../../../feature/account-settings/utils/filter-helper/filter-helper";
import { Config } from "../../../utils/config";
const accountsAdditionalOption = {
  name: "+ Add Account",
  value: "ADD_ACCOUNT"
};
export const DropdownAccounts = ({
  accountsDropdownId = "accounts",
  currentElement,
  onChange,
  onAccountCreate,
  onAccountUpdate,
  styles,
  placeholder = "Select Account...",
  queryParams,
  fetchFnMapper,
  filterDataFn,
  include,
  accountType,
  additionalOption
}) => {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const { addSocialConnection, refreshSocialConnection } = useSocialAccountsAuthorizationService({
    session: session.data,
    onEditAccountSuccess: (data) => {
      onAccountUpdate(data);
      dispatch(
        updateDropdownData({
          id: accountsDropdownId,
          data
        })
      );
    },
    onNewAccountSuccess: (data) => {
      dispatch(getSocialConnection({ socialConnectionId: data.id })).then(() => {
        onAccountCreate(data);
        dispatch(
          setDropdownData({
            id: accountsDropdownId,
            data: [data]
          })
        );
      }).catch((err) => handleApiError(err, toast.error));
    }
  });
  return /* @__PURE__ */ React.createElement("div", { className: "tint-accounts-dropdown" }, /* @__PURE__ */ React.createElement(
    ApiDropdown,
    {
      placeholder,
      currentElement,
      fetchUrl: (url, query) => getUrl({
        url,
        query,
        baseUrl: queryParams.baseUrl,
        filters: (queryParams == null ? void 0 : queryParams.filters) ? [
          {
            by: filters.QUERY,
            value: query
          },
          ...queryParams == null ? void 0 : queryParams.filters
        ] : [
          {
            by: filters.QUERY,
            value: query
          }
        ],
        size: Config.defaultAccountsPageSize,
        include
      }),
      baseUrl: queryParams == null ? void 0 : queryParams.baseUrl,
      dropdownId: accountsDropdownId,
      fetchFnMapper,
      filterDataFn,
      onChange,
      additionalOption: additionalOption || {
        name: accountsAdditionalOption.name,
        value: accountType,
        action: (e) => addSocialConnection(e)
      },
      styles,
      refreshSocialConnection,
      fixedOption: true
    }
  ));
};
