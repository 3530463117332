import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  GET_MIXES: createRoutine(createPrefixedActionName("TINT_MIX", "GET_MIXES")),
  GET_MIX: createRoutine(createPrefixedActionName("TINT_MIX", "GET_MIX")),
  POST_MIX: createRoutine(createPrefixedActionName("TINT_MIX", "POST_MIX")),
  PATCH_MIX: createRoutine(createPrefixedActionName("TINT_MIX", "PATCH_MIX")),
  DELETE_MIX: createRoutine(createPrefixedActionName("TINT_MIX", "DELETE_MIX")),
  BULK_DELETE_MIXES: createRoutine(createPrefixedActionName("TINT_MIX", "BULK_DELETE_MIXES")),
  SET_NEW_MIX: createPrefixedActionName("TINT_MIX", "SET_NEW_MIX"),
  SET_CURRENT_MIX_UI: createPrefixedActionName("TINT_MIX", "SET_CURRENT_MIX_UI"),
  UPDATE_TINT_MIX_UI: createPrefixedActionName("TINT_MIX", "UPDATE_TINT_MIX_UI"),
  CLEAR_CURRENT_MIX_UI: createPrefixedActionName("TINT_MIX", "CLEAR_CURRENT_MIX"),
  CHANGE_SCENE_LAYOUT: createPrefixedActionName("TINT_MIX", "CHANGE_SCENE_LAYOUT"),
  DELETE_SCENE: createPrefixedActionName("TINT_MIX", "DELETE_SCENE"),
  ADD_NEW_SCENE: createPrefixedActionName("TINT_MIX", "ADD_NEW_SCENE"),
  ADD_NEW_AREA_ITEM: createPrefixedActionName("TINT_MIX", "ADD_NEW_AREA_ITEM"),
  RENAME_TINT_MIX: createPrefixedActionName("TINT_MIX", "RENAME_TINT_MIX"),
  UPDATE_AREA_ITEM: createPrefixedActionName("TINT_MIX", "UPDATE_AREA_ITEM"),
  REMOVE_AREA_ITEM: createPrefixedActionName("TINT_MIX", "REMOVE_AREA_ITEM"),
  REORDER_SCENE: createPrefixedActionName("TINT_MIX", "REORDER_SCENE"),
  REORDER_AREA_ITEM: createPrefixedActionName("TINT_MIX", "REORDER_AREA_ITEM"),
  SELECT_MIX: createPrefixedActionName("TINT_MIX", "SELECT_MIX"),
  UNSELECT_MIX: createPrefixedActionName("TINT_MIX", "UNSELECT_MIX"),
  DESELECT_ALL_MIXES: createPrefixedActionName("TINT_MIX", "DESELECT_ALL_MIXES")
};
export const updateTintMixUI = () => ({
  type: ACTIONS.UPDATE_TINT_MIX_UI
});
export const getMixesStart = () => ({
  type: ACTIONS.GET_MIXES.REQUEST
});
export const getMixesSuccess = (data) => ({
  type: ACTIONS.GET_MIXES.SUCCESS,
  payload: data
});
export const getMixesError = (err) => ({
  type: ACTIONS.GET_MIXES.FAILURE,
  payload: err
});
export const getMixStart = () => ({
  type: ACTIONS.GET_MIX.REQUEST
});
export const getMixSuccess = (data) => ({
  type: ACTIONS.GET_MIX.SUCCESS,
  payload: data
});
export const getMixError = (err) => ({
  type: ACTIONS.GET_MIX.FAILURE,
  payload: err
});
export const postMixStart = () => ({
  type: ACTIONS.POST_MIX.REQUEST
});
export const postMixSuccess = (data) => ({
  type: ACTIONS.POST_MIX.SUCCESS,
  payload: data
});
export const postMixError = (err) => ({
  type: ACTIONS.POST_MIX.FAILURE,
  payload: err
});
export const patchMixStart = () => ({
  type: ACTIONS.PATCH_MIX.REQUEST
});
export const patchMixSuccess = (data) => ({
  type: ACTIONS.PATCH_MIX.SUCCESS,
  payload: data
});
export const patchMixError = (err) => ({
  type: ACTIONS.PATCH_MIX.FAILURE,
  payload: err
});
export const deleteMixStart = () => ({
  type: ACTIONS.DELETE_MIX.REQUEST
});
export const deleteMixSuccess = (id) => ({
  type: ACTIONS.DELETE_MIX.SUCCESS,
  payload: id
});
export const deleteMixError = (err) => ({
  type: ACTIONS.DELETE_MIX.FAILURE,
  payload: err
});
export const setNewTintMix = () => ({
  type: ACTIONS.SET_NEW_MIX
});
export const setTintMix = (data) => ({
  type: ACTIONS.SET_CURRENT_MIX_UI,
  payload: data
});
export const clearTintMix = () => ({
  type: ACTIONS.CLEAR_CURRENT_MIX_UI
});
export const changeSceneLayout = ({ sceneIndex, template }) => ({
  type: ACTIONS.CHANGE_SCENE_LAYOUT,
  payload: {
    sceneIndex,
    template
  }
});
export const renameTintMix = (name, id) => ({
  type: ACTIONS.RENAME_TINT_MIX,
  payload: { name, id }
});
export const reorderScene = (list) => ({
  type: ACTIONS.REORDER_SCENE,
  payload: list
});
export const reorderAreaItem = ({ areas, sceneIndex }) => ({
  type: ACTIONS.REORDER_AREA_ITEM,
  payload: { areas, sceneIndex }
});
export const addNewScene = (index) => ({
  type: ACTIONS.ADD_NEW_SCENE,
  payload: index
});
export const deleteScene = (index) => ({
  type: ACTIONS.DELETE_SCENE,
  payload: index
});
export const addNewTintMixSceneAreaItem = ({ data, contentType, sceneIndex, area }) => ({
  type: ACTIONS.ADD_NEW_AREA_ITEM,
  payload: { data, contentType, sceneIndex, area }
});
export const updateAreaItem = ({ data, contentType, sceneIndex, area, areaIndex }) => ({
  type: ACTIONS.UPDATE_AREA_ITEM,
  payload: { data, contentType, sceneIndex, area, areaIndex }
});
export const removeAreaItem = ({ index, sceneIndex, area }) => ({
  type: ACTIONS.REMOVE_AREA_ITEM,
  payload: { index, sceneIndex, area }
});
export const selectMix = (tint) => ({
  type: ACTIONS.SELECT_MIX,
  payload: tint
});
export const unselectMix = (id) => ({
  type: ACTIONS.UNSELECT_MIX,
  payload: id
});
export const deselectAllMixes = () => ({
  type: ACTIONS.DESELECT_ALL_MIXES
});
export const bulkDeleteMixesStart = () => ({
  type: ACTIONS.BULK_DELETE_MIXES.REQUEST
});
export const bulkDeleteMixesSuccess = (id) => ({
  type: ACTIONS.BULK_DELETE_MIXES.SUCCESS,
  payload: id
});
export const bulkDeleteMixesError = (err) => ({
  type: ACTIONS.BULK_DELETE_MIXES.FAILURE,
  payload: err
});
