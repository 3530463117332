import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./progress-bar.sass";
export const ProgressBarColorMode = {
  BLUE: "blue",
  GREEN: "green"
};
const ProgressBar = ({ totalProgress, currentProgress, color = ProgressBarColorMode.BLUE }) => {
  const [value, setValue] = useState(0);
  const maxSize = 100;
  const minSize = 0;
  useEffect(() => {
    if (currentProgress <= minSize) return setValue(minSize);
    if (currentProgress > totalProgress) return setValue(maxSize);
    setValue(currentProgress / totalProgress * maxSize);
  });
  return /* @__PURE__ */ React.createElement("div", { className: `tint-progress-bar tint-progress-bar--${color}`, style: { width: `${maxSize}%` } }, /* @__PURE__ */ React.createElement("div", { style: { width: `${value}%` }, className: "tint-progress-bar__current-progress" }));
};
export default ProgressBar;
ProgressBar.propTypes = {
  totalProgress: PropTypes.number,
  currentProgress: PropTypes.number,
  color: PropTypes.string
};
