import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const numberScale = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE]: {
    display: "grid",
    gap: "10px",
    "grid-template-columns": "repeat(11, 1fr)",
    padding: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_LEGEND]: {
    "grid-column": "auto/span 11"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_INPUT]: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    appearance: "none",
    border: "1px solid #DFDFDF",
    padding: "10px",
    "border-radius": "6px",
    cursor: "pointer",
    margin: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_LABEL]: {
    display: "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_DIV]: {
    "word-break": "normal",
    "overflow-wrap": "anywhere",
    "grid-row": 3
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_INPUT_BEFORE]: {
    content: "attr(value)"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_INPUT_ACTIVE]: {
    background: "#3B99FC",
    color: "#ffffff"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_LABEL_MIN]: {
    "grid-column": "auto/span 5"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_LABEL_MAX]: {
    "text-align": "right",
    "grid-column": "auto/span 6"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NUMBER_SCALE_RTL]: {
    "text-align": "left"
  }
};
