var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./assets-sort-by.sass";
import { useDispatch } from "react-redux";
import { setSortOption } from "../../actions/filters/asset-manager-filters.actions";
import { getAssets } from "../../actions/asset-manager.fat-actions";
import { getAssetUrlWithFilters } from "../../utils/filter-helper/filter-helper";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
const filterDropdown = [
  { name: "Modified (Newest)", value: "-updated_at" },
  { name: "Modified (Oldest)", value: "updated_at" },
  { name: "Added (Newest)", value: "-created_at" },
  { name: "Added (Oldest)", value: "created_at" }
];
export const AssetsSortBy = () => {
  const dispatch = useDispatch();
  const onChange = (selectedItem) => {
    dispatch(setSortOption(selectedItem.value));
    dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-assets-sort-by" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      list: filterDropdown,
      iconLeft: "far fa-sort-amount-down",
      iconRight: "fa fa-caret-down",
      placeholder: "Added (Newest)",
      buttonType: ButtonType.BUTTON_SORT,
      onChangeValue: onChange
    }
  ));
};
