import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { SocialFeedsFooterLabels, webexDisabledButton } from "./helpers";
import { validationSchemaWebex } from "../utils/validation-schema/validation-webex";
export const webex = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "spark",
    selectedOption: { id: "spark", title: "Add Cisco Webex" },
    header: "Add Cisco Webex",
    description: "To ingest Webex content, please select an account and space below:",
    formSchema: {
      spark: {
        validationSchema: validationSchemaWebex(),
        submitMapper: (values) => {
          return {
            account_id: values.account.value,
            external_id: values.space.value,
            search_term: values.term
          };
        },
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "Select Account",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "space",
            backendId: "external_id",
            label: "Select Space",
            placeholder: "Select Space...",
            icon: SidebarIcons.BOOKMARK,
            type: SidebarItemTypes.SELECT,
            disabled: webexDisabledButton
          },
          {
            id: "term",
            backendId: "search_term",
            label: "ENTER KEYWORD",
            placeholder: "Keyword",
            icon: SidebarIcons.SEARCH,
            type: SidebarItemTypes.TEXT,
            disabled: webexDisabledButton
          }
        ]
      }
    }
  }
];
