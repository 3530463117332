var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/social-publishing.actions";
import { ACTIONS as ACTIONS_CAMPAIGNS } from "../actions/social-publishing-campaigns.actions";
import produce from "immer";
const initialState = {
  data: [],
  accounts: [],
  media: [],
  subAccounts: [],
  aggregations: {
    tags: [],
    accounts: [],
    subAccounts: []
  },
  campaigns: [],
  error: false,
  isFetching: false,
  socialConnections: {
    accounts: [],
    subAccounts: []
  }
};
export const socialPublishingCalendar = (state = initialState, action) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u;
  switch (action.type) {
    case ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS.GET_SCHEDULED_CONTENT.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isCalendarFetching: false
      });
    case ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.FAILURE:
    case ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.FAILURE:
    case ACTIONS.PATCH_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS.POST_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS.DELETE_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS_CAMPAIGNS.POST_CAMPAIGN.FAILURE:
    case ACTIONS_CAMPAIGNS.GET_CAMPAIGNS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        error: action.payload,
        isFetching: false,
        isPartialFetching: false
      });
    case ACTIONS_CAMPAIGNS.POST_CAMPAIGN.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        campaigns: [action.payload, ...state.campaigns],
        isPartialFetching: false
      });
    case ACTIONS.POST_SCHEDULED_CONTENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [...state.data, (_a = action.payload) == null ? void 0 : _a.data],
        accounts: removeDuplicates([
          ...state == null ? void 0 : state.accounts,
          ...(_b = action.payload.included) == null ? void 0 : _b.filter((el) => (el == null ? void 0 : el.type) === "account")
        ]),
        subAccounts: removeDuplicates([
          ...state == null ? void 0 : state.subAccounts,
          ...(_c = action.payload.included) == null ? void 0 : _c.filter((el) => (el == null ? void 0 : el.type) === "subaccount")
        ]),
        media: [...state == null ? void 0 : state.media, ...(_e = (_d = action.payload) == null ? void 0 : _d.included) == null ? void 0 : _e.filter((el) => (el == null ? void 0 : el.type) === "upload")],
        aggregations: __spreadProps(__spreadValues({}, state.aggregations), {
          accounts: [
            .../* @__PURE__ */ new Set([
              ...state.aggregations.accounts,
              ...(_f = action.payload.included) == null ? void 0 : _f.filter((el) => (el == null ? void 0 : el.type) === "account").map((el) => el.id)
            ])
          ],
          subAccounts: [
            .../* @__PURE__ */ new Set([
              ...state.aggregations.subAccounts,
              ...(_g = action.payload.included) == null ? void 0 : _g.filter((el) => (el == null ? void 0 : el.type) === "subaccount").map((el) => el.id)
            ])
          ]
        }),
        isPartialFetching: false
      });
    case ACTIONS.GET_SCHEDULED_CONTENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: (_h = action.payload) == null ? void 0 : _h.data,
        accounts: ((_j = (_i = action.payload) == null ? void 0 : _i.included) == null ? void 0 : _j.filter((el) => (el == null ? void 0 : el.type) === "account")) || [],
        subAccounts: ((_l = (_k = action.payload) == null ? void 0 : _k.included) == null ? void 0 : _l.filter((el) => (el == null ? void 0 : el.type) === "subaccount")) || [],
        media: ((_n = (_m = action.payload) == null ? void 0 : _m.included) == null ? void 0 : _n.filter((el) => (el == null ? void 0 : el.type) === "upload")) || [],
        isCalendarFetching: false
      });
    case ACTIONS.CLEAR_DATA:
      return __spreadProps(__spreadValues({}, state), {
        data: [],
        accounts: [],
        media: [],
        subAccounts: []
      });
    case ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [...state == null ? void 0 : state.data, ...(_o = action.payload) == null ? void 0 : _o.data],
        accounts: [...state == null ? void 0 : state.accounts, ...((_q = (_p = action == null ? void 0 : action.payload) == null ? void 0 : _p.included) == null ? void 0 : _q.filter((el) => (el == null ? void 0 : el.type) === "account")) || []],
        subAccounts: [
          ...state == null ? void 0 : state.subAccounts,
          ...((_s = (_r = action == null ? void 0 : action.payload) == null ? void 0 : _r.included) == null ? void 0 : _s.filter((el) => (el == null ? void 0 : el.type) === "subaccount")) || []
        ],
        media: [...state == null ? void 0 : state.media, ((_u = (_t = action == null ? void 0 : action.payload) == null ? void 0 : _t.included) == null ? void 0 : _u.filter((el) => (el == null ? void 0 : el.type) === "upload")) || []].flat(),
        isCalendarFetching: false
      });
    case ACTIONS.PATCH_SCHEDULED_CONTENT.SUCCESS:
      return produce(state, (draft) => {
        var _a2, _b2, _c2, _d2, _e2, _f2;
        const currentIndex = draft.data.findIndex((el) => el.id === action.payload.id);
        draft.data[currentIndex] = action.payload.data;
        draft.accounts = [...state.accounts, ...(_b2 = (_a2 = action.payload) == null ? void 0 : _a2.included) == null ? void 0 : _b2.filter((el) => (el == null ? void 0 : el.type) === "account")];
        draft.subAccounts = [
          ...state.subAccounts,
          ...(_d2 = (_c2 = action.payload) == null ? void 0 : _c2.included) == null ? void 0 : _d2.filter((el) => (el == null ? void 0 : el.type) === "subaccount")
        ];
        draft.media = [...state.media, ...(_f2 = (_e2 = action.payload) == null ? void 0 : _e2.included) == null ? void 0 : _f2.filter((el) => (el == null ? void 0 : el.type) === "upload")];
        draft.isPartialFetching = false;
      });
    case ACTIONS.DELETE_SCHEDULED_CONTENT.SUCCESS:
      return produce(state, (draft) => {
        draft.data = draft.data.filter((content) => content.id !== action.payload);
        draft.isPartialFetching = false;
      });
    case ACTIONS_CAMPAIGNS.GET_CAMPAIGNS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        campaigns: action.payload,
        isFetching: false
      });
    case ACTIONS_CAMPAIGNS.PATCH_CAMPAIGN.SUCCESS:
      return produce(state, (draft) => {
        const currentIndex = draft.campaigns.findIndex((el) => el.id === action.payload.id);
        draft.campaigns[currentIndex] = action.payload.data;
      });
    case ACTIONS_CAMPAIGNS.DELETE_CAMPAIGN.SUCCESS:
      return produce(state, (draft) => {
        draft.campaigns = draft.campaigns.filter((campaign) => campaign.id !== action.payload);
      });
    case ACTIONS_CAMPAIGNS.DELETE_CAMPAIGN.REQUEST:
    case ACTIONS_CAMPAIGNS.PATCH_CAMPAIGN.REQUEST:
    case ACTIONS_CAMPAIGNS.DELETE_CAMPAIGN.FAILURE:
    case ACTIONS_CAMPAIGNS.PATCH_CAMPAIGN.FAILURE:
      return __spreadValues({}, state);
    case ACTIONS_CAMPAIGNS.POST_CAMPAIGN.REQUEST:
    case ACTIONS.PATCH_SCHEDULED_CONTENT.REQUEST:
    case ACTIONS.DELETE_SCHEDULED_CONTENT.REQUEST:
    case ACTIONS.POST_SCHEDULED_CONTENT.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isPartialFetching: true
      });
    case ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.REQUEST:
    case ACTIONS.GET_SCHEDULED_CONTENT.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isCalendarFetching: true
      });
    case ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.REQUEST:
    case ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.REQUEST:
    case ACTIONS_CAMPAIGNS.GET_CAMPAIGNS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true
      });
    case ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.SUCCESS:
      return produce(state, (draft) => {
        var _a2, _b2, _c2, _d2, _e2;
        const tags = action.payload.find((el) => el.id === "tags");
        const accountIds = (_b2 = (_a2 = action.payload.find((el) => el.id === "account_ids")) == null ? void 0 : _a2.attributes) == null ? void 0 : _b2.buckets;
        const subaccountsIds = (_d2 = (_c2 = action.payload.find((el) => el.id === "subaccount_ids")) == null ? void 0 : _c2.attributes) == null ? void 0 : _d2.buckets;
        const accountIdKeys = Object.keys(accountIds);
        const subaccountIdKeys = Object.keys(subaccountsIds);
        const mapSubaccountIds = subaccountIdKeys == null ? void 0 : subaccountIdKeys.map((key) => {
          const idMap = key.split("|");
          const hasAccount = idMap.length > 2;
          return !hasAccount ? idMap[1] : idMap[2];
        }).filter(Boolean);
        draft.isFetching = false;
        draft.aggregations = {
          tags: Object.keys((_e2 = tags == null ? void 0 : tags.attributes) == null ? void 0 : _e2.buckets),
          accounts: accountIdKeys,
          subAccounts: mapSubaccountIds
        };
      });
    case ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.SUCCESS:
      return produce(state, (draft) => {
        var _a2, _b2;
        draft.socialConnections = {
          accounts: action.payload.data,
          subAccounts: (_b2 = (_a2 = action.payload) == null ? void 0 : _a2.included) == null ? void 0 : _b2.filter((el) => el.type === "subaccount")
        };
      });
    default:
      return state;
  }
};
const removeDuplicates = (data) => Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
export const isScheduledContentLoading = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.isCalendarFetching;
};
export const getMappedCalendarData = (state) => {
  var _a, _b, _c, _d;
  const data = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.data;
  const accounts = (_b = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _b.accounts;
  const subAccounts = (_c = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _c.subAccounts;
  const media = (_d = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _d.media;
  if (!data || !media || !accounts || !subAccounts) return;
  return data.map((el) => {
    var _a2, _b2, _c2, _d2, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
    const mediaList = (_b2 = (_a2 = el == null ? void 0 : el.relationships) == null ? void 0 : _a2.media) == null ? void 0 : _b2.data.map((asset) => media.find((_media) => _media.id === asset.id));
    const accountId = (_e = (_d2 = (_c2 = el == null ? void 0 : el.relationships) == null ? void 0 : _c2.account) == null ? void 0 : _d2.data) == null ? void 0 : _e.id;
    const currentAccount = accounts.find((account) => account.id === accountId);
    const currentSubAccount = getSubAccountById(subAccounts, (_h = (_g = (_f = el == null ? void 0 : el.relationships) == null ? void 0 : _f.subaccount) == null ? void 0 : _g.data) == null ? void 0 : _h.id);
    return __spreadProps(__spreadValues({}, el), {
      campaignId: (_k = (_j = (_i = el == null ? void 0 : el.relationships) == null ? void 0 : _i.campaign) == null ? void 0 : _j.data) == null ? void 0 : _k.id,
      media: mediaList,
      socialAccount: currentAccount ? {
        name: (_l = currentAccount == null ? void 0 : currentAccount.attributes) == null ? void 0 : _l.name,
        accountId: currentAccount == null ? void 0 : currentAccount.id,
        userName: (_m = currentAccount == null ? void 0 : currentAccount.attributes) == null ? void 0 : _m.username,
        meta: (_n = currentAccount == null ? void 0 : currentAccount.attributes) == null ? void 0 : _n.meta,
        subAccountName: ((_o = currentSubAccount == null ? void 0 : currentSubAccount.attributes) == null ? void 0 : _o.name) || ((_p = currentSubAccount == null ? void 0 : currentSubAccount.attributes) == null ? void 0 : _p.username),
        type: (_q = currentAccount == null ? void 0 : currentAccount.attributes) == null ? void 0 : _q.type,
        imageUrl: ((_r = currentSubAccount == null ? void 0 : currentSubAccount.attributes) == null ? void 0 : _r.image_url) || ((_s = currentAccount == null ? void 0 : currentAccount.attributes) == null ? void 0 : _s.image_url)
      } : null
    });
  });
};
export const getSocialConnectionAccounts = (state) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.socialConnections) == null ? void 0 : _b.accounts;
};
export const getSocialConnectionSubAccounts = (state) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.socialConnections) == null ? void 0 : _b.subAccounts;
};
export const getAccounts = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.accounts;
};
export const getSubAccounts = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.subAccounts;
};
export const getSubAccountById = (subAccounts, id) => {
  if (!subAccounts || subAccounts.length === 0 || !id) return;
  return subAccounts.find((subAccount) => subAccount.id === id);
};
export const getCampaignsData = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.campaigns;
};
export const getAssetPreview = (state, id) => {
  var _a, _b;
  const currentAsset = (_b = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.media) == null ? void 0 : _b.find((el) => el.id === id);
  return currentAsset ? [currentAsset == null ? void 0 : currentAsset.attributes.url] : [];
};
export const getAssetsPreview = (state, idsList) => {
  if (!idsList) return [];
  return idsList.map((id) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.media.find((asset) => asset.id === id)) == null ? void 0 : _b.attributes) == null ? void 0 : _c.url;
  }).filter(Boolean);
};
export const getActiveCampaignsDropdownList = (state, currentDate = (/* @__PURE__ */ new Date()).getTime()) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.campaigns.filter((el) => new Date(el == null ? void 0 : el.attributes.ends_at).getTime() > new Date(currentDate).getTime()).map((campaign) => {
    var _a2;
    return {
      name: (_a2 = campaign == null ? void 0 : campaign.attributes) == null ? void 0 : _a2.name,
      value: campaign.id
    };
  });
};
export const getMappedCampaignsData = (state) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.campaigns) == null ? void 0 : _b.map((campaign) => ({
    id: campaign.id,
    name: campaign == null ? void 0 : campaign.attributes.name,
    startDate: campaign == null ? void 0 : campaign.attributes.starts_at,
    endDate: campaign == null ? void 0 : campaign.attributes.ends_at,
    labelColor: campaign == null ? void 0 : campaign.attributes.label_color
  }));
};
export const getAggregations = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.aggregations;
};
export const getScheduledContentMedia = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.media;
};
export const getCurrentTags = (state) => {
  var _a, _b, _c, _d;
  const aggregationsTags = (_b = (_a = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _a.aggregations) == null ? void 0 : _b.tags;
  const tags = ((_d = (_c = state == null ? void 0 : state.socialPublishingCalendar) == null ? void 0 : _c.data) == null ? void 0 : _d.map((el) => el.attributes.tags).flat()) || [];
  return [.../* @__PURE__ */ new Set([...aggregationsTags, ...tags])].map((tag) => ({
    name: tag,
    value: tag
  }));
};
