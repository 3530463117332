import { postSimilarImage } from "../../../feature/tint-editor/actions/posts.fat-actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
export const useVisualSearch = () => {
  const dispatch = useDispatch();
  const onVisualSearchUpload = ({ tintId, src, postId, pageSize, filters, sortBy }) => {
    dispatch(
      postSimilarImage({
        tintId,
        src,
        postId,
        pageSize,
        filters,
        sortBy
      })
    ).then(() => {
    }).catch((err) => {
      var _a, _b, _c, _d, _e, _f, _g;
      if ((_c = (_b = (_a = err == null ? void 0 : err.response) == null ? void 0 : _a.data) == null ? void 0 : _b.errors) == null ? void 0 : _c.find((error) => error.status === "404")) {
        toast.info("We could not found similar images");
      } else if ((_f = (_e = (_d = err == null ? void 0 : err.response) == null ? void 0 : _d.data) == null ? void 0 : _e.errors) == null ? void 0 : _f.find((error) => error.status === "402")) {
        toast.error(((_g = err[0]) == null ? void 0 : _g.details) || "Reached upload limits");
      } else {
        toast.error("Something went wrong, try again later");
      }
    });
  };
  return {
    onVisualSearchUpload
  };
};
