var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../../components/interface/button/button";
const FilePicker = ({ onClick, value, onChange, imageUrl }) => {
  const styles = {
    padding: " 3em",
    margin: "0 0 10px"
  };
  const updatedStyles = imageUrl ? __spreadProps(__spreadValues({}, styles), { background: `center / cover no-repeat url(${imageUrl}` }) : styles;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("input", { type: "text", onChange, name: "file", value, style: { display: "none" } }), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick,
      type: "gray",
      size: "full-width",
      text: "Add files",
      icon: "fas fa-plus-circle",
      styles: updatedStyles
    }
  ));
};
FilePicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  imageUrl: PropTypes.string
};
export default FilePicker;
