import { useState, useEffect } from "react";
export const DropdownListPosition = {
  LEFT: "position-left",
  RIGHT: "position-right",
  TOP: "position-top",
  BOTTOM: "position-bottom"
};
const useDropdownListPosition = (ref, parentRef) => {
  const [positionHorizontal, setPositionHorizontalClass] = useState("left");
  const [positionVertical, setPositionVerticalClass] = useState("top");
  const checkElementPositionHorizontal = (element) => {
    var _a, _b;
    const offsetLeft = (_a = parentRef == null ? void 0 : parentRef.current) == null ? void 0 : _a.getBoundingClientRect();
    if (window.innerWidth - offsetLeft.left > ((_b = element == null ? void 0 : element.current) == null ? void 0 : _b.clientWidth)) {
      setPositionHorizontalClass(DropdownListPosition.LEFT);
    } else {
      setPositionHorizontalClass(DropdownListPosition.RIGHT);
    }
  };
  const checkElementPositionVertical = (element) => {
    var _a, _b, _c, _d, _e;
    if (window.innerHeight - (((_a = parentRef == null ? void 0 : parentRef.current) == null ? void 0 : _a.offsetTop) + (((_b = parentRef == null ? void 0 : parentRef.current) == null ? void 0 : _b.offsetParent) ? (_d = (_c = parentRef == null ? void 0 : parentRef.current) == null ? void 0 : _c.offsetParent) == null ? void 0 : _d.offsetTop : 0)) > ((_e = element == null ? void 0 : element.current) == null ? void 0 : _e.clientHeight)) {
      setPositionVerticalClass(DropdownListPosition.BOTTOM);
    } else {
      setPositionVerticalClass(DropdownListPosition.TOP);
    }
  };
  useEffect(() => {
    checkElementPositionHorizontal(ref);
    checkElementPositionVertical(ref);
  }, []);
  return { positionHorizontal, positionVertical };
};
export default useDropdownListPosition;
