import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const multipleMatrix = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX]: {
    padding: 0,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_LEGEND]: {
    "margin-bottom": "16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_TABLE]: {
    width: "100%",
    "table-layout": "fixed"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_TBODY_TH]: {
    "font-weight": "initial",
    "text-align": "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_TBODY_TD]: {
    "text-align": "center"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_THEAD_TH]: {
    "font-weight": "initial",
    display: "table-cell"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_TH_TD]: {
    padding: "8px 8px 8px 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_INPUT]: {
    position: "relative",
    cursor: "pointer",
    width: "18px",
    height: "18px",
    padding: "0 !important",
    "border-radius": "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MULTIPLE_MATRIX_RTL]: {
    "text-align": "center"
  }
};
