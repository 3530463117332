import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { SocialFeedsFooterLabels } from "./helpers";
import { SocialFeedsSource } from "../../../../../../../types/social-feeds";
import { validationSchemaVesta } from "../utils/validation-schema/vesta-schema";
import { SocialFeedsAccountTypes, BackendKeys } from "../consts";
import { CommunityContentTypesTranslations } from "../../../../../../../utils/translations/community";
import { CommunityContentTypes } from "../../../../../../../types/community";
const initializeActivitySourceCheckedValue = (socialFeed, option) => {
  var _a, _b;
  if (socialFeed) {
    return ((_b = (_a = socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.activity_item_types.includes(option)) ? option : null;
  } else {
    return option === CommunityContentTypes.SHARE_LINK ? null : option;
  }
};
export const vesta = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave
    },
    accountSourceType: SocialFeedsSource.VESTA,
    selectedOption: { id: "mission", title: "Add Mission" },
    header: "Add Community Content",
    description: "Fill out the section below to pull in submitted content from your Mission Hub.",
    formSchema: {
      mission: {
        validationSchema: validationSchemaVesta(),
        submitMapper: (values) => {
          var _a;
          const checkedActivities = Object.values(CommunityContentTypes).reduce((acc, curr) => {
            const checked = !!values.checked.find((i) => i === curr);
            if (checked) {
              acc.push(curr);
            } else {
              acc.filter((e) => e !== curr);
            }
            return acc;
          }, []);
          return {
            external_id: (_a = values == null ? void 0 : values.mission) == null ? void 0 : _a.value,
            type: void 0,
            options: {
              activity_item_types: checkedActivities
            }
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.MISSION,
            backendId: BackendKeys.EXTERNAL_ID,
            label: "SELECT MISSION",
            placeholder: "Select Mission...",
            icon: SidebarIcons.MEGAPHONE,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "label",
            type: SidebarItemTypes.LABEL,
            label: "ACTIVITY ITEM TYPES"
          },
          ...Object.entries(CommunityContentTypes).map((e) => {
            const option = e[1];
            return {
              id: option,
              backendId: "activity_item_types",
              label: CommunityContentTypesTranslations[option],
              type: SidebarItemTypes.CHECKBOX,
              defaultValue: initializeActivitySourceCheckedValue
            };
          })
        ],
        editInputs: [
          {
            id: "label",
            type: SidebarItemTypes.LABEL,
            label: "ACTIVITY ITEM TYPES"
          },
          ...Object.entries(CommunityContentTypes).map((e) => {
            const option = e[1];
            return {
              id: option,
              backendId: "activity_item_types",
              label: CommunityContentTypesTranslations[option],
              type: SidebarItemTypes.CHECKBOX,
              defaultValue: initializeActivitySourceCheckedValue
            };
          })
        ]
      }
    }
  }
];
