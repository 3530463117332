import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { experienceBuilderDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { validationSchemaExperienceBuilder } from "../utils/validation-schema/experience-schema";
import { SocialFeedsAccountTypes, BackendKeys } from "../consts";
export const experienceBuilder = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "form_submission",
    selectedOption: { id: "builder", title: "Add ExB Form" },
    header: "Add ExB Form",
    description: "To display Experience Builder submission in your TINT, fill out the sections below:",
    formSchema: {
      builder: {
        validationSchema: validationSchemaExperienceBuilder(),
        submitMapper: (values) => {
          return {
            external_id: values.experience.value,
            options: {
              author_name_field: values.name.value,
              post_body_field: values.body.value
            },
            type: void 0
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.EXPERIENCE,
            backendId: BackendKeys.EXTERNAL_ID,
            label: "SELECT EXPERIENCE",
            placeholder: "Select Experience...",
            icon: SidebarIcons.MEGAPHONE,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "name",
            backendId: BackendKeys.AUTHOR_NAME_FIELD,
            label: "SELECT AUTHOR NAME",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s author name section.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.EXPERIENCE_SELECT,
            disabled: experienceBuilderDisabledButton
          },
          {
            id: "body",
            backendId: BackendKeys.POST_BODY_FIELD,
            label: "POST BODY",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the post\u2019s body content section.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.EXPERIENCE_SELECT,
            disabled: experienceBuilderDisabledButton
          }
        ]
      }
    }
  }
];
