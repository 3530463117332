var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { Config } from "../../../../../../utils/config";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../../../utils/styles/elements-classes/form-builder.styles.elements-classes";
const FormBuilderFormInterfaceImage = ({ item, styles, included = [], uploadedImages = [], t }) => {
  var _a, _b;
  const { img: image } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const imageSrc = t(image == null ? void 0 : image.src) === (image == null ? void 0 : image.src) ? "" : t(image == null ? void 0 : image.src);
  const match = imageSrc.match(Config.regExp.jsonPathID);
  const id = match && match[1] ? match[1] : null;
  const logoSrc = imageSrc ? ((_a = included.find((el) => el.id === id)) == null ? void 0 : _a.attributes.url) || ((_b = uploadedImages == null ? void 0 : uploadedImages.find((el) => (el == null ? void 0 : el.id) === id)) == null ? void 0 : _b.preview) : null;
  const src = logoSrc;
  const commonStylesNoImg = styles[FORM_BUILDER_STYLE_ELEMENT_CLASSES.NO_IMAGE];
  return /* @__PURE__ */ React.createElement("figure", { className: `${item.class}`, style: __spreadValues({}, !src ? commonStylesNoImg : {}) }, src && /* @__PURE__ */ React.createElement("img", { id: image.id, src, alt: t(image == null ? void 0 : image.alt) }));
};
FormBuilderFormInterfaceImage.displayName = "FormBuilderFormInterfaceImage";
export default React.memo(FormBuilderFormInterfaceImage);
