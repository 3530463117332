import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import { RangeInput } from "../../../../../../../components/interface/inputs/range-input/range-input";
import { EditUploadDesign } from "../../../";
export class EditHeaderStyles extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Edit your header background color" }, /* @__PURE__ */ React.createElement(
      RangeInput,
      {
        value: this.props.data.styles.logoHeight,
        onChange: (e) => {
          this.props.styleDataUpdate({ logoHeight: e });
        },
        label: "Logo size",
        maxRange: "150",
        minRange: "1",
        step: "1"
      }
    ), /* @__PURE__ */ React.createElement(
      EditUploadDesign,
      {
        dataUpdate: this.props.dataUpdate,
        styleDataUpdate: this.props.styleDataUpdate,
        data: this.props.data
      }
    ));
  }
}
