var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./customizable-radio-button.sass";
export const CustomizableRadioButtonDisplayType = {
  ROW: "row",
  COLUMN: "column",
  BORDER: "border"
};
const CustomizableRadioButton = ({
  id,
  name,
  value,
  isSelected,
  label,
  onSelect,
  onRemoveSelect,
  disabled,
  children,
  childrenProps,
  displayTypeClass = [CustomizableRadioButtonDisplayType.ROW, CustomizableRadioButtonDisplayType.BORDER]
}, ref) => {
  const onChange = () => {
    onSelect(value);
  };
  const renderChildrenContent = () => /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "customizable-radio-button__content",
      onClick: (e) => {
        e.stopPropagation();
      }
    },
    typeof children === "object" ? React.Children.map(children, (child, i) => /* @__PURE__ */ React.createElement("div", { key: i }, React.cloneElement(child, __spreadValues({}, childrenProps)))) : children && /* @__PURE__ */ React.createElement("span", null, children)
  );
  const getDisplayTypeClass = () => {
    return displayTypeClass.map((type) => {
      return `customizable-radio-button--${type}`;
    }).join(" ");
  };
  return /* @__PURE__ */ React.createElement("div", { className: `customizable-radio-button ${getDisplayTypeClass()} ${isSelected ? "active" : ""}` }, /* @__PURE__ */ React.createElement("div", { className: "customizable-radio-button__wrapper" }, /* @__PURE__ */ React.createElement("label", { htmlFor: id }, /* @__PURE__ */ React.createElement(
    "input",
    {
      ref,
      id,
      name,
      value,
      onChange,
      type: "radio",
      checked: isSelected,
      disabled
    }
  ), /* @__PURE__ */ React.createElement("span", { className: "customizable-radio-button__label" }, label))), renderChildrenContent(), onRemoveSelect && isSelected && /* @__PURE__ */ React.createElement("span", { className: "customizable-radio-button__unselect", onClick: onRemoveSelect }, "x"));
};
const CustomizableRadioButtonWithForwardedRef = forwardRef(CustomizableRadioButton);
CustomizableRadioButtonWithForwardedRef.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  children: PropTypes.any,
  displayTypeClass: PropTypes.array
};
export default CustomizableRadioButtonWithForwardedRef;
