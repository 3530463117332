import React from "react";
import "./sidebar-switcher-remove-modal-confirm.sass";
import { Button } from "../../../interface/button/button";
const TintSidebarSwitcherRemoveModalConfirm = ({
  title,
  description,
  onConfirm,
  onCancel,
  confirmButtonText = "Yes",
  cancelButtonText = "No"
}) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-switcher-remove-modal-confirm" }, /* @__PURE__ */ React.createElement("header", null, title), /* @__PURE__ */ React.createElement("p", null, description), /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-switcher-remove-modal-confirm__action" }, /* @__PURE__ */ React.createElement(Button, { type: "gray", text: cancelButtonText, onClick: onCancel }), /* @__PURE__ */ React.createElement(Button, { type: "error", text: confirmButtonText, onClick: onConfirm })));
};
export default TintSidebarSwitcherRemoveModalConfirm;
