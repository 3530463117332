import React from "react";
import "./dropdown-header.sass";
import TintIcon from "../../../icon/icon";
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType,
  PreloaderSizeType
} from "../../../preloaders/preloader/preloader";
export const DropdownHeader = ({
  dropdownHeaderStyle,
  iconLeft,
  iconRight,
  iconCenter,
  iconOverlap,
  title,
  children,
  isLoading
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-dropdown-header ${!title ? "tint-dropdown-header--no-title" : ""}`,
      style: dropdownHeaderStyle
    },
    iconLeft && typeof iconLeft === "string" && !iconCenter && /* @__PURE__ */ React.createElement("span", { className: `icon icon__left ${iconLeft}`, "data-testid": "iconLeft" }),
    iconLeft && typeof iconLeft !== "string" && !iconCenter && /* @__PURE__ */ React.createElement("span", { className: "icon icon__left" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: iconLeft, "data-testid": "iconLeft" })),
    iconCenter && !iconOverlap ? /* @__PURE__ */ React.createElement("span", { className: `icon ${iconCenter}`, "data-testid": "iconCenter" }) : null,
    iconCenter && iconOverlap ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { className: `icon ${iconCenter}`, "data-testid": "iconCenter" }), /* @__PURE__ */ React.createElement("span", { className: `icon__overlap ${iconOverlap}`, "data-testid": "iconOverlap" })) : null,
    title && !iconCenter ? /* @__PURE__ */ React.createElement(
      "span",
      {
        className: "tint-dropdown-header__title",
        style: { color: dropdownHeaderStyle && dropdownHeaderStyle.color ? dropdownHeaderStyle.color : void 0 }
      },
      title
    ) : null,
    children && /* @__PURE__ */ React.createElement(React.Fragment, null, children),
    isLoading ? /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-header__icons" }, isLoading && /* @__PURE__ */ React.createElement(
      PreloaderComponent,
      {
        positionType: PreloaderPositionType.RELATIVE,
        backdropType: PreloaderBackdropType.TRANSPARENT,
        size: PreloaderSizeType.SMALL
      }
    )) : iconRight && !iconCenter && /* @__PURE__ */ React.createElement("div", { className: "tint-dropdown-header__icons" }, typeof iconRight === "string" && /* @__PURE__ */ React.createElement("span", { className: `icon icon__right ${iconRight}`, "data-testid": "iconRight" }), typeof iconRight !== "string" && /* @__PURE__ */ React.createElement("span", { className: "icon icon__right" }, /* @__PURE__ */ React.createElement(TintIcon, { icon: iconRight, "data-testid": "iconRight" })))
  );
};
