import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  SET_FORM_SECTION_MODAL_STEP: createPrefixedActionName("TINT", "SET_FORM_SECTION_MODAL_STEP"),
  CLOSE_FORM_SECTION_INPUT_MODAL: createPrefixedActionName("TINT", "CLOSE_FORM_SECTION_INPUT_MODAL")
};
export const setFormSectionFieldModalStep = (payload) => ({
  type: ACTIONS.SET_FORM_SECTION_MODAL_STEP,
  payload
});
export const closeFormSectionFieldModal = () => ({
  type: ACTIONS.CLOSE_FORM_SECTION_INPUT_MODAL
});
