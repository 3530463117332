import Counter from "./components/counter/counter";
import React, { Component } from "react";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { connect } from "react-redux";
import Logger from "../../../../../../services/logger/logger";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import PreloaderComponent from "../../../../../../components/interface/preloaders/preloader/preloader";
import SurveyCounterEditStyles from "./edit/survey-counter-edit/survey-counter-edit-styles";
import { ApiService } from "../../../../../../services/api-service/api-service";
import { getFormSubmissionUrl } from "../../../../utils/experience-helper";
class SurveySectionCounter extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isCounterLoaded: false,
      amountOfVotes: 0
    };
  }
  componentDidMount() {
    this.updateMetrics();
  }
  updateMetrics() {
    ApiService.pureInstance().get(`${getFormSubmissionUrl(this.props.experienceId)}/metrics`).then((resp) => {
      this.setState({
        isCounterLoaded: true,
        amountOfVotes: resp.data.data[0] !== void 0 ? resp.data.data[0].attributes.count : 0
      });
    }).catch((e) => {
      Logger.warning(`Can not fetch data: ${e}`);
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--survey_counter ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement("div", { className: "tint-page-section__counter-container" }, this.state.isCounterLoaded || !this.props.isPreviewMode ? /* @__PURE__ */ React.createElement(
        Counter,
        {
          baseClass: this.props.baseClass,
          totalVotes: this.state.amountOfVotes,
          styles: this.props.data.styles,
          isLoading: this.state.isCounterLoaded || !this.props.isPreviewMode
        }
      ) : /* @__PURE__ */ React.createElement(
        PreloaderComponent,
        {
          color: ColorParser.defaultColor(this.props.data.styles.counterTextColor),
          "data-testid": "preloaderId"
        }
      )),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          stepIndex: this.props.stepIndex,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Survey Counter" }, /* @__PURE__ */ React.createElement(
          SurveyCounterEditStyles,
          {
            data: this.props.data,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        ))
      )
    );
  }
}
const mapStateToProps = (state) => ({
  experienceId: state.experience.data && state.experience.data.id
});
export default connect(mapStateToProps)(PageSectionHoc(SurveySectionCounter));
