import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const singleChoice = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE]: {
    display: "grid",
    "grid-template-columns": "min-content 1fr",
    padding: 0,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE_LEGEND]: {
    "margin-bottom": "16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE_INPUT]: {
    position: "relative",
    cursor: "pointer",
    width: "16px",
    height: "16px",
    "border-radius": "50%",
    padding: "0 !important",
    appearance: "none",
    margin: "2px 8px 16px 0",
    border: "1px solid #b6b8bb"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE_INPUT_CHECKED]: {
    border: "3px solid #3B99FC"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE_LABEL]: {
    "clip-path": "initial",
    overflow: "initial",
    position: "static",
    margin: "4px 0 16px 0",
    width: "initial",
    height: "initial",
    "word-break": "normal",
    "overflow-wrap": "anywhere",
    "padding-right": "4px"
  }
};
