var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/builder/form-builder.builder.actions";
import produce from "immer";
import { defaultFormStyles } from "../../utils/styles/schema/form-builder.styles.schema";
import {
  FORM_BUILDER_ELEMENTS_WITH_HINTS,
  FORM_BUILDER_LAYOUT_ELEMENT_TYPE,
  FORM_BUILDER_NODE_NAMES,
  GroupOneName,
  itemClassToType
} from "../../utils/form-builder.types";
import { createHint, createInputLabelGroup } from "../../utils/form-builder.form-elements.schema";
import { Config } from "../../../../utils/config";
import {
  _generateTranslation,
  FormBuilderTranslatorService
} from "../../services/translator/form-builder.translator.service";
import { getSlugFromId } from "../../helpers/helpers";
import Logger from "../../../../services/logger/logger";
import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../utils/styles/elements-classes/form-builder.styles.elements-classes";
export const initialState = {
  language: Config.language.defaultLanguage,
  languages: [],
  isFetching: false,
  data: {
    groups: [],
    layout: [],
    locales: {},
    styles: {},
    mapping: {},
    draft: {
      groups: [],
      layout: [],
      locales: {},
      styles: {}
    }
  },
  included: [],
  selectedElement: null,
  selectedElementIndex: null,
  type: "",
  id: "",
  uploadedImages: []
};
export const formBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_LANGUAGE:
      return __spreadProps(__spreadValues({}, state), {
        language: action.payload
      });
    case ACTIONS.UPLOAD_IMAGE:
      return __spreadProps(__spreadValues({}, state), {
        uploadedImages: [action.payload]
      });
    case ACTIONS.ADD_LOCALES:
      return produce(state, (draft) => {
        const { lang, locales } = action.payload;
        draft.languages.push(lang);
        draft.data.draft.locales[lang] = locales;
      });
    case ACTIONS.ADD_DEFAULT_LOCALES:
      return produce(state, (draft) => {
        const locales = draft.data.draft.locales;
        const uniqueKeysSet = /* @__PURE__ */ new Set();
        for (const locale in locales) {
          for (const key in locales[locale]) {
            uniqueKeysSet.add(key);
          }
        }
        for (const locale in locales) {
          for (const key of uniqueKeysSet) {
            if (!locales[locale].hasOwnProperty(key)) {
              draft.data.draft.locales[locale][key] = "";
            }
          }
        }
      });
    case ACTIONS.REMOVE_LOCALES:
      return produce(state, (draft) => {
        const { lang } = action.payload;
        draft.languages = draft.languages.filter((language) => language !== lang);
        delete draft.data.draft.locales[lang];
      });
    case ACTIONS.ADD_MISSING_HINTS:
      return produce(state, (draft) => {
        const allElements = draft.data.draft.groups[GroupOneName].elements;
        const locales = draft.data.draft.locales;
        const styles = draft.data.draft.styles;
        const addAllMissingHints = (elements, parentElement) => {
          var _a;
          for (const element of elements) {
            if (!elements.find((el) => {
              var _a2;
              return (_a2 = el.id) == null ? void 0 : _a2.includes("hint");
            }) && (element == null ? void 0 : element.id)) {
              const splitSlug = (_a = element.id) == null ? void 0 : _a.split("_");
              if (typeof splitSlug[splitSlug.length - 1] === "number" && typeof splitSlug[splitSlug.length - 2] === "number") {
                splitSlug.pop();
              }
              splitSlug.splice(splitSlug.length - 1, 0, "hint");
              const slug = splitSlug.join("_");
              element["aria-describedby"] = slug;
              const hintTranslationKey = _generateTranslation(parentElement, element);
              for (const locale in locales) {
                locales[locale][hintTranslationKey] = "";
                FormBuilderTranslatorService.updateSingleTranslation(locale, hintTranslationKey, "");
              }
              const hint = createHint(slug, hintTranslationKey);
              elements.push(hint);
            }
            if (element.elements) {
              addAllMissingHints(element.elements, parentElement);
            }
          }
        };
        for (const element of allElements) {
          if (element.elements && FORM_BUILDER_ELEMENTS_WITH_HINTS.find((el) => itemClassToType(element.class) === el)) {
            addAllMissingHints(element.elements, element);
          }
        }
        if (!styles[FORM_BUILDER_STYLE_ELEMENT_CLASSES.HINT]) {
          styles[FORM_BUILDER_STYLE_ELEMENT_CLASSES.HINT] = defaultFormStyles[FORM_BUILDER_STYLE_ELEMENT_CLASSES.HINT];
        }
      });
    case ACTIONS.ADD_FILE_UPLOAD_TRANSLATIONS:
      return produce(state, (draft) => {
        const allElements = draft.data.draft.groups[GroupOneName].elements;
        const locales = draft.data.draft.locales;
        const FILE_UPLOAD_LOCALES = {
          uploading: null,
          remove: null,
          failed: null,
          invalid: null,
          tooShortVideo: null,
          tooLongVideo: null
        };
        const addFileUploadTranslations = (elements, parent) => {
          for (const element of elements) {
            if (element.nodeName === FORM_BUILDER_NODE_NAMES.INPUT && element.type === "file") {
              let dataLocale = element["data-locale"] ? JSON.parse(element["data-locale"]) : {};
              for (const key in FILE_UPLOAD_LOCALES) {
                if (!dataLocale.hasOwnProperty(key)) {
                  const translationKey = _generateTranslation(parent, element);
                  dataLocale[key] = translationKey;
                  for (const locale in locales) {
                    if (!locales[locale][translationKey]) {
                      locales[locale][translationKey] = "";
                      FormBuilderTranslatorService.updateSingleTranslation(locale, translationKey, "");
                    }
                  }
                }
              }
              element["data-locale"] = JSON.stringify(dataLocale);
            }
            if (element.elements) {
              addFileUploadTranslations(element.elements, parent);
            }
          }
        };
        for (const element of allElements) {
          addFileUploadTranslations(element.elements || [], element);
        }
      });
    case ACTIONS.UPDATE_WORKFLOWS_PROPERTIES:
      return produce(state, (draft) => {
        var _a, _b, _c, _d;
        const { mapping, accountType, accountId, emailType, emailTemplateId } = action.payload;
        if (accountType) {
          draft.data.workflows[accountType] = {
            account_id: accountId || ((_b = (_a = draft.data.workflows) == null ? void 0 : _a[accountType]) == null ? void 0 : _b.account_id),
            mapping
          };
        }
        if (emailType) {
          draft.data.workflows[emailType] = {
            email_template_id: emailTemplateId || ((_d = (_c = draft.data.workflows) == null ? void 0 : _c[emailType]) == null ? void 0 : _d.email_template_id),
            mapping
          };
        }
      });
    case ACTIONS.GET_FORM.REQUEST:
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    case ACTIONS.GET_FORM.FAILURE:
      return produce(state, (draft) => {
        draft.isFetching = false;
        draft.error = action.payload;
      });
    case ACTIONS.PATCH_FORM.FAILURE:
      return produce(state, (draft) => {
        draft.error = action.payload;
      });
    case ACTIONS.PATCH_FORM.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b, _c, _d;
        draft.data.mapping = (_b = (_a = action == null ? void 0 : action.payload) == null ? void 0 : _a.data.attributes) == null ? void 0 : _b.mapping;
        draft.included = ((_c = action == null ? void 0 : action.payload) == null ? void 0 : _c.included) ? [...(_d = action == null ? void 0 : action.payload) == null ? void 0 : _d.included] : [];
        draft.isFetching = false;
      });
    case ACTIONS.GET_FORM.SUCCESS:
      return produce(state, (draft) => {
        var _a, _b;
        draft.data = action.payload.data.attributes;
        draft.id = action.payload.data.id;
        draft.type = action.payload.data.type;
        draft.languages = Object.keys(action.payload.data.attributes.draft.locales);
        draft.included = ((_a = action == null ? void 0 : action.payload) == null ? void 0 : _a.included) ? [...(_b = action == null ? void 0 : action.payload) == null ? void 0 : _b.included] : [];
        draft.isFetching = false;
      });
    case ACTIONS.UPDATE_TRANSLATION:
      return produce(state, (draft) => {
        draft.data.draft.locales[action.payload.lang][action.payload.key] = action.payload.value;
      });
    case ACTIONS.UPDATE_TRANSLATIONS:
      return produce(state, (draft) => {
        const { translations } = action.payload;
        for (const lang in draft.data.draft.locales) {
          draft.data.draft.locales[lang] = __spreadValues(__spreadValues({}, draft.data.draft.locales[lang] || {}), translations);
        }
      });
    case ACTIONS.SELECT_ELEMENT:
      return __spreadProps(__spreadValues({}, state), {
        selectedElement: action.payload.e,
        selectedElementIndex: action.payload.index
      });
    case ACTIONS.CANCEL_ELEMENT_SELECTION:
      return __spreadProps(__spreadValues({}, state), {
        selectedElement: null,
        selectedElementIndex: null
      });
    case ACTIONS.DELETE_FORM_ELEMENT:
      return produce(state, (draft) => {
        const allElements = draft.data.draft.groups[GroupOneName].elements;
        const element = allElements[action.payload];
        const locales = draft.data.draft.locales;
        const styles = draft.data.draft.styles;
        const removeRelatedStyles = (elements) => {
          for (const element2 of elements) {
            for (const style in styles) {
              if (style.includes(element2 == null ? void 0 : element2.id)) {
                delete styles[style];
              }
            }
            if (element2 == null ? void 0 : element2.elements) {
              removeRelatedStyles(element2 == null ? void 0 : element2.elements);
            }
          }
        };
        if (element == null ? void 0 : element.elements) {
          removeRelatedStyles(element.elements);
        }
        const uniqueKeys = /* @__PURE__ */ new Set();
        const translatableKeys = ["nodeValue", "placeholder", "content", "src", "alt", "href"];
        const findAllTranslations = (elements) => {
          if (elements) {
            elements.forEach((e) => {
              for (const key in e) {
                if (translatableKeys.some((el) => el === key)) {
                  uniqueKeys.add(e[key]);
                }
              }
              if (e == null ? void 0 : e.elements) {
                findAllTranslations(e.elements);
              }
            });
          }
        };
        findAllTranslations(element.elements);
        for (const key of uniqueKeys) {
          for (const locale in locales) {
            if (locales[locale].hasOwnProperty(key)) {
              delete draft.data.draft.locales[locale][key];
            }
          }
        }
        draft.data.draft.groups[GroupOneName].elements.splice(action.payload, 1);
      });
    case ACTIONS.REORDER_ELEMENT:
      return produce(state, (draft) => {
        const { from, to } = action.payload;
        const elementFrom = draft.data.draft.groups[GroupOneName].elements[from];
        const elementTo = draft.data.draft.groups[GroupOneName].elements[to];
        draft.data.draft.groups[GroupOneName].elements.splice(to, 1, elementFrom);
        draft.data.draft.groups[GroupOneName].elements.splice(from, 1, elementTo);
      });
    case ACTIONS.ADD_FORM_ELEMENT:
      return produce(state, (draft) => {
        var _a;
        const elements = (_a = draft.data.draft.groups[GroupOneName]) == null ? void 0 : _a.elements;
        if (elements) {
          elements.push(action.payload.element);
        } else {
          draft.data.draft.groups = [{ elements: [action.payload.element] }];
        }
      });
    case ACTIONS.CANCEL_DRAFT_CHANGES:
      return produce(state, (draft) => {
        draft.data.draft.groups = draft.data.groups;
        draft.data.draft.locales = draft.data.locales;
        draft.data.draft.styles = draft.data.styles;
      });
    case ACTIONS.REMOVE_CLASS:
      return produce(state, (draft) => {
        const element = draft.data.draft.groups[action.payload.group].elements[action.payload.element];
        element.class = element.class.replace(action.payload.className, "").trim();
      });
    case ACTIONS.ADD_CLASS:
      return produce(state, (draft) => {
        const element = draft.data.draft.groups[action.payload.group].elements[action.payload.element];
        element.class = `${element.class} ${action.payload.className}`;
      });
    case ACTIONS.CHANGE_POST_SUBMIT_ACTION:
      return produce(state, (draft) => {
        draft.data.draft.layout.postSubmit = action.payload;
      });
    case ACTIONS.UPDATE_NODE_NAME_PROPERTY:
      return produce(state, (draft) => {
        const updateElementProperty = (elements, nodeName2, property2, value2) => {
          if (!elements || !Array.isArray(elements)) {
            return;
          }
          for (const elem of elements) {
            if (elem.nodeName === nodeName2) {
              if (Object.prototype.hasOwnProperty.call(elem, property2)) {
                elem[property2] = value2;
              } else {
                Logger.warning(`Property "${property2}" does not exist on element with nodeName: "${nodeName2}"`);
              }
            }
            if (elem.elements) {
              updateElementProperty(elem.elements, nodeName2, property2, value2);
            }
          }
        };
        const { group, element, nodeName, property, value } = action.payload;
        const targetGroup = draft.data.draft.groups[group];
        if (targetGroup) {
          updateElementProperty(targetGroup.elements[element].elements, nodeName, property, value);
        } else {
          Logger.warning(`No group found with index: "${group}"`);
        }
      });
    case ACTIONS.UPDATE_ELEMENT_PROPERTY:
      return produce(state, (draft) => {
        const element = draft.data.draft.groups[action.payload.group].elements[action.payload.element].elements[action.payload.selectedSubElementIndex];
        if (element) {
          element[action.payload.property] = action.payload.value;
        }
      });
    case ACTIONS.UPDATE_ELEMENT_PROPERTY_BY_UNIQUE_PROPERTY:
      return produce(state, (draft) => {
        const { propertyNameId } = action.payload;
        const updateElementProperty = (elements, id2, property2, value2) => {
          if (!elements || !Array.isArray(elements)) {
            return;
          }
          for (const elem of elements) {
            if (elem[propertyNameId] === id2) {
              if (Object.prototype.hasOwnProperty.call(elem, property2)) {
                elem[property2] = value2;
              } else {
                Logger.warning(`Property "${property2}" does not exist on element with id: "${id2}"`);
              }
            }
            if (elem.elements) {
              updateElementProperty(elem.elements, id2, property2, value2);
            }
          }
        };
        const { id, property, value } = action.payload;
        const targetGroup = draft.data.draft.groups[GroupOneName];
        if (targetGroup) {
          updateElementProperty(targetGroup.elements, id, property, value);
        } else {
          Logger.warning(`No group found with index: "${GroupOneName}"`);
        }
      });
    case ACTIONS.UPDATE_ELEMENT_PROPERTY_BY_ID:
      return produce(state, (draft) => {
        const updateElementProperty = (elements, id2, property2, value2, addDefault2) => {
          if (!elements || !Array.isArray(elements)) {
            return;
          }
          for (const elem of elements) {
            if (elem.id === id2) {
              if (Object.prototype.hasOwnProperty.call(elem, property2) || addDefault2) {
                elem[property2] = value2;
              } else {
                Logger.warning(`Property "${property2}" does not exist on element with id: "${id2}"`);
              }
            }
            if (elem.elements) {
              updateElementProperty(elem.elements, id2, property2, value2, addDefault2);
            }
          }
        };
        const { id, property, value, addDefault } = action.payload;
        const targetGroup = draft.data.draft.groups[GroupOneName];
        if (targetGroup) {
          updateElementProperty(targetGroup.elements, id, property, value, addDefault);
        } else {
          Logger.warning(`No group found with index: "${GroupOneName}"`);
        }
      });
    case ACTIONS.CHANGE_SINGLE_MATRIX_VALUES:
      return produce(state, (draft) => {
        const { value, elementIndex, selectedElementIndex, changeQuestion } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const tbody = group.elements[selectedElementIndex].elements[1].elements[1];
        if (changeQuestion) {
          const tr = tbody.elements[elementIndex];
          for (const elem of tr.elements) {
            if (elem == null ? void 0 : elem.elements) {
              const input = elem == null ? void 0 : elem.elements[0];
              const question = input.name.match(/\[(.*?)\]/)[1];
              const splitName = input.name.split(`[${question}]`);
              splitName[1] = `[${value}]`;
              input.name = splitName.join("");
            }
          }
        } else {
          for (const tr of tbody.elements) {
            const input = tr.elements[elementIndex + 1].elements[0];
            input.value = value;
          }
        }
      });
    case ACTIONS.CHANGE_SINGLE_MATRIX_ELEMENT_ID:
      return produce(state, (draft) => {
        const { value, selectedElementIndex } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const tbody = group.elements[selectedElementIndex].elements[1].elements[1];
        const updateAllNames = (elements) => {
          for (const elem of elements) {
            if (elem == null ? void 0 : elem.name) {
              const questionValue = elem.name.match(/\[(.*?)\]/)[1];
              const elementName = `${value}[${questionValue}]`;
              if ((elem == null ? void 0 : elem.type) === "checkbox") {
                elem.name = `${elementName}[]`;
              } else {
                elem.name = elementName;
              }
            }
            if (elem == null ? void 0 : elem.elements) {
              updateAllNames(elem.elements);
            }
          }
        };
        for (const tr of tbody.elements) {
          updateAllNames(tr.elements);
        }
      });
    case ACTIONS.ADD_SINGLE_MATRIX_OPTIONS:
      return produce(state, (draft) => {
        const { selectedElementIndex, addQuestion, newOption, newTheadElement } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const fieldset = group.elements[selectedElementIndex];
        const thead = fieldset.elements[1].elements[0];
        const tbody = fieldset.elements[1].elements[1];
        if (addQuestion) {
          tbody.elements.push(newOption);
        } else {
          thead.elements[0].elements.push(newTheadElement);
          tbody.elements = [...newOption];
        }
      });
    case ACTIONS.DELETE_SINGLE_MATRIX_OPTIONS:
      return produce(state, (draft) => {
        const { selectedElementIndex, deleteQuestion, elementIndex } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const fieldset = group.elements[selectedElementIndex];
        const tbody = fieldset.elements[1].elements[1];
        const thead = fieldset.elements[1].elements[0];
        if (deleteQuestion) {
          tbody.elements.splice(elementIndex, 1);
        } else {
          for (const tr of tbody.elements) {
            tr.elements.splice(elementIndex, 1);
          }
          thead.elements[0].elements.splice(elementIndex, 1);
        }
      });
    case ACTIONS.UPDATE_STYLES:
      return produce(state, (draft) => {
        draft.data.draft.styles = __spreadValues({}, action.payload.style);
      });
    case ACTIONS.UPDATE_STYLE:
      return produce(state, (draft) => {
        const style = draft.data.draft.styles[action.payload.id];
        if (style) {
          draft.data.draft.styles[action.payload.id] = __spreadValues(__spreadValues({}, style), action.payload.style);
        } else {
          draft.data.draft.styles[action.payload.id] = action.payload.style;
        }
      });
    case ACTIONS.ADD_SUCCESS_MESSAGE_LINK:
      return produce(state, (draft) => {
        const successMessageElements = draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements;
        draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements = [
          ...successMessageElements,
          action.payload
        ];
      });
    case ACTIONS.PATCH_SUCCESS_MESSAGE_LINK:
      return produce(state, (draft) => {
        const successMessageElement = draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements.find((el) => el.key === action.payload.key);
        const getCurrentPatchProperty = () => {
          return action.payload.nodeName ? { name: action.payload.data } : { nodeValue: action.payload.data };
        };
        const itemIndex = draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements.indexOf(
          successMessageElement
        );
        draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements[itemIndex] = __spreadValues(__spreadValues({}, successMessageElement), getCurrentPatchProperty());
      });
    case ACTIONS.CHANGE_RATING_OPTIONS_COUNT:
      return produce(state, (draft) => {
        const { group, element: elementIndex, value } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[elementIndex];
        const numberOfStars = element.elements.filter((el) => el.nodeName === "input").length;
        if (numberOfStars > value) {
          element.elements.splice(value * 2 + 1);
        } else {
          const inputElement = element.elements.find((el) => el.nodeName.includes("input"));
          const slug = getSlugFromId(inputElement == null ? void 0 : inputElement.id);
          const newStars = Array.from({ length: value - numberOfStars }, (_, index) => {
            const startValue = index + numberOfStars + 1;
            return createInputLabelGroup({
              slug,
              inputName: inputElement.name,
              index: startValue,
              labelValue: startValue,
              inputValue: startValue,
              inputType: "radio",
              combineSlugAndIndex: true
            });
          }).flat();
          element.elements = [...element.elements, ...newStars];
        }
      });
    case ACTIONS.CHANGE_NUMBER_SCALE_OPTIONS_COUNT:
      return produce(state, (draft) => {
        const { group, elementIndex, newElements } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[elementIndex];
        element.elements = [...newElements];
      });
    case ACTIONS.ADD_NESTED_FORM_ELEMENT_NEW:
      return produce(state, (draft) => {
        const { parentId, element, replaceOldWithNew } = action.payload;
        const findParentElement = (elements, id) => {
          for (const element2 of elements) {
            if (element2.id === id) {
              return element2;
            } else if (element2.elements) {
              const foundElement = findParentElement(element2.elements, id);
              if (foundElement) {
                return foundElement;
              }
            }
          }
        };
        const parentElement = findParentElement(draft.data.draft.groups[GroupOneName].elements, parentId);
        if (parentElement) {
          if (replaceOldWithNew) {
            parentElement.elements = Array.isArray(element) ? [...element] : [element];
          } else {
            parentElement.elements = [
              ...parentElement.elements || [],
              ...Array.isArray(element) ? element : [element]
            ];
          }
        }
      });
    case ACTIONS.ADD_NESTED_FORM_ELEMENT:
      return produce(state, (draft) => {
        const { group, elementIndex, element: newElement } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[elementIndex];
        element.elements = [...element.elements, ...newElement.elements];
      });
    case ACTIONS.DELETE_NESTED_FORM_ELEMENT:
      return produce(state, (draft) => {
        const {
          group,
          selectedElementIndex,
          property,
          parentId,
          deleteElementIndex,
          deleteElementId,
          deleteAll
        } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[selectedElementIndex];
        const deleteElement = (elements) => {
          var _a;
          let parentElement;
          if (parentId) {
            parentElement = elements.find((el) => (el == null ? void 0 : el.id) === parentId);
          } else {
            parentElement = ((_a = element == null ? void 0 : element.elements) == null ? void 0 : _a.find((el) => (el == null ? void 0 : el.id) === deleteElementId)) ? element : elements.find((el) => {
              var _a2;
              return (_a2 = el == null ? void 0 : el.elements) == null ? void 0 : _a2.find((el2) => (el2 == null ? void 0 : el2.id) === deleteElementId);
            });
          }
          if (parentElement) {
            if (deleteAll) {
              parentElement.elements = [];
              return;
            }
            if (property) {
              parentElement.elements = parentElement.elements.filter((el) => {
                if (Array.isArray(deleteElementId)) {
                  return !deleteElementId.includes(el == null ? void 0 : el[property]);
                } else {
                  return !((el == null ? void 0 : el[property]) === deleteElementId);
                }
              });
              return;
            }
            if (deleteElementIndex >= 0) {
              parentElement.elements.splice(deleteElementIndex, 1);
              return;
            }
            if (deleteElementId) {
              parentElement.elements = parentElement.elements.filter((el) => {
                return !((el == null ? void 0 : el.for) && (el == null ? void 0 : el.for) === deleteElementId || (el == null ? void 0 : el.id) && (el == null ? void 0 : el.id) === deleteElementId);
              });
            }
          } else {
            for (const element2 of elements) {
              if (element2 == null ? void 0 : element2.elements) {
                deleteElement(element2.elements);
              }
            }
          }
        };
        deleteElement(element.elements);
      });
    case ACTIONS.PUBLISH_FORM.SUCCESS:
      return produce(state, (draft) => {
        draft.data.styles = draft.data.draft.styles;
        draft.data.locales = draft.data.draft.locales;
        draft.data.groups = draft.data.draft.groups;
        draft.data.layout = draft.data.draft.layout;
      });
    case ACTIONS.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
