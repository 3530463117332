import React, { useRef } from "react";
import "./dropdown-link-list.sass";
import { Link } from "react-router-dom";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import TintButtonIcon, { TintButtonIconTypes } from "../../../button-icon/button-icon";
const DropdownLinkList = ({ onChange, parentRef, currentItems, children, topComponent }) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const renderLi = (item) => {
    return /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `dropdown-list__item ${item.isActive ? "active" : ""}`,
        onClick: () => {
          onChange && onChange(item);
        }
      },
      item.icon && typeof item.icon === "string" ? /* @__PURE__ */ React.createElement("span", { className: `icon ${item.icon}` }) : item.icon && /* @__PURE__ */ React.createElement(TintButtonIcon, { type: TintButtonIconTypes.NONE, icon: item.icon }),
      /* @__PURE__ */ React.createElement("span", null, item.name),
      /* @__PURE__ */ React.createElement("span", { className: "dropdown-list__description" }, item.description)
    );
  };
  const renderLink = (item, i) => {
    if (item.absoluteUrl) {
      return /* @__PURE__ */ React.createElement("a", { href: item.href, key: i }, " ", renderLi(item), " ");
    }
    return item.href ? /* @__PURE__ */ React.createElement(Link, { to: item.href, key: i }, " ", renderLi(item), " ") : /* @__PURE__ */ React.createElement("a", { key: i }, renderLi(item));
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: elementRef,
      "data-testid": "dropdown-click-list",
      className: `dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--click dropdown-list--wide dropdown-list--with-description`
    },
    topComponent && topComponent(),
    currentItems && /* @__PURE__ */ React.createElement("ul", null, currentItems.map(renderLink)),
    children
  );
};
export default DropdownLinkList;
DropdownLinkList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  children: PropTypes.object
};
