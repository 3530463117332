var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/oauth-applications/oauth-applications-table-rows.actions";
export const initialState = {
  data: []
};
export const oAuthApplicationsTableRows = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ROWS_TO_TABLE:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.map((e) => {
          return { id: e.id };
        })
      });
    case ACTIONS.ADD_ROW_TO_TABLE:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.filter((item) => {
          return item.id !== action.payload.id;
        }).concat(action.payload)
      });
    case ACTIONS.REMOVE_ROW_FROM_TABLE:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.filter((e) => e.id !== action.payload)
      });
    case ACTIONS.CLEAR_TABLE:
      return initialState;
    case ACTIONS.SHOW_SECRET_ID:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload ? __spreadProps(__spreadValues({}, e), { isShownSecretId: true }) : e)
      });
    case ACTIONS.HIDE_SECRET_ID:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload ? __spreadProps(__spreadValues({}, e), { isShownSecretId: false }) : e)
      });
    case ACTIONS.EXPAND_TABLE_ROW:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload ? __spreadProps(__spreadValues({}, e), { isExpanded: true }) : e)
      });
    case ACTIONS.COLLAPSE_TABLE_ROW:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload ? __spreadProps(__spreadValues({}, e), { isExpanded: false }) : e)
      });
    default:
      return state;
  }
};
