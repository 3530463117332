import React from "react";
import "./single-answer.sass";
import * as PropTypes from "prop-types";
import CheckboxButton, {
  CheckboxButtonType
} from "../../../../../../../../components/interface/inputs/checkbox-button/checkbox-button";
import ColorParser from "../../../../../../../../services/color-parser/color-parser";
import { pollImageType } from "../../../../../../../../model/poll-image-type.model";
const SingleAnswer = ({ answer, styles, imageType, onUpdateAnswerStatus, getTranslation }) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "survey-single-answer-container",
      style: {
        background: ColorParser.defaultColor(styles.answerBackgroundColor)
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "survey-single-answer-container__top" }, /* @__PURE__ */ React.createElement(
      CheckboxButton,
      {
        color: ColorParser.defaultColor(styles.answerTextColor),
        borderColor: ColorParser.defaultColor(styles.answerTextColor),
        markColor: ColorParser.defaultColor(styles.answerBackgroundColor),
        id: answer.id,
        onStatusChange: onUpdateAnswerStatus,
        type: CheckboxButtonType.ROUND
      }
    ), /* @__PURE__ */ React.createElement("h5", { style: { color: ColorParser.defaultColor(styles.answerTextColor) } }, getTranslation(answer.title) || "Title")),
    getTranslation(answer.description) ? /* @__PURE__ */ React.createElement("p", { style: { color: ColorParser.defaultColor(styles.answerTextColor) } }, getTranslation(answer.description)) : null,
    getTranslation(answer.imageUrl) ? /* @__PURE__ */ React.createElement("div", { className: "survey-single-answer-container__image-wrapper" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `survey-single-answer-container__thumb ${imageType !== void 0 && imageType === pollImageType.FIT ? "survey-single-answer-container__thumb--fit" : ""}`,
        style: {
          backgroundImage: `url("${getTranslation(answer.imageUrl)}")`
        }
      }
    ), " ") : null
  );
};
SingleAnswer.propTypes = {
  answer: PropTypes.object,
  styles: PropTypes.object,
  imageType: PropTypes.string,
  onUpdateAnswerStatus: PropTypes.func
};
export default SingleAnswer;
