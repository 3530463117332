import React, { Fragment } from "react";
import { AddContentOption } from "../../../../../../../../components/interface/add-content-option/add-content-option";
import FeatureGuard from "../../../../../../../../guards/feature-guard";
import ButtonContentIcon from "../../../../../../../../components/interface/button-content-icon/button-content-icon";
import { GuardNames } from "../../../../../../../../guards/guards-list";
import useFeatureAccessibility from "../../../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
const exceptionFeatures = [GuardNames.SOCIAL_FEEDS_THREADS];
const SocialList = ({ list, onChange, selectedOption, socialFeedType }) => {
  const isThreadsAble = useFeatureAccessibility(GuardNames.SOCIAL_FEEDS_THREADS);
  return list.filter((el) => isThreadsAble || !exceptionFeatures.includes(el.featureName)).map((socialType) => {
    return /* @__PURE__ */ React.createElement(Fragment, { key: socialType.id }, /* @__PURE__ */ React.createElement(
      FeatureGuard,
      {
        featureName: socialType.featureName,
        hasAccess: !socialType.featureName ? true : socialType.featureName,
        key: socialType.id
      },
      /* @__PURE__ */ React.createElement(
        AddContentOption,
        {
          content: [{ title: socialType.title, description: socialType.description }],
          id: socialType.id,
          onChange: () => onChange({ id: socialType.id, title: socialType.title }),
          isSelected: (selectedOption == null ? void 0 : selectedOption.id) === socialType.id,
          icon: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
            ButtonContentIcon,
            {
              source: socialFeedType,
              circleSize: 50,
              fontSize: 28,
              styles: { border: "1px solid #e9edf3" }
            }
          ))
        }
      )
    ));
  });
};
export default React.memo(SocialList);
