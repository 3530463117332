import React, { useRef } from "react";
import "./language-list.sass";
import useDropdownListPosition from "../../../../hooks/use-dropdown-list-position/use-dropdown-list-position";
import PropTypes from "prop-types";
import { DropdownHeader } from "../../button/header/dropdown-header";
import { Button } from "../../../button/button";
import FeatureGuard from "../../../../../guards/feature-guard";
const LanguageList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems,
  onAction,
  currentLanguage,
  isAddLanguageButton = true,
  manageLanguageFeatureGuard
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const DropdownTextElement = (title) => {
    return /* @__PURE__ */ React.createElement(DropdownHeader, { iconLeft, iconRight, iconCenter, title });
  };
  const renderManageLanguageOption = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "dropdown-list__item" }, /* @__PURE__ */ React.createElement(Button, { onClick: onAction, type: "info", text: "Manage Languages" }));
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `dropdown-list dropdown-list--language dropdown-list--${positionHorizontal} dropdown-list--${positionVertical}`,
      ref: elementRef,
      "data-testid": "dropdown-simple-list"
    },
    /* @__PURE__ */ React.createElement("ul", null, currentItems.map(
      (item, i) => item.value === currentLanguage ? /* @__PURE__ */ React.createElement(
        "li",
        {
          className: `dropdown-list__item ${item.value === currentLanguage ? "active" : ""}`,
          key: i,
          onClick: () => {
            onChange(item);
            onChangeTextComponent(DropdownTextElement(item.name));
          }
        },
        item.value === currentLanguage ? /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-check" }) : null,
        /* @__PURE__ */ React.createElement("span", null, item.name),
        item.isDefault ? /* @__PURE__ */ React.createElement("span", { className: "dropdown-list-default" }, "Default") : null
      ) : null
    ), currentItems.map(
      (item, i) => item.value !== currentLanguage ? /* @__PURE__ */ React.createElement(
        "li",
        {
          className: `dropdown-list__item ${item.isActive ? "active" : ""}`,
          key: i,
          onClick: () => {
            onChange(item);
            onChangeTextComponent(DropdownTextElement(item.name));
          }
        },
        /* @__PURE__ */ React.createElement("span", null, item.name),
        item.isDefault ? /* @__PURE__ */ React.createElement("span", { className: "dropdown-list-default" }, "Default") : null
      ) : null
    )),
    /* @__PURE__ */ React.createElement("div", { className: "dropdown-list-fixed-section" }, isAddLanguageButton && /* @__PURE__ */ React.createElement("div", { className: "dropdown-list__item dropdown-list__item--special", onClick: onAction }, /* @__PURE__ */ React.createElement("span", null, "+ Add Another")), manageLanguageFeatureGuard ? /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: manageLanguageFeatureGuard }, renderManageLanguageOption()) : renderManageLanguageOption())
  );
};
export default LanguageList;
LanguageList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string
};
