var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { connect } from "react-redux";
import { Config } from "../../../utils/config";
import { getCustomFontsList } from "../../../feature/experience/reducers/experience/experience";
import { pageSectionType } from "../../../model/page-section-type.model";
export const AddFontLinkHoc = (PassedComponent) => {
  class AddLinkScript extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "createElement", (element) => {
        let currentFontFamilies = [];
        const elementList = element.querySelectorAll("span");
        elementList.forEach((span) => {
          if (span.style.fontFamily !== "") {
            currentFontFamilies.push(span.style.fontFamily);
          }
        });
        return currentFontFamilies;
      });
      __publicField(this, "addCustomFontScript", (fontList, teamId) => {
        const customFonts = fontList.filter((font) => !this.isGoogleFont(font.name)).map((font) => ({
          value: font.value,
          name: font.name,
          id: font.url ? this.getCSSIdFromUrl(font.url) : font.id ? font.id : this.getCustomFontId(font.name)
        }));
        let customFontsIds = customFonts.map((font) => font.id).filter((font) => font !== void 0).join();
        this.addCustomFont(this.getTeamId(teamId), customFontsIds);
      });
      __publicField(this, "addGoogleFontScript", (fontList) => fontList.forEach((font) => {
        this.addGoogleFont(font);
      }));
      __publicField(this, "addFontsScript", (translations, teamId) => {
        const fontList = this.getFontFamilies(translations);
        this.addCustomFontScript(fontList.filter((font) => !this.isGoogleFont(font.name)), teamId);
        this.addGoogleFontScript(fontList.filter((font) => this.isGoogleFont(font.name)));
      });
      __publicField(this, "loadFont", (font) => {
        if (this.isGoogleFont(font.name)) {
          this.addGoogleFont(font.name);
        } else {
          this.addCustomFont(this.getTeamId(), font.id);
        }
      });
      this.state = {
        isLoading: false,
        loadedFontList: [],
        teamId: void 0
      };
    }
    isGoogleFont(fontName) {
      return Config.fontFamilyList.includes(fontName);
    }
    addGoogleFont(fontFamily) {
      if (!fontFamily) return;
      const fontFamilyValue = typeof fontFamily === "object" ? fontFamily.value : fontFamily;
      if (this.state.loadedFontList.includes(fontFamilyValue)) return;
      const url = Config.getGoogleFontSource(fontFamilyValue);
      this.setState((previousState) => ({
        loadedFontList: [...previousState.loadedFontList, fontFamilyValue]
      }));
      this.embedScript(url);
      this.setState({ isLoading: true });
    }
    addCustomFont(teamId, fontFamilyIds) {
      if (this.state.loadedFontList.includes(fontFamilyIds)) return;
      if (!fontFamilyIds) return;
      const fontUrl = this.getFontUrl(teamId, fontFamilyIds);
      this.setState((previousState) => ({
        loadedFontList: [...previousState.loadedFontList, fontFamilyIds]
      }));
      this.embedScript(fontUrl);
      this.setState({ isLoading: true });
    }
    getFontUrl(teamId, fontFamilyIds) {
      if (window.location.host.includes("localhost")) {
        return `https://api.tintup.com/v2/teams/${teamId}/fonts/${fontFamilyIds}.css`;
      } else {
        return `${Config.getApiUrl()}/v2/teams/${teamId}/fonts/${fontFamilyIds}.css`;
      }
    }
    embedScript(url) {
      const fontFamilyScript = document.createElement("link");
      fontFamilyScript.href = url;
      fontFamilyScript.rel = "stylesheet";
      fontFamilyScript.onload = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 700);
      };
      fontFamilyScript.onerror = () => {
        this.setState({ isLoading: false });
      };
      document.head.appendChild(fontFamilyScript);
    }
    getDefaultFontFamilyList() {
      let currentFontList = [];
      const property = this.props.experienceDataDraft.attributes.data.draft ? "draft" : "published";
      this.props.experienceDataDraft.attributes.data[property].forEach((item) => {
        if (item.data.content.sections) {
          item.data.content.sections.filter((column) => {
            return column.data && column.data.content.customizableHeaders;
          }).forEach((columnHeader) => {
            return columnHeader.data.content.customizableHeaders.forEach((item2) => {
              currentFontList = [...currentFontList, item2.fontFamily];
            });
          });
        } else if (item.data.content.customizableHeaders) {
          return item.data.content.customizableHeaders.forEach((item2) => {
            currentFontList = [...currentFontList, item2.fontFamily];
          });
        } else if (item.sectionType === pageSectionType.SURVEY_COUNTER) {
          currentFontList = [...currentFontList, item.data.styles.fontFamily];
        }
      });
      return currentFontList;
    }
    matchFontFamily(string) {
      const matchFonts = string.match(Config.regExp.fontFamilyStyle);
      return matchFonts && matchFonts.map((font) => font.replace("font-family: ", "")) || [];
    }
    getFontFamilies(data) {
      let currentFontFamilies = [];
      Object.keys(data).forEach((key) => {
        if (key.includes("TEXTAREA") || key.includes("HEADER")) {
          const fontFamilyData = data[key].includes("&quot;") ? this.matchFontFamily(data[key].replace(/&quot;/g, "")) : this.matchFontFamily(data[key]);
          currentFontFamilies = [...currentFontFamilies, ...fontFamilyData];
        }
        return [...new Set(currentFontFamilies)];
      });
      const innerHtmlFontList = currentFontFamilies.map((item) => ({
        name: item ? item.replace(/["']/g, "") : item,
        value: item ? item.replace(/["']/g, "") : item
      }));
      return this.getUniqueFonts([...this.getDefaultFontFamilyList(), ...innerHtmlFontList]);
    }
    getUniqueFonts(fontList) {
      return fontList.filter((font, index, arr) => {
        return arr.filter(() => font && font.name).map((font2) => font2.name).indexOf(font.name) === index;
      });
    }
    getCSSIdFromUrl(url) {
      const removeSlash = url.replace(/\//g, " ");
      const getLastWord = removeSlash.split(" ").splice(-1);
      return getLastWord[0].replace(".css", "");
    }
    getCustomFontId(fontName) {
      return this.props.customFonts && this.props.customFonts.find((customFont) => customFont && customFont.name === fontName) && this.props.customFonts.find((customFont) => customFont.name === fontName).id;
    }
    getTeamId(teamId) {
      return teamId ? teamId : this.props.customDomain && this.props.customDomain.domain ? this.props.customDomain.domain.name : this.props.experienceDataDraft.relationships.user.data.id;
    }
    render() {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        PassedComponent,
        __spreadProps(__spreadValues({}, this.props), {
          isFontLoading: this.state.isLoading,
          loadFonts: this.addFontsScript,
          loadFont: this.loadFont
        })
      ));
    }
  }
  const mapStateToProps = (state) => ({
    experienceDataDraft: state.experience.dataDraft,
    customFonts: getCustomFontsList(state.experience.data),
    customDomain: state.customDomain
  });
  return connect(mapStateToProps)(AddLinkScript);
};
