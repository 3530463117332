var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/roles/roles.actions";
import { convertTypeName } from "../../components/roles/utils/filter-helper/filter-helper";
export const INCLUDE_TYPES = {
  tints: "tint",
  personalizations: "personalization",
  accounts: "account",
  experiences: "experience",
  collections: "collection",
  domains: "domain",
  fonts: "font",
  product_accounts: "product_account",
  product_feeds: "product_feed",
  webhooks: "webhook",
  oauth_applications: "oauth_application",
  forms: "form",
  mixes: "mix"
};
export const initialState = {
  data: [],
  included: [],
  links: void 0,
  isFetching: false,
  error: void 0,
  querySearchValue: void 0
};
export const roles = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_ROLES.REQUEST:
    case ACTIONS.GET_ROLE.REQUEST:
    case ACTIONS.DELETE_ROLE.REQUEST:
    case ACTIONS.POST_ROLE.REQUEST:
    case ACTIONS.PATCH_ROLE.REQUEST:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_ROLES.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: void 0
      });
    case ACTIONS.PATCH_ROLE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.map((e) => e.id === action.payload.data.id ? action.payload.data : e),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_ROLE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [
          action.payload.data,
          ...state.data.filter((item) => {
            return item.id !== action.payload.data.id;
          })
        ],
        included: state.included ? state.included.concat(
          action.payload.included ? action.payload.included.filter((e) => !state.included.some((r) => r.id === e.id)) : []
        ) : [],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.POST_ROLE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: [
          action.payload.data,
          ...state.data.filter((item) => {
            return item.id !== action.payload.data.id;
          })
        ],
        isFetching: false,
        error: void 0
      });
    case ACTIONS.DELETE_ROLE.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.filter((e) => e.id !== action.payload),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_ROLES.FAILURE:
    case ACTIONS.GET_ROLE.FAILURE:
    case ACTIONS.POST_ROLE.FAILURE:
    case ACTIONS.PATCH_ROLE.FAILURE:
    case ACTIONS.DELETE_ROLE.FAILURE:
      return __spreadValues(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    case ACTIONS.SET_ROLE_QUERY_SEARCH_VALUE:
      return __spreadProps(__spreadValues({}, state), {
        querySearchValue: action.payload
      });
    case ACTIONS.CLEAR_ROLE_QUERY_SEARCH_VALUE:
      return __spreadProps(__spreadValues({}, state), {
        querySearchValue: void 0
      });
    default:
      return state;
  }
};
export const getRoleAccessIds = (role) => {
  return (role == null ? void 0 : role.length) ? role.map((e) => e.id) : [];
};
export const filterAccessItems = (items) => {
  return (items == null ? void 0 : items.length) ? items.map(({ id, type: accessType, attributes: { name, username, type, slug, url } }) => ({
    id,
    name: `${name && name !== "" ? name : username || slug || url || ""} ${accessType !== INCLUDE_TYPES.personalizations && type ? `(${convertTypeName(type)})` : ""}`
  })) : [];
};
export const filterSelectedAccessItems = (items, selectedItems) => {
  return filterAccessItems(items).filter(({ id }) => selectedItems && selectedItems.includes(id));
};
export const selectIncludedData = (data, type) => data ? data.filter((e) => e.type === type) : [];
export const getSelectedAccesData = (role, type) => {
  if (!role) return;
  const data = selectIncludedData(role.included, INCLUDE_TYPES[type]);
  return __spreadProps(__spreadValues({}, role.data.relationships[type]), {
    data: data && data.length > 0 ? data.filter(
      (e) => role.data.relationships[type] && role.data.relationships[type].data ? role.data.relationships[type].data.some((r) => e.id === r.id) : []
    ) : []
  });
};
