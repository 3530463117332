var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { combineReducers } from "redux";
import account from "./account";
import session from "./session";
import appRoute from "./app-route";
import experience from "../feature/experience/reducers/experience/experience";
import experiences from "../feature/experience/reducers/experiences";
import experienceBuilder from "../feature/experience/reducers/experience-builder";
import formSectionFieldModal from "../feature/experience/reducers/form-section-field-modal/form-section-field-modal.reducer";
import posts from "../feature/experience/reducers/post/posts";
import { insightsReducers } from "../feature/insights/reducers/insights.reducer";
import submissions from "./../feature/submissions/reducers/submissions";
import editModal from "./edit-modal";
import dropdown from "./dropdown";
import {
  assetManager,
  initialState as assetManagerInitialState
} from "../feature/asset-manager/reducers/asset-manager.reducer";
import { ACTIONS as ASSET_MANAGER_ACTIONS } from "../feature/asset-manager/actions/asset-manager.actions";
import { assetManagerFilters } from "../feature/asset-manager/reducers/filters/asset-manager-filters.reducer";
import { assetManagerTransformUICanvas } from "../feature/transform-ui/reducers/transform-ui-canvas.reducer";
import undoable from "redux-undo";
import { languages } from "../feature/experience/reducers/languages/languages";
import { ACTIONS } from "../feature/transform-ui/actions/transform-ui-canvas.actions";
import { assetManagerTransformUI } from "../feature/transform-ui/reducers/transform-ui.reducer";
import requestRights from "./request-rights/request-rights.reducer";
import profileDropdown from "./profile-dropdown/profile-dropdown.reducer";
import { collaborators } from "../feature/account-settings/reducers/collaborators/collaborators.reducer";
import { collaboratorsSidebar } from "../feature/account-settings/reducers/collaborators/collaborators-sidebar.reducer";
import customFonts from "../feature/account-settings/reducers/custom-fonts/custom-fonts.reducer";
import { assetManagerGifBuilder } from "../feature/asset-manager/reducers/gif-builder/asset-manager-gif-builder.reducer";
import { assetMarketingIntagrations } from "../feature/asset-manager/reducers/asset-marketing-integrations/asset-marketing-integrations.reducer";
import { assetCollectionsFilters } from "../feature/asset-manager/reducers/asset-collections-filters/asset-collections-filters.reducer";
import { assetCollections } from "../feature/asset-manager/reducers/asset-collections/asset-collections.reducer";
import { assetsAggregations } from "../feature/asset-manager/reducers/assets-aggregations/assets-aggregations.reducer";
import { selectable } from "./enchancers/selectable";
import { ACTIONS as COLLECTION_ACTIONS } from "../feature/asset-manager/actions/asset-collections/asset-collections.actions";
import { initialState as assetCollectionsInitialState } from "../feature/asset-manager/reducers/asset-collections/asset-collections.reducer";
import { assetReducer } from "../feature/asset-manager/reducers/asset-variants/asset-variants.reducer";
import customDomains from "../feature/experience/reducers/custom-domains/custom-domains.reducer";
import { personalSettings } from "../feature/personal-settings/reducer/profile/profile.reducer";
import uploadDialog from "./upload-dialog/upload-dialog";
import { socialConnections } from "../feature/account-settings/reducers/social-connections/social-connections.reducer";
import { marketingIntegrations } from "../feature/account-settings/reducers/marketing-integrations/marketing-integrations.reducer";
import { marketingIntegrationsFolders } from "../feature/account-settings/reducers/marketing-integrations/marketing-integrations-folders.reducer";
import { socialFeedsToTransfer } from "../feature/account-settings/reducers/social-connections/social-feeds-transfer.reducer";
import teams from "./teams/teams.reducer";
import { socialConnectionsModal } from "../feature/account-settings/reducers/social-connections/social-connections-modal.reducer";
import { oAuthApplications } from "../feature/account-settings/reducers/oauth-applications/oauth-applications.reducer";
import { oAuthApplicationsTableRows } from "../feature/account-settings/reducers/oauth-applications/oauth-applications-table-rows.reducer";
import { addProductAccountsModal } from "../feature/account-settings/reducers/product-accounts/add-product-accounts-modal.reducer";
import { addProductFeedsModal } from "../feature/account-settings/reducers/product-feeds/add-product-feeds-modal.reducer";
import { productDetailsModal } from "../feature/account-settings/reducers/product-feeds/product-details-modal.reducer";
import { productAccounts } from "../feature/account-settings/reducers/product-accounts/product-accounts.reducer";
import { productFeeds } from "../feature/account-settings/reducers/product-feeds/product-feeds.reducer";
import { reuploadFeedsSidebar } from "../feature/account-settings/reducers/product-feeds/reupload-feeds-sidebar.reducer";
import { products } from "../feature/account-settings/reducers/products/products.reducer";
import { marketingIntegrationsSidebar } from "../feature/account-settings/reducers/marketing-integrations/marketing-integrations-sidebar.reducer";
import { roles } from "../feature/account-settings/reducers/roles/roles.reducer";
import { rolesSidebar } from "../feature/account-settings/reducers/roles/roles-sidebar.reducer";
import { uiEcommerce } from "../feature/ecommerce/reducer/ui-ecommerce.reducer";
import { tintMix } from "../feature/tint-mix/reducers/tint-mix.reducer";
import { discover } from "../feature/discover/reducers/discover.reducer";
import { socialFeedsSidebar } from "../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/reducers/social-feeds-sidebar.reducer";
import { tintMixPreview } from "../feature/tint-mix/reducers/tint-mix-preview.reducer";
import { addProductReducer } from "../feature/account-settings/reducers/product-feeds/add-product.reducer";
import { socialFeeds } from "../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/reducers/social-feeds.reducer";
import mediaComposer from "../feature/media-composer/reducers/media-composer.reducer";
import tintEditorReducer from "../feature/tint-editor/reducers";
import { newTeamReducer } from "../feature/new-team/reducers/new-team.reducer";
import { planAddonsReducer } from "../feature/plan-addons/reducers/plan-addons.reducer";
import { wizards } from "../feature/wizards/reducers/wizards.reducer";
import { subscribePlanReducer } from "../feature/subscribe-plan/reducers/subscribe-plan.reducer";
import { webhooks } from "../feature/account-settings/reducers/webhooks/webhooks.reducer";
import sidebar from "./sidebar/sidebar.reducer";
import { ui } from "./ui/ui.reducer";
import socialPublishingReducer from "../feature/social-publishing/reducer";
import formBuilderReducers from "../feature/form-builder/reducers";
import { missionHubsListReducer } from "../feature/mission-hubs/reducers/list/mission-hubs.list.reducer";
import { missionHubReducer } from "../feature/mission-hubs/reducers/mission-hub/mission-hub.reducer";
import { emailDomains } from "../feature/account-settings/reducers/email-domains/email-domains.reducer";
import { emailTemplates } from "../feature/account-settings/reducers/email-templates/email-templates.reducer";
import { emailTemplatesBuilder } from "../feature/account-settings/reducers/email-templates/email-templates-builder.reducer";
import audienceBuilderReducer from "../feature/audience-builder/reducers";
const reducers = __spreadValues(__spreadProps(__spreadValues(__spreadProps(__spreadValues(__spreadValues(__spreadValues({
  session,
  account,
  appRoute,
  experience,
  experiences,
  experienceBuilder,
  formSectionFieldModal,
  posts,
  customFonts,
  submissions,
  editModal,
  dropdown,
  languages,
  marketingIntegrations,
  marketingIntegrationsFolders,
  marketingIntegrationsSidebar,
  assetManager: selectable(
    assetManager,
    {
      SELECT: ASSET_MANAGER_ACTIONS.SELECT_ASSET,
      UNSELECT: ASSET_MANAGER_ACTIONS.UNSELECT_ASSET,
      SELECT_ALL: ASSET_MANAGER_ACTIONS.SELECT_ALL_ASSETS,
      UNSELECT_ALL: ASSET_MANAGER_ACTIONS.UNSELECT_ALL_ASSETS,
      SET_DISPLAY_TYPE: ASSET_MANAGER_ACTIONS.SET_ASSET_DISPLAY_TYPE
    },
    assetManagerInitialState
  ),
  assetManagerFilters,
  assetManagerGifBuilder,
  assetManagerTransformUI,
  assetManagerTransformUICanvas: undoable(assetManagerTransformUICanvas, {
    undoType: ACTIONS.UNDO_CANVAS,
    redoType: ACTIONS.REDO_CANVAS,
    clearHistoryType: ACTIONS.CLEAR_HISTORY
  }),
  assetCollectionsFilters,
  assetCollections: selectable(
    assetCollections,
    {
      SELECT: COLLECTION_ACTIONS.SELECT_COLLECTION,
      UNSELECT: COLLECTION_ACTIONS.UNSELECT_COLLECTION,
      SELECT_ALL: COLLECTION_ACTIONS.SELECT_ALL_COLLECTIONS,
      UNSELECT_ALL: COLLECTION_ACTIONS.UNSELECT_ALL_COLLECTIONS,
      SET_DISPLAY_TYPE: COLLECTION_ACTIONS.SET_COLLECTION_DISPLAY_TYPE
    },
    assetCollectionsInitialState
  ),
  asset: assetReducer,
  assetMarketingIntagrations,
  assetsAggregations,
  customDomains,
  userProfile: personalSettings,
  uploadDialog,
  socialConnections,
  socialConnectionsModal,
  requestRights,
  teams,
  collaborators,
  collaboratorsSidebar,
  socialFeedsToTransfer,
  oAuthApplications,
  oAuthApplicationsTableRows,
  addProductAccountsModal,
  addProductFeedsModal,
  productAccounts,
  productFeeds,
  reuploadFeedsSidebar,
  products,
  productDetailsModal,
  roles,
  rolesSidebar,
  UIEcommerce: uiEcommerce,
  discover,
  socialFeedsSidebar,
  tintMix,
  tintMixPreview,
  addProduct: addProductReducer,
  socialFeeds,
  mediaComposer
}, tintEditorReducer), insightsReducers), socialPublishingReducer), {
  newTeam: newTeamReducer,
  planAddons: planAddonsReducer,
  subscribePlan: subscribePlanReducer,
  profileDropdown,
  wizards,
  webhooks,
  sidebar,
  ui
}), formBuilderReducers), {
  missionHubsList: missionHubsListReducer,
  missionHub: missionHubReducer,
  emailDomains,
  emailTemplates,
  emailTemplatesBuilder
}), audienceBuilderReducer);
const sortedReducers = Object.keys(reducers).sort((a, b) => {
  if (a === "session" || a === "teams") return -1;
  if (b === "session" || b === "teams") return 1;
  return a.toLowerCase().localeCompare(b.toLowerCase());
}).reduce((acc, key) => {
  acc[key] = reducers[key];
  return acc;
}, {});
export default combineReducers(sortedReducers);
