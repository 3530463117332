import React from "react";
import TintThumbnail from "../../../../../../../../components/interface/thumbnail/thumbnail";
import PropTypes from "prop-types";
import "./post-item.sass";
import ColorParser from "../../../../../../../../services/color-parser/color-parser";
const PostItem = function({ thumb, title, styles }) {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "tint-post-item",
      style: {
        background: ColorParser.defaultColor(styles.backgroundColor),
        borderBottom: `4px solid ${ColorParser.defaultColor(styles.backgroundColorRange)}`,
        color: ColorParser.defaultColor(styles.textColor)
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-post-item__wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "tint-post-item__thumb" }, /* @__PURE__ */ React.createElement(TintThumbnail, { noAlt: true, imageUrl: thumb, style: { width: "auto", height: "auto" } })), title ? /* @__PURE__ */ React.createElement("div", { className: "tint-post-item__content" }, /* @__PURE__ */ React.createElement("p", { style: { color: ColorParser.defaultColor(styles.textColor) } }, title)) : null)
  );
};
export default PostItem;
PostItem.propTypes = {
  thumb: PropTypes.string,
  title: PropTypes.string,
  styles: PropTypes.any
};
