var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../../actions/marketing-integrations/marketing-integrations-folders.actions";
import { ExpirationStatuses } from "../../components/marketing-integrations/table/row/marketing-integrations-table-row";
import { marketingIntegrationsSources } from "../../utils/marketing-integrations-sources/marketing-integrations-sources";
export const initialState = {
  data: [],
  isFetching: false,
  error: void 0
};
export const marketingIntegrationsFolders = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.SUCCESS:
      return __spreadProps(__spreadValues({}, state), {
        data: state.data.filter((item) => {
          return item.id !== action.payload.data.id;
        }).concat(action.payload.data),
        isFetching: false,
        error: void 0
      });
    case ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.REQUEST:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: true,
        error: void 0
      });
    case ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.FAILURE:
      return __spreadProps(__spreadValues({}, state), {
        isFetching: false,
        error: action.payload
      });
    default:
      return state;
  }
};
const getSourceName = (source) => {
  const item = marketingIntegrationsSources.find((e) => e.source === source);
  return item ? `(${item.title})` : "";
};
export const mapMarketingIntegrationsAccountsForDropdown = (list) => list.reduce((acc, item) => {
  if (item.attributes.status === ExpirationStatuses.READY) {
    if (item.attributes.source === "twitter") return acc;
    acc.push({
      value: item.id,
      name: `${item.attributes.name || "Unknown"} ${getSourceName(item.attributes.source)}`
    });
  }
  return acc;
}, []);
export const mapMarketingIntegrationsFoldersForDropdown = (list) => list.map((e) => ({ value: e.data.id, name: e.data.attributes.name || "Unknown" }));
