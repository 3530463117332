var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./custom-component.sass";
import PropTypes from "prop-types";
export class CustomComponent extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "onClick", () => {
      if (!this.props.isBlocked) {
        this.props.onClick();
        this.props.action();
      }
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-custom-component ${this.props.isBlocked ? "tint-custom-component--disabled" : ""}`,
        onClick: this.onClick
      },
      /* @__PURE__ */ React.createElement("span", { className: `far ${this.props.icon}` }),
      /* @__PURE__ */ React.createElement("p", { className: "tint-custom-component__title" }, this.props.title)
    );
  }
}
CustomComponent.propTypes = {
  onClick: PropTypes.func,
  action: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  isBlocked: PropTypes.bool
};
