var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import PreloaderComponent, {
  PreloaderPositionType
} from "../../../../../../../../components/interface/preloaders/preloader/preloader";
import { useSelector, useDispatch } from "react-redux";
import SocialFeedFormInput from "../social-feed-form-input/social-feed-form-input";
import {
  getRequestRightsTemplates,
  getRequestRightsTerms
} from "../../../../../../../../actions/request-rights/request-rights.fat-actions";
import { SocialFeedsSource } from "../../../../../../../../types/social-feeds";
import { SidebarItemTypes } from "../../utils/meta/consts";
import { dropdownAdditionalSelectField, dropdownAdditionalFormSelectField } from "../../utils/common/common";
import { LinkSocialFeedSidebar } from "../../link-social-feed-sidebar/link-social-feed-sidebar";
import { HeaderSocialFeedSidebar } from "../common/header-social-feed-sidebar/header-social-feed-sidebar";
import * as Yup from "yup";
import "./social-feed-form.sass";
import { isAllowed } from "../../../../../../../../services/functions/features-checker/features-checker";
import { Config } from "../../../../../../../../utils/config";
import FeatureGuard from "../../../../../../../../guards/feature-guard";
const dropdownUserNameDefaultValue = {
  name: "Select Username...",
  value: "select-username"
};
const dropdownNameDefaultValue = {
  name: "Select...",
  value: "select-name"
};
const dropdownCompanyDefaultValue = {
  name: "Select company...",
  value: "select-company"
};
const SocialFeedForm = ({
  onSubmit,
  onPatch,
  selectedOption,
  schema,
  currentActiveSocialFeedSource,
  formRef,
  setIsFormValid,
  backendErrors,
  editFeedData,
  isSocialFeedUpgradeSidebarOpen
}) => {
  const dispatch = useDispatch();
  const formSchema = schema.formSchema[selectedOption];
  const getValidationSchema = () => {
    return !editFeedData || isSocialFeedUpgradeSidebarOpen ? formSchema.validationSchema : Yup.object({});
  };
  const isSubAccountsPending = useSelector((state) => state.socialConnections.isSubAccountsPending);
  const isFetchingForm = useSelector((state) => state.socialFeeds.isFetchingForm);
  const isPending = useSelector((state) => state.socialConnections.isPending);
  const socialSubAccounts = useSelector((state) => state.socialConnections.subAccounts);
  const form = useSelector((state) => {
    var _a, _b;
    return (_b = (_a = state.socialFeeds) == null ? void 0 : _a.form) == null ? void 0 : _b.data;
  });
  const session = useSelector((state) => state.session);
  const featureList = session.data.features;
  const hasAccessToComments = isAllowed(featureList, [Config.features.postsReplies]);
  const hasAccessToDomainsEmail = isAllowed(featureList, [Config.features.domainsEmail]);
  const isInfluencerSocialFeed = currentActiveSocialFeedSource === SocialFeedsSource.INFLUENCER;
  const isPublicPostingSocialFeed = currentActiveSocialFeedSource === SocialFeedsSource.REVIEWS_RATINGS || currentActiveSocialFeedSource === SocialFeedsSource.PUBLIC_POST;
  const [hashTagsCount, setHashTagsCount] = useState(0);
  const checkboxInitialValue = (previousValue, currentValue) => {
    const previousChecked = previousValue.checked ? previousValue.checked : [];
    return {
      checked: [...previousChecked, currentValue.defaultValue(editFeedData, currentValue.id)]
    };
  };
  const getInitialValues = (previousValue, currentValue) => {
    var _a, _b;
    if (currentValue.type === SidebarItemTypes.CHECKBOX) {
      return checkboxInitialValue(previousValue, currentValue);
    }
    if (isPublicPostingSocialFeed && ((_b = (_a = editFeedData == null ? void 0 : editFeedData.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.form_id)) {
      return { [currentValue.id]: currentValue.defaultValue ? currentValue.defaultValue(editFeedData, form) : "" };
    }
    return { [currentValue.id]: currentValue.defaultValue ? currentValue.defaultValue(editFeedData) : "" };
  };
  const inputs = !editFeedData ? formSchema.inputs : formSchema.editInputs;
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialErrors: [{}],
    validateOnMount: true,
    validationSchema: getValidationSchema,
    initialValues: inputs.reduce((previousValue, currentValue) => {
      const value = getInitialValues(previousValue, currentValue);
      return __spreadValues(__spreadValues({}, previousValue), value);
    }, {}),
    onSubmit: (values) => {
      if (editFeedData) {
        if ([SocialFeedsSource.PUBLIC_POST, SocialFeedsSource.REVIEWS_RATINGS, SocialFeedsSource.VESTA].includes(
          currentActiveSocialFeedSource
        )) {
          onPatch(formSchema.submitMapper(values), editFeedData.id);
        } else {
          onPatch(
            {
              pre_processing_rules: values.checked.find((i) => i === "pre_processing_rules") ? [
                {
                  conditions: {
                    media_type: ["image", "video"]
                  }
                }
              ] : []
            },
            editFeedData.id
          );
        }
      } else {
        onSubmit(formSchema.submitMapper(values));
      }
    }
  });
  useEffect(() => {
    if (isInfluencerSocialFeed) {
      dispatch(getRequestRightsTemplates({}));
      dispatch(getRequestRightsTerms({}));
    }
  }, []);
  useEffect(() => {
    if (form == null ? void 0 : form.id) {
      formik.setFieldValue("form", { value: form.id, name: form.attributes.name });
    }
  }, [form]);
  useEffect(() => {
    var _a, _b, _c, _d;
    switch (currentActiveSocialFeedSource) {
      case SocialFeedsSource.PUBLIC_POST:
      case SocialFeedsSource.REVIEWS_RATINGS:
        setIsFormValid(((_b = (_a = formik.values) == null ? void 0 : _a.form) == null ? void 0 : _b.value) !== dropdownAdditionalFormSelectField.value);
        break;
      case SocialFeedsSource.FORMS:
        setIsFormValid(formik.isValid && ((_d = (_c = formik.values) == null ? void 0 : _c.form) == null ? void 0 : _d.value) !== dropdownAdditionalSelectField.value);
        break;
      case SocialFeedsSource.EXPERIENCE_BUILDER:
        setIsFormValid(
          formik.isValid && formik.values.experience.value !== dropdownAdditionalSelectField.value && formik.values.name.value !== dropdownNameDefaultValue.value && formik.values.body.value !== dropdownNameDefaultValue.value
        );
        break;
      case SocialFeedsSource.LINKED_IN:
        setIsFormValid(
          formik.isValid && formik.values.account.value !== dropdownAdditionalSelectField.value && formik.values.company.value !== dropdownCompanyDefaultValue.value
        );
        break;
      default:
        setIsFormValid(formik.isValid);
    }
  }, [currentActiveSocialFeedSource, setIsFormValid, formik.isValid, formik.values, formik.dirty]);
  const renderDescription = (value) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, value.description && /* @__PURE__ */ React.createElement("p", { className: "tint-social-feed-sidebar-experience-builder__description" }, value.description, value.link && /* @__PURE__ */ React.createElement(LinkSocialFeedSidebar, { href: value.link })));
  };
  const renderInput = (value, formik2) => {
    return /* @__PURE__ */ React.createElement(
      SocialFeedFormInput,
      {
        inputValue: value,
        formik: formik2,
        accountSourceType: schema.accountSourceType,
        currentActiveSocialFeedSource,
        defaultList: value.defaultList,
        setHashTagsCount,
        selectedType: selectedOption
      }
    );
  };
  const renderMoreInfo = (value, formik2) => {
    var _a, _b;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, currentActiveSocialFeedSource === SocialFeedsSource.INSTAGRAM_BUSINESS && value.id === "username" && selectedOption === "hashtags" && ((_a = formik2.values.username) == null ? void 0 : _a.value) !== dropdownUserNameDefaultValue.value && ((_b = formik2.values.username) == null ? void 0 : _b.value) && /* @__PURE__ */ React.createElement("div", { className: "using-hashtags" }, "Currently using ", hashTagsCount, " of 30 hashtags."));
  };
  const renderLineBreak = (value) => {
    return (value == null ? void 0 : value.lineBreak) ? /* @__PURE__ */ React.createElement("span", { className: "tint-sidebar-social-feeds__line-break" }) : null;
  };
  const renderFooter = () => {
    return /* @__PURE__ */ React.createElement(
      "p",
      {
        className: "tint-social-feed-sidebar-experience-builder__footer",
        dangerouslySetInnerHTML: { __html: formSchema.footerCopy }
      }
    );
  };
  const renderImage = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("img", { src: schema.img }), /* @__PURE__ */ React.createElement("span", { className: "tint-sidebar-social-feeds__line-break" }));
  };
  const renderHeader = () => {
    return /* @__PURE__ */ React.createElement(
      HeaderSocialFeedSidebar,
      {
        copy: schema.description,
        renderLink: schema.zendeskUrl ? /* @__PURE__ */ React.createElement(LinkSocialFeedSidebar, { href: schema.zendeskUrl }) : null
      }
    );
  };
  const renderSubDescription = () => {
    return /* @__PURE__ */ React.createElement(HeaderSocialFeedSidebar, { copy: schema.subDescription });
  };
  const renderPreloader = () => {
    return /* @__PURE__ */ React.createElement(PreloaderComponent, { positionType: PreloaderPositionType.ABSOLUTE });
  };
  const renderBackendErrors = (value) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, backendErrors[value.backendId] && /* @__PURE__ */ React.createElement("div", { className: "tint-sidebar-social-feeds__error-message" }, backendErrors[value.backendId].replace(value.backendId, value.id)));
  };
  const renderForm = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, schema.description && renderHeader(), schema.subDescription && renderSubDescription(), schema.img && renderImage(), /* @__PURE__ */ React.createElement(
      "form",
      {
        id: `${currentActiveSocialFeedSource}-${isSocialFeedUpgradeSidebarOpen ? "upgrade_" : ""}form`,
        ref: formRef,
        onSubmit: formik.handleSubmit
      },
      /* @__PURE__ */ React.createElement("ul", null, inputs.map((value) => /* @__PURE__ */ React.createElement(
        "li",
        {
          key: value.id,
          className: `${value.id}
              ${value.type === SidebarItemTypes.EMAIL_TEMPLATE_ID && currentActiveSocialFeedSource === SocialFeedsSource.REVIEWS_RATINGS && !(hasAccessToComments && hasAccessToDomainsEmail) || value.disabled && value.disabled(formik.values, !socialSubAccounts) ? "social-feed__hide-item" : ""}`
        },
        /* @__PURE__ */ React.createElement(
          FeatureGuard,
          {
            featureName: value == null ? void 0 : value.featureName,
            hasAccess: !(value == null ? void 0 : value.featureName) ? true : void 0,
            showUpgradeSidebar: false
          },
          /* @__PURE__ */ React.createElement(React.Fragment, null, renderDescription(value), renderInput(value, formik), renderBackendErrors(value), renderMoreInfo(value, formik), renderLineBreak(value))
        )
      ))),
      formSchema.footerCopy && renderFooter(),
      isPending || (isSubAccountsPending || isFetchingForm) && renderPreloader()
    ));
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, renderForm());
};
export default SocialFeedForm;
