export const smileyScale = {
  SMILEY_SCALE: ".input-smile-scale",
  SMILEY_SCALE_LABEL: ".input-smile-scale label",
  SMILEY_SCALE_INPUT: ".input-smile-scale input",
  SMILEY_SCALE_INPUT_ONE: ".input-smile-scale input:first-of-type",
  SMILEY_SCALE_INPUT_ONE_ACTIVE: ".input-smile-scale input:first-of-type:checked, .input-smile-scale input:first-of-type:hover",
  SMILEY_SCALE_INPUT_TWO: ".input-smile-scale input:nth-of-type(2)",
  SMILEY_SCALE_INPUT_TWO_ACTIVE: ".input-smile-scale input:nth-of-type(2):checked, .input-smile-scale input:nth-of-type(2):hover",
  SMILEY_SCALE_INPUT_THREE: ".input-smile-scale input:nth-of-type(3)",
  SMILEY_SCALE_INPUT_THREE_ACTIVE: ".input-smile-scale input:nth-of-type(3):checked, .input-smile-scale input:nth-of-type(3):hover",
  SMILEY_SCALE_INPUT_FOUR: ".input-smile-scale input:nth-of-type(4)",
  SMILEY_SCALE_INPUT_FOUR_ACTIVE: ".input-smile-scale input:nth-of-type(4):checked, .input-smile-scale input:nth-of-type(4):hover",
  SMILEY_SCALE_INPUT_FIVE: ".input-smile-scale input:nth-of-type(5)",
  SMILEY_SCALE_INPUT_FIVE_ACTIVE: ".input-smile-scale input:nth-of-type(5):checked, .input-smile-scale input:nth-of-type(5):hover",
  SMILEY_SCALE_RTL: "*[dir='rtl'] .input-smile-scale input:first-of-type"
};
