var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import PropTypes from "prop-types";
import "./edit-checkbox.sass";
const EditCheckbox = (_a) => {
  var _b = _a, {
    id,
    name,
    onChange,
    onBlur,
    onFocus,
    value,
    placeholder,
    label,
    labelUrl,
    errorMsg,
    touched,
    disabled,
    onClickTerms
  } = _b, props = __objRest(_b, [
    "id",
    "name",
    "onChange",
    "onBlur",
    "onFocus",
    "value",
    "placeholder",
    "label",
    "labelUrl",
    "errorMsg",
    "touched",
    "disabled",
    "onClickTerms"
  ]);
  const errorClasses = errorMsg && touched ? "tint-edit-checkbox-container--error" : "";
  const isClickable = labelUrl || onClickTerms;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-edit-checkbox-container ${errorClasses} ${disabled ? "tint-edit-checkbox-container--disabled" : ""}`
    },
    /* @__PURE__ */ React.createElement("label", { htmlFor: id }, /* @__PURE__ */ React.createElement(
      "input",
      __spreadValues({
        id,
        name,
        onChange: (e) => !disabled && onChange(e),
        onBlur,
        onFocus,
        value,
        checked: value,
        placeholder,
        type: "checkbox",
        required: true
      }, props)
    ), /* @__PURE__ */ React.createElement("span", { className: "tint-edit-checkbox-container__label" }, /* @__PURE__ */ React.createElement("span", { className: `checkbox-label-text ${isClickable ? "checkbox-label-text--clickable" : ""}` }, labelUrl ? /* @__PURE__ */ React.createElement("a", { rel: "noopener noreferrer", target: "_blank", href: labelUrl }, label) : onClickTerms ? /* @__PURE__ */ React.createElement(
      "a",
      {
        onClick: (e) => {
          e.preventDefault();
          onClickTerms();
        }
      },
      label
    ) : /* @__PURE__ */ React.createElement(React.Fragment, null, label))))
  );
};
EditCheckbox.propTypes = {
  label: PropTypes.string,
  labelUrl: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClickTerms: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string
};
export default EditCheckbox;
