import * as Yup from "yup";
export const validationSchemaFlickrHashtag = () => {
  return Yup.object({
    hashtag: Yup.string().required("Hashtag is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaFlickrAccount = () => {
  return Yup.object({
    user: Yup.string().required("Username is required."),
    account: Yup.object().required("Account is required.")
  });
};
export const validationSchemaFlickrBrandContent = () => {
  return Yup.object({
    account: Yup.object().required("Account is required.")
  });
};
