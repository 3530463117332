var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./content-loader.sass";
import { breakpoints } from "../../../../utils/variables/breakpoints";
export const ContentLoader = ({
  rects,
  loadersCount = 1,
  customClassName,
  shouldAnimate = true,
  hasOneByOneAnimation
}) => {
  const definedRects = (window.innerWidth < breakpoints.mdm && (rects == null ? void 0 : rects.mobile) ? rects.mobile : window.innerWidth > breakpoints.xxl && (rects == null ? void 0 : rects.desktopXXL) ? rects.desktopXXL : rects.desktop).map((el, i) => {
    const CustomTag = el.tagType;
    return /* @__PURE__ */ React.createElement(CustomTag, __spreadValues({ key: i }, el.props));
  });
  return Array.from({ length: loadersCount }).map((el, index) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        "data-loaders-count": loadersCount,
        className: `content-loader ${customClassName} content-loader--${shouldAnimate ? "animate" : "no-animate"} content-loader--${hasOneByOneAnimation ? "animation-linear" : ""}`,
        style: hasOneByOneAnimation ? { opacity: 0, animationDelay: `${(index + 1) * 50}ms` } : {},
        key: index
      },
      /* @__PURE__ */ React.createElement("svg", null, definedRects)
    );
  });
};
