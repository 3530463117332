var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ACTIONS } from "../actions/content/insights-content.actions";
import { produce } from "immer";
import { InsightsUtil } from "../utils/insights.util";
import { parseISO, format, differenceInDays, addDays } from "date-fns";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const initialState = {
  topAccounts: {
    data: void 0,
    isFetching: false
  },
  topClickedPosts: {
    data: void 0,
    isFetching: false
  },
  postsIngested: {
    data: void 0,
    isFetching: false
  },
  postsIngestedPerDay: {
    data: void 0,
    isFetching: false
  },
  topSources: {
    data: void 0,
    isFetching: false
  },
  topFeeds: {
    data: void 0,
    isFetching: false
  },
  totalPosts: {
    data: void 0,
    isFetching: false
  },
  moderationActivity: {
    data: void 0,
    isFetching: false
  },
  dailyModeration: {
    data: void 0,
    isFetching: false
  },
  topModerators: {
    data: void 0,
    isFetching: false
  },
  rightsRequested: {
    data: void 0,
    isFetching: false
  },
  rightsApproved: {
    data: void 0,
    isFetching: false
  },
  totalContentCollected: {
    data: void 0,
    isFetching: false
  },
  topRightsRequesters: {
    data: void 0,
    isFetching: false
  }
};
export const insightsContent = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;
    case ACTIONS.GET_POSTS_INGESTED_PER_DAY.FAILURE:
      return produce(state, (draftState) => {
        draftState.postsIngestedPerDay.isFetching = false;
      });
    case ACTIONS.GET_POSTS_INGESTED_PER_DAY.REQUEST:
      return produce(state, (draftState) => {
        draftState.postsIngestedPerDay.isFetching = true;
      });
    case ACTIONS.GET_POSTS_INGESTED_PER_DAY.SUCCESS:
      return produce(state, (draftState) => {
        draftState.postsIngestedPerDay.isFetching = false;
        draftState.postsIngestedPerDay.data = action.payload;
      });
    case ACTIONS.GET_POSTS_INGESTED.FAILURE:
      return produce(state, (draftState) => {
        draftState.postsIngested.isFetching = false;
      });
    case ACTIONS.GET_POSTS_INGESTED.REQUEST:
      return produce(state, (draftState) => {
        draftState.postsIngested.isFetching = true;
      });
    case ACTIONS.GET_POSTS_INGESTED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.postsIngested.isFetching = false;
        draftState.postsIngested.data = action.payload;
      });
    case ACTIONS.GET_TOP_ACCOUNTS.FAILURE:
      return produce(state, (draftState) => {
        draftState.topAccounts.isFetching = false;
      });
    case ACTIONS.GET_TOP_ACCOUNTS.REQUEST:
      return produce(state, (draftState) => {
        draftState.topAccounts.isFetching = true;
      });
    case ACTIONS.GET_TOP_ACCOUNTS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topAccounts.isFetching = false;
        draftState.topAccounts.data = action.payload;
      });
    case ACTIONS.GET_TOP_RIGHTS_REQUESTERS.FAILURE:
      return produce(state, (draftState) => {
        draftState.topRightsRequesters.isFetching = false;
      });
    case ACTIONS.GET_TOP_RIGHTS_REQUESTERS.REQUEST:
      return produce(state, (draftState) => {
        draftState.topRightsRequesters.isFetching = true;
      });
    case ACTIONS.GET_TOP_RIGHTS_REQUESTERS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topRightsRequesters.isFetching = false;
        draftState.topRightsRequesters.data = action.payload;
      });
    case ACTIONS.GET_TOTAL_CONTENT_COLLECTED.FAILURE:
      return produce(state, (draftState) => {
        draftState.totalContentCollected.isFetching = false;
      });
    case ACTIONS.GET_TOTAL_CONTENT_COLLECTED.REQUEST:
      return produce(state, (draftState) => {
        draftState.totalContentCollected.isFetching = true;
      });
    case ACTIONS.GET_TOTAL_CONTENT_COLLECTED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.totalContentCollected.isFetching = false;
        draftState.totalContentCollected.data = action.payload;
      });
    case ACTIONS.GET_RIGHTS_APPROVED.FAILURE:
      return produce(state, (draftState) => {
        draftState.rightsApproved.isFetching = false;
      });
    case ACTIONS.GET_RIGHTS_APPROVED.REQUEST:
      return produce(state, (draftState) => {
        draftState.rightsApproved.isFetching = true;
      });
    case ACTIONS.GET_RIGHTS_APPROVED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.rightsApproved.isFetching = false;
        draftState.rightsApproved.data = action.payload;
      });
    case ACTIONS.GET_TOP_CLICKED_POSTS.FAILURE:
      return produce(state, (draftState) => {
        draftState.topClickedPosts.isFetching = false;
      });
    case ACTIONS.GET_TOP_CLICKED_POSTS.REQUEST:
      return produce(state, (draftState) => {
        draftState.topClickedPosts.isFetching = true;
      });
    case ACTIONS.GET_TOP_CLICKED_POSTS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topClickedPosts.isFetching = false;
        draftState.topClickedPosts.data = action.payload;
      });
    case ACTIONS.GET_RIGHTS_REQUESTED.FAILURE:
      return produce(state, (draftState) => {
        draftState.rightsRequested.isFetching = false;
      });
    case ACTIONS.GET_RIGHTS_REQUESTED.REQUEST:
      return produce(state, (draftState) => {
        draftState.rightsRequested.isFetching = true;
      });
    case ACTIONS.GET_RIGHTS_REQUESTED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.rightsRequested.isFetching = false;
        draftState.rightsRequested.data = action.payload;
      });
    case ACTIONS.GET_TOP_MODERATORS.FAILURE:
      return produce(state, (draftState) => {
        draftState.topModerators.isFetching = false;
      });
    case ACTIONS.GET_TOP_MODERATORS.REQUEST:
      return produce(state, (draftState) => {
        draftState.topModerators.isFetching = true;
      });
    case ACTIONS.GET_TOP_MODERATORS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topModerators.isFetching = false;
        draftState.topModerators.data = action.payload;
      });
    case ACTIONS.GET_DAILY_MODERATION.FAILURE:
      return produce(state, (draftState) => {
        draftState.dailyModeration.isFetching = false;
      });
    case ACTIONS.GET_DAILY_MODERATION.REQUEST:
      return produce(state, (draftState) => {
        draftState.dailyModeration.isFetching = true;
      });
    case ACTIONS.GET_DAILY_MODERATION.SUCCESS:
      return produce(state, (draftState) => {
        draftState.dailyModeration.isFetching = false;
        draftState.dailyModeration.data = action.payload;
      });
    case ACTIONS.GET_MODERATION_ACTIVITY.FAILURE:
      return produce(state, (draftState) => {
        draftState.moderationActivity.isFetching = false;
      });
    case ACTIONS.GET_MODERATION_ACTIVITY.REQUEST:
      return produce(state, (draftState) => {
        draftState.moderationActivity.isFetching = true;
      });
    case ACTIONS.GET_MODERATION_ACTIVITY.SUCCESS:
      return produce(state, (draftState) => {
        draftState.moderationActivity.isFetching = false;
        draftState.moderationActivity.data = action.payload;
      });
    case ACTIONS.GET_TOTAL_POSTS_INGESTED.FAILURE:
      return produce(state, (draftState) => {
        draftState.totalPosts.isFetching = false;
      });
    case ACTIONS.GET_TOTAL_POSTS_INGESTED.REQUEST:
      return produce(state, (draftState) => {
        draftState.totalPosts.isFetching = true;
      });
    case ACTIONS.GET_TOTAL_POSTS_INGESTED.SUCCESS:
      return produce(state, (draftState) => {
        draftState.totalPosts.data = action.payload;
        draftState.totalPosts.isFetching = false;
      });
    case ACTIONS.GET_TOP_SOCIAL_FEEDS.FAILURE:
      return produce(state, (draftState) => {
        draftState.topFeeds.isFetching = false;
      });
    case ACTIONS.GET_TOP_SOCIAL_FEEDS.REQUEST:
      return produce(state, (draftState) => {
        draftState.topFeeds.isFetching = true;
      });
    case ACTIONS.GET_TOP_SOCIAL_FEEDS.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topFeeds.data = action.payload;
        draftState.topFeeds.isFetching = false;
      });
    case ACTIONS.GET_TOP_SOURCES.FAILURE:
      return produce(state, (draftState) => {
        draftState.topSources.isFetching = false;
      });
    case ACTIONS.GET_TOP_SOURCES.REQUEST:
      return produce(state, (draftState) => {
        draftState.topSources.isFetching = true;
      });
    case ACTIONS.GET_TOP_SOURCES.SUCCESS:
      return produce(state, (draftState) => {
        draftState.topSources.data = action.payload;
        draftState.topSources.isFetching = false;
      });
    default:
      return state;
  }
};
export const getTopSocialFeedsData = (state) => {
  if (!state || !state.topFeeds.data || state.topFeeds.data.data.length < 1) {
    return [];
  }
  const { data, included } = state.topFeeds.data;
  let arr = data.map((e) => ({
    id: e.id,
    postAmount: Number(e.attributes.count),
    socialFeedId: e.attributes.dimensions.social_feed_id
  })).sort((a, b) => b.postAmount - a.postAmount);
  const maxAmount = arr[0].postAmount;
  arr = arr.map((e) => {
    var _a, _b;
    let title;
    const socialFeed = included == null ? void 0 : included.find((i) => Number(i.id) === Number(e.socialFeedId));
    const source = InsightsUtil.socialFeedSource(socialFeed);
    title = InsightsUtil.socialFeedToReadableString(socialFeed);
    return __spreadProps(__spreadValues({}, e), {
      width: Math.round(100 * e.postAmount / maxAmount),
      title,
      status: ((_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.status) || "deleted",
      source: socialFeed ? (_b = source == null ? void 0 : source.toUpperCase) == null ? void 0 : _b.call(source) : void 0
    });
  });
  return arr;
};
export const getPostsIngestedData = (state) => {
  if (!state || !state.postsIngested.data) {
    return [];
  }
  return state.postsIngested.data.data.map((e) => ({
    day: e.attributes.dimensions.day_of_week,
    hour: e.attributes.dimensions.hour_of_day,
    count: e.attributes.count
  }));
};
export const getPostsIngestedPerDayData = (state) => {
  if (!state || !state.postsIngestedPerDay.data) {
    return [];
  }
  return state.postsIngestedPerDay.data.data.map((e) => ({
    day: e.attributes.dimensions.day_of_week,
    count: e.attributes.count
  })).sort((a, b) => a.count - b.count);
};
export const getTopClickedPostsData = (state) => {
  if (!state || !state.topClickedPosts.data) {
    return [];
  }
  const { data, included } = state.topClickedPosts.data;
  const _arr = [];
  data.map((e) => {
    var _a, _b, _c, _d;
    const post = included == null ? void 0 : included.find((i) => i.id === e.attributes.dimensions.post_id);
    let socialFeed;
    if (post) {
      const socialFeedId = (_c = (_b = (_a = post == null ? void 0 : post.relationships) == null ? void 0 : _a.social_feed) == null ? void 0 : _b.data) == null ? void 0 : _c.id;
      socialFeed = (_d = included == null ? void 0 : included.filter((e2) => e2.type === "social_feed")) == null ? void 0 : _d.find((e2) => e2.id === socialFeedId);
      _arr.push(__spreadProps(__spreadValues({ count: e.attributes.count }, post.attributes), { socialFeed }));
    }
  });
  return _arr;
};
export const getTopAccountsData = (state) => {
  if (!state || !state.topAccounts.data) {
    return { arr: [] };
  }
  const { data, included } = state.topAccounts.data;
  const _data = { arr: [] };
  _data.postSum = data.reduce((a, b) => a + Number(b.attributes.count), 0);
  data.map((e) => {
    if (!e.relationships) {
      return;
    }
    const count = Number(e.attributes.count);
    const account = included == null ? void 0 : included.find((i) => {
      var _a;
      return i.id === ((_a = e.relationships.account.data) == null ? void 0 : _a.id);
    });
    if (!account) {
      return;
    }
    _data.arr.push({
      amount: count,
      percentage: Math.round(count * 100 / _data.postSum),
      name: account.attributes.name || account.attributes.username,
      type: account.attributes.type.toUpperCase()
    });
  });
  _data.arr = _data.arr.sort((a, b) => b.amount - a.amount);
  return _data;
};
export const getTopSourcesData = (state) => {
  if (!state || !state.topSources.data) {
    return [];
  }
  const { data } = state.topSources.data;
  const sum = data.reduce((a, b) => a + Number(b.attributes.count), 0);
  return data.map((e) => {
    var _a, _b;
    const source = (_b = (_a = e.attributes) == null ? void 0 : _a.dimensions) == null ? void 0 : _b.social_feed_source;
    let name = source ? source[0].toUpperCase() + source.slice(1) : "Deleted";
    return {
      name,
      value: Number(e.attributes.count),
      percentage: Math.round(Number(e.attributes.count) * 100 / sum),
      type: "pie"
    };
  }).sort((a, b) => b.value - a.value);
};
export const getTotalPostsData = (state, insightsState) => {
  var _a, _b;
  if (!state || !((_a = state.totalPosts) == null ? void 0 : _a.data)) {
    return [];
  }
  const { data } = (_b = state.totalPosts) == null ? void 0 : _b.data;
  const _helperArr = data.map((e) => {
    var _a2;
    return {
      time: parseISO(e.attributes.dimensions.time),
      name: `${(_a2 = monthNames[parseISO(e.attributes.dimensions.time).getMonth()]) == null ? void 0 : _a2.substring(0, 3)} ${parseISO(
        e.attributes.dimensions.time
      ).getDate()}`,
      count: Number(e.attributes.count)
    };
  });
  let { startDate, endDate } = insightsState.filter.date;
  const ensureISOString = (date) => {
    if (typeof date === "string") {
      return date;
    } else if (typeof date === "number") {
      return new Date(date).toISOString();
    } else if (date instanceof Date) {
      return date.toISOString();
    } else {
      throw new Error(`Unsupported date format: ${date}`);
    }
  };
  try {
    startDate = ensureISOString(startDate);
    endDate = ensureISOString(endDate);
  } catch (error) {
    console.error("Invalid date format:", { startDate, endDate });
    return [];
  }
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  const days = differenceInDays(end, start);
  const dayArray = [];
  for (let i = 0; i <= days; i++) {
    dayArray.push(format(addDays(start, i), "yyyy-MM-dd"));
  }
  const _arr = dayArray.map((e) => {
    var _a2;
    const time = parseISO(e);
    const element = _helperArr.find((f) => format(f.time, "yyyy-MM-dd") === e);
    return {
      time,
      name: `${(_a2 = monthNames[time.getMonth()]) == null ? void 0 : _a2.substring(0, 3)} ${time.getDate()}`,
      count: element ? Number(element.count) : 0
    };
  });
  const sum = _helperArr.reduce((a, b) => a + Number(b.count), 0);
  return { data: _arr, totalPosts: sum };
};
export const getModerationActivityData = (state) => {
  if (!state || !state.moderationActivity.data) {
    return {};
  }
  const data = {};
  state.moderationActivity.data.data.map((e) => {
    if (!e.attributes.dimensions) {
      return;
    }
    switch (e.attributes.dimensions.status) {
      case "deleted":
        data.deleted = Number(e.attributes.count);
        break;
      case "private":
        data.private = Number(e.attributes.count);
        break;
      case "public":
        data.public = Number(e.attributes.count);
        break;
    }
  });
  return data;
};
export const getDailyModerationData = (state) => {
  if (!state || !state.dailyModeration.data) {
    return [];
  }
  const data = {};
  let arr = [];
  state.dailyModeration.data.data.map((e) => {
    let { status, time } = e.attributes.dimensions;
    time = new Date(time).getTime();
    if (!data[time]) {
      data[time] = {};
    }
    switch (status) {
      case "deleted":
        data[time].deleted = Number(e.attributes.count);
        break;
      case "private":
        data[time].private = Number(e.attributes.count);
        break;
      case "public":
        data[time].public = Number(e.attributes.count);
        break;
    }
  });
  for (const [key, value] of Object.entries(data)) {
    arr.push(__spreadValues({ date: key }, value));
  }
  return arr.sort((a, b) => a.date - b.date).map((e) => {
    const time = new Date(Number(e.date));
    return __spreadProps(__spreadValues({}, e), { name: `${monthNames[time.getMonth()]} ${time.getDate()}` });
  });
};
export const getTopModeratorsData = (state) => {
  if (!state || !state.topModerators.data) {
    return [];
  }
  const data = {};
  let arr = [];
  state.topModerators.data.data.map((e) => {
    let { status, user_id } = e.attributes.dimensions;
    if (!data[user_id]) {
      data[user_id] = { deleted: 0, private: 0, public: 0 };
    }
    switch (status) {
      case "deleted":
        data[user_id].deleted = Number(e.attributes.count);
        break;
      case "private":
        data[user_id].private = Number(e.attributes.count);
        break;
      case "public":
        data[user_id].public = Number(e.attributes.count);
        break;
    }
  });
  for (const [key, value] of Object.entries(data)) {
    arr.push(__spreadValues({ id: key }, value));
  }
  arr = arr.sort((a, b) => {
    return a.private + a.public + a.deleted - b.private + b.public + b.deleted;
  }).map((e) => {
    var _a;
    const user = (_a = state.topModerators.data.included) == null ? void 0 : _a.find((i) => i.id === e.id);
    if (user) {
      return __spreadProps(__spreadValues({}, e), {
        name: user.attributes.first_name,
        avatar: user.attributes.image_url,
        email: user.attributes.email
      });
    }
  }).filter((e) => !!e);
  return arr;
};
export const getSuggestedHashtagsData = () => {
  return [
    {
      name: "airjordan",
      size: 40302
    },
    {
      name: "adidas",
      size: 40302
    },
    {
      name: "jordan",
      size: 40302
    },
    {
      name: "sneaker",
      size: 40302
    },
    {
      name: "sneaker",
      size: 30302
    },
    {
      name: "fashion",
      size: 30302
    },
    {
      name: "shoe",
      size: 30302
    },
    {
      name: "air",
      size: 30302
    },
    {
      name: "size",
      size: 30302
    }
  ];
};
export const getPopularKeywordsData = () => {
  return [
    {
      name: "airjordan",
      size: 40302
    },
    {
      name: "adidas",
      size: 40302
    },
    {
      name: "jordan",
      size: 40302
    },
    {
      name: "sneaker",
      size: 40302
    },
    {
      name: "sneaker",
      size: 30302
    },
    {
      name: "fashion",
      size: 30302
    },
    {
      name: "shoe",
      size: 30302
    },
    {
      name: "air",
      size: 30302
    },
    {
      name: "size",
      size: 30302
    }
  ];
};
export const getContentRoiData = (state) => {
  var _a, _b;
  if (!state || !state.moderationActivity.data || !state.totalContentCollected.data) {
    return [];
  }
  const rightsManagement = getRightsManagementData(state);
  const _data = {};
  _data.postsCollected = ((_a = state.totalContentCollected.data.data[0]) == null ? void 0 : _a.attributes.count) || 0;
  _data.postsApproved = (_b = state.moderationActivity.data.data.find(
    (e) => {
      var _a2;
      return ((_a2 = e.attributes.dimensions) == null ? void 0 : _a2.status) === "public";
    }
  )) == null ? void 0 : _b.attributes.count;
  return __spreadValues(__spreadValues({}, _data), rightsManagement);
};
export const getTopRightsRequestersData = (state) => {
  if (!state || !state.topRightsRequesters.data) {
    return [];
  }
  let arr = [];
  const { included, data } = state.topRightsRequesters.data;
  data.map((e) => {
    var _a, _b, _c;
    if (!included) {
      return;
    }
    const _obj = {};
    const _userId = e.attributes.dimensions.user_id;
    const user = included.find((u) => u.id === _userId);
    if (user) {
      _obj.requests = e.attributes.count;
      _obj.name = ((_a = user.attributes) == null ? void 0 : _a.first_name) && ((_b = user.attributes) == null ? void 0 : _b.last_name) ? `${user.attributes.first_name} ${user.attributes.last_name}` : null;
      _obj.email = (_c = user.attributes) == null ? void 0 : _c.email;
      _obj.avatar = user == null ? void 0 : user.attributes.image_url;
      arr.push(_obj);
    }
  });
  return arr;
};
export const getRightsManagementData = (state) => {
  if (!state || !state.rightsRequested.data || !state.rightsApproved.data) {
    return {};
  }
  const _data = {};
  _data.rightsRequested = state.rightsRequested.data.data.reduce((a, b) => a + Number(b.attributes.count), 0);
  _data.rightsApproved = state.rightsApproved.data.data.reduce((a, b) => a + Number(b.attributes.count), 0);
  return _data;
};
export const hasAllInsightsContentData = (state) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  return !!((_a = state == null ? void 0 : state.rightsApproved) == null ? void 0 : _a.data) && !!((_b = state == null ? void 0 : state.topClickedPosts) == null ? void 0 : _b.data) && !!((_c = state == null ? void 0 : state.rightsRequested) == null ? void 0 : _c.data) && !!((_d = state == null ? void 0 : state.topRightsRequesters) == null ? void 0 : _d.data) && !!((_e = state == null ? void 0 : state.totalContentCollected) == null ? void 0 : _e.data) && !!((_f = state == null ? void 0 : state.topModerators) == null ? void 0 : _f.data) && !!((_g = state == null ? void 0 : state.dailyModeration) == null ? void 0 : _g.data) && !!((_h = state == null ? void 0 : state.moderationActivity) == null ? void 0 : _h.data) && !!((_i = state == null ? void 0 : state.topSources) == null ? void 0 : _i.data) && !!((_j = state == null ? void 0 : state.topAccounts) == null ? void 0 : _j.data) && !!((_k = state == null ? void 0 : state.topFeeds) == null ? void 0 : _k.data) && !!((_l = state == null ? void 0 : state.postsIngested) == null ? void 0 : _l.data) && !!((_m = state == null ? void 0 : state.postsIngestedPerDay) == null ? void 0 : _m.data) && !!((_n = state == null ? void 0 : state.totalPosts) == null ? void 0 : _n.data);
};
