var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./edit-social-feed-counter-content.sass";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import EditInputDropdown from "../../../edit-input-dropdown/edit-input-dropdown";
import { Timer } from "../../../../../../../components/timer/timer";
import { Button } from "../../../../../../../components/interface/button/button";
import EditInput from "../../../edit-input/edit-input";
import PropTypes from "prop-types";
const dropdownFieldsLimit = 3;
const dropdownSocialFeedPlaceholder = "Select Social Feed";
const dropdownTintSlugPlaceholder = "Select TINT";
export class EditSocialFeedCounterContent extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "mapDropdownItem", () => {
      return this.props.data.content.slugList.map((item) => {
        return {
          value: item.slugId,
          slugId: item.slugId,
          socialFeedId: item.socialFeedId,
          name: item.slugId,
          socialFeeds: this.isSelectDefault(item.slugId) ? this.getSocialFeedList(item.slugId) : []
        };
      });
    });
    __publicField(this, "isSelectDefault", (value) => {
      return value !== void 0 && value !== dropdownSocialFeedPlaceholder && value !== dropdownTintSlugPlaceholder;
    });
    __publicField(this, "getSelectedItem", (value, index) => {
      const currentItem = this.state.customList.find((item, i) => i === index);
      return __spreadValues(__spreadValues({}, currentItem), {
        value,
        slugId: value,
        socialFeedId: void 0,
        socialFeeds: this.isSelectDefault(value) ? this.getSocialFeedList(value) : []
      });
    });
    __publicField(this, "mapEmptyFieldsObject", () => {
      return {
        value: void 0,
        name: void 0,
        slugId: void 0,
        socialFeedId: void 0,
        socialFeeds: []
      };
    });
    __publicField(this, "updateData", () => {
      const mapToUpdate = this.state.customList.map((item) => {
        return {
          slugId: item.value,
          socialFeedId: item.socialFeedId
        };
      });
      this.props.dataUpdate({ slugList: mapToUpdate });
    });
    __publicField(this, "getSocialFeedList", (slugId) => {
      return this.props.tintSlugOptions.find((tint) => tint.value === slugId).socialFeeds;
    });
    __publicField(this, "onChangeStartDate", (date) => {
      const currentDate = new Date(date.currentDate);
      const formatDate = new Date(currentDate).toJSON();
      return this.props.dataUpdate({ startDate: formatDate });
    });
    __publicField(this, "onChangeEndDate", (date) => {
      const currentDate = new Date(date.currentDate);
      const formatDate = new Date(currentDate).toJSON();
      return this.props.dataUpdate({ endDate: formatDate });
    });
    __publicField(this, "addAnotherFeed", () => {
      this.setState({
        dropdownFieldsLength: this.state.customList.length + 1,
        customList: [...this.state.customList, this.mapEmptyFieldsObject()]
      });
    });
    __publicField(this, "removeFeed", (index) => {
      this.setState(
        {
          dropdownFieldsLength: this.state.customList.length - 1,
          customList: [...this.state.customList.slice(0, index), ...this.state.customList.slice(index + 1)]
        },
        () => {
          this.updateData();
        }
      );
    });
    __publicField(this, "renderDropdownList", () => {
      return this.state.customList.length > 0 && this.state.customList.map((el, i) => /* @__PURE__ */ React.createElement(
        "div",
        {
          "data-testid": "socialFeedDropdownGroup",
          className: "edit-social-feed-counter-content__select-section",
          key: `tint-slug-${i}`
        },
        /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
          EditInputDropdown,
          {
            id: `tintSlugDropdown_${i}`,
            value: el.slugId,
            isControlled: true,
            placeholder: dropdownTintSlugPlaceholder,
            onChange: (e) => {
              this.onTintSlugChange(e.target.value, i);
            },
            options: this.props.tintSlugOptions,
            label: dropdownTintSlugPlaceholder
          }
        ), /* @__PURE__ */ React.createElement(
          EditInputDropdown,
          {
            id: `socialFeedDropdown_${i}`,
            value: el.socialFeedId,
            isControlled: true,
            placeholder: dropdownSocialFeedPlaceholder,
            onChange: (e) => {
              this.onTintSocialFeedChange(e.target.value, i);
            },
            options: el.socialFeeds,
            label: "Select Social Feed"
          }
        )),
        /* @__PURE__ */ React.createElement(
          "span",
          {
            "data-testid": "socialFeedDropdownRemoveButton",
            className: "fas fa-trash",
            onClick: () => this.removeFeed(i)
          }
        )
      ));
    });
    this.state = {
      customList: this.mapDropdownItem(),
      dropdownFieldsLength: this.props.data.content.slugList.length,
      isCountingFinished: false
    };
  }
  onTintSocialFeedChange(socialFeedId, index) {
    const selectedItem = this.state.customList.find((item, i) => i === index);
    const currentItem = __spreadValues(__spreadValues({}, selectedItem), { socialFeedId });
    const currentList = [
      ...this.state.customList.slice(0, index),
      currentItem,
      ...this.state.customList.slice(index + 1)
    ];
    this.setState({ customList: currentList }, () => {
      if (socialFeedId === dropdownSocialFeedPlaceholder) return;
      this.updateData();
    });
  }
  onTintSlugChange(value, index) {
    const selectedItem = this.getSelectedItem(value, index);
    const currentList = [
      ...this.state.customList.slice(0, index),
      selectedItem,
      ...this.state.customList.slice(index + 1)
    ];
    this.setState(
      {
        customList: this.state.customList.length <= 1 ? [selectedItem] : currentList
      },
      () => {
        this.updateData();
      }
    );
  }
  render() {
    const inactiveClass = this.props.data.content.isCountingEnd || this.state.isCountingFinished ? "edit-social-feed-counter-content--inactive" : "";
    return /* @__PURE__ */ React.createElement(EditModalBody, null, /* @__PURE__ */ React.createElement("div", { "data-testid": "editSocialFeedCounter", className: `edit-social-feed-counter-content ${inactiveClass}` }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group" }, this.renderDropdownList(), this.state.dropdownFieldsLength < dropdownFieldsLimit ? /* @__PURE__ */ React.createElement(
      Button,
      {
        id: "addAnotherFeedButton",
        onClick: this.addAnotherFeed,
        type: "upload",
        size: "full-width",
        text: "+ Add Another Feed"
      }
    ) : null, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__description" }, "Limited to 3 Social Feeds")), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group", "data-testid": "socialFeedLabelText" }, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        onChange: (e) => {
          this.props.onTranslationUpdate(this.props.data.content.counterLabelText, e.target.value);
        },
        label: "Label text",
        type: "text",
        placeholder: "Posts & Counting",
        defaultValue: this.props.getTranslation(this.props.data.content.counterLabelText)
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group" }, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__header" }, "COUNTER START DATE"), /* @__PURE__ */ React.createElement(Timer, { dataUpdate: this.onChangeStartDate, currentDate: this.props.data.content.startDate })), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group" }, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__header" }, "COUNTER END DATE"), /* @__PURE__ */ React.createElement(Timer, { dataUpdate: this.onChangeEndDate, currentDate: this.props.data.content.endDate })), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal-body__group" }, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__header" }, "END DATE"), /* @__PURE__ */ React.createElement(
      Button,
      {
        id: "stopCounterButton",
        onClick: () => {
          this.setState({ isCountingFinished: true });
          this.props.dataUpdate({ count: this.props.count });
          this.props.onStopCounter();
        },
        type: "upload",
        size: "full-width",
        text: "Stop Counter"
      }
    ), /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__description" }, "Please note: you cannot undo this action. When you stop the counter, the total count will be finalized.")), this.props.data.content.isCountingEnd ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { className: "tint-edit-modal-body__description" }, "Counting is finished"), this.state.isCountingFinished ? /* @__PURE__ */ React.createElement("p", { className: "confirm-alert" }, "Click Apply for accept") : null) : null));
  }
}
EditSocialFeedCounterContent.propTypes = {
  count: PropTypes.number,
  tintSlugOptions: PropTypes.array,
  data: PropTypes.shape({
    content: PropTypes.shape({
      slugList: PropTypes.array,
      counterLabelText: PropTypes.string,
      startDate: PropTypes.any,
      endDate: PropTypes.any,
      isCountingEnd: PropTypes.bool
    })
  }),
  onChangeData: PropTypes.func,
  dataUpdate: PropTypes.func,
  onStopCounter: PropTypes.func,
  getTranslation: PropTypes.func,
  onTranslationUpdate: PropTypes.func
};
