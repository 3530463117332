var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./uploader-drop-area.sass";
import { useDrop } from "react-dnd";
import { Typography, TypographyColors } from "../../interface/typography/typography";
import { Button } from "../../interface/button/button";
import InputFile from "../../interface/inputs/input-file/input-file";
import { faPhotoFilm, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
export const UploaderDropArea = ({ onDrop, style, onUGCClick, onUpload, acceptOnlyImages }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ["__NATIVE_FILE__"],
    drop: (item) => {
      if (onDrop) {
        if (acceptOnlyImages && item.files.some((file) => {
          var _a;
          return (_a = file == null ? void 0 : file.type) == null ? void 0 : _a.includes("video");
        })) {
          toast.error("Only image files are supported");
        } else {
          onDrop(item.files);
        }
      }
      return { name: "=" };
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
    }
  });
  const isActive = canDrop && isOver;
  return /* @__PURE__ */ React.createElement("div", { className: `drag-drop-area ${isActive ? "drag-drop-area--release" : ""}`, ref: drop, style: __spreadValues({}, style) }, onUGCClick && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, { icon: faPhotoFilm, text: "Select from UGC Studio", type: "info", onClick: onUGCClick }), /* @__PURE__ */ React.createElement(Typography, { className: "button-separator", color: TypographyColors.SECONDARY }, "\xA0or\xA0")), /* @__PURE__ */ React.createElement(
    InputFile,
    {
      isCropped: false,
      accept: ["image/*", "video/*"],
      maxSize: "maxVideoSizePlanLimit",
      handleChange: (url) => onUpload(url.target.files)
    },
    /* @__PURE__ */ React.createElement(Button, { type: "info", icon: faUpload, htmlFor: "file" }, "Upload Media")
  ));
};
