import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { SocialFeedsFooterLabels, flickrDisabledButton } from "./helpers";
import {
  validationSchemaFlickrAccount,
  validationSchemaFlickrHashtag,
  validationSchemaFlickrBrandContent
} from "../utils/validation-schema/flickr-validation";
export const flickr = [
  {
    header: "Add Flickr",
    description: "Choose a Social Feed type below:",
    typeList: [
      {
        id: "posted",
        title: "Brand Content",
        description: "Import content from your authorized Flickr account."
      },
      {
        id: "user",
        title: "Username",
        description: "Import content from a Flickr username."
      },
      {
        id: "hashtag",
        title: "Tag",
        description: "Import content from a Flickr tag."
      }
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext
    },
    zendeskUrl: void 0,
    isAlert: false,
    formSchema: void 0
  },
  {
    header: void 0,
    description: void 0,
    zendeskUrl: void 0,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    accountSourceType: "flickr",
    formSchema: {
      posted: {
        id: "posted",
        title: "Brand Content",
        submitMapper: (values) => ({
          account_id: values.account.value,
          type: "posted"
        }),
        validationSchema: validationSchemaFlickrBrandContent(),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          }
        ]
      },
      user: {
        id: "user",
        title: "Username",
        submitMapper: (values) => ({
          search_term: values.user,
          account_id: values.account.value,
          type: "user"
        }),
        validationSchema: validationSchemaFlickrAccount(),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "user",
            backendId: "search_term",
            label: "ENTER USERNAME",
            placeholder: "Username",
            description: "Enter a Flickr username to ingest content.",
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
            disabled: flickrDisabledButton
          }
        ]
      },
      hashtag: {
        id: "hashtag",
        title: "Tag",
        validationSchema: validationSchemaFlickrHashtag(),
        submitMapper: (values) => ({
          search_term: values.hashtag,
          account_id: values.account.value,
          type: "hashtag"
        }),
        inputs: [
          {
            id: "account",
            backendId: "account_id",
            label: "SELECT ACCOUNT",
            placeholder: "Select Account...",
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "hashtag",
            backendId: "search_term",
            label: "ENTER TAG",
            placeholder: "Tag",
            description: "Enter a Flickr tag to ingest content.",
            icon: SidebarIcons.HASHTAG,
            type: SidebarItemTypes.TEXT,
            disabled: flickrDisabledButton
          }
        ]
      }
    }
  }
];
