var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  closeEditModal,
  closeOrderModal,
  openOrderModal,
  openEditModal,
  openOrderModalForMultiColumn
} from "../../../actions/edit-modal";
export const ModalHoc = (PassedComponent) => {
  class Modal extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "onOrderCloseModal", () => {
        this.setState({ isOrderModalOpen: false });
        this.props.closeOrderModal();
      });
      __publicField(this, "onOrderOpenModal", () => {
        if (!this.state.isOrderModalOpen && this.props.isOrderModalOpen) {
          this.props.closeOrderModal();
        }
        setTimeout(this.props.openOrderModal.bind(this));
        this.setState({ isOrderModalOpen: true });
      });
      this.state = {
        stepIndex: 0,
        isModalOpen: false,
        isOrderModalOpen: false
      };
    }
    selectStep(stepIndex) {
      this.setState({ stepIndex });
    }
    nextStep() {
      this.setState({ stepIndex: this.state.stepIndex + 1 });
    }
    previousStep() {
      this.setState({ stepIndex: this.state.stepIndex - 1 });
    }
    onCloseModal() {
      this.setState({ isModalOpen: false, stepIndex: 0 });
      this.props.closeEditModal();
    }
    onDismissModal() {
      this.setState({ isModalOpen: false, stepIndex: 0 });
      this.props.closeEditModal();
    }
    componentWillUnmount() {
      if (this.state.isModalOpen) {
        this.props.closeEditModal();
      }
    }
    onOpenModal() {
      if (!this.state.isModalOpen && this.props.isEditModalOpened) {
        this.onCloseModal();
      }
      setTimeout(() => {
        this.props.openEditModal();
        this.setState({ isModalOpen: true });
      });
    }
    componentWillReceiveProps(nextProps) {
      if (!nextProps.isEditModalOpened && this.state.isModalOpen) {
        this.setState({ isModalOpen: false, stepIndex: 0 });
      }
      if (!nextProps.isOrderModalOpen && this.state.isOrderModalOpen) {
        this.setState({ isOrderModalOpen: false });
      }
    }
    render() {
      return /* @__PURE__ */ React.createElement(
        PassedComponent,
        __spreadProps(__spreadValues({}, this.props), {
          stepIndex: this.state.stepIndex,
          isModalOpen: this.state.isModalOpen,
          selectStep: this.selectStep.bind(this),
          nextStep: this.nextStep.bind(this),
          previousStep: this.previousStep.bind(this),
          closeModal: this.onCloseModal.bind(this),
          dismissModal: this.onDismissModal.bind(this),
          openModal: this.onOpenModal.bind(this),
          closeOrderModal: this.onOrderCloseModal,
          openOrderModal: this.onOrderOpenModal
        })
      );
    }
  }
  const mapStateToProps = (state) => ({
    isEditModalOpened: state.editModal.isOpened,
    isOrderModalOpen: state.editModal.isOrderModal
  });
  const mapDispatchToProps = (dispatch) => ({
    openEditModal: () => dispatch(openEditModal()),
    closeEditModal: () => dispatch(closeEditModal()),
    openOrderModal: () => dispatch(openOrderModal()),
    openOrderModalForMultiColumn: (multiColumnData) => dispatch(openOrderModalForMultiColumn(multiColumnData)),
    closeOrderModal: () => dispatch(closeOrderModal())
  });
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal);
};
