var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import "./tint-editor-settings-sidebar.sass";
import { Button } from "../../../../../components/interface/button/button";
import TintEditorSocialFeeds from "../social-feeds/tint-editor-social-feeds";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faBullhorn, faMagicWandSparkles } from "@fortawesome/pro-solid-svg-icons";
import SocialFeedCard from "../../social-feed-card/social-feed-card";
import TintEditorSettingsSidebarHeader from "../settings-sidebar-header/tint-editor-settings-sidebar-header";
import { Config } from "../../../../../utils/config";
import TintEditorSettingsSidebarFooter from "../settings-sidebar-footer/tint-editor-settings-sidebar-footer";
import TintSidebar from "../../../../../components/sidebar/sidebar";
import TintEditorModifyRulesSidebar from "../sidebars/modify-rules/tint-editor-modify-rules.sidebar";
import { useSelector, useDispatch } from "react-redux";
import { patchTintModerationRules } from "../../../actions/tint-editor.fat-actions";
import { GuardNames } from "../../../../../guards/guards-list";
import DisplaySettingsSwitcherSidebar from "../sidebars/display-settings/display-settings-switcher/display-settings-switcher";
import { selectFilters, sortPostsBy } from "../../../actions/posts.actions";
import { FeedQuery } from "../top-bar-navigation/tint-editor-top-navigation";
import TintModal from "../../../../../components/modal/modal";
import ModalHeader from "../../../../../components/modal/components/modal-header/modal-header";
import ModalFooter from "../../../../../components/modal/components/footers/modal-footer/modal-footer";
import { toggleCTASidebar } from "../../../actions/tint-editor.actions";
import { FilterGroupTypes } from "../top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal";
import { getSlugName } from "../../../reducers/tint-editor.reducer";
import { track } from "../../../../../services/functions/analytics-wrapper/analytics-wrapper";
import FeatureGuard from "../../../../../guards/feature-guard";
import { customPostSidebar } from "../sidebars/manage-custom-post/tint-editor-manage-custom-post.sidebar";
import { openSidebar } from "../../../../../actions/ui/ui.actions";
const TintEditorSettingsSidebar = ({ onToggleSidebar, isSettingsSidebarExpanded }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const tintEditor = useSelector((state) => state.tintEditor);
  const socialFeeds = useSelector((state) => {
    var _a2, _b2;
    return ((_b2 = (_a2 = state.socialFeeds) == null ? void 0 : _a2.tintEditor) == null ? void 0 : _b2.data) || [];
  });
  const dispatch = useDispatch();
  const [isSocialFeedLimitReachedModalVisible, setSocialFeedLimitReachedModalVisibility] = useState(false);
  const [autoModerationRules, setAutoModerationRules] = useState();
  const slugName = useSelector(getSlugName);
  const [isDisplaySidebarVisible, setDisplaySidebarVisibility] = useState(false);
  const isCTASidebarVisible = useSelector((state) => {
    var _a2, _b2;
    return (_b2 = (_a2 = state.tintEditor) == null ? void 0 : _a2.CTAModal) == null ? void 0 : _b2.isCTASidebarOpen;
  });
  const [isModifyRulesModalVisible, setModifyRulesModalVisibility] = useState(false);
  const [isModifyRulesModalDisabled, setModifyRulesModalDisabled] = useState(false);
  const onChangeAutoModeration = (data) => {
    setAutoModerationRules(data.values);
    setModifyRulesModalDisabled(!data.isValid);
  };
  const onModifyRulesSubmit = () => {
    dispatch(patchTintModerationRules(tintEditor.data.id, autoModerationRules)).then(() => {
      setModifyRulesModalVisibility(false);
    });
  };
  const onSocialFeedClick = ({ isModerated, id }) => {
    dispatch(selectFilters([id], FilterGroupTypes.SOCIAL_FEEDS));
    dispatch(sortPostsBy(!isModerated ? FeedQuery.PUBLIC : FeedQuery.PRIVATE, "status"));
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar__header" }, /* @__PURE__ */ React.createElement(
    TintEditorSettingsSidebarHeader,
    {
      onCollapse: () => onToggleSidebar(false),
      iconLink: Config.url.zenDesk.createTintAndAddContent,
      isSettingsSidebarExpanded
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar__socials" }, /* @__PURE__ */ React.createElement("p", { className: "tint-editor-settings-sidebar__label-small" }, "Pull in Social Feeds from a network."), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar__social-feeds" }, /* @__PURE__ */ React.createElement(TintEditorSocialFeeds, { onModalOpen: () => setSocialFeedLimitReachedModalVisibility(true) })), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar__actions" }, /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.CUSTOM_POSTS }, /* @__PURE__ */ React.createElement(
    Button,
    {
      text: "Custom Post",
      type: "new-gray",
      size: "full-width",
      icon: faPlus,
      onClick: () => {
        dispatch(openSidebar(customPostSidebar, { editMode: false }));
      }
    }
  )), /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.CTA }, /* @__PURE__ */ React.createElement(
    Button,
    {
      text: "Manage CTAs",
      type: "new-gray",
      size: "full-width",
      icon: faBullhorn,
      onClick: () => dispatch(toggleCTASidebar(!isCTASidebarVisible))
    }
  )), /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.MODIFY_RULES }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      text: ((_b = (_a = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _a.attributes) == null ? void 0 : _b.auto_moderation_rules.length) > 0 ? "Modify Rules" : "Add Rules",
      type: "new-gray",
      size: "full-width",
      icon: faMagicWandSparkles,
      onClick: () => setModifyRulesModalVisibility(true)
    }
  ), ((_d = (_c = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _c.attributes) == null ? void 0 : _d.auto_moderation_rules.length) > 0 && /* @__PURE__ */ React.createElement("p", null, "You have active rules that are filtering the content of your TINT Board.")))), socialFeeds.length > 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "tint-editor-settings-sidebar__label-uppercase" }, " FEEDS YOU\u2019VE ADDED"), /* @__PURE__ */ React.createElement("ul", { className: "tint-editor-settings-sidebar__content-feeds" }, socialFeeds.map((feed) => /* @__PURE__ */ React.createElement("li", { key: feed.id }, /* @__PURE__ */ React.createElement(SocialFeedCard, { feed, onSocialFeedClick }))))))), /* @__PURE__ */ React.createElement("div", { className: "tint-editor-settings-sidebar__footer" }, /* @__PURE__ */ React.createElement(
    TintEditorSettingsSidebarFooter,
    {
      onDisplay: () => {
        track("clickedDisplay");
        setDisplaySidebarVisibility(true);
      },
      isDisable: ((_f = (_e = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _e.attributes) == null ? void 0 : _f.private) || !(tintEditor == null ? void 0 : tintEditor.data)
    }
  )), isModifyRulesModalVisible && /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      title: "Auto-moderation Rules",
      isOpen: isModifyRulesModalVisible,
      onClose: () => setModifyRulesModalVisibility(false),
      defaultFooterStyle: false,
      rightButtonText: "Apply",
      isRightButtonDisabled: isModifyRulesModalDisabled,
      onRightButtonClick: onModifyRulesSubmit
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-modify-rules-sidebar__content" }, /* @__PURE__ */ React.createElement(
      TintEditorModifyRulesSidebar,
      {
        onChangeData: onChangeAutoModeration,
        data: (_h = (_g = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _g.attributes) == null ? void 0 : _h.auto_moderation_rules
      }
    ))
  ), isDisplaySidebarVisible && /* @__PURE__ */ React.createElement(
    DisplaySettingsSwitcherSidebar,
    {
      tintDetails: {
        tintId: (_i = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _i.id,
        defaultPersonalization: (_m = (_l = (_k = (_j = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _j.relationships) == null ? void 0 : _k.default_personalization) == null ? void 0 : _l.data) == null ? void 0 : _m.id,
        slugName
      },
      hideTintDropdown: true,
      hidePersonalizationDropdown: false,
      isVisible: isDisplaySidebarVisible,
      onClose: () => setDisplaySidebarVisibility(false)
    }
  )), isSocialFeedLimitReachedModalVisible && /* @__PURE__ */ React.createElement(
    TintModal,
    {
      headerTitle: "Limit Reached",
      modalHeader: (props) => /* @__PURE__ */ React.createElement(ModalHeader, __spreadValues({}, props)),
      modalFooter: (props) => /* @__PURE__ */ React.createElement(
        ModalFooter,
        __spreadProps(__spreadValues({}, props), {
          onConfirm: () => window.open(Config.url.contactUpgrade, "_blank"),
          confirmButtonText: "Contact Us"
        })
      ),
      isOpen: isSocialFeedLimitReachedModalVisible,
      onConfirm: () => setSocialFeedLimitReachedModalVisibility(false),
      onClose: () => setSocialFeedLimitReachedModalVisibility(false)
    },
    /* @__PURE__ */ React.createElement("p", { className: "secondary-text" }, "You have reached the maximum number of Social Feeds available on your current plan. To add more, please upgrade.")
  ));
};
export default TintEditorSettingsSidebar;
