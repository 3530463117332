import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { PRODUCT_ACCOUNTS } from "../../consts";
export const ACTIONS = {
  OPEN_MODAL: createPrefixedActionName(PRODUCT_ACCOUNTS, "OPEN_MODAL"),
  OPEN_EDIT_MODAL: createPrefixedActionName(PRODUCT_ACCOUNTS, "OPEN_EDIT_MODAL"),
  OPEN_REAUTHENTICATE_MODAL: createPrefixedActionName(PRODUCT_ACCOUNTS, "OPEN_REAUTHENTICATE_MODAL"),
  UPDATE_MODAL: createPrefixedActionName(PRODUCT_ACCOUNTS, "UPDATE_MODAL"),
  CLOSE_MODAL: createPrefixedActionName(PRODUCT_ACCOUNTS, "CLOSE_MODAL")
};
export const openEditModal = (data) => ({
  type: ACTIONS.OPEN_EDIT_MODAL,
  payload: data
});
export const openReauthenticateModal = (data) => ({
  type: ACTIONS.OPEN_REAUTHENTICATE_MODAL,
  payload: data
});
export const openModal = () => ({
  type: ACTIONS.OPEN_MODAL
});
export const updateModal = (data) => ({
  type: ACTIONS.UPDATE_MODAL,
  payload: data
});
export const closeModal = () => ({
  type: ACTIONS.CLOSE_MODAL
});
