var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { insightsContent } from "./insights-content.reducer";
import { insightsPerformance } from "./insights-performance.reducer";
import { Config } from "../../../utils/config";
import { ACTIONS } from "../actions/content/insights-content.actions";
import { produce } from "immer";
import { insightsCommerce } from "./insights-commerce.reducer";
import { insightsSocial } from "./insight-social.reducer";
export const sevenDaysPastDate = (/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() - 7);
export const dateDropdownPlaceholder = `${Config.monthList[new Date(sevenDaysPastDate).getMonth()]} ${new Date(
  sevenDaysPastDate
).getDate()}, ${new Date(sevenDaysPastDate).getFullYear()} - ${Config.monthList[(/* @__PURE__ */ new Date()).getMonth()]} ${(/* @__PURE__ */ new Date()).getDate()}, ${(/* @__PURE__ */ new Date()).getFullYear()}`;
const initialState = {
  filter: {
    tintId: void 0,
    personalizationId: void 0,
    locationId: void 0,
    date: {
      endDate: /* @__PURE__ */ new Date(),
      name: dateDropdownPlaceholder,
      startDate: sevenDaysPastDate
    },
    timeframe: {
      name: "Last 14 days",
      value: "last_14_days"
    }
  },
  download: false,
  insightsType: void 0
};
const insights = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DOWNLOAD:
      return __spreadProps(__spreadValues({}, state), { download: action.payload.download, insightsType: action.payload.insightsType });
    case ACTIONS.SET_INSIGHT_FILTER:
      return produce(state, (draftState) => {
        draftState.filter = __spreadValues(__spreadValues({}, draftState.filter), action.payload);
      });
    default:
      return state;
  }
};
export const insightsReducers = {
  insights,
  insightsContent,
  insightsPerformance,
  insightsCommerce,
  insightsSocial
};
