import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const address = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS]: {
    padding: 0,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS_LABEL]: {
    "margin-bottom": "10px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS_LEGEND]: {
    "margin-bottom": "16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS_DIV]: {
    width: "100%",
    "margin-bottom": "24px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS_SELECT]: {
    padding: "8px 36px 8px 8px",
    "background-position": "calc(100% - 8px)"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS_SELECT]: {
    padding: "8px 36px 8px 8px",
    "background-position": "calc(100% - 8px)"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ADDRESS_RTL]: {
    padding: "8px 8px 8px 36px",
    "background-position": "8px 50%"
  }
};
