var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import "./form-builder.sass";
import { ApiService } from "../../services/api-service/api-service";
import { handleApiError } from "../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import { FormBuilderForm } from "../../feature/form-builder/components/form/form-builder.form";
import { Config } from "../../utils/config";
const FormBuilder = ({ id, language = Config.language.defaultLanguage, teamId }) => {
  const [data, setData] = useState();
  useEffect(() => {
    ApiService.pureInstance().get(`/v2/teams/${teamId}/forms/${id}?include=media`).then(
      (res) => {
        var _a, _b;
        return setData(__spreadProps(__spreadValues({}, (_b = (_a = res == null ? void 0 : res.data) == null ? void 0 : _a.data) == null ? void 0 : _b.attributes), {
          teamId,
          language,
          formId: id,
          apiUrl: Config.getApiUrl()
        }));
      }
    ).catch((err) => handleApiError(err, toast.error));
  }, [id, teamId]);
  return /* @__PURE__ */ React.createElement("div", { className: "post-modal-form-builder" }, data && /* @__PURE__ */ React.createElement(FormBuilderForm, { injectedData: data, language }));
};
export default FormBuilder;
