import React, { useEffect, useState } from "react";
import "./tint-editor-edit-tags.sidebar.sass";
import TintSidebarSwitcherRemoveModalConfirm from "../../../../../../components/sidebar-switcher/components/remove-modal-confirm/sidebar-switcher-remove-modal-confirm";
import SearchInput, { SearchInputType } from "../../../../../../components/search-input/search-input";
import AddTags, { TagsPosition } from "../../../../../../components/interface/add-tags/add-tags";
import { toast } from "react-toastify";
export const ManageCTASidebarType = {
  MAIN: "main",
  EDIT: "edit",
  CREATE: "create"
};
const TintEditorEditTagsSidebar = ({ list, onChange }) => {
  const [tagList, setTagList] = useState(list);
  const [filteredList, setFilteredList] = useState(list);
  const [inputValue, setInputValue] = useState("");
  const [deletingTag, setDeletingTag] = useState();
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  useEffect(() => {
    if (JSON.stringify(list) !== JSON.stringify(tagList)) {
      onChange == null ? void 0 : onChange(tagList);
    }
  }, [tagList, list]);
  const onRemoveClick = (tagList2, index) => {
    setDeletingTag(tagList2[index]);
    setDisplayConfirmModal(true);
  };
  const onDeleteTag = () => {
    setTagList((s) => s.filter((el) => el !== deletingTag));
    setFilteredList((s) => s.filter((el) => el !== deletingTag));
    setDisplayConfirmModal(false);
    setDeletingTag(void 0);
  };
  const onAddTag = (newTagList, tag) => {
    if (tagList.includes(tag)) {
      toast.error(`${tag} is already exist`);
      return;
    }
    setTagList((s) => [...s, tag]);
    if (inputValue.length > 0) {
      if (inputValue.includes(tag)) {
        setFilteredList((s) => [...s, tag]);
      }
    } else {
      setFilteredList((s) => [...s, tag]);
    }
  };
  const onInputChange = (inputValue2) => {
    setInputValue(inputValue2);
    if (inputValue2) {
      setFilteredList([
        ...tagList.filter((tag) => {
          return tag.toLowerCase().includes(inputValue2.toLowerCase());
        })
      ]);
    } else {
      setFilteredList(tagList);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-edit-cta-sidebar" }, /* @__PURE__ */ React.createElement("div", { className: "tint-editor-edit-cta-sidebar__search tint-sidebar-group" }, /* @__PURE__ */ React.createElement(
    SearchInput,
    {
      onInputChange,
      inputValue,
      styles: { width: "100%", maxWidth: "100%" },
      types: [SearchInputType.SQUARE]
    }
  )), /* @__PURE__ */ React.createElement(
    AddTags,
    {
      tagList: filteredList,
      tagsPosition: TagsPosition.BOTTOM,
      onDeleteTag: onRemoveClick,
      shouldConfirmDelete: true,
      onAddTag,
      selectedTag: deletingTag
    }
  ), displayConfirmModal && /* @__PURE__ */ React.createElement(
    TintSidebarSwitcherRemoveModalConfirm,
    {
      onConfirm: onDeleteTag,
      onCancel: () => {
        setDisplayConfirmModal(false);
      },
      description: "This tag will be permanently removed from all your TINT\u2019s posts. Are you sure you still want to continue?",
      title: "Are you sure?",
      cancelButtonText: "No, Keep Tag",
      confirmButtonText: "Yes, Delete Tag"
    }
  ));
};
export default React.memo(TintEditorEditTagsSidebar);
