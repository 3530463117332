import React, { Component } from "react";
import "./social-section.sass";
import SocialContainer from "./social-container/social-container";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditSocialContent } from "./edit/edit-social-content";
import { EditSocialStyles } from "./edit/edit-social-styles";
class SocialSection extends Component {
  renderSocialContainers(socialObject, i, { iconBackgroundColor, iconColor }, translation) {
    return /* @__PURE__ */ React.createElement(
      SocialContainer,
      {
        key: i,
        type: socialObject.type,
        url: translation,
        backgroundColor: iconBackgroundColor,
        iconColor
      }
    );
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--social ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
          height: this.props.data.styles.sectionPadding ? "auto" : "200px"
        }
      },
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Social Links" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditSocialContent,
          {
            data: this.props.data.content,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            getTranslation: this.props.getTranslation,
            onTranslationUpdate: this.props.onTranslationUpdate,
            onNewTranslationUpdate: this.props.onNewTranslationUpdate,
            experienceType: this.props.experienceType
          }
        ), /* @__PURE__ */ React.createElement(
          EditSocialStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      ),
      this.props.renderGenericComponents(),
      /* @__PURE__ */ React.createElement("div", { className: "tint-page-section__social-media" }, this.props.data.content.socialLinks.map((socialLink, i) => {
        return this.renderSocialContainers(
          socialLink,
          i,
          this.props.data.styles,
          this.props.getTranslation(socialLink.url)
        );
      }))
    );
  }
}
export default PageSectionHoc(SocialSection);
