var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useRef, useState } from "react";
import "./tint-editor.container.sass";
import TintEditorSettingsSidebar from "../../components/tint-editor/settings-sidebar/tint-editor-settings-sidebar";
import TintEditorTopBar from "../../components/tint-editor/top-bar/tint-editor-top-bar";
import PaginationBar from "../../components/pagination-bar/pagination-bar";
import { useLocation, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getCTAS, getTint } from "../../actions/tint-editor.fat-actions";
import TintIcon from "../../../../components/interface/icon/icon";
import { getGlobalPosts, getPosts, getPostsAggregations, getPostsFromURL } from "../../actions/posts.fat-actions";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import ECommerceContainer from "../../../ecommerce/containers/ecommerce/ecommerce.container";
import SocialFeedsSidebar from "../../components/tint-editor/sidebars/social-feed-sidebar/social-feed-sidebar";
import { clearPostState, sortPostsBy } from "../../actions/posts.actions";
import { FeedQuery } from "../../components/tint-editor/top-bar-navigation/tint-editor-top-navigation";
import { PageInitService } from "../../../../services/page-init/page-init.service";
import {
  clearTintEditorState,
  closeEcommerceModal,
  closeVisualSearchModal,
  toggleCTASidebar
} from "../../actions/tint-editor.actions";
import TintEditorModal from "../../components/tint-editor/modal/tint-editor-modal";
import TintEditorManageCtaSidebarWrapper from "../../components/tint-editor/sidebars/mange-cta-sidebar-wrapper/tint-editor-manage-cta-sidebar-wrapper";
import { TintEditorConfig } from "../../utils/tint-editor.config";
import { toast } from "react-toastify";
import { getSocialConnections } from "../../components/tint-editor/sidebars/social-feed-sidebar/actions/social-feeds.fat-actions";
import { useQuery } from "../../../../components/hooks/use-query/use-query";
import TintEditorVisualSearchSidebar from "../../components/tint-editor/sidebars/visual-search/tint-editor-visual-search-sidebar";
import { UpgradeNote } from "../../components/upgrade-note/upgrade-note";
import session, { getPlan } from "../../../../reducers/session";
import { clearSocialFeedState } from "../../components/tint-editor/sidebars/social-feed-sidebar/actions/social-feeds.actions";
import useSavedFilters from "../../../../components/hooks/use-saved-filters/use-saved-filters";
import TintEditorManageCustomPostSidebar, {
  customPostSidebar
} from "../../components/tint-editor/sidebars/manage-custom-post/tint-editor-manage-custom-post.sidebar";
import { FeedList } from "../../components/tint-editor/feed-list/feed-list";
import { getSocialFeedLength } from "../../components/tint-editor/sidebars/social-feed-sidebar/reducers/social-feeds.selector";
import { Config } from "../../../../utils/config";
import { useCardLoadersCount } from "../../../../components/hooks/use-card-loaders-count/use-card-loaders-count";
import { PostRenderType } from "../../reducers/posts.reducer";
import { FeedListHorizontal } from "../../components/tint-editor/feed-list-horizontal/feed-list-horizontal";
import { isSidebarOpen, getSidebarData, isModalOpen } from "../../../../reducers/ui/ui.selector";
import { closeSidebar, closeModal, setMediaData } from "../../../../actions/ui/ui.actions";
import { socialFeedSidebar } from "../../components/social-feed-card/social-feed-card";
import { assetModalId, AssetsModal } from "../../../social-publishing/components/assets/assets-modal/assets-modal";
import { unselectAllAssets } from "../../../asset-manager/actions/asset-manager.actions";
import { getMappedPosts } from "../../selectors/tint-editor-posts.selector";
import { getSortKey } from "../../../../components/interface/dropdown/lists/sort-list-radio/dropdown-sort-list-radio-list";
export const tintEditorNavigationStorageKey = "tint-editor-navigation";
export const TintEditorViewState = {
  ALL: "all",
  PUBLIC: "public",
  PRIVATE: "private",
  TRASH: "trash"
};
const startNavbarAnimationHeight = 124;
const TintEditorContainer = () => {
  var _a, _b;
  const location = useLocation();
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const listScrollRef = useRef(null);
  const { tintId } = useParams();
  const isGlobalPageState = tintId === TintEditorConfig.routePaths.global.tintId;
  const plan = useSelector(getPlan);
  const [isTrialNoteVisible, setTrialNoteVisibility] = useState((_a = plan == null ? void 0 : plan.attributes) == null ? void 0 : _a.trial);
  const [isFetchingData, setFetchingData] = useState(!isGlobalPageState);
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.tintEditor.modal);
  const teamId = useSelector((state) => state.session.data["team_id"]);
  const session2 = useSelector((state) => state.session);
  const isNavigationVisible = localStorage.getItem(tintEditorNavigationStorageKey) ? JSON.parse(localStorage.getItem(tintEditorNavigationStorageKey)) : true;
  const [isSettingsSidebarExpanded, setIsSettingsSidebarExpanded] = useState(isNavigationVisible);
  const ecommercePost = useSelector((state) => state.tintEditor.modalEcommerce);
  const tintEditor = useSelector((state) => state == null ? void 0 : state.tintEditor);
  const posts = useSelector(getMappedPosts);
  const selectedPosts = useSelector((state) => state.tintEditorPosts.selectedPosts);
  const arePostsFetching = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.isFetching);
  const socialFeedLength = useSelector(getSocialFeedLength);
  const links = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.links);
  const pageSize = useSelector((state) => state.tintEditorPosts.pageSize || 20);
  const currentPageStart = useSelector((state) => state.tintEditorPosts.currentPageStart);
  const renderType = useSelector((state) => state.tintEditorPosts.renderType);
  const currentPageEnd = useSelector((state) => state.tintEditorPosts.currentPageEnd);
  const selectedFilters = useSelector((state) => state == null ? void 0 : state.tintEditorPosts.selectedFilters);
  const isLastPage = useSelector((state) => state.tintEditorPosts.isLastPage);
  const isSocialFeedSidebarOpen = useSelector((state) => isSidebarOpen(state, socialFeedSidebar));
  const isAssetPreviewModalVisible = useSelector((state) => isModalOpen(state, assetModalId));
  const isCustomPostSidebarOpen = useSelector((state) => isSidebarOpen(state, customPostSidebar));
  const sidebarData = useSelector((state) => getSidebarData(state, customPostSidebar));
  const [scrollingParameters, setScrollingParameters] = useState({
    previousScroll: 0,
    isScrollingDown: false
  });
  const isAnyFilterSelected = selectedFilters && Object.values(selectedFilters).some((el) => (el == null ? void 0 : el.length) > 0) || (selectedFilters == null ? void 0 : selectedFilters.published_at);
  const [isSelectedMenuVisible, setIsSelectedMenuVisible] = useState((selectedPosts == null ? void 0 : selectedPosts.length) > 0);
  const minCardWidthOptions = {
    desktop: 340,
    mobile: 260
  };
  const listRef = useRef();
  const loadersNumber = useCardLoadersCount({
    minCardWidthOptions,
    ref: listRef
  });
  const queryParams = useQuery();
  const tintEditorState = queryParams.get(TintEditorConfig.urlSearchParamState);
  const [isFilterModalOpen, setFilterModalVisibility] = useState(false);
  const onToggleSidebar = (isVisible) => {
    setIsSettingsSidebarExpanded(isVisible);
    localStorage.setItem(tintEditorNavigationStorageKey, isVisible);
  };
  const defineNavbarClass = () => {
    return scrollingParameters.isScrollingDown ? "tint-editor-navbar__background-filler--collapsed" : isAnyFilterSelected ? "tint-editor-navbar__background-filler--filter-selected" : "";
  };
  const defineFeedClass = () => {
    return isAnyFilterSelected ? isSelectedMenuVisible ? "tint-editor-container__feed--post-and-filter-selected" : "tint-editor-container__feed--filter-selected" : isSelectedMenuVisible ? "tint-editor-container__feed--post-selected" : "";
  };
  const getPostStatusState = () => {
    switch (tintEditorState) {
      case TintEditorViewState.PRIVATE:
        return FeedQuery.PRIVATE;
      case TintEditorViewState.PUBLIC:
        return FeedQuery.PUBLIC;
      case TintEditorViewState.TRASH:
        return FeedQuery.TRASH;
      case TintEditorViewState.ALL:
      default:
        return FeedQuery.ALL;
    }
  };
  const sortByFilters = useSelector((state) => {
    var _a2;
    return (_a2 = state == null ? void 0 : state.tintEditorPosts) == null ? void 0 : _a2.sortPostsBy;
  });
  const getSortByFilters = () => {
    var _a2;
    const sortBy = localStorage.getItem(getSortKey((_a2 = session2 == null ? void 0 : session2.data) == null ? void 0 : _a2.currentTeam));
    return __spreadProps(__spreadValues({}, sortByFilters), { sortBy });
  };
  useEffect(() => {
    const postsRequest = { teamId, pageSize, sortBy: getSortByFilters(), filters: selectedFilters };
    postsRequest.tintId = tintId;
    dispatch(getPostsAggregations(postsRequest));
  }, []);
  useEffect(() => {
    if (socialFeedLength !== 0) {
      getTintEditorPosts();
    }
  }, [dispatch, teamId, pageSize, isGlobalPageState, sortByFilters, selectedFilters, socialFeedLength, renderType]);
  const getTintEditorPosts = () => {
    var _a2;
    const postsRequest = { teamId, pageSize, sortBy: getSortByFilters(), filters: selectedFilters, renderType };
    if (!(sortByFilters == null ? void 0 : sortByFilters.status)) {
      dispatch(sortPostsBy(getPostStatusState(tintEditorState), "status"));
      return;
    }
    if (((_a2 = getSortByFilters()) == null ? void 0 : _a2.status) && isGlobalPageState) {
      dispatch(getGlobalPosts(postsRequest)).catch(() => toast.error("Cannot get posts, try again later"));
    } else {
      postsRequest.tintId = tintId;
      dispatch(getPosts(postsRequest)).catch(() => {
        toast.error("Cannot get posts, try again later");
      });
    }
  };
  !isGlobalPageState && useSavedFilters({ tintId });
  useEffect(() => {
    if (!isGlobalPageState) {
      const requests = [
        dispatch(getTint(tintId)),
        dispatch(getCTAS(tintId)),
        dispatch(getSocialConnections({ url: `/social_feeds?include=tint&filter[tint_id]=${tintId}&page[size]=500` }))
      ];
      Promise.all(requests).finally(() => setFetchingData(false));
    }
    return () => {
      dispatch(clearPostState());
      dispatch(clearTintEditorState());
      dispatch(clearSocialFeedState());
      dispatch(closeSidebar(socialFeedSidebar));
      dispatch(closeSidebar(customPostSidebar));
    };
  }, [dispatch, tintId]);
  useEffect(() => {
    var _a2, _b2;
    if (tintEditor.data) {
      const pageTitleHeader = isGlobalPageState ? TintEditorConfig.routePaths.global.title : (_b2 = (_a2 = tintEditor == null ? void 0 : tintEditor.data) == null ? void 0 : _a2.attributes) == null ? void 0 : _b2.name;
      PageInitService.configure({
        pageTitle: pageTitleHeader,
        pageHeader: pageTitleHeader
      });
    }
  }, [tintEditor.data]);
  useEffect(() => {
    if (location.pathname.includes(Config.routePaths.globalModeration.plainPath)) {
      PageInitService.configure({
        pageTitle: Config.routePaths.globalModeration.title,
        pageHeader: Config.routePaths.globalModeration.title
      });
    }
    return () => {
      dispatch(sortPostsBy(void 0, "status"));
    };
  }, []);
  const onScrollHandler = (e) => {
    const currentScrollTop = e.currentTarget.scrollTop;
    if (e.currentTarget.scrollTop > startNavbarAnimationHeight) {
      setScrollingParameters(
        currentScrollTop <= scrollingParameters.previousScroll ? { previousScroll: currentScrollTop, isScrollingDown: false } : { previousScroll: currentScrollTop, isScrollingDown: true }
      );
    } else {
      e.currentTarget.scrollTop < startNavbarAnimationHeight && scrollingParameters.isScrollingDown && setScrollingParameters({ previousScroll: currentScrollTop, isScrollingDown: false });
    }
  };
  const onCloseAssetModal = (id) => {
    dispatch(unselectAllAssets());
    dispatch(closeModal(id));
  };
  const onUGCAssetConfirm = (data) => {
    const mappedAssets = data == null ? void 0 : data.map((asset) => {
      var _a2, _b2, _c, _d, _e, _f, _g, _h, _i;
      return {
        id: (_c = (_b2 = (_a2 = asset == null ? void 0 : asset.relationships) == null ? void 0 : _a2.media) == null ? void 0 : _b2.data) == null ? void 0 : _c.id,
        type: (_d = asset.attributes) == null ? void 0 : _d.mime_type,
        preview: (_e = asset.attributes) == null ? void 0 : _e.thumbnail_url,
        poster: (_f = asset.attributes) == null ? void 0 : _f.thumbnail_url,
        size: (_g = asset.attributes) == null ? void 0 : _g.size,
        resolution: (_h = asset.attributes) == null ? void 0 : _h.dimensions,
        duration: (_i = asset.attributes) == null ? void 0 : _i.duration
      };
    });
    const currentMedia = (sidebarData == null ? void 0 : sidebarData.media) || [];
    dispatch(setMediaData({ id: customPostSidebar, data: [.../* @__PURE__ */ new Set([...currentMedia, ...mappedAssets])] }));
    onCloseAssetModal(assetModalId);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-editor-container", ref: containerRef }, !isGlobalPageState && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-editor-container__navigation ${!isGlobalPageState && isSettingsSidebarExpanded ? "tint-editor-container__navigation--expanded" : "tint-editor-container__navigation--collapsed"}`
    },
    /* @__PURE__ */ React.createElement(
      TintEditorSettingsSidebar,
      {
        isSettingsSidebarExpanded,
        onToggleSidebar
      }
    )
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tint-editor-container__content ${!isGlobalPageState && isSettingsSidebarExpanded ? "tint-editor-container__content--expanded" : "tint-editor-container__content--collapsed"} ${isFilterModalOpen ? "tint-editor-container__content--modal-open" : ""}`,
      id: "tint-editor-container-content",
      ref: contentRef
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-editor-navbar" }, /* @__PURE__ */ React.createElement("div", { className: `tint-editor-navbar__background-filler ${defineNavbarClass()} ` }), /* @__PURE__ */ React.createElement(
      TintEditorTopBar,
      {
        isGlobalPageState,
        onToggleClick: () => onToggleSidebar(true),
        modalDOMDestination: contentRef.current,
        onFilterModalOpen: (isOpen) => setFilterModalVisibility(isOpen),
        isScrollingDown: scrollingParameters.isScrollingDown,
        isAnyFilterSelected,
        isSettingsSidebarExpanded
      }
    ), /* @__PURE__ */ React.createElement(
      PaginationBar,
      {
        scrollRef: listScrollRef,
        getPosts: getTintEditorPosts,
        isScrollingDown: scrollingParameters.isScrollingDown,
        isAnyFilterSelected,
        isSelectedMenuVisible,
        setIsSelectedMenuVisible
      }
    )),
    isTrialNoteVisible && /* @__PURE__ */ React.createElement(UpgradeNote, { setTrialNoteVisibility }),
    /* @__PURE__ */ React.createElement("div", { className: `tint-editor-container__feed ${defineFeedClass()}`, onScroll: onScrollHandler, ref: listRef }, renderType === PostRenderType.GRID ? /* @__PURE__ */ React.createElement(
      FeedList,
      {
        posts,
        loadersNumber,
        listRef,
        isFetchingData,
        arePostsFetching,
        isGlobalPageState,
        isSettingsSidebarExpanded
      }
    ) : /* @__PURE__ */ React.createElement(
      FeedListHorizontal,
      {
        posts,
        getTintEditorPosts,
        loadersNumber,
        listRef,
        isFetchingData,
        arePostsFetching,
        isGlobalPageState,
        isSettingsSidebarExpanded
      }
    ), /* @__PURE__ */ React.createElement("div", { ref: listScrollRef, style: { position: "absolute", top: "-100px", left: 0 } })),
    (posts == null ? void 0 : posts.length) > 0 && /* @__PURE__ */ React.createElement("div", { className: "tint-editor-container__pagination-buttons-wrapper" }, /* @__PURE__ */ React.createElement(
      "button",
      {
        disabled: currentPageStart === 1,
        onClick: () => {
          listScrollRef.current.scrollIntoView();
          dispatch(getPostsFromURL(links == null ? void 0 : links.prev, currentPageStart - pageSize));
        },
        className: `tint-editor-container__pagination-button ${currentPageStart === 1 ? "tint-editor-container__pagination-button--disabled" : ""}`
      },
      /* @__PURE__ */ React.createElement(TintIcon, { icon: faChevronLeft, fontSize: "16px" }),
      /* @__PURE__ */ React.createElement("span", { className: "tint-editor-container__button-text" }, "Previous")
    ), /* @__PURE__ */ React.createElement(
      "button",
      {
        disabled: isLastPage,
        className: `tint-editor-container__pagination-button ${currentPageEnd < pageSize ? "tint-editor-container__pagination-button--disabled" : ""}`,
        onClick: () => {
          if (currentPageEnd < pageSize) {
            return;
          }
          listScrollRef.current.scrollIntoView();
          dispatch(getPostsFromURL(links == null ? void 0 : links.next, currentPageStart + pageSize));
        }
      },
      /* @__PURE__ */ React.createElement("span", { className: "tint-editor-container__button-text" }, "Next"),
      /* @__PURE__ */ React.createElement(TintIcon, { icon: faChevronRight, fontSize: "16px" })
    )),
    ecommercePost && /* @__PURE__ */ React.createElement(
      ECommerceContainer,
      {
        tintId,
        newEditorPost: ecommercePost,
        onClose: () => dispatch(closeEcommerceModal())
      }
    ),
    isSocialFeedSidebarOpen && /* @__PURE__ */ React.createElement(SocialFeedsSidebar, { tintId })
  ), modal && /* @__PURE__ */ React.createElement(TintEditorModal, { isOpen: modal }), ((_b = tintEditor == null ? void 0 : tintEditor.CTAModal) == null ? void 0 : _b.isCTASidebarOpen) && /* @__PURE__ */ React.createElement(
    TintEditorManageCtaSidebarWrapper,
    {
      tintId,
      CTAModalDetails: tintEditor == null ? void 0 : tintEditor.CTAModal,
      isVisible: tintEditor == null ? void 0 : tintEditor.CTAModal.isCTASidebarOpen,
      onClose: () => dispatch(toggleCTASidebar(!(tintEditor == null ? void 0 : tintEditor.CTAModal.isCTASidebarOpen)))
    }
  ), (tintEditor == null ? void 0 : tintEditor.modalVisualSearch) && /* @__PURE__ */ React.createElement(
    TintEditorVisualSearchSidebar,
    {
      isOpen: tintEditor.modalVisualSearch,
      onClose: () => dispatch(closeVisualSearchModal())
    }
  ), isCustomPostSidebarOpen && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    TintEditorManageCustomPostSidebar,
    {
      isVisible: !!isCustomPostSidebarOpen,
      onClose: () => dispatch(closeSidebar(customPostSidebar)),
      data: []
    }
  ), isAssetPreviewModalVisible && /* @__PURE__ */ React.createElement(
    AssetsModal,
    {
      isOpen: isAssetPreviewModalVisible,
      isAssetPreviewModalVisible,
      onClose: onCloseAssetModal,
      onSubmit: onUGCAssetConfirm,
      modalDOMDestination: containerRef == null ? void 0 : containerRef.current
    }
  )));
};
export default TintEditorContainer;
