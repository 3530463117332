var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ApiService } from "../../../../services/api-service/api-service";
import {
  getSocialConnectionsStart,
  getSocialConnectionsSuccess,
  getSocialConnectionsError,
  getSocialConnectionStart,
  getSocialConnectionSuccess,
  getSocialConnectionError,
  filterSocialAccountsStart,
  filterSocialAccountsSuccess,
  filterSocialAccountsError,
  deleteSocialConnectionStart,
  deleteSocialConnectionSuccess,
  deleteSocialConnectionError,
  postSocialConnectionStart,
  postSocialConnectionSuccess,
  postSocialConnectionError,
  getSubAccountsStart,
  getSubAccountsSuccess,
  getSubAccountsError,
  patchSocialFeedStart,
  patchSocialFeedSuccess,
  patchSocialFeedError,
  getSocialConnectionSubAccountsStart,
  getSocialConnectionSubAccountsSuccess,
  getSocialConnectionSubAccountsError,
  getSocialConnectionDropdownSubaccountsStart,
  getSocialConnectionDropdownSubaccountsFailure,
  getSocialConnectionDropdownSubaccountsSuccess
} from "./social-connections.actions";
export const getSocialConnections = ({ url = "/accounts?include=subaccounts" } = {}) => {
  return (dispatch) => {
    dispatch(getSocialConnectionsStart());
    return ApiService.get(url).then((res) => {
      dispatch(getSocialConnectionsSuccess(res.data));
      return res.data;
    }).catch((err) => {
      dispatch(getSocialConnectionsError(err));
      throw err;
    });
  };
};
export const getSocialConnection = ({ socialConnectionId }) => {
  return (dispatch) => {
    const url = `/accounts/${socialConnectionId}?include=social_feeds.tint,subaccounts`;
    dispatch(getSocialConnectionStart());
    return ApiService.get(url).then((res) => {
      dispatch(getSocialConnectionSuccess(res.data));
      return res.data;
    }).catch((err) => {
      dispatch(getSocialConnectionError(err));
      throw err;
    });
  };
};
export const getSocialConnectionSubAccounts = (socialConnectionId, filter) => {
  return (dispatch) => {
    const url = `/accounts/${socialConnectionId}/subaccounts${filter ? `?filter[type]=${filter}` : ""}`;
    dispatch(getSocialConnectionSubAccountsStart());
    return ApiService.get(url).then((res) => {
      dispatch(getSocialConnectionSubAccountsSuccess(res.data));
      return res.data;
    }).catch((err) => {
      dispatch(getSocialConnectionSubAccountsError(err));
      throw err;
    });
  };
};
export const getSocialConnectionSubaccountDrodpown = ({
  socialConnectionId,
  filter,
  query,
  socialConnectionUrl,
  isReFetch
}) => {
  return (dispatch) => {
    const url = `/accounts/${socialConnectionId}/subaccounts${filter ? `?filter[type]=${filter}` : ""}${query && filter ? `&filter[q]=${query}` : query ? `?filter[q]=${query}` : ""}`;
    dispatch(getSocialConnectionDropdownSubaccountsStart());
    return ApiService.get(socialConnectionUrl || url).then((res) => {
      dispatch(getSocialConnectionDropdownSubaccountsSuccess(res.data, socialConnectionId, isReFetch));
      return res.data;
    }).catch((err) => {
      dispatch(getSocialConnectionDropdownSubaccountsFailure(err));
      throw err;
    });
  };
};
export const getMediaComposerSocialConnectionSubAccounts = (socialConnectionId) => {
  return (dispatch) => {
    const url = `/accounts/${socialConnectionId}/subaccounts?filter[capability]=compose`;
    dispatch(getSocialConnectionSubAccountsStart());
    return ApiService.get(url).then((res) => {
      dispatch(getSocialConnectionSubAccountsSuccess(res.data));
      return res.data;
    }).catch((err) => {
      dispatch(getSocialConnectionSubAccountsError(err));
      throw err;
    });
  };
};
export const filterSocialConnections = ({ pageSize = 25, filters = [] } = {}) => {
  const filtersParam = filters.reduce((acc, curr) => {
    return `${acc}&filter[${curr.by}]=${curr.value}&`;
  }, "");
  return (dispatch) => {
    const url = `/accounts?page[size]=${pageSize}&${filtersParam}`;
    dispatch(filterSocialAccountsStart());
    return ApiService.get(url).then((res) => {
      dispatch(filterSocialAccountsSuccess(res.data.data));
    }).catch((err) => {
      dispatch(filterSocialAccountsError(err));
      throw err;
    });
  };
};
export const deleteSocialConnection = ({ socialConnectionId }) => {
  return (dispatch) => {
    const url = `/accounts/${socialConnectionId}`;
    dispatch(deleteSocialConnectionStart());
    return ApiService.delete(url).then(() => {
      dispatch(deleteSocialConnectionSuccess({ id: socialConnectionId }));
    }).catch((err) => {
      dispatch(deleteSocialConnectionError(err));
      throw err;
    });
  };
};
export const getSubAccounts = ({ accountId }) => {
  return (dispatch) => {
    const url = `/accounts/${accountId}/subaccounts?page[size]=500`;
    dispatch(getSubAccountsStart());
    return ApiService.get(url).then((res) => {
      dispatch(getSubAccountsSuccess(res.data));
      return res.data.data;
    }).catch((err) => {
      dispatch(getSubAccountsError(err));
      throw err;
    });
  };
};
export const patchSocialFeed = ({ socialFeeds = [], transferToId }) => {
  const data = socialFeeds.map((id) => ({
    id: Number(id),
    type: "social_feed",
    attributes: {
      account_id: Number(transferToId)
    }
  }));
  return (dispatch) => {
    const url = "/social_feeds";
    dispatch(patchSocialFeedStart());
    return ApiService.patch(
      url,
      { data },
      {
        headers: {
          "Content-Type": "application/vnd.api+json; ext=bulk"
        }
      }
    ).then(() => dispatch(patchSocialFeedSuccess())).catch((err) => {
      dispatch(patchSocialFeedError(err));
      throw err;
    });
  };
};
export const postSocialConnection = (values) => {
  const data = {
    type: "account",
    attributes: __spreadValues({}, values)
  };
  return (dispatch) => {
    dispatch(postSocialConnectionStart());
    return ApiService.post("/accounts", { data }).then((res) => {
      dispatch(postSocialConnectionSuccess(res.data.data));
      return res.data.data;
    }).catch((err) => {
      dispatch(postSocialConnectionError(err));
      throw err;
    });
  };
};
