import { Config } from "../../../utils/config";
import { TintEditorPaths } from "./tint-editor.paths";
export const TintEditorConfig = {
  routePaths: TintEditorPaths,
  tintIdQueryParam: "tintId",
  urlSearchParamState: "page",
  embedScript: `${Config.getEnvironmentVariable("BASE_URL")}dist/embedded.js`,
  formsEmbedScript: `${Config.getEnvironmentVariable("BASE_URL")}dist/tint.js`,
  defaultBoardName: "My first TINT Board",
  defaultSlugName: "my_first_tint_board",
  defaultPersonalizationName: "Untitled personalization"
};
