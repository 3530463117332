export const MuiSliderVariants = {
  coloredProgressSlider: "coloredProgressSlider",
  markedTrackSlider: "markedTrackSlider"
};
export const MuiSlider = (theme) => ({
  variants: [
    {
      props: { variant: "coloredProgressSlider" },
      style: {
        backgroundColor: theme.palette.grey.lightGray,
        color: theme.palette.info.main,
        padding: 0,
        ".MuiSlider-rail": {
          opacity: 0
        }
      }
    },
    {
      props: { variant: "markedTrackSlider" },
      style: {
        backgroundColor: theme.palette.grey.lightGray,
        color: theme.palette.info.main,
        padding: 0,
        ".MuiSlider-rail": {
          opacity: 0
        },
        ".MuiSlider-mark": {
          opacity: 1,
          "&::before": {
            position: "absolute",
            content: '""',
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: theme.palette.grey.lightGray,
            left: "-5px",
            top: "-5px",
            transition: "background 0.3s"
          }
        },
        ".MuiSlider-markActive": {
          "&::before": {
            background: theme.palette.info.main
          }
        },
        ".MuiSlider-thumb": {
          width: "16px",
          height: "16px"
        }
      }
    }
  ]
});
