var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./edit-modal.sass";
export class EditModal extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "transitionEnd", (e) => {
      if (e.propertyName !== "opacity" || this.state.animationType === "in") return;
      if (this.state.animationType === "out") {
        if (this.state.isClosed) {
          this.props.closeModal();
        } else {
          this.props.dismissModal && this.props.dismissModal();
        }
      }
    });
    __publicField(this, "dismissClick", () => {
      this.setState({ animationType: "out", isClosed: false });
    });
    __publicField(this, "closeClick", () => {
      this.setState({ animationType: "out", isClosed: true });
    });
    this.state = {
      animationType: null,
      isClosed: false
    };
    this.timer = null;
  }
  saveData() {
    this.props.saveData().then(() => {
      this.props.dismissModal && this.props.dismissModal();
    });
    this.setState({ animationType: "out" });
  }
  renderStep() {
    const childrenArray = React.Children.toArray(this.props.children);
    if (childrenArray[this.props.stepIndex] === void 0) {
      return React.cloneElement(childrenArray[0], {
        saveData: this.saveData.bind(this),
        closeModalMain: this.closeClick,
        dismissModal: this.dismissClick
      });
    }
    return React.cloneElement(childrenArray[this.props.stepIndex], {
      saveData: this.saveData.bind(this),
      closeModalMain: this.closeClick,
      dismissModal: this.dismissClick
    });
  }
  renderAnimationWrapper() {
    return /* @__PURE__ */ React.createElement("div", { className: `edit-modal edit-modal--move-${this.state.animationType}`, "data-test-id": "edit-modal" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `edit-modal__animation-wrapper edit-modal__animation-wrapper--move-${this.state.animationType}`,
        onTransitionEnd: this.transitionEnd
      },
      /* @__PURE__ */ React.createElement("div", { className: "tint-edit-modal" }, this.renderStep())
    ));
  }
  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ animationType: "in" }), 0);
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  componentDidUpdate(prevProps) {
    if (!this.props.isVisible && prevProps.isVisible) {
      this.setState({ animationType: "out" });
    }
  }
  render() {
    return ReactDOM.createPortal(this.renderAnimationWrapper(), document.querySelector("#tintup-edit-modal"));
  }
}
EditModal.propTypes = {
  isVisible: PropTypes.bool,
  dismissModal: PropTypes.func,
  saveData: PropTypes.func,
  closeModal: PropTypes.func,
  footer: PropTypes.object,
  id: PropTypes.string
};
