import React from "react";
import "./video-renderer.sass";
import { VideoRendererService } from "./video-renderer.service";
export const VideoRenderer = ({ mediaUrl, width, autoPlay, parentLocation }) => {
  const determineVideoSource = () => {
    if (VideoRendererService.isTwitch(mediaUrl)) {
      return /* @__PURE__ */ React.createElement("div", { style: { width: `${width}%` }, className: "video-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "fluid-width-video-wrapper" }, /* @__PURE__ */ React.createElement(
        "iframe",
        {
          src: VideoRendererService.getTwitchUrl(mediaUrl, autoPlay, parentLocation),
          frameBorder: "0",
          allowFullScreen: true,
          scrolling: "no"
        }
      )));
    }
    if (VideoRendererService.isTikTok(mediaUrl)) {
      return /* @__PURE__ */ React.createElement("div", { style: { width: `${width}%` }, className: "video-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "fluid-width-video-wrapper fluid-width-video-wrapper__tiktok" }, /* @__PURE__ */ React.createElement(
        "iframe",
        {
          src: VideoRendererService.getTikTokUrl(mediaUrl, autoPlay),
          frameBorder: "0",
          allowFullScreen: true,
          scrolling: "no",
          allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        }
      )));
    }
    if (VideoRendererService.isYouTube(mediaUrl)) {
      return /* @__PURE__ */ React.createElement("div", { style: { width: `${width}%` }, className: "video-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "fluid-width-video-wrapper" }, /* @__PURE__ */ React.createElement(
        "iframe",
        {
          src: VideoRendererService.getYouTubeUrl(mediaUrl, autoPlay),
          allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          allowFullScreen: true
        }
      )));
    }
    if (VideoRendererService.isFacebook(mediaUrl)) {
      return /* @__PURE__ */ React.createElement("div", { style: { width: `${width}%` }, className: "video-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "fluid-width-video-wrapper" }, /* @__PURE__ */ React.createElement(
        "iframe",
        {
          src: VideoRendererService.getFacebookUrl(mediaUrl, autoPlay),
          style: { border: "none", overflow: "hidden" },
          scrolling: "no",
          allowFullScreen: true
        }
      )));
    }
    if (VideoRendererService.isVimeo(mediaUrl)) {
      return /* @__PURE__ */ React.createElement("div", { style: { width: `${width}%` }, className: "video-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "fluid-width-video-wrapper" }, /* @__PURE__ */ React.createElement("iframe", { title: "vimeo-player", src: VideoRendererService.getVimeoUrl(mediaUrl, autoPlay), allowFullScreen: true })));
    }
    return /* @__PURE__ */ React.createElement(
      "span",
      {
        className: "tint-exb-video-container",
        style: {
          width: `${width}%`
        },
        key: mediaUrl,
        dangerouslySetInnerHTML: {
          __html: `
        <video
          controls
          class='tint-exb-video-container__video'
          muted
          ${autoPlay ? "autoPlay" : ""}
          playsinline
        >
         <source src=${mediaUrl}#t=0.1 />
        </video>
      `
        }
      }
    );
  };
  return determineVideoSource();
};
export default VideoRenderer;
