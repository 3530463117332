import React, { forwardRef } from "react";
import "./wysiwyg.sass";
import { commandIdList } from "./components/wysiwyg-navigation/wysiwyg-navigation-options";
import useSelectContentEditable from "./hooks/useSelectContentEditable";
import * as PropTypes from "prop-types";
import { Config } from "../../utils/config";
import Wysiwyg from "./wysiwyg";
import {
  addBackgroundToChild,
  copyParentStylesToChildren,
  setFontSizeOnFireFoxOnly,
  setChildrenFontSize
} from "./wysiwyg-container-DOM-utils";
const WysiwygContainer = ({
  id,
  contentValue,
  style,
  onBlur,
  onChange,
  onNavigationRender,
  textDirection,
  isNavStatic,
  onEditorInit,
  isPending,
  onCodeMode
}, ref) => {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const [saveSelection, getPrevSelection] = useSelectContentEditable();
  const onNavigationOptionChoose = (commandId, value, showUI) => {
    switch (commandId) {
      case commandIdList.FONT_SIZE:
        setFontSizeCssValue(value);
        break;
      case commandIdList.FONT_FAMILY:
        setFontNameCssValue(value);
        break;
      case commandIdList.LINK:
        setLink(value);
        break;
      case commandIdList.BACKGROUND_COLOR:
        setBackgroundColor(value, commandIdList.BACKGROUND_COLOR);
        break;
      case commandIdList.COLOR:
        setColor(value, commandIdList.COLOR);
        break;
      case commandIdList.TEXT_ALIGN_LEFT:
      case commandIdList.TEXT_ALIGN_RIGHT:
      case commandIdList.TEXT_ALIGN_CENTER:
        const align = commandId === "justifyCenter" ? "center" : commandId === "justifyLeft" ? "left" : "right";
        toggleTextAlign(align);
        break;
      default:
        addExecCommand(commandId, showUI, value);
        break;
    }
  };
  const toggleTextAlign = (alignType) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const content = range.cloneContents();
    const selectedDivs = Array.from(content.querySelectorAll("div"));
    if (selectedDivs.length === 0) {
      const newDiv = document.createElement("div");
      newDiv.style.textAlign = alignType;
      newDiv.appendChild(range.extractContents());
      range.insertNode(newDiv);
      const newRange = document.createRange();
      newRange.selectNodeContents(newDiv);
      newRange.collapse(false);
      selection.removeAllRanges();
      selection.addRange(newRange);
      return;
    }
    let parentContainer = range.commonAncestorContainer;
    if (parentContainer.nodeType === Node.TEXT_NODE) {
      parentContainer = parentContainer.parentElement;
    }
    if (parentContainer && parentContainer.classList.contains("tint-wysiwyg-editor")) {
      Array.from(parentContainer.querySelectorAll("div")).forEach((childDiv) => {
        childDiv.style.textAlign = alignType;
      });
    }
  };
  const addExecCommand = (commandId, showUI, value) => {
    document.execCommand("styleWithCSS", !isFirefox, null);
    document.execCommand(commandId, showUI, value);
    addBackgroundColorToChildrenElements();
    setAHtmlTagProperties();
    saveSelection();
  };
  const setFontNameCssValue = (value) => {
    addExecCommand(
      commandIdList.FONT_FAMILY,
      false,
      !isFirefox ? value.replace(Config.regExp.lettersOnlyPattern, "") : `'${value}'`
    );
    replaceUnsupportedSigns(value);
  };
  const replaceUnsupportedSigns = (value) => {
    const fontElements = ref.current.querySelectorAll("span");
    for (let i = 0; i < fontElements.length; ++i) {
      if (fontElements[i].style.fontFamily === value.replace(Config.regExp.lettersOnlyPattern, "")) {
        fontElements[i].style.fontFamily = `'${value}'`;
      }
    }
  };
  const setFontSizeCssValue = (value) => {
    addExecCommand(commandIdList.FONT_SIZE, false, `${value}px`);
    if (!isFirefox) {
      const spanElements = ref.current.querySelectorAll("span");
      setChildrenFontSize(spanElements, value);
      const aElements = ref.current.querySelectorAll("a");
      setChildrenFontSize(aElements, value);
    } else {
      setFontSizeOnFireFoxOnly(value, ref);
    }
  };
  const markTextAsLink = (urlLink) => {
    addExecCommand(commandIdList.LINK, false, urlLink);
    addExecCommand(commandIdList.UNDERLINE, false);
  };
  const markLinkAsText = () => {
    addExecCommand(commandIdList.UN_LINK, false, null);
    addExecCommand(commandIdList.UNDERLINE, false);
  };
  const setColor = (color, commandId) => {
    getPrevSelection();
    addExecCommand(commandId, false, color);
  };
  const setBackgroundColor = (color, commandId) => {
    setColor(color, commandId);
  };
  const addBackgroundColorToChildrenElements = () => {
    const spanElements = ref.current.querySelectorAll("span");
    const fontElements = ref.current.querySelectorAll("font");
    addBackgroundToChild(spanElements);
    addBackgroundToChild(fontElements);
  };
  const setLink = (urlLink) => {
    getPrevSelection();
    urlLink ? markTextAsLink(urlLink) : markLinkAsText();
  };
  const setAHtmlTagProperties = () => {
    const fontElements = ref.current.querySelectorAll("a");
    for (let j = 0; j < fontElements.length; j++) {
      if (fontElements[j].href) {
        fontElements[j].setAttribute("target", "_blank");
        fontElements[j].setAttribute("rel", "noopener noreferrer");
      }
    }
    copyParentStylesToChildren(fontElements);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-wysiwyg-container", "data-testid": "tint-wysiwyg-container" }, /* @__PURE__ */ React.createElement(
    Wysiwyg,
    {
      id,
      textDirection,
      style,
      onBlur,
      ref,
      saveSelection,
      getPrevSelection,
      contentValue,
      onContentChange: onChange,
      onNavigationRender,
      onNavigationOptionChoose,
      isNavStatic,
      onEditorInit,
      isPending,
      onCodeMode
    }
  ));
};
export default forwardRef(WysiwygContainer);
WysiwygContainer.propTypes = {
  contentValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addFontFamily: PropTypes.func,
  styles: PropTypes.any,
  onNavigationRender: PropTypes.func
};
