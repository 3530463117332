var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DropdownButton, { DropdownButtonType } from "../interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../interface/dropdown/lists/sort-list/dropdown-sort-list";
import { ButtonType } from "../interface/dropdown/button/dropdown-button";
import { useFormik } from "formik";
import "./hour-picker.sass";
export const HourPicker = ({ data, onChange, formikProps }) => {
  const [hours] = useState(renderOptions(23));
  const [mins] = useState(renderOptions(59));
  const formik = useFormik(__spreadValues({
    isInitialValid: false,
    initialValues: {
      mins: (data == null ? void 0 : data.mins) || (/* @__PURE__ */ new Date()).getMinutes(),
      hour: (data == null ? void 0 : data.hour) || (/* @__PURE__ */ new Date()).getHours()
    }
  }, formikProps));
  return /* @__PURE__ */ React.createElement("div", { className: "tint-hour-picker" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      list: hours,
      iconRight: "fa fa-caret-down",
      onChangeValue: (option) => {
        formik.setFieldValue("hour", option.value);
        onChange == null ? void 0 : onChange({ hour: option.value });
      },
      currentItem: hours.find((hour) => hour.value === formik.values.hour),
      dropdownType: DropdownButtonType.SINGLE_SELECT,
      buttonType: ButtonType.BUTTON_GRAY_BORDER
    }
  ), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      list: mins,
      iconRight: "fa fa-caret-down",
      onChangeValue: (option) => {
        formik.setFieldValue("mins", option.value);
        onChange == null ? void 0 : onChange({ mins: option.value });
      },
      currentItem: mins.find((hour) => hour.value === formik.values.mins),
      dropdownType: DropdownButtonType.SINGLE_SELECT,
      buttonType: ButtonType.BUTTON_GRAY_BORDER
    }
  ));
};
export default HourPicker;
const renderOptions = (number) => {
  const numbersArray = [];
  for (let i = 0; i <= number; i++) {
    numbersArray.push({ value: i, name: String(i).padStart(2, "0") });
  }
  return numbersArray;
};
