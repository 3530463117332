import { createPrefixedActionName, createRoutine } from "../../../../services/functions/redux-routines/redux-routines";
import { COLLABORATORS } from "../../consts";
export const ACTIONS = {
  POST_EXPORT_USERS: createRoutine(createPrefixedActionName(COLLABORATORS, "POST_EXPORT_USERS")),
  GET_EXPORT_USERS_STATUS: createRoutine(createPrefixedActionName(COLLABORATORS, "GET_EXPORT_USERS_STATUS")),
  GET_COLLABORATORS: createRoutine(createPrefixedActionName(COLLABORATORS, "GET_COLLABORATORS")),
  POST_COLLABORATOR: createRoutine(createPrefixedActionName(COLLABORATORS, "POST_COLLABORATOR")),
  PATCH_COLLABORATOR: createRoutine(createPrefixedActionName(COLLABORATORS, "PATCH_COLLABORATOR")),
  BULK_PATCH_COLLABORATORS: createRoutine(createPrefixedActionName(COLLABORATORS, "BULK_PATCH_COLLABORATORS")),
  DELETE_COLLABORATOR: createRoutine(createPrefixedActionName(COLLABORATORS, "DELETE_COLLABORATOR")),
  SELECT_COLLABORATOR: createPrefixedActionName(COLLABORATORS, "SELECT_COLLABORATOR"),
  UNSELECT_COLLABORATOR: createPrefixedActionName(COLLABORATORS, "UNSELECT_COLLABORATOR"),
  DESELECT_ALL_COLLABORATORS: createPrefixedActionName(COLLABORATORS, "DESELECT_ALL_COLLABORATORS"),
  BULK_DELETE_COLLABORATORS: createRoutine(createPrefixedActionName(COLLABORATORS, "BULK_DELETE_COLLABORATORS"))
};
export const getExportUsersStatusStart = () => ({
  type: ACTIONS.GET_EXPORT_USERS_STATUS.REQUEST
});
export const getExportUsersStatusSuccess = (data) => ({
  type: ACTIONS.GET_EXPORT_USERS_STATUS.SUCCESS,
  payload: data
});
export const getExportUsersStatusError = (err) => ({
  type: ACTIONS.GET_EXPORT_USERS_STATUS.FAILURE,
  payload: err
});
export const postExportUsersStart = () => ({
  type: ACTIONS.POST_EXPORT_USERS.REQUEST
});
export const postExportUsersSuccess = (data) => ({
  type: ACTIONS.POST_EXPORT_USERS.SUCCESS,
  payload: data
});
export const postExportUsersError = (err) => ({
  type: ACTIONS.POST_EXPORT_USERS.FAILURE,
  payload: err
});
export const getCollaboratorsStart = () => ({
  type: ACTIONS.GET_COLLABORATORS.REQUEST
});
export const getCollaboratorsSuccess = (data) => ({
  type: ACTIONS.GET_COLLABORATORS.SUCCESS,
  payload: data
});
export const getCollaboratorsError = (err) => ({
  type: ACTIONS.GET_COLLABORATORS.FAILURE,
  payload: err
});
export const postCollaboratorStart = () => ({
  type: ACTIONS.POST_COLLABORATOR.REQUEST
});
export const postCollaboratorSuccess = (data) => ({
  type: ACTIONS.POST_COLLABORATOR.SUCCESS,
  payload: data
});
export const postCollaboratorError = (err) => ({
  type: ACTIONS.POST_COLLABORATOR.FAILURE,
  payload: err
});
export const deleteCollaboratorStart = () => ({
  type: ACTIONS.DELETE_COLLABORATOR.REQUEST
});
export const deleteCollaboratorSuccess = (id) => ({
  type: ACTIONS.DELETE_COLLABORATOR.SUCCESS,
  payload: id
});
export const deleteCollaboratorError = (err) => ({
  type: ACTIONS.DELETE_COLLABORATOR.FAILURE,
  payload: err
});
export const patchCollaboratorStart = () => ({
  type: ACTIONS.PATCH_COLLABORATOR.REQUEST
});
export const patchCollaboratorSuccess = (data) => ({
  type: ACTIONS.PATCH_COLLABORATOR.SUCCESS,
  payload: data
});
export const patchCollaboratorError = (err) => ({
  type: ACTIONS.PATCH_COLLABORATOR.FAILURE,
  payload: err
});
export const bulkPatchCollaboratorsStart = () => ({
  type: ACTIONS.BULK_PATCH_COLLABORATORS.REQUEST
});
export const bulkPatchCollaboratorsSuccess = (data) => ({
  type: ACTIONS.BULK_PATCH_COLLABORATORS.SUCCESS,
  payload: data
});
export const bulkPatchCollaboratorsError = (err) => ({
  type: ACTIONS.BULK_PATCH_COLLABORATORS.FAILURE,
  payload: err
});
export const selectCollaborator = (data) => ({
  type: ACTIONS.SELECT_COLLABORATOR,
  payload: data
});
export const unselectCollaborator = (id) => ({
  type: ACTIONS.UNSELECT_COLLABORATOR,
  payload: id
});
export const deselectAllCollaborators = () => ({
  type: ACTIONS.DESELECT_ALL_COLLABORATORS
});
export const bulkDeleteCollaboratorStart = () => ({
  type: ACTIONS.BULK_DELETE_COLLABORATORS.REQUEST
});
export const bulkDeleteCollaboratorSuccess = (id) => ({
  type: ACTIONS.BULK_DELETE_COLLABORATORS.SUCCESS,
  payload: id
});
export const bulkDeleteCollaboratorError = (err) => ({
  type: ACTIONS.BULK_DELETE_COLLABORATORS.FAILURE,
  payload: err
});
