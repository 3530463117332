import { createPrefixedActionName, createRoutine } from "../../../services/functions/redux-routines/redux-routines";
export const ACTIONS = {
  POST_SCHEDULED_CONTENT: createRoutine(createPrefixedActionName("MEDIA_COMPOSER", "POST_SCHEDULED_CONTENT")),
  GET_ACCOUNTS: createRoutine(createPrefixedActionName("MEDIA_COMPOSER", "GET_ACCOUNTS")),
  GET_ASSET: createRoutine(createPrefixedActionName("MEDIA_COMPOSER", "GET_ASSET")),
  CLEAR_STATE: createPrefixedActionName("MEDIA_COMPOSER", "CLEAR_STATE"),
  UPDATE_FORM: createPrefixedActionName("MEDIA_COMPOSER", "UPDATE_FORM"),
  OPEN_ADD_NETWORK_MODAL: createPrefixedActionName("MEDIA_COMPOSER", "OPEN_ADD_NETWORK_MODAL"),
  CLOSE_ADD_NETWORK_MODAL: createPrefixedActionName("MEDIA_COMPOSER", "CLOSE_ADD_NETWORK_MODAL"),
  SET_ADD_NETWORK_MODAL_DATA: createPrefixedActionName("MEDIA_COMPOSER", "SET_ADD_NETWORK_MODAL_DATA"),
  SET_ADD_NETWORK_MODAL_DATA_OPTIONS: createPrefixedActionName("MEDIA_COMPOSER", "SET_ADD_NETWORK_MODAL_DATA_OPTIONS"),
  ADD_SELECTED_ACCOUNT: createPrefixedActionName("MEDIA_COMPOSER", "ADD_SELECTED_ACCOUNT"),
  REMOVE_SELECTED_ACCOUNT: createPrefixedActionName("MEDIA_COMPOSER", "REMOVE_SELECTED_ACCOUNT"),
  SET_SUBMIT_TYPE: createPrefixedActionName("MEDIA_COMPOSER", "SET_SUBMIT_TYPE"),
  REMOVE_PUBLISHED_AT_FORM_DATA: createPrefixedActionName("MEDIA_COMPOSER", "REMOVE_PUBLISHED_AT_FORM_DATA"),
  SET_DATA: createPrefixedActionName("MEDIA_COMPOSER", "SET_DATA")
};
export const setModalData = (data) => ({
  type: ACTIONS.SET_DATA,
  payload: data
});
export const setSubmitType = (data) => ({
  type: ACTIONS.SET_SUBMIT_TYPE,
  payload: data
});
export const addSelectedAccount = (data) => ({
  type: ACTIONS.ADD_SELECTED_ACCOUNT,
  payload: data
});
export const removeSelectedAccount = (data) => ({
  type: ACTIONS.REMOVE_SELECTED_ACCOUNT,
  payload: data
});
export const openAddNetworkModal = () => ({
  type: ACTIONS.OPEN_ADD_NETWORK_MODAL
});
export const closeAddNetworkModal = () => ({
  type: ACTIONS.CLOSE_ADD_NETWORK_MODAL
});
export const setAddNetworkModalData = (data) => ({
  type: ACTIONS.SET_ADD_NETWORK_MODAL_DATA,
  payload: data
});
export const setAddNetworkModalDataOptions = (data) => ({
  type: ACTIONS.SET_ADD_NETWORK_MODAL_DATA_OPTIONS,
  payload: data
});
export const postScheduledContentStart = () => ({
  type: ACTIONS.POST_SCHEDULED_CONTENT.REQUEST
});
export const postScheduledContentSuccess = (data) => ({
  type: ACTIONS.POST_SCHEDULED_CONTENT.SUCCESS,
  payload: data
});
export const postScheduledContentError = (err) => ({
  type: ACTIONS.POST_SCHEDULED_CONTENT.FAILURE,
  payload: err
});
export const getAccountsStart = () => ({
  type: ACTIONS.GET_ACCOUNTS.REQUEST
});
export const getAccountsSuccess = (data) => ({
  type: ACTIONS.GET_ACCOUNTS.SUCCESS,
  payload: data
});
export const getAccountsError = (err) => ({
  type: ACTIONS.GET_ACCOUNTS.FAILURE,
  payload: err
});
export const getAssetStart = () => ({
  type: ACTIONS.GET_ASSET.REQUEST
});
export const getAssetSuccess = (data) => ({
  type: ACTIONS.GET_ASSET.SUCCESS,
  payload: data
});
export const getAssetError = (err) => ({
  type: ACTIONS.GET_ASSET.FAILURE,
  payload: err
});
export const clearState = () => ({
  type: ACTIONS.CLEAR_STATE
});
export const updateFormData = ({ field, value }) => ({
  type: ACTIONS.UPDATE_FORM,
  payload: { field, value }
});
export const removePublishedAtFormData = () => ({
  type: ACTIONS.REMOVE_PUBLISHED_AT_FORM_DATA
});
