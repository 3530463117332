var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useMemo, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import "./api-dropdown.sass";
import { DropdownHeader } from "../interface/dropdown/button/header/dropdown-header";
import DropdownInfiniteList from "../interface/dropdown/lists/infinite-list/dropdown-infinite-list";
import DropdownButton, { ButtonType, DropdownButtonType } from "../interface/dropdown/button/dropdown-button";
import { dropdownStyles } from "../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/common/common";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInfiniteData, UISelectorKeys } from "../../reducers/ui/ui.selector";
import { clearDropdownData, setDropdownData } from "../../actions/ui/ui.actions";
import { colors } from "../../utils/variables/colors";
import { Typography, TypographyColors, TypographyVariants } from "../interface/typography/typography";
import { ApiService } from "../../services/api-service/api-service";
import { handleApiError } from "../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
const defaultFetchMapFn = (el) => {
  var _a, _b;
  return { name: ((_a = el == null ? void 0 : el.attributes) == null ? void 0 : _a.name) || ((_b = el == null ? void 0 : el.attributes) == null ? void 0 : _b.username), value: el == null ? void 0 : el.id };
};
const ApiDropdown = forwardRef(
  (_a, ref) => {
    var _b = _a, {
      label,
      placeholder = "Select Account",
      styles,
      fetchOnInit = true,
      dropdownId,
      fetchFnMapper = defaultFetchMapFn,
      filterDataFn,
      onChange,
      currentElement,
      additionalOption,
      unselectOption,
      fixedOption,
      baseUrl,
      fetchUrl
    } = _b, props = __objRest(_b, [
      "label",
      "placeholder",
      "styles",
      "fetchOnInit",
      "dropdownId",
      "fetchFnMapper",
      "filterDataFn",
      "onChange",
      "currentElement",
      "additionalOption",
      "unselectOption",
      "fixedOption",
      "baseUrl",
      "fetchUrl"
    ]);
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState();
    const [selectedElement, setSelectedElement] = useState();
    const { data, links, isAbleToLoad } = useSelector(
      (s) => getInfiniteData(UISelectorKeys.DROPDOWNS, s, dropdownId, fetchFnMapper, filterDataFn)
    );
    const fetchData = (url) => {
      setLoading(true);
      ApiService.get(fetchUrl == null ? void 0 : fetchUrl(url, inputValue)).then((res) => {
        var _a2, _b2;
        dispatch(
          setDropdownData({
            id: dropdownId,
            data: (_a2 = res == null ? void 0 : res.data) == null ? void 0 : _a2.data,
            query: inputValue || void 0,
            links: (_b2 = res == null ? void 0 : res.data) == null ? void 0 : _b2.links
          })
        );
      }).catch((err) => handleApiError(err, toast.error)).finally(() => {
        setLoading(false);
      });
    };
    useImperativeHandle(ref, () => ({
      refetch: () => {
        dispatch(clearDropdownData({ id: dropdownId }));
        fetchData();
      }
    }));
    useEffect(() => {
      if (!fetchOnInit) {
        return;
      }
      fetchData();
      if (currentElement && typeof currentElement === "string") {
        ApiService.get(`${baseUrl}/${currentElement}`).then((res) => {
          var _a2, _b2, _c, _d, _e;
          setSelectedElement({
            name: (_c = (_b2 = (_a2 = res == null ? void 0 : res.data) == null ? void 0 : _a2.data) == null ? void 0 : _b2.attributes) == null ? void 0 : _c.name,
            value: (_e = (_d = res == null ? void 0 : res.data) == null ? void 0 : _d.data) == null ? void 0 : _e.id
          });
        }).catch((err) => handleApiError(err, toast.error));
      }
      return () => {
        if (fetchOnInit) {
          dispatch(clearDropdownData({ id: dropdownId }));
        }
        dispatch(
          setDropdownData({
            id: dropdownId,
            data: [],
            query: "",
            links: void 0
          })
        );
      };
    }, [fetchOnInit, dispatch]);
    const onOpenDropdown = () => {
      if (fetchOnInit || data.length > 0) {
        return;
      }
      fetchData();
    };
    const currentItem = useMemo(() => {
      return selectedElement || data.find(
        (el) => typeof currentElement === "string" ? el.value === currentElement : el.value === (currentElement == null ? void 0 : currentElement.value)
      );
    }, [currentElement, selectedElement, data]);
    useEffect(() => {
      if (inputValue) {
        fetchData(void 0);
      } else if (inputValue === "") {
        fetchData();
      }
    }, [inputValue]);
    return /* @__PURE__ */ React.createElement("div", { className: "tint-api-dropdown" }, label && /* @__PURE__ */ React.createElement(
      Typography,
      {
        color: TypographyColors.PRIMARY,
        variant: TypographyVariants.LABEL,
        component: "label",
        className: "tint-api-dropdown__label"
      },
      label
    ), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        onOpen: onOpenDropdown,
        currentItem: currentItem || currentElement,
        styles: styles ? styles : dropdownStyles,
        dropdownHeader: (props2) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props2)),
        placeholder,
        dropdownType: DropdownButtonType.SEARCH_INPUT,
        dropdownList: (_props) => /* @__PURE__ */ React.createElement(
          DropdownInfiniteList,
          __spreadProps(__spreadValues({}, _props), {
            refreshSocialConnection: props == null ? void 0 : props.refreshSocialConnection,
            lastChildColor: additionalOption ? colors.info : void 0,
            onLoadData: () => fetchData(links == null ? void 0 : links.next),
            isAbleToLoad,
            isLoading,
            onInputChange: setInputValue,
            onChange: (e) => {
              if (e.value === (additionalOption == null ? void 0 : additionalOption.value) && additionalOption.link) {
                history.push(additionalOption == null ? void 0 : additionalOption.link);
              } else if (e.value === (additionalOption == null ? void 0 : additionalOption.value) && additionalOption.action) {
                additionalOption.action(additionalOption.value);
              } else {
                _props.onChange(e);
              }
            },
            fixedOption
          })
        ),
        list: [...data, ...unselectOption && currentItem ? [unselectOption] : [], additionalOption].filter(Boolean),
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_GRAY_BORDER,
        onChangeValue: onChange
      }
    ));
  }
);
ApiDropdown.displayName = "ApiDropdown";
export default ApiDropdown;
