import Header from "../../components/experience/page-sections/header/header-section";
import Banner from "../../components/experience/page-sections/banner/banner-section";
import CookieBanner from "../../components/experience/page-sections/cookie-banner/cookie-banner-section";
import Form from "../../components/experience/page-sections/form/form-section";
import Tint from "../../components/experience/page-sections/tint/tint-section";
import Social from "../../components/experience/page-sections/social/social-section";
import Footer from "../../components/experience/page-sections/footer/footer-section";
import Timer from "../../components/experience/page-sections/timer/timer-section";
import BackToTop from "../../components/experience/page-sections/back-to-top/back-to-top";
import Poll from "../../components/experience/page-sections/poll/poll-section";
import PostSection from "../../components/experience/page-sections/post/post-section";
import HeadlineSection from "../../components/experience/page-sections/custom/headline/headline-section";
import ImageSection from "../../components/experience/page-sections/custom/image/image-section";
import VideoSection from "../../components/experience/page-sections/custom/video/video-section";
import React from "react";
import { pageSectionType } from "../../../../model/page-section-type.model";
import ParagraphSection from "../../components/experience/page-sections/custom/paragraph/paragraph-section";
import CustomButtonSection from "../../components/experience/page-sections/custom/button/button-section";
import CustomSpacerSection from "../../components/experience/page-sections/custom/spacer/spacer-section";
import TagOWarSectionContainer from "../../components/experience/page-sections/tag-o-war/tag-o-war-section-container";
import SocialFeedCounterSection from "../../components/experience/page-sections/social-feed-counter/social-feed-counter-section";
import Logger from "../../../../services/logger/logger";
import SurveySection from "../../components/experience/page-sections/survey/survey-section";
import SurveySectionCounter from "../../components/experience/page-sections/survey/survey-section-counter";
import MultiColumnSection from "../../components/experience/page-sections/multi-column/multi-column-section";
import MediaSection from "../../components/experience/page-sections/media/media-section";
import FormBuilderSection from "../../components/experience/page-sections/form-builder/form-builder-section";
export const elementToComponent = ({
  element,
  pageWidth,
  _id,
  index,
  translations,
  parentSectionIndex,
  addSectionForMultiColumn,
  parentLocation,
  cookieEnabled,
  handleAction,
  isPreviewMode = true,
  isGDPRAllowed,
  isGDPRSeen,
  language,
  teamId
}) => {
  switch (element.sectionType) {
    case pageSectionType.HEADER:
      return /* @__PURE__ */ React.createElement(
        Header,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.HEADER,
          translations
        }
      );
    case pageSectionType.BANNER:
      return /* @__PURE__ */ React.createElement(
        Banner,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.BANNER,
          pageWidth,
          translations
        }
      );
    case pageSectionType.COOKIE_BANNER:
      return (isPreviewMode ? navigator.userAgent === "TINT" ? false : cookieEnabled && !isGDPRSeen : cookieEnabled) && /* @__PURE__ */ React.createElement(
        CookieBanner,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.COOKIE_BANNER,
          pageWidth,
          translations,
          onGPDRAllow: handleAction
        }
      );
    case pageSectionType.FOOTER:
      return /* @__PURE__ */ React.createElement(
        Footer,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.FOOTER,
          translations
        }
      );
    case pageSectionType.FORM_BUILDER:
      return /* @__PURE__ */ React.createElement(
        FormBuilderSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.FORM_BUILDER,
          translations,
          isPreviewMode
        }
      );
    case pageSectionType.FORM:
      return /* @__PURE__ */ React.createElement(
        Form,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.FORM,
          pageWidth,
          translations
        }
      );
    case pageSectionType.SOCIAL:
      return /* @__PURE__ */ React.createElement(
        Social,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.SOCIAL,
          translations
        }
      );
    case pageSectionType.TINT:
      return /* @__PURE__ */ React.createElement(
        Tint,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.TINT,
          pageWidth,
          translations,
          parentSectionIndex,
          addSectionForMultiColumn,
          isGDPRAllowed,
          cookieEnabled,
          isPreviewMode
        }
      );
    case pageSectionType.TIMER:
      return /* @__PURE__ */ React.createElement(
        Timer,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.TIMER,
          pageWidth,
          translations,
          addSectionForMultiColumn
        }
      );
    case pageSectionType.BACK_TO_TOP:
      return /* @__PURE__ */ React.createElement(
        BackToTop,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.BACK_TO_TOP,
          pageWidth,
          translations
        }
      );
    case pageSectionType.POST:
      return /* @__PURE__ */ React.createElement(
        PostSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.POST,
          pageWidth,
          translations,
          language,
          teamId
        }
      );
    case pageSectionType.POLL:
      return /* @__PURE__ */ React.createElement(
        Poll,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.POLL,
          pageWidth,
          translations
        }
      );
    case pageSectionType.CUSTOM_HEADLINE:
      return /* @__PURE__ */ React.createElement(
        HeadlineSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.CUSTOM_HEADLINE,
          pageWidth,
          translations,
          parentSectionIndex,
          addSectionForMultiColumn
        }
      );
    case pageSectionType.CUSTOM_PARAGRAPH:
      return /* @__PURE__ */ React.createElement(
        ParagraphSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.CUSTOM_PARAGRAPH,
          pageWidth,
          translations,
          parentSectionIndex,
          addSectionForMultiColumn
        }
      );
    case pageSectionType.CUSTOM_BUTTON:
      return /* @__PURE__ */ React.createElement(
        CustomButtonSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.CUSTOM_BUTTON,
          pageWidth,
          translations,
          parentSectionIndex,
          addSectionForMultiColumn
        }
      );
    case pageSectionType.IMAGE:
      return /* @__PURE__ */ React.createElement(ImageSection, { key: _id + index, index, data: element.data, pageSection: pageSectionType.IMAGE });
    case pageSectionType.VIDEO:
      return /* @__PURE__ */ React.createElement(
        VideoSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.VIDEO,
          parentLocation
        }
      );
    case pageSectionType.MEDIA:
      return /* @__PURE__ */ React.createElement(
        MediaSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.MEDIA,
          pageWidth,
          translations,
          parentSectionIndex,
          addSectionForMultiColumn,
          parentLocation
        }
      );
    case pageSectionType.CUSTOM_SPACER:
      return /* @__PURE__ */ React.createElement(
        CustomSpacerSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.CUSTOM_SPACER,
          pageWidth,
          translations
        }
      );
    case pageSectionType.TAG_O_WAR:
      return /* @__PURE__ */ React.createElement(
        TagOWarSectionContainer,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.TAG_O_WAR,
          pageWidth,
          translations
        }
      );
    case pageSectionType.SOCIAL_FEED_COUNTER:
      return /* @__PURE__ */ React.createElement(
        SocialFeedCounterSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.SOCIAL_FEED_COUNTER,
          pageWidth,
          translations
        }
      );
    case pageSectionType.SURVEY_COUNTER:
      return /* @__PURE__ */ React.createElement(
        SurveySectionCounter,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.SURVEY_COUNTER,
          pageWidth,
          translations
        }
      );
    case pageSectionType.SURVEY:
      return /* @__PURE__ */ React.createElement(
        SurveySection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.SURVEY,
          pageWidth,
          translations
        }
      );
    case pageSectionType.MULTI_COLUMN:
      return /* @__PURE__ */ React.createElement(
        MultiColumnSection,
        {
          key: _id + index,
          index,
          data: element.data,
          pageSection: pageSectionType.MULTI_COLUMN,
          pageWidth,
          translations
        }
      );
    default:
      Logger.error(`Section ${element.sectionType} doesn't exist`);
      return null;
  }
};
