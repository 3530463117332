var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import PropTypes from "prop-types";
import DropdownButton, { ButtonType } from "../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
export const MarketingIntegrationsDropdownSettingsType = {
  DELETE: "delete"
};
const MarketingIntegrationsDropdownSettingsList = [
  {
    type: MarketingIntegrationsDropdownSettingsType.DELETE,
    name: "Delete",
    icon: "fas fa-trash"
  }
];
export const MarketingIntegrationsSettingsList = ({ marketingIntegration, onRemove }) => {
  const onDropdownClick = (item) => {
    switch (item.type) {
      case MarketingIntegrationsDropdownSettingsType.DELETE:
        onRemove(item.value);
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      list: MarketingIntegrationsDropdownSettingsList.map((item) => __spreadProps(__spreadValues({}, item), {
        value: marketingIntegration.id
      })),
      iconCenter: "far fa-ellipsis-h",
      buttonType: ButtonType.BUTTON_SORT,
      onChangeValue: onDropdownClick
    }
  );
};
MarketingIntegrationsSettingsList.propTypes = {
  marketingIntegration: PropTypes.object,
  onRemove: PropTypes.func
};
