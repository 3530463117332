import React from "react";
import { Button } from "../../../../../../../../components/interface/button/button";
import { isInstintDisabled } from "../../../../../../../../services/functions/features-checker/features-checker";
export const SignageSidebarButtonActions = {
  CREATE_TINT_MIX: "create-tintmix",
  OPEN_IN_NEW_TAB: "open-in-new-tab",
  CHROMECAST_OPEN_IN_NEW_TAB: "chromecast-open-in-new-tab",
  DOWNLOAD_CHROME_CAST_APP: "download-chrome-cast-app",
  BUT_INS_TINT_UPDATES: "buy_ins_tint_updates"
};
export const standardConnectionDefineList = (onClick, plan) => [
  {
    dt: "Step 1",
    dd: [
      "Connect a laptop or table to your TV/Projector display via HDMI/VGA cord. This allows you to display your TINT URL on TV/Projector."
    ]
  },
  isInstintDisabled(plan.id) ? {
    dt: "Step 2",
    dd: [
      "If you want your TINT to update in near real-time, be sure to turn on InsTINT Updates.",
      /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "gray",
          size: "full-width",
          text: "Buy InsTint Updates",
          key: "step2",
          onClick: () => onClick(SignageSidebarButtonActions.OPEN_IN_NEW_TAB)
        }
      )
    ]
  } : void 0,
  {
    dt: isInstintDisabled(plan.id) ? "Step 3" : "Step 2",
    dd: [
      "Click the button below when you are ready to display your TINT. Then toggle fullscreen mode by clicking on the center button, or in Browser settings.",
      /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "primary",
          size: "full-width",
          text: "Open In New Tab",
          key: "step3",
          onClick: () => onClick(SignageSidebarButtonActions.OPEN_IN_NEW_TAB)
        }
      )
    ]
  }
];
export const chromeCastDefineList = (onClick, plan) => [
  {
    dt: "Step 1",
    dd: [
      "Plug your Chromecast into your TV and change the Input (or Source) until you see a screen that says \u2018Set Me Up\u2019."
    ]
  },
  {
    dt: "Step 2",
    dd: [
      "If you haven\u2019t already downloaded the Chromecast App, click the link below. Drag the Chromecast app to your Applications folder, then double click the folder to open it.",
      /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "gray",
          size: "full-width",
          text: "Download Chromecast App",
          key: "step2",
          onClick: () => onClick(SignageSidebarButtonActions.DOWNLOAD_CHROME_CAST_APP)
        }
      )
    ]
  },
  !isInstintDisabled(plan.id) && {
    dt: "Step 3",
    dd: [
      "If you want your TINT to update in near real-time be sure to turn on InsTINT Updates.",
      /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "gray",
          size: "full-width",
          text: "Buy InsTint Updates",
          key: "step3",
          onClick: () => onClick(SignageSidebarButtonActions.BUT_INS_TINT_UPDATES)
        }
      )
    ]
  },
  {
    dt: !isInstintDisabled(plan.id) ? "Step 4" : "Step 3",
    dd: ["Run the Chromecast app and follow the prompts."]
  },
  {
    dt: !isInstintDisabled(plan.id) ? "Step 5" : "Step 4",
    dd: [
      "Click our 'Cast to Chromecast' button on the Boards page. It can be found in the dropdown list under the view more (\u2026) icon.",
      /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "primary",
          size: "full-width",
          text: "Open In New Tab",
          key: "step5",
          onClick: () => onClick(SignageSidebarButtonActions.CHROMECAST_OPEN_IN_NEW_TAB)
        }
      )
    ]
  }
];
export const tintMixDefineList = (onClick) => [
  {
    dt: "What is TINTmix?",
    dd: [
      "TINTmix is a dynamic content management system that makes your TINTs and other content more engaging and exciting. You can display your TINTs together with sponsor videos, other TINTs, data and analysis websites, or other rotating content."
    ]
  },
  {
    dt: "Step 1",
    dd: ["Click the \u2018Create TINTmix\u201D button below."]
  },
  {
    dt: "Step 2",
    dd: ['You will be in a TINTmix. Click the "Add Scene" button and then "Add Item" to add rotating content.']
  },
  {
    dt: "Step 3",
    dd: ['Click the "Change Layout" and "Add Item" button to add content side by side.']
  },
  {
    dt: "Step 4",
    dd: [
      'When you are happy with the TINTmix, click "Display" to show your mix on a screen.',
      /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "primary",
          size: "full-width",
          text: "Create TINTmix",
          onClick: () => onClick(SignageSidebarButtonActions.CREATE_TINT_MIX)
        }
      )
    ]
  }
];
