export const GuardNames = {
  CTA: "cta",
  ALL: "all",
  PERMISSIONS_ALL: "permissionsAll",
  MODIFY_RULES: "modifyRules",
  COLLABORATORS: "collaborators",
  MODERATE_TINT: "moderateTint",
  ECOMMERCE: "ecommerce",
  TAG_PRODUCTS: "tagProducts",
  USER_RIGHTS: "userRights",
  BLOCK_USER: "blockUser",
  FILTER_LANGUAGES: "filterLanguages",
  FILTER_RATINGS: "filterRatings",
  CHROMECAST: "chromecast",
  TINT_MIX_LIST: "tintMixList",
  MIXES_READ: "mixesRead",
  MIXES_CREATE: "mixesCreate",
  MIXES_DELETE: "mixesDelete",
  INFLUENCER: "influencer",
  BILLING_HIDE_UPGRADE_PROMPTS: "billingHideUpgradePrompts",
  ANALYTICS: "analytics",
  NEW_SIMILAR_IMAGES: "newVisualSimilarity",
  HUE: "hue",
  POST_METADATA_TAGS: "postsMetadataTags",
  ZENDESK_WIDGET: "zenDeskWidget",
  CUSTOM_POSTS: "socialFeedsCustom",
  CUSTOM_CSS: "customCSS",
  EXPERIENCES: "experiences",
  EXPERIENCES_PERMISSIONS: "experiencesPermissions",
  EXPERIENCES_READ: "experiencesRead",
  EXPERIENCES_CREATE: "experiencesCreate",
  EXPERIENCES_DELETE: "experiencesDelete",
  FORM_SUBMISSIONS: "formSubmissions",
  DOMAINS: "domains",
  DOMAINS_READ: "domainsRead",
  DOMAINS_DELETE: "domainsDelete",
  DOMAINS_CREATE: "domainsCreate",
  DISCOVER_LAUNCHPAD: "disableLaunchpad",
  DISCOVER_LAUNCHPAD_EXPLORE: "discoverLaunchpadExplore",
  SELF_SERVICE_FEATURE_DISCOVERY: "selfServiceFeatureDiscovery",
  GLOBAL_MODERATION: "globalModeration",
  ECOMMERCE_WIP: "ecommerceWIP",
  TEAMS_USERS_READ: "teamsUsersRead",
  TEAMS_USERS_CREATE: "teamsUsersCreate",
  TEAMS_USERS_DELETE: "teamsUsersDelete",
  ROLES_CREATE: "rolesCreate",
  ROLES_DELETE: "rolesDelete",
  ACCOUNTS_CREATE: "accountsCreate",
  ACCOUNTS_DELETE: "accountsDelete",
  PRODUCT_ACCOUNT: "productAccounts",
  PRODUCT_ACCOUNT_CREATE: "productAccountsCreate",
  PRODUCT_ACCOUNT_DELETE: "productAccountsDelete",
  PRODUCT_ACCOUNT_READ: "productAccountsRead",
  PRODUCT_FEED_READ: "productFeedsRead",
  PRODUCT_ACCOUNT_SALESFORCE: "productAccountSalesforce",
  PRODUCTS_CREATE: "productsCreate",
  PRODUCTS_DELETE: "productsDelete",
  PRODUCT_FEEDS: "productFeeds",
  WEBHOOKS: "webhooks",
  WEBHOOKS_CREATE: "webhooksCreate",
  WEBHOOKS_DELETE: "webhooksDelete",
  WEBHOOKS_READ: "webhooksRead",
  OAUTH_APPLICATIONS: "oauthApplications",
  OAUTH_APPLICATIONS_CREATE: "oauthApplicationsCreate",
  OAUTH_APPLICATIONS_DELETE: "oauthApplicationsDelete",
  OAUTH_APPLICATIONS_READ: "oauthApplicationsRead",
  FORMS: "forms",
  FORMS_PERMISSIONS: "formsPermissions",
  FORMS_CREATE: "formsCreate",
  FORMS_MAPPING: "formsMapping",
  FORMS_DELETE: "formsDelete",
  FORMS_READ: "formsRead",
  FORMS_PUBLISH: "formsPublish",
  FORMS_ADVANCED: "formsAdvanced",
  FORMS_INSIGHTS: "formsInsights",
  FORMS_LOCALES: "formsLocales",
  FORMS_EMBED: "formsEmbed",
  FORMS_ALL_FILE_TYPES: "formsAllFileTypes",
  FORMS_TRACKING: "formsTracking",
  FORMS_CAPTCHA: "formsCaptcha",
  SOCIAL_FEEDS_HOOT_SUITE: "socialFeedsHootsuite",
  SOCIAL_FEEDS_REVIEW_TRACKERS: "socialFeedsReviewTrackers",
  SOCIAL_FEEDS_EMAIL: "socialFeedsEmail",
  SOCIAL_FEEDS_PINTEREST: "socialFeedsPinterest",
  SOCIAL_FEEDS_RSS: "socialFeedsRSS",
  SOCIAL_FEEDS_INSTAGRAM_BUSINESS: "socialFeedsInstagramBusiness",
  SOCIAL_FEEDS_THREADS: "socialFeedsThreads",
  SOCIAL_FEEDS_THREADS_MENTIONED: "socialFeedsThreadsMentioned",
  SOCIAL_FEEDS_THREADS_KEYWORD: "socialFeedsThreadsKeyword",
  SOCIAL_FEEDS_FACEBOOK: "socialFeedsFacebook",
  SOCIAL_FEEDS_TUMBLR: "socialFeedsTumblr",
  SOCIAL_FEEDS_FLICKR: "socialFeedFlickr",
  SOCIAL_FEEDS_LINKEDIN: "socialFeedLinkedIn",
  SOCIAL_FEEDS_PUBLIC_POST: "socialFeedPublicPost",
  SOCIAL_FEEDS_SMS: "socialFeedsSMS",
  SOCIAL_FEEDS_GOOGLE_NEWS: "socialFeedGoogleNews",
  SOCIAL_FEEDS_SLACK: "socialFeedSlack",
  SOCIAL_FEEDS_WEBEX: "socialFeedsWebex",
  SOCIAL_FEEDS_TWITTER: "socialFeedsTwitter",
  SOCIAL_CONNECTIONS: "socialConnections",
  SOCIAL_FEEDS_YOUTUBE: "socialFeedsYouTube",
  SOCIAL_FEEDS_TIKTOK: "socialFeedsTikTok",
  SOCIAL_FEEDS_CREATOR_IQ: "socialFeedsCreatorIq",
  SOCIAL_FEEDS_POWER_REVIEWS: "socialFeedsPowerReviews",
  SOCIAL_FEEDS_BAZAARVOICE: "socialFeedsBazaarvoice",
  MODIFY_TINT: "modifyTint",
  DELETE_TINT: "deleteTint",
  CREATE_TINT: "createTint",
  PRODUCT_FEED_CREATE: "productFeedsCreate",
  MULTIPLE_ASSETS: "multipleAssets",
  PRODUCT_READ: "productsRead",
  PRODUCT_DELETE: "productsDelete",
  GDPR_COMPLIANCE: "gdprCompliance",
  TOP_CLICKED_POSTS: "topClickedPosts",
  CAPTCHA: "captcha",
  INSIGHTS: "insights",
  INSIGHTS_HASH_TAGS: "insightsHashtags",
  INSIGHTS_PERFORMANCE: "insightsPerformance",
  INSIGHTS_SOCIAL: "insightsSocial",
  INSIGHTS_COMMERCE: "insightsCommerce",
  ASSETS_EDIT: "assetsEdit",
  EDIT_ASSETS_LIGHTOBOX: "assetsEditLightbox",
  ASSETS_READ: "assetsRead",
  ASSETS_METADATA_IMAGE_SENTIMENT: "assetsMetadataImageSentiment",
  ASSETS_METADATA_INSIGHTS: "assetsMetadataInsights",
  ASSETS_TRANSFORMATIONS: "assetsTransformations",
  EXPERIENCES_FIELDS_HIDDEN: "experiencesFieldsHidden",
  EXPERIENCES_ADVANCED_OPTIONS: "experiencesAdvancedOptions",
  ZAPIER: "zapier",
  API: "api",
  CHANGE_BILLING: "changeBilling",
  ASSET_MARKETING_INTEGRATION: "assetMarketingIntegration",
  MARKETING_INTEGRATIONS: "marketingIntegrations",
  TEAM_SECURITY: "teamSecurity",
  TEAMS_SOCIAL_ACCOUNTS_THREADS: "teamsSocialAccountsFeedsThreads",
  PERSONALIZATIONS_READ: "personalizationsRead",
  PERSONALIZATIONS_EDIT: "personalizationsEdit",
  PERSONALIZATIONS_DELETE: "personalizationsDelete",
  FONTS_READ: "fontsRead",
  FONTS_UPLOAD: "fontsUpload",
  FONTS_DELETE: "fontsDelete",
  ONBOARDING_SAMPLE_BOARD: "onboardingSampleBoard",
  STRIPE_BILLING: "stripeBilling",
  ONBOARDING_TINTS: "onboardingTints",
  SELF_SERVICE_ONBOARDING: "selfServiceOnboarding",
  EXPERIENCE_SEO: "experiencesSeo",
  SETTINGS_SOCIAL_ACCOUNTS: "settingsSocialAccounts",
  SETTINGS_COLLABORATORS: "settingsCollaborators",
  SETTINGS_SECURITY: "settingsSecurity",
  SETTINGS_ROLES: "settingsRoles",
  SETTINGS_PRODUCT_ACCOUNTS: "settingsProductAccounts",
  SETTINGS_PRODUCT_FEEDS: "settingsProductFeeds",
  SOCIAL_CONNECTIONS_HOOTSUITE: "socialConnectionsHootsuite",
  PERSONALIZATION_TINT_PROJECTION: "personalizationTintProjection",
  PERSONALIZATION_EMAIL_TEMPLATE: "personalizationEmailTemplate",
  TINT_EDITOR_SOCIAL_FEED_INSTAGRAM: "tintsSocialFeedsInstagramSocialListening",
  NEW_INFLUENCER_PAGE: "selfServiceInfluencers",
  TAGGING: "tagging",
  TINTS_SOCIAL_FEEDS_FACEBOOK_RATINGS: "tints_social_feeds_facebook_ratings",
  TINTS_SOCIAL_FEEDS_FACEBOOK_TAGGED: "tints_social_feeds_facebook_tagged",
  SCHEDULER: "scheduler",
  COMMUNITY_UPDATE: "communityUpdate",
  PUBLISHING_YOUTUBE: "publisher-youtube",
  PUBLISHING_THREADS: "publisher-threads",
  COLLABORATORS_OPTIONS: "collaborators-options",
  TEAMS_SAML: "teams-saml",
  PERSONALIZATIONS_ARCHIVED: "personalizationsArchived",
  REVIEWS: "reviews",
  REVIEWS_PERSONALIZATION: "reviewsPersonalization",
  DOMAINS_EMAIL: "domainsEmail",
  EMAIL_TEMPLATES: "emailTemplatesBuilder",
  AUDIENCE_BUILDER: "audience_builder",
  AUDIENCE_BADGES: "audience_badges",
  INSIGHTS_SOCIAL_TIKTOK: "insightsSocialTiktok",
  POSTS_REPLIES: "postsReplies",
  INTEGRATIONS_POWER_REVIEWS: "integrationsPowerReviews",
  INTEGRATIONS_BAZZARVOICE: "integrationsBazaarvoice",
  USER_RIGHT_MESSAGE_AI_SUGGESTION: "userRightMessageAiSuggestion",
  ZINRELO_NAVIGATION: "zinreloNavigation"
};
