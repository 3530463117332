var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./post-section.sass";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { PostEditContent } from "./edit/post-edit-content";
import { PostEditStyles } from "./edit/post-edit-styles";
import { connect } from "react-redux";
import PostEditPopup from "./edit/post-edit-popup";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { handleApiError } from "../../../../../../services/functions/error-handler/error-handler";
import { toast } from "react-toastify";
import { ApiService } from "../../../../../../services/api-service/api-service";
import { Button } from "../../../../../../components/interface/button/button";
import TintModal from "../../../../../../components/modal/modal";
import ModalHeader from "../../../../../../components/modal/components/modal-header/modal-header";
import ModalFooter from "../../../../../../components/modal/components/footers/modal-footer/modal-footer";
import PostModalBody from "./modal/post-modal-body";
import FormBuilder from "../../../../../../components/form-builder/form-builder";
const POST_MODAL_FORM_ID = "post-modal-form";
class PostSection extends Component {
  constructor(...args) {
    super(...args);
    __publicField(this, "getTintSlugDetails", () => {
      if (!this.props.isPreviewMode) {
        ApiService.get("/tints?include=social_feeds&page[size]=500").then(this.onTintFetchSuccess, this.onTintFetchError).finally(() => {
          this.setState({ isFetching: false });
        });
      } else {
        ApiService.pureInstance().get(`/v2/tints/${this.props.data.content.tintSlug}?include=social_feeds&page[size]=500`).then(this.onTintFetchSuccess, this.onTintFetchError).finally(() => {
          this.setState({ isFetching: false });
        });
      }
    });
    __publicField(this, "onTintFetchSuccess", (res) => {
      this.tints = res.data;
      this.determineRequiredFields(this.tints);
    });
    __publicField(this, "onTintFetchError", (err) => {
      handleApiError(err, toast.error);
    });
    __publicField(this, "determineRequiredFields", (tints) => {
      if (tints && !!this.props.data.content.tintSlug) {
        const publicPostFeed = this.getCurrentPublicPostFeed(tints);
        this.setState({
          isCustomFieldAvailable: publicPostFeed.attributes.options ? publicPostFeed.attributes.options.custom_field : false,
          isEmailRequired: publicPostFeed.attributes.options ? publicPostFeed.attributes.options.email : false
        });
      }
    });
    __publicField(this, "openPostModal", () => {
      this.setState({ isPostModalOpened: true });
    });
    __publicField(this, "closePostModal", () => {
      this.setState({ isPostModalOpened: false });
    });
    __publicField(this, "getCurrentPublicPostFeed", (data) => {
      const isArray = Array.isArray(data.data);
      const tintSocialFeeds = isArray && data.data.find((e) => e.attributes.slug === this.props.data.content.tintSlug);
      const tintSocialFeedsData = tintSocialFeeds ? tintSocialFeeds.relationships.social_feeds.data : data.data.relationships.social_feeds.data;
      return data.included.filter((e) => e.type === "social_feed" && e.attributes.source === "public_post").find((e) => tintSocialFeedsData.some((r) => e.id === r.id));
    });
    this.state = {
      isPostModalOpened: false,
      isEmailRequired: false,
      isCustomFieldAvailable: false,
      isFetching: true
    };
  }
  componentDidMount() {
    if (!this.props.data.content.tintSlug) {
      return;
    }
    this.getTintSlugDetails();
  }
  componentDidUpdate(prevProps) {
    const tintSlug = this.props.data.content.tintSlug;
    if (prevProps.data.content.tintSlug !== tintSlug && tintSlug !== "") {
      this.determineRequiredFields(this.tints);
    }
    if (this.props.data.content.tintSlug && !prevProps.data.content.tintSlug) {
      this.getTintSlugDetails();
    }
  }
  renderContent() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "tint-page-section--post__submit-button",
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.buttonBackgroundColor)
        },
        onClick: this.openPostModal
      },
      /* @__PURE__ */ React.createElement(
        "span",
        {
          style: {
            color: ColorParser.defaultColor(this.props.data.styles.buttonTextColor)
          }
        },
        this.props.getTranslation(this.props.data.content.buttonText)
      )
    );
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--post ${this.props.getSectionClass()}`,
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          stepIndex: this.props.stepIndex,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { closeModal: this.closePostModal, title: "Edit Post" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          PostEditContent,
          {
            getTranslation: this.props.getTranslation,
            onTranslationUpdate: this.props.onTranslationUpdate,
            data: this.props.data.content,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            nextStep: this.props.nextStep,
            openPostModal: this.openPostModal,
            isCustomFieldAvailable: this.state.isCustomFieldAvailable
          }
        ), /* @__PURE__ */ React.createElement(
          PostEditStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate,
            styleDataUpdate: this.props.onStylesUpdate
          }
        ))),
        /* @__PURE__ */ React.createElement(
          EditModalStep,
          {
            title: "Customize Popup",
            closeModal: () => {
              this.closePostModal();
              this.props.previousStep();
            },
            footer: /* @__PURE__ */ React.createElement("div", { className: "edit-modal-button" }, /* @__PURE__ */ React.createElement(
              Button,
              {
                type: "secondary",
                width: "100%",
                onClick: () => {
                  this.closePostModal();
                  this.props.previousStep();
                },
                text: "Done",
                size: "secondary"
              }
            ))
          },
          /* @__PURE__ */ React.createElement(
            PostEditPopup,
            {
              getTranslation: this.props.getTranslation,
              onTranslationUpdate: this.props.onTranslationUpdate,
              isCustomFieldAvailable: this.state.isCustomFieldAvailable,
              data: this.props.data.content,
              saveData: this.props.onDataSave,
              dataUpdate: this.props.onContentUpdate
            }
          )
        )
      ),
      this.renderContent(),
      /* @__PURE__ */ React.createElement(
        TintModal,
        {
          zIndex: 1049,
          className: `${!this.props.data.content.formBuilderId ? "post-section-modal" : "form-builder-modal  post-section-modal--no-padding"}`,
          headerTitle: this.props.getTranslation(this.props.data.content.popupTitle),
          modalHeader: (props) => /* @__PURE__ */ React.createElement(ModalHeader, __spreadProps(__spreadValues({}, props), { title: "" })),
          modalFooter: (props) => !this.props.data.content.formBuilderId ? /* @__PURE__ */ React.createElement(ModalFooter, __spreadValues({}, props)) : /* @__PURE__ */ React.createElement(React.Fragment, null),
          isOpen: this.state.isPostModalOpened,
          isBackDropVisible: this.props.isPreviewMode,
          onClose: this.closePostModal
        },
        this.props.data.content.formBuilderId ? /* @__PURE__ */ React.createElement(
          FormBuilder,
          {
            id: this.props.data.content.formBuilderId,
            language: this.props.language,
            teamId: this.props.teamId
          }
        ) : /* @__PURE__ */ React.createElement(
          PostModalBody,
          {
            translations: this.props.translations,
            experienceId: this.props.experienceId,
            formId: POST_MODAL_FORM_ID,
            tintSlug: this.props.data.content.tintSlug,
            onSuccessPost: this.closePostModal,
            isFetching: this.state.isFetching,
            isEmailRequired: this.state.isEmailRequired,
            isCustomFieldAvailable: this.state.isCustomFieldAvailable,
            customFieldPlaceholder: this.props.getTranslation(this.props.data.content.customFieldPlaceholder),
            buttonText: this.props.getTranslation(this.props.data.content.popupButtonText),
            maxVideoFileSize: this.props.getMaxVideoSizePlanLimit(),
            defaultValue: true
          }
        )
      )
    );
  }
}
const mapStateToProps = (state) => ({
  experienceId: state.experience.data && state.experience.data.id
});
export default connect(mapStateToProps, null)(PageSectionHoc(PostSection));
