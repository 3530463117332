import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { FORM_BUILDER } from "../../../../actions/consts";
export const ACTIONS = {
  SET_LANGUAGE: createPrefixedActionName("FORM_BUILDER.FORM", "SET_LANGUAGE"),
  RESET_STATE: createPrefixedActionName(FORM_BUILDER, "RESET_STATE")
};
export const setLanguage = (lang) => ({
  type: ACTIONS.SET_LANGUAGE,
  payload: lang
});
