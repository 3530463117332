import React, { Component } from "react";
import { EditModalBody } from "../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body";
import "./edit-banner-content.sass";
import { EditUploadDesign } from "../../../";
export class EditBannerContent extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(EditModalBody, { title: "Edit your banner background image below or upload your own." }, /* @__PURE__ */ React.createElement(
      "form",
      {
        noValidate: true,
        onSubmit: (e) => {
          e.preventDefault();
        }
      },
      /* @__PURE__ */ React.createElement(
        EditUploadDesign,
        {
          dataUpdate: this.props.dataUpdate,
          styleDataUpdate: this.props.styleDataUpdate,
          data: this.props.data
        }
      ),
      /* @__PURE__ */ React.createElement("button", { style: { display: "none" }, type: "submit" }, "Submit")
    ));
  }
}
