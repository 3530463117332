var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { toast } from "react-toastify";
import store from "../../store";
import { Config } from "../../utils/config";
import Logger from "../logger/logger";
export class Chromecast {
  constructor() {
    __publicField(this, "init", () => {
      if (typeof chrome.cast.SessionRequest !== "function") {
        toast.info("Please Install Chromecast Chrome Extension.");
        return;
      }
      const sessionRequest = new chrome.cast.SessionRequest(this.chromecastAppId);
      const apiConfig = new chrome.cast.ApiConfig(sessionRequest, this.sessionListener, this.receiverListener);
      chrome.cast.initialize(apiConfig, this.onInitSuccess, this.onError);
    });
    __publicField(this, "sessionListener", (e) => {
      Logger.info("New session ID:" + e.sessionId);
      this.session = e;
      this.session.addUpdateListener(this.sessionUpdateListener);
      this.session.addMessageListener(this.namespace, this.receiverMessage);
    });
    __publicField(this, "sessionUpdateListener", (isActive) => {
      const message = `Session ${isActive ? "Updated" : "Removed"}: ${this.session.sessionId}`;
      Logger.info(message);
      if (!isActive) {
        this.session = null;
      }
    });
    __publicField(this, "receiverListener", (e) => {
      e === "available" ? Logger.info("receiver found") : Logger.info("receiver list empty");
    });
    __publicField(this, "onInitSuccess", () => {
      Logger.info("onInitSuccess");
    });
    __publicField(this, "onError", (message) => {
      Logger.error(`onError: ${JSON.stringify(message)}`);
    });
    __publicField(this, "onSuccess", (message) => {
      Logger.error(`onSuccess: ${JSON.stringify(message)}`);
    });
    __publicField(this, "receiverMessage", (namespace, message) => {
      Logger.info(`receiverMessage: ${namespace}, ${message}`);
    });
    __publicField(this, "sendMessage", (message) => {
      if (!this.canCast()) {
        return;
      }
      if (this.session !== null) {
        this.session.sendMessage(
          this.namespace,
          message,
          this.onSuccess.bind(this, `Message sent: ${message}`),
          this.onError
        );
      } else {
        chrome.cast.requestSession(
          function(e) {
            this.session = e;
            this.session.addUpdateListener(this.sessionUpdateListener);
            this.session.addMessageListener(this.namespace, this.receiverMessage);
            this.session.sendMessage(
              this.namespace,
              message,
              this.onSuccess.bind(this, `Message sent: ${message}`),
              this.onError
            );
          }.bind(this),
          this.onError
        );
      }
    });
    __publicField(this, "canCast", () => {
      var _a;
      const getSession = store.getState().session;
      if (!((_a = getSession == null ? void 0 : getSession.guards) == null ? void 0 : _a.chromecast)) {
        toast.error("Upgrade TINT plan to get an access to Chromecast.");
        return false;
      } else if (!navigator.userAgent.match(/(Chrome|CriOS)/g)) {
        toast.error("Please Install Google Chrome.");
        return false;
      }
      return true;
    });
    __publicField(this, "importer", (url) => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.addEventListener("load", () => resolve(script), false);
        script.addEventListener("error", () => reject(script), false);
        document.body.appendChild(script);
      });
    });
    this.chromecastAppId = Config.chromecastAppId();
    this.namespace = Config.chromecastNamespace;
    this.session = null;
    if (typeof chrome === "undefined" || typeof chrome.cast === "undefined") {
      window["__onGCastApiAvailable"] = (loaded, err) => {
        loaded ? this.init() : Logger.error(err);
      };
      this.importer("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1").then(() => {
        Logger.info("Chromecast sender script loaded");
      }).catch((err) => Logger.error(err));
    }
  }
}
