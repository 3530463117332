var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./edit-area.sass";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const renderTitle = (title) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-box__title-container" }, /* @__PURE__ */ React.createElement("span", null, title));
};
class EditArea extends Component {
  constructor() {
    super(...arguments);
    __publicField(this, "delete", () => {
      if (!this.props.isDeletable) {
        return;
      }
      this.props.deleteSection();
    });
    __publicField(this, "renderDelete", () => {
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          "data-testid": "deleteAreaButton",
          onClick: this.delete,
          className: `tint-edit-box__item ${!this.props.isDeletable ? "tint-edit-box__item--disabled" : ""}`
        },
        this.props.isDeletable ? renderTitle("Delete") : renderTitle("Can't Remove"),
        /* @__PURE__ */ React.createElement("span", { "data-testid": "deleteAreaIcon", className: `fas fa-${this.props.isDeletable ? "trash" : "ban"}` })
      );
    });
    __publicField(this, "renderReorderButton", () => {
      return this.props.isReorderAble && /* @__PURE__ */ React.createElement("div", { "data-testid": "reorderAreaButton", onClick: this.props.openOrderModal, className: "tint-edit-box__item" }, renderTitle("Reorder"), /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-exchange-alt" }));
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-edit-box" }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-box__nav" }, /* @__PURE__ */ React.createElement("div", { className: "tint-edit-box__title" }, /* @__PURE__ */ React.createElement("span", { "data-testid": "editBoxTitle" }, this.props.title.replace(/_/g, " "))), /* @__PURE__ */ React.createElement("div", { className: "tint-edit-box__flex-container" }, /* @__PURE__ */ React.createElement("div", { onClick: this.props.openModal, className: "tint-edit-box__item", "data-testid": "editAreaButton" }, renderTitle("Edit"), /* @__PURE__ */ React.createElement("span", { className: "fas fa-pen" })), this.renderReorderButton(), this.renderDelete())));
  }
}
const mapStateToProps = (state) => ({
  session: state.session.data
});
export default connect(mapStateToProps)(EditArea);
EditArea.propTypes = {
  openOrderModal: PropTypes.func,
  openModal: PropTypes.func,
  title: PropTypes.string,
  deleteSection: PropTypes.func,
  isDeletable: PropTypes.bool,
  isReorderAble: PropTypes.bool
};
