var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./asset-marketing-integrations-sidebar.sass";
import PropTypes from "prop-types";
import TintSidebar from "../../../../components/sidebar/sidebar";
import { Config } from "../../../../utils/config";
import { closeMarketingIntegrationsSidebar } from "../../actions/asset-marketing-integrations/asset-marketing-integrations.actions";
import {
  postMarketingIntegrationsAssetRequest,
  getMarketingIntegrationsAssetRequest
} from "../../actions/asset-manager.fat-actions.js";
import { getSelectedAssets } from "../../reducers/asset-manager.reducer";
import { getSelectedCollections } from "../../reducers/asset-collections/asset-collections.reducer";
import { getMarketingIntegrationsFolders } from "../../../account-settings/actions/marketing-integrations/marketing-integrations-folders.fat-actions";
import { getMarketingIntegrations } from "../../../account-settings/actions/marketing-integrations/marketing-integrations.fat-actions";
import {
  mapMarketingIntegrationsAccountsForDropdown,
  mapMarketingIntegrationsFoldersForDropdown
} from "../../../account-settings/reducers/marketing-integrations/marketing-integrations-folders.reducer";
import DropdownButton, { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import PreloaderComponent, {
  PreloaderPositionType,
  PreloaderBackdropType
} from "../../../../components/interface/preloaders/preloader/preloader";
import { toast } from "react-toastify";
import { LongPollingService } from "../../../../services/long-polling/long-polling.service";
import { clearUploadDialog, setUploadDialog } from "../../../../actions/upload-dialog/upload-dialog";
import { UPLOAD_DIALOG_TYPE } from "../../../../components/uploading-dialog/uploading-dialog";
import { handleApiError } from "../../../../services/functions/error-handler/error-handler";
const ADD_ACCOUNT_DROPDOWN_OPTION = {
  name: "Add Account",
  value: "addAccount"
};
export const AssetPostKey = {
  ASSET: "asset_id",
  COLLECTION: "collection_id"
};
export const PublishAssetsRequestStatus = {
  SUCCESS: "success",
  PENDING: "pending",
  FAILURE: "failure"
};
export const AssetMarketingIntagrationsSidebar = ({ isOpen, postKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const assetManager = useSelector((state) => state.assetManager);
  const assetCollections = useSelector((state) => state.assetCollections);
  const marketingIntegrations = useSelector((state) => state.marketingIntegrations);
  const marketingIntegrationsFolders = useSelector((state) => state.marketingIntegrationsFolders);
  const isFetchingFolders = marketingIntegrationsFolders.isFetching;
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [selectedFolderId, setSelectedFolderId] = useState();
  const lightBoxAsset = assetManager.selectedLightBoxAsset ? [assetManager.selectedLightBoxAsset.id] : void 0;
  const selectedAssets = getSelectedAssets(assetManager);
  const variantAssets = selectedAssets ? selectedAssets.map((e) => e.id) : void 0;
  const selectedVariantIds = lightBoxAsset || variantAssets;
  const selectedCollections = getSelectedCollections(assetCollections);
  const collectionIds = selectedCollections.map((e) => e.id);
  const postValues = postKey === AssetPostKey.ASSET ? selectedVariantIds : collectionIds;
  const filterFolders = marketingIntegrationsFolders.data.filter((e) => e.id === selectedAccountId);
  const folders = mapMarketingIntegrationsFoldersForDropdown(filterFolders);
  const isFoldersAvailable = folders && folders.length > 0;
  const isRightButtonDisabled = !selectedAccountId || isFoldersAvailable && !selectedFolderId;
  const accounts = mapMarketingIntegrationsAccountsForDropdown(marketingIntegrations.data);
  useEffect(() => {
    dispatch(getMarketingIntegrations()).catch((err) => handleApiError(err, toast.error));
  }, [dispatch]);
  useEffect(() => {
    if (selectedAccountId === ADD_ACCOUNT_DROPDOWN_OPTION.value) return;
    if (selectedAccountId && filterFolders.length === 0) {
      dispatch(getMarketingIntegrationsFolders(selectedAccountId)).catch((err) => handleApiError(err, toast.error));
    }
  }, [dispatch, selectedAccountId]);
  const resetAccountsAndFolders = () => {
    setSelectedAccountId(void 0);
    setSelectedFolderId(void 0);
  };
  const closeSidebar = () => {
    resetAccountsAndFolders();
    dispatch(closeMarketingIntegrationsSidebar());
  };
  const onSelectAccount = (selectedAccount) => {
    if (selectedAccount.value === ADD_ACCOUNT_DROPDOWN_OPTION.value) {
      history.push(Config.routePaths.teamSettings.marketingIntegrations.path);
      closeSidebar();
    } else {
      setSelectedAccountId(selectedAccount.value);
      setSelectedFolderId(void 0);
    }
  };
  const onSelectFolder = (selectedFolder) => {
    setSelectedFolderId(selectedFolder.value);
  };
  const startPollingPublishAssets = () => {
    let validator = { isCancelled: false };
    const validate = (result) => {
      if (validator.isCancelled) {
        return true;
      }
      switch (result && result.payload.status) {
        case PublishAssetsRequestStatus.FAILURE:
        case PublishAssetsRequestStatus.SUCCESS:
          return true;
        default:
          return false;
      }
    };
    dispatch(
      postMarketingIntegrationsAssetRequest({
        id: selectedAccountId,
        postKey,
        postValues,
        folderId: selectedFolderId
      })
    ).then((res) => {
      closeSidebar();
      dispatch(
        setUploadDialog({
          type: UPLOAD_DIALOG_TYPE.UPLOAD,
          isPending: true,
          title: "Publishing assets",
          onCloseClick: () => {
            validator.isCancelled = true;
          },
          refreshFn: () => dispatch(getMarketingIntegrationsAssetRequest(res.payload[0].id))
        })
      );
      LongPollingService.Poll({
        request: () => dispatch(getMarketingIntegrationsAssetRequest(res.payload[0].id)),
        validate,
        interval: 2e3
      }).then((data) => {
        if (data.payload) {
          switch (data.payload.status) {
            case PublishAssetsRequestStatus.FAILURE:
              toast.error("We could not publish assets, please contact support.");
              dispatch(clearUploadDialog());
              break;
            case PublishAssetsRequestStatus.SUCCESS:
              dispatch(
                setUploadDialog({
                  isFinished: true,
                  successMsg: "Completed."
                })
              );
              return true;
          }
        }
      });
    });
  };
  const getCurrentFolderItem = () => {
    return folders.find((folder) => folder.value === selectedFolderId);
  };
  const getCurrentAccountItem = () => {
    return accounts.find((account) => account.value === selectedAccountId);
  };
  const renderFolders = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-asset-marketing-integrations-sidebar__content" }, isFetchingFolders ? /* @__PURE__ */ React.createElement(
      PreloaderComponent,
      {
        positionType: PreloaderPositionType.ABSOLUTE,
        backdropType: PreloaderBackdropType.SEMI_COVER
      }
    ) : null, isFoldersAvailable ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h5", null, "STEP 2: Select Folder"), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: folders,
        currentItem: getCurrentFolderItem(),
        placeholder: "Select Folder...",
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
        onChangeValue: onSelectFolder
      }
    )) : null);
  };
  const renderAccounts = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "tint-asset-marketing-integrations-sidebar__content" }, /* @__PURE__ */ React.createElement("h5", null, "STEP 1: Select Account"), /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
        list: [...accounts, ADD_ACCOUNT_DROPDOWN_OPTION],
        placeholder: "Select Account...",
        currentItem: getCurrentAccountItem(),
        iconRight: "fa fa-caret-down",
        buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
        onChangeValue: onSelectAccount
      }
    ));
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, selectedVariantIds && /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      title: "Publish Assets",
      onClose: closeSidebar,
      isOpen,
      defaultFooterStyle: false,
      onRightButtonClick: startPollingPublishAssets,
      isRightButtonDisabled,
      rightButtonText: "Publish"
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-asset-marketing-integrations-sidebar" }, renderAccounts(), renderFolders())
  ));
};
AssetMarketingIntagrationsSidebar.propTypes = {
  isOpen: PropTypes.bool,
  postKey: PropTypes.string
};
