import React from "react";
import "./uploader-preview.sass";
import { TintThumbWrapper } from "../../image-wrapper/image-wrapper";
import { Grid } from "../../interface/grid/grid";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TintButtonIcon, { TintButtonIconShape, TintButtonIconTypes } from "../../interface/button-icon/button-icon";
import { imageTypes } from "../../hooks/use-uploader/use-uploader";
export const UploaderPreview = ({ fileList, onRemoveFile, errors }) => {
  return fileList.length > 0 ? /* @__PURE__ */ React.createElement(Grid, { className: "uploader-preview", spacing: 6 }, fileList.map((file, i) => {
    return /* @__PURE__ */ React.createElement("div", { className: "uploader-preview__item", key: file.id }, /* @__PURE__ */ React.createElement(
      TintThumbWrapper,
      {
        key: i,
        error: errors.includes(file.id),
        src: imageTypes.includes(file.type) && file.preview,
        videoSrc: !imageTypes.includes(file.type) && file.preview,
        videoPosterSrc: !imageTypes.includes(file.type) && (file == null ? void 0 : file.poster),
        alt: file.id
      }
    ), /* @__PURE__ */ React.createElement(
      TintButtonIcon,
      {
        type: errors.includes(file.id) ? TintButtonIconTypes.ERROR : TintButtonIconTypes.DARK,
        shape: TintButtonIconShape.CIRCLE,
        width: 32,
        height: 32,
        icon: faTrash,
        iconSize: 14,
        onClick: () => onRemoveFile == null ? void 0 : onRemoveFile(file, i)
      }
    ));
  })) : null;
};
