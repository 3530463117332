const SOCIAL_FEEDS = "SOCIAL_FEEDS";
const names = {
  SOCIAL_FEEDS
};
export const SocialFeedsAccountTypes = {
  EXPERIENCE: "experience",
  MISSION: "mission",
  FORM: "form",
  ACCOUNT: "account",
  PAGE: "page"
};
export const BackendKeys = {
  EXTERNAL_ID: "external_id",
  FILE: "file",
  MEDIA: "media",
  FORM_SUBMISSION_KEY: "form_submission_key",
  POST_MEDIA_URL_KEY: "post_media_url_key",
  POST_BODY_FIELD: "post_body_field",
  AUTHOR_NAME_FIELD: "author_name_field"
};
export const OptionsKeys = {
  AUTHOR_NAME_FIELD: "author.name",
  AUTHOR_EMAIL_FIELD: "author.email",
  AUTHOR_AVATAR_FIELD: "author.avatar",
  POST_RATING_FIELD: "rating",
  POST_TITLE_FIELD: "title",
  POST_TEXT_FIELD: "text",
  MEDIA: "media",
  POST_ALT_TEXT_FIELD: "alternative_text",
  TAGS: "tags",
  EMAIL_TEMPLATE_ID: "email_template_id",
  INCENTIVIZED: "incentivized"
};
export const FormMediaKeys = {
  MEDIA: "media",
  AVATAR: "avatar",
  POST_MEDIA_ALT_TEXT: "alternative_text"
};
export { names as default, SOCIAL_FEEDS };
