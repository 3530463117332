const mapSingleElementByItsHTMLTags = (element) => {
  const mappedElement = {
    class: element == null ? void 0 : element.class,
    tags: {}
  };
  const findAllNodeNames = (element2) => {
    if (element2 == null ? void 0 : element2.nodeName) {
      if (Array.isArray(mappedElement.tags[element2.nodeName])) {
        mappedElement.tags[element2.nodeName].push(element2);
      } else {
        mappedElement.tags[element2.nodeName] = [element2];
      }
    }
    if (element2 == null ? void 0 : element2.elements) {
      for (const nestedElement of element2.elements) {
        findAllNodeNames(nestedElement);
      }
    }
  };
  findAllNodeNames(element);
  return mappedElement;
};
const mapElementsByTheirHTMLTags = (elements) => {
  return elements.map((element) => {
    return mapSingleElementByItsHTMLTags(element);
  });
};
const getAllSingleElementHTMLTags = (element) => {
  var _a;
  return (_a = mapSingleElementByItsHTMLTags(element)) == null ? void 0 : _a.tags;
};
const getSingleElementHTMLTags = (element) => {
  var _a;
  const tags = (_a = mapSingleElementByItsHTMLTags(element)) == null ? void 0 : _a.tags;
  return Object.keys(tags).reduce((acc, key) => {
    acc[key] = tags[key][0] || "";
    return acc;
  }, {});
};
export const FormBuilderElementsMappingService = {
  mapElementsByTheirHTMLTags,
  mapSingleElementByItsHTMLTags,
  getAllSingleElementHTMLTags,
  getSingleElementHTMLTags
};
