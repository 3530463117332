export function getPropertyValueOfElement(currentElement, property) {
  if (currentElement.className === "tint-wysiwyg") return "";
  let element = currentElement;
  if (!element[property] && element.parentNode.className !== "tint-wysiwyg-editor") {
    do {
      if ((element == null ? void 0 : element.className) !== "tint-wysiwyg") return;
      element = element == null ? void 0 : element.parentNode;
    } while ((element == null ? void 0 : element.className) !== "tint-wysiwyg" && typeof (element == null ? void 0 : element[property]) === "undefined" && (element == null ? void 0 : element[property]) !== "");
    return element[property] ? element[property] : "";
  } else {
    return element[property];
  }
}
export function getCurrentStyle(currentElement, cssProperty) {
  var _a, _b, _c;
  if (currentElement.className === "tint-wysiwyg") return "";
  let element = currentElement;
  if (element.style[cssProperty] === "") {
    do {
      element = element.parentNode;
    } while (element.className !== "tint-wysiwyg" && ((_a = element.style) == null ? void 0 : _a[cssProperty]) === "");
    return ((_b = element.style) == null ? void 0 : _b[cssProperty]) ? (_c = element.style) == null ? void 0 : _c[cssProperty] : "";
  } else {
    return element.style[cssProperty];
  }
}
export function addBackgroundToChild(children) {
  children.forEach((child) => {
    if (!child.style.backgroundColor) {
      child.style.backgroundColor = getCurrentStyle(child, "backgroundColor");
    }
  });
}
export function copyParentStylesToChildren(elements) {
  for (let j = 0; j < elements.length; j++) {
    elements[j].style.color = getCurrentStyle(elements[j], "color");
    elements[j].style.backgroundColor = getCurrentStyle(elements[j], "backgroundColor");
    elements[j].style.textDecorationLine = getCurrentStyle(elements[j], "textDecorationLine");
    elements[j].style.textDecoration = getCurrentStyle(elements[j], "textDecorationLine");
    elements[j].style.fontWeight = getCurrentStyle(elements[j], "fontWeight");
    elements[j].style.fontStyle = getCurrentStyle(elements[j], "fontStyle");
    elements[j].style.fontSize = getCurrentStyle(elements[j], "fontSize");
    elements[j].style.fontFamily = getCurrentStyle(elements[j], "fontFamily");
  }
}
export function setChildrenFontSize(elements, value) {
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].style.fontSize === "xxx-large" || elements[i].style.fontSize === "-webkit-xxx-large") {
      elements[i].style.fontSize = `${value}px`;
      elements[i].style.height = `${value}px`;
    }
  }
}
export function setFontSizeOnFireFoxOnly(value, contentEditableRef) {
  const fontElements = contentEditableRef.current.querySelectorAll("font");
  for (let j = 0; j < fontElements.length; j++) {
    if (fontElements[j].size) {
      fontElements[j].removeAttribute("size");
      fontElements[j].style.fontSize = `${value}px`;
      fontElements[j].style.height = `${value}px`;
      removeChildrenFontSize(fontElements[j]);
    }
  }
}
function removeChildrenFontSize(fontElements) {
  const spanChildren = fontElements.querySelectorAll("span");
  resetChildrenFontSizeStyle(spanChildren);
  const fontChildren = fontElements.querySelectorAll("font");
  resetChildrenFontSizeStyle(fontChildren);
  const aChildren = fontElements.querySelectorAll("a");
  resetChildrenFontSizeStyle(aChildren);
}
function resetChildrenFontSizeStyle(children) {
  for (let j = 0; j < children.length; j++) {
    children[j].style.fontSize = "";
    children[j].style.height = "";
  }
}
