var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { useHistory } from "react-router-dom";
import "./action-menu.sass";
import DropdownButton, { ButtonType } from "../../../../../components/interface/dropdown/button/dropdown-button";
import DropdownSortList from "../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import { DropdownHeader } from "../../../../../components/interface/dropdown/button/header/dropdown-header";
import { ACCOUNT_TYPE } from "../accounts-table";
import { Config } from "../../../../../utils/config";
import useFeatureAccessibility from "../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { MODAL_TYPES } from "../../../reducers/product-accounts/add-product-accounts-modal.reducer";
import { ProductAccounts } from "../../../../../types/product-accounts";
import { SocialFeedsSource } from "../../../../../types/social-feeds";
const AccountTableDropdownListType = {
  DELETE: "delete",
  EDIT: "edit",
  REFRESH: "refresh",
  SYNC_FEED: "sync feed",
  EXPORT_TO_CSV: "export to csv"
};
export const ProductFeedsSources = {
  MANUAL: "manual",
  CSV: "csv"
};
export const ActionMenu = ({
  account,
  onDelete,
  onRefresh,
  onEdit,
  onSyncFeed,
  onExportToCSV,
  hasPermissionToDelete = true,
  hasPermissionToEdit = true
}) => {
  const history = useHistory();
  const {
    productsRead: hasPermissionToReadCustomProduct,
    productsDelete: hasPermissionToDeleteCustomProduct,
    accountsCreate: hasPermissionToEditSocialAccount,
    accountsDelete: hasPermissionToDeleteSocialAccount
  } = useFeatureAccessibility();
  const isCustomProduct = account.source === ProductFeedsSources.MANUAL || account.source === ProductFeedsSources.CSV;
  const isNotReauthorized = [
    SocialFeedsSource.CREATOR_IQ,
    SocialFeedsSource.BAZAARVOICE,
    SocialFeedsSource.POWER_REVIEWS,
    SocialFeedsSource.RE_CAPTCHA
  ].includes(account == null ? void 0 : account.source);
  const AccountTableDropdownListSocialAccounts = [
    {
      type: AccountTableDropdownListType.DELETE,
      name: "Delete",
      icon: "fas fa-trash",
      action: () => onDelete && onDelete(account.id),
      isVisible: hasPermissionToDeleteSocialAccount && account.count === 0
    },
    {
      type: AccountTableDropdownListType.REFRESH,
      name: "Reauthorize",
      icon: "fas fa-exclamation-circle",
      action: () => onRefresh && onRefresh(account),
      isVisible: hasPermissionToEditSocialAccount && !isNotReauthorized
    },
    {
      type: AccountTableDropdownListType.EDIT,
      name: "Transfer Social Feeds",
      icon: "fas fa-exchange-alt",
      action: () => onEdit && onEdit(account.id),
      isVisible: hasPermissionToEditSocialAccount && account.count !== 0
    }
  ];
  const AccountTableDropdownListProductAccounts = [
    {
      type: AccountTableDropdownListType.DELETE,
      name: "Delete",
      icon: "fas fa-trash",
      action: () => onDelete && onDelete(account.id),
      isVisible: hasPermissionToDelete && account.count === 0
    },
    {
      type: AccountTableDropdownListType.EDIT,
      name: "Edit",
      icon: "fas fa-edit",
      action: () => onEdit && onEdit(account),
      isVisible: hasPermissionToEdit && account.count !== 0
    }
  ];
  const AccountTableDropdownListProductFeeds = [
    {
      type: AccountTableDropdownListType.EDIT,
      name: "View Products",
      icon: "fas fa-shopping-cart",
      action: () => onEdit && onEdit(account.id),
      isVisible: hasPermissionToReadCustomProduct && (account.source === ProductFeedsSources.MANUAL || account.count !== 0)
    },
    {
      type: AccountTableDropdownListType.SYNC_FEED,
      name: "Sync Feed",
      icon: "fas fa-sync-alt",
      action: () => onSyncFeed && onSyncFeed(account),
      isVisible: account.source !== ProductFeedsSources.MANUAL && hasPermissionToEdit
    },
    {
      type: AccountTableDropdownListType.DELETE,
      name: "Delete",
      icon: "fas fa-trash",
      action: () => onDelete && onDelete(account.id),
      isVisible: isCustomProduct ? hasPermissionToDeleteCustomProduct : hasPermissionToDelete
    },
    {
      type: AccountTableDropdownListType.EXPORT_TO_CSV,
      name: "Export to CSV",
      icon: "far fa-arrow-to-bottom",
      action: () => onExportToCSV && onExportToCSV(account.id),
      isVisible: hasPermissionToReadCustomProduct
    }
  ];
  const productAccountsWithoutEditMode = [ProductAccounts.FACEBOOK, ProductAccounts.SHOPIFY];
  const onDropdownClick = (item) => item.action();
  const visibleMenuActionItemsForSocialAccounts = AccountTableDropdownListSocialAccounts.filter((e) => e.isVisible);
  const visibleMenuActionItemsForProductAccounts = AccountTableDropdownListProductAccounts.filter((e) => e.isVisible);
  const visibleMenuActionItemsForProductFeeds = AccountTableDropdownListProductFeeds.filter((e) => e.isVisible);
  const renderActionMenu = () => {
    switch (account.type) {
      case ACCOUNT_TYPE.SOCIAL_ACCOUNT:
        return visibleMenuActionItemsForSocialAccounts.length !== 0 ? /* @__PURE__ */ React.createElement(
          DropdownButton,
          {
            dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
            dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
            list: visibleMenuActionItemsForSocialAccounts,
            iconCenter: "far fa-ellipsis-h",
            buttonType: ButtonType.BUTTON_SORT,
            onChangeValue: onDropdownClick
          }
        ) : null;
      case ACCOUNT_TYPE.PRODUCT_ACCOUNT:
        return /* @__PURE__ */ React.createElement(
          "div",
          {
            className: `tint-action-menu ${visibleMenuActionItemsForProductAccounts.length === 0 ? "tint-action-menu--disabled" : ""}`
          },
          /* @__PURE__ */ React.createElement(
            DropdownButton,
            {
              dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
              dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
              list: !productAccountsWithoutEditMode.includes(account.source) ? visibleMenuActionItemsForProductAccounts : visibleMenuActionItemsForProductAccounts.filter(
                (option) => option.type !== AccountTableDropdownListType.EDIT
              ),
              iconCenter: "far fa-ellipsis-h",
              buttonType: ButtonType.BUTTON_SORT,
              onChangeValue: onDropdownClick
            }
          )
        );
      case ACCOUNT_TYPE.PRODUCT_FEED:
        return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
          DropdownButton,
          {
            dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
            dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
            list: visibleMenuActionItemsForProductFeeds,
            iconCenter: "far fa-ellipsis-h",
            buttonType: ButtonType.BUTTON_SORT,
            onChangeValue: onDropdownClick
          }
        ));
      default:
        return null;
    }
  };
  return renderActionMenu();
};
