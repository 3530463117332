var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import "./cookie-banner-section.sass";
import { PageSectionHoc } from "../../../../../../components/HOC/page-section/page-section.hoc";
import { EditModal } from "../../../../../../components/edit-modal/edit-modal/edit-modal";
import { EditModalStep } from "../../../../../../components/edit-modal/edit-modal-step/edit-modal-step";
import ColorParser from "../../../../../../services/color-parser/color-parser";
import { Button } from "../../../../../../components/interface/button/button";
import { EditModalSwitcher } from "../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher";
import { EditCookieBannerContent } from "./edit/edit-cookie-banner-content";
import { EditCookieBannerStyles } from "./edit/edit-cookie-banner-styles";
import { Config } from "../../../../../../utils/config";
import { getIsGDPRSeen } from "../../../../utils/experience-helper";
import { getCookieBanner } from "../../../../../../data/default-experience-data/shared.data";
const translationKeys = {
  cookieAllowButton: "COOKIE_BANNER_BUTTON_ALLOW",
  cookieDismissButton: "COOKIE_BANNER_BUTTON_DISMISS",
  cookiePolicyText: "COOKIE_BANNER_BUTTON_POLICY_TEXT",
  privacyPolicyText: "COOKIE_BANNER_BUTTON_PRIVACY_POLICY",
  cookiePolicyHref: "COOKIE_BANNER_BUTTON_POLICY_HREF",
  privacyPolicyHref: "COOKIE_BANNER_BUTTON_PRIVACY_POLICY_HREF"
};
class CookieBannerSection extends Component {
  constructor(props) {
    super(props);
    // This method generate the missing translation keys for the cookie banner that already exist
    __publicField(this, "generateMissingKeys", (data) => {
      const dataContentKeys = Object.keys(data.content);
      let isAnyDifferance = false;
      Object.keys(getCookieBanner(null).data.content).map((key) => {
        if (dataContentKeys.includes(key)) {
          return key;
        }
        isAnyDifferance = true;
        const trKey = `EXPERIENCE.WHATEVER_TYPE.COOKIE_BANNER.${Config.getUniqueId().toUpperCase()}.${translationKeys[key]}`;
        this.props.onNewTranslationUpdate(trKey, null);
        this.props.onContentUpdate({ [key]: trKey });
        return key;
      });
      if (isAnyDifferance) {
        this.props.onDataSave();
      }
    });
    __publicField(this, "onChangeGDPRCompliance", (isAccepted) => {
      localStorage.setItem(
        `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`,
        JSON.stringify({
          seen: (/* @__PURE__ */ new Date()).getTime(),
          marketing: isAccepted,
          analytics: isAccepted
        })
      );
      this.props.onGPDRAllow(this.props.pageSection);
    });
    if (!this.props.isPreviewMode) {
      this.generateMissingKeys(this.props.data);
    }
    this.state = {
      isModalOpen: false,
      isAllowed: getIsGDPRSeen() && this.props.isPreviewMode
    };
  }
  render() {
    return !this.state.isAllowed && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `tint-page-section tint-page-section--cookie-banner ${this.props.getSectionClass()}`,
        dir: "auto",
        style: {
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`
        }
      },
      this.props.renderGenericComponents(),
      this.props.isModalOpen && /* @__PURE__ */ React.createElement(
        EditModal,
        {
          nextStep: this.props.nextStep,
          isVisible: this.props.isModalOpen,
          saveData: this.props.onDataSave,
          dismissModal: this.props.dismissModal,
          closeModal: this.props.closeModal
        },
        /* @__PURE__ */ React.createElement(EditModalStep, { title: "Edit Cookie Banner" }, /* @__PURE__ */ React.createElement(EditModalSwitcher, null, /* @__PURE__ */ React.createElement(
          EditCookieBannerContent,
          {
            getTranslation: this.props.getTranslation,
            onTranslationUpdate: this.props.onTranslationUpdate,
            data: this.props.data,
            saveData: this.props.onDataSave,
            dataUpdate: this.props.onContentUpdate
          }
        ), /* @__PURE__ */ React.createElement(
          EditCookieBannerStyles,
          {
            data: this.props.data,
            saveData: this.props.onDataSave,
            styleDataUpdate: this.props.onStylesUpdate
          }
        )))
      ),
      /* @__PURE__ */ React.createElement("div", { className: "cookie-banner-content", dir: "ltr" }, /* @__PURE__ */ React.createElement(
        "p",
        {
          style: {
            color: ColorParser.defaultColor(this.props.data.styles.bannerTextColor)
          },
          dir: this.props.textDirection
        },
        this.props.getTranslation(this.props.data.content.cookieText)
      ), /* @__PURE__ */ React.createElement("div", { className: "cookie-banner-content__actions", dir: this.props.textDirection }, /* @__PURE__ */ React.createElement(
        Button,
        {
          dir: this.props.textDirection,
          onClick: () => this.onChangeGDPRCompliance(true),
          styles: {
            background: ColorParser.defaultColor(this.props.data.styles.buttonAllowStyleBackground),
            color: ColorParser.defaultColor(this.props.data.styles.buttonAllowStyleTextColor),
            outline: this.props.data.styles.buttonAllowStyleOutline ? ColorParser.defaultColor(this.props.data.styles.buttonAllowStyleOutlineColor) : null
          },
          type: "white",
          text: this.props.getTranslation(this.props.data.content.cookieAllowButton)
        }
      ), this.props.getTranslation(this.props.data.content.cookieDismissButton) ? /* @__PURE__ */ React.createElement(
        Button,
        {
          dir: this.props.textDirection,
          onClick: () => this.onChangeGDPRCompliance(false),
          styles: {
            background: ColorParser.defaultColor(this.props.data.styles.buttonDismissStyleBackground),
            color: ColorParser.defaultColor(this.props.data.styles.buttonDismissStyleTextColor),
            outline: this.props.data.styles.buttonDismissStyleOutline ? ColorParser.defaultColor(this.props.data.styles.buttonDismissStyleOutlineColor) : null
          },
          type: "white",
          text: this.props.getTranslation(this.props.data.content.cookieDismissButton)
        }
      ) : null)),
      /* @__PURE__ */ React.createElement("div", { className: "cookie-banner-footer" }, this.props.getTranslation(this.props.data.content.cookiePolicyHref) ? /* @__PURE__ */ React.createElement(
        "a",
        {
          dir: this.props.textDirection,
          className: "cookie-banner-footer__link",
          target: "_blank",
          style: { color: ColorParser.defaultColor(this.props.data.styles.bannerTextColor) },
          href: this.props.getTranslation(this.props.data.content.cookiePolicyHref),
          rel: "noreferrer"
        },
        this.props.getTranslation(this.props.data.content.cookiePolicyText)
      ) : null, this.props.getTranslation(this.props.data.content.privacyPolicyHref) ? /* @__PURE__ */ React.createElement(
        "a",
        {
          dir: this.props.textDirection,
          className: "cookie-banner-footer__link",
          target: "_blank",
          style: { color: ColorParser.defaultColor(this.props.data.styles.bannerTextColor) },
          href: this.props.getTranslation(this.props.data.content.privacyPolicyHref),
          rel: "noreferrer"
        },
        this.props.getTranslation(this.props.data.content.privacyPolicyText)
      ) : null)
    );
  }
}
export default PageSectionHoc(CookieBannerSection);
