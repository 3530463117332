var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./checkbox-button.sass";
export const CheckboxButtonType = {
  ROUND: "round",
  PRIMARY: "primary"
};
const CheckboxButton = (_a, ref) => {
  var _b = _a, {
    defaultChecked,
    id,
    onStatusChange,
    name,
    color,
    borderColor,
    markColor,
    type = CheckboxButtonType.PRIMARY,
    backgroundColor = "transparent",
    textColor,
    details
  } = _b, rest = __objRest(_b, [
    "defaultChecked",
    "id",
    "onStatusChange",
    "name",
    "color",
    "borderColor",
    "markColor",
    "type",
    "backgroundColor",
    "textColor",
    "details"
  ]);
  const [isSelected, setIsSelected] = useState(defaultChecked);
  const onChange = (event) => {
    setIsSelected(!isSelected);
    if (onStatusChange !== void 0) {
      onStatusChange(id, event, !isSelected, details);
    }
  };
  useEffect(() => {
    setIsSelected(defaultChecked);
  }, [defaultChecked]);
  const markStyles = `
  .checkbox-button.${id} label::after {
    border-color: ${markColor};
    opacity: ${isSelected ? 1 : 0};
  }`;
  return /* @__PURE__ */ React.createElement("div", { className: `checkbox-button ${id}` }, /* @__PURE__ */ React.createElement("style", { dangerouslySetInnerHTML: { __html: markStyles } }), /* @__PURE__ */ React.createElement("input", __spreadValues({ type: "checkbox", id, name, value: isSelected, onChange, ref }, rest)), /* @__PURE__ */ React.createElement(
    "label",
    {
      "data-after": markColor,
      className: `checkbox-button__${type}`,
      style: {
        backgroundColor: isSelected ? color : backgroundColor,
        borderColor: isSelected ? color : borderColor,
        color: textColor
      },
      htmlFor: id
    }
  ));
};
const CheckboxButtonWithForwardedRef = forwardRef(CheckboxButton);
CheckboxButtonWithForwardedRef.propTypes = {
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  onStatusChange: PropTypes.func,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  markColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.string
};
export default CheckboxButtonWithForwardedRef;
