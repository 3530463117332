import React from "react";
import PropTypes from "prop-types";
import "./range-list.sass";
import usePercent from "../../../../../../../../components/hooks/use-percent/use-percent";
import useColumnSetter from "../../../../../../../../components/hooks/use-column-setter/use-column-setter";
import RangeColumn from "./range-col/range-col";
const RangeList = function({ leftSideWidth, rightSideWidth, socialFeedLeft, socialFeedRight, isPreviewMode, styles }) {
  const { percentValue } = usePercent(leftSideWidth, rightSideWidth);
  const { columnClass } = useColumnSetter(percentValue.firstValue, percentValue.secondValue);
  const mapStyles = {
    leftSide: {
      backgroundColor: styles.backgroundColorPostLeft,
      backgroundColorRange: styles.backgroundRangeLeft,
      textColor: styles.textColorPostLeft
    },
    rightSide: {
      backgroundColor: styles.backgroundColorPostRight,
      backgroundColorRange: styles.backgroundRangeRight,
      textColor: styles.textColorPostRight
    }
  };
  const leftWidth = percentValue.firstValue ? `${percentValue.firstValue}%` : "50%";
  const rightWidth = percentValue.secondValue ? `${percentValue.secondValue}%` : "50%";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: `tint-range-list tint-range-list--${columnClass}` }, /* @__PURE__ */ React.createElement("div", { className: "tint-range-list__left", style: { width: leftWidth } }, /* @__PURE__ */ React.createElement(RangeColumn, { isPreviewMode, socialFeedPosts: socialFeedLeft, styles: mapStyles.leftSide })), /* @__PURE__ */ React.createElement("div", { className: "tint-range-list__right", style: { width: rightWidth } }, /* @__PURE__ */ React.createElement(RangeColumn, { isPreviewMode, socialFeedPosts: socialFeedRight, styles: mapStyles.rightSide }))));
};
export default RangeList;
RangeList.propTypes = {
  leftSideWidth: PropTypes.number,
  rightSideWidth: PropTypes.number,
  socialFeedLeft: PropTypes.array,
  socialFeedRight: PropTypes.array,
  styles: PropTypes.any,
  isPreviewMode: PropTypes.bool
};
