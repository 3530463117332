import { CommunityContentTypes } from "../../types/community";
export const CommunityContentTypesTranslations = {
  [CommunityContentTypes.UPLOAD_PHOTO_FACEBOOK]: "Upload Photo (Facebook)",
  [CommunityContentTypes.RETAIL_REVIEW]: "Retail Review",
  [CommunityContentTypes.TIKTOK]: "TikTok",
  [CommunityContentTypes.YOUTUBE]: "YouTube",
  [CommunityContentTypes.BRAND_CONNECT]: "Brand Connect",
  [CommunityContentTypes.SHARE_LINK]: "Share Link",
  [CommunityContentTypes.PINTEREST]: "Pinterest",
  [CommunityContentTypes.UPLOAD_PHOTO_TWITTER]: "Upload Photo (X)",
  [CommunityContentTypes.RATING]: "Rating",
  [CommunityContentTypes.INSTAGRAM]: "Instagram",
  [CommunityContentTypes.FACEBOOK_PAGE]: "Facebook Page",
  [CommunityContentTypes.FACEBOOK]: "Facebook",
  [CommunityContentTypes.EMAIL_GROUP_INVITE]: "Email Group Invite",
  [CommunityContentTypes.LINKED_IN]: "LinkedIn",
  [CommunityContentTypes.TWITTER]: "X",
  [CommunityContentTypes.CUSTOM_UPLOAD]: "Custom Upload",
  [CommunityContentTypes.FACE2FACE]: "Face to Face",
  [CommunityContentTypes.UPLOAD_PHOTO_FB_PAGE]: "Upload Photo (Facebook Page)",
  [CommunityContentTypes.BLOG]: "Blog",
  [CommunityContentTypes.BAZAARVOICE]: "Bazaarvoice",
  [CommunityContentTypes.PRODUCT_REVIEW]: "Product Review",
  [CommunityContentTypes.UPLOAD_PHOTO]: "Upload Photo",
  [CommunityContentTypes.POWER_REVIEWS]: "Power Reviews",
  [CommunityContentTypes.THREADS]: "Threads",
  [CommunityContentTypes.REDDIT]: "Reddit",
  [CommunityContentTypes.UPLOAD_PHOTO_THREADS]: "Upload Photo (Threads)",
  [CommunityContentTypes.CUSTOM_FORM]: "Custom Form"
};
