var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React from "react";
import "./rights-management.sidebar.sass";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getSidebarData } from "../../../reducers/ui/ui.selector";
import { Config } from "../../../utils/config";
import TintSidebar from "../../sidebar/sidebar";
import { closeSidebar } from "../../../actions/ui/ui.actions";
import InputPrimary from "../../interface/inputs/input-primary/input-primary";
import CustomizableRadioButton from "../../interface/inputs/customizable-radio-button/customizable-radio-button";
import { UploadButton } from "../../upload-button/upload-button";
import { UploadServiceURLs } from "../../../services/upload/upload.service";
import { toast } from "react-toastify";
import TextareaPrimary from "../../interface/inputs/textarea-primary/textarea-primary";
import {
  createRequestRightsTermsAndConditions,
  deleteRequestRightsTermsAndCondition
} from "../../../actions/request-rights/request-rights.fat-actions";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import TintIcon from "../../interface/icon/icon";
import { Button } from "../../interface/button/button";
import { Typography, TypographyVariants } from "../../interface/typography/typography";
import { TintLink } from "../../interface/link/link";
import { isTermsAndConditionsLoading } from "../../../reducers/selectors/request-rights.selector";
export const rightsManagementSidebarId = "rightsManagementSidebarId";
export const RightsManagementDropdownState = {
  EDIT: "edit",
  CREATE: "create",
  PREVIEW: "preview"
};
const rightsManagementType = {
  MANUAL: "manual",
  UPLOAD: "upload"
};
const sidebarRightButtonTranslations = {
  [RightsManagementDropdownState.EDIT]: "Update",
  [RightsManagementDropdownState.CREATE]: "Save"
};
const sidebarHeaderTranslations = {
  [RightsManagementDropdownState.EDIT]: "Edit",
  [RightsManagementDropdownState.CREATE]: "Add"
};
const radioButtons = [
  {
    value: rightsManagementType.MANUAL,
    name: "Manual"
  },
  {
    value: rightsManagementType.UPLOAD,
    name: "Upload"
  }
];
export const RightsManagementSidebar = ({ onUpdate, isBackDropVisible = true }) => {
  var _a, _b, _c, _d;
  const sidebarData = useSelector((state) => getSidebarData(state, rightsManagementSidebarId));
  const isLoading = useSelector(isTermsAndConditionsLoading);
  const dispatch = useDispatch();
  const { data } = sidebarData;
  const formik = useFormik({
    isInitialValid: false,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: (_a = data == null ? void 0 : data.name) != null ? _a : "",
      title: (_b = data == null ? void 0 : data.title) != null ? _b : "",
      text: (_c = data == null ? void 0 : data.text) != null ? _c : "",
      document: (_d = data == null ? void 0 : data.document) != null ? _d : "",
      type: (data == null ? void 0 : data.document) ? rightsManagementType.UPLOAD : rightsManagementType.MANUAL
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name cannot be blank"),
      title: Yup.string().when("type", {
        is: rightsManagementType.MANUAL,
        then: Yup.string().required("Title cannot be blank"),
        otherwise: Yup.string().notRequired()
      }),
      text: Yup.string().when("type", {
        is: rightsManagementType.MANUAL,
        then: Yup.string().required("Content cannot be blank"),
        otherwise: Yup.string().notRequired()
      }).min(100, "Content must be at least 100 characters"),
      document: Yup.string().when("type", {
        is: rightsManagementType.UPLOAD,
        then: Yup.string().required("File cannot be blank"),
        otherwise: Yup.string().notRequired()
      })
    }),
    onSubmit: (values) => onSubmit(values)
  });
  const removeEmptyStringKeys = (obj) => {
    return Object.keys(obj).filter((key) => obj[key] !== "").reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
  };
  const onSubmit = (values) => __async(void 0, null, function* () {
    switch (sidebarData.type) {
      case RightsManagementDropdownState.EDIT:
        yield dispatch(deleteRequestRightsTermsAndCondition(data.id));
        yield dispatch(createRequestRightsTermsAndConditions(removeEmptyStringKeys(values)));
        dispatch(closeSidebar(rightsManagementSidebarId));
        onUpdate == null ? void 0 : onUpdate();
        break;
      case RightsManagementDropdownState.CREATE:
      default:
        yield dispatch(createRequestRightsTermsAndConditions(removeEmptyStringKeys(values)));
        dispatch(closeSidebar(rightsManagementSidebarId));
        onUpdate == null ? void 0 : onUpdate();
        return;
    }
  });
  const handleTypeChange = (type) => {
    formik.setValues({
      name: formik.values.name,
      title: "",
      text: "",
      document: type === rightsManagementType.UPLOAD && (data == null ? void 0 : data.document),
      type
    });
    formik.setErrors({});
    formik.setTouched({});
  };
  return /* @__PURE__ */ React.createElement(
    TintSidebar,
    {
      sidebarClassName: "tint-rights-management-sidebar",
      title: `${sidebarHeaderTranslations[sidebarData == null ? void 0 : sidebarData.type]} ${Config.routePaths.teamSettings.rightsManagement.title}`,
      defaultFooterStyle: false,
      onRightButtonClick: () => onSubmit(formik.values),
      isOpen: true,
      isBackDropVisible,
      rightButtonText: sidebarRightButtonTranslations[sidebarData == null ? void 0 : sidebarData.type],
      isRightButtonDisabled: !formik.isValid || isLoading,
      onClose: () => dispatch(closeSidebar(rightsManagementSidebarId))
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-rights-management-sidebar__content" }, /* @__PURE__ */ React.createElement(TintLink, { to: Config.url.rightsRequest, type: "gray", size: "full-width", className: "link" }, "Click here to view sample terms"), /* @__PURE__ */ React.createElement("form", { className: "form", id: "rights-management-form", onSubmit: formik.handleSubmit }, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        autoFocus: true,
        label: "Name",
        id: "name-input",
        name: "name",
        type: "text",
        value: formik.values.name,
        placeholder: "",
        error: formik.errors.name,
        handleBlur: formik.handleBlur,
        touched: formik.touched.name,
        handleChange: formik.handleChange
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "tint-rights-management-sidebar__type" }, radioButtons.map((item) => /* @__PURE__ */ React.createElement(
      CustomizableRadioButton,
      {
        name: "formName",
        label: item.name,
        id: item.value,
        value: item.name,
        isSelected: item.value === formik.values.type,
        displayTypeClass: [],
        onSelect: () => handleTypeChange(item.value),
        key: item.value
      }
    ))), formik.values.document && /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "gray",
        size: "full-width",
        className: "button-file",
        onClick: () => formik.setFieldValue("document", "")
      },
      /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(TintIcon, { icon: faFile }), /* @__PURE__ */ React.createElement(Typography, null, "Click to remove")),
      /* @__PURE__ */ React.createElement(TintIcon, { icon: faClose })
    ), !formik.values.document && formik.values.type === rightsManagementType.UPLOAD && /* @__PURE__ */ React.createElement("div", { className: "button-file-upload" }, /* @__PURE__ */ React.createElement(
      UploadButton,
      {
        accept: [".pdf, .doc, .docx"],
        onUploadSuccess: (e) => {
          formik.setFieldValue("document", e.id);
        },
        singleUpload: true,
        onUploadError: () => toast.error("We couldn't upload your assets"),
        url: UploadServiceURLs.RIGHTS_REQUEST
      }
    ), /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.SUBTITLE }, "Accepted file types are doc, docx and pdf.")), formik.values.type === rightsManagementType.MANUAL && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        label: "Title",
        id: "title-input",
        name: "title",
        type: "text",
        value: formik.values.title,
        placeholder: "",
        error: formik.errors.title,
        handleBlur: formik.handleBlur,
        touched: formik.touched.title,
        handleChange: formik.handleChange
      }
    ), /* @__PURE__ */ React.createElement(
      TextareaPrimary,
      {
        label: "Content",
        id: "content-input",
        name: "text",
        type: "text",
        value: formik.values.text,
        placeholder: "",
        error: formik.errors.text,
        handleBlur: formik.handleBlur,
        touched: formik.touched.text,
        handleChange: formik.handleChange
      }
    ), /* @__PURE__ */ React.createElement(Typography, { variant: TypographyVariants.SUBTITLE }, /* @__PURE__ */ React.createElement(TintLink, { to: Config.url.externalLinks.markdown, type: "gray", size: "full-width", className: "link" }, "Markdown"), "\xA0is supported for Terms & Conditions. You can learn more about Markdown", " ", /* @__PURE__ */ React.createElement(TintLink, { to: Config.url.externalLinks.markdown, type: "gray", size: "full-width", className: "link" }, "here"), "."))))
  );
};
