import { SocialFeedsSourceTranslate, SocialFeedsTypesTranslate } from "./social-feeds";
const useSocialFeedTranslation = () => {
  const getSocialFeedName = (source, type) => {
    var _a, _b, _c;
    const _source = ((_a = SocialFeedsSourceTranslate) == null ? void 0 : _a[source]) || source;
    const _type = ((_c = (_b = SocialFeedsTypesTranslate) == null ? void 0 : _b[source]) == null ? void 0 : _c[type]) || type;
    return {
      type: _type ? (_type.charAt(0).toUpperCase() + (_type == null ? void 0 : _type.slice(1))).replace(/_/g, " ") : "",
      source: _source ? (_source.charAt(0).toUpperCase() + (_source == null ? void 0 : _source.slice(1))).replace(/_/g, " ") : ""
    };
  };
  return {
    getSocialFeedName
  };
};
export default useSocialFeedTranslation;
