var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import "./tints-action-bar.sass";
import { useSelector, useDispatch } from "react-redux";
import {
  isInstintDisabled,
  onEventPlan,
  onEventAnnualPlan
} from "../../../../services/functions/features-checker/features-checker";
import { openSidebar } from "../../actions/tint-editor.actions";
import DropdownButton from "../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../components/interface/dropdown/button/header/dropdown-header";
import { ButtonType } from "../../../../components/interface/dropdown/button/dropdown-button";
import { Button } from "../../../../components/interface/button/button";
import DropdownCurrentUsageList, {
  isLimitReached
} from "../../../../components/interface/dropdown/lists/current-usage-list/dropdown-current-usage-list.jsx";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../../services/functions/number-format/number-format";
import { getSocialFeedsLimits, getTeam, getPlan, isAnyTintAdded } from "../../../../reducers/session";
import { TintsFilterBar } from "../filter-bar/tints-filter-bar";
import TintIcon from "../../../../components/interface/icon/icon";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { TintEditorConfig } from "../../utils/tint-editor.config";
import FeatureGuard from "../../../../guards/feature-guard";
import { GuardNames } from "../../../../guards/guards-list";
import useFeatureAccessibility from "../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { openSidebar as openUISidebar } from "../../../../actions/ui/ui.actions";
import { tintInsTintUpdatesSidebarId } from "../sidebar/tint-instint-updates-sidebar/tint-instint-updates-sidebar";
export const ActionBarMenu = {
  ADD_TINT: "add_tint",
  ADD_EVENT: "add_event",
  INSTINT_UPDATES: "instint_updates"
};
export const TintsActionBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const plan = useSelector(getPlan);
  const team = useSelector(getTeam);
  const socialFeedLimits = useSelector(getSocialFeedsLimits);
  const tintList = useSelector(isAnyTintAdded);
  const activePostsCount = team && team.relationships.posts ? team.relationships.posts.meta.posts_ingested_from_beginning_of_month : 0;
  const activePostsCountOnPlan = plan ? plan.attributes.posts : 0;
  const boardsCount = team && team.relationships.tints ? team.relationships.tints.meta.count : 0;
  const currentActiveBoardsCount = team && team.relationships.tints ? team.relationships.tints.meta.active_tints_count : 0;
  const activeBoardsCountOnPlan = plan ? plan.attributes.tints : 0;
  const activeSocialFeeds = socialFeedLimits.activeSocialFeedsCount;
  const activeSocialFeedsOnPlan = socialFeedLimits.activeSocialFeedsLimit;
  const isBillingHideUpgradePromptsAllowed = useFeatureAccessibility(GuardNames.BILLING_HIDE_UPGRADE_PROMPTS);
  const currentUsageList = [
    {
      name: "Boards",
      activeCount: boardsCount
    },
    {
      name: "Active Boards",
      activeCount: currentActiveBoardsCount,
      activeOnPlan: activeBoardsCountOnPlan
    },
    {
      name: "Feeds",
      activeCount: activeSocialFeeds,
      activeOnPlan: activeSocialFeedsOnPlan
    },
    {
      name: "Posts",
      activeCount: activePostsCount,
      activeOnPlan: activePostsCountOnPlan,
      customDescription: `Ingested ${numberWithCommas(activePostsCount)} of ${numberWithCommas(
        activePostsCountOnPlan
      )} Posts this month`
    }
  ];
  const renderTintStatisticsDropdown = () => {
    return /* @__PURE__ */ React.createElement(FeatureGuard, { hasAccess: !isBillingHideUpgradePromptsAllowed }, /* @__PURE__ */ React.createElement("div", { className: "tint-statistics-button hidden-mobile-block" }, /* @__PURE__ */ React.createElement(
      DropdownButton,
      {
        dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
        dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownCurrentUsageList, __spreadValues({}, props)),
        list: currentUsageList,
        iconCenter: "fas fa-chart-pie-alt",
        iconOverlap: isLimitReached(currentUsageList) ? "fas fa-exclamation-circle" : void 0,
        buttonType: ButtonType.BUTTON_SOFT_GRAY
      }
    )));
  };
  const renderGlobalModerationButton = () => {
    return /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.GLOBAL_MODERATION }, /* @__PURE__ */ React.createElement("div", { className: "tint-global-moderation-button hidden-mobile-block" }, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "tints-action-bar__button-moderate",
        onClick: () => {
          history.push(TintEditorConfig.routePaths.global.path);
        }
      },
      /* @__PURE__ */ React.createElement(TintIcon, { icon: faGlobe, fontSize: "16px" })
    )));
  };
  const renderInsTintUpdatesButton = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, onEventPlan(plan.id) ? onEventAnnualPlan(plan.id) ? /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-button hidden-mobile-block" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "white",
        size: "medium",
        text: "Add an Event",
        icon: "fas fa-calendar-alt",
        onClick: () => {
          dispatch(openUISidebar(tintInsTintUpdatesSidebarId, { title: "InsTINT Updates" }));
        }
      }
    )) : /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-button hidden-mobile-block" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "white",
        size: "medium",
        text: "Add an Event",
        icon: "fas fa-calendar-alt",
        onClick: () => {
          dispatch(openUISidebar(tintInsTintUpdatesSidebarId, { title: "Event Details" }));
        }
      }
    )) : !isInstintDisabled(plan.id) && !team.attributes.self_service && /* @__PURE__ */ React.createElement("div", { className: "tint-instint-updates-button hidden-mobile-block" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "new-gray",
        size: "medium",
        icon: "fas fa-bolt",
        text: "InsTINT Updates",
        onClick: () => {
          dispatch(openUISidebar(tintInsTintUpdatesSidebarId, { title: "InsTINT Updates" }));
        }
      }
    )));
  };
  const renderAddTintButton = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FeatureGuard, { featureName: GuardNames.CREATE_TINT }, /* @__PURE__ */ React.createElement("div", { className: "tint-create-tint-button hidden-mobile-block" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          dispatch(
            openSidebar({
              actionName: ActionBarMenu.ADD_TINT,
              analytics: false
            })
          );
        },
        type: "primary",
        size: "medium",
        text: "+ Add a Board"
      }
    ))));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tints-action-bar", "data-testid": "tints-action-bar" }, team.relationships !== void 0 && team.relationships.social_feeds ? /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `tints-action-bar__buttons-action-bar ${!tintList ? "tints-action-bar__buttons-action-bar--mobile-no-tints" : ""}`
    },
    /* @__PURE__ */ React.createElement(TintsFilterBar, null),
    renderTintStatisticsDropdown(),
    renderGlobalModerationButton(),
    renderInsTintUpdatesButton(),
    renderAddTintButton()
  ) : null);
};
export default TintsActionBar;
