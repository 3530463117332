import React, { useEffect, useState } from "react";
import "./date-picker-define-range-list.sass";
import { datePickerOptions } from "../../dropdown/lists/date-list/dropdown-date-list";
import { getDatePickerDataRange } from "../../../../utils/date-modifiers/date-modifiers";
import { useSelector } from "react-redux";
const DateDefineRange = {
  ALL_TIME: "all-time",
  LAST_7_DAYS: "last-7-days",
  LAST_MONTH: "last-month",
  LAST_3_MONTHS: "last-3-months",
  LAST_6_MONTHS: "last-6-months",
  LAST_YEAR: "last-year"
};
export const TintDatePickerDefineRangeList = ({ onSelectDate, noAllTime }) => {
  const accountDate = useSelector((state) => {
    var _a;
    return (_a = state.session) == null ? void 0 : _a.data.created_at;
  });
  const weekInDays = 7;
  const [rangeArray, setRangeArray] = useState([]);
  useEffect(() => {
    if (noAllTime) {
      setRangeArray(Object.keys(DateDefineRange).filter((e) => DateDefineRange[e] !== DateDefineRange.ALL_TIME));
    } else {
      setRangeArray(Object.keys(DateDefineRange));
    }
  }, []);
  const selectDate = (type) => {
    switch (type) {
      case DateDefineRange.ALL_TIME:
        onSelectDate({
          startDate: new Date(accountDate),
          endDate: /* @__PURE__ */ new Date(),
          key: "selection"
        });
        break;
      case DateDefineRange.LAST_7_DAYS:
        onSelectDate({
          startDate: new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() - weekInDays)),
          endDate: /* @__PURE__ */ new Date(),
          key: "selection"
        });
        break;
      case DateDefineRange.LAST_MONTH:
        onSelectDate({
          startDate: new Date(getDatePickerDataRange(datePickerOptions.MONTH)),
          endDate: /* @__PURE__ */ new Date(),
          key: "selection"
        });
        break;
      case DateDefineRange.LAST_3_MONTHS:
        onSelectDate({
          startDate: new Date(getDatePickerDataRange(datePickerOptions.THREE_MONTHS)),
          endDate: /* @__PURE__ */ new Date(),
          key: "selection"
        });
        break;
      case DateDefineRange.LAST_6_MONTHS:
        onSelectDate({
          startDate: new Date(getDatePickerDataRange(datePickerOptions.SIX_MONTHS)),
          endDate: /* @__PURE__ */ new Date(),
          key: "selection"
        });
        break;
      case DateDefineRange.LAST_YEAR:
        onSelectDate({
          startDate: new Date(getDatePickerDataRange(datePickerOptions.YEAR)),
          endDate: /* @__PURE__ */ new Date(),
          key: "selection"
        });
        break;
      default:
        return;
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "tint-date-picker-define-range-list" }, /* @__PURE__ */ React.createElement("div", { className: "tint-date-picker-define-range-list__row" }, /* @__PURE__ */ React.createElement("ul", { className: "define-range-list" }, rangeArray.map((el, i) => /* @__PURE__ */ React.createElement("li", { key: i }, /* @__PURE__ */ React.createElement("button", { onClick: () => selectDate(DateDefineRange[el]) }, DateDefineRange[el].replace(/-/g, " ")))))));
};
