import React from "react";
import { Config } from "../../../utils/config";
import ApiDropdown from "../api-dropdown";
import { getUrl } from "../api-dropdown.helpers";
import { filters } from "../../../feature/account-settings/utils/filter-helper/filter-helper";
const tintsDropdownId = "tints";
const createTintAdditionalOption = {
  name: "Create Tint",
  value: "create_tint",
  link: Config.routePaths.main.react_path
};
export const DropdownTints = ({ currentElement, onChange, fixedOption = true }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "tint-tints-dropdown" }, /* @__PURE__ */ React.createElement(
    ApiDropdown,
    {
      label: "Select Board",
      placeholder: "Select a Board",
      fetchUrl: (url, query) => getUrl({
        url,
        query,
        baseUrl: "/tints",
        filters: [
          {
            by: filters.QUERY,
            value: query
          }
        ]
      }),
      baseUrl: "/tints",
      currentElement,
      dropdownId: tintsDropdownId,
      onChange,
      fixedOption,
      additionalOption: fixedOption ? createTintAdditionalOption : void 0
    }
  ));
};
