import { instagram_business } from "./instagram.schema";
import { twitter } from "./twitter.schema";
import { facebook } from "./facebook.shemas";
import { rss } from "./rss.schema";
import { publicPosting } from "./public-posting.schemas";
import { publicPostingUpgrade } from "./public-posting-upgrade.schemas";
import { reviewsRatings } from "./reviews-ratings.schemas";
import { reviewsRatingsUpgrade } from "./reviews-ratings-upgrade.schemas";
import { sms } from "./sms.schema";
import { email } from "./email.schema";
import { userReview } from "./user-review.schema";
import { tumblr } from "./tumblr.schema";
import { linkedin } from "./linkedin.schema";
import { flickr } from "./flickr.schema";
import { googleNews } from "./google-news.schema";
import { slack } from "./slack.schema";
import { hootsuite } from "./hootsuite.schema";
import { tiktok } from "./tiktok.schema";
import { webex } from "./webex.schema";
import { experienceBuilder } from "./experiance-builder.schema";
import { pinterest } from "./pinterest.schema";
import { influencer } from "./influencer.schema";
import { youtube } from "./youtube.schema";
import { vesta } from "./vesta.schema";
import { forms } from "./forms.schema";
import { threads } from "./threads.schema";
import { creatorIq } from "./creator-iq.schema";
export const socialFeedSchema = {
  twitter,
  instagram_business,
  facebook,
  rss,
  sms,
  email,
  public_post: publicPosting,
  review_trackers: userReview,
  tumblr,
  linkedin,
  flickr,
  google_news: googleNews,
  slack,
  hootsuite,
  tiktok,
  spark: webex,
  experience_builder: experienceBuilder,
  pinterest,
  influencer,
  reviews_ratings: reviewsRatings,
  youtube,
  mission: vesta,
  forms,
  public_post_upgrade: publicPostingUpgrade,
  reviews_ratings_upgrade: reviewsRatingsUpgrade,
  threads,
  creator_iq: creatorIq
};
