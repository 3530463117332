import { SocialFeedsSource, SocialFeedsTypes } from "../../../types/social-feeds";
export const getTintEditorModal = (state, name) => {
  var _a, _b;
  return (_b = (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.modals) == null ? void 0 : _b[name];
};
export const getTintList = (state) => {
  var _a;
  return (_a = state == null ? void 0 : state.tintEditor) == null ? void 0 : _a.tints;
};
export const getSocialFeedSource = (socialFeed) => {
  var _a, _b;
  const source = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.source;
  const type = (_b = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _b.type;
  if (source === SocialFeedsSource.PUBLIC_POST && type === SocialFeedsTypes.REVIEWS) {
    return SocialFeedsSource.REVIEWS_RATINGS;
  }
  if (source === SocialFeedsSource.PUBLIC_POST && type === SocialFeedsTypes.POST) {
    return SocialFeedsSource.PUBLIC_POST;
  }
  if (source === SocialFeedsSource.FORM_SUBMISSION && type === SocialFeedsTypes.FORM) {
    return SocialFeedsSource.FORMS;
  }
  return source;
};
