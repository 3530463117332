var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useMemo, useEffect } from "react";
import "./web-embed-settings-sidebar.sass";
import AccordionTab from "../../../../../../../../components/interface/accordion/accordion-tab/accordion-tab";
import Accordion from "../../../../../../../../components/interface/accordion/accordion";
import DropdownButton, {
  ButtonType
} from "../../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSimpleList from "../../../../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list";
import CustomizableRadioButton from "../../../../../../../../components/interface/inputs/customizable-radio-button/customizable-radio-button";
import InputPrimary from "../../../../../../../../components/interface/inputs/input-primary/input-primary";
import { useFormik } from "formik";
import UnitsInput, { unitList } from "../../../../../../../../components/interface/units-input/units-input";
import {
  InputGroup,
  InputGroupPosition
} from "../../../../../../../../components/interface/inputs/input-group/input-group";
import { useSelector } from "react-redux";
import OutputEmbedScript from "../../../../../../../../components/interface/output-embed-script/output-embed-script";
import Tooltip from "../../../../../../../transform-ui/components/tooltip/tooltip";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import TintIcon from "../../../../../../../../components/interface/icon/icon";
import { DisplaySettingsSwitcherSteps } from "../../display-settings-switcher/display-settings-switcher";
import { getSlugName } from "../../../../../../../../reducers/account";
import useSavedFilters from "../../../../../../../../components/hooks/use-saved-filters/use-saved-filters";
import { Config } from "../../../../../../../../utils/config";
export const DisplayType = {
  INFINITE_SCROLL: "infiniteScroll",
  CLICK_FOR_MORE: "clickForMore",
  PAGE_BY_PAGE: "paginate"
};
export const HeightType = {
  UNLIMITED: "unlimited",
  FIXED: "fixed"
};
const displayTypeList = [
  {
    name: "Infinite Scroll",
    value: DisplayType.INFINITE_SCROLL
  },
  {
    name: "Click For More",
    value: DisplayType.CLICK_FOR_MORE
  },
  {
    name: "Page by Page",
    value: DisplayType.PAGE_BY_PAGE
  }
];
const heightTypeList = [
  {
    name: "Unlimited",
    value: HeightType.UNLIMITED
  },
  {
    name: "Fixed",
    value: HeightType.FIXED
  }
];
const WebEmbedSettingsSidebar = ({ onChangeData, switcherData }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const savedFilters = useSavedFilters({ tintId: (_a = switcherData["1"]) == null ? void 0 : _a.tintId });
  const slugName = useSelector(getSlugName);
  useEffect(() => {
    onChangeData({ isValid: true });
  }, []);
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialErrors: [{}],
    initialValues: {
      displayType: displayTypeList[0],
      width: "",
      height: "",
      unit: unitList[0],
      heightType: heightTypeList[0],
      columns: "",
      savedFilter: "",
      productSKU: "",
      keywords: "",
      tags: ""
    }
  });
  const displayTypeDescription = useMemo(() => {
    const getDisplayTypeDescription = () => {
      switch (formik.values.displayType.value) {
        case DisplayType.INFINITE_SCROLL:
          return "More posts will load automatically on your TINT as you scroll down.";
        case DisplayType.PAGE_BY_PAGE:
          return "A fixed number of posts is shown, with buttons to navigate from page to page.";
        case DisplayType.CLICK_FOR_MORE:
          return "More posts will load on your TINT when a button is clicked.";
        default:
          return;
      }
    };
    return getDisplayTypeDescription();
  }, [formik.values.displayType.value]);
  return /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar" }, /* @__PURE__ */ React.createElement(Accordion, null, /* @__PURE__ */ React.createElement(AccordionTab, { title: "General", isActive: true }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement(UnitsInput, { label: "Width", formik, name: "width", id: "width", formikValueKey: "width" })), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement("label", null, "Height"), heightTypeList.map((item) => /* @__PURE__ */ React.createElement(
    CustomizableRadioButton,
    {
      name: item.name,
      id: item.name,
      label: item.name,
      value: item.name,
      isSelected: item.value === formik.values.heightType.value,
      displayTypeClass: [],
      onSelect: () => formik.setFieldValue("heightType", item),
      key: item.value
    }
  )), /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      inputGroupLabel: "px",
      labelPosition: InputGroupPosition.RIGHT,
      disabled: formik.values.heightType.value !== HeightType.FIXED
    },
    /* @__PURE__ */ React.createElement(
      InputPrimary,
      {
        disabled: formik.values.heightType.value !== HeightType.FIXED,
        name: "height",
        id: "height",
        type: "number",
        value: formik.values.height,
        placeholder: "ex. 100",
        handleChange: formik.handleChange,
        error: formik.errors.height,
        touched: !!formik.errors.height,
        style: {
          border: "none"
        }
      }
    )
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__header" }, /* @__PURE__ */ React.createElement("label", null, "Display Type"), /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      style: { left: "-50px", top: "-38px", height: "30px" },
      text: "Set display type",
      component: /* @__PURE__ */ React.createElement(TintIcon, { icon: faCircleQuestion })
    }
  )), /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      currentItem: formik.values.displayType,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, props), { isDefaultOption: false })),
      list: displayTypeList,
      placeholder: "None",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: (selectedItem) => {
        formik.setFieldValue("displayType", selectedItem);
      }
    }
  )), /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, displayTypeDescription), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__header" }, /* @__PURE__ */ React.createElement("label", null, "# Of columns"), /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      style: { left: "-50px", top: "-38px", height: "30px" },
      text: "Set number of columns",
      component: /* @__PURE__ */ React.createElement(TintIcon, { icon: faCircleQuestion })
    }
  )), /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      name: "columns",
      id: "columns",
      type: "number",
      placeholder: "example: 3",
      value: formik.values.columns,
      handleChange: formik.handleChange,
      error: formik.errors.columns,
      touched: !!formik.errors.columns
    }
  )))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Advanced Filters" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "tint-sidebar-label" }, "You can further customize your gallery\u2019s content by applying a filter below.", /* @__PURE__ */ React.createElement(
    "a",
    {
      href: Config.url.zenDesk.webEmbedSettingsSavedLinks,
      target: "_blank",
      rel: "noopener noreferrer",
      className: "link"
    },
    "Learn More"
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      label: "Saved filter",
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSimpleList, __spreadProps(__spreadValues({}, props), { isDefaultOption: true, defaultOptionTitle: "None" })),
      list: savedFilters,
      placeholder: "Select Saved filter",
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      onChangeValue: (selectedItem) => {
        formik.setFieldValue("tags", "");
        formik.setFieldValue("keywords", "");
        formik.setFieldValue("productSKU", "");
        formik.setFieldValue("savedFilter", selectedItem);
      }
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "Filter by product",
      value: formik.values.productSKU,
      type: "text",
      name: "productSKU",
      id: "productSKU",
      placeholder: "Enter SKU",
      handleChange: (e) => {
        formik.handleChange(e);
      },
      disabled: (_b = formik.values.savedFilter) == null ? void 0 : _b.id,
      error: formik.errors.productSKU,
      touched: !!formik.errors.productSKU
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "Filter by keyword",
      value: formik.values.keywords,
      type: "text",
      name: "keywords",
      id: "keywords",
      placeholder: "Enter Keywords",
      handleChange: formik.handleChange,
      disabled: (_c = formik.values.savedFilter) == null ? void 0 : _c.id,
      error: formik.errors.keywords,
      touched: !!formik.errors.keywords
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "tint-web-embed-sidebar__group" }, /* @__PURE__ */ React.createElement(
    InputPrimary,
    {
      label: "Filter by tags",
      value: formik.values.tags,
      type: "text",
      name: "tags",
      id: "tags",
      placeholder: "Enter Keywords",
      handleChange: formik.handleChange,
      disabled: (_d = formik.values.savedFilter) == null ? void 0 : _d.id,
      error: formik.errors.tags,
      touched: !!formik.errors.tags
    }
  )))), /* @__PURE__ */ React.createElement(AccordionTab, { title: "Embed Code" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    OutputEmbedScript,
    {
      tintId: slugName,
      personalizationId: switcherData[DisplaySettingsSwitcherSteps.DISPLAY_OPTIONS].personalizationId,
      product: formik.values.productSKU,
      savedFilterId: (_e = formik.values.savedFilter) == null ? void 0 : _e.id,
      keywords: formik.values.keywords,
      tags: formik.values.tags,
      columns: formik.values.columns,
      displayType: (_f = formik.values.displayType) == null ? void 0 : _f.value,
      heightType: (_g = formik.values.heightType) == null ? void 0 : _g.value,
      styles: {
        width: formik.values.width ? `${formik.values.width}${formik.values.unit.value}` : void 0,
        height: formik.values.height && ((_h = formik.values.heightType) == null ? void 0 : _h.value) === HeightType.FIXED ? `${formik.values.height}px` : void 0
      }
    }
  )))));
};
export default WebEmbedSettingsSidebar;
