var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ACTIONS } from "../../actions/filters/asset-manager-filters.actions";
import { produce } from "immer";
import { AssetStatus } from "../../components/settings-dropdown/asset-settings-dropdown";
export const initialState = {
  sortValue: "-created_at",
  filter: {
    sentiment: [],
    objects: [],
    tags: [],
    collectionId: "",
    collections: [],
    assetType: "",
    source: "",
    date: void 0,
    status: "active"
  },
  searchPhrase: ""
};
export const assetManagerFilters = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SORT_OPTION:
      return __spreadValues(__spreadValues({}, state), {
        sortValue: action.payload
      });
    case ACTIONS.SET_SEARCH_PHRASE:
      return __spreadValues(__spreadValues({}, state), {
        searchPhrase: action.payload
      });
    case ACTIONS.SET_FILTER_OPTION:
      return __spreadValues(__spreadValues({}, state), {
        filter: __spreadValues(__spreadValues({}, state.filter), action.payload)
      });
    case ACTIONS.CLEAR_FILTER_OPTION:
      return produce(state, (draftState) => {
        draftState.filter.objects = draftState.filter.objects.map((e) => {
          e.isActive = false;
          return e;
        });
        draftState.filter.tags = [], draftState.filter.date = void 0;
        draftState.filter.assetType = "";
        draftState.filter.source = "";
        draftState.filter.collectionId = "";
        draftState.filter.status = AssetStatus.ACTIVE;
      });
    default:
      return state;
  }
};
