export const TintEditorPaths = {
  title: "TINT Editor",
  path: "/tints/:tintId",
  createUrl: ({ tintId }) => `/tints/${tintId}`,
  preview: {
    title: "TINT Editor Preview",
    path: "/tints/:tintId/preview",
    createUrl: ({ tintSlug }) => `/tints/${tintSlug}/preview`
  },
  global: {
    title: "Global Moderation",
    tintId: "global",
    path: "/tints/global"
  }
};
