import { createPrefixedActionName } from "../../../../services/functions/redux-routines/redux-routines";
import { MARKETING_INTEGRATIONS } from "../../consts";
export const ACTIONS = {
  OPEN_SIDEBAR: createPrefixedActionName(MARKETING_INTEGRATIONS, "OPEN_SIDEBAR"),
  CLOSE_SIDEBAR: createPrefixedActionName(MARKETING_INTEGRATIONS, "CLOSE_SIDEBAR")
};
export const openSidebar = () => ({
  type: ACTIONS.OPEN_SIDEBAR
});
export const closeSidebar = () => ({
  type: ACTIONS.CLOSE_SIDEBAR
});
