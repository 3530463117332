import React, { useState } from "react";
import Button from "../../../button";
import "./add-link.sass";
import { toast } from "react-toastify";
import { getUrlAddress, isDomainAddress, isUrlAddress } from "../../../../services/functions/url-checker/url-address";
import EditInput from "../../../../feature/experience/components/experience/edit-input/edit-input";
const AddLink = ({ urlLink, dataUpdate, onClose }) => {
  const [urlValue, setUrlValue] = useState(urlLink);
  function onCancelClick() {
    onClose(false);
  }
  function onSaveClick() {
    if (urlValue === "" || isUrlAddress(urlValue) || isDomainAddress(urlValue)) {
      onSubmit();
      onClose(false);
    } else {
      toast.error("It is not correct address URL, example: https://example.com");
    }
  }
  function onSubmit() {
    dataUpdate({ urlLink: getUrlAddress(urlValue) });
  }
  return /* @__PURE__ */ React.createElement(
    "form",
    {
      noValidate: true,
      onSubmit: (e) => {
        e.preventDefault();
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "tint-font-menu-url", id: "tint-font-menu-url-input" }, /* @__PURE__ */ React.createElement(
      EditInput,
      {
        defaultValue: urlValue,
        placeholder: "Enter URL",
        onChange: (e) => {
          setUrlValue(e.target.value);
        },
        autoFocus: true
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "tint-font-menu-url__nav" }, /* @__PURE__ */ React.createElement(Button, { className: "tint-btn tint-btn--primary", onClick: () => onSaveClick() }, /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-check" })), /* @__PURE__ */ React.createElement(Button, { className: "tint-btn tint-btn--secondary", onClick: () => onCancelClick() }, /* @__PURE__ */ React.createElement("span", { className: "icon fas fa-times" }))))
  );
};
export default AddLink;
